import React, { useState } from 'react';

import _ from 'lodash';

import {
    TextField,
    withTheme,
    Card,
    CardHeader,
    CardContent,
    Collapse,
    IconButton,
    Icon,
    colors
} from '@material-ui/core';

import StringListInput from 'components/CustomInputs/StringListInput';

function SocialShareInput({ path, formik, headerText, theme, isExpanded = false }) {
    const [expanded, setExpanded] = useState(isExpanded);

    const handleExpand = () => {
        setExpanded(!expanded);
    };

    const hasError =
        (_.get(formik.touched, `${path}.subject`, false) && _.get(formik.errors, `${path}.subject`, false)) ||
        (_.get(formik.touched, `${path}.message`, false) && _.get(formik.errors, `${path}.message`, false)) ||
        (_.get(formik.touched, `${path}.hashtags`, false) && _.get(formik.errors, `${path}.hashtags`, false));

    return (
        <Card
            style={{
                marginTop: theme.spacing.unit,
                boxShadow: 'none',
                border: '1px solid rgba(224, 224, 224, 1)'
            }}
        >
            <CardHeader
                titleTypographyProps={{ variant: 'h6' }}
                title={headerText}
                action={
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                        {hasError && <Icon style={{ color: colors.red[500] }}>error</Icon>}
                        <IconButton onClick={handleExpand}>
                            <Icon>keyboard_arrow_down</Icon>
                        </IconButton>
                    </span>
                }
            />
            <Collapse in={expanded}>
                <CardContent>
                    <TextField
                        {...formik.getFieldProps(`${path}.subject`)}
                        error={
                            _.get(formik.touched, `${path}.subject`, false) &&
                            _.get(formik.errors, `${path}.subject`, false)
                                ? true
                                : null
                        }
                        label="Subject"
                        style={{ marginTop: theme.spacing.unit }}
                        multiline
                        variant="outlined"
                        helperText={
                            _.get(formik.touched, `${path}.subject`, false) &&
                            _.get(formik.errors, `${path}.subject`, false)
                                ? _.get(formik.errors, `${path}.subject`, false)
                                : null
                        }
                        fullWidth
                    />
                    <div style={{ marginTop: theme.spacing.unit }}>
                        <TextField
                            {...formik.getFieldProps(`${path}.message`)}
                            error={
                                _.get(formik.touched, `${path}.message`, false) &&
                                _.get(formik.errors, `${path}.message`, false)
                                    ? true
                                    : null
                            }
                            label="Message"
                            style={{ marginTop: theme.spacing.unit }}
                            multiline
                            variant="outlined"
                            helperText={
                                _.get(formik.touched, `${path}.message`, false) &&
                                _.get(formik.errors, `${path}.message`, false)
                                    ? _.get(formik.errors, `${path}.message`, false)
                                    : null
                            }
                            fullWidth
                        />
                    </div>
                    <StringListInput
                        label="Hashtags"
                        values={_.get(formik.values, `${path}.hashtags`)}
                        onChange={values => formik.setFieldValue(`${path}.hashtags`, values)}
                        error={() => {}}
                    />
                </CardContent>
            </Collapse>
        </Card>
        /*
        <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">{headerText}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails style={{ flexDirection: 'column' }}>
                {getTextInput(theme, `social.${path}.subject`, 'Subject', formik)}
                <TextField
                    {...formik.getFieldProps(`social.${path}.message`)}
                    error={
                        _.get(formik.touched, `social.${path}.message`, false) &&
                        _.get(formik.errors, `social.${path}.message`, false)
                            ? true
                            : null
                    }
                    label="Message"
                    style={{ marginTop: theme.spacing.unit }}
                    multiline
                    variant="outlined"
                    helperText={
                        _.get(formik.touched, `social.${path}.message`, false) &&
                        _.get(formik.errors, `social.${path}.message`, false)
                            ? _.get(formik.errors, `social.${path}.message`, false)
                            : null
                    }
                    fullWidth
                />
                <StringListInput
                    label="Hashtags"
                    values={_.get(formik.values, `social.${path}.hashtags`)}
                    onChange={values => formik.setFieldValue(`social.${path}.hashtags`, values)}
                    error={() => {}}
                />
            </ExpansionPanelDetails>
                </ExpansionPanel>*/
    );
}

export default withTheme()(SocialShareInput);
