import React from 'react';
import _ from 'lodash';

import { withTheme } from '@material-ui/core/styles';

import { TextField } from '@material-ui/core';
import CustomWrapper from 'containers/Operators/BulkCounter/CustomWrapper';

const DAYS_OF_WEEK = ['Sun', 'Mon', 'Tues', 'Wed', 'Thu', 'Fri', 'Sat'];

function CollectorCapacities(props) {
    const { theme, onChange, value } = props;

    const handleChange = (e, index) => {
        const cloneArr = _.cloneDeep(value);
        const newVal = _.get(e, 'target.value', 0);

        if (!_.isEmpty(newVal)) {
            cloneArr[index] = parseInt(e.target.value);
        } else {
            cloneArr[index] = 0;
        }
        onChange(cloneArr);
    };

    return (
        <CustomWrapper title="Capacities">
            {DAYS_OF_WEEK.map((day, index) => (
                <TextField
                    key={`${index}-${day}-collector-capacity`}
                    data-cy={`${index}-collector-capacity`}
                    id="bulk-label"
                    type="number"
                    label={day}
                    value={value[index]}
                    margin="normal"
                    InputProps={{ inputProps: { min: 0, max: 999 } }}
                    style={{ width: 68, marginLeft: theme.spacing.unit, marginTop: theme.spacing.unit / 2 }}
                    onChange={e => handleChange(e, index)}
                />
            ))}
        </CustomWrapper>
    );
}

export default withTheme()(CollectorCapacities);
