// getMapCenter has been moved to system config. please use the config intead
export const getMapCenter = () => {
    switch (process.env.REACT_APP_REGION_EXT) {
        case 'STD':
            return { lat: 51.0376, lng: -114.0866 };
        case 'EXP':
            return { lat: 49.2551, lng: -123.0099 };
        case 'MXD':
            return { lat: 46.229, lng: -71.7748 };
        case 'CON':
            return { lat: 45.498, lng: -73.705 };
        case 'AUS':
            return { lat: -35.2809, lng: 149.13 };
        default:
            throw new Error('Invalid REACT_APP_REGION_EXT');
    }
};

// MODES has been moved to constants in Operator Pickup constants due to the need to check referential equality
export const MODES = {
    ADMIN: {
        index: 0,
        message: `Looks like you already know what you're doing!`,
        icon: 'developer_mode'
    },
    PICKUP_SELECT: {
        index: 1,
        message: 'Select how to handle this pickup:',
        icon: 'ballot'
    },
    PICKUP: {
        index: 2,
        message: 'Proceed to your next pickup:',
        icon: 'directions_car'
    },
    SELECT: {
        index: 3,
        message: 'Select your next action:',
        icon: 'ballot'
    },
    DROPOFF: {
        index: 4,
        message: 'Proceed to your drop zone:',
        icon: 'directions_car'
    },
    START_ROUTE: {
        index: 5,
        message: 'Proceed to your starting location',
        icon: 'directions_car'
    }
};
