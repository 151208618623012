import React from 'react';

import { withTheme, Icon, IconButton, Hidden } from '@material-ui/core';

import DatePicker from 'components/DateTimePickersTz/DatePicker';

function dateRangePicker(props) {
    const {
        theme,
        timezone,
        startDate,
        endDate,
        window,
        disabled,
        handlePrevious,
        handleNext,
        handleChangeStartDate,
        handleChangeEndDate,
        startLabel = 'Start Date',
        endLabel = 'End Date',
        allowFutureDates,
        inputStyle = {}
    } = props;
    return (
        <>
            <Hidden xsDown>
                <IconButton onClick={handlePrevious} disabled={disabled || window === 'allTime'}>
                    <Icon style={{ position: 'relative', left: 4 }}>arrow_back_ios</Icon>
                </IconButton>
            </Hidden>
            <DatePicker
                timezone={timezone}
                label={startLabel}
                disableFuture={!allowFutureDates}
                format="DD MMM YYYY"
                value={startDate}
                disabled={disabled}
                style={{ margin: theme.spacing.unit }}
                inputStyle={inputStyle}
                onChange={handleChangeStartDate}
            />
            <div style={{ marginRight: theme.spacing.unit * 2 }} />
            <DatePicker
                timezone={timezone}
                type="end"
                label={endLabel}
                disableFuture={!allowFutureDates}
                format="DD MMM YYYY"
                value={endDate}
                disabled={disabled}
                style={{ margin: theme.spacing.unit }}
                inputStyle={inputStyle}
                onChange={handleChangeEndDate}
            />
            <Hidden xsDown>
                <IconButton aria-label="left" onClick={handleNext} disabled={disabled || window === 'allTime'}>
                    <Icon>arrow_forward_ios</Icon>
                </IconButton>
            </Hidden>
        </>
    );
}

export default withTheme()(dateRangePicker);
