import React from 'react';

import { withTheme } from '@material-ui/core/styles';
import ServiceTable from 'components/CRUDTables/ServiceTable';

function Services(props) {
    return <ServiceTable {...props} />;
}

export default withTheme()(Services);
