import React, { useContext } from 'react';

import { formatAsCurrency } from '../../../utils/misc';

import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

const NotApproved = ({ onClose }) => {
    const { lang } = useContext(LocalizationContext);
    return (
        <>
            <DialogContent>
                <DialogContentText>{loc('redemption60', lang)}</DialogContentText>
            </DialogContent>
            <DialogActions style={{ marginBottom: 'max(8px, env(safe-area-inset-bottom, 8px))' }}>
                <Hidden smUp>
                    <Button color="primary" onClick={onClose} data-cy="redemption-dialog-close-button">
                        {loc('close', lang)}
                    </Button>
                </Hidden>
            </DialogActions>
        </>
    );
};

export default NotApproved;
