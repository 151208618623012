import React, { useState, useEffect } from 'react';

import _ from 'lodash';

import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { withTheme } from '@material-ui/core/styles';

import OTCGroupTable from 'components/CRUDTables/OTCGroupTable';
import OTCLocationTable from 'components/CRUDTables/OTCLocationTable';

function OTC(props) {
    const { theme, http, operator } = props;
    const isMultiCollector = _.get(operator, 'collectors', []).length > 1;
    const managedCollectors = _.get(operator, 'collectors', []);

    const [toggle, setToggle] = useState('group');
    const [groupFilter, setGroupFilter] = useState('all');
    const [allCollectors, setAllCollectors] = useState([]);

    useEffect(() => {
        if (isMultiCollector) {
            setAllCollectors(managedCollectors);
        } else {
            getAllCollectors();
        }
    }, []);

    async function getAllCollectors() {
        const res = await http.getJSON('/collectors');
        if (res.ok) {
            setAllCollectors(_.get(res, 'data.collectors', []));
        }
    }

    const handleToggle = (e, toggleType) => {
        setToggle(toggleType);
    };

    return (
        <>
            <div style={{ padding: theme.spacing.unit * 2, display: 'flex', justifyContent: 'center' }}>
                <ToggleButtonGroup value={toggle} exclusive onChange={handleToggle}>
                    <ToggleButton value="group" data-cy="groups-tab">
                        External Groups
                    </ToggleButton>
                    <ToggleButton value="location" data-cy="locations-tab">
                        Locations
                    </ToggleButton>
                </ToggleButtonGroup>
            </div>
            {toggle === 'group' && (
                <OTCGroupTable
                    {...props}
                    handleToggleTableTab={setToggle}
                    setGroupFilter={setGroupFilter}
                    allCollectors={allCollectors}
                />
            )}
            {toggle === 'location' && (
                <OTCLocationTable
                    {...props}
                    setGroupFilter={setGroupFilter}
                    groupFilter={groupFilter}
                    allCollectors={allCollectors}
                />
            )}
        </>
    );
}

export default withTheme()(OTC);
