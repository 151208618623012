import React, { useEffect, useState, useContext } from 'react';

import _ from 'lodash';
import moment from 'moment-timezone';

import { formatAsCurrency, downloadObjectAsCSV, deviceHelper } from 'utils/misc';

import {
    getBulksForMonth,
    getDonationsFromRedemptions,
    getCompleteRedemptions,
    addPostalCodes
} from 'helpers/charityDonationsHelper';

import {
    colors,
    Hidden,
    Icon,
    IconButton,
    CircularProgress,
    Grid,
    Paper,
    Toolbar,
    Typography,
    Select,
    MenuItem,
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    LinearProgress,
    Tooltip
} from '@material-ui/core';

import { withTheme } from '@material-ui/core/styles';

import SnackbarContext from 'components/CustomSnackbar/SnackbarContext';
import GoogleContext from 'utils/contexts/GoogleContext';
import HttpContext from 'utils/contexts/HttpContext';

// TODO: do not use dev express anymore
import {
    PagingState,
    IntegratedPaging,
    SortingState,
    IntegratedSorting,
    DataTypeProvider
} from '@devexpress/dx-react-grid';
import {
    Grid as TableGrid,
    Table,
    TableHeaderRow,
    PagingPanel,
    TableColumnVisibility
} from '@devexpress/dx-react-grid-material-ui';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc, locDate } from 'localizations/localizationHandler';

function CharityDonations(props) {
    const onSnackbar = useContext(SnackbarContext);
    const http = useContext(HttpContext);
    const { placesService, google } = useContext(GoogleContext);
    const { lang } = useContext(LocalizationContext);

    const { theme, bulks, charity, redemptions, taxReceiptsIssued, teamsColumnVisible } = props;

    const [loadingDonatedRedemptions, setLoadingDonatedRedemptions] = useState(true);
    const [donatedRedemptions, setDonatedRedemptions] = useState([]);

    const [redeemedRows, setRedeemedRows] = useState([]);
    const [unredeemedRows, setUnredeemedRows] = useState([]);

    const [completeRedemptions, setCompleteRedemptions] = useState(getCompleteRedemptions(redemptions));
    const [redemptionSelected, setRedemptionSelected] = useState(
        !_.isEmpty(getCompleteRedemptions(redemptions)) ? _.last(getCompleteRedemptions(redemptions))._id : ''
    );

    const [help1DialogOpen, setHelp1DialogOpen] = useState(false);
    const [help2DialogOpen, setHelp2DialogOpen] = useState(false);
    const [monthSelected, setMonthSelected] = useState(moment());

    const [generatingCSV, setGeneratingCSV] = useState(false);

    const handleRedemptionSelected = e => {
        setRedemptionSelected(e.target.value);
    };

    const handleHelp1Dialog = state => {
        setHelp1DialogOpen(state);
    };

    const handleHelp2Dialog = state => {
        setHelp2DialogOpen(state);
    };

    const handleNextMonthSelected = () => {
        setMonthSelected(moment(monthSelected).add(1, 'M'));
    };

    const handlePrevMonthSelected = () => {
        setMonthSelected(moment(monthSelected).subtract(1, 'M'));
    };

    const containerBreakdownRows = getBulksForMonth(bulks, monthSelected);

    const handleDownloadUnredeemedCSV = async () => {
        let data = getDonationsFromRedemptions(
            donatedRedemptions,
            completeRedemptions,
            undefined,
            true,
            taxReceiptsIssued,
            teamsColumnVisible,
            lang
        );

        setGeneratingCSV(true);

        data = await addPostalCodes(http, google, placesService, data);
        let date = locDate(moment(), 'YYYY_MM_DD', lang);

        setGeneratingCSV(false);
        const fileName = `donations_UNREDEEMED_${date}`;
        try {
            await downloadObjectAsCSV(data, fileName);
            if (deviceHelper.isNativeApp()) {
                onSnackbar(loc('charitySnackbar15', lang, { name: fileName }));
            }
        } catch (err) {
            onSnackbar(loc('charitySnackbar16', lang), 'error');
        }
    };

    const handleDownloadCSV = async () => {
        let data = getDonationsFromRedemptions(
            donatedRedemptions,
            completeRedemptions,
            redemptionSelected,
            true,
            taxReceiptsIssued,
            teamsColumnVisible,
            lang
        );

        setGeneratingCSV(true);

        data = await addPostalCodes(http, google, placesService, data);

        let date = locDate(
            completeRedemptions.find(redemption => redemption._id === redemptionSelected).updatedAt,
            'YYYY_MM_DD',
            lang
        );
        setGeneratingCSV(false);

        const fileName = `donations_${date}`;
        try {
            await downloadObjectAsCSV(data, fileName);
            if (deviceHelper.isNativeApp()) {
                onSnackbar(loc('charitySnackbar15', lang, { name: fileName }));
            }
        } catch (err) {
            onSnackbar(loc('charitySnackbar16', lang), 'error');
        }
        //await downloadObjectAsCSV(data, `donations_${date}`);
    };

    useEffect(() => {
        async function getDonatedRedemptions() {
            const res = await http.getJSON(`/charities/donatedRedemptions/${charity._id}`);
            try {
                setDonatedRedemptions(res.data.donatedRedemptions);
                setLoadingDonatedRedemptions(false);
            } catch (err) {
                console.error('Error loading donated redemptions');
            }
        }
        getDonatedRedemptions();
    }, []);

    const disabledToolTip = (
        <div style={{ display: 'flex', verticalAlign: 'center' }}>
            <Typography variant="caption" style={{ color: theme.palette.text.disabled }}>
                N/A
            </Typography>
            <Tooltip title="Customer has chosen to not share their customer info">
                <Icon
                    style={{
                        fontSize: 15,
                        marginTop: 2,
                        marginLeft: theme.spacing.unit / 2
                    }}
                >
                    info
                </Icon>
            </Tooltip>
        </div>
    );

    useEffect(() => {
        let tempUnredeemedRows = getDonationsFromRedemptions(
            donatedRedemptions,
            completeRedemptions,
            undefined,
            false,
            taxReceiptsIssued,
            teamsColumnVisible,
            lang
        );
        for (let obj of tempUnredeemedRows) {
            if (obj.disabled) {
                obj.email = disabledToolTip;
                obj.phone = disabledToolTip;
                obj.address = disabledToolTip;
            }
        }
        setUnredeemedRows(tempUnredeemedRows);

        let tempRedeemedRows = !_.isEmpty(completeRedemptions)
            ? getDonationsFromRedemptions(
                  donatedRedemptions,
                  completeRedemptions,
                  redemptionSelected,
                  false,
                  taxReceiptsIssued,
                  teamsColumnVisible,
                  lang
              )
            : [];
        for (let obj of tempRedeemedRows) {
            if (obj.disabled) {
                obj.email = disabledToolTip;
                obj.phone = disabledToolTip;
                obj.address = disabledToolTip;
            }
        }
        setRedeemedRows(tempRedeemedRows);
    }, [donatedRedemptions, completeRedemptions, redemptionSelected, taxReceiptsIssued, teamsColumnVisible, lang]);

    if (_.isNil(bulks) || _.isNil(completeRedemptions)) {
        return (
            <Paper
                style={{
                    width: `calc(100% - ${theme.spacing.unit * 4}px)`,
                    margin: theme.spacing.unit * 2,
                    padding: theme.spacing.unit * 4,
                    textAlign: 'center'
                }}
            >
                <CircularProgress style={{ color: 'primary' }} />
            </Paper>
        );
    }

    let hiddenColumns = [];
    if (!taxReceiptsIssued) {
        hiddenColumns = hiddenColumns.concat(['email', 'phone', 'address']);
    }
    if (!teamsColumnVisible) {
        hiddenColumns = hiddenColumns.concat(['team']);
    }

    const DateFormatter = ({ value }) => (!_.isNil(value) ? locDate(value, 'MMM Do YYYY', lang) : '');

    const DateTypeProvider = props => <DataTypeProvider formatterComponent={DateFormatter} {...props} />;

    const integratedSortingColumnExtensions = [
        { columnName: 'amount', compare: compareCurrencies },
        { columnName: 'startDate', compare: compareDates },
        { columnName: 'cutOffDate', compare: compareDates },
        { columnName: 'date', compare: compareDates }
    ];

    return (
        <>
            <Grid container spacing={theme.spacing.unit * 4} style={{ width: '100%', margin: 0 }}>
                <Grid item xs={12}>
                    <Paper data-cy="charity-donations-unredeemed-table" style={{ position: 'relative' }}>
                        <Toolbar
                            style={{
                                flexWrap: 'wrap',
                                justifyContent: 'space-between',
                                paddingRight: theme.spacing.unit * 2
                            }}
                        >
                            <Hidden smUp>
                                <div style={{ width: '100%', paddingTop: theme.spacing.unit * 2 }} />
                            </Hidden>
                            <Typography variant="h6">
                                {loc('taxReceipts23', lang)}{' '}
                                <IconButton onClick={() => handleHelp1Dialog(true)}>
                                    <Icon>help</Icon>
                                </IconButton>
                            </Typography>
                            <Button
                                color="primary"
                                disabled={_.isEmpty(unredeemedRows)}
                                onClick={handleDownloadUnredeemedCSV}
                            >
                                {loc('taxReceipts25', lang)}
                            </Button>
                        </Toolbar>
                        {loadingDonatedRedemptions ? (
                            <LinearProgress />
                        ) : (
                            <TableGrid
                                rows={unredeemedRows}
                                columns={[
                                    { title: loc('taxReceiptCol1', lang), name: 'name' },
                                    { title: loc('taxReceiptCol2', lang), name: 'email' },
                                    { title: loc('taxReceiptCol3', lang), name: 'phone' },
                                    { title: loc('taxReceiptCol4', lang), name: 'address' },
                                    { title: loc('taxReceiptCol14', lang), name: 'date' },
                                    { title: loc('taxReceiptCol12', lang), name: 'team' },
                                    { title: loc('taxReceiptCol13', lang), name: 'amount' }
                                ]}
                            >
                                <DateTypeProvider for={['date']} />
                                <SortingState defaultSorting={[{ columnName: 'name', direction: 'asc' }]} />
                                <PagingState defaultCurrentPage={0} />
                                <IntegratedSorting columnExtensions={integratedSortingColumnExtensions} />
                                <IntegratedPaging />
                                <Table cellComponent={Cell(theme)} />
                                <TableHeaderRow showSortingControls />
                                <PagingPanel pageSizes={[5, 10, 25, 0]} />
                                <TableColumnVisibility defaultHiddenColumnNames={hiddenColumns} />
                            </TableGrid>
                        )}
                    </Paper>
                </Grid>

                <Grid item xs={12}>
                    <Paper data-cy="charity-donations-redeemed-table">
                        <Toolbar
                            style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                justifyContent: 'space-between',
                                paddingRight: theme.spacing.unit * 2
                            }}
                        >
                            <Hidden smUp>
                                <div style={{ width: '100%', paddingTop: theme.spacing.unit * 2 }} />
                            </Hidden>
                            <Typography variant="h6">
                                {loc('taxReceipts26', lang)}{' '}
                                <IconButton onClick={() => handleHelp2Dialog(true)}>
                                    <Icon>help</Icon>
                                </IconButton>
                            </Typography>
                            <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
                                <div>
                                    <Select
                                        fullWidth
                                        displayEmpty
                                        value={redemptionSelected}
                                        onChange={handleRedemptionSelected}
                                    >
                                        <MenuItem value="" disabled>
                                            {loc('taxReceipts28', lang)}
                                        </MenuItem>
                                        {completeRedemptions
                                            .slice(0)
                                            .reverse()
                                            .map(redemption => (
                                                <MenuItem key={redemption._id} value={redemption._id}>
                                                    {locDate(redemption.createdAt, 'DD MMM YYYY', lang)}
                                                    <span
                                                        style={{
                                                            marginLeft: theme.spacing.unit,
                                                            color: theme.palette.primary.main
                                                        }}
                                                    >
                                                        ({formatAsCurrency(redemption.amount, lang)})
                                                    </span>
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </div>
                                <Button
                                    color="primary"
                                    disabled={_.isEmpty(redeemedRows)}
                                    onClick={handleDownloadCSV}
                                    style={{ margin: theme.spacing.unit }}
                                >
                                    {loc('taxReceipts25', lang)}
                                </Button>
                            </div>
                        </Toolbar>
                        {loadingDonatedRedemptions ? (
                            <LinearProgress />
                        ) : (
                            <TableGrid
                                rows={redeemedRows}
                                columns={[
                                    { title: loc('taxReceiptCol1', lang), name: 'name' },
                                    { title: loc('taxReceiptCol2', lang), name: 'email' },
                                    { title: loc('taxReceiptCol3', lang), name: 'phone' },
                                    { title: loc('taxReceiptCol4', lang), name: 'address' },
                                    { title: loc('taxReceiptCol11', lang), name: 'date' },
                                    { title: loc('taxReceiptCol12', lang), name: 'team' },
                                    { title: loc('taxReceiptCol13', lang), name: 'amount' }
                                ]}
                            >
                                <DateTypeProvider for={['date']} />
                                <SortingState defaultSorting={[{ columnName: 'name', direction: 'asc' }]} />
                                <PagingState defaultCurrentPage={0} />
                                <IntegratedSorting columnExtensions={integratedSortingColumnExtensions} />
                                <IntegratedPaging />
                                <Table cellComponent={Cell(theme)} />
                                <TableHeaderRow showSortingControls />
                                <PagingPanel pageSizes={[5, 10, 25, 0]} />
                                <TableColumnVisibility defaultHiddenColumnNames={hiddenColumns} />
                            </TableGrid>
                        )}
                    </Paper>
                </Grid>

                <Grid item xs={12}>
                    <Paper data-cy="charity-donations-container-breakdown" style={{ position: 'relative' }}>
                        <Toolbar
                            style={{
                                flexWrap: 'wrap',
                                justifyContent: 'space-between',
                                paddingRight: theme.spacing.unit * 2
                            }}
                        >
                            <Hidden smUp>
                                <div style={{ width: '100%', paddingTop: theme.spacing.unit * 2 }} />
                            </Hidden>
                            <Typography variant="h6">{loc('taxReceipts29', lang)}</Typography>
                            <div
                                style={{
                                    width: 300,
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                <IconButton onClick={handlePrevMonthSelected}>
                                    <Icon>keyboard_arrow_left</Icon>
                                </IconButton>
                                <TextField
                                    value={monthSelected.format('MMMM YYYY')}
                                    InputProps={{
                                        readOnly: true,
                                        disableUnderline: true,
                                        inputProps: {
                                            style: { textAlign: 'center' }
                                        }
                                    }}
                                    style={{ width: '40%' }}
                                />

                                <IconButton
                                    onClick={handleNextMonthSelected}
                                    disabled={moment(monthSelected).add(1, 'M') > moment().endOf('M')}
                                >
                                    <Icon>keyboard_arrow_right</Icon>
                                </IconButton>
                            </div>
                        </Toolbar>
                        <TableGrid
                            rows={containerBreakdownRows}
                            columns={[
                                { title: loc('taxReceipts30', lang), name: 'material' },
                                { title: `${loc('taxReceipts31', lang)} (0-1L)`, name: 'small' },
                                { title: `${loc('taxReceipts32', lang)} (>1L)`, name: 'large' },
                                { title: loc('taxReceipts33', lang), name: 'total' }
                            ]}
                        >
                            <PagingState defaultCurrentPage={0} />
                            <IntegratedPaging />
                            <Table cellComponent={Cell(theme)} />
                            <TableHeaderRow />
                            <PagingPanel pageSizes={[5, 10, 25, 0]} />
                        </TableGrid>
                    </Paper>
                </Grid>
            </Grid>

            <Dialog open={help1DialogOpen} fullWidth onClose={() => handleHelp1Dialog(false)}>
                <DialogTitle>{loc('taxReceipts13', lang)}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{loc('taxReceipts24', lang)}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={() => handleHelp1Dialog(false)}>
                        {loc('close', lang)}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={help2DialogOpen} fullWidth onClose={() => handleHelp2Dialog(false)}>
                <DialogTitle>{loc('taxReceipts13', lang)}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{loc('taxReceipts27', lang)}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={() => handleHelp2Dialog(false)}>
                        {loc('close', lang)}
                    </Button>
                </DialogActions>
            </Dialog>

            {generatingCSV && (
                <div
                    style={{
                        position: 'absolute',
                        width: 'calc(100% - 280px)',
                        height: 'calc(100% - 64px)',
                        background: '#000',
                        opacity: 0.5,
                        zIndex: 99
                    }}
                >
                    <CircularProgress
                        size={120}
                        style={{
                            position: 'absolute',
                            left: '50%',
                            top: '50%',
                            marginLeft: -60,
                            marginTop: -60,
                            color: 'primary'
                        }}
                    />
                </div>
            )}
        </>
    );
}

export default withTheme()(CharityDonations);

const Cell = theme => props => {
    const { row } = props;

    if (row.disabled) {
        return <Table.Cell {...props} style={{ color: theme.palette.text.disabled }} />;
    } else {
        return <Table.Cell {...props} />;
    }
};

function compareCurrencies(a, b) {
    const priorityA = parseFloat(a.substr(1).replace(/,/g, ''), 10);
    const priorityB = parseFloat(b.substr(1).replace(/,/g, ''), 10);
    if (priorityA === priorityB) {
        return 0;
    } else {
        return priorityA < priorityB ? -1 : 1;
    }
}

function compareDates(a, b) {
    if (a === b) {
        return 0;
    } else {
        return a < b ? -1 : 1;
    }
}
