import imageCompression from 'browser-image-compression';
import { handleUploadImage, formatAsMarkdown, options, insertMarkDown, insertTempName } from './helpers';

export async function handlePaste(event, http, onChange) {
    if (!hasPlainText(event)) {
        const fileList = [...event.clipboardData.files];

        for (const file of fileList) {
            if (file.type.indexOf('image') !== -1) {
                event.preventDefault();

                const compressedFile = await imageCompression(file, options);

                const tempFileName = file.name || 'image';
                insertTempName(event, tempFileName, onChange);

                let imgUrl = await handleUploadImage(http, compressedFile, { urlPrefix: 'DRIVER_COMMENT' });

                let markdown = formatAsMarkdown(imgUrl, tempFileName);
                insertMarkDown(event, tempFileName, markdown, onChange);
            }
        }
    }
}

function hasPlainText(event) {
    const clipboardDataList = [...event.clipboardData.items];
    return clipboardDataList.some(item => item.type === 'text/plain');
}
