import React, { useContext } from 'react';

import _ from 'lodash';
import moment from 'moment-timezone';
import dot from 'dot-object';

import Badge from 'components/Badge/Badge';
import { withStyles } from '@material-ui/core/styles';
import {
    Card,
    Typography,
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    IconButton
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withTheme } from '@material-ui/core/styles';
import * as colors from '@material-ui/core/colors';
import { mdiCodeJson } from '@mdi/js';
import MDIcon from '@mdi/react';
import { Clipboard } from '@capacitor/clipboard';

import SnackbarContext from 'components/CustomSnackbar/SnackbarContext';

const LogCard = ({ theme, setSelectedLog, data }) => {
    const onSnackbar = useContext(SnackbarContext);
    const { s, t, i, p, o, c, source, target, createdAt } = data;

    let parsedData;
    if (o === 'u') {
        parsedData = parseChanges(data);
    }

    const handleClick = e => {
        e.stopPropagation();
        setSelectedLog(data);
    };

    const handleCopy = string => e => {
        e.stopPropagation();
        try {
            onSnackbar(`Copied ${string} to your clipboard!`, 'success', 1000);
            Clipboard.write({ string });
        } catch (err) {
            onSnackbar('Unable to copy value to clipboard', 'error', 1000);
        }
    };

    const StyledExpansionPanelSummary = withStyles({
        root: {
            padding: '0'
        },
        content: {
            margin: '0'
        }
    })(ExpansionPanelSummary);

    const copyStyle = { textDecoration: 'underline', cursor: 'pointer' };
    const previewStyle = { display: 'flex', flexWrap: 'wrap', overflow: 'hidden', height: '35px' };

    return (
        <>
            <Card
                style={{
                    margin: `${theme.spacing.unit}px auto`,
                    border: '1px solid',
                    borderColor: theme.palette.grey[300]
                }}
            >
                {o === 'u' && (
                    <ExpansionPanel style={{ padding: theme.spacing.unit }}>
                        <StyledExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                            <div
                                style={{
                                    display: 'grid',
                                    gridTemplateColumns: 'max-content 1fr',
                                    gridGap: '10px'
                                }}
                            >
                                <Typography variant="body2">
                                    <IconButton onClick={handleClick} onFocus={e => e.stopPropagation()}>
                                        <MDIcon path={mdiCodeJson} size={0.75} />
                                    </IconButton>
                                    <span style={{ width: 150, display: 'inline-block' }}>
                                        {moment(createdAt).format('MMM Do h:mm A')}
                                    </span>
                                    <span onClick={handleCopy(s)} style={copyStyle}>
                                        {!_.isNil(p)
                                            ? p
                                            : `${_.get(source, 'name.first', 'Native DB')} ${_.get(
                                                  source,
                                                  'name.last',
                                                  'Event'
                                              )}`}
                                    </span>{' '}
                                    {ops[o]}{' '}
                                    <span onClick={handleCopy(t)} style={copyStyle}>
                                        {_.get(target, 'name.first', 'null')} {_.get(target, 'name.last', null)}'s
                                    </span>{' '}
                                    <span onClick={handleCopy(i)}>{colls[c]}</span>
                                    <br />
                                </Typography>
                                <div style={previewStyle}>
                                    {parsedData &&
                                        parsedData.map(({ path, prev, curr }, i) => {
                                            return (
                                                <Badge
                                                    style={{
                                                        marginRight: theme.spacing.unit,
                                                        marginBottom: theme.spacing.unit,
                                                        textAlign: 'center'
                                                    }}
                                                    key={i}
                                                >
                                                    <div style={{ fontWeight: '500' }}>{path}</div>
                                                    <div style={{ color: 'rgba(0, 0, 0, .5)' }}>
                                                        {_.isEmpty(prev) ? (
                                                            <span style={{ fontStyle: 'oblique' }}>null</span>
                                                        ) : (
                                                            prev
                                                        )}{' '}
                                                        &#8594;{' '}
                                                        {_.isEmpty(curr) ? (
                                                            <span style={{ fontStyle: 'oblique' }}>null</span>
                                                        ) : (
                                                            curr
                                                        )}
                                                    </div>
                                                </Badge>
                                            );
                                        })}
                                </div>
                            </div>
                        </StyledExpansionPanelSummary>

                        <ExpansionPanelDetails
                            style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                overflow: 'scroll'
                            }}
                        >
                            {parsedData &&
                                parsedData.map(({ path, prev, curr }, count) => {
                                    return (
                                        <Badge
                                            key={count}
                                            style={{
                                                marginRight: theme.spacing.unit,
                                                marginBottom: theme.spacing.unit,
                                                textAlign: 'center'
                                            }}
                                        >
                                            <div style={{ fontWeight: '500' }}>{path}</div>
                                            <div style={{ color: 'rgba(0, 0, 0, .5)' }}>
                                                {_.isEmpty(prev) ? (
                                                    <span style={{ fontStyle: 'oblique' }}>null</span>
                                                ) : (
                                                    prev
                                                )}{' '}
                                                &#8594;{' '}
                                                {_.isEmpty(curr) ? (
                                                    <span style={{ fontStyle: 'oblique' }}>null</span>
                                                ) : (
                                                    curr
                                                )}
                                            </div>
                                        </Badge>
                                    );
                                })}
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                )}
                {o === 'l' && (
                    <div style={{ padding: theme.spacing.unit }}>
                        <Typography variant="body2">
                            <IconButton onClick={handleClick} onFocus={e => e.stopPropagation()}>
                                <MDIcon path={mdiCodeJson} size={0.75} />
                            </IconButton>
                            <span style={{ width: 150, display: 'inline-block' }}>
                                {moment(createdAt).format('MMM Do h:mm A')}
                            </span>
                            <span onClick={handleCopy(s)} style={copyStyle}>
                                {!_.isNil(p)
                                    ? p
                                    : `${_.get(source, 'name.first', 'Native DB')} ${_.get(
                                          source,
                                          'name.last',
                                          'Event'
                                      )}`}
                            </span>{' '}
                            {ops[o]} <span>{p}</span>{' '}
                        </Typography>
                    </div>
                )}
                {['i', 'd'].includes(o) && (
                    <div style={{ padding: theme.spacing.unit }}>
                        <Typography variant="body2">
                            <IconButton onClick={handleClick} onFocus={e => e.stopPropagation()}>
                                <MDIcon path={mdiCodeJson} size={0.75} />
                            </IconButton>
                            <span style={{ width: 150, display: 'inline-block' }}>
                                {moment(createdAt).format('MMM Do h:mm A')}
                            </span>
                            <span onClick={handleCopy(s)} style={copyStyle}>
                                {!_.isNil(p)
                                    ? p
                                    : `${_.get(source, 'name.first', 'Native DB')} ${_.get(
                                          source,
                                          'name.last',
                                          'Event'
                                      )}`}
                            </span>{' '}
                            {ops[o]}{' '}
                            <span onClick={handleCopy(t)} style={copyStyle}>
                                {_.get(target, 'name.first', 'null')} {_.get(target, 'name.last', null)}'s
                            </span>{' '}
                            <span onClick={handleCopy(i)}>{colls[c]}</span>
                            <br />
                        </Typography>
                    </div>
                )}
            </Card>
        </>
    );
};

export default withTheme()(LogCard);

function parseChanges(data) {
    const formattedData = [];

    // Parse updated fields
    if (!_.isNil(data.u.updatedFields)) {
        const dotData = dot.dot(data.u.updatedFields);
        for (let path in dotData) {
            if (_.get(data.prev, path, null) !== _.get(data.u.updatedFields, path, null)) {
                const row = {
                    path: path,
                    prev: _.toString(_.get(data.prev, path, null)),
                    curr: _.toString(_.get(data.u.updatedFields, path, null))
                };
                formattedData.push(row);
            }
        }
    }
    return formattedData;
}

const ops = {
    i: <span style={{ color: 'green' }}>inserted</span>,
    d: <span style={{ color: 'red' }}>deleted</span>,
    u: <span style={{ color: 'blue' }}>updated</span>,
    l: <span style={{ color: 'purple' }}>logged in with</span>
};

const colls = {
    batches: <Badge>batch</Badge>,
    bulks: <Badge style={{ backgroundColor: colors.deepPurple[500], color: '#fff' }}>bulk</Badge>,
    charities: <Badge>charity</Badge>,
    collectors: <Badge>collector</Badge>,
    configs: <Badge>config</Badge>,
    configuration: <Badge>configuration</Badge>,
    defaultcharitytasks: <Badge>defaultcharitytasks</Badge>,
    entries: <Badge>entries</Badge>,
    locations: <Badge>location</Badge>,
    pickups: <Badge style={{ backgroundColor: colors.blue[500], color: '#fff' }}>pickup</Badge>,
    promos: <Badge>promo</Badge>,
    rates: <Badge>rates</Badge>,
    ratingPlatforms: <Badge>ratingPlatform</Badge>,
    redemptions: <Badge style={{ backgroundColor: colors.green[600], color: '#fff' }}>redemption</Badge>,
    referrals: <Badge>referral</Badge>,
    regions: <Badge>region</Badge>,
    trips: <Badge>trip</Badge>,
    users: <Badge style={{ backgroundColor: colors.red[500], color: '#fff' }}>user</Badge>,
    zones: <Badge>zone</Badge>
};

/*




*/
