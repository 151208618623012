import React, { useState } from 'react';
import _ from 'lodash';

import { withTheme } from '@material-ui/core/styles';
import {
    Avatar,
    Tooltip,
    IconButton,
    Icon,
    Badge,
    Button,
    colors,
    Menu,
    MenuItem,
    ListItemIcon,
    ListItemText,
    withStyles,
    Paper,
    Typography,
    Collapse,
    ListItem,
    ListItemAvatar
} from '@material-ui/core';
import { Icon as MDIcon } from '@mdi/react';
import {
    mdiCodeJson,
    mdiCog,
    mdiPackageVariant,
    mdiMonitorDashboard,
    mdiLabelPercent,
    mdiTextureBox,
    mdiLock,
    mdiDevices
} from '@mdi/js';
import * as allIcons from '@mdi/js';

import { deviceHelper, formatAsCurrency, isEXPRegion } from 'utils/misc';

import CollectorForm from './Forms/CollectorForms/CollectorForm';

import CollectorCommoditiesDialog from './Dialogs/CollectorCommoditiesDialog';
import BulkUpdateZoneDialog from './Dialogs/BulkUpdateZoneDialog';
import BulkStationConfigurationInput from './Inputs/BulkStationConfigurationInput';
import ServiceFeeDialog from './Dialogs/ServiceFeesDialog';
import CommodityPickupEmailsDialog from './Dialogs/CommodityPickupEmailsDialog';
import BulkPermissionsDialog from './Dialogs/BulkPermissionsDialog';
import GasPriceDialog from './Dialogs/GasPriceDialog';

import CRUDTable from './CRUDTable';
import useCRUD from './hooks/useCRUD';

import useGetJSON from 'utils/hooks/useGetJSON';
import RatesInput from './Inputs/RatesInput';
import { useContext } from 'react';

import HttpContext from 'utils/contexts/HttpContext';

import { DAYS_OF_THE_WEEK, maxFileSize } from 'constants.js';
import { useEffect } from 'react';
import { _commodity } from 'std';
import ConfirmDialogContext from 'components/Dialogs/Confirm/ConfirmDialogContext';
import { mdiAlertCircle } from '@mdi/js';
import CustomDebugDialog from 'components/CustomDebugDialog/CustomDebugDialog';
import LocalizationContext from 'utils/contexts/LocalizationContext';

const phoneNumberGroupings = process.env.REACT_APP_PHONE_NUMBER_GROUPING.split(' ');
const styles = theme => ({
    badgeOk: {
        backgroundColor: colors.green[500],
        color: 'white'
    },
    badgeError: {
        backgroundColor: colors.red[500],
        color: 'white'
    }
});

const RATES_BADGE_NUM = {
    'Alberta Rates': 1,
    Other: 2
};

function CollectorCRUDTable(props) {
    const { lang } = useContext(LocalizationContext);
    const { theme, operator, onSnackbar, google, classes, pickupsEnabled, switchToDropLocation } = props;

    const http = useContext(HttpContext);
    const warnAction = useContext(ConfirmDialogContext);

    const { loading: ratesLoading, error: ratesError, data: ratesObj = { rates: [], masterRatesId: 0 } } = useGetJSON(
        '/rates/allRates'
    );
    const { data: containerSizes = [] } = useGetJSON('/system/containerSizes', 'containerSizes');
    const { data: serviceFees = [] } = useGetJSON('/serviceFees', 'collectionData');
    const { data: collectorsConfig = {} } = useGetJSON('/config/collectors', 'collectorsConfig');

    /*const { loading: commoditiesLoading, error: commoditiesError, data: allCommodities = [] } = useGetJSON(
        '/commodities/getAllEnabledCommodities',
        'commodities'
    );*/

    const endpoints = {
        getEndPoint: '/collector/getCollectorsCRUD',
        getEditEndPoint: _id => `/collector/${_id}/updateCollector`, // url to edit documents NOTE: this should be a function
        createEndPoint: '/collector/createCollector' // url to add new documents
    };

    const [allCommodities, setAllCommodities] = useState([]);
    const [commoditiesLoading, setCommoditiesLoading] = useState(false);

    const [commoditiesDialogOpen, setCommoditiesDialogOpen] = useState(false);
    const [zoneCommoditiesDialogOpen, setZoneCommoditiesDialogOpen] = useState(false);
    const [ratesOpen, setRatesOpen] = useState(false);
    const [bulkStationConfigOpen, setBulkStationConfigOpen] = useState(false);
    const [serviceFeesDialogOpen, setServiceFeesDialogOpen] = useState(false);
    const [pickupEmailsDialogOpen, setPickupEmailsDialogOpen] = useState(false);
    const [bulkPermissionsDialogOpen, setBulkPermissionsDialogOpen] = useState(false);
    const [gasPriceDialogOpen, setGasPriceDialogOpen] = useState(false);
    const [zoneMenuEL, setZoneMenuEL] = useState(null);
    const [bulkUpdateDialogOpen, setBulkUpdateDialogOpen] = useState(false);

    const [selectedDocument, setSelectedDocument] = useState(null);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [activeOrderBy, setActiveOrderBy] = useState(null);
    const [activeOrder, setActiveOrder] = useState('asc');

    const [importFile, setImportFile] = useState(undefined);
    const [importing, setImporting] = useState(false);
    const [importReports, setImportReports] = useState([]);
    const [expandedReports, setExpandedReports] = useState([]);

    const [settingsMenuEL, setSettingsMenuEL] = useState(null);
    const [actionsMenuEL, setActionsMenuEL] = useState(null);

    const [debugDialogOpen, setDebugDialogOpen] = useState(false);
    const [debugDialogJSON, setDebugDialogJSON] = useState({});

    const {
        data,
        otherStats,
        startDateFilterStats,
        endDateFilterStats,
        loading,
        setLoading,
        setStartDateFilterStats,
        setEndDateFilterStats,
        dateFilterStatsErrorMessage,
        handleCreate,
        handleEdit,
        handleReloadData
    } = useCRUD({
        endpoints,
        setEditDialogOpen,
        setActiveOrderBy,
        setActiveOrder,
        http,
        onSnackbar
    });

    const masterRateObj = !_.isEmpty(ratesObj) ? ratesObj.rates.find(rate => rate._id === ratesObj.masterRatesId) : {};
    const columns = [
        {
            key: 'name',
            header: 'Name',
            formatValue: (name, collector) => {
                // if (_.get(collector, 'readOnly', false)) {
                if (false) {
                    return (
                        <>
                            {name} <span style={{ color: theme.palette.text.secondary }}>(read only)</span>
                        </>
                    );
                } else {
                    return name;
                }
            }
        },
        { key: 'code', header: 'Code' },
        { key: 'timezone', header: 'Timezone' },
        {
            key: 'configuration',
            header: 'Type',
            formatValue: value => {
                if (value.enableCounting && value.enablePickups) {
                    return 'Both';
                } else if (value.enableCounting) {
                    return 'Processor';
                } else if (value.enablePickups) {
                    return 'Transporter';
                } else {
                    return 'None';
                }
            }
        },
        { key: 'openToPublic', header: 'Open To Public', isBoolean: true },
        {
            key: 'payloadAccepted',
            header: 'Commodities',
            formatValue: value => (
                <span style={{ display: 'flex', alignItems: 'center' }}>
                    {Object.assign([], value)
                        .sort()
                        .map(commodityId => {
                            const commodity = _.find(allCommodities, { _id: commodityId });
                            const commodityIcon = _.get(commodity, 'iconName');
                            const commodityName = _.get(commodity, `payloadInputName.${lang}`);
                            return (
                                <span>
                                    <Tooltip title={commodityName}>
                                        <MDIcon
                                            key={commodityId}
                                            path={_.get(allIcons, commodityIcon)}
                                            size={1}
                                            color={_.get(commodity, 'color')}
                                        />
                                    </Tooltip>
                                </span>
                            );
                        })}
                </span>
            )
        },
        {
            key: 'configuration.rates',
            header: 'Rates',
            formatValue: value => {
                const ratesDoc = _.find(ratesObj.rates, { _id: value });
                return _.get(ratesDoc, 'name', '');
            }
        },
        {
            key: 'configuration.serviceFees',
            header: 'Service Fees',
            formatValue: serviceFees => {
                const serviceFeeStrs = serviceFees.map(serviceFee => formatServiceFee(serviceFee));
                return serviceFeeStrs.join(', ');
            }
        },
        {
            key: 'routeDestinationBy',
            header: 'Destination By',
            formatValue: (routeDestinationBy, rowData) => {
                return rowData.configuration.enablePickups ? routeDestinationBy : null;
            }
        },

        {
            key: 'assignedDrivers',
            header: 'Assigned Drivers',
            formatValue: (assignedDrivers, rowData) => {
                return (
                    rowData.configuration.enablePickups && (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Avatar
                                style={{
                                    backgroundColor: assignedDrivers > 0 ? colors.green[500] : colors.red[500],
                                    height: '24px',
                                    width: '24px',
                                    fontSize: '12px'
                                }}
                            >
                                {assignedDrivers}
                            </Avatar>
                        </div>
                    )
                );
            }
        },
        {
            key: 'configuration.aggregationPreferences',
            header: 'Schedule (Drivers Available)',
            formatValue: (aggregationPreferences, rowData) => {
                return rowData.configuration.enablePickups
                    ? displayDriverAvailableDays(aggregationPreferences, theme)
                    : null;
            }
        },
        {
            key: 'payloadImageConfirmation.minimum',
            header: 'Image Confirmation Minimum',
            formatValue: (minimum, rowData) => {
                return rowData.configuration.enablePickups ? minimum : null;
            }
        },
        {
            key: 'gasPrice',
            header: 'Gas Price',
            formatValue: (gasPrice, rowData) => {
                return rowData.configuration.enablePickups ? gasPrice : null;
            }
        },
        {
            key: 'fuelEfficiency',
            header: 'Fuel Efficiency',
            formatValue: (fuelEfficiency, rowData) => {
                return rowData.configuration.enablePickups ? fuelEfficiency : null;
            }
        },
        {
            key: 'lunchBreakDuration',
            header: 'Lunch',
            formatValue: (lunchBreakDuration, rowData) => {
                return rowData.configuration.enablePickups ? lunchBreakDuration : null;
            }
        },
        {
            key: 'walkInLabelCode',
            header: 'Depot Label Code',
            formatValue: (walkInLabelCode, rowData) => {
                return rowData.configuration.enableCounting ? walkInLabelCode : null;
            }
        },
        {
            key: 'configuration.volumeFeeRates',
            header: 'Volume Fee',
            formatValue: (volumeFeeRates, rowData) => {
                if (!rowData.configuration.enableCounting || _.isNil(volumeFeeRates)) {
                    return null;
                }

                return (
                    <div>
                        {volumeFeeRates.map((rateInfo, index) => (
                            <div key={index} style={{ marginBottom: '4px' }}>
                                <strong>Size:</strong> {rateInfo.containerSize} <strong>Rate:</strong> {rateInfo.rate}
                            </div>
                        ))}
                    </div>
                );
            }
        },
        {
            key: 'hideCustomerIdentification',
            header: 'Hide from Admin',
            formatValue: (hideCustomerIdentification, rowData) => {
                if (rowData.configuration.enableCounting && hideCustomerIdentification) {
                    const isValueTrue = hideCustomerIdentification.hideForAdmin;
                    return (
                        <Icon style={{ color: isValueTrue ? colors.green[500] : colors.red[500] }}>
                            {isValueTrue ? 'check' : 'close'}
                        </Icon>
                    );
                }
                return null;
            }
        },
        {
            key: 'hideCustomerIdentification',
            header: 'Hide from Clerk',
            formatValue: (hideCustomerIdentification, rowData) => {
                if (rowData.configuration.enableCounting && hideCustomerIdentification) {
                    const isValueTrue = hideCustomerIdentification.hideForClerk;
                    return (
                        <Icon style={{ color: isValueTrue ? colors.green[500] : colors.red[500] }}>
                            {isValueTrue ? 'check' : 'close'}
                        </Icon>
                    );
                }
                return null;
            }
        },
        { key: 'emails', header: 'Emails', formatValue: value => (value || []).join(', ') },
        {
            key: 'phones',
            header: 'Phones',
            formatValue: value =>
                (value || [])
                    .map(phone => {
                        value = phone.replace(
                            new RegExp(
                                `(\\d{${phoneNumberGroupings[0]}})(\\d{${phoneNumberGroupings[1]}})(\\d{${
                                    phoneNumberGroupings[2]
                                }})`
                            ),
                            process.env.REACT_APP_PHONE_NUMBER_FORMAT
                        );
                        return value;
                    })
                    .join(', ')
        },
        { key: 'location.description', header: 'Address' },
        { key: 'payloadImageConfirmation.enabled', header: 'Image Confirmation', isBoolean: true },

        { key: 'configuration.currency', header: 'Currency' },
        { key: 'configuration.lateCountsFee.gracePeriod', header: 'Grace Period' },
        {
            key: 'configuration.lateCountsFee.amount',
            header: 'Late Counts Fee',
            formatValue: value => formatAsCurrency(value)
        },
        { key: 'configuration.viewTripsNDaysAhead', header: '# of viewable days' },
        { key: 'intuit.customerRef', header: 'Intuit Customer Ref' },
        {
            key: 'notServicedDates',
            header: 'Unserviced Dates',
            formatValue: value => (value || []).join(', '),
            width: '20ch'
        }
    ];

    if (process.env.REACT_APP_REGION_EXT !== 'AUS') {
        columns.splice(5, 0, {
            key: 'quickdropOverrideCode',
            header: `Override Code`
        });
    }

    const defaultColumnFilters = columns.map(c => c.key);
    const editForm = (
        <CollectorForm
            serviceFees={serviceFees}
            collectors={data}
            commodities={allCommodities}
            collector={selectedDocument}
            selectableCollectors={_.filter(data, c => !c.disabled)}
            onSubmit={_.isNil(selectedDocument) ? handleCreate : handleEdit}
            loading={loading}
            editing={!_.isNil(selectedDocument)}
            open={editDialogOpen}
            onClose={() => setEditDialogOpen(false)}
            http={http}
            google={google}
            ratesObj={ratesObj}
            containerSizes={containerSizes}
            pickupsEnabled={pickupsEnabled}
        />
    );

    const onViewAccount = document => {
        if (deviceHelper.isNativeApp() || JSON.parse(process.env.REACT_APP_AUTOMATED_TESTER_MODE)) {
            props.history.push(`/operators/${document.collectorManager._id}/collector`);
        } else {
            window.open(`/operators/${document.collectorManager._id}/collector`, '_blank');
        }
    };

    const onEditRates = () => {
        if (deviceHelper.isNativeApp()) {
            props.history.push(`/operators/${operator._id}/rates`);
        } else {
            window.open(`/operators/${operator._id}/rates`, '_blank');
        }
    };

    const handleCommodityDialogOpen = document => {
        setSelectedDocument(document);
        setCommoditiesDialogOpen(true);
    };

    const handleRatesOpen = document => {
        setSelectedDocument(document);
        setRatesOpen(true);
    };

    const handleUpdateRates = async rates => {
        const collectorCopy = _.cloneDeep(selectedDocument);
        collectorCopy.configuration.rates = rates;
        const res = await http.postJSON(`/collector/${selectedDocument._id}/updateCollector`, collectorCopy);
        if (res.ok) {
            handleReloadData();
        }
    };

    const handleBulkStationConfigOpen = document => {
        setSelectedDocument(document);
        setBulkStationConfigOpen(true);
    };

    const handleUpdateBulkStationConfig = async updatedBulkStationConfig => {
        const collectorCopy = _.cloneDeep(selectedDocument);
        collectorCopy.bulkStationConfig = updatedBulkStationConfig;
        const res = await http.postJSON(`/collector/${selectedDocument._id}/updateCollector`, collectorCopy);
        if (res.ok) {
            handleReloadData();
        }
    };

    const handleServiceFeesDialogOpen = document => {
        setSelectedDocument(document);
        setServiceFeesDialogOpen(true);
    };

    const handleUpdateCollectorServiceFees = async serviceFees => {
        const collectorCopy = _.cloneDeep(selectedDocument);
        collectorCopy.configuration.serviceFees = serviceFees;
        const res = await http.postJSON(`/collector/${selectedDocument._id}/updateCollector`, collectorCopy);
        if (res.ok) {
            handleReloadData();
            setServiceFeesDialogOpen(false);
        }
    };

    const handlePickupEmailsDialogOpen = document => {
        setSelectedDocument(document);
        setPickupEmailsDialogOpen(true);
    };

    const handleUpdateCommodityPickupEmails = async commodityPickupConfig => {
        const collectorCopy = _.cloneDeep(selectedDocument);
        collectorCopy.commodityPickupConfig = commodityPickupConfig;
        const res = await http.postJSON(`/collector/${selectedDocument._id}/updateCollector`, collectorCopy);
        if (res.ok) {
            handleReloadData();
            setPickupEmailsDialogOpen(false);
        }
    };

    const handleZoneEditClick = (e, collector) => {
        setSelectedDocument(collector);
        setZoneMenuEL(e.currentTarget);
    };

    const handleZoneEditClose = () => {
        setSelectedDocument(null);
        setZoneMenuEL(null);
    };

    const handleZoneEditRedirect = collector => {
        if (deviceHelper.isNativeApp()) {
            props.history.push(
                `/operators/${operator._id}/pickups-manager?lat=${_.get(collector, 'location.lat', '')}&lng=${_.get(
                    collector,
                    'location.lng',
                    ''
                )}&showZones=${true}`
            );
        } else {
            window.open(
                `/operators/${operator._id}/pickups-manager?lat=${_.get(collector, 'location.lat', '')}&lng=${_.get(
                    collector,
                    'location.lng',
                    ''
                )}&showZones=${true}`,
                '_blank'
            );
        }
    };

    const handleBulkUpdateDialog = document => {
        setSelectedDocument(document);
        setBulkUpdateDialogOpen(true);
    };

    const handleBulkUpdateDialogClose = document => {
        setSelectedDocument(null);
        setBulkUpdateDialogOpen(false);
        handleZoneEditClose();
    };

    const handleBulkPermissionsDialogOpen = document => {
        setSelectedDocument(document);
        setBulkPermissionsDialogOpen(true);
    };

    const handleUpdateBulkPermissions = async bulkPermissions => {
        const collectorCopy = _.cloneDeep(selectedDocument);
        collectorCopy.configuration.bulkPermissions = bulkPermissions;
        const res = await http.postJSON(`/collector/${selectedDocument._id}/updateCollector`, collectorCopy);
        if (res.ok) {
            handleReloadData();
            setBulkPermissionsDialogOpen(false);
        }
    };

    const handleSettingsMenuClick = (e, collector) => {
        setSelectedDocument(collector);
        setSettingsMenuEL(e.currentTarget);
    };

    const handleSettingsMenuClose = () => {
        setSelectedDocument(null);
        setSettingsMenuEL(null);
    };

    const handleActionsMenuClick = (e, collector) => {
        setSelectedDocument(collector);
        setActionsMenuEL(e.currentTarget);
    };

    const handleActionsMenuClose = () => {
        setSelectedDocument(null);
        setActionsMenuEL(null);
    };
    const onDebugDialogClose = () => {
        setDebugDialogOpen(false);
    };
    const onViewJSON = document => {
        setDebugDialogJSON(document);
        setDebugDialogOpen(true);
    };
    const onEditRow = document => {
        setSelectedDocument(document);
        setEditDialogOpen(true);
    };
    useEffect(() => {
        (async () => {
            setCommoditiesLoading(true);
            const res = await http.getJSON('/commodities/getAllEnabledCommodities');
            if (res.ok) {
                setAllCommodities(res.data.commodities);
            }
            setCommoditiesLoading(false);
        })();
    }, []);

    const renderExtraActions = document => {
        let totalPermissions = 0;
        const bulkPermissions = _.get(document, 'configuration.bulkPermissions', {});
        const code = _.get(document, 'code', '');
        const bulkTypes = Object.keys(bulkPermissions);
        bulkTypes.forEach(bulkType => {
            const permissionsForBulkType = bulkPermissions[bulkType];
            Object.values(permissionsForBulkType).forEach((permission, i) => {
                totalPermissions += permission ? 1 : 0;
            });
        });
        const payloadAccepted = _.get(document, 'payloadAccepted', []);
        const collectorServiceFees = _.get(document, 'configuration.serviceFees', []);
        const serviceFeeMissing = _.some(payloadAccepted, commodityId => {
            const commodity = _.find(allCommodities, { _id: commodityId });
            const sf = _.find(collectorServiceFees, sf => sf.skuType === _.get(commodity, 'skuType'));
            return _.isNil(sf) || _.isNil(_.get(sf, 'serviceFeeId'));
        });
        return (
            <>
                <Tooltip title="Actions">
                    <IconButton
                        data-cy={`collector-table-actions-button`}
                        onClick={e => {
                            handleActionsMenuClick(e, document);
                        }}
                    >
                        <Icon>tune</Icon>
                    </IconButton>
                </Tooltip>
                <Tooltip title="Settings">
                    <IconButton
                        data-cy={`collector-table-status-button`}
                        onClick={e => {
                            handleSettingsMenuClick(e, document);
                        }}
                    >
                        <MDIcon path={mdiCog} size={1} color={theme.palette.text.secondary} />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Technology">
                    <IconButton
                        data-cy={`collector-table-technology-button`}
                        onClick={e => {
                            switchToDropLocation(e, document._id, document.technologyCount);
                        }}
                    >
                        <Badge
                            classes={{
                                badge:
                                    document.technologyCount > 0
                                        ? classes.badgeOk
                                        : document.openToPublic
                                        ? classes.badgeError
                                        : null
                            }}
                            badgeContent={
                                document.technologyCount > 0 || document.openToPublic ? document.technologyCount : null
                            }
                            showZero
                        >
                            <MDIcon path={mdiDevices} size={1} color={theme.palette.text.secondary} />
                        </Badge>
                    </IconButton>
                </Tooltip>
                {serviceFeeMissing && !_.get(collectorsConfig, 'serviceFeesDisabled', false) && (
                    <Tooltip title="Missing Service Fees">
                        <IconButton>
                            <Icon style={{ color: colors.orange[500] }}>warning</Icon>
                        </IconButton>
                    </Tooltip>
                )}
                {!_.get(document, 'openToPublic', false) && document.technologyCount > 0 && (
                    <Tooltip title="Technologies not open to public">
                        <IconButton>
                            <Icon style={{ color: colors.orange[500] }}>warning</Icon>
                        </IconButton>
                    </Tooltip>
                )}

                {/*<Tooltip title="Collector Commodity Pickup Emails" disableFocusListener disableTouchListener>
                <span>
                    <IconButton
                        onClick={() => {
                            handlePickupEmailsDialogOpen(document);
                        }}
                    >
                        <Icon>email</Icon>
                    </IconButton>
                </span>
                    </Tooltip>*/}
            </>
        );
    };

    const handleDrop = e => {
        const uploadFile = e.target.files[0];
        if (uploadFile.size <= maxFileSize) {
            setImportFile(e.target.files[0]);
        } else {
            onSnackbar('File exceeds max file size', 'error');
        }
    };

    const handleImport = async () => {
        setImporting(true);
        const formData = new FormData();
        formData.append('importFile', importFile);

        const res = await http.postJSON(`/collectors/importCollectors`, formData, false, true);
        if (res.ok) {
            let importReportsCopy = _.cloneDeep(importReports);
            importReportsCopy.splice(0, 0, res.data);
            setImportReports(importReportsCopy);
            let expandedReportsCopy = _.cloneDeep(expandedReports);
            expandedReportsCopy.splice(0, 0, false);
            setExpandedReports(expandedReportsCopy);
            setImportFile(undefined);
            setImporting(false);
            onSnackbar(`${res.data.numOfCollectorsImported} collectors successfully imported`);
        } else {
            setImporting(false);
            onSnackbar('Error importing collectors', 'error');
        }
        handleReloadData();
    };

    const handleExpandReport = (expand, index) => {
        let expandedReportsCopy = _.cloneDeep(expandedReports);
        expandedReportsCopy[index] = expand;
        setExpandedReports(expandedReportsCopy);
    };

    const headerContent = (
        <>
            {pickupsEnabled && (
                <Button onClick={() => setGasPriceDialogOpen(true)} variant="outlined">
                    Bulk Update Gas Price
                </Button>
            )}
            {_.isNil(importFile) && (
                <>
                    <Button
                        style={{ marginLeft: theme.spacing.unit }}
                        variant="outlined"
                        disabled={importing || !_.isNil(importFile)}
                    >
                        <label htmlFor="fileInput" style={{ padding: 0, cursor: 'pointer', display: 'block' }}>
                            Bulk Import Collectors
                        </label>
                    </Button>

                    <input
                        data-cy="file-drop-input"
                        type="file"
                        accept={'.csv'}
                        id="fileInput"
                        name="fileInput"
                        onChange={handleDrop}
                        style={{ display: 'none' }}
                    />
                </>
            )}
        </>
    );

    useEffect(() => {
        if (!_.isNil(importFile)) {
            warnAction(() => {
                return handleImport();
            }, `Are you sure you want to import collectors from ${importFile.name}`);
            setImportFile(undefined);
            setImporting(false);
        }
    }, [importFile]);

    let totalPermissions = 0;
    const bulkPermissions = _.get(selectedDocument, 'configuration.bulkPermissions', {});
    const code = _.get(selectedDocument, 'code', '');
    const bulkTypes = Object.keys(bulkPermissions);
    bulkTypes.forEach(bulkType => {
        const permissionsForBulkType = bulkPermissions[bulkType];
        Object.values(permissionsForBulkType).forEach((permission, i) => {
            totalPermissions += permission ? 1 : 0;
        });
    });
    return (
        <>
            <CRUDTable
                headerContent={headerContent}
                operator={operator}
                columns={columns}
                data={data}
                editForm={editForm}
                documentIsDisabled={collector => _.get(collector, 'disabled', false)}
                // documentNotEditable={collector => _.get(collector, 'readOnly', false)}
                defaultRowsPerPage={5}
                startDateFilterStats={startDateFilterStats}
                setStartDateFilterStats={setStartDateFilterStats}
                endDateFilterStats={endDateFilterStats}
                setEndDateFilterStats={setEndDateFilterStats}
                dateFilterStatsErrorMessage={dateFilterStatsErrorMessage}
                selectedDocument={selectedDocument}
                setSelectedDocument={setSelectedDocument}
                editDialogOpen={editDialogOpen}
                setEditDialogOpen={setEditDialogOpen}
                activeOrderBy={activeOrderBy}
                setActiveOrderBy={setActiveOrderBy}
                activeOrder={activeOrder}
                setActiveOrder={setActiveOrder}
                enabledHeaderText={'Enabled Collectors'}
                disabledHeaderText={'Disabled Collectors'}
                loading={loading}
                renderExtraActions={renderExtraActions}
                hideDateFilters
                defaultColumnFilters={
                    pickupsEnabled
                        ? defaultColumnFilters
                        : _.filter(
                              defaultColumnFilters,
                              f => !['assignedDrivers', 'configuration.aggregationPreferences'].includes(f)
                          )
                }
                hideEditButton={true}
                hideViewJSON={true}
                collectorTypeFilter={isEXPRegion() ? false : true}
            />
            {importReports.map((report, index) => {
                return (
                    <Paper
                        style={{
                            margin: theme.spacing.unit * 2,
                            marginTop: 0,
                            padding: theme.spacing.unit * 2,
                            overflowX: 'scroll'
                        }}
                    >
                        <Typography variant="h6">
                            <IconButton onClick={() => handleExpandReport(!expandedReports[index], index)}>
                                <Icon>{expandedReports[index] ? 'expand_less' : 'expand_more'}</Icon>
                            </IconButton>
                            Imported {_.filter(report.collectors, p => _.isNil(p.errorMessage)).length}/
                            {report.collectors.length} collectors from{' '}
                            <span style={{ fontWeight: 'bold' }}>{report.fileName}</span>
                            {!_.isNil(_.find(report.collectors, p => !_.isNil(p.errorMessage))) && (
                                <Tooltip title={'Some imports were unsuccessful'}>
                                    <span style={{ margin: theme.spacing.unit / 2 }}>
                                        <Icon style={{ color: theme.palette.error.main }}>warning_amber</Icon>
                                    </span>
                                </Tooltip>
                            )}
                        </Typography>
                        <Collapse in={expandedReports[index]}>
                            {report.collectors.map(p => {
                                const hasError = !_.isNil(p.errorMessage);
                                return (
                                    <ListItem style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <ListItemAvatar>
                                                <Avatar
                                                    style={{
                                                        backgroundColor: hasError
                                                            ? theme.palette.error.main
                                                            : theme.palette.primary.main
                                                    }}
                                                >
                                                    {hasError ? (
                                                        <MDIcon path={mdiAlertCircle} size={1} color={'white'} />
                                                    ) : (
                                                        <MDIcon path={allIcons.mdiCheck} size={1} color={'white'} />
                                                    )}
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={
                                                    hasError ? (
                                                        <span style={{ color: theme.palette.error.main }}>
                                                            <span style={{ fontWeight: 'bold' }}>
                                                                Import Unsuccessful:{' '}
                                                            </span>
                                                            {p.errorMessage}
                                                        </span>
                                                    ) : (
                                                        <span>
                                                            <span style={{ fontWeight: 'bold' }}>Name: </span>
                                                            {p.name} |{' '}
                                                            <span style={{ fontWeight: 'bold' }}>Code: </span>
                                                            {p.code} |{' '}
                                                            <span style={{ fontWeight: 'bold' }}>Address: </span>
                                                            {_.get(p, 'location.description', p.address)}
                                                        </span>
                                                    )
                                                }
                                            />
                                        </div>
                                    </ListItem>
                                );
                            })}
                        </Collapse>
                    </Paper>
                );
            })}
            <CollectorCommoditiesDialog
                reloadCollectors={handleReloadData}
                allCommodities={allCommodities}
                commoditiesLoading={commoditiesLoading}
                collector={selectedDocument}
                open={commoditiesDialogOpen}
                onClose={() => setCommoditiesDialogOpen(false)}
            />
            <BulkUpdateZoneDialog
                reloadCollectors={handleReloadData}
                allCommodities={allCommodities}
                commoditiesLoading={commoditiesLoading}
                collector={selectedDocument}
                open={bulkUpdateDialogOpen}
                onClose={handleBulkUpdateDialogClose}
            />
            {ratesOpen && (
                <RatesInput
                    open={ratesOpen}
                    commodities={allCommodities}
                    onClose={() => setRatesOpen(false)}
                    initialRate={_.get(selectedDocument, 'configuration.rates')}
                    onSubmit={handleUpdateRates}
                    ratesObj={ratesObj}
                    onEditRates={onEditRates}
                />
            )}
            {bulkStationConfigOpen && (
                <BulkStationConfigurationInput
                    open={bulkStationConfigOpen}
                    onClose={() => {
                        setBulkStationConfigOpen(false);
                    }}
                    commodities={allCommodities}
                    initialConfig={_.get(selectedDocument, 'bulkStationConfig')}
                    collectorRates={_.find(ratesObj.rates, { _id: _.get(selectedDocument, 'configuration.rates') })}
                    onSubmit={handleUpdateBulkStationConfig}
                    collectors={data}
                    collector={selectedDocument}
                    http={http}
                    onSnackbar={onSnackbar}
                    handleReloadData={handleReloadData}
                />
            )}
            {serviceFeesDialogOpen && (
                <ServiceFeeDialog
                    open={serviceFeesDialogOpen}
                    serviceFees={serviceFees}
                    collector={selectedDocument}
                    collectorRates={_.find(ratesObj.rates, { _id: _.get(selectedDocument, 'configuration.rates') })}
                    commodities={allCommodities}
                    onClose={() => setServiceFeesDialogOpen(false)}
                    onSubmit={handleUpdateCollectorServiceFees}
                />
            )}
            {/*pickupEmailsDialogOpen && (
                <CommodityPickupEmailsDialog
                    open={pickupEmailsDialogOpen}
                    collector={selectedDocument}
                    commodities={allCommodities}
                    onClose={() => setPickupEmailsDialogOpen(false)}
                    onSubmit={handleUpdateCommodityPickupEmails}
                />
            )*/}
            {gasPriceDialogOpen && (
                <GasPriceDialog
                    open={gasPriceDialogOpen}
                    collectors={data}
                    onClose={() => setGasPriceDialogOpen(false)}
                    onReloadData={handleReloadData}
                />
            )}
            {bulkPermissionsDialogOpen && (
                <BulkPermissionsDialog
                    open={bulkPermissionsDialogOpen}
                    collector={selectedDocument}
                    onClose={() => setBulkPermissionsDialogOpen(false)}
                    onSubmit={handleUpdateBulkPermissions}
                />
            )}
            {/* <Menu
                data-cy="collector-edit-zones-btn"
                anchorEl={zoneMenuEL}
                open={Boolean(zoneMenuEL)}
                onClose={handleZoneEditClose}
            >
                <MenuItem onClick={() => handleZoneEditRedirect(selectedDocument)}>
                    <ListItemIcon>
                        <Icon>edit</Icon>
                    </ListItemIcon>
                    <ListItemText primary={'Edit'} />
                </MenuItem>
                <MenuItem
                    onClick={() => handleBulkUpdateDialog(selectedDocument)}
                    disabled={_.get(selectedDocument, 'zoneCount') <= 0}
                >
                    <ListItemIcon>
                        <Icon>list</Icon>
                    </ListItemIcon>
                    <ListItemText primary="Bulk Update" />
                </MenuItem>
            </Menu> */}
            {!_.isNil(selectedDocument) && (
                <Menu
                    data-cy="collector-edit-zones-btn"
                    anchorEl={settingsMenuEL}
                    open={Boolean(settingsMenuEL)}
                    onClose={handleSettingsMenuClose}
                >
                    {pickupsEnabled && (
                        <MenuItem
                            onClick={() => {
                                handleSettingsMenuClose();
                                handleCommodityDialogOpen(selectedDocument);
                            }}
                            // disabled={_.get(document, 'readOnly', false)}
                            data-cy="collector-commodity-pickup-button"
                        >
                            <ListItemIcon>
                                <Badge
                                    classes={{
                                        badge: getCommodityPickupBadgeCount(selectedDocument, allCommodities)
                                            ? classes.badgeOk
                                            : classes.badgeError
                                    }}
                                    badgeContent={getCommodityPickupBadgeCount(selectedDocument, allCommodities)}
                                    showZero
                                >
                                    <MDIcon
                                        path={mdiPackageVariant}
                                        size={1}
                                        color={
                                            // _.get(document, 'readOnly', false)
                                            false ? 'rgba(0, 0, 0, 0.26)' : theme.palette.text.secondary
                                        }
                                    />
                                </Badge>
                            </ListItemIcon>
                            <ListItemText primary={'Commodity Pickup'} />
                        </MenuItem>
                    )}

                    <MenuItem
                        onClick={() => {
                            handleSettingsMenuClose();
                            handleRatesOpen(selectedDocument);
                        }}
                        data-cy="collector-table-rates"
                    >
                        <ListItemIcon>
                            <Badge
                                classes={{
                                    badge: _.get(masterRateObj, 'name') ? classes.badgeOk : classes.badgeError
                                }}
                                badgeContent={
                                    RATES_BADGE_NUM[_.get(masterRateObj, 'name', '')]
                                        ? RATES_BADGE_NUM[_.get(masterRateObj, 'name', '')]
                                        : RATES_BADGE_NUM['Other']
                                }
                                showZero
                            >
                                <Icon
                                    style={{
                                        // color: _.get(document, 'readOnly', false)
                                        color: false ? 'rgba(0, 0, 0, 0.26)' : theme.palette.text.secondary
                                    }}
                                >
                                    price_change
                                </Icon>
                            </Badge>
                        </ListItemIcon>
                        <ListItemText primary={'Rates'} />
                    </MenuItem>

                    {pickupsEnabled && (
                        <MenuItem
                            onClick={() => {
                                handleSettingsMenuClose();
                                handleServiceFeesDialogOpen(selectedDocument);
                            }}
                            // disabled={_.get(document, 'readOnly', false)}
                            data-cy="collector-service-fees-button"
                        >
                            <ListItemIcon>
                                <Badge
                                    classes={{
                                        badge: _.get(selectedDocument, 'configuration.serviceFees', []).length
                                            ? classes.badgeOk
                                            : classes.badgeError
                                    }}
                                    badgeContent={_.get(selectedDocument, 'configuration.serviceFees', []).length}
                                    showZero
                                >
                                    <MDIcon path={mdiLabelPercent} size={1} color={theme.palette.text.secondary} />
                                </Badge>
                            </ListItemIcon>
                            <ListItemText primary={'Service Fees'} />
                        </MenuItem>
                    )}

                    <MenuItem
                        onClick={() => {
                            handleSettingsMenuClose();
                            handleBulkStationConfigOpen(selectedDocument);
                        }}
                        data-cy={`orderStationConfig-${code}`}
                    >
                        <ListItemIcon>
                            <Badge
                                classes={{
                                    badge: _.get(selectedDocument, 'enabledBulkStationConfigCount', 0)
                                        ? classes.badgeOk
                                        : classes.badgeError
                                }}
                                badgeContent={_.get(selectedDocument, 'enabledBulkStationConfigCount', 0)}
                                showZero
                            >
                                <MDIcon path={mdiMonitorDashboard} size={1} color={theme.palette.text.secondary} />
                            </Badge>
                        </ListItemIcon>
                        <ListItemText primary={'Order Station Config'} />
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            handleSettingsMenuClose();
                            handleBulkPermissionsDialogOpen(selectedDocument);
                        }}
                        data-cy={`orderStationConfig-permissions`}
                    >
                        <ListItemIcon>
                            <Badge
                                classes={{
                                    badge: totalPermissions ? classes.badgeOk : classes.badgeError
                                }}
                                badgeContent={totalPermissions}
                                showZero
                            >
                                <MDIcon path={mdiLock} size={1} color={theme.palette.text.secondary} />
                            </Badge>
                        </ListItemIcon>
                        <ListItemText primary={'Edit Permissions'} />
                    </MenuItem>

                    {/* {pickupsEnabled && (
                            <MenuItem
                                onClick={e => {
                                    handleZoneEditClick(e, document);
                                }}
                            >
                                <ListItemIcon>
                                    <IconButton>
                                        <Badge
                                            classes={{
                                                badge: _.get(document, 'zoneCount')
                                                    ? classes.badgeOk
                                                    : classes.badgeError
                                            }}
                                            badgeContent={_.get(document, 'zoneCount')}
                                            showZero
                                        >
                                            <MDIcon path={mdiTextureBox} size={1} color="rgba(0, 0, 0, 0.54)" />
                                        </Badge>
                                    </IconButton>
                                </ListItemIcon>
                                <ListItemText primary={'Edit Zones'} />
                            </MenuItem>
                        )} */}
                    {pickupsEnabled && (
                        <MenuItem
                            onClick={() => {
                                handleSettingsMenuClose();
                                handleZoneEditRedirect(selectedDocument);
                            }}
                        >
                            <ListItemIcon>
                                <Badge
                                    classes={{
                                        badge: _.get(selectedDocument, 'zoneCount')
                                            ? classes.badgeOk
                                            : classes.badgeError
                                    }}
                                    badgeContent={_.get(selectedDocument, 'zoneCount')}
                                    showZero
                                >
                                    <MDIcon path={mdiTextureBox} size={1} color={theme.palette.text.secondary} />
                                </Badge>
                            </ListItemIcon>
                            <ListItemText primary={'Edit Zones'} />
                        </MenuItem>
                    )}
                    {pickupsEnabled && (
                        <MenuItem
                            onClick={() => {
                                handleSettingsMenuClose();
                                handleBulkUpdateDialog(selectedDocument);
                            }}
                            disabled={_.get(selectedDocument, 'zoneCount') <= 0}
                        >
                            <ListItemIcon>
                                {' '}
                                <Icon>list</Icon>
                            </ListItemIcon>
                            <ListItemText primary="Bulk Update" />
                        </MenuItem>
                    )}
                </Menu>
            )}
            {!_.isNil(selectedDocument) && (
                <Menu
                    data-cy="actions-menu"
                    anchorEl={actionsMenuEL}
                    open={Boolean(actionsMenuEL)}
                    onClose={handleActionsMenuClose}
                >
                    <MenuItem
                        onClick={() => {
                            handleActionsMenuClose();
                            onViewAccount(selectedDocument);
                        }}
                        disabled={_.isNil(selectedDocument.collectorManager)}
                        data-cy="collector-table-view-account"
                    >
                        <ListItemIcon>
                            <Icon>visibility</Icon>
                        </ListItemIcon>
                        <ListItemText primary={'View Account'} />
                    </MenuItem>

                    {/* <MenuItem
                        onClick={() => {
                            handleActionsMenuClose();
                            onViewJSON(selectedDocument);
                        }}
                        data-cy="collector-table-view-json"
                    >
                        <ListItemIcon>
                            <MDIcon path={mdiCodeJson} size={1} color={theme.palette.text.secondary} />
                        </ListItemIcon>
                        <ListItemText primary={'View JSON'} />
                    </MenuItem> */}

                    <MenuItem
                        onClick={() => {
                            handleActionsMenuClose();
                            onEditRow(selectedDocument);
                        }}
                        data-cy="collector-table-edit-button"
                        // disabled={!_.isNil(documentNotEditable) ? documentNotEditable(document) : false}
                    >
                        <ListItemIcon>
                            <Icon>edit</Icon>
                        </ListItemIcon>
                        <ListItemText primary={'Edit'} />
                    </MenuItem>
                </Menu>
            )}
            <CustomDebugDialog open={debugDialogOpen} json={debugDialogJSON} onClose={onDebugDialogClose} />
        </>
    );
}

export default withStyles(styles)(withTheme()(CollectorCRUDTable));

function displayDriverAvailableDays(aggregationPreferences, theme) {
    return (
        <div style={{ display: 'flex' }}>
            {aggregationPreferences.map(aggregationPreferencesForDay => {
                const dayOfWeek = _.get(aggregationPreferencesForDay, 'day', 0);
                const drivers = _.get(aggregationPreferencesForDay, 'drivers', []);

                return (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            marginRight: theme.spacing.unit
                        }}
                    >
                        <div
                            style={{
                                textAlign: 'center',
                                backgroundColor: drivers.length > 0 && colors.green[500],
                                color: drivers.length > 0 ? 'white' : colors.grey[500],
                                padding: theme.spacing.unit * 0.5,
                                borderRadius: theme.shape.borderRadius,
                                borderWidth: 1,
                                borderStyle: 'solid',
                                minWidth: '25px'
                            }}
                        >
                            {drivers.length}
                        </div>
                        <div style={{ color: colors.grey[500], textAlign: 'center' }}>
                            {DAYS_OF_THE_WEEK[dayOfWeek].slice(0, 2).toUpperCase()}
                        </div>
                    </div>
                );
            })}
        </div>
    );
}

function formatServiceFee(serviceFee) {
    if (_.get(serviceFee, 'type', '') === 'Percent Fee') {
        return `${serviceFee.skuType} ${serviceFee.value * 100}%`;
    } else if (_.get(serviceFee, 'type', '') === 'Count Split') {
        return `${serviceFee.skuType} ${_.get(serviceFee, 'split.customer', 0)}¢/${_.get(
            serviceFee,
            'split.charity',
            0
        )}¢/${_.get(serviceFee, 'split.collector', 0)}¢/${_.get(serviceFee, 'split.processor', 0)}¢`;
    }
}

function getCommodityPickupBadgeCount(collector, allCommodities) {
    return _.get(collector, 'commodityPickupConfig', []).filter(config => {
        const commodityForConfig = _.find(allCommodities, commodity => commodity._id === config.commodity);
        return (
            config.collectorSubmittable && !_.get(commodityForConfig, 'disabled', false) && !_.isNil(commodityForConfig)
        );
    }).length;
}
