import React, { useMemo, useContext } from 'react';
import _ from 'lodash';

import { DialogActions, DialogContent, DialogContentText, Button, withTheme } from '@material-ui/core';

import { _pickup, _commodity, _dropLocation } from 'std';

import CustomFormTitle from 'components/MaterialUIExtensions/CustomFormTitle';
import PayloadInput from 'components/InputComponents/PayloadInput';
import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

function Payload(props) {
    const {
        payload,
        subPayloads,
        onSubPayloads,
        formErrors,
        customer,
        selectedDropLocation,
        toggleHelpDialog,
        onNext,
        onBack,
        onPayload,
        theme
    } = props;
    const { lang } = useContext(LocalizationContext);
    const payloadAccepted = _dropLocation.getPayloadAccepted(selectedDropLocation);

    const formContainsErrors = _.some(payloadAccepted, commodity =>
        _.get(formErrors, `${_commodity.getSkuType(commodity)}.fail`, false)
    );

    return (
        <>
            <DialogContent>
                <CustomFormTitle titleText={loc('pickupDialogDrop11', lang)} iconName="list_alt" />
                <PayloadInput
                    payload={payload}
                    subPayloads={subPayloads}
                    onPayload={onPayload}
                    onSubPayloads={onSubPayloads}
                    formErrors={formErrors}
                    toggleHelpDialog={toggleHelpDialog}
                    payloadAccepted={payloadAccepted}
                    payloadRequired={_dropLocation.getPayloadRequired(selectedDropLocation)}
                    adminView={_.get(customer, 'adminView')}
                />
                <DialogContentText style={{ marginTop: theme.spacing.unit * 3, marginBottom: theme.spacing.unit * 2 }}>
                    {loc('pickupDialog34a', lang)}
                    <strong style={{ textDecoration: 'underline', color: theme.palette.secondary.main }}>
                        {customer.uniqueID}
                    </strong>
                    {loc('pickupDialog34b', lang)}
                </DialogContentText>
            </DialogContent>
            <DialogActions style={{ marginBottom: 'max(8px, env(safe-area-inset-bottom, 8px))' }}>
                <Button color="primary" onClick={onBack}>
                    {loc('back', lang)}
                </Button>
                <Button
                    data-cy="quickdrop-steps-next"
                    color="primary"
                    onClick={onNext}
                    disabled={
                        formContainsErrors ||
                        (_pickup.payloadIsEmpty(payload) && _pickup.subPayloadsAreEmpty(subPayloads))
                    }
                >
                    {loc('next', lang)}
                </Button>
            </DialogActions>
        </>
    );
}

export default withTheme()(Payload);
