import React, { useContext } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';

import { _time, _user } from 'std';
import useTable from 'components/Table/useTable';
import DashBoardTable from 'components/Table/DashBoardTable';

import useDateRangePicker from 'components/DateTimePickersTz/hooks/useDateRangePicker';
import DateRangePicker from 'components/DateTimePickersTz/DateRangePicker';
import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery';

import { downloadObjectAsCSV, getCustomerName } from 'utils/misc';

import {
    FormControl,
    MenuItem,
    Select,
    withTheme,
    TableRow,
    TableCell,
    Paper,
    Icon,
    Grid,
    IconButton,
    Button,
    TextField,
    OutlinedInput,
    InputLabel,
    Menu,
    Dialog,
    Checkbox,
    ListItemText,
    Tooltip,
    Chip,
    Avatar,
    Typography
} from '@material-ui/core';
import MDIcon from '@mdi/react';
import { mdiAccountOff } from '@mdi/js';

import { useState } from 'react';
import useComplaintsStats from './hooks/useComplaintsStat';
import { deviceHelper } from 'utils/misc';
import { openWindow } from 'helpers/windowHelper';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

import ImageViewer from 'components/ImageUploads/ImageViewer';

const headers = [
    { name: 'Actions', key: null },
    { name: 'Customer^', key: 'customer.first' },
    { name: 'Type^', key: 'issueType' },
    { name: 'Collector^', key: 'collectorName' },
    { name: 'Driver/Clerk^', key: 'employeeName.first' },
    { name: 'Issues', key: null },
    { name: 'Description', key: 'issueDescription' }
];

function Complaints(props) {
    const { theme, history } = props;
    const { lang } = useContext(LocalizationContext);
    const collapseSearch = useMediaQuery(theme.breakpoints.down('sm'));
    const [filtersOpen, setFiltersOpen] = useState(null);
    const [images, setImages] = useState([]);
    const [open, setOpen] = useState(false);

    const {
        startDate,
        endDate,
        timezone,
        dateWindow,
        handleChangeStartDate,
        handleChangeEndDate,
        handleGoForwards,
        handleGoBackwards,
        handleWindowSelect
    } = useDateRangePicker({
        saveStateInURL: true,
        timezones: [process.env.REACT_APP_REGION_TIMEZONE],
        initStartVal: _time.getStartOfWeek(process.env.REACT_APP_REGION_TIMEZONE),
        initEndVal: _time.getEndOfDay(process.env.REACT_APP_REGION_TIMEZONE)
    });

    const {
        allCollectors,
        collectorsSelected,
        setCollectorsSelected,
        allDrivers,
        driversSelected,
        setDriversSelected,
        allComplaints,
        complaintsSelected,
        setComplaintsSelected,
        userTypesSelected,
        setUserTypesSelected,
        userTypes,
        handleAllFilterCollectors,
        handleAllFilterUserTypes,
        handleAllFilterComplaints
    } = useComplaintsStats();

    const {
        loading,
        getData,
        page,
        search,
        rowsPerPage,
        response,
        filteredData,
        filteredHeaders,
        totalDataEntries,
        handleChange,
        handleSearch,
        handleChangeFilterHeaders,
        handleAllFilterHeaders,
        handleChangePage,
        handleChangeRowsPerPage
    } = useTable({
        url: `/system/paginatedComplaints?collectors=${collectorsSelected}&receivers=${driversSelected}&userTypes=${userTypesSelected}&complaintCodes=${complaintsSelected}`,
        key: 'complaints',
        headers: headers.map(h => h.name)
    });

    const handleOpenImages = images => {
        setImages(images);
        setOpen(true);
    };

    const renderSearch = children => {
        if (collapseSearch) {
            return (
                <Menu open={Boolean(filtersOpen)} anchorEl={filtersOpen} onClose={handleFiltersOpen(true)}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            padding: theme.spacing.unit * 2
                        }}
                    >
                        {children}
                    </div>
                </Menu>
            );
        } else {
            return (
                <div
                    style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'space-between' }}
                >
                    {/* <div>{customSearch}</div> */}
                    <div style={{ display: 'flex', alignItems: 'center' }}>{children}</div>
                </div>
            );
        }
    };

    const onViewAccount = customerId => {
        const URL = `/customers/${customerId}`;
        openWindow(history, !deviceHelper.isNativeApp(), URL);
    };

    const renderRow = (complaint, filteredHeaders) => {
        const { _id, issueType, images } = complaint;
        return (
            <TableRow key={`${_id} - ${issueType}`}>
                {filteredHeaders.includes('Actions') && (
                    <TableCell style={{ minWidth: 150 }}>
                        <Tooltip title="View Account" disableFocusListener disableTouchListener>
                            <IconButton
                                onClick={() => {
                                    onViewAccount(complaint.customerId);
                                }}
                            >
                                <Icon>visibility</Icon>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="View Image" disableFocusListener disableTouchListener>
                            <span>
                                <IconButton disabled={_.isEmpty(images)} onClick={() => handleOpenImages(images || [])}>
                                    <Icon>image</Icon>
                                </IconButton>
                            </span>
                        </Tooltip>
                    </TableCell>
                )}
                {filteredHeaders.includes('Customer^') && (
                    <TableCell>
                        {_.get(complaint, 'customer.first', '')} {_.get(complaint, 'customer.last', '')}
                    </TableCell>
                )}
                {filteredHeaders.includes('Type^') && <TableCell>{_.get(complaint, 'issueType', '')}</TableCell>}
                {filteredHeaders.includes('Collector^') && (
                    <TableCell>{_.get(complaint, 'collectorName', '')}</TableCell>
                )}
                {filteredHeaders.includes('Driver/Clerk^') && (
                    <TableCell>
                        {_.get(complaint, 'employeeName.first', 0)} {_.get(complaint, 'employeeName.last', '')}
                    </TableCell>
                )}
                {filteredHeaders.includes('Issues') && (
                    <TableCell style={{ maxWidth: 400 }}>
                        {_.get(complaint, 'issueLabel', []).map((issue, idx) => (
                            <Chip
                                key={`${complaint._id}-${idx}`}
                                label={issue}
                                style={{ margin: theme.spacing.unit / 4 }}
                            />
                        ))}
                    </TableCell>
                )}
                {filteredHeaders.includes('Description') && (
                    <TableCell>{_.get(complaint, 'issueDescription', '')}</TableCell>
                )}
            </TableRow>
        );
    };

    const renderSummary = complaintStats => (
        <Grid item xs={12}>
            <div
                style={{
                    padding: theme.spacing.unit,
                    paddingLeft: theme.spacing.unit * 2,
                    paddingRight: theme.spacing.unit * 2
                }}
            >
                <Paper
                    style={{
                        display: 'flex',
                        justifyContent: 'space-evenly',
                        padding: theme.spacing.unit * 2,
                        height: '100%',
                        flexWrap: 'wrap'
                    }}
                >
                    <span style={{ margin: theme.spacing.unit, display: 'flex', alignItems: 'center' }}>
                        <Avatar
                            style={{
                                backgroundColor: theme.palette.primary.main,
                                marginRight: theme.spacing.unit
                            }}
                        >
                            <Icon>sentiment_dissatisfied</Icon>
                        </Avatar>
                        <Typography style={{ marginRight: theme.spacing.unit * 2, display: 'inline-block' }}>
                            Customer Complaints: {_.get(complaintStats, 'totalCustomerComplaints', 0)}
                        </Typography>
                    </span>
                    <span style={{ margin: theme.spacing.unit, display: 'flex', alignItems: 'center' }}>
                        <Avatar
                            style={{
                                backgroundColor: theme.palette.primary.main,
                                marginRight: theme.spacing.unit
                            }}
                        >
                            <Icon>error_outline</Icon>
                        </Avatar>
                        <Typography style={{ marginRight: theme.spacing.unit * 2, display: 'inline-block' }}>
                            Counter Complaints: {_.get(complaintStats, 'totalCounterComplaints', 0)}
                        </Typography>
                    </span>
                    <span style={{ margin: theme.spacing.unit, display: 'flex', alignItems: 'center' }}>
                        <Avatar
                            style={{
                                backgroundColor: theme.palette.primary.main,
                                marginRight: theme.spacing.unit
                            }}
                        >
                            <Icon>error_sharp</Icon>
                        </Avatar>
                        <Typography style={{ marginRight: theme.spacing.unit * 2, display: 'inline-block' }}>
                            Driver Complaints: {_.get(complaintStats, 'totalDriverComplaints', 0)}
                        </Typography>
                    </span>
                    <span style={{ margin: theme.spacing.unit, display: 'flex', alignItems: 'center' }}>
                        <Avatar
                            style={{
                                backgroundColor: theme.palette.primary.main,
                                marginRight: theme.spacing.unit
                            }}
                        >
                            <Icon>settings</Icon>
                        </Avatar>
                        <Typography style={{ marginRight: theme.spacing.unit * 2, display: 'inline-block' }}>
                            System Complaints: {_.get(complaintStats, 'totalSystemComplaints', 0)}
                        </Typography>
                    </span>
                </Paper>
            </div>
        </Grid>
    );

    const handleDownload = async () => {
        const csvRows = [];

        filteredData.forEach(complaint => {
            csvRows.push({
                complaintDate: moment(complaint.date).tz(process.env.REACT_APP_REGION_TIMEZONE),
                name:
                    complaint.issueType === 'system' || complaint.issueType === 'System'
                        ? ''
                        : getCustomerName({ name: complaint.customer }),
                phone: complaint.customerPhone,
                customerCode: complaint.customerUniqueID,
                email: complaint.customerEmail,
                collector: complaint.collectorName,
                collectorCode: complaint.collectorCode,
                issuesCodes: complaint.issueCode.join(', '),
                issuesLabels: complaint.issueLabel.join(', '),
                issuedBy: complaint.issueType,
                description: complaint.issueDescription
            });
        });

        const date = moment().format('YYYY-MM-DD HH:mm');
        const fileName = `complaint_statistics_${date}`;
        try {
            await downloadObjectAsCSV(csvRows, fileName);
        } catch (err) {
            console.log(err);
        }
    };

    const customAction = (
        <Button disabled={loading} color="primary" style={{ marginRight: theme.spacing.unit }} onClick={handleDownload}>
            <Icon>get_app</Icon>
        </Button>
    );

    const handleSubmit = e => {
        handleSearch(e);
        if (filtersOpen) {
            setFiltersOpen(false);
        }
    };

    const inputStyles = {
        margin: theme.spacing.unit / 2
    };

    const handleFiltersOpen = close => e => {
        if (close || Boolean(filtersOpen)) {
            setFiltersOpen(null);
        } else {
            setFiltersOpen(e.currentTarget);
        }
    };
    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <div style={{ padding: theme.spacing.unit * 2, paddingBottom: theme.spacing.unit }}>
                        <Paper
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                padding: theme.spacing.unit * 2,
                                height: '100%',
                                flexWrap: 'wrap'
                            }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <DateRangePicker
                                    timezone={timezone}
                                    startDate={startDate}
                                    endDate={endDate}
                                    window={dateWindow}
                                    disabled={loading}
                                    handlePrevious={handleGoBackwards}
                                    handleNext={handleGoForwards}
                                    handleChangeStartDate={handleChangeStartDate}
                                    handleChangeEndDate={handleChangeEndDate}
                                />
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexWrap: 'wrap'
                                }}
                            >
                                <form onSubmit={handleSubmit} style={{ flex: '0 1 100%' }}>
                                    {renderSearch(
                                        <>
                                            <FormControl>
                                                <Select
                                                    value={dateWindow}
                                                    disabled={loading}
                                                    onChange={handleWindowSelect}
                                                    style={{
                                                        maxWidth: '250px',
                                                        margin: theme.spacing.unit
                                                    }}
                                                >
                                                    <MenuItem value="today">Daily</MenuItem>
                                                    <MenuItem value="thisWeek">Weekly</MenuItem>
                                                    <MenuItem value="thisMonth">Monthly</MenuItem>
                                                    <MenuItem value="thisQuarter">Quarterly</MenuItem>
                                                    <MenuItem value="allTime">All Time</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <FormControl>
                                                <Select
                                                    multiple
                                                    disabled={loading}
                                                    onChange={e => setCollectorsSelected(e.target.value)}
                                                    style={{
                                                        maxWidth: '250px',
                                                        margin: theme.spacing.unit
                                                    }}
                                                    value={collectorsSelected}
                                                    renderValue={s => `${s.length} Collectors`}
                                                >
                                                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                        <Button
                                                            disabled={loading}
                                                            onClick={handleAllFilterCollectors(true)}
                                                        >
                                                            All
                                                        </Button>
                                                        <Button
                                                            disabled={loading}
                                                            onClick={handleAllFilterCollectors(false)}
                                                        >
                                                            None
                                                        </Button>
                                                    </div>
                                                    {allCollectors.map(({ _id, name }) => (
                                                        <MenuItem disabled={loading} key={_id} value={_id}>
                                                            <Checkbox checked={collectorsSelected.includes(_id)} />
                                                            <ListItemText>{name}</ListItemText>
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            <FormControl>
                                                <Select
                                                    disabled={loading}
                                                    multiple
                                                    renderValue={val =>
                                                        `${val.length} Driver${val.length !== 1 ? 's' : ''}`
                                                    }
                                                    value={driversSelected}
                                                    onChange={e => setDriversSelected(e.target.value)}
                                                    style={{
                                                        maxWidth: '250px',
                                                        margin: theme.spacing.unit
                                                    }}
                                                >
                                                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                        <Button
                                                            onClick={e => {
                                                                e.stopPropagation();
                                                                setDriversSelected(
                                                                    _(allDrivers)
                                                                        .filter(driver =>
                                                                            collectorsSelected.includes(
                                                                                driver.collector._id
                                                                            )
                                                                        )
                                                                        .map(driver => driver._id)
                                                                        .value()
                                                                );
                                                            }}
                                                        >
                                                            All
                                                        </Button>
                                                        <Button
                                                            onClick={e => {
                                                                e.stopPropagation();
                                                                setDriversSelected([]);
                                                            }}
                                                        >
                                                            None
                                                        </Button>
                                                    </div>
                                                    {_.orderBy(
                                                        allDrivers,
                                                        [d => !d.banned, d => d.name.first.toLowerCase()],
                                                        ['desc', 'asc']
                                                    ).map(driver => {
                                                        const { _id, collector, banned } = driver;
                                                        if (!collectorsSelected.includes(collector._id)) return null;
                                                        return (
                                                            <MenuItem
                                                                key={_id}
                                                                value={_id}
                                                                style={{
                                                                    textTransform: 'capitalize'
                                                                }}
                                                            >
                                                                {_user.getNameFull(driver).toLowerCase()}
                                                                {banned && (
                                                                    <span
                                                                        style={{
                                                                            marginLeft: theme.spacing.unit,
                                                                            display: 'grid',
                                                                            placeItems: 'center'
                                                                        }}
                                                                    >
                                                                        <MDIcon
                                                                            path={mdiAccountOff}
                                                                            size={0.85}
                                                                            color={theme.palette.text.secondary}
                                                                        />
                                                                    </span>
                                                                )}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                            </FormControl>
                                            <FormControl>
                                                <Select
                                                    multiple
                                                    disabled={loading}
                                                    onChange={e => setUserTypesSelected(e.target.value)}
                                                    style={{
                                                        maxWidth: '250px',
                                                        margin: theme.spacing.unit
                                                    }}
                                                    value={userTypesSelected}
                                                    renderValue={s => `${s.length} Types`}
                                                >
                                                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                        <Button
                                                            disabled={loading}
                                                            onClick={handleAllFilterUserTypes(true)}
                                                        >
                                                            All
                                                        </Button>
                                                        <Button
                                                            disabled={loading}
                                                            onClick={handleAllFilterUserTypes(false)}
                                                        >
                                                            None
                                                        </Button>
                                                    </div>
                                                    {userTypes.map(complaint => (
                                                        <MenuItem disabled={loading} key={complaint} value={complaint}>
                                                            <Checkbox checked={userTypesSelected.includes(complaint)} />
                                                            <ListItemText>{complaint}</ListItemText>
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            <FormControl>
                                                <Select
                                                    multiple
                                                    disabled={loading}
                                                    onChange={e => setComplaintsSelected(e.target.value)}
                                                    style={{
                                                        maxWidth: '250px',
                                                        margin: theme.spacing.unit
                                                    }}
                                                    value={complaintsSelected}
                                                    renderValue={s => `${s.length} Complaints`}
                                                >
                                                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                        <Button
                                                            disabled={loading}
                                                            onClick={handleAllFilterComplaints(true)}
                                                        >
                                                            All
                                                        </Button>
                                                        <Button
                                                            disabled={loading}
                                                            onClick={handleAllFilterComplaints(false)}
                                                        >
                                                            None
                                                        </Button>
                                                    </div>
                                                    {allComplaints.map(complaint => (
                                                        <MenuItem
                                                            disabled={loading}
                                                            key={complaint.code}
                                                            value={complaint.code}
                                                        >
                                                            <Checkbox
                                                                checked={complaintsSelected.includes(complaint.code)}
                                                            />
                                                            <ListItemText>{complaint.label}</ListItemText>
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            <FormControl style={inputStyles}>
                                                <InputLabel htmlFor="collection" variant="outlined">
                                                    Column Filters
                                                </InputLabel>
                                                <Select
                                                    variant="outlined"
                                                    multiple
                                                    value={filteredHeaders}
                                                    onChange={handleChangeFilterHeaders}
                                                    style={{ width: 250 }}
                                                    input={<OutlinedInput labelWidth={140} />}
                                                >
                                                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                        <Button onClick={handleAllFilterHeaders(true)}>All</Button>
                                                        <Button onClick={handleAllFilterHeaders(false)}>None</Button>
                                                    </div>
                                                    {headers.map(({ name, locKey }) => (
                                                        <MenuItem key={name} value={name}>
                                                            {!_.isNil(locKey) ? loc(locKey, lang) : _.startCase(name)}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            <>
                                                <TextField
                                                    variant="outlined"
                                                    name="search"
                                                    value={search || ''}
                                                    onChange={handleChange}
                                                    placeholder="Search"
                                                    style={{
                                                        ...inputStyles,
                                                        width: 250
                                                    }}
                                                />

                                                {
                                                    <Button
                                                        color="primary"
                                                        disabled={loading}
                                                        variant="contained"
                                                        data-cy="dashboard-search-button"
                                                        type="submit"
                                                        onClick={handleSubmit}
                                                    >
                                                        <Icon>search</Icon>
                                                    </Button>
                                                }
                                            </>
                                        </>
                                    )}

                                    {collapseSearch && (
                                        <div style={{ textAlign: 'right' }}>
                                            <IconButton onClick={handleFiltersOpen()}>
                                                <Icon>filter_list</Icon>
                                            </IconButton>
                                        </div>
                                    )}
                                </form>
                            </div>
                        </Paper>
                    </div>
                </Grid>
                {renderSummary(response)}
            </Grid>
            <DashBoardTable
                title="Complaints"
                loading={loading}
                headers={headers}
                filteredHeaders={filteredHeaders}
                length={totalDataEntries}
                page={page}
                rowsPerPage={rowsPerPage}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                refresh={getData}
                customAction={customAction}
            >
                {filteredData &&
                    filteredData.map(user => {
                        return renderRow(user, filteredHeaders);
                    })}
            </DashBoardTable>
            <Dialog open={open} fullWidth onClose={() => setOpen(false)}>
                <ImageViewer
                    displayImages={images}
                    disableDelete={true}
                    innerStyles={{ marginTop: theme.spacing.unit * 2 }}
                    scrollerStyles={{ maxWidth: 420, overflowX: 'auto' }}
                />
            </Dialog>
        </>
    );
}

export default withTheme()(Complaints);
