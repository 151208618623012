import React, { useState, useEffect, useContext } from 'react';

import _ from 'lodash';
import moment from 'moment-timezone';

import { getCustomerName } from 'utils/misc';

import {
    Dialog,
    DialogContent,
    Typography,
    Icon,
    colors,
    DialogTitle,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    LinearProgress,
    Collapse,
    IconButton,
    DialogActions,
    Button
} from '@material-ui/core';

import { default as MuiTable } from '@material-ui/core/Table';
import { default as MuiTableBody } from '@material-ui/core/TableBody';
import { default as MuiTableRow } from '@material-ui/core/TableRow';
import { default as MuiTableCell } from '@material-ui/core/TableCell';

import { withTheme } from '@material-ui/core/styles';

import { _charity } from 'std';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

function CharityInfoEditDialog(props) {
    const {
        open,
        organizationSelected,
        disabledColor,
        initialInfoEditsState,
        loading,
        onClose,
        onApproveEdits,
        onReject,
        theme
    } = props;

    const [infoEditsState, setInfoEditsState] = useState([]);
    const { lang } = useContext(LocalizationContext);

    useEffect(() => {
        setInfoEditsState(initialInfoEditsState);
    }, [organizationSelected, initialInfoEditsState]);

    const organizationInfoEdits = _.filter(
        infoEditsState,
        infoEdit => infoEdit.charity === _.get(organizationSelected, '_id', undefined)
    );

    const handleToggleComparison = _id => {
        let infoEditsUpdated = _.cloneDeep(infoEditsState);
        let infoEditToToggle = _.find(infoEditsUpdated, infoEdit => infoEdit._id === _id);
        infoEditToToggle.toggled = !infoEditToToggle.toggled;

        setInfoEditsState(infoEditsUpdated);
    };

    return (
        <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
            <DialogTitle>{organizationSelected.name}</DialogTitle>
            <DialogContent>
                <List>
                    {organizationInfoEdits
                        .slice()
                        .reverse()
                        .map(infoEdit => {
                            return (
                                <React.Fragment key={infoEdit._id}>
                                    <ListItem disableGutters>
                                        <ListItemIcon>
                                            <IconButton onClick={() => handleToggleComparison(infoEdit._id)}>
                                                {infoEdit.toggled ? (
                                                    <Icon>keyboard_arrow_down</Icon>
                                                ) : (
                                                    <Icon>keyboard_arrow_right</Icon>
                                                )}
                                            </IconButton>
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={
                                                <span>
                                                    {moment(infoEdit.createdAt).format('DD MMM YYYY [at] h:mm A')}{' '}
                                                    <span style={{ color: theme.palette.primary.main }}>
                                                        {infoEdit.container.approved && '[APPROVED]'}
                                                    </span>
                                                </span>
                                            }
                                            secondary={getCustomerName(infoEdit.actor)}
                                        />
                                    </ListItem>
                                    <Collapse in={infoEdit.toggled} style={{ overflowX: 'auto' }}>
                                        <MuiTable>
                                            <MuiTableBody>
                                                {_.keys(infoEdit.container.oldInfo).map(prop => {
                                                    let displayProp = (prop[0].toUpperCase() + prop.slice(1))
                                                        .replace(/([A-Z]+)/g, ' $1')
                                                        .replace(/([A-Z][a-z])/g, ' $1');
                                                    if (prop === 'charityRegistrationNumber') {
                                                        displayProp = 'CRN';
                                                    }
                                                    let oldValue = infoEdit.container.oldInfo[prop];
                                                    let newValue = infoEdit.container.newInfo[prop];
                                                    let oldDisplayValue;
                                                    let newDisplayValue;
                                                    if (prop === 'location') {
                                                        oldDisplayValue = !_.isEmpty(_.get(oldValue, 'description', ''))
                                                            ? _.get(oldValue, 'description', '')
                                                            : 'No Address';
                                                        oldDisplayValue += ` - (${_.get(oldValue, 'lat', '')}, ${_.get(
                                                            oldValue,
                                                            'lng',
                                                            ''
                                                        )})`;
                                                        newDisplayValue = !_.isEmpty(_.get(newValue, 'description', ''))
                                                            ? _.get(newValue, 'description', '')
                                                            : 'No Address';
                                                        newDisplayValue += ` - (${_.get(newValue, 'lat', '')}, ${_.get(
                                                            newValue,
                                                            'lng',
                                                            ''
                                                        )})`;
                                                    } else {
                                                        oldDisplayValue = (oldValue || '').toString();
                                                        newDisplayValue = (newValue || '').toString();
                                                    }
                                                    let rowChanged = oldDisplayValue !== newDisplayValue;
                                                    if (prop === 'website' || prop === 'logo') {
                                                        oldDisplayValue = (
                                                            <a
                                                                href={oldDisplayValue}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                {oldDisplayValue}
                                                            </a>
                                                        );
                                                        newDisplayValue = (
                                                            <a
                                                                href={newDisplayValue}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                {newDisplayValue}
                                                            </a>
                                                        );
                                                    }
                                                    return (
                                                        <MuiTableRow
                                                            key={prop}
                                                            style={{
                                                                backgroundColor: rowChanged && colors.yellow['A100']
                                                            }}
                                                        >
                                                            <MuiTableCell>{displayProp}</MuiTableCell>
                                                            <MuiTableCell>
                                                                {oldValue ? (
                                                                    oldDisplayValue
                                                                ) : (
                                                                    <span style={{ color: disabledColor }}>N/A</span>
                                                                )}
                                                            </MuiTableCell>
                                                            <MuiTableCell style={{ padding: 4 }}>→</MuiTableCell>
                                                            <MuiTableCell>
                                                                {newValue ? (
                                                                    newDisplayValue
                                                                ) : (
                                                                    <span style={{ color: disabledColor }}>N/A</span>
                                                                )}
                                                            </MuiTableCell>
                                                        </MuiTableRow>
                                                    );
                                                })}
                                            </MuiTableBody>
                                        </MuiTable>
                                    </Collapse>
                                </React.Fragment>
                            );
                        })}
                    {_.isEmpty(organizationInfoEdits) && <Typography variant="subtitle1">No edit history.</Typography>}
                </List>
            </DialogContent>
            {loading && <LinearProgress style={{ marginTop: theme.spacing.unit * 2 }} />}
            <DialogActions>
                <Button onClick={onClose}>{loc('close', lang)}</Button>
                <Button
                    onClick={() => {
                        onReject(_charity.getId(organizationSelected));
                    }}
                    color="secondary"
                >
                    Reject
                </Button>
                <Button
                    onClick={() => {
                        onApproveEdits(_charity.getId(organizationSelected));
                    }}
                    color="primary"
                >
                    Approve
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default withTheme()(CharityInfoEditDialog);
