import React, { useContext, useMemo } from 'react';
import _ from 'lodash';
import { _commodity, _user } from 'std';
import {
    DialogActions,
    DialogContent,
    Button,
    DialogContentText,
    withTheme,
    Typography,
    CircularProgress
} from '@material-ui/core';
import CharitySelect from 'components/CharitySelect/CharitySelect';

import CustomFormTitle from 'components/MaterialUIExtensions/CustomFormTitle';
import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

import BottleDriveForm from 'components/BottleDriveForm';
import useBottleDriveForm from 'components/BottleDriveForm/hooks/useBottleDriveForm';

function BottleDrive(props) {
    const { onBack, theme, customer } = props;
    const { lang } = useContext(LocalizationContext);

    const {
        formik: bottleDriveFormik,
        handleSetDonation: bottleDriveHandleSetDonation,
        donate: bottleDriveFormDonate,
        loading: bottleDriveLoading,
        displayConfirmation: bottleDriveDisplayConfirmation
    } = useBottleDriveForm({ customer });

    const formControlStyle = {
        marginTop: theme.spacing.unit
    };
    return (
        <>
            <DialogContent>
                <div style={{ marginTop: theme.spacing.unit * 2 }}>
                    <DialogContentText>{loc('pickupDialogBottleDrive10', lang)}</DialogContentText>
                    <div style={{ marginTop: theme.spacing.unit * 2 }}>
                        <BottleDriveForm
                            customer={customer}
                            onClose={onBack}
                            formControlStyle={formControlStyle}
                            formik={bottleDriveFormik}
                            handleSetDonation={bottleDriveHandleSetDonation}
                            donate={bottleDriveFormDonate}
                            loading={bottleDriveLoading}
                            displayConfirmation={bottleDriveDisplayConfirmation}
                        />
                    </div>
                </div>
            </DialogContent>
            <DialogActions style={{ marginBottom: 'max(8px, env(safe-area-inset-bottom, 8px))' }}>
                <Button color="primary" onClick={onBack}>
                    Back
                </Button>
                {!bottleDriveDisplayConfirmation && (
                    <Button
                        type="submit"
                        color="primary"
                        style={{ height: 40 }}
                        onClick={bottleDriveFormik.handleSubmit}
                        disabled={bottleDriveLoading}
                        data-cy="bottle-drive-submit-btn"
                    >
                        <span>{loc('submit', lang)}</span>
                        {bottleDriveLoading && (
                            <CircularProgress
                                size={22}
                                thickness={4.8}
                                color="primary"
                                style={{
                                    marginLeft: theme.spacing.unit,
                                    verticalAlign: 'bottom'
                                }}
                            />
                        )}
                    </Button>
                )}
            </DialogActions>
        </>
    );
}

export default withTheme()(BottleDrive);
