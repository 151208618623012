import React, { useContext } from 'react';
import _ from 'lodash';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { withTheme } from '@material-ui/core/styles';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { getTextInput, getSelect } from '../helperFunctions';

import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

const appVersionTypes = ['All', 'IOS', 'Android', 'Web'];

function RatingPlatformForm(props) {
    const {
        ratingPlatform, //NOTE: will be null/undefined when creating a new one
        ratingPlatforms,
        open,
        onSubmit,
        onClose,
        theme,
        fullScreen
    } = props;

    const { lang } = useContext(LocalizationContext);

    const highestPriorityPlatform = _.maxBy(ratingPlatforms, 'priority');
    const highestPriority = _.get(highestPriorityPlatform, 'priority', 0);

    const formik = useFormik({
        initialValues: {
            name: _.get(ratingPlatform, 'name', ''),
            appVersion: _.get(ratingPlatform, 'appVersion', 'All'),
            rateUsText: _.get(ratingPlatform, 'rateUsText', ''),
            priority: _.get(ratingPlatform, 'priority', highestPriority + 1),
            defaultUrl: _.get(ratingPlatform, 'defaultUrl', '')
        },
        validationSchema: Yup.object({
            name: Yup.string().required('You must enter a platform name.'),
            appVersion: Yup.string()
                .required('You must select an app version.')
                .oneOf(appVersionTypes),
            rateUsText: Yup.string().required('You must fill this out'),
            priority: Yup.number().required(),
            defaultUrl: Yup.string().url('This must be a valid url')
        }),
        onSubmit
    });

    return (
        <Dialog onClose={onClose} open={open} fullScreen={fullScreen}>
            <DialogTitle>{!_.isNil(ratingPlatform) ? 'Update' : 'Create'}</DialogTitle>
            <DialogContent>
                {getTextInput(theme, 'name', 'Platform Name', formik)}
                {getTextInput(theme, 'rateUsText', 'Rate Button Text', formik)}
                {getSelect(theme, 'appVersion', 'Show Platform On', appVersionTypes, formik)}
                {getTextInput(
                    theme,
                    'defaultUrl',
                    'Default Url(Optional)',
                    formik,
                    'text',
                    {},
                    {},
                    'Url used to pre-populate the text field when adding it to collectors'
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button type="submit" color="primary" onClick={formik.handleSubmit}>
                    {loc('submit', lang)}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default withMobileDialog()(withTheme()(RatingPlatformForm));
