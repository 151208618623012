import React, { Component } from 'react';

import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import { withTheme } from '@material-ui/core/styles';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc, locDate } from '../../../localizations/localizationHandler';

class UnservicedPickupSubwidget extends Component {
    static contextType = LocalizationContext;
    render() {
        const { theme, clicksAllowed } = this.props;

        const { lang } = this.context;

        return (
            <div
                id="schedule-pickup-subwidget"
                data-cy="schedule-pickup-subwidget"
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            >
                <div
                    style={{
                        paddingLeft: theme.spacing.unit * 2,
                        paddingRight: theme.spacing.unit * 2,
                        overflow: 'hidden'
                    }}
                >
                    <Typography>{loc('pickupWidgets16', lang)}</Typography>
                </div>
                <div
                    style={{
                        borderLeft: '1px solid ' + theme.palette.text.hint,
                        paddingLeft: theme.spacing.unit,
                        paddingRight: theme.spacing.unit
                    }}
                >
                    <IconButton
                        disabled={!clicksAllowed}
                        onClick={this.props.onPickupDialog(true)}
                        data-cy="edit-pickup-subwidget-edit-button"
                    >
                        <Icon>edit</Icon>
                    </IconButton>
                </div>
            </div>
        );
    }
}

export default withTheme()(UnservicedPickupSubwidget);
