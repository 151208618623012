import React, { useContext } from 'react';

import _ from 'lodash';

import { IconButton, Icon, withTheme, Link, Typography, TableCell } from '@material-ui/core';

import SnackbarContext from 'components/CustomSnackbar/SnackbarContext';
import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

import { Clipboard } from '@capacitor/clipboard';

function CopyableLink(props) {
    const { children, href, theme, subdivision } = props;
    const { lang } = useContext(LocalizationContext);
    const onSnackbar = useContext(SnackbarContext);

    const handleCopyToClipboard = string => () => {
        Clipboard.write({
            string
        });
        onSnackbar(loc('copyClipSnackBar', lang, { href }));
    };

    return (
        <>
            <TableCell
                style={{
                    borderBottom: 'none',
                    maxWidth: 40,
                    marginLeft: 0,
                    paddingLeft: 0,
                    paddingRight: 50
                }}
            >
                <IconButton style={{ marginLeft: '-8px' }} onClick={handleCopyToClipboard(href)}>
                    <Icon color="primary">file_copy</Icon>
                </IconButton>
            </TableCell>
            {!_.isNil(subdivision) && (
                <TableCell style={{ borderBottom: 'none', minWidth: 40, padding: 0 }}>
                    <Typography>{subdivision}</Typography>
                </TableCell>
            )}
            <TableCell style={{ borderBottom: 'none', padding: 0 }}>
                <Link
                    href={href}
                    style={{
                        color: theme.palette.linkColor,
                        overflowWrap: 'break-word',
                        wordWrap: 'break-word'
                    }}
                >
                    {href}
                </Link>
            </TableCell>
        </>
    );
}

export default withTheme()(CopyableLink);
