import React, { useContext } from 'react';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText,
    Button,
    withTheme
} from '@material-ui/core';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from '../../../localizations/localizationHandler';

import HttpContext from 'utils/contexts/HttpContext';
import { formatAsPhoneNumber } from 'utils/misc';

import SnackbarContext from 'components/CustomSnackbar/SnackbarContext';

function NoSMSDialog(props) {
    const { open, phone, email, firstName, lastName, onClose, onNoClicked } = props;

    const { lang } = useContext(LocalizationContext);
    const http = useContext(HttpContext);
    const onSnackbar = useContext(SnackbarContext);

    const handleSmsIssue = async () => {
        const res = await http.post('/contact', {
            reason: 'Problem with authentication code.',
            message: `The following customer did not recieve a two factor authentication code when registering. Phone Number Used: ${formatAsPhoneNumber(
                phone
            )}`,
            email,
            name: (firstName || '').trim() + ' ' + (lastName || '').trim(),
            toEmail: 'service'
        });

        if (res.ok) {
            onSnackbar(loc('registration2fa10', lang), 'info');
            onClose();
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>{loc('registration2fa11', lang)}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {loc('registration2fa9', lang, { phone: formatAsPhoneNumber(phone) })}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={onNoClicked}>
                    {loc('no', lang)}
                </Button>
                <Button color="primary" onClick={handleSmsIssue}>
                    {loc('yes', lang)}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default withTheme()(NoSMSDialog);
