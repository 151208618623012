import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash';
import dot from 'dot-object';
import { _time } from 'std';
import { DB_LOG_TYPES, ROLES } from '../../../constants';

import { withTheme } from '@material-ui/core/styles';
import {
    Icon,
    Paper,
    Grid,
    Typography,
    TextField,
    FormControl,
    InputLabel,
    Select,
    Button,
    MenuItem,
    OutlinedInput,
    CircularProgress,
    InputAdornment,
    Tooltip
} from '@material-ui/core';

import useDateRangePicker from 'components/DateTimePickersTz/hooks/useDateRangePicker';
import DateRangePicker from 'components/DateTimePickersTz/DateRangePicker';

import CustomDebugDialog from 'components/CustomDebugDialog/CustomDebugDialog';
import HttpContext from 'utils/contexts/HttpContext';
import DbLogCard from './DbLogCard';

const DbLogs = props => {
    const http = useContext(HttpContext);
    const { theme, auth, lockToCustomer = null } = props;

    let defaultTypes = _.values(DB_LOG_TYPES);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [query, setQuery] = useState({
        actor: '',
        object: '',
        user: _.isNil(lockToCustomer) ? '' : lockToCustomer,
        type: defaultTypes,
        operation: ['i', 'u', 'd', 'l'],
        systemEvents: 'show'
    });
    const [data, setData] = useState([]);
    const [selectedLog, setSelectedLog] = useState();
    const [commodities, setCommodities] = useState();
    const {
        startDate,
        endDate,
        timezone,
        dateWindow,
        handleChangeStartDate,
        handleChangeEndDate,
        handleGoForwards,
        handleGoBackwards,
        handleWindowSelect
    } = useDateRangePicker({
        saveStateInURL: true,
        timezones: [process.env.REACT_APP_REGION_TIMEZONE],
        initStartVal: _time.getStartOfWeek(process.env.REACT_APP_REGION_TIMEZONE),
        initEndVal: _time.getEndOfDay(process.env.REACT_APP_REGION_TIMEZONE)
    });

    const fetchData = async () => {
        let qs = '';
        for (let key in query) {
            if (qs !== '') {
                qs += '&';
            }
            qs += key + '=' + encodeURIComponent(query[key]);
        }
        const [resDbLogs, resLogins, resCommodities] = await Promise.all([
            http.getJSON(
                `/dbLogs?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}&page=${page}&${qs}`
            ),
            query.operation.includes('l')
                ? http.getJSON(
                      `/logs/logins?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}&page=${page}&${qs}`
                  )
                : { ok: true, data: [] },
            http.getJSON('/commodities/getAllCommodities')
        ]);
        if (resDbLogs.ok && resCommodities.ok && resLogins.ok) {
            let combinedData = resDbLogs.data;
            if (query.operation.includes('l')) {
                combinedData = _.concat(combinedData, resLogins.data);
                combinedData = _.sortBy(combinedData, 'createdAt');
                combinedData = combinedData.reverse();
            }
            setData(combinedData);
            setCommodities(_.get(resCommodities, 'data.collectionData', []));
            setLoading(false);
        }
    };

    const handleSearch = () => {
        setPage(0);
        setLoading(true);
        fetchData();
    };

    const handleChange = e => {
        setQuery({ ...query, [e.target.name]: e.target.value });
    };

    const handlePrev = () => {
        setPage(page - 1);
        setLoading(true);
    };

    const handleNext = () => {
        setPage(page + 1);
        setLoading(true);
    };

    // Fetch all log data
    useEffect(() => {
        setLoading(true);
        fetchData();
    }, [page, startDate, endDate, timezone, dateWindow]);

    const inputStyle = { marginRight: theme.spacing.unit, width: 175, minWidth: 100, maxWidth: 225 };

    const buttonStyle = { marginTop: theme.spacing.unit, marginBottom: theme.spacing.unit, textAlign: 'right' };

    if (auth.accountType !== 'System Administrator' && !ROLES.includes(auth.accountType))
        return <div>Unauthorized</div>;

    return (
        <Grid>
            <Grid item xs={12}>
                {_.isNil(lockToCustomer) && (
                    <div style={{ padding: theme.spacing.unit * 2, paddingBottom: theme.spacing.unit }}>
                        <Paper
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                padding: theme.spacing.unit * 2,
                                height: '100%',
                                flexWrap: 'wrap'
                            }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <DateRangePicker
                                    timezone={timezone}
                                    startDate={startDate}
                                    endDate={endDate}
                                    window={dateWindow}
                                    disabled={loading}
                                    handlePrevious={handleGoBackwards}
                                    handleNext={handleGoForwards}
                                    handleChangeStartDate={handleChangeStartDate}
                                    handleChangeEndDate={handleChangeEndDate}
                                />
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexWrap: 'wrap'
                                }}
                            >
                                <FormControl>
                                    <Select
                                        value={dateWindow}
                                        disabled={loading}
                                        onChange={handleWindowSelect}
                                        style={{
                                            maxWidth: '250px',
                                            margin: theme.spacing.unit
                                        }}
                                    >
                                        <MenuItem value="today">Daily</MenuItem>
                                        <MenuItem value="thisWeek">Weekly</MenuItem>
                                        <MenuItem value="thisMonth">Monthly</MenuItem>
                                        <MenuItem value="thisQuarter">Quarterly</MenuItem>
                                        <MenuItem value="allTime">All Time</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </Paper>
                    </div>
                )}
            </Grid>

            <Grid item xs={12}>
                <div
                    style={{
                        padding: theme.spacing.unit,
                        paddingLeft: theme.spacing.unit * 2,
                        paddingRight: theme.spacing.unit * 2
                    }}
                >
                    <Paper
                        style={{
                            padding: theme.spacing.unit * 2,
                            height: '100%'
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                flexWrap: 'wrap',
                                alignItems: 'center'
                            }}
                        >
                            <Typography variant="h5">Search Database Logs</Typography>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    paddingLeft: theme.spacing.unit / 2,
                                    paddingRight: theme.spacing.unit / 2,
                                    flexWrap: 'wrap',
                                    alignItems: 'center'
                                }}
                            >
                                {_.isNil(lockToCustomer) ? (
                                    <>
                                        <TextField
                                            data-cy="db-logs-actor-input"
                                            value={query.actor}
                                            type="search"
                                            name="actor"
                                            variant="outlined"
                                            label="Actor"
                                            onChange={handleChange}
                                            style={inputStyle}
                                            placeholder="59ee7decd2f19d4e9f1b9861"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <Tooltip
                                                            title="ObjectId of user that initiated change"
                                                            data-cy={`db-logs-user-info-text`}
                                                            style={{ color: theme.palette.text.disabled, fontSize: 18 }}
                                                        >
                                                            <Icon data-cy={`db-logs-user-info-icon`}>info</Icon>
                                                        </Tooltip>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                        <TextField
                                            data-cy="db-logs-user-input"
                                            value={query.user}
                                            type="search"
                                            name="user"
                                            variant="outlined"
                                            label="User"
                                            onChange={handleChange}
                                            style={inputStyle}
                                            placeholder="59ee7decd2f19d4e9f1b9861"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <Tooltip
                                                            title="ObjectId of user associated with changed object"
                                                            data-cy={`db-logs-user-info-text`}
                                                            style={{ color: theme.palette.text.disabled, fontSize: 18 }}
                                                        >
                                                            <Icon data-cy={`db-logs-user-info-icon`}>info</Icon>
                                                        </Tooltip>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />

                                        <TextField
                                            data-cy="db-logs-object-input"
                                            value={query.object}
                                            type="search"
                                            name="object"
                                            variant="outlined"
                                            label="Object"
                                            onChange={handleChange}
                                            style={inputStyle}
                                            placeholder="59ee7decd2f19d4e9f1b9861"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <Tooltip
                                                            title="ObjectId of updated object"
                                                            data-cy={`db-logs-user-info-text`}
                                                            style={{ color: theme.palette.text.disabled, fontSize: 18 }}
                                                        >
                                                            <Icon data-cy={`db-logs-user-info-icon`}>info</Icon>
                                                        </Tooltip>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </>
                                ) : (
                                    <>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <DateRangePicker
                                                timezone={timezone}
                                                startDate={startDate}
                                                endDate={endDate}
                                                window={dateWindow}
                                                disabled={loading}
                                                handlePrevious={handleGoBackwards}
                                                handleNext={handleGoForwards}
                                                handleChangeStartDate={handleChangeStartDate}
                                                handleChangeEndDate={handleChangeEndDate}
                                            />
                                        </div>
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                flexWrap: 'wrap'
                                            }}
                                        >
                                            <FormControl>
                                                <Select
                                                    value={dateWindow}
                                                    disabled={loading}
                                                    onChange={handleWindowSelect}
                                                    style={{
                                                        maxWidth: '250px',
                                                        margin: theme.spacing.unit
                                                    }}
                                                >
                                                    <MenuItem value="today">Daily</MenuItem>
                                                    <MenuItem value="thisWeek">Weekly</MenuItem>
                                                    <MenuItem value="thisMonth">Monthly</MenuItem>
                                                    <MenuItem value="thisQuarter">Quarterly</MenuItem>
                                                    <MenuItem value="allTime">All Time</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </>
                                )}
                                <FormControl variant="outlined">
                                    <InputLabel htmlFor="collection">Object Type</InputLabel>
                                    <Select
                                        multiple
                                        input={<OutlinedInput id="type" name="type" labelWidth={75} />}
                                        value={query.type}
                                        onChange={handleChange}
                                        style={{ ...inputStyle, width: 125 }}
                                    >
                                        {_.values(DB_LOG_TYPES).map(type => (
                                            <MenuItem key={type} value={type}>
                                                {type}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl variant="outlined">
                                    <InputLabel htmlFor="operation">Operation</InputLabel>
                                    <Select
                                        multiple
                                        inputProps={{ id: 'operation', name: 'operation' }}
                                        input={<OutlinedInput id="operation" name="operation" labelWidth={75} />}
                                        value={query.operation}
                                        onChange={handleChange}
                                        style={{ ...inputStyle, width: 125 }}
                                    >
                                        <MenuItem value="i">Insert</MenuItem>
                                        <MenuItem value="u">Update</MenuItem>
                                        <MenuItem value="d">Delete</MenuItem>
                                        <MenuItem value="l">Login</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl variant="outlined">
                                    <InputLabel htmlFor="systemEvents">System Events</InputLabel>
                                    <Select
                                        inputProps={{ id: 'systemEvents', name: 'systemEvents' }}
                                        input={<OutlinedInput id="systemEvents" name="systemEvents" labelWidth={105} />}
                                        value={query.systemEvents}
                                        onChange={handleChange}
                                        style={{ ...inputStyle, width: 125 }}
                                        disabled={!_.isEmpty(query.source)}
                                    >
                                        <MenuItem value="show">Show</MenuItem>
                                        <MenuItem value="hide">Hide</MenuItem>
                                        <MenuItem value="only">Only</MenuItem>
                                    </Select>
                                </FormControl>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    data-cy="config-logs-search-button"
                                    type="submit"
                                    onClick={handleSearch}
                                >
                                    <Icon>search</Icon>
                                </Button>
                            </div>
                        </div>
                    </Paper>
                </div>
            </Grid>
            <Grid item xs={12}>
                {!_.isEmpty(data) ? (
                    <div
                        style={{
                            padding: theme.spacing.unit,
                            paddingLeft: theme.spacing.unit * 2,
                            paddingRight: theme.spacing.unit * 2
                        }}
                    >
                        <Paper style={{ padding: theme.spacing.unit * 2 }}>
                            {loading ? (
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <CircularProgress />
                                </div>
                            ) : (
                                <div style={{ overflowY: 'scroll', maxHeight: '70vh' }}>
                                    {data.map((log, i) => {
                                        return (
                                            <DbLogCard
                                                key={i}
                                                setSelectedLog={setSelectedLog}
                                                data={log}
                                                commodities={commodities}
                                                isLogin={_.keys(log).includes('s') || _.keys(log).includes('source')}
                                                timezone={timezone}
                                            />
                                        );
                                    })}
                                </div>
                            )}
                        </Paper>
                    </div>
                ) : (
                    loading && (
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <CircularProgress />
                        </div>
                    )
                )}
                <div style={buttonStyle}>
                    <Button onClick={handlePrev} disabled={page === 0}>
                        Prev
                    </Button>
                    <Button onClick={handleNext} disabled={_.isEmpty(data) || data.length < 25}>
                        Next
                    </Button>
                </div>
            </Grid>
            <CustomDebugDialog open={!_.isNil(selectedLog)} json={selectedLog} onClose={() => setSelectedLog()} />
        </Grid>
    );
};

export default withTheme()(DbLogs);
