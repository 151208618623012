import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import { withTheme } from '@material-ui/core/styles';

function InProgress(props) {
	const { theme } = props;
	
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: 273 - theme.spacing.unit
            }}
        >
            <CircularProgress size={80} />
        </div>
    );
}

export default withTheme()(InProgress);
