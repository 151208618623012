import _ from 'lodash';
import { getDeviceInformation } from './misc';

/**
 * Use this function for errors that will occur on the client side only.
 * @param {*} error
 * @param {*} filenameAndMethod
 * @param {*} data
 */
export async function logError(error, data) {
    // const topOfErrorStack = _.head(ErrorStackParser.parse(error));

    let url = window.location.href || 'Unable to determine URL';
    let device = await getDeviceInformation();

    let err = {
        message: error.message,
        code: error.name,
        stack: error.stack
    };

    fetch(process.env.REACT_APP_API_URL + '/errors/log', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({ err, device, url, data })
    })
        .then(async res => {
            console.log('POST /errors/log', res);
            if (res.status !== 200) {
                console.error('Error logging error. Oh the irony');
            }
        })
        .catch(err => {
            throw err;
        });
}

export async function logWarning(error, data) {
    // const topOfErrorStack = _.head(ErrorStackParser.parse(error));

    let url = window.location.href || 'Unable to determine URL';
    let device = await getDeviceInformation();

    let err = {
        message: error.message,
        code: error.name,
        stack: error.stack
    };

    fetch(process.env.REACT_APP_API_URL + '/errors/log', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({ err, device, url, data })
    })
        .then(async res => {
            console.log('POST /errors/log', res);
            if (res.status !== 200) {
                console.error('Error logging error. Oh the irony');
            }
        })
        .catch(err => {
            throw err;
        });
}
