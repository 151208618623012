import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';

import VERSION_FRONT from 'VERSION';

import ReactMarkdown from 'react-markdown';
import customMuiMarkdownRenderer from 'utils/customMuiMarkdownRenderer';
import { getRichTextEditorContent } from 'components/RichTextEditor';

import CompanyLogo from 'components/CompanyLogo/CompanyLogo';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { Typography } from '@material-ui/core';
import { getQueryStringValue } from 'utils/query';
import { AVAILABLE_LANGS } from '../../../constants.js';

const availableLangs = AVAILABLE_LANGS[process.env.REACT_APP_REGION_EXT];

function Privacy(props) {
    const { http, theme, useFallbackData } = props;
    const { lang } = useContext(LocalizationContext);

    const [markdownText, setMarkdownText] = useState('');
    const [brandLogoOverride, setBrandLogoOverride] = useState({});

    const [content, setContent] = useState();

    const loadPrivacy = async () => {
        let queryLang = getQueryStringValue('lang');
        let informationFormsEndpoint = `/informationForms/${
            !_.isNil(queryLang) && availableLangs.includes(queryLang) ? queryLang : lang
        }`;

        if (useFallbackData) {
            const version = VERSION_FRONT.replaceAll('.', '-');
            informationFormsEndpoint = `/informationForms/fallback/${
                !_.isNil(queryLang) && availableLangs.includes(queryLang) ? queryLang : lang
            }/${version}`;
        }
        const brandRes = await http.getJSON('/getBrandConfig');
        if (brandRes.ok) {
            setBrandLogoOverride(brandRes.data.brandLogo);
        }

        let res = await http.getJSON(informationFormsEndpoint);
        if (res.ok && !_.isNil(res.data.informationForms.privacy)) {
            if (_.isObject(res.data.informationForms.privacy)) {
                setContent(getRichTextEditorContent(res.data.informationForms.privacy));
            } else {
                setMarkdownText(res.data.informationForms.privacy);
            }
        } else {
            const filePath = require('./privacy-' + process.env.REACT_APP_REGION_EXT.toLowerCase() + '.md');

            fetch(filePath)
                .then(response => {
                    return response.text();
                })
                .then(text => {
                    this.setState({
                        markdownText: text
                    });
                });
        }
    };

    useEffect(() => {
        loadPrivacy();
    }, []);

    return (
        <Grid container spacing={0} style={{ justifyContent: 'center', height: '100%' }}>
            <Grid item xs={12} sm={8} style={{ maxWidth: 800, margin: 'auto 0' }}>
                <Paper style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <CompanyLogo brandLogoOverride={brandLogoOverride} />
                    <div data-cy="privacy" style={{ padding: theme.spacing.unit * 3, marginTop: 55.5 }}>
                        {markdownText ? (
                            <ReactMarkdown source={markdownText} renderers={customMuiMarkdownRenderer} />
                        ) : (
                            <Typography variant="body1" gutterBottom>
                                {content}
                            </Typography>
                        )}
                    </div>
                </Paper>
            </Grid>
        </Grid>
    );
}

export default withRouter(Privacy);
