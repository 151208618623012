import React from 'react';

import * as colors from '@material-ui/core/colors';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withTheme } from '@material-ui/core/styles';

export const getErrorBackgroundColor = theme => theme.palette.secondary[300];

function BaseWidget(props) {
    const { theme, elevated, title, error, style, children, ...rest } = props;

    const widgetStyle = {
        width: `calc(100% - ${theme.spacing.unit * 4}px)`,
        margin: theme.spacing.unit * 2,
        padding: `${theme.spacing.unit * 1.5}px ${theme.spacing.unit * 2}px`,
        backgroundColor: error ? getErrorBackgroundColor(theme) : theme.palette.background.paper,
        ...style
    };

    const backgroundColor = colors.green[700];

    return (
        <Paper elevation={elevated ? 4 : 2} style={widgetStyle} {...rest}>
            {title && (
                <Typography
                    variant="body1"
                    style={{
                        margin: -theme.spacing.unit * 2,
                        marginTop: -theme.spacing.unit * 1.5,
                        marginBottom: theme.spacing.unit * 1.5,
                        padding: `${theme.spacing.unit * 1}px ${theme.spacing.unit * 2}px`,
                        backgroundColor,
                        // color: theme.palette.common.white,
                        color: theme.palette.getContrastText(backgroundColor),
                        borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`
                        // fontSize: 16
                    }}
                >
                    {title}
                </Typography>
            )}
            {/* <Typography
                    variant="h6"
                    style={{ marginBottom: theme.spacing.unit, color: theme.palette.getContrastText(widgetStyle.backgroundColor), fontSize: 18 }}
                >
                    {title}
                </Typography> */}
            {children}
        </Paper>
    );
}

export default withTheme()(BaseWidget);
