import React, { useState, useContext } from 'react';
import moment from 'moment-timezone';

import _ from 'lodash';
import { formatAsCurrency, deviceHelper, isEXPRegion } from 'utils/misc';
import { GOOGLE_PLACE_TYPE_LOCALIZATIONS } from 'constants.js';

import { green, grey, red } from '@material-ui/core/colors';

import { withTheme } from '@material-ui/core/styles';

import {
    Badge,
    Button,
    FormControl,
    Grid,
    Icon,
    IconButton,
    InputLabel,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    OutlinedInput,
    Paper,
    Select,
    TableCell,
    TableRow,
    TextField,
    Tooltip,
    Dialog,
    DialogActions,
    DialogContent,
    Typography,
    CircularProgress,
    Avatar,
    colors
} from '@material-ui/core';

import { mdiAccountKey } from '@mdi/js';
import MDIcon from '@mdi/react';

import useTable from 'components/Table/useTable';
import { _user, _time } from 'std';
import ConfirmDialogContext from 'components/Dialogs/Confirm/ConfirmDialogContext';

import { openWindow } from 'helpers/windowHelper';

import OperatorContext from 'utils/contexts/OperatorContext';
import { downloadObjectAsCSV, getCustomerName } from 'utils/misc';

import { Clipboard } from '@capacitor/clipboard';
import DashBoardTable from 'components/Table/DashBoardTable';
import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery';
import UserNoteDialog from 'components/Dialogs/Users/UserNoteDialog';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';
import PastLocationDialog from 'components/Dialogs/Users/PastLocationDialog';

import QuickRegisterFormDialog from 'components/Dialogs/QuickRegister';
import useQuickRegister from 'containers/Operators/QuickRegistration/hooks/useQuickRegister';
import useDefaultCoordinates from 'utils/hooks/useDefaultCoordinates';

import useDateRangePicker from 'components/DateTimePickersTz/hooks/useDateRangePicker';
import DateRangePicker from 'components/DateTimePickersTz/DateRangePicker';
import { useEffect } from 'react';
import { set } from 'dot-object';

const enabledHeaders = [
    { name: 'Actions', key: null },
    { name: 'Icon', key: null },
    { name: 'Name^', key: 'Name' },
    { name: 'ID', key: null },
    { name: 'Email^', key: 'Email' },
    { name: 'Phone', key: null },
    { name: 'Balance^', key: 'Balance' },
    { name: 'Address^', key: 'Address' },
    { name: 'City^', key: 'City' },
    { name: 'State/Province^', key: 'State/Province' },
    { name: 'Postal Code^', key: 'Postal Code' },
    { name: 'Past Locations', key: null },
    { name: 'Status^', key: 'Status' },
    { name: 'Type^', key: 'Type' },
    { name: 'Business Name^', key: 'Business Name' },
    { name: 'Recurring Fees^', key: 'Recurring Fees' },
    { name: 'Preferences', key: null }
];

const disabledHeaders = [
    { name: 'Actions', key: null },
    { name: 'Icon', key: null },
    { name: 'Name^', key: 'Name' },
    { name: 'ID', key: null },
    { name: 'Email^', key: 'Email' },
    { name: 'Phone', key: null },
    { name: 'Balance^', key: 'Balance' },
    { name: 'Address^', key: 'Address' },
    { name: 'City^', key: 'City' },
    { name: 'State/Province^', key: 'State/Province' },
    { name: 'Postal Code^', key: 'Postal Code' },
    { name: 'Past Locations', key: null },
    { name: 'Status^', key: 'Status' },
    { name: 'Type^', key: 'Type' },
    { name: 'Business Name^', key: 'Business Name' },
    { name: 'Recurring Fees^', key: 'Recurring Fees' },
    { name: 'Preferences', key: null }
];

const CLOSED_ICON = 'block';
const BANNED_ICON = 'person_off';
const DELETED_ICON = 'delete';
const SUSPENDED_ICON = 'cancel';
const IN_ARREARS_ICON = 'attach_money';
const UNDER_REVIEW_ICON = 'visibility';
const STALE_ICON = 'hourglass_bottom';

const Users = ({ theme, google, http, onSnackbar, history, minRedemption }) => {
    const operator = useContext(OperatorContext);
    const { lang } = useContext(LocalizationContext);

    const { defaultCoordinates } = useDefaultCoordinates(http);
    const collapseSearch = useMediaQuery(theme.breakpoints.down('sm'));

    const [filtersOpen, setFiltersOpen] = useState(null);

    const [showUserNoteDialog, setShowUserNoteDialog] = useState(false);
    const [userNote, setUserNote] = useState('');
    const [userNotes, setUserNotes] = useState([]);
    const [noteIsReadOnly, setNoteIsReadOnly] = useState(false);
    const [currentUserID, setCurrentUserID] = useState('');

    const [showUserStatusDialog, setShowUserStatusDialog] = useState(false);
    const [userStatusActionType, setUserStatusActionType] = useState('');
    const [userStatusActionReason, setUserStatusActionReason] = useState('');

    const [showPastLocationDialog, setShowPastLocationDialog] = useState(false);
    const [currentPastLocations, setCurrentPastLocations] = useState([]);

    const [showVolumeReportDialog, setShowVolumeReportDialog] = useState(false);
    const [emailVerificationRequired, setEmailVerificationRequired] = useState(true);
    const [services, setServices] = useState([]);

    const [locType, setLocType] = useState('All');
    const [statusType, setStatusType] = useState('All');
    const [isCollectionProgramMember, setIsCollectionProgramMember] = useState('All');
    const [isSchemeIDUser, setIsSchemeIDUser] = useState('All');
    const [selectedBusinessType, setSelectedBusinessType] = useState([]);

    const [queryObj, setQueryObj] = useState({ accountType: 'Customer', deleted: { $ne: true } });
    const [queryObj2, setQueryObj2] = useState({ accountType: 'Customer', $or: [{ deleted: true }] });
    const [inArrears, setInArrears] = useState(false);

    const [isCollectionProgramEnabled, setIsCollectionProgramEnabled] = useState(false);

    const getCollectionsConfig = async () => {
        let res = await http.getJSON('/getEnabledFunctions', false, true);
        if (res.ok) {
            setIsCollectionProgramEnabled(_.get(res.data, 'collectionProgramEnabled', false));
        } else {
            setIsCollectionProgramEnabled(false)
        }
    };

    const handleStatusTypeChange = e => {
        const type = e.target.value;
        setStatusType(type);
        const newQueryObj = _.cloneDeep(queryObj);
        delete newQueryObj.disabled;
        delete newQueryObj.suspended;
        delete newQueryObj.banned;
        delete newQueryObj.underReview;
        delete newQueryObj.stale;
        delete newQueryObj.deleted;
        delete newQueryObj.$and;

        if (type === 'Active') {
            setInArrears(false);
            newQueryObj.$and = [
                {
                    $or: [{ disabled: { $ne: true, $exists: true } }, { disabled: { $exists: false } }]
                },
                {
                    $or: [{ suspended: { $ne: true, $exists: true } }, { suspended: { $exists: false } }]
                },
                {
                    $or: [{ banned: { $ne: true, $exists: true } }, { banned: { $exists: false } }]
                },
                {
                    $or: [{ underReview: { $ne: true, $exists: true } }, { underReview: { $exists: false } }]
                },
                {
                    $or: [{ stale: { $ne: true, $exists: true } }, { stale: { $exists: false } }]
                }
            ];
        } else if (type === 'Closed') {
            setInArrears(false);
            newQueryObj.disabled = true;
        } else if (type === 'Suspended') {
            setInArrears(false);
            newQueryObj.suspended = true;
        } else if (type === 'Banned') {
            setInArrears(false);
            newQueryObj.banned = true;
        } else if (type === 'Under Review') {
            setInArrears(false);
            newQueryObj.underReview = true;
        } else if (type === 'Stale') {
            setInArrears(false);
            newQueryObj.stale = true;
        } else if (type === 'In Arrears') {
            setInArrears(true);
        } else {
            setInArrears(false);
        }
        setQueryObj(newQueryObj);
    };

    const handleLocationTypeChange = e => {
        const type = e.target.value;
        setLocType(type);
        const newQueryObj = _.cloneDeep(queryObj);
        const newQueryObj2 = _.cloneDeep(queryObj2);
        if (_.isEmpty(type) || type === 'All') {
            delete newQueryObj['location.locationType'];
            delete newQueryObj2['location.locationType'];
        } else {
            newQueryObj['location.locationType'] = type;
            newQueryObj2['location.locationType'] = type;
        }
        // else if (type === 'None'){
        //     newQueryObj['location.locationType'] = {$exists:false};
        //     newQueryObj2['location.locationType'] = {$exists:false};
        // }

        setQueryObj(newQueryObj);
        setQueryObj2(newQueryObj2);
    };

    const handleIsCollectionProgramMemberChange = e => {
        const newVal = e.target.value;
        setIsCollectionProgramMember(newVal);
        const newQueryObj = _.cloneDeep(queryObj);
        const newQueryObj2 = _.cloneDeep(queryObj2);
        if (newVal === 'All') {
            delete newQueryObj['collectionProgramPreference'];
            delete newQueryObj2['collectionProgramPreference'];
        } else if (newVal === false) {
            newQueryObj['collectionProgramPreference'] = { $ne: true };
            newQueryObj2['collectionProgramPreference'] = { $ne: true };
        } else {
            newQueryObj['collectionProgramPreference'] = newVal;
            newQueryObj2['collectionProgramPreference'] = newVal;
        }
        setQueryObj(newQueryObj);
        setQueryObj2(newQueryObj2);
    };
    const handleIsSchemeIDUserChange = e => {
        const newVal = e.target.value;
        setIsSchemeIDUser(newVal);
        const newQueryObj = _.cloneDeep(queryObj);
        const newQueryObj2 = _.cloneDeep(queryObj2);
        if (newVal === 'All') {
            delete newQueryObj['isSchemeIDUser'];
            delete newQueryObj2['isSchemeIDUser'];
        } else if (newVal === false) {
            newQueryObj['isSchemeIDUser'] = { $ne: true };
            newQueryObj2['isSchemeIDUser'] = { $ne: true };
        } else {
            newQueryObj['isSchemeIDUser'] = newVal;
            newQueryObj2['isSchemeIDUser'] = newVal;
        }
        setQueryObj(newQueryObj);
        setQueryObj2(newQueryObj2);
    };
    const handleSelectedBusinessTypeChange = e => {
        const newVal = e.target.value;
        setSelectedBusinessType(newVal);
        const newQueryObj = _.cloneDeep(queryObj);
        const newQueryObj2 = _.cloneDeep(queryObj2);
        if (_.isEmpty(newVal)) {
            delete newQueryObj['location.businessLocationTags'];
            delete newQueryObj2['location.businessLocationTags'];
        } else {
            newQueryObj['location.businessLocationTags'] = { $in: newVal };
            newQueryObj2['location.businessLocationTags'] = { $in: newVal };
        }
        setQueryObj(newQueryObj);
        setQueryObj2(newQueryObj2);
    };
    const handleBusinessTypeSelectNone = () => {
        const newVal = [];
        setSelectedBusinessType(newVal);
        const newQueryObj = _.cloneDeep(queryObj);
        const newQueryObj2 = _.cloneDeep(queryObj2);
        delete newQueryObj['location.businessLocationTags'];
        delete newQueryObj2['location.businessLocationTags'];
        setQueryObj(newQueryObj);
        setQueryObj2(newQueryObj2);
    };

    const handleLocationTypeDisplay = locType => {
        // GITLAB #2862 - Display Residential and Condo as Personal.
        // Doing it this way because to change the actual Users.location.locationType values
        // and future proof them would require a big and careful refactoring that may have potential side-effects.
        if (isEXPRegion() && (locType === 'Residential' || locType === 'Condo')) {
            return 'Personal';
        }
        return locType;
    };

    const getServices = async () => {
        let res = await http.getJSON('/services', false, true);
        if (res.ok) {
            setServices(_.get(res, 'data.services', []));
        }
    };

    const getEmailVerificationRequired = async () => {
        let res = await http.getJSON('/emailVerificationRequired', false, true);
        if (res.ok) {
            setEmailVerificationRequired(_.get(res, 'data.emailVerificationRequired', []));
        }
    };

    useEffect(() => {
        getServices();
        getEmailVerificationRequired();
        getCollectionsConfig();
    }, []);

    const {
        startDate,
        endDate,
        timezone,
        dateWindow,
        handleChangeStartDate,
        handleChangeEndDate,
        handleGoForwards,
        handleGoBackwards,
        handleWindowSelect
    } = useDateRangePicker({
        saveStateInURL: true,
        timezones: [process.env.REACT_APP_REGION_TIMEZONE],
        initStartVal: _time.getStartOfIsoWeek(process.env.REACT_APP_REGION_TIMEZONE, 1),
        initEndVal: _time.getEndOfDay(process.env.REACT_APP_REGION_TIMEZONE),
        initDateWindow: 'thisIsoWeek'
    });

    const {
        loading,
        searchTerm,
        getData,
        page,
        rowsPerPage,
        filteredData,
        search,
        filteredHeaders,
        totalDataEntries,
        handleChange,
        handleSearch,
        handleChangeFilterHeaders,
        handleAllFilterHeaders,
        handleChangePage,
        handleChangeRowsPerPage
    } = useTable({
        url: '/users',
        key: 'customers',
        headers: enabledHeaders.map(h => h.name),
        queryObj: queryObj,
        customQueryName: 'in_arrears',
        customQueryValue: inArrears
    });

    const {
        loading: loading2,
        getData: getData2,
        page: page2,
        rowsPerPage: rowsPerPage2,
        filteredData: filteredData2,
        search: search2,
        filteredHeaders: filteredHeaders2,
        totalDataEntries: totalDataEntries2,
        handleChange: handleChange2,
        handleSearch: handleSearch2,
        handleChangeFilterHeaders: handleChangeFilterHeaders2,
        handleAllFilterHeaders: handleAllFilterHeaders2,
        handleChangePage: handleChangePage2,
        handleChangeRowsPerPage: handleChangeRowsPerPage2
    } = useTable({
        url: '/users',
        key: 'customers',
        headers: disabledHeaders.map(h => h.name),
        queryObj: queryObj2
    });

    const { quickRegisterFormOpen, setQuickRegisterFormOpen, handleRegisterUser } = useQuickRegister({
        http,
        history,
        reloadUsers: getData,
        onSnackbar
    });

    const [passwordResetLinkLoading, setPasswordResetLinkLoading] = useState(false);
    const [deactivateMenuEl, setDeactivateMenuEl] = useState(null);
    const [downloadMenuEl, setDownloadMenuEl] = useState(null);
    const [downloadingReports, setDownloadingReports] = useState(false);
    const [deactivateActionsMenu, setDeactivateActionsMenu] = useState(null);

    const warnAction = useContext(ConfirmDialogContext);

    const deactivateMenuClick = (event, _id) => {
        setCurrentUserID(_id);
        setDeactivateMenuEl(event.currentTarget);
    };

    const deactivateActionsMenuClick = (event, _id) => {
        setCurrentUserID(_id);
        setDeactivateActionsMenu(event.currentTarget);
    };

    const handleDownloadMenuClick = event => {
        setDownloadMenuEl(event.currentTarget);
    };

    const handleDownloadMenuClose = () => {
        setDownloadMenuEl(null);
    };

    const handleDeactivateMenuClose = () => {
        setCurrentUserID(null);
        setDeactivateMenuEl(null);
    };

    const handleDeactivateActionsMenuClose = () => {
        setCurrentUserID(null);
        setDeactivateActionsMenu(null);
    };

    const handleView = _id => {
        const URL = `/customers/${_id}`;
        openWindow(history, !deviceHelper.isNativeApp(), URL);
    };

    const handleViewLastBulk = _id => {
        const URL = `/operators/${_user.getId(operator)}/bulks/${_id}`;
        openWindow(history, !deviceHelper.isNativeApp(), URL);
    };

    const renderBalance = value => {
        if (value > minRedemption) {
            return <b style={{ color: green['500'] }}>{formatAsCurrency(value)}</b>;
        } else if (value > 0) {
            return <b style={{ color: theme.palette.text.secondary }}>{formatAsCurrency(value)}</b>;
        } else if (value < 0) {
            return <b style={{ color: red['500'] }}>{formatAsCurrency(value)}</b>;
        } else {
            return <span style={{ color: grey['500'] }}>{formatAsCurrency(value)}</span>;
        }
    };

    const getPasswordResetLink = async _id => {
        setPasswordResetLinkLoading(true);

        const res = await http.getJSON(`/users/${_id}/getPasswordResetLink`);

        if (res.ok) {
            const { url } = res.data;
            try {
                Clipboard.write({ string: url });
                onSnackbar('Copied password reset link to clipboard');
            } catch (err) {
                onSnackbar('Unable to copy value to clipboard', 'error');
            }
        } else {
            onSnackbar('Problem getting password reset link from server', 'error');
        }

        setPasswordResetLinkLoading(false);
    };

    const handleBanAccount = async (_id, banReason) => {
        const res = await http.post(`/users/${_id}/banUser`, {
            banReason
        });
        if (res.ok) {
            await getData();
            await getData2();
            onSnackbar('Successfully banned user');
        }
    };

    const handleDeleteAccount = async (_id, deleteReason) => {
        const res = await http.post(`/users/${_id}/deleteUserAccount`, {
            deleteReason
        });
        if (res.ok) {
            await http.post(`/users/${_id}/adminLogoutUser`);
            await getData();
            await getData2();
            onSnackbar('Successfully deleted user account');
        }
    };

    const handleUnbanAccount = async (_id, reason) => {
        const res = await http.post(`/users/${_id}/enableUser`, { reason });
        if (res.ok) {
            await getData();
            await getData2();
            onSnackbar('Successfully unbanned user');
        }
    };

    const handleSetAccountDisabled = async (_id, disabled, disableReason) => {
        const res = await http.post(`/users/${_id}/setUserDisabled`, { disabled, disableReason });
        if (res.ok) {
            await getData();
            await getData2();
            onSnackbar(`Successfully ${disabled ? 'closed' : 'reopened'} acount`);
        }
    };

    const handleSetAccountSuspended = async (_id, suspended, suspendReason) => {
        const res = await http.post(`/users/${_id}/updateAccountSubStatus`, {
            subStatusType: 'suspended',
            state: suspended,
            reason: suspendReason
        });
        if (res.ok) {
            await getData();
            await getData2();
            onSnackbar(`Successfully ${suspended ? 'suspended' : 'unsuspended'} acount`);
        }
    };

    const handleConvertAccountToEmailPass = async _id => {
        const res = await http.post(`/users/convertToEmailPass`, { _id });
        if (res.ok) {
            await getData();
            await getData2();
            onSnackbar(`Successfully converted user to email/password authentication.`);
        }
    };

    const handleDeactivateMenuItemClick = (action, _id) => {
        let user = _.find(filteredData.concat(filteredData2), u => u._id.toString() === _id.toString());
        const userBalance = _.get(user, 'balance', 0);
        if ((action === 'disable' || action === 'delete') && userBalance > 0) {
            warnAction(() => handleView(_id), 'This user has an outstanding balance. You must redeem it to proceed.');
        } else if (
            action === 'ban' ||
            action === 'disable' ||
            action === 'delete' ||
            action === 'suspend' ||
            action === 'enable' ||
            action === 'unban' ||
            action === 'unsuspend'
        ) {
            setShowUserStatusDialog(true);
            setUserStatusActionType(action);

            setDeactivateMenuEl(null);
            return;
        } else if (action === 'convert') {
            warnAction(() => {
                handleConvertAccountToEmailPass(_id);
            }, 'Are you sure you want to convert this account to email/password authentication? This action cannot be undone.');
        }

        setCurrentUserID(null);
        setDeactivateMenuEl(null);
    };

    const handleUserStatusConfirmation = async action => {
        if (action === 'ban') {
            await handleBanAccount(currentUserID, userStatusActionReason);
        }

        if (action === 'unban') {
            await handleUnbanAccount(currentUserID, userStatusActionReason);
        }

        if (action === 'disable') {
            await handleSetAccountDisabled(currentUserID, true, userStatusActionReason);
        }
        if (action === 'enable') {
            await handleSetAccountDisabled(currentUserID, false, userStatusActionReason);
        }

        if (action === 'suspend') {
            await handleSetAccountSuspended(currentUserID, true, userStatusActionReason);
        }
        if (action === 'unsuspend') {
            await handleSetAccountSuspended(currentUserID, false, userStatusActionReason);
        }

        if (action === 'delete') {
            await handleDeleteAccount(currentUserID, userStatusActionReason);
        }

        setCurrentUserID(null);
        setShowUserStatusDialog(false);
        setUserStatusActionReason('');
    };

    const handleUpdateNote = async (_id, note) => {
        const res = await http.post(`/users/${_id}/addNote`, { note });
        if (res.ok) {
            await getData();
            await getData2();
            onSnackbar(`Successfully updated user notes.`);
        }
    };

    const handleFiltersOpen = close => e => {
        if (close || Boolean(filtersOpen)) {
            setFiltersOpen(null);
        } else {
            setFiltersOpen(e.currentTarget);
        }
    };

    const handleSubmit = e => {
        handleSearch(e);
        handleSearch2(e);
        if (filtersOpen) {
            setFiltersOpen(false);
        }
    };

    const handleReportDownload = async type => {
        setDownloadingReports(true);
        setDownloadMenuEl(null);

        let date = `${moment().format('YYYY-MM-DD HH:mm')}`;
        if (type === 'volume') {
            date = `${moment(startDate).format('YYYY-MM-DD')} - ${moment(endDate).format('YYYY-MM-DD')}`;
        }

        const types = {
            location: {
                name: 'customer_location_history',
                endpoint: `/users/getCustomerLocationReport`
            },
            activity: {
                name: 'customer_activity_history',
                endpoint: `/users/getCustomerActivityReport`
            },
            volume: {
                name: 'customer_volume_history',
                endpoint: `/users/getCustomerVolumeReport?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`
            },
            edit: {
                name: 'customer_edit_history',
                endpoint: `/users/getCustomerEditHistory`
            },
            aging: {
                name: 'aging_customers',
                endpoint: `/users/getAgingCustomersReport`
            }
        };

        const name = types[type].name;
        const endpoint = types[type].endpoint;

        const res = await http.getJSON(endpoint);
        if (!res.ok) {
            onSnackbar('Unable to download report', 'error');
        }
        const fileName = `${name}_${date}`;
        try {
            if (_.isEmpty(res.data.report)) {
                onSnackbar('No information available', 'info');
            } else {
                await downloadObjectAsCSV(res.data.report, fileName);
            }
        } catch (err) {
            console.log(err);
        }
        setDownloadingReports(false);
    };

    const handleDownloadSuspensions = async () => {
        setDownloadingReports(true);
        setDownloadMenuEl(null);
        const csvRows = [];

        filteredData2.forEach(customer => {
            if (customer.deleted) return;

            //TO-DO -> update this function to grab info from account status update events
            const newRow = {
                name: getCustomerName(customer),
                email: customer.email,
                customerID: customer.uniqueID,
                disabled: customer.disabled,
                dateDisabled: '',
                reasonDisabled: '',
                // deleted: customer.deleted,
                // dateDeleted: '',
                banned: customer.banned,
                dateBanned: '',
                reasonBanned: '',
                numTimesDisabledOrBanned: 0,
                accountType: customer.accountType
            };

            const isDisabled = customer.disabled;
            const dateDisabled = customer.dateDisabled; // arr
            const reasonDisabled = customer.reasonDisabled; // arr

            if (isDisabled && dateDisabled && reasonDisabled) {
                const lastDateDisabled = _.last(customer.dateDisabled);
                newRow.dateDisabled = moment(lastDateDisabled).tz(process.env.REACT_APP_REGION_TIMEZONE);
                newRow.reasonDisabled = _.last(customer.reasonDisabled);
                newRow.numTimesDisabledOrBanned += customer.dateDisabled.length;
            }

            // if (customer.deleted && customer.dateDeleted) {
            //     newRow.dateDeleted = customer.dateDeleted;
            // }

            const isBanned = customer.banned;
            const dateBanned = customer.dateBanned; // arr
            const reasonBanned = customer.reasonBanned; // arr

            if (isBanned && dateBanned && reasonBanned) {
                const lastDateBanned = _.last(customer.dateBanned);
                newRow.dateBanned = moment(lastDateBanned).tz(process.env.REACT_APP_REGION_TIMEZONE);
                newRow.reasonBanned = _.last(customer.reasonBanned);
                newRow.numTimesDisabledOrBanned += customer.dateBanned.length;
            }

            csvRows.push(newRow);
        });

        const date = moment().format('YYYY-MM-DD HH:mm');
        const fileName = `suspension_statistics_${date}`;
        try {
            await downloadObjectAsCSV(csvRows, fileName);
        } catch (err) {
            console.log(err);
        }
        setDownloadingReports(false);
    };

    const handleChangeAll = e => {
        handleChange(e);
        handleChange2(e);
    };

    const onChangeFilterHeader = e => {
        handleChangeFilterHeaders2(e);
        handleChangeFilterHeaders(e);
    };

    const onChangeAllFilterHeaders = all => e => {
        handleAllFilterHeaders(all)(e);
        handleAllFilterHeaders2(all)(e);
    };
    const inputStyles = {
        margin: theme.spacing.unit / 2
    };

    const statusIconStyle = {
        color: theme.palette.text.disabled,
        marginRight: '5px'
    };

    const cellStyle = { whiteSpace: 'nowrap' };

    const renderUserRows = (user, filteredHeaders) => {
        const {
            _id,
            fullName,
            uniqueID,
            email,
            oAuth,
            phone,
            location,
            settings,
            balance,
            recurringFees,
            verification,
            banned,
            disabled,
            deleted,
            suspended,
            underReview,
            stale,
            //bulks,
            lastBulkArr,
            note,
            notes,
            pastLocations = []
        } = user;
        const statusType = deleted
            ? 'Deleted'
            : banned
            ? 'Banned'
            : disabled
            ? 'Closed'
            : suspended
            ? 'Suspended'
            : underReview
            ? 'Under Review'
            : stale
            ? 'Stale'
            : 'Active';

        let statusIcons = [];

        banned && statusIcons.push({ icon: BANNED_ICON, status: 'Banned' });

        deleted && statusIcons.push({ icon: DELETED_ICON, status: 'Deleted' });

        disabled && statusIcons.push({ icon: CLOSED_ICON, status: 'Closed' });

        suspended && statusIcons.push({ icon: SUSPENDED_ICON, status: 'Suspended' });

        balance < 0 && statusIcons.push({ icon: IN_ARREARS_ICON, status: 'In Arrears' });

        underReview && statusIcons.push({ icon: UNDER_REVIEW_ICON, status: 'Under Review' });

        stale && statusIcons.push({ icon: STALE_ICON, status: 'Stale' });

        const lastBulk = lastBulkArr ? _.last(lastBulkArr) : undefined;
        return (
            <TableRow key={_id}>
                {filteredHeaders.includes('Actions') && (
                    <TableCell style={cellStyle}>
                        <>
                            {!deleted && (
                                <>
                                    <Tooltip title="Actions">
                                        <IconButton
                                            data-cy={`user-table-${uniqueID}-actions-button`}
                                            onClick={e => {
                                                deactivateActionsMenuClick(e, _id);
                                            }}
                                        >
                                            <Badge color="primary" variant="dot" invisible={_.isEmpty(notes)}>
                                                <Icon>tune</Icon>
                                            </Badge>
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Update Account Status">
                                        <IconButton
                                            data-cy={`user-table-${uniqueID}-account-menu-button`}
                                            onClick={e => {
                                                deactivateMenuClick(e, _id);
                                            }}
                                        >
                                            <MDIcon
                                                path={mdiAccountKey}
                                                size={1}
                                                color={theme.palette.text.secondary}
                                            />
                                        </IconButton>
                                    </Tooltip>
                                </>
                            )}
                            <Menu
                                data-cy="actions-menu"
                                anchorEl={deactivateActionsMenu}
                                open={Boolean(deactivateActionsMenu) && currentUserID === _id}
                                onClose={handleDeactivateActionsMenuClose}
                            >
                                <MenuItem
                                    data-cy={`user-table-${uniqueID}-handle-view`}
                                    onClick={() => {
                                        handleView(_id);
                                        setCurrentUserID(null);
                                        setDeactivateActionsMenu(null);
                                    }}
                                >
                                    <ListItemIcon>
                                        <Icon>visibility</Icon>
                                    </ListItemIcon>
                                    <ListItemText primary={'View Account'} />
                                </MenuItem>

                                <MenuItem
                                    data-cy={`user-table-${uniqueID}-comments-button`}
                                    onClick={() => {
                                        setCurrentUserID(_id);
                                        setUserNote('');
                                        setUserNotes(notes);
                                        setNoteIsReadOnly(deleted);
                                        setShowUserNoteDialog(true);
                                        setDeactivateActionsMenu(null);
                                    }}
                                >
                                    <ListItemIcon>
                                        <Tooltip title={'Notes' + (_.isNil(note) ? '' : `: ${note}`)}>
                                            <Icon
                                                style={{
                                                    color: !_.isEmpty(notes) ? green['500'] : undefined
                                                }}
                                            >
                                                {' '}
                                                add_comment
                                            </Icon>
                                        </Tooltip>
                                    </ListItemIcon>
                                    <ListItemText primary={'Admin Note'} />
                                </MenuItem>

                                <MenuItem
                                    data-cy={`user-table-${uniqueID}-view-bulk-button`}
                                    disabled={!lastBulk}
                                    onClick={() => {
                                        handleViewLastBulk(_.get(lastBulk, '_id'));
                                        setCurrentUserID(null);
                                        setDeactivateActionsMenu(null);
                                    }}
                                >
                                    <ListItemIcon>
                                        <Icon>ballot</Icon>
                                    </ListItemIcon>
                                    <ListItemText primary={'View Last Order'} />
                                </MenuItem>

                                <MenuItem
                                    data-cy={`user-table-${uniqueID}-account-menu-copy-password`}
                                    disabled={passwordResetLinkLoading}
                                    onClick={() => {
                                        getPasswordResetLink(_id);
                                        setCurrentUserID(null);
                                        setDeactivateActionsMenu(null);
                                    }}
                                >
                                    <ListItemIcon>
                                        <Icon>link</Icon>
                                    </ListItemIcon>
                                    <ListItemText primary={'Copy Password Reset Link'} />
                                </MenuItem>
                            </Menu>
                            <Menu
                                data-cy="help-menu"
                                anchorEl={deactivateMenuEl}
                                open={Boolean(deactivateMenuEl) && currentUserID === _id}
                                onClose={handleDeactivateMenuClose}
                            >
                                <MenuItem
                                    data-cy={`user-table-${uniqueID}-account-menu-${disabled ? 'enable' : 'disable'}`}
                                    onClick={() => handleDeactivateMenuItemClick(disabled ? 'enable' : 'disable', _id)}
                                >
                                    <ListItemIcon>
                                        <Icon>{disabled ? 'check_circle_outline' : 'block'}</Icon>
                                    </ListItemIcon>
                                    <ListItemText primary={disabled ? 'Reopen Account' : 'Close Account'} />
                                </MenuItem>
                                <MenuItem
                                    data-cy={`user-table-${uniqueID}-account-menu-${banned ? 'unban' : 'ban'}`}
                                    onClick={() => handleDeactivateMenuItemClick(banned ? 'unban' : 'ban', _id)}
                                >
                                    <ListItemIcon>
                                        <Icon>person_off</Icon>
                                    </ListItemIcon>
                                    <ListItemText primary={banned ? 'Unban Account' : 'Ban Account'} />
                                </MenuItem>
                                <MenuItem
                                    data-cy={`user-table-${uniqueID}-account-menu-${
                                        suspended ? 'suspend' : 'unsuspend'
                                    }`}
                                    onClick={() =>
                                        handleDeactivateMenuItemClick(suspended ? 'unsuspend' : 'suspend', _id)
                                    }
                                >
                                    <ListItemIcon>
                                        <Icon>cancel</Icon>
                                    </ListItemIcon>
                                    <ListItemText primary={suspended ? 'Unsuspend Account' : 'Suspend Account'} />
                                </MenuItem>
                                <MenuItem
                                    data-cy={`user-table-${uniqueID}-account-menu-delete`}
                                    onClick={() => handleDeactivateMenuItemClick('delete', _id)}
                                >
                                    <ListItemIcon>
                                        <Icon>person_remove</Icon>
                                    </ListItemIcon>
                                    <ListItemText primary={'Delete Account'} />
                                </MenuItem>
                                {oAuth.provider && (
                                    <MenuItem onClick={() => handleDeactivateMenuItemClick('convert', _id)}>
                                        <ListItemIcon>
                                            <Icon>sync_lock</Icon>
                                        </ListItemIcon>
                                        <ListItemText primary={'Convert to Email/Password Account'} />
                                    </MenuItem>
                                )}
                            </Menu>
                        </>
                    </TableCell>
                )}

                {filteredHeaders.includes('Icon') && (
                    <TableCell style={cellStyle}>
                        {statusIcons.map(iconObject => (
                            <Tooltip title={iconObject.status}>
                                <Icon style={statusIconStyle}>{iconObject.icon}</Icon>
                            </Tooltip>
                        ))}
                    </TableCell>
                )}

                {filteredHeaders.includes('Name^') && <TableCell style={cellStyle}>{fullName}</TableCell>}

                {filteredHeaders.includes('ID') && <TableCell style={cellStyle}>{uniqueID}</TableCell>}
                {filteredHeaders.includes('Email^') && (
                    <TableCell style={cellStyle} data-cy={`user-table-${uniqueID}-email`}>
                        {email}
                    </TableCell>
                )}

                {filteredHeaders.includes('Phone') && (
                    <TableCell data-cy={`user-table-${uniqueID}-phone`} style={cellStyle}>
                        {phone || <span style={{ color: grey['500'] }}>N/A</span>}
                    </TableCell>
                )}

                {filteredHeaders.includes('Balance^') && (
                    <TableCell style={cellStyle}>{renderBalance(balance)}</TableCell>
                )}

                {filteredHeaders.includes('Address^') && (
                    <TableCell
                        style={{
                            ...cellStyle,
                            maxWidth: 250,
                            textOverflow: 'ellipsis',
                            overflow: 'hidden'
                        }}
                    >
                        <Tooltip title={_.get(location, 'description', null)} interactive>
                            <span>{_.get(location, 'description', null)}</span>
                        </Tooltip>
                    </TableCell>
                )}
                {filteredHeaders.includes('City^') && (
                    <TableCell style={cellStyle} data-cy={`user-table-${uniqueID}-city`}>
                        {_.get(location, 'city') || <span style={{ color: grey['500'] }}>N/A</span>}
                    </TableCell>
                )}

                {filteredHeaders.includes('State/Province^') && (
                    <TableCell data-cy={`user-table-${uniqueID}-province`} style={cellStyle}>
                        {_.get(location, 'province') || <span style={{ color: grey['500'] }}>N/A</span>}
                    </TableCell>
                )}

                {filteredHeaders.includes('Postal Code^') && (
                    <TableCell data-cy={`user-table-${uniqueID}-postal-code`} style={cellStyle}>
                        {_.get(location, 'postalCode') || <span style={{ color: grey['500'] }}>N/A</span>}
                    </TableCell>
                )}

                {filteredHeaders.includes('Past Locations') && (
                    <TableCell
                        style={{
                            ...cellStyle,
                            maxWidth: 250,
                            textOverflow: 'ellipsis',
                            overflow: 'hidden'
                        }}
                    >
                        <IconButton
                            onClick={() => {
                                setCurrentPastLocations(pastLocations);
                                setShowPastLocationDialog(true);
                            }}
                            disabled={_.get(pastLocations, 'length', 0) <= 0}
                        >
                            <Avatar
                                style={{
                                    backgroundColor:
                                        _.get(pastLocations, 'length', 0) > 0 ? colors.green[500] : colors.grey[300],
                                    height: '24px',
                                    width: '24px',
                                    fontSize: '12px'
                                }}
                            >
                                {_.get(pastLocations, 'length', 0)}
                            </Avatar>
                        </IconButton>
                    </TableCell>
                )}

                {filteredHeaders.includes('Status^') && <TableCell style={cellStyle}>{statusType}</TableCell>}

                {filteredHeaders.includes('Type^') && (
                    <TableCell style={cellStyle}>
                        {handleLocationTypeDisplay(_.get(location, 'locationType', null))}
                    </TableCell>
                )}

                {filteredHeaders.includes('Business Name^') && (
                    <TableCell style={cellStyle}>{_.get(location, 'businessName', null)}</TableCell>
                )}

                {filteredHeaders.includes('Recurring Fees^') && (
                    <TableCell style={cellStyle}>{recurringFees !== 0 ? recurringFees : null}</TableCell>
                )}

                {filteredHeaders.includes('Preferences') && (
                    <TableCell style={cellStyle}>
                        {emailVerificationRequired && (
                            <Icon
                                title="Email verified"
                                style={{
                                    color: _.get(verification, 'email.verified', false)
                                        ? green['A700']
                                        : theme.palette.text.disabled
                                }}
                            >
                                verified_user
                            </Icon>
                        )}
                        {!isEXPRegion() && (
                            <Icon
                                title="SMS notifications"
                                style={{
                                    color: settings.textNotificationsEnabled
                                        ? green['A700']
                                        : theme.palette.text.disabled
                                }}
                            >
                                phone
                            </Icon>
                        )}
                        <Icon
                            title="Email notifications"
                            style={{
                                color: settings.emailNotificationsEnabled ? green['A700'] : theme.palette.text.disabled
                            }}
                        >
                            email
                        </Icon>
                        <Icon
                            title="Push notifications"
                            style={{
                                color: settings.pushNotificationsEnabled ? green['A700'] : theme.palette.text.disabled
                            }}
                        >
                            mobile_friendly
                        </Icon>
                    </TableCell>
                )}
            </TableRow>
        );
    };

    const renderSearch = children => {
        if (collapseSearch) {
            return (
                <Menu open={Boolean(filtersOpen)} anchorEl={filtersOpen} onClose={handleFiltersOpen(true)}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            padding: theme.spacing.unit * 2
                        }}
                    >
                        {children}
                    </div>
                </Menu>
            );
        } else {
            return (
                <div
                    style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'space-between' }}
                >
                    <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>{children}</div>
                </div>
            );
        }
    };

    return (
        <>
            <UserNoteDialog
                open={showUserNoteDialog}
                onSave={() => {
                    handleUpdateNote(currentUserID, userNote);
                    setShowUserNoteDialog(false);
                }}
                onCancel={() => {
                    setShowUserNoteDialog(false);
                }}
                title={`Admin Note`}
                note={userNote}
                notes={userNotes}
                onChangeNote={(event, newValue) => {
                    setUserNote(event.target.value);
                }}
                viewOnly={noteIsReadOnly}
            />
            <PastLocationDialog
                open={showPastLocationDialog}
                onClose={() => {
                    setShowPastLocationDialog(false);
                }}
                onSnackbar={onSnackbar}
                title={`Past Locations`}
                currentPastLocations={currentPastLocations}
                searchTerm={searchTerm}
            />
            <Grid container>
                <Grid item xs={12}>
                    <div style={{ padding: theme.spacing.unit * 2, paddingBottom: theme.spacing.unit }}>
                        <Paper
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                padding: theme.spacing.unit * 2,
                                height: '100%',
                                flexWrap: 'wrap',
                                alignItems: 'center'
                            }}
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                style={{ marginBottom: theme.spacing.unit * 2 }}
                                onClick={() => setQuickRegisterFormOpen(true)}
                            >
                                Register Customer
                            </Button>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexWrap: 'wrap'
                                }}
                            >
                                <form onSubmit={handleSubmit} style={{ flex: '0 1 100%', display: 'flex' }}>
                                    {renderSearch(
                                        <>
                                            <FormControl style={inputStyles}>
                                                <InputLabel htmlFor="isSchemeIDUser" variant="outlined">
                                                    {'Scheme Member'}
                                                </InputLabel>
                                                <Select
                                                    variant="outlined"
                                                    value={isSchemeIDUser}
                                                    onChange={handleIsSchemeIDUserChange}
                                                    style={{ width: 110 }}
                                                    input={<OutlinedInput labelWidth={80} />}
                                                >
                                                    <MenuItem value="All">All</MenuItem>
                                                    <MenuItem value={false}>Disabled</MenuItem>
                                                    <MenuItem value={true}>Enabled</MenuItem>
                                                </Select>
                                            </FormControl>
                                            {isCollectionProgramEnabled && (
                                                <FormControl style={inputStyles}>
                                                    <InputLabel htmlFor="IsCollectionProgramMember" variant="outlined">
                                                        {'Collection Program'}
                                                    </InputLabel>
                                                    <Select
                                                        variant="outlined"
                                                        value={isCollectionProgramMember}
                                                        onChange={handleIsCollectionProgramMemberChange}
                                                        style={{ width: 140 }}
                                                        input={<OutlinedInput labelWidth={140} />}
                                                    >
                                                        <MenuItem value="All">All</MenuItem>
                                                        <MenuItem value={false}>Disabled</MenuItem>
                                                        <MenuItem value={true}>Enabled</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            )}
                                            <FormControl style={inputStyles}>
                                                <InputLabel htmlFor="locationType" variant="outlined">
                                                    {isEXPRegion() ? '' : 'Location '}Type
                                                </InputLabel>
                                                <Select
                                                    variant="outlined"
                                                    value={locType}
                                                    onChange={handleLocationTypeChange}
                                                    style={{ width: 150 }}
                                                    input={<OutlinedInput labelWidth={110} />}
                                                >
                                                    <MenuItem value="All">All</MenuItem>
                                                    <MenuItem value="Residential">
                                                        {isEXPRegion() ? 'Personal' : 'Residential'}
                                                    </MenuItem>
                                                    {isEXPRegion() ? <></> : <MenuItem value="Condo">Condo</MenuItem>}
                                                    <MenuItem value="Commercial">Commercial</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <FormControl style={inputStyles}>
                                                <InputLabel htmlFor="collection" variant="outlined">
                                                    Business Type
                                                </InputLabel>
                                                <Select
                                                    variant="outlined"
                                                    multiple
                                                    value={selectedBusinessType}
                                                    onChange={handleSelectedBusinessTypeChange}
                                                    style={{ width: 145 }}
                                                    input={<OutlinedInput labelWidth={100} />}
                                                >
                                                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                        <Button
                                                            onClick={e => {
                                                                e.stopPropagation();
                                                                handleSelectedBusinessTypeChange({
                                                                    target: {
                                                                        value: Object.keys(
                                                                            GOOGLE_PLACE_TYPE_LOCALIZATIONS
                                                                        )
                                                                    }
                                                                });
                                                            }}
                                                        >
                                                            All
                                                        </Button>
                                                        <Button
                                                            onClick={e => {
                                                                e.stopPropagation();
                                                                handleBusinessTypeSelectNone();
                                                            }}
                                                        >
                                                            None
                                                        </Button>
                                                    </div>
                                                    {Object.keys(GOOGLE_PLACE_TYPE_LOCALIZATIONS).map(name => (
                                                        <MenuItem key={name} value={name}>
                                                            {GOOGLE_PLACE_TYPE_LOCALIZATIONS[name][lang]}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            <FormControl style={inputStyles}>
                                                <InputLabel htmlFor="statusType" variant="outlined">
                                                    Status Type
                                                </InputLabel>
                                                <Select
                                                    variant="outlined"
                                                    value={statusType}
                                                    onChange={handleStatusTypeChange}
                                                    style={{ width: 180, maxHeight: 56 }}
                                                    input={<OutlinedInput labelWidth={100} />}
                                                >
                                                    <MenuItem value="All">All</MenuItem>
                                                    {isEXPRegion() ? <MenuItem value="Active">Active</MenuItem> : <></>}
                                                    <MenuItem value="Stale">
                                                        <Grid container direction="row" alignItems="center">
                                                            <Icon style={statusIconStyle}>{STALE_ICON}</Icon> Stale
                                                        </Grid>
                                                    </MenuItem>
                                                    <MenuItem value="Under Review">
                                                        <Grid container direction="row" alignItems="center">
                                                            <Icon style={statusIconStyle}>{UNDER_REVIEW_ICON}</Icon>{' '}
                                                            Under Review
                                                        </Grid>
                                                    </MenuItem>
                                                    <MenuItem value="Suspended">
                                                        <Grid container direction="row" alignItems="center">
                                                            <Icon style={statusIconStyle}>{SUSPENDED_ICON}</Icon>{' '}
                                                            Suspended
                                                        </Grid>
                                                    </MenuItem>
                                                    <MenuItem value="Closed">
                                                        <Grid container direction="row" alignItems="center">
                                                            <Icon style={statusIconStyle}>{CLOSED_ICON}</Icon> Closed
                                                        </Grid>
                                                    </MenuItem>
                                                    <MenuItem value="Banned">
                                                        <Grid container direction="row" alignItems="center">
                                                            <Icon style={statusIconStyle}>{BANNED_ICON}</Icon> Banned
                                                        </Grid>
                                                    </MenuItem>
                                                    <MenuItem value="In Arrears">
                                                        <Grid container direction="row" alignItems="center">
                                                            <Icon style={statusIconStyle}>{IN_ARREARS_ICON}</Icon> In
                                                            Arrears
                                                        </Grid>
                                                    </MenuItem>
                                                </Select>
                                            </FormControl>
                                            <FormControl style={inputStyles}>
                                                <InputLabel htmlFor="collection" variant="outlined">
                                                    Column Filters
                                                </InputLabel>
                                                <Select
                                                    variant="outlined"
                                                    multiple
                                                    value={filteredHeaders}
                                                    onChange={onChangeFilterHeader}
                                                    style={{ width: 200 }}
                                                    input={<OutlinedInput labelWidth={140} />}
                                                >
                                                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                        <Button onClick={onChangeAllFilterHeaders(true)}>All</Button>
                                                        <Button onClick={onChangeAllFilterHeaders(false)}>None</Button>
                                                    </div>
                                                    {enabledHeaders.map(({ name }) => (
                                                        <MenuItem key={name} value={name}>
                                                            {_.startCase(name)}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            <>
                                                <TextField
                                                    data-cy="user-table-search"
                                                    variant="outlined"
                                                    name="search"
                                                    value={search2 || ''}
                                                    onChange={handleChangeAll}
                                                    placeholder="Search"
                                                    style={{
                                                        ...inputStyles,
                                                        width: 220
                                                    }}
                                                />

                                                <Button
                                                    color="primary"
                                                    disabled={loading || loading2}
                                                    variant="contained"
                                                    data-cy="dashboard-search-button"
                                                    type="submit"
                                                    onClick={handleSubmit}
                                                >
                                                    <Icon>search</Icon>
                                                </Button>

                                                {downloadingReports ? (
                                                    <CircularProgress
                                                        style={{
                                                            marginRight: theme.spacing.unit * 2,
                                                            marginLeft: theme.spacing.unit * 2
                                                        }}
                                                        size={32}
                                                    />
                                                ) : (
                                                    <IconButton
                                                        disabled={loading}
                                                        color="primary"
                                                        style={{
                                                            marginRight: theme.spacing.unit,
                                                            marginLeft: theme.spacing.unit
                                                        }}
                                                        onClick={handleDownloadMenuClick}
                                                    >
                                                        <Icon>get_app</Icon>
                                                    </IconButton>
                                                )}
                                            </>

                                            <Menu
                                                id="help-menu"
                                                anchorEl={downloadMenuEl}
                                                open={Boolean(downloadMenuEl)}
                                                onClose={handleDownloadMenuClose}
                                            >
                                                <MenuItem onClick={() => setShowVolumeReportDialog(true)}>
                                                    <ListItemIcon>
                                                        <Icon>delete</Icon>
                                                    </ListItemIcon>
                                                    <ListItemText primary={`Volume Report`} />
                                                </MenuItem>
                                                <MenuItem onClick={() => handleReportDownload('location')}>
                                                    <ListItemIcon>
                                                        <Icon>home</Icon>
                                                    </ListItemIcon>
                                                    <ListItemText primary={'Location Report'} />
                                                </MenuItem>
                                                <MenuItem onClick={() => handleReportDownload('activity')}>
                                                    <ListItemIcon>
                                                        <Icon>perm_contact_calendar</Icon>
                                                    </ListItemIcon>
                                                    <ListItemText primary={'Last Active Report'} />
                                                </MenuItem>
                                                <MenuItem onClick={() => handleReportDownload('aging')}>
                                                    <ListItemIcon>
                                                        <Icon>history</Icon>
                                                    </ListItemIcon>
                                                    <ListItemText primary={'Aging Customer Report'} />
                                                </MenuItem>
                                                <MenuItem onClick={() => handleReportDownload('edit')}>
                                                    <ListItemIcon>
                                                        <Icon>edit</Icon>
                                                    </ListItemIcon>
                                                    <ListItemText primary={'Profile Audit Trail Report'} />
                                                </MenuItem>
                                                <MenuItem onClick={handleDownloadSuspensions}>
                                                    <ListItemIcon>
                                                        <Icon>person_off</Icon>
                                                    </ListItemIcon>
                                                    <ListItemText primary={'Suspension Report'} />
                                                </MenuItem>
                                            </Menu>
                                        </>
                                    )}

                                    {collapseSearch && (
                                        <div style={{ textAlign: 'right' }}>
                                            <IconButton
                                                data-cy="user-table-filter-button"
                                                onClick={handleFiltersOpen()}
                                            >
                                                <Icon>filter_list</Icon>
                                            </IconButton>
                                        </div>
                                    )}
                                </form>
                            </div>
                        </Paper>
                    </div>
                </Grid>
            </Grid>
            <DashBoardTable
                title="Customers"
                loading={loading}
                headers={enabledHeaders}
                filteredHeaders={filteredHeaders}
                length={totalDataEntries}
                page={page}
                rowsPerPage={rowsPerPage}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                refresh={getData}
            >
                {filteredData &&
                    filteredData.map(user => {
                        return renderUserRows(user, filteredHeaders);
                    })}
            </DashBoardTable>
            <DashBoardTable
                title="Deleted Customers"
                loading={loading2}
                headers={disabledHeaders}
                filteredHeaders={filteredHeaders2}
                length={totalDataEntries2}
                page={page2}
                rowsPerPage={rowsPerPage2}
                handleChangePage={handleChangePage2}
                handleChangeRowsPerPage={handleChangeRowsPerPage2}
                refresh={getData2}
            >
                {filteredData2 &&
                    filteredData2.map(user => {
                        return renderUserRows(user, filteredHeaders2);
                    })}
            </DashBoardTable>

            <Dialog open={showUserStatusDialog}>
                <DialogContent>
                    <Typography variant="h6">{getStatusConfirmationText(userStatusActionType)}</Typography>
                    <TextField
                        data-cy="account-status-warning-dialog-reason-input"
                        label={`Reason`}
                        error={!userStatusActionReason}
                        value={userStatusActionReason}
                        onChange={e => setUserStatusActionReason(e.target.value)}
                        variant="outlined"
                        fullWidth
                        autoFocus
                        style={{ marginTop: theme.spacing.unit * 2 }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button color="default" size="small" onClick={() => setShowUserStatusDialog(false)}>
                        {loc('nevermind', lang)}
                    </Button>
                    <Button
                        color="primary"
                        size="small"
                        disabled={!userStatusActionReason}
                        onClick={() => handleUserStatusConfirmation(userStatusActionType)}
                        data-cy="account-status-warning-dialog-confirm"
                    >
                        {loc('yes', lang)}
                    </Button>
                </DialogActions>
            </Dialog>

            <QuickRegisterFormDialog
                open={quickRegisterFormOpen}
                google={google}
                collectorObj={null}
                onClose={() => setQuickRegisterFormOpen(false)}
                onSubmitForm={handleRegisterUser}
                defaultCoordinates={defaultCoordinates}
                services={services}
            />

            <Dialog open={showVolumeReportDialog}>
                <DialogContent>
                    <Typography variant="h6">Select date range for volume report download</Typography>
                    <div style={{ display: 'flex', alingItem: 'center', marginTop: theme.spacing.unit * 2 }}>
                        <DateRangePicker
                            timezone={timezone}
                            startDate={startDate}
                            endDate={endDate}
                            window={dateWindow}
                            disabled={loading}
                            handlePrevious={handleGoBackwards}
                            handleNext={handleGoForwards}
                            handleChangeStartDate={handleChangeStartDate}
                            handleChangeEndDate={handleChangeEndDate}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="default"
                        size="small"
                        onClick={() => {
                            setShowVolumeReportDialog(false);
                            setDownloadMenuEl(false);
                        }}
                    >
                        Close
                    </Button>
                    <Button color="primary" size="small" onClick={() => handleReportDownload('volume')}>
                        Download
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default withTheme()(Users);

function getStatusConfirmationText(action) {
    if (action === 'delete') return 'Are you sure you want to delete this account?';
    if (action === 'ban') return 'Are you sure you want to ban this account?';
    if (action === 'disable') return 'Are you sure you want to close this account?';
    if (action === 'suspend') return 'Are you sure you want to suspend this account?';
    if (action === 'enable') return 'Are you sure you want to repoen this account?';
    if (action === 'unban') return 'Are you sure you want to unban this account?';
    if (action === 'unsuspend') return 'Are you sure you want to unsuspend this account?';

    return '';
}
