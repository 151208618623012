import { useState, useEffect } from 'react';

import _ from 'lodash';

import { Geolocation } from '@capacitor/geolocation';

let timer = null;

function useGPSLocation(history, socket, destination) {
    const [gpsNotWorking, setGpsNotWorking] = useState(true);
    const [currentLocation, setCurrentLocation] = useState(null);

    useEffect(() => {
        if (!_.isNil(socket)) {
            socket.on('socket-ready', async () => {
                getGPSLocation();
                console.log('%cuseGPSLocation: establishing timer', 'color: orange');
                clearInterval(timer);
                timer = setInterval(getGPSLocation, 60 * 1000);
            });
        }
    }, [socket]);

    const getGPSLocation = async (refresh = false) => {
        if (navigator && navigator.geolocation) {
            try {
                let coordinates;

                if (refresh) {
                    //refresh only read cache every 1 seconds
                    coordinates = await Geolocation.getCurrentPosition({
                        maximumAge: 1000,
                        timeout: 5000,
                        enableHighAccuracy: true
                    });
                } else {
                    coordinates = await Geolocation.getCurrentPosition({
                        maximumAge: 60000, //60 * 1000 seconds
                        timeout: 5000,
                        enableHighAccuracy: true
                    });
                }

                console.log('%ccoordinates', 'color: orange', coordinates);
                const location = {
                    lat: coordinates.coords.latitude,
                    lng: coordinates.coords.longitude
                };

                socket.emit('send-location', { location, destination });

                if (
                    _.isNil(currentLocation) ||
                    parseFloat(coordinates.coords.latitude) !== parseFloat(currentLocation.lat) ||
                    parseFloat(coordinates.coords.longitude) !== parseFloat(currentLocation.lng)
                ) {
                    setCurrentLocation(location);
                }
                setGpsNotWorking(false);
            } catch (err) {
                console.error('Geolocation service is unable to obtain GPS location.');
                setGpsNotWorking(true);
            }
        } else {
            console.error('Geolocation service is unavailable.');
            setGpsNotWorking(true);
        }
    };

    const handleAbortGPSWait = () => {
        history.push('/');
    };

    const handleManuallySetLocation = location => {
        console.log('%ccoordinates', 'color: orange', location);

        socket.emit('send-location', {
            location: { lat: _.get(location, 'lat'), lng: _.get(location, 'lng') }, //only send lat and lng in location object
            destination
        });

        if (
            _.isNil(currentLocation) ||
            parseFloat(location.latitude) !== parseFloat(currentLocation.lat) ||
            parseFloat(location.longitude) !== parseFloat(currentLocation.lng)
        ) {
            setCurrentLocation(location);
        }
    };

    useEffect(() => {
        return () => {
            console.log('%cuseGPSLocation: clearing timer', 'color: orange');
            clearInterval(timer);
        };
    }, []);

    return { gpsNotWorking, currentLocation, handleAbortGPSWait, handleManuallySetLocation, getGPSLocation };
}

export default useGPSLocation;
