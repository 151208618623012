import { useContext, useEffect, useState } from 'react';

import HttpContext from 'utils/contexts/HttpContext';

const useTaxGroups = () => {
    const http = useContext(HttpContext);

    const [taxGroups, setTaxGroups] = useState([]);
    const [taxGroupsLoading, setTaxGroupsLoading] = useState(false);

    useEffect(() => {
        (async () => {
            setTaxGroupsLoading(true);
            const res = await http.getJSON('/taxGroups/getEnabledTaxGroups');
            if (res.ok) {
                setTaxGroups(res.data.collectionData);
            }
            setTaxGroupsLoading(false);
        })();
    }, []);

    return { taxGroups, taxGroupsLoading };
};

export default useTaxGroups;
