import React, { useContext } from 'react';

import { colors } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@material-ui/core';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

function GeneralWarningDialog({ theme, open = false, onClose, title, body }) {
    let warningColor = colors.orange[500];

    const { lang } = useContext(LocalizationContext);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{
                style: {
                    backgroundColor: warningColor
                }
            }}
        >
            <DialogContent style={{ padding: theme.spacing.unit * 2 }}>
                <DialogTitle
                    disableTypography
                    style={{ padding: theme.spacing.unit * 2, paddingTop: theme.spacing.unit }}
                >
                    <Typography variant="h6" style={{ color: 'white' }}>
                        <b>{title ? title : 'Warning: Pickups not shown'}</b>
                    </Typography>
                </DialogTitle>
                <DialogContent
                    style={{ padding: theme.spacing.unit * 2, paddingTop: theme.spacing.unit, borderRadius: '5px' }}
                >
                    {body ? (
                        <Typography style={{ color: 'white' }}>{body}</Typography>
                    ) : (
                        <Typography style={{ color: 'white' }}>
                            Trips and pickups further than 1 week out from the current date aren't shown on the map, but
                            may still exist. Check the pickups calendar to see actual counts.
                        </Typography>
                    )}
                </DialogContent>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} style={{ color: 'white' }}>
                    {loc('close', lang)}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default withTheme()(GeneralWarningDialog);
