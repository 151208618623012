import React from 'react';
import { startCase, get } from 'lodash';

import { formatAsCurrency } from 'utils/misc';

import { Paper, Typography, Divider } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';

function StatsWidget({ theme, driverFinancialInfo, operator }) {
    return (
        <Paper style={{ padding: theme.spacing.unit * 2 }}>
            <Typography variant="h6">Current Variables</Typography>
            <Divider />
            <div style={{ overflow: 'auto', display: 'grid', placeItems: 'center' }}>
                <div
                    style={{
                        display: 'flex',
                        textAlign: 'center'
                    }}
                >
                    {Object.keys(driverFinancialInfo).map(key => {
                        let val;
                        if (key === 'pay' || key === 'currentWage') {
                            if (
                                !get(operator, 'permissions.payThroughSystem') ||
                                (key === 'currentWage' && operator.permissions.paymentModel !== 'Wage')
                            )
                                return null;
                            val = formatAsCurrency(driverFinancialInfo[key]);
                        } else if (key === 'commissionRate') {
                            if (operator.permissions.paymentModel !== 'Commission') return null;
                            val = driverFinancialInfo[key] / 100 || 0;
                        } else if (key === 'containersCounted') {
                            val = driverFinancialInfo[key];
                        } else if (key === 'distanceDriven') {
                            val = `${Math.round(driverFinancialInfo[key]).toLocaleString()} km`;
                        } else {
                            val = driverFinancialInfo[key] ? driverFinancialInfo[key].toFixed(2) : 0;
                        }

                        if (!val) return null;

                        return (
                            <div style={{ margin: `0 ${theme.spacing.unit * 2}px` }} key={key}>
                                <Typography variant="body2" color="textSecondary" style={{ whiteSpace: 'nowrap' }}>
                                    {startCase(key)}
                                </Typography>
                                <Typography variant="h6" color="default" style={{ fontWeight: 400 }}>
                                    {val}
                                </Typography>
                            </div>
                        );
                    })}
                </div>
            </div>
        </Paper>
    );
}

export default withTheme()(StatsWidget);
