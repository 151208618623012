import React from 'react';

import _ from 'lodash';

import { Button, TextField, Dialog, DialogContent, DialogActions, Typography } from '@material-ui/core';

import { loc } from 'localizations/localizationHandler';

function RejectBottleDriverDialog({
    showRejectReasonDialog,
    bottleDriveIdsToReject,
    rejectReason,
    setRejectReason,
    theme,
    lang,
    setShowRejectReasonDialog,
    handleReject
}) {
    return (
        <Dialog open={showRejectReasonDialog}>
            <DialogContent>
                <Typography variant="h6">
                    Reason for Rejecting
                    {bottleDriveIdsToReject.length > 1 && ` ${bottleDriveIdsToReject.length} Bottle Drives`}
                </Typography>
                <TextField
                    data-cy="account-status-warning-dialog-reason-input"
                    label={`Reason`}
                    error={!rejectReason}
                    value={rejectReason}
                    onChange={e => setRejectReason(e.target.value)}
                    variant="outlined"
                    fullWidth
                    autoFocus
                    style={{ marginTop: theme.spacing.unit * 2 }}
                    helperText="Customer will see this text."
                />
            </DialogContent>
            <DialogActions>
                <Button color="default" size="small" onClick={() => setShowRejectReasonDialog(false)}>
                    {loc('nevermind', lang)}
                </Button>
                <Button
                    color="primary"
                    size="small"
                    disabled={!rejectReason}
                    onClick={() => handleReject()}
                    data-cy="account-status-warning-dialog-confirm"
                >
                    Reject
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default RejectBottleDriverDialog;
