import React, { useState, useEffect } from 'react';

function useTrailerLabelDialog() {
    const [open, setOpen] = useState(false);
    const [trailerLabel, setTrailerLabel] = useState('');

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleLabelChange = e => {
        setTrailerLabel(e.target.value);
    };

    useEffect(() => {
        setTrailerLabel('');
    }, [open]);

    return {
        open,
        trailerLabel,
        handleOpen,
        handleClose,
        handleLabelChange
    };
}

export default useTrailerLabelDialog;
