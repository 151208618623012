import React, { useState, useContext, useEffect } from 'react';
import _ from 'lodash';

import {
    Dialog,
    DialogContent,
    DialogActions,
    withTheme,
    withMobileDialog,
    DialogTitle,
    Button,
    CircularProgress
} from '@material-ui/core';

import StringListInput from 'components/CustomInputs/StringListInput';

import { _commodity } from 'std';

import CommodityListInput from '../Inputs/CommodityListInput';

import useGetJSON from 'utils/hooks/useGetJSON';

import HttpContext from 'utils/contexts/HttpContext';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import SnackbarContext from 'components/CustomSnackbar/SnackbarContext';
import { loc } from 'localizations/localizationHandler';

function BulkUpdateZoneDialog(props) {
    const { collector, open, allCommodities, commoditiesLoading, onClose, reloadCollectors, theme, fullScreen } = props;

    const http = useContext(HttpContext);
    const { lang } = useContext(LocalizationContext);
    const onSnackbar = useContext(SnackbarContext);
    const [loading, setLoading] = useState(false);

    const [payloadAccepted, setPayloadAccepted] = useState(_.get(collector, 'payloadAccepted', []));
    const [payloadRequired, setPayloadRequired] = useState(_.get(collector, 'payloadRequired', []));

    const handleUpdatePayloadAccepted = values => {
        setPayloadAccepted(values);

        //unselect anything in payloadRequired thats not in values
        let newPayloadRequired = _.cloneDeep(payloadRequired);
        payloadRequired.forEach(commodityId => {
            const inValues = _.some(values, value => value === commodityId);
            if (!inValues) {
                _.remove(newPayloadRequired, id => commodityId === id);
            }
        });
        setPayloadRequired(newPayloadRequired);
    };

    const handleUpdateCollectorCommodities = async () => {
        setLoading(true);
        await http.post(`/collectors/${collector._id}/setCollectorCommodities`, { payloadAccepted, payloadRequired });
        await reloadCollectors();
        setLoading(false);
        onSnackbar('Collector zone commodities updated!');
        onClose();
    };

    useEffect(() => {
        setPayloadAccepted(_.get(collector, 'payloadAccepted', []));
        setPayloadRequired(_.get(collector, 'payloadRequired', []));
    }, [collector, open]);

    return (
        <Dialog open={open} fullScreen={fullScreen} onClose={onClose} maxWidth={'sm'} fullWidth>
            <DialogTitle>{_.get(collector, 'name', '')} commodities</DialogTitle>
            <DialogContent>
                {!commoditiesLoading && (
                    <>
                        <CommodityListInput
                            label="Commodities Accepted"
                            enabledCommodities={allCommodities.map(commodity => commodity._id)}
                            selectedCommodityIds={payloadAccepted}
                            allCommodities={allCommodities}
                            onChange={handleUpdatePayloadAccepted}
                        />
                        <div style={{ marginTop: theme.spacing.unit * 4 }}>
                            <CommodityListInput
                                label="Commodities Required"
                                enabledCommodities={payloadAccepted}
                                selectedCommodityIds={payloadRequired}
                                allCommodities={allCommodities}
                                onChange={values => setPayloadRequired(values)}
                            />
                        </div>
                    </>
                )}
            </DialogContent>

            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button
                    onClick={handleUpdateCollectorCommodities}
                    color="primary"
                    disabled={loading}
                    data-cy="collector-bulk-update-zone-dialog-submit-button"
                >
                    {loading ? <CircularProgress size={24} color="primary" /> : loc('submit', lang)}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default withMobileDialog()(withTheme()(BulkUpdateZoneDialog));
