import React, { Component } from 'react';

import Typography from '@material-ui/core/Typography';
import _ from 'lodash';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import { withTheme } from '@material-ui/core/styles';

import androidBadge from 'icons/play-store.svg';
import iOSBadge from 'icons/app-store.svg';
import { deviceHelper } from '../../utils/misc.js';

function AppStoreLink({ theme }) {
    const handleClick = downloadLink => {
        window.open(downloadLink, '_system');
    };

    if (!_.isNil(window.cordova)) {
        return null;
    }

    if (deviceHelper.MobileWeb()) {
        return (
            <Paper
                elevation={0}
                style={{
                    paddingTop: theme.spacing.unit,
                    paddingBottom: theme.spacing.unit,
                    paddingLeft: theme.spacing.unit * 2,
                    paddingRight: theme.spacing.unit * 2,
                    textAlign: 'center',
                    backgroundColor: theme.palette.background.default,
                    border: `thin solid rgba(0, 0, 0, 0.23)`
                }}
            >
                <Typography variant="body2" color="textSecondary" style={{ fontSize: '0.825rem' }}>
                    Download the app for the best experience!
                </Typography>
                <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: theme.spacing.unit }}>
                    <img
                        style={{ maxWidth: 130, height: 50, display: 'block' }}
                        src={iOSBadge}
                        alt="App Store"
                        onClick={() => handleClick(process.env.REACT_APP_APPSTORE_LINK)}
                    />
                    <img
                        style={{ maxWidth: 130, height: 50, display: 'block' }}
                        src={androidBadge}
                        alt="Google Play"
                        onClick={() => handleClick(process.env.REACT_APP_GOOGLE_PLAY_LINK)}
                    />
                </div>
            </Paper>
        );
    } else {
        return <span />;
    }
}

export default withTheme()(AppStoreLink);
