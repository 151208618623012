import React, { useContext } from 'react';
import _ from 'lodash';

import { _time } from 'std';
import useTable from 'components/Table/useTable';
import DashBoardTable from 'components/Table/DashBoardTable';

import useDateRangePicker from 'components/DateTimePickersTz/hooks/useDateRangePicker';
import DateRangePicker from 'components/DateTimePickersTz/DateRangePicker';
import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery';
import StateSelectDropDown from 'components/DropDownSelects/StateSelectDropDown';
import queryString from 'query-string';

import {
    FormControl,
    MenuItem,
    Select,
    withTheme,
    TableRow,
    TableCell,
    Paper,
    Typography,
    Icon,
    Avatar,
    Grid,
    IconButton,
    Button,
    TextField,
    OutlinedInput,
    InputLabel,
    Menu
} from '@material-ui/core';

import { downloadObjectAsCSV, formatAsCurrency, isAUSRegion } from 'utils/misc';

import { _charity } from 'std';
import { useState } from 'react';
import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';
import moment from 'moment-timezone';

let headers = [
    { name: 'Organization^', key: 'Organization', locKey: null },
    { name: 'Total Donors^', key: 'Total Donors', locKey: null },
    { name: 'Referrals^', key: 'Referrals', locKey: null },
    { name: 'Donations^', key: 'Donations', locKey: null },
    { name: 'Amount Donated^', key: 'Amount Donated', locKey: null }
];

isAUSRegion() && headers.push({ name: 'State^', key: 'State' });

function CharityDonations(props) {
    const { theme } = props;
    const collapseSearch = useMediaQuery(theme.breakpoints.down('sm'));
    const [filtersOpen, setFiltersOpen] = useState(null);
    const { lang } = useContext(LocalizationContext);
    const [exportLoading, setExportLoading] = useState(false);

    const {
        loading,
        getData,
        response,
        page,
        rowsPerPage,
        filteredData,
        search,
        filteredHeaders,
        totalDataEntries,
        handleChange,
        handleSearch,
        handleChangeFilterHeaders,
        handleAllFilterHeaders,
        handleChangePage,
        handleChangeRowsPerPage
    } = useTable({
        url: `/charities/getUsage`,
        key: 'charities',
        headers: headers.map(h => h.name)
    });

    const renderRow = charity => {
        const _id = _charity.getId(charity);
        return (
            <TableRow key={_id + charity.state}>
                {filteredHeaders.includes('Organization^') && <TableCell>{_charity.getName(charity)}</TableCell>}
                {filteredHeaders.includes('Total Donors^') && <TableCell>{_.get(charity, 'totalDonors', 0)}</TableCell>}
                {filteredHeaders.includes('Referrals^') && <TableCell>{_.get(charity, 'referrals', 0)}</TableCell>}

                {filteredHeaders.includes('Donations^') && <TableCell>{_.get(charity, 'donations', 0)}</TableCell>}

                {filteredHeaders.includes('Amount Donated^') && (
                    <TableCell>{formatAsCurrency(_.get(charity, 'amountDonated', 0))}</TableCell>
                )}
                {filteredHeaders.includes('State^') && <TableCell>{_.get(charity, 'state', 'N/A')}</TableCell>}
            </TableRow>
        );
    };

    const renderSearch = children => {
        if (collapseSearch) {
            return (
                <Menu open={Boolean(filtersOpen)} anchorEl={filtersOpen} onClose={handleFiltersOpen(true)}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            padding: theme.spacing.unit * 2
                        }}
                    >
                        {children}
                    </div>
                </Menu>
            );
        } else {
            return (
                <div
                    style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'space-between' }}
                >
                    {/* <div>{customSearch}</div> */}
                    <div style={{ display: 'flex', alignItems: 'center' }}>{children}</div>
                </div>
            );
        }
    };

    const {
        startDate,
        endDate,
        timezone,
        dateWindow,
        handleChangeStartDate,
        handleChangeEndDate,
        handleGoForwards,
        handleGoBackwards,
        handleWindowSelect
    } = useDateRangePicker({
        saveStateInURL: true,
        timezones: [process.env.REACT_APP_REGION_TIMEZONE],
        initStartVal: _time.getStartOfWeek(process.env.REACT_APP_REGION_TIMEZONE),
        initEndVal: _time.getEndOfDay(process.env.REACT_APP_REGION_TIMEZONE)
    });

    const handleSubmit = e => {
        handleSearch(e);
        if (filtersOpen) {
            setFiltersOpen(false);
        }
    };

    const inputStyles = {
        margin: theme.spacing.unit / 2
    };

    const handleFiltersOpen = close => e => {
        if (close || Boolean(filtersOpen)) {
            setFiltersOpen(null);
        } else {
            setFiltersOpen(e.currentTarget);
        }
    };

    const handleDownload = async type => {
        setExportLoading(true);
        const csvRows = [];

        const url = `/charities/getUsage?page_size=${999999999}`;

        const qs = queryString.parse(window.location.search);
        let apiURL = url + `${url.includes('?') ? '&' : '?'}`;
        for (let key in qs) {
            if (qs[key]) {
                const searchKey = key;
                apiURL += `&${searchKey}=${encodeURIComponent(qs[key])}`;
            }
        }

        let res = await props.http.getJSON(apiURL);

        res.data.charities.forEach(charity => {
            const newRow = {
                Organization: _charity.getName(charity),
                TotalDonors: _.get(charity, 'totalDonors', 0),
                Referrals: _.get(charity, 'referrals', 0),
                Donations: _.get(charity, 'donations', 0),
                AmountDonated: formatAsCurrency(_.get(charity, 'amountDonated', 0)),
                State: _.get(charity, 'state', 0)
            };

            csvRows.push(newRow);
        });

        const date = moment().format('YYYY-MM-DD HH:mm');
        const fileName = `donation_usage_${date}`;

        try {
            await downloadObjectAsCSV(csvRows, fileName);
        } catch (err) {
            console.log(err);
        }
        setExportLoading(false);
    };

    const customActions = () => {
        return (
            isAUSRegion() && (
                <Button
                    disabled={loading || exportLoading}
                    color="primary"
                    style={{ marginRight: theme.spacing.unit }}
                    onClick={() => handleDownload()}
                >
                    <Icon>get_app</Icon>
                </Button>
            )
        );
    };

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <div style={{ padding: theme.spacing.unit * 2, paddingBottom: theme.spacing.unit }}>
                        <Paper
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                padding: theme.spacing.unit * 2,
                                height: '100%',
                                flexWrap: 'wrap'
                            }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <DateRangePicker
                                    timezone={timezone}
                                    startDate={startDate}
                                    endDate={endDate}
                                    window={dateWindow}
                                    disabled={loading || exportLoading}
                                    handlePrevious={handleGoBackwards}
                                    handleNext={handleGoForwards}
                                    handleChangeStartDate={handleChangeStartDate}
                                    handleChangeEndDate={handleChangeEndDate}
                                />
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexWrap: 'wrap'
                                }}
                            >
                                <form onSubmit={handleSubmit} style={{ flex: '0 1 100%' }}>
                                    {renderSearch(
                                        <>
                                            <FormControl>
                                                <Select
                                                    value={dateWindow}
                                                    disabled={loading || exportLoading}
                                                    onChange={handleWindowSelect}
                                                    style={{
                                                        maxWidth: '250px',
                                                        margin: theme.spacing.unit
                                                    }}
                                                >
                                                    <MenuItem value="today">Daily</MenuItem>
                                                    <MenuItem value="thisWeek">Weekly</MenuItem>
                                                    <MenuItem value="thisMonth">Monthly</MenuItem>
                                                    <MenuItem value="thisQuarter">Quarterly</MenuItem>
                                                    <MenuItem value="allTime">All Time</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <FormControl style={inputStyles}>
                                                <InputLabel htmlFor="collection" variant="outlined">
                                                    Column Filters
                                                </InputLabel>
                                                <Select
                                                    variant="outlined"
                                                    multiple
                                                    value={filteredHeaders}
                                                    onChange={handleChangeFilterHeaders}
                                                    style={{ width: 250 }}
                                                    input={<OutlinedInput labelWidth={140} />}
                                                >
                                                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                        <Button onClick={handleAllFilterHeaders(true)}>All</Button>
                                                        <Button onClick={handleAllFilterHeaders(false)}>None</Button>
                                                    </div>
                                                    {headers.map(({ name, locKey }) => (
                                                        <MenuItem key={name} value={name}>
                                                            {!_.isNil(locKey) ? loc(locKey, lang) : _.startCase(name)}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>

                                            <TextField
                                                variant="outlined"
                                                name="search"
                                                value={search || ''}
                                                onChange={handleChange}
                                                placeholder="Search"
                                                style={{
                                                    ...inputStyles,
                                                    width: 250
                                                }}
                                            />

                                            {isAUSRegion() && (
                                                <StateSelectDropDown loading={loading || exportLoading} />
                                            )}

                                            {
                                                <Button
                                                    color="primary"
                                                    disabled={loading || exportLoading}
                                                    variant="contained"
                                                    data-cy="dashboard-search-button"
                                                    type="submit"
                                                    onClick={handleSubmit}
                                                >
                                                    <Icon>search</Icon>
                                                </Button>
                                            }
                                        </>
                                    )}

                                    {collapseSearch && (
                                        <div style={{ textAlign: 'right' }}>
                                            <IconButton onClick={handleFiltersOpen()}>
                                                <Icon>filter_list</Icon>
                                            </IconButton>
                                        </div>
                                    )}
                                </form>
                            </div>
                        </Paper>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div
                        style={{
                            padding: theme.spacing.unit,
                            paddingLeft: theme.spacing.unit * 2,
                            paddingRight: theme.spacing.unit * 2
                        }}
                    >
                        <Paper
                            style={{
                                display: 'flex',
                                justifyContent: 'space-evenly',
                                padding: theme.spacing.unit * 2,
                                height: '100%',
                                flexWrap: 'wrap'
                            }}
                        >
                            <span style={{ margin: theme.spacing.unit, display: 'flex', alignItems: 'center' }}>
                                <Avatar
                                    style={{
                                        backgroundColor: theme.palette.primary.main,
                                        marginRight: theme.spacing.unit
                                    }}
                                >
                                    <Icon>attach_money</Icon>
                                </Avatar>
                                <Typography style={{ marginRight: theme.spacing.unit * 2, display: 'inline-block' }}>
                                    Amount Donated: {formatAsCurrency(_.get(response, 'totalAmountDonated', 0))}
                                </Typography>
                            </span>
                            <span style={{ margin: theme.spacing.unit, display: 'flex', alignItems: 'center' }}>
                                <Avatar
                                    style={{
                                        backgroundColor: theme.palette.primary.main,
                                        marginRight: theme.spacing.unit
                                    }}
                                >
                                    <Icon>favorite</Icon>
                                </Avatar>
                                <Typography style={{ marginRight: theme.spacing.unit * 2, display: 'inline-block' }}>
                                    Donations: {_.get(response, 'totalDonations', 0)}
                                </Typography>
                            </span>
                            <span style={{ margin: theme.spacing.unit, display: 'flex', alignItems: 'center' }}>
                                <Avatar
                                    style={{
                                        backgroundColor: theme.palette.primary.main,
                                        marginRight: theme.spacing.unit
                                    }}
                                >
                                    <Icon>share</Icon>
                                </Avatar>
                                <Typography style={{ marginRight: theme.spacing.unit * 2, display: 'inline-block' }}>
                                    Referrals: {_.get(response, 'totalReferrals', 0)}
                                </Typography>
                            </span>
                            <span style={{ margin: theme.spacing.unit, display: 'flex', alignItems: 'center' }}>
                                <Avatar
                                    style={{
                                        backgroundColor: theme.palette.primary.main,
                                        marginRight: theme.spacing.unit
                                    }}
                                >
                                    <Icon>person</Icon>
                                </Avatar>
                                <Typography style={{ marginRight: theme.spacing.unit * 2, display: 'inline-block' }}>
                                    Total Donors: {_.get(response, 'totalDonors', 0)}
                                </Typography>
                            </span>
                        </Paper>
                    </div>
                </Grid>
            </Grid>
            <DashBoardTable
                title="Donations"
                loading={loading || exportLoading}
                headers={headers}
                filteredHeaders={filteredHeaders}
                length={totalDataEntries}
                page={page}
                rowsPerPage={rowsPerPage}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                refresh={getData}
                customAction={customActions()}
            >
                {filteredData &&
                    filteredData.map(user => {
                        return renderRow(user, filteredHeaders);
                    })}
            </DashBoardTable>
        </>
    );
}

export default withTheme()(CharityDonations);
