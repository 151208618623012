import { useState, useContext, useEffect } from 'react';

import _ from 'lodash';
import { _time } from 'std';
import moment from 'moment-timezone';

import HttpContext from 'utils/contexts/HttpContext';

import useDateRangePicker from 'components/DateTimePickersTz/hooks/useDateRangePicker';

import { deviceHelper } from 'utils/misc';
import OperatorContext from 'utils/contexts/OperatorContext';

function usePickupCalendar({ transporter, processor, history }) {
    const timezone = _.get(transporter, 'timezone', process.env.REACT_APP_REGION_TIMEZONE);
    const operator = useContext(OperatorContext);
    const http = useContext(HttpContext);
    const initStartDate = _time.getStartOfMonth(timezone);
    const initEndDate = moment(initStartDate)
        .tz(timezone)
        .endOf('month');

    const {
        startDate,
        endDate,
        timezone: datePickerTz,
        dateWindow,
        handleChangeStartDate,
        handleChangeEndDate,
        handleGoForwards,
        handleGoBackwards,
        handleWindowSelect
    } = useDateRangePicker({
        saveStateInURL: true,
        timezones: [timezone],
        initStartVal: initStartDate,
        initEndVal: initEndDate,
        initDateWindow: 'thisMonth',
        allowFutureDates: true
    });

    const dates = _time.generateDatesBetweenTwoDates(
        timezone,
        moment(startDate)
            .tz(timezone)
            .startOf('w'),
        moment(endDate)
            .tz(timezone)
            .endOf('w')
    );

    const [loading, setLoading] = useState(false);
    const [datesWithPickups, setDatesWithPickups] = useState({});

    const getPickups = async () => {
        setLoading(true);

        const res = await http.getJSON(
            `/pickups/calendar?transporter_id=${_.get(transporter, '_id', 'all')}&processor_id=${_.get(
                processor,
                '_id',
                'all'
            )}&startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}&timezone=${timezone}`
        );

        if (res.ok) {
            const pickups = _.get(res, 'data.pickups', []);
            setDatesWithPickups(pickups); // they are already grouped by day
        }

        setTimeout(setLoading(false), 3000);
    };

    const handleOpenDateOnPickupsScreen = date => {
        const url = `/operators/${operator._id}/pickups-manager?collector=${_.get(
            transporter,
            '_id',
            operator.collector._id
        )}&date=${moment(date).toISOString()}&timezone=${timezone}`;

        if (deviceHelper.isNativeApp()) {
            history.push(url);
        } else {
            window.open(url);
        }
    };

    return {
        loading,
        datesWithPickups,
        dates,
        timezone,
        startDate,
        endDate,
        dateWindow,
        handleOpenDateOnPickupsScreen,
        getPickups,
        handleGoForwards,
        handleGoBackwards,
        handleChangeStartDate,
        handleChangeEndDate,
        handleWindowSelect
    };
}

export default usePickupCalendar;
