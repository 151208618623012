export const defaultLight = [
    { featureType: 'all', elementType: 'labels.icon', stylers: [{ visibility: 'off' }] },
    { featureType: 'administrative', elementType: 'geometry', stylers: [{ color: '#dddfe3' }] },
    { featureType: 'administrative', elementType: 'labels.text.fill', stylers: [{ color: '#5986a6' }] },
    { featureType: 'administrative.neighborhood', elementType: 'labels.text', stylers: [{ lightness: 25 }] },
    { featureType: 'landscape.man_made', elementType: 'geometry', stylers: [{ color: '#dddfe3' }] },
    { featureType: 'landscape.natural', elementType: 'geometry', stylers: [{ color: '#dddfe3' }] },
    { featureType: 'landscape.natural', elementType: 'labels.text.fill', stylers: [{ color: '#5986a6' }] },
    { featureType: 'landscape.natural.landcover', stylers: [{ visibility: 'off' }] },
    { featureType: 'landscape.natural.terrain', stylers: [{ visibility: 'off' }] },
    { featureType: 'water', elementType: 'geometry', stylers: [{ color: '#a3c7df' }] },
    { featureType: 'water', elementType: 'labels.text.fill', stylers: [{ color: '#4d8ab3' }] },
    { featureType: 'road', elementType: 'labels.text.fill', stylers: [{ color: '#41626b' }] },
    { featureType: 'road.local', elementType: 'geometry.fill', stylers: [{ color: '#ffffff' }] },
    { featureType: 'road.highway', elementType: 'geometry.fill', stylers: [{ color: '#c1d1d6' }] },
    { featureType: 'road.highway', elementType: 'geometry.stroke', stylers: [{ color: '#a6b5bb' }] },
    { featureType: 'road.arterial', elementType: 'geometry.fill', stylers: [{ color: '#ffffff' }] },
    { featureType: 'transit', stylers: [{ visibility: 'off' }] },
    { featureType: 'poi', elementType: 'geometry.fill', stylers: [{ color: '#dddfe3' }] },
    { featureType: 'poi.attraction', stylers: [{ visibility: 'off' }] },
    { featureType: 'poi.business', stylers: [{ visibility: 'off' }] },
    { featureType: 'poi.government', stylers: [{ visibility: 'off' }] },
    { featureType: 'poi.medical', stylers: [{ visibility: 'off' }] },
    { featureType: 'poi.place_of_worship', stylers: [{ visibility: 'off' }] },
    { featureType: 'poi.school', stylers: [{ visibility: 'off' }] },
    { featureType: 'poi.sports_complex', stylers: [{ visibility: 'off' }] },
    { featureType: 'poi.park', elementType: 'geometry', stylers: [{ color: '#a6d9bf' }] },
    { featureType: 'poi.park', elementType: 'labels.text.fill', stylers: [{ color: '#64b48c' }] }
];

export const ultraLight = [
    {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [
            {
                color: '#e9e9e9'
            },
            {
                lightness: 17
            }
        ]
    },
    {
        featureType: 'landscape',
        elementType: 'geometry',
        stylers: [
            {
                color: '#f5f5f5'
            },
            {
                lightness: 20
            }
        ]
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#ffffff'
            },
            {
                lightness: 17
            }
        ]
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry.stroke',
        stylers: [
            {
                color: '#ffffff'
            },
            {
                lightness: 29
            },
            {
                weight: 0.2
            }
        ]
    },
    {
        featureType: 'road.arterial',
        elementType: 'geometry',
        stylers: [
            {
                color: '#ffffff'
            },
            {
                lightness: 18
            }
        ]
    },
    {
        featureType: 'road.local',
        elementType: 'geometry',
        stylers: [
            {
                color: '#ffffff'
            },
            {
                lightness: 16
            }
        ]
    },
    {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [
            {
                color: '#f5f5f5'
            },
            {
                lightness: 21
            }
        ]
    },
    {
        featureType: 'poi.park',
        elementType: 'geometry',
        stylers: [
            {
                color: '#dedede'
            },
            {
                lightness: 21
            }
        ]
    },
    {
        elementType: 'labels.text.stroke',
        stylers: [
            {
                visibility: 'on'
            },
            {
                color: '#ffffff'
            },
            {
                lightness: 16
            }
        ]
    },
    {
        elementType: 'labels.text.fill',
        stylers: [
            {
                saturation: 36
            },
            {
                color: '#333333'
            },
            {
                lightness: 40
            }
        ]
    },
    {
        elementType: 'labels.icon',
        stylers: [
            {
                visibility: 'off'
            }
        ]
    },
    {
        featureType: 'transit',
        elementType: 'geometry',
        stylers: [
            {
                color: '#f2f2f2'
            },
            {
                lightness: 19
            }
        ]
    },
    {
        featureType: 'administrative',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#fefefe'
            },
            {
                lightness: 20
            }
        ]
    },
    {
        featureType: 'administrative',
        elementType: 'geometry.stroke',
        stylers: [
            {
                color: '#fefefe'
            },
            {
                lightness: 17
            },
            {
                weight: 1.2
            }
        ]
    }
];

export const dark = [
    {
        featureType: 'all',
        elementType: 'labels.text.fill',
        stylers: [
            {
                saturation: 36
            },
            {
                color: '#707070'
            },
            {
                lightness: 40
            }
        ]
    },
    {
        featureType: 'all',
        elementType: 'labels.text.stroke',
        stylers: [
            {
                visibility: 'on'
            },
            {
                color: '#000000'
            },
            {
                lightness: 16
            }
        ]
    },
    {
        featureType: 'all',
        elementType: 'labels.icon',
        stylers: [
            {
                visibility: 'off'
            }
        ]
    },
    {
        featureType: 'administrative',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#000000'
            },
            {
                lightness: 20
            }
        ]
    },
    {
        featureType: 'administrative',
        elementType: 'geometry.stroke',
        stylers: [
            {
                color: '#000000'
            },
            {
                lightness: 17
            },
            {
                weight: 1.2
            }
        ]
    },
    {
        featureType: 'landscape',
        elementType: 'geometry',
        stylers: [
            {
                color: '#424242'
            }
        ]
    },
    {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [
            {
                color: '#000000'
            },
            {
                lightness: 21
            }
        ]
    },
    {
        featureType: 'poi',
        elementType: 'labels',
        stylers: [
            {
                visibility: 'on'
            }
        ]
    },
    {
        featureType: 'poi',
        elementType: 'labels.icon',
        stylers: [
            {
                visibility: 'off'
            }
        ]
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry.fill',
        stylers: [
            {
                lightness: 17
            },
            {
                color: '#484848'
            }
        ]
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry.stroke',
        stylers: [
            {
                lightness: 29
            },
            {
                weight: 0.2
            },
            {
                color: '#ff0000'
            },
            {
                visibility: 'off'
            }
        ]
    },
    {
        featureType: 'road.arterial',
        elementType: 'geometry',
        stylers: [
            {
                color: '#000000'
            },
            {
                lightness: 18
            }
        ]
    },
    {
        featureType: 'road.local',
        elementType: 'geometry',
        stylers: [
            {
                color: '#000000'
            },
            {
                lightness: 16
            }
        ]
    },
    {
        featureType: 'transit',
        elementType: 'geometry',
        stylers: [
            {
                color: '#000000'
            },
            {
                lightness: 19
            }
        ]
    },
    {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [
            {
                color: '#000000'
            },
            {
                lightness: 17
            }
        ]
    }
];

export const mostlyGrayscale = [
    {
        featureType: 'administrative',
        elementType: 'all',
        stylers: [
            {
                visibility: 'on'
            },
            {
                lightness: 33
            }
        ]
    },
    {
        featureType: 'administrative',
        elementType: 'labels',
        stylers: [
            {
                saturation: '-100'
            }
        ]
    },
    {
        featureType: 'administrative',
        elementType: 'labels.text',
        stylers: [
            {
                gamma: '0.75'
            }
        ]
    },
    {
        featureType: 'administrative.neighborhood',
        elementType: 'labels.text.fill',
        stylers: [
            {
                lightness: '-37'
            }
        ]
    },
    {
        featureType: 'landscape',
        elementType: 'geometry',
        stylers: [
            {
                color: '#f9f9f9'
            }
        ]
    },
    {
        featureType: 'landscape.man_made',
        elementType: 'geometry',
        stylers: [
            {
                saturation: '-100'
            },
            {
                lightness: '40'
            },
            {
                visibility: 'off'
            }
        ]
    },
    {
        featureType: 'landscape.natural',
        elementType: 'labels.text.fill',
        stylers: [
            {
                saturation: '-100'
            },
            {
                lightness: '-37'
            }
        ]
    },
    {
        featureType: 'landscape.natural',
        elementType: 'labels.text.stroke',
        stylers: [
            {
                saturation: '-100'
            },
            {
                lightness: '100'
            },
            {
                weight: '2'
            }
        ]
    },
    {
        featureType: 'landscape.natural',
        elementType: 'labels.icon',
        stylers: [
            {
                saturation: '-100'
            }
        ]
    },
    {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [
            {
                saturation: '-100'
            },
            {
                lightness: '80'
            }
        ]
    },
    {
        featureType: 'poi',
        elementType: 'labels',
        stylers: [
            {
                saturation: '-100'
            },
            {
                lightness: '0'
            }
        ]
    },
    {
        featureType: 'poi.attraction',
        elementType: 'geometry',
        stylers: [
            {
                lightness: '-4'
            },
            {
                saturation: '-100'
            }
        ]
    },
    {
        featureType: 'poi.park',
        elementType: 'geometry',
        stylers: [
            {
                color: '#c5dac6'
            },
            {
                visibility: 'on'
            },
            {
                saturation: '-95'
            },
            {
                lightness: '62'
            }
        ]
    },
    {
        featureType: 'poi.park',
        elementType: 'labels',
        stylers: [
            {
                visibility: 'on'
            },
            {
                lightness: 20
            }
        ]
    },
    {
        featureType: 'road',
        elementType: 'all',
        stylers: [
            {
                lightness: 20
            }
        ]
    },
    {
        featureType: 'road',
        elementType: 'labels',
        stylers: [
            {
                saturation: '-100'
            },
            {
                gamma: '1.00'
            }
        ]
    },
    {
        featureType: 'road',
        elementType: 'labels.text',
        stylers: [
            {
                gamma: '0.50'
            }
        ]
    },
    {
        featureType: 'road',
        elementType: 'labels.icon',
        stylers: [
            {
                saturation: '-100'
            },
            {
                gamma: '0.50'
            }
        ]
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry',
        stylers: [
            {
                color: '#c5c6c6'
            },
            {
                saturation: '-100'
            }
        ]
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry.stroke',
        stylers: [
            {
                lightness: '-13'
            }
        ]
    },
    {
        featureType: 'road.highway',
        elementType: 'labels.icon',
        stylers: [
            {
                lightness: '0'
            },
            {
                gamma: '1.09'
            }
        ]
    },
    {
        featureType: 'road.arterial',
        elementType: 'geometry',
        stylers: [
            {
                color: '#e4d7c6'
            },
            {
                saturation: '-100'
            },
            {
                lightness: '47'
            }
        ]
    },
    {
        featureType: 'road.arterial',
        elementType: 'geometry.stroke',
        stylers: [
            {
                lightness: '-12'
            }
        ]
    },
    {
        featureType: 'road.arterial',
        elementType: 'labels.icon',
        stylers: [
            {
                saturation: '-100'
            }
        ]
    },
    {
        featureType: 'road.local',
        elementType: 'geometry',
        stylers: [
            {
                color: '#fbfaf7'
            },
            {
                lightness: '77'
            }
        ]
    },
    {
        featureType: 'road.local',
        elementType: 'geometry.fill',
        stylers: [
            {
                lightness: '-5'
            },
            {
                saturation: '-100'
            }
        ]
    },
    {
        featureType: 'road.local',
        elementType: 'geometry.stroke',
        stylers: [
            {
                saturation: '-100'
            },
            {
                lightness: '-15'
            }
        ]
    },
    {
        featureType: 'transit.station.airport',
        elementType: 'geometry',
        stylers: [
            {
                lightness: '47'
            },
            {
                saturation: '-100'
            }
        ]
    },
    {
        featureType: 'water',
        elementType: 'all',
        stylers: [
            {
                visibility: 'on'
            },
            {
                color: '#acbcc9'
            }
        ]
    },
    {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [
            {
                saturation: '53'
            }
        ]
    },
    {
        featureType: 'water',
        elementType: 'labels.text.fill',
        stylers: [
            {
                lightness: '-42'
            },
            {
                saturation: '17'
            }
        ]
    },
    {
        featureType: 'water',
        elementType: 'labels.text.stroke',
        stylers: [
            {
                lightness: '61'
            }
        ]
    }
];

export const blueLight = [
    {
        featureType: 'all',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#7c93a3'
            },
            {
                lightness: '-10'
            }
        ]
    },
    {
        featureType: 'administrative.country',
        elementType: 'geometry',
        stylers: [
            {
                visibility: 'on'
            }
        ]
    },
    {
        featureType: 'administrative.country',
        elementType: 'geometry.stroke',
        stylers: [
            {
                color: '#c2d1d6'
            }
        ]
    },
    {
        featureType: 'landscape',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#dde3e3'
            }
        ]
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#c2d1d6'
            }
        ]
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry.stroke',
        stylers: [
            {
                color: '#a9b4b8'
            },
            {
                lightness: '0'
            }
        ]
    },
    {
        featureType: 'water',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#a3c7df'
            }
        ]
    }
];

export const blueDark = [
    {
        featureType: 'all',
        elementType: 'geometry',
        stylers: [
            {
                color: '#293037'
            }
        ]
    },
    {
        featureType: 'all',
        elementType: 'labels.text.fill',
        stylers: [
            {
                gamma: 0.01
            },
            {
                lightness: 20
            },
            {
                color: '#949aa6'
            }
        ]
    },
    {
        featureType: 'all',
        elementType: 'labels.text.stroke',
        stylers: [
            {
                saturation: -31
            },
            {
                lightness: -33
            },
            {
                weight: 2
            },
            {
                gamma: '0.00'
            },
            {
                visibility: 'off'
            }
        ]
    },
    {
        featureType: 'all',
        elementType: 'labels.icon',
        stylers: [
            {
                visibility: 'off'
            }
        ]
    },
    {
        featureType: 'administrative.country',
        elementType: 'all',
        stylers: [
            {
                visibility: 'off'
            }
        ]
    },
    {
        featureType: 'administrative.province',
        elementType: 'all',
        stylers: [
            {
                visibility: 'off'
            }
        ]
    },
    {
        featureType: 'administrative.locality',
        elementType: 'all',
        stylers: [
            {
                visibility: 'simplified'
            }
        ]
    },
    {
        featureType: 'administrative.locality',
        elementType: 'labels.icon',
        stylers: [
            {
                visibility: 'off'
            }
        ]
    },
    {
        featureType: 'administrative.neighborhood',
        elementType: 'all',
        stylers: [
            {
                visibility: 'off'
            }
        ]
    },
    {
        featureType: 'administrative.land_parcel',
        elementType: 'all',
        stylers: [
            {
                visibility: 'off'
            }
        ]
    },
    {
        featureType: 'landscape',
        elementType: 'geometry',
        stylers: [
            {
                lightness: 30
            },
            {
                saturation: 30
            },
            {
                color: '#344150'
            },
            {
                visibility: 'on'
            }
        ]
    },
    {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [
            {
                saturation: '0'
            },
            {
                lightness: '0'
            },
            {
                gamma: '0.30'
            },
            {
                weight: '0.01'
            },
            {
                visibility: 'off'
            }
        ]
    },
    {
        featureType: 'poi.park',
        elementType: 'geometry',
        stylers: [
            {
                lightness: '100'
            },
            {
                saturation: '-20'
            },
            {
                visibility: 'simplified'
            },
            {
                color: '#344150'
            },
            {
                gamma: '0.92'
            }
        ]
    },
    {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [
            {
                lightness: 10
            },
            {
                saturation: -30
            },
            {
                color: '#28323f'
            }
        ]
    },
    {
        featureType: 'road',
        elementType: 'geometry.stroke',
        stylers: [
            {
                saturation: '-100'
            },
            {
                lightness: '-100'
            },
            {
                gamma: '0.00'
            },
            {
                color: '#0076ff'
            },
            {
                visibility: 'off'
            }
        ]
    },
    {
        featureType: 'road',
        elementType: 'labels',
        stylers: [
            {
                visibility: 'on'
            }
        ]
    },
    {
        featureType: 'road',
        elementType: 'labels.text',
        stylers: [
            {
                visibility: 'on'
            },
            {
                color: '#575e6b'
            }
        ]
    },
    {
        featureType: 'road',
        elementType: 'labels.text.stroke',
        stylers: [
            {
                visibility: 'off'
            }
        ]
    },
    {
        featureType: 'road',
        elementType: 'labels.icon',
        stylers: [
            {
                visibility: 'off'
            }
        ]
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#232c37'
            },
            {
                visibility: 'on'
            }
        ]
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry.stroke',
        stylers: [
            {
                visibility: 'off'
            }
        ]
    },
    {
        featureType: 'transit',
        elementType: 'all',
        stylers: [
            {
                visibility: 'off'
            }
        ]
    },
    {
        featureType: 'transit',
        elementType: 'geometry',
        stylers: [
            {
                visibility: 'simplified'
            },
            {
                color: '#222935'
            }
        ]
    },
    {
        featureType: 'transit.station.airport',
        elementType: 'all',
        stylers: [
            {
                visibility: 'off'
            }
        ]
    },
    {
        featureType: 'water',
        elementType: 'all',
        stylers: [
            {
                lightness: -20
            },
            {
                color: '#212a35'
            }
        ]
    }
];
