import { useState, useEffect, useContext } from 'react';

import _ from 'lodash';

import HttpContext from 'utils/contexts/HttpContext';
import usePrevious from 'utils/hooks/usePrevious';

function useGetJSON(url, dataPath, defaultData = {}, silenceNetworkErrors = false) {
    const http = useContext(HttpContext);

    const prevUrl = usePrevious(url);
    const prevDataPath = usePrevious(dataPath);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [data, setData] = useState(defaultData); // NB: empty object allows to decompose data at the caller

    useEffect(() => {
        async function fetchData() {
            const res = await http.getJSON(url, false, silenceNetworkErrors);
            if (res.ok) {
                _.isNil(dataPath) ? setData(res.data) : setData(res.data[dataPath]);
            } else {
                setError(true);
            }

            setLoading(false); // TODO: put in reducer to update data and loading in one operation
        }

        //only reload data if the url or datapath changed
        if (url !== prevUrl || dataPath !== prevDataPath) {
            setLoading(true);
            fetchData();
        }
    }, [http, url, dataPath, silenceNetworkErrors, prevUrl, prevDataPath]);

    const refresh = async () => {
        async function fetchData() {
            const res = await http.getJSON(url, false, silenceNetworkErrors);
            if (res.ok) {
                _.isNil(dataPath) ? setData(res.data) : setData(res.data[dataPath]);
            } else {
                setError(true);
            }

            setLoading(false);
        }

        setLoading(true);
        fetchData();
    };

    return { loading, error, data, refresh };
}

export default useGetJSON;
