import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import _ from 'lodash';

import ContainerWithLogo from 'components/ContainerWithLogo/ContainerWithLogo';

import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { withTheme } from '@material-ui/core/styles';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from '../../localizations/localizationHandler';

class ChangeEmail extends Component {
    static contextType = LocalizationContext;
    state = {
        success: undefined,
        message: undefined
    };

    async componentDidMount() {
        const { lang } = this.context;
        let _id = this.props.match.params._id;
        let email = this.props.match.params.email;
        let token = this.props.match.params.token;
        let res = await this.props.http.postJSON('/updateEmail', { _id, email, token });
        if (res.ok) {
            this.setState({ success: res.data.success, message: loc(res.data.message, lang) });
        }
    }

    // handleResendChangeEmailLink = async () => {
    //     let email = this.props.match.params.email;
    //     let _id = this.props.match.params._id;
    //     let res = await this.props.http.post('/sendUpdateEmailLink', { email, _id });
    //     if (res.ok) {
    //         this.setState({
    //             message: loc('verificationLinkSent', this.context.lang),
    //             success: true
    //         });
    //     } else {
    //         this.setState({ message: loc('verificationLinkFailed', this.context.lang), success: false });
    //     }
    // };

    render() {
        const { theme } = this.props;
        const { success, message } = this.state;
        const { lang } = this.context;

        if (_.isNil(success)) {
            return (
                <ContainerWithLogo brandLogoOverride={_.get(this.props, 'brandLogoOverride')}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <div style={{ textAlign: 'center' }}>
                            <CircularProgress />
                            <Typography variant="subtitle1" style={{ marginTop: theme.spacing.unit * 2 }}>
                                {loc('verifying', lang)}...
                            </Typography>
                        </div>
                    </div>
                </ContainerWithLogo>
            );
        } else {
            return (
                <ContainerWithLogo brandLogoOverride={_.get(this.props, 'brandLogoOverride')}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <div style={{ textAlign: 'center' }}>
                            <Typography variant="subtitle1" color={success ? 'default' : 'error'}>
                                {message}
                            </Typography>
                            {/* {!success && (
                                <>
                                    <Button
                                        variant="contained"
                                        style={{ marginTop: theme.spacing.unit * 4 }}
                                        onClick={this.handleResendChangeEmailLink}
                                    >
                                        {loc('resendVerification', lang)}
                                    </Button>
                                    <br />
                                </>
                            )} */}
                            <Button
                                variant="contained"
                                component={Link}
                                to="/login"
                                color="primary"
                                style={{ marginTop: theme.spacing.unit * 4 }}
                            >
                                {loc('goBack', lang)}
                            </Button>
                        </div>
                    </div>
                </ContainerWithLogo>
            );
        }
    }
}

export default withTheme()(ChangeEmail);
