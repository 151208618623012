import React from 'react';

import { Typography, Paper, withTheme } from '@material-ui/core';

import * as terms from 'localizations/terms';
import { loc } from 'localizations/localizationHandler';

function BlogDisplay({ charity, referralLinkComponent, lang }) {
    const linkStyle = { overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' };

    return (
        <Typography variant="body1">
            {loc('growBlog1', lang)}
            <ul>
                <li>{loc('growBlog2', lang, { brand: process.env.REACT_APP_BRAND_NAME, charity: charity.name })}</li>
                <li>{loc('growBlog3', lang, { brand: process.env.REACT_APP_BRAND_NAME, charity: charity.name })}</li>
                <li>{loc('growBlog4', lang, { brand: process.env.REACT_APP_BRAND_NAME, charity: charity.name })}</li>
                <li>{loc('growBlog5', lang, { brand: process.env.REACT_APP_BRAND_NAME, charity: charity.name })}</li>
            </ul>
            {loc('growBlog6', lang)}
            <div style={linkStyle}>{referralLinkComponent}</div>
        </Typography>
    );
}

export default withTheme()(BlogDisplay);
