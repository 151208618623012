import React, { useState } from 'react';
import _ from 'lodash';

import {
    Checkbox,
    Icon,
    IconButton,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TablePagination,
    TableRow,
    Paper,
    Toolbar,
    Typography,
    FormControlLabel,
    TableSortLabel
} from '@material-ui/core';

import { getQuickDropName } from 'utils/misc';

import { red } from '@material-ui/core/colors';

import { withTheme } from '@material-ui/core/styles';

function BaseTable(props) {
    const {
        data,
        headers,
        tableTitle,
        controlLabel,
        controlChecked,
        controlChanged,
        handleClothingDialog,
        handleViewCollector,
        defaultPage,
        defaultOrder,
        defaultOrderBy,
        defaultRowsPerPage,
        cellStyle,
        formatAsCurrency,
        loading
    } = props;

    const [order, setOrder] = useState(defaultOrder || 'asc');
    const [orderBy, setOrderBy] = useState(defaultOrderBy || '');
    const [page, setPage] = useState(defaultPage);
    const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const createSortHandler = property => event => {
        handleRequestSort(event, property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <>
            <Paper>
                <Toolbar style={{ dispaylay: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="h6">{tableTitle}</Typography>
                    <FormControlLabel
                        control={<Checkbox disabled={loading} checked={controlChecked} onChange={controlChanged} />}
                        label={controlLabel}
                        labelPlacement="start"
                    />
                </Toolbar>
                <div style={{ overflow: 'auto' }}>
                    <Table data-cy="operator-dashboard-collectors-table">
                        <TableHead>
                            <TableRow>
                                {headers.map((header, i) => (
                                    <TableCell
                                        style={cellStyle}
                                        align={i < 3 ? 'left' : 'right'}
                                        key={header.id}
                                        sortDirection={orderBy === header.id ? order : false}
                                    >
                                        {_.get(header, 'sortOption', true) ? (
                                            <TableSortLabel
                                                active={orderBy === header.id}
                                                direction={orderBy === header.id ? order : 'asc'}
                                                onClick={createSortHandler(header.id)}
                                            >
                                                {header.label}
                                            </TableSortLabel>
                                        ) : (
                                            header.label
                                        )}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data &&
                                stableSort(data, getComparator(order, orderBy))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map(
                                        ({
                                            _id,
                                            name,
                                            location,
                                            collectorManager,
                                            numberOfUncountedBulks,
                                            numberOfBulksUnderThreshold,
                                            numberOfPickups,
                                            numberOfInhouseCompleted,
                                            numberOfAbortedPickups,
                                            numberOfOutstandingClothingBags,
                                            amountOfEWaste,
                                            revenue,
                                            ratings,
                                            totalContainers,
                                            totalInhouseContainers,
                                            totalCompleted,
                                            totalInhouseCustomers,
                                            totalCustomers
                                        }) => {
                                            return (
                                                <TableRow hover key={_id}>
                                                    <TableCell style={{ whiteSpace: 'nowrap' }}>
                                                        <div style={{ display: 'flex' }}>
                                                            <IconButton
                                                                onClick={handleClothingDialog(_id)}
                                                                disabled={numberOfOutstandingClothingBags === 0}
                                                            >
                                                                <Icon>local_mall</Icon>
                                                            </IconButton>
                                                            <IconButton
                                                                disabled={_.isNil(collectorManager)}
                                                                title="The first registered manager will be shown"
                                                                onClick={handleViewCollector(collectorManager)}
                                                            >
                                                                <Icon>visibility</Icon>
                                                            </IconButton>
                                                        </div>
                                                    </TableCell>
                                                    <TableCell style={cellStyle}>{name}</TableCell>
                                                    <TableCell style={cellStyle}>{location.description}</TableCell>
                                                    <TableCell align="right">{numberOfPickups}</TableCell>
                                                    <TableCell
                                                        align="right"
                                                        title={`(Total | ${getQuickDropName('en')}/Adjustment)`}
                                                        style={cellStyle}
                                                    >
                                                        {totalCompleted} | {numberOfInhouseCompleted}
                                                    </TableCell>
                                                    <TableCell align="right">{numberOfUncountedBulks}</TableCell>
                                                    <TableCell align="right">{numberOfBulksUnderThreshold}</TableCell>
                                                    <TableCell
                                                        align="right"
                                                        style={{
                                                            color: numberOfPickups > 0 ? red[500] : null
                                                        }}
                                                    >
                                                        {numberOfAbortedPickups}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {numberOfOutstandingClothingBags}
                                                    </TableCell>
                                                    <TableCell align="right">{amountOfEWaste}</TableCell>
                                                    <TableCell align="right">
                                                        {formatAsCurrency(revenue.totalDeposits)}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {formatAsCurrency(revenue.handlingFees)}
                                                    </TableCell>
                                                    <TableCell
                                                        align="right"
                                                        title={`(Total | ${getQuickDropName('en')})`}
                                                        style={cellStyle}
                                                    >
                                                        {totalContainers.toLocaleString()} |{' '}
                                                        {totalInhouseContainers.toLocaleString()}
                                                    </TableCell>
                                                    <TableCell
                                                        align="right"
                                                        title={`(Total | ${getQuickDropName('en')})`}
                                                        style={cellStyle}
                                                    >
                                                        {totalCustomers.toLocaleString()} | {totalInhouseCustomers}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {ratings.pickups.good}/{ratings.pickups.total}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {ratings.counts.good}/{ratings.counts.total}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        }
                                    )}
                        </TableBody>
                    </Table>
                </div>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
        </>
    );
}

export default withTheme()(BaseTable);

// MATERIAL UI: https://material-ui.com/components/tables/
function descendingComparator(a, b, orderBy) {
    if (_.get(b, orderBy) < _.get(a, orderBy)) {
        return -1;
    }
    if (_.get(b, orderBy) > _.get(a, orderBy)) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}
