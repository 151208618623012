import React from 'react';
import _ from 'lodash';

import useTable from 'components/Table/useTable';
import DashBoardTable from 'components/Table/DashBoardTable';

import StateSelectDropDown from 'components/DropDownSelects/StateSelectDropDown';

import {
    withTheme,
    TableRow,
    TableCell,
    Paper,
    Grid,
    FormControl,
    MenuItem,
    Select,
    Menu,
    IconButton,
    Icon,
    Button,
    TextField,
    InputLabel,
    OutlinedInput,
    Checkbox,
    ListItemText,
    Tooltip,
    colors
} from '@material-ui/core';

import { formatAsCurrency, isAUSRegion } from 'utils/misc';
import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery';
import { useState } from 'react';
import moment from 'moment';

import useQueryString from 'utils/hooks/useQueryString';

const subscriptionHeaders = [
    { name: 'Actions', key: null },
    { name: 'Customer Name^', key: 'name.first' },
    { name: 'Name', key: 'subscription.description' },
    { name: 'Description', key: 'recurringFees.description' },
    { name: 'Frequency^', key: 'recurringFees.frequency' },
    { name: 'Date Applied^', key: 'recurringFees.dateApplied' },
    { name: 'Apply When Order Is Completed^', key: 'recurringFees.applyWhenBulkIsCompleted' },
    { name: 'Bin Swap Required^', key: 'recurringFees.binSwapRequired' },
    { name: 'Active^', key: 'recurringFees.active' },
    { name: 'Address', key: 'recurringFees.address' },
    { name: 'Amount^', key: 'amount' },
    { name: 'Applied By^', key: 'appliedBy.name.first' },
    { name: 'Last Paid Date^', key: 'lastPaidDate' },
    { name: 'Not Charged (Bin)^', key: 'notCharged' }
];

const subscriptionFrequencies = ['0', '7', '14', '21', '28'];

function SubScriptions({ theme, onViewCustomer }) {
    const collapseSearch = useMediaQuery(theme.breakpoints.down('sm'));
    const [filtersOpen, setFiltersOpen] = useState(null);

    const [frequencies, setFrequencies] = useQueryString('frequencies', subscriptionFrequencies, true);

    const {
        loading,
        getData,
        page,
        rowsPerPage,
        filteredData,
        search,
        filteredHeaders,
        totalDataEntries,
        handleChange,
        handleSearch,
        handleChangeFilterHeaders,
        handleAllFilterHeaders,
        handleChangePage,
        handleChangeRowsPerPage
    } = useTable({
        url: `/users/recurringFees`,
        key: 'usersWithRecFees',
        headers: subscriptionHeaders.map(h => h.name)
    });

    const renderSubScriptionRow = (fee, filteredHeaders, key) => {
        const isLegacyRecurringFee =
            _.get(fee, 'subscription.description', '').includes('Bin') && _.isNil(fee.recurringFees.binSwapRequired);

        return (
            <TableRow key={key}>
                {filteredHeaders.includes('Actions') && (
                    <TableCell>
                        <Tooltip title="View User" disableFocusListener disableTouchListener>
                            <IconButton
                                onClick={() => {
                                    onViewCustomer(fee);
                                }}
                            >
                                <Icon>visibility</Icon>
                            </IconButton>
                        </Tooltip>
                    </TableCell>
                )}
                {filteredHeaders.includes('Customer Name^') && (
                    <TableCell>
                        {_.get(fee, 'name.first', '')} {_.get(fee, 'name.last', '')}
                    </TableCell>
                )}
                {filteredHeaders.includes('Name') && (
                    <TableCell>{_.get(fee, 'subscription.description', '')}</TableCell>
                )}
                {filteredHeaders.includes('Description') && (
                    <TableCell>{_.get(fee, 'recurringFees.description', '')}</TableCell>
                )}
                {filteredHeaders.includes('Frequency^') && (
                    <TableCell>{_.get(fee, 'recurringFees.frequency', 0)}</TableCell>
                )}
                {filteredHeaders.includes('Date Applied^') && (
                    <TableCell>
                        {_.get(fee, 'recurringFees.dateApplied', '')
                            ? moment(_.get(fee, 'recurringFees.dateApplied', '')).format('YYYY-MM-DD')
                            : ''}
                    </TableCell>
                )}
                {filteredHeaders.includes('Apply When Bulk Is Completed^') && (
                    <TableCell>
                        <Icon
                            style={{
                                color: _.get(fee, 'recurringFees.applyWhenBulkIsCompleted', false)
                                    ? colors.green[500]
                                    : colors.red[500]
                            }}
                        >
                            {_.get(fee, 'recurringFees.applyWhenBulkIsCompleted', false) ? 'check' : 'close'}
                        </Icon>
                    </TableCell>
                )}
                {filteredHeaders.includes('Bin Swap Required^') && (
                    <TableCell>
                        <Icon
                            style={{
                                color:
                                    _.get(fee, 'recurringFees.binSwapRequired', false) || isLegacyRecurringFee
                                        ? colors.green[500]
                                        : colors.red[500]
                            }}
                        >
                            {_.get(fee, 'recurringFees.binSwapRequired', false) || isLegacyRecurringFee
                                ? 'check'
                                : 'close'}
                        </Icon>
                    </TableCell>
                )}
                {filteredHeaders.includes('Active^') && (
                    <TableCell>
                        <Icon
                            style={{
                                color: _.get(fee, 'recurringFees.active', false) ? colors.green[500] : colors.red[500]
                            }}
                        >
                            {_.get(fee, 'recurringFees.active', false) ? 'check' : 'close'}
                        </Icon>
                    </TableCell>
                )}
                {filteredHeaders.includes('Address') && (
                    <TableCell>{_.get(fee, 'recurringFees.address', '')}</TableCell>
                )}
                {filteredHeaders.includes('Amount^') && (
                    <TableCell>{formatAsCurrency(_.get(fee, 'amount', ''))}</TableCell>
                )}
                {filteredHeaders.includes('Applied By^') && (
                    <TableCell>
                        {_.get(fee, 'appliedBy.name.first', '')} {_.get(fee, 'appliedBy.name.last', '')}
                    </TableCell>
                )}
                {filteredHeaders.includes('Last Paid Date^') && <TableCell>{_.get(fee, 'lastPaidDate', '')}</TableCell>}
                {filteredHeaders.includes('Not Charged (Bin)^') && (
                    <TableCell>
                        {fee.notCharged === 'notCharged' && <Icon style={{ color: colors.red[500] }}>warning</Icon>}
                    </TableCell>
                )}
            </TableRow>
        );
    };

    const renderSearch = children => {
        if (collapseSearch) {
            return (
                <Menu open={Boolean(filtersOpen)} anchorEl={filtersOpen} onClose={handleFiltersOpen(true)}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            padding: theme.spacing.unit * 2
                        }}
                    >
                        {children}
                    </div>
                </Menu>
            );
        } else {
            return (
                <div
                    style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'space-between' }}
                >
                    {/* <div>{customSearch}</div> */}
                    <div style={{ display: 'flex', alignItems: 'center' }}>{children}</div>
                </div>
            );
        }
    };

    const handleSubmit = e => {
        getData();
        handleSearch(e);
        if (filtersOpen) {
            setFiltersOpen(false);
        }
    };

    const inputStyles = {
        margin: theme.spacing.unit / 2
    };

    const handleAllFrequencies = all => e => {
        e.stopPropagation();
        if (all) {
            setFrequencies([...subscriptionFrequencies]);
        } else {
            setFrequencies([]);
        }
    };

    const handleFiltersOpen = close => e => {
        if (close || Boolean(filtersOpen)) {
            setFiltersOpen(null);
        } else {
            setFiltersOpen(e.currentTarget);
        }
    };

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <div style={{ padding: theme.spacing.unit * 2, paddingBottom: theme.spacing.unit }}>
                        <Paper
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                padding: theme.spacing.unit * 2,
                                height: '100%',
                                flexWrap: 'wrap'
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexWrap: 'wrap'
                                }}
                            >
                                <form onSubmit={handleSubmit} style={{ flex: '0 1 100%' }}>
                                    {renderSearch(
                                        <>
                                            <FormControl style={{ padding: 8 }}>
                                                <Select
                                                    multiple
                                                    disabled={loading}
                                                    value={frequencies}
                                                    onChange={e => setFrequencies(e.target.value)}
                                                    renderValue={val =>
                                                        `${
                                                            val.length === subscriptionFrequencies.length
                                                                ? 'All'
                                                                : val.length
                                                        } Frequenc${val.length !== 1 ? 'ies' : 'y'}`
                                                    }
                                                    style={{
                                                        minWidth: 132
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent: 'space-around'
                                                        }}
                                                    >
                                                        <Button disabled={loading} onClick={handleAllFrequencies(true)}>
                                                            All
                                                        </Button>
                                                        <Button
                                                            disabled={loading}
                                                            onClick={handleAllFrequencies(false)}
                                                        >
                                                            None
                                                        </Button>
                                                    </div>
                                                    <MenuItem value={'0'} disabled={loading}>
                                                        <Checkbox checked={frequencies.includes('0')} />
                                                        <ListItemText>One Time</ListItemText>
                                                    </MenuItem>
                                                    {subscriptionFrequencies.map((freq, index) => {
                                                        return (
                                                            index && (
                                                                <MenuItem key={index} value={freq} disabled={loading}>
                                                                    <Checkbox checked={frequencies.includes(freq)} />
                                                                    <ListItemText>Every {freq} days</ListItemText>
                                                                </MenuItem>
                                                            )
                                                        );
                                                    })}
                                                </Select>
                                            </FormControl>

                                            {isAUSRegion() && <StateSelectDropDown loading={loading} />}

                                            <FormControl style={inputStyles}>
                                                <InputLabel htmlFor="collection" variant="outlined">
                                                    Column Filters
                                                </InputLabel>
                                                <Select
                                                    variant="outlined"
                                                    multiple
                                                    value={filteredHeaders}
                                                    onChange={handleChangeFilterHeaders}
                                                    style={{ width: 250 }}
                                                    input={<OutlinedInput labelWidth={140} />}
                                                >
                                                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                        <Button onClick={handleAllFilterHeaders(true)}>All</Button>
                                                        <Button onClick={handleAllFilterHeaders(false)}>None</Button>
                                                    </div>
                                                    {subscriptionHeaders.map(({ name }) => (
                                                        <MenuItem key={name} value={name}>
                                                            {_.startCase(name)}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            <>
                                                <TextField
                                                    variant="outlined"
                                                    name="search"
                                                    value={search || ''}
                                                    onChange={handleChange}
                                                    placeholder="Search"
                                                    style={{
                                                        ...inputStyles,
                                                        width: 250
                                                    }}
                                                />

                                                {
                                                    <Button
                                                        color="primary"
                                                        disabled={loading}
                                                        variant="contained"
                                                        data-cy="dashboard-search-button"
                                                        type="submit"
                                                        onClick={handleSubmit}
                                                    >
                                                        <Icon>search</Icon>
                                                    </Button>
                                                }
                                            </>
                                        </>
                                    )}

                                    {collapseSearch && (
                                        <div style={{ textAlign: 'right' }}>
                                            <IconButton onClick={handleFiltersOpen()}>
                                                <Icon>filter_list</Icon>
                                            </IconButton>
                                        </div>
                                    )}
                                </form>
                            </div>
                        </Paper>
                    </div>
                </Grid>
            </Grid>

            <DashBoardTable
                title="Subscription"
                loading={loading}
                headers={subscriptionHeaders}
                filteredHeaders={filteredHeaders}
                length={totalDataEntries}
                page={page}
                rowsPerPage={rowsPerPage}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                refresh={getData}
            >
                {filteredData &&
                    filteredData.map((fee, idx) => {
                        return renderSubScriptionRow(fee, filteredHeaders, idx);
                    })}
            </DashBoardTable>
        </>
    );
}

export default withTheme()(SubScriptions);
