import React from 'react';

import _ from 'lodash';

import { Switch, withTheme, Typography } from '@material-ui/core';

function HideCustomerInfoInput(props) {
    const {
        onHideCustomerInfoAdmins,
        hideCustomerInfoAdmins,
        onHideCustomerInfoClerks,
        hideCustomerInfoClerks,
        theme
    } = props;

    return (
        <div
            style={{
                position: 'relative',
                paddingRight: theme.spacing.unit,
                paddingLeft: theme.spacing.unit,
                borderStyle: 'solid',
                borderWidth: '1px',
                borderRadius: '4px 4px 4px 4px',
                borderColor: 'rgba(0, 0, 0, 0.23)',
                marginTop: theme.spacing.unit * 2
            }}
        >
            <div
                style={{
                    paddingLeft: 4,
                    paddingRight: 4,
                    position: 'absolute',
                    top: -10,
                    backgroundColor: theme.palette.background.paper,
                    fontFamily: theme.typography.subtitle2.fontFamily,
                    fontSize: '12px',
                    color: theme.palette.grey[600]
                }}
            >
                Hide Customer Identification From
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="body1" style={{ marginRight: theme.spacing.unit / 2 }}>
                        Admins:
                    </Typography>
                    <Switch
                        color="primary"
                        onChange={e => onHideCustomerInfoAdmins(e.target.checked)}
                        value={hideCustomerInfoAdmins}
                        checked={hideCustomerInfoAdmins}
                    />
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginLeft: theme.spacing.unit }}>
                    <Typography variant="body1" style={{ marginRight: theme.spacing.unit / 2 }}>
                        Clerks:
                    </Typography>
                    <Switch
                        color="primary"
                        onChange={e => onHideCustomerInfoClerks(e.target.checked)}
                        value={hideCustomerInfoClerks}
                        checked={hideCustomerInfoClerks}
                    />
                </div>
            </div>
        </div>
    );
}

export default withTheme()(HideCustomerInfoInput);
