import { useState, useEffect, useContext } from 'react';

import _ from 'lodash';

import { padBagtag } from 'utils/misc';

import HttpContext from 'utils/contexts/HttpContext';

function useDriverTripStatsList({ pickup, reloadOperator, onClose, onSnackbar }) {
    const http = useContext(HttpContext);

    const [bagtag, setBagtag] = useState(_.get(pickup, 'bagtag', '').toUpperCase());
    const [bagtagDialogOpen, setBagtagDialogOpen] = useState(false);
    const [undoDialogOpen, setUndoDialogOpen] = useState(false);

    const [inProgress, setInProgress] = useState(false);

    useEffect(() => {
        setBagtag(_.get(pickup, 'bagtag', '').toUpperCase());
    }, [pickup]);

    useEffect(() => {
        setBagtag(_.get(pickup, 'bagtag', '').toUpperCase());
    }, [pickup, bagtagDialogOpen]);

    const handleSubmitBagtag = async () => {
        setInProgress(true);

        const res = await http.post(
            `/bulks/updateBagtag`,
            { bulk_ids: pickup.bulk_ids, bagtag: padBagtag(bagtag) },
            true
        );
        if (res.ok) {
            await reloadOperator();
            onSnackbar('Bagtag updated!');
            setBagtagDialogOpen(false);
            onClose();
        } else {
            onSnackbar(res.errorMessage, 'error');
        }

        setInProgress(false);
    };

    const handleSubmitUndo = async () => {
        setInProgress(true);

        const res = await http.post(`/pickups/${pickup._id}/undo`, {}, true);

        if (res.ok) {
            await reloadOperator();
            window.location.reload();
            onSnackbar('Pickup is back on your route!');
            setUndoDialogOpen(false);
            onClose();
        } else {
            onSnackbar(res.errorMessage, 'error');
        }

        setInProgress(false);
    };

    const handleUndoDialogOpen = state => {
        setUndoDialogOpen(state);
    };

    const handleBagtagDialogOpen = state => {
        setBagtagDialogOpen(state);
    };

    const handleBagtagChange = val => {
        setBagtagDialogOpen(val);
    };

    return {
        inProgress,
        bagtag,
        bagtagDialogOpen,
        undoDialogOpen,
        handleSubmitBagtag,
        handleSubmitUndo,
        handleUndoDialogOpen,
        handleBagtagDialogOpen,
        handleBagtagChange
    };
}

export default useDriverTripStatsList;
