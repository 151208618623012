import React from 'react';

import { withTheme } from '@material-ui/core/styles';
import withMobileDialog from '@material-ui/core/withMobileDialog';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';

function DiscardSessionDialog({ open, onOk, onBack }) {
    return (
        <Dialog open={open}>
            <DialogContent>
                <DialogContentText>
                    You have not counted anything, your session will be removed if you leave.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color="default" size="small" onClick={onBack} data-cy="DiscardSessionDialogBack">
                    Go back
                </Button>
                <Button color="primary" size="small" onClick={onOk} data-cy="DiscardSessionDialogContinue">
                    Continue
                </Button>
            </DialogActions>
        </Dialog>
    );
}
export default withMobileDialog({ breakpoint: 'xs' })(withTheme()(DiscardSessionDialog));
