import React, { useState, useEffect, useContext } from 'react';

import _ from 'lodash';
import { _user, _pickup } from 'std';
import moment from 'moment-timezone';

import { orange, red, grey } from '@material-ui/core/colors';
import { withTheme } from '@material-ui/core/styles';
import {
    Checkbox,
    ListItemSecondaryAction,
    ListItemText,
    ListItem,
    List,
    Icon,
    IconButton,
    Typography,
    Tooltip
} from '@material-ui/core';

import CustomTextBadge from 'components/CustomTextBadge/CustomTextBadge';
import HttpContext from 'utils/contexts/HttpContext';
import { getReceiverName } from 'utils/misc';
import ConfirmDialogContext from 'components/Dialogs/Confirm/ConfirmDialogContext';

function TripsList(props) {
    const {
        theme,
        trips,
        tripIdsUnchecked,
        pickups,
        commoditiesAvailable,
        handlePinClick,
        onTripDialog,
        onTripToCenter,
        onCheckTrip,
        selectedCollector,
        recalculatingTrips,
        sendingEtas,
        onForceRecalculation,
        onSendEtas
    } = props;
    const http = useContext(HttpContext);
    const [pickupsOnEachTrip, setPickupsOnEachTrip] = useState({}); // determine if a trip requires recalculation or not
    const [etaPickupsOnEachTrip, setEtaPickupsOnEachTrip] = useState({}); // determine if a trip requires recalculation or not
    const [etaNotificationWindow, setEtaNotificationWindow] = useState(60);
    const [maxCustomerEtaNotifications, setMaxCustomerEtaNotifications] = useState(3);

    const warnAction = useContext(ConfirmDialogContext);
    const handleSearch = trip => () => {
        onTripToCenter(trip);

        const driver = {
            _id: trip.transporter._id,
            name: _user.getNameFull(trip.transporter),
            collector_id: trip.collector._id,
            trip_id: trip._id
        };
        handlePinClick(driver);
    };

    const getTripsConfig = async () => {
        const res = await http.getJSON(`/system/getTripsConfig`, {
            pick: 'etaNotificationWindow maxCustomerEtaNotifications'
        });
        if (res.ok) {
            setEtaNotificationWindow(res.data.etaNotificationWindow);
            setMaxCustomerEtaNotifications(res.data.maxCustomerEtaNotifications);
        }
    };

    useEffect(() => {
        getTripsConfig();
    }, []);

    useEffect(() => {
        if (!recalculatingTrips) {
            const newPickupsOnEachTrip = {};
            const newEtaPickupsOnEachTrip = {};
            trips.forEach(async trip => {
                const res = await http.getJSON(`/pickups?trip_id=${trip._id}`);
                if (res.ok) {
                    const { pickups: allPickupsOnTrip } = res.data;
                    newPickupsOnEachTrip[trip._id] = allPickupsOnTrip.length;
                    newEtaPickupsOnEachTrip[trip._id] = _.filter(
                        allPickupsOnTrip,
                        p => !p.complete && !p.skipped && !p.aborted
                    );
                }
            });

            setEtaPickupsOnEachTrip(newEtaPickupsOnEachTrip);
            setPickupsOnEachTrip(newPickupsOnEachTrip);
        }
    }, [recalculatingTrips]);

    return (
        <List>
            {_.isEmpty(trips) && <Typography color="textSecondary">No trips on this date.</Typography>}
            {trips.map((trip, i) => {
                if (_.get(trip, 'transporter.collector', '') !== selectedCollector) return null;

                let timezone = trip.collector.timezone;

                let numberOfAllPickups = 0,
                    numberOfUnconfirmedPickups = 0,
                    numberOfAllBags = 0,
                    numberOfUnconfirmedBags = 0;

                pickups.forEach(p => {
                    if (p.collector && p.receiver && _.get(p, 'trip._id', p.trip) === trip._id) {
                        if (p.receiver._id.toString() === trip.transporter._id.toString()) {
                            numberOfAllPickups++;
                            numberOfAllBags += _pickup.numberOfPayloadBags(p, commoditiesAvailable);

                            if (!p.confirmed) {
                                numberOfUnconfirmedPickups++;
                                numberOfUnconfirmedBags += _pickup.numberOfPayloadBags(p, commoditiesAvailable);
                            }
                        }
                    }
                });

                // The pickups array is always pulled from the database, but
                // the nubmerOfPickups entry is only updated on recalculations.
                // numberOfPickups only includes routed pickups, not skipped.

                const inSync =
                    (_.isEmpty(pickupsOnEachTrip) || pickupsOnEachTrip[trip._id] === trip.numberOfPickups) &&
                    (_.get(trip, 'startLocation.description') === _.get(trip, 'route.startLocation.description') ||
                        !_.isNil(trip.actualStartTime) ||
                        _.isNil(trip.route));
                let etasOutOfSync = 0,
                    etasToUpdate = 0,
                    etaUpdatesAvailable = 0;
                _.get(etaPickupsOnEachTrip, `${trip._id}`, []).forEach(p => {
                    let pickupEta = moment(_.last(p.estimatedCompletionDates));
                    if (
                        moment(pickupEta)
                            .tz(timezone)
                            .isSame(moment().tz(timezone), 'day') && //and the current ETA for this pickup is today
                        moment(_.get(p, 'date', ''))
                            .tz(timezone)
                            .isSame(moment().tz(timezone), 'day') && //and the specified pickup date is today
                        (_.isNil(p.lastEtaNotificationSent) ||
                            Math.abs(
                                moment(p.lastEtaNotificationSent).diff(
                                    moment(_.last(p.estimatedCompletionDates)),
                                    'minutes'
                                )
                            ) >
                                etaNotificationWindow / 2)
                    ) {
                        etasOutOfSync++;
                        if (
                            _.get(p, 'etaNotificationsSent', 0) < maxCustomerEtaNotifications //and the maximum number of notifications has not yet been reached
                        ) {
                            etasToUpdate++;
                        }
                    }
                    if (p.etaNotificationsSent < maxCustomerEtaNotifications) {
                        etaUpdatesAvailable++;
                    }
                });
                let etaTooltipTitle = 'Send ETA updates to customers';
                if (sendingEtas) {
                    etaTooltipTitle = 'Sending eta notifications now';
                } else if (
                    moment(_.get(trip, 'startTime'))
                        .tz(timezone)
                        .isAfter(moment().tz(timezone), 'date')
                ) {
                    etaTooltipTitle = 'Cannot sent ETA updates for future dates';
                } else if (
                    moment(_.get(trip, 'startTime'))
                        .tz(timezone)
                        .isBefore(moment().tz(timezone), 'date')
                ) {
                    etaTooltipTitle = 'Cannot sent ETA updates for past dates';
                } else if (etasOutOfSync === 0) {
                    etaTooltipTitle = 'All ETAs within acceptable window';
                } else if (etaUpdatesAvailable === 0 || (etasOutOfSync > 0 && etasToUpdate === 0)) {
                    etaTooltipTitle = 'Max ETA notifications reached';
                } else if (etasOutOfSync > 0 && etasToUpdate > 0) {
                    if (etasOutOfSync === etasToUpdate) {
                        etaTooltipTitle = `Send notifications to ${etasToUpdate} customers`;
                    } else {
                        etaTooltipTitle = `Send notifications to ${etasToUpdate}/${etasOutOfSync} customers`;
                    }
                }

                return (
                    <ListItem
                        key={trip._id}
                        style={{
                            paddingLeft: 0,
                            paddingTop: 0,
                            paddingBottom: theme.spacing.unit * 0.5
                        }}
                    >
                        <IconButton
                            data-cy="date-picker-panel-search"
                            onClick={handleSearch(trip)}
                            style={{ padding: theme.spacing.unit / 2 }}
                        >
                            <Icon style={{ padding: 0 }}>search</Icon>
                        </IconButton>
                        <IconButton
                            data-cy={`date-picker-panel-recalc-${getReceiverName(trip.transporter)
                                .replace(/\s/g, '-')
                                .toLowerCase()}`}
                            onClick={onForceRecalculation(trip._id)}
                            disabled={recalculatingTrips}
                            style={{ padding: theme.spacing.unit / 2 }}
                        >
                            <Icon style={{ color: inSync ? 'currentColor' : red[500], padding: 0 }}>refresh</Icon>
                        </IconButton>
                        <Tooltip title={etaTooltipTitle}>
                            <span>
                                <IconButton
                                    data-cy={`date-picker-panel-send-eta-updates-${getReceiverName(trip.transporter)
                                        .replace(/\s/g, '-')
                                        .toLowerCase()}`}
                                    onClick={() =>
                                        warnAction(() => {
                                            onSendEtas(trip._id);
                                        }, `${etaTooltipTitle}?`)
                                    }
                                    disabled={!inSync || recalculatingTrips || sendingEtas || etasToUpdate === 0}
                                    style={{ padding: theme.spacing.unit / 2 }}
                                >
                                    <Icon
                                        style={{
                                            color:
                                                etasOutOfSync === 0 || sendingEtas
                                                    ? grey[!sendingEtas && etasToUpdate > 0 ? 600 : 300]
                                                    : red[etasToUpdate > 0 ? 500 : 200],
                                            padding: 0
                                        }}
                                    >
                                        {etaUpdatesAvailable === 0 ? 'timer_off' : 'timer'}
                                    </Icon>
                                </IconButton>
                            </span>
                        </Tooltip>

                        <ListItemText
                            data-cy={`date-picker-panel-trip-${_.kebabCase(getReceiverName(trip.transporter) || '')}`}
                            primary={
                                <span>
                                    <span
                                        style={{ color: theme.palette.primary.main }}
                                        data-cy={`date-picker-panel-trip-${_.kebabCase(
                                            getReceiverName(trip.transporter) || ''
                                        )}-transporter`}
                                    >
                                        [
                                        {_.get(
                                            trip,
                                            'transporterCollector.code',
                                            _.get(trip, 'transporter.collector.code', 'N/A')
                                        )}
                                        ]{' '}
                                    </span>
                                    {getReceiverName(trip.transporter)} ➡{' '}
                                    <span
                                        style={{ color: theme.palette.primary.main }}
                                        data-cy={`date-picker-panel-trip-${_.kebabCase(
                                            getReceiverName(trip.transporter) || ''
                                        )}-processor`}
                                    >
                                        {trip.collector.code}
                                    </span>
                                </span>
                            }
                            secondary={
                                <span
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between'
                                    }}
                                >
                                    <span
                                        data-cy={`date-picker-panel-trip-${_.kebabCase(
                                            getReceiverName(trip.transporter) || ''
                                        )}-start-time`}
                                    >
                                        {moment(trip.startTime)
                                            .tz(timezone)
                                            .format('h:mm A')}
                                        <CustomTextBadge
                                            tooltip={
                                                moment(trip.startTime)
                                                    .tz(timezone)
                                                    .format('h:mm A Z') +
                                                ' ' +
                                                timezone
                                            }
                                        >
                                            {moment(trip.startTime)
                                                .tz(timezone)
                                                .format('z')}
                                        </CustomTextBadge>
                                    </span>{' '}
                                    <span>
                                        {numberOfAllPickups}{' '}
                                        <span style={{ color: orange[700] }}>({numberOfUnconfirmedPickups})</span>{' '}
                                        pickups
                                    </span>{' '}
                                    <span data-cy={`number-of-bags-on-route-${i}`}>
                                        {numberOfAllBags}{' '}
                                        <span style={{ color: orange[700] }}>({numberOfUnconfirmedBags})</span> bags
                                    </span>
                                </span>
                            }
                            style={{
                                paddingLeft: theme.spacing.unit,
                                paddingRight: theme.spacing.unit * 4,
                                cursor: 'pointer'
                            }}
                            onClick={() => onTripDialog(true, trip)}
                        />
                        <ListItemSecondaryAction>
                            <Checkbox
                                checked={!tripIdsUnchecked.includes(trip._id)}
                                style={{ color: trip.color }}
                                onChange={onCheckTrip(trip)}
                            />
                        </ListItemSecondaryAction>
                    </ListItem>
                );
            })}
        </List>
    );
}

export default withTheme()(TripsList);
