import ReactGA from 'react-ga4';
import { deviceHelper, getDeviceInformation } from './misc';
import _ from 'lodash';

import { Plugins } from '@capacitor/core';
import { registerPlugin } from '@capacitor/core';

const { CustomAnalyticsPlugin } = registerPlugin('CustomAnalyticsPlugin');

export async function initializeAnalytics() {
    try {
        // if capacitor, do it with custom native code
        if (deviceHelper.isNativeApp()) {
            CustomAnalyticsPlugin.initFacebook(); // starts the custom facebook plugin
        }

        if (!_.isNil(process.env.REACT_APP_FIREBASE_GTAG)) {
            ReactGA.initialize([
                { trackingId: process.env.REACT_APP_GTAG },
                { trackingId: process.env.REACT_APP_FIREBASE_GTAG }
            ]);
        } else {
            ReactGA.initialize(process.env.REACT_APP_GTAG);
        }

        console.log('%cAnalytics initialized', 'color: green');
    } catch (err) {
        console.error('Error starting Analytics', err);
        return false;
    }
}

/**
 * REFERENCE: https://github.com/danwilson/google-analytics-plugin
 */
export async function logEvent(action, label, value) {
    if (_.isNil(action)) return;
    const category = process.env.REACT_APP_ENV !== 'PROD' ? 'Test' : 'Prod';

    try {
        const webEvent = { category, action, label };
        if (!_.isNil(value)) webEvent.value = value;
        ReactGA.event(webEvent);
    } catch (err) {
        console.error('Error logging GoogleAnalytics ', err);
    }
}

/**
 *
 * @param {String} eventName
 * @param {Number} valueToSum
 *
 * TODO: parameters are not being used by the native plugins due to their complexity to implement.
 */
export async function logFacebookEvent(eventName, valueToSum = 0.0) {
    try {
        // prevent bad data from making it to the native plugin calls
        if (isNaN(valueToSum)) {
            throw new Error('valueToSum must be a number');
        } else if (_.isEmpty(eventName) || eventName === '') {
            throw new Error('eventName must be a non-empty string');
        }

        const device = await getDeviceInformation();
        const params = {
            env: process.env.REACT_APP_ENV !== 'PROD' ? 'Test' : 'Prod',
            device
        };

        if (deviceHelper.isNativeApp()) {
            //send a "debug" event so it does not inflate actual analytics data
            if (JSON.parse(process.env.REACT_APP_DEBUG)) {
                await CustomAnalyticsPlugin.logFacebookEvent({ eventName: 'debug native', valueToSum: 0.0, params });
                console.log(`%cNative Facebook analytics event [${eventName}]`, 'color: green; font-weight: bold;');
            } else {
                // must be passed in as an object for PluginCall to pick it up
                await CustomAnalyticsPlugin.logFacebookEvent({ eventName, valueToSum, params });
            }
        } else {
            // web facebook analytics
            if (_.isNil(window.FB)) {
                throw new Error('window.FB not initialized or undefined');
            }

            // if debug
            if (JSON.parse(process.env.REACT_APP_DEBUG)) {
                window.FB.AppEvents.logEvent('debug web', 1.0, params);
                console.log(`%cWeb Facebook analytics event [${eventName}]`, 'color: green; font-weight: bold;');
            } else {
                window.FB.AppEvents.logEvent(eventName, valueToSum, params);
            }
        }
    } catch (err) {
        console.error('Error logging Facebook Event ', err);
    }
}
