import { useState, useEffect } from 'react';
import _ from 'lodash';

import { _user } from 'std';
import moment from 'moment-timezone';

function useOutOfSystemStopDialog(http, operator, filterDate, onSnackbar, handleRecalc, collector) {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [form, setForm] = useState(
        generateFormInitialValues(filterDate, collector ? collector._id : operator.collector._id, operator)
    );
    const [inProgress, setInProgress] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    const [OOSPreset, setOOSPreset] = useState([]);

    useEffect(() => {
        setForm(form => _.set(form, 'date', filterDate));
    }, [filterDate]);

    useEffect(() => {
        loadOOSPreset();
    }, [dialogOpen]);

    const toggleStopDialogClose = () => {
        setDialogOpen(false);
        setForm(generateFormInitialValues(filterDate, collector ? collector._id : operator.collector._id, operator));
        setIsEdit(false);
    };

    const onCreateStop = () => {
        setIsEdit(false);
        setDialogOpen(true);
    };

    const onEditStop = stop => {
        setIsEdit(true);
        setDialogOpen(true);
        let stopCopy = _.cloneDeep(stop);
        stopCopy.date = moment(stopCopy.date).tz(
            _.get(stopCopy, 'collector.timezone', process.env.REACT_APP_REGION_TIMEZONE)
        );
        stopCopy.stopName = _.get(stopCopy, 'stopName', null);
        stopCopy.trip_id = _.get(stopCopy, 'trip._id', null);
        stopCopy.collector_id = _.isNil(stopCopy.trip_id)
            ? _.get(stopCopy, 'collector._id', collector ? collector._id : operator.collector._id)
            : _.get(stopCopy, 'trip.transporterCollector._id', collector ? collector._id : operator.collector._id);
        stopCopy.receiver_id = _.isNil(stopCopy.trip_id)
            ? _.get(stopCopy, 'receiver._id', null)
            : _.get(stopCopy, 'trip.transporter._id', null);
        stopCopy.placeAtStartOfTrip = _.get(stopCopy, 'placeAtStartOfTrip', null);
        stopCopy.limitOneStopPerTrip = _.get(stopCopy, 'limitOneStopPerTrip', null);
        stopCopy.stopDuration = (stopCopy.stopDuration / 60).toString(); //convert back to minutes
        setForm(stopCopy);
    };

    const handleOutOfSystemStopChange = e => {
        const { name, value } = e.target;

        const updatedForm = _.cloneDeep(form);

        if (name === 'collector_id') {
            _.set(updatedForm, 'collector_id', value);
            _.set(updatedForm, 'receiver_id', null);
        } else if (name === 'stopPayAmount') {
            _.set(updatedForm, 'stopPayAmount', !_.isNil(value) ? value * 100 : null);
        } else {
            _.set(updatedForm, name, value);
        }

        setForm(updatedForm);
    };
    const handleOOSPresetChange = ({
        icon,
        iconColor,
        stopName,
        frequency,
        limitOneStopPerTrip,
        placeAtStartOfTrip,
        collector_id,
        receiver_id,
        stopPayAmount,
        stopDuration,
        location,
        comment
    }) => {
        setForm({
            ...form,
            stopIcon: icon,
            stopIconColor: iconColor,
            stopName,
            frequency,
            limitOneStopPerTrip,
            placeAtStartOfTrip,
            collector_id,
            receiver_id,
            stopPayAmount,
            stopDuration,
            location,
            comment
        });
        onSnackbar('Preset Loaded.');
    };

    const handleLocationChange = ({ description, place_id, lat, lng }) => {
        const updatedForm = _.cloneDeep(form);

        _.set(updatedForm, 'location.description', description);
        _.set(updatedForm, 'location.place_id', place_id);
        _.set(updatedForm, 'location.lat', lat);
        _.set(updatedForm, 'location.lng', lng);

        setForm(updatedForm);
    };

    const handleTripChange = (trip_id, collector_id, receiver_id) => {
        const updatedForm = _.cloneDeep(form);
        _.set(updatedForm, 'trip_id', trip_id);
        if (_.isNil(trip_id) || _.isEmpty(trip_id)) {
            _.set(updatedForm, 'collector_id', collector ? collector._id : operator.collector._id);
            _.set(updatedForm, 'receiver_id', null);
        } else {
            _.set(updatedForm, 'collector_id', collector_id);
            _.set(updatedForm, 'receiver_id', receiver_id);
        }

        setForm(updatedForm);
    };

    const handleOutOfSystemStopSubmit = async () => {
        setInProgress(true);

        let formCopy = _.cloneDeep(form);
        formCopy.stopDuration *= 60; //convert to seconds
        if (_.isEmpty(formCopy.trip_id)) {
            formCopy.trip_id = null; //convert to null
        }

        if (!isEdit) {
            // create stop
            const res = await http.post('/stops/create', formCopy, true);
            if (res.ok) {
                onSnackbar('Created an out of system stop.');
                toggleStopDialogClose();
                handleRecalc();
            } else {
                onSnackbar(res.errorMessage, 'error');
            }
        } else {
            // edit stop
            const res = await http.post(`/stops/${form._id}/update`, formCopy, true);
            if (res.ok) {
                onSnackbar('Updated an out of system stop.');
                toggleStopDialogClose();
                handleRecalc();
            } else {
                onSnackbar(res.errorMessage, 'error');
            }
        }
        setInProgress(false);
    };
    async function loadOOSPreset() {
        const res = await http.getJSON(`/system/getOOSPreset`);
        if (res.ok) {
            const { OOSPreset } = res.data;
            if (!_.isNil(OOSPreset) && _.isArray(OOSPreset)) {
                let orderedOOSPreset = _.orderBy(OOSPreset, p => _.get(p, 'stopName', '').toLowerCase(), 'asc');
                setOOSPreset(_.isNil(orderedOOSPreset) ? OOSPreset : orderedOOSPreset);
            }
        }
    }
    async function addOOSPreset() {
        let icon = _.get(form, 'stopIcon', '');
        let iconColor = _.get(form, 'stopIconColor', '');
        let stopName = _.get(form, 'stopName', '');
        let frequency = _.get(form, 'frequency', 0);
        let limitOneStopPerTrip = _.get(form, 'limitOneStopPerTrip', false);
        let placeAtStartOfTrip = _.get(form, 'placeAtStartOfTrip', false);
        let collector_id = _.get(
            form,
            'collector_id',
            _user.isSystemAdmin(operator) || _user.isInternalRole(operator)
                ? null
                : collector
                ? collector._id
                : operator.collector._id
        );
        let receiver_id = _.get(form, 'receiver_id', '');
        let stopPayAmount = _.get(form, 'stopPayAmount', null);
        let stopDuration = _.get(form, 'stopDuration', 0);
        let location = _.get(form, 'location', {
            description: '',
            place_id: undefined
        });
        let comment = _.get(form, 'comment', '');
        const res = await http.post(
            `/system/addOOSPreset`,
            {
                icon,
                iconColor,
                stopName,
                frequency,
                limitOneStopPerTrip,
                placeAtStartOfTrip,
                collector_id,
                receiver_id,
                stopPayAmount,
                stopDuration,
                location,
                comment
            },
            true
        );
        if (res.ok) {
            loadOOSPreset();
            onSnackbar('Preset saved.');
        }
    }
    async function removeOOSPreset(presetId) {
        const res = await http.post(`/system/removeOOSPreset`, { presetId: presetId }, true);
        if (res.ok) {
            loadOOSPreset();
            onSnackbar('Preset removed.');
        }
    }

    return {
        dialogOpen,
        form,
        inProgress,
        isEdit,
        OOSPreset,
        onCreateStop,
        onEditStop,
        toggleStopDialogClose,
        handleOutOfSystemStopChange,
        handleLocationChange,
        handleTripChange,
        handleOutOfSystemStopSubmit,
        addOOSPreset,
        removeOOSPreset,
        handleOOSPresetChange
    };
}

export default useOutOfSystemStopDialog;

function generateFormInitialValues(filterDate, collector_id, operator) {
    return {
        stopName: '',
        date: filterDate,
        location: {
            description: '',
            place_id: undefined
        },
        comment: '',
        // weekly: false,
        frequency: 0,
        receiver_id: '',
        collector_id: collector_id,
        trip_id: '',
        stopDuration: '',
        stopPayAmount: null,
        placeAtStartOfTrip: false,
        limitOneStopPerTrip: false,
        stopIcon: '',
        stopIconColor: ''
    };
}
