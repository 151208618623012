import { useState, useCallback } from 'react';

function useTripToCenter() {
    const [tripToCenter, setTripToCenter] = useState(null);

    const handleTripToCenter = useCallback(trip => {
        setTripToCenter(trip);
    }, []);

    return { tripToCenter, handleTripToCenter };
}

export default useTripToCenter;
