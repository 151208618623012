import React, { useMemo, useState, useEffect } from 'react';
import _ from 'lodash';

import {
    FormControl,
    InputLabel,
    Select,
    Button,
    MenuItem,
    Checkbox,
    ListItemText,
    TextField,
    withTheme,
    Divider,
    Chip,
    Typography
} from '@material-ui/core';

function QuickSelect(props) {
    const {
        theme,
        value,
        selectableValues,
        onChange,
        quickSelectId,
        quickSelectMaxOptions = 3,
        customSort = null,
        renderValue = null,
        renderMenuItem = null,
        ...other
    } = props;

    const [selectOpen, setSelectOpen] = useState(false);
    const [quickSelectOptions, setQuickSelectOptions] = useState([]);

    const defaultRenderValue = selection => {
        const selectedNames = selection.map(value => _.get(_.find(selectableValues, { value }), 'name'));
        return selectedNames.join(', ');
    };

    const handleAll = e => {
        e.stopPropagation();
        const newSelectedValues = selectableValues.map(({ name, value }) => value);
        onChange(newSelectedValues);
    };

    const handleNone = e => {
        e.stopPropagation();
        onChange([]);
    };

    const handleSelectOpen = e => {
        setSelectOpen(true);
    };

    const handleSelectClosed = e => {
        setSelectOpen(false);

        let valueIsInOptions = false;
        quickSelectOptions.forEach(quickSelection => {
            if (_.every(value, v => _.includes(quickSelection, v)) && quickSelection.length === value.length) {
                valueIsInOptions = true;
            }
        });
        if (!valueIsInOptions && value.length !== 0 && value.length !== selectableValues.length) {
            const newQuickSelectOptions = _.cloneDeep(quickSelectOptions);
            if (newQuickSelectOptions.length >= quickSelectMaxOptions) {
                newQuickSelectOptions.pop();
            }
            const selectedValuesCopy = _.cloneDeep(value);
            newQuickSelectOptions.splice(0, 0, selectedValuesCopy);
            setQuickSelectOptions(newQuickSelectOptions);
            try {
                localStorage.setItem(`quickselect-${quickSelectId}`, JSON.stringify(newQuickSelectOptions));
            } catch (err) {}
        }
    };

    const handleQuickSelectOptionSelected = (e, quickSelection) => {
        e.stopPropagation();
        onChange(quickSelection);
    };

    const selectableValuesSorted = useMemo(() => {
        return !_.isNil(customSort) ? customSort(selectableValues) : _.sortBy(selectableValues, 'name');
    }, [selectableValues, customSort]);

    const filteredQuickSelectOptions = useMemo(() => {
        return filterQuickSelectOptions(quickSelectOptions, selectableValues);
    }, [selectableValues, quickSelectOptions]);

    useEffect(() => {
        try {
            const jsonValue = localStorage.getItem(`quickselect-${quickSelectId}`);
            if (!_.isEmpty(jsonValue)) {
                setQuickSelectOptions(JSON.parse(jsonValue));
            }
        } catch (err) {}
    }, [quickSelectId]);

    return (
        <TextField
            select
            SelectProps={{
                multiple: true,
                renderValue: _.isNil(renderValue) ? defaultRenderValue : renderValue,
                open: selectOpen,
                onOpen: handleSelectOpen,
                onClose: handleSelectClosed
            }}
            value={value}
            onChange={e => onChange(e.target.value)}
            {...other}
        >
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <Button fullWidth data-cy={`${props['data-cy']}-all`} onClick={handleAll}>
                    All
                </Button>
                <Button fullWidth data-cy={`${props['data-cy']}-none`} onClick={handleNone}>
                    None
                </Button>
            </div>

            {!_.isEmpty(filteredQuickSelectOptions) && (
                <div>
                    <Typography color="textSecondary" style={{ marginLeft: theme.spacing.unit * 2 }}>
                        Recent Selections
                    </Typography>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-around',
                            alignItems: 'center',
                            flexWrap: 'wrap'
                            //flexDirection: 'column',
                            /*marginTop: theme.spacing.unit,
                            marginBottom: theme.spacing.unit*/
                        }}
                    >
                        {filteredQuickSelectOptions.map(quickSelection => {
                            const firstQuickSelectOptionName = _.get(
                                _.find(selectableValues, { value: quickSelection[0] }),
                                'name'
                            );
                            let label =
                                firstQuickSelectOptionName +
                                (quickSelection.length > 1 ? ` + ${quickSelection.length - 1} more` : '');
                            return (
                                <Chip
                                    onClick={e => handleQuickSelectOptionSelected(e, quickSelection)}
                                    label={label}
                                    color="primary"
                                    style={{ margin: theme.spacing.unit * 0.5 }}
                                >
                                    {label}
                                </Chip>
                            );
                        })}
                    </div>
                </div>
            )}
            <Divider style={{ marginTop: theme.spacing.unit, marginBottom: theme.spacing.unit }} />
            {selectableValuesSorted.map(({ name, value: optionValue, obj }) => {
                const selected = value.includes(optionValue);
                return _.isNil(renderMenuItem)
                    ? defaultRenderMenuItem({ name, value: optionValue, obj, selected })
                    : renderMenuItem({ name, value: optionValue, obj, selected });
            })}
        </TextField>
    );
}

export default withTheme()(QuickSelect);

function defaultRenderMenuItem({ name, value, obj, selected }) {
    return (
        <MenuItem key={value} value={value}>
            <Checkbox checked={selected} />
            <ListItemText>{name}</ListItemText>
        </MenuItem>
    );
}

function filterQuickSelectOptions(quickSelectOptions, selectableValues) {
    return _.filter(quickSelectOptions, quickselection => {
        return allValuesSelectable(quickselection, selectableValues);
    });
}

function allValuesSelectable(quickSelection, selectableValues) {
    return _.every(quickSelection, quickSelectionValue => {
        return !_.isNil(_.find(selectableValues, { value: quickSelectionValue }));
    });
}
