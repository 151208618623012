import React, { useContext } from 'react';
import _ from 'lodash';

// npm components
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { withTheme } from '@material-ui/core/styles';
import ReactToPrint from 'react-to-print';

import ConfirmDialogContext from 'components/Dialogs/Confirm/ConfirmDialogContext';

import { _bulk } from 'std';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    Typography,
    Tooltip,
    IconButton,
    Icon,
    colors
} from '@material-ui/core';
import RoundSquareButton from 'components/Buttons/RoundSquareButton';

function EmailReceiptDialog({
    theme,
    open,
    email,
    customer,
    bulk,
    error,
    onChangeCustomerInfo,
    onSubmit,
    onClose,
    total,
    countsScreenToPrintRef,
    printingReceipt,
    handlePrintReceipt,
    handleDetachCustomer,
    handleOpenAttachCustomer,
    onSnackbar,
    setShowEmailDialog,
    setEmailDialogMode
}) {
    const warnAction = useContext(ConfirmDialogContext);

    const donating = _bulk.isDonating(bulk);
    const onBeforeGetContentResolve = React.useRef(null);
    React.useEffect(() => {
        if (onBeforeGetContentResolve.current) {
            onBeforeGetContentResolve.current();
        }
    }, [printingReceipt]);
    const buttons = [];
    if (_.isNil(customer)) {
        buttons.push({
            onClick: () => {
                handleOpenAttachCustomer(true);
            },
            color: colors.red[500],
            text: 'Find',
            disabled: !_.isNil(customer),
            'data-cy': 'bulk-counter-etransfer'
        });
    }
    buttons.push({
        onClick: () => {},
        color: colors.orange[500],
        text: 'Print',
        disabled: false,
        'data-cy': 'print-receipt'
    });
    buttons.push({
        onClick: () => {
            if (_.isNil(customer) || _.isNil(_.get(customer, 'email', null))) {
                if (!donating) {
                    setEmailDialogMode('eTransfer');
                    setShowEmailDialog(true);
                    onSnackbar('Please link account or enter email to e-Transfer', 'error');
                    return;
                } else {
                    setEmailDialogMode('donate');
                    setShowEmailDialog(true);
                    onSnackbar('Enter an email for a receipt');
                    return;
                }
            }
            onSubmit(true, _.get(customer, 'email', ''), donating);
        },
        color: colors.green[500],
        text: donating ? 'Donate' : 'E-Transfer',
        disabled: false,
        'data-cy': 'bulk-counter-etransfer'
    });
    if (!donating) {
        buttons.push({
            onClick: !donating
                ? () => {
                      onSubmit(false);
                  }
                : () =>
                      warnAction(() => {
                          onSubmit(false);
                      }, `Donations are only available for eTranfers, the order will no longer be donated.`),
            color: colors.blue[500],
            text: 'Pay Cash',
            disabled: donating,
            'data-cy': 'bulk-counter-cash-out'
        });
    }

    return (
        <>
            <Dialog open={open} onClose={onClose} maxWidth="sm">
                <DialogTitle>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <div style={{ width: 26 }} />
                        <Typography style={{ textAlign: 'center', fontSize: 18, fontWeight: 250 }}>
                            {_.isNil(customer)
                                ? 'No Customer Linked'
                                : _.get(customer, 'name.first', 'Guest') + ' ' + _.get(customer, 'name.last', '')}
                        </Typography>
                        {!_.isNil(customer) && (
                            <Tooltip title="Detach customer" disableFocusListener disableTouchListener>
                                <IconButton
                                    onClick={() =>
                                        warnAction(
                                            () => {
                                                handleDetachCustomer();
                                            },
                                            'You are about to remove this customer from this order. Do you wish to continue?',
                                            _bulk.getTruncatedLabel(bulk)
                                        )
                                    }
                                    style={{ padding: 2, marginLeft: 5 }}
                                >
                                    <Icon style={{ fontSize: '22px', alignSelf: 'center', color: 'red' }}>close</Icon>
                                </IconButton>
                            </Tooltip>
                        )}
                    </div>
                    <Typography style={{ textAlign: 'center', fontSize: 42 }}>{total}</Typography>
                    <Typography style={{ textAlign: 'center', fontSize: 16, fontWeight: 500 }}>
                        {donating ? 'Total Donation' : 'Total Amount'}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    {buttons.map((button, index) => {
                        if (button.disabled) return null;
                        if (button.text === 'Print') {
                            return (
                                <ReactToPrint
                                    trigger={() => (
                                        <RoundSquareButton
                                            button={button}
                                            theme={theme}
                                            style={{ marginRight: index !== buttons.length - 1 ? 20 : 0 }}
                                        />
                                    )}
                                    content={() => countsScreenToPrintRef.current}
                                    onBeforeGetContent={() => {
                                        return new Promise(resolve => {
                                            handlePrintReceipt(true);
                                            onBeforeGetContentResolve.current = resolve;
                                        });
                                    }}
                                    onAfterPrint={() => {
                                        handlePrintReceipt(false);
                                    }}
                                />
                            );
                        }
                        return (
                            <RoundSquareButton
                                button={button}
                                theme={theme}
                                style={{ marginRight: index !== buttons.length - 1 ? 20 : 0 }}
                            />
                        );
                    })}
                </DialogContent>
                <DialogActions>
                    <Button color="default" data-cy="bulk-counter-cancel" onClick={onClose} style={{}}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
export default withMobileDialog({ breakpoint: 'xs' })(withTheme()(EmailReceiptDialog));
