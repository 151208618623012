import React from 'react';

import _ from 'lodash';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { Toolbar as MuiToolbar, withStyles, withTheme } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { Icon, LinearProgress } from '@material-ui/core';

import {
    SelectionState,
    PagingState,
    IntegratedPaging,
    IntegratedSelection,
    SearchState,
    IntegratedFiltering
} from '@devexpress/dx-react-grid';
import {
    Grid as TableGrid,
    Table,
    TableHeaderRow,
    TableSelection,
    PagingPanel,
    SearchPanel,
    Toolbar
} from '@devexpress/dx-react-grid-material-ui';

import StateSelectDropDown from 'components/DropDownSelects/StateSelectDropDown';

function DirectBankingTab({
    theme,
    inProgress,
    fetchRedemptionsAndBatches,
    reviewBTRows,
    directBankingRows,
    onNonChequeSelection,
    selectedNonCheque,
    batchesRows,
    onConfirmationDialog,
    disableInAppRedemptionCancel
}) {
    const [searchValue, setSearchValue] = React.useState('');
    const handleSearchChange = value => setSearchValue(value);

    const handleFilterClick = async () => {
        await fetchRedemptionsAndBatches();
    };

    React.useEffect(() => {
        (async () => {
            await fetchRedemptionsAndBatches();
        })();
    }, []);

    return (
        <Grid container spacing={theme.spacing.unit * 2} style={{ padding: theme.spacing.unit * 2 }}>
            <Grid item xs={12}>
                <Paper id="redemptions-table-review-BT" data-cy="redemptions-table-review-BT">
                    <MuiToolbar
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            padding: theme.spacing.unit * 2,
                            height: '100%',
                            flexWrap: 'wrap',
                            width: '100%'
                        }}
                    >
                        <StateSelectDropDown loading={inProgress} />
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => handleFilterClick()}
                            disabled={inProgress}
                        >
                            <Icon>search</Icon>
                        </Button>
                    </MuiToolbar>
                </Paper>
            </Grid>
            {/* {reviewChequeRows.length > 0 && (
                    <Grid item xs={12}>
                        <Paper id="redemptions-table-review-cheque" data-cy="redemptions-table-review-cheque">
                            <MuiToolbar>
                                <Typography variant="h6">Review Pending Cheques</Typography>
                            </MuiToolbar>
                            <TableGrid
                                rows={reviewChequeRows}
                                columns={[
                                    { title: 'Actions', name: 'actions' },
                                    { title: 'Account Number', name: 'accountNumberMasked' },
                                    { title: 'Name', name: 'name' },
                                    { title: 'Amount', name: 'amount' },
                                    { title: 'Type', name: 'type' },
                                    { title: 'Method', name: 'method' },
                                    { title: 'Info', name: 'passphrase' }
                                ]}
                            >
                                <PagingState defaultCurrentPage={0} />
                                <IntegratedPaging />
                                <Table cellComponent={TableCell} tableComponent={TableComponent} />
                                <TableHeaderRow />
                                <PagingPanel pageSizes={[5, 10, 25, 0]} />
                            </TableGrid>
                        </Paper>
                    </Grid>
                )} */}
            {reviewBTRows.length > 0 && (
                <Grid item xs={12}>
                    {inProgress && <LinearProgress />}
                    <Paper id="redemptions-table-review-BT" data-cy="redemptions-table-review-BT">
                        <MuiToolbar>
                            <Typography variant="h6">Review Pending Bank Transfers</Typography>
                        </MuiToolbar>
                        <TableGrid
                            rows={reviewBTRows}
                            columns={[
                                { title: 'Actions', name: 'actions' },
                                { title: 'Account Number', name: 'accountNumberMasked' },
                                { title: 'Name', name: 'name' },
                                { title: 'Amount', name: 'amount' },
                                { title: 'Type', name: 'type' },
                                { title: 'Method', name: 'method' },
                                { title: 'Info', name: 'passphrase' },
                                { title: 'State', name: 'state' }
                            ]}
                        >
                            <PagingState defaultCurrentPage={0} />
                            <IntegratedPaging />
                            <Table cellComponent={TableCell} tableComponent={TableComponent} />
                            <TableHeaderRow />
                            <PagingPanel pageSizes={[5, 10, 25, 0]} />
                        </TableGrid>
                    </Paper>
                </Grid>
            )}
            {/* <Grid item xs={12}>
                    <Paper id="redemptions-table-cheque" data-cy="redemptions-table-cheque">
                        <MuiToolbar>
                            <Typography variant="h6">Pending Cheques</Typography>
                        </MuiToolbar>
                        <TableGrid
                            rows={pendingChequeRows}
                            columns={[
                                { title: 'Account Number', name: 'accountNumberMasked' },
                                { title: 'Name', name: 'name' },
                                { title: 'Amount', name: 'amount' },
                                { title: 'Type', name: 'type' },
                                { title: 'Method', name: 'method' },
                                { title: 'Info', name: 'passphrase' }
                            ]}
                        >
                            <PagingState defaultCurrentPage={0} />
                            <SelectionState onSelectionChange={onChequeSelection} selection={selectedCheque} />
                            <IntegratedPaging />
                            <IntegratedSelection />
                            <Table cellComponent={TableCell} tableComponent={TableComponent} />
                            <TableHeaderRow />
                            <TableSelection showSelectAll />
                            <PagingPanel pageSizes={[5, 10, 25, 0]} />
                        </TableGrid>
                    </Paper>
                    <Button
                        data-cy="redemptions-complete-cheques"
                        variant="contained"
                        color="secondary"
                        disabled={selectedCheque.length === 0}
                        style={{ marginTop: theme.spacing.unit * 2 }}
                        onClick={props.onConfirmationDialog(true, 'CHEQUE', ['Customer', 'Charity'])}
                    >
                        Process Cheques
                    </Button>
                    <Button
                        data-cy="redemptions-reject-cheques"
                        variant="contained"
                        color="secondary"
                        disabled={selectedCheque.length === 0}
                        style={{ marginTop: theme.spacing.unit * 2, marginLeft: theme.spacing.unit * 2 }}
                        onClick={props.onConfirmationDialog(true, 'CHEQUE', ['Customer', 'Charity'], false, true)}
                    >
                        Reject
                    </Button>
                </Grid> */}

            <Grid item xs={12}>
                {inProgress && <LinearProgress />}
                <Paper id="redemptions-table" data-cy="redemptions-table">
                    <MuiToolbar style={{ minHeight: 48, alignItems: 'flex-end' }}>
                        <Typography variant="h6">Incomplete Redemptions</Typography>
                    </MuiToolbar>
                    <TableGrid
                        rows={directBankingRows}
                        columns={[
                            { title: 'Account Number', name: 'accountNumberMasked' },
                            { title: 'Name', name: 'name' },
                            { title: 'Amount', name: 'amount' },
                            { title: 'Type', name: 'type' },
                            { title: 'Method', name: 'method' },
                            { title: 'Info', name: 'passphrase' },
                            { title: 'State', name: 'state' }
                        ]}
                    >
                        <PagingState defaultCurrentPage={0} />
                        <SelectionState onSelectionChange={onNonChequeSelection} selection={selectedNonCheque} />
                        <IntegratedPaging />
                        <IntegratedSelection />
                        <Table cellComponent={TableCell} tableComponent={TableComponent} />
                        <TableHeaderRow />
                        <TableSelection showSelectAll />
                        <PagingPanel pageSizes={[5, 10, 25, 0]} />
                    </TableGrid>
                </Paper>
                <Button
                    data-cy="redemptions-complete"
                    variant="contained"
                    color="secondary"
                    disabled={selectedNonCheque.length === 0}
                    style={{ marginTop: theme.spacing.unit * 2, color: theme.palette.common.white }}
                    onClick={onConfirmationDialog(true, 'AUS_DIRECT_BANKING', ['Customer', 'Charity'])}
                >
                    Process Selected Redemptions
                </Button>
                {!disableInAppRedemptionCancel && (
                    <Button
                        data-cy="redemptions-reject"
                        variant="contained"
                        color="secondary"
                        disabled={selectedNonCheque.length === 0}
                        style={{ marginTop: theme.spacing.unit * 2, marginLeft: theme.spacing.unit * 2 }}
                        onClick={onConfirmationDialog(true, 'AUS_DIRECT_BANKING', ['Customer', 'Charity'], false, true)}
                    >
                        Reject
                    </Button>
                )}
            </Grid>

            <Grid item xs={12}>
                <Paper id="batches-redemptions-table" data-cy="batches-redemptions-table">
                    <MuiToolbar style={{ minHeight: 48, alignItems: 'flex-end' }}>
                        <Typography variant="h6">Batches</Typography>
                    </MuiToolbar>
                    <TableGrid
                        rows={batchesRows.slice().reverse()}
                        columns={[
                            { title: 'Batch', name: 'batch' },
                            { title: 'Date Created', name: 'dateCreated' },
                            { title: 'Operator', name: 'operatorName' },
                            { title: 'Redemptions', name: 'redemptions' },
                            { title: 'Amount', name: 'totalValue' },
                            { title: 'Actions', name: 'actions' }
                        ]}
                    >
                        <SearchState value={searchValue} onValueChange={handleSearchChange} />
                        <PagingState defaultCurrentPage={0} />
                        <IntegratedFiltering />
                        <IntegratedPaging />
                        <Table cellComponent={TableCell} tableComponent={TableComponent} />
                        <TableHeaderRow />
                        <Toolbar />
                        <SearchPanel />
                        <PagingPanel pageSizes={[5, 10, 25, 0]} />
                    </TableGrid>
                </Paper>
            </Grid>
            {/* <Grid item xs={12}>
                    <Paper id="redemptions-table" data-cy="completed-cheque-redemptions-table">
                        <MuiToolbar style={{ minHeight: 48, alignItems: 'flex-end' }}>
                            <Typography variant="h6">Completed Cheque Redemptions</Typography>
                        </MuiToolbar>
                        <TableGrid
                            rows={chequeRows}
                            columns={[
                                { title: 'E-mail', name: 'email' },
                                { title: 'Name', name: 'name' },
                                { title: 'Amount', name: 'amount' },
                                { title: 'Date Completed', name: 'dateCompleted' },
                                { title: 'Type', name: 'type' },
                                { title: 'Info', name: 'passphrase' }
                            ]}
                        >
                            <SearchState value={searchValue} onValueChange={handleSearchChange} />
                            <PagingState defaultCurrentPage={0} />
                            <IntegratedFiltering />
                            <IntegratedPaging />
                            <Table cellComponent={TableCell} tableComponent={TableComponent} />
                            <TableHeaderRow />
                            <Toolbar />
                            <SearchPanel />
                            <PagingPanel pageSizes={[5, 10, 25, 0]} />
                        </TableGrid>
                    </Paper>
                </Grid> */}
        </Grid>
    );
}

export default withTheme()(DirectBankingTab);

const TableCell = ({ ...props }) => <Table.Cell {...props} style={{ overflow: 'visible' }} />;

const styles = theme => ({
    tableStyles: {
        tableLayout: 'auto'
    }
});
const TableComponentBase = ({ classes, ...props }) => <Table.Table {...props} className={classes.tableStyles} />;
const TableComponent = withStyles(styles, { name: 'TableComponent' })(TableComponentBase);
