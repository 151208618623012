import React, { useContext } from 'react';
import _ from 'lodash';

import { REPORT_TYPES } from 'constants.js';

// react components
import { withTheme } from '@material-ui/core/styles';
import { Icon } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import { Icon as MDIcon } from '@mdi/react';
import * as allIcons from '@mdi/js';

import { Typography, colors, Avatar } from '@material-ui/core';
import SnackbarContext from 'components/CustomSnackbar/SnackbarContext';
import ConfirmDialogContext from 'components/Dialogs/Confirm/ConfirmDialogContext';
import * as userHelper from 'helpers/userHelper';

import { _bulk } from 'std';

const SKU_COLORS = {
    1010.0: colors.green[400],
    10010.0: colors.blue[600],
    10020.0: colors.blue[600],
    2010.0: colors.yellow[700],
    2020.0: colors.yellow[700],
    4020.0: colors.blue[200],
    4010.0: colors.blue[200]
};

const widthList = { Full: 12, '1/2': 6, '1/3': 4, '1/4': 3 };
function BulkSorterCard({
    theme,
    sku,
    disableEditing,
    amount,
    onAddCountsAndUpdateBulkSorter,
    setIdleCountdown,
    resetTimeoutCount,
    operator,
    bulkDonating,
    onCustomFeeDialog,
    opaque,
    bulk,
    onOpenReportDialog,
    reportType,
    onOpenAICountDialog
}) {
    const onSnackbar = useContext(SnackbarContext);
    const warnAction = useContext(ConfirmDialogContext);
    let cardText = `${sku.label} ${sku.description === 'Non-conformant' ? 'Ineligible Containers' : sku.description}`;
    let backgroundColor = SKU_COLORS[sku.sku] || colors.grey[300];
    let icon = null;
    let iconColor = null;
    let textColor = null;
    let cardWidth = sku.fullWidth ? 12 : sku.description === 'Non-conformant' ? 7 : 6;
    let isFees = false;
    let isCustomerReport = false;
    let isDriverReport = false;
    let isAICount = false;
    if (!_.isNil(sku.name) && !_.isEmpty(sku.name)) {
        isFees = sku.special === 'fees';
        isCustomerReport = sku.special === 'reportCustomer';
        isDriverReport = sku.special === 'reportDriver';
        isAICount = sku.special === 'AICount';
        cardText = sku.name;
    }
    if (!_.isNil(sku.backgroundColor) && !_.isEmpty(sku.backgroundColor)) {
        backgroundColor = sku.backgroundColor;
    }
    if (!_.isNil(sku.icon) && !_.isEmpty(sku.icon)) {
        icon = sku.icon;
    }
    if (!_.isNil(sku.iconColor) && !_.isEmpty(sku.iconColor)) {
        iconColor = sku.iconColor;
    }
    if (!_.isNil(sku.textColor) && !_.isEmpty(sku.textColor)) {
        textColor = sku.textColor;
    }
    if (!_.isNil(sku.width) && !_.isNil(widthList[sku.width])) {
        cardWidth = widthList[sku.width];
    } else if (isFees) {
        // if width not set and is fees, use only width of 5
        cardWidth = 5;
    }

    if (isDriverReport && bulk && !bulk.receiver) return <></>;

    if ((isDriverReport && _bulk.isDriverReported(bulk)) || (isCustomerReport && _bulk.isReported(bulk))) {
        textColor = 'white';
        icon = 'priority_high';
        backgroundColor = colors.red[500];
    }

    if (sku.special) return <SpecialCard />;
    return (
        <Grid item xs={cardWidth}>
            <Card
                style={{
                    height: theme.spacing.unit * 7,
                    backgroundColor: backgroundColor
                }}
                elevation={0}
                key={sku.sku}
            >
                <CardActionArea
                    data-cy={`count-input-material-${_.get(sku, 'description', '')
                        .toLowerCase()
                        // .replace(/&/g, 'and')
                        .replace(/\s*\W*\s+/g, '-')}-${sku.label === '> 1L' ? 'lg' : 'sm'}`}
                    onClick={() => {
                        onAddCountsAndUpdateBulkSorter(amount, sku, `${sku.label} ${sku.description}`);
                        setIdleCountdown(0);
                        resetTimeoutCount();
                    }}
                    disabled={disableEditing}
                    style={{ height: '100%', border: 0, opacity: disableEditing && !opaque ? 0.5 : 1 }}
                >
                    <CardContent
                        style={{
                            padding: theme.spacing.unit * 1.5,
                            paddingTop: 0,
                            paddingBottom: 0,
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <table
                            style={{
                                tableLayout: 'fixed',
                                width: '100%'
                            }}
                        >
                            <tbody>
                                <tr>
                                    {icon && (
                                        <td
                                            style={{
                                                width: theme.spacing.unit * 5
                                            }}
                                        >
                                            <Avatar
                                                style={{
                                                    backgroundColor: theme.palette.background.paper,
                                                    height: theme.spacing.unit * 4,
                                                    width: theme.spacing.unit * 4
                                                }}
                                            >
                                                <MDIcon
                                                    path={_.get(allIcons, convertToImportName(icon))}
                                                    size={1}
                                                    color={iconColor}
                                                />
                                            </Avatar>
                                        </td>
                                    )}
                                    <td>
                                        <span
                                            className="scalingFont"
                                            style={{
                                                width: '100%',
                                                display: 'inline-block',
                                                overflow: 'hidden',
                                                color: textColor !== null ? textColor : undefined,
                                                textAlign: 'left'
                                            }}
                                        >
                                            {cardText}
                                        </span>
                                    </td>
                                    {sku.fillCapacity > 0 && (
                                        <td
                                            style={{
                                                width: theme.spacing.unit * 4
                                            }}
                                        >
                                            <Avatar
                                                style={{
                                                    backgroundColor: theme.palette.background.paper,
                                                    height: theme.spacing.unit * 4,
                                                    width: theme.spacing.unit * 4
                                                }}
                                            >
                                                <Typography variant="body2">
                                                    <strong>{sku.fillAmountRemaining}</strong>
                                                </Typography>
                                            </Avatar>
                                        </td>
                                    )}
                                </tr>
                            </tbody>
                        </table>
                    </CardContent>
                </CardActionArea>
            </Card>
        </Grid>
    );
    function SpecialCard() {
        return (
            <Grid item xs={!_.isNil(cardWidth) ? cardWidth : 5}>
                <Card
                    style={{
                        height: theme.spacing.unit * 7,
                        backgroundColor: backgroundColor
                    }}
                    elevation={0}
                >
                    <CardActionArea
                        style={{ height: '100%' }}
                        data-cy={isFees ? 'por-screen-apply-fee-btn' : `por-report-${reportType}`}
                        onClick={() => {
                            if (isCustomerReport) {
                                onOpenReportDialog(REPORT_TYPES.CUSTOMER);
                            }
                            if (isDriverReport) {
                                onOpenReportDialog(REPORT_TYPES.DRIVER);
                            }
                            if (isAICount) {
                                onOpenAICountDialog();
                            }

                            if (isFees) {
                                if (userHelper.isDriver(operator)) {
                                    onSnackbar(
                                        'Only collector managers can do this, please contact a manager.',
                                        'info'
                                    );
                                } else if (bulkDonating) {
                                    warnAction(() => {
                                        onCustomFeeDialog(true, amount);
                                    }, 'Warning! This order is donating to a charity. If you apply enough fees to make the total negative, it will remove the donation from the bulk permanently');
                                } else {
                                    onCustomFeeDialog(true, amount);
                                }
                            }
                            setIdleCountdown(0);
                            resetTimeoutCount();
                        }}
                        disabled={disableEditing}
                    >
                        <CardContent
                            style={{
                                padding: theme.spacing.unit * 1.5,
                                paddingTop: 0,
                                paddingBottom: 0,
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            <table
                                style={{
                                    tableLayout: 'fixed',
                                    width: '100%'
                                }}
                            >
                                <tbody>
                                    <tr>
                                        {icon && (
                                            <td
                                                style={{
                                                    width: theme.spacing.unit * 5
                                                }}
                                            >
                                                <Avatar
                                                    style={{
                                                        backgroundColor: theme.palette.background.paper,
                                                        height: theme.spacing.unit * 4,
                                                        width: theme.spacing.unit * 4
                                                    }}
                                                >
                                                    <MDIcon
                                                        path={_.get(allIcons, convertToImportName(icon))}
                                                        size={1}
                                                        color={iconColor}
                                                    />
                                                </Avatar>
                                            </td>
                                        )}
                                        <td>
                                            <span
                                                className="scalingFont"
                                                style={{
                                                    width: '100%',
                                                    display: 'inline-block',
                                                    overflow: 'hidden',
                                                    color: textColor !== null ? textColor : undefined,
                                                    textAlign: 'left'
                                                }}
                                            >
                                                {isCustomerReport && (
                                                    <>{_bulk.isReported(bulk) ? 'Customer Reported' : cardText}</>
                                                )}
                                                {isDriverReport && (
                                                    <>{_bulk.isDriverReported(bulk) ? 'Driver Reported' : cardText}</>
                                                )}
                                                {!isCustomerReport && !isDriverReport && cardText}
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>{' '}
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Grid>
        );
    }
}
export default withTheme()(BulkSorterCard);

function convertToImportName(iconName) {
    return _.camelCase(`mdi-${iconName}`);
}
