import React, { useEffect, useState } from 'react';

import _ from 'lodash';
import io from 'socket.io-client';

import { _bulk } from 'std';

const pathExtension = process.env.REACT_APP_ENV !== 'LOCAL' ? '/api' : ''; // connect through Nginx if not localhost

function useLiveCountsCounterSocket() {
    const [socket, setSocket] = useState(null);

    useEffect(() => {
        console.log(`%cuseLiveCountsSocketCounterIO: opening socket connection`, 'color: blue');
        const originURL =
            process.env.REACT_APP_ENV !== 'LOCAL'
                ? process.env.REACT_APP_ORIGIN_URL
                : process.env.REACT_APP_ORIGIN_URL.replace(/.$/, '1');

        const socket = io(`${originURL}/liveCounts`, {
            path: `${pathExtension}/socket.io`
        });

        setSocket(socket);

        socket.emit('socket-ready', { type: 'COUNTER' });

        return () => {
            if (!_.isNil(socket)) {
                console.log(`%cclosed socket connection`, 'color: blue');
                socket.close();
            }
        };
    }, []);

    const handleEmitCountsUpdated = bulk => {
        if (_bulk.isWalkIn(bulk)) {
            socket.emit('counts-updated', { _id: bulk._id });
        }
    };

    const handleEmitSwitchedBulk = bulk => {
        if (_.isNil(bulk) || !_bulk.isWalkIn(bulk)) {
            socket.emit('switched-bulk', { _id: null });
        } else {
            socket.emit('switched-bulk', { _id: bulk._id });
        }
    };

    return {
        handleEmitCountsUpdated,
        handleEmitSwitchedBulk
    };
}

export default useLiveCountsCounterSocket;
