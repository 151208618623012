import React, { useState, useEffect, useContext } from 'react';

import _ from 'lodash';

import ConfirmDialogContext from 'components/Dialogs/Confirm/ConfirmDialogContext';

import InlineChip from 'components/Chips/InlineChip';
import InputWithConfirmButtons from 'components/CustomInputs/InputWithConfirmButtons';

import {
    IconButton,
    Icon,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Button,
    Typography,
    withTheme,
    Divider,
    Tooltip,
    TextField,
    InputAdornment,
    colors
} from '@material-ui/core';

import { Icon as MDIcon } from '@mdi/react';
import { mdiHandBackRight, mdiCash } from '@mdi/js';

function RatesDisplay(props) {
    const {
        rates = [],
        commodities,
        ratesSelectedId,
        ratesSelectable = false,
        // ratesEditable,
        ratesPageConfig,
        onRatesSelected,
        onEditContainerRate,
        onDeleteContainerRate,
        onUpdateRatesName,
        onDeleteRates,
        theme,
        readOnlyRates = false
    } = props;
    const warnAction = useContext(ConfirmDialogContext);
    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                overflowX: 'auto',
                overflowY: 'hidden',
                marginTop: theme.spacing.unit,
                paddingBottom: theme.spacing.unit
            }}
        >
            {rates.map((rate, i) => {
                const configForRate = _.find(ratesPageConfig, { rates: rate._id });
                const rateIsReadOnly = _.get(configForRate, 'readOnly', false) || readOnlyRates;

                let ratesHeaderContent;
                if (ratesSelectable) {
                    ratesHeaderContent = (
                        <>
                            <Typography variant="h6">{_.get(rate, 'name', '')}</Typography>
                            <Button
                                size="small"
                                variant="outlined"
                                onClick={() => onRatesSelected(rate._id)}
                                color={rate._id === ratesSelectedId ? 'primary' : 'default'}
                            >
                                {rate._id === ratesSelectedId ? (
                                    <>
                                        <Icon>check</Icon> Selected
                                    </>
                                ) : (
                                    'Select'
                                )}
                            </Button>
                        </>
                    );
                } else if (rateIsReadOnly) {
                    ratesHeaderContent = <Typography variant="h6">{_.get(rate, 'name', '')}</Typography>;
                } else {
                    ratesHeaderContent = (
                        <>
                            <InputWithConfirmButtons
                                initialValue={_.get(rate, 'name', '')}
                                onConfirmValue={name => onUpdateRatesName(rate._id, name)}
                            />
                            <IconButton
                                onClick={() => {
                                    warnAction(
                                        () => onDeleteRates(rate._id),
                                        `Are you sure you want to delete ${_.get(rate, 'name')}`
                                    );
                                }}
                            >
                                <Icon>delete</Icon>
                            </IconButton>
                        </>
                    );
                }

                return (
                    <div
                        key={rate._id}
                        style={{
                            marginRight: theme.spacing.unit,
                            maxWidth: '100%',
                            flex: '0 0 400px',
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                marginRight: theme.spacing.unit,
                                marginBottom: theme.spacing.unit
                            }}
                        >
                            {ratesHeaderContent}
                        </div>
                        <Divider style={{ marginBottom: theme.spacing.unit }} />
                        <List style={{ overflowY: 'auto', flex: 1 }}>
                            {_.get(rate, 'rates', []).map(containerRate => {
                                return (
                                    <ListItem key={containerRate.sku}>
                                        <ListItemText
                                            style={{
                                                paddingRight: theme.spacing.unit * 7
                                            }}
                                            primary={getDescriptionFormatted(containerRate, commodities, theme)}
                                            secondary={getContainerRateSecondary(containerRate, theme)}
                                        />
                                        {!rateIsReadOnly && (
                                            <ListItemSecondaryAction>
                                                <IconButton
                                                    size="small"
                                                    onClick={() => onEditContainerRate(rate, containerRate.sku)}
                                                >
                                                    <Icon>edit</Icon>
                                                </IconButton>
                                                <IconButton
                                                    onClick={() =>
                                                        warnAction(
                                                            () => onDeleteContainerRate(rate, containerRate.sku),
                                                            `Are you sure you want to delete ${_.get(
                                                                containerRate,
                                                                'description',
                                                                ''
                                                            )} ${containerRate.label &&
                                                                `(${containerRate.label})`} on ${_.get(rate, 'name')}`
                                                        )
                                                    }
                                                >
                                                    <Icon>delete</Icon>
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        )}
                                    </ListItem>
                                );
                            })}
                        </List>
                    </div>
                );
            })}
        </div>
    );
}

export default withTheme()(RatesDisplay);

function getDescriptionFormatted(containerRate, commodities, theme) {
    const commodityForSku = _.find(commodities, { skuType: containerRate.skuType });
    let description = (
        <span style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <span>
                {_.get(containerRate, 'description', '')}{' '}
                {!_.isEmpty(_.get(containerRate, 'label')) && `(${_.get(containerRate, 'label')})`} -{' '}
                <span style={{ color: theme.palette.primary.main }}>{-_.get(containerRate, 'value', 0)}¢</span>
            </span>
            <InlineChip
                color={_.get(commodityForSku, 'color')}
                textColor={'white'}
                text={`Sku: ${_.get(containerRate, 'sku', 0)}`}
            />
        </span>
    );
    return description;
}

function getContainerRateSecondary(containerRate, theme) {
    return (
        <>
            <span style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span style={{ display: 'flex' }}>
                    <Tooltip title="Handling fees">
                        <span style={{ display: 'flex', alignItems: 'center', marginRight: theme.spacing.unit }}>
                            <MDIcon path={mdiHandBackRight} size={'1.3rem'} color={theme.palette.text.primary}/>{' '}
                            {_.get(containerRate, 'rate.handling', 0)}¢
                        </span>
                    </Tooltip>
                    <Tooltip title="Tax">
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                            <MDIcon path={mdiCash} size={'1.3rem'} color={theme.palette.text.primary}/> {_.get(containerRate, 'tax.type', '')} -{' '}
                            {_.get(containerRate, 'tax.value', '') * 100}%
                        </span>
                    </Tooltip>
                </span>
                <Tooltip title="Hidden on order counter">
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                        {!_.get(containerRate, 'countable', false) && (
                            <Icon style={{ fontSize: '1.3rem' }}>visibility_off</Icon>
                        )}
                    </span>
                </Tooltip>
            </span>
        </>
    );
}
