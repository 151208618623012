import React from 'react';

import { withTheme } from '@material-ui/core/styles';

function CustomTextBadge(props) {
    const { theme, tooltip } = props;

    return (
        <span
            title={tooltip}
            style={{
                marginLeft: 2,
                verticalAlign: 'super',
                padding: '1px 3px',
                borderRadius: 4,
                border: `1px solid ${theme.palette.action.selected}`,
                backgroundColor: theme.palette.action.hover,
                fontSize: theme.typography.fontSize * 0.675,
                color: theme.palette.text.primary,
                opacity: 0.675
            }}
        >
            {props.children}
        </span>
    );
}

export default withTheme()(CustomTextBadge);
