import React, { useMemo } from 'react';
import _ from 'lodash';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { withTheme } from '@material-ui/core';

function SelectTemplate({
    theme,
    fullWidth,
    helperText,
    id,
    name,
    disabled,
    value,
    onChange,
    labelCustomWidth,
    items = [],
    itemValueKey = '_id', // the object key for what to set as value from item in array
    itemRenderValueKey, // the object key for what to render
    itemRenderValueFunction, // or pass in a function to create/fetch the render value, NOTE must be one input only
    cypressId,
    style,
    multiple,
    showAll = true,
    endAdornment
}) {
    const memoizedItems = useMemo(
        () =>
            items.map(i => {
                const newItem = { ...i };
                newItem.value = i[itemValueKey];
                if (_.isNil(itemRenderValueKey)) {
                    newItem.renderValue = itemRenderValueFunction(i);
                } else {
                    newItem.renderValue = i[itemRenderValueKey];
                }

                return newItem;
            }),
        [itemRenderValueFunction, itemRenderValueKey, itemValueKey, items]
    );

    return (
        <FormControl fullWidth={fullWidth} style={style}>
            <InputLabel variant="outlined" htmlFor="receiver-filter">
                {helperText}
            </InputLabel>
            <Select
                data-cy={cypressId}
                value={value}
                onChange={onChange}
                disabled={disabled || !(memoizedItems.length > 1)}
                multiple={multiple}
                renderValue={multiple ? () => `${value.length} Collectors` : null}
                inputProps={{
                    name: name,
                    id: id
                }}
                input={<OutlinedInput labelWidth={labelCustomWidth || 36} name={name} id={id} />}
                style={{
                    minWidth: 132
                }}
                endAdornment={endAdornment}
            >
                {showAll && memoizedItems.length !== 1 && (
                    <MenuItem value="all">
                        <em>All</em>
                    </MenuItem>
                )}
                {memoizedItems.map(i => {
                    return (
                        <MenuItem value={i.value} key={i._id}>
                            <em>{i.renderValue || 'Error'}</em>
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
}

export default withTheme()(SelectTemplate);
