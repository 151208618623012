import React, { useContext } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';

import { withTheme } from '@material-ui/core/styles';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { FormControlLabel, FormGroup, LinearProgress, colors, Typography } from '@material-ui/core';
import { Dialog, DialogContent, DialogActions, DialogTitle } from '@material-ui/core';
import { TextField, Switch } from '@material-ui/core';
import { Button } from '@material-ui/core';
import CustomTextInput from '../../InputComponents/CustomTextInput';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

function TruckForm(props) {
    const {
        active,
        name,
        number,
        odometer,
        licensePlate,
        onSubmit,
        _id,
        loading,
        editing,
        open,
        onClose,
        fullScreen
    } = props;
    const { lang } = useContext(LocalizationContext);

    const handleFormSubmit = values => {
        values._id = _id;
        onSubmit(values);
    };

    const formik = useFormik({
        initialValues: {
            active: _.isNil(active) ? true : active,
            name: _.isNil(name) ? '' : name,
            number: _.isNil(number) ? undefined : number,
            odometer: _.isNil(odometer) ? undefined : odometer,
            licensePlate: _.isNil(licensePlate) ? '' : licensePlate
        },
        validationSchema: Yup.object({
            active: Yup.boolean(),
            name: Yup.string()
                .required('You need to provide a name')
                .strict()
                .ensure(),
            number: Yup.number()
                .positive('This must be greater than 0')
                .integer('This must be a whole number'),
            odometer: Yup.number()
                .positive('This must be greater than 0')
                .integer('This must be a whole number'),
            licensePlate: Yup.string()
                .required('You need to provide a license plate')
                .strict()
                .ensure()
        }),
        onSubmit: handleFormSubmit
    });

    return (
        <Dialog onClose={onClose} open={open} fullScreen={fullScreen}>
            <DialogTitle>{editing ? 'Update' : 'Create'}</DialogTitle>
            <DialogContent>
                <Typography style={{ color: colors.orange[500] }} variant="h6">
                    {(_.isNil(active) ? false : !active) && 'This truck is currently disabled'}
                </Typography>
                {editing && (
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Switch
                                    id="active"
                                    name="active"
                                    color="primary"
                                    onChange={formik.handleChange}
                                    value={formik.values.active}
                                    checked={formik.values.active}
                                    onBlur={formik.handleBlur}
                                />
                            }
                            label="Active"
                        />
                    </FormGroup>
                )}

                <TextField
                    {...formik.getFieldProps('number')}
                    type="number"
                    error={
                        _.get(formik.touched, 'number', false) && _.get(formik.errors, 'number', false) ? true : null
                    }
                    label="Truck Number"
                    margin="normal"
                    variant="outlined"
                    helperText={
                        _.get(formik.touched, 'number', false) && _.get(formik.errors, 'number', false)
                            ? formik.errors.number
                            : null
                    }
                    fullWidth
                    data-cy="truck-number"
                    disabled={editing}
                />
                <CustomTextInput
                    {...formik.getFieldProps('name')}
                    // upperCase
                    // removeWhitespace
                    // alphanumeric
                    error={formik.touched.name && formik.errors.name ? true : null}
                    label="Truck Name"
                    margin="normal"
                    variant="outlined"
                    helperText={formik.touched.name && formik.errors.name ? formik.errors.name : null}
                    fullWidth
                    data-cy="truck-name"
                />
                <TextField
                    {...formik.getFieldProps('odometer')}
                    type="number"
                    error={
                        _.get(formik.touched, 'odometer', false) && _.get(formik.errors, 'odometer', false)
                            ? true
                            : null
                    }
                    label="Odometer(km)"
                    margin="normal"
                    variant="outlined"
                    helperText={
                        _.get(formik.touched, 'odometer', false) && _.get(formik.errors, 'odometer', false)
                            ? formik.errors.odometer
                            : null
                    }
                    fullWidth
                    data-cy="odometer"
                />
                <CustomTextInput
                    upperCase
                    {...formik.getFieldProps('licensePlate')}
                    error={formik.touched.licensePlate && formik.errors.licensePlate ? true : null}
                    label="License Plate"
                    margin="normal"
                    variant="outlined"
                    helperText={
                        formik.touched.licensePlate && formik.errors.licensePlate ? formik.errors.licensePlate : null
                    }
                    fullWidth
                    data-cy="license-plate"
                />

                {loading && <LinearProgress />}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} disabled={loading}>
                    Cancel
                </Button>
                <Button
                    type="submit"
                    color="primary"
                    disabled={loading}
                    onClick={formik.handleSubmit}
                    data-cy="truck-submit"
                >
                    {loc('submit', lang)}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default withMobileDialog()(withTheme()(TruckForm));
