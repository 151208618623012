import React, { useState, useMemo, useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';

import VERSION_FRONT from 'VERSION';

import ContainerWithLogo from 'components/ContainerWithLogo/ContainerWithLogo';

import {
    Typography,
    Paper,
    Button,
    CircularProgress,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    FormControl,
    Select,
    InputLabel,
    MenuItem
} from '@material-ui/core';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

function Rates({ theme, history, http, useFallbackData }) {
    const { lang } = useContext(LocalizationContext);

    const [ratesLoading, setRatesLoading] = useState(true);
    const [locations, setLocations] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState();
    const [allRates, setAllRates] = useState([]);
    const [config, setConfig] = useState([]);
    /*const [allFees, setAllFees] = useState([]);
    const [customerRates, setCustomerRates] = useState([]);
    const [columns, setColumns] = useState([]);
    const [message, setMessage] = useState('');*/

    const handleChange = e => {
        setSelectedLocation(e.target.value);
    };

    const getRates = async () => {
        let ratesEndpoint = '/rates/ratesConfig';
        if (useFallbackData) {
            const version = VERSION_FRONT.replaceAll('.', '-');
            ratesEndpoint = `/rates/fallback/${lang}/${version}`;
        }

        const res = await http.getJSON(ratesEndpoint);
        if (res.ok) {
            const { ratesConfig, allRates } = res.data;
            setConfig(ratesConfig);
            setLocations(ratesConfig.map(locationConfig => locationConfig.geographicArea));
            setAllRates(allRates);
            /*const { rates, fees, columns, message } = res.data.rates;
            setColumns(columns);
            setAllRates(rates);
            setAllFees(fees);
            setLocations(Object.keys(rates));
            setMessage(message);*/
            setRatesLoading(false);
        }
    };
    useEffect(() => {
        getRates();
    }, []);

    useEffect(() => {
        if (locations) {
            setSelectedLocation(locations[0]);
        }
    }, [locations]);

    /*useEffect(() => {
        setCustomerRates(allRates[selectedLocation]);
    }, [selectedLocation]);*/

    const configLocation = _.find(config, { geographicArea: selectedLocation });
    const ratesSelectedLocation = _.find(allRates, { _id: _.get(configLocation, 'rates') });

    return (
        <ContainerWithLogo brandLogoOverride={_.get(this, 'props.brandLogoOverride')}>
            <div style={{ maxWidth: 640, margin: 'auto 0', marginTop: 55.5 }}>
                <Paper
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        padding: theme.spacing.unit,
                        minWidth: 300
                    }}
                >
                    <Typography variant="h4" style={{ marginTop: theme.spacing.unit * 3 }}>
                        {loc('rates', lang)}
                    </Typography>
                    <div style={{ padding: theme.spacing.unit * 3, paddingBottom: theme.spacing.unit * 5 }}>
                        {ratesLoading ? (
                            <CircularProgress size={32} />
                        ) : (
                            <>
                                <div
                                    style={{
                                        margin: theme.spacing.unit * 2,
                                        display: 'flex',
                                        justifyContent: 'center'
                                    }}
                                >
                                    <FormControl>
                                        <InputLabel>{loc('location', lang)}</InputLabel>
                                        <Select value={selectedLocation || ''} onChange={handleChange}>
                                            {locations &&
                                                locations.map(location => {
                                                    return (
                                                        <MenuItem value={location}>{_.startCase(location)}</MenuItem>
                                                    );
                                                })}
                                        </Select>
                                    </FormControl>
                                </div>
                                {/*Boolean(allFees[selectedLocation]) && (
                                    <Typography paragraph align="center">
                                        {message.replace('{PERCENTAGE}', `${allFees[selectedLocation]}%`)}
                                    </Typography>
                                )*/}
                                {!_.isEmpty(_.get(configLocation, 'message')) && (
                                    <Typography paragraph align="center">
                                        {_.get(configLocation, 'message')}
                                    </Typography>
                                )}

                                <Table>
                                    <TableHead>
                                        {['Material', 'Size', 'Rate'].map(name => {
                                            return <TableCell key={name}>{name}</TableCell>;
                                        })}
                                    </TableHead>
                                    <TableBody>
                                        {_.get(ratesSelectedLocation, 'rates', []).map(
                                            ({ description, label, value, hideFromRatesPage }, i) => {
                                                return (
                                                    !hideFromRatesPage && (
                                                        <TableRow key={i}>
                                                            <TableCell>{description}</TableCell>
                                                            <TableCell style={{ whiteSpace: 'nowrap' }}>
                                                                {label}
                                                            </TableCell>
                                                            <TableCell style={{ whiteSpace: 'nowrap' }}>
                                                                {_.get(
                                                                    configLocation,
                                                                    'includeServiceFeeInContainerValues',
                                                                    false
                                                                )
                                                                    ? -value *
                                                                      (1 - _.get(configLocation, 'serviceFee', 0))
                                                                    : -value}
                                                                ¢
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                );
                                            }
                                        )}
                                    </TableBody>
                                </Table>
                            </>
                        )}
                    </div>
                    <Button
                        color="primary"
                        onClick={() => history.goBack()}
                        style={{ marginBottom: theme.spacing.unit }}
                    >
                        {loc('back', lang)}
                    </Button>
                </Paper>
            </div>
        </ContainerWithLogo>
    );
}

export default withRouter(Rates);
