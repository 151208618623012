import React, { useContext, useState, useEffect } from 'react';

import moment from 'moment-timezone';
import { withTheme } from '@material-ui/core/styles';

import _ from 'lodash';

import SnackbarContext from 'components/CustomSnackbar/SnackbarContext';
import HttpContext from 'utils/contexts/HttpContext';
import OperatorContext from 'utils/contexts/OperatorContext';
import CollectorConfig from 'containers/Operators/CollectorDashboard/CollectorConfig';
// Manually set form and form errors shapes

function Options(props) {
    const operator = useContext(OperatorContext);
    const http = useContext(HttpContext);
    const { theme, google, collector, processors } = props;

    const [payloadOptions, setPayloadOptions] = useState({
        enabled: collector.payloadImageConfirmation.enabled,
        minimum: collector.payloadImageConfirmation.minimum
    });
    const [notServicedDates, setNotServicedDates] = useState(collector.notServicedDates);
    const [location, setLocation] = useState(collector.location);
    const [collectorName, setCollectorName] = useState(collector.name);
    const [collectorEmails, setCollectorEmails] = useState(collector.emails || []);
    const [collectorPhones, setCollectorPhones] = useState(collector.phones || []);
    const [destinationRouting, setDestinationRouting] = useState(collector.routeDestinationBy || '');
    const [historyForm, setHistoryForm] = useState({
        gasPrice: collector.gasPrice / 100,
        employeeWage: collector.employeeWage / 100,
        fuelEfficiency: collector.fuelEfficiency
    });
    const [saveStatus, setSaveStatus] = useState('');
    const [pickupAggregation, setPickupAggregation] = useState(collector.configuration.pickupAggregation);
    const [aggregationPreferences, setAggregationPreferences] = useState(
        collector.configuration.aggregationPreferences
    );
    const [avoidTolls, setAvoidTolls] = useState(collector.configuration.avoidTolls);
    const [recurringFixedCosts, setRecurringFixedCosts] = useState(collector.recurringFixedCosts || []);
    const [fixedCosts, setFixedCosts] = useState(collector.fixedCosts || []);
    const [fixedCostForm, setFixedCostForm] = useState({
        name: '',
        amount: 0,
        recurring: false,
        date: moment()
            .tz(collector.timezone)
            .startOf('month')
    });

    const [activeChanges, setActiveChanges] = useState(false);

    useEffect(() => {
        setPayloadOptions({
            enabled: collector.payloadImageConfirmation.enabled,
            minimum: collector.payloadImageConfirmation.minimum
        });
        setNotServicedDates(collector.notServicedDates);
        setLocation(collector.location);
        setCollectorName(collector.name);
        setCollectorEmails(collector.emails);
        setCollectorPhones(collector.phones);
        setHistoryForm({
            gasPrice: collector.gasPrice / 100,
            employeeWage: collector.employeeWage / 100,
            fuelEfficiency: collector.fuelEfficiency
        });

        setPickupAggregation(collector.configuration.pickupAggregation);
        setAggregationPreferences(collector.configuration.aggregationPreferences);
        setAvoidTolls(collector.configuration.avoidTolls);
        setRecurringFixedCosts(collector.recurringFixedCosts || []);
        setFixedCosts(collector.fixedCosts || []);
        setFixedCostForm({
            name: '',
            amount: 0,
            recurring: false,
            date: moment()
                .tz(collector.timezone)
                .startOf('month')
        });
        setActiveChanges(false);
    }, [collector]);
    const handleChange = e => {
        const { name, value, checked } = e.target;
        setActiveChanges(true);

        switch (name) {
            case 'enabled':
                setPayloadOptions({ ...payloadOptions, [name]: !payloadOptions[name] });
                break;
            case 'minimum':
                setPayloadOptions({ ...payloadOptions, [name]: value });
                break;
            case 'notServicedDates':
                setNotServicedDates(value);
                break;
            case 'collectorName':
                setCollectorName(value);
                break;
            case 'destinationRouting':
                setDestinationRouting(value);
                break;
            case 'emails':
                setCollectorEmails(value);
                break;
            case 'phones':
                setCollectorPhones(value);
                break;
            case 'location':
                setLocation(value);
                break;
            case 'pickupAggregation':
                setPickupAggregation(value);
                break;
            case 'aggregationPreferences':
                const newAggregationPreferences = _.cloneDeep(aggregationPreferences);
                const index = newAggregationPreferences.findIndex(({ day }) => day === value.day);
                newAggregationPreferences[index] = value;
                setAggregationPreferences(newAggregationPreferences);
                break;
            case 'allAggregationPreferences':
                setAggregationPreferences(value);
                break;
            case 'avoidTolls':
                setAvoidTolls(checked);
                break;
            case 'fixedCostName':
                setFixedCostForm({ ...fixedCostForm, name: value });
                break;
            case 'fixedCostAmount':
                setFixedCostForm({ ...fixedCostForm, amount: value });
                break;
            case 'fixedCostRecurring':
                setFixedCostForm({ ...fixedCostForm, recurring: value });
                break;
            case 'fixedCostMonth':
                setFixedCostForm({ ...fixedCostForm, date: fixedCostForm.date.month(value) });
                break;
            case 'fixedCostYear':
                setFixedCostForm({ ...fixedCostForm, date: fixedCostForm.date.year(value) });
                break;
            default:
                setHistoryForm({ ...historyForm, [name]: value });
                break;
        }
    };
    const handleSave = async () => {
        const { gasPrice, employeeWage, fuelEfficiency } = historyForm;
        const res = await http.post(`/collector/${collector._id}/updateCollector`, {
            payloadImageConfirmation: payloadOptions,
            name: collectorName,
            emails: collectorEmails,
            phones: collectorPhones,
            location,
            notServicedDates,
            gasPrice: Math.round((gasPrice || 0) * 100), //convert to cents
            employeeWage: Math.round((employeeWage || 0) * 100),
            fuelEfficiency,
            'configuration.pickupAggregation': pickupAggregation,
            'configuration.aggregationPreferences': aggregationPreferences,
            'configuration.avoidTolls': avoidTolls,
            routeDestinationBy: destinationRouting,
            fixedCosts,
            recurringFixedCosts
        });
        if (res.ok) {
            setActiveChanges(false);
            setSaveStatus('ok');
        } else {
            setSaveStatus('not ok');
        }
    };

    const handleAddFixedCost = () => {
        setFixedCosts([
            ...fixedCosts,
            { ...fixedCostForm, amount: fixedCostForm.amount * 100, date: fixedCostForm.date.toDate() }
        ]);

        if (fixedCostForm.recurring) {
            setRecurringFixedCosts([
                ...recurringFixedCosts,
                { name: fixedCostForm.name, amount: fixedCostForm.amount * 100 }
            ]);
        }
        setFixedCostForm({ ...fixedCostForm, name: '', amount: 0 });
    };

    const handleDeleteRecurringFixedCost = fixedCost => {
        const { name, amount } = fixedCost;

        const updatedRecurringFixedCosts = recurringFixedCosts.filter(recurringFixedCost => {
            const nameMatch = name == recurringFixedCost.name;
            const amountMatch = amount == recurringFixedCost.amount;
            return !nameMatch || !amountMatch;
        });

        setRecurringFixedCosts(updatedRecurringFixedCosts);
        setActiveChanges(true);
    };

    const handleDeleteFixedCost = fixedCost => {
        const { name, amount, date } = fixedCost;

        const updatedFixedCosts = fixedCosts.filter(fixedCost => {
            const nameMatch = name == fixedCost.name;
            const amountMatch = amount == fixedCost.amount;
            const dateMatch = date == fixedCost.date;
            return !nameMatch || !amountMatch || !dateMatch;
        });

        setFixedCosts(updatedFixedCosts);
        setActiveChanges(true);
    };

    return (
        <div style={{ padding: theme.spacing.unit, display: 'flex', justifyContent: 'center' }}>
            <CollectorConfig
                theme={theme}
                operator={operator}
                payloadOptions={payloadOptions}
                handleChange={handleChange}
                handleSave={handleSave}
                saveStatus={saveStatus}
                notServicedDates={notServicedDates}
                historyForm={historyForm}
                handleAddFixedCost={handleAddFixedCost}
                handleDeleteFixedCost={handleDeleteFixedCost}
                handleDeleteRecurringFixedCost={handleDeleteRecurringFixedCost}
                fixedCosts={fixedCosts}
                recurringFixedCosts={recurringFixedCosts}
                fixedCostForm={fixedCostForm}
                location={location}
                pickupAggregation={pickupAggregation}
                aggregationPreferences={aggregationPreferences}
                emails={collectorEmails}
                phones={collectorPhones}
                collectorName={collector.name}
                destinationRouting={destinationRouting}
                avoidTolls={avoidTolls}
                http={http}
                google={google}
                activeChanges={activeChanges}
                timezone={collector.timezone}
                collector={collector}
                processors={processors}
            />
        </div>
    );
}

export default withTheme()(Options);
