import React from 'react';

import { withTheme } from '@material-ui/core/styles';

import PromoTable from 'components/CRUDTables/PromoTable';

function Promos(props) {
    return <PromoTable {...props} />;
}

export default withTheme()(Promos);
