import React from 'react';
import _ from 'lodash';

import { TextField, InputLabel } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
const StyledTextField = withStyles({
    cssLabel: {
        '&$cssFocused': {
            color: 'var(--textColor)'
        },
        color: 'var(--textColor)'
    },
    cssFocused: {},
    cssOutlinedInput: {
        '&$cssFocused $notchedOutline': {
            borderColor: `var(--textColor) !important`
        },
        color: 'var(--textColor)'
    },
    notchedOutline: {
        '&$cssFocused': {
            borderColor: `var(--textColor) !important`
        },
        borderColor: `var(--textColor) !important`
    },
    select: {
        '&$cssFocused $notchedOutline': {
            borderColor: `var(--textColor) !important`
        },
        color: 'var(--textColor)'
    },
    selectMenu: {
        whiteSpace: 'unset'
    },
    selectMenuItem: {
        '& li': {
            whiteSpace: 'unset',
            height: 'unset'
        }
    },
    selectIcon: {
        color: 'var(--textColor)'
    }
})(
    ({
        question,
        lang,
        classes,
        textColor = null,
        children,
        InputProps,
        SelectProps,
        InputLabelProps,
        style,
        ...other
    }) => (
        <>
            <InputLabel
                style={{
                    '--textColor': textColor,
                    ...style
                }}
                classes={
                    !_.isNil(textColor) && {
                        root: classes.cssLabel
                    }
                }
            >
                {_.get(question, `description.${lang}`)}
            </InputLabel>
            <TextField
                style={{
                    '--textColor': textColor,
                    ...style
                }}
                InputLabelProps={{
                    classes: !_.isNil(textColor) && {
                        root: classes.cssLabel,
                        focused: classes.cssFocused
                    },
                    ...InputLabelProps
                }}
                InputProps={{
                    classes: !_.isNil(textColor) && {
                        root: classes.cssOutlinedInput,
                        focused: classes.cssFocused,
                        notchedOutline: classes.notchedOutline
                    },
                    ...InputProps
                }}
                SelectProps={{
                    classes: !_.isNil(textColor)
                        ? {
                              root: classes.select,
                              icon: classes.selectIcon,
                              selectMenu: classes.selectMenu
                          }
                        : {
                              selectMenu: classes.selectMenu
                          },
                    MenuProps: {
                        MenuListProps: {
                            classes: { root: classes.selectMenuItem }
                        }
                    },
                    ...SelectProps
                }}
                {...other}
            >
                {children}
            </TextField>
        </>
    )
);

export default withStyles()(StyledTextField);
