import React from 'react';
import PropTypes from 'prop-types';
import Badge from '@material-ui/core/Badge';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    badge: {
        top: '20%',
        right: 5
    }
});

function CustomizedBadge(props) {
    const { classes, children, badgeContent } = props;

    return (
        <Badge badgeContent={badgeContent} color="primary" classes={{ badge: classes.badge }}>
            {children}
        </Badge>
    );
}

CustomizedBadge.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(CustomizedBadge);
