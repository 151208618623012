import React from 'react';

import _ from 'lodash';

import {
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    Button,
    TextField,
    withTheme,
    LinearProgress,
    InputAdornment
} from '@material-ui/core';

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { useEffect } from 'react';

function AddIpDialog(props) {
    const { open, loading, onClose, onSubmit } = props;

    const formik = useFormik({
        initialValues: {
            ip: '',
            ipVersion: 'IPv4'
        },
        validationSchema: Yup.object({
            ip: Yup.string()
                .required('You must enter a value.')
                .when('ipVersion', {
                    is: 'IPv4',
                    then: Yup.string().matches(
                        /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/gi,
                        'Invalid IPv4 address.'
                    )
                })
                .when('ipVersion', {
                    is: 'IPv6',
                    then: Yup.string().matches(
                        /(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))/gi,
                        'Invalid IPv6 address.'
                    )
                })
        }),
        onSubmit: async values => {
            onSubmit(values.ip);
        },
        enableReinitialize: true
    });

    useEffect(() => {
        formik.handleReset();
    }, [open]);

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>Create</DialogTitle>
            <DialogContent>
                <TextField
                    {...formik.getFieldProps('ip')}
                    autoFocus
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    label="Ip"
                    name="ip"
                    helperText={
                        _.get(formik.touched, 'ip', false) && _.get(formik.errors, 'ip', false)
                            ? _.get(formik.errors, 'ip', false)
                            : null
                    }
                    error={_.get(formik.touched, 'ip', false) && _.get(formik.errors, 'ip', false) ? true : null}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <ToggleButtonGroup
                                    style={{ display: 'inline-flex' }}
                                    value={formik.values.ipVersion}
                                    exclusive
                                    onChange={(e, name) => {
                                        !_.isEmpty(name) && formik.setFieldValue('ipVersion', name);
                                    }}
                                >
                                    <ToggleButton value="IPv4">IPv4</ToggleButton>
                                    <ToggleButton value="IPv6">IPv6</ToggleButton>
                                </ToggleButtonGroup>
                            </InputAdornment>
                        )
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose()}>cancel</Button>{' '}
                <Button color="primary" onClick={formik.handleSubmit}>
                    submit
                </Button>
            </DialogActions>
            {loading && <LinearProgress />}
        </Dialog>
    );
}

export default withTheme()(AddIpDialog);
