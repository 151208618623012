import React, { useState, useContext } from 'react';

import HttpContext from 'utils/contexts/HttpContext';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from '../../../../localizations/localizationHandler';

import _ from 'lodash';
import {
    Dialog,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    withTheme,
    DialogTitle,
    LinearProgress
} from '@material-ui/core';

import { _user } from 'std';
import { formatAsPhoneNumber, isEXPRegion } from 'utils/misc';
import { useEffect } from 'react';

function ReactivateAccountDialog(props) {
    const { open, handleClose, onReloadCustomer, onLogout, customer, theme, isAdmin } = props;
    const { lang } = useContext(LocalizationContext);

    const [loading, setLoading] = useState(false);
    const [serviceEmail, setServiceEmail] = useState('');
    const [servicePhone, setServicePhone] = useState('');

    const http = useContext(HttpContext);

    const handleReactivate = async () => {
        setLoading(true);
        await http.post(`/users/${_user.getId(customer)}/setUserDisabled`, { disabled: false });
        await onReloadCustomer();
        setLoading(false);
    };

    const fetchServiceContactOptions = async () => {
        let res = await http.getJSON('/system/configuration/serviceContactOptions');
        if (res.ok) {
            setServiceEmail(_.get(res, 'data.email', ''));
            setServicePhone(_.get(res, 'data.phone', ''));
        }
    };

    useEffect(() => {
        fetchServiceContactOptions();
    }, []);

    return (
        <Dialog fullWidth open={open}>
            <DialogTitle>{loc('accountDisabled1', lang)}</DialogTitle>
            <DialogContent style={{ paddingBottom: 0 }}>
                <DialogContentText>
                    {isEXPRegion()
                        ? loc('accountDisabled4', lang, {
                              brandname: process.env.REACT_APP_BRAND_NAME,
                              contact:
                                  _.isNil(servicePhone) || _.isEmpty(servicePhone)
                                      ? serviceEmail
                                      : formatAsPhoneNumber(servicePhone)
                          })
                              .split('\n')
                              .map((line, index) => (
                                  <span key={index}>
                                      {line}
                                      <br />
                                  </span>
                              ))
                        : loc('accountDisabled2', lang)}
                </DialogContentText>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        margin: theme.spacing.unit * 2
                    }}
                >
                    {(!isEXPRegion() || isAdmin) && (
                        <Button onClick={handleReactivate} variant="contained" color="primary">
                            {loc('accountDisabled3', lang)}
                        </Button>
                    )}
                </div>
                {loading && <LinearProgress />}
            </DialogContent>
            <DialogActions>
                {isAdmin && <Button onClick={handleClose}>{loc('dismiss', lang)}</Button>}
                {!isAdmin && (
                    <Button onClick={onLogout} color="primary">
                        {loc('signOut', lang)}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
}

export default withTheme()(ReactivateAccountDialog);
