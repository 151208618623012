import React, { useState, useContext, useEffect, useMemo } from 'react';

import _ from 'lodash';
import moment from 'moment-timezone';

import {
    Dialog,
    DialogActions,
    DialogContent,
    Button,
    Grid,
    withTheme,
    withMobileDialog,
    Typography,
    Switch,
    TextField,
    Icon,
    Select,
    FormControl,
    MenuItem,
    Checkbox,
    ListItemText,
    OutlinedInput,
    Tooltip,
    FormHelperText,
    LinearProgress,
    InputLabel,
    Divider
} from '@material-ui/core';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import DatePicker from 'components/DateTimePickersTz/DatePicker';

import CharityMultiSelect from 'components/CharitySelect/CharityMultiSelect';
import * as terms from 'localizations/terms';

import { downloadObjectAsCSV } from 'utils/misc';

import ConfirmDialogContext from 'components/Dialogs/Confirm/ConfirmDialogContext';
import SnackbarContext from 'components/CustomSnackbar/SnackbarContext';

const CUSTOMER_FILTERS = [
    'statusType',
    'modifiers',
    'locationTypes',
    'address',
    'orderTypes',
    'commodityTypes',
    'containsTypes',
    'bulkCountMin',
    'bulkCountMax',
    'beforeAfter',
    'lastBulkDate',
    'customerCollectors',
    'zones',
    'lastOrderOnly',
    'donationPreference'
];

const CHARITY_FILTERS = ['regions', 'charityPreference'];

const COLLECTOR_FILTERS = ['collectorCollectors', 'collectorAccountTypes'];

const COLLECTOR_ACCOUNT_TYPES = ['Clerk', 'Driver', 'Collector Admin'];

const ADVANCED_FILTERS = ['includeBanned', 'includeSuspended'];

const STATUS_TYPES = ['Active', 'Closed', 'Banned'];

const MODIFIER_TYPES = ['Suspended', 'Under Review', 'Stale', 'In Arrears'];

const ORDER_TYPES = ['Adjustment', 'Pickup', 'Drop Off', 'Walk-in'];

const COMMODITY_TYPES = ['Beverage', 'Clothing', 'Electronics', 'Sun Care Donation', 'Food Donation'];

const CONTAINS_TYPES = ['Hazard/Garbage', 'Non-conformant'];

const BEFORE_AFTER = ['Before', 'After'];

const LAST_ORDER_ONLY = ['Last Order Only', 'All Orders'];

const DONATION_QUERY_OPTIONS = ['All Donation Preferences', 'No Donation Preferences', 'Specific Charities'];

function SendOutListDialog(props) {
    const {
        open,
        regions,
        zones,
        charities,
        collectors,
        mobilePickupSubServices,
        sendOutList,
        fullScreen,
        handleSubmit,
        onClose,
        theme,
        http
    } = props;
    const onSnackbar = useContext(SnackbarContext);

    const [queryValue, setQueryValue] = useState(_.get(sendOutList, 'filters.queryBuilder', ''));
    const [donate, setDonate] = useState(false);
    const [questions, setQuestions] = useState([]);

    const warnAction = useContext(ConfirmDialogContext);

    const timezone = process.env.REACT_APP_REGION_TIMEZONE;

    const today = moment(undefined);

    const allZoneIds = useMemo(() => {
        return (zones || []).map(z => z._id);
    }, [zones]);
    const allRegionIds = useMemo(() => {
        return (regions || []).map(r => r._id);
    }, [regions]);
    const allMobilePickupSubServices = useMemo(() => {
        return (mobilePickupSubServices || []).map(s => s.pickupType);
    }, [mobilePickupSubServices]);
    const allCollectorIds = useMemo(() => {
        return (collectors || []).map(c => c._id);
    }, [collectors]);
    const fetchQuestions = async () => {
        const res = await http.getJSON('/questionnaires');
        if (res.ok) {
            setQuestions(res.data.questionnaires.map(questionnaire => questionnaire.questions).flat());
        }
    };
    const handleDeleteAnswer = deletedAnswer => {
        const currentAnswers = _.get(formik, 'values.filters.questionnaireAnswerCharity', []);
        const updatedAnswers = currentAnswers.filter(ans => ans !== deletedAnswer);
        formik.setFieldValue('values.filters.questionnaireAnswerCharity', updatedAnswers);
    };
    const initialValues = useMemo(() => {
        return {
            name: _.get(sendOutList, 'name', ''),
            filters: {
                //These are not saved to the list they are only here for validation
                includeCustomers: _.get(sendOutList, 'filters.includeCustomers', true),
                includeCharities: _.get(sendOutList, 'filters.includeCharities', false),
                includeCollectors: _.get(sendOutList, 'filters.includeCollectors', false),

                statusType: _.get(sendOutList, 'filters.statusType', undefined),
                modifiers: _.get(sendOutList, 'filters.modifiers', []),
                locationTypes: _.get(sendOutList, 'filters.locationTypes', []),
                address: _.get(sendOutList, 'filters.address', undefined),
                orderTypes: _.get(sendOutList, 'filters.orderTypes', []),
                commodityTypes: _.get(sendOutList, 'filters.commodityTypes', ['Beverage']),
                containsTypes: _.get(sendOutList, 'filters.containsTypes', []),
                bulkCountMin: _.get(sendOutList, 'filters.bulkCountMin', 0),
                bulkCountMax: _.get(sendOutList, 'filters.bulkCountMax', undefined),
                beforeAfter: _.get(sendOutList, 'filters.beforeAfter', undefined),
                lastBulkDate: _.get(sendOutList, 'filters.lastBulkDate', moment()),
                customerCollectors: _.get(sendOutList, 'filters.customerCollectors', []),
                zones: _.get(sendOutList, 'filters.zones', []),
                lastOrderOnly: _.get(sendOutList, 'filters.lastOrderOnly', 'All Orders'),
                donationOption: _.get(sendOutList, 'filters.donationOption', 'All Donation Preferences'),
                donationPreference: _.get(sendOutList, 'filters.donationPreference', []),

                collectorCollectors: _.get(sendOutList, 'filters.collectorCollectors', []),
                collectorAccountTypes: _.get(sendOutList, 'filters.collectorAccountTypes', []),

                queryBuilder: _.get(sendOutList, 'filters.queryBuilder', ''),

                regions: _.get(sendOutList, 'filters.regions', []),
                charityPreference: _.get(sendOutList, 'filters.charityPreference', undefined),
                donationMin: _.get(sendOutList, 'filters.donationMin', undefined),
                donationMax: _.get(sendOutList, 'filters.donationMax', undefined),
                tasksMin: _.get(sendOutList, 'filters.tasksMin', undefined),
                tasksMax: _.get(sendOutList, 'filters.tasksMax', undefined),

                questionnaireQuestion: _.get(sendOutList, 'filters.questionnaireQuestion', undefined),
                questionnaireAnswer: _.get(sendOutList, 'filters.questionnaireAnswer', undefined),
                questionnaireQuestionCharity: _.get(sendOutList, 'filters.questionnaireQuestionCharity', undefined),
                questionnaireAnswerCharity: _.get(sendOutList, 'filters.questionnaireAnswerCharity', undefined)
            }
        };
    }, [sendOutList]);
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: Yup.object({
            name: Yup.string().required('You must enter a value'),
            filters: Yup.object({
                bulkCountMin: Yup.number()
                    .integer('You must enter a whole number')
                    .min(0, 'Negative Value'),
                bulkCountMax: Yup.number()
                    .integer('You must enter a whole number')
                    .min(0, 'Negative Value'),
                questionnaireQuestion: Yup.string(),
                questionnaireAnswer: Yup.array().when(['includeCustomers', 'questionnaireQuestion'], {
                    is: (cond1, cond2) => cond1 && cond2 !== undefined,
                    then: Yup.array().required('Response(s) selection required'),
                    otherwise: Yup.array()
                }),
                questionnaireQuestionCharity: Yup.string(),
                questionnaireAnswerCharity: Yup.array().when(['includeCharities', 'questionnaireQuestion'], {
                    is: (cond1, cond2) => cond1 && cond2 !== undefined,
                    then: Yup.array().required('Response(s) selection required'),
                    otherwise: Yup.array()
                }),
                donationPreference: Yup.array().when('donationOption', {
                    is: 'Specific Charities',
                    then: Yup.array()
                        .min(1, 'Please select at least one charity')
                        .required('Please select a charity.')
                })
            })
        }),
        onSubmit: async values => {
            const valuesCopy = _.cloneDeep(values);

            if (!valuesCopy.filters.includeCustomers) {
                valuesCopy.filters = _.omit(valuesCopy.filters, CUSTOMER_FILTERS);
            }
            if (!valuesCopy.filters.includeCharities) {
                valuesCopy.filters = _.omit(valuesCopy.filters, CHARITY_FILTERS);
            }
            if (!valuesCopy.filters.includeCollectors) {
                valuesCopy.filters = _.omit(valuesCopy.filters, COLLECTOR_FILTERS);
            }
            if (valuesCopy.filters.donationOption !== 'Specific Charities') {
                valuesCopy.filters.donationPreference = [];
            }
            onSnackbar(`Submit ${valuesCopy.name}`);
            await handleSubmit(valuesCopy);
        }
    });

    const clearCharitiesPreferred = () => {
        formik.setFieldValue('filters.charityPreference', []);
    };
    const clearCustomerCharitiesPreferred = () => {
        formik.setFieldValue('filters.donationPreference', []);
    };

    const handleCharitiesPreferred = charities => () => {
        formik.setFieldValue('filters.charityPreference', charities);
    };
    const handleCustomerCharitiesPreferred = charities => () => {
        formik.setFieldValue('filters.donationPreference', charities);
    };

    const handleSubdivisionPreferred = e => {};

    const handleSetDonation = donate => {
        setDonate(donate);
    };
    useEffect(() => {
        fetchQuestions();
    }, []);
    useEffect(() => {
        if (open) formik.resetForm();
    }, [open]);

    useEffect(() => {
        let name = '';

        if (formik.values.filters.includeCustomers) {
            if (!_.isNil(formik.values.filters.statusType)) {
                name += `● ${formik.values.filters.statusType} Customer accounts`;
            } else {
                name += `● Customer accounts`;
            }
            if (!_.isEmpty(formik.values.filters.modifiers)) {
                name += ` that are [${formik.values.filters.modifiers.join(', or ')}]`;
            }
            if (!_.isEmpty(formik.values.filters.locationTypes)) {
                name += ` of Account Types [${formik.values.filters.locationTypes.join(', or ')}]`;
            } else {
                name += ` of ALL Location Types`;
            }
            if (!_.isEmpty(formik.values.filters.address)) {
                name += ` matching address string \'${formik.values.filters.address}\'`;
            }
            if (
                !_.isNil(formik.values.filters.questionnaireQuestion) &&
                !_.isEmpty(formik.values.filters.questionnaireAnswer)
            ) {
                const questionFound = questions.find(q => q._id === formik.values.filters.questionnaireQuestion);
                const questionName = questionFound ? questionFound.description.en : undefined;
                const answersSelected =
                    formik.values.filters.questionnaireAnswer.length < 5
                        ? formik.values.filters.questionnaireAnswer.join(', or ')
                        : `One of ${formik.values.filters.questionnaireAnswer.length} Responses`;
                name += `\n● Responded [${answersSelected}] for Question \'${questionName}\'`;
            }
            if (
                !_.isEmpty(formik.values.filters.lastOrderOnly) &&
                (!_.isNil(formik.values.filters.bulkCountMin) || !_.isNil(formik.values.filters.bulkCountMax))
            ) {
                let orderTypes = _.isEmpty(formik.values.filters.orderTypes)
                    ? 'ALL Orders Types'
                    : `${formik.values.filters.orderTypes.join(', or ')}`;

                if (formik.values.filters.lastOrderOnly === 'All Orders') {
                    if (!_.isNil(formik.values.filters.bulkCountMin) && !_.isNil(formik.values.filters.bulkCountMax)) {
                        if (formik.values.filters.bulkCountMin === formik.values.filters.bulkCountMax) {
                            name += `.\n● Having completed exactly ${
                                formik.values.filters.bulkCountMin
                            } [${orderTypes}] orders`;
                        } else {
                            name += `.\n● Having completed between ${formik.values.filters.bulkCountMin} and ${
                                formik.values.filters.bulkCountMax
                            } [${orderTypes}] orders`;
                        }
                    } else if (!_.isNil(formik.values.filters.bulkCountMin)) {
                        name += `.\n● Having completed at least ${
                            formik.values.filters.bulkCountMin
                        } [${orderTypes}] orders`;
                    } else if (!_.isNil(formik.values.filters.bulkCountMax)) {
                        name += `.\n● Having completed at most ${
                            formik.values.filters.bulkCountMax
                        } [${orderTypes}] orders`;
                    }
                } else {
                    name += `.\n● Having completed their last [${orderTypes}] order`;
                }

                if (!_.isNil(formik.values.filters.beforeAfter) && !_.isNil(formik.values.filters.lastBulkDate)) {
                    const formattedDate = moment(formik.values.filters.lastBulkDate).format('MMM D, YYYY');
                    name += ` ${formik.values.filters.beforeAfter.toLowerCase()} ${formattedDate}`;
                }
            }
            if (!_.isEmpty(formik.values.filters.commodityTypes)) {
                name += ` with [${formik.values.filters.commodityTypes.join(', or ')}] Commodity Type(s)`;
            }
            if (!_.isEmpty(formik.values.filters.customerCollectors)) {
                const collectorNames = formik.values.filters.customerCollectors.map(
                    c_id => collectors.find(c => c._id === c_id).name
                );
                if (collectorNames.length < 5) {
                    name += `.\n● from [${collectorNames.join(' , or ')}]`;
                } else {
                    name += `.\n● from ${collectorNames.length} Collectors(s)`;
                }
            }
            if (
                !_.isEmpty(formik.values.filters.zones) &&
                (_.isEmpty(formik.values.filters.orderTypes) || formik.values.filters.orderTypes.includes('Pickup'))
            ) {
                const zoneNames = formik.values.filters.zones.map(z_id => zones.find(z => z._id === z_id).name);
                if (zoneNames.length < 5) {
                    name += `.\n● in [${zoneNames.join(' , or ')}] Zone(s)`;
                } else {
                    name += `.\n● in ${zoneNames.length} Zone(s)`;
                }
            }

            if (formik.values.filters.donationOption !== 'All Donation Preferences') {
                name += `. With donation preference of [${formik.values.filters.donationOption}]`;
            }
        } else if (formik.values.filters.includeCharities) {
            name += '● Charity Admin Accounts';
            if (!_.isEmpty(formik.values.filters.charityPreference)) {
                name += ` from ${formik.values.filters.charityPreference.length} Charities`;
            } else {
                name += ` from ALL Charities`;
            }
            if (!_.isEmpty(formik.values.filters.regions)) {
                if (formik.values.filters.regions.length < 5) {
                    const regionNames = formik.values.filters.regions.map(id => regions.find(z => z._id === id).name);
                    name += ` of [${regionNames.join(', or ')}] Region(s)`;
                } else {
                    name += ` of ${formik.values.filters.regions.length} Regions`;
                }
            } else {
                name += ` of ALL Regions`;
            }
            if (!_.isNil(formik.values.filters.donationMin) || !_.isNil(formik.values.filters.donationMax)) {
                name += `.\n● Having donated `;
                if (!_.isNil(formik.values.filters.donationMin) && !_.isNil(formik.values.filters.donationMax)) {
                    name += `between $${formik.values.filters.donationMin} and $${formik.values.filters.donationMax}`;
                } else if (!_.isNil(formik.values.filters.donationMin)) {
                    name += `at least $${formik.values.filters.donationMin}`;
                } else {
                    name += `at most $${formik.values.filters.donationMax}`;
                }
            }
            if (!_.isNil(formik.values.filters.tasksMin) || !_.isNil(formik.values.filters.tasksMax)) {
                name += `.\n● Having completed `;
                if (!_.isNil(formik.values.filters.tasksMin) && !_.isNil(formik.values.filters.tasksMax)) {
                    name += `between ${formik.values.filters.tasksMin} and ${
                        formik.values.filters.tasksMax
                    } tasks completed`;
                } else if (!_.isNil(formik.values.filters.tasksMin)) {
                    name += `at least ${formik.values.filters.tasksMin} tasks completed`;
                } else {
                    name += `at most ${formik.values.filters.tasksMax} tasks completed`;
                }
            }
            if (
                !_.isNil(formik.values.filters.questionnaireQuestionCharity) &&
                !_.isEmpty(formik.values.filters.questionnaireAnswerCharity)
            ) {
                const questionFound = questions.find(q => q._id === formik.values.filters.questionnaireQuestionCharity);
                const questionName = questionFound ? questionFound.description.en : undefined;
                const answersSelected =
                    formik.values.filters.questionnaireAnswerCharity.length < 5
                        ? formik.values.filters.questionnaireAnswerCharity.join(', or ')
                        : `One of ${formik.values.filters.questionnaireAnswerCharity.length} Responses`;
                name += `\n● Responded [${answersSelected}] for Question \'${questionName}\'`;
            }
        } else if (formik.values.filters.includeCollectors) {
            if (!_.isEmpty(formik.values.filters.collectorAccountTypes)) {
                name += `${formik.values.filters.collectorAccountTypes.join(', or ')} Accounts`;
            } else {
                name += 'ALL Collector Accounts';
            }
            if (!_.isEmpty(formik.values.filters.collectorCollectors)) {
                if (formik.values.filters.collectorCollectors.length < 5) {
                    const collectorNames = formik.values.filters.collectorCollectors.map(
                        id => collectors.find(c => c._id === id).name
                    );
                    name += ` of ${collectorNames.join(', ')} Depot Locations`;
                } else {
                    name += ` of ${formik.values.filters.collectorCollectors.length} Depot Locations`;
                }
            }
        }
        // Remove the trailing comma and add a period
        name = name.replace(/,\s*$/, '') + '.';

        setQueryValue(name);
    }, [formik.values.filters]);

    /*const handleDownloadUsers = async () => {
        setLoadingCustomerReport(true);
        const valuesCopy = _.cloneDeep(formik.values);

        if (!valuesCopy.filters.filterByZone) delete valuesCopy.filters.zones;
        if (!valuesCopy.filters.filterByRegion) delete valuesCopy.filters.regions;
        if (!valuesCopy.filters.filterByLocationType) delete valuesCopy.filters.locationTypes;
        if (!valuesCopy.filters.filterByLastBulkDate) delete valuesCopy.filters.lastBulkDate;

        const res = await http.postJSON('/sendOuts/customerReport', valuesCopy);
        if (res.ok) {
            await downloadObjectAsCSV(_.get(res, 'data.customers', []), _.get(formik.values, 'name', 'Untitled'));
        }
        setLoadingCustomerReport(false);
    };*/

    const useCustomerFilters = _.get(formik.values, 'filters.includeCustomers');
    const useCharityFilters = _.get(formik.values, 'filters.includeCharities');
    const useCollectorFilters = _.get(formik.values, 'filters.includeCollectors');

    return (
        <Dialog open={open} fullScreen={fullScreen} onClose={onClose} maxWidth="lg" fullWidth>
            <DialogContent style={{ overflowY: 'hidden' }}>
                {/* MAIN CONTAINER */}
                <Grid container spacing={theme.spacing.unit * 2}>
                    {/* TOP CONTAINER */}
                    <Grid container spacing={theme.spacing.unit} item xs={12} md={12}>
                        <Grid item xs={12} md={12}>
                            <Typography variant="h6">General</Typography>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextField
                                {...formik.getFieldProps('name')}
                                label="List Name"
                                variant="outlined"
                                style={{ flex: 1 }}
                                fullWidth
                                helperText={_.get(formik.touched, 'name', false) && _.get(formik.errors, 'name', '')}
                                error={_.get(formik.touched, 'name', false) && _.get(formik.errors, 'name', false)}
                            />
                        </Grid>
                    </Grid>

                    {/* CUSTOMER FILTER CONTAINER */}
                    <Grid container spacing={theme.spacing.unit} item xs={12} md={8}>
                        <Grid item xs={12} md={12}>
                            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                <Typography variant="h6">Customer Filters</Typography>
                                <Switch
                                    {...formik.getFieldProps('filters.includeCustomers')}
                                    onChange={e => {
                                        formik.setFieldValue('filters.includeCustomers', e.target.checked);
                                        if (e.target.checked) {
                                            formik.setFieldValue('filters.includeCharities', false);
                                            formik.setFieldValue('filters.includeCollectors', false);
                                        }
                                    }}
                                    checked={_.get(formik.values, 'filters.includeCustomers')}
                                />
                                <Tooltip
                                    title={'This will return customer information.'}
                                    data-cy={`config-customer-filter-text`}
                                    style={{ color: theme.palette.text.disabled, fontSize: 24 }}
                                >
                                    <Icon data-cy={`config-info-icon`}>info</Icon>
                                </Tooltip>
                            </div>
                        </Grid>

                        <Grid container spacing={theme.spacing.unit} item xs={12} md={2}>
                            <Grid
                                item
                                xs={12}
                                md={12}
                                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                            >
                                <Typography variant="subtitle2">Account</Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={theme.spacing.unit} item xs={12} md={10}>
                            <Grid item xs={12} md={4}>
                                <div>
                                    <FormControl
                                        fullWidth
                                        error={
                                            _.get(formik.touched, 'filters.statusType', false) &&
                                            _.get(formik.errors, 'filters.statusType', false)
                                        }
                                    >
                                        <Select
                                            {...formik.getFieldProps('values.filters.statusType')}
                                            value={_.get(formik, 'values.filters.statusType', undefined)}
                                            disabled={!useCustomerFilters}
                                            displayEmpty={true}
                                            renderValue={x => (_.isNil(x) ? `All Statuses` : `${x} Users`)}
                                            input={<OutlinedInput name="filters.statusType" />}
                                            //onChange={e => formik.setFieldValue('filters.statusType', e.target.value)}
                                            onChange={e => {
                                                const selectedValue = e.target.value;
                                                // Toggle selection
                                                const newValue =
                                                    _.get(formik, 'values.filters.statusType') === selectedValue
                                                        ? undefined
                                                        : selectedValue;
                                                formik.setFieldValue('filters.statusType', newValue);
                                            }}
                                        >
                                            {!_.isNil(STATUS_TYPES) &&
                                                STATUS_TYPES.map(s => (
                                                    <MenuItem key={s} value={s}>
                                                        {/* <Checkbox
                                                            checked={_.get(
                                                                formik,
                                                                'values.filters.statusType',
                                                                []
                                                            ).includes(s)}
                                                        /> */}
                                                        <ListItemText>{s}</ListItemText>
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                        {_.get(formik.touched, 'filters.statusType', false) &&
                                            _.get(formik.errors, 'filters.statusType', false) && (
                                                <FormHelperText>
                                                    values.filters
                                                    {_.get(formik.touched, 'filters.statusType', false) &&
                                                        _.get(formik.errors, 'filters.statusType', '')}
                                                </FormHelperText>
                                            )}
                                    </FormControl>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <div>
                                    <FormControl
                                        fullWidth
                                        error={
                                            _.get(formik.touched, 'filters.modifiers', false) &&
                                            _.get(formik.errors, 'filters.modifiers', false)
                                        }
                                    >
                                        <Select
                                            {...formik.getFieldProps('values.filters.modifiers')}
                                            value={_.get(formik, 'values.filters.modifiers', [])}
                                            disabled={!useCustomerFilters}
                                            displayEmpty={true}
                                            multiple
                                            renderValue={x =>
                                                _.isEmpty(x)
                                                    ? 'All Modifiers'
                                                    : x.length > 0 && x.length <= 2
                                                    ? x.join(', ')
                                                    : `${x.length} Modifiers`
                                            }
                                            input={<OutlinedInput labelWidth={0} name="filters.modifiers" />}
                                            onChange={e => formik.setFieldValue('filters.modifiers', e.target.value)}
                                        >
                                            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                <Button
                                                    onClick={e => {
                                                        e.stopPropagation();
                                                        formik.setFieldValue('filters.modifiers', MODIFIER_TYPES);
                                                    }}
                                                >
                                                    All
                                                </Button>
                                                <Button
                                                    onClick={e => {
                                                        e.stopPropagation();
                                                        formik.setFieldValue('filters.modifiers', undefined);
                                                    }}
                                                >
                                                    None
                                                </Button>
                                            </div>
                                            {!_.isNil(MODIFIER_TYPES) &&
                                                MODIFIER_TYPES.map(s => (
                                                    <MenuItem key={s} value={s}>
                                                        <Checkbox
                                                            checked={_.get(
                                                                formik,
                                                                'values.filters.modifiers',
                                                                []
                                                            ).includes(s)}
                                                        />
                                                        <ListItemText>{s}</ListItemText>
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                        {_.get(formik.touched, 'filters.modifiers', false) &&
                                            _.get(formik.errors, 'filters.modifiers', false) && (
                                                <FormHelperText>
                                                    values.filters
                                                    {_.get(formik.touched, 'filters.modifiers', false) &&
                                                        _.get(formik.errors, 'filters.modifiers', '')}
                                                </FormHelperText>
                                            )}
                                    </FormControl>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <div>
                                    <FormControl
                                        fullWidth
                                        error={
                                            _.get(formik.touched, 'filters.locationTypes', false) &&
                                            _.get(formik.errors, 'filters.locationTypes', false)
                                        }
                                    >
                                        <Select
                                            {...formik.getFieldProps('values.filters.locationTypes')}
                                            value={_.get(formik, 'values.filters.locationTypes', [])}
                                            disabled={!useCustomerFilters}
                                            displayEmpty={true}
                                            multiple
                                            renderValue={x =>
                                                _.isEmpty(x)
                                                    ? 'All Types'
                                                    : x.length > 0 && x.length <= 3
                                                    ? x.join(', ')
                                                    : `${x.length} Types`
                                            }
                                            input={<OutlinedInput labelWidth={0} name="filters.locationTypes" />}
                                            onChange={e =>
                                                formik.setFieldValue('filters.locationTypes', e.target.value)
                                            }
                                        >
                                            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                <Button
                                                    onClick={e => {
                                                        e.stopPropagation();
                                                        formik.setFieldValue(
                                                            'filters.locationTypes',
                                                            mobilePickupSubServices.map(s => s.pickupType)
                                                        );
                                                    }}
                                                >
                                                    All
                                                </Button>
                                                <Button
                                                    onClick={e => {
                                                        e.stopPropagation();
                                                        formik.setFieldValue('filters.locationTypes', []);
                                                    }}
                                                >
                                                    None
                                                </Button>
                                            </div>
                                            {!_.isNil(mobilePickupSubServices) &&
                                                mobilePickupSubServices.map(s => (
                                                    <MenuItem key={s.pickupType} value={s.pickupType}>
                                                        <Checkbox
                                                            checked={_.get(
                                                                formik,
                                                                'values.filters.locationTypes',
                                                                []
                                                            ).includes(s.pickupType)}
                                                        />
                                                        <ListItemText>{s.pickupType}</ListItemText>
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                        {_.get(formik.touched, 'filters.locationTypes', false) &&
                                            _.get(formik.errors, 'filters.locationTypes', false) && (
                                                <FormHelperText>
                                                    values.filters
                                                    {_.get(formik.touched, 'filters.locationTypes', false) &&
                                                        _.get(formik.errors, 'filters.locationTypes', '')}
                                                </FormHelperText>
                                            )}
                                    </FormControl>
                                </div>
                            </Grid>
                        </Grid>

                        <Grid container item xs={12} md={2} spacing={theme.spacing.unit} />
                        <Grid container item xs={12} md={10} spacing={theme.spacing.unit}>
                            <Grid item xs={12} md={12}>
                                <TextField
                                    fullWidth
                                    {...formik.getFieldProps('filters.address')}
                                    label="Address Search"
                                    variant="outlined"
                                    disabled={!_.get(formik.values, 'filters.includeCustomers')}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={theme.spacing.unit} item xs={12} md={2}>
                            <Grid
                                item
                                xs={12}
                                md={12}
                                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                            >
                                <Typography variant="subtitle2" style={{ textAlign: 'center' }}>
                                    Questionnaire Select
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={theme.spacing.unit} item xs={12} md={10}>
                            <Grid item xs={12} md={6}>
                                <div>
                                    <FormControl
                                        fullWidth
                                        error={
                                            _.get(formik.touched, 'filters.questionnaireQuestion', false) &&
                                            _.get(formik.errors, 'filters.questionnaireQuestion', false)
                                        }
                                    >
                                        <Select
                                            {...formik.getFieldProps('values.filters.questionnaireQuestion')}
                                            value={_.get(formik, 'values.filters.questionnaireQuestion', undefined)}
                                            disabled={!useCustomerFilters}
                                            displayEmpty={true}
                                            renderValue={x => {
                                                const question = questions.find(q => q._id === x);
                                                return question ? question.description.en : 'No Question Selection';
                                            }}
                                            input={<OutlinedInput name="filters.questionnaireQuestion" />}
                                            onChange={e => {
                                                const selectedValue = e.target.value;
                                                // Toggle selection
                                                const newValue =
                                                    _.get(formik, 'values.filters.questionnaireQuestion') ===
                                                    selectedValue
                                                        ? undefined
                                                        : selectedValue;

                                                formik.setFieldValue('filters.questionnaireAnswer', []);
                                                formik.setFieldValue('filters.questionnaireQuestion', newValue);
                                            }}
                                        >
                                            {!_.isNil(questions) &&
                                                questions.map(q => (
                                                    <MenuItem key={q._id} value={q._id}>
                                                        {/* <Checkbox
                                                            checked={_.get(
                                                                formik,
                                                                'values.filters.questionnaireQuestion',
                                                                []
                                                            ).includes(q._id)}
                                                        /> */}
                                                        <ListItemText>{q.description.en}</ListItemText>
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                        {_.get(formik.touched, 'filters.questionnaireQuestion', false) &&
                                            _.get(formik.errors, 'filters.questionnaireQuestion', false) && (
                                                <FormHelperText>
                                                    {_.get(formik.touched, 'filters.questionnaireQuestion', false) &&
                                                        _.get(formik.errors, 'filters.questionnaireQuestion', '')}
                                                </FormHelperText>
                                            )}
                                    </FormControl>
                                </div>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <div>
                                    <FormControl
                                        fullWidth
                                        error={
                                            _.get(formik.touched, 'filters.questionnaireAnswer', false) &&
                                            _.get(formik.errors, 'filters.questionnaireAnswer', false)
                                        }
                                    >
                                        <Select
                                            {...formik.getFieldProps('values.filters.questionnaireAnswer')}
                                            value={_.get(formik, 'values.filters.questionnaireAnswer', [])}
                                            disabled={!useCustomerFilters}
                                            multiple
                                            displayEmpty={true}
                                            renderValue={x =>
                                                _.isEmpty(x)
                                                    ? 'No Response(s) Selected'
                                                    : `${x.length} Responses Selected`
                                            }
                                            input={<OutlinedInput name="filters.questionnaireAnswer" />}
                                            onChange={e => {
                                                formik.setFieldValue('filters.questionnaireAnswer', e.target.value);
                                            }}
                                        >
                                            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                <Button
                                                    onClick={e => {
                                                        e.stopPropagation();
                                                        const selectedQuestion = questions.find(
                                                            q =>
                                                                q._id ===
                                                                _.get(formik, 'values.filters.questionnaireQuestion')
                                                        );

                                                        if (selectedQuestion) {
                                                            formik.setFieldValue(
                                                                'filters.questionnaireAnswer',
                                                                selectedQuestion.responses.map(ans => ans.en)
                                                            );
                                                        }
                                                    }}
                                                >
                                                    All
                                                </Button>
                                                <Button
                                                    onClick={e => {
                                                        e.stopPropagation();
                                                        formik.setFieldValue('filters.questionnaireAnswer', []);
                                                    }}
                                                >
                                                    None
                                                </Button>
                                            </div>
                                            {!_.isNil(_.get(formik, 'values.filters.questionnaireQuestion')) &&
                                                (() => {
                                                    const selectedQuestion = questions.find(
                                                        q =>
                                                            q._id ===
                                                            _.get(formik, 'values.filters.questionnaireQuestion')
                                                    );

                                                    if (selectedQuestion) {
                                                        return selectedQuestion.responses.map(ans => (
                                                            <MenuItem key={ans.en} value={ans.en}>
                                                                <Checkbox
                                                                    checked={_.get(
                                                                        formik,
                                                                        'values.filters.questionnaireAnswer',
                                                                        []
                                                                    ).includes(ans.en)}
                                                                />
                                                                <ListItemText>{ans.en}</ListItemText>
                                                            </MenuItem>
                                                        ));
                                                    } else {
                                                        return null;
                                                    }
                                                })()}
                                        </Select>
                                        {_.get(formik.touched, 'filters.questionnaireAnswer', false) &&
                                            _.get(formik.errors, 'filters.questionnaireAnswer', false) && (
                                                <FormHelperText>
                                                    {_.get(formik.touched, 'filters.questionnaireAnswer', false) &&
                                                        _.get(formik.errors, 'filters.questionnaireAnswer', '')}
                                                </FormHelperText>
                                            )}
                                    </FormControl>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container spacing={theme.spacing.unit} item xs={12} md={2}>
                            <Grid
                                item
                                xs={12}
                                md={12}
                                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                            >
                                <Typography variant="subtitle2">Orders</Typography>
                            </Grid>
                        </Grid>

                        <Grid container spacing={theme.spacing.unit} item xs={12} md={10}>
                            <Grid item xs={12} md={4}>
                                <div>
                                    <FormControl
                                        fullWidth
                                        error={
                                            _.get(formik.touched, 'filters.lastOrderOnly', false) &&
                                            _.get(formik.errors, 'filters.lastOrderOnly', false)
                                        }
                                    >
                                        <Select
                                            {...formik.getFieldProps('values.filters.lastOrderOnly')}
                                            value={_.get(formik, 'values.filters.lastOrderOnly', 'All Orders')}
                                            disabled={!useCustomerFilters}
                                            displayEmpty={true}
                                            renderValue={s => `${s}`}
                                            input={<OutlinedInput labelWidth={0} name="filters.lastOrderOnly" />}
                                            onChange={e =>
                                                formik.setFieldValue('filters.lastOrderOnly', e.target.value)
                                            }
                                        >
                                            {!_.isNil(LAST_ORDER_ONLY) &&
                                                LAST_ORDER_ONLY.map(s => (
                                                    <MenuItem key={s} value={s}>
                                                        {/* <Checkbox
                                                            checked={
                                                                _.get(
                                                                    formik,
                                                                    'values.filters.lastOrderOnly',
                                                                    undefined
                                                                ) === s
                                                            }
                                                        /> */}
                                                        <ListItemText>{s}</ListItemText>
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                        {_.get(formik.touched, 'filters.lastOrderOnly', false) &&
                                            _.get(formik.errors, 'filters.lastOrderOnly', false) && (
                                                <FormHelperText>
                                                    {_.get(formik.touched, 'filters.lastOrderOnly', false) &&
                                                        _.get(formik.errors, 'filters.lastOrderOnly', '')}
                                                </FormHelperText>
                                            )}
                                    </FormControl>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <div>
                                    <FormControl
                                        fullWidth
                                        error={
                                            _.get(formik.touched, 'filters.orderTypes', false) &&
                                            _.get(formik.errors, 'filters.orderTypes', false)
                                        }
                                    >
                                        <Select
                                            {...formik.getFieldProps('values.filters.orderTypes')}
                                            value={_.get(formik, 'values.filters.orderTypes', [])}
                                            disabled={!useCustomerFilters}
                                            displayEmpty={true}
                                            multiple
                                            renderValue={x =>
                                                _.isEmpty(x)
                                                    ? 'All Order Types'
                                                    : x.length > 0 && x.length <= 3
                                                    ? x.join(', ')
                                                    : `${x.length} Order Types`
                                            }
                                            input={<OutlinedInput name="filters.orderTypes" />}
                                            onChange={e => formik.setFieldValue('filters.orderTypes', e.target.value)}
                                        >
                                            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                <Button
                                                    onClick={e => {
                                                        e.stopPropagation();
                                                        formik.setFieldValue('filters.orderTypes', ORDER_TYPES);
                                                    }}
                                                >
                                                    All
                                                </Button>
                                                <Button
                                                    onClick={e => {
                                                        e.stopPropagation();
                                                        formik.setFieldValue('filters.orderTypes', []);
                                                    }}
                                                >
                                                    None
                                                </Button>
                                            </div>
                                            {!_.isNil(ORDER_TYPES) &&
                                                ORDER_TYPES.map(orders => (
                                                    <MenuItem key={orders} value={orders}>
                                                        <Checkbox
                                                            checked={_.get(
                                                                formik,
                                                                'values.filters.orderTypes',
                                                                []
                                                            ).includes(orders)}
                                                        />
                                                        <ListItemText>{orders}</ListItemText>
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                        {_.get(formik.touched, 'filters.orderTypes', false) &&
                                            _.get(formik.errors, 'filters.orderTypes', false) && (
                                                <FormHelperText>
                                                    values.filters
                                                    {_.get(formik.touched, 'filters.orderTypes', false) &&
                                                        _.get(formik.errors, 'filters.orderTypes', '')}
                                                </FormHelperText>
                                            )}
                                    </FormControl>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <div>
                                    <FormControl
                                        fullWidth
                                        error={
                                            _.get(formik.touched, 'filters.commodityTypes', false) &&
                                            _.get(formik.errors, 'filters.commodityTypes', false)
                                        }
                                    >
                                        <Select
                                            {...formik.getFieldProps('values.filters.commodityTypes')}
                                            value={_.get(formik, 'values.filters.commodityTypes', [])}
                                            disabled={!useCustomerFilters}
                                            displayEmpty={true}
                                            multiple
                                            renderValue={x =>
                                                _.isEmpty(x)
                                                    ? 'All Commodities'
                                                    : x.length > 0 && x.length <= 2
                                                    ? x.join(', ')
                                                    : `${x.length} Commodities`
                                            }
                                            input={<OutlinedInput labelWidth={0} name="filters.commodityTypes" />}
                                            onChange={e =>
                                                formik.setFieldValue('filters.commodityTypes', e.target.value)
                                            }
                                        >
                                            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                <Button
                                                    onClick={e => {
                                                        e.stopPropagation();
                                                        formik.setFieldValue('filters.commodityTypes', COMMODITY_TYPES);
                                                    }}
                                                >
                                                    All
                                                </Button>
                                                <Button
                                                    onClick={e => {
                                                        e.stopPropagation();
                                                        formik.setFieldValue('filters.commodityTypes', []);
                                                    }}
                                                >
                                                    None
                                                </Button>
                                            </div>
                                            {!_.isNil(COMMODITY_TYPES) &&
                                                COMMODITY_TYPES.map(commodities => (
                                                    <MenuItem key={commodities} value={commodities}>
                                                        <Checkbox
                                                            checked={_.get(
                                                                formik,
                                                                'values.filters.commodityTypes',
                                                                []
                                                            ).includes(commodities)}
                                                        />
                                                        <ListItemText>{commodities}</ListItemText>
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                        {_.get(formik.touched, 'filters.commodityTypes', false) &&
                                            _.get(formik.errors, 'filters.commodityTypes', false) && (
                                                <FormHelperText>
                                                    values.filters
                                                    {_.get(formik.touched, 'filters.commodityTypes', false) &&
                                                        _.get(formik.errors, 'filters.commodityTypes', '')}
                                                </FormHelperText>
                                            )}
                                    </FormControl>
                                </div>
                            </Grid>
                        </Grid>

                        <Grid container spacing={theme.spacing.unit} item xs={12} md={2} />
                        <Grid container spacing={theme.spacing.unit} item xs={12} md={10}>
                            {formik.values.filters.lastOrderOnly === 'All Orders' && (
                                <>
                                    <Grid item xs={12} md={2}>
                                        <TextField
                                            fullWidth
                                            {...formik.getFieldProps('filters.bulkCountMin')}
                                            type="number"
                                            label="Min"
                                            variant="outlined"
                                            style={{ flex: 1 }}
                                            helperText={
                                                _.get(formik.touched, 'filters.bulkCountMin', false) &&
                                                _.get(formik.errors, 'filters.bulkCountMin', '')
                                            }
                                            error={
                                                _.get(formik.touched, 'filters.bulkCountMin', false) &&
                                                _.get(formik.errors, 'filters.bulkCountMin', false)
                                            }
                                            disabled={!useCustomerFilters}
                                            onChange={e => {
                                                const value = e.target.value.trim();
                                                if (value === '' || isNaN(value)) {
                                                    formik.setFieldValue('filters.bulkCountMin', undefined);
                                                } else {
                                                    formik.setFieldValue('filters.bulkCountMin', parseInt(value));
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <TextField
                                            fullWidth
                                            {...formik.getFieldProps('filters.bulkCountMax')}
                                            type="number"
                                            label="Max"
                                            variant="outlined"
                                            style={{ flex: 1 }}
                                            helperText={
                                                _.get(formik.touched, 'filters.bulkCountMax', false) &&
                                                _.get(formik.errors, 'filters.bulkCountMax', '')
                                            }
                                            error={
                                                _.get(formik.touched, 'filters.bulkCountMax', false) &&
                                                _.get(formik.errors, 'filters.bulkCountMax', false)
                                            }
                                            disabled={!useCustomerFilters}
                                            onChange={e => {
                                                const value = e.target.value.trim();
                                                if (value === '' || isNaN(value)) {
                                                    formik.setFieldValue('filters.bulkCountMax', undefined);
                                                } else {
                                                    formik.setFieldValue('filters.bulkCountMax', parseInt(value));
                                                }
                                            }}
                                        />
                                    </Grid>
                                </>
                            )}
                            <Grid item xs={12} md={4}>
                                <div>
                                    <FormControl
                                        fullWidth
                                        error={
                                            _.get(formik.touched, 'filters.beforeAfter', false) &&
                                            _.get(formik.errors, 'filters.beforeAfter', false)
                                        }
                                    >
                                        <Select
                                            {...formik.getFieldProps('values.filters.beforeAfter')}
                                            value={_.get(formik, 'values.filters.beforeAfter', undefined)}
                                            disabled={!useCustomerFilters}
                                            displayEmpty={true}
                                            renderValue={s => (!_.isNil(s) ? `${s}` : 'Before/After')}
                                            input={<OutlinedInput labelWidth={0} name="filters.beforeAfter" />}
                                            onChange={e => {
                                                const selectedValue = e.target.value;
                                                // Toggle selection
                                                const newValue =
                                                    _.get(formik, 'values.filters.beforeAfter') === selectedValue
                                                        ? undefined
                                                        : selectedValue;
                                                formik.setFieldValue('filters.beforeAfter', newValue);
                                            }}
                                        >
                                            {!_.isNil(BEFORE_AFTER) &&
                                                BEFORE_AFTER.map(s => (
                                                    <MenuItem key={s} value={s}>
                                                        {/* <Checkbox
                                                            checked={
                                                                _.get(
                                                                    formik,
                                                                    'values.filters.beforeAfter',
                                                                    undefined
                                                                ) === s
                                                            }
                                                        /> */}
                                                        <ListItemText>{s}</ListItemText>
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                        {_.get(formik.touched, 'filters.beforeAfter', false) &&
                                            _.get(formik.errors, 'filters.beforeAfter', false) && (
                                                <FormHelperText>
                                                    values.filters
                                                    {_.get(formik.touched, 'filters.beforeAfter', false) &&
                                                        _.get(formik.errors, 'filters.beforeAfter', '')}
                                                </FormHelperText>
                                            )}
                                    </FormControl>
                                </div>
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <DatePicker
                                    timezone={timezone}
                                    type="end"
                                    label="Date"
                                    format="DD MMM YYYY"
                                    value={moment(_.get(formik, 'values.filters.lastBulkDate')).tz(timezone)}
                                    style={{ flex: 1 }}
                                    inputStyle={{ width: '100%' }}
                                    variant="outlined"
                                    onChange={d => formik.setFieldValue('filters.lastBulkDate', d.toDate())}
                                    disabled={!useCustomerFilters}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={theme.spacing.unit} item xs={12} md={2} />
                        <Grid container spacing={theme.spacing.unit} item xs={12} md={10}>
                            <Grid item xs={12} md={4}>
                                <FormControl
                                    style={{ flexGrow: 1 }}
                                    fullWidth
                                    error={
                                        _.get(formik.touched, 'filters.customerCollectors', false) &&
                                        _.get(formik.errors, 'filters.customerCollectors', false)
                                    }
                                >
                                    <Select
                                        {...formik.getFieldProps('filters.customerCollectors')}
                                        value={_.get(formik, 'values.filters.customerCollectors', [])}
                                        displayEmpty={true}
                                        disabled={!useCustomerFilters}
                                        multiple
                                        renderValue={x => {
                                            if (_.isEmpty(x)) {
                                                return 'All Collectors';
                                            } else if (x.length > 0 && x.length <= 2) {
                                                const collectorNames = x
                                                    .map(id => {
                                                        const collector = collectors.find(
                                                            collector => collector._id === id
                                                        );
                                                        return collector ? collector.name : '';
                                                    })
                                                    .filter(name => name !== '');
                                                return collectorNames.join(', ');
                                            } else {
                                                return `${x.length} Collectors`;
                                            }
                                        }}
                                        input={<OutlinedInput labelWidth={0} name="filters.customerCollectors" />}
                                    >
                                        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                            <Button
                                                onClick={e => {
                                                    e.stopPropagation();
                                                    formik.setFieldValue(
                                                        'filters.customerCollectors',
                                                        collectors.map(c => c._id)
                                                    );
                                                }}
                                            >
                                                All
                                            </Button>
                                            <Button
                                                onClick={e => {
                                                    e.stopPropagation();
                                                    formik.setFieldValue('filters.customerCollectors', []);
                                                }}
                                            >
                                                None
                                            </Button>
                                        </div>
                                        {!_.isNil(collectors) &&
                                            collectors.map(c => (
                                                <MenuItem key={c._id} value={c._id}>
                                                    <Checkbox
                                                        checked={_.get(
                                                            formik,
                                                            'values.filters.customerCollectors',
                                                            []
                                                        ).includes(c._id)}
                                                    />
                                                    <ListItemText>{c.name}</ListItemText>
                                                </MenuItem>
                                            ))}
                                    </Select>
                                    {_.get(formik.touched, 'filters.customerCollectors', false) &&
                                        _.get(formik.errors, 'filters.customerCollectors', false) && (
                                            <FormHelperText>
                                                {_.get(formik.touched, 'filters.customerCollectors', false) &&
                                                    _.get(formik.errors, 'filters.customerCollectors', '')}
                                            </FormHelperText>
                                        )}
                                </FormControl>
                            </Grid>
                            {(_.isEmpty(formik.values.filters.orderTypes) ||
                                formik.values.filters.orderTypes.includes('Pickup')) && (
                                <Grid item xs={12} md={4}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <FormControl
                                            style={{ flexGrow: 1 }}
                                            error={
                                                _.get(formik.touched, 'filters.zones', false) &&
                                                _.get(formik.errors, 'filters.zones', false)
                                            }
                                        >
                                            <Select
                                                {...formik.getFieldProps('filters.zones')}
                                                value={_.get(formik, 'values.filters.zones', [])}
                                                disabled={!useCustomerFilters}
                                                multiple
                                                displayEmpty={true}
                                                renderValue={x => {
                                                    if (_.isEmpty(x)) {
                                                        return 'All Zones';
                                                    } else if (x.length > 0 && x.length <= 2) {
                                                        const result = x
                                                            .map(id => {
                                                                const zone = zones.find(z => z._id === id);
                                                                return zone ? zone.name : '';
                                                            })
                                                            .filter(name => name !== '');
                                                        return result.join(', ');
                                                    } else {
                                                        return `${x.length} Zones`;
                                                    }
                                                }}
                                                input={<OutlinedInput labelWidth={0} name="filters.zones" />}
                                            >
                                                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                    <Button
                                                        onClick={e => {
                                                            e.stopPropagation();
                                                            formik.setFieldValue(
                                                                'filters.zones',
                                                                zones.map(zone => zone._id)
                                                            );
                                                        }}
                                                    >
                                                        All
                                                    </Button>
                                                    <Button
                                                        onClick={e => {
                                                            e.stopPropagation();
                                                            formik.setFieldValue('filters.zones', []);
                                                        }}
                                                    >
                                                        None
                                                    </Button>
                                                </div>
                                                {!_.isNil(zones) &&
                                                    zones.map(zone => (
                                                        <MenuItem key={zone._id} value={zone._id}>
                                                            <Checkbox
                                                                checked={_.get(
                                                                    formik,
                                                                    'values.filters.zones',
                                                                    []
                                                                ).includes(zone._id)}
                                                            />
                                                            <ListItemText>{zone.name}</ListItemText>
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                            {_.get(formik.touched, 'filters.zones', false) &&
                                                _.get(formik.errors, 'filters.zones', false) && (
                                                    <FormHelperText>
                                                        {_.get(formik.touched, 'filters.zones', false) &&
                                                            _.get(formik.errors, 'filters.zones', '')}
                                                    </FormHelperText>
                                                )}
                                        </FormControl>
                                    </div>
                                </Grid>
                            )}
                            <Grid item xs={12} md={4}>
                                <div>
                                    <FormControl
                                        fullWidth
                                        error={
                                            _.get(formik.touched, 'filters.containsTypes', false) &&
                                            _.get(formik.errors, 'filters.containsTypes', false)
                                        }
                                    >
                                        <Select
                                            {...formik.getFieldProps('values.filters.containsTypes')}
                                            value={_.get(formik, 'values.filters.containsTypes', [])}
                                            disabled={!useCustomerFilters}
                                            displayEmpty={true}
                                            multiple
                                            // renderValue={x => _.isEmpty(x)? 'All Contains': x.length > 0 && x.length <= 2 ? x.join(", ") : `${x.length} Contains`}
                                            renderValue={x => 'All Contains - TBD'}
                                            input={<OutlinedInput labelWidth={0} name="filters.containsTypes" />}
                                            onChange={e =>
                                                formik.setFieldValue('filters.containsTypes', e.target.value)
                                            }
                                        >
                                            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                <Button
                                                    onClick={e => {
                                                        e.stopPropagation();
                                                        formik.setFieldValue('filters.containsTypes', CONTAINS_TYPES);
                                                    }}
                                                >
                                                    All
                                                </Button>
                                                <Button
                                                    onClick={e => {
                                                        e.stopPropagation();
                                                        formik.setFieldValue('filters.containsTypes', []);
                                                    }}
                                                >
                                                    None
                                                </Button>
                                            </div>
                                            {!_.isNil(CONTAINS_TYPES) &&
                                                CONTAINS_TYPES.map(c => (
                                                    <MenuItem key={c} value={c}>
                                                        <Checkbox
                                                            checked={_.get(
                                                                formik,
                                                                'values.filters.containsTypes',
                                                                []
                                                            ).includes(c)}
                                                        />
                                                        <ListItemText>{c}</ListItemText>
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                        {_.get(formik.touched, 'filters.containsTypes', false) &&
                                            _.get(formik.errors, 'filters.containsTypes', false) && (
                                                <FormHelperText>
                                                    values.filters
                                                    {_.get(formik.touched, 'filters.containsTypes', false) &&
                                                        _.get(formik.errors, 'filters.containsTypes', '')}
                                                </FormHelperText>
                                            )}
                                    </FormControl>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container spacing={theme.spacing.unit} item xs={12} md={2}>
                            <Grid
                                item
                                xs={12}
                                md={12}
                                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                            >
                                <Typography variant="subtitle2" style={{ textAlign: 'center' }}>
                                    Donation Preference
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid container spacing={theme.spacing.unit} item xs={12} md={10}>
                            <Grid item xs={12} md={6}>
                                <div>
                                    <FormControl
                                        fullWidth
                                        error={
                                            _.get(formik.touched, 'filters.donationOption', false) &&
                                            _.get(formik.errors, 'filters.donationOption', false)
                                        }
                                    >
                                        <Select
                                            {...formik.getFieldProps('values.filters.donationOption')}
                                            value={_.get(
                                                formik,
                                                'values.filters.donationOption',
                                                'All Donation Preferences'
                                            )}
                                            disabled={!useCustomerFilters}
                                            displayEmpty={true}
                                            renderValue={s => `${s}`}
                                            input={<OutlinedInput labelWidth={0} name="filters.donationOption" />}
                                            onChange={e =>
                                                formik.setFieldValue('filters.donationOption', e.target.value)
                                            }
                                        >
                                            {!_.isNil(DONATION_QUERY_OPTIONS) &&
                                                DONATION_QUERY_OPTIONS.map(s => (
                                                    <MenuItem key={s} value={s}>
                                                        <ListItemText>{s}</ListItemText>
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                        {_.get(formik.touched, 'filters.donationOption', false) &&
                                            _.get(formik.errors, 'filters.donationOption', false) && (
                                                <FormHelperText>
                                                    {_.get(formik.touched, 'filters.donationOption', false) &&
                                                        _.get(formik.errors, 'filters.donationOption', '')}
                                                </FormHelperText>
                                            )}
                                    </FormControl>
                                </div>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <CharityMultiSelect
                                    label={`${terms.ORGANIZATION_NAME}`}
                                    hideTaxReciptAndSubdivision
                                    donate={donate}
                                    charities={charities}
                                    charitiesPreferred={_.get(formik, 'values.filters.donationPreference', [])}
                                    disableTextField={
                                        _.get(formik.values, 'filters.includeCustomers') &&
                                        _.get(formik.values, 'filters.donationOption') === 'Specific Charities'
                                    }
                                    subdivisionPreferred={null}
                                    handleCharitiesPreferred={handleCustomerCharitiesPreferred}
                                    clearCharitiesPreferred={clearCustomerCharitiesPreferred}
                                    onChange={handleSubdivisionPreferred}
                                    onSwitch={() => handleSetDonation(!donate)}
                                    onSetDonation={handleSetDonation}
                                    TextFieldProps={{
                                        helperText:
                                            _.get(formik.touched, 'donationPreference', false) &&
                                            _.get(formik.errors, 'donationPreference', false),
                                        error:
                                            _.get(formik.touched, 'donationPreference', false) &&
                                            _.get(formik.errors, 'donationPreference', '')
                                    }}
                                    placeholder=""
                                    filterToggle={useCustomerFilters}
                                />
                                {_.get(formik.touched, 'filters.donationPreference', false) &&
                                    _.get(formik.errors, 'filters.donationPreference', false) && (
                                        <FormHelperText style={{ color: 'red' }}>
                                            {_.get(formik.touched, 'filters.donationPreference', false) &&
                                                _.get(formik.errors, 'filters.donationPreference', '')}
                                        </FormHelperText>
                                    )}
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* CHARITY/COLLECTOR FILTER CONTAINER */}
                    <Grid container spacing={theme.spacing.unit} alignContent="flex-start" item xs={12} md={4}>
                        <Grid item xs={12}>
                            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                <Typography variant="h6">Charity Filters</Typography>
                                <Switch
                                    {...formik.getFieldProps('filters.includeCharities')}
                                    onChange={e => {
                                        formik.setFieldValue('filters.includeCharities', e.target.checked);
                                        if (e.target.checked) {
                                            formik.setFieldValue('filters.includeCustomers', false);
                                            formik.setFieldValue('filters.includeCollectors', false);
                                        }
                                    }}
                                    checked={_.get(formik.values, 'filters.includeCharities')}
                                />
                                <Tooltip
                                    title={'This will return charity and charity administrator, information.'}
                                    data-cy={`config-charity-filter-text`}
                                    style={{ color: theme.palette.text.disabled, fontSize: 24 }}
                                >
                                    <Icon data-cy={`config-info-icon`}>info</Icon>
                                </Tooltip>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <FormControl
                                style={{ flexGrow: 1 }}
                                fullWidth
                                error={
                                    _.get(formik.touched, 'filters.regions', false) &&
                                    _.get(formik.errors, 'filters.regions', false)
                                }
                            >
                                <Select
                                    {...formik.getFieldProps('filters.regions')}
                                    value={_.get(formik, 'values.filters.regions', [])}
                                    disabled={!useCharityFilters}
                                    multiple
                                    displayEmpty={true}
                                    renderValue={x => {
                                        if (_.isEmpty(x)) {
                                            return 'All Regions';
                                        } else if (x.length > 0 && x.length <= 2) {
                                            const regionNames = x
                                                .map(id => {
                                                    const query = regions.find(z => z._id === id);
                                                    return query ? query.name : '';
                                                })
                                                .filter(name => name !== '');
                                            return regionNames.join(', ');
                                        } else {
                                            return `${x.length} Regions`;
                                        }
                                    }}
                                    input={<OutlinedInput labelWidth={0} name="filters.regions" />}
                                >
                                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                        <Button
                                            onClick={e => {
                                                e.stopPropagation();
                                                formik.setFieldValue('filters.regions', regions.map(zone => zone._id));
                                            }}
                                        >
                                            All
                                        </Button>
                                        <Button
                                            onClick={e => {
                                                e.stopPropagation();
                                                formik.setFieldValue('filters.regions', []);
                                            }}
                                        >
                                            None
                                        </Button>
                                    </div>
                                    {!_.isNil(regions) &&
                                        regions.map(r => (
                                            <MenuItem key={r._id} value={r._id}>
                                                <Checkbox
                                                    checked={_.get(formik, 'values.filters.regions', []).includes(
                                                        r._id
                                                    )}
                                                />
                                                <ListItemText>{r.name}</ListItemText>
                                            </MenuItem>
                                        ))}
                                </Select>
                                {_.get(formik.touched, 'filters.regions', false) &&
                                    _.get(formik.errors, 'filters.regions', false) && (
                                        <FormHelperText>
                                            {_.get(formik.touched, 'filters.regions', false) &&
                                                _.get(formik.errors, 'filters.regions', '')}
                                        </FormHelperText>
                                    )}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <CharityMultiSelect
                                label={`${terms.ORGANIZATION_NAME}`}
                                hideTaxReciptAndSubdivision
                                donate={donate}
                                charities={charities}
                                charitiesPreferred={_.get(formik, 'values.filters.charityPreference', [])}
                                disableTextField={_.get(formik.values, 'filters.includeCharities')}
                                subdivisionPreferred={null}
                                handleCharitiesPreferred={handleCharitiesPreferred}
                                clearCharitiesPreferred={clearCharitiesPreferred}
                                onChange={handleSubdivisionPreferred}
                                onSwitch={() => handleSetDonation(!donate)}
                                onSetDonation={handleSetDonation}
                                TextFieldProps={{
                                    helperText:
                                        _.get(formik.touched, 'charity', false) &&
                                        _.get(formik.errors, 'charity', false),
                                    error:
                                        _.get(formik.touched, 'charity', false) && _.get(formik.errors, 'charity', '')
                                }}
                                placeholder=""
                                filterToggle={useCharityFilters}
                            />
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            md={4}
                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                        >
                            <Typography style={{ textAlign: 'center' }} variant="subtitle2">
                                Total Donated
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                fullWidth
                                {...formik.getFieldProps('filters.donationMin')}
                                type="number"
                                label="Min"
                                variant="outlined"
                                style={{ flex: 1 }}
                                helperText={
                                    _.get(formik.touched, 'filters.donationMin', false) &&
                                    _.get(formik.errors, 'filters.donationMin', '')
                                }
                                error={
                                    _.get(formik.touched, 'filters.donationMin', false) &&
                                    _.get(formik.errors, 'filters.donationMin', false)
                                }
                                disabled={!useCharityFilters}
                                onChange={e => {
                                    const value = e.target.value.trim();
                                    if (value === '' || isNaN(value)) {
                                        formik.setFieldValue('filters.donationMin', undefined);
                                    } else {
                                        formik.setFieldValue('filters.donationMin', parseInt(value));
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                fullWidth
                                {...formik.getFieldProps('filters.donationMax')}
                                type="number"
                                label="Max"
                                variant="outlined"
                                style={{ flex: 1 }}
                                helperText={
                                    _.get(formik.touched, 'filters.donationMax', false) &&
                                    _.get(formik.errors, 'filters.donationMax', '')
                                }
                                error={
                                    _.get(formik.touched, 'filters.donationMax', false) &&
                                    _.get(formik.errors, 'filters.donationMax', false)
                                }
                                disabled={!useCharityFilters}
                                onChange={e => {
                                    const value = e.target.value.trim();
                                    if (value === '' || isNaN(value)) {
                                        formik.setFieldValue('filters.donationMax', undefined);
                                    } else {
                                        formik.setFieldValue('filters.donationMax', parseInt(value));
                                    }
                                }}
                            />
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            md={4}
                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                        >
                            <Typography style={{ textAlign: 'center' }} variant="subtitle2">
                                Tasks Completed
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                fullWidth
                                {...formik.getFieldProps('filters.tasksMin')}
                                type="number"
                                label="Min"
                                variant="outlined"
                                style={{ flex: 1 }}
                                helperText={
                                    _.get(formik.touched, 'filters.tasksMin', false) &&
                                    _.get(formik.errors, 'filters.tasksMin', '')
                                }
                                error={
                                    _.get(formik.touched, 'filters.tasksMin', false) &&
                                    _.get(formik.errors, 'filters.tasksMin', false)
                                }
                                disabled={!useCharityFilters}
                                onChange={e => {
                                    const value = e.target.value.trim();
                                    if (value === '' || isNaN(value)) {
                                        formik.setFieldValue('filters.tasksMin', undefined);
                                    } else {
                                        formik.setFieldValue('filters.tasksMin', parseInt(value));
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                fullWidth
                                {...formik.getFieldProps('filters.tasksMax')}
                                type="number"
                                label="Max"
                                variant="outlined"
                                style={{ flex: 1 }}
                                helperText={
                                    _.get(formik.touched, 'filters.tasksMax', false) &&
                                    _.get(formik.errors, 'filters.tasksMax', '')
                                }
                                error={
                                    _.get(formik.touched, 'filters.tasksMax', false) &&
                                    _.get(formik.errors, 'filters.tasksMax', false)
                                }
                                disabled={!useCharityFilters}
                                onChange={e => {
                                    const value = e.target.value.trim();
                                    if (value === '' || isNaN(value)) {
                                        formik.setFieldValue('filters.tasksMax', undefined);
                                    } else {
                                        formik.setFieldValue('filters.tasksMax', parseInt(value));
                                    }
                                }}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={4}
                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                        >
                            <Typography style={{ textAlign: 'center' }} variant="subtitle2">
                                Questionnaire Select
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <div>
                                <FormControl
                                    fullWidth
                                    error={
                                        _.get(formik.touched, 'filters.questionnaireQuestionCharity', false) &&
                                        _.get(formik.errors, 'filters.questionnaireQuestionCharity', false)
                                    }
                                >
                                    <Select
                                        {...formik.getFieldProps('values.filters.questionnaireQuestionCharity')}
                                        value={_.get(formik, 'values.filters.questionnaireQuestionCharity', undefined)}
                                        disabled={!useCharityFilters}
                                        displayEmpty={true}
                                        renderValue={x => {
                                            const question = questions.find(q => q._id === x);
                                            return question ? question.description.en : 'No Question Selection';
                                        }}
                                        input={<OutlinedInput name="filters.questionnaireQuestionCharity" />}
                                        onChange={e => {
                                            const selectedValue = e.target.value;
                                            // Toggle selection
                                            const newValue =
                                                _.get(formik, 'values.filters.questionnaireQuestionCharity') ===
                                                selectedValue
                                                    ? undefined
                                                    : selectedValue;

                                            formik.setFieldValue('filters.questionnaireAnswerCharity', []);

                                            formik.setFieldValue('filters.questionnaireQuestionCharity', newValue);
                                        }}
                                    >
                                        {!_.isNil(questions) &&
                                            questions.map(q => (
                                                <MenuItem key={q._id} value={q._id}>
                                                    {/* <Checkbox
                                                        checked={_.get(
                                                            formik,
                                                            'values.filters.questionnaireQuestionCharity',
                                                            []
                                                        ).includes(q._id)}
                                                    /> */}
                                                    <ListItemText>{q.description.en}</ListItemText>
                                                </MenuItem>
                                            ))}
                                    </Select>
                                    {_.get(formik.touched, 'filters.questionnaireQuestionCharity', false) &&
                                        _.get(formik.errors, 'filters.questionnaireQuestionCharity', false) && (
                                            <FormHelperText>
                                                values.filters
                                                {_.get(formik.touched, 'filters.questionnaireQuestionCharity', false) &&
                                                    _.get(formik.errors, 'filters.questionnaireQuestionCharity', '')}
                                            </FormHelperText>
                                        )}
                                </FormControl>
                            </div>
                        </Grid>
                        {!_.isNil(_.get(formik, 'values.filters.questionnaireQuestionCharity', undefined)) && (
                            <>
                                <Grid
                                    item
                                    xs={12}
                                    md={4}
                                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                >
                                    <Typography style={{ textAlign: 'center' }} variant="subtitle2">
                                        Responses
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <div>
                                        <FormControl
                                            fullWidth
                                            error={
                                                _.get(formik.touched, 'filters.questionnaireAnswerCharity', false) &&
                                                _.get(formik.errors, 'filters.questionnaireAnswerCharity', false)
                                            }
                                        >
                                            <Select
                                                {...formik.getFieldProps('values.filters.questionnaireAnswerCharity')}
                                                value={_.get(formik, 'values.filters.questionnaireAnswerCharity', [])}
                                                disabled={!useCharityFilters}
                                                multiple
                                                displayEmpty={true}
                                                renderValue={x =>
                                                    _.isEmpty(x) ? 'No Selection' : `${x.length} Responses`
                                                }
                                                input={<OutlinedInput name="filters.questionnaireAnswerCharity" />}
                                                onChange={e => {
                                                    formik.setFieldValue(
                                                        'filters.questionnaireAnswerCharity',
                                                        e.target.value
                                                    );
                                                }}
                                            >
                                                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                    <Button
                                                        onClick={e => {
                                                            e.stopPropagation();
                                                            const selectedQuestion = questions.find(
                                                                q =>
                                                                    q._id ===
                                                                    _.get(
                                                                        formik,
                                                                        'values.filters.questionnaireQuestionCharity'
                                                                    )
                                                            );

                                                            if (selectedQuestion) {
                                                                formik.setFieldValue(
                                                                    'filters.questionnaireAnswerCharity',
                                                                    selectedQuestion.responses.map(ans => ans.en)
                                                                );
                                                            }
                                                        }}
                                                    >
                                                        All
                                                    </Button>
                                                    <Button
                                                        onClick={e => {
                                                            e.stopPropagation();
                                                            formik.setFieldValue(
                                                                'filters.questionnaireAnswerCharity',
                                                                []
                                                            );
                                                        }}
                                                    >
                                                        None
                                                    </Button>
                                                </div>
                                                {!_.isNil(
                                                    _.get(formik, 'values.filters.questionnaireQuestionCharity')
                                                ) &&
                                                    (() => {
                                                        const selectedQuestion = questions.find(
                                                            q =>
                                                                q._id ===
                                                                _.get(
                                                                    formik,
                                                                    'values.filters.questionnaireQuestionCharity'
                                                                )
                                                        );

                                                        if (selectedQuestion) {
                                                            return selectedQuestion.responses.map(ans => (
                                                                <MenuItem key={ans.en} value={ans.en}>
                                                                    <Checkbox
                                                                        checked={_.get(
                                                                            formik,
                                                                            'values.filters.questionnaireAnswerCharity',
                                                                            []
                                                                        ).includes(ans.en)}
                                                                    />
                                                                    <ListItemText>{ans.en}</ListItemText>
                                                                </MenuItem>
                                                            ));
                                                        } else {
                                                            return null;
                                                        }
                                                    })()}
                                            </Select>
                                            {_.get(formik.touched, 'filters.questionnaireAnswerCharity', false) &&
                                                _.get(formik.errors, 'filters.questionnaireAnswerCharity', false) && (
                                                    <FormHelperText>
                                                        {_.get(
                                                            formik.touched,
                                                            'filters.questionnaireAnswerCharity',
                                                            false
                                                        ) &&
                                                            _.get(
                                                                formik.errors,
                                                                'filters.questionnaireAnswerCharity',
                                                                ''
                                                            )}
                                                    </FormHelperText>
                                                )}
                                        </FormControl>
                                    </div>
                                </Grid>
                            </>
                        )}

                        {/* <Grid item xs={12}>
                            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                <Switch
                                />
                                <Typography variant='subtitle1'>Duplicate for account owner</Typography>
                                
                            </div>
                        </Grid>                 */}

                        {/* <Grid item xs={12}>
                            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                <Typography variant="h6">Collector Filters</Typography>
                                <Switch
                                    {...formik.getFieldProps('filters.includeCollectors')}
                                    onChange={e => {
                                        formik.setFieldValue('filters.includeCollectors', e.target.checked);
                                        if (e.target.checked) {
                                            formik.setFieldValue('filters.includeCustomers', false);
                                            formik.setFieldValue('filters.includeCharities', false);
                                        }
                                    }}
                                    checked={_.get(formik.values, 'filters.includeCollectors')}
                                />
                                <Tooltip
                                    title={'This will return collector manager, driver, and clerk information.'}
                                    data-cy={`config-collector-filter-text`}
                                    style={{ color: theme.palette.text.disabled, fontSize: 24 }}
                                >
                                    <Icon data-cy={`config-info-icon`}>info</Icon>
                                </Tooltip>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <FormControl
                                style={{ flexGrow: 1 }}
                                fullWidth
                                error={
                                    _.get(formik.touched, 'filters.collectorCollectors', false) &&
                                    _.get(formik.errors, 'filters.collectorCollectors', false)
                                }
                            >
                                <Select
                                    {...formik.getFieldProps('filters.collectorCollectors')}
                                    value={_.get(formik, 'values.filters.collectorCollectors', [])}
                                    disabled={!useCollectorFilters}
                                    multiple
                                    displayEmpty={true}
                                    renderValue={x => {
                                        if (_.isEmpty(x)) {
                                            return 'All Collectors';
                                        } else if (x.length > 0 && x.length <= 2) {
                                            const collectorNames = x
                                                .map(id => {
                                                    const collector = collectors.find(
                                                        collector => collector._id === id
                                                    );
                                                    return collector ? collector.name : '';
                                                })
                                                .filter(name => name !== '');
                                            return collectorNames.join(', ');
                                        } else {
                                            return `${x.length} Collectors`;
                                        }
                                    }}
                                    input={<OutlinedInput labelWidth={0} name="filters.collectorCollectors" />}
                                >
                                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                        <Button
                                            onClick={e => {
                                                e.stopPropagation();
                                                formik.setFieldValue(
                                                    'filters.collectorCollectors',
                                                    collectors.map(c => c._id)
                                                );
                                            }}
                                        >
                                            All
                                        </Button>
                                        <Button
                                            onClick={e => {
                                                e.stopPropagation();
                                                formik.setFieldValue('filters.collectorCollectors', []);
                                            }}
                                        >
                                            None
                                        </Button>
                                    </div>
                                    {!_.isNil(collectors) &&
                                        collectors.map(c => (
                                            <MenuItem key={c._id} value={c._id}>
                                                <Checkbox
                                                    checked={_.get(
                                                        formik,
                                                        'values.filters.collectorCollectors',
                                                        []
                                                    ).includes(c._id)}
                                                />
                                                <ListItemText>{c.name}</ListItemText>
                                            </MenuItem>
                                        ))}
                                </Select>
                                {_.get(formik.touched, 'filters.collectorCollectors', false) &&
                                    _.get(formik.errors, 'filters.collectorCollectors', false) && (
                                        <FormHelperText>
                                            {_.get(formik.touched, 'filters.collectorCollectors', false) &&
                                                _.get(formik.errors, 'filters.collectorCollectors', '')}
                                        </FormHelperText>
                                    )}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <FormControl
                                style={{ flexGrow: 1 }}
                                fullWidth
                                error={
                                    _.get(formik.touched, 'filters.collectorAccountTypes', false) &&
                                    _.get(formik.errors, 'filters.collectorAccountTypes', false)
                                }
                            >
                                <Select
                                    {...formik.getFieldProps('filters.collectorAccountTypes')}
                                    value={_.get(formik, 'values.filters.collectorAccountTypes', [])}
                                    disabled={
                                        !_.get(formik, 'values.filters.collectorAccountTypes', false) ||
                                        !useCollectorFilters
                                    }
                                    multiple
                                    displayEmpty={true}
                                    renderValue={x =>
                                        _.isEmpty(x)
                                            ? 'All Account Types'
                                            : x.length > 0 && x.length <= 3
                                            ? x.join(', ')
                                            : `${x.length} Account Types`
                                    }
                                    input={<OutlinedInput labelWidth={0} name="filters.collectorAccountTypes" />}
                                >
                                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                        <Button
                                            onClick={e => {
                                                e.stopPropagation();
                                                formik.setFieldValue(
                                                    'filters.collectorAccountTypes',
                                                    COLLECTOR_ACCOUNT_TYPES
                                                );
                                            }}
                                        >
                                            All
                                        </Button>
                                        <Button
                                            onClick={e => {
                                                e.stopPropagation();
                                                formik.setFieldValue('filters.collectorAccountTypes', []);
                                            }}
                                        >
                                            None
                                        </Button>
                                    </div>
                                    {COLLECTOR_ACCOUNT_TYPES.map(accountType => (
                                        <MenuItem key={accountType} value={accountType}>
                                            <Checkbox
                                                checked={_.get(
                                                    formik,
                                                    'values.filters.collectorAccountTypes',
                                                    []
                                                ).includes(accountType)}
                                            />
                                            <ListItemText>{accountType}</ListItemText>
                                        </MenuItem>
                                    ))}
                                </Select>
                                {_.get(formik.touched, 'filters.collectorAccountTypes', false) &&
                                    _.get(formik.errors, 'filters.collectorAccountTypes', false) && (
                                        <FormHelperText>
                                            {_.get(formik.touched, 'filters.collectorAccountTypes', false) &&
                                                _.get(formik.errors, 'filters.collectorAccountTypes', '')}
                                        </FormHelperText>
                                    )}
                            </FormControl>
                        </Grid> */}
                    </Grid>
                    <Divider style={{ marginTop: theme.spacing.unit }} />

                    {/* Heading for the TextField */}

                    <Grid container spacing={theme.spacing.unit} item xs={12} md={12}>
                        <Grid item xs={12} md={12}>
                            <Typography variant="body1" style={{ marginBottom: 0 }}>
                                What is being Queried:
                            </Typography>
                            <Typography variant="body2" style={{ marginBottom: 0 }}>
                                <pre>{queryValue}</pre>
                            </Typography>
                        </Grid>
                    </Grid>

                    {/*<Grid item xs={12} md={4}>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button
                                size="large"
                                variant="outlined"
                                color="primary"
                                onClick={handleDownloadUsers}
                                disabled={loadingCustomerReport}
                            >
                                Download Users{' '}
                                {loadingCustomerReport && (
                                    <CircularProgress size={16} style={{ marginLeft: theme.spacing.unit }} />
                                )}
                            </Button>
                        </div>
                                </Grid>*/}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">
                    Cancel
                </Button>
                <Button
                    onClick={formik.handleSubmit}
                    color="primary"
                    disabled={
                        !_.get(formik, 'values.filters.includeCustomers', true) &&
                        !_.get(formik, 'values.filters.includeCharities', false) &&
                        !_.get(formik, 'values.filters.includeCollectors', false)
                    }
                >
                    Submit
                </Button>
            </DialogActions>
            {/* {loading && <LinearProgress />} */}
        </Dialog>
    );
}

export default withMobileDialog()(withTheme()(SendOutListDialog));
