import { useEffect, useState } from 'react';

const useDefaultCoordinates = http => {
    const [defaultCoordinates, setDefaultCoordinates] = useState({ latitude: 0, longitude: 0 });
    const [loading, setLoading] = useState(false);

    const fetchDefaultCoordinates = async () => {
        setLoading(true);

        const res = await http.getJSON('/config/defaultCoordinates');
        if (res.ok) {
            setDefaultCoordinates(res.data.defaultCoordinates);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchDefaultCoordinates();
    }, []);

    return {
        defaultCoordinates,
        loading
    };
};

export default useDefaultCoordinates;
