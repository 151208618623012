import React, { useState, useContext } from 'react';
import _, { startCase } from 'lodash';

import {
    DialogActions,
    Dialog,
    DialogContent,
    Button,
    Typography,
    TextField,
    InputLabel,
    Select,
    OutlinedInput,
    MenuItem,
    withTheme,
    withMobileDialog,
    DialogTitle,
    FormControl,
    IconButton,
    Grid,
    Paper,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction
} from '@material-ui/core';

import { mdiDrag, mdiTrashCan } from '@mdi/js';

import { Icon as MDIcon } from '@mdi/react';
import * as allIcons from '@mdi/js';

import SearchIcon from '@material-ui/icons/Search';
import * as colors from '@material-ui/core/colors';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';
import useWindowSize from 'utils/hooks/useWindowSize';

import useGetJSON from '../../../../utils/hooks/useGetJSON';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import HttpContext from 'utils/contexts/HttpContext';
import { useEffect } from 'react';

function ConfigurationEditDialog(props) {
    const {
        open,
        onClose,
        theme,
        handleChangeConfig,
        handleChangeSpecialConfig,
        currentConfigForSku,
        onSnackbar,
        onReload,
        collector
    } = props;

    const { lang } = useContext(LocalizationContext);

    const http = useContext(HttpContext);

    const widthList = ['Full', '1/2', '1/3', '1/4'];
    const [width, height] = useWindowSize();

    let mobileMode = width <= 600;

    const [newCapacity, setNewCapacity] = useState(null);
    const [oldCapacity, setOldCapacity] = useState(null);

    const [newWidth, setNewWidth] = useState(null);
    const [oldWidth, setOldWidth] = useState(null);

    const [newIcon, setNewIcon] = useState(null);
    const [oldIcon, setOldIcon] = useState(null);

    const [newIconColor, setNewIconColor] = useState(null);
    const [oldIconColor, setOldIconColor] = useState(null);

    const [newBackgroundColor, setNewBackgroundColor] = useState(null);
    const [oldBackgroundColor, setOldBackgroundColor] = useState(null);

    const [newTextColor, setNewTextColor] = useState(null);
    const [oldTextColor, setOldTextColor] = useState(null);

    const [newName, setNewName] = useState(null);
    const [oldName, setOldName] = useState(null);

    const [newGroupFees, setNewGroupFees] = useState(_.get(collector), 'feeGroups', []);
    const specialConfigType = _.get(currentConfigForSku, 'special');

    const { data: groupFees = [] } = useGetJSON('/feeGroups/getAllEnabledFeeGroups', 'collectionData');

    // let colorsCopy = Array.from(colors, color => color);
    React.useEffect(() => {
        if (!Object.keys(colors).includes('white')) {
            colors['white'] = '#fff';
        }
    }, []);

    React.useEffect(() => {
        if (!_.isNil(currentConfigForSku)) {
            setNewCapacity(_.get(currentConfigForSku, 'fillCapacity', 0));
            setOldCapacity(_.get(currentConfigForSku, 'fillCapacity', 0));
            setNewWidth(_.get(currentConfigForSku, 'width', 'Full'));
            setOldWidth(_.get(currentConfigForSku, 'width', 'Full'));
            setNewIcon(_.get(currentConfigForSku, 'icon', ''));
            setOldIcon(_.get(currentConfigForSku, 'icon', ''));
            setNewIconColor(_.get(currentConfigForSku, 'iconColor', ''));
            setOldIconColor(_.get(currentConfigForSku, 'iconColor', ''));
            setNewBackgroundColor(_.get(currentConfigForSku, 'backgroundColor', ''));
            setOldBackgroundColor(_.get(currentConfigForSku, 'backgroundColor', ''));
            setNewName(_.get(currentConfigForSku, 'name', ''));
            setOldName(_.get(currentConfigForSku, 'name', ''));
            setNewTextColor(_.get(currentConfigForSku, 'textColor', ''));
            setOldTextColor(_.get(currentConfigForSku, 'textColor', ''));
        }
    }, [currentConfigForSku]);

    React.useEffect(() => {
        if (!_.isNil(currentConfigForSku)) {
            updateConfig();
        }
    }, [newCapacity, newIcon, newIconColor, newBackgroundColor, newName, newWidth, newTextColor]);

    function updateConfig() {
        var configUpdates = {
            fillCapacity: !_.isNaN(newCapacity) && newCapacity > 0 ? newCapacity : 0,
            icon: newIcon,
            iconColor: newIconColor,
            backgroundColor: newBackgroundColor,
            name: newName,
            width: newWidth,
            textColor: newTextColor
        };

        if (!specialConfigType) {
            handleChangeConfig(currentConfigForSku.sku, configUpdates);
        } else {
            handleChangeSpecialConfig(specialConfigType, configUpdates);
        }
    }

    function revertConfig() {
        var configUpdates = {
            fillCapacity: !_.isNaN(oldCapacity) && oldCapacity > 0 ? oldCapacity : 0,
            icon: oldIcon,
            iconColor: oldIconColor,
            backgroundColor: oldBackgroundColor,
            name: oldName,
            width: oldWidth,
            textColor: oldTextColor
        };

        if (!specialConfigType) {
            handleChangeConfig(currentConfigForSku.sku, configUpdates);
        } else {
            handleChangeSpecialConfig(specialConfigType, configUpdates);
        }
    }

    async function setCollectorGroupFees() {
        await http.post(`/collectors/${collector._id}/setGroupFees`, {
            groupFees: newGroupFees
        });

        collector.feeGroups = newGroupFees
    }

    useEffect(() => {
        async function getCollectorGroupFees() {
            await http.getJSON(`/collectors/${collector._id}/getGroupFees`).then(res => {
                setNewGroupFees(_.get(res, 'data.feeGroups', []));
            });
        }

        getCollectorGroupFees();
    }, [open]);

    const removeFeeGroup = feeGroup => {
        const feeToRemoveIdx = _.findIndex(newGroupFees, { name: feeGroup.name });
        const updatedGroupfees = _.cloneDeep(newGroupFees);
        updatedGroupfees.splice(feeToRemoveIdx, 1);

        setNewGroupFees(updatedGroupfees);
    };

    const handleDragEnd = ({ draggableId, destination }) => {
        if (!destination) return;

        const feeToMove = _.find(newGroupFees, { name: draggableId });
        const filteredGroupFees = _.filter(newGroupFees, fee => fee.name !== draggableId);
        const updatedGroupFees = _.cloneDeep(filteredGroupFees); // Problem if list items lose order
        updatedGroupFees.splice(destination.index, 0, feeToMove);

        setNewGroupFees(updatedGroupFees);
    };

    const addGroupFee = e => {
        const groupFee = e.target.value;
        const alreadyExists = _.find(newGroupFees, { name: groupFee.name });

        if (alreadyExists) {
            onSnackbar(`${_.get(newGroupFees, 'name', 'Group Fee')} is already added.`);
            return;
        }

        setNewGroupFees([...newGroupFees, groupFee]);
    };

    return (
        <Dialog open={open}>
            <DialogTitle>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h6" noWrap style={{ flex: 1 }}>
                        Order Station Configuration
                    </Typography>
                </div>
            </DialogTitle>
            <DialogContent>
                <div style={{ marginTop: 10 }}>
                    {mobileMode ? (
                        <>
                            <Grid container spacing={theme.spacing.unit}>
                                <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row' }}>
                                    {!_.isEmpty(newIcon) && (
                                        <div style={{ height: 50, width: 50, marginRight: theme.spacing.unit }}>
                                            <MDIcon
                                                path={_.get(allIcons, convertToImportName(newIcon))}
                                                size={2.2}
                                                color={newIconColor}
                                            />
                                        </div>
                                    )}
                                    <TextField
                                        style={{
                                            marginRight: theme.spacing.unit,
                                            width: _.isEmpty(newIcon) ? 250 : 192
                                        }}
                                        name="icon"
                                        label="Icon"
                                        variant="outlined"
                                        value={newIcon}
                                        onChange={e => {
                                            setNewIcon(e.target.value);
                                        }}
                                        data-cy={'orderStationConfigIcon'}
                                    />
                                    <IconButton
                                        onClick={() => {
                                            window.open('https://materialdesignicons.com/');
                                        }}
                                    >
                                        <SearchIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                            <Grid container spacing={theme.spacing.unit}>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        label={'Name'}
                                        InputProps={{
                                            min: 0
                                        }}
                                        value={newName}
                                        onChange={e => {
                                            let newVal = e.target.value;
                                            setNewName(newVal);
                                        }}
                                        data-cy={'orderStationConfigName'}
                                        // disabled={isFees}
                                        // style={{ visibility: isFees ? 'hidden' : undefined }}
                                    />
                                </Grid>
                            </Grid>
                        </>
                    ) : (
                        <Grid container spacing={theme.spacing.unit}>
                            <Grid item xs={6} style={{ display: 'flex', flexDirection: 'row' }}>
                                {!_.isEmpty(newIcon) && (
                                    <div style={{ height: 50, width: 50, marginRight: theme.spacing.unit }}>
                                        <MDIcon
                                            path={_.get(allIcons, convertToImportName(newIcon))}
                                            size={2.2}
                                            color={newIconColor}
                                        />
                                    </div>
                                )}
                                <TextField
                                    style={{ marginRight: theme.spacing.unit, width: _.isEmpty(newIcon) ? 250 : 192 }}
                                    name="icon"
                                    label="Icon"
                                    variant="outlined"
                                    value={newIcon}
                                    onChange={e => {
                                        setNewIcon(e.target.value);
                                    }}
                                    data-cy={'orderStationConfigIcon'}
                                />
                                <IconButton
                                    onClick={() => {
                                        window.open('https://materialdesignicons.com/');
                                    }}
                                >
                                    <SearchIcon />
                                </IconButton>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label={'Name'}
                                    InputProps={{
                                        min: 0
                                    }}
                                    value={newName}
                                    onChange={e => {
                                        let newVal = e.target.value;
                                        setNewName(newVal);
                                    }}
                                    data-cy={'orderStationConfigName'}
                                    // disabled={isFees}
                                    // style={{ visibility: isFees ? 'hidden' : undefined }}
                                />
                            </Grid>
                        </Grid>
                    )}
                    <Grid container spacing={theme.spacing.unit} style={{ marginTop: 10 }}>
                        <Grid item xs={6}>
                            <FormControl style={{ width: '100%' }} fullWidth>
                                <InputLabel variant="outlined">Icon Color</InputLabel>
                                <Select
                                    value={newIconColor}
                                    onChange={e => {
                                        let newVal = e.target.value;
                                        setNewIconColor(newVal);
                                    }}
                                    input={<OutlinedInput name="Icon Color" id="Icon Color" labelWidth={70} />}
                                    SelectDisplayProps={{ style: { display: 'flex', alignItems: 'center' } }}
                                    data-cy={'orderStationConfigIconColor'}
                                >
                                    {Object.keys(colors).map(color => {
                                        let colorName = color;
                                        let colorCode = colors[color][400];
                                        if (color === 'common') {
                                            colorName = 'Black';
                                            colorCode = '#000';
                                        } else if (color === 'white') {
                                            colorName = 'White';
                                            colorCode = '#fff';
                                        }
                                        return (
                                            <MenuItem
                                                key={colorCode}
                                                value={colorCode}
                                                data-cy={colorCode.replace('#', '')}
                                            >
                                                <div
                                                    style={{
                                                        marginRight: theme.spacing.unit,
                                                        height: 12,
                                                        width: 12,
                                                        borderRadius: '100%',
                                                        backgroundColor: colorCode
                                                    }}
                                                />
                                                {startCase(colorName)}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl style={{ width: '100%' }} fullWidth>
                                <InputLabel variant="outlined">Background Color</InputLabel>
                                <Select
                                    value={newBackgroundColor}
                                    onChange={e => {
                                        let newVal = e.target.value;
                                        setNewBackgroundColor(newVal);
                                    }}
                                    input={
                                        <OutlinedInput name="Background Color" id="Background Color" labelWidth={120} />
                                    }
                                    SelectDisplayProps={{ style: { display: 'flex', alignItems: 'center' } }}
                                    data-cy={'orderStationConfigBGColor'}
                                >
                                    {Object.keys(colors).map(color => {
                                        let colorName = color;
                                        let colorCode = colors[color][400];
                                        if (color === 'common') {
                                            colorName = 'Black';
                                            colorCode = '#000';
                                        } else if (color === 'white') {
                                            colorName = 'White';
                                            colorCode = '#fff';
                                        }
                                        return (
                                            <MenuItem
                                                key={colorCode}
                                                value={colorCode}
                                                data-cy={colorCode.replace('#', '')}
                                            >
                                                <div
                                                    style={{
                                                        marginRight: theme.spacing.unit,
                                                        height: 12,
                                                        width: 12,
                                                        borderRadius: '100%',
                                                        backgroundColor: colorCode
                                                    }}
                                                />
                                                {startCase(colorName)}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={theme.spacing.unit} style={{ marginTop: 10 }}>
                        <Grid item xs={6}>
                            <FormControl style={{ width: '100%' }} fullWidth>
                                <InputLabel variant="outlined">Text Color</InputLabel>
                                <Select
                                    value={newTextColor}
                                    onChange={e => {
                                        let newVal = e.target.value;
                                        setNewTextColor(newVal);
                                    }}
                                    input={<OutlinedInput name="Text Color" id="Text Color" labelWidth={120} />}
                                    SelectDisplayProps={{ style: { display: 'flex', alignItems: 'center' } }}
                                    data-cy={'orderStationConfigTextColor'}
                                >
                                    {Object.keys(colors).map(color => {
                                        let colorName = color;
                                        let colorCode = colors[color][400];
                                        if (color === 'common') {
                                            colorName = 'Black';
                                            colorCode = '#000';
                                        }
                                        if (color === 'white') {
                                            colorName = 'White';
                                            colorCode = '#fff';
                                        }
                                        return (
                                            <MenuItem
                                                key={colorCode}
                                                value={colorCode}
                                                data-cy={colorCode.replace('#', '')}
                                            >
                                                <div
                                                    style={{
                                                        marginRight: theme.spacing.unit,
                                                        height: 12,
                                                        width: 12,
                                                        borderRadius: '100%',
                                                        backgroundColor: colorCode
                                                    }}
                                                />
                                                {startCase(colorName)}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl style={{ width: '100%' }} fullWidth>
                                <InputLabel variant="outlined">Width</InputLabel>
                                <Select
                                    input={<OutlinedInput labelWidth={'Width'.length * theme.spacing.unit * 1.2} />}
                                    onChange={e => {
                                        setNewWidth(e.target.value);
                                    }}
                                    value={newWidth}
                                    data-cy={'orderStationConfigWidth'}
                                >
                                    {widthList.map(width => (
                                        <MenuItem key={width} value={width} data-cy={width}>
                                            {width}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={theme.spacing.unit} style={{ marginTop: 10 }}>
                        <Grid item xs={6}>
                            {!specialConfigType && (
                                <TextField
                                    fullWidth
                                    type="tel"
                                    variant="outlined"
                                    label={'Capacity'}
                                    InputProps={{
                                        min: 0
                                    }}
                                    value={newCapacity}
                                    onChange={e => {
                                        let newVal = e.target.value === '' ? 0 : e.target.value;
                                        setNewCapacity(parseInt(newVal));
                                    }}
                                    data-cy={'orderStationConfigCapacity'}
                                />
                            )}
                        </Grid>
                    </Grid>
                    {_.isEqual(specialConfigType, 'fees') && (
                        <>
                            {!_.isEmpty(newGroupFees) && !_.isNil(newGroupFees) && (
                                <Paper
                                    elevation={1}
                                    style={{
                                        width: '100%',
                                        padding: theme.spacing.unit,
                                        marginTop: theme.spacing.unit * 2,
                                        marginBottom: theme.spacing.unit
                                    }}
                                >
                                    <DragDropContext onDragEnd={handleDragEnd}>
                                        <Droppable droppableId="feeGroupList">
                                            {(provided, snapshot) => (
                                                <div ref={provided.innerRef} {...provided.droppableProps}>
                                                    <List>
                                                        {newGroupFees.map((groupFee, i) => (
                                                            <Draggable
                                                                draggableId={groupFee.name}
                                                                index={i}
                                                                key={groupFee.name}
                                                            >
                                                                {(provided, snapshot) => (
                                                                    <div
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        style={provided.draggableProps.style}
                                                                    >
                                                                        <ListItem>
                                                                            <ListItemText
                                                                                disableTypography
                                                                                style={{
                                                                                    display: 'flex',
                                                                                    alignItems: 'center',
                                                                                    marginRight: theme.spacing.unit * 4
                                                                                }}
                                                                            >
                                                                                <MDIcon
                                                                                    path={mdiDrag}
                                                                                    size={1}
                                                                                    color={theme.palette.text.secondary}
                                                                                />

                                                                                <Typography
                                                                                    style={{
                                                                                        marginRight: theme.spacing.unit
                                                                                    }}
                                                                                >
                                                                                    {groupFee.name}
                                                                                    {groupFee.disabled && (
                                                                                        <span>
                                                                                            {' '}
                                                                                            -{' '}
                                                                                            <span
                                                                                                style={{
                                                                                                    color:
                                                                                                        theme.palette
                                                                                                            .error.light
                                                                                                }}
                                                                                            >
                                                                                                Disabled
                                                                                            </span>
                                                                                        </span>
                                                                                    )}
                                                                                </Typography>
                                                                            </ListItemText>
                                                                            <ListItemSecondaryAction>
                                                                                <IconButton
                                                                                    onClick={() => {
                                                                                        removeFeeGroup(groupFee);
                                                                                    }}
                                                                                >
                                                                                    <MDIcon
                                                                                        path={mdiTrashCan}
                                                                                        size={1}
                                                                                        color={
                                                                                            theme.palette.text.secondary
                                                                                        }
                                                                                    />
                                                                                </IconButton>
                                                                            </ListItemSecondaryAction>
                                                                        </ListItem>
                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        ))}
                                                    </List>
                                                    <div style={{ width: 0 }}>{provided.placeholder}</div>
                                                </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                </Paper>
                            )}

                            <FormControl fullWidth>
                                <InputLabel variant="outlined">Add Fee Groups</InputLabel>
                                <Select
                                    fullWidth
                                    input={<OutlinedInput labelWidth={'Add Fee Groups'.length * theme.spacing.unit} />}
                                    onChange={groupFee => addGroupFee(groupFee)}
                                >
                                    {groupFees.map((groupFee, idx) => (
                                        <MenuItem key={idx} value={groupFee}>
                                            {groupFee.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </>
                    )}
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        revertConfig();
                        setNewCapacity(oldCapacity);
                        setNewIcon(oldIcon);
                        setNewIconColor(oldIconColor);
                        setNewBackgroundColor(oldBackgroundColor);
                        setNewName(oldName);
                        setNewWidth(oldWidth);
                        setNewTextColor(oldTextColor);
                        onClose();
                    }}
                    data-cy={'orderStationConfigCloseInner'}
                >
                    {loc('close', lang)}
                </Button>
                <Button
                    color="primary"
                    onClick={() => {
                        updateConfig();
                        setCollectorGroupFees();
                        setOldCapacity(newCapacity);
                        setOldIcon(newIcon);
                        setOldIconColor(newIconColor);
                        setOldBackgroundColor(newBackgroundColor);
                        setOldWidth(newWidth);
                        setOldName(newName);
                        setNewTextColor(newTextColor);
                        onClose();
                    }}
                    data-cy={'orderStationConfigSubmitInner'}
                >
                    {loc('submit', lang)}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function convertToImportName(iconName) {
    return _.camelCase(`mdi-${iconName}`);
}

export default withMobileDialog()(withTheme()(ConfigurationEditDialog));
