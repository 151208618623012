import React, { Component } from 'react';

import _ from 'lodash';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

export default class InvalidBagtagDialog extends Component {
    static contextType = LocalizationContext;

    render() {
        const { open, message } = this.props;

        return (
            <Dialog open={open} fullWidth maxWidth="xs" onClose={this.props.onClose}>
                <DialogTitle>Bagtag error!</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {!_.isNil(message) ? message : 'Unrecoverable error. Please try again.'}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={this.props.onClose}>
                        {loc('close', this.context.lang)}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}
