import React, { useEffect, useState, useContext } from 'react';

import _ from 'lodash';
import { formatAsCurrency } from 'utils/misc';

import { withTheme } from '@material-ui/core/styles';

import * as colors from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import List from '@material-ui/core/List';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import * as allIcons from '@mdi/js';
import { Icon as MDIcon } from '@mdi/react';
import HttpContext from 'utils/contexts/HttpContext';
import {
    DialogActions,
    Grid,
    TextField,
    Typography,
    Avatar,
    Card,
    CardActionArea,
    CardContent
} from '@material-ui/core';

// can take in multiple bulks and roll them up into
function BulkFeesSelectionDialog({
    theme,
    disabled,
    open,
    defaultFeeCount = 1,
    operator,
    onClose,
    onApply,
    collector
}) {
    const http = useContext(HttpContext);
    const [feesLoading, setFeesLoading] = useState(true);
    const [fees, setFees] = useState([]);
    const [feeGroups, setFeeGroups] = useState([]);

    const [feeSelected, setFeeSelected] = useState(null);
    const [feeCount, setFeeCount] = useState(1);
    const [screenI, setScreenI] = useState(0);

    useEffect(() => {
        setFeesLoading(true);
        if (open) {
            setScreenI(0);

            fetchData();

            async function fetchData() {
                await http.getJSON(`/collectors/${collector._id}/getEnabledGroupFees`).then(res => {
                    setFeeGroups(_.get(res, 'data.feeGroups', []));
                });

                await http.getJSON(`/fees/getAllEnabledFees`).then(res => {
                    setFees(_.get(res, 'data.collectionData', []));
                });
            }
        }

        setFeeCount(defaultFeeCount === 0 ? 1 : defaultFeeCount);
        setFeesLoading(false);
    }, [open, operator, collector]);

    const handleConfirmFeeCount = fee => {
        setScreenI(1);
        setFeeSelected(fee);
    };

    return (
        <Dialog open={open} fullWidth onClose={onClose}>
            <DialogContent>
                {feesLoading ? (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress size={80} />
                    </div>
                ) : (
                    <>
                        {screenI === 0 && (
                            <>
                                <DialogContentText
                                    style={{ marginBottom: 10, paddingBottom: 10, borderBottom: '1px solid lightgrey' }}
                                >
                                    Select a fee to apply.
                                </DialogContentText>

                                {_.isEmpty(feeGroups) ? (
                                    <Grid
                                        container
                                        spacing={theme.spacing.unit}
                                        style={{ marginTop: 5, marginBottom: 5 }}
                                    >
                                        {fees.map((fee, index) => (
                                            <FeeCard
                                                key={index}
                                                fee={fee}
                                                theme={theme}
                                                handleOnClick={handleConfirmFeeCount}
                                                disabled={disabled}
                                            />
                                        ))}
                                    </Grid>
                                ) : (
                                    feeGroups.map((groupFee, index) => (
                                        <>
                                            <Typography variant="body1" key={index}>
                                                {groupFee.name}
                                            </Typography>
                                            {groupFee.fees && (
                                                <Grid
                                                    container
                                                    spacing={theme.spacing.unit}
                                                    style={{ marginTop: 5, marginBottom: 5 }}
                                                >
                                                    {groupFee.fees.map((fee, index) => (
                                                        <FeeCard
                                                            key={index}
                                                            fee={fee}
                                                            theme={theme}
                                                            handleOnClick={handleConfirmFeeCount}
                                                            disabled={disabled}
                                                        />
                                                    ))}
                                                </Grid>
                                            )}
                                        </>
                                    ))
                                )}
                            </>
                        )}
                        {screenI === 1 && (
                            <>
                                <DialogContentText>Enter number to apply</DialogContentText>
                                <TextField
                                    margin="normal"
                                    autoFocus
                                    fullWidth
                                    value={feeCount}
                                    onChange={e => setFeeCount(e.target.value.replace(/\D/, ''))}
                                    type="number"
                                    variant="outlined"
                                />
                            </>
                        )}
                    </>
                )}
            </DialogContent>
            {screenI === 1 && (
                <DialogActions>
                    <Button onClick={() => setScreenI(0)} color="secondary">
                        Back
                    </Button>
                    <Button
                        onClick={() => onApply(feeSelected, feeCount)}
                        color="primary"
                        disabled={feeCount <= 0}
                        data-cy="bulk-fee-number-dialog-apply-btn"
                    >
                        Apply
                    </Button>
                </DialogActions>
            )}
        </Dialog>
    );
}

export function FeeCard({ fee, theme, handleOnClick, disabled, isSelected }) {
    return (
        <Grid item xs={6}>
            <Card
                style={{
                    height: theme.spacing.unit * 8
                }}
                elevation={0}
            >
                <CardActionArea
                    data-cy="bulk-fee-selection-dialog-apply-btn"
                    onClick={() => handleOnClick(fee)}
                    disabled={disabled}
                    style={{
                        height: '100%',
                        background: isSelected ? colors.green[300] : theme.palette.background.default
                    }}
                >
                    <CardContent
                        style={{
                            padding: theme.spacing.unit * 1.5,
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <table
                            style={{
                                tableLayout: 'fixed',
                                width: '100%'
                            }}
                        >
                            <tbody>
                                <tr>
                                    {fee.iconName && (
                                        <td
                                            style={{
                                                width: theme.spacing.unit * 5
                                            }}
                                        >
                                            <Avatar
                                                style={{
                                                    backgroundColor: theme.palette.background.paper,
                                                    height: theme.spacing.unit * 4,
                                                    width: theme.spacing.unit * 4
                                                }}
                                            >
                                                <MDIcon
                                                    path={_.get(allIcons, fee.iconName)}
                                                    size={1}
                                                    color={fee.color}
                                                />
                                            </Avatar>
                                        </td>
                                    )}
                                    <td>
                                        <Typography
                                            className="scalingFont"
                                            style={{
                                                width: '100%',
                                                display: 'inline-block',
                                                overflow: 'hidden',
                                                color: theme.palette.text.primary,
                                                textAlign: 'left'
                                            }}
                                        >
                                            {fee.description}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            className="scalingFont"
                                            style={{
                                                color: theme.palette.text.secondary
                                            }}
                                        >
                                            {formatAsCurrency(fee.amount)}
                                        </Typography>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </CardContent>
                </CardActionArea>
            </Card>
        </Grid>
    );
}

export default withTheme()(BulkFeesSelectionDialog);
