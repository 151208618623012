import { useState, useContext } from 'react';
import SnackbarContext from 'components/CustomSnackbar/SnackbarContext';

const useSpeedMode = () => {
    const onSnackbar = useContext(SnackbarContext);
    const [speedMode, setSpeedMode] = useState(JSON.parse(localStorage.getItem('speedMode')) || false);

    const handleToggleSpeedMode = () => {
        const newSpeedMode = !speedMode;
        setSpeedMode(newSpeedMode);
        localStorage.setItem('speedMode', JSON.stringify(newSpeedMode));
        if (newSpeedMode) {
            onSnackbar(
                `Enabled search dialog to pop up after you submit to speed up counting! You speed demon!`,
                'info'
            );
        }
    };
    return { speedMode, handleToggleSpeedMode };
};

export default useSpeedMode;
