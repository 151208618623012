import React, { useContext } from 'react';
import { Dialog, DialogActions, DialogContent, Button, DialogTitle } from '@material-ui/core';

import ReactJson from 'react-json-view';

import { withTheme } from '@material-ui/core/styles';

import { Clipboard } from '@capacitor/clipboard';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

function CustomDebugDialog({ theme, open, json, onClose }) {
    const { lang } = useContext(LocalizationContext);
    return (
        <Dialog open={open} fullWidth onClose={onClose}>
            <DialogTitle>Viewing JSON</DialogTitle>
            <DialogContent>
                <ReactJson theme="bright:inverted" collapsed={2} indentWidth={2} src={json || {}} />
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={() => Clipboard.write({ string: JSON.stringify(json) })}>
                    Copy JSON
                </Button>
                <Button color="default" onClick={onClose}>
                    {loc('close', lang)}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default withTheme()(CustomDebugDialog);
