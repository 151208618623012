import { useState, useCallback, useEffect } from 'react';
import _ from 'lodash';

// https://medium.com/swlh/using-react-hooks-to-sync-your-component-state-with-the-url-query-string-81ccdfcb174f
import { setQueryStringValue, getQueryStringValue } from 'utils/query';

function defaultValidationFunction() {
    return true;
}

function useQueryString(key, initVal, enabled = false, isValidFunc = defaultValidationFunction) {
    const [value, setValue] = useState(getInitialState(key, isValidFunc, _.isArray(initVal)) || initVal);

    const handleSetValue = useCallback(
        newValue => {
            setValue(newValue);
            if (enabled) {
                setQueryStringValue(key, newValue);
            }
        },
        [enabled, key]
    );

    useEffect(() => {
        if (enabled) {
            setQueryStringValue(key, value);
        }
    }, []);

    return [value, handleSetValue];
}

export default useQueryString;

function getInitialState(key, isValidFunc, isArray = false) {
    const queryValue = getQueryStringValue(key);

    if (!_.isNil(queryValue) && isValidFunc(queryValue)) {
        if (isArray) {
            return queryValue.split(','); //Query string arrays must be comma separated
        }
        return queryValue;
    }
    return;
}
