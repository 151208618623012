import React from 'react';
import _ from 'lodash';

import { withTheme } from '@material-ui/core';

import { useState } from 'react';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import Configuration from '../Configuration';
import InformationForms from '../InformationForms';

function Settings(props) {
    const { theme } = props;

    const [view, setView] = useState('configuration');
    const [lang, setLang] = useState(process.env.REACT_APP_REGION_LANGUAGE);
    const showConfiguration = view === 'configuration';
    const showInformationForms = view === 'informationForms';

    return (
        <>
            <div style={{ paddingTop: theme.spacing.unit * 2, display: 'flex', justifyContent: 'center' }}>
                <ToggleButtonGroup
                    value={view}
                    exclusive
                    onChange={(e, view) => {
                        if (!_.isEmpty(view)) setView(view);
                    }}
                    style={{ display: 'flex', flexWrap: 'wrap', marginRight: '16px' }}
                >
                    <ToggleButton style={{ flexGrow: 1 }} data-cy="settings-page-settings-tab" value="configuration">
                        Settings
                    </ToggleButton>
                    <ToggleButton
                        style={{ flexGrow: 1 }}
                        data-cy="settings-page-information-forms-tab"
                        value="informationForms"
                    >
                        Information Forms
                    </ToggleButton>
                </ToggleButtonGroup>{' '}
                <ToggleButtonGroup
                    value={lang}
                    exclusive
                    onChange={(e, lang) => {
                        if (!_.isEmpty(lang)) setLang(lang);
                    }}
                    style={{ display: 'flex', flexWrap: 'wrap' }}
                >
                    <ToggleButton style={{ flexGrow: 1 }} value="en">
                        English
                    </ToggleButton>
                    <ToggleButton style={{ flexGrow: 1 }} value="fr">
                        French
                    </ToggleButton>
                </ToggleButtonGroup>
            </div>
            {showConfiguration && <Configuration {...props} lang={lang} />}
            {showInformationForms && <InformationForms {...props} lang={lang} />}
        </>
    );
}

export default withTheme()(Settings);
