import { useState, useContext, useEffect } from 'react';
import _ from 'lodash';

import HttpContext from 'utils/contexts/HttpContext';

function useCommodityPickupFilters(collector = null) {
    const http = useContext(HttpContext);

    const [filtersLoading, setFiltersLoading] = useState(true);

    const [allCollectors, setAllCollectors] = useState([]);
    const [collectorsSelected, setCollectorsSelected] = useState([]);

    const [allCommodities, setAllCommodities] = useState([]);
    const [commoditiesSelected, setCommoditiesSelected] = useState([]);

    useEffect(() => {
        (async () => {
            const res = await http.getJSON('/commodities/getAllEnabledCommodities');
            if (res.ok) {
                let commodities = _.get(res, 'data.commodities', []).filter(cm => cm.skuType !== 'beverage');
                setAllCommodities(commodities);
                setCommoditiesSelected(commodities.map(commodity => commodity._id));
            }
            const res2 = await http.getJSON('/collectors');
            if (res2.ok) {
                let collectors = _.get(res2, 'data.collectors', []);
                setAllCollectors(collectors);
                setCollectorsSelected(collector ? [collector._id] : collectors.map(collector => collector._id));
            }
            setFiltersLoading(false);
        })();
    }, []);

    useEffect(() => {
        if (!_.isNil(collector)) {
            setCollectorsSelected([collector._id]);
        }
    }, [collector]);

    const handleAllFilterCollectors = all => e => {
        e.stopPropagation();
        if (all) {
            setCollectorsSelected(allCollectors.map(c => c._id));
        } else {
            setCollectorsSelected([]);
        }
    };

    const handleAllFilterCommodities = all => e => {
        e.stopPropagation();
        if (all) {
            setCommoditiesSelected(allCommodities.map(cm => cm._id));
        } else {
            setCommoditiesSelected([]);
        }
    };

    return {
        allCollectors,
        collectorsSelected,
        setCollectorsSelected,
        allCommodities,
        commoditiesSelected,
        setCommoditiesSelected,
        filtersLoading,
        handleAllFilterCollectors,
        handleAllFilterCommodities
    };
}

export default useCommodityPickupFilters;
