import React, { useState } from 'react';
import _ from 'lodash';
import { _user } from 'std';

import { withTheme } from '@material-ui/core/styles';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    LinearProgress,
    DialogContentText,
    TextField
} from '@material-ui/core';
import { Button } from '@material-ui/core';

import { getCustomerName } from 'utils/misc';

function DisableAccountDialog(props) {
    const {
        selectedAccount,
        open,
        onClose,
        loading,
        setLoading,
        http,
        onReloadData,
        theme,
        fullScreen,
        setPromptForDriverCode
    } = props;

    const [banReason, setBanReason] = useState('');

    const handleDisableAccount = async reason => {
        setLoading(true);
        await http.post(`/users/${selectedAccount._id}/banUser`, { banReason: reason });
        await onReloadData();
        setLoading(false);
        onClose();
    };

    const handleEnableAccount = async () => {
        setLoading(true);
        await http.post(`/users/${selectedAccount._id}/enableUser`);
        await onReloadData();
        setLoading(false);
        if (
            selectedAccount.accountType === 'Collector Employee' &&
            _.get(selectedAccount, 'accountPermissions', []).includes('Driver')
        ) {
            setPromptForDriverCode(true);
        }
        onClose();
    };

    return (
        <Dialog open={open} fullScreen={fullScreen} onClose={onClose} maxWidth={'sm'} fullWidth>
            <DialogTitle>{_.get(selectedAccount, 'banned', false) ? 'Unban Account' : 'Ban Account'}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {_.get(selectedAccount, 'banned', false) ? 'Unban' : 'Ban'} {getCustomerName(selectedAccount)}
                    's account?
                </DialogContentText>
                {!_.get(selectedAccount, 'banned', false) && (
                    <TextField
                        label={`Reason`}
                        error={!banReason}
                        value={banReason}
                        onChange={e => setBanReason(e.target.value)}
                        variant="outlined"
                        fullWidth
                        autoFocus
                        style={{ marginTop: theme.spacing.unit * 2 }}
                        data-cy="disable-account-dialog-disable-reason"
                    />
                )}
            </DialogContent>
            <DialogActions>
                {loading && <LinearProgress />}
                <Button onClick={onClose} disabled={loading}>
                    Cancel
                </Button>
                {_.get(selectedAccount, 'banned', false) ? (
                    <Button
                        disabled={loading}
                        onClick={handleEnableAccount}
                        data-cy="disable-account-dialog-enable-btn"
                    >
                        Unban Account
                    </Button>
                ) : (
                    <Button
                        data-cy="disable-account-dialog-disable-btn"
                        disabled={loading || !banReason}
                        onClick={() => handleDisableAccount(banReason)}
                    >
                        Ban Account
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
}

export default withMobileDialog()(withTheme()(DisableAccountDialog));
