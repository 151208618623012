import React, { useContext } from 'react';

import _ from 'lodash';
import moment from 'moment-timezone';
import { Card, Typography, IconButton, Avatar, Link, Collapse, Icon, Button } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import * as colors from '@material-ui/core/colors';
import { mdiAccount, mdiBallot, mdiCircle, mdiCodeJson, mdiCreditCardOutline, mdiLogin, mdiTruck } from '@mdi/js';
import MDIcon from '@mdi/react';
import { Clipboard } from '@capacitor/clipboard';

import SnackbarContext from 'components/CustomSnackbar/SnackbarContext';
import { useState } from 'react';
import { DB_LOG_TYPES } from '../../../constants';
import { _user } from 'std';
import { formatAsCurrency, formatAsPhoneNumber } from 'utils/misc';

const CUSTOM_DISPLAY_FIELDS = ['countingSessions', 'customFees'];
const CURRENCY_FIELDS = ['amount', 'fee'];
const DATE_FIELDS = ['twoFactorAuthentication.lockAccountUntil'];

const DbLogCard = ({ theme, setSelectedLog, data, commodities, isLogin, timezone }) => {
    const onSnackbar = useContext(SnackbarContext);
    const {
        user,
        actor,
        object,
        systemUpdate,
        operation,
        newValues,
        oldValues,
        trigger,
        type,
        s,
        o,
        p,
        source,
        createdAt,
        identifier
    } = data;

    const [expanded, setExpanded] = useState(false);

    const icon = (
        <Avatar
            style={{
                height: 45,
                width: 45,
                padding: theme.spacing.unit,
                margin: theme.spacing.unit,
                backgroundColor: isLogin
                    ? colors.grey[500]
                    : type === DB_LOG_TYPES.PICKUP
                    ? colors.indigo[500]
                    : type === DB_LOG_TYPES.ORDER
                    ? colors.blue[500]
                    : type === DB_LOG_TYPES.REDEMPTION
                    ? colors.green[500]
                    : type === DB_LOG_TYPES.USER
                    ? colors.teal[500]
                    : theme.palette.primary.main
            }}
        >
            <MDIcon
                path={
                    isLogin
                        ? mdiLogin
                        : type === DB_LOG_TYPES.PICKUP
                        ? mdiTruck
                        : type === DB_LOG_TYPES.REDEMPTION
                        ? mdiCreditCardOutline
                        : type === DB_LOG_TYPES.USER
                        ? mdiAccount
                        : type === DB_LOG_TYPES.ORDER
                        ? mdiBallot
                        : mdiCircle
                }
                size={2}
                color={theme.palette.background.paper}
            />
        </Avatar>
    );

    const logIdentifier = !(_.isNil(identifier) || _.isEmpty(identifier))
        ? identifier
        : isLogin
        ? _.get(source, 'uniqueID', 'N/A')
        : type === DB_LOG_TYPES.PICKUP
        ? _.get(
              object,
              'location.description',
              _.get(newValues, 'location.description', _.get(oldValues, 'location.description', 'N/A'))
          )
        : type === DB_LOG_TYPES.ORDER
        ? _.get(object, 'collector.name', _.get(newValues, 'collector.name', _.get(oldValues, 'collector.name', 'N/A')))
        : type === DB_LOG_TYPES.USER
        ? _.get(object, 'uniqueID', _.get(newValues, 'uniqueID', _.get(oldValues, 'uniqueID', 'N/A')))
        : '';
    const renderCountingSessions = () => {
        let oldCountingSessions = _.get(oldValues, 'countingSessions', []);
        let newCountingSessions = _.get(newValues, 'countingSessions', []);
        if (_.isEmpty(oldCountingSessions) && _.isEmpty(newCountingSessions)) {
            return;
        }
        let info = [];
        if (oldCountingSessions.length > newCountingSessions.length) {
            oldCountingSessions.forEach(countingSession => {
                if (!newCountingSessions.map(cs => cs._id.toString()).includes(countingSession._id.toString())) {
                    info.push(
                        <Typography variant="body2">
                            <span style={{ fontWeight: 'bold' }}>Counting Session Deleted</span>
                        </Typography>
                    );
                    let commodityInfo = [];
                    countingSession.commodityCounted.forEach(commodity => {
                        let populatedCommodity = _.find(commodities, c => c => c.skuType === commodity.skuType);
                        if (commodity.isSubCommodity) {
                            populatedCommodity = _.find(
                                populatedCommodity.subCommodities,
                                sc => sc._id.toString() === commodity.subCommodity.toString()
                            );
                        }
                        commodityInfo.push(
                            `${commodity.amount} ${_.get(
                                populatedCommodity,
                                'name.en',
                                _.startCase(commodity.skuType)
                            )}`
                        );
                    });
                    info.push(
                        <Typography
                            variant="caption"
                            style={{ marginLeft: theme.spacing.unit * 2, textDecoration: 'line-through' }}
                        >
                            <span style={{ fontWeight: 'bold' }}>Commodities: </span>
                            {commodityInfo.length === 0 ? 'None' : commodityInfo.join(', ')}
                        </Typography>
                    );
                    countingSession.items.forEach(item => {
                        info.push(
                            <Typography
                                variant="caption"
                                style={{ marginLeft: theme.spacing.unit * 2, textDecoration: 'line-through' }}
                            >
                                <span style={{ fontWeight: 'bold' }}>Count: </span>
                                {item.quantity} x {item.size} {item.materialType}
                            </Typography>
                        );
                    });
                    // info = info.concat(countingSession, {});
                }
            });
        } else if (oldCountingSessions.length < newCountingSessions.length) {
            newCountingSessions.forEach(countingSession => {
                if (!oldCountingSessions.map(cs => cs._id.toString()).includes(countingSession._id.toString())) {
                    info.push(
                        <Typography variant="body2">
                            <span style={{ fontWeight: 'bold' }}>Counting Session Created</span>
                        </Typography>
                    );
                    let commodityInfo = [];
                    countingSession.commodityCounted.forEach(commodity => {
                        let populatedCommodity = _.find(commodities, c => c => c.skuType === commodity.skuType);
                        if (commodity.isSubCommodity) {
                            populatedCommodity = _.find(
                                populatedCommodity.subCommodities,
                                sc => sc._id.toString() === commodity.subCommodity.toString()
                            );
                        }
                        commodityInfo.push(
                            `${commodity.amount} ${_.get(
                                populatedCommodity,
                                'name.en',
                                _.startCase(commodity.skuType)
                            )}`
                        );
                    });
                    info.push(
                        <Typography variant="caption" style={{ marginLeft: theme.spacing.unit * 2 }}>
                            <span style={{ fontWeight: 'bold' }}>Commodities: </span>
                            {commodityInfo.length === 0 ? 'None' : commodityInfo.join(', ')}
                        </Typography>
                    );
                }
            });
        } else {
            newCountingSessions.forEach(countingSession => {
                let oldCountingSession = _.find(
                    oldCountingSessions,
                    cs => cs._id.toString() === countingSession._id.toString()
                );
                if (_.isEqual(oldCountingSession, countingSession)) return;
                info.push(
                    <Typography variant="body2">
                        <span style={{ fontWeight: 'bold' }}>Counting Session Updated</span>
                    </Typography>
                );
                if (!_.isEqual(oldCountingSession.commodityCounted, countingSession.commodityCounted)) {
                    let oldCommodityInfo = [];
                    oldCountingSession.commodityCounted.forEach(commodity => {
                        let populatedCommodity = _.find(commodities, c => c => c.skuType === commodity.skuType);
                        if (commodity.isSubCommodity) {
                            populatedCommodity = _.find(
                                populatedCommodity.subCommodities,
                                sc => sc._id.toString() === commodity.subCommodity.toString()
                            );
                        }
                        oldCommodityInfo.push(
                            `${commodity.amount} ${_.get(
                                populatedCommodity,
                                'name.en',
                                _.startCase(commodity.skuType)
                            )}`
                        );
                    });
                    let commodityInfo = [];
                    countingSession.commodityCounted.forEach(commodity => {
                        let populatedCommodity = _.find(commodities, c => c => c.skuType === commodity.skuType);
                        if (commodity.isSubCommodity) {
                            populatedCommodity = _.find(
                                populatedCommodity.subCommodities,
                                sc => sc._id.toString() === commodity.subCommodity.toString()
                            );
                        }
                        commodityInfo.push(
                            `${commodity.amount} ${_.get(
                                populatedCommodity,
                                'name.en',
                                _.startCase(commodity.skuType)
                            )}`
                        );
                    });
                    info.push(
                        <Typography variant="caption" style={{ marginLeft: theme.spacing.unit * 2 }}>
                            <span style={{ fontWeight: 'bold' }}>Commodities Updated: </span>
                            {oldCommodityInfo.length === 0 ? 'None' : oldCommodityInfo.join(', ')} →{' '}
                            {commodityInfo.length === 0 ? 'None' : commodityInfo.join(', ')}
                        </Typography>
                    );
                }
                if (_.get(oldCountingSession, 'items', []).length > _.get(countingSession, 'items', []).length) {
                    let itemsRemoved = _.filter(
                        _.get(oldCountingSession, 'items', []),
                        item =>
                            !_.get(countingSession, 'items', [])
                                .map(i => i._id.toString())
                                .includes(item._id.toString())
                    );
                    itemsRemoved.forEach(item => {
                        info.push(
                            <Typography variant="caption" style={{ marginLeft: theme.spacing.unit * 2 }}>
                                <span style={{ fontWeight: 'bold' }}>Count removed: </span>{' '}
                                <span style={{ textDecoration: 'line-through' }}>
                                    {item.quantity} x {item.size} {item.materialType}
                                </span>
                            </Typography>
                        );
                    });
                    // info = info.concat(oldCountingSession, countingSession);
                } else if (_.get(oldCountingSession, 'items', []).length < _.get(countingSession, 'items', []).length) {
                    let itemsAdded = _.filter(
                        _.get(countingSession, 'items', []),
                        item =>
                            !_.get(oldCountingSession, 'items', [])
                                .map(i => i._id.toString())
                                .includes(item._id.toString())
                    );
                    itemsAdded.forEach(item => {
                        info.push(
                            <Typography variant="caption" style={{ marginLeft: theme.spacing.unit * 2 }}>
                                <span style={{ fontWeight: 'bold' }}>Count added: </span> {item.quantity} x {item.size}{' '}
                                {item.materialType}
                            </Typography>
                        );
                    });
                }
                if (
                    _.get(oldCountingSession, 'customFees', []).length > _.get(countingSession, 'customFees', []).length
                ) {
                    let feesRemoved = _.filter(
                        _.get(oldCountingSession, 'customFees', []),
                        fee =>
                            !_.get(countingSession, 'customFees', [])
                                .map(i => i._id.toString())
                                .includes(fee._id.toString())
                    );
                    feesRemoved.forEach(fee => {
                        info.push(renderCustomFee(fee, {}));
                    });
                    // info = info.concat(oldCountingSession, countingSession);
                } else if (
                    _.get(oldCountingSession, 'customFees', []).length < _.get(countingSession, 'customFees', []).length
                ) {
                    let feesAdded = _.filter(
                        _.get(countingSession, 'customFees', []),
                        fee =>
                            !_.get(oldCountingSession, 'customFees', [])
                                .map(i => i._id.toString())
                                .includes(fee._id.toString())
                    );
                    feesAdded.forEach(fee => {
                        info.push(renderCustomFee({}, fee));
                    });
                }
            });
        }

        return info;
    };

    const renderCustomFee = (oldFee, newFee) => {
        if ((_.isNil(oldFee) || _.isEmpty(oldFee)) && (_.isNil(newFee) || _.isEmpty(newFee))) {
            return;
        }
        if (_.isNil(oldFee) || _.isEmpty(oldFee)) {
            return (
                <Typography variant="caption" style={{ marginLeft: theme.spacing.unit * 2 }}>
                    <span style={{ fontWeight: 'bold' }}>Fee added: </span>
                    {newFee.quantity} x {newFee.description} {formatAsCurrency(newFee.amount)} ({newFee.tax.value}% tax{' '}
                    {newFee.tax.type})
                </Typography>
            );
        } else if (_.isNil(newFee) || _.isEmpty(newFee)) {
            return (
                <Typography
                    variant="caption"
                    style={{ marginLeft: theme.spacing.unit * 2, textDecoration: 'line-through' }}
                >
                    <span style={{ fontWeight: 'bold' }}>Fee removed: </span>
                    {oldFee.quantity} x {oldFee.description} {formatAsCurrency(oldFee.amount)} ({oldFee.tax.value}% tax{' '}
                    {oldFee.tax.type})
                </Typography>
            );
        } else {
            return (
                <Typography variant="caption" style={{ marginLeft: theme.spacing.unit * 2 }}>
                    <span style={{ fontWeight: 'bold' }}>Fee updated: </span>
                    {oldFee.quantity} x {oldFee.description} {formatAsCurrency(oldFee.amount)} ({oldFee.tax.value}% tax{' '}
                    {oldFee.tax.type}) → {newFee.quantity} x {newFee.description} {formatAsCurrency(newFee.amount)} (
                    {newFee.tax.value}% tax {newFee.tax.type})
                </Typography>
            );
        }
    };

    const renderChangedFields = () => {
        let renderValues = [];
        let title = operation === 'i' ? 'Values' : operation === 'd' ? 'Previous Values' : 'Changes';
        const flattenedOldValues = flattenObject(oldValues);
        const flattenedNewValues = flattenObject(newValues);
        _.uniq(_.concat(_.keys(flattenedNewValues), _.keys(flattenedOldValues))).forEach(field => {
            if (_.some(CUSTOM_DISPLAY_FIELDS, cdf => field.includes(cdf))) return;
            const oldValue = _.get(flattenedOldValues, field, '');
            const newValue = _.get(flattenedNewValues, field, '');
            if (oldValue === newValue) return;
            let renderOldValue, renderNewValue;
            if (field.includes('date') || field.includes('Date') || DATE_FIELDS.includes(field)) {
                renderOldValue =
                    _.isNil(oldValue) || _.isEmpty(oldValue)
                        ? ''
                        : moment(oldValue)
                              .tz(timezone)
                              .locale('en')
                              .format('MMM. DD, YYYY h:mm:ss A');
                renderNewValue =
                    _.isNil(newValue) || _.isEmpty(newValue)
                        ? ''
                        : moment(newValue)
                              .tz(timezone)
                              .locale('en')
                              .format('MMM. DD, YYYY h:mm:ss A');
            } else if (CURRENCY_FIELDS.includes(field)) {
                renderOldValue = formatAsCurrency(oldValue);
                renderNewValue = formatAsCurrency(newValue);
            } else if (field === 'passwordChanged') {
                renderOldValue = '*****';
                renderNewValue = '*****';
            } else {
                renderOldValue = oldValue.toString();
                renderNewValue = newValue.toString();
            }
            if (field !== 'passwordChanged' && renderOldValue === renderNewValue) return;
            switch (operation) {
                case 'i':
                    if (!_.isNil(renderNewValue) && !_.isEmpty(renderNewValue)) {
                        renderValues.push(
                            <Typography variant="body2">
                                <span style={{ fontWeight: 'bold' }}>{_.startCase(field)}: </span>
                                {renderNewValue}
                            </Typography>
                        );
                    }
                    break;
                case 'u':
                    renderValues.push(
                        <Typography variant="body2">
                            <span style={{ fontWeight: 'bold' }}>{_.startCase(field)}: </span>
                            {renderOldValue} → {renderNewValue}
                        </Typography>
                    );
                    break;
                case 'd':
                    if (!_.isNil(renderOldValue) && !_.isEmpty(renderOldValue)) {
                        renderValues.push(
                            <Typography variant="body2">
                                <span style={{ fontWeight: 'bold' }}>{_.startCase(field)}: </span>
                                {renderOldValue}
                            </Typography>
                        );
                    }
                    break;
                default:
            }
        });
        return (
            <div
                style={{
                    margin: theme.spacing.unit,
                    padding: theme.spacing.unit * 2,
                    borderRadius: theme.shape.borderRadius,
                    backgroundColor: theme.palette.background.default,
                    width: '80%'
                }}
            >
                <div style={{ display: 'flex', marginBottom: 5 }}>
                    <Typography variant="h6" style={{ marginRight: theme.spacing.unit * 2, fontSize: '1rem' }}>
                        {title}
                    </Typography>
                </div>
                <div>{renderValues}</div>
                <div>{renderCountingSessions()}</div>
                {_.uniq(_.concat(_.keys(oldValues), _.keys(newValues))).includes('customFees') &&
                    (_.get(oldValues, 'customFees', []).length > 0 ||
                        _.get(newValues, 'customFees', []).length > 0) && (
                        <div>
                            <Typography variant="body2">
                                <span style={{ fontWeight: 'bold' }}>Custom Fees:</span>
                            </Typography>
                            {(_.get(oldValues, 'customFees', []).length > _.get(newValues, 'customFees', []).length
                                ? oldValues.customFees
                                : newValues.customFees
                            ).map((fee, index) =>
                                renderCustomFee(
                                    _.get(oldValues, `customFees.${index}`),
                                    _.get(newValues, `customFees.${index}`)
                                )
                            )}
                        </div>
                    )}
            </div>
        );
    };
    return isLogin ? (
        <Card
            style={{
                margin: `${theme.spacing.unit}px auto`,
                border: '1px solid',
                borderColor: theme.palette.grey[300]
            }}
        >
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <tr
                    style={{
                        verticalAlign: 'middle'
                    }}
                >
                    <td style={{ lineHeight: 0, width: 32, color: 'white' }}>{icon}</td>
                    <td>
                        <Typography variant="body1">
                            {o === 'f' && 'Failed'} Login {!_.isNil(p) && `(${p})`} -{' '}
                            <Link
                                href={`${process.env.REACT_APP_ORIGIN_URL}/${
                                    !_.isNil(source) && _user.isCustomer(source) ? 'customers' : 'operators'
                                }/${_.get(source, '_id', '')}`}
                                style={{
                                    color: theme.palette.primary.main,
                                    textDecoration: 'underline',
                                    cursor: 'pointer'
                                }}
                                onClick={e => {
                                    e.preventDefault();
                                    window.open(
                                        `${process.env.REACT_APP_ORIGIN_URL}/${
                                            !_.isNil(source) && _user.isCustomer(source) ? 'customers' : 'operators'
                                        }/${_.get(source, '_id', '')}`
                                    );
                                }}
                            >
                                {_.get(source, 'name.first', _.get(source, 'name', 'N/A'))}{' '}
                                {_.get(source, 'name.last', '')}
                            </Link>{' '}
                            ({_.get(source, 'accountType', '')})
                        </Typography>
                    </td>
                </tr>

                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        verticalAlign: 'middle'
                    }}
                >
                    <tr
                        style={{
                            verticalAlign: 'middle'
                        }}
                    >
                        <td style={{ lineHeight: 0, color: 'white' }}>
                            <Typography variant="caption">
                                {moment(createdAt)
                                    .tz(timezone)
                                    .locale('en')
                                    .format('MMM. DD, YYYY h:mm:ss A')}
                            </Typography>
                        </td>
                        <td>
                            <div style={{ width: 55, height: 55 }} />
                        </td>
                    </tr>
                </div>
            </div>
        </Card>
    ) : (
        <Card
            style={{
                margin: `${theme.spacing.unit}px auto`,
                border: '1px solid',
                borderColor: theme.palette.grey[300]
            }}
        >
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <tr
                    style={{
                        verticalAlign: 'middle'
                    }}
                >
                    <td style={{ lineHeight: 0, width: 32, color: 'white' }}>{icon}</td>
                    <td>
                        <Typography variant="body1">
                            {type} {operation === 'd' ? 'Deleted' : operation === 'i' ? 'Created' : 'Updated'}{' '}
                            {!_.isNil(logIdentifier) && !_.isEmpty(logIdentifier) && `(${logIdentifier})`} - {trigger}{' '}
                            {systemUpdate ? (
                                '(System Task)'
                            ) : _.isNil(actor) ? (
                                'by N/A'
                            ) : (
                                <>
                                    by{' '}
                                    <Link
                                        href={`${process.env.REACT_APP_ORIGIN_URL}/${
                                            !_.isNil(actor) && _user.isCustomer(actor) ? 'customers' : 'operators'
                                        }/${_.get(actor, '_id', '')}`}
                                        style={{
                                            color: theme.palette.primary.main,
                                            textDecoration: 'underline',
                                            cursor: 'pointer'
                                        }}
                                        onClick={e => {
                                            e.preventDefault();
                                            window.open(
                                                `${process.env.REACT_APP_ORIGIN_URL}/${
                                                    !_.isNil(actor) && _user.isCustomer(actor)
                                                        ? 'customers'
                                                        : 'operators'
                                                }/${_.get(actor, '_id', '')}`
                                            );
                                        }}
                                    >
                                        {_.get(actor, 'name.first', 'N/A')} {_.get(actor, 'name.last', '')}
                                    </Link>{' '}
                                    ({_.get(actor, 'accountType', '')})
                                </>
                            )}
                        </Typography>
                    </td>
                </tr>

                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        verticalAlign: 'middle'
                    }}
                >
                    <tr
                        style={{
                            verticalAlign: 'middle'
                        }}
                    >
                        <td style={{ lineHeight: 0, color: 'white' }}>
                            <Typography variant="caption">
                                {moment(createdAt)
                                    .tz(timezone)
                                    .locale('en')
                                    .format('MMM. DD, YYYY h:mm:ss A')}
                            </Typography>
                        </td>
                        <td>
                            <IconButton style={{ margin: theme.spacing.unit }} onClick={() => setExpanded(!expanded)}>
                                <Icon>{expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</Icon>
                            </IconButton>
                        </td>
                    </tr>
                </div>
            </div>
            <Collapse in={expanded}>
                <div
                    style={{
                        display: 'flex'
                    }}
                >
                    {renderChangedFields()}
                    <div
                        style={{
                            margin: theme.spacing.unit,
                            padding: theme.spacing.unit * 2,
                            borderLeft: '3px solid gray'
                        }}
                    >
                        <Typography variant="body2">
                            {_.get(user, 'name.first', 'N/A')} {_.get(user, 'name.last', '')} (
                            {_.get(user, 'accountType', '')})
                        </Typography>
                        <Typography variant="body2">
                            <Link
                                href={`mailto:${_.get(user, 'email', '')}`}
                                style={{
                                    color: theme.palette.primary.main,
                                    textDecoration: 'underline',
                                    cursor: 'pointer'
                                }}
                                onClick={e => {
                                    e.preventDefault();
                                    window.open(`mailto:${_.get(user, 'email', '')}`);
                                }}
                            >
                                {_.get(user, 'email', '')}
                            </Link>
                        </Typography>
                        <Typography variant="body2">
                            <Link
                                href={`tel:${process.env.REACT_APP_COUNTRY_CALLING_CODE}${_.get(user, 'phone', '')}`}
                                style={{
                                    color: theme.palette.primary.main,
                                    textDecoration: 'underline',
                                    cursor: 'pointer'
                                }}
                                onClick={e => {
                                    e.preventDefault();
                                    window.open(
                                        `tel:${process.env.REACT_APP_COUNTRY_CALLING_CODE}${_.get(user, 'phone', '')}`
                                    );
                                }}
                            >
                                {formatAsPhoneNumber(_.get(user, 'phone', ''))}
                            </Link>
                        </Typography>
                        <Button
                            variant="outlined"
                            onClick={e => {
                                e.preventDefault();
                                window.open(
                                    `${process.env.REACT_APP_ORIGIN_URL}/${
                                        !_.isNil(user) && _user.isCustomer(user) ? 'customers' : 'operators'
                                    }/${_.get(user, '_id', '')}`
                                );
                            }}
                        >
                            View User
                        </Button>
                    </div>
                </div>
            </Collapse>
        </Card>
    );
};

export default withTheme()(DbLogCard);

function flattenObject(object) {
    var toReturn = {};

    for (var key in object) {
        if (!object.hasOwnProperty(key)) continue;

        if (typeof object[key] == 'object' && !_.isNil(object[key])) {
            var flatObject = flattenObject(object[key]);
            for (var nestedKey in flatObject) {
                if (!flatObject.hasOwnProperty(nestedKey)) continue;

                toReturn[key + '.' + nestedKey] = flatObject[nestedKey];
            }
        } else if (!_.isNil(object[key])) {
            toReturn[key] = object[key];
        }
    }
    return toReturn;
}
