import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';

import {
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    withTheme,
    withMobileDialog,
    FormControl,
    InputLabel,
    Select,
    OutlinedInput,
    MenuItem,
    Icon,
    IconButton,
    DialogContentText,
    Table as TableMUI,
    TableHead,
    TableBody,
    TableSortLabel,
    TableCell,
    TableRow
} from '@material-ui/core';
import DialogTitlePrimary from 'components/MaterialUIExtensions/DialogTitlePrimary';

function IPConflictDialog(props) {
    const { open, onClose, fullScreen, customer, theme, http, handleViewAccount } = props;
    const [pastIPs, setPastIPs] = useState([]);
    const [selectedPastIP, setselectedPastIP] = useState('');
    const [sameIPUsers, setSameIPUsers] = useState([]);
    useEffect(() => {
        let newPastIPs = _.get(customer, 'deviceInfos', []).map(di => di.ip);
        newPastIPs = [...new Set(newPastIPs)];
        if (newPastIPs.length > 0) {
            setselectedPastIP(newPastIPs[0]);
        }
        setPastIPs(newPastIPs);
    }, [customer]);
    useEffect(() => {
        if (open) {
            getUsersByIP(selectedPastIP);
        }
    }, [open]);
    async function getUsersByIP(ip) {
        if (_.isNil(ip) || _.isEmpty(ip)) return;
        const res = await http.postJSON('/users/getUserWithSameIP', {
            ip: ip
        });

        if (res.ok) {
            let newUsers = _.get(res, 'data.users', []).filter(u => u._id !== _.get(customer, '_id', ''));
            setSameIPUsers(newUsers);
        }
    }
    const cellStyle = { paddingLeft: 7, paddingRight: 7 };
    const headers = [
        { name: '', key: 'action' },
        { name: 'Name^', key: 'Name' },
        { name: 'Type^', key: 'Type' },
        { name: 'Device^', key: 'Device' },
        { name: 'Last Login Date^', key: 'LastLoginDate' }
    ];
    const [sortBy, setSortBy] = useState('LastLoginDate');
    const [sortDir, setSortDir] = useState('desc');
    useEffect(() => {
        handleSort(sortBy, sortDir === 'desc' ? -1 : 1);
    }, [sortBy, sortDir]);
    const handleSortBy = key => {
        if (sortBy !== key) {
            setSortBy(key);
            setSortDir('desc');
        } else if (sortBy === key && sortDir === 'desc') {
            setSortDir('asc');
        } else {
            setSortBy(key);
            setSortDir('desc');
        }
    };
    function handleSort(key, dir) {
        let newSameIPUsers = _.cloneDeep(sameIPUsers);
        if (key === 'Name') {
            newSameIPUsers.sort((a, b) => {
                let nameA = _.get(a, 'name.first', '') + ' ' + _.get(a, 'name.last', '');
                let nameB = _.get(b, 'name.first', '') + ' ' + _.get(b, 'name.last', '');
                if (nameA > nameB) {
                    return 1 * dir;
                } else if (nameA === nameB) {
                    return 0 * dir;
                }
                return -1 * dir;
            });
        }
        if (key === 'Type') {
            newSameIPUsers.sort((a, b) => {
                let valueA = _.get(a, 'accountType', '');
                let valueB = _.get(b, 'accountType', '');
                if (valueA > valueB) {
                    return 1 * dir;
                } else if (valueA === valueB) {
                    return 0 * dir;
                }
                return -1 * dir;
            });
        }
        if (key === 'Device') {
            newSameIPUsers.sort((a, b) => {
                let lastLoginA = _.get(a, 'deviceInfos', []).find(di => di.ip === selectedPastIP);
                let lastLoginB = _.get(b, 'deviceInfos', []).find(di => di.ip === selectedPastIP);
                let valueA = _.get(lastLoginA, 'device', '');
                let valueB = _.get(lastLoginB, 'device', '');
                if (valueA > valueB) {
                    return 1 * dir;
                } else if (valueA === valueB) {
                    return 0 * dir;
                }
                return -1 * dir;
            });
        }
        if (key === 'LastLoginDate') {
            newSameIPUsers.sort((a, b) => {
                let lastLoginA = _.get(a, 'deviceInfos', []).find(di => di.ip === selectedPastIP);
                let lastLoginDateA = new Date(_.get(lastLoginA, 'lastLogIn', ''));
                let lastLoginB = _.get(b, 'deviceInfos', []).find(di => di.ip === selectedPastIP);
                let lastLoginDateB = new Date(_.get(lastLoginB, 'lastLogIn', ''));
                if (lastLoginDateA > lastLoginDateB) {
                    return 1 * dir;
                } else if (lastLoginDateA === lastLoginDateB) {
                    return 0 * dir;
                }
                return -1 * dir;
            });
        }
        setSameIPUsers(newSameIPUsers);
    }
    return (
        <Dialog open={open} fullScreen={fullScreen} maxWidth="lg" style={{ marginBottom: theme.spacing.unit }}>
            <DialogTitlePrimary closeButtonShown onClose={onClose}>
                Users With IP Conflict
            </DialogTitlePrimary>
            {pastIPs.length < 1 ? (
                <DialogContent>
                    <DialogContentText style={{ paddingTop: theme.spacing.unit * 2 }}>
                        No login IP have been logged for this user
                    </DialogContentText>
                </DialogContent>
            ) : (
                <DialogContent style={{ paddingTop: theme.spacing.unit * 2, paddingBottom: theme.spacing.unit }}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row'
                        }}
                    >
                        <FormControl variant="outlined">
                            <InputLabel htmlFor="collection">Login IPs</InputLabel>
                            <Select
                                input={<OutlinedInput id="collection" name="collection" labelWidth={75} />}
                                value={selectedPastIP}
                                onChange={e => {
                                    setselectedPastIP(e.target.value);
                                }}
                                style={{ minWidth: 185 }}
                            >
                                {pastIPs.map(ip => (
                                    <MenuItem key={ip} value={ip}>
                                        {ip}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginLeft: 10
                            }}
                        >
                            <Button
                                onClick={() => {
                                    getUsersByIP(selectedPastIP);
                                }}
                                color="primary"
                                variant="contained"
                            >
                                Search
                            </Button>
                        </div>
                    </div>
                    {sameIPUsers.length > 0 ? (
                        <TableMUI size="small" padding="dense">
                            <TableHead>
                                <TableRow>
                                    {headers.map(({ name, key, padding }, i) => {
                                        const formattedName = name.split('^');
                                        const labelText = formattedName[0];

                                        const canSortBy = name.includes('^') && key !== null;
                                        return (
                                            <TableCell
                                                style={cellStyle}
                                                key={i}
                                                padding={_.isNil(padding) ? 'default' : padding}
                                            >
                                                {canSortBy ? (
                                                    <TableSortLabel
                                                        onClick={() => {
                                                            handleSortBy(key);
                                                        }}
                                                        active={sortBy === key}
                                                        direction={sortDir}
                                                    >
                                                        {labelText}
                                                    </TableSortLabel>
                                                ) : (
                                                    labelText
                                                )}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sameIPUsers.map(user => {
                                    let name = _.get(user, 'name.first', '') + ' ' + _.get(user, 'name.last', '');
                                    let _id = _.get(user, '_id', '');
                                    let lastLogin = _.get(user, 'deviceInfos', []).find(di => di.ip === selectedPastIP);
                                    let device = _.get(lastLogin, 'device', '');
                                    let lastLoginDate = _.get(lastLogin, 'lastLogIn', '');
                                    let accountType = _.get(user, 'accountType', '');
                                    console.log('lastLogin', lastLogin);
                                    return (
                                        <TableRow key={_id}>
                                            <TableCell style={cellStyle}>
                                                <IconButton
                                                    style={{ padding: 5 }}
                                                    onClick={() => {
                                                        if (!_.isEmpty(_id)) handleViewAccount(user);
                                                    }}
                                                >
                                                    <Icon color="action" style={{}}>
                                                        visibility
                                                    </Icon>
                                                </IconButton>
                                            </TableCell>
                                            <TableCell style={cellStyle}>{name}</TableCell>
                                            <TableCell style={cellStyle}>{accountType}</TableCell>
                                            <TableCell style={cellStyle}>{device}</TableCell>
                                            <TableCell style={cellStyle}>
                                                {moment(lastLoginDate).format('YYYY/MM/DD h:mm a')}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </TableMUI>
                    ) : (
                        <DialogContentText style={{ paddingTop: theme.spacing.unit * 2 }}>
                            No other users found with this IP
                        </DialogContentText>
                    )}
                </DialogContent>
            )}
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Dismiss
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default withMobileDialog({ breakpoint: 'xs' })(withTheme()(IPConflictDialog));
