import React from 'react';

import * as colors from '@material-ui/core/colors';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withTheme } from '@material-ui/core/styles';

function LoadingScreen(props) {
    const { theme, color, size } = props;
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <CircularProgress size={size || 80} style={{ color: color || 'primary' }} />
        </div>
    );
}

export default withTheme()(LoadingScreen);
