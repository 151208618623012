import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Fade from '@material-ui/core/Fade';
import LinearProgress from '@material-ui/core/LinearProgress';

const ConfirmationDialog = ({
    confirmationDialogOpen,
    handleConfirmationDialog,
    confirmationText,
    inProgress,
    handleRedeem
}) => {
    return (
        <Dialog open={confirmationDialogOpen} fullWidth onClose={() => handleConfirmationDialog(false)}>
            <DialogTitle>Redemption Confirmation</DialogTitle>
            <DialogContent>
                <DialogContentText>{confirmationText}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color="primary" disabled={inProgress} onClick={() => handleConfirmationDialog(false)}>
                    Cancel
                </Button>
                <Button
                    data-cy="redemption-dialog-yes-yes"
                    color="primary"
                    disabled={inProgress}
                    onClick={handleRedeem}
                >
                    Yes
                </Button>
            </DialogActions>
            <Fade in={inProgress}>
                <LinearProgress />
            </Fade>
        </Dialog>
    );
};

export default ConfirmationDialog;
