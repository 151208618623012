import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { getQuickDropName, isCONRegion, isEXPRegion, isSTDRegion } from 'utils/misc';

import HttpContext from 'utils/contexts/HttpContext';
import { toDollars } from 'utils/misc';
import { _user } from 'std';
import moment from 'moment-timezone';
import { Icon as MDIcon } from '@mdi/react';

import { getSwitch, getTextInput, getCheckbox, getSelect, getTimePicker } from 'components/CRUDTables/helperFunctions';
import * as allMdiIcons from '@mdi/js';

import {
    Button,
    Paper,
    Typography,
    Grid,
    withTheme,
    CircularProgress,
    TextField,
    Icon,
    IconButton,
    Collapse,
    Dialog,
    colors,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    MenuItem,
    Tooltip,
    InputAdornment,
    FormControlLabel,
    FormControl,
    List,
    ListItemIcon,
    ListItemText,
    withWidth,
    Menu,
    ListItem
} from '@material-ui/core';

import StringListInput from 'components/CustomInputs/StringListInput';
import SnackbarContext from 'components/CustomSnackbar/SnackbarContext';
import {
    TIPPING_MODEL_INDIVIDUAL,
    TIPPING_MODEL_POOL,
    RESIDENTIAL,
    COMMERCIAL,
    CONDO,
    maxFileSize,
    AUDIT_INFRACTION_USER_STATE_UNDER_REVIEW,
    AUDIT_INFRACTION_USER_STATES,
    AVAILABLE_LANGS
} from 'constants.js';

import { loc } from 'localizations/localizationHandler';

import { isAUSRegion } from 'utils/misc';
import { useRef } from 'react';
import { Switch } from '@material-ui/core';

import { lightTheme, darkTheme, adminTheme, markerPinTheme } from 'theme';
import { validate } from 'utils/validate';

import { useMemo } from 'react';
import CustomWrapper from '../BulkCounter/CustomWrapper';
import { mdiBackburger, mdiForwardburger, mdiMenu } from '@mdi/js';
import { isWidthDown } from '@material-ui/core/withWidth';
import LocalizedTextInput from 'components/CRUDTables/Inputs/LocalizedTextInput';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import JournalEntries from '../JournalEntries/JournalEntries';
import ConfirmDialogContext from 'components/Dialogs/Confirm/ConfirmDialogContext';

function ConfigurationV2(props) {
    const { theme, twoFactorAuthenticationWarning, operator, width } = props;
    const warnAction = useContext(ConfirmDialogContext);
    const headerRef = useRef(null);
    const isSmallScreen = isWidthDown('md', width);

    const [lang, setLang] = useState(process.env.REACT_APP_REGION_LANGUAGE);
    const [configLoading, setConfigLoading] = useState(false);
    const [configUpdating, setConfigUpdating] = useState(false);
    const [config, setConfig] = useState({});
    const [selectedView, setSelectedView] = useState('2FA & SMS');
    const [showMenu, setShowMenu] = useState(true);
    const [menuEl, setMenuEl] = useState(null);
    const [errorDialogText, setErrorDialogText] = useState('');
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const [allCommodities, setAllCommodities] = useState([]);
    const [taxGroups, setTaxGroups] = useState([]);
    const [quickDropPromo, setQuickDropPromo] = useState({});
    const [questionnaires, setQuestionnaires] = useState([]);
    const [feeGroups, setFeeGroups] = useState([]);
    const [colorOverrides, setColorOverrides] = useState({
        light: {
            primary: false,
            secondary: false
        },
        dark: {
            primary: false,
            secondary: false
        },
        admin: {
            primary: false,
            secondary: false
        },
        charityPin: false,
        schemeUserPin: false
    });
    const [dropLocationMarkerFile, setDropLocationMarkerFile] = useState(undefined);
    const [dropLocationMarkerHeight, setDropLocationMarkerHeight] = useState(64);
    const [dropLocationMarkerWidth, setDropLocationMarkerWidth] = useState(48);
    const [dropLocationImageError, setDropLocationImageError] = useState(false);

    const [charityMarkerFile, setCharityMarkerFile] = useState(undefined);
    const [charityMarkerHeight, setCharityMarkerHeight] = useState(64);
    const [charityMarkerWidth, setCharityMarkerWidth] = useState(48);
    const [charityImageError, setCharityImageError] = useState(false);

    const [brandLogoFile, setBrandLogoFile] = useState(undefined);
    const [brandImageError, setBrandImageError] = useState(false);
    const [showCreateEditReasonDialog, setShowCreateEditReasonDialog] = useState(false);
    const [createEditReasonDialogMode, setCreateEditReasonDialogMode] = useState('create');
    const [editingReason, setEditingReason] = useState(null);

    const imageFileTypes = [
        'apng',
        'avif',
        'gif',
        'jpg',
        'jpeg',
        'jfif',
        'pjpeg',
        'pjp',
        'png',
        'svg',
        'webp',
        'bmp',
        'ico',
        'cur',
        'tif'
    ];

    const http = useContext(HttpContext);
    const onSnackbar = useContext(SnackbarContext);

    const fetchCommodities = async () => {
        const commodityRes = await http.getJSON('/commodities/getAllEnabledCommodities');
        if (commodityRes.ok) {
            let newCommodityList = Array.from(commodityRes.data.commodities, commoditiy => {
                return commoditiy.skuType;
            });
            setAllCommodities(newCommodityList);
        }
    };
    const fetchQuestionnaires = async () => {
        const res = await http.getJSON('/questionnaires');
        if (res.ok) {
            setQuestionnaires(res.data.questionnaires);
        }
    };

    const fetchGroupFees = async () => {
        const res = await http.getJSON('/feeGroups/getAllEnabledFeeGroups');
        if (res.ok) {
            setFeeGroups(res.data.collectionData);
        }
    };

    const errorList = {
        twoFactorAuthentication: ['twoFactorAuthentication'],
        contactInfo: ['emailAddresses', 'phoneNumbers'],
        email: [
            'sendGridConfiguration',
            'inactiveCustomerConfiguration.firstMessage',
            'inactiveCustomerConfiguration.secondMessage',
            'inactiveCustomerConfiguration.thirdMessage'
        ],
        general: [
            'charityTaskConfiguration',
            'charityTaxReceipts',
            'googleApi',
            'serviceableAreaLink',
            'customerHistory',
            'defaultCoordinates',
            'dashboard',
            'inactiveCustomerConfiguration.staleCustomerThreshold',
            'enabledFunctions',
            'idleLogoutTimeout',
            'charityNameSimilarityThreshold.charityNameSimilarityThreshold',
            'autoInvoicing',
            ...(isAUSRegion() ? ['customerInvoicing'] : [])
        ],
        helpCenter: ['helpCenter'],
        theme: [],
        audit: ['audit'],
        journalEntries: ['journalEntries'],
        intuit: ['intuit'],
        charity: [
            'charityTaskConfiguration.minimumVerifiedTasksForFeatured',
            'charityTaxReceipts.defaultMinTaxReceiptAmount',
            'charityNameSimilarityThreshold.charityNameSimilarityThreshold'
        ],
        impactReport: ['impactReport'],
        redemptions: ['redemptionOptions'],
        registration: ['registration'],
        shareFeedbackReferrals: ['shareAndFeedbackDialogConfig', 'referrals'],
        dropOff: [
            'dropAndGo.multiBagDrops',
            'quickDropPromo.label',
            'quickDropPromo.description',
            'quickDropPromo.serviceFee'
        ],
        por: ['liveCountAds', 'counterScreen', 'adjustments', 'bulkCounter', 'aiCounts'],
        drivers: [
            'driverTipping',
            'trips.generalOffloadReminders',
            'trips.lastTripOffloadReminders',
            'trips.maxOffloadTime',
            'trips.startingTripTasks',
            'trips.maxEarlyStartRouteTime',
            'trips.maxDistanceFromStartLocation',
            'pickups.afterCompleteDialogEnabled',
            'pickups.afterCompleteDialogTitle',
            'pickups.afterCompleteDialogContent',
            'pickups.afterCompleteDialogExamples',
            'pickups.completePickupImageTitle',
            'pickups.completePickupImageInstructions',
            'pickups.abortPickupImageTitle',
            'pickups.abortPickupImageInstructions'
        ],
        pickups: [
            'pickups.lowVolumeContainerThreshold',
            'pickups.abortThreshold',
            'pickups.postponeThreshold',
            'pickups.minPickupFeeEnabled',
            'pickups.minPickupFee',
            'pickups.minFrequencyPickupTypes',
            'pickups.minFrequency',
            'pickups.customerImageUploadEnabled',
            'collectors.defaultFixedCosts',
            'collectors.defaultServiceFee.type',
            'collectors.defaultServiceFee.value'
        ],
        routing: [
            'trips.bagEstimateOOSStop',
            'trips.clusterTimeOfDayPreferences',
            'trips.prioritizeRescheduled',
            'trips.clusterZones',
            'trips.capPickups',
            'trips.warningDistanceFromDepot',
            'trips.maxGasPayout'
        ],
        etas: [
            'trips.segregateETAVariablesByZone',
            'trips.etaUpdateImprovementPercentage',
            'trips.etaUpdateLimitThresholdPercentage',
            'trips.etaUpdateDataPointScaleValue',
            'trips.etaOptimizationWindow',
            'trips.dropOffPerBagDuration',
            'trips.dropOffBaseDuration',
            'trips.stopPerBagDuration',
            'trips.stopBaseDuration'
        ],
        SMS: ['SMS.isEnabled']
    };
    const hasError = lists => {
        let combinedList = [];
        lists.forEach(list => (combinedList = combinedList.concat(errorList[list])));

        return combinedList.some(field => _.get(formik.touched, field, false) && _.get(formik.errors, field, false));
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            ..._.omit(config, 'expressSignIn', 'sync'),
            promoCodeCustomer: {
                ..._.get(config, 'promoCodeCustomer'),
                customerSplit: !_.isNil(_.get(config, 'promoCodeCustomer.customerSplit'))
                    ? _.get(config, 'promoCodeCustomer.customerSplit') * 100
                    : '',
                fixedRateKickback: !_.isNil(_.get(config, 'promoCodeCustomer.fixedRateKickback'))
                    ? toDollars(_.get(config, 'promoCodeCustomer.fixedRateKickback'))
                    : '',
                kickbackThreshold: !_.isNil(_.get(config, 'promoCodeCustomer.kickbackThreshold'))
                    ? toDollars(_.get(config, 'promoCodeCustomer.kickbackThreshold'))
                    : ''
            },
            charityTaxReceipts: {
                defaultMinTaxReceiptAmount: _.get(config, 'charityTaxReceipts.defaultMinTaxReceiptAmount', 2000) / 100
            },
            customerHistory: {
                allowViewReceipt: _.get(config, 'customerHistory.allowViewReceipt', false),
                showMessageCollector: _.get(config, 'customerHistory.showMessageCollector', false),
                displayDepositTax: _.get(config, 'customerHistory.displayDepositTax', false),
                depositTaxGroup: _.get(config, 'customerHistory.depositTaxGroup', null),
                displayNetTax: _.get(config, 'customerHistory.displayNetTax', false),
                allowViewTaxReceiptHistory: _.get(config, 'customerHistory.allowViewTaxReceiptHistory', false),
                allowViewBagtags: _.get(config, 'customerHistory.allowViewBagtags', false),
                serviceFeeDisplay: _.get(config, 'customerHistory.serviceFeeDisplay', '')
            },
            customerPermissions: {
                canChangeAccountType: _.get(config, 'customerPermissions.canChangeAccountType', false),
                tokenExpirationTime: _.get(config, 'customerPermissions.tokenExpirationTime', 120),
                allowReceiptDownload: _.get(config, 'customerPermissions.allowReceiptDownload', false),
                allowRedemptionReceiptDownload: _.get(
                    config,
                    'customerPermissions.allowRedemptionReceiptDownload',
                    false
                ),
                showRedemptionBreakdown: _.get(config, 'customerPermissions.showRedemptionBreakdown', false),
                showImpactReportInSideMenu: _.get(config, 'customerPermissions.showImpactReportInSideMenu', true)
            },
            dropAndGo: {
                promoCode: _.get(config, 'dropAndGo.promoCode', ''),
                walkInPromoCode: _.get(config, 'dropAndGo.walkInPromoCode', ''),
                // displayQRCode: _.get(config, 'dropAndGo.displayQRCode', false),
                // allowRequestLabels: _.get(config, 'dropAndGo.allowRequestLabels', false),
                multiBagDrops: _.get(config, 'dropAndGo.multiBagDrops', false)
            },
            login: {
                showThirdPartyLogins: _.get(config, 'login.showThirdPartyLogins', true)
            },
            adminPermissions: {
                canEditCustomerEmail: _.get(config, 'adminPermissions.canEditCustomerEmail', true),
                canChangeCustomerPassword: _.get(config, 'adminPermissions.canChangeCustomerPassword', true),
                canRedeemCustomerBalance: _.get(config, 'adminPermissions.canRedeemCustomerBalance', true),
                canRedeemCharityBalance: _.get(config, 'adminPermissions.canRedeemCharityBalance', true)
            },
            inactiveCustomerConfiguration: {
                firstMessage: _.get(config, 'inactiveCustomerConfiguration.firstMessage'),
                secondMessage: {
                    daysSinceFirst: _.get(config, 'inactiveCustomerConfiguration.secondMessage.daysSinceFirst'),
                    promo: _.get(config, 'inactiveCustomerConfiguration.secondMessage.promo.code'),
                    shortUrl: _.get(config, 'inactiveCustomerConfiguration.secondMessage.shortUrl')
                },
                thirdMessage: {
                    daysSinceFirst: _.get(config, 'inactiveCustomerConfiguration.thirdMessage.daysSinceFirst'),
                    promo: _.get(config, 'inactiveCustomerConfiguration.thirdMessage.promo.code'),
                    shortUrl: _.get(config, 'inactiveCustomerConfiguration.thirdMessage.shortUrl')
                },
                staleCustomerThreshold: _.get(config, 'inactiveCustomerConfiguration.staleCustomerThreshold', 2 * 365)
            },
            adjustments: {
                maxDailyAmountPerUser: _.get(config, 'adjustments.maxDailyAmountPerUser', 0) / 100,
                enableAmountCapping: _.get(config, 'adjustments.enableAmountCapping', false),
                reasons: _.get(config, 'adjustments.reasons', [])
            },
            counterScreen: {
                refreshInterval: _.get(config, 'counterScreen.refreshInterval', 0) / 1000
            },
            trips: {
                ..._.get(config, 'trips'),
                defaultEtaStartTime: _.get(
                    config,
                    'trips.defaultEtaStartTime',
                    moment()
                        .tz(process.env.REACT_APP_REGION_TIMEZONE)
                        .startOf('day')
                        .add(8, 'hours')
                ),
                defaultEtaEndTime: _.get(
                    config,
                    'trips.defaultEtaEndTime',
                    moment()
                        .tz(process.env.REACT_APP_REGION_TIMEZONE)
                        .startOf('day')
                        .add(17, 'hours')
                ),
                etaUpdateLimitThresholdPercentage: _.get(config, 'trips.etaUpdateLimitThresholdPercentage', 0) * 100,
                etaUpdateImprovementPercentage: _.get(config, 'trips.etaUpdateImprovementPercentage', 0) * 100,
                enableDriverLateEmails: _.get(config, 'trips.enableDriverLateEmails', false)
            },
            redemptionOptions: {
                ..._.get(config, 'redemptionOptions'),
                commercialAccountsCanRequestETransfer: _.get(
                    config,
                    'redemptionOptions.commercialAccountsCanRequestETransfer',
                    true
                ),
                emailVerificationRequiredForETransfer: _.get(
                    config,
                    'redemptionOptions.emailVerificationRequiredForETransfer',
                    false
                ),
                bmoVariables: {
                    useBMOFormat: _.get(config, 'redemptionOptions.bmoVariables.useBMOFormat', false),
                    id: _.get(config, 'redemptionOptions.bmoVariables.id', ''),
                    olbbCustomerId: _.get(config, 'redemptionOptions.bmoVariables.olbbCustomerId', ''),
                    accountNumber: _.get(config, 'redemptionOptions.bmoVariables.accountNumber', ''),
                    expiryWindow: _.get(config, 'redemptionOptions.bmoVariables.expiryWindow', 30),
                    reminderFrequency: _.get(config, 'redemptionOptions.bmoVariables.reminderFrequency', 3),
                    maxReminders: _.get(config, 'redemptionOptions.bmoVariables.maxReminders', 5)
                }
            },
            collectors: {
                defaultFixedCosts: _.get(config, 'collectors.defaultFixedCosts', []),
                defaultServiceFee: {
                    ..._.get(config, 'collectors.defaultServiceFee', {}),
                    value: _.get(config, 'collectors.defaultServiceFee.value', 0) * 100
                },
                serviceFeesDisabled: _.get(config, 'collectors.serviceFeesDisabled', false)
            },
            audit: {
                bagDifference: {
                    enabled: _.get(config, 'audit.bagDifference.enabled', false),
                    threshold: _.get(config, 'audit.bagDifference.threshold', 1),
                    enabledCommodities: _.get(config, 'audit.bagDifference.enabledCommodities', []),
                    infractionUserState: _.get(
                        config,
                        'audit.bagDifference.infractionUserState',
                        AUDIT_INFRACTION_USER_STATE_UNDER_REVIEW
                    )
                },
                unitDifference: {
                    enabled: _.get(config, 'audit.unitDifference.enabled', false),
                    percentThreshold: _.get(config, 'audit.unitDifference.percentThreshold', 5),
                    enabledCommodities: _.get(config, 'audit.unitDifference.enabledCommodities', []),
                    infractionUserState: _.get(
                        config,
                        'audit.unitDifference.infractionUserState',
                        AUDIT_INFRACTION_USER_STATE_UNDER_REVIEW
                    )
                },
                driverComplaint: {
                    enabled: _.get(config, 'audit.driverComplaint.enabled', false),
                    threshold: _.get(config, 'audit.driverComplaint.threshold', 1),
                    timeWindow: _.get(config, 'audit.driverComplaint.timeWindow', 12),
                    enabledCommodities: _.get(config, 'audit.driverComplaint.enabledCommodities', []),
                    infractionUserState: _.get(
                        config,
                        'audit.driverComplaint.infractionUserState',
                        AUDIT_INFRACTION_USER_STATE_UNDER_REVIEW
                    )
                },
                counterComplaint: {
                    enabled: _.get(config, 'audit.counterComplaint.enabled', false),
                    threshold: _.get(config, 'audit.counterComplaint.threshold', 3),
                    timeWindow: _.get(config, 'audit.counterComplaint.timeWindow', 12),
                    enabledCommodities: _.get(config, 'audit.counterComplaint.enabledCommodities', []),
                    infractionUserState: _.get(
                        config,
                        'audit.counterComplaint.infractionUserState',
                        AUDIT_INFRACTION_USER_STATE_UNDER_REVIEW
                    )
                },
                customerRedemptionEmail: {
                    enabled: _.get(config, 'audit.customerRedemptionEmail.enabled', false),
                    infractionUserState: _.get(
                        config,
                        'audit.customerRedemptionEmail.infractionUserState',
                        AUDIT_INFRACTION_USER_STATE_UNDER_REVIEW
                    )
                },
                customerRedemptionAmount: {
                    enabled: _.get(config, 'audit.customerRedemptionAmount.enabled', false),
                    threshold: _.get(config, 'audit.customerRedemptionAmount.threshold', 1000),
                    timeWindow: _.get(config, 'audit.customerRedemptionAmount.timeWindow', 12),
                    infractionUserState: _.get(
                        config,
                        'audit.customerRedemptionAmount.infractionUserState',
                        AUDIT_INFRACTION_USER_STATE_UNDER_REVIEW
                    )
                },
                ipConflict: {
                    enabled: _.get(config, 'audit.ipConflict.enabled', false),
                    excludePastIPs: _.get(config, 'audit.ipConflict.excludePastIPs', false),
                    compareAllUsers: _.get(config, 'audit.ipConflict.compareAllUsers', false),
                    infractionUserState: _.get(
                        config,
                        'audit.ipConflict.infractionUserState',
                        AUDIT_INFRACTION_USER_STATE_UNDER_REVIEW
                    )
                },
                garbageComplaint: {
                    enabled: _.get(config, 'audit.garbageComplaint.enabled', false),
                    threshold: _.get(config, 'audit.garbageComplaint.threshold', 1),
                    timeWindow: _.get(config, 'audit.garbageComplaint.timeWindow', 12),
                    enabledCommodities: _.get(config, 'audit.garbageComplaint.enabledCommodities', []),
                    repeatInfractionAdvisory: _.get(config, 'audit.garbageComplaint.repeatInfractionAdvisory', false),
                    infractionUserState: _.get(
                        config,
                        'audit.garbageComplaint.infractionUserState',
                        AUDIT_INFRACTION_USER_STATE_UNDER_REVIEW
                    )
                },
                hazardousComplaint: {
                    enabled: _.get(config, 'audit.hazardousComplaint.enabled', false),
                    threshold: _.get(config, 'audit.hazardousComplaint.threshold', 1),
                    timeWindow: _.get(config, 'audit.hazardousComplaint.timeWindow', 12),
                    enabledCommodities: _.get(config, 'audit.hazardousComplaint.enabledCommodities', []),
                    repeatInfractionAdvisory: _.get(config, 'audit.hazardousComplaint.repeatInfractionAdvisory', false),
                    infractionUserState: _.get(
                        config,
                        'audit.hazardousComplaint.infractionUserState',
                        AUDIT_INFRACTION_USER_STATE_UNDER_REVIEW
                    )
                }
            },
            emailConfig: {
                enableCompletedPickupSurvey: _.get(config, 'emailConfig.enableCompletedPickupSurvey', false),
                completedPickupSurveyURL: _.get(config, 'emailConfig.completedPickupSurveyURL', ''),
                completedPickupSurveyLabel: _.get(config, 'emailConfig.completedPickupSurveyLabel', ''),
                globalFormatting: _.get(config, 'emailConfig.globalFormatting', {})
                // showServiceFeeInEmailReceipts: _.get(config, 'emailConfig.showServiceFeeInEmailReceipts', true)
            },
            dashboard: {
                showDepot: _.get(config, 'dashboard.showDepot', true),
                showInvoicing: _.get(config, 'dashboard.showInvoicing', true),
                showChecksAndBalances: _.get(config, 'dashboard.showChecksAndBalances', true),
                showDrivers: _.get(config, 'dashboard.showDrivers', true),
                consolidateCommission: _.get(config, 'dashboard.consolidateCommission', false),
                showFivePercentRefund: _.get(config, 'dashboard.showFivePercentRefund', true),
                showTotalInactiveUsers: _.get(config, 'dashboard.showTotalInactiveUsers', true),
                showContractorCost: _.get(config, 'dashboard.showContractorCost', false),
                inactiveBeforeNumMonths: _.get(config, 'dashboard.inactiveBeforeNumMonths', 12)
            },
            menuOptions: {
                showDefaultCollectorOptions: _.get(config, 'menuOptions.showDefaultCollectorOptions', false),
                showAccounting: _.get(config, 'menuOptions.showAccounting', false),
                //showQuickbooks: _.get(config, 'menuOptions.showQuickbooks', false),
                showJournalEntries: _.get(config, 'menuOptions.showJournalEntries', false),
                showReconciliation: _.get(config, 'menuOptions.showReconciliation', false),
                showAgingBalances: _.get(config, 'menuOptions.showAgingBalances', false)
            },
            quickDropPromo: {
                label: _.get(quickDropPromo, 'label'),
                description: _.get(quickDropPromo, 'description'),
                serviceFee: _.round((1 - _.get(quickDropPromo, 'variables.customerSplit', 0.95)) * 100, 2)
            },
            registration: {
                questionnaire: _.get(config, 'registration.questionnaire', ''),
                tagline: _.get(config, 'registration.tagline', ''),
                securityQuestions: _.get(config, 'registration.securityQuestions', []),
                tooltips: _.get(config, 'registration.tooltips', {}),
                options: {
                    regCustomer: _.get(config, 'registration.options.regCustomer', false),
                    regBusiness: _.get(config, 'registration.options.regBusiness', false),
                    regOrganization: _.get(config, 'registration.options.regOrganization', false),
                    regGroup: _.get(config, 'registration.options.regGroup', false),
                    bottleDrive: _.get(config, 'registration.options.bottleDrive', false),
                    regCollectionProgram: _.get(config, 'registration.options.regCollectionProgram', false)
                },
                require2FABeforeCompletion: _.get(config, 'registration.require2FABeforeCompletion', false),
                emailVerificationRequired: _.get(config, 'registration.emailVerificationRequired', false),
                showSecurityQuestions: _.get(config, 'registration.showSecurityQuestions', false),
                showPOBoxInput: _.get(config, 'registration.showPOBoxInput', false),
                showRepeatPassword: _.get(config, 'registration.showRepeatPassword', false),
                showBusinessPhone: _.get(config, 'registration.showBusinessPhone', false),
                showBusinessNumber: _.get(config, 'registration.showBusinessNumber', true),
                showSchemeID: _.get(config, 'registration.showSchemeID', false)
            },
            charityNameSimilarityThreshold: {
                charityNameSimilarityThreshold: _.get(
                    config,
                    'charityNameSimilarityThreshold.charityNameSimilarityThreshold',
                    0.8
                )
            },
            charityTaskConfiguration: {
                growthPlanEnabled: _.get(config, 'charityTaskConfiguration.growthPlanEnabled', true),
                minimumVerifiedTasksForFeatured: _.get(
                    config,
                    'charityTaskConfiguration.minimumVerifiedTasksForFeatured'
                )
            },
            idleLogoutTimeout: _.get(config, 'idleLogoutTimeout', {}),
            pickups: {
                ..._.get(config, 'pickups', {}),
                minFrequency: _.get(config, 'pickups.minFrequency', 1),
                minFrequencyPickupTypes: _.get(config, 'pickups.minFrequencyPickupTypes', []),
                minPickupFee: _.get(config, 'pickups.minPickupFee', 0) / 100,
                customerImageUploadEnabled: _.get(config, 'pickups.customerImageUploadEnabled', true)
            },
            helpCenter: {
                showHowItWorks: _.get(config, 'helpCenter.showHowItWorks', true),
                showFaq: _.get(config, 'helpCenter.showFaq', true),
                showContactUs: _.get(config, 'helpCenter.showContactUs', true),
                showZohoHelpDesk: _.get(config, 'helpCenter.showZohoHelpDesk', false)
            },
            impactReport: {
                showImpactReport: _.get(config, 'shareAndFeedbackDialogConfig.shareScreen.enabled', true),
                waterAmount: _.get(config, 'impactReport.waterAmount', 6.686),
                energyAmount: _.get(config, 'impactReport.energyAmount', 1.551),
                landfillAmount: _.get(config, 'impactReport.landfillAmount', 0.07),
                carbonAmount: _.get(config, 'impactReport.carbonAmount', 0.145),
                waterUnit: _.get(config, 'impactReport.waterUnit', 'Liters'),
                energyUnit: _.get(config, 'impactReport.energyUnit', 'Megajoules'),
                landfillUnit: _.get(config, 'impactReport.landfillUnit', 'Kgs'),
                carbonUnit: _.get(config, 'impactReport.carbonUnit', 'Kgs')
            },
            aiCounts: {
                quality: _.get(config, 'aiCounts.quality', 100),
                maxDimensions: _.get(config, 'aiCounts.maxDimensions', 0)
            },
            customerInvoicing: {
                defaultFeeInvoicePaymentWindow: isAUSRegion()
                    ? _.get(config, 'customerInvoicing.defaultFeeInvoicePaymentWindow', 30)
                    : null
            },
            theme: {
                light: {
                    primary: _.get(config, 'theme.light.primary'),
                    secondary: _.get(config, 'theme.light.secondary'),
                    env: _.get(config, 'theme.light.env')
                },
                dark: {
                    primary: _.get(config, 'theme.dark.primary'),
                    secondary: _.get(config, 'theme.dark.secondary'),
                    env: _.get(config, 'theme.dark.env')
                },
                admin: {
                    primary: _.get(config, 'theme.admin.primary'),
                    secondary: _.get(config, 'theme.admin.secondary'),
                    env: _.get(config, 'theme.admin.env')
                },
                charityPin: _.get(config, 'theme.charityPin'),
                schemeUserPin: _.get(config, 'theme.schemeUserPin', markerPinTheme.schemeUserPin),
                pinLabelStyle: _.get(config, 'theme.pinLabelStyle', markerPinTheme.pinLabelStyle),
                technologyLogo: _.get(config, 'theme.technologyLogo', markerPinTheme.technologyLogo),

                noTechnologyLogo: _.get(config, 'theme.noTechnologyLogo', markerPinTheme.noTechnologyLogo)
            },
            dropLocationMarker: {
                ..._.get(config, 'dropLocationMarker', {})
            },
            charityMarker: {
                ..._.get(config, 'charityMarker', {})
            },
            brandLogo: {
                ..._.get(config, 'brandLogo', {})
            },
            subscription: {
                feeGroups: _.get(config, 'subscription.feeGroups', [])
            },
            bulkCounter: {
                hourWindow: _.get(config, 'bulkCounter.hourWindow', 48),
                daysToCount: _.get(config, 'bulkCounter.daysToCount', 2),
                containersPerBag: _.get(config, 'bulkCounter.containersPerBag', 250),
                porCameraEnabled: _.get(config, 'bulkCounter.porCameraEnabled', false)
            },
            address: {
                customerReceiptAddress: _.get(config, 'address.customerReceiptAddress', '')
            },
            autoInvoicing: {
                enabled: _.get(config, 'autoInvoicing.enabled', false),
                minDaysSincePickup: _.get(config, 'autoInvoicing.minDaysSincePickup', 7)
            }

            // imageConfig: {
            //     maxImageUploads: _.get(config, 'imageConfig.maxImageUploads', 5)
            // }
        },
        validationSchema: Yup.object({
            bulkCounter: Yup.object({
                hourWindow: Yup.number()
                    .required('You must enter a value')
                    .min(0, 'Must be zero or more'),
                containersPerBag: Yup.number()
                    .required('You must enter a value')
                    .min(0, 'Must be zero or more'),
                porCameraEnabled: Yup.boolean()
            }),
            pickups: Yup.object({
                lowVolumeContainerThreshold: Yup.number()
                    .required('You must enter a value')
                    .min(0, 'Must be zero or more'),
                abortThreshold: Yup.number()
                    .required('You must enter a value')
                    .min(0, 'Must be zero or more'),
                postponeThreshold: Yup.number()
                    .required('You must enter a value')
                    .min(0, 'Must be zero or more'),
                afterCompleteDialogEnabled: Yup.boolean(),
                afterCompleteDialogTitle: Yup.object({
                    en: Yup.string().required('You must enter a value')
                }).nullable(),
                afterCompleteDialogContent: Yup.object({
                    en: Yup.string().required('You must enter a value')
                }).nullable(),
                afterCompleteDialogExamples: Yup.object({
                    en: Yup.array()
                        .of(Yup.string())
                        .min(1, 'You must enter a value')
                }).nullable(),
                completePickupImageTitle: Yup.object({
                    en: Yup.string().required('You must enter a value')
                }).nullable(),
                completePickupImageInstructions: Yup.object({
                    en: Yup.array()
                        .of(Yup.string())
                        .min(1, 'You must enter a value')
                }).nullable(),
                abortPickupImageTitle: Yup.object({
                    en: Yup.string().required('You must enter a value')
                }).nullable(),
                abortPickupImageInstructions: Yup.object({
                    en: Yup.array()
                        .of(Yup.string())
                        .min(1, 'You must enter a value')
                }).nullable(),
                sendPickupCompleteEmail: Yup.boolean(),
                sendPickupCompleteAndRecurringEmail: Yup.boolean(),
                minPickupFee: Yup.number().min(0, 'This must be 0 or more'),
                minPickupFeeEnabled: Yup.boolean(),
                minFrequency: Yup.number().min(1, 'This must be 1 or more'),
                minFrequencyPickupTypes: Yup.array().of(Yup.string()),
                customerImageUploadEnabled: Yup.boolean()
            }),
            trips: Yup.object({
                stopBaseDuration: Yup.number()
                    .required('You must enter a value')
                    .min(0, 'Must be zero or more'),
                stopPerBagDuration: Yup.number()
                    .required('You must enter a value')
                    .min(0, 'Must be zero or more'),
                etaOptimizationWindow: Yup.number()
                    .required('You must enter a value')
                    .min(0, 'Must be zero or more'),
                etaUpdateDataPointScaleValue: Yup.number()
                    .required('You must enter a value')
                    .min(0, 'Must be zero or more'),
                etaUpdateLimitThresholdPercentage: Yup.number()
                    .required('You must enter a value')
                    .min(0, 'Must be zero or more'),
                etaUpdateImprovementPercentage: Yup.number()
                    .required('You must enter a value')
                    .min(0, 'Must be zero or more'),
                dropOffBaseDuration: Yup.number()
                    .required('You must enter a value')
                    .min(0, 'Must be zero or more'),
                dropOffPerBagDuration: Yup.number()
                    .required('You must enter a value')
                    .min(0, 'Must be zero or more'),
                etaNotificationWindow: Yup.number()
                    .required('You must enter a value')
                    .min(1, 'Must be greater than 0'),
                useEtaWindow: Yup.boolean(),
                maxCustomerEtaNotifications: Yup.number()
                    .required('You must enter a value')
                    .min(0, 'Must be zero or more'),
                customerEtaNotifications: Yup.boolean(),
                generalOffloadReminders: Yup.object({
                    en: Yup.array()
                        .of(Yup.string())
                        .min(1, 'You must enter a value')
                }).nullable(),
                startingTripTasks: Yup.object({
                    en: Yup.array()
                        .of(Yup.string())
                        .min(1, 'You must enter a value')
                }).nullable(),
                maxEarlyStartRouteTime: Yup.number().min(1, 'Must be greater than 0'),
                maxDistanceFromStartLocation: Yup.number().min(0, 'Must be greater than 0'),
                enableDriverLateEmails: Yup.boolean(),
                lastTripOffloadReminders: Yup.object({
                    en: Yup.array().of(Yup.string())
                }).nullable(),
                maxOffloadTime: Yup.number()
                    .required('You must enter a value')
                    .min(1, 'Must be greater than 0'),
                // maxStartTripTime: Yup.number().min(1, 'Must be greater than 0'),
                clusterTimeOfDayPreferences: Yup.boolean(),
                clusterZones: Yup.boolean(),
                prioritizeRescheduled: Yup.boolean(),
                capPickups: Yup.boolean(),
                bagEstimateOOSStop: Yup.number()
                    .required('You must enter a value')
                    .min(1, 'Must be greater than 0'),
                warningDistanceFromDepot: Yup.number().min(1, 'Must be greater than 0'),
                maxGasPayout: Yup.number().min(1, 'Must be greater than 0')
            }),
            twoFactorAuthentication: Yup.object({
                isRequired: Yup.boolean(),
                isEnabled: Yup.boolean(),
                limitCodeRequests: Yup.boolean(),
                limitAttempts: Yup.boolean(),
                maxCodeRequests: Yup.number().when('limitCodeRequests', {
                    is: value => value === true,
                    then: Yup.number()
                        .required('You must enter a value')
                        .min(1, 'Must be greater than zero')
                }),
                maxAuthenticationAttempts: Yup.number().when('limitAttempts', {
                    is: true,
                    then: Yup.number()
                        .required('You must enter a value')
                        .min(1, 'Must be greater than zero')
                }),
                minutesToLockAccount: Yup.number().when(['limitAttempts', 'limitCodeRequests'], {
                    is: (limitAttempts, limitCodeRequests) => {
                        return limitAttempts || limitCodeRequests;
                    },
                    then: Yup.number()
                        .required('You must enter a value')
                        .min(1, 'Must be greater than zero')
                }),
                secondsBetweenCodeRequests: Yup.number().min(0, 'Must be zero or more'),
                monthsBeforeTrustedDevicesExpire: Yup.number()
                    .min(1, 'Must be 1 or more')
                    .required('You must enter a value'),
                identityVerification: Yup.object({
                    minutesToLockAccount: Yup.number().min(0, 'Must be 0 or more'),
                    maxAttempts: Yup.number().min(1, 'Must be 1 or more')
                })
            }),
            driverTipping: Yup.object({
                enableDriverTipping: Yup.boolean(),
                tippingModel: Yup.string()
            }),
            serviceableAreaLink: Yup.object({
                isEnabled: Yup.boolean(),
                link: Yup.string().required('You must enter a value'),
                text: Yup.string().required('You must enter a value')
            }),
            redemptionOptions: Yup.object({
                charity: Yup.object({
                    methods: Yup.object({
                        directDeposit: Yup.boolean(),
                        cheque: Yup.boolean(),
                        etransfer: Yup.boolean(),
                        cash: Yup.boolean(),
                        bankTransfer: Yup.boolean()
                    }),
                    enableEtransferLimit: Yup.boolean(),
                    chequeMinimum: isAUSRegion()
                        ? Yup.number()
                        : Yup.number()
                              .required('You must enter a value')
                              .min(0, 'Must be zero or more'),
                    bankTransferMinimum: isAUSRegion()
                        ? Yup.number()
                        : Yup.number()
                              .required('You must enter a value')
                              .min(0, 'Must be zero or more'),
                    eTransferMinimum: isAUSRegion()
                        ? Yup.number()
                        : Yup.number()
                              .required('You must enter a value')
                              .min(0, 'Must be zero or more'),
                    etransferLimit: isAUSRegion()
                        ? Yup.number()
                        : Yup.number()
                              .required('You must enter a value')
                              .min(0, 'Must be zero or more'),
                    cashMinimum: isAUSRegion()
                        ? Yup.number()
                        : Yup.number()
                              .required('You must enter a value')
                              .min(0, 'Must be zero or more')
                }),
                customer: Yup.object({
                    methods: Yup.object({
                        directDeposit: Yup.boolean(),
                        cheque: Yup.boolean(),
                        etransfer: Yup.boolean(),
                        cash: Yup.boolean(),
                        bankTransfer: Yup.boolean()
                    }),
                    enableEtransferLimit: Yup.boolean(),
                    chequeMinimum: isAUSRegion()
                        ? Yup.number()
                        : Yup.number()
                              .required('You must enter a value')
                              .min(0, 'Must be zero or more'),
                    bankTransferMinimum: isAUSRegion()
                        ? Yup.number()
                        : Yup.number()
                              .required('You must enter a value')
                              .min(0, 'Must be zero or more'),
                    eTransferMinimum: isAUSRegion()
                        ? Yup.number()
                        : Yup.number()
                              .required('You must enter a value')
                              .min(0, 'Must be zero or more'),
                    etransferLimit: isAUSRegion()
                        ? Yup.number()
                        : Yup.number()
                              .required('You must enter a value')
                              .min(0, 'Must be zero or more'),
                    cashMinimum: isAUSRegion()
                        ? Yup.number()
                        : Yup.number()
                              .required('You must enter a value')
                              .min(0, 'Must be zero or more')
                }),
                redemptionFee: Yup.number()
                    .required('You must enter a value')
                    .min(0, 'Must be zero or more'),
                securityQuestions: Yup.object().required('You must enter a value'),
                bmoVariables: Yup.object({
                    useBMOFormat: Yup.boolean(),
                    id: Yup.string().when('useBMOFormat', {
                        is: true,
                        then: Yup.string()
                            .required('You must enter a value')
                            .min(6, 'Must be exactly 6 characters')
                            .max(6, 'Must be exactly 6 characters')
                    }),
                    olbbCustomerId: Yup.string().when('useBMOFormat', {
                        is: true,
                        then: Yup.string()
                            .required('You must enter a value')
                            .min(8, 'Must be exactly 8 characters')
                            .max(8, 'Must be exactly 8 characters')
                    }),
                    accountNumber: Yup.string().when('useBMOFormat', {
                        is: true,
                        then: Yup.string()
                            .required('You must enter a value')
                            .min(15, 'Must be exactly 15 characters')
                            .max(15, 'Must be exactly 15 characters')
                    }),
                    expiryWindow: Yup.number().when('useBMOFormat', {
                        is: true,
                        then: Yup.number()
                            .required('You must enter a value')
                            .min(1, 'Must be between 1 and 90')
                            .max(90, 'Must be between 1 and 90')
                    }),
                    reminderFrequency: Yup.number().when('useBMOFormat', {
                        is: true,
                        then: Yup.number()
                            .required('You must enter a value')
                            .min(3, 'Must be between 3 and 90')
                            .max(90, 'Must be between 3 and 90')
                    }),
                    maxReminders: Yup.number().when('useBMOFormat', {
                        is: true,
                        then: Yup.number()
                            .required('You must enter a value')
                            .min(1, 'Must be between 1 and 5')
                            .max(5, 'Must be between 1 and 5')
                    })
                })
            }),
            intuit: Yup.object({
                company_id: Yup.string().required('You must enter a value'),
                accounts: Yup.object({
                    depositsReceivable: Yup.object({
                        name: Yup.string().required('You must enter a value'),
                        id: Yup.string().required('You must enter a value')
                    }),
                    customerLiability: Yup.object({
                        name: Yup.string().required('You must enter a value'),
                        id: Yup.string().required('You must enter a value')
                    }),
                    serviceIncome: Yup.object({
                        name: Yup.string().required('You must enter a value'),
                        id: Yup.string().required('You must enter a value')
                    }),
                    taxLiability: Yup.object({
                        name: Yup.string().required('You must enter a value'),
                        id: Yup.string().required('You must enter a value')
                    }),
                    referrals: Yup.object({
                        name: Yup.string().required('You must enter a value'),
                        id: Yup.string().required('You must enter a value')
                    }),
                    serviceFees: Yup.object({
                        name: Yup.string().required('You must enter a value'),
                        id: Yup.string().required('You must enter a value')
                    }),
                    redemptionsPayment: Yup.object({
                        name: Yup.string().required('You must enter a value'),
                        id: Yup.string().required('You must enter a value')
                    }),
                    adSpend: Yup.object({
                        name: Yup.string().required('You must enter a value'),
                        id: Yup.string().required('You must enter a value')
                    })
                })
            }),
            idleLogoutTimeout: Yup.object({
                collectorAdministrator: Yup.number()
                    .required('You must enter a value')
                    .min(0, 'Must be zero or more')
                    .max(99999, 'Must be less than 100000'),
                collectorEmployee: Yup.number()
                    .required('You must enter a value')
                    .min(0, 'Must be zero or more')
                    .max(99999, 'Must be less than 100000'),
                customer: Yup.number()
                    .required('You must enter a value')
                    .min(0, 'Must be zero or more')
                    .max(99999, 'Must be less than 100000'),
                systemAdministrator: Yup.number()
                    .required('You must enter a value')
                    .min(0, 'Must be zero or more')
                    .max(99999, 'Must be less than 100000')
            }),
            inactiveCustomerConfiguration: Yup.object({
                firstMessage: Yup.object({
                    maxCustomers: Yup.number()
                        .required('You must enter a value')
                        .min(0, 'Must be zero or more'),
                    registeredNoPickup: Yup.number()
                        .required('You must enter a value')
                        .min(0, 'Must be zero or more'),
                    lastPickup: Yup.number()
                        .required('You must enter a value')
                        .min(0, 'Must be zero or more'),
                    shortUrl: Yup.string()
                        .required('You must enter a value')
                        .url('Must be a valid url')
                }),
                secondMessage: Yup.object({
                    daysSinceFirst: Yup.number()
                        .required('You must enter a value')
                        .min(0, 'Must be zero or more'),
                    promo: Yup.string().required('You must enter a value'),
                    shortUrl: Yup.string()
                        .required('You must enter a value')
                        .url('Must be a valid url')
                }),
                thirdMessage: Yup.object({
                    daysSinceFirst: Yup.number()
                        .required('You must enter a value')
                        .min(0, 'Must be zero or more'),
                    promo: Yup.string().required('You must enter a value'),
                    shortUrl: Yup.string()
                        .required('You must enter a value')
                        .url('Must be a valid url')
                }),
                staleCustomerThreshold: Yup.number()
                    .required('You must enter a value')
                    .min(0, 'Must by greater than 0')
            }),
            charityTaskConfiguration: Yup.object({
                growthPlanEnabled: Yup.boolean(),
                minimumVerifiedTasksForFeatured: Yup.number().when('growthPlanEnabled', {
                    is: true,
                    then: Yup.number()
                        .required('You must enter a value')
                        .min(0, 'Must be zero or more')
                })
            }),
            sendGridConfiguration: Yup.object({
                templateIds: Yup.object({
                    // inactiveFirstMessage: Yup.string().required('You must enter a value'),
                    // inactiveSecondMessage: Yup.string().required('You must enter a value'),
                    // inactiveThirdMessage: Yup.string().required('You must enter a value'),
                    // notFeaturedReminder: Yup.string().required('You must enter a value'),
                    // unfeaturedCharity: Yup.string().required('You must enter a value'),
                    // featuredCharityEmail: Yup.string().required('You must enter a value'),
                    // expiredCharityTask: Yup.string().required('You must enter a value'),
                    notificationEmail: Yup.object({
                        en: Yup.string().required('You must enter a value'),
                        fr:
                            AVAILABLE_LANGS[process.env.REACT_APP_REGION_EXT].includes('fr') &&
                            Yup.string().required('You must enter a value')
                    })
                }),
                unsubscribeGroups: Yup.object({
                    inactiveCustomers: Yup.string().required('You must enter a value'),
                    charities: Yup.string().required('You must enter a value'),
                    general: Yup.string().required('You must enter a value')
                })
            }),
            shareAndFeedbackDialogConfig: Yup.object({
                ratingScreen: Yup.object({
                    ordering: Yup.number().required('You must enter a value')
                }),
                referralScreen: Yup.object({
                    ordering: Yup.number().required('You must enter a value')
                }),
                shareScreen: Yup.object({
                    ordering: Yup.number().required('You must enter a value')
                })
            }),
            promoCodeCustomer: Yup.object({
                customerSplit: Yup.number()
                    .required('You must enter a value')
                    .min(0, 'This must be positive')
                    .max(100, 'This cannot be more than 100%'),
                fixedRateKickback: Yup.number()
                    .required('You must enter a value')
                    .min(0, 'This must be positive'),
                kickbackThreshold: Yup.number()
                    .required('You must enter a value')
                    .min(0, 'This must be positive')
            }),
            googleApi: Yup.object({
                mapsCoordsKey: Yup.string().required('You must enter a value')
            }),
            defaultCoordinates: Yup.object({
                latitude: Yup.number()
                    .required('You must enter a value')
                    .min(-90, 'Cannot be less than -90')
                    .max(90, 'Cannot be greater than 90'),
                longitude: Yup.number()
                    .required('You must enter a value')
                    .min(-180, 'Cannot be less than -180')
                    .max(180, 'Cannot be greater than 180')
            }),
            charityTaxReceipts: Yup.object({
                defaultMinTaxReceiptAmount: Yup.number()
                    .required('You must enter a value')
                    .min(0, 'Must be greater than zero')
            }),
            emailAddresses: Yup.object({
                service: Yup.string()
                    .required('You must enter a value')
                    .email('Must be a valid email address'),
                payments: Yup.string()
                    .required('You must enter a value')
                    .email('Must be a valid email address'),
                orders: Yup.string()
                    .required('You must enter a value')
                    .email('Must be a valid email address')
            }),
            phoneNumbers: Yup.object({
                service: Yup.string().test('validPhoneNumber', 'Phone number invalid', value => {
                    if (!value) return false;
                    let errorsObj = validate(['phone'], value, lang);
                    return !errorsObj.fail;
                }),
                kantech: Yup.string().test('validPhoneNumber', 'Phone number invalid', value => {
                    if (!value) return false;
                    let errorsObj = validate(['phone'], value, lang);
                    return !errorsObj.fail;
                })
            }),
            // complaints: Yup.object({
            //     customerComplaints: Yup.object({
            //         resolutions: Yup.array().of(
            //             Yup.object().shape({
            //                 code: Yup.string()
            //                     .required('Code should be auto generated')
            //                     .max(4, 'Must be less than 4 characters'),
            //                 label: Yup.string(),
            //                 description: Yup.string(),
            //                 emailToCustomer: Yup.boolean()
            //             })
            //         )
            //     }),
            //     counterComplaints: Yup.object({
            //         options: Yup.array().of(
            //             Yup.object().shape({
            //                 code: Yup.string()
            //                     .required('Code should be auto generated')
            //                     .max(4, 'Must be less than 4 characters'),
            //                 label: Yup.string(),
            //                 emailToCustomer: Yup.boolean()
            //             })
            //         )
            //     }),
            //     driverComplaints: Yup.object({
            //         options: Yup.array().of(
            //             Yup.object().shape({
            //                 code: Yup.string()
            //                     .required('Code should be auto generated')
            //                     .max(4, 'Must be less than 4 characters'),
            //                 label: Yup.string(),
            //                 emailToCustomer: Yup.boolean()
            //             })
            //         )
            //     })
            // }),
            counterScreen: Yup.object({
                refreshInterval: Yup.number().test('min-5', 'Must be minimum 5 seconds', value => {
                    return value === 0 || value === undefined || value >= 5;
                })
            }),
            audit: Yup.object({
                bagDifference: Yup.object({
                    threshold: Yup.number()
                        .required('You must enter a value')
                        .min(0, 'This must be positive')
                }),
                unitDifference: Yup.object({
                    percentThreshold: Yup.number()
                        .required('You must enter a value')
                        .min(0, 'This must be positive')
                }),
                driverComplaint: Yup.object({
                    threshold: Yup.number()
                        .required('You must enter a value')
                        .min(0, 'This must be positive'),
                    timeWindow: Yup.number()
                        .required('You must enter a value')
                        .min(0, 'This must be positive')
                }),
                counterComplaint: Yup.object({
                    threshold: Yup.number()
                        .required('You must enter a value')
                        .min(0, 'This must be positive'),
                    timeWindow: Yup.number()
                        .required('You must enter a value')
                        .min(0, 'This must be positive')
                }),
                garbageComplaint: Yup.object({
                    threshold: Yup.number()
                        .required('You must enter a value')
                        .min(0, 'This must be positive'),
                    timeWindow: Yup.number()
                        .required('You must enter a value')
                        .min(0, 'This must be positive')
                }),
                hazardousComplaint: Yup.object({
                    threshold: Yup.number()
                        .required('You must enter a value')
                        .min(0, 'This must be positive'),
                    timeWindow: Yup.number()
                        .required('You must enter a value')
                        .min(0, 'This must be positive')
                }),
                customerRedemptionAmount: Yup.object({
                    threshold: Yup.number()
                        .required('You must enter a value')
                        .min(0, 'This must be positive'),
                    timeWindow: Yup.number()
                        .required('You must enter a value')
                        .min(0, 'This must be positive')
                })
            }),
            dashboard: Yup.object({
                inactiveBeforeNumMonths: Yup.number()
                    .required('You must enter a value')
                    .min(0, 'This must be positive')
            }),
            quickDropPromo: Yup.object({
                label: Yup.string().required('You must enter a value'),
                description: Yup.string().required('You must enter a value'),
                serviceFee: Yup.number()
                    .required('You must enter a value')
                    .min(0, 'This must be 0 or more')
                    .max(100, 'This must be 100 or less')
            }),
            registration: Yup.object({
                questionnaire: Yup.string(),
                // tagline: Yup.object().required('You must enter a value'),
                options: Yup.object()
            }),
            charityNameSimilarityThreshold: Yup.object({
                charityNameSimilarityThreshold: Yup.number()
                    .required('You must enter a value')
                    .min(0, 'This must be 0 or more')
                    .max(1, 'This must be 1 or less')
            }),
            impactReport: Yup.object({
                showImpactReport: Yup.boolean(),
                waterAmount: Yup.number().when('showImpactReport', {
                    is: true,
                    then: Yup.number()
                        .required('You must enter a value')
                        .min(0, 'This must be 0 or more')
                }),
                energyAmount: Yup.number().when('showImpactReport', {
                    is: true,
                    then: Yup.number()
                        .required('You must enter a value')
                        .min(0, 'This must be 0 or more')
                }),
                landfillAmount: Yup.number().when('showImpactReport', {
                    is: true,
                    then: Yup.number()
                        .required('You must enter a value')
                        .min(0, 'This must be 0 or more')
                }),
                carbonAmount: Yup.number().when('showImpactReport', {
                    is: true,
                    then: Yup.number()
                        .required('You must enter a value')
                        .min(0, 'This must be 0 or more')
                }),
                waterUnit: Yup.string().when('showImpactReport', {
                    is: true,
                    then: Yup.string().required('You must enter a value')
                }),
                energyUnit: Yup.string().when('showImpactReport', {
                    is: true,
                    then: Yup.string().required('You must enter a value')
                }),
                landfillUnit: Yup.string().when('showImpactReport', {
                    is: true,
                    then: Yup.string().required('You must enter a value')
                }),
                carbonUnit: Yup.string().when('showImpactReport', {
                    is: true,
                    then: Yup.string().required('You must enter a value')
                })
            }),
            aiCounts: Yup.object({
                quality: Yup.number()
                    .required('You must enter a value')
                    .min(1, 'This must be 1 or more'),
                maxDimensions: Yup.number()
                    .required('You must enter a value')
                    .min(0, 'This must be 0 or more')
            }),
            customerInvoicing: Yup.object({
                defaultFeeInvoicePaymentWindow: isAUSRegion() ? Yup.number().min(1, 'Must be 1 or more') : null
            }),
            SMS: Yup.object({
                isEnabled: Yup.boolean()
            }),
            autoInvoicing: Yup.object({
                enabled: Yup.boolean(),
                minDaysSincePickup: Yup.number().min(1, 'This must be 1 or more')
            })
            // dropAndGo: Yup.object({
            //     promoCode: Yup.string().required('You must enter a value (ex. DROP&GO)'),
            //     walkInPromoCode: Yup.string().required('You must enter a value (ex. WALKIN)')
            // })
            // imageConfig: Yup.object({
            //     maxImageUploads: Yup.number()
            //         .required('You must enter a value')
            //         .min(0, 'This must be positive')
            // })
        }),
        onSubmit: async values => {
            values.theme.light.primary = colorOverrides.light.primary ? values.theme.light.primary : undefined;
            values.theme.light.secondary = colorOverrides.light.secondary ? values.theme.light.secondary : undefined;
            values.theme.dark.primary = colorOverrides.dark.primary ? values.theme.dark.primary : undefined;
            values.theme.dark.secondary = colorOverrides.dark.secondary ? values.theme.dark.secondary : undefined;
            values.theme.admin.primary = colorOverrides.admin.primary ? values.theme.admin.primary : undefined;
            values.theme.admin.secondary = colorOverrides.admin.secondary ? values.theme.admin.secondary : undefined;

            values.theme.charityPin = colorOverrides.charityPin ? values.theme.charityPin : undefined;

            values.theme.schemeUserPin = colorOverrides.schemeUserPin ? values.theme.schemeUserPin : undefined;

            if (isSTDRegion() || isAUSRegion()) {
                values.theme.technologyLogo = values.theme.technologyLogo
                    ? _.get(allMdiIcons, convertToImportName(values.theme.technologyLogo), false)
                        ? convertToImportName(values.theme.technologyLogo)
                        : markerPinTheme.technologyLogo
                    : markerPinTheme.technologyLogo;
                values.theme.noTechnologyLogo = values.theme.noTechnologyLogo
                    ? _.get(allMdiIcons, convertToImportName(values.theme.noTechnologyLogo), false)
                        ? convertToImportName(values.theme.noTechnologyLogo)
                        : markerPinTheme.noTechnologyLogo
                    : markerPinTheme.noTechnologyLogo;
            }
            //convert to ratio
            values.promoCodeCustomer.customerSplit /= 100;

            //convert to cents
            values.promoCodeCustomer.fixedRateKickback *= 100;
            values.promoCodeCustomer.kickbackThreshold *= 100;
            values.pickups.minPickupFee *= 100;
            values.charityTaxReceipts.defaultMinTaxReceiptAmount *= 100;
            values.adjustments.maxDailyAmountPerUser *= 100;
            values.counterScreen.refreshInterval *= 1000;
            values.trips.etaUpdateLimitThresholdPercentage /= 100;
            values.trips.etaUpdateImprovementPercentage /= 100;

            values.collectors.defaultServiceFee.value /= 100;

            values.dropLocationMarker.height = dropLocationMarkerHeight;
            values.dropLocationMarker.width = dropLocationMarkerWidth;
            values.charityMarker.height = charityMarkerHeight;
            values.charityMarker.width = charityMarkerWidth;

            if (!_.get(values, 'enabledFunctions.collectionProgram', true)) {
                values.registration.options.regCollectionProgram = false;
            }

            const formData = new FormData(); // NB: FormData objects cannot be displayed in console.log
            if (!_.isNil(dropLocationMarkerFile)) {
                formData.append('dropLocationMarkerFile', dropLocationMarkerFile); //will show up in req.files
            }
            if (!_.isNil(charityMarkerFile)) {
                formData.append('charityMarkerFile', charityMarkerFile); //will show up in req.files
            }
            if (!_.isNil(brandLogoFile)) {
                formData.append('brandLogoFile', brandLogoFile); //will show up in req.files
            }
            formData.append('form', JSON.stringify(_.omit(values, 'quickDropPromo')));

            //throttle config uses a different endpoint to update since its stored in a different config collection
            const throttleConfig = values.throttleConfig;
            delete values.throttleConfig;

            setConfigUpdating(true);
            const configRes = await http.postJSON('/system/updateConfigs', formData, true, true);
            if (configRes.ok) {
                const configTemp = configRes.data.config;
                if (isSTDRegion() || isAUSRegion()) {
                    if (!_.isNil(configTemp.theme.technologyLogo)) {
                        configTemp.theme.technologyLogo = convertImportToIconName(configTemp.theme.technologyLogo);
                    }
                    if (!_.isNil(configTemp.theme.noTechnologyLogo)) {
                        configTemp.theme.noTechnologyLogo = convertImportToIconName(configTemp.theme.noTechnologyLogo);
                    }
                }
                setConfig(configTemp);
                setDropLocationMarkerHeight(_.get(configRes.data.config, 'dropLocationMarker.height', 64));
                setDropLocationMarkerWidth(_.get(configRes.data.config, 'dropLocationMarker.width', 48));
                setCharityMarkerHeight(_.get(configRes.data.config, 'charityMarker.height', 64));
                setCharityMarkerWidth(_.get(configRes.data.config, 'charityMarker.width', 48));
            } else {
                onSnackbar(configRes.errorMessage, 'error');
                setConfigUpdating(false);
                return;
            }

            const promoRes = await http.postJSON('/promos/quickDrop/update', { promo: values.quickDropPromo }, true);
            if (promoRes.ok) {
                setQuickDropPromo(promoRes.data.promo);
            } else {
                onSnackbar(
                    `Failed to update ${getQuickDropName('en')} promo. All other configs have been saved`,
                    'error'
                );
            }

            const throttleConfigRes = await http.postJSON('/system/updateThrottleConfig', { throttleConfig });
            if (throttleConfigRes.ok) {
                onSnackbar('All values updated successfully');
            } else {
                onSnackbar(
                    `Failed to update Enable Endpoint Throttling config. All other configs have been saved`,
                    'error'
                );
            }
            setConfigUpdating(false);
        }
    });

    const loadConfig = async () => {
        setConfigLoading(true);
        const res = await http.getJSON('/system/allConfigs');
        if (res.ok) {
            const configTemp = res.data.config;
            if (!_.isNil(configTemp.theme.technologyLogo)) {
                configTemp.theme.technologyLogo = convertImportToIconName(configTemp.theme.technologyLogo);
            }
            if (!_.isNil(configTemp.theme.noTechnologyLogo)) {
                configTemp.theme.noTechnologyLogo = convertImportToIconName(configTemp.theme.noTechnologyLogo);
            }

            setConfig(configTemp);
        }
        const res3 = await http.getJSON('/promos/quickDrop');
        if (res3.ok) {
            setQuickDropPromo(res3.data.promo);
        }
        const res4 = await http.getJSON('/taxGroups/getAllTaxGroups');
        if (res4.ok) {
            setTaxGroups(res4.data.collectionData);
        }

        const res5 = await http.getJSON('/system/throttleConfig');
        if (res5.ok) {
            formik.setFieldValue('throttleConfig', res5.data.throttleConfig);
        }

        setColorOverrides({
            light: {
                primary: !_.isNil(_.get(res.data.config, 'theme.light.primary')),
                secondary: !_.isNil(_.get(res.data.config, 'theme.light.secondary'))
            },
            dark: {
                primary: !_.isNil(_.get(res.data.config, 'theme.dark.primary')),
                secondary: !_.isNil(_.get(res.data.config, 'theme.dark.secondary'))
            },
            admin: {
                primary: !_.isNil(_.get(res.data.config, 'theme.admin.primary')),
                secondary: !_.isNil(_.get(res.data.config, 'theme.admin.secondary'))
            },
            charityPin: !_.isNil(_.get(res.data.config, 'theme.charityPin')),
            schemeUserPin: !_.isNil(_.get(res.data.config, 'theme.schemeUserPin'))
        });

        setConfigLoading(false);
    };

    const handleChangeColorRef = useRef(
        _.throttle((e, formik) => {
            if (!_.isNil(e) && !_.isNil(e.target)) {
                formik.setFieldValue(`theme.${e.target.name}`, e.target.value);
            }
        }, 100)
    );
    const handleChangeColorWrapper = e => {
        e.persist(); //stops error from popping up in the console
        handleChangeColorRef.current(e, formik, e.target.name);
    };

    const handleColorOverride = (name, checked) => {
        let colorOverridesCopy = _.cloneDeep(colorOverrides);
        _.set(colorOverridesCopy, name, checked);
        setColorOverrides(colorOverridesCopy);
    };

    useEffect(() => {
        loadConfig();
        fetchCommodities();
        fetchQuestionnaires();
        fetchGroupFees();
    }, []);

    useEffect(() => {
        formik.handleReset();
    }, [config]);

    useEffect(() => {
        if (!formik.isSubmitting && !formik.isValid) {
            let errorText = '';
            let errors = flattenObject(formik.errors);
            let errorCount = 0;
            for (let key in errors) {
                errorText += `${errorCount > 0 ? ', ' : ''}${key}: ${errors[key]}`;
                errorCount++;
                _.set(formik.touched, key, true);
            }
            if (errorCount > 3) {
                setErrorDialogText(errorText);
                setErrorDialogOpen(true);
            } else if (errorCount > 0) {
                onSnackbar(errorText, 'error');
            }
        }
    }, [formik.isSubmitting]);

    const [headerWidth, setHeaderWidth] = useState(width);

    useEffect(() => {
        if (!headerRef.current) {
            return;
        }

        const newWidth = headerRef.current.offsetWidth;
        setHeaderWidth(newWidth);
    }, [width]);

    const handleDropLocationMarkerFile = async e => {
        const img = e.target.files[0];

        if (img.size <= maxFileSize) {
            const image = new Image();
            image.src = URL.createObjectURL(img);
            image.onload = () => {
                setDropLocationMarkerHeight(image.height);
                setDropLocationMarkerWidth(image.width);
                _.set(formik.values, 'dropLocationMarker.height', image.height);
                _.set(formik.values, 'dropLocationMarker.width', image.width);
            };
            setDropLocationMarkerFile(e.target.files[0]);
        } else {
            onSnackbar(loc('fileTooLarge', lang), 'error');
        }
    };

    const handleCharityMarkerFile = async e => {
        const img = e.target.files[0];

        if (img.size <= maxFileSize) {
            const image = new Image();
            image.src = URL.createObjectURL(img);
            image.onload = () => {
                setCharityMarkerHeight(image.height);
                setCharityMarkerWidth(image.width);
                _.set(formik.values, 'charityMarker.height', image.height);
                _.set(formik.values, 'charityMarker.width', image.width);
            };
            setCharityMarkerFile(e.target.files[0]);
        } else {
            onSnackbar(loc('fileTooLarge', lang), 'error');
        }
    };

    const handleBrandLogoFile = async e => {
        const img = e.target.files[0];

        if (img.size <= maxFileSize) {
            setBrandLogoFile(e.target.files[0]);
        } else {
            onSnackbar(loc('fileTooLarge', lang), 'error');
        }
    };

    const handleMenu = open => e => {
        if (open) {
            if (isSmallScreen) {
                setMenuEl(e.currentTarget);
            }
            setShowMenu(true);
        } else {
            setMenuEl(null);
            setShowMenu(false);
        }
    };

    // ERROR CHECKING
    const configMenuItems = (
        <Paper
            style={{
                overflowY: 'auto',
                height: '100%'
            }}
        >
            <ListItem data-cy={`system-settings-customize-and-secure`}>
                <Typography
                    variant="body1"
                    style={{
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        display: 'block',
                        paddingRight: 0,
                        color: hasError([
                            'twoFactorAuthentication',
                            'contactInfo',
                            'email',
                            'general',
                            'helpCenter',
                            'theme',
                            'SMS'
                        ])
                            ? 'red'
                            : `${theme.palette.text.primary}`
                    }}
                >
                    Customize & Secure
                </Typography>
            </ListItem>
            <ConfigListItem
                setSelectedView={setSelectedView}
                selectedView={selectedView}
                theme={theme}
                formik={formik}
                title="2FA & SMS"
                icon="phonelink_lock"
                error={hasError(['twoFactorAuthentication'], ['SMS'])}
                isSubmitting={formik.isSubmitting}
                isValid={formik.isValid}
            />
            <ConfigListItem
                setSelectedView={setSelectedView}
                selectedView={selectedView}
                theme={theme}
                formik={formik}
                title="Contact Info"
                icon="contact_phone"
                isSubmitting={formik.isSubmitting}
                isValid={formik.isValid}
                error={hasError(['contactInfo'])}
            />
            <ConfigListItem
                setSelectedView={setSelectedView}
                selectedView={selectedView}
                theme={theme}
                formik={formik}
                title="Email"
                icon="email"
                isSubmitting={formik.isSubmitting}
                isValid={formik.isValid}
                error={hasError(['email'])}
            />
            <ConfigListItem
                setSelectedView={setSelectedView}
                selectedView={selectedView}
                theme={theme}
                formik={formik}
                title="General"
                icon="miscellaneous_services"
                error={hasError(['general'])}
                isSubmitting={formik.isSubmitting}
                isValid={formik.isValid}
            />
            <ConfigListItem
                setSelectedView={setSelectedView}
                selectedView={selectedView}
                theme={theme}
                formik={formik}
                title="Help Center"
                icon="info"
                error={hasError(['helpCenter'])}
                isSubmitting={formik.isSubmitting}
                isValid={formik.isValid}
            />
            <ConfigListItem
                setSelectedView={setSelectedView}
                selectedView={selectedView}
                theme={theme}
                formik={formik}
                title="Theme"
                icon="palette"
                error={hasError(['theme'])}
                isSubmitting={formik.isSubmitting}
                isValid={formik.isValid}
            />
            <ListItem data-cy={`system-settings-accounting-and-audits`}>
                <Typography
                    variant="body1"
                    style={{
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        display: 'block',
                        paddingRight: 0,
                        color: hasError(['audit', 'intuit']) ? 'red' : `${theme.palette.text.primary}`
                    }}
                >
                    Accounting & Audits
                </Typography>
            </ListItem>
            {_user.isSystemAdmin(operator) && (
                <ConfigListItem
                    setSelectedView={setSelectedView}
                    selectedView={selectedView}
                    theme={theme}
                    formik={formik}
                    title="Audit"
                    icon="policy"
                    error={hasError(['audit'])}
                    isSubmitting={formik.isSubmitting}
                    isValid={formik.isValid}
                />
            )}
            <ConfigListItem
                setSelectedView={setSelectedView}
                selectedView={selectedView}
                theme={theme}
                formik={formik}
                title="Account Names"
                icon="auto_stories"
                error={hasError(['journalEntries'])}
                isSubmitting={formik.isSubmitting}
                isValid={formik.isValid}
            />
            <ConfigListItem
                setSelectedView={setSelectedView}
                selectedView={selectedView}
                theme={theme}
                formik={formik}
                title="Intuit Configuration"
                icon="receipt_long"
                error={hasError(['intuit'])}
                isSubmitting={formik.isSubmitting}
                isValid={formik.isValid}
            />
            <ListItem data-cy={`system-settings-customer-and-charity`}>
                <Typography
                    variant="body1"
                    style={{
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        display: 'block',
                        paddingRight: 0,
                        color: hasError([
                            'charity',
                            'impactReport',
                            'redemptions',
                            'registration',
                            'shareFeedbackReferrals'
                        ])
                            ? 'red'
                            : `${theme.palette.text.primary}`
                    }}
                >
                    Customer & Charity
                </Typography>
            </ListItem>
            <ConfigListItem
                setSelectedView={setSelectedView}
                selectedView={selectedView}
                theme={theme}
                formik={formik}
                title="Charity"
                icon="favorite"
                error={hasError(['charity'])}
                isSubmitting={formik.isSubmitting}
                isValid={formik.isValid}
            />
            <ConfigListItem
                setSelectedView={setSelectedView}
                selectedView={selectedView}
                theme={theme}
                formik={formik}
                title="Impact Report"
                icon="eco"
                error={hasError(['impactReport'])}
                isSubmitting={formik.isSubmitting}
                isValid={formik.isValid}
            />
            <ConfigListItem
                setSelectedView={setSelectedView}
                selectedView={selectedView}
                theme={theme}
                formik={formik}
                title="Redemptions"
                icon="credit_card"
                isSubmitting={formik.isSubmitting}
                isValid={formik.isValid}
                error={hasError(['redemptions'], formik)}
            />
            <ConfigListItem
                setSelectedView={setSelectedView}
                selectedView={selectedView}
                theme={theme}
                formik={formik}
                title="Registration"
                icon="how_to_reg"
                error={hasError(['registration'], formik)}
                isSubmitting={formik.isSubmitting}
                isValid={formik.isValid}
            />
            <ConfigListItem
                setSelectedView={setSelectedView}
                selectedView={selectedView}
                theme={theme}
                formik={formik}
                title="Share, Feedback, and Referrals"
                icon="share"
                error={hasError(['shareFeedbackReferrals'], formik)}
                isSubmitting={formik.isSubmitting}
                isValid={formik.isValid}
            />

            <ListItem data-cy={`system-settings-depot`}>
                <Typography
                    variant="body1"
                    style={{
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        display: 'block',
                        paddingRight: 0,
                        color: hasError(['dropOff', 'por']) ? 'red' : `${theme.palette.text.primary}`
                    }}
                >
                    Depot
                </Typography>
            </ListItem>
            <ConfigListItem
                setSelectedView={setSelectedView}
                selectedView={selectedView}
                theme={theme}
                formik={formik}
                title="Drop Off"
                icon="warehouse"
                error={hasError(['dropOff'])}
                isSubmitting={formik.isSubmitting}
                isValid={formik.isValid}
            />
            <ConfigListItem
                setSelectedView={setSelectedView}
                selectedView={selectedView}
                theme={theme}
                formik={formik}
                title="POR"
                icon="ballot"
                error={hasError(['por'])}
                isSubmitting={formik.isSubmitting}
                isValid={formik.isValid}
            />
            <ListItem data-cy={`system-settings-logistics`}>
                <Typography
                    variant="body1"
                    style={{
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        display: 'block',
                        paddingRight: 0,
                        color: hasError(['drivers', 'pickups', 'routing', 'etas'])
                            ? 'red'
                            : `${theme.palette.text.primary}`
                    }}
                >
                    Logistics
                </Typography>
            </ListItem>
            <ConfigListItem
                setSelectedView={setSelectedView}
                selectedView={selectedView}
                theme={theme}
                formik={formik}
                title="Drivers"
                icon="badge"
                error={hasError(['drivers'])}
                isSubmitting={formik.isSubmitting}
                isValid={formik.isValid}
            />
            <ConfigListItem
                setSelectedView={setSelectedView}
                selectedView={selectedView}
                theme={theme}
                formik={formik}
                title="Pickups"
                icon="local_shipping"
                error={hasError(['pickups'])}
                isSubmitting={formik.isSubmitting}
                isValid={formik.isValid}
            />
            <ConfigListItem
                setSelectedView={setSelectedView}
                selectedView={selectedView}
                theme={theme}
                formik={formik}
                title="Routing"
                icon="route"
                error={hasError(['routing'], formik)}
                isSubmitting={formik.isSubmitting}
                isValid={formik.isValid}
            />
            <ConfigListItem
                setSelectedView={setSelectedView}
                selectedView={selectedView}
                theme={theme}
                formik={formik}
                title="ETAs"
                icon="timer"
                error={hasError(['etas'], formik)}
                isSubmitting={formik.isSubmitting}
                isValid={formik.isValid}
            />
        </Paper>
    );

    return (
        <div style={{ display: 'flex', height: '100%' }}>
            {!isSmallScreen && <div style={{ width: 400 }}>{configMenuItems}</div>}

            <div
                ref={headerRef}
                style={{ width: '100%', padding: theme.spacing.unit * 2, height: '100%', overflowY: 'scroll' }}
            >
                <Paper
                    style={{
                        padding: theme.spacing.unit * 2,
                        display: 'flex',
                        justifyContent: 'space-between',
                        position: 'absolute',
                        zIndex: 10,
                        boxShadow: theme.shadows[2],
                        width: _.isNil(headerRef.current) ? headerWidth - 50 : headerRef.current.offsetWidth - 50
                    }}
                >
                    <Typography variant="h5">
                        {isSmallScreen && (
                            <IconButton onClick={handleMenu(true)} data-cy="settings-menu-toggle">
                                <MDIcon path={mdiMenu} size={0.7} color={theme.palette.text.primary} />
                            </IconButton>
                        )}{' '}
                        {selectedView}
                    </Typography>
                    {isSmallScreen && (
                        <Menu
                            open={showMenu && isSmallScreen && !_.isNil(menuEl)}
                            anchorEl={menuEl}
                            onClose={handleMenu(false)}
                        >
                            {configMenuItems}
                        </Menu>
                    )}
                    {formik.dirty && (
                        <Typography variant="body2" style={{ color: 'red', marginTop: theme.spacing.unit }}>
                            There are unsaved changes
                        </Typography>
                    )}
                    <Button
                        data-cy={`information-editor-submit`}
                        color="primary"
                        onClick={formik.handleSubmit}
                        variant="contained"
                    >
                        Save
                    </Button>
                </Paper>
                {configLoading ? (
                    <Paper style={{ margin: theme.spacing.unit / 2, padding: theme.spacing.unit * 2, marginTop: 75 }}>
                        <div
                            style={{
                                margin: theme.spacing.unit * 2,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <CircularProgress />
                        </div>
                    </Paper>
                ) : (
                    <Paper style={{ margin: theme.spacing.unit / 2, padding: theme.spacing.unit, marginTop: 75 }}>
                        {selectedView === 'Audit' && (
                            <Grid container spacing={theme.spacing.unit}>
                                <Grid item xs={12} sm={6}>
                                    <CustomWrapper
                                        title="Pickup Bag Count Difference"
                                        outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}
                                    >
                                        <Grid container spacing={theme.spacing.unit}>
                                            <Grid item xs={12} sm={3}>
                                                {getSwitch(
                                                    theme,
                                                    'audit.bagDifference.enabled',
                                                    'Enabled',
                                                    formik,
                                                    { marginTop: theme.spacing.unit * 2 },
                                                    {},
                                                    undefined,
                                                    'Enables audit check for difference between expected and reported number of bags'
                                                )}
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                {getSelect(
                                                    theme,
                                                    'audit.bagDifference.infractionUserState',
                                                    'Type',
                                                    AUDIT_INFRACTION_USER_STATES,
                                                    formik,
                                                    null,
                                                    null,
                                                    null,
                                                    false,
                                                    !_.get(formik.values, 'audit.bagDifference.enabled')
                                                )}
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                {getTextInput(
                                                    theme,
                                                    'audit.bagDifference.threshold',
                                                    'Max Difference',
                                                    formik,
                                                    'number',
                                                    getInfoIcon(
                                                        'Max Bag Count Difference',
                                                        'Threshold difference in number of bags collected that will trigger a bag difference infraction',
                                                        theme
                                                    ),
                                                    null,
                                                    null,
                                                    !_.get(formik.values, 'audit.bagDifference.enabled')
                                                )}
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                {getSelect(
                                                    theme,
                                                    'audit.bagDifference.enabledCommodities',
                                                    'Commodities',
                                                    allCommodities,
                                                    formik,
                                                    null,
                                                    null,
                                                    null,
                                                    true,
                                                    !_.get(formik.values, 'audit.bagDifference.enabled')
                                                )}
                                            </Grid>
                                        </Grid>
                                    </CustomWrapper>
                                    <CustomWrapper
                                        title="Customer Amount Per Unit Difference"
                                        outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}
                                    >
                                        <Grid container spacing={theme.spacing.unit}>
                                            <Grid item xs={12} sm={3}>
                                                {getSwitch(
                                                    theme,
                                                    'audit.unitDifference.enabled',
                                                    'Enabled',
                                                    formik,
                                                    { marginTop: theme.spacing.unit * 2 },
                                                    {},
                                                    undefined,
                                                    'Enables audit check for difference between average and reported number of containers per bag'
                                                )}
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                {getSelect(
                                                    theme,
                                                    'audit.unitDifference.infractionUserState',
                                                    'Type',
                                                    AUDIT_INFRACTION_USER_STATES,
                                                    formik,
                                                    null,
                                                    null,
                                                    null,
                                                    false,
                                                    !_.get(formik.values, 'audit.unitDifference.enabled')
                                                )}
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                {getTextInput(
                                                    theme,
                                                    'audit.unitDifference.percentThreshold',
                                                    'Max Difference (%)',
                                                    formik,
                                                    'number',
                                                    getInfoIcon(
                                                        'Amount Per Bag Difference',
                                                        'Threshold difference in counts per bag that will trigger a counting threshold infraction',
                                                        theme
                                                    ),
                                                    null,
                                                    null,
                                                    !_.get(formik.values, 'audit.unitDifference.enabled')
                                                )}
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                {getSelect(
                                                    theme,
                                                    'audit.unitDifference.enabledCommodities',
                                                    'Commodities',
                                                    allCommodities,
                                                    formik,
                                                    null,
                                                    null,
                                                    null,
                                                    true,
                                                    !_.get(formik.values, 'audit.unitDifference.enabled')
                                                )}
                                            </Grid>
                                        </Grid>
                                    </CustomWrapper>
                                    <CustomWrapper
                                        title="Driver Initiated Complaint"
                                        outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}
                                    >
                                        <Grid container spacing={theme.spacing.unit}>
                                            <Grid item xs={12} sm={3}>
                                                {getSwitch(
                                                    theme,
                                                    'audit.driverComplaint.enabled',
                                                    'Enabled',
                                                    formik,
                                                    { marginTop: theme.spacing.unit * 2 },
                                                    {},
                                                    undefined,
                                                    'Enables audit check for number of driver initiated complaints'
                                                )}
                                            </Grid>
                                            <Grid item xs={12} sm={9}>
                                                {getSelect(
                                                    theme,
                                                    'audit.driverComplaint.infractionUserState',
                                                    'Type',
                                                    AUDIT_INFRACTION_USER_STATES,
                                                    formik,
                                                    null,
                                                    null,
                                                    null,
                                                    false,
                                                    !_.get(formik.values, 'audit.driverComplaint.enabled')
                                                )}
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                {getTextInput(
                                                    theme,
                                                    'audit.driverComplaint.threshold',
                                                    'Max Complaints',
                                                    formik,
                                                    'number',
                                                    getInfoIcon(
                                                        'Max Allowed Driver Initiated Complaint',
                                                        'Maximum number of driver initiated complaints within time window before triggering a driver complaint infraction',
                                                        theme
                                                    ),
                                                    null,
                                                    null,
                                                    !_.get(formik.values, 'audit.driverComplaint.enabled')
                                                )}
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                {getTextInput(
                                                    theme,
                                                    'audit.driverComplaint.timeWindow',
                                                    'Time Window (months)',
                                                    formik,
                                                    'number',
                                                    getInfoIcon(
                                                        'Driver Complaint Time Window',
                                                        'Time window for maximum number of driver initiated complaints before triggering a driver complaint infraction',
                                                        theme
                                                    ),
                                                    null,
                                                    null,
                                                    !_.get(formik.values, 'audit.driverComplaint.enabled')
                                                )}
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                {getSelect(
                                                    theme,
                                                    'audit.driverComplaint.enabledCommodities',
                                                    'Commodities',
                                                    allCommodities,
                                                    formik,
                                                    null,
                                                    null,
                                                    null,
                                                    true,
                                                    !_.get(formik.values, 'audit.driverComplaint.enabled')
                                                )}
                                            </Grid>
                                        </Grid>
                                    </CustomWrapper>
                                    <CustomWrapper
                                        title="Counter Initiated Complaint"
                                        outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}
                                    >
                                        <Grid container spacing={theme.spacing.unit}>
                                            <Grid item xs={12} sm={3}>
                                                {getSwitch(
                                                    theme,
                                                    'audit.counterComplaint.enabled',
                                                    'Enabled',
                                                    formik,
                                                    { marginTop: theme.spacing.unit * 2 },
                                                    {},
                                                    undefined,
                                                    'Enables audit check for number of counter initiated complaints'
                                                )}
                                            </Grid>
                                            <Grid item xs={12} sm={9}>
                                                {getSelect(
                                                    theme,
                                                    'audit.customerRedemptionEmail.infractionUserState',
                                                    'Type',
                                                    AUDIT_INFRACTION_USER_STATES,
                                                    formik,
                                                    null,
                                                    null,
                                                    null,
                                                    false,
                                                    !_.get(formik.values, 'audit.counterComplaint.enabled')
                                                )}
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                {getTextInput(
                                                    theme,
                                                    'audit.counterComplaint.threshold',
                                                    'Max Complaints',
                                                    formik,
                                                    'number',
                                                    getInfoIcon(
                                                        'Max Allowed Counter Initiated Complaint',
                                                        'Maximum number of counter initiated complaints within time window before triggering a driver complaint infraction',
                                                        theme
                                                    ),
                                                    null,
                                                    null,
                                                    !_.get(formik.values, 'audit.counterComplaint.enabled')
                                                )}
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                {getTextInput(
                                                    theme,
                                                    'audit.counterComplaint.timeWindow',
                                                    'Time Window (months)',
                                                    formik,
                                                    'number',
                                                    getInfoIcon(
                                                        'Counter Complaint Time Window',
                                                        'Time window for maximum number of counter initiated complaints before triggering a driver complaint infraction',
                                                        theme
                                                    ),
                                                    null,
                                                    null,
                                                    !_.get(formik.values, 'audit.counterComplaint.enabled')
                                                )}
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                {getSelect(
                                                    theme,
                                                    'audit.counterComplaint.enabledCommodities',
                                                    'Commodities',
                                                    allCommodities,
                                                    formik,
                                                    null,
                                                    null,
                                                    null,
                                                    true,
                                                    !_.get(formik.values, 'audit.counterComplaint.enabled')
                                                )}
                                            </Grid>
                                        </Grid>
                                    </CustomWrapper>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <CustomWrapper
                                        title="Customer Redemption Email"
                                        outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}
                                    >
                                        <Grid container spacing={theme.spacing.unit}>
                                            <Grid item xs={12} sm={3}>
                                                {getSwitch(
                                                    theme,
                                                    'audit.customerRedemptionEmail.enabled',
                                                    'Enabled',
                                                    formik,
                                                    { marginTop: theme.spacing.unit * 2 },
                                                    {},
                                                    undefined,
                                                    'Enables audit check for different email used to request redemption'
                                                )}
                                            </Grid>
                                            <Grid item xs={12} sm={9}>
                                                {getSelect(
                                                    theme,
                                                    'audit.customerRedemptionEmail.infractionUserState',
                                                    'Type',
                                                    AUDIT_INFRACTION_USER_STATES,
                                                    formik,
                                                    null,
                                                    null,
                                                    null,
                                                    false,
                                                    !_.get(formik.values, 'audit.customerRedemptionEmail.enabled')
                                                )}
                                            </Grid>
                                        </Grid>
                                    </CustomWrapper>
                                    <CustomWrapper
                                        title="Customer Redemption Amount"
                                        outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}
                                    >
                                        <Grid container spacing={theme.spacing.unit}>
                                            <Grid item xs={12} sm={3}>
                                                {getSwitch(
                                                    theme,
                                                    'audit.customerRedemptionAmount.enabled',
                                                    'Enabled',
                                                    formik,
                                                    { marginTop: theme.spacing.unit * 2 },
                                                    {},
                                                    undefined,
                                                    'Enables audit check for unusually high redemption amount'
                                                )}
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                {getSelect(
                                                    theme,
                                                    'audit.customerRedemptionAmount.infractionUserState',
                                                    'Type',
                                                    AUDIT_INFRACTION_USER_STATES,
                                                    formik,
                                                    null,
                                                    null,
                                                    null,
                                                    false,
                                                    !_.get(formik.values, 'audit.customerRedemptionAmount.enabled')
                                                )}
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                {getTextInput(
                                                    theme,
                                                    'audit.customerRedemptionAmount.threshold',
                                                    'Threshold',
                                                    formik,
                                                    'number',
                                                    getInfoIcon(
                                                        'Threshold',
                                                        'Threshold difference in redemption amount that will trigger a redemption threshold infraction',
                                                        theme
                                                    ),
                                                    null,
                                                    null,
                                                    !_.get(formik.values, 'audit.customerRedemptionAmount.enabled')
                                                )}
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                {getTextInput(
                                                    theme,
                                                    'audit.customerRedemptionAmount.timeWindow',
                                                    'Time Window (months)',
                                                    formik,
                                                    'number',
                                                    getInfoIcon(
                                                        'Redemption Amount Time Window',
                                                        'Time window for total redemption amount to compare against redemption threshold',
                                                        theme
                                                    ),
                                                    null,
                                                    null,
                                                    !_.get(formik.values, 'audit.customerRedemptionAmount.enabled')
                                                )}
                                            </Grid>
                                        </Grid>
                                    </CustomWrapper>
                                    <CustomWrapper
                                        title="IP Conflict"
                                        outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}
                                    >
                                        <Grid container spacing={theme.spacing.unit}>
                                            <Grid item xs={12} sm={3}>
                                                {getSwitch(
                                                    theme,
                                                    'audit.ipConflict.enabled',
                                                    'Enabled',
                                                    formik,
                                                    { marginTop: theme.spacing.unit * 2 },
                                                    {},
                                                    undefined,
                                                    "Checks IP upon login with all user's login IPs (default: true)"
                                                )}
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                {getSelect(
                                                    theme,
                                                    'audit.ipConflict.infractionUserState',
                                                    'Type',
                                                    AUDIT_INFRACTION_USER_STATES,
                                                    formik,
                                                    null,
                                                    null,
                                                    null,
                                                    false,
                                                    !_.get(formik.values, 'audit.ipConflict.enabled')
                                                )}
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                {getSwitch(
                                                    theme,
                                                    'audit.ipConflict.excludePastIPs',
                                                    'Only check current login IP',
                                                    formik,
                                                    { marginTop: theme.spacing.unit * 2 },
                                                    { fontSize: '80%' },
                                                    undefined,
                                                    "Enable to only check against other user's current login IP, ignores IPs from past devices (default: false)"
                                                )}
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                {getSwitch(
                                                    theme,
                                                    'audit.ipConflict.compareAllUsers',
                                                    'Check between all account types',
                                                    formik,
                                                    { marginTop: theme.spacing.unit * 2 },
                                                    { fontSize: '80%' },
                                                    undefined,
                                                    "Enable to check against all user's login IP, disable to only check between admin and customer accounts (default: false)"
                                                )}
                                            </Grid>
                                        </Grid>
                                    </CustomWrapper>
                                    <CustomWrapper
                                        title="Garbage Complaint"
                                        outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}
                                    >
                                        <Grid container spacing={theme.spacing.unit}>
                                            <Grid item xs={12} sm={3}>
                                                {getSwitch(
                                                    theme,
                                                    'audit.garbageComplaint.enabled',
                                                    'Enabled',
                                                    formik,
                                                    { marginTop: theme.spacing.unit * 2 },
                                                    {},
                                                    undefined,
                                                    'Enables audit check for number of garbage complaints'
                                                )}
                                            </Grid>
                                            <Grid item xs={12} sm={9}>
                                                {getSelect(
                                                    theme,
                                                    'audit.garbageComplaint.infractionUserState',
                                                    'Type',
                                                    AUDIT_INFRACTION_USER_STATES,
                                                    formik,
                                                    null,
                                                    null,
                                                    null,
                                                    false,
                                                    !_.get(formik.values, 'audit.garbageComplaint.enabled')
                                                )}
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                {getTextInput(
                                                    theme,
                                                    'audit.garbageComplaint.threshold',
                                                    'Max Complaints',
                                                    formik,
                                                    'number',
                                                    getInfoIcon(
                                                        'Max Complaints',
                                                        'Maximum number of garbage complaints within time window before triggering a garbage complaint infraction',
                                                        theme
                                                    ),
                                                    null,
                                                    null,
                                                    !_.get(formik.values, 'audit.garbageComplaint.enabled')
                                                )}
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                {getTextInput(
                                                    theme,
                                                    'audit.garbageComplaint.timeWindow',
                                                    'Time Window (months)',
                                                    formik,
                                                    'number',
                                                    getInfoIcon(
                                                        'Time Window',
                                                        'Time window for maximum number of garbage complaints before triggering a garbage complaint infraction',
                                                        theme
                                                    ),
                                                    null,
                                                    null,
                                                    !_.get(formik.values, 'audit.garbageComplaint.enabled')
                                                )}
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                {getSelect(
                                                    theme,
                                                    'audit.garbageComplaint.enabledCommodities',
                                                    'Commodities',
                                                    allCommodities,
                                                    formik,
                                                    null,
                                                    null,
                                                    null,
                                                    true,
                                                    !_.get(formik.values, 'audit.garbageComplaint.enabled')
                                                )}
                                            </Grid>
                                            <Grid item xs={12}>
                                                {getSwitch(
                                                    theme,
                                                    'audit.garbageComplaint.repeatInfractionAdvisory',
                                                    'Advise Customer of Penalty for Repeating Infraction',
                                                    formik,
                                                    { marginTop: theme.spacing.unit }
                                                    // {},
                                                    // undefined
                                                    // 'Advise Customer of Penalty for Repeating Infraction'
                                                )}
                                            </Grid>
                                        </Grid>
                                    </CustomWrapper>
                                    <CustomWrapper
                                        title="Hazardous Complaint"
                                        outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}
                                    >
                                        <Grid container spacing={theme.spacing.unit}>
                                            <Grid item xs={12} sm={3}>
                                                {getSwitch(
                                                    theme,
                                                    'audit.hazardousComplaint.enabled',
                                                    'Enabled',
                                                    formik,
                                                    { marginTop: theme.spacing.unit * 2 },
                                                    {},
                                                    undefined,
                                                    'Enables audit check for number of hazardous complaints'
                                                )}
                                            </Grid>
                                            <Grid item xs={12} sm={9}>
                                                {getSelect(
                                                    theme,
                                                    'audit.hazardousComplaint.infractionUserState',
                                                    'Type',
                                                    AUDIT_INFRACTION_USER_STATES,
                                                    formik,
                                                    null,
                                                    null,
                                                    null,
                                                    false,
                                                    !_.get(formik.values, 'audit.hazardousComplaint.enabled')
                                                )}
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                {getTextInput(
                                                    theme,
                                                    'audit.hazardousComplaint.threshold',
                                                    'Max Complaints',
                                                    formik,
                                                    'number',
                                                    getInfoIcon(
                                                        'Max Complaints',
                                                        'Maximum number of hazardous complaints within time window before triggering a hazardous complaint infraction',
                                                        theme
                                                    ),
                                                    null,
                                                    null,
                                                    !_.get(formik.values, 'audit.hazardousComplaint.enabled')
                                                )}
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                {getTextInput(
                                                    theme,
                                                    'audit.hazardousComplaint.timeWindow',
                                                    'Time Window (month)',
                                                    formik,
                                                    'number',
                                                    getInfoIcon(
                                                        'Time Window',
                                                        'Time window for maximum number of hazardous complaints before triggering a hazardous complaint infraction',
                                                        theme
                                                    ),
                                                    null,
                                                    null,
                                                    !_.get(formik.values, 'audit.hazardousComplaint.enabled')
                                                )}
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                {getSelect(
                                                    theme,
                                                    'audit.hazardousComplaint.enabledCommodities',
                                                    'Commodities',
                                                    allCommodities,
                                                    formik,
                                                    null,
                                                    null,
                                                    null,
                                                    true,
                                                    !_.get(formik.values, 'audit.hazardousComplaint.enabled')
                                                )}
                                            </Grid>
                                            <Grid item xs={12}>
                                                {getSwitch(
                                                    theme,
                                                    'audit.hazardousComplaint.repeatInfractionAdvisory',
                                                    'Advise Customer of Penalty for Repeating Infraction',
                                                    formik,
                                                    { marginTop: theme.spacing.unit }
                                                    // {},
                                                    // undefined
                                                    // 'Advise Customer of Penalty for Repeating Infraction'
                                                )}
                                            </Grid>
                                        </Grid>
                                    </CustomWrapper>
                                </Grid>
                            </Grid>
                        )}
                        {selectedView === 'Contact Info' && (
                            <Grid container spacing={theme.spacing.unit}>
                                <Grid item xs={12} sm={6}>
                                    <CustomWrapper
                                        title="Contact Emails"
                                        outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}
                                    >
                                        <div style={{ margin: theme.spacing.unit }}>
                                            {getTextInput(
                                                theme,
                                                'emailAddresses.service',
                                                'Service Email',
                                                formik,
                                                'text',
                                                getInfoIcon(
                                                    'Service Email',
                                                    'Email for handling general customer concerns',
                                                    theme
                                                )
                                            )}
                                            {getTextInput(
                                                theme,
                                                'emailAddresses.payments',
                                                'Payments Email',
                                                formik,
                                                'text',
                                                getInfoIcon(
                                                    'Payments Email',
                                                    'Email for handling customer payment concerns',
                                                    theme
                                                )
                                            )}
                                            {getTextInput(
                                                theme,
                                                'emailAddresses.orders',
                                                'Orders Email',
                                                formik,
                                                'text',
                                                getInfoIcon(
                                                    'Orders Email',
                                                    'Email for handling customer counts concerns',
                                                    theme
                                                )
                                            )}
                                            <StringListInput
                                                label="Finance Report Recipient Emails"
                                                values={_.get(formik.values, 'emailAddresses.financeReportRecipients')}
                                                onChange={values =>
                                                    formik.setFieldValue(
                                                        'emailAddresses.financeReportRecipients',
                                                        values
                                                    )
                                                }
                                                error={email => {
                                                    let regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                                                    return !(email === '') && !regexEmail.test(email.toLowerCase());
                                                }}
                                                errorMessage="Please enter a valid email address."
                                                InputProps={{
                                                    variant: 'outlined'
                                                }}
                                                infoText="Email list for receiving monthly financial report"
                                            />
                                        </div>
                                    </CustomWrapper>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <CustomWrapper
                                        title="Contact Phone"
                                        outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}
                                    >
                                        {getTextInput(
                                            theme,
                                            'phoneNumbers.service',
                                            'Service Phone Number',
                                            formik,
                                            'tel',
                                            getInfoIcon(
                                                'Service Phone Number',
                                                'Phone number for handling customer counts concerns',
                                                theme
                                            )
                                        )}
                                        {getTextInput(
                                            theme,
                                            'phoneNumbers.kantech',
                                            'Kantech Passcode Phone Number',
                                            formik,
                                            'tel',
                                            getInfoIcon(
                                                'Kantech Passcode Phone Number',
                                                'Phone number to text to receive access door OTP',
                                                theme
                                            )
                                        )}
                                    </CustomWrapper>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <CustomWrapper
                                        title="Address"
                                        outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}
                                    >
                                        <div style={{ margin: theme.spacing.unit }}>
                                            {getTextInput(
                                                theme,
                                                'address.customerReceiptAddress',
                                                'Customer Receipt Address',
                                                formik,
                                                'text',
                                                getInfoIcon(
                                                    'customerReceiptAddress',
                                                    'Address to display on customer receipts',
                                                    theme
                                                )
                                            )}
                                        </div>
                                    </CustomWrapper>
                                </Grid>
                            </Grid>
                        )}
                        {selectedView === 'Charity' && (
                            <Grid container spacing={theme.spacing.unit}>
                                <Grid item xs={12} sm={6}>
                                    <CustomWrapper
                                        title="General"
                                        outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}
                                    >
                                        {getSwitch(
                                            theme,
                                            'charityTaskConfiguration.growthPlanEnabled',
                                            'Enable Growth Plan',
                                            formik,
                                            {},
                                            {},
                                            undefined,
                                            'Controls whether organizations are shown growth tasks and have the ability to become featured'
                                        )}
                                        <Collapse
                                            in={_.get(
                                                formik,
                                                'values.charityTaskConfiguration.growthPlanEnabled',
                                                false
                                            )}
                                        >
                                            {getTextInput(
                                                theme,
                                                'charityTaskConfiguration.minimumVerifiedTasksForFeatured',
                                                'AutoFeature Completed Tasks',
                                                formik,
                                                'number',
                                                getInfoIcon(
                                                    'AutoFeature Completed Tasks',
                                                    'Number of tasks an organization must successfully complete to become featured',
                                                    theme
                                                )
                                            )}
                                        </Collapse>
                                        {getTextInput(
                                            theme,
                                            'charityTaxReceipts.defaultMinTaxReceiptAmount',
                                            'Minimum Tax Receipt Amount',
                                            formik,
                                            'number',
                                            getInfoIcon(
                                                'Minimum Tax Receipt Amount',
                                                'Minimum donation amount for a donating customer to receive a tax receipt',
                                                theme
                                            )
                                        )}
                                        {getTextInput(
                                            theme,
                                            'charityNameSimilarityThreshold.charityNameSimilarityThreshold',
                                            'Charity Name Similarity Threshold',
                                            formik,
                                            'text',
                                            getInfoIcon(
                                                'Charity Name Similarity Threshold',
                                                'Minimum degree of similaritybetween two charity name to be considered too close, range from 0 to 1.',
                                                theme
                                            )
                                        )}
                                    </CustomWrapper>
                                </Grid>
                            </Grid>
                        )}
                        {selectedView === 'Drop Off' && (
                            <Grid container spacing={theme.spacing.unit}>
                                <Grid item xs={12} sm={6}>
                                    <CustomWrapper
                                        title="Promo Code"
                                        outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}
                                    >
                                        {getTextInput(
                                            theme,
                                            'quickDropPromo.label',
                                            'Drop off promo label',
                                            formik,
                                            'text',
                                            getInfoIcon(
                                                'Drop off promo label',
                                                'Promo label applied for drop offs',
                                                theme
                                            )
                                        )}
                                        {getTextInput(
                                            theme,
                                            'quickDropPromo.description',
                                            'Drop off promo description',
                                            formik,
                                            'text',
                                            getInfoIcon(
                                                'Drop off promo description',
                                                'Description of promo applied to drop offs',
                                                theme
                                            )
                                        )}
                                        {getTextInput(
                                            theme,
                                            'quickDropPromo.serviceFee',
                                            'Drop off service fee (%)',
                                            formik,
                                            'text',
                                            getInfoIcon(
                                                'Drop off service fee',
                                                'Service fee applied to drop offs',
                                                theme
                                            )
                                        )}
                                    </CustomWrapper>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <CustomWrapper
                                        title="Options"
                                        outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}
                                    >
                                        {getSwitch(
                                            theme,
                                            'dropAndGo.multiBagDrops',
                                            'Allow multiple (bag) drop offs',
                                            formik,
                                            {
                                                marginTop: theme.spacing.unit * 2
                                            },
                                            {},
                                            undefined,
                                            'Allow multiple drop offs to be booked without waiting for the first to be processed'
                                        )}
                                    </CustomWrapper>
                                </Grid>
                            </Grid>
                        )}
                        {selectedView === 'Email' && (
                            <Grid container spacing={theme.spacing.unit}>
                                <Grid item xs={12} sm={6}>
                                    <CustomWrapper
                                        title="SendGrid Configuration"
                                        outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}
                                    >
                                        <Typography variant="body1" style={{ marginTop: theme.spacing.unit * 2 }}>
                                            Inactive Customer Email Templates
                                        </Typography>
                                        <div style={{ marginLeft: theme.spacing.unit * 2 }}>
                                            {AVAILABLE_LANGS[process.env.REACT_APP_REGION_EXT].map(lang => {
                                                return getTextInput(
                                                    theme,
                                                    `sendGridConfiguration.templateIds.inactiveCustomerEmail.${lang}`,
                                                    `Inactive Customer Email (${lang})`,
                                                    formik,
                                                    'text',
                                                    getInfoIcon(
                                                        `Inactive Customer Email (${lang})`,
                                                        'Template ID for email notifications send to inactive customers.',
                                                        theme
                                                    )
                                                );
                                            })}
                                            {/* {getTextInput(
                                                theme,
                                                'sendGridConfiguration.templateIds.inactiveFirstMessage',
                                                'Inactive First Email',
                                                formik,
                                                'text',
                                                getInfoIcon(
                                                    'Inactive First Email',
                                                    'Template ID of first email that is sent to inactive customers',
                                                    theme
                                                )
                                            )}
                                            {getTextInput(
                                                theme,
                                                'sendGridConfiguration.templateIds.inactiveSecondMessage',
                                                'Inactive Second Email',
                                                formik,
                                                'text',
                                                getInfoIcon(
                                                    'Inactive Second Email',
                                                    'Template ID of second email that is sent to inactive customers',
                                                    theme
                                                )
                                            )}
                                            {getTextInput(
                                                theme,
                                                'sendGridConfiguration.templateIds.inactiveThirdMessage',
                                                'Inactive Third Email',
                                                formik,
                                                'text',
                                                getInfoIcon(
                                                    'Inactive Third Email',
                                                    'Template ID of third email that is sent to inactive customers',
                                                    theme
                                                )
                                            )} */}
                                        </div>
                                        <Typography variant="body1" style={{ marginTop: theme.spacing.unit * 2 }}>
                                            Charity Email Templates
                                        </Typography>
                                        <div style={{ marginLeft: theme.spacing.unit * 2 }}>
                                            {/* {getTextInput(
                                                theme,
                                                'sendGridConfiguration.templateIds.notFeaturedReminder',
                                                'Not Featured Reminder',
                                                formik,
                                                'text',
                                                getInfoIcon(
                                                    'Not Featured Reminder',
                                                    `Template ID of email reminding organizations that they are not featured`,
                                                    theme
                                                )
                                            )}
                                            {getTextInput(
                                                theme,
                                                'sendGridConfiguration.templateIds.unfeaturedCharity',
                                                'Unfeatured Email',
                                                formik,
                                                'text',
                                                getInfoIcon(
                                                    'Unfeatured Email',
                                                    'Template ID of email informing organizations that they are no longer featured',
                                                    theme
                                                )
                                            )}
                                            {getTextInput(
                                                theme,
                                                'sendGridConfiguration.templateIds.featuredCharityEmail',
                                                'Featured Email',
                                                formik,
                                                'text',
                                                getInfoIcon(
                                                    'Featured Email',
                                                    'Template ID of email informing organizations that they have become featured',
                                                    theme
                                                )
                                            )}
                                            {getTextInput(
                                                theme,
                                                'sendGridConfiguration.templateIds.expiredCharityTask',
                                                'Expired Task',
                                                formik,
                                                'text',
                                                getInfoIcon(
                                                    'Expired Task',
                                                    'Template ID of email informing organizations that a task has expired',
                                                    theme
                                                )
                                            )} */}
                                            {AVAILABLE_LANGS[process.env.REACT_APP_REGION_EXT].map(lang => {
                                                return getTextInput(
                                                    theme,
                                                    `sendGridConfiguration.templateIds.organizationApprovalEmail.${lang}`,
                                                    `Organization Approval Email (${lang})`,
                                                    formik,
                                                    'text',
                                                    getInfoIcon(
                                                        `Notification Email (${lang})`,
                                                        `Template ID for notifying organizations that they have been approved.`,
                                                        theme
                                                    )
                                                );
                                            })}
                                        </div>

                                        <Typography variant="body1" style={{ marginTop: theme.spacing.unit * 2 }}>
                                            General Notification Email Template
                                        </Typography>
                                        <div style={{ marginLeft: theme.spacing.unit * 2 }}>
                                            {AVAILABLE_LANGS[process.env.REACT_APP_REGION_EXT].map(lang => {
                                                return getTextInput(
                                                    theme,
                                                    `sendGridConfiguration.templateIds.notificationEmail.${lang}`,
                                                    `Notification Email (${lang})`,
                                                    formik,
                                                    'text',
                                                    getInfoIcon(
                                                        `Notification Email (${lang})`,
                                                        'Template ID for general email notifications used throughout app.',
                                                        theme
                                                    )
                                                );
                                            })}
                                        </div>
                                        <Typography variant="body1" style={{ marginTop: theme.spacing.unit * 2 }}>
                                            Registration Email Templates
                                        </Typography>
                                        <div style={{ marginLeft: theme.spacing.unit * 2 }}>
                                            {AVAILABLE_LANGS[process.env.REACT_APP_REGION_EXT].map(lang => {
                                                return getTextInput(
                                                    theme,
                                                    `sendGridConfiguration.templateIds.residentialRegistrationEmail.${lang}`,
                                                    `${
                                                        isEXPRegion() ? 'Personal' : 'Residential'
                                                    } Registration Email (${lang})`,
                                                    formik,
                                                    'text',
                                                    getInfoIcon(
                                                        `Residential Registration Email (${lang})`,
                                                        `Template ID for registration emails for ${
                                                            isEXPRegion()
                                                                ? 'personal accounts'
                                                                : 'residential customers'
                                                        }.`,
                                                        theme
                                                    )
                                                );
                                            })}

                                            {!isEXPRegion() &&
                                                AVAILABLE_LANGS[process.env.REACT_APP_REGION_EXT].map(lang => {
                                                    return getTextInput(
                                                        theme,
                                                        `sendGridConfiguration.templateIds.condoRegistrationEmail.${lang}`,
                                                        `Condo Registration Email (${lang})`,
                                                        formik,
                                                        'text',
                                                        getInfoIcon(
                                                            `Condo Registration Email (${lang})`,
                                                            `Template ID for registration emails for condo customers.`,
                                                            theme
                                                        )
                                                    );
                                                })}
                                            {AVAILABLE_LANGS[process.env.REACT_APP_REGION_EXT].map(lang => {
                                                return getTextInput(
                                                    theme,
                                                    `sendGridConfiguration.templateIds.commercialRegistrationEmail.${lang}`,
                                                    `${
                                                        isEXPRegion() ? 'Business' : 'Commercial'
                                                    } Registration Email (${lang})`,
                                                    formik,
                                                    'text',
                                                    getInfoIcon(
                                                        `Commercial Registration Email (${lang})`,
                                                        `Template ID for registration emails for ${
                                                            isEXPRegion() ? 'business accounts' : 'commercial customers'
                                                        }.`,
                                                        theme
                                                    )
                                                );
                                            })}
                                        </div>

                                        <Typography variant="body1" style={{ marginTop: theme.spacing.unit * 2 }}>
                                            Unsubscribe Groups
                                        </Typography>
                                        {getTextInput(
                                            theme,
                                            'sendGridConfiguration.unsubscribeGroups.inactiveCustomers',
                                            'Inactive Customers',
                                            formik,
                                            'text',
                                            getInfoIcon(
                                                'Inactive Customers',
                                                'Unsubscribe group for inactive customer emails',
                                                theme
                                            )
                                        )}
                                        {getTextInput(
                                            theme,
                                            'sendGridConfiguration.unsubscribeGroups.charities',
                                            'Charities',
                                            formik,
                                            'text',
                                            getInfoIcon('Charities', 'Unsubscribe group for charity emails', theme)
                                        )}
                                        {getTextInput(
                                            theme,
                                            'sendGridConfiguration.unsubscribeGroups.general',
                                            'General',
                                            formik,
                                            'text',
                                            getInfoIcon('General', 'Unsubscribe group for general emails', theme)
                                        )}
                                    </CustomWrapper>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <CustomWrapper
                                        title="Driver Clock in Emails"
                                        outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}
                                    >
                                        {getSwitch(
                                            theme,
                                            'trips.enableDriverLateEmails',
                                            'Enable Driver Late Emails',
                                            formik,
                                            {},
                                            {},
                                            undefined,
                                            'This will determine whether to send out emails regarding the driver starting his pickup trip late.'
                                        )}
                                    </CustomWrapper>
                                    <CustomWrapper
                                        title="Completed Pickup Emails"
                                        outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}
                                    >
                                        {getSwitch(
                                            theme,
                                            'pickups.sendPickupCompleteEmail',
                                            'Send Pickup Completed Email',
                                            formik,
                                            {},
                                            {},
                                            undefined,
                                            'Send emails to customers informing them that their pickup has been completed'
                                        )}
                                        {getSwitch(
                                            theme,
                                            'pickups.sendPickupCompleteAndRecurringEmail',
                                            'Send Recurring Pickup Completed Email',
                                            formik,
                                            {},
                                            {},
                                            undefined,
                                            'Send emails to customers informing them that their pickup has been completed and a new pickup has been scheduled'
                                        )}
                                    </CustomWrapper>
                                    <CustomWrapper
                                        title="Inactive Mailout Configuration"
                                        outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}
                                    >
                                        <Grid container spacing={theme.spacing.unit}>
                                            <Grid item xs={12}>
                                                <Typography
                                                    variant="body1"
                                                    style={{ marginTop: theme.spacing.unit * 2 }}
                                                >
                                                    First Email
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                {getTextInput(
                                                    theme,
                                                    'inactiveCustomerConfiguration.firstMessage.maxCustomers',
                                                    'Max Customers',
                                                    formik,
                                                    'number',
                                                    getInfoIcon(
                                                        'Max Customers',
                                                        'Maximum number of inactive customers to email at once',
                                                        theme
                                                    )
                                                )}
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                {getTextInput(
                                                    theme,
                                                    'inactiveCustomerConfiguration.firstMessage.registeredNoPickup',
                                                    'Registered N Days Ago And No Pickup',
                                                    formik,
                                                    'number',
                                                    getInfoIcon(
                                                        'Registered N Days Ago And No Pickup',
                                                        'Number of days without a pickup before sending an inactive email',
                                                        theme
                                                    )
                                                )}
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                {getTextInput(
                                                    theme,
                                                    'inactiveCustomerConfiguration.firstMessage.lastPickup',
                                                    'Last Pickup N Days Ago',
                                                    formik,
                                                    'number',
                                                    getInfoIcon(
                                                        'Last Pickup N Days Ago',
                                                        'Number of days since last pickup before sending an inactive email',
                                                        theme
                                                    )
                                                )}
                                            </Grid>
                                            <Grid item xs={12}>
                                                {getTextInput(
                                                    theme,
                                                    'inactiveCustomerConfiguration.firstMessage.shortUrl',
                                                    'Url',
                                                    formik,
                                                    'text',
                                                    getInfoIcon('Url', 'Link to include in first inactive email', theme)
                                                )}
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography
                                                    variant="body1"
                                                    style={{ marginTop: theme.spacing.unit * 2 }}
                                                >
                                                    Second Email
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                {getTextInput(
                                                    theme,
                                                    'inactiveCustomerConfiguration.secondMessage.daysSinceFirst',
                                                    'Days Since First Message',
                                                    formik,
                                                    'number',
                                                    getInfoIcon(
                                                        'Days Since First Message',
                                                        'Number of days since first inactive email before sending second email',
                                                        theme
                                                    )
                                                )}
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                {getTextInput(
                                                    theme,
                                                    'inactiveCustomerConfiguration.secondMessage.promo',
                                                    'Promo',
                                                    formik,
                                                    'text',
                                                    getInfoIcon('Promo', 'Promo to include in second email', theme)
                                                )}
                                            </Grid>
                                            <Grid item xs={12}>
                                                {getTextInput(
                                                    theme,
                                                    'inactiveCustomerConfiguration.secondMessage.shortUrl',
                                                    'Url',
                                                    formik,
                                                    'text',
                                                    getInfoIcon('Url', 'Link to include in second email', theme)
                                                )}
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography
                                                    variant="body1"
                                                    style={{ marginTop: theme.spacing.unit * 2 }}
                                                >
                                                    Third Email
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                {getTextInput(
                                                    theme,
                                                    'inactiveCustomerConfiguration.thirdMessage.daysSinceFirst',
                                                    'Days Since First Message',
                                                    formik,
                                                    'number',
                                                    getInfoIcon(
                                                        'Days Since First Message',
                                                        'Number of days since first inactive email before sending third email',
                                                        theme
                                                    )
                                                )}
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                {getTextInput(
                                                    theme,
                                                    'inactiveCustomerConfiguration.thirdMessage.promo',
                                                    'Promo',
                                                    formik,
                                                    'text',
                                                    getInfoIcon('Promo', 'Promo to include in third email', theme)
                                                )}
                                            </Grid>
                                            <Grid item xs={12}>
                                                {getTextInput(
                                                    theme,
                                                    'inactiveCustomerConfiguration.thirdMessage.shortUrl',
                                                    'Url',
                                                    formik,
                                                    'text',
                                                    getInfoIcon('Url', 'Link to include in third email', theme)
                                                )}
                                            </Grid>
                                        </Grid>
                                    </CustomWrapper>
                                    <CustomWrapper
                                        title="Email Verification"
                                        outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}
                                    >
                                        {getTextInput(
                                            theme,
                                            'customerPermissions.tokenExpirationTime',
                                            'Token Expiration Time (minutes)',
                                            formik,
                                            'number',
                                            getInfoIcon(
                                                'Token Expiration Time',
                                                'Time until links to verify email addresses or change passwords expire',
                                                theme
                                            )
                                        )}
                                    </CustomWrapper>
                                    {isAUSRegion() && (
                                        <CustomWrapper
                                            title="Completed Pickup Survey"
                                            outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}
                                        >
                                            {getSwitch(
                                                theme,
                                                'emailConfig.enableCompletedPickupSurvey',
                                                'Show Survey On Completed Pickup Emails',
                                                formik,
                                                {},
                                                {},
                                                undefined,
                                                'Include a link to a customer survey in the pickup completed email'
                                            )}
                                            {getTextInput(
                                                theme,
                                                'emailConfig.completedPickupSurveyLabel',
                                                'Survey Button Label',
                                                formik,
                                                'text',
                                                getInfoIcon(
                                                    'Survey Button Label',
                                                    'Label shown on customer survey button',
                                                    theme
                                                )
                                            )}
                                            {getTextInput(
                                                theme,
                                                'emailConfig.completedPickupSurveyURL',
                                                'Survey URL',
                                                formik,
                                                'text',
                                                getInfoIcon(
                                                    'Survey URL',
                                                    'Url to direct customer to complete survey',
                                                    theme
                                                )
                                            )}
                                        </CustomWrapper>
                                    )}
                                </Grid>
                            </Grid>
                        )}
                        {selectedView === 'General' && (
                            <Grid container spacing={theme.spacing.unit}>
                                <Grid item xs={12} sm={6}>
                                    <CustomWrapper
                                        title="Enabled Functions"
                                        outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}
                                    >
                                        {getSwitch(
                                            theme,
                                            'enabledFunctions.pickups',
                                            'Enable Site/App Pickup Functions',
                                            formik,
                                            { marginTop: theme.spacing.unit * 2 },
                                            {},
                                            e => {
                                                const checked = e.target.checked;
                                                if (!checked) {
                                                    formik.setFieldValue('enabledFunctions.collectionProgram', false);
                                                }
                                            }
                                        )}

                                        {getSwitch(
                                            theme,
                                            'enabledFunctions.collectionProgram',
                                            `Enable Collection Program (${isCONRegion() ? 'eCSP' : 'IC&I'})`,
                                            formik,
                                            { marginTop: theme.spacing.unit * 2 },
                                            {},
                                            () => {},
                                            '',
                                            !_.get(formik.values, 'enabledFunctions.pickups', false)
                                        )}
                                        {getSwitch(
                                            theme,
                                            'enabledFunctions.promos',
                                            'Enable Site/App Promo Functions',
                                            formik,
                                            {
                                                marginTop: theme.spacing.unit * 2
                                            }
                                        )}
                                        {getSwitch(
                                            theme,
                                            'enabledFunctions.charity',
                                            'Enable Site/App Charity Functions',
                                            formik,
                                            {
                                                marginTop: theme.spacing.unit * 2
                                            }
                                        )}
                                        {getSwitch(
                                            theme,
                                            'enabledFunctions.scanQR',
                                            'Enable Scan QR Functionality',
                                            formik,
                                            {
                                                marginTop: theme.spacing.unit * 2
                                            }
                                        )}
                                        {getSwitch(
                                            theme,
                                            'enabledFunctions.showQR',
                                            'Enable Show QR Functionality',
                                            formik,
                                            {
                                                marginTop: theme.spacing.unit * 2
                                            }
                                        )}
                                        {getSwitch(
                                            theme,
                                            'throttleConfig.enabled',
                                            'Enable Endpoint Throttling',
                                            formik,
                                            {
                                                marginTop: theme.spacing.unit * 2
                                            },
                                            {},
                                            undefined,
                                            'Enable/Disable post request throttling. (Takes 1 min for updates to this to show in the app)'
                                        )}
                                        {/* {getSwitch(
                                            theme,
                                            'dropAndGo.displayQRCode',
                                            'Show QR code view from menu/dash',
                                            formik,
                                            { marginTop: theme.spacing.unit * 2 },
                                            {},
                                            undefined,
                                            'Show drop off QR code view from the main dashboard or menu'
                                        )} */}
                                        {/* {getSwitch(
                                            theme,
                                            'dropAndGo.allowRequestLabels',
                                            'Allow customers to request labels',
                                            formik,
                                            { marginTop: theme.spacing.unit * 2 },
                                            {},
                                            undefined,
                                            'Show option to request labels from the main dashboard or menu'
                                        )} */}
                                    </CustomWrapper>

                                    <CustomWrapper
                                        title="Menu Options"
                                        outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}
                                    >
                                        {getSwitch(
                                            theme,
                                            'menuOptions.showDefaultCollectorOptions',
                                            'Show default collector options',
                                            formik
                                        )}
                                        {getSwitch(theme, 'menuOptions.showAccounting', 'Show invoicing', formik)}
                                        {getSwitch(
                                            theme,
                                            'menuOptions.showJournalEntries',
                                            'Show Journal Entries',
                                            formik
                                        )}
                                        {getSwitch(
                                            theme,
                                            'menuOptions.showReconciliation',
                                            'Show Reconciliation',
                                            formik
                                        )}
                                        {getSwitch(
                                            theme,
                                            'menuOptions.showAgingBalances',
                                            'Show aging balances',
                                            formik
                                        )}
                                    </CustomWrapper>

                                    <CustomWrapper
                                        title="Dashboard Options"
                                        outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}
                                    >
                                        {getSwitch(
                                            theme,
                                            'dashboard.showDepot',
                                            'Show Depot Section',
                                            formik,
                                            {},
                                            {},
                                            undefined,
                                            'Display card containing depot information on main dashboard'
                                        )}
                                        {getSwitch(
                                            theme,
                                            'dashboard.showInvoicing',
                                            'Show Invoicing Section',
                                            formik,
                                            {},
                                            {},
                                            undefined,
                                            'Display card containing invoicing information on main dashboard'
                                        )}
                                        {getSwitch(
                                            theme,
                                            'dashboard.showChecksAndBalances',
                                            'Show Checks/Balances Section',
                                            formik,
                                            {},
                                            {},
                                            undefined,
                                            'Display card containing checks and balances on main dashboard'
                                        )}
                                        {getSwitch(
                                            theme,
                                            'dashboard.showDrivers',
                                            'Show Drivers Section',
                                            formik,
                                            {},
                                            {},
                                            undefined,
                                            'Display card containing driver stats on main dashboard'
                                        )}
                                        {getSwitch(
                                            theme,
                                            'dashboard.consolidateCommission',
                                            'Consolidate Commission',
                                            formik,
                                            {
                                                marginTop: theme.spacing.unit * 2
                                            },
                                            {},
                                            undefined,
                                            `Add Depot CSF to ${
                                                process.env.REACT_APP_BRAND_NAME
                                            } CSF on main dashboard statistics`
                                        )}
                                        {getSwitch(
                                            theme,
                                            'dashboard.showFivePercentRefund',
                                            'Show Full Refund (5%)',
                                            formik,
                                            {
                                                marginTop: theme.spacing.unit * 2
                                            },
                                            {},
                                            undefined,
                                            'Display Full Refund 5% on main dashboard statistics'
                                        )}
                                        {getSwitch(
                                            theme,
                                            'dashboard.showTotalInactiveUsers',
                                            'Show Total Inactive Users',
                                            formik,
                                            { marginTop: theme.spacing.unit * 2 },
                                            {},
                                            undefined,
                                            'Display total number of inactive users on main dashboard statistics'
                                        )}
                                        {getSwitch(
                                            theme,
                                            'dashboard.showContractorCost',
                                            'Show Contractor Cost',
                                            formik,
                                            { marginTop: theme.spacing.unit * 2 },
                                            {},
                                            undefined,
                                            'Display contractor cost on main dashboard statistics'
                                        )}
                                        {getTextInput(
                                            theme,
                                            'dashboard.inactiveBeforeNumMonths',
                                            'Show users inactive for # months',
                                            formik,
                                            'text',
                                            getInfoIcon(
                                                'Inactive months',
                                                'Number of months of inactivity after which a user is counted as inactive',
                                                theme
                                            )
                                        )}

                                        {getTextInput(
                                            theme,
                                            'inactiveCustomerConfiguration.staleCustomerThreshold',
                                            'Stale Customer Threshold (days)',
                                            formik,
                                            'text',
                                            getInfoIcon(
                                                'Stale Customer Threshold',
                                                'Number of days of inactivity after which a user account is set as stale',
                                                theme
                                            )
                                        )}
                                    </CustomWrapper>

                                    <CustomWrapper
                                        title="Other"
                                        outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}
                                    >
                                        {getSelect(
                                            theme,
                                            'subscription.feeGroups',
                                            'Subscription Fee Groups',
                                            feeGroups,
                                            formik,
                                            null,
                                            '_id',
                                            'name',
                                            true
                                        )}
                                    </CustomWrapper>
                                    <CustomWrapper
                                        title="Auto Invoicing"
                                        outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}
                                    >
                                        {getSwitch(
                                            theme,
                                            'autoInvoicing.enabled',
                                            'Enabled',
                                            formik,
                                            {
                                                marginTop: theme.spacing.unit * 2
                                            },
                                            {},
                                            undefined,
                                            'Auto invoice orders and create journal entries weekly'
                                        )}
                                        {getTextInput(
                                            theme,
                                            'autoInvoicing.minDaysSincePickup',
                                            'Auto invoicing grace period',
                                            formik,
                                            'number',
                                            getInfoIcon(
                                                'Number of days',
                                                'Number of days from when order was recieved before they are auto invoiced',
                                                theme
                                            )
                                        )}
                                    </CustomWrapper>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <CustomWrapper
                                        title="System Admin Permissions"
                                        outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}
                                    >
                                        {getSwitch(
                                            theme,
                                            'adminPermissions.canEditCustomerEmail',
                                            'Edit Customer Email',
                                            formik,
                                            {
                                                marginTop: theme.spacing.unit * 2
                                            },
                                            {},
                                            undefined,
                                            'Allow system admins to edit the email address associated with a customer account'
                                        )}
                                        {getSwitch(
                                            theme,
                                            'adminPermissions.canChangeCustomerPassword',
                                            'Change Customer Password',
                                            formik,
                                            {
                                                marginTop: theme.spacing.unit * 2
                                            },
                                            {},
                                            undefined,
                                            'Allow system admins to change the password associated with a customer account'
                                        )}
                                        {getSwitch(
                                            theme,
                                            'adminPermissions.canRedeemCustomerBalance',
                                            'Redeem Customer Balance',
                                            formik,
                                            {
                                                marginTop: theme.spacing.unit * 2
                                            },
                                            {},
                                            undefined,
                                            'Allow system admins to redeem the financial balance associated with a customer account'
                                        )}
                                        {getSwitch(
                                            theme,
                                            'adminPermissions.canRedeemCharityBalance',
                                            'Redeem Charity/Group Balance',
                                            formik,
                                            {
                                                marginTop: theme.spacing.unit * 2
                                            },
                                            {},
                                            undefined,
                                            'Allow system admins to redeem the financial balance associated with a charity or group account'
                                        )}
                                    </CustomWrapper>

                                    <CustomWrapper
                                        title="Customer Permissions"
                                        outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}
                                    >
                                        {getSwitch(
                                            theme,
                                            'customerPermissions.canChangeAccountType',
                                            'Allow customers to change account type',
                                            formik,
                                            { marginTop: theme.spacing.unit * 2 }
                                        )}
                                        {getSwitch(
                                            theme,
                                            'customerHistory.allowViewTaxReceiptHistory',
                                            'Show Tax Receipt in History Dropdown',
                                            formik,
                                            {
                                                marginTop: theme.spacing.unit * 2
                                            }
                                        )}
                                        {getSwitch(
                                            theme,
                                            'customerHistory.allowViewBagtags',
                                            'Show Bagtags to Customer',
                                            formik,
                                            {
                                                marginTop: theme.spacing.unit * 2
                                            }
                                        )}
                                        {getSwitch(
                                            theme,
                                            'customerPermissions.allowReceiptDownload',
                                            'Allow Customers to Download Receipt PDF From History',
                                            formik,
                                            {
                                                marginTop: theme.spacing.unit * 2
                                            }
                                        )}
                                        {getSwitch(
                                            theme,
                                            'customerPermissions.allowRedemptionReceiptDownload',
                                            'Allow Customers to Download Redemption Receipt PDF From History',
                                            formik,
                                            {
                                                marginTop: theme.spacing.unit * 2
                                            }
                                        )}
                                        {getSwitch(
                                            theme,
                                            'customerPermissions.showRedemptionBreakdown',
                                            'Show Redemption Breakdown',
                                            formik,
                                            {
                                                marginTop: theme.spacing.unit * 2
                                            }
                                        )}
                                        {getSwitch(
                                            theme,
                                            'customerPermissions.showImpactReportInSideMenu',
                                            'Show Impact Report in Side Menu',
                                            formik,
                                            {
                                                marginTop: theme.spacing.unit * 2
                                            }
                                        )}
                                    </CustomWrapper>

                                    <CustomWrapper
                                        title="Customer Receipt Options"
                                        outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}
                                    >
                                        {getSwitch(
                                            theme,
                                            'customerHistory.allowViewReceipt',
                                            'Allow customers to see receipt info',
                                            formik,
                                            { marginTop: theme.spacing.unit * 2 }
                                        )}
                                        {getSwitch(
                                            theme,
                                            'customerHistory.showMessageCollector',
                                            'Show Message Collector',
                                            formik,
                                            { marginTop: theme.spacing.unit * 2 }
                                        )}
                                        {getSwitch(
                                            theme,
                                            'customerHistory.displayDepositTax',
                                            'Show Deposit GST',
                                            formik,
                                            { marginTop: theme.spacing.unit * 2 }
                                        )}
                                        <Collapse in={_.get(formik.values, 'customerHistory.displayDepositTax', false)}>
                                            {getSelect(
                                                theme,
                                                'customerHistory.depositTaxGroup',
                                                'Deposit Tax Group',
                                                taxGroups,
                                                formik,
                                                null,
                                                '_id',
                                                'name'
                                            )}
                                        </Collapse>
                                        {getSwitch(
                                            theme,
                                            'customerHistory.displayNetTax',
                                            'Show Net Tax Breakdown',
                                            formik,
                                            {
                                                marginTop: theme.spacing.unit * 2
                                            }
                                        )}
                                        {getSelect(
                                            theme,
                                            'customerHistory.serviceFeeDisplay',
                                            'Service Fee Display',
                                            ['Hide', 'Show Fee', 'Show Fee + Discount'],
                                            formik
                                        )}
                                    </CustomWrapper>

                                    <CustomWrapper
                                        title="Map Configuration"
                                        outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}
                                    >
                                        {getTextInput(
                                            theme,
                                            'defaultCoordinates.latitude',
                                            'Default Latitude',
                                            formik,
                                            'number',
                                            getInfoIcon(
                                                'Default Latitude',
                                                'Default latitude coordinate used for initial map centering',
                                                theme
                                            )
                                        )}
                                        {getTextInput(
                                            theme,
                                            'defaultCoordinates.longitude',
                                            'Default Longitude',
                                            formik,
                                            'number',
                                            getInfoIcon(
                                                'Default Longitude',
                                                'Default longitude coordinate used for initial map centering',
                                                theme
                                            )
                                        )}
                                        {getTextInput(
                                            theme,
                                            'googleApi.mapsCoordsKey',
                                            'Google Maps Coords Key',
                                            formik,
                                            'text',
                                            getInfoIcon('Google Maps Coords Key', 'Google Maps API Coords Key', theme)
                                        )}
                                        {getSwitch(
                                            theme,
                                            'dropLocationMarker.override',
                                            'Override Drop Location Marker',
                                            formik
                                        )}
                                        <Collapse in={_.get(formik.values, 'dropLocationMarker.override', false)}>
                                            {_.isNil(dropLocationMarkerFile) &&
                                            _.isNil(formik.values.dropLocationMarker.url) ? (
                                                <FormControl fullWidth data-cy="upload-drop-location-marker-input">
                                                    <input
                                                        accept={'image/*'}
                                                        style={{ display: 'none' }}
                                                        id="raised-button-file-drop-location"
                                                        multiple={false}
                                                        type="file"
                                                        onChange={handleDropLocationMarkerFile}
                                                    />
                                                    <label htmlFor="raised-button-file-drop-location">
                                                        <Button
                                                            color="primary"
                                                            size="small"
                                                            variant="outlined"
                                                            component="span"
                                                            style={{ width: '100%', marginTop: theme.spacing.unit }}
                                                        >
                                                            Upload Image
                                                        </Button>
                                                    </label>
                                                </FormControl>
                                            ) : (
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'center'
                                                    }}
                                                >
                                                    {!_.isNil(dropLocationMarkerFile) ? (
                                                        <img
                                                            src={URL.createObjectURL(dropLocationMarkerFile)}
                                                            style={{ maxWidth: '100%' }}
                                                            alt="Drop Location Marker"
                                                        />
                                                    ) : !dropLocationImageError &&
                                                      imageFileTypes.includes(
                                                          formik.values.dropLocationMarker.url.split('.').pop()
                                                      ) ? (
                                                        <img
                                                            src={formik.values.dropLocationMarker.url}
                                                            alt="charity-high-resolution-logo"
                                                            onError={() => setDropLocationImageError(true)}
                                                            style={{
                                                                maxWidth: '128px',
                                                                maxHeight: '128px'
                                                            }}
                                                        />
                                                    ) : (
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                alignItems: 'center'
                                                            }}
                                                        >
                                                            <Icon fontSize="large" style={{ color: colors.green[500] }}>
                                                                check_circle
                                                            </Icon>
                                                            <Typography
                                                                style={{
                                                                    color: colors.green[500],
                                                                    margin: theme.spacing.unit * 2,
                                                                    textAlign: 'center'
                                                                }}
                                                                variant="subtitle2"
                                                            >
                                                                Image Uploaded
                                                            </Typography>
                                                        </div>
                                                    )}
                                                    <FormControl fullWidth data-cy="upload-photo-input">
                                                        <input
                                                            accept={'image/*'}
                                                            style={{ display: 'none' }}
                                                            id="raised-button-file-drop-location"
                                                            multiple={false}
                                                            type="file"
                                                            onChange={handleDropLocationMarkerFile}
                                                        />
                                                        <label htmlFor="raised-button-file-drop-location">
                                                            <Button
                                                                color="primary"
                                                                size="small"
                                                                variant="outlined"
                                                                component="span"
                                                                style={{
                                                                    width: '100%',
                                                                    marginTop: theme.spacing.unit
                                                                }}
                                                            >
                                                                Replace Image
                                                            </Button>
                                                        </label>
                                                    </FormControl>
                                                </div>
                                            )}
                                        </Collapse>
                                        {getSwitch(
                                            theme,
                                            'charityMarker.override',
                                            'Override Organization Marker',
                                            formik
                                        )}
                                        <Collapse in={formik.values.charityMarker.override}>
                                            {_.isNil(charityMarkerFile) && _.isNil(formik.values.charityMarker.url) ? (
                                                <FormControl fullWidth data-cy="upload-charity-marker-input">
                                                    <input
                                                        accept={'image/*'}
                                                        style={{ display: 'none' }}
                                                        id="raised-button-file-charity"
                                                        multiple={false}
                                                        type="file"
                                                        onChange={handleCharityMarkerFile}
                                                    />
                                                    <label htmlFor="raised-button-file-charity">
                                                        <Button
                                                            color="primary"
                                                            size="small"
                                                            variant="outlined"
                                                            component="span"
                                                            style={{ width: '100%', marginTop: theme.spacing.unit }}
                                                        >
                                                            Upload Image
                                                        </Button>
                                                    </label>
                                                </FormControl>
                                            ) : (
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'center'
                                                    }}
                                                >
                                                    {!_.isNil(charityMarkerFile) ? (
                                                        <img
                                                            src={URL.createObjectURL(charityMarkerFile)}
                                                            style={{ maxWidth: '100%' }}
                                                            alt="Drop Location Marker"
                                                        />
                                                    ) : !charityImageError &&
                                                      imageFileTypes.includes(
                                                          formik.values.charityMarker.url.split('.').pop()
                                                      ) ? (
                                                        <img
                                                            src={formik.values.charityMarker.url}
                                                            alt="charity-high-resolution-logo"
                                                            onError={() => setCharityImageError(true)}
                                                            style={{
                                                                maxWidth: '128px',
                                                                maxHeight: '128px'
                                                            }}
                                                        />
                                                    ) : (
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                alignItems: 'center'
                                                            }}
                                                        >
                                                            <Icon fontSize="large" style={{ color: colors.green[500] }}>
                                                                check_circle
                                                            </Icon>
                                                            <Typography
                                                                style={{
                                                                    color: colors.green[500],
                                                                    margin: theme.spacing.unit * 2,
                                                                    textAlign: 'center'
                                                                }}
                                                                variant="subtitle2"
                                                            >
                                                                Image Uploaded
                                                            </Typography>
                                                        </div>
                                                    )}
                                                    <FormControl fullWidth data-cy="upload-photo-input">
                                                        <input
                                                            accept={'image/*'}
                                                            style={{ display: 'none' }}
                                                            id="raised-button-file-charity"
                                                            multiple={false}
                                                            type="file"
                                                            onChange={handleCharityMarkerFile}
                                                        />
                                                        <label htmlFor="raised-button-file-charity">
                                                            <Button
                                                                color="primary"
                                                                size="small"
                                                                variant="outlined"
                                                                component="span"
                                                                style={{
                                                                    width: '100%',
                                                                    marginTop: theme.spacing.unit
                                                                }}
                                                            >
                                                                Replace Image
                                                            </Button>
                                                        </label>
                                                    </FormControl>
                                                </div>
                                            )}
                                        </Collapse>
                                    </CustomWrapper>
                                    <CustomWrapper
                                        title="Idle"
                                        outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}
                                    >
                                        {getTextInput(
                                            theme,
                                            'idleLogoutTimeout.systemAdministrator',
                                            'System Administrator (min)',
                                            formik,
                                            'number',
                                            getInfoIcon(
                                                'System Administrator Timeout (minutes)',
                                                'Minutes of inactivity after which to log out a System Administrator (0 to disable)',
                                                theme
                                            )
                                        )}
                                        {getTextInput(
                                            theme,
                                            'idleLogoutTimeout.collectorAdministrator',
                                            'Collector Administrator Timeout (min)',
                                            formik,
                                            'number',
                                            getInfoIcon(
                                                'Collector Administrator Timeout (minutes)',
                                                'Minutes of inactivity after which to log out a Collector Administrator (0 to disable)',
                                                theme
                                            )
                                        )}
                                        {getTextInput(
                                            theme,
                                            'idleLogoutTimeout.collectorEmployee',
                                            'Collector Employee Timeout (min)',
                                            formik,
                                            'number',
                                            getInfoIcon(
                                                'Collector Employee Timeout (minutes)',
                                                'Minutes of inactivity after which to log out a Collector Employee (0 to disable)',
                                                theme
                                            )
                                        )}
                                        {getTextInput(
                                            theme,
                                            'idleLogoutTimeout.customer',
                                            'Customer Timeout (min)',
                                            formik,
                                            'number',
                                            getInfoIcon(
                                                'Customer Timeout (minutes)',
                                                'Minutes of inactivity after which to log out a Customer (0 to disable)',
                                                theme
                                            )
                                        )}
                                    </CustomWrapper>
                                    {isAUSRegion() && (
                                        <CustomWrapper
                                            title="Customer Invoicing"
                                            outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}
                                        >
                                            {getTextInput(
                                                theme,
                                                'customerInvoicing.defaultFeeInvoicePaymentWindow',
                                                'Default Invoice Payment Window',
                                                formik,
                                                'number',
                                                getInfoIcon(
                                                    'Default Invoice Payment Window',
                                                    'Default number of days for customer to pay invoice after it is generated',
                                                    theme
                                                )
                                            )}
                                        </CustomWrapper>
                                    )}
                                </Grid>
                            </Grid>
                        )}
                        {selectedView === 'Help Center' && (
                            <Grid container spacing={theme.spacing.unit}>
                                <Grid item xs={12} sm={6}>
                                    <CustomWrapper
                                        title="Display Options"
                                        outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}
                                    >
                                        {getSwitch(theme, 'helpCenter.showHowItWorks', 'Show How it Works', formik)}
                                        {getSwitch(theme, 'helpCenter.showFaq', 'Show FAQ', formik)}
                                        {getSwitch(theme, 'helpCenter.showContactUs', 'Show Contact Us', formik)}
                                        {isEXPRegion() &&
                                            getSwitch(
                                                theme,
                                                'helpCenter.showZohoHelpDesk',
                                                'Show Zoho Help Desk',
                                                formik
                                            )}
                                    </CustomWrapper>
                                </Grid>
                            </Grid>
                        )}
                        {selectedView === 'Impact Report' && (
                            <Grid item xs={12}>
                                {getSwitch(
                                    theme,
                                    'shareAndFeedbackDialogConfig.shareScreen.enabled',
                                    'Show Impact Report',
                                    formik,
                                    {},
                                    {},
                                    e => {
                                        formik.setFieldValue('impactReport.showImpactReport', e.target.checked);
                                    }
                                )}
                                <Collapse
                                    in={_.get(formik, 'values.shareAndFeedbackDialogConfig.shareScreen.enabled', false)}
                                >
                                    <Grid container spacing={theme.spacing.unit}>
                                        <Grid item xs={12} sm={6}>
                                            <CustomWrapper
                                                title="Water"
                                                outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}
                                            >
                                                {getTextInput(
                                                    theme,
                                                    'impactReport.waterAmount',
                                                    'Amount per container',
                                                    formik,
                                                    'number',
                                                    getInfoIcon(
                                                        'Amount per container',
                                                        'Amount of water per container',
                                                        theme
                                                    )
                                                )}
                                                {getTextInput(
                                                    theme,
                                                    'impactReport.waterUnit',
                                                    'Unit',
                                                    formik,
                                                    'text',
                                                    getInfoIcon('Unit-water', 'Unit of water', theme)
                                                )}
                                            </CustomWrapper>
                                            <CustomWrapper
                                                title="Energy"
                                                outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}
                                            >
                                                {getTextInput(
                                                    theme,
                                                    'impactReport.energyAmount',
                                                    'Amount per container',
                                                    formik,
                                                    'number',
                                                    getInfoIcon(
                                                        'Amount per container',
                                                        'Amount of energy per container',
                                                        theme
                                                    )
                                                )}
                                                {getTextInput(
                                                    theme,
                                                    'impactReport.energyUnit',
                                                    'Unit',
                                                    formik,
                                                    'text',
                                                    getInfoIcon('Unit-energy', 'Unit of energy', theme)
                                                )}
                                            </CustomWrapper>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <CustomWrapper
                                                title="Landfill"
                                                outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}
                                            >
                                                {getTextInput(
                                                    theme,
                                                    'impactReport.landfillAmount',
                                                    'Amount per container',
                                                    formik,
                                                    'number',
                                                    getInfoIcon(
                                                        'Amount per container',
                                                        'Amount of landfill per container',
                                                        theme
                                                    )
                                                )}
                                                {getTextInput(
                                                    theme,
                                                    'impactReport.landfillUnit',
                                                    'Unit',
                                                    formik,
                                                    'text',
                                                    getInfoIcon('Unit-landfill', 'Unit of landfill', theme)
                                                )}
                                            </CustomWrapper>
                                            <CustomWrapper
                                                title="Carbon"
                                                outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}
                                            >
                                                {getTextInput(
                                                    theme,
                                                    'impactReport.carbonAmount',
                                                    'Amount per container',
                                                    formik,
                                                    'number',
                                                    getInfoIcon(
                                                        'Amount per container',
                                                        'Amount of carbon per container',
                                                        theme
                                                    )
                                                )}
                                                {getTextInput(
                                                    theme,
                                                    'impactReport.carbonUnit',
                                                    'Unit',
                                                    formik,
                                                    'text',
                                                    getInfoIcon('Unit-carbon', 'Unit of carbon', theme)
                                                )}
                                            </CustomWrapper>
                                        </Grid>
                                    </Grid>
                                </Collapse>
                            </Grid>
                        )}
                        {selectedView === 'Account Names' && (
                            <Grid container spacing={theme.spacing.unit}>
                                <Grid item xs={12} sm={6}>
                                    {getTextInput(
                                        theme,
                                        'journalEntries.accountNames.depositsReceivable',
                                        'Deposits Receivable',
                                        formik,
                                        'text',
                                        getInfoIcon(
                                            'depositsReceivable',
                                            'Deposits Receivable account name on the journal entries page',
                                            theme
                                        )
                                    )}
                                    {getTextInput(
                                        theme,
                                        'journalEntries.accountNames.customerLiability',
                                        'Customer Liability',
                                        formik,
                                        'text',
                                        getInfoIcon(
                                            'customerLiability',
                                            'Customer Liability account name on the journal entries page',
                                            theme
                                        )
                                    )}
                                    {getTextInput(
                                        theme,
                                        'journalEntries.accountNames.serviceIncome',
                                        'Service Income',
                                        formik,
                                        'text',
                                        getInfoIcon(
                                            'serviceIncome',
                                            'Service Income account name on the journal entries page',
                                            theme
                                        )
                                    )}
                                    {getTextInput(
                                        theme,
                                        'journalEntries.accountNames.taxLiability',
                                        'Tax Liability',
                                        formik,
                                        'text',
                                        getInfoIcon(
                                            'taxLiability',
                                            'Tax Liability account name on the journal entries page',
                                            theme
                                        )
                                    )}
                                    {getTextInput(
                                        theme,
                                        'journalEntries.accountNames.referrals',
                                        'Referrals',
                                        formik,
                                        'text',
                                        getInfoIcon(
                                            'referrals',
                                            'Referrals account name on the journal entries page',
                                            theme
                                        )
                                    )}
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {getTextInput(
                                        theme,
                                        'journalEntries.accountNames.serviceFees',
                                        'Service Fees',
                                        formik,
                                        'text',
                                        getInfoIcon(
                                            'serviceFees',
                                            'Service Fees account name on the journal entries page',
                                            theme
                                        )
                                    )}
                                    {getTextInput(
                                        theme,
                                        'journalEntries.accountNames.redemptionsPayment',
                                        'Redemptions Payment',
                                        formik,
                                        'text',
                                        getInfoIcon(
                                            'redemptionsPayment',
                                            'Redemptions Payment account name on the journal entries page',
                                            theme
                                        )
                                    )}
                                    {getTextInput(
                                        theme,
                                        'journalEntries.accountNames.feeIncome',
                                        'Fee Income',
                                        formik,
                                        'text',
                                        getInfoIcon(
                                            'feeIncome',
                                            'Fee Income account name on the journal entries page',
                                            theme
                                        )
                                    )}
                                    {getTextInput(
                                        theme,
                                        'journalEntries.accountNames.redemptionFees',
                                        'Redemption Fees',
                                        formik,
                                        'text',
                                        getInfoIcon(
                                            'redemptionFees',
                                            'Redemption Fees account name on the journal entries page',
                                            theme
                                        )
                                    )}
                                </Grid>
                            </Grid>
                        )}
                        {selectedView === 'Intuit Configuration' && (
                            <Grid container spacing={theme.spacing.unit}>
                                <Grid item xs={12} sm={6}>
                                    <CustomWrapper
                                        title="General"
                                        outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}
                                    >
                                        {getTextInput(
                                            theme,
                                            'intuit.company_id',
                                            'Company Id',
                                            formik,
                                            'text',
                                            getInfoIcon('Company Id', 'Intuit company ID', theme)
                                        )}
                                    </CustomWrapper>
                                    {intuitAccountForm(formik, 'depositsReceivable', 'Deposits Receivable', theme)}
                                    {intuitAccountForm(formik, 'customerLiability', 'Customer Liability', theme)}
                                    {intuitAccountForm(formik, 'serviceIncome', 'Service Income', theme)}
                                    {intuitAccountForm(formik, 'taxLiability', 'Tax Liability', theme)}
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {intuitAccountForm(formik, 'referrals', 'Referrals', theme)}
                                    {intuitAccountForm(formik, 'serviceFees', 'Service Fees', theme)}
                                    {intuitAccountForm(formik, 'redemptionsPayment', 'Redemptions Payment', theme)}
                                    {intuitAccountForm(formik, 'adSpend', 'Ad Spend', theme)}
                                </Grid>
                            </Grid>
                        )}

                        {selectedView === 'Drivers' && (
                            <Grid container spacing={theme.spacing.unit}>
                                <Grid item xs={12} sm={6}>
                                    <CustomWrapper
                                        title="Driver Tipping"
                                        outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}
                                    >
                                        {getSwitch(
                                            theme,
                                            'driverTipping.isEnabled',
                                            'Enable Driver Tipping',
                                            formik,
                                            {},
                                            {},
                                            undefined,
                                            'Allow customers to tip drivers from returns on pickup counts'
                                        )}
                                        {getSelect(
                                            theme,
                                            'driverTipping.tippingModel',
                                            'Tipping Model',
                                            [TIPPING_MODEL_INDIVIDUAL, TIPPING_MODEL_POOL],
                                            formik
                                        )}
                                    </CustomWrapper>
                                    <CustomWrapper
                                        title="Clock out Options"
                                        outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}
                                    >
                                        <LocalizedStringListInput
                                            label="General Offload Instructions"
                                            theme={theme}
                                            formik={formik}
                                            formikPath="trips.generalOffloadReminders"
                                            infoText="List of instructions to display to driver at the end of every trip"
                                        />

                                        <LocalizedStringListInput
                                            label="Last Trip Offload Instructions"
                                            theme={theme}
                                            formik={formik}
                                            formikPath="trips.lastTripOffloadReminders"
                                            infoText="List of instructions to display to driver at the end of their last trip of the day"
                                        />

                                        {getTextInput(
                                            theme,
                                            'trips.maxOffloadTime',
                                            'Max Allowable Offload Time (min)',
                                            formik,
                                            'number',
                                            getInfoIcon(
                                                'Max Allowable Offload Time',
                                                'Maximum cap on time required to offload all bags at depot',
                                                theme
                                            )
                                        )}
                                    </CustomWrapper>
                                    <CustomWrapper
                                        title="Clock in Options"
                                        outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}
                                    >
                                        <LocalizedStringListInput
                                            label="Starting Trip Tasks"
                                            theme={theme}
                                            formik={formik}
                                            formikPath="trips.startingTripTasks"
                                            infoText="List of tasks a driver should complete before starting their trip"
                                        />
                                        {getTextInput(
                                            theme,
                                            'trips.maxEarlyStartRouteTime',
                                            'Max Early Start Trip Time (min)',
                                            formik,
                                            'number',
                                            getInfoIcon(
                                                'Max Early Start Trip Time',
                                                'Maximum amount of time before official start time a driver can start their trip',
                                                theme
                                            )
                                        )}
                                        {getTextInput(
                                            theme,
                                            'trips.maxDistanceFromStartLocation',
                                            'Max Distance from Start Location (km)',
                                            formik,
                                            'number',
                                            getInfoIcon(
                                                'Max Distance from Start Location',
                                                'Maximum GPS distance from start location from which a driver can start their trip',
                                                theme
                                            )
                                        )}
                                        {/* <Grid item sm={4} xs={12}>
                                {getTextInput(
                                    theme,
                                    'trips.maxStartTripTime',
                                    'Max Allowable Start Trip Time (min)',
                                    formik,
                                    'number'
                                )}
                            </Grid> */}
                                        {/* <Grid item sm={2} xs={12}>
                                {getSwitch(
                                    theme,
                                    'trips.enableStartScreen',
                                    'Enable Trip Start Screen',
                                    formik,
                                    {
                                        marginTop: theme.spacing.unit * 2
                                    },
                                    {},
                                    undefined,
                                    'Display Start Trip Screen to driver before starting trip'
                                )}
                            </Grid> */}
                                    </CustomWrapper>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <CustomWrapper
                                        title="Driver Reminders"
                                        outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}
                                    >
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            {getSwitch(
                                                theme,
                                                'pickups.afterCompleteDialogEnabled',
                                                'Enable Driver Reminder Dialog After Pickup',
                                                formik,
                                                {},
                                                {},
                                                undefined,
                                                'Display a configurable reminder dialog to the driver after they complete each pickup'
                                            )}

                                            <LocalizedTextInput
                                                label="Complete Pickup Picture Title"
                                                value={_.get(formik, 'values.pickups.afterCompleteDialogTitle')}
                                                onChange={value =>
                                                    formik.setFieldValue('pickups.afterCompleteDialogTitle', value)
                                                }
                                                style={{ marginTop: theme.spacing.unit }}
                                                touched={_.get(formik, 'touched.pickups.afterCompleteDialogTitle')}
                                                error={_.get(formik, 'errors.pickups.afterCompleteDialogTitle')}
                                                infoText="Title of the reminder dialog that is shown to drivers after completing a pickup"
                                            />
                                            <LocalizedTextInput
                                                label="After Complete Dialog Content"
                                                value={_.get(formik, 'values.pickups.afterCompleteDialogContent')}
                                                onChange={value =>
                                                    formik.setFieldValue('pickups.afterCompleteDialogContent', value)
                                                }
                                                style={{ marginTop: theme.spacing.unit * 2 }}
                                                touched={_.get(formik, 'touched.pickups.afterCompleteDialogContent')}
                                                error={_.get(formik, 'errors.pickups.afterCompleteDialogContent')}
                                                infoText="Text of the reminder dialog that is shown to drivers after completing a pickup"
                                            />

                                            <LocalizedStringListInput
                                                label="After Complete Dialog Examples"
                                                theme={theme}
                                                formik={formik}
                                                formikPath="pickups.afterCompleteDialogExamples"
                                                infoText="List of examples to include in the reminder dialog that is shown to drivers after completing a pickup"
                                            />
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <LocalizedTextInput
                                                label="Complete Pickup Picture Title"
                                                value={_.get(formik, 'values.pickups.completePickupImageTitle')}
                                                onChange={value =>
                                                    formik.setFieldValue('pickups.completePickupImageTitle', value)
                                                }
                                                style={{ marginTop: theme.spacing.unit * 2 }}
                                                touched={_.get(formik, 'touched.pickups.completePickupImageTitle')}
                                                error={_.get(formik, 'errors.pickups.completePickupImageTitle')}
                                                infoText="Title of screen that prompts driver to capture an image when completing a pickup"
                                            />

                                            <LocalizedStringListInput
                                                label="Complete Pickup Picture Instructions"
                                                theme={theme}
                                                formik={formik}
                                                formikPath="pickups.completePickupImageInstructions"
                                                infoText="List of instructions for image capture when completing a pickup"
                                            />
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <LocalizedTextInput
                                                label="Abort Pickup Picture Title"
                                                value={_.get(formik, 'values.pickups.abortPickupImageTitle')}
                                                onChange={value =>
                                                    formik.setFieldValue('pickups.abortPickupImageTitle', value)
                                                }
                                                style={{ marginTop: theme.spacing.unit * 2 }}
                                                touched={_.get(formik, 'touched.pickups.abortPickupImageTitle')}
                                                error={_.get(formik, 'errors.pickups.abortPickupImageTitle')}
                                                infoText="Title of screen that prompts driver to capture an image when aborting a pickup"
                                            />

                                            <LocalizedStringListInput
                                                label="Abort Pickup Picture Instructions"
                                                theme={theme}
                                                formik={formik}
                                                formikPath="pickups.abortPickupImageInstructions"
                                                infoText="List of instructions for image capture when aborting a pickup"
                                            />
                                        </div>
                                    </CustomWrapper>
                                </Grid>
                            </Grid>
                        )}
                        {selectedView === 'Pickups' && (
                            <Grid container spacing={theme.spacing.unit}>
                                <Grid item xs={12} sm={6}>
                                    <CustomWrapper
                                        title="General"
                                        outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}
                                    >
                                        {getTextInput(
                                            theme,
                                            'pickups.abortThreshold',
                                            'Abort Threshold',
                                            formik,
                                            'number',
                                            getInfoIcon(
                                                'Abort Threshold',
                                                'Number of times a pickup can be aborted before being cancelled',
                                                theme
                                            )
                                        )}
                                        {getTextInput(
                                            theme,
                                            'pickups.postponeThreshold',
                                            'Postpone Threshold',
                                            formik,
                                            'number',
                                            getInfoIcon(
                                                'Postpone Threshold',
                                                'Number of times a pickup can be postponed before being cancelled',
                                                theme
                                            )
                                        )}
                                        {getTextInput(
                                            theme,
                                            'pickups.lowVolumeContainerThreshold',
                                            'Low Volume Container Threshold',
                                            formik,
                                            'number',
                                            getInfoIcon(
                                                'Low Volume Container Threshold',
                                                'Minimum intended number of containers to collect per pickup',
                                                theme
                                            )
                                        )}
                                        {getSwitch(
                                            theme,
                                            'pickups.minPickupFeeEnabled',
                                            'Minimum Pickup Fee Enabled',
                                            formik
                                        )}
                                        {getTextInput(
                                            theme,
                                            'pickups.minPickupFee',
                                            'Minimum Pickup Fee ($)',
                                            formik,
                                            'number',
                                            getInfoIcon(
                                                'Minimum Pickup Fee',
                                                'Fee applied to pickups under the threshold',
                                                theme
                                            ),
                                            {},
                                            null,
                                            !_.get(formik.values, 'pickups.minPickupFeeEnabled', false)
                                        )}
                                        {getSelect(
                                            theme,
                                            'pickups.minFrequencyPickupTypes',
                                            'Min. Frequency Pickup Types',
                                            [RESIDENTIAL, COMMERCIAL, CONDO],
                                            formik,
                                            null,
                                            null,
                                            null,
                                            true
                                        )}
                                        {getTextInput(
                                            theme,
                                            'pickups.minFrequency',
                                            'Minimum Pickup Frequency (# weeks)',
                                            formik,
                                            'number',
                                            getInfoIcon(
                                                'Minimum Pickup Frequency',
                                                'Minimum recurring pickup frequency in number of weeks (1 means no minimum)',
                                                theme
                                            )
                                        )}
                                        {getSwitch(
                                            theme,
                                            'pickups.customerImageUploadEnabled',
                                            'Customer Pickup Image Upload Enabled',
                                            formik
                                        )}
                                    </CustomWrapper>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <CustomWrapper
                                        title="Fixed Cost Options"
                                        outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}
                                    >
                                        <StringListInput
                                            label="Default Fixed Costs"
                                            values={_.get(formik.values, 'collectors.defaultFixedCosts')}
                                            onChange={values =>
                                                formik.setFieldValue('collectors.defaultFixedCosts', values)
                                            }
                                            error={inputValue => {
                                                return (
                                                    _.isEmpty(inputValue) &&
                                                    _.get(formik.errors, 'collectors.defaultFixedCosts', false)
                                                );
                                            }}
                                            errorMessage={
                                                _.get(formik.errors, 'collectors.defaultFixedCosts', false)
                                                    ? _.get(formik.errors, 'collectors.defaultFixedCosts', '123')
                                                    : '321'
                                            }
                                            InputProps={{
                                                variant: 'outlined'
                                            }}
                                            longStringFormat
                                            infoText="List of default fixed cost options for collectors"
                                        />
                                    </CustomWrapper>
                                    <CustomWrapper
                                        title="Default Service Fee Options"
                                        outerDivStyle={{ marginBottom: theme.spacing.unit * 4 }}
                                    >
                                        {getSwitch(
                                            theme,
                                            'collectors.serviceFeesDisabled',
                                            'Disable Service Fees For All Collectors',
                                            formik
                                        )}
                                        {getTextInput(
                                            theme,
                                            'collectors.defaultServiceFee.type',
                                            'Fee Type',
                                            formik,
                                            'text',
                                            null,
                                            null,
                                            null,
                                            true
                                        )}
                                        {getTextInput(
                                            theme,
                                            'collectors.defaultServiceFee.value',
                                            'Fee (percent)',
                                            formik,
                                            'number',
                                            {},
                                            {},
                                            null,
                                            _.get(formik, 'values.collectors.serviceFeesDisabled')
                                        )}
                                    </CustomWrapper>
                                    <CustomWrapper
                                        title="Collections Program Options"
                                        outerDivStyle={{ marginBottom: theme.spacing.unit * 4 }}
                                    >
                                        <StringListInput
                                            label="Rejection Options"
                                            values={_.get(formik.values, 'pickups.collectionProgram.rejectionReasons')}
                                            onChange={values =>
                                                formik.setFieldValue(
                                                    'pickups.collectionProgram.rejectionReasons',
                                                    values
                                                )
                                            }
                                            error={() => {}}
                                            errorMessage="Please enter a valid reason."
                                            InputProps={{
                                                variant: 'outlined'
                                            }}
                                            infoText="Rejection options for collection program customers"
                                        />
                                    </CustomWrapper>
                                </Grid>
                            </Grid>
                        )}
                        {selectedView === 'POR' && (
                            <Grid container spacing={theme.spacing.unit}>
                                <Grid item xs={12} sm={6}>
                                    <CustomWrapper
                                        title="POR Advertisement Screens"
                                        outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}
                                    >
                                        <StringListInput
                                            label="Half Screen Ads"
                                            values={_.get(formik.values, 'liveCountAds.halfScreen')}
                                            onChange={values => formik.setFieldValue('liveCountAds.halfScreen', values)}
                                            error={() => {}}
                                            InputProps={{
                                                variant: 'outlined'
                                            }}
                                            infoText="List of image files to show half screen ads on the live counts screen"
                                        />
                                        <StringListInput
                                            label="Full Screen Ads"
                                            values={_.get(formik.values, 'liveCountAds.fullScreen')}
                                            onChange={values => formik.setFieldValue('liveCountAds.fullScreen', values)}
                                            error={() => {}}
                                            InputProps={{
                                                variant: 'outlined'
                                            }}
                                            infoText="List of image files to show full screen ads on the live counts screen"
                                        />
                                    </CustomWrapper>
                                    <CustomWrapper
                                        title="Counter Screen"
                                        outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}
                                    >
                                        {_user.isSystemAdmin(operator) &&
                                            getTextInput(
                                                theme,
                                                'counterScreen.refreshInterval',
                                                'Counter screen auto refresh interval (s), zero or blank to disable',
                                                formik,
                                                'number',
                                                getInfoIcon(
                                                    'Counter screen auto refresh interval',
                                                    'Interval between automatic refreshes to update data displayed on counter screen',
                                                    theme
                                                )
                                            )}
                                        {getTextInput(
                                            theme,
                                            'bulkCounter.hourWindow',
                                            'Overdue Threshold (hrs) ',
                                            formik,
                                            'number',
                                            getInfoIcon(
                                                'Overdue Threshold',
                                                'Period of time after which an incomplete order is considered overdue',
                                                theme
                                            )
                                        )}
                                        {getSwitch(
                                            theme,
                                            'bulkCounter.porCameraEnabled',
                                            'Enable POR camera functionality',
                                            formik
                                        )}
                                    </CustomWrapper>
                                    <CustomWrapper
                                        title="Counts API"
                                        outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}
                                    >
                                        {getTextInput(
                                            theme,
                                            'bulkCounter.containersPerBag',
                                            'Containers per bag',
                                            formik,
                                            'number',
                                            getInfoIcon(
                                                'Containers per bag',
                                                'Default number of containers per bag submit through counts API',
                                                theme
                                            )
                                        )}
                                    </CustomWrapper>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <CustomWrapper
                                        title="Customers"
                                        outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}
                                    >
                                        {getTextInput(
                                            theme,
                                            'bulkCounter.daysToCount',
                                            'Displayed number of days until bags counted',
                                            formik,
                                            'number',
                                            getInfoIcon(
                                                'Displayed number of days until bags counted',
                                                'Number of days until commodities counted shown on the customer dashboard',
                                                theme
                                            )
                                        )}
                                    </CustomWrapper>
                                    <CustomWrapper
                                        title="Upload Quality"
                                        outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}
                                    >
                                        {getTextInput(
                                            theme,
                                            'aiCounts.quality',
                                            'Image Upload Quality % (0-100)',
                                            formik,
                                            'number',
                                            getInfoIcon(
                                                'Image upload quality',
                                                'Reduces image quality to increase processing speed. Keep at 100 for no image quality reduction',
                                                theme
                                            )
                                        )}
                                        {getTextInput(
                                            theme,
                                            'aiCounts.maxDimensions',
                                            'Image Upload Max Height/Width (px)',
                                            formik,
                                            'number',
                                            getInfoIcon(
                                                'Max image height/width',
                                                'Scales down image to a maximum size according to the entered value. Set to 0 for no scaling',
                                                theme
                                            )
                                        )}
                                    </CustomWrapper>
                                    {_user.isSystemAdmin(operator) && (
                                        <CustomWrapper
                                            title="Adjustments"
                                            outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}
                                        >
                                            {getTextInput(
                                                theme,
                                                'adjustments.maxDailyAmountPerUser',
                                                'Max amount claimable per user per day ($)',
                                                formik,
                                                'number',
                                                getInfoIcon(
                                                    'Max amount claimable',
                                                    'Maximum dollar amount a user can claim in one day through adjustments',
                                                    theme
                                                )
                                            )}
                                            {getSwitch(
                                                theme,
                                                'adjustments.enableAmountCapping',
                                                'Enabled',
                                                formik,
                                                {},
                                                {},
                                                undefined,
                                                'Limit the total dollar amount a user can claim in one day through adjustments'
                                            )}

                                            <AdjustmentReasonsList
                                                reasons={_.get(formik.values, 'adjustments.reasons')}
                                                theme={theme}
                                                warnAction={warnAction}
                                                onChange={newVal => {
                                                    formik.setFieldValue('adjustments.reasons', newVal);
                                                }}
                                                setShowCreateEditReasonDialog={setShowCreateEditReasonDialog}
                                                setCreateEditReasonDialogMode={setCreateEditReasonDialogMode}
                                                setEditingReason={setEditingReason}
                                            />
                                        </CustomWrapper>
                                    )}
                                </Grid>
                            </Grid>
                        )}
                        {selectedView === 'Redemptions' && (
                            <Grid container spacing={theme.spacing.unit}>
                                <Grid item xs={12}>
                                    <CustomWrapper
                                        title="General"
                                        outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}
                                    >
                                        <Grid container spacing={theme.spacing.unit}>
                                            <Grid item xs={6} sm={3}>
                                                {getTextInput(
                                                    theme,
                                                    'redemptionOptions.redemptionFee',
                                                    'Redemption Fee ($)',
                                                    formik,
                                                    'number',
                                                    getInfoIcon(
                                                        'Redemption Fee',
                                                        'Fixed fee for processing redemptions',
                                                        theme
                                                    )
                                                )}
                                            </Grid>
                                            <Grid item xs={6} sm={3}>
                                                {getSwitch(
                                                    theme,
                                                    'redemptionOptions.commercialAccountsCanRequestETransfer',
                                                    'Allow commercial customers to request e-Transfers',
                                                    formik,
                                                    { marginTop: theme.spacing.unit * 2 },
                                                    {},
                                                    undefined,
                                                    'Allow commercial accounts to redeem their balance via e-Transfer'
                                                )}
                                            </Grid>
                                            <Grid item xs={6} sm={3}>
                                                {getSwitch(
                                                    theme,
                                                    'redemptionOptions.disableInAppRedemptionCancel',
                                                    'Disable In App Redemption Cancellation',
                                                    formik,
                                                    { paddingTop: theme.spacing.unit * 2 },
                                                    {},
                                                    undefined,
                                                    'Redemptions can only be cancelled through integrations'
                                                )}
                                            </Grid>
                                            <Grid item xs={6} sm={3}>
                                                {getSwitch(
                                                    theme,
                                                    'redemptionOptions.hideEtransferPassphrase',
                                                    'Hide Customer E-Transfer Passphrase',
                                                    formik,
                                                    { paddingTop: theme.spacing.unit * 2 },
                                                    {},
                                                    undefined,
                                                    "Toggle for hiding/showing customer's E-tranfer answers"
                                                )}
                                            </Grid>
                                            <Grid item xs={6} sm={3}>
                                                {getSwitch(
                                                    theme,
                                                    'redemptionOptions.emailVerificationRequiredForETransfer',
                                                    'Email Verification Required For e-Transfer',
                                                    formik,
                                                    { marginTop: theme.spacing.unit * 2 },
                                                    {},
                                                    undefined,
                                                    _.get(
                                                        formik,
                                                        'values.registration.emailVerificationRequired',
                                                        false
                                                    )
                                                        ? 'Require customer email to be verified before allowing e-Transfer redemption'
                                                        : 'Email verification is not enabled for this system',
                                                    !_.get(
                                                        formik,
                                                        'values.registration.emailVerificationRequired',
                                                        false
                                                    )
                                                )}
                                            </Grid>
                                        </Grid>
                                    </CustomWrapper>
                                </Grid>
                                {_user.isSystemAdmin(operator) && isCONRegion() && (
                                    <Grid item xs={12}>
                                        <CustomWrapper
                                            title="BMO e-Transfer Configuration"
                                            outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}
                                        >
                                            {getSwitch(
                                                theme,
                                                'redemptionOptions.bmoVariables.useBMOFormat',
                                                'Enabled',
                                                formik,
                                                {},
                                                {},
                                                undefined,
                                                'Use BMO format for generating e-transfer csv'
                                            )}
                                            <Collapse
                                                in={_.get(
                                                    formik,
                                                    'values.redemptionOptions.bmoVariables.useBMOFormat',
                                                    false
                                                )}
                                            >
                                                <Grid container spacing={theme.spacing.unit}>
                                                    <Grid item xs={12} sm={4}>
                                                        {getTextInput(
                                                            theme,
                                                            'redemptionOptions.bmoVariables.id',
                                                            'Client ID',
                                                            formik,
                                                            'text',
                                                            getInfoIcon(
                                                                'Client ID',
                                                                '6 character ID provided by BMO',
                                                                theme
                                                            )
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                        {getTextInput(
                                                            theme,
                                                            'redemptionOptions.bmoVariables.olbbCustomerId',
                                                            'OLBB Customer ID',
                                                            formik,
                                                            'text',
                                                            getInfoIcon(
                                                                'OLBB Customer ID',
                                                                'Online Banking for Business customer ID provided by BMO',
                                                                theme
                                                            )
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                        {getTextInput(
                                                            theme,
                                                            'redemptionOptions.bmoVariables.accountNumber',
                                                            'Account Number',
                                                            formik,
                                                            'text',
                                                            getInfoIcon(
                                                                'Account Number',
                                                                'Account number from which to send e-transfers',
                                                                theme
                                                            )
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                        {getTextInput(
                                                            theme,
                                                            'redemptionOptions.bmoVariables.expiryWindow',
                                                            'Expiry Window (days)',
                                                            formik,
                                                            'number',
                                                            getInfoIcon(
                                                                'Expiry Window (days)',
                                                                'Days after which the e-Transfer will expire',
                                                                theme
                                                            )
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                        {getTextInput(
                                                            theme,
                                                            'redemptionOptions.bmoVariables.reminderFrequency',
                                                            'Reminder Frequency (days)',
                                                            formik,
                                                            'number',
                                                            getInfoIcon(
                                                                'Reminder Frequency (days)',
                                                                'Number of days between customer reminders',
                                                                theme
                                                            )
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                        {getTextInput(
                                                            theme,
                                                            'redemptionOptions.bmoVariables.maxReminders',
                                                            'Max Reminders',
                                                            formik,
                                                            'number',
                                                            getInfoIcon(
                                                                'Max Reminders',
                                                                'Maximum number of applicable remiders to send',
                                                                theme
                                                            )
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </Collapse>
                                        </CustomWrapper>
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    <CustomWrapper
                                        title="Customer"
                                        outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}
                                    >
                                        <Grid container spacing={theme.spacing.unit}>
                                            <Grid item xs={12} sm={3}>
                                                <Typography variant="subtitle1">Methods</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                <Typography variant="subtitle1">Minimum Value ($)</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                <Typography variant="subtitle1">Min Processing Time (Days)</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                <Typography variant="subtitle1">Max Processing Time (Days)</Typography>
                                            </Grid>
                                            {!isAUSRegion() && (
                                                <>
                                                    <Grid item xs={12} sm={3}>
                                                        {getSwitch(
                                                            theme,
                                                            'redemptionOptions.customer.methods.cheque',
                                                            'Cheque',
                                                            formik,
                                                            { paddingTop: theme.spacing.unit * 2 },
                                                            {},
                                                            undefined,
                                                            'Allow customers to redeem balance by cheque',
                                                            undefined,
                                                            undefined,
                                                            '-customer'
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={12} sm={3}>
                                                        {getTextInput(
                                                            theme,
                                                            'redemptionOptions.customer.chequeMinimum',
                                                            'Minimum (cheque)',
                                                            formik,
                                                            'number',
                                                            getInfoIcon(
                                                                'Minimum Cheque',
                                                                'Minimum balance required for customer to redeem by cheque',
                                                                theme
                                                            )
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={12} sm={3}>
                                                        {getTextInput(
                                                            theme,
                                                            'redemptionOptions.customer.chequeProcessingTimeMin',
                                                            'Min Time (cheque)',
                                                            formik,
                                                            'number',
                                                            getInfoIcon(
                                                                'Min Time Cheque',
                                                                'Estimated minimum time required to process cheque redemption',
                                                                theme
                                                            )
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={12} sm={3}>
                                                        {getTextInput(
                                                            theme,
                                                            'redemptionOptions.customer.chequeProcessingTimeMax',
                                                            'Max Time (cheque)',
                                                            formik,
                                                            'number',
                                                            getInfoIcon(
                                                                'Max Time Cheque',
                                                                'Estimated maximum time required to process cheque redemption',
                                                                theme
                                                            )
                                                        )}
                                                    </Grid>
                                                </>
                                            )}

                                            {!isAUSRegion() && (
                                                <>
                                                    <Grid item xs={12} sm={3}>
                                                        {getSwitch(
                                                            theme,
                                                            'redemptionOptions.customer.methods.bankTransfer',
                                                            loc('redemption6', lang),
                                                            formik,
                                                            { paddingTop: theme.spacing.unit * 2 },
                                                            {},
                                                            undefined,
                                                            'Allow customers to redeem balance via bank transfer',
                                                            undefined,
                                                            undefined,
                                                            '-customer'
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={12} sm={3}>
                                                        {getTextInput(
                                                            theme,
                                                            'redemptionOptions.customer.bankTransferMinimum',
                                                            'Minimum (bank transfer)',
                                                            formik,
                                                            'number',
                                                            getInfoIcon(
                                                                'Minimum Bank Transfer',
                                                                'Minimum balance required to redeem via bank transfer',
                                                                theme
                                                            )
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={12} sm={3}>
                                                        {getTextInput(
                                                            theme,
                                                            'redemptionOptions.customer.bankTransferProcessingTimeMin',
                                                            'Time (bank transfer)',
                                                            formik,
                                                            'number',
                                                            getInfoIcon(
                                                                'Min Time Bank Transfer',
                                                                'Estimated minimum time required to process bank transfer redemption',
                                                                theme
                                                            )
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={12} sm={3}>
                                                        {getTextInput(
                                                            theme,
                                                            'redemptionOptions.customer.bankTransferProcessingTimeMax',
                                                            'Max Time (bank transfer)',
                                                            formik,
                                                            'number',
                                                            getInfoIcon(
                                                                'Max Time Bank Transfer',
                                                                'Estimated maximum time required to process bank transfer redemption',
                                                                theme
                                                            )
                                                        )}
                                                    </Grid>

                                                    <Grid item xs={12} sm={3}>
                                                        {getSwitch(
                                                            theme,
                                                            'redemptionOptions.customer.methods.etransfer',
                                                            loc('redemption4', lang),
                                                            formik,
                                                            { paddingTop: theme.spacing.unit * 2 },
                                                            {},
                                                            undefined,
                                                            'Allow customers to redeem balance via e-Transfer',
                                                            undefined,
                                                            undefined,
                                                            '-customer'
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={12} sm={3}>
                                                        {getTextInput(
                                                            theme,
                                                            'redemptionOptions.customer.eTransferMinimum',
                                                            'Minimum (e-transfer)',
                                                            formik,
                                                            'number',
                                                            getInfoIcon(
                                                                'Minimum e-Transfer',
                                                                'Minimum balance required to redeem via e-Transfer',
                                                                theme
                                                            )
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={12} sm={3}>
                                                        {getTextInput(
                                                            theme,
                                                            'redemptionOptions.customer.eTransferProcessingTimeMin',
                                                            'Time (e-transfer)',
                                                            formik,
                                                            'number',
                                                            getInfoIcon(
                                                                'Min Time e-Transfer',
                                                                'Estimated minimum time required to process e-Transfer redemption',
                                                                theme
                                                            )
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={12} sm={3}>
                                                        {getTextInput(
                                                            theme,
                                                            'redemptionOptions.customer.eTransferProcessingTimeMax',
                                                            'Max Time (e-transfer)',
                                                            formik,
                                                            'number',
                                                            getInfoIcon(
                                                                'Max Time e-Transfer',
                                                                'Estimated maximum time required to process e-Transfer redemption',
                                                                theme
                                                            )
                                                        )}
                                                    </Grid>

                                                    <Grid item xs={12} sm={3}>
                                                        {getSwitch(
                                                            theme,
                                                            'redemptionOptions.customer.methods.cash',
                                                            loc('redemption7', lang),
                                                            formik,
                                                            { paddingTop: theme.spacing.unit * 2 },
                                                            {},
                                                            undefined,
                                                            'Allow customers to redeem balance in cash',
                                                            undefined,
                                                            undefined,
                                                            '-customer'
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={12} sm={3}>
                                                        {getTextInput(
                                                            theme,
                                                            'redemptionOptions.customer.cashMinimum',
                                                            'Minimum (cash)',
                                                            formik,
                                                            'number',
                                                            getInfoIcon(
                                                                'Minimum Cash',
                                                                'Minimum balance required to redeem in cash',
                                                                theme
                                                            )
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} />
                                                </>
                                            )}
                                            {isAUSRegion() && (
                                                <>
                                                    <Grid item xs={12} sm={3}>
                                                        {getSwitch(
                                                            theme,
                                                            'redemptionOptions.customer.methods.directDeposit',
                                                            'Direct Deposit',
                                                            formik,
                                                            { paddingTop: theme.spacing.unit * 2 },
                                                            {},
                                                            undefined,
                                                            'Allow customers to redeem via direct deposit',
                                                            undefined,
                                                            undefined,
                                                            '-customer'
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={12} sm={3} />
                                                    <Grid item xs={12} sm={3}>
                                                        {getTextInput(
                                                            theme,
                                                            'redemptionOptions.customer.directDepositProcessingTimeMin',
                                                            'Min Time (direct deposit)',
                                                            formik,
                                                            'number',
                                                            getInfoIcon(
                                                                'Minimum Time Direct Deposit',
                                                                'Estimated minimum time to process direct deposit redemption',
                                                                theme
                                                            )
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={12} sm={3}>
                                                        {getTextInput(
                                                            theme,
                                                            'redemptionOptions.customer.directDepositProcessingTimeMax',
                                                            'Max Time (direct deposit)',
                                                            formik,
                                                            'number',
                                                            getInfoIcon(
                                                                'Max Time Direct Deposit',
                                                                'Estimated maximum time to process direct deposit redemption',
                                                                theme
                                                            )
                                                        )}
                                                    </Grid>
                                                </>
                                            )}
                                            {!isAUSRegion() && (
                                                <>
                                                    <Grid item xs={12}>
                                                        <Typography variant="subtitle1">Other Settings</Typography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={3}>
                                                        {getSwitch(
                                                            theme,
                                                            'redemptionOptions.customer.enableEtransferLimit',
                                                            'Enable Yearly e-Transfer Limit',
                                                            formik,
                                                            { paddingTop: theme.spacing.unit * 2 },
                                                            {},
                                                            undefined,
                                                            'Limit the total amount customers can redeem via e-Transfer in one year'
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={12} sm={3}>
                                                        {_.get(
                                                            formik.values,
                                                            'redemptionOptions.customer.enableEtransferLimit'
                                                        ) &&
                                                            getTextInput(
                                                                theme,
                                                                'redemptionOptions.customer.etransferLimit',
                                                                'Yearly Limit (e-transfer)',
                                                                formik,
                                                                'number',
                                                                getInfoIcon(
                                                                    'Yearly Limit e-Transfer',
                                                                    'Maximum amount customers can redeem via e-Transfer in one year',
                                                                    theme
                                                                )
                                                            )}
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} />
                                                </>
                                            )}
                                        </Grid>
                                    </CustomWrapper>
                                    <CustomWrapper
                                        title="Customer Time Frame Expectations"
                                        outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}
                                    >
                                        <Grid container spacing={theme.spacing.unit}>
                                            <Grid item xs={12} sm={3}>
                                                <Typography variant="subtitle1">Display</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={9}>
                                                <Typography variant="subtitle1">Text</Typography>
                                            </Grid>
                                            {!isAUSRegion() && (
                                                <>
                                                    <Grid item xs={12} sm={3}>
                                                        {getSwitch(
                                                            theme,
                                                            'redemptionOptions.customer.timeframeToggles.cheque',
                                                            'Cheque',
                                                            formik,
                                                            { paddingTop: theme.spacing.unit * 2 },
                                                            {},
                                                            undefined,
                                                            'Show time frame text for cheques'
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={12} sm={9}>
                                                        <LocalizedTextInput
                                                            label="Time frame (cheque)"
                                                            value={_.get(
                                                                formik,
                                                                'values.redemptionOptions.customer.timeframeText.cheque'
                                                            )}
                                                            onChange={value =>
                                                                formik.setFieldValue(
                                                                    'redemptionOptions.customer.timeframeText.cheque',
                                                                    value
                                                                )
                                                            }
                                                            style={{ marginTop: theme.spacing.unit }}
                                                            touched={_.get(
                                                                formik,
                                                                'touched.redemptionOptions.customer.timeframeText.cheque'
                                                            )}
                                                            error={_.get(
                                                                formik,
                                                                'errors.redemptionOptions.customer.timeframeText.cheque'
                                                            )}
                                                            infoText="Available dynamic fields: minHours, maxHours, minDays, maxDays, minWeeks, maxWeeks"
                                                        />
                                                    </Grid>
                                                </>
                                            )}

                                            {!isAUSRegion() && (
                                                <>
                                                    <Grid item xs={12} sm={3}>
                                                        {getSwitch(
                                                            theme,
                                                            'redemptionOptions.customer.timeframeToggles.bankTransfer',
                                                            loc('redemption6', lang),
                                                            formik,
                                                            { paddingTop: theme.spacing.unit * 2 },
                                                            {},
                                                            undefined,
                                                            'Show time frame text for bank transfer'
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={12} sm={9}>
                                                        <LocalizedTextInput
                                                            label="Time frame (bank transfer)"
                                                            value={_.get(
                                                                formik,
                                                                'values.redemptionOptions.customer.timeframeText.bankTransfer'
                                                            )}
                                                            onChange={value =>
                                                                formik.setFieldValue(
                                                                    'redemptionOptions.customer.timeframeText.bankTransfer',
                                                                    value
                                                                )
                                                            }
                                                            style={{ marginTop: theme.spacing.unit }}
                                                            touched={_.get(
                                                                formik,
                                                                'touched.redemptionOptions.customer.timeframeText.bankTransfer'
                                                            )}
                                                            error={_.get(
                                                                formik,
                                                                'errors.redemptionOptions.customer.timeframeText.bankTransfer'
                                                            )}
                                                            infoText="Available dynamic fields: minHours, maxHours, minDays, maxDays, minWeeks, maxWeeks"
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12} sm={3}>
                                                        {getSwitch(
                                                            theme,
                                                            'redemptionOptions.customer.timeframeToggles.etransfer',
                                                            loc('redemption4', lang),
                                                            formik,
                                                            { paddingTop: theme.spacing.unit * 2 },
                                                            {},
                                                            undefined,
                                                            'Show time frame text for e-transfer'
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={12} sm={9}>
                                                        <LocalizedTextInput
                                                            label="Time frame (e-transfer)"
                                                            value={_.get(
                                                                formik,
                                                                'values.redemptionOptions.customer.timeframeText.etransfer'
                                                            )}
                                                            onChange={value =>
                                                                formik.setFieldValue(
                                                                    'redemptionOptions.customer.timeframeText.etransfer',
                                                                    value
                                                                )
                                                            }
                                                            style={{ marginTop: theme.spacing.unit }}
                                                            touched={_.get(
                                                                formik,
                                                                'touched.redemptionOptions.customer.timeframeText.etransfer'
                                                            )}
                                                            error={_.get(
                                                                formik,
                                                                'errors.redemptionOptions.customer.timeframeText.etransfer'
                                                            )}
                                                            infoText="Available dynamic fields: minHours, maxHours, minDays, maxDays, minWeeks, maxWeeks"
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12} sm={3}>
                                                        {getSwitch(
                                                            theme,
                                                            'redemptionOptions.customer.timeframeToggles.cash',
                                                            loc('redemption7', lang),
                                                            formik,
                                                            { paddingTop: theme.spacing.unit * 2 },
                                                            {},
                                                            undefined,
                                                            'Show time frame text for cash'
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={12} sm={9}>
                                                        <LocalizedTextInput
                                                            label="Time frame (cash)"
                                                            value={_.get(
                                                                formik,
                                                                'values.redemptionOptions.customer.timeframeText.cash'
                                                            )}
                                                            onChange={value =>
                                                                formik.setFieldValue(
                                                                    'redemptionOptions.customer.timeframeText.cash',
                                                                    value
                                                                )
                                                            }
                                                            style={{ marginTop: theme.spacing.unit }}
                                                            touched={_.get(
                                                                formik,
                                                                'touched.redemptionOptions.customer.timeframeText.cash'
                                                            )}
                                                            error={_.get(
                                                                formik,
                                                                'errors.redemptionOptions.customer.timeframeText.cash'
                                                            )}
                                                            infoText="No dynamic fields available"
                                                        />
                                                    </Grid>
                                                </>
                                            )}

                                            {isAUSRegion() && (
                                                <>
                                                    <Grid item xs={12} sm={3}>
                                                        {getSwitch(
                                                            theme,
                                                            'redemptionOptions.customer.timeframeToggles.directDeposit',
                                                            'Direct Deposit',
                                                            formik,
                                                            { paddingTop: theme.spacing.unit * 2 },
                                                            {},
                                                            undefined,
                                                            'Show time frame text for direct deposit'
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={12} sm={9}>
                                                        <LocalizedTextInput
                                                            label="Time frame (direct deposit)"
                                                            value={_.get(
                                                                formik,
                                                                'values.redemptionOptions.customer.timeframeText.directDeposit'
                                                            )}
                                                            onChange={value =>
                                                                formik.setFieldValue(
                                                                    'redemptionOptions.customer.timeframeText.directDeposit',
                                                                    value
                                                                )
                                                            }
                                                            style={{ marginTop: theme.spacing.unit }}
                                                            touched={_.get(
                                                                formik,
                                                                'touched.redemptionOptions.customer.timeframeText.directDeposit'
                                                            )}
                                                            error={_.get(
                                                                formik,
                                                                'errors.redemptionOptions.customer.timeframeText.directDeposit'
                                                            )}
                                                            infoText="Available dynamic fields: minHours, maxHours, minDays, maxDays, minWeeks, maxWeeks"
                                                        />
                                                    </Grid>
                                                </>
                                            )}
                                        </Grid>
                                    </CustomWrapper>
                                    <CustomWrapper
                                        title="Charity"
                                        outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}
                                    >
                                        <Grid container spacing={theme.spacing.unit}>
                                            <Grid item xs={12} sm={3}>
                                                <Typography variant="subtitle1">Methods</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                <Typography variant="subtitle1">Minimum Value ($)</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                <Typography variant="subtitle1">Min Processing Time (Days)</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                <Typography variant="subtitle1">Max Processing Time (Days)</Typography>
                                            </Grid>

                                            {!isAUSRegion() && (
                                                <>
                                                    <Grid item xs={12} sm={3}>
                                                        {getSwitch(
                                                            theme,
                                                            'redemptionOptions.charity.methods.cheque',
                                                            'Cheque',
                                                            formik,
                                                            { paddingTop: theme.spacing.unit * 2 },
                                                            {},
                                                            undefined,
                                                            'Allow charities to redeem by cheque',
                                                            undefined,
                                                            undefined,
                                                            '-charity'
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={12} sm={3}>
                                                        {getTextInput(
                                                            theme,
                                                            'redemptionOptions.charity.chequeMinimum',
                                                            'Minimum (cheque)',
                                                            formik,
                                                            'number',
                                                            getInfoIcon(
                                                                'Minimum Cheque',
                                                                'Minimum balance required to redeem by cheque',
                                                                theme
                                                            )
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={12} sm={3}>
                                                        {getTextInput(
                                                            theme,
                                                            'redemptionOptions.charity.chequeProcessingTimeMin',
                                                            'Min Time (cheque)',
                                                            formik,
                                                            'number',
                                                            getInfoIcon(
                                                                'Min Time Cheque',
                                                                'Estimated minimum time required to process cheque redemption',
                                                                theme
                                                            )
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={12} sm={3}>
                                                        {getTextInput(
                                                            theme,
                                                            'redemptionOptions.charity.chequeProcessingTimeMax',
                                                            'Max Time (cheque)',
                                                            formik,
                                                            'number',
                                                            getInfoIcon(
                                                                'Max Time Cheque',
                                                                'Estimated maximum time required to process cheque redemption',
                                                                theme
                                                            )
                                                        )}
                                                    </Grid>
                                                </>
                                            )}

                                            {!isAUSRegion() && (
                                                <>
                                                    <Grid item xs={12} sm={3}>
                                                        {getSwitch(
                                                            theme,
                                                            'redemptionOptions.charity.methods.bankTransfer',
                                                            loc('redemption6', lang),
                                                            formik,
                                                            { paddingTop: theme.spacing.unit * 2 },
                                                            {},
                                                            undefined,
                                                            'Allow charities to redeem via bank transfer',
                                                            undefined,
                                                            undefined,
                                                            '-charity'
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={12} sm={3}>
                                                        {getTextInput(
                                                            theme,
                                                            'redemptionOptions.charity.bankTransferMinimum',
                                                            'Minimum (bank transfer)',
                                                            formik,
                                                            'number',
                                                            getInfoIcon(
                                                                'Minimum Bank Transfer',
                                                                'Minimum balance required to redeem via bank transfer',
                                                                theme
                                                            )
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={12} sm={3}>
                                                        {getTextInput(
                                                            theme,
                                                            'redemptionOptions.charity.bankTransferProcessingTimeMin',
                                                            'Min Time (bank transfer)',
                                                            formik,
                                                            'number',
                                                            getInfoIcon(
                                                                'Min Time Bank Transfer',
                                                                'Estimated minimum time required to process bank transfer redemption',
                                                                theme
                                                            )
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={12} sm={3}>
                                                        {getTextInput(
                                                            theme,
                                                            'redemptionOptions.charity.bankTransferProcessingTimeMax',
                                                            'Max Time (bank transfer)',
                                                            formik,
                                                            'number',
                                                            getInfoIcon(
                                                                'Max Time Bank Transfer',
                                                                'Estimated maximum time required to process bank transfer redemption',
                                                                theme
                                                            )
                                                        )}
                                                    </Grid>

                                                    <Grid item xs={12} sm={3}>
                                                        {getSwitch(
                                                            theme,
                                                            'redemptionOptions.charity.methods.etransfer',
                                                            loc('redemption4', lang),
                                                            formik,
                                                            { paddingTop: theme.spacing.unit * 2 },
                                                            {},
                                                            undefined,
                                                            'Allow charities to redeem via e-Transfer',
                                                            undefined,
                                                            undefined,
                                                            '-charity'
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={12} sm={3}>
                                                        {getTextInput(
                                                            theme,
                                                            'redemptionOptions.charity.eTransferMinimum',
                                                            'Minimum (e-transfer)',
                                                            formik,
                                                            'number',
                                                            getInfoIcon(
                                                                'Minimum e-Transfer',
                                                                'Minimum balance required to redeem via e-Transfer',
                                                                theme
                                                            )
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={12} sm={3}>
                                                        {getTextInput(
                                                            theme,
                                                            'redemptionOptions.charity.eTransferProcessingTimeMin',
                                                            'Min Time (e-transfer)',
                                                            formik,
                                                            'number',
                                                            getInfoIcon(
                                                                'Min Time e-Transfer',
                                                                'Estimated minimum time required to process e-Transfer redemption',
                                                                theme
                                                            )
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={12} sm={3}>
                                                        {getTextInput(
                                                            theme,
                                                            'redemptionOptions.charity.eTransferProcessingTimeMax',
                                                            'Max Time (e-transfer)',
                                                            formik,
                                                            'number',
                                                            getInfoIcon(
                                                                'Max Time e-Transfer',
                                                                'Estimated maximum time required to process e-Transfer redemption',
                                                                theme
                                                            )
                                                        )}
                                                    </Grid>

                                                    <Grid item xs={12} sm={3}>
                                                        {getSwitch(
                                                            theme,
                                                            'redemptionOptions.charity.methods.cash',
                                                            loc('redemption7', lang),
                                                            formik,
                                                            { paddingTop: theme.spacing.unit * 2 },
                                                            {},
                                                            undefined,
                                                            'Allow charities to redeem in cash',
                                                            undefined,
                                                            undefined,
                                                            '-charity'
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={12} sm={3}>
                                                        {getTextInput(
                                                            theme,
                                                            'redemptionOptions.charity.cashMinimum',
                                                            'Minimum (cash)',
                                                            formik,
                                                            'number',
                                                            getInfoIcon(
                                                                'Minimum Cash',
                                                                'Minimum balance required to redeem in cash',
                                                                theme
                                                            )
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} />
                                                </>
                                            )}
                                            {isAUSRegion() && (
                                                <>
                                                    <Grid item xs={12} sm={3}>
                                                        {getSwitch(
                                                            theme,
                                                            'redemptionOptions.charity.methods.directDeposit',
                                                            'Direct Deposit',
                                                            formik,
                                                            { paddingTop: theme.spacing.unit * 2 },
                                                            {},
                                                            undefined,
                                                            'Allow charities to redeem via direct deposit',
                                                            undefined,
                                                            undefined,
                                                            '-charity'
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={12} sm={3} />
                                                    <Grid item xs={12} sm={3}>
                                                        {getTextInput(
                                                            theme,
                                                            'redemptionOptions.charity.directDepositProcessingTimeMin',
                                                            'Min Time (direct deposit)',
                                                            formik,
                                                            'number',
                                                            getInfoIcon(
                                                                'Min Time Direct Deposit',
                                                                'Estimated minimum time required to process direct deposit redemption',
                                                                theme
                                                            )
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={12} sm={3}>
                                                        {getTextInput(
                                                            theme,
                                                            'redemptionOptions.charity.directDepositProcessingTimeMax',
                                                            'Max Time (direct deposit)',
                                                            formik,
                                                            'number',
                                                            getInfoIcon(
                                                                'Max Time Direct Deposit',
                                                                'Estimated maximum time required to process direct deposit redemption',
                                                                theme
                                                            )
                                                        )}
                                                    </Grid>
                                                </>
                                            )}
                                            {!isAUSRegion() && (
                                                <>
                                                    <Grid item xs={12}>
                                                        <Typography variant="subtitle1">Other Settings</Typography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={3}>
                                                        {getSwitch(
                                                            theme,
                                                            'redemptionOptions.charity.enableEtransferLimit',
                                                            'Enable Yearly e-Transfer Limit',
                                                            formik,
                                                            { paddingTop: theme.spacing.unit * 2 },
                                                            {},
                                                            undefined,
                                                            'Limit total amount charities can redeem via e-Transfer in one year'
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={12} sm={3}>
                                                        {_.get(
                                                            formik.values,
                                                            'redemptionOptions.charity.enableEtransferLimit'
                                                        ) &&
                                                            getTextInput(
                                                                theme,
                                                                'redemptionOptions.charity.etransferLimit',
                                                                'Yearly Limit (e-transfer)',
                                                                formik,
                                                                'number',
                                                                getInfoIcon(
                                                                    'Yearly Limit e-Transfer',
                                                                    'Maximum amount charities can redeem via e-Transfer in one year',
                                                                    theme
                                                                )
                                                            )}
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} />
                                                </>
                                            )}
                                        </Grid>
                                    </CustomWrapper>
                                </Grid>
                            </Grid>
                        )}
                        {selectedView === 'Registration' && (
                            <Grid container spacing={theme.spacing.unit}>
                                <Grid item xs={12} sm={6}>
                                    <CustomWrapper
                                        title="General"
                                        outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}
                                    >
                                        {getSwitch(
                                            theme,
                                            'registration.require2FABeforeCompletion',
                                            'Require 2FA Verification Before Registration Completion',
                                            formik,
                                            { marginTop: theme.spacing.unit * 2 },
                                            {},
                                            undefined,
                                            'Prevent new accounts from being created until 2FA succeeds.' +
                                                (_.get(formik.values, 'twoFactorAuthentication.isEnabled', true)
                                                    ? ''
                                                    : ' (requires 2FA to be enabled)'),
                                            !_.get(formik.values, 'twoFactorAuthentication.isEnabled', true)
                                        )}
                                        {getSwitch(
                                            theme,
                                            'registration.emailVerificationRequired',
                                            'Email Verification Required',
                                            formik,
                                            { marginTop: theme.spacing.unit * 2 }
                                        )}
                                        <TextField
                                            {...formik.getFieldProps('registration.questionnaire')}
                                            fullWidth
                                            label="Show How Did You Find Us"
                                            variant="outlined"
                                            select
                                            data-cy="registration-questionnaire-select"
                                            style={{ marginTop: theme.spacing.unit * 2, padding: 0 }}
                                        >
                                            <MenuItem value={''} data-cy="registration-questionnaire-select-none">
                                                <i>None</i>
                                            </MenuItem>
                                            {!_.isEmpty(questionnaires) &&
                                                questionnaires.map(questionnaire => (
                                                    <MenuItem
                                                        value={questionnaire._id}
                                                        data-cy={`registration-questionnaire-select-${_.get(
                                                            questionnaire,
                                                            'name'
                                                        )}`}
                                                    >
                                                        {questionnaire.name}
                                                    </MenuItem>
                                                ))}
                                        </TextField>
                                        {getSwitch(
                                            theme,
                                            'login.showThirdPartyLogins',
                                            'Show third party logins',
                                            formik,
                                            {
                                                marginTop: theme.spacing.unit * 2
                                            },
                                            {},
                                            undefined,
                                            'Display third party login options on Login and Registration pages'
                                        )}
                                        {getSwitch(
                                            theme,
                                            'registration.showPOBoxInput',
                                            'Show PO Box Input',
                                            formik,
                                            {
                                                marginTop: theme.spacing.unit * 2
                                            },
                                            {},
                                            undefined,
                                            'Allow users to enter a PO Box when registering for a new account'
                                        )}
                                        {getSwitch(
                                            theme,
                                            'registration.showSecurityQuestions',
                                            'Show Security Questions',
                                            formik,
                                            {
                                                marginTop: theme.spacing.unit * 2
                                            },
                                            {},
                                            undefined,
                                            'Require users to enter security questions/answers when registering for a new account'
                                        )}
                                        {getSwitch(
                                            theme,
                                            'registration.showRepeatPassword',
                                            'Show Repeat Password',
                                            formik,
                                            {
                                                marginTop: theme.spacing.unit * 2
                                            },
                                            {},
                                            undefined,
                                            'Require users to repeat their password when registering for a new account'
                                        )}
                                        {getSwitch(
                                            theme,
                                            'registration.showBusinessPhone',
                                            'Show Business Phone',
                                            formik,
                                            {
                                                marginTop: theme.spacing.unit * 2
                                            },
                                            {},
                                            undefined,
                                            'Require business users to enter a business phone number'
                                        )}
                                        {getSwitch(
                                            theme,
                                            'registration.showBusinessNumber',
                                            'Show Business Number',
                                            formik,
                                            {
                                                marginTop: theme.spacing.unit * 2
                                            },
                                            {},
                                            undefined,
                                            'Require business users to enter a business number'
                                        )}
                                        {getSwitch(
                                            theme,
                                            'registration.showSchemeID',
                                            'Show Scheme/Member ID',
                                            formik,
                                            {
                                                marginTop: theme.spacing.unit * 2
                                            },
                                            {},
                                            undefined,
                                            'Allow users to enter Scheme/Member ID for a new account'
                                        )}
                                    </CustomWrapper>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <CustomWrapper
                                        title="Serviceable Area Link"
                                        outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}
                                    >
                                        {getTextInput(
                                            theme,
                                            'serviceableAreaLink.link',
                                            'Serviceable Area Link',
                                            formik,
                                            'text',
                                            getInfoIcon(
                                                'Serviceable Area Link',
                                                'URL of webpage informing users of the current serviceable area',
                                                theme
                                            )
                                        )}
                                        {getSwitch(
                                            theme,
                                            'serviceableAreaLink.isEnabled',
                                            'Show Link',
                                            formik,
                                            {},
                                            {},
                                            undefined,
                                            'Display link to webpage informing users of the current serviceable area'
                                        )}
                                        {getTextInput(
                                            theme,
                                            'serviceableAreaLink.text',
                                            'Link Text',
                                            formik,
                                            'text',
                                            getInfoIcon(
                                                'Link Text',
                                                'Text of link to webpage informing users of the current serviceable area',
                                                theme
                                            )
                                        )}
                                    </CustomWrapper>
                                    <CustomWrapper
                                        title="Registration Types"
                                        outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}
                                    >
                                        {getSwitch(
                                            theme,
                                            'registration.options.regCustomer',
                                            'Show Register Customer',
                                            formik,
                                            { marginTop: theme.spacing.unit * 2 }
                                        )}
                                        {getSwitch(
                                            theme,
                                            'registration.options.regBusiness',
                                            'Show Register Business',
                                            formik,
                                            { marginTop: theme.spacing.unit * 2 }
                                        )}
                                        {getSwitch(
                                            theme,
                                            'registration.options.regOrganization',
                                            'Show Register Organization',
                                            formik,
                                            { marginTop: theme.spacing.unit * 2 }
                                        )}
                                        {isEXPRegion() &&
                                            getSwitch(
                                                theme,
                                                'registration.options.regGroup',
                                                'Show Register Group',
                                                formik,
                                                { marginTop: theme.spacing.unit * 2 }
                                            )}
                                        {getSwitch(
                                            theme,
                                            'registration.options.bottleDrive',
                                            'Show Register Bottle Drive',
                                            formik,
                                            { marginTop: theme.spacing.unit * 2 }
                                        )}
                                        {getSwitch(
                                            theme,
                                            'registration.options.regCollectionProgram',
                                            'Show Register Collection Program',
                                            formik,
                                            { marginTop: theme.spacing.unit * 2 },
                                            {},
                                            () => {},
                                            !_.get(formik.values, 'enabledFunctions.collectionProgram', false) &&
                                                'Collection Program is disabled. Cant enabled Collection Registration.',
                                            !_.get(formik.values, 'enabledFunctions.collectionProgram', false)
                                        )}
                                    </CustomWrapper>
                                </Grid>
                            </Grid>
                        )}
                        {selectedView === 'Share, Feedback, and Referrals' && (
                            <Grid container spacing={theme.spacing.unit}>
                                <Grid item xs={12} sm={6}>
                                    <CustomWrapper
                                        title="Screens"
                                        outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}
                                    >
                                        <Typography variant="body1" style={{ marginTop: theme.spacing.unit * 2 }}>
                                            Rating Screen
                                        </Typography>
                                        {getTextInput(
                                            theme,
                                            'shareAndFeedbackDialogConfig.ratingScreen.ordering',
                                            'Ordering No',
                                            formik,
                                            'number',
                                            getInfoIcon(
                                                'Rating Ordering No',
                                                'Order of the rating screen when prompting the user to share and give feedback',
                                                theme
                                            )
                                        )}
                                        {getSwitch(
                                            theme,
                                            'shareAndFeedbackDialogConfig.ratingScreen.enabled',
                                            'Enabled',
                                            formik,
                                            {},
                                            {},
                                            undefined,
                                            'Display the rating screen when prompting the user to share and give feedback'
                                        )}
                                        <Typography variant="body1" style={{ marginTop: theme.spacing.unit * 2 }}>
                                            Referral Screen
                                        </Typography>
                                        {getTextInput(
                                            theme,
                                            'shareAndFeedbackDialogConfig.referralScreen.ordering',
                                            'Ordering No',
                                            formik,
                                            'number',
                                            getInfoIcon(
                                                'Referral Ordering No',
                                                'Order of the referral screen when prompting the user to share and give feedback',
                                                theme
                                            )
                                        )}
                                        {getSwitch(
                                            theme,
                                            'shareAndFeedbackDialogConfig.referralScreen.enabled',
                                            'Enabled',
                                            formik,
                                            {},
                                            {},
                                            undefined,
                                            'Display the referral screen when prompting the user to share and give feedback'
                                        )}
                                        <Typography variant="body1" style={{ marginTop: theme.spacing.unit * 2 }}>
                                            Share Screen
                                        </Typography>
                                        {getTextInput(
                                            theme,
                                            'shareAndFeedbackDialogConfig.shareScreen.ordering',
                                            'Ordering No',
                                            formik,
                                            'number',
                                            getInfoIcon(
                                                'Share Screen Ordering No',
                                                'Order of the share screen when prompting the user to share and give feedback',
                                                theme
                                            )
                                        )}
                                        {getSwitch(
                                            theme,
                                            'shareAndFeedbackDialogConfig.shareScreen.enabled',
                                            'Enabled',
                                            formik,
                                            {},
                                            {},
                                            e => {
                                                formik.setFieldValue('impactReport.showImpactReport', e.target.checked);
                                            },
                                            'Display the share screen when prompting the user to share and give feedback'
                                        )}
                                    </CustomWrapper>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <CustomWrapper
                                        title="Referral Promo Configuration"
                                        outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}
                                    >
                                        {getTextInput(
                                            theme,
                                            'promoCodeCustomer.customerSplit',
                                            'Referee Refund Percentage',
                                            formik,
                                            'number',
                                            getInfoIcon(
                                                'Referee Refund Percentage',
                                                'Percentage of refund that goes to referee',
                                                theme
                                            )
                                        )}
                                        {getTextInput(
                                            theme,
                                            'promoCodeCustomer.fixedRateKickback',
                                            'Kickback Amount($)',
                                            formik,
                                            'number',
                                            getInfoIcon(
                                                'Kickback Amount',
                                                'Fixed kickback amount that goes to the referee',
                                                theme
                                            )
                                        )}
                                        {getTextInput(
                                            theme,
                                            'promoCodeCustomer.kickbackThreshold',
                                            'Kickback Threshold($)',
                                            formik,
                                            'number',
                                            getInfoIcon(
                                                'Kickback Threshold',
                                                'Count value threshold for promo to be applied',
                                                theme
                                            )
                                        )}
                                    </CustomWrapper>
                                    <CustomWrapper
                                        title="Refund Summary Configuration"
                                        outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}
                                    >
                                        {getSwitch(
                                            theme,
                                            'refundSummaryConfig.includeFeesText',
                                            'Include Fees Text',
                                            formik,
                                            {},
                                            {},
                                            undefined,
                                            'Display the fees text ($ less fees of $) on the refund summary screen'
                                        )}
                                    </CustomWrapper>
                                </Grid>
                            </Grid>
                        )}
                        {selectedView === 'Theme' && (
                            <Grid container spacing={theme.spacing.unit}>
                                <Grid item xs={12} sm={6}>
                                    <CustomWrapper
                                        title="Light"
                                        outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}
                                    >
                                        <div style={{ display: 'flex' }}>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={_.get(colorOverrides, 'light.primary', false)}
                                                        onChange={e =>
                                                            handleColorOverride('light.primary', e.target.checked)
                                                        }
                                                        style={{ marginRight: 0 }}
                                                    />
                                                }
                                                label={
                                                    <Typography variant="body2" style={{ fontSize: '85%' }}>
                                                        Override
                                                    </Typography>
                                                }
                                                style={{ marginLeft: theme.spacing.unit }}
                                            />

                                            {colorOverrides.light.primary ? (
                                                <TextField
                                                    type="color"
                                                    onChange={handleChangeColorWrapper}
                                                    value={
                                                        formik.values.theme.light.primary ||
                                                        _.get(lightTheme, 'palette.primary.main')
                                                    }
                                                    onBlur={formik.handleBlur}
                                                    name="light.primary"
                                                    error={
                                                        _.get(formik.touched, 'theme.light.primary') &&
                                                        _.get(formik.errors, 'theme.light.primary')
                                                            ? true
                                                            : null
                                                    }
                                                    label="Primary Color"
                                                    margin="normal"
                                                    variant="outlined"
                                                    helperText={
                                                        _.get(formik.touched, 'theme.light.primary') &&
                                                        _.get(formik.errors, 'theme.light.primary')
                                                            ? _.get(formik.errors, 'theme.light.primary')
                                                            : null
                                                    }
                                                    fullWidth
                                                />
                                            ) : (
                                                <TextField
                                                    type="color"
                                                    value={lightTheme.palette.primary.main}
                                                    onBlur={formik.handleBlur}
                                                    label="Primary Default"
                                                    margin="normal"
                                                    variant="outlined"
                                                    fullWidth
                                                    disabled
                                                />
                                            )}
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={_.get(colorOverrides, 'light.secondary', false)}
                                                        onChange={e =>
                                                            handleColorOverride('light.secondary', e.target.checked)
                                                        }
                                                        style={{ marginRight: 0 }}
                                                    />
                                                }
                                                label={
                                                    <Typography variant="body2" style={{ fontSize: '85%' }}>
                                                        Override
                                                    </Typography>
                                                }
                                                style={{ marginLeft: theme.spacing.unit }}
                                            />

                                            {colorOverrides.light.secondary ? (
                                                <TextField
                                                    type="color"
                                                    onChange={handleChangeColorWrapper}
                                                    value={
                                                        formik.values.theme.light.secondary ||
                                                        _.get(lightTheme, 'palette.secondary.main')
                                                    }
                                                    onBlur={formik.handleBlur}
                                                    name="light.secondary"
                                                    error={
                                                        _.get(formik.touched, 'theme.light.secondary') &&
                                                        _.get(formik.errors, 'theme.light.secondary')
                                                            ? true
                                                            : null
                                                    }
                                                    label="Secondary Color"
                                                    margin="normal"
                                                    variant="outlined"
                                                    helperText={
                                                        _.get(formik.touched, 'theme.light.secondary') &&
                                                        _.get(formik.errors, 'theme.light.secondary')
                                                            ? _.get(formik.errors, 'theme.light.secondary')
                                                            : null
                                                    }
                                                    fullWidth
                                                />
                                            ) : (
                                                <TextField
                                                    type="color"
                                                    value={lightTheme.palette.secondary.main}
                                                    onBlur={formik.handleBlur}
                                                    label="Secondary Default"
                                                    margin="normal"
                                                    variant="outlined"
                                                    fullWidth
                                                    disabled
                                                />
                                            )}
                                        </div>
                                    </CustomWrapper>
                                    <CustomWrapper
                                        title="Dark"
                                        outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}
                                    >
                                        <div style={{ display: 'flex' }}>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={_.get(colorOverrides, 'dark.primary', false)}
                                                        onChange={e =>
                                                            handleColorOverride('dark.primary', e.target.checked)
                                                        }
                                                        style={{ marginRight: 0 }}
                                                    />
                                                }
                                                label={
                                                    <Typography variant="body2" style={{ fontSize: '85%' }}>
                                                        Override
                                                    </Typography>
                                                }
                                                style={{ marginLeft: theme.spacing.unit }}
                                            />

                                            {colorOverrides.dark.primary ? (
                                                <TextField
                                                    type="color"
                                                    onChange={handleChangeColorWrapper}
                                                    value={
                                                        formik.values.theme.dark.primary ||
                                                        _.get(darkTheme, 'palette.primary.main')
                                                    }
                                                    onBlur={formik.handleBlur}
                                                    name="dark.primary"
                                                    error={
                                                        _.get(formik.touched, 'theme.dark.primary') &&
                                                        _.get(formik.errors, 'theme.dark.primary')
                                                            ? true
                                                            : null
                                                    }
                                                    label="Primary Color"
                                                    margin="normal"
                                                    variant="outlined"
                                                    helperText={
                                                        _.get(formik.touched, 'theme.dark.primary') &&
                                                        _.get(formik.errors, 'theme.dark.primary')
                                                            ? _.get(formik.errors, 'theme.dark.primary')
                                                            : null
                                                    }
                                                    fullWidth
                                                />
                                            ) : (
                                                <TextField
                                                    type="color"
                                                    value={darkTheme.palette.primary.main}
                                                    onBlur={formik.handleBlur}
                                                    label="Primary Default"
                                                    margin="normal"
                                                    variant="outlined"
                                                    fullWidth
                                                    disabled
                                                />
                                            )}
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={_.get(colorOverrides, 'dark.secondary', false)}
                                                        onChange={e =>
                                                            handleColorOverride('dark.secondary', e.target.checked)
                                                        }
                                                        style={{ marginRight: 0 }}
                                                    />
                                                }
                                                label={
                                                    <Typography variant="body2" style={{ fontSize: '85%' }}>
                                                        Override
                                                    </Typography>
                                                }
                                                style={{ marginLeft: theme.spacing.unit }}
                                            />

                                            {colorOverrides.dark.secondary ? (
                                                <TextField
                                                    type="color"
                                                    onChange={handleChangeColorWrapper}
                                                    value={
                                                        formik.values.theme.dark.secondary ||
                                                        _.get(darkTheme, 'palette.secondary.main')
                                                    }
                                                    onBlur={formik.handleBlur}
                                                    name="dark.secondary"
                                                    error={
                                                        _.get(formik.touched, 'theme.dark.secondary') &&
                                                        _.get(formik.errors, 'theme.dark.secondary')
                                                            ? true
                                                            : null
                                                    }
                                                    label="Secondary Color"
                                                    margin="normal"
                                                    variant="outlined"
                                                    helperText={
                                                        _.get(formik.touched, 'theme.dark.secondary') &&
                                                        _.get(formik.errors, 'theme.dark.secondary')
                                                            ? _.get(formik.errors, 'theme.dark.secondary')
                                                            : null
                                                    }
                                                    fullWidth
                                                />
                                            ) : (
                                                <TextField
                                                    type="color"
                                                    value={darkTheme.palette.secondary.main}
                                                    onBlur={formik.handleBlur}
                                                    label="Secondary Default"
                                                    margin="normal"
                                                    variant="outlined"
                                                    fullWidth
                                                    disabled
                                                />
                                            )}
                                        </div>
                                    </CustomWrapper>
                                    <CustomWrapper
                                        title="Logo"
                                        outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}
                                    >
                                        {getSwitch(theme, 'brandLogo.override', 'Override Brand Logo', formik)}
                                        <Collapse in={formik.values.brandLogo.override}>
                                            {_.isNil(brandLogoFile) && _.isNil(formik.values.brandLogo.url) ? (
                                                <FormControl fullWidth data-cy="upload-brand-logo-input">
                                                    <input
                                                        accept={'image/*'}
                                                        style={{ display: 'none' }}
                                                        id="raised-button-file-brand-logo"
                                                        multiple={false}
                                                        type="file"
                                                        onChange={handleBrandLogoFile}
                                                    />
                                                    <label htmlFor="raised-button-file-brand-logo">
                                                        <Button
                                                            color="primary"
                                                            size="small"
                                                            variant="outlined"
                                                            component="span"
                                                            style={{ width: '100%', marginTop: theme.spacing.unit }}
                                                        >
                                                            Upload Image
                                                        </Button>
                                                    </label>
                                                </FormControl>
                                            ) : (
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'center'
                                                    }}
                                                >
                                                    {!_.isNil(brandLogoFile) ? (
                                                        <img
                                                            src={URL.createObjectURL(brandLogoFile)}
                                                            style={{ maxWidth: '100%' }}
                                                            alt="Drop Location Marker"
                                                        />
                                                    ) : !brandImageError &&
                                                      imageFileTypes.includes(
                                                          formik.values.brandLogo.url.split('.').pop()
                                                      ) ? (
                                                        <img
                                                            src={formik.values.brandLogo.url}
                                                            alt="charity-high-resolution-logo"
                                                            onError={() => setBrandImageError(true)}
                                                            style={{
                                                                maxWidth: '128px',
                                                                maxHeight: '128px'
                                                            }}
                                                        />
                                                    ) : (
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                alignItems: 'center'
                                                            }}
                                                        >
                                                            <Icon fontSize="large" style={{ color: colors.green[500] }}>
                                                                check_circle
                                                            </Icon>
                                                            <Typography
                                                                style={{
                                                                    color: colors.green[500],
                                                                    margin: theme.spacing.unit * 2,
                                                                    textAlign: 'center'
                                                                }}
                                                                variant="subtitle2"
                                                            >
                                                                Image Uploaded
                                                            </Typography>
                                                        </div>
                                                    )}
                                                    <FormControl fullWidth data-cy="upload-brand-logo-input">
                                                        <input
                                                            accept={'image/*'}
                                                            style={{ display: 'none' }}
                                                            id="raised-button-file-brand-logo"
                                                            multiple={false}
                                                            type="file"
                                                            onChange={handleBrandLogoFile}
                                                        />
                                                        <label htmlFor="raised-button-file-brand-logo">
                                                            <Button
                                                                color="primary"
                                                                size="small"
                                                                variant="outlined"
                                                                component="span"
                                                                style={{
                                                                    width: '100%',
                                                                    marginTop: theme.spacing.unit
                                                                }}
                                                            >
                                                                Replace Image
                                                            </Button>
                                                        </label>
                                                    </FormControl>
                                                </div>
                                            )}
                                        </Collapse>
                                    </CustomWrapper>
                                    {(isAUSRegion() || isSTDRegion()) && (
                                        <CustomWrapper
                                            title="Collector Icon"
                                            outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}
                                        >
                                            <div style={{ display: 'flex' }}>
                                                <MDIcon
                                                    path={
                                                        allMdiIcons[
                                                            convertToImportName(formik.values.theme.technologyLogo)
                                                        ]
                                                    }
                                                    size={'50%'}
                                                    color={theme.palette.primary.main}
                                                    style={{
                                                        marginTop: theme.spacing.unit * 2,
                                                        maxWidth: '50px',
                                                        maxHeight: '50px',
                                                        marginRight: theme.spacing.unit
                                                    }}
                                                />

                                                {getTextInput(
                                                    theme,
                                                    'theme.technologyLogo',
                                                    'Technology Collector',
                                                    formik,
                                                    'text',
                                                    getInfoIcon(
                                                        'Override Technology Logo',
                                                        'Custom Logo for collectors with Technology',
                                                        theme
                                                    )
                                                )}
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                                <MDIcon
                                                    path={
                                                        allMdiIcons[
                                                            convertToImportName(formik.values.theme.noTechnologyLogo)
                                                        ]
                                                    }
                                                    size={'50%'}
                                                    color={theme.palette.primary.main}
                                                    style={{
                                                        marginTop: theme.spacing.unit * 2,
                                                        maxWidth: '50px',
                                                        maxHeight: '50px',
                                                        marginRight: theme.spacing.unit
                                                    }}
                                                />

                                                {getTextInput(
                                                    theme,
                                                    'theme.noTechnologyLogo',
                                                    'No Technology Collector',
                                                    formik,
                                                    'text',
                                                    getInfoIcon(
                                                        'Override No Technology Logo',
                                                        'Custom Logo for collectors with No Technology',
                                                        theme
                                                    )
                                                )}
                                            </div>
                                        </CustomWrapper>
                                    )}

                                    <CustomWrapper
                                        title="Pin Background Style"
                                        outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}
                                    >
                                        <FormControl fullWidth style={{ marginBottom: theme.spacing.unit * 3 }}>
                                            <TextField
                                                {...formik.getFieldProps('theme.pinLabelStyle')}
                                                fullWidth
                                                // label="Outline/ Solid"
                                                variant="outlined"
                                                select
                                                style={{ marginTop: theme.spacing.unit }}
                                            >
                                                <MenuItem value="outline">Outline</MenuItem>
                                                <MenuItem value="solid">Solid</MenuItem>
                                            </TextField>
                                        </FormControl>
                                    </CustomWrapper>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <CustomWrapper
                                        title="Admin"
                                        outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}
                                    >
                                        <div style={{ display: 'flex' }}>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={_.get(colorOverrides, 'admin.primary', false)}
                                                        onChange={e =>
                                                            handleColorOverride('admin.primary', e.target.checked)
                                                        }
                                                        style={{ marginRight: 0 }}
                                                    />
                                                }
                                                label={
                                                    <Typography variant="body2" style={{ fontSize: '85%' }}>
                                                        Override
                                                    </Typography>
                                                }
                                                style={{ marginLeft: theme.spacing.unit }}
                                            />

                                            {colorOverrides.admin.primary ? (
                                                <TextField
                                                    type="color"
                                                    onChange={handleChangeColorWrapper}
                                                    value={
                                                        formik.values.theme.admin.primary ||
                                                        _.get(adminTheme, 'palette.primary.main')
                                                    }
                                                    onBlur={formik.handleBlur}
                                                    name="admin.primary"
                                                    error={
                                                        _.get(formik.touched, 'theme.admin.primary') &&
                                                        _.get(formik.errors, 'theme.admin.primary')
                                                            ? true
                                                            : null
                                                    }
                                                    label="Primary Color"
                                                    margin="normal"
                                                    variant="outlined"
                                                    helperText={
                                                        _.get(formik.touched, 'theme.admin.primary') &&
                                                        _.get(formik.errors, 'theme.admin.primary')
                                                            ? _.get(formik.errors, 'theme.admin.primary')
                                                            : null
                                                    }
                                                    fullWidth
                                                />
                                            ) : (
                                                <TextField
                                                    type="color"
                                                    value={adminTheme.palette.primary.main}
                                                    onBlur={formik.handleBlur}
                                                    label="Primary Default"
                                                    margin="normal"
                                                    variant="outlined"
                                                    fullWidth
                                                    disabled
                                                />
                                            )}
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={_.get(colorOverrides, 'admin.secondary', false)}
                                                        onChange={e =>
                                                            handleColorOverride('admin.secondary', e.target.checked)
                                                        }
                                                        style={{ marginRight: 0 }}
                                                    />
                                                }
                                                label={
                                                    <Typography variant="body2" style={{ fontSize: '85%' }}>
                                                        Override
                                                    </Typography>
                                                }
                                                style={{ marginLeft: theme.spacing.unit }}
                                            />

                                            {colorOverrides.admin.secondary ? (
                                                <TextField
                                                    type="color"
                                                    onChange={handleChangeColorWrapper}
                                                    value={
                                                        formik.values.theme.admin.secondary ||
                                                        _.get(adminTheme, 'palette.secondary.main')
                                                    }
                                                    onBlur={formik.handleBlur}
                                                    name="admin.secondary"
                                                    error={
                                                        _.get(formik.touched, 'theme.admin.secondary') &&
                                                        _.get(formik.errors, 'theme.admin.secondary')
                                                            ? true
                                                            : null
                                                    }
                                                    label="Secondary Color"
                                                    margin="normal"
                                                    variant="outlined"
                                                    helperText={
                                                        _.get(formik.touched, 'theme.admin.secondary') &&
                                                        _.get(formik.errors, 'theme.admin.secondary')
                                                            ? _.get(formik.errors, 'theme.admin.secondary')
                                                            : null
                                                    }
                                                    fullWidth
                                                />
                                            ) : (
                                                <TextField
                                                    type="color"
                                                    value={adminTheme.palette.secondary.main}
                                                    onBlur={formik.handleBlur}
                                                    label="Secondary Default"
                                                    margin="normal"
                                                    variant="outlined"
                                                    fullWidth
                                                    disabled
                                                />
                                            )}
                                        </div>
                                    </CustomWrapper>

                                    <Grid item xs={12} sm={12}>
                                        <CustomWrapper title="Charity Pin Color" outerDivStyle={{}}>
                                            <div style={{ display: 'flex' }}>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            checked={_.get(colorOverrides, 'charityPin', false)}
                                                            onChange={e =>
                                                                handleColorOverride('charityPin', e.target.checked)
                                                            }
                                                            style={{ marginRight: 0 }}
                                                        />
                                                    }
                                                    label={
                                                        <Typography variant="body2" style={{ fontSize: '85%' }}>
                                                            Override
                                                        </Typography>
                                                    }
                                                    style={{ marginLeft: theme.spacing.unit }}
                                                />

                                                {colorOverrides.charityPin ? (
                                                    <TextField
                                                        type="color"
                                                        onChange={handleChangeColorWrapper}
                                                        value={
                                                            formik.values.theme.charityPin ||
                                                            _.get(markerPinTheme, 'charityPin')
                                                        }
                                                        onBlur={formik.handleBlur}
                                                        name="charityPin"
                                                        error={
                                                            _.get(formik.touched, 'theme.charityPin') &&
                                                            _.get(formik.errors, 'theme.charityPin')
                                                                ? true
                                                                : null
                                                        }
                                                        label="Primary Color"
                                                        margin="normal"
                                                        variant="outlined"
                                                        helperText={
                                                            _.get(formik.touched, 'theme.charityPin') &&
                                                            _.get(formik.errors, 'theme.charityPin')
                                                                ? _.get(formik.errors, 'theme.charityPin')
                                                                : null
                                                        }
                                                        fullWidth
                                                    />
                                                ) : (
                                                    <TextField
                                                        type="color"
                                                        value={markerPinTheme.charityPin}
                                                        onBlur={formik.handleBlur}
                                                        label="Primary Default"
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        disabled
                                                    />
                                                )}
                                            </div>
                                        </CustomWrapper>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <CustomWrapper title="Scheme User Pin Color" outerDivStyle={{}}>
                                            <div style={{ display: 'flex' }}>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            checked={_.get(colorOverrides, 'schemeUserPin', false)}
                                                            onChange={e =>
                                                                handleColorOverride('schemeUserPin', e.target.checked)
                                                            }
                                                            style={{ marginRight: 0 }}
                                                        />
                                                    }
                                                    label={
                                                        <Typography variant="body2" style={{ fontSize: '85%' }}>
                                                            Override
                                                        </Typography>
                                                    }
                                                    style={{ marginLeft: theme.spacing.unit }}
                                                />

                                                {colorOverrides.schemeUserPin ? (
                                                    <TextField
                                                        type="color"
                                                        onChange={handleChangeColorWrapper}
                                                        value={
                                                            formik.values.theme.schemeUserPin ||
                                                            _.get(markerPinTheme, 'schemeUserPin')
                                                        }
                                                        onBlur={formik.handleBlur}
                                                        name="schemeUserPin"
                                                        error={
                                                            _.get(formik.touched, 'theme.schemeUserPin') &&
                                                            _.get(formik.errors, 'theme.schemeUserPin')
                                                                ? true
                                                                : null
                                                        }
                                                        label="Primary Color"
                                                        margin="normal"
                                                        variant="outlined"
                                                        helperText={
                                                            _.get(formik.touched, 'theme.schemeUserPin') &&
                                                            _.get(formik.errors, 'theme.schemeUserPin')
                                                                ? _.get(formik.errors, 'theme.schemeUserPin')
                                                                : null
                                                        }
                                                        fullWidth
                                                    />
                                                ) : (
                                                    <TextField
                                                        type="color"
                                                        value={markerPinTheme.schemeUserPin}
                                                        onBlur={formik.handleBlur}
                                                        label="Primary Default"
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        disabled
                                                    />
                                                )}
                                            </div>
                                        </CustomWrapper>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                        {selectedView === 'ETAs' && (
                            <Grid container spacing={theme.spacing.unit}>
                                <Grid item xs={12} sm={6}>
                                    <CustomWrapper
                                        title="ETA Variables"
                                        outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}
                                    >
                                        <Grid container spacing={theme.spacing.unit}>
                                            <Grid item xs={12} sm={6}>
                                                {getTextInput(
                                                    theme,
                                                    'trips.stopBaseDuration',
                                                    'Default ETA Pickup Base Duration (sec)',
                                                    formik,
                                                    'number',
                                                    getInfoIcon(
                                                        'Default ETA Pickup Base Duration',
                                                        'Default estimated base stop duration per pickup used to calculate ETA values',
                                                        theme
                                                    )
                                                )}
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                {getTextInput(
                                                    theme,
                                                    'trips.stopPerBagDuration',
                                                    'Default ETA Pickup Per Bag Duration (sec/bag)',
                                                    formik,
                                                    'number',
                                                    getInfoIcon(
                                                        'Default ETA Pickup Per Bag Duration',
                                                        'Default estimated per bag time used to calculate ETA values',
                                                        theme
                                                    )
                                                )}
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                {getTextInput(
                                                    theme,
                                                    'trips.dropOffBaseDuration',
                                                    'ETA Drop off Base Duration (sec)',
                                                    formik,
                                                    'number',
                                                    getInfoIcon(
                                                        'ETA Drop off Base Duration',
                                                        'Default estimated base duration for unloading bags at the depot',
                                                        theme
                                                    )
                                                )}
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                {getTextInput(
                                                    theme,
                                                    'trips.dropOffPerBagDuration',
                                                    'ETA Drop off Per Bag Duration (sec/bag)',
                                                    formik,
                                                    'number',
                                                    getInfoIcon(
                                                        'ETA Drop off Per Bag Duration',
                                                        'Default estimated per bag time for unloading bags at the depot',
                                                        theme
                                                    )
                                                )}
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                {getTextInput(
                                                    theme,
                                                    'trips.etaOptimizationWindow',
                                                    'ETA Variable Moving Average Window (months)',
                                                    formik,
                                                    'number',
                                                    getInfoIcon(
                                                        'ETA Variable Moving Average Window',
                                                        'Period of time to include when attempting to calculate optimal ETA variables for each driver',
                                                        theme
                                                    )
                                                )}
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                {getTextInput(
                                                    theme,
                                                    'trips.etaUpdateDataPointScaleValue',
                                                    'Min Data Points for Full ETA Update',
                                                    formik,
                                                    'number',
                                                    getInfoIcon(
                                                        'Min Data Points for Full ETA Update',
                                                        'Minimum number of pickup data points needed for reliable ETA variable optimization',
                                                        theme
                                                    )
                                                )}
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                {getTextInput(
                                                    theme,
                                                    'trips.etaUpdateLimitThresholdPercentage',
                                                    'Threshold Change to Limit ETA Update (%)',
                                                    formik,
                                                    'number',
                                                    getInfoIcon(
                                                        'Threshold Change to Limit ETA Update',
                                                        'Threshold of change beyond which the new ETA variable are limited by the number of available data points',
                                                        theme
                                                    )
                                                )}
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                {getTextInput(
                                                    theme,
                                                    'trips.etaUpdateImprovementPercentage',
                                                    'ETA Target Reduction (%)',
                                                    formik,
                                                    'number',
                                                    getInfoIcon(
                                                        'ETA Target Reduction',
                                                        'Fixed percent reduction in ETA optimization to encourage faster collection times',
                                                        theme
                                                    )
                                                )}
                                            </Grid>
                                            <Grid item xs={12}>
                                                {getSwitch(
                                                    theme,
                                                    'trips.segregateETAVariablesByZone',
                                                    'Segregate ETA Variables By Zone',
                                                    formik,
                                                    {},
                                                    {},
                                                    undefined,
                                                    'Segregate pickup times by zone in order to calculate optimal per zone ETA variable values'
                                                )}
                                            </Grid>
                                        </Grid>
                                    </CustomWrapper>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <CustomWrapper
                                        title="ETA Notifications"
                                        outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}
                                    >
                                        {getTextInput(
                                            theme,
                                            'trips.etaNotificationWindow',
                                            'Pickup ETA Window (min)',
                                            formik,
                                            'number',
                                            getInfoIcon(
                                                'Pickup ETA Window',
                                                'Window of time on either side of calculated ETA shown to customer',
                                                theme
                                            )
                                        )}
                                        {getSwitch(
                                            theme,
                                            'trips.useEtaWindow',
                                            'Use ETA Window in Customer Communication',
                                            formik,
                                            {},
                                            {},
                                            undefined,
                                            'Show customer ETA window instead of specific calculated ETA'
                                        )}

                                        {getTextInput(
                                            theme,
                                            'trips.maxCustomerEtaNotifications',
                                            'Max ETA Update Notifications',
                                            formik,
                                            'number',
                                            getInfoIcon(
                                                'Max ETA Update Notifications',
                                                'Maximum number of times to notify a customer about changes to their ETA',
                                                theme
                                            )
                                        )}
                                        {getSwitch(
                                            theme,
                                            'trips.customerEtaNotifications',
                                            'Send ETA Updates',
                                            formik,
                                            {},
                                            {},
                                            undefined,
                                            'Notify customer when their ETA changes'
                                        )}

                                        {getTimePicker(
                                            theme,
                                            'trips.defaultEtaStartTime',
                                            'Default ETA Start Hour',
                                            formik,
                                            moment(formik.values.trips.defaultEtaStartTime).tz(
                                                process.env.REACT_APP_REGION_TIMEZONE
                                            ),
                                            time => {
                                                const hour = time.get('hour');
                                                const minute = time.get('minute');
                                                formik.setFieldValue(
                                                    'trips.defaultEtaStartTime',
                                                    moment()
                                                        .tz(process.env.REACT_APP_REGION_TIMEZONE)
                                                        .set({ hour, minute })
                                                        .toDate()
                                                );
                                            }
                                        )}

                                        {getTimePicker(
                                            theme,
                                            'trips.defaultEtaEndTime',
                                            'Default ETA End Hour',
                                            formik,
                                            moment(formik.values.trips.defaultEtaEndTime).tz(
                                                process.env.REACT_APP_REGION_TIMEZONE
                                            ),
                                            time => {
                                                const hour = time.get('hour');
                                                const minute = time.get('minute');
                                                formik.setFieldValue(
                                                    'trips.defaultEtaEndTime',
                                                    moment()
                                                        .tz(process.env.REACT_APP_REGION_TIMEZONE)
                                                        .set({ hour, minute })
                                                        .toDate()
                                                );
                                            }
                                        )}
                                    </CustomWrapper>
                                </Grid>
                            </Grid>
                        )}
                        {selectedView === 'Routing' && (
                            <Grid container spacing={theme.spacing.unit}>
                                <Grid item xs={12} sm={6}>
                                    <CustomWrapper
                                        title="General"
                                        outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}
                                    >
                                        {getTextInput(
                                            theme,
                                            'trips.bagEstimateOOSStop',
                                            'Est. Capacity Reduction from OOS Stop (bags/hr)', //e.g. 50 -> 1 hr stop = est. 50 bags, 1.5 hr stop = est. 75 bags
                                            formik,
                                            'number',
                                            getInfoIcon(
                                                'Capacity Reduction from OOS Stop',
                                                'Estimated conversion rate from stop time to number of bags collected used for balancing bag capacity',
                                                theme
                                            )
                                        )}
                                        {getSwitch(
                                            theme,
                                            'trips.clusterTimeOfDayPreferences',
                                            'Cluster by AM/PM Preference',
                                            formik,
                                            {
                                                marginTop: theme.spacing.unit * 2
                                            },
                                            {},
                                            undefined,
                                            'Separate morning and afternoon pickups into their own routing clusters at the start and end of each trip'
                                        )}
                                        {getSwitch(
                                            theme,
                                            'trips.prioritizeRescheduled',
                                            'Prioritize Rescheduled Pickups',
                                            formik,
                                            {
                                                marginTop: theme.spacing.unit * 2
                                            },
                                            {},
                                            undefined,
                                            'Prioritize completion of rescheduled pickups in automatic trip routing'
                                        )}
                                        {getSwitch(
                                            theme,
                                            'trips.clusterZones',
                                            'Split Clusters by Zone',
                                            formik,
                                            {
                                                marginTop: theme.spacing.unit * 2
                                            },
                                            {},
                                            undefined,
                                            'Separate pickups in different zones into distinct routing clusters'
                                        )}
                                        {/* {getSwitch(
                                            theme,
                                            'trips.capPickups',
                                            'Cap Trip at Pickup Capacity',
                                            formik,
                                            {
                                                marginTop: theme.spacing.unit * 2
                                            },
                                            {},
                                            undefined,
                                            'Use driver pickup capacity in addition to bag capacity to attempt to balance driver load'
                                        )} */}
                                        {getTextInput(
                                            theme,
                                            'trips.warningDistanceFromDepot',
                                            'Expected Max Distance Between Start Location and Depot (km)',
                                            formik,
                                            'number',
                                            getInfoIcon(
                                                'Expected max distance between start location and depot',
                                                'Toggles a warning on the edit trip dialog if a start location is at a distance that is too far from the depot',
                                                theme
                                            )
                                        )}
                                        {getTextInput(
                                            theme,
                                            'trips.maxGasPayout',
                                            'Max Gas Payout per Trip',
                                            formik,
                                            'number',
                                            getInfoIcon(
                                                'Max Gas Payout per Trip',
                                                'Caps the maximum amount that can be paid out to drivers for gas for a single trip',
                                                theme
                                            )
                                        )}
                                    </CustomWrapper>
                                </Grid>
                            </Grid>
                        )}
                        {selectedView === '2FA & SMS' && (
                            <Grid container spacing={theme.spacing.unit}>
                                <Grid item xs={12}>
                                    {!_.isEmpty(twoFactorAuthenticationWarning) &&
                                        process.env.REACT_APP_ENV === 'TEST' && (
                                            <Typography variant="subtitle1">
                                                {twoFactorAuthenticationWarning}
                                            </Typography>
                                        )}
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <CustomWrapper
                                        title="General"
                                        outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}
                                    >
                                        {getSwitch(
                                            theme,
                                            'SMS.isEnabled',
                                            'Enable SMS messages (Non 2FA)',
                                            formik,
                                            {},
                                            {},
                                            undefined,
                                            'Enables/disables SMS for non 2FA.'
                                        )}
                                        {getSwitch(
                                            theme,
                                            'twoFactorAuthentication.isEnabled',
                                            'Enable Two-Factor Authentication',
                                            formik,
                                            {},
                                            {},
                                            undefined,
                                            'Enables/disables 2FA across entire system. If disabled 2FA will not be sent.'
                                        )}
                                        {getSwitch(
                                            theme,
                                            'twoFactorAuthentication.isRequired',
                                            'Force Two-Factor Authentication',
                                            formik,
                                            {},
                                            {},
                                            undefined,
                                            'Force all accounts to use 2FA unless overridden or disabled.',
                                            !_.get(formik.values, 'twoFactorAuthentication.isEnabled', true)
                                        )}
                                        <TextField
                                            {...formik.getFieldProps('twoFactorAuthentication.maxCodeRequests')}
                                            type={'number'}
                                            error={
                                                (_.get(
                                                    formik.touched,
                                                    'twoFactorAuthentication.maxCodeRequests',
                                                    false
                                                ) ||
                                                    _.get(
                                                        formik.touched,
                                                        'twoFactorAuthentication.limitCodeRequests',
                                                        false
                                                    )) &&
                                                _.get(formik.errors, 'twoFactorAuthentication.maxCodeRequests', false)
                                                    ? true
                                                    : null
                                            }
                                            label={'Code Requests Before Locking Account'}
                                            style={{ marginTop: theme.spacing.unit * 2 }}
                                            variant="outlined"
                                            helperText={
                                                (_.get(
                                                    formik.touched,
                                                    'twoFactorAuthentication.maxCodeRequests',
                                                    false
                                                ) ||
                                                    _.get(
                                                        formik.touched,
                                                        'twoFactorAuthentication.limitCodeRequests',
                                                        false
                                                    )) &&
                                                _.get(formik.errors, 'twoFactorAuthentication.maxCodeRequests', false)
                                                    ? _.get(
                                                          formik.errors,
                                                          'twoFactorAuthentication.maxCodeRequests',
                                                          false
                                                      )
                                                    : ''
                                            }
                                            fullWidth
                                            disabled={
                                                !_.get(formik.values, 'twoFactorAuthentication.isEnabled', true) ||
                                                !_.get(formik.values, 'twoFactorAuthentication.limitCodeRequests')
                                            }
                                            data-cy={`${_.kebabCase('twoFactorAuthentication.maxCodeRequests')}-input`}
                                            InputProps={getInfoIcon(
                                                'Code Requests Before Locking Account',
                                                'Maximum number of authentication code requests a customer can make before being temporarily locked out of their account',
                                                theme
                                            )}
                                        />
                                        {getSwitch(
                                            theme,
                                            'twoFactorAuthentication.limitCodeRequests',
                                            'Limit Code Requests',
                                            formik,
                                            {},
                                            {},
                                            undefined,
                                            'Limit the number of authentication code requests a customer can make before being temporarily locked out of their account',
                                            !_.get(formik.values, 'twoFactorAuthentication.isEnabled', true)
                                        )}
                                        <TextField
                                            {...formik.getFieldProps(
                                                'twoFactorAuthentication.maxAuthenticationAttempts'
                                            )}
                                            type={'number'}
                                            error={
                                                (_.get(
                                                    formik.touched,
                                                    'twoFactorAuthentication.maxAuthenticationAttempts',
                                                    false
                                                ) ||
                                                    _.get(
                                                        formik.touched,
                                                        'twoFactorAuthentication.limitAttempts',
                                                        false
                                                    )) &&
                                                _.get(
                                                    formik.errors,
                                                    'twoFactorAuthentication.maxAuthenticationAttempts',
                                                    false
                                                )
                                                    ? true
                                                    : null
                                            }
                                            label={'Authentication Attempts Before Locking Account'}
                                            style={{ marginTop: theme.spacing.unit * 2 }}
                                            variant="outlined"
                                            helperText={
                                                (_.get(
                                                    formik.touched,
                                                    'twoFactorAuthentication.maxAuthenticationAttempts',
                                                    false
                                                ) ||
                                                    _.get(
                                                        formik.touched,
                                                        'twoFactorAuthentication.limitAttempts',
                                                        false
                                                    )) &&
                                                _.get(
                                                    formik.errors,
                                                    'twoFactorAuthentication.maxAuthenticationAttempts',
                                                    false
                                                )
                                                    ? _.get(
                                                          formik.errors,
                                                          'twoFactorAuthentication.maxAuthenticationAttempts',
                                                          false
                                                      )
                                                    : ''
                                            }
                                            fullWidth
                                            disabled={
                                                !_.get(formik.values, 'twoFactorAuthentication.isEnabled', true) ||
                                                !_.get(formik.values, 'twoFactorAuthentication.limitAttempts')
                                            }
                                            data-cy={`${_.kebabCase(
                                                'twoFactorAuthentication.maxAuthenticationAttempts'
                                            )}-input`}
                                            InputProps={getInfoIcon(
                                                'Authentication Attempts Before Locking Account',
                                                'Maximum number of failed authentication attempts a customer can make before being temporarily locked out of their account',
                                                theme
                                            )}
                                        />
                                        {getSwitch(
                                            theme,
                                            'twoFactorAuthentication.limitAttempts',
                                            'Limit Attempts',
                                            formik,
                                            {},
                                            {},
                                            undefined,
                                            'Limit the number of failed authentication attempts a customer can make before being temporarily locked out of their account',
                                            !_.get(formik.values, 'twoFactorAuthentication.isEnabled', true)
                                        )}
                                        {getTextInput(
                                            theme,
                                            'twoFactorAuthentication.minutesToLockAccount',
                                            'Minutes Until Account is Unlocked',
                                            formik,
                                            'number',
                                            getInfoIcon(
                                                'Minutes Until Account is Unlocked',
                                                'Number of minutes to lock a customer out of their account after failed two-factor authentication',
                                                theme
                                            ),
                                            {},
                                            null,
                                            !_.get(formik.values, 'twoFactorAuthentication.isEnabled', true)
                                        )}
                                        {getTextInput(
                                            theme,
                                            'twoFactorAuthentication.secondsBetweenCodeRequests',
                                            'Seconds Between Code Requests',
                                            formik,
                                            'number',
                                            getInfoIcon(
                                                'Seconds Between Code Requests',
                                                'Amount of time to disable authentication code resend requests to prevent spamming',
                                                theme
                                            ),
                                            {},
                                            null,
                                            !_.get(formik.values, 'twoFactorAuthentication.isEnabled', true)
                                        )}
                                        {getTextInput(
                                            theme,
                                            'twoFactorAuthentication.monthsBeforeTrustedDevicesExpire',
                                            'Months Until Trusted Devices Expire',
                                            formik,
                                            'number',
                                            getInfoIcon(
                                                'Months Until Trusted Devices Expire',
                                                'Number of months a device can be trusted to bypass 2FA',
                                                theme
                                            )
                                        )}
                                    </CustomWrapper>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <CustomWrapper
                                        title="Identity Verification"
                                        outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}
                                    >
                                        {getSwitch(
                                            theme,
                                            'twoFactorAuthentication.identityVerification.isEnabled',
                                            'Allow Identity Verification To Change Phone Number',
                                            formik,
                                            {},
                                            {},
                                            undefined,
                                            'Allow users to modify the phone number used for 2FA by verifying their identity via security question & postal code'
                                        )}
                                        {getTextInput(
                                            theme,
                                            'twoFactorAuthentication.identityVerification.maxAttempts',
                                            'Verification Attempts Before Locking Account',
                                            formik,
                                            'number',
                                            getInfoIcon(
                                                'Verification Attempts Before Locking Account',
                                                'Maximum number of failed identity verification attempts a customer can make before being temporarily locked out of their account',
                                                theme
                                            )
                                        )}
                                        {getTextInput(
                                            theme,
                                            'twoFactorAuthentication.identityVerification.minutesToLockAccount',
                                            'Minutes Until Account Is Unlocked',
                                            formik,
                                            'number',
                                            getInfoIcon(
                                                'Minutes Until Account is Unlocked',
                                                'Number of minutes to lock a customer out of their account after failed identify verification',
                                                theme
                                            )
                                        )}
                                    </CustomWrapper>
                                </Grid>
                            </Grid>
                        )}
                    </Paper>
                )}
                <Dialog open={errorDialogOpen} fullWidth>
                    <DialogTitle disableTypography>
                        <Typography variant="h5" style={{ fontWeight: 300, color: colors.red[500] }}>
                            Validation Error
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText style={{ color: colors.red[500] }}>{errorDialogText}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            style={{ color: colors.red[500] }}
                            onClick={() => {
                                setErrorDialogOpen(false);
                                setErrorDialogText('');
                            }}
                        >
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
                <CreateEditReasonDialog
                    open={showCreateEditReasonDialog}
                    onClose={() => {
                        setShowCreateEditReasonDialog(false);
                    }}
                    onSubmit={({ name, info }) => {
                        if (createEditReasonDialogMode === 'create') {
                            let reasonsCopy = _.cloneDeep(_.get(formik.values, 'adjustments.reasons'));
                            reasonsCopy.push({ name, info });
                            formik.setFieldValue('adjustments.reasons', reasonsCopy);
                        } else {
                            if (!_.isNil(name)) {
                                let reasonsCopy = _.cloneDeep(_.get(formik.values, 'adjustments.reasons'));
                                for (let i = 0; i < reasonsCopy.length; i++) {
                                    if (reasonsCopy[i].name === name) {
                                        reasonsCopy[i].info = info;
                                    }
                                }
                                formik.setFieldValue('adjustments.reasons', reasonsCopy);
                            }
                        }
                    }}
                    mode={createEditReasonDialogMode}
                    editingReason={editingReason}
                    reasons={_.get(formik.values, 'adjustments.reasons')}
                />
                <div />
            </div>
        </div>
    );
}

export default withWidth()(withTheme()(ConfigurationV2));

function ConfigListItem(props) {
    const { title, icon, error, isSubmitting, isValid, setSelectedView, selectedView, theme } = props;
    useEffect(() => {
        if (!isSubmitting && !isValid && error) {
            setSelectedView(title);
        }
    }, [isSubmitting]);

    return (
        <MenuItem
            dense
            onClick={() => setSelectedView(title)}
            selected={selectedView === title}
            style={{ marginLeft: 8 }}
            data-cy={title.replace(/\s+/g, '-').toLowerCase()}
        >
            <ListItemIcon>
                <Icon
                    style={{
                        alignSelf: 'flex-end',
                        color: error ? 'red' : 'grey'
                    }}
                >
                    {icon}
                </Icon>
            </ListItemIcon>
            <ListItemText
                inset
                primary={
                    <span
                        style={{
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            display: 'block',
                            paddingRight: 0,
                            color: error ? 'red' : `${theme.palette.text.primary}`
                        }}
                    >
                        {title}
                    </span>
                }
            />
        </MenuItem>
    );
}

const intuitAccountForm = (formik, accountKey, accountName, theme) => {
    return (
        <CustomWrapper title={accountName} outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}>
            {getTextInput(
                theme,
                `intuit.accounts.${accountKey}.name`,
                'Name',
                formik,
                'text',
                getInfoIcon(`${accountName} name`, `Name of ${accountName} intuit account`, theme)
            )}
            {getTextInput(
                theme,
                `intuit.accounts.${accountKey}.id`,
                'Id',
                formik,
                'number',
                getInfoIcon(`${accountName} id`, `Id number of ${accountName} intuit account`, theme)
            )}
        </CustomWrapper>
    );
};

function flattenObject(object) {
    var toReturn = {};

    for (var key in object) {
        if (!object.hasOwnProperty(key)) continue;

        if (typeof object[key] == 'object' && !_.isNil(object[key])) {
            var flatObject = flattenObject(object[key]);
            for (var nestedKey in flatObject) {
                if (!flatObject.hasOwnProperty(nestedKey)) continue;

                toReturn[key + '.' + nestedKey] = flatObject[nestedKey];
            }
        } else if (!_.isNil(object[key])) {
            toReturn[key] = object[key];
        }
    }
    return toReturn;
}

function getInfoIcon(title, infoText, theme) {
    return {
        endAdornment: (
            <InputAdornment position="end">
                {!_.isEmpty(infoText) && (
                    <Tooltip
                        title={infoText}
                        data-cy={`config-${_.kebabCase(title)}-info-text`}
                        style={{ color: theme.palette.text.disabled, fontSize: 18 }}
                    >
                        <Icon data-cy={`config-${_.kebabCase(title)}-info-icon`}>info</Icon>
                    </Tooltip>
                )}
            </InputAdornment>
        )
    };
}

function convertToImportName(iconName) {
    return !iconName.startsWith('mdi') ? _.camelCase(`mdi-${iconName}`) : _.camelCase(`${iconName}`);
}
function convertImportToIconName(importName) {
    return _.kebabCase(importName.substring(3));
}

function LocalizedStringListInput({ theme, formik, label, formikPath, infoText }) {
    const availableLangs = useMemo(() => AVAILABLE_LANGS[process.env.REACT_APP_REGION_EXT].sort(), []);

    const [inputsLang, setInputsLang] = useState('en');

    return _.size(availableLangs) > 1 ? (
        <CustomWrapper outerDivStyle={{ marginBottom: theme.spacing.unit * 3 }}>
            <LocalizationSelect
                theme={theme}
                inputsLang={inputsLang}
                setInputsLang={setInputsLang}
                availableLangs={availableLangs}
            />
            <StringListInput
                label={label}
                values={_.get(formik.values, `${formikPath}.${inputsLang}`)}
                onChange={values => formik.setFieldValue(`${formikPath}.${inputsLang}`, values)}
                error={inputValue => {
                    return _.isEmpty(inputValue) && _.get(formik.errors, `${formikPath}.${inputsLang}`, false);
                }}
                errorMessage={
                    _.get(formik.errors, `${formikPath}.${inputsLang}`, false)
                        ? _.get(formik.errors, `${formikPath}.${inputsLang}`, '')
                        : ''
                }
                InputProps={{
                    variant: 'outlined'
                }}
                longStringFormat
                infoText={infoText}
            />
        </CustomWrapper>
    ) : (
        <StringListInput
            label={label}
            values={_.get(formik.values, `${formikPath}.${inputsLang}`)}
            onChange={values => formik.setFieldValue(`${formikPath}.${inputsLang}`, values)}
            error={inputValue => {
                return _.isEmpty(inputValue) && _.get(formik.errors, `${formikPath}.${inputsLang}`, false);
            }}
            errorMessage={
                _.get(formik.errors, `${formikPath}.${inputsLang}`, false)
                    ? _.get(formik.errors, `${formikPath}.${inputsLang}`, '')
                    : ''
            }
            InputProps={{
                variant: 'outlined'
            }}
            longStringFormat
            infoText={infoText}
        />
    );
}

const LocalizationSelect = ({ theme, inputsLang, setInputsLang, availableLangs }) => {
    return (
        <ToggleButtonGroup
            value={inputsLang}
            exclusive
            onChange={(e, lang) => {
                if (!_.isEmpty(lang)) setInputsLang(lang);
            }}
            style={{
                display: 'flex',
                flexWrap: 'wrap',
                marginTop: theme.spacing.unit
            }}
        >
            {availableLangs.map(lang => (
                <ToggleButton style={{ flexGrow: 1 }} value={lang} data-cy={`language-toggle-en`}>
                    {lang}
                </ToggleButton>
            ))}
        </ToggleButtonGroup>
    );
};

function AdjustmentReasonsList({
    reasons,
    theme,
    warnAction,
    onChange,
    setShowCreateEditReasonDialog,
    setCreateEditReasonDialogMode,
    setEditingReason
}) {
    return (
        <div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignContent: 'center'
                }}
            >
                <Typography style={{ marginLeft: 8, alignContent: 'center' }}>Adjustment Reasons</Typography>
                <IconButton
                    style={{ marginRight: 17 }}
                    data-cy={`new_reason`}
                    onClick={e => {
                        setEditingReason(null);
                        setCreateEditReasonDialogMode('create');
                        setShowCreateEditReasonDialog(true);
                    }}
                >
                    <Icon>add</Icon>
                </IconButton>
            </div>
            <List style={{ paddingTop: 0, paddingBottom: theme.spacing.unit }}>
                {_.isEmpty(reasons) && <Typography style={{ textAlign: 'center' }}>No Reasons</Typography>}
                {reasons.map((reason, i) => {
                    return (
                        <ListItem key={_.get(reason, 'name')}>
                            <ListItemText
                                style={{ paddingRight: 0 }}
                                primary={_.get(reason, 'name')}
                                secondary={_.get(reason, 'info')}
                            />
                            <ListItemIcon style={{ margin: 0 }}>
                                <IconButton
                                    data-cy={`delete_reason`}
                                    onClick={e => {
                                        setEditingReason(reason);
                                        setCreateEditReasonDialogMode('edit');
                                        setShowCreateEditReasonDialog(true);
                                    }}
                                >
                                    <Icon>edit</Icon>
                                </IconButton>
                            </ListItemIcon>
                            <ListItemIcon style={{ margin: 0 }}>
                                <IconButton
                                    data-cy={`delete_reason`}
                                    onClick={e => {
                                        warnAction(() => {
                                            let reasonsCopy = _.cloneDeep(reasons);
                                            reasonsCopy.splice(i, 1);
                                            onChange(reasonsCopy);
                                        }, `Are you sure you want to remove the reason: ${_.get(reason, 'name')}?`);
                                    }}
                                >
                                    <Icon>delete</Icon>
                                </IconButton>
                            </ListItemIcon>
                        </ListItem>
                    );
                })}
            </List>
        </div>
    );
}
function CreateEditReasonDialog({ open, onClose, onSubmit, mode, editingReason, reasons }) {
    const [reasonName, setReasonName] = useState('');
    const [reasonInfo, setReasonInfo] = useState('');

    useEffect(() => {
        setReasonName(_.get(editingReason, 'name', ''));
        setReasonInfo(_.get(editingReason, 'info', ''));
    }, [editingReason]);

    const reasonNames = reasons.map(r => r.name);

    return (
        <Dialog open={open}>
            <DialogTitle style={{ paddingBottom: 0 }}>
                {mode === 'create' ? 'New Adjustment Reason' : `Edit Reason: ${_.get(editingReason, 'name', '')}`}
            </DialogTitle>
            <DialogContent style={{ paddingBottom: 10 }}>
                {mode === 'create' && (
                    <FormControl fullWidth style={{ minWidth: 300, marginTop: 10 }}>
                        <TextField
                            value={reasonName}
                            onChange={e => {
                                setReasonName(e.target.value);
                            }}
                            variant="outlined"
                            name="name"
                            label="Name"
                            error={mode === 'create' && reasonNames.includes(reasonName)}
                            helperText={
                                mode === 'create' && reasonNames.includes(reasonName) ? 'Name already exists' : ''
                            }
                        />
                    </FormControl>
                )}
                <FormControl fullWidth style={{ minWidth: 300, marginTop: 10 }}>
                    <TextField
                        multiline
                        value={reasonInfo}
                        onChange={e => {
                            setReasonInfo(e.target.value);
                        }}
                        variant="outlined"
                        name="info"
                        label="Info"
                    />
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button
                    type="submit"
                    color="primary"
                    data-cy="submit"
                    onClick={() => {
                        onSubmit({ name: reasonName, info: reasonInfo });
                        onClose();
                    }}
                    disabled={mode === 'create' && reasonNames.includes(reasonName)}
                >
                    submit
                </Button>
            </DialogActions>
        </Dialog>
    );
}
