import React, { useMemo } from 'react';
import { Typography, Dialog } from '@material-ui/core';
import { _bulk } from 'std';
import _ from 'lodash';
import moment from 'moment-timezone';
import { formatAsCurrency } from 'utils/misc';
const materialNames = { '0-1L': '0 - 1L Containers', '> 1L': 'Over 1L Containers' };
const Receipt = React.forwardRef((props, ref) => {
    const { open, collector, operator, bulk, grandTotal, skus, totalCountFormAmount } = props;
    const labelValueMap = useMemo(() => getLabelValueMap(skus[bulk.skuType] || []), [bulk, skus]);
    const breakdowns = useMemo(() => getBreakdowns(bulk, skus[bulk.skuType] || [], grandTotal + totalCountFormAmount), [
        bulk,
        grandTotal,
        skus,
        totalCountFormAmount
    ]);
    let address = collector.location.description.split(',');
    let now = new Date();
    let date = moment(now).format('dddd, MMMM Do YYYY');
    let time = moment(now).format('h:mm A');
    return (
        <Dialog open={open}>
            <div ref={ref} style={{ marginLeft: 20, marginRight: 20, marginTop: 50, marginBottom: 50, width: 305 }}>
                <Typography style={{ textAlign: 'center' }}>{collector.name}</Typography>
                <Typography style={{ textAlign: 'center' }}>{address[0]}</Typography>
                <Typography style={{ textAlign: 'center' }}>{address[1] + ', ' + address[2]}</Typography>
                <Typography style={{ textAlign: 'center' }}>
                    - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
                </Typography>
                <Typography style={{ textAlign: 'center' }}>=== Returns ===</Typography>
                <Typography style={{ textAlign: 'right' }}>{date}</Typography>
                <div
                    style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 20 }}
                >
                    <Typography inline>{operator.name.first}</Typography>
                    <Typography inline>{time}</Typography>
                </div>
                <table
                    style={{
                        tableLayout: 'fixed',
                        width: 310,
                        padding: 0,
                        marginLeft: -3
                    }}
                >
                    <tbody>
                        {breakdowns.map((breakdown, index) => {
                            if (index === 0 || breakdown.quantity === 0) return null;
                            let name = materialNames[breakdown.title];
                            if (name === undefined) name = breakdown.title;
                            return (
                                <tr key={breakdown.title}>
                                    <td>
                                        <Typography style={{ minWidth: 150 }}>{name}</Typography>
                                    </td>
                                    <td>
                                        <Typography style={{ textAlign: 'right' }}>
                                            {Math.floor(breakdown.quantity)}
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography style={{ textAlign: 'right' }}>
                                            {formatAsCurrency(breakdown.value)}
                                        </Typography>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                <Typography style={{ textAlign: 'center' }}>
                    - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
                </Typography>
                {breakdowns.length > 0 && (
                    <table
                        style={{
                            tableLayout: 'fixed',
                            width: 310,
                            padding: 0,
                            marginLeft: -3,
                            marginBottom: 20
                        }}
                    >
                        <tbody>
                            <tr key={breakdowns[0].title}>
                                <td>
                                    <Typography style={{ minWidth: 150 }}>{breakdowns[0].title}</Typography>
                                </td>
                                <td>
                                    <Typography style={{ textAlign: 'right' }}>
                                        {Math.floor(breakdowns[0].quantity)}
                                    </Typography>
                                </td>
                                <td>
                                    <Typography style={{ textAlign: 'right' }}>
                                        {formatAsCurrency(breakdowns[0].value)}
                                    </Typography>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                )}
                <table
                    style={{
                        tableLayout: 'fixed',
                        width: 310,
                        padding: 0,
                        marginLeft: -3
                    }}
                >
                    <tbody>
                        {breakdowns.map((breakdown, index) => {
                            let value = labelValueMap[breakdown.title];
                            if (value === undefined) return null;
                            return (
                                <tr key={breakdown.title}>
                                    <td>
                                        <Typography style={{ minWidth: 150 }}>{formatAsCurrency(value)}</Typography>
                                    </td>
                                    <td>
                                        <Typography>{Math.floor(breakdown.quantity)}</Typography>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </Dialog>
    );
});
export default Receipt;

function getBreakdowns(bulk, skus, grandTotal) {
    const fees = _.get(bulk, 'customFees', []);

    const [feesAmount, paymentsAmount] = fees.reduce(
        (arr, { amount }) => {
            if (amount > 0) {
                arr[0] = arr[0] + amount;
            } else if (amount < 0) {
                arr[1] = arr[1] + amount;
            }

            return arr;
        },

        [0, 0]
    );

    const feesBreakdown = {
        title: 'Fees',
        quantity: fees.filter(({ amount }) => amount > 0).length,
        value: -feesAmount
    };

    const paymentsBreakdown = {
        title: 'Payments',
        quantity: fees.filter(({ amount }) => amount < 0).length,
        value: -paymentsAmount
    };

    const totalBreakdown = {
        title: 'Total',
        quantity: _bulk.getCountsTotalItemCount(bulk),
        value: grandTotal
    };

    let breakdowns = [totalBreakdown];

    if (!_.isEmpty(skus)) {
        const skuMap = _.keyBy(skus, 'sku');
        const allSkuLabels = _(skus)
            .groupBy('label')
            .keys()
            .value();

        if (allSkuLabels.length > 0) {
            const flattenedAndGroupedCountsObj = _.groupBy(
                _.get(bulk, 'counts', []).reduce((array, count) => (array = array.concat(count.items)), []),
                'size'
            );

            for (let label of allSkuLabels) {
                if (!_.isEmpty(label)) {
                    let quantity = 0;
                    let value = 0;

                    const flattenedAndGroupedCountsArr = flattenedAndGroupedCountsObj[label];
                    if (!_.isEmpty(flattenedAndGroupedCountsArr)) {
                        for (let flatCounts of flattenedAndGroupedCountsArr) {
                            quantity += flatCounts.quantity;

                            const sku = skuMap[flatCounts.sku];

                            value += flatCounts.quantity * _.get(sku, 'value', 0) * -1; // skus values are inverted
                        }
                    }

                    const newBreakdown = {
                        title: label,
                        quantity,
                        value
                    };

                    breakdowns.push(newBreakdown);
                } else if (label === '') {
                    // for regions other than STD, groupBy material type
                    const counts = flattenedAndGroupedCountsObj[label];

                    if (!_.isEmpty(counts)) {
                        const countsByMatType = _.groupBy(counts, 'materialType');

                        for (let materialType in countsByMatType) {
                            let quantity = 0;
                            let value = 0;

                            for (let count of countsByMatType[materialType]) {

                                quantity += count.quantity;

                                const sku = skuMap[count.sku];

                                value += count.quantity * _.get(sku, 'value', 0) * -1; // skus values are inverted
                            }

                            const newBreakdown = {
                                title: materialType,
                                quantity,
                                value
                            };
                            breakdowns.push(newBreakdown);
                        }
                    }
                }
            }
        }
    }

    if (!_.isEmpty(fees)) {
        breakdowns.push(feesBreakdown);
        breakdowns.push(paymentsBreakdown);
    }

    return breakdowns;
}

function getLabelValueMap(skus) {
    let res = {};
    for (let i = 0; i < skus.length; i++) {
        res[skus[i].label] = skus[i].value * -1;
    }
    return res;
}
