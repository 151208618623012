import React, { useState } from 'react';
import _ from 'lodash';

import {
    Paper,
    withTheme,
    LinearProgress,
    Collapse,
    Typography,
    IconButton,
    Icon,
    Button,
    Avatar,
    Select,
    MenuItem,
    Grid,
    colors
} from '@material-ui/core';

import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    Dot,
    ReferenceLine,
    Label
} from 'recharts';
import useWindowSize from 'utils/hooks/useWindowSize';
import { useEffect } from 'react';
import moment from 'moment';

const colorMap = {
    pink: colors.pink[500],
    red: colors.red[500],
    deepOrange: colors.deepOrange[500],
    orange: colors.orange[500],
    amber: colors.amber[500],
    yellow: colors.yellow[500],
    lightGreen: colors.lightGreen[500],
    green: colors.green[500],
    teal: colors.teal[500],
    blue: colors.blue[500],
    indigo: colors.indigo[500],
    purple: colors.purple[500],
    grey: colors.grey[500],
    black: colors.common.black
};

function isLightColor(color) {
    const getColorHex = c => colorMap[c] || c;

    let hex = '000000' // Default as black

    if (!_.isNil(getColorHex(color))) {
        hex = getColorHex(color).replace('#', '')
    }

    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;
    return brightness > 155;
}

const CustomLabel = ({ viewBox, value, markerColor }) => {
    const { x, y, width } = viewBox; // Extract dimensions from viewBox

    const padding = 10; // Padding around the text inside the rectangle
    const textWidth = (value || "").length * 7; // Approximate width per character
    const rectWidth = textWidth + 2 * padding; // Add padding to total width

    return (
        <g>
            <rect
                x={x + width / 2 - rectWidth / 2}
                y={y}
                width={rectWidth}
                height={30}
                fill={markerColor}
                rx={5}
                ry={5}
            />
            <text
                x={x + width / 2}
                y={y + 15}
                fill={isLightColor(markerColor) ? 'black' : 'white'}
                textAnchor="middle"
                alignmentBaseline="middle"
            >
                {value}
            </text>
        </g>
    );
};

function StatisticsChart(props) {
    const [width, height] = useWindowSize();
    const [expanded, setExpanded] = useState(true);
    let mobileMode = width <= 600;
    const {
        data,
        dataLoading,
        visibleTrendKeys,
        trendLines,
        totalUsers,
        totalPickups,
        totalOrders,
        title = 'Statistics Chart',
        YAxisProps = {},
        tooltipComponent = <UsageTrendsTooltip />,
        theme,
        handleDownload,
        handleRefresh,
        handleRemoveChart,
        handleFilters,
        keyVal,
        showMarker,
        markerTitle,
        markerDate,
        markerColor,
        lastUpdated,
        dateRangeOption,
        chartInterval,
        useToday
    } = props;
    let updatedMarkerDate = markerDate;

    if (chartInterval === 'DAY') {
        updatedMarkerDate = moment(markerDate).format('DD MMM YYYY');
    } else if (chartInterval === 'WEEK') {
        updatedMarkerDate = moment(markerDate)
            .startOf('isoWeek')
            .format('DD MMM YYYY');
    } else {
        updatedMarkerDate = moment(markerDate)
            .startOf('isoMonth')
            .format('MMM YYYY');
    }

    const getLocalStorageMapValue = (key, defaultValue) => {
        const storedValue = localStorage.getItem('trendsChartSizing');
        if (storedValue) {
            const parsedValue = JSON.parse(storedValue);
            return parsedValue[key] !== undefined ? parsedValue[key] : defaultValue;
        }
        return defaultValue;
    };

    const setLocalStorageMapValue = (key, value) => {
        const storedValue = localStorage.getItem('trendsChartSizing');
        const currentMap = storedValue ? JSON.parse(storedValue) : {};
        currentMap[key] = value;
        localStorage.setItem('trendsChartSizing', JSON.stringify(currentMap));
    };

    const [chartWidth, setChartWidth] = useState(() => getLocalStorageMapValue(`chartWidth_${keyVal}`, 12));
    const [chartHeight, setChartHeight] = useState(() => getLocalStorageMapValue(`chartHeight_${keyVal}`, 700));

    useEffect(() => {
        setLocalStorageMapValue(`chartWidth_${keyVal}`, chartWidth);
    }, [chartWidth]);

    useEffect(() => {
        setLocalStorageMapValue(`chartHeight_${keyVal}`, chartHeight);
    }, [chartHeight]);

    const filteredTrendLines = trendLines.filter(line => visibleTrendKeys.includes(line.dataKey));

    const totals = calculateTotals(data, visibleTrendKeys);

    return (
        <Grid item xs={chartWidth} key={keyVal}>
            <Paper style={{ padding: theme.spacing.unit * 2, paddingRight: 0, width: '100%' }}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: expanded ? 0 : theme.spacing.unit * 2
                    }}
                >
                    <div style={{ display: 'flex', alignItems: 'left' }}>
                        <Typography variant="h6" style={{ margin: theme.spacing.unit }}>
                            {title}
                        </Typography>
                    </div>
                    <div style={{ display: 'flex' }}>
                        <Select
                            value={chartWidth}
                            onChange={e => setChartWidth(e.target.value)}
                            style={{
                                margin: theme.spacing.unit,
                                width: '75px',
                                maxWidth: 'auto'
                            }}
                            data-cy="date-range-select"
                        >
                            <MenuItem value={12} data-cy="size-select-full">
                                Full Width
                            </MenuItem>
                            <MenuItem value={6} data-cy="size-select-half">
                                1/2 Width
                            </MenuItem>
                            <MenuItem value={4} data-cy="size-select-third">
                                1/3 Width
                            </MenuItem>
                            <MenuItem value={3} data-cy="size-select-quarter">
                                1/4 Width
                            </MenuItem>
                        </Select>

                        <Select
                            value={chartHeight}
                            onChange={e => setChartHeight(e.target.value)}
                            style={{
                                margin: theme.spacing.unit,
                                width: '75px',
                                maxWidth: 'auto'
                            }}
                            data-cy="height-select"
                        >
                            <MenuItem value={700} data-cy="height-select-full">
                                Full Height
                            </MenuItem>
                            <MenuItem value={525} data-cy="height-select-half">
                                3/4 Height
                            </MenuItem>
                            <MenuItem value={350} data-cy="height-select-half">
                                1/2 Height
                            </MenuItem>
                            <MenuItem value={234} data-cy="height-select-third">
                                1/3 Height
                            </MenuItem>
                            <MenuItem value={175} data-cy="height-select-quarter">
                                1/4 Height
                            </MenuItem>
                        </Select>
                        <div style={{ textAlign: 'right' }}>
                            <IconButton
                                style={{ marginBottom: '4px' }}
                                onClick={handleFilters}
                                data-cy="statistics-chart-filter"
                            >
                                <Icon>edit</Icon>
                            </IconButton>
                            <IconButton onClick={handleDownload} data-cy="statistics-chart-download">
                                <Icon>download</Icon>
                            </IconButton>
                            <IconButton onClick={handleRefresh} data-cy="statistics-chart-refresh">
                                <Icon>refresh</Icon>
                            </IconButton>
                            <IconButton onClick={() => setExpanded(!expanded)} data-cy="statistics-chart-expand">
                                <Icon>{expanded ? 'expand_less' : 'expand_more'}</Icon>
                            </IconButton>
                            <IconButton onClick={handleRemoveChart} data-cy="statistics-chart-delete">
                                <Icon>clear</Icon>
                            </IconButton>
                        </div>
                    </div>
                </div>

                <Collapse in={expanded}>
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            overflow: 'hidden',
                            flexDirection: mobileMode ? 'column' : 'row',
                            justifyContent: 'space-evenly'
                        }}
                    >
                        <ResponsiveContainer width="95%" height={chartHeight} minWidth={200}>
                            <LineChart data={data} style={{ fontFamily: 'Roboto' }}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="label" tickFormatter={label => label.replace(/\d{4}$/, '')} />
                                <YAxis type="number" tickCount={8} allowDataOverflow={true} {...YAxisProps} />
                                <Tooltip content={tooltipComponent} />
                                <Legend
                                    content={
                                        <CustomLegend data={data} trendLines={filteredTrendLines} totals={totals} />
                                    }
                                    layout="vertical"
                                    verticalAlign="middle"
                                    align="right"
                                    wrapperStyle={{ paddingLeft: theme.spacing.unit * 2 }}
                                />
                                {showMarker && (
                                    <ReferenceLine
                                        x={updatedMarkerDate}
                                        stroke={colorMap[markerColor]}
                                        strokeWidth={1.75}
                                    >
                                        <Label
                                            content={props => (
                                                <CustomLabel markerColor={colorMap[markerColor]} {...props} />
                                            )}
                                            value={markerTitle}
                                            position="insideTop"
                                        />
                                    </ReferenceLine>
                                )}

                                {_.filter(trendLines, line => visibleTrendKeys.includes(line.dataKey)).map(dataSet => (
                                    <Line
                                        key={dataSet.dataKey}
                                        type="monotone"
                                        dataKey={dataSet.dataKey}
                                        stroke={dataSet.stroke}
                                        name={dataSet.name}
                                        dot={<UsageTrendsGraphDot />}
                                        activeDot={<UsageTrendsGraphDot />}
                                    />
                                ))}
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                    {/* <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-evenly',
                        padding: theme.spacing.unit * 2,
                        flexWrap: 'wrap'
                    }}
                >
                    <span style={{ margin: theme.spacing.unit, display: 'flex', alignItems: 'center' }}>
                        <Avatar
                            style={{
                                backgroundColor: theme.palette.primary.main,
                                marginRight: theme.spacing.unit
                            }}
                        >
                            <Icon>people</Icon>
                        </Avatar>
                        <Typography style={{ marginRight: theme.spacing.unit * 2, display: 'inline-block' }}>
                            Customers: {totalUsers}
                        </Typography>
                    </span>
                    <span style={{ margin: theme.spacing.unit, display: 'flex', alignItems: 'center' }}>
                        <Avatar
                            style={{
                                backgroundColor: theme.palette.primary.main,
                                marginRight: theme.spacing.unit
                            }}
                        >
                            <Icon>local_shipping</Icon>
                        </Avatar>
                        <Typography style={{ marginRight: theme.spacing.unit * 2, display: 'inline-block' }}>
                            Pickups: {totalPickups}
                        </Typography>
                    </span>
                    <span style={{ margin: theme.spacing.unit, display: 'flex', alignItems: 'center' }}>
                        <Avatar
                            style={{
                                backgroundColor: theme.palette.primary.main,
                                marginRight: theme.spacing.unit
                            }}
                        >
                            <Icon>ballot</Icon>
                        </Avatar>
                        <Typography style={{ marginRight: theme.spacing.unit * 2, display: 'inline-block' }}>
                            Orders: {totalOrders}
                        </Typography>
                    </span>
                </div> */}
                    <div style={{ paddingTop: theme.spacing.unit * 2 }}>{dataLoading && <LinearProgress />}</div>
                </Collapse>
                {(dateRangeOption !== 'custom' || (dateRangeOption === 'custom' && useToday)) && (
                    <Typography align="right" style={{ color: 'red', paddingRight: theme.spacing.unit * 2 }}>
                        Last Updated {moment(lastUpdated).format('MMM D, YYYY')}
                    </Typography>
                )}
            </Paper>
        </Grid>
    );
}

export default withTheme()(StatisticsChart);

function UsageTrendsTooltip(props) {
    if (props.payload && props.payload.length > 0) {
        const label = _.get(props, 'payload[0].payload.label', null);
        return (
            <div
                data-cy="graph-tooltip"
                style={{
                    border: '1px solid lightgray',
                    backgroundColor: 'white',
                    padding: '5px 10px'
                }}
            >
                {!_.isNil(label) && (
                    <span
                        data-cy="tooltip-label"
                        style={{
                            paddingBottom: '4px',
                            paddingTop: '8px'
                        }}
                    >
                        {label}
                    </span>
                )}
                {props.payload.map(item => {
                    const { name, value, color, dataKey } = item;
                    return (
                        <div style={{ fontSize: '90%', paddingBottom: '2px', paddingTop: '2px' }}>
                            <span data-cy={`tooltip-${dataKey}`} style={{ color: color }}>
                                {name}: {value}
                            </span>
                        </div>
                    );
                })}
            </div>
        );
    } else {
        return <span data-cy="empty-tooltip" />;
    }
}

function UsageTrendsGraphDot(props) {
    const label = _.get(props, 'payload.label', null);
    const dataKey = _.get(props, 'dataKey', null);
    return <Dot {...props} role={`dot-${label}-${dataKey}`} />;
}

function calculateTotals(data, visibleTrendKeys) {
    const totals = {};

    visibleTrendKeys.forEach(dataKey => {
        totals[dataKey] = data.reduce((total, entry) => {
            return total + (entry[dataKey] || 0); // Add the value of the dataKey, defaulting to 0 if undefined
        }, 0);
    });

    return totals;
}

function CustomLegend({ payload, data, trendLines, totals }) {
    const groupedByType = _.groupBy(trendLines, 'type');

    return (
        <div>
            {Object.keys(groupedByType).map(type => (
                <div key={type}>
                    {/* Render the type as a header with a lighter grey color */}
                    <h4 style={{ marginBottom: '5px', color: '#B0B0B0', fontWeight: 'normal', fontSize: '14px' }}>
                        {type.charAt(0).toUpperCase() + type.slice(1)}
                    </h4>
                    <ul style={{ listStyleType: 'none', paddingLeft: '20px', margin: 0, fontSize: '12px' }}>
                        {groupedByType[type].map(line => {
                            // Find the legend entry from payload based on dataKey
                            const legendEntry = payload.find(entry => entry.dataKey === line.dataKey);
                            if (!legendEntry) return null;

                            // Get the pre-calculated total for this dataKey
                            const totalValue = Math.round(totals[line.dataKey] * 100) / 100;
                            const formattedTotalValue = totalValue.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            });

                            return (
                                <li key={line.dataKey} style={{ color: legendEntry.color }}>
                                    {line.displayName}: {formattedTotalValue}
                                </li>
                            );
                        })}
                    </ul>
                </div>
            ))}
        </div>
    );
}
