import React, { useRef, useState } from 'react';
import _ from 'lodash';

import {
    List,
    ListItemAvatar,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    Icon,
    Avatar,
    Divider,
    withTheme,
    IconButton,
    Typography,
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    TextField,
    DialogTitle,
    Grow,
    Grid,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
    OutlinedInput
} from '@material-ui/core';
import { useFormik } from 'formik';

import { AUS_STATES, AVAILABLE_LANGS, RESIDENTIAL } from '../../../constants';

import * as Yup from 'yup';

import { loc } from 'localizations/localizationHandler';
import LocalizationContext from 'utils/contexts/LocalizationContext';
import { useContext } from 'react';

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import LocalizedTextInput from 'components/CRUDTables/Inputs/LocalizedTextInput';
import { getCheckbox, getTextInput, getSelect, getSwitch } from 'components/CRUDTables/helperFunctions';

import { Icon as MDIcon } from '@mdi/react';

import ExternalLink from 'components/ExternalLink/ExternalLink';

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { isAUSRegion } from 'utils/misc';

function SingleCommodityList({
    allFees,
    allIcons,
    allIconNames,
    commodity,
    commodityFormik,
    editDisabled,
    theme,
    mobilePickupSubServices,
    width,
    defaultFormToOpen
}) {
    const [formOpen, setFormOpen] = useState(defaultFormToOpen);
    const [selectedLang, setSelectedLang] = useState('en');

    const isSmallScreen = isWidthDown('xs', width);

    const { lang } = useContext(LocalizationContext);

    const availableLangs = AVAILABLE_LANGS[process.env.REACT_APP_REGION_EXT];

    const handleEditOpen = index => {
        setFormOpen(true);
    };

    const languageToDisplay = editDisabled ? lang : selectedLang;

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ width: '60%', display: 'flex' }}>
                    <Typography variant="caption">Icon</Typography>
                    <Typography variant="caption" style={{ marginLeft: theme.spacing.unit * 8 }}>
                        Name
                    </Typography>
                </div>

                <div style={{ width: '40%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography variant="caption">Unit</Typography>
                    {!editDisabled && (
                        <ToggleButtonGroup
                            value={selectedLang}
                            exclusive
                            onChange={(e, lang) => !_.isEmpty(lang) && setSelectedLang(lang)}
                            style={{ marginRight: theme.spacing.unit * 2 }}
                        >
                            {availableLangs.map(lang => (
                                <ToggleButton key={lang} value={lang}>
                                    {lang}
                                </ToggleButton>
                            ))}
                        </ToggleButtonGroup>
                    )}
                </div>
            </div>
            <List>
                <Divider />
                <ListItem
                    style={{
                        paddingLeft: 0,
                        paddingRight: 0,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                >
                    <div style={{ display: 'flex', alignItems: 'center', width: '60%' }}>
                        <ListItemAvatar>
                            <Avatar style={{ backgroundColor: 'rgba(0,0,0,0)' }}>
                                <MDIcon
                                    path={_.get(allIcons, convertToImportName(commodity.iconName))}
                                    size={1.5}
                                    color={commodity.color}
                                />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            style={{ paddingLeft: theme.spacing.unit * 6 }}
                            primary={_.get(commodity, `payloadInputName.${languageToDisplay}`, '')}
                            primaryTypographyProps={{
                                'data-cy': `primary-commodity-name`
                            }}
                            secondary={`x${_.get(commodity, 'projectionMultiplier', '')}`}
                            secondaryTypographyProps={{
                                'data-cy': `primary-commodity-multiplier`
                            }}
                        />
                    </div>
                    <ListItemSecondaryAction
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '40%'
                        }}
                    >
                        <ListItemText
                            style={{ marginLeft: theme.spacing.unit / 2 }}
                            primary={_.get(commodity, `units.${languageToDisplay}`, '')}
                            primaryTypographyProps={{
                                'data-cy': `primary-commodity-unit`
                            }}
                        />
                        {!editDisabled && (
                            <IconButton data-cy={`primary-commodity-edit`} onClick={() => handleEditOpen()}>
                                <Icon>edit</Icon>
                            </IconButton>
                        )}
                    </ListItemSecondaryAction>
                </ListItem>
            </List>
            {formOpen && (
                <SimplifiedCommodityForm
                    allFees={allFees}
                    allIcons={allIcons}
                    allIconNames={allIconNames}
                    commodity={commodity}
                    values={commodityFormik}
                    open={formOpen}
                    onClose={() => setFormOpen(false)}
                    onSubmit={values => {
                        _.keys(values).forEach(key => {
                            commodityFormik.setFieldValue(key, values[key]);
                        });
                        setFormOpen(false);
                    }}
                    theme={theme}
                    width={width}
                    mobilePickupSubServices={mobilePickupSubServices}
                    formik={commodityFormik}
                />
            )}
        </>
    );
}

function SimplifiedCommodityForm({
    allFees,
    allIcons,
    allIconNames,
    commodity,
    values,
    open,
    onClose,
    onSubmit,
    theme,
    mobilePickupSubServices,
    width
}) {
    const commodityIconName = _.get(commodity, 'iconName', 'recycle');
    const commodityColor = _.get(commodity, 'color', '#000000');
    const isSmallScreen = isWidthDown('xs', width);

    const handleChangeColorRef = useRef(
        _.throttle((e, formik) => {
            if (!_.isNil(e) && !_.isNil(e.target)) {
                formik.setFieldValue('color', e.target.value);
            }
        }, 100)
    );

    const formik = useFormik({
        initialValues: {
            _id: _.get(commodity, '_id'),
            payloadInputName: _.get(commodity, 'payloadInputName', ''),
            payloadHelperText: _.get(commodity, 'payloadHelperText', ''),
            projectionMultiplier: _.get(commodity, 'projectionMultiplier', 1),
            color: _.get(commodity, 'color', commodityColor),
            iconName: _.get(commodity, 'iconName', commodityIconName),
            fee: _.get(commodity, 'fee', null), //pre-applied fee
            feeSingle: _.get(commodity, 'feeSingle', _.get(commodity, 'fee', null)), //pre-applied fee
            feeMultiple: _.get(commodity, 'feeMultiple', _.get(commodity, 'fee', null)), //pre-applied fee
            showOnMap: _.get(commodity, 'showOnMap', false),
            units: _.get(commodity, 'units', ''),
            feeApply: _.get(commodity, 'feeApply', 'Once'), //pre-applied fee -> apply once or per item
            trackInventory: _.get(commodity, 'trackInventory', false),
            enableCustomerOrders: _.get(commodity, 'enableCustomerOrders', false),
            locationType: _.get(commodity, 'locationType', [RESIDENTIAL]),
            state: _.get(commodity, 'state', []),
            subscriptionFee: _.get(commodity, 'subscriptionFee', 'none'),
            subscriptionFeeRecurrence: _.get(commodity, 'subscriptionFeeRecurrence', 0)
        },
        validationSchema: Yup.object({
            payloadInputName: Yup.object().test(
                'payloadInputName',
                'You must enter a name for this commodity',
                value => {
                    for (let language of AVAILABLE_LANGS[process.env.REACT_APP_REGION_EXT]) {
                        if (_.isEmpty(_.get(value, language, '').trim())) {
                            return false;
                        }
                        return true;
                    }
                }
            ),
            payloadHelperText: Yup.object().test('payloadHelperText', 'You must enter a value', value => {
                for (let language of AVAILABLE_LANGS[process.env.REACT_APP_REGION_EXT]) {
                    if (_.isEmpty(_.get(value, language, '').trim())) {
                        return false;
                    }
                    return true;
                }
            }),
            // .trim()
            // .required('You must enter a value.'),
            projectionMultiplier: Yup.number().min(0, 'This must be greater than or equal to 0'),
            color: Yup.string().required('You must enter a color'),
            iconName: Yup.string()
                .trim()
                .required('You must enter an icon name')
                .oneOf(allIconNames, 'Invalid icon name'),
            units: Yup.object().test('units', 'You must enter a unit of measure', value => {
                for (let language of AVAILABLE_LANGS[process.env.REACT_APP_REGION_EXT]) {
                    if (_.isEmpty(_.get(value, language, '').trim())) {
                        return false;
                    }
                    return true;
                }
            }),
            enableCustomerOrders: Yup.boolean(),
            trackInventory: Yup.boolean(),
            subscriptionFee: Yup.string()
                .nullable()
                .when('enableCustomerOrders', {
                    is: true,
                    then: Yup.string()
                        .nullable()
                        .required('You must select a subscription fee')
                })
        }),
        onSubmit: values => {
            if (values.subscriptionFee === 'None') {
                values.subscriptionFee = null;
            }
            onSubmit(values);
        }
    });

    const handleChangeColorWrapper = e => {
        e.persist(); //stops error from popping up in the console
        handleChangeColorRef.current(e, formik);
    };

    const handleFeeChange = e => {
        const fee = e.target.value;

        if (fee === 'none') {
            formik.setFieldValue(e.target.name, null);
            return;
        }

        formik.setFieldValue(e.target.name, fee);
        if (e.target.name === 'fee') {
            formik.setFieldValue('feeSingle', fee);
            formik.setFieldValue('feeMultiple', fee);
        }
        if (e.target.name === 'feeSingle' && _.isNil(formik.values.feeMultiple)) {
            formik.setFieldValue('feeMultiple', fee);
        }
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogContent
                style={{
                    height: isSmallScreen ? 'auto' : '650px',
                    width: isSmallScreen ? '100%' : '550px'
                }}
            >
                <DialogTitle style={{ padding: 0, marginBottom: theme.spacing.unit }}>Edit Commodity</DialogTitle>
                <Grid container spacing={theme.spacing.unit}>
                    {/* <Grid item xs={12} sm={6}>
                        <LocalizedTextInput
                            label="Display Name"
                            value={_.get(formik, 'values.payloadInputName')}
                            onChange={value => formik.setFieldValue('payloadInputName', value)}
                            touched={_.get(formik, 'touched.payloadInputName')}
                            error={_.get(formik, 'errors.payloadInputName')}
                            infoText="ex. General Electronics, Clothing Donation"
                        />
                    </Grid> */}
                    <Grid item xs={12} sm={12}>
                        <LocalizedTextInput
                            label="Unit Of Measurement"
                            value={_.get(formik, 'values.units')}
                            onChange={value => formik.setFieldValue('units', value)}
                            touched={_.get(formik, 'touched.units')}
                            error={_.get(formik, 'errors.units')}
                            infoText="ex. bag, item, phone"
                        />
                    </Grid>
                    <Grid item xs="12">
                        <LocalizedTextInput
                            label="Customer Input Helper Text"
                            value={_.get(formik, 'values.payloadHelperText')}
                            onChange={value => formik.setFieldValue('payloadHelperText', value)}
                            style={{ marginTop: theme.spacing.unit }}
                            touched={_.get(formik, 'touched.payloadHelperText')}
                            error={_.get(formik, 'errors.payloadHelperText')}
                            infoText="ex. Number of garbage bags"
                        />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        {getTextInput(
                            theme,
                            'projectionMultiplier',
                            'Equivalent Bags',
                            formik,
                            'number',
                            {},
                            {},
                            '3 means 1 unit/item is the same as 3 large garbage bags'
                        )}
                    </Grid>
                    <Grid item xs={6} sm={5}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Avatar
                                style={{
                                    backgroundColor: 'rgba(0,0,0,0)',
                                    marginRight: theme.spacing.unit,
                                    marginTop: theme.spacing.unit * 1.5
                                }}
                            >
                                <MDIcon
                                    path={_.get(
                                        allIcons,
                                        convertToImportName(_.get(formik.values, 'iconName', commodityIconName))
                                    )}
                                    size={1.5}
                                    color={_.get(formik.values, 'color', commodityColor)}
                                />
                            </Avatar>
                            {getTextInput(theme, 'iconName', 'Icon', formik)}
                        </div>
                        <div style={{ textAlign: 'right' }}>
                            <ExternalLink text="Click here to see all icons" url="https://materialdesignicons.com/" />
                        </div>
                    </Grid>
                    <Grid item xs={6} sm={2}>
                        <TextField
                            type="color"
                            onChange={handleChangeColorWrapper}
                            value={formik.values.color}
                            onBlur={formik.handleBlur}
                            name="color"
                            error={formik.touched.color && formik.errors.color ? true : null}
                            label="Color"
                            margin="normal"
                            variant="outlined"
                            helperText={formik.touched.color && formik.errors.color ? formik.errors.color : null}
                            fullWidth
                        />
                    </Grid>
                    <Grid
                        item
                        xs={_.isNil(formik.values.fee) ? '12' : formik.values.feeApply === 'Per Item' ? '4' : '6'}
                    >
                        {formik.values.feeApply === 'Once' &&
                            getSelect(
                                theme,
                                'fee',
                                'Apply Fee on Completion',
                                [{ _id: 'none', description: 'None' }, ...allFees],
                                formik,
                                handleFeeChange,
                                '_id',
                                'description'
                            )}
                        {formik.values.feeApply === 'Per Item' &&
                            getSelect(
                                theme,
                                'feeSingle',
                                'Fee (Single Unit)',
                                [{ _id: 'none', description: 'None' }, ...allFees],
                                formik,
                                handleFeeChange,
                                '_id',
                                'description'
                            )}
                    </Grid>
                    {formik.values.feeApply === 'Per Item' && (
                        <Grid item xs={4}>
                            {getSelect(
                                theme,
                                'feeMultiple',
                                'Fee (Multiple Units)',
                                [{ _id: 'none', description: 'None' }, ...allFees],
                                formik,
                                handleFeeChange,
                                '_id',
                                'description'
                            )}
                        </Grid>
                    )}
                    {!_.isNil(formik.values.fee) && (
                        <Grid item xs={formik.values.feeApply === 'Per Item' ? '4' : '6'}>
                            {getSelect(
                                theme,
                                'feeApply',
                                'Apply',
                                ['Once', 'Per Item'],
                                formik,
                                null,
                                null,
                                null,
                                false,
                                _.isNil(formik.values.fee)
                            )}
                        </Grid>
                    )}
                    <Grid item xs="12">
                        {getSwitch(
                            theme,
                            'showOnMap',
                            'Show Icon On Map',
                            formik,
                            {},
                            { fontSize: '95%' },
                            undefined,
                            'Will display the commodity icon above pin on the routing admin view.'
                        )}
                        {getSwitch(
                            theme,
                            'trackInventory',
                            'Track Inventory',
                            formik,
                            {},
                            { fontSize: '95%' },
                            undefined,
                            'Will include the commodity in inventory management.'
                        )}
                        {getSwitch(
                            theme,
                            'enableCustomerOrders',
                            'Track Assets & Customer Ordering',
                            formik,
                            {},
                            { fontSize: '95%' },
                            undefined,
                            'Allows customers to request these items with an approval workflow. These items will be tracked in asset manager.'
                        )}
                    </Grid>
                    {formik.values.enableCustomerOrders && (
                        <>
                            <Grid item xs={isAUSRegion() ? 6 : 12}>
                                {!_.isNil(mobilePickupSubServices) &&
                                    !_.isEmpty(mobilePickupSubServices) &&
                                    mobilePickupSubServices.length > 1 && (
                                        <FormControl fullWidth>
                                            <InputLabel variant="outlined">Allowed Ordering Types</InputLabel>
                                            <Select
                                                multiple
                                                fullWidth
                                                input={
                                                    <OutlinedInput labelWidth={110} data-cy={`location-type-input`} />
                                                }
                                                onChange={e => formik.setFieldValue('locationType', e.target.value)}
                                                value={formik.values.locationType}
                                                renderValue={values => (
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            overflow: 'hidden',
                                                            maxHeight: '19px'
                                                        }}
                                                    >
                                                        {values.map((v, index) => {
                                                            const service = _.find(
                                                                mobilePickupSubServices,
                                                                s => s.pickupType === v
                                                            );
                                                            return (
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        verticalAlign: 'center',
                                                                        marginRight: theme.spacing.unit
                                                                    }}
                                                                >
                                                                    <Icon
                                                                        style={{
                                                                            color: 'inherit',
                                                                            marginRight: theme.spacing.unit
                                                                        }}
                                                                    >
                                                                        {_.get(service, 'icon')}
                                                                    </Icon>
                                                                    <Typography variant="body1">
                                                                        {service.text.en.title}
                                                                        {index < values.length - 1 && ', '}
                                                                    </Typography>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                )}
                                                data-cy="commodity-request-type-select"
                                            >
                                                {_(mobilePickupSubServices)
                                                    .map((service, i) => {
                                                        const title = _.get(service, `text.en.title`, '');
                                                        return (
                                                            <MenuItem
                                                                value={_.get(service, 'pickupType')}
                                                                key={_.get(service, 'pickupType')}
                                                            >
                                                                <Icon
                                                                    style={{
                                                                        color: 'inherit',
                                                                        marginRight: theme.spacing.unit
                                                                    }}
                                                                >
                                                                    {_.get(service, 'icon')}
                                                                </Icon>
                                                                {title}
                                                            </MenuItem>
                                                        );
                                                    })
                                                    .value()}
                                            </Select>
                                        </FormControl>
                                    )}
                            </Grid>
                            {isAUSRegion() && (
                                <Grid item xs={6}>
                                    {getSelect(
                                        theme,
                                        'state',
                                        'Allowed Ordering Locations',
                                        AUS_STATES,
                                        formik,
                                        null,
                                        null,
                                        null,
                                        true,
                                        false,
                                        { marginTop: 0 }
                                    )}
                                </Grid>
                            )}
                            <Grid item xs={6}>
                                {getSelect(
                                    theme,
                                    'subscriptionFee',
                                    'Subscription Fee',
                                    [{ _id: 'none', description: 'None' }, ...allFees],
                                    formik,
                                    undefined,
                                    '_id',
                                    'description'
                                )}
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth style={{ marginTop: theme.spacing.unit * 2 }}>
                                    <InputLabel variant="outlined">Interval</InputLabel>
                                    <Select
                                        fullWidth
                                        input={<OutlinedInput labelWidth={50} data-cy={`visibility-input`} />}
                                        onChange={e =>
                                            formik.setFieldValue('subscriptionFeeRecurrence', e.target.value)
                                        }
                                        value={formik.values.subscriptionFeeRecurrence}
                                    >
                                        <MenuItem value={0} data-cy="select-interval-0">
                                            One Time Fee
                                        </MenuItem>
                                        {[7, 14, 21, 28].map((interval, index) => {
                                            return (
                                                <MenuItem
                                                    key={index}
                                                    value={interval}
                                                    data-cy={`select-interval-${interval}`}
                                                >
                                                    Every {interval} days
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button data-cy="edit-commodity-ok" onClick={formik.handleSubmit}>
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default withWidth()(withTheme()(SingleCommodityList));

function convertToImportName(iconName) {
    return _.camelCase(`mdi-${iconName}`);
}
