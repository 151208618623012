import { useContext } from 'react';
import { useEffect, useState } from 'react';
import { _time } from 'std';
import LocalizationContext from 'utils/contexts/LocalizationContext';

const CurrentTime = ({ timezone = _time.getTimezone(), format = 'MMM D hh:mm:ss A' }) => {
    const { lang } = useContext(LocalizationContext);
    const [currentTime, setCurrentTime] = useState(_time.getCurrentTimeFormatted(timezone, 'MMM D hh:mm:ss A', lang));
    useEffect(() => {
        setCurrentTime(_time.getCurrentTimeFormatted(timezone, format, lang));
        const intervalId = setInterval(() => {
            setCurrentTime(_time.getCurrentTimeFormatted(timezone, format, lang));
        }, 1000);
        return () => {
            clearInterval(intervalId);
        };
    }, [timezone, lang]);
    return currentTime;
};

export default CurrentTime;
