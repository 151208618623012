import React from 'react';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { withTheme } from '@material-ui/core/styles';
import { Dialog, DialogActions, DialogTitle, Button, Typography, Icon } from '@material-ui/core';
function EmailReceiptResultDialog(props) {
    const { open, onClose, text } = props;
    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm">
            <DialogTitle>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <Typography style={{ textAlign: 'center', fontSize: 18, fontWeight: 250 }}>
                        Transaction Finished
                    </Typography>
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <Icon
                        style={{
                            fontSize: '100px',
                            alignSelf: 'center',
                            color: 'green',
                            marginTop: 10,
                            marginBottom: 10
                        }}
                    >
                        check_circle_outline
                    </Icon>
                </div>
                <Typography style={{ textAlign: 'center', fontSize: 16, fontWeight: 500 }}>{text}</Typography>
            </DialogTitle>
            <DialogActions>
                <Button color="primary" data-cy="bulk-counter-cancel" onClick={onClose} style={{}}>
                    Finish
                </Button>
            </DialogActions>
        </Dialog>
    );
}
export default withMobileDialog({ breakpoint: 'xs' })(withTheme()(EmailReceiptResultDialog));
