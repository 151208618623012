import useQueryDate from 'utils/hooks/useQueryDate';
import moment from 'moment-timezone';
import _ from 'lodash';
import { _time } from 'std';

import useQueryString from 'utils/hooks/useQueryString';

function useDatePicker({
    timezones = [process.env.REACT_APP_REGION_TIMEZONE],
    initVal,
    stateURLKey = 'date',
    saveStateInURL = false,
    initToNull = false
}) {
    const [timezone, setTimezone] = useQueryString(
        'timezone',
        getInitialTimezoneState(timezones),
        saveStateInURL,
        timezone => {
            return isValidTimezone(timezones, timezone);
        }
    );

    const [date, setDate] = useQueryDate({
        key: stateURLKey,
        initVal: !_.isNil(initVal) ? initVal : _time.getStartOfToday(timezone),
        enabled: saveStateInURL,
        initToNull,
        timezone
    });

    const handleChangeDate = date => {
        setDate(date);
    };

    const handleChangeTimezone = e => {
        const newTimezone = e.target.value;
        setTimezone(newTimezone);
        setDate(_time.convertToNewStartOfDay(newTimezone, date));
    };

    return {
        date,
        timezone,
        handleChangeDate,
        handleChangeTimezone
    };
}

export default useDatePicker;

function getInitialTimezoneState(timezones) {
    if (_.isNil(timezones) || _.isEmpty(timezones)) {
        return process.env.REACT_APP_REGION_TIMEZONE;
    } else if (timezones.length === 1) {
        return _.first(timezones);
    } else {
        return process.env.REACT_APP_REGION_TIMEZONE;
    }
}

function isValidTimezone(timezoneArr, timezone) {
    return timezoneArr.includes(timezone);
}
