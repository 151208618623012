import React from 'react';

const Badge = ({ style, children }) => {
    return (
        <span
            style={{
                fontSize: 12.25,
                backgroundColor: 'rgba(0, 0, 0, 0.05)',
                padding: '2px 4px',
                borderRadius: '4px',
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                whiteSpace: 'nowrap',
                ...style
            }}
        >
            {children}
        </span>
    );
};

export default Badge;
