import React, { useState, useMemo, useContext } from 'react';
import _ from 'lodash';

import { Grid, Typography, Button, Icon, FormHelperText, Paper, withTheme } from '@material-ui/core';
import { maxFileSize } from 'constants.js';
import useGetJSON from 'utils/hooks/useGetJSON';

import SendOutListComponent from './SendOutListComponent';
import SendOutListDialog from './SendOutListDialog';

import HttpContext from 'utils/contexts/HttpContext';
import SnackbarContext from 'components/CustomSnackbar/SnackbarContext';
import { downloadObjectAsCSV } from 'utils/misc';

function SendOutLists(props) {
    const onSnackbar = useContext(SnackbarContext);
    const { regions, theme } = props;

    const [sendOutListDialogOpen, setSendOutListDialogOpen] = useState(false);
    const [sendOutListToEdit, setSendOutListToEdit] = useState(null);

    const http = useContext(HttpContext);

    const { loading: zonesLoading, error: zonesLoadingErr, data: zoneData } = useGetJSON(`/system/zones`);
    /*const {
        loading: sendOutsLoading,
        error: sendOutsLoadingErr,
        data: sendOutsData,
        refresh: reloadSendOuts
    } = useGetJSON(`/sendOuts`);*/
    const { loading: charitiesLoading, error: charitiesLoadingErr, data: charityData } = useGetJSON(
        `/charities/sendouts`
    );
    const {
        loading: collectorsLoading,
        error: collectorsError,
        data: { collectors = [] }
    } = useGetJSON(`/collectors/all`);
    const { loading: servicesLoading, error: servicesLoadingErr, data: servicesData } = useGetJSON('/services');
    const { loading: ratingPlatformsLoading, error: ratingPlatformsErr, data: ratingPlatformsData } = useGetJSON(
        '/ratingPlatforms'
    );
    const {
        loading: sendOutListsLoading,
        error: sendOutListsErr,
        data: sendOutListsData,
        refresh: handleReloadSendOutLists
    } = useGetJSON('/sendOuts/sendOutLists');

    const zones = _.get(zoneData, 'zones', []);
    const charities = _.get(charityData, 'charities', []);
    const services = _.get(servicesData, 'services', []);
    const ratingPlatforms = _.get(ratingPlatformsData, 'ratingPlatforms', []);
    const sendOutLists = _.get(sendOutListsData, 'sendOutLists', []);

    const mobilePickupSubServices = useMemo(
        () => _.get(_.find(services, service => service.isMobilePickupService && !service.disabled), 'subServices', []),
        [services]
    );

    const handleCreateList = () => {
        setSendOutListToEdit(null);
        setSendOutListDialogOpen(true);
    };

    const handleEditList = list => {
        setSendOutListToEdit(list);
        setSendOutListDialogOpen(true);
    };

    const handleDownloadTemplate = async event => {
        let csvData = [
            {
                email: '',
                first_name: '',
                last_name: '',
                address: '',
                charity_name: '',
                charity_email: '',
                balance: '',
                containers: '',
                co2_emissions: '',
                energy_saved: '',
                kg_spared: '',
                account_code: '',
                rating_links: '',
                last_location_used: '',
                google_business: '',
                ios_app_store: '',
                google_play_store: '',
                facebook: '',
                yellow_pages_business: '',
                yelp: ''
            }
        ];
        await downloadObjectAsCSV(csvData, 'CustomerListTemplate');
        await handleReloadSendOutLists();
    };

    const handleUploadList = async event => {
        const file = event.target.files[0];
        setSendOutListLoading(`tmp_${file.name}`, true);
        if (!file) return;

        if (file.size > maxFileSize) {
            onSnackbar(`File size exceeds the maximum limit of ${maxFileSize / (1024 * 1024)} MB.`);
            return;
        }

        const formData = new FormData();
        formData.append('file', file);

        try {
            const res = await http.post('/sendOuts/sendOutLists/uploadSendOutList', formData, false, true);

            if (res.ok) {
                onSnackbar('File uploaded successfully');
                await handleReloadSendOutLists();
            } else {
                onSnackbar('File upload failed', 'error');
            }
        } catch (error) {
            onSnackbar(`Error uploading file: ${error.message}`, 'error');
        }
        setSendOutListLoading(`tmp_${file.name}`, false);
    };

    const setSendOutListLoading = (_id, value) => {
        let sendOutListLoadingArray = JSON.parse(localStorage.getItem('sendOutListLoadingArray')) || [];
        let index = sendOutListLoadingArray.indexOf(_id);
        if (value) {
            if (index === -1) {
                sendOutListLoadingArray.push(_id);
            }
        } else {
            if (index !== -1) {
                sendOutListLoadingArray.splice(index, 1);
            }
        }
        localStorage.setItem('sendOutListLoadingArray', JSON.stringify(sendOutListLoadingArray));
        window.dispatchEvent(new Event('storage'));
    };

    const handleSendOutListDialogSubmit = async values => {
        setSendOutListDialogOpen(false);
        if (_.isNil(sendOutListToEdit)) {
            setSendOutListLoading(`tmp_${values.name}`, true);
            const res = await http.post('/sendOuts/sendOutLists/createSendOutList', values);
            if (res.ok) {
                await handleReloadSendOutLists();
            }
            setSendOutListLoading(`tmp_${values.name}`, false);
        } else {
            setSendOutListLoading(`${sendOutListToEdit._id}_${values.name}`, true);
            const res = await http.post(`/sendOuts/sendOutLists/${sendOutListToEdit._id}/update`, values);
            if (res.ok) {
                await handleReloadSendOutLists();
            }
            setSendOutListLoading(`${sendOutListToEdit._id}_${values.name}`, false);
        }
    };

    return (
        <>
            <Grid
                container
                spacing={theme.spacing.unit * 2}
                style={{ padding: theme.spacing.unit, paddingBottom: 0, width: '100%', margin: 0 }}
            >
                <Grid item xs={12}>
                    <Paper
                        style={{
                            padding: theme.spacing.unit
                        }}
                    >
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography variant="h6" style={{ marginBottom: theme.spacing.unit }}>
                                Set User List
                            </Typography>
                            <div style={{ display: 'flex' }}>
                                <>
                                    <Button
                                        component="span"
                                        variant="outlined"
                                        color="primary"
                                        style={{ margin: theme.spacing.unit }}
                                        onClick={handleDownloadTemplate}
                                    >
                                        <Icon>download</Icon>
                                        Download Template
                                    </Button>

                                    <Button
                                        component="span"
                                        variant="outlined"
                                        color="primary"
                                        style={{ margin: theme.spacing.unit }}
                                    >
                                        <label htmlFor="file-upload" style={{ display: 'flex', alignItems: 'center' }}>
                                            <Icon>upload</Icon>
                                            Upload CSV
                                        </label>
                                    </Button>
                                    <input
                                        type="file"
                                        id="file-upload"
                                        onChange={handleUploadList}
                                        accept=".csv"
                                        style={{ display: 'none' }}
                                    />
                                </>

                                <Button
                                    style={{ margin: theme.spacing.unit }}
                                    variant="outlined"
                                    onClick={handleCreateList}
                                    color="primary"
                                >
                                    <Icon style={{ marginRight: theme.spacing.unit }}>add_circle_outline</Icon>
                                    Create List
                                </Button>
                            </div>
                        </div>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper
                        style={{
                            padding: theme.spacing.unit
                        }}
                    >
                        <SendOutListComponent
                            selectionDisabled
                            selectedLists={[]}
                            sendOutLists={sendOutLists}
                            error={false}
                            onChange={() => {}}
                            onReloadSendOutLists={handleReloadSendOutLists}
                            onEditList={handleEditList}
                        />
                    </Paper>
                </Grid>
            </Grid>
            <SendOutListDialog
                sendOutList={sendOutListToEdit}
                open={sendOutListDialogOpen}
                onClose={() => setSendOutListDialogOpen(false)}
                handleSubmit={handleSendOutListDialogSubmit}
                //onReloadSendOutLists={handleReloadSendOutLists}
                collectors={collectors}
                regions={regions}
                zones={zones}
                charities={charities}
                mobilePickupSubServices={mobilePickupSubServices}
                http={http}
            />
        </>
    );
}

export default withTheme()(SendOutLists);
