import React, { useState, useMemo } from 'react';

import _ from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { _time } from 'std';
import { isLocalEnv, isStagingEnv } from 'utils/misc';

import CurrentTime from 'components/Misc/CurrentTime';
import Icon from '@material-ui/core/Icon';

import {
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Typography,
    Grid,
    withTheme,
    withMobileDialog
} from '@material-ui/core';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc, locDate } from 'localizations/localizationHandler';
import { useContext } from 'react';

const columnWidth = 42;

function DatePicker(props) {
    const {
        theme,
        fullScreen,
        inputStyle = {},
        label = 'Date',
        format = 'll',
        timezone = process.env.REACT_APP_REGION_TIMEZONE,
        value,
        availableDates = [],
        helperText,
        disableFuture = false,
        disablePast = false,
        disabled = false,
        type = 'start',
        variant = 'standard',
        autoOk = false,
        InputProps,
        onChange,
        textFieldOverride = '',
        disableTimeChange = false,
        minDate = null,
        maxDate = null,
        FormHelperTextProps = null
    } = props;

    const [open, setOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState(value);
    const [monthToView, setMonthToView] = useState(_time.getStartOfMonthStartOfDayObjFromDate(timezone, value));

    const { lang } = useContext(LocalizationContext);

    const [prevMonthAvailable, nextMonthAvailable] = useMemo(() => {
        return checkNextAndPrevMonthAvailability(monthToView, availableDates);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [monthToView]);

    const handleNextMonth = () => {
        const nextMonthToView = moment(monthToView)
            .tz(timezone)
            .endOf('month')
            .add(1, 'day')
            .startOf('day');
        setMonthToView(nextMonthToView);
    };

    const handlePrevMonth = () => {
        const prevMonthToView = moment(monthToView)
            .tz(timezone)
            .subtract(1, 'day')
            .startOf('month');
        setMonthToView(prevMonthToView);
    };

    const handleOpen = () => {
        if (!disabled) {
            setOpen(true);
            setSelectedValue(value);
            setMonthToView(_time.getStartOfMonthStartOfDayObjFromDate(timezone, value));
        }
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedValue(value);
        setTimeout(() => setMonthToView(_time.getStartOfMonthStartOfDayObjFromDate(timezone, value)), 200); // arbitrary delay to simluate "better" transition
    };

    const handleChange = dateObj => () => {
        if (dateObj.enabled && dateObj.display) {
            if (autoOk) {
                setOpen(false);
                onChange(moment(dateObj.date).tz(timezone));
            }
            if (disableTimeChange) {
                let second = selectedValue.tz(timezone).second();
                let minute = selectedValue.tz(timezone).minute();
                let hour = selectedValue.tz(timezone).hour();
                let newTime = moment(dateObj.date)
                    .tz(timezone)
                    .set({ hour, minute, second });
                setSelectedValue(newTime);
            } else {
                setSelectedValue(moment(dateObj.date).tz(timezone));
            }
        }
    };

    const handleOk = () => {
        if (!selectedValue.isSame(value)) {
            onChange(selectedValue);
        }
        setOpen(false);
    };

    const dates = useMemo(() => {
        const month = monthToView;
        if (month.tz(timezone).hours() === 23) {
            month.add(1, 'hours');
        } else if (month.tz(timezone).hours() === 1) {
            month.subtract(1, 'hours');
        }
        return generateDates(timezone, availableDates, month, disableFuture, type, disablePast, minDate, maxDate);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [monthToView, timezone]);

    const showHover = (e, dateObj) => {
        if (dateObj.enabled && dateObj.display) {
            e.target.style.background = theme.palette.envColor[500];
            e.target.style.color = '#fff';
        }
    };

    const removeHover = (e, dateObj) => {
        const newBgColor = dateObj.isToday ? theme.palette.background.default : theme.palette.background.paper;
        if (dateObj.enabled && dateObj.display) {
            e.target.style.background = newBgColor;
            e.target.style.color = theme.palette.text.primary;
        }
    };

    return (
        <>
            <TextField
                style={inputStyle}
                data-cy="datepicker-input"
                name="date"
                label={label}
                disabled={disabled}
                value={textFieldOverride !== '' ? textFieldOverride : locDate(value, format, lang, timezone)}
                variant={variant}
                InputProps={{
                    readOnly: true,
                    // endAdornment: (
                    //     <InputAdornment position="end">
                    //         <EndAdornment difference={timezoneDifference} />
                    //     </InputAdornment>
                    // ),
                    ...InputProps
                }}
                onClick={handleOpen}
                helperText={helperText}
                FormHelperTextProps={FormHelperTextProps}
                // error={!_.isNil(customerResolutionTextError)}
            />
            <Dialog open={open} onClose={handleClose} fullScreen={fullScreen} fullWidth maxWidth="xs">
                <DialogTitle
                    disableTypography
                    style={{
                        backgroundColor: theme.palette.primary.main,
                        paddingTop: 'max(var(--custom-safe-area-top), 16px)'
                    }}
                >
                    <Typography variant="body1" style={{ color: '#fff' }} data-cy="date-picker-year">
                        {locDate(selectedValue, 'YYYY', lang, timezone)}
                    </Typography>
                    <Typography variant="h4" style={{ color: '#fff' }} data-cy="date-picker-date">
                        {locDate(selectedValue, 'ddd, MMM Do', lang, timezone)}
                    </Typography>
                    {(isLocalEnv || isStagingEnv) && (
                        <Typography variant="caption" style={{ color: '#fff' }}>
                            DEBUG (local): {selectedValue.tz(timezone).format()}
                            <br />
                            DEBUG (UTC): {selectedValue.tz(timezone).toISOString()}
                        </Typography>
                    )}
                </DialogTitle>
                <DialogContent>
                    <div
                        style={{
                            marginTop: theme.spacing.unit,
                            marginBottom: theme.spacing.unit,
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}
                    >
                        <IconButton
                            onClick={handlePrevMonth}
                            id="date-picker-leftarrow-button"
                            disabled={!prevMonthAvailable}
                            data-cy="datePickerTz-previous-month"
                        >
                            <Icon>keyboard_arrow_left</Icon>
                        </IconButton>
                        <span
                            style={{
                                paddingTop: 12,
                                color: theme.palette.text.secondary,
                                fontSize: theme.typography.h6.fontSize
                            }}
                        >
                            <Typography>{locDate(monthToView, 'MMMM YYYY', lang, timezone)}</Typography>
                        </span>
                        <IconButton
                            onClick={handleNextMonth}
                            id="date-picker-leftarrow-button"
                            disabled={!nextMonthAvailable}
                            data-cy="datePickerTz-next-month"
                        >
                            <Icon>keyboard_arrow_right</Icon>
                        </IconButton>
                    </div>
                    <Grid
                        container
                        style={{
                            textAlign: 'center',
                            paddingLeft: 8,
                            marginBottom: dates.length < 42 ? columnWidth + 2 : 0,
                            width: '100%'
                        }}
                    >
                        {['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'].map((d, i) => {
                            return (
                                <Grid item style={{ width: '14.2%', marginBottom: 24 }} key={i}>
                                    <div>
                                        <Typography variant="caption" style={{ textAlign: 'center' }}>
                                            {locDate(
                                                moment()
                                                    .tz(timezone)
                                                    .day(i),
                                                'dd',
                                                lang,
                                                timezone
                                            )}
                                        </Typography>
                                    </div>{' '}
                                </Grid>
                            );
                        })}
                        {dates.map(d => {
                            const dateObj = _time.convertISODateToMomentObject(timezone, d.date);
                            const formattedDate = locDate(dateObj, 'DD', lang, timezone);
                            const pointerType = d.enabled && d.display ? 'pointer' : 'not-allowed';

                            let style = {
                                color:
                                    d.enabled && d.display ? theme.palette.text.primary : theme.palette.text.disabled,
                                cursor: pointerType,
                                fontFamily: theme.typography.fontFamily,
                                width: columnWidth - 2,
                                height: columnWidth - 2,
                                paddingTop: 11,
                                borderRadius: columnWidth / 2,
                                textAlign: 'center',
                                marginRight: 'auto',
                                marginLeft: 'auto'
                            };

                            if (d.enabled) {
                                style.fontWeight = 500;
                            }

                            if (d.isToday) {
                                style.backgroundColor = theme.palette.background.default;
                            }

                            const isSelected =
                                d.display &&
                                moment(selectedValue)
                                    .tz(timezone)
                                    .format('LL') === dateObj.tz(timezone).format('LL');

                            if (isSelected) {
                                style.backgroundColor = theme.palette.envColor[500];
                                style.color = '#fff';
                            }

                            return (
                                <Grid
                                    item
                                    style={{
                                        width: '14.2%',
                                        marginBottom: 4
                                    }}
                                    key={dateObj.toISOString()}
                                >
                                    <div
                                        style={style}
                                        onMouseEnter={e => showHover(e, d)}
                                        onMouseLeave={e => {
                                            if (!isSelected) removeHover(e, d);
                                        }}
                                        onClick={handleChange(d)}
                                        data-cy={`datePickerTz-day-${dateObj.format('YYYY-MM-DD')}-${
                                            d.enabled ? 'enabled' : 'disabled'
                                        }`}
                                    >
                                        {parseInt(formattedDate, 10)}
                                    </div>
                                </Grid>
                            );
                        })}
                    </Grid>

                    {(isLocalEnv || isStagingEnv) && (
                        <>
                            <Typography variant="caption">CURRENT SYSTEM TIME</Typography>
                            <Typography variant="caption">{timezone}</Typography>
                            <Typography variant="caption">
                                <CurrentTime timezone={timezone} />
                            </Typography>
                        </>
                    )}
                </DialogContent>
                <DialogActions style={{ marginBottom: 'max(env(safe-area-inset-bottom, 8px), 8px)' }}>
                    <Button color="default" variant="text" onClick={handleClose} data-cy="date-picker-cancel">
                        Cancel
                    </Button>
                    <Button color="primary" variant="text" onClick={handleOk}>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

DatePicker.propTypes = {
    timezone: PropTypes.string.isRequired,
    value: PropTypes.object,
    availableDates: PropTypes.array,
    disabled: PropTypes.bool,
    variant: PropTypes.string
};

export default withMobileDialog({ breakpoint: 'xs' })(withTheme()(DatePicker));

/**
 *
 * @param {Moment Object} startOfMonth
 * @param {Array} availableDates
 * @return {Tuple} booleans [prevMonthAvailable, nextMonthAvailable]
 */
function checkNextAndPrevMonthAvailability(startOfMonth, availableDates) {
    let prevMonthAvailable = true;
    let nextMonthAvailable = true;
    if (!_.isEmpty(availableDates)) {
        if (availableDates.length > 1) {
            const firstDay = moment(_.first(availableDates));
            const lastDay = moment(_.last(availableDates));

            const endOfMonth = moment(startOfMonth)
                .endOf('month')
                .startOf('day');

            prevMonthAvailable = firstDay.isBefore(startOfMonth);
            nextMonthAvailable = lastDay.isAfter(endOfMonth);
        } else {
            prevMonthAvailable = false;
            nextMonthAvailable = false;
        }
    }

    return [prevMonthAvailable, nextMonthAvailable];
}

/**
 *
 * @param {String} timezone
 * @param {Array} availableDates
 * @param {Moment Object} monthToView
 */
function generateDates(timezone, availableDates, monthToView, disableFuture, type, disablePast, minDate, maxDate) {
    const startOfMonth = _time.getStartOfMonthStartOfDayObjFromDate(timezone, monthToView);
    const endOfMonth = _time.getEndOfMonthStartOfDayObjFromDate(timezone, monthToView);

    const endOfWeekOfEndOfMonth = moment(endOfMonth)
        .tz(timezone)
        .endOf('week');

    let dates = [];

    // Sets date to middle of day
    let date = startOfMonth
        .tz(timezone)
        .startOf('week')
        .hours(12);

    const startOfToday = _time.getStartOfToday(timezone);

    const checkIfInAvailableDates = date => {
        return availableDates.some(availableDate => date.tz(timezone).isSame(availableDate, 'day'));
    };

    while (!date.isAfter(endOfWeekOfEndOfMonth)) {
        const dateIsWithinMonth = date.isSame(monthToView, 'month');
        const dateIsToday = startOfToday.isSame(date, 'day');

        const isFutureDate = startOfToday.isBefore(
            moment(date)
                .tz(timezone)
                .startOf('day')
        );
        const isPastDate = startOfToday.isAfter(
            moment(date)
                .tz(timezone)
                .endOf('day')
        );

        let enabled = dateIsWithinMonth && (_.isEmpty(availableDates) || checkIfInAvailableDates(date));
        if (isFutureDate && disableFuture) {
            enabled = false;
        }

        if (isPastDate && disablePast) {
            enabled = false;
        }

        if (
            !_.isNil(minDate) &&
            moment(minDate).isAfter(
                moment(date)
                    .tz(timezone)
                    .endOf('day'),
                'date'
            )
        ) {
            enabled = false;
        }

        if (
            !_.isNil(maxDate) &&
            moment(maxDate).isBefore(
                moment(date)
                    .tz(timezone)
                    .endOf('day'),
                'date'
            )
        ) {
            enabled = false;
        }

        const newDate =
            type === 'end'
                ? moment(date)
                      .tz(timezone)
                      .endOf('day')
                : moment(date)
                      .tz(timezone)
                      .startOf('day');

        dates.push({
            date: newDate.toISOString(),
            enabled,
            display: dateIsWithinMonth,
            isToday: dateIsToday
        });

        date = date.add(24, 'hours');
    }
    return dates;
}
