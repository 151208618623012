import React, { useContext } from 'react';
import _ from 'lodash';

import { Dialog, DialogTitle, DialogContent, DialogActions, Button, withTheme } from '@material-ui/core';
import * as allIcons from '@mdi/js';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';
import { useMemo } from 'react';
import { useState } from 'react';
import { convertToImportName } from 'utils/misc';
import NumberOfBagsInput from 'components/InputComponents/NumberOfBagsInput';
import { useEffect } from 'react';

function CommodityInventoryDialog(props) {
    const { open, onClose, onSubmit, theme, collector, commodity } = props;
    const { lang } = useContext(LocalizationContext);

    const [commodityAmount, setCommodityAmount] = useState(_.get(collector, `inventory.${_.get(commodity, '_id')}`, 0));

    const commodityName = useMemo(() => {
        return _.get(commodity, _.get(commodity, 'isSubCommodity') ? 'name.en' : 'skutype', 'Item');
    }, [commodity]);

    useEffect(() => {
        setCommodityAmount(_.get(collector, `inventory.${_.get(commodity, '_id')}`, 0));
    }, [collector, commodity]);

    if (_.isNil(commodity)) {
        return <></>;
    }
    return (
        <Dialog open={open} fullWidth onClose={onClose}>
            <DialogTitle>Manage {commodityName} Inventory</DialogTitle>
            <DialogContent>
                <div style={{ marginTop: theme.spacing.unit }} />
                <NumberOfBagsInput
                    required={false}
                    disableEntireInput={false}
                    startAdornmentIcon={_.get(
                        allIcons,
                        commodity.isSubCommodity ? convertToImportName(commodity.iconName) : commodity.iconName
                    )}
                    startAdornmentIconColor={commodity.color}
                    maxNumber={Number.MAX_SAFE_INTEGER}
                    minNumber={0}
                    componentValue={commodityAmount}
                    componentLabel={commodityName + ' Inventory'}
                    componentName={commodityAmount}
                    componentHelperText={''}
                    componentError={commodityAmount < 0}
                    onBags={e => setCommodityAmount(e.target.value)}
                    hasSubCommodity={false}
                    toggleSubCommodityView={false}
                    commodityUnits={''}
                    subPayloadAmount={0}
                    groupedPayloadString={''}
                    subCommoditiesVisible={false}
                    driverView={false}
                />
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={onClose}>
                    {loc('cancel', lang)}
                </Button>
                <Button
                    color="primary"
                    onClick={() => onSubmit(collector, commodity, commodityAmount)}
                    data-cy="commodity-inventory-dialog-save-button"
                >
                    Set
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default withTheme()(CommodityInventoryDialog);
