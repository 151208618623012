import React, { Component, useContext } from 'react';
import _ from 'lodash';

import ExternalLink from 'components/ExternalLink/ExternalLink';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withTheme } from '@material-ui/core/styles';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import Avatar from '@material-ui/core/Avatar';
import Icon from '@material-ui/core/Icon';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import * as colors from '@material-ui/core/colors';
import DialogTitlePrimary from 'components/MaterialUIExtensions/DialogTitlePrimary';

import { deviceHelper } from 'utils/misc';
import * as terms from 'localizations/terms';

import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    RedditShareButton,
    EmailShareButton,
    FacebookIcon,
    TwitterIcon,
    LinkedinIcon,
    RedditIcon,
    EmailIcon
} from 'react-share';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

const iconSize = 40;

const iconStyle = {
    padding: '0px 2px',
    cursor: 'pointer'
};

function CharityStatsDialog(props) {
    const { subject, message, url, open, fullScreen, theme, customersDonating, socialMediaShares, hashtags } = props;
    const { lang } = useContext(LocalizationContext);

    return (
        <Dialog open={open} fullScreen={fullScreen} fullWidth onClose={props.onClose}>
            <DialogTitlePrimary closeButtonShown onClose={props.onClose}>
                {loc('charityStats1', lang, { organization: terms.ORGANIZATION_NAME })}
            </DialogTitlePrimary>
            <DialogContent>
                <List style={{ paddingTop: theme.spacing.unit }}>
                    <Grid container>
                        <Grid item xs={12} lg={6}>
                            <ListItem style={{ paddingLeft: 0, paddingBottom: 0 }}>
                                <Avatar style={{ backgroundColor: colors.green['A700'] }}>
                                    <Icon>people</Icon>
                                </Avatar>
                                <ListItemText primary={customersDonating || 0} secondary={loc('charityStats2', lang)} />
                            </ListItem>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <ListItem style={{ paddingLeft: 0, paddingBottom: 0 }}>
                                <Avatar style={{ backgroundColor: colors.lightBlue[500] }}>
                                    <Icon style={{ position: 'relative', left: -1.5 }}>share</Icon>
                                </Avatar>
                                <ListItemText
                                    primary={socialMediaShares || 0}
                                    secondary={loc('charityStats3', lang)}
                                    style={{ paddingRight: 0 }}
                                />
                            </ListItem>
                        </Grid>
                    </Grid>
                </List>
                <DialogTitle style={{ paddingLeft: 0, paddingTop: theme.spacing.unit }}>
                    {loc('charityStats4', lang, { organization: terms.ORGANIZATION_NAME.toLowerCase() })}
                </DialogTitle>
                <DialogContentText>
                    {loc('charityStats5a', lang)}
                    <strong>
                        {' '}
                        {loc('charityStats5b', lang, {
                            organization: terms.ORGANIZATION_NAME.toLowerCase()
                        })}
                    </strong>
                    {loc('charityStats5c', lang, {
                        organization: terms.ORGANIZATION_NAME.toLowerCase(),
                        brand: process.env.REACT_APP_BRAND_NAME
                    })}
                    :
                </DialogContentText>
                <DialogContentText component="span">
                    <ul>
                        <li>
                            {loc('charityStats6a', lang)}
                            <strong>{process.env.REACT_APP_ORIGIN_URL}</strong> {loc('charityStats6b', lang)}
                        </li>
                        <li>{loc('charityStats7', lang)}</li>
                        <ul>
                            <li>
                                <ExternalLink text="Facebook" url={process.env.REACT_APP_FACEBOOK_LINK} />
                            </li>
                            <li>
                                <ExternalLink text="Instagram" url={process.env.REACT_APP_INSTAGRAM_LINK} />
                            </li>
                            <li>
                                <ExternalLink text="Twitter" url={process.env.REACT_APP_TWITTER_LINK} />
                            </li>
                            <li>
                                <ExternalLink text="LinkedIn" url={process.env.REACT_APP_LINKEDIN_LINK} />
                            </li>
                        </ul>
                        <li>{loc('charityStats8', lang)}</li>
                        <li>{loc('charityStats9', lang)}</li>
                        <li>
                            {loc('charityStats10', lang)}{' '}
                            {process.env.REACT_APP_REGION_EXT !== 'AUS' && (
                                <>
                                    <ExternalLink
                                        text="Google Business"
                                        href={process.env.REACT_APP_GOOGLE_BUSINESS_LINK}
                                    />
                                    /
                                </>
                            )}
                            <ExternalLink text="Facebook" href={`${process.env.REACT_APP_FACEBOOK_LINK}/?ref=py_c`} />/
                            <ExternalLink text="iOS" href={process.env.REACT_APP_APPSTORE_LINK} />/
                            <ExternalLink text="Android" href={process.env.REACT_APP_GOOGLE_PLAY_LINK} />
                        </li>
                    </ul>
                </DialogContentText>
                <DialogContentText>{loc('charityStats11', lang)}</DialogContentText>
                {!deviceHelper.isNativeApp() && _.isNil(navigator.share) && (
                    <div>
                        <div style={{ display: 'inline-flex', marginTop: theme.spacing.unit * 4 }}>
                            <FacebookShareButton
                                style={iconStyle}
                                url={url}
                                hashtag={`#${hashtags[0]}`}
                                quote={message}
                                onShareWindowClose={props.onShare}
                            >
                                <FacebookIcon size={iconSize} round={true} />
                            </FacebookShareButton>
                            <LinkedinShareButton
                                style={iconStyle}
                                url={url}
                                title={subject}
                                description={message}
                                onShareWindowClose={props.onShare}
                            >
                                <LinkedinIcon size={iconSize} round={true} />
                            </LinkedinShareButton>
                            <TwitterShareButton
                                style={iconStyle}
                                url={url}
                                title={message}
                                hashtags={hashtags}
                                via={`${process.env.REACT_APP_BRAND_NAME} App`}
                                onShareWindowClose={props.onShare}
                            >
                                <TwitterIcon size={iconSize} round={true} />
                            </TwitterShareButton>
                            <RedditShareButton
                                style={iconStyle}
                                url={url}
                                title={message}
                                onShareWindowClose={props.onShare}
                            >
                                <RedditIcon size={iconSize} round={true} />
                            </RedditShareButton>
                            <EmailShareButton
                                style={iconStyle}
                                url={url}
                                subject={subject}
                                body={message}
                                onShareWindowClose={props.onShare}
                            >
                                <EmailIcon size={iconSize} round={true} />
                            </EmailShareButton>
                        </div>
                        <DialogContentText component="span">
                            {loc('charityStats12', lang, { organization: terms.ORGANIZATION_NAME.toLowerCase() })}
                        </DialogContentText>
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                <Button color="primary" style={{ margin: theme.spacing.unit }} onClick={props.onReferralDialog(true)}>
                    {loc('charityStats13', lang)}
                </Button>
                {(deviceHelper.isNativeApp() || !_.isNil(window.navigator.share)) && (
                    <Button color="primary" onClick={() => props.onNativeShare(subject, message, url)}>
                        {loc('share', lang)}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
}

export default withMobileDialog({ breakpoint: 'xs' })(withTheme()(CharityStatsDialog));
