import React from 'react';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { withTheme } from '@material-ui/core';

function BulkCollectorSelectionDropdown({
    theme,
    fullWidth,
    helperText,
    loading,
    bulkCollectorFilter,
    handleChange,
    labelCustomWidth,
    collectors = [],
    showAll = true,
    gutterTop = false
}) {
    return (
        <FormControl fullWidth={fullWidth} style={{ marginRight: 8, marginTop: gutterTop ? theme.spacing.unit : 0 }}>
            <InputLabel variant="outlined" htmlFor="collector-filter">
                {helperText}
            </InputLabel>
            <Select
                data-cy="bulk-type-selection-dropdown"
                value={loading ? 'loading' : bulkCollectorFilter}
                onChange={handleChange}
                disabled={loading}
                inputProps={{
                    name: 'collector-filter',
                    id: 'collector-filter'
                }}
                input={<OutlinedInput labelWidth={labelCustomWidth || 36} name="collector" id="outlined-age-simple" />}
                style={{
                    minWidth: 125
                }}
            >
                {loading && (
                    <MenuItem value="loading">
                        <em>Loading...</em>
                    </MenuItem>
                )}
                {showAll && (
                    <MenuItem value="all">
                        <em>All</em>
                    </MenuItem>
                )}
                {collectors.map(c => {
                    return (
                        <MenuItem value={c._id} key={c._id}>
                            {c.name}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
}

export default withTheme()(BulkCollectorSelectionDropdown);
