import React, { useMemo, useContext } from 'react';

import { REPORT_TYPES } from 'constants.js';

import _ from 'lodash';

import DialogTitlePrimary from 'components/MaterialUIExtensions/DialogTitlePrimary';
import CustomFormTitle from 'components/MaterialUIExtensions/CustomFormTitle';

import ImageCapture from 'components/ImageUploads/ImageCapture';

import {
    Icon,
    Button,
    FormControl,
    DialogContent,
    DialogActions,
    Dialog,
    TextField,
    FormGroup,
    Checkbox,
    FormControlLabel,
    withMobileDialog,
    CircularProgress,
    Typography,
    Grid
} from '@material-ui/core';
import * as colors from '@material-ui/core/colors';
import { withTheme } from '@material-ui/core/styles';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

function BulkReportDialog(props) {
    const {
        fullScreen,
        theme,
        open,
        inProgress,
        onClose,
        reportReasons,
        reportPreviews,
        reportIssueDescription,
        nonConformantIssue,
        nonConformantWarning,
        onSubmit,
        onUndo,
        onReportIssueDescription,
        onReasonsChange,
        handleAddImage,
        handleDeleteImage,
        isCustomerReported,
        isDriverReported,
        reportType,
        reportOptions
    } = props;

    const { lang } = useContext(LocalizationContext);

    const memoizedReasonsAvailable = useMemo(
        () =>
            reportOptions
                .filter(option => {
                    if (reportType === REPORT_TYPES.CUSTOMER && !option.driverIssue) return true;
                    if (reportType === REPORT_TYPES.DRIVER && option.driverIssue) return true;
                    return false;
                })
                .map((reason, index) => {
                    return reason.appliedBySystem && _.indexOf(reportReasons, reason.code) === -1 ? (
                        <></>
                    ) : (
                        <Grid item xs={12} sm={6}>
                            <FormControlLabel
                                key={reason.label}
                                control={
                                    <Checkbox
                                        checked={_.indexOf(reportReasons, reason.code) !== -1}
                                        onChange={onReasonsChange(reason.code)}
                                        value={reason.code}
                                        style={{
                                            paddingTop: theme.spacing.unit / 2,
                                            paddingBottom: theme.spacing.unit / 2,
                                            marginLeft: theme.spacing.unit
                                        }}
                                        disabled={reason.appliedBySystem}
                                    />
                                }
                                label={reason.label}
                            />
                        </Grid>
                    );
                }),
        [onReasonsChange, reportReasons, theme]
    );

    const nonConformantIssueText = _.get(
        _.find(reportOptions, option => option.code === nonConformantIssue),
        'label',
        'Ineligible Containers'
    );

    return (
        <Dialog data-cy="multi-step-dialog" fullScreen={fullScreen} fullWidth open={open}>
            <DialogTitlePrimary>Reporting</DialogTitlePrimary>
            <DialogContent>
                <div style={{ marginTop: theme.spacing.unit * 2 }}>
                    <CustomFormTitle titleText="Select all that apply" iconName="message" />
                    <FormControl fullWidth>
                        <FormGroup>
                            <Grid container spacing={theme.spacing.unit / 2}>
                                {memoizedReasonsAvailable}
                            </Grid>
                        </FormGroup>
                    </FormControl>
                </div>
                {reportReasons.includes(nonConformantIssue) && nonConformantWarning && (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: theme.spacing.unit * 2,
                            marginLeft: theme.spacing.unit * 2
                        }}
                    >
                        <Icon style={{ color: colors.red[300] }}>warning</Icon>
                        <Typography style={{ marginLeft: theme.spacing.unit, color: colors.grey[700] }}>
                            {`The report has been updated to include ${nonConformantIssueText}. Please make sure you have an image
                            selected and submit this form once completed.`}
                        </Typography>
                    </div>
                )}
                {reportType !== REPORT_TYPES.DRIVER && (
                    <div style={{ marginTop: theme.spacing.unit * 2 }}>
                        <CustomFormTitle titleText="Upload photo" iconName="insert_photo" />
                        <ImageCapture
                            imageURLs={reportPreviews}
                            handleAddImage={handleAddImage}
                            handleDeleteImage={handleDeleteImage}
                            placeholderText={
                                <span>
                                    Upload or take a photo to provide information about the hazard and/or ineligible
                                    containers.
                                    <br />
                                    This will be displayed to the {reportType}.
                                </span>
                            }
                            condensed={true}
                        />
                    </div>
                )}
                <div style={{ marginTop: theme.spacing.unit * 2 }}>
                    <FormControl style={{ marginBottom: theme.spacing.unit }} fullWidth>
                        <TextField
                            data-cy="porscreen-description-input"
                            multiline
                            rows="2"
                            name="description"
                            label="Description"
                            value={reportIssueDescription}
                            variant="outlined"
                            onChange={onReportIssueDescription}
                        />
                    </FormControl>
                </div>
            </DialogContent>
            <DialogActions>
                <Button color="default" onClick={onClose}>
                    Cancel
                </Button>
                <Button
                    color="secondary"
                    disabled={
                        (reportType === REPORT_TYPES.CUSTOMER && !isCustomerReported) ||
                        (reportType === REPORT_TYPES.DRIVER && !isDriverReported)
                    }
                    onClick={onUndo}
                >
                    Undo
                </Button>
                <Button
                    color="primary"
                    disabled={!(reportReasons.length > 0) || inProgress}
                    onClick={onSubmit}
                    data-cy="3-pickupdialog-next-button"
                >
                    {loc('submit', lang)}
                    <CircularProgress
                        size={22}
                        thickness={4.8}
                        style={{
                            display: inProgress ? '' : 'none',
                            marginLeft: theme.spacing.unit / 2
                        }}
                    />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default withMobileDialog({ breakpoint: 'xs' })(withTheme()(BulkReportDialog));
