import React from 'react';

import _ from 'lodash';

import AustralianFinancialInfoInputs from '../../InputComponents/AustralianFinancialInfoInputs';
import { containsErrors } from '../../../utils/validate';

import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import CustomFormTitle from 'components/MaterialUIExtensions/CustomFormTitle';

const AusDirectBanking = ({
    askForPreviousBankInfo,
    theme,
    banking,
    handleUsePreviousBankingInformation,
    redemptionForm,
    handleChange,
    usePreviousBankingInfo,
    formErrors,
    donationDisabled,
    handleBack,
    handleNext
}) => {
    return (
        <>
            <DialogContent>
                <CustomFormTitle titleText="EFT information" iconName="account_balance_wallet" />
                {askForPreviousBankInfo ? (
                    <DialogContentText>
                        EFT information from previous redemption:
                        <br />
                        <br />
                        Account Number:{' '}
                        <span style={{ color: theme.palette.text.primary }}>
                            {_.get(banking, 'accountNumberMasked', 'N/A')}
                        </span>
                        <Button
                            color="primary"
                            size="small"
                            style={{
                                position: 'relative',
                                top: -2,
                                marginLeft: theme.spacing.unit / 2
                            }}
                            onClick={() => handleUsePreviousBankingInformation(false)}
                        >
                            Change
                        </Button>
                    </DialogContentText>
                ) : (
                    <AustralianFinancialInfoInputs
                        bsbNumber={redemptionForm.bsbNumber}
                        accountNumber={redemptionForm.accountNumber}
                        accountName={redemptionForm.accountName}
                        usePreviousBankingInfo={usePreviousBankingInfo}
                        formErrors={formErrors}
                        onChange={handleChange}
                    />
                )}
            </DialogContent>
            <DialogActions style={{ marginBottom: 'max(8px, env(safe-area-inset-bottom, 8px))' }}>
                {!donationDisabled && (
                    <Button color="primary" onClick={handleBack}>
                        Back
                    </Button>
                )}
                <Button
                    data-cy="redemption-dialog-next"
                    color="primary"
                    disabled={containsErrors(formErrors) && !usePreviousBankingInfo}
                    onClick={handleNext}
                >
                    Next
                </Button>
            </DialogActions>
        </>
    );
};

export default AusDirectBanking;
