import React, { useState, useEffect } from 'react';

import _ from 'lodash';

import {
    Dialog,
    DialogContent,
    DialogActions,
    Typography,
    withTheme,
    Button,
    FormGroup,
    FormControlLabel,
    Checkbox,
    FormLabel,
    FormControl,
    FormHelperText,
    Switch,
    LinearProgress
} from '@material-ui/core';
import * as terms from 'localizations/terms';

import { getDistanceFromLatLonInKm } from 'utils/latlngFunctions';

const getCheckBoxStates = (regions, organizationSelected) => {
    return regions.map(region => {
        let featured = false;
        if (organizationSelected.regionsFeaturedIn && organizationSelected.isFeatured) {
            featured = _.find(organizationSelected.regionsFeaturedIn, city => city._id === region._id) !== undefined;
        }
        return { region, feature: featured };
    });
};

function FeatureCharityDialog(props) {
    const {
        organizationSelected,
        regions,
        featureDialogOpen,
        handleFeatureDialogClose,
        handleFeatureApprove,
        handleFeatureRemove,
        handleFeatureEditCities,
        theme,
        loading
    } = props;

    let closestCity = null;
    if (
        !_.isNil(organizationSelected.location) &&
        organizationSelected.location.lat &&
        organizationSelected.location.lng
    ) {
        closestCity = _.minBy(regions, city => {
            return getDistanceFromLatLonInKm(
                organizationSelected.location.lat,
                organizationSelected.location.lng,
                city.lat,
                city.lng
            );
        });
    }

    const [checkboxStates, setCheckBoxesStates] = useState(getCheckBoxStates(regions, organizationSelected));

    const [disableAutoFeatureAndUnfeature, setDisableAutoFeatureAndUnfeature] = useState(
        organizationSelected.disableAutoFeatureAndUnfeature
    );

    useEffect(() => {
        setCheckBoxesStates(getCheckBoxStates(regions, organizationSelected));
    }, [organizationSelected, regions, featureDialogOpen]);

    const onCheckboxChange = index => event => {
        let checkboxStatesCopy = _.cloneDeep(checkboxStates);
        checkboxStatesCopy[index].feature = event.target.checked;
        setCheckBoxesStates(checkboxStatesCopy);
    };

    const inputValid = _.sumBy(checkboxStates, 'feature') > 0;

    const regionsFeaturedIn = _.filter(checkboxStates, checkboxState => checkboxState.feature).map(
        checkboxState => checkboxState.region._id
    );

    return (
        <Dialog fullWidth open={featureDialogOpen} onClose={handleFeatureDialogClose} aria-labelledby="Dialog">
            <DialogContent>
                {!organizationSelected.approved ? (
                    <Typography variant="subtitle1" style={{ marginBottom: theme.spacing.unit }}>
                        To feature an {terms.ORGANIZATION_NAME.toLowerCase()}, it must first be approved.
                    </Typography>
                ) : (
                    <>
                        <Typography variant="subtitle1" style={{ marginBottom: theme.spacing.unit }}>
                            {organizationSelected.isFeatured ? (
                                <>
                                    <span style={{ fontWeight: '500' }}>Edit Charity: </span>{' '}
                                    <span style={{ color: theme.palette.primary.main }}>
                                        {organizationSelected.name}
                                    </span>
                                </>
                            ) : (
                                <>
                                    Would you like to <span style={{ fontWeight: '500' }}>approve</span>{' '}
                                    <span style={{ color: theme.palette.primary.main }}>
                                        {organizationSelected.name}
                                    </span>{' '}
                                    as a featured charity?
                                </>
                            )}
                        </Typography>

                        <Typography variant="subtitle1" style={{ marginBottom: theme.spacing.unit * 2 }}>
                            {!_.isNil(closestCity)
                                ? `Closest city to charity: ${closestCity.name}`
                                : 'Charity does not have its latitude or longitude set'}
                        </Typography>
                        <FormControl component="fieldset" error={!inputValid}>
                            <FormLabel component="legend">Feature charity in:</FormLabel>
                            <FormGroup row>
                                {regions.map((region, index) => {
                                    return (
                                        <FormControlLabel
                                            key={region._id}
                                            control={
                                                <Checkbox
                                                    data-cy={`feature-charity-dialog-region-${region.name}`}
                                                    checked={checkboxStates[index].feature}
                                                    onChange={onCheckboxChange(index)}
                                                />
                                            }
                                            label={region.name}
                                        />
                                    );
                                })}
                            </FormGroup>
                            <FormHelperText>{inputValid ? '' : 'You must select at least one city'}</FormHelperText>
                        </FormControl>
                        <div>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={disableAutoFeatureAndUnfeature}
                                        onChange={event => setDisableAutoFeatureAndUnfeature(event.target.checked)}
                                        color="primary"
                                    />
                                }
                                label={`Disable Auto Featuring/Unfeaturing On This ${terms.ORGANIZATION_NAME}`}
                            />
                        </div>
                    </>
                )}
                {loading && <LinearProgress />}
            </DialogContent>
            <DialogActions>
                <Button color="default" onClick={handleFeatureDialogClose}>
                    Cancel
                </Button>
                {organizationSelected.approved && !organizationSelected.isFeatured && (
                    <Button
                        data-cy="feature-charity-dialog-feature"
                        color="primary"
                        onClick={() => {
                            handleFeatureApprove(regionsFeaturedIn, disableAutoFeatureAndUnfeature);
                        }}
                        disabled={!inputValid}
                    >
                        Approve
                    </Button>
                )}
                {organizationSelected.approved && organizationSelected.isFeatured && (
                    <>
                        <Button
                            color="primary"
                            onClick={() => {
                                handleFeatureEditCities(regionsFeaturedIn, disableAutoFeatureAndUnfeature);
                            }}
                            disabled={!inputValid}
                        >
                            Update
                        </Button>
                        <Button
                            color="secondary"
                            onClick={() => handleFeatureRemove(disableAutoFeatureAndUnfeature)}
                            data-cy="feature-charity-dialog-unfeature"
                        >
                            unfeature
                        </Button>
                    </>
                )}
            </DialogActions>
        </Dialog>
    );
}

export default withTheme()(FeatureCharityDialog);
