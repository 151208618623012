import React from 'react';

import { withTheme } from '@material-ui/core/styles';
import AdminTable from '../../../components/CRUDTables/AdminTable';

function Admins(props) {
    return <AdminTable {...props} />;
}

export default withTheme()(Admins);
