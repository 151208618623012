import React, { useState, useContext } from 'react';

import _ from 'lodash';
import moment from 'moment-timezone';

import {
    Grid,
    withTheme,
    Paper,
    FormControl,
    Select,
    MenuItem,
    Button,
    Icon,
    Tooltip,
    withStyles,
    InputLabel,
    Checkbox,
    ListItemText,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TableFooter,
    Typography,
    IconButton,
    CircularProgress
} from '@material-ui/core';

import useDateRangePicker from 'components/DateTimePickersTz/hooks/useDateRangePicker';
import DateRangePicker from 'components/DateTimePickersTz/DateRangePicker';

import IconCard from 'components/IconCard/IconCard';

import HttpContext from 'utils/contexts/HttpContext';

import { _time } from 'std';
import { downloadObjectAsCSV, formatAsCurrency } from 'utils/misc';
import { collect } from '@turf/turf';
import { useEffect } from 'react';

const styles = theme => ({
    tooltip: {
        maxWidth: 300
    }
});

function Reconciliation(props) {
    const { theme, classes } = props;

    const [loading, setLoading] = useState(false);
    const [balanceSheetData, setBalanceSheetData] = useState([]);
    const [incomeStatmentData, setIncomeStatmentData] = useState([]);
    const [depositSummaryData, setDepositySummaryData] = useState([]);
    const [collectorsLoading, setCollectorsLoading] = useState(false);
    const [collectors, setCollectors] = useState([]);
    const [collectorsSelected, setCollectorsSelected] = useState([]);
    const [downloadingReports, setDownloadingReports] = useState(false);

    const http = useContext(HttpContext);

    useEffect(() => {
        (async () => {
            setCollectorsLoading(true);
            const res = await http.getJSON('/collectors?includeDisabled=true');
            if (res.ok) {
                setCollectors(res.data.collectors);
                setCollectorsSelected(_.get(res.data, 'collectors', []).map(c => c._id));
            }
            setCollectorsLoading(false);
        })();
    }, []);

    const {
        startDate,
        endDate,
        timezone,
        dateWindow,
        handleChangeStartDate,
        handleChangeEndDate,
        handleGoForwards,
        handleGoBackwards,
        handleWindowSelect
    } = useDateRangePicker({
        initDateWindow: 'allTime',
        saveStateInURL: true,
        timezones: [process.env.REACT_APP_REGION_TIMEZONE],
        initStartVal: moment(new Date(2018, 0, 1)),
        initEndVal: moment(new Date())
            .tz(process.env.REACT_APP_REGION_TIMEZONE)
            .endOf('day')
            .subtract(1, 'month')
            .endOf('month')
    });

    const addInfoIconToName = (name, info) => (
        <span style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ marginRight: theme.spacing.unit }}>{name}</span>
            <Tooltip classes={{ tooltip: classes.tooltip }} title={info}>
                <Icon style={{ color: theme.palette.text.secondary }} fontSize="small">
                    info
                </Icon>
            </Tooltip>
        </span>
    );

    const handleGetReport = async () => {
        setLoading(true);
        const res = await http.getJSON(
            `/system/reconciliationReport?startDate=${startDate
                .toDate()
                .toISOString()}&endDate=${endDate.toDate().toISOString()}&collectors=${
                collectorsSelected.length === collectors.length ? 'all' : collectorsSelected
            }`
        );
        if (res.ok) {
            const report = res.data.report;
            setBalanceSheetData([
                {
                    name: 'Uninvoiced Deposits',
                    value: formatAsCurrency(report.uninvoicedDeposits),
                    title: '',
                    formatName: name =>
                        addInfoIconToName(
                            name,
                            'Should be a small discrepancy.  App pulls data from order objects with exact dates, QBO pulls data from invoices, which are issued on Mondays and only have the data from the previous week.'
                        )
                },
                {
                    name: 'Deposits Payable',
                    value: formatAsCurrency(report.depositsPayable),
                    title: '',
                    formatName: name =>
                        addInfoIconToName(
                            name,
                            'Should be a small discrepancy.  App pulls data from order objects with exact dates, QBO pulls data from invoices, which are issued on Mondays and only have the data from the previous week.'
                        )
                }
            ]);
            setIncomeStatmentData([
                {
                    name: 'Bank Fee Income',
                    value: formatAsCurrency(report.redemptionFees),
                    title: '',
                    formatName: name => addInfoIconToName(name, 'Should match exactly.')
                },
                {
                    name: 'Referral Expense',
                    value: formatAsCurrency(report.referralExpense),
                    title: '',
                    formatName: name => addInfoIconToName(name, 'Should match exactly.')
                },
                {
                    name: 'Deposit Service Fee Income',
                    value: formatAsCurrency(report.totalServiceFees),
                    title: '',
                    formatName: name =>
                        addInfoIconToName(
                            name,
                            'Should be a small discrepancy.  App pulls data from order objects with exact dates, QBO pulls data from invoices, which are issued on Mondays and only have the data from the previous week.'
                        )
                }
            ]);

            let depositSummary = [
                {
                    name: 'Uninvoiced Deposits',
                    debit: report.uninvoicedDeposits,
                    credit: 0,
                    formatName: name =>
                        addInfoIconToName(
                            name,
                            'Should be a small discrepancy.  App pulls data from order objects with exact dates, QBO pulls data from invoices, which are issued on Mondays and only have the data from the previous week.'
                        )
                },
                {
                    name: 'Deposits Payable',
                    debit: 0,
                    credit: report.depositsPayable,
                    formatName: name =>
                        addInfoIconToName(
                            name,
                            'Should be a small discrepancy.  App pulls data from order objects with exact dates, QBO pulls data from invoices, which are issued on Mondays and only have the data from the previous week.'
                        )
                }
            ];
            _.keys(report.allFees).forEach(key => {
                depositSummary.push({
                    name: key,
                    debit: 0,
                    credit: _.get(report.allFees, key, 0),
                    formatName: name =>
                        addInfoIconToName(
                            name,
                            'Should be a small discrepancy.  App pulls data from order objects with exact dates, QBO pulls data from invoices, which are issued on Mondays and only have the data from the previous week.'
                        )
                });
            });
            depositSummary.push({
                name: 'Bank Fee Income',
                debit: 0,
                credit: report.redemptionFees,
                formatName: name => addInfoIconToName(name, 'Should match exactly.')
            });
            depositSummary.push({
                name: 'Referral Expense',
                debit: 0,
                credit: report.referralExpense,
                formatName: name => addInfoIconToName(name, 'Should match exactly.')
            });
            setDepositySummaryData(depositSummary);
        }
        setLoading(false);
    };

    const handleReportDownload = async type => {
        setDownloadingReports(true);
        let date = `${moment().format('YYYY-MM-DD HH:mm')}`;

        const name = 'deposit_summary';
        const report = depositSummaryData.map(item => {
            return {
                Name: item.name,
                Debit: item.debit === 0 ? '' : _.round(item.debit / 100, 2),
                Credit: item.credit === 0 ? '' : _.round(item.credit / 100, 2)
            };
        });
        report.push({
            Name: 'Totals',
            Debit: _.sumBy(depositSummaryData, 'debit') / 100,
            Credit: _.sumBy(depositSummaryData, 'credit') / 100
        });
        const fileName = `${name}_${date}`;
        try {
            await downloadObjectAsCSV(report, fileName);
        } catch (err) {
            console.log(err);
        }
        setDownloadingReports(false);
    };

    const columns = [
        {
            key: 'name',
            header: 'Name',
            formatValue: (name, test, document) => {
                return !_.isNil(document.formatName) ? document.formatName(name) : name;
            }
        },
        {
            key: 'debit',
            header: 'Debits',
            formatValue: value => {
                return value === 0 ? '-' : formatAsCurrency(value);
            },
            displayTotal: value => {
                return value === 0 ? '-' : formatAsCurrency(value);
            }
        },
        {
            key: 'credit',
            header: 'Credits',
            formatValue: value => {
                return value === 0 ? '-' : formatAsCurrency(value);
            },
            displayTotal: value => {
                return value === 0 ? '-' : formatAsCurrency(value);
            }
        }
    ];

    return (
        <div style={{ maxWidth: '100%', padding: theme.spacing.unit * 2 }}>
            <Grid container spacing={theme.spacing.unit * 2}>
                <Grid item xs={12}>
                    <div>
                        <Paper
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                padding: theme.spacing.unit * 2,
                                height: '100%',
                                flexWrap: 'wrap'
                            }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <DateRangePicker
                                    timezone={timezone}
                                    startDate={startDate}
                                    endDate={endDate}
                                    window={dateWindow}
                                    disabled={loading}
                                    handlePrevious={handleGoBackwards}
                                    handleNext={handleGoForwards}
                                    handleChangeStartDate={handleChangeStartDate}
                                    handleChangeEndDate={handleChangeEndDate}
                                />
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexWrap: 'wrap'
                                }}
                            >
                                <FormControl>
                                    <Select
                                        value={dateWindow}
                                        disabled={loading}
                                        onChange={handleWindowSelect}
                                        style={{
                                            maxWidth: '250px',
                                            margin: theme.spacing.unit
                                        }}
                                    >
                                        <MenuItem value="today">Daily</MenuItem>
                                        <MenuItem value="thisWeek">Weekly</MenuItem>
                                        <MenuItem value="thisMonth">Monthly</MenuItem>
                                        <MenuItem value="thisQuarter">Quarterly</MenuItem>
                                        <MenuItem value="allTime">All Time</MenuItem>
                                    </Select>
                                </FormControl>

                                {!collectorsLoading && (
                                    <FormControl variant="outlined">
                                        <InputLabel htmlFor="collection">Depot Filters</InputLabel>
                                        <Select
                                            multiple
                                            onChange={e => {
                                                setCollectorsSelected(e.target.value);
                                            }}
                                            style={{
                                                width: '250px',
                                                margin: theme.spacing.unit
                                            }}
                                            value={collectorsSelected}
                                            renderValue={s => `${s.length} Collectors`}
                                        >
                                            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                <Button
                                                    onClick={e => {
                                                        e.stopPropagation();
                                                        setCollectorsSelected(collectors.map(c => c._id));
                                                    }}
                                                >
                                                    All
                                                </Button>
                                                <Button
                                                    onClick={e => {
                                                        e.stopPropagation();
                                                        setCollectorsSelected([]);
                                                    }}
                                                >
                                                    None
                                                </Button>
                                            </div>
                                            {collectors.map(({ _id, name }) => (
                                                <MenuItem key={_id} value={_id}>
                                                    <Checkbox checked={collectorsSelected.includes(_id)} />
                                                    <ListItemText>{name}</ListItemText>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                )}
                                <Button color="primary" variant="contained" onClick={handleGetReport}>
                                    <Icon>search</Icon>
                                </Button>

                                {downloadingReports ? (
                                    <CircularProgress
                                        style={{
                                            marginRight: theme.spacing.unit * 2,
                                            marginLeft: theme.spacing.unit * 2
                                        }}
                                        size={32}
                                    />
                                ) : (
                                    <IconButton
                                        disabled={loading || _.isEmpty(depositSummaryData)}
                                        color="primary"
                                        style={{
                                            marginRight: theme.spacing.unit,
                                            marginLeft: theme.spacing.unit
                                        }}
                                        onClick={handleReportDownload}
                                    >
                                        <Icon>get_app</Icon>
                                    </IconButton>
                                )}
                            </div>
                        </Paper>
                    </div>
                </Grid>
                {!loading && !_.isEmpty(depositSummaryData) && (
                    <Grid item xs={12}>
                        <Paper style={{ width: '100%', paddingTop: theme.spacing.unit, margin: 0 }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {columns.map(column => {
                                            return (
                                                <TableCell
                                                    key={column.header}
                                                    style={{
                                                        paddingLeft: theme.spacing.unit,
                                                        paddingRight: theme.spacing.unit
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            width: column.width,
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis'
                                                        }}
                                                    >
                                                        {column.header}
                                                    </span>
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                </TableHead>
                                <TableBody style={{ whiteSpace: 'nowrap' }}>
                                    {depositSummaryData.map((document, index) => {
                                        return (
                                            <TableRow key={index}>
                                                {columns.map(column => {
                                                    return (
                                                        <TableCell
                                                            key={column.key}
                                                            style={{
                                                                paddingLeft: theme.spacing.unit,
                                                                paddingRight: theme.spacing.unit
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    width: column.width,
                                                                    overflow: 'hidden',
                                                                    textOverflow: 'ellipsis'
                                                                }}
                                                            >
                                                                {_.isNil(column.formatValue)
                                                                    ? _.get(document, column.key)
                                                                    : column.formatValue(
                                                                          _.get(document, column.key),
                                                                          column,
                                                                          document
                                                                      )}
                                                            </div>
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                                <TableFooter>
                                    <TableRow style={{ height: theme.spacing.unit }}>
                                        {columns.map((column, index) => (
                                            <TableCell
                                                key={column.header}
                                                align="left"
                                                style={{
                                                    paddingRight: theme.spacing.unit,
                                                    paddingLeft: theme.spacing.unit,
                                                    width: column.width,
                                                    height: 50,
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis'
                                                }}
                                            >
                                                {!_.isNil(column.displayTotal) && (
                                                    <Tooltip title="Total">
                                                        <Typography
                                                            variant="body1"
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                fontWeight: 'bold'
                                                            }}
                                                        >
                                                            {column.displayTotal(
                                                                _.sumBy(depositSummaryData, column.key)
                                                            )}
                                                        </Typography>
                                                    </Tooltip>
                                                )}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </Paper>
                    </Grid>
                )}

                {/* <IconCard
                    title="Balance Sheet"
                    icon="balance"
                    color="pink"
                    data={balanceSheetData}
                    width={6}
                    loading={loading}
                />
                <IconCard
                    title="Income Statement"
                    icon="attach_money"
                    color="green"
                    data={incomeStatmentData}
                    width={6}
                    loading={loading}
                /> */}
            </Grid>
        </div>
    );
}

export default withStyles(styles)(withTheme()(Reconciliation));
