import { useState, useMemo, useCallback, useEffect } from 'react';

import _ from 'lodash';
import { _pickup } from 'std';

import { setQueryStringValue, getQueryStringValue } from 'utils/query';

function useSelection(trips, allPickups, commoditiesAvailable) {
    const [pickupIdSelected, setPickupIdSelected] = useState(null);
    const [draggablePickupsSelected, setDraggablePickupsSelected] = useState([]);
    const [pickupIdsChecked, setPickupIdsChecked] = useState([]);
    const [tripIdsUnchecked, setTripIdsUnchecked] = useState([]);
    const [pinSelected, setPinSelected] = useState(null);

    const unselectEverything = () => {
        setPickupIdSelected(null);
        setDraggablePickupsSelected([]);
        setPickupIdsChecked([]);
        setPinSelected(null);
    };

    useEffect(() => {
        // grabs the trip from the URL
        const trip = getQueryStringValue('trip');
        if (!_.isEmpty(trip)) {
            const tripIds = trips.map(t => t._id);
            setTripIdsUnchecked(
                _(tripIds)
                    .filter(t => t !== trip)
                    .value()
            );
        }
    }, [trips]);

    const handleMapClick = useCallback(props => {
        unselectEverything();
    }, []);

    const handlePickupClick = useCallback(
        (pickup, shiftPressed, ctrlPressed) => props => {
            if (!_.isNil(props) && shiftPressed) {
                if (!pickupIdsChecked.includes(pickup._id)) {
                    setPickupIdsChecked(pickupIdsChecked.concat(pickup._id));
                    setPickupIdSelected(pickup._id);
                } else {
                    setPickupIdsChecked(_.without(pickupIdsChecked, pickup._id));
                    setPickupIdSelected(null);
                }
                setDraggablePickupsSelected([]);
            } else if (!_.isNil(props) && ctrlPressed) {
                if (!draggablePickupsSelected.includes(pickup._id)) {
                    setDraggablePickupsSelected(draggablePickupsSelected.concat(pickup._id));
                } else {
                    setDraggablePickupsSelected(_.without(draggablePickupsSelected, pickup._id));
                }
            } else {
                setDraggablePickupsSelected([]);
                setPickupIdsChecked([pickup._id]);
                setPickupIdSelected(pickup._id);
            }
        },
        [pickupIdsChecked, draggablePickupsSelected]
    );

    const handlePinClick = useCallback(pin => {
        unselectEverything();
        setPinSelected(pin);
    }, []);

    const handleCheckPickup = pickup => event => {
        setPinSelected(null);
        if (event.target.checked) {
            handlePickupClick(pickup, false)();
            setPickupIdsChecked(pickupIdsChecked.concat(pickup._id));
        } else {
            const newPickupIdsChecked = _.without(pickupIdsChecked, pickup._id);
            setPickupIdsChecked(newPickupIdsChecked);
            if (pickup._id === pickupIdSelected) {
                setPickupIdSelected(_.isEmpty(newPickupIdsChecked) ? null : _.first(newPickupIdsChecked));
            }
        }
    };

    const handleCheckPickups = pickups => event => {
        setPinSelected(null);
        if (pickups.length === 0) return;
        if (event.target.checked) {
            handlePickupClick(_.first(pickups), false)();
            setPickupIdsChecked(pickupIdsChecked.concat(...pickups.map(p => p._id)));
        } else {
            const newPickupIdsChecked = _.without(pickupIdsChecked, ...pickups.map(p => p._id));
            setPickupIdsChecked(newPickupIdsChecked);
            if (!newPickupIdsChecked.includes(pickupIdSelected)) {
                setPickupIdSelected(_.isEmpty(newPickupIdsChecked) ? null : _.first(newPickupIdsChecked));
            }
        }
    };

    const handleRectangleSelectPickups = pickup_ids => {
        setPinSelected(null);
        if (!_.isEmpty(pickup_ids)) {
            setPickupIdSelected(pickup_ids[0]);
            setPickupIdsChecked([...pickupIdsChecked, ...pickup_ids]);
        }
    };

    const handleCheckTrip = trip => event => {
        setPinSelected(null);
        if (event.target.checked) {
            setTripIdsUnchecked(_.without(tripIdsUnchecked, trip._id));
        } else {
            setTripIdsUnchecked(tripIdsUnchecked.concat(trip._id));
        }
        setQueryStringValue('trip', null);
    };

    const pickupSelected = useMemo(() => {
        let pickup = _.find(allPickups, p => p._id === pickupIdSelected) || {};
        if (!_.isNil(pickup.trip) && !_.isObject(pickup.trip)) {
            let trip = _.find(trips, t => t._id === pickup.trip);
            pickup.trip = trip;
        }
        return pickup;
    }, [allPickups, pickupIdSelected]);

    const pickupsChecked = useMemo(() => _.filter(allPickups, p => pickupIdsChecked.includes(p._id)), [
        allPickups,
        pickupIdsChecked
    ]);

    const selectedNums = useMemo(() => {
        const bags = pickupsChecked.reduce(
            (sum, pickup) => sum + _pickup.numberOfPayloadBags(pickup, commoditiesAvailable),
            0
        );

        return { pickups: pickupsChecked.length, bags };
    }, [pickupsChecked]);

    if (!_.isEmpty(pickupsChecked)) {
        // DEBUG do not remove! Has to be in prod!
        console.log('✔ pickupsChecked', '[' + pickupsChecked.map(p => `ObjectId("${p._id}")`).join(', ') + ']');
    }

    return {
        pickupSelected,
        pickupsChecked,
        pickupIdSelected, // not used in PickupsAdmin/PickupsManager
        pickupIdsChecked,
        pinSelected,
        tripIdsUnchecked,
        selectedNums,
        unselectEverything,
        handleMapClick,
        handlePickupClick,
        handlePinClick,
        handleCheckPickup,
        handleCheckPickups,
        handleRectangleSelectPickups,
        handleCheckTrip,
        draggablePickupsSelected
    };
}

export default useSelection;
