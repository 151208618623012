import React, { useState, useContext } from 'react';

import { Paper, Typography, TextField, Button, withTheme } from '@material-ui/core';

import HttpContext from 'utils/contexts/HttpContext';
import SnackbarContext from 'components/CustomSnackbar/SnackbarContext';
import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

function SendContactEmailComponent(props) {
    const { reason, baseMessage, theme } = props;
    const { lang } = useContext(LocalizationContext);

    const [comments, setComments] = useState('');

    const messageWithComments = `${baseMessage} questions/comments: (${comments})`;

    const http = useContext(HttpContext);
    const onSnackbar = useContext(SnackbarContext);

    const sendEmail = async () => {
        let res = await http.post('/contact', { message: messageWithComments, reason: reason, toEmail: 'orders' });
        if (res.ok) {
            onSnackbar(loc('charitySnackbar6', lang));
            setComments('');
        }
    };

    return (
        <Paper style={{ padding: theme.spacing.unit * 2 }}>
            <TextField
                id="standard-multiline-flexible"
                label={loc('labels1', lang)}
                multiline
                value={comments}
                onChange={event => setComments(event.target.value)}
                variant="outlined"
                fullWidth
                style={{ marginBottom: theme.spacing.unit * 2 }}
            />
            <Button variant="outlined" color="primary" onClick={sendEmail}>
                {loc('growthGeneral21', lang)}
            </Button>
        </Paper>
    );
}

export default withTheme()(SendContactEmailComponent);
