import React, { useContext } from 'react';
import _ from 'lodash';

import { Button } from '@material-ui/core';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

const LogoDrop = props => {
    const { file, handleDrop, theme, disabled } = props;
    const { lang } = useContext(LocalizationContext);

    return (
        <>
            <Button
                style={{ padding: 0, margin: '0 auto', display: 'block' }}
                color="secondary"
                variant="outlined"
                disabled={disabled}
            >
                <label htmlFor="fileInput" style={{ padding: theme.spacing.unit, cursor: 'pointer', display: 'block' }}>
                    {!file ? (
                        loc('dashboardGrowthInfo10', lang)
                    ) : (
                        <img
                            htmlFor="fileInput"
                            src={URL.createObjectURL(file)}
                            style={{ maxWidth: '100%' }}
                            alt="Upload Logo"
                        />
                    )}
                </label>
            </Button>

            <input
                data-cy="logo-drop-input"
                type="file"
                accept="image/jpeg, image/png, image/webp, image/heif, image/heic, image/gif"
                id="fileInput"
                name="fileInput"
                onChange={handleDrop}
                style={{ display: 'none' }}
                disabled={disabled}
            />
        </>
    );
};

export default LogoDrop;
