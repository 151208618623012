import React, { useMemo } from 'react';
import { _bulk } from 'std';

import _ from 'lodash';

import { withTheme } from '@material-ui/core/styles';

import bulkHelper from 'helpers/bulkHelper';

import { formatAsCurrency } from 'utils/misc';

import * as colors from '@material-ui/core/colors';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

function BulkLedgerTable({ theme, bulks, displayDepositTax = false, depositTaxGroup = null }) {
    const totalContainerCount = useMemo(() => {
        if (_.isNil(bulks) || _.isEmpty(bulks)) return 0;

        return bulks.reduce((sum, b) => {
            const isBeverage = _.get(b, 'skuType', '') === 'beverage' || _.get(b, 'skuType', '') === 'collectionbins';
            const isCompleted = bulkHelper.isCompleted(b);

            if (isBeverage && isCompleted) {
                return sum + _bulk.getCountsTotalItemCount(b);
            }

            return sum;
        }, 0);
    }, [bulks]);

    const ledgersCombined = useMemo(() => {
        return getRolledUpLedgerLines(bulks || [], totalContainerCount, displayDepositTax, depositTaxGroup);
    }, [bulks]);

    const combinedTotal = useMemo(() => {
        if (_.isNil(bulks) || _.isEmpty(bulks)) return 0;
        return bulks.reduce((sum, bulk) => {
            return (sum += bulkHelper.getCustomerAmount(bulk) - _.get(bulk, 'tip.adjustedAmount', 0) * 100);
        }, 0);
    }, [bulks]);

    if (_.isEmpty(ledgersCombined)) {
        return null;
    }
    return (
        <Table>
            <TableBody>
                {ledgersCombined.map((count, index) => {
                    let lineData = formatAsCurrency(-count.amount);

                    if (process.env.REACT_APP_REGION_EXT === 'EXP' && count.quantity) {
                        // Count has a quantity (i.e. containers)
                        if (!_.isNil(count.amount)) {
                            lineData = `${count.quantity} - ${formatAsCurrency(-count.amount)}`;
                        } else {
                            lineData = count.quantity;
                        }
                    }

                    return (
                        <TableRow key={index} style={{ height: 36 }}>
                            <TableCell style={{ paddingTop: 0, paddingBottom: 0 }}>{count.description}</TableCell>
                            <TableCell style={{ paddingTop: 0, paddingBottom: 0, textAlign: 'right' }}>
                                <span
                                    style={{
                                        color:
                                            !_.isNil(count.amount) && count.amount > 0
                                                ? colors.red[500]
                                                : theme.palette.text.primary,
                                        whiteSpace: 'nowrap'
                                    }}
                                >
                                    {lineData}
                                </span>
                            </TableCell>
                        </TableRow>
                    );
                })}
                <TableRow style={{ height: 36 }}>
                    <TableCell style={{ paddingTop: 0, paddingBottom: 0 }}>
                        <strong>Net Total</strong>
                    </TableCell>
                    <TableCell style={{ paddingTop: 0, paddingBottom: 0, textAlign: 'right' }}>
                        <span
                            style={{
                                color: combinedTotal < 0 ? colors.red[500] : theme.palette.text.primary
                            }}
                        >
                            <strong>{formatAsCurrency(combinedTotal)}</strong>
                        </span>
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );
}

export default withTheme()(BulkLedgerTable);

function getRolledUpLedgerLines(bulks, totalContainerCount = 0, displayDepositTax = false, depositTaxGroup = null) {
    const smallContainers = [];
    const largeContainers = [];
    const otherCountables = []; // this will catch exp containers
    const otherContents = [];
    const tips = [];
    let depositTaxAmounts = [];
    // Ignore non conformants (0 value lines aren't displayed. See return value below)

    if (_.isNil(bulks) || _.isEmpty(bulks)) return [];

    for (let bulk of bulks) {
        if (!_.isNil(bulk.ledger) && !_.isEmpty(bulk.ledger.lines)) {
            const lines = _.cloneDeep(bulk.ledger.lines) || [];

            lines.forEach(line => {
                const desc = _.get(line, 'description', '');
                const quantity = _.get(line, 'quantity', 0);

                if (
                    displayDepositTax &&
                    !_.isNil(depositTaxGroup) &&
                    line.amount < 0 //deposit line
                ) {
                    let totalIncludedTax = 0;
                    _.get(depositTaxGroup, 'taxes', []).forEach(tax => {
                        let currentAmountForTax = _.find(depositTaxAmounts, { _id: tax._id });
                        if (_.isNil(currentAmountForTax)) {
                            currentAmountForTax = { _id: tax._id, taxName: _.get(tax, 'name', 'Unknown'), amount: 0 };
                            depositTaxAmounts.push(currentAmountForTax);
                        }
                        const taxType = _.get(tax, 'configuration.type', 'included');
                        if (taxType === 'included') {
                            const beforeTaxAmount = _.round(line.amount / (1 + _.get(tax, 'configuration.value', 0)));

                            const taxAmount = line.amount - beforeTaxAmount;
                            currentAmountForTax.amount += taxAmount;
                            totalIncludedTax += taxAmount;
                        } else {
                            //I don't think excluded taxes should be included here as they would make the receipt show the net total as being more then what was actually paid out
                            return;
                        }
                    });

                    line.amount -= totalIncludedTax;
                }

                if (desc.includes('0-1L')) {
                    smallContainers.push(line);
                } else if (desc.includes('> 1L')) {
                    largeContainers.push(line);
                } else if (quantity) {
                    otherCountables.push(line);
                } else {
                    otherContents.push(line);
                }
            });
        }
        if (bulk.tip) {
            tips.push({ description: 'Driver Tip', amount: _.get(bulk, 'tip.adjustedAmount') * 100 });
        }
    }
    const totalContainerLine =
        process.env.REACT_APP_REGION_EXT === 'EXP'
            ? [{ description: 'Total Containers', quantity: totalContainerCount }]
            : [];

    // Sorting ledger lines is hardcoded atm
    const smallContainersSorted = _.sortBy(smallContainers, ['description']);
    const largeContainersSorted = _.sortBy(largeContainers, ['description']);
    const otherCountablesSorted = _.sortBy(otherCountables, ['description']);

    let depositTaxLines = [];
    if (displayDepositTax) {
        depositTaxLines = depositTaxAmounts.map(({ taxName, amount }) => {
            return {
                description: taxName,
                amount
            };
        });
    }
    //const otherContentsSorted = _.sortBy(otherContents, ['description']);

    const newLedgerLines = _.concat(
        smallContainersSorted,
        largeContainersSorted,
        otherCountablesSorted,
        totalContainerLine,
        depositTaxLines,
        otherContents,
        tips
    );

    return _.filter(newLedgerLines, line => {
        return line.amount !== 0;
    });
}
