import React from 'react';
import _ from 'lodash';

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import {
    List,
    ListItem,
    ListItemText,
    Typography,
    Paper,
    IconButton,
    ListItemSecondaryAction,
    Dialog,
    DialogActions,
    DialogContent,
    Button,
    withMobileDialog,
    withTheme,
    DialogTitle
} from '@material-ui/core';

import { getSelect, getTextInput, getSwitch } from '../helperFunctions';

import { Icon as MDIcon } from '@mdi/react';

import { mdiDrag, mdiTrashCan } from '@mdi/js';

function FeeGroupForm(props) {
    const {
        fees,
        feeGroup, //NOTE: will be null/undefined if creating a new one
        open,
        onClose,
        onSubmit,
        onSnackbar,
        theme,
        fullScreen
    } = props;

    const formik = useFormik({
        initialValues: {
            _id: _.get(feeGroup, '_id'),
            name: _.get(feeGroup, 'name', ''),
            disabled: _.get(feeGroup, 'disabled', false),
            fees: _.get(feeGroup, 'fees', [])
        },
        validationSchema: Yup.object({
            name: Yup.string().required(),
            disabled: Yup.boolean()
        }),
        onSubmit
    });

    const addFee = fee => {
        const value = fee.target.value;

        const feeToAdd = _.find(fees, { description: value });

        const groupFees = formik.values.fees;
        const groupFee = _.find(groupFees, { description: value });

        if (groupFee) {
            onSnackbar(`${_.get(groupFee, 'description', 'Fee')} is already a part of the group.`)
            return;
        }

        const updatedGroupFees = _.cloneDeep(groupFees);
        updatedGroupFees.push(feeToAdd);

        formik.setFieldValue('fees', updatedGroupFees);
    };

    const removeFee = fee => {
        const groupFees = formik.values.fees;
        const feeToRemoveIdx = _.findIndex(groupFees, { description: fee });

        if (feeToRemoveIdx < 0) {
            // couldnt find fee. Add snackbar
            return;
        }

        const updatedGroupfees = _.cloneDeep(groupFees); // Problem if list items lose order
        updatedGroupfees.splice(feeToRemoveIdx, 1);

        formik.setFieldValue('fees', updatedGroupfees);
    };

    const handleDragEnd = ({ draggableId, destination }) => {
        if (!destination) return;

        const groupFees = formik.values.fees;
        const feeToMove = _.find(groupFees, { description: draggableId });

        const filteredGroupFees = _.filter(groupFees, fee => fee.description !== draggableId);

        const updatedGroupFees = _.cloneDeep(filteredGroupFees); // Problem if list items lose order
        updatedGroupFees.splice(destination.index, 0, feeToMove);

        formik.setFieldValue('fees', updatedGroupFees);
    };

    return (
        <Dialog onClose={onClose} open={open} fullScreen={fullScreen} fullWidth>
            <DialogTitle>Fee Group</DialogTitle>
            <DialogContent>
                {getSwitch(theme, 'disabled', 'Disabled', formik)}

                {getTextInput(theme, 'name', 'Name', formik)}

                {!_.isEmpty(formik.values.fees) && (
                    <Paper
                        elevation={1}
                        style={{
                            width: '100%',
                            padding: theme.spacing.unit,
                            marginTop: theme.spacing.unit * 2,
                            marginBottom: theme.spacing.unit
                        }}
                    >
                        <DragDropContext onDragEnd={handleDragEnd}>
                            <Droppable droppableId="feeGroupList">
                                {(provided, snapshot) => (
                                    <div ref={provided.innerRef} {...provided.droppableProps}>
                                        <List>
                                            {formik.values.fees.map((fee, i) => (
                                                <Draggable
                                                    draggableId={fee.description}
                                                    index={i}
                                                    key={fee.description}
                                                >
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            style={provided.draggableProps.style}
                                                        >
                                                            <ListItem>
                                                                <ListItemText
                                                                    disableTypography
                                                                    style={{
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        marginRight: theme.spacing.unit * 4
                                                                    }}
                                                                >
                                                                    <MDIcon
                                                                        path={mdiDrag}
                                                                        size={1}
                                                                        color={theme.palette.text.secondary}
                                                                    />

                                                                    <Typography
                                                                        style={{ marginRight: theme.spacing.unit }}
                                                                    >
                                                                        {fee.description}
                                                                        {fee.disabled && (
                                                                            <span>
                                                                                {' '}
                                                                                -{' '}
                                                                                <span
                                                                                    style={{
                                                                                        color: theme.palette.error.light
                                                                                    }}
                                                                                >
                                                                                    Disabled
                                                                                </span>
                                                                            </span>
                                                                        )}
                                                                    </Typography>
                                                                </ListItemText>
                                                                <ListItemSecondaryAction>
                                                                    <IconButton
                                                                        onClick={() => {
                                                                            removeFee(fee.description);
                                                                        }}
                                                                    >
                                                                        <MDIcon
                                                                            path={mdiTrashCan}
                                                                            size={1}
                                                                            color={theme.palette.text.secondary}
                                                                        />
                                                                    </IconButton>
                                                                </ListItemSecondaryAction>
                                                            </ListItem>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                        </List>
                                        <div style={{ width: 0 }}>{provided.placeholder}</div>
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </Paper>
                )}

                {getSelect(theme, 'fees', 'Add fee', fees.map(fee => fee.description), formik, addFee)}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={formik.handleSubmit} data-cy="fee-group-crud-submit-btn">
                    {'Submit'}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default withMobileDialog()(withTheme()(FeeGroupForm));
