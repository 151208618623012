import React, { useContext, useState, useEffect } from 'react';
import _ from 'lodash';

import SnackbarContext from 'components/CustomSnackbar/SnackbarContext';
import HttpContext from 'utils/contexts/HttpContext';

import RatingPlaformForm from './Forms/RatingPlatformForm';

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import {
    Paper,
    Icon,
    IconButton,
    Button,
    Typography,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    withTheme,
    LinearProgress
} from '@material-ui/core';

import { colors } from '@material-ui/core';

import { Icon as MDIcon } from '@mdi/react';

import { mdiDrag, mdiPencil } from '@mdi/js';

import Badge from 'components/Badge/Badge';

const badgeColors = {
    All: colors.green[500],
    Android: colors.blue[500],
    IOS: colors.orange[500],
    Web: colors.red[500]
};

function RatingPlaformList(props) {
    const { ratingPlatforms = [], onReloadRatingPlatforms, theme } = props;
    const ratingPlatformsSorted = _.sortBy(ratingPlatforms, 'priority');

    const [updatedRatingPlatforms, setUpdatedRatingPlatforms] = useState(ratingPlatformsSorted);
    const [formOpen, setFormOpen] = useState(false);
    const [ratingPlatformToEdit, setRatingPlatformToEdit] = useState(null);
    const [loading, setLoading] = useState(false);

    const ratingPlatformsChanged = !_.isEqual(ratingPlatforms, updatedRatingPlatforms);

    const http = useContext(HttpContext);
    const onSnackbar = useContext(SnackbarContext);

    const handleDragEnd = ({ draggableId, destination }) => {
        if (!destination) return;
        let newUpdatedRatingPlatforms = _.filter(
            updatedRatingPlatforms,
            ratingPlatform => ratingPlatform._id !== draggableId
        );
        const ratingPlatformMoved = _.find(updatedRatingPlatforms, { _id: draggableId });
        newUpdatedRatingPlatforms.splice(destination.index, 0, ratingPlatformMoved);
        setUpdatedRatingPlatforms(newUpdatedRatingPlatforms);
    };

    const handleCreate = () => {
        setRatingPlatformToEdit(null);
        setFormOpen(true);
    };

    const handleEdit = ratingPlatform => {
        setRatingPlatformToEdit(ratingPlatform);
        setFormOpen(true);
    };

    const handleSubmit = async values => {
        setLoading(true);
        if (!_.isNil(ratingPlatformToEdit)) {
            setLoading(true);
            const res = await http.post(`/ratingPlatforms/${ratingPlatformToEdit._id}/updateRatingPlatform`, values);
            if (res.ok) {
                onSnackbar('Successfully updated rating platform.');
                setFormOpen(false);
                await onReloadRatingPlatforms();
            }
        } else {
            const res = await http.post('/ratingPlatforms/createRatingPlatform', values);
            if (res.ok) {
                onSnackbar('Successfully created rating platform.');
                setFormOpen(false);
                await onReloadRatingPlatforms();
            }
        }
        setLoading(false);
    };

    const handleSaveOrdering = async () => {
        setLoading(true);
        const updateObjs = updatedRatingPlatforms.map((ratingPlatform, i) => ({
            _id: ratingPlatform._id,
            priority: i
        }));
        const res = await http.post('/ratingPlatforms/updatePriorities', updateObjs);
        if (res.ok) {
            onSnackbar('Successfully updated rating platform ordering.');
            await onReloadRatingPlatforms();
        }
        setLoading(false);
    };

    useEffect(() => {
        setUpdatedRatingPlatforms(ratingPlatformsSorted);
    }, [ratingPlatforms]);

    return (
        <Paper style={{ padding: theme.spacing.unit * 2 }}>
            <Typography variant="h6">All Rating Platforms</Typography>
            <Typography color="secondary">Changes apply to all collectors</Typography>
            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="ratingPlatformList">
                    {(provided, snapshot) => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                            <List>
                                {updatedRatingPlatforms.map((ratingPlatform, i) => (
                                    <Draggable draggableId={ratingPlatform._id} index={i} key={ratingPlatform._id}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={provided.draggableProps.style}
                                            >
                                                <ListItem>
                                                    <ListItemText
                                                        disableTypography
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            marginRight: theme.spacing.unit * 4
                                                        }}
                                                    >
                                                        <MDIcon
                                                            path={mdiDrag}
                                                            size={1}
                                                            color={theme.palette.text.secondary}
                                                        />
                                                        <Typography style={{ marginRight: theme.spacing.unit }}>
                                                            {_.get(ratingPlatform, 'name', '')}
                                                        </Typography>
                                                        <Badge
                                                            style={{
                                                                backgroundColor: _.get(
                                                                    badgeColors,
                                                                    ratingPlatform.appVersion,
                                                                    'rgba(0, 0, 0, .54)'
                                                                ),
                                                                color: '#fff',
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis',
                                                                whiteSpace: 'nowrap',
                                                                maxWidth: '70%'
                                                            }}
                                                        >
                                                            {ratingPlatform.appVersion}
                                                        </Badge>
                                                    </ListItemText>
                                                    <ListItemSecondaryAction>
                                                        <IconButton
                                                            onClick={() => {
                                                                handleEdit(ratingPlatform);
                                                            }}
                                                        >
                                                            <MDIcon
                                                                path={mdiPencil}
                                                                size={1}
                                                                color={theme.palette.text.secondary}
                                                            />
                                                        </IconButton>
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                            </List>
                            <div style={{ width: 0 }}>{provided.placeholder}</div>
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            <Button variant="outlined" color="primary" style={{ width: '100%' }} onClick={handleCreate}>
                <Icon style={{ marginRight: theme.spacing.unit }}>add_circle</Icon>
                Create New Platform
            </Button>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    marginTop: theme.spacing.unit * 2
                }}
            >
                {ratingPlatformsChanged && (
                    <Typography color="error" style={{ marginRight: theme.spacing.unit * 2, textAlign: 'right' }}>
                        Unsaved ordering changes
                    </Typography>
                )}
                <Button
                    variant="contained"
                    color="primary"
                    disabled={!ratingPlatformsChanged || loading}
                    onClick={handleSaveOrdering}
                >
                    Save
                </Button>
            </div>
            {formOpen && (
                <RatingPlaformForm
                    open={formOpen}
                    ratingPlatform={ratingPlatformToEdit}
                    ratingPlatforms={ratingPlatforms}
                    onSubmit={handleSubmit}
                    onClose={() => setFormOpen(false)}
                />
            )}
            {loading && (
                <div style={{ marginTop: theme.spacing.unit }}>
                    <LinearProgress />
                </div>
            )}
        </Paper>
    );
}

export default withTheme()(RatingPlaformList);
