import React, { useEffect } from 'react';
import _ from 'lodash';
import { _time } from 'std';

import useTable from 'components/Table/useTable';
import Table from 'components/Table/Table';
import useGetJSON from 'utils/hooks/useGetJSON';

import { mdiDesktopClassic, mdiMapMarkerPath } from '@mdi/js';
import MDIcon from '@mdi/react';

import useDateRangePicker from 'components/DateTimePickersTz/hooks/useDateRangePicker';
import DateRangePicker from 'components/DateTimePickersTz/DateRangePicker';

import {
    Grid,
    withTheme,
    TableRow,
    TableCell,
    Paper,
    Typography,
    Icon,
    Avatar,
    FormControl,
    Select,
    MenuItem
} from '@material-ui/core';

import { _user } from 'std';

const headers = [{ name: 'Name^' }, { name: 'Email^' }, { name: 'Account Type^' }, { name: 'Direction Calls^' }];

function GoogleCalls(props) {
    const { theme } = props;

    const {
        loading,
        getData,
        page,
        rowsPerPage,
        filteredData,
        search,
        filteredHeaders,
        totalDataEntries,
        handleChange,
        handleSearch,
        handleChangeFilterHeaders,
        handleAllFilterHeaders,
        handleChangePage,
        handleChangeRowsPerPage
    } = useTable({ url: '/users/getUsersGoogleCalls', key: 'googleCalls', headers: headers.map(h => h.name) });

    const renderRow = user => {
        return (
            <TableRow key={user._id}>
                {filteredHeaders.includes('Name^') && <TableCell>{_user.getNameFull(user)}</TableCell>}
                {filteredHeaders.includes('Email^') && <TableCell>{_user.getEmail(user)}</TableCell>}
                {filteredHeaders.includes('Account Type^') && <TableCell>{_user.getAccountType(user)}</TableCell>}
                {filteredHeaders.includes('Direction Calls^') && (
                    <TableCell>{_.get(user, 'googleDirectionCalls', 0)}</TableCell>
                )}
            </TableRow>
        );
    };

    const {
        startDate,
        endDate,
        timezone,
        dateWindow,
        handleChangeStartDate,
        handleChangeEndDate,
        handleGoForwards,
        handleGoBackwards,
        handleWindowSelect
    } = useDateRangePicker({
        saveStateInURL: true,
        timezones: [process.env.REACT_APP_REGION_TIMEZONE],
        initStartVal: _time.getStartOfWeek(process.env.REACT_APP_REGION_TIMEZONE),
        initEndVal: _time.getEndOfDay(process.env.REACT_APP_REGION_TIMEZONE)
    });

    const { loading: totalStatsLoading, data: totals } = useGetJSON(
        `/externalCallLogs/getGoogleCallTotals?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`
    );

    useEffect(() => {
        getData();
    }, [startDate, endDate, timezone, dateWindow]);

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <div style={{ padding: theme.spacing.unit * 2, paddingBottom: theme.spacing.unit }}>
                        <Paper
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                padding: theme.spacing.unit * 2,
                                height: '100%',
                                flexWrap: 'wrap'
                            }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <DateRangePicker
                                    timezone={timezone}
                                    startDate={startDate}
                                    endDate={endDate}
                                    window={dateWindow}
                                    disabled={loading || totalStatsLoading}
                                    handlePrevious={handleGoBackwards}
                                    handleNext={handleGoForwards}
                                    handleChangeStartDate={handleChangeStartDate}
                                    handleChangeEndDate={handleChangeEndDate}
                                />
                            </div>

                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexWrap: 'wrap'
                                }}
                            >
                                {
                                    <FormControl>
                                        <Select
                                            value={dateWindow}
                                            disabled={loading || totalStatsLoading}
                                            onChange={handleWindowSelect}
                                            style={{
                                                maxWidth: '250px',
                                                margin: theme.spacing.unit
                                            }}
                                        >
                                            <MenuItem value="today">Daily</MenuItem>
                                            <MenuItem value="thisWeek">Weekly</MenuItem>
                                            <MenuItem value="thisMonth">Monthly</MenuItem>
                                            <MenuItem value="thisQuarter">Quarterly</MenuItem>
                                            <MenuItem value="allTime">All Time</MenuItem>
                                        </Select>
                                    </FormControl>
                                }
                            </div>
                        </Paper>
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <div
                        style={{
                            padding: theme.spacing.unit,
                            paddingLeft: theme.spacing.unit * 2,
                            paddingRight: theme.spacing.unit * 2
                        }}
                    >
                        <Paper
                            style={{
                                display: 'flex',
                                justifyContent: 'space-evenly',
                                padding: theme.spacing.unit * 2,
                                height: '100%',
                                flexWrap: 'wrap'
                            }}
                        >
                            <span style={{ margin: theme.spacing.unit, display: 'flex', alignItems: 'center' }}>
                                <Avatar
                                    style={{
                                        backgroundColor: theme.palette.primary.main,
                                        marginRight: theme.spacing.unit
                                    }}
                                >
                                    <Icon>local_shipping</Icon>
                                </Avatar>
                                <Typography style={{ marginRight: theme.spacing.unit * 2, display: 'inline-block' }}>
                                    Collector/Driver Calls: {_.get(totals, 'collectorAndDriverCalls', 0)}
                                </Typography>
                            </span>

                            <span style={{ margin: theme.spacing.unit, display: 'flex', alignItems: 'center' }}>
                                <Avatar
                                    style={{
                                        backgroundColor: theme.palette.primary.main,
                                        marginRight: theme.spacing.unit
                                    }}
                                >
                                    <Icon>person</Icon>
                                </Avatar>
                                <Typography style={{ marginRight: theme.spacing.unit * 2, display: 'inline-block' }}>
                                    Sys. Admin Calls: {_.get(totals, 'systemAdminCalls', 0)}
                                </Typography>
                            </span>

                            <span style={{ margin: theme.spacing.unit, display: 'flex', alignItems: 'center' }}>
                                <Avatar
                                    style={{
                                        backgroundColor: theme.palette.primary.main,
                                        marginRight: theme.spacing.unit
                                    }}
                                >
                                    <MDIcon fill="white" path={mdiDesktopClassic} size={1} />
                                </Avatar>
                                <Typography style={{ marginRight: theme.spacing.unit * 2, display: 'inline-block' }}>
                                    System Calls: {_.get(totals, 'systemGoogleCalls', 0)}
                                </Typography>
                            </span>

                            <span style={{ margin: theme.spacing.unit, display: 'flex', alignItems: 'center' }}>
                                <Avatar
                                    style={{
                                        backgroundColor: theme.palette.primary.main,
                                        marginRight: theme.spacing.unit
                                    }}
                                >
                                    <MDIcon fill="white" path={mdiMapMarkerPath} size={1} />{' '}
                                </Avatar>
                                <Typography style={{ marginRight: theme.spacing.unit * 2, display: 'inline-block' }}>
                                    Customer Calls: {_.get(totals, 'customerCalls', 0)}
                                </Typography>
                            </span>
                            <span style={{ margin: theme.spacing.unit, display: 'flex', alignItems: 'center' }}>
                                <Avatar
                                    style={{
                                        backgroundColor: theme.palette.primary.main,
                                        marginRight: theme.spacing.unit
                                    }}
                                >
                                    <Icon>place</Icon>
                                </Avatar>
                                <Typography style={{ marginRight: theme.spacing.unit * 2, display: 'inline-block' }}>
                                    Places calls: {_.get(totals, 'totalPlacesCalls', 0)}
                                </Typography>
                            </span>
                        </Paper>
                    </div>
                </Grid>
            </Grid>
            <Table
                title="Google Calls"
                loading={loading || totalStatsLoading}
                headers={headers}
                filteredHeaders={filteredHeaders}
                length={totalDataEntries}
                page={page}
                rowsPerPage={rowsPerPage}
                search={search}
                handleChange={handleChange}
                handleChangeFilterHeaders={handleChangeFilterHeaders}
                handleAllFilterHeaders={handleAllFilterHeaders}
                handleSearch={handleSearch}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                refresh={getData}
            >
                {filteredData &&
                    filteredData.map(user => {
                        return renderRow(user, filteredHeaders);
                    })}
            </Table>
        </>
    );
}

export default withTheme()(GoogleCalls);
