import React, { useState } from 'react';
import _ from 'lodash';
import { withTheme } from '@material-ui/core/styles';
import SelectDropDownTemplate from './SelectDropDownTemplate';
import moment from 'moment';

const thisMonth = {
    name: 'This Month',
    value: [
        moment()
            .startOf('month')
            .format('MM-DD-YYYY'),
        moment()
            .endOf('day')
            .format('MM-DD-YYYY')
    ].toString()
};

const getLastMonth = () => {
    let lastMonthStart = moment()
        .subtract(1, 'month')
        .startOf('month');
    let lastMonthEnd = moment(lastMonthStart).endOf('month');

    return {
        name: 'Last Month',
        value: [lastMonthStart.format('MM-DD-YYYY'), lastMonthEnd.format('MM-DD-YYYY')].toString()
    };
};

const lastThirtyDays = {
    name: 'Last 30 Days',
    value: [
        moment()
            .subtract(30, 'days')
            .startOf('day')
            .format('MM-DD-YYYY'),
        moment()
            .endOf('day')
            .format('MM-DD-YYYY')
    ].toString()
};

const lastSixtyDays = {
    name: 'Last 60 Days',
    value: [
        moment()
            .subtract(60, 'days')
            .startOf('day')
            .format('MM-DD-YYYY'),
        moment()
            .endOf('day')
            .format('MM-DD-YYYY')
    ].toString()
};

function AssortedTimeIntervalDropdown({
    theme,
    style,
    value,
    cypress_id,
    id,
    showAll = false,
    onChange,
    multiple,
    dateFloor = moment().startOf('month')
}) {
    let floor = moment(dateFloor);

    // get the difference in years between today and the earliest applicable date specified in the props
    let yearDiff = moment().diff(floor, 'years');

    let allTimeValue = [];

    // values for the year options will be from jan 1st to dec 31st of each year starting from the current year to the year of the date floor
    const years = new Array(yearDiff + 1).fill(null).map((element, idx) => {
        let startDate = moment()
            .startOf('year')
            .subtract(idx, 'year');

        // for the last iteration of the loop which is the oldest year, set the start of that year as the start date for the All Time option
        if (idx === yearDiff) {
            allTimeValue[0] = startDate.format('MM-DD-YYYY');
        }

        let endDate = moment(startDate).endOf('year');

        // for the first iteration of the loop which the current year, set (the end of) today as the end date for the current year and All Time options
        if (idx === 0) {
            endDate = moment().endOf('day');
            allTimeValue[1] = endDate.format('MM-DD-YYYY');
        }

        return {
            name: startDate.year(),
            value: [startDate.format('MM-DD-YYYY'), endDate.format('MM-DD-YYYY')].toString()
        };
    });

    // the All Time option encompasses the start and end of all valid years as a whole
    const allTime = {
        name: 'All Time',
        value: allTimeValue.toString()
    };

    // if the earliest applicable date is before the start of the current month then allow for a Last Month option
    const lastMonth = floor.isBefore(moment().startOf('month')) ? [getLastMonth()] : [];

    // In the case that lastMonth is an empty array from the above ternary, it will simply not be added to intervals
    const intervals = [lastThirtyDays, lastSixtyDays, thisMonth, ...lastMonth, ...years, allTime];

    let updatedStyle = _.cloneDeep(style);
    if (_.isNil(style)) {
        updatedStyle = { marginRight: theme.spacing.unit, width: 160 };
    }

    return (
        <SelectDropDownTemplate
            helperText="Interval"
            id={id}
            name="interval"
            value={value}
            onChange={onChange}
            labelCustomWidth={51}
            items={intervals}
            itemValueKey="value"
            itemRenderValueKey="name"
            cypress_id={cypress_id}
            showAll={showAll}
            style={updatedStyle}
            multiple={multiple}
        />
    );
}

export default withTheme()(AssortedTimeIntervalDropdown);
