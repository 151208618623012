import React, { useState, useContext } from 'react';
import moment from 'moment-timezone';

import _ from 'lodash';
import ExternalLink from 'components/ExternalLink/ExternalLink';
import PromoCodesInput from 'components/InputComponents/PromoCodesInput';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitlePrimary from 'components/MaterialUIExtensions/DialogTitlePrimary';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';

import withMobileDialog from '@material-ui/core/withMobileDialog';
import { withTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import { validate } from 'utils/validate';

import googleIcon from 'icons/google-icon.png';
import facebookIcon from 'icons/fb-icon.png';
import appleIcon from 'icons/apple-icon.png';
import expressIcon from 'icons/ExpressMobile.svg';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';
import { useRef } from 'react';
import { Icon, ListItem, ListItemAvatar, ListItemText, Paper, colors } from '@material-ui/core';
import { formatsSecondsToTime } from 'utils/misc';
import { useEffect } from 'react';
import TextMaskPhoneNumber from 'components/TextMasks/TextMaskPhoneNumber';

function OAuthDialog({
    theme,
    open,
    oAuthUser,
    promoToApply,
    http,
    onNext,
    onClose,
    charityPreferred,
    onCharityPreferred,
    require2FABeforeRegistration,
    onSnackbar = () => {}
}) {
    const { lang } = useContext(LocalizationContext);

    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [emailErrorReason, setEmailErrorReason] = useState('');

    const [phone, setPhone] = useState(_.get(oAuthUser, 'phone', ''));
    const [phoneError, setPhoneError] = useState(false);
    const [phoneErrorReason, setPhoneErrorReason] = useState('');

    const [promoInputShown, setPromoInputShown] = useState(false);
    const [promos, setPromos] = useState([]);
    const [inProgress, setInProgress] = useState(false);

    const [twoFactorAuthenticationCode, setTwoFactorAuthenticationCode] = useState('');
    const codeInputRef = useRef(null);
    const [preRegistration2FAComplete, setPreRegistration2FAComplete] = useState(false);
    const [authenticationSID, setAuthenticationSID] = useState('');
    const [enableSendTime, setEnableSendTime] = useState(null);
    const [resendDisabled, setResendDisabled] = useState(false);
    const [twoFactorAuthenticationCodeInputDisabled, setTwoFactorAuthenticationCodeInputDisabled] = useState(true);

    const fetchPromo = () => {
        // this carries over the promo if it was applied before
        if (!_.isNil(promoToApply) && !_.isEmpty(promoToApply) && _.isEmpty(promos)) {
            handleApplyPromo([promoToApply]);
            showPromoInput();
        }
    };

    const handleClose = () => {
        onClose();
    };

    const handleChangeEmail = e => {
        const err = validate(['email'], email, lang);
        setEmailError(err.fail);
        setEmailErrorReason(err.reason);
        setEmail(e.target.value);
    };

    const handleChangePhone = e => {
        const value = e.target.value.replace(/\D+/g, '');
        if (value !== phone) {
            setPreRegistration2FAComplete(false);
        }
        const err = validate(['phone'], value, lang);
        setPhoneError(err.fail);
        setPhoneErrorReason(err.reason);
        setPhone(value);
    };

    const handleSubmit = () => {
        setInProgress(true);
        let updatedUser = _.cloneDeep(oAuthUser);
        _.set(updatedUser, 'altEmail', email);
        _.set(updatedUser, 'phone', phone);
        _.set(updatedUser, 'promos', promos);
        onNext(updatedUser, () => setInProgress(false));
    };

    const handleApplyPromo = promo => {
        setPromos(_.concat(promos, promo));
    };

    const handleRemovePromo = promo => {
        setPromos(_.filter(promos, p => p.code !== promo.code));
    };

    const showPromoInput = () => {
        setPromoInputShown(true);
    };

    let icon;
    if (!_.isNil(oAuthUser)) {
        // cherry pick this
        if (['Google', 'google.com'].includes(oAuthUser.oAuth.provider)) {
            icon = googleIcon;
        } else if (['Facebook', 'facebook.com'].includes(oAuthUser.oAuth.provider)) {
            icon = facebookIcon;
        } else if (['Apple', 'apple.com'].includes(oAuthUser.oAuth.provider)) {
            icon = appleIcon;
        } else if (['Express'].includes(oAuthUser.oAuth.provider)) {
            icon = expressIcon;
        }
    }

    const focusOnCodeInput = () => {
        if (codeInputRef.current && !twoFactorAuthenticationCodeInputDisabled) {
            codeInputRef.current.focus();
        } else if (codeInputRef.current && twoFactorAuthenticationCodeInputDisabled) {
            setTimeout(() => {
                codeInputRef.current.focus();
            }, 50);
        }
    };

    const disableSendButton = timeoutInSeconds => {
        setResendDisabled(true);
        setTimeout(() => {
            setResendDisabled(false);
        }, timeoutInSeconds * 1000);
    };

    const handleSendVerificationCodeClick = async (channel = 'sms') => {
        handleSendPreRegistrationVerificationCode({
            twoFactorAuthenticationPhoneNumber: phone,
            channel,
            authenticationSID
        });
    };

    const handleSendPreRegistrationVerificationCode = async ({
        twoFactorAuthenticationPhoneNumber,
        channel,
        authenticationSID
    }) => {
        let body = {
            twoFactorAuthenticationPhoneNumber,
            channel,
            authenticationSID
        };
        const Break = {};
        fetch(process.env.REACT_APP_API_URL + '/multipurposeSendTwoFactorAuthenticationCode', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify(body)
        })
            .then(async res => {
                if (res.status === 200) {
                    const json = await res.json();
                    let currentTime = moment(new Date());
                    // if (!_.isEmpty(phoneNumber)) {
                    //     setTwoFactorAuthenticationPhoneNumber(phoneNumber);
                    // }
                    setAuthenticationSID(json.authenticationSID);
                    setTwoFactorAuthenticationCodeInputDisabled(false);
                    setEnableSendTime(currentTime.add(json.timeUntilNextRetry, 's'));
                    disableSendButton(json.timeUntilNextRetry);
                    focusOnCodeInput();
                    onSnackbar(loc('authCodeSent', lang), 'success');
                } else if (res.status === 429) {
                    onClose();
                    setInProgress(false);
                    onSnackbar(loc('tooManyAttempts', lang), 'error');
                    throw new Break();
                } else if (res.status === 400) {
                    let message;
                    try {
                        message = (await res.json()).message; // error message supplied by the server
                    } catch (err) {
                        message = 'Bad request.'; // error message to be generated locally
                    }
                    setInProgress(false);
                    onSnackbar(message, 'error');
                } else {
                    setInProgress(false);
                    onSnackbar(loc('somethingWentWrong', lang), 'error');
                }
            })
            .catch(Break => {})
            .catch(err => {
                console.error('POST /login', err);
                onSnackbar(loc('connectionErr', lang), 'error');
            });
    };

    const handlePreRegistrationVerify = async e => {
        let body = {
            twoFactorAuthenticationCode,
            twoFactorAuthenticationPhoneNumber: phone
        };
        setInProgress(true);
        fetch(process.env.REACT_APP_API_URL + '/multipurposeCheckTwoFactorAuthenticationCode', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify(body)
        })
            .then(async res => {
                if (res.status === 200) {
                    setPreRegistration2FAComplete(true);
                } else if (res.status === 400) {
                    let message;
                    try {
                        message = (await res.json()).message; // error message supplied by the server
                    } catch (err) {
                        message = 'Bad request.'; // error message to be generated locally
                    }
                    onSnackbar(message, 'error');
                } else if (res.status === 401) {
                    onSnackbar(loc('invalidAuthCode', lang), 'error');
                } else if (res.status === 429) {
                    onClose();
                    onSnackbar(loc('tooManyAttempts', lang), 'error');
                } else if (res.status === 409) {
                    onSnackbar(loc('errorVerifyingCreds', lang), 'error');
                    setPhone('');
                    onClose();
                } else if (res.status === 502) {
                    onSnackbar(loc('serverUnreachable', lang), 'error');
                } else {
                    onSnackbar(loc('serverError', lang), 'error');
                }
                setInProgress(false);
                setTwoFactorAuthenticationCode('');
            })
            .catch(err => {
                console.error('POST /checkTwoFactorAuthenticationCode', err);
                setInProgress(false);
                onSnackbar(loc('connectionErr', lang), 'error');
            });
    };

    return (
        <Dialog fullScreen={false} fullWidth open={open} onClose={handleClose} onEntering={fetchPromo}>
            {!_.isNil(oAuthUser) && (
                <span>
                    <DialogTitlePrimary closeButtonShown onClose={handleClose}>
                        {loc('registration', lang)}
                    </DialogTitlePrimary>
                    <DialogContent style={{ marginTop: theme.spacing.unit * 3, paddingBottom: 0 }}>
                        <Typography variant="subtitle1" style={{ marginTop: theme.spacing.unit, textAlign: 'center' }}>
                            {loc('registrationOAuth1', lang)}:
                        </Typography>
                        <div variant="subtitle1" style={{ marginTop: theme.spacing.unit * 2, textAlign: 'center' }}>
                            <img style={{ height: '50px' }} src={icon} alt="oAuth Type" />
                            {!_.isNil(oAuthUser.email) && (
                                <Typography variant="subtitle2">{oAuthUser.email}</Typography>
                            )}
                        </div>
                        <Divider style={{ marginTop: theme.spacing.unit * 2 }} />
                        <div style={{ maxWidth: 360, margin: 'auto', textAlign: 'center' }}>
                            {_.isNil(oAuthUser.email) && (
                                <span>
                                    <Typography
                                        variant="body2"
                                        style={{
                                            marginTop: theme.spacing.unit * 2,
                                            marginBottom: theme.spacing.unit
                                        }}
                                    >
                                        {loc('registrationOAuth2', lang)}
                                    </Typography>
                                    <FormControl fullWidth>
                                        <TextField
                                            name="email"
                                            label={loc('email', lang)}
                                            variant="outlined"
                                            autoFocus={true}
                                            helperText={emailErrorReason}
                                            error={emailError}
                                            value={email}
                                            onChange={handleChangeEmail}
                                        />
                                    </FormControl>
                                </span>
                            )}
                            <Typography
                                variant="caption"
                                color="textSecondary"
                                style={{ marginTop: theme.spacing.unit }}
                            >
                                {loc('registrationOAuth3', lang)}
                                <br /> {loc('registrationOAuth4', lang)}
                            </Typography>
                            {_.isEmpty(_.get(oAuthUser, 'phone', '')) && require2FABeforeRegistration && (
                                <span>
                                    <Typography
                                        variant="body2"
                                        style={{
                                            marginTop: theme.spacing.unit * 2,
                                            marginBottom: theme.spacing.unit
                                        }}
                                    >
                                        {loc('registrationOAuth2a', lang)}
                                    </Typography>
                                    <FormControl fullWidth>
                                        <TextField
                                            type="tel"
                                            name="phone"
                                            label={loc('phoneNumber', lang)}
                                            variant="outlined"
                                            autoFocus={true}
                                            helperText={phoneErrorReason}
                                            error={phoneError}
                                            value={phone}
                                            onChange={handleChangePhone}
                                            InputProps={{
                                                inputComponent: TextMaskPhoneNumber
                                            }}
                                        />
                                    </FormControl>
                                </span>
                            )}
                            <Collapse in={require2FABeforeRegistration && !isIncompletePhoneNumber(phone)}>
                                {process.env.REACT_APP_ENV === 'TEST' && (
                                    <Typography variant="body2" color="error">
                                        {loc('registration2fa3', lang)}
                                    </Typography>
                                )}
                                <Paper
                                    style={{
                                        boxShadow: 'none',
                                        margin: `${theme.spacing.unit}px 0px`,
                                        padding: theme.spacing.unit,
                                        border: '1px solid rgba(224, 224, 224, 1)',
                                        backgroundColor: preRegistration2FAComplete
                                            ? colors.green[500]
                                            : theme.palette.background.paper,
                                        textAlign: 'left'
                                    }}
                                >
                                    {preRegistration2FAComplete ? (
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Icon
                                                    style={{
                                                        color: 'white',
                                                        margin: theme.spacing.unit
                                                    }}
                                                >
                                                    check
                                                </Icon>
                                            </ListItemAvatar>
                                            <ListItemText>
                                                <Typography variant="body1" style={{ color: 'white' }}>
                                                    {loc('phoneNumberVerified', lang)}
                                                </Typography>
                                            </ListItemText>
                                        </ListItem>
                                    ) : (
                                        <>
                                            <Typography
                                                variant="body1"
                                                style={{
                                                    color: theme.palette.text.secondary
                                                }}
                                            >
                                                {loc('verifyPhone', lang)}
                                            </Typography>
                                            <div
                                                fullwidth
                                                style={{
                                                    width: '100%',
                                                    display: 'flex',
                                                    flexDirection: 'column'
                                                }}
                                            >
                                                <div>
                                                    <Typography
                                                        style={{
                                                            marginTop: theme.spacing.unit
                                                        }}
                                                        variant="body2"
                                                    >
                                                        <span
                                                            style={{
                                                                color:
                                                                    resendDisabled || isIncompletePhoneNumber(phone)
                                                                        ? theme.palette.text.secondary
                                                                        : theme.palette.primary.main,
                                                                textDecoration:
                                                                    resendDisabled || isIncompletePhoneNumber(phone)
                                                                        ? ''
                                                                        : 'underline',
                                                                cursor:
                                                                    resendDisabled || isIncompletePhoneNumber(phone)
                                                                        ? 'default'
                                                                        : 'pointer'
                                                            }}
                                                            data-cy="2fa-resend-code-via-text"
                                                            onClick={
                                                                resendDisabled || isIncompletePhoneNumber(phone)
                                                                    ? () => {}
                                                                    : e => {
                                                                          e.preventDefault();
                                                                          handleSendVerificationCodeClick('sms');
                                                                      }
                                                            }
                                                        >
                                                            {`${loc(
                                                                require2FABeforeRegistration
                                                                    ? 'sendViaText'
                                                                    : 'resendViaText',
                                                                lang
                                                            )} >`}
                                                        </span>
                                                    </Typography>
                                                </div>
                                                <div>
                                                    <Typography
                                                        style={{
                                                            marginTop: theme.spacing.unit
                                                        }}
                                                        variant="body2"
                                                    >
                                                        <span
                                                            style={{
                                                                color:
                                                                    resendDisabled || isIncompletePhoneNumber(phone)
                                                                        ? theme.palette.text.secondary
                                                                        : theme.palette.primary.main,
                                                                textDecoration:
                                                                    resendDisabled || isIncompletePhoneNumber(phone)
                                                                        ? ''
                                                                        : 'underline',
                                                                cursor:
                                                                    resendDisabled || isIncompletePhoneNumber(phone)
                                                                        ? 'default'
                                                                        : 'pointer'
                                                            }}
                                                            data-cy="2fa-resend-code-via-voice"
                                                            onClick={
                                                                resendDisabled || isIncompletePhoneNumber(phone)
                                                                    ? () => {}
                                                                    : e => {
                                                                          e.preventDefault();
                                                                          handleSendVerificationCodeClick('call');
                                                                      }
                                                            }
                                                        >
                                                            {`${loc(
                                                                require2FABeforeRegistration
                                                                    ? 'sendViaVoice'
                                                                    : 'resendViaVoice',
                                                                lang
                                                            )} >`}
                                                        </span>
                                                    </Typography>
                                                </div>
                                                {resendDisabled && (
                                                    <div fullwidth style={{ display: 'inline-flex' }}>
                                                        <Typography
                                                            variant="body2"
                                                            color="textSecondary"
                                                            style={{
                                                                float: 'right',
                                                                fontSize: '60%'
                                                            }}
                                                        >
                                                            {loc('registration2fa5', lang)}{' '}
                                                            <WaitToRetry time={enableSendTime} />
                                                        </Typography>
                                                    </div>
                                                )}
                                                <div style={{ display: 'flex' }}>
                                                    <FormControl
                                                        fullWidth
                                                        style={{
                                                            marginTop: theme.spacing.unit
                                                        }}
                                                    >
                                                        <TextField
                                                            data-cy="login-2fa-code-input"
                                                            disabled={twoFactorAuthenticationCodeInputDisabled}
                                                            inputRef={codeInputRef}
                                                            variant="outlined"
                                                            name="twoFactorAuthenticationCode"
                                                            type="numeric"
                                                            label={loc('registration2fa6', lang)}
                                                            placeholder="e.g. 123456"
                                                            value={twoFactorAuthenticationCode}
                                                            InputProps={{
                                                                style: {
                                                                    backgroundColor: theme.palette.background.paper
                                                                }
                                                            }}
                                                            onChange={e =>
                                                                setTwoFactorAuthenticationCode(e.target.value)
                                                            }
                                                        />
                                                    </FormControl>
                                                    <Button
                                                        data-cy="verify-btn"
                                                        disabled={
                                                            twoFactorAuthenticationCodeInputDisabled ||
                                                            _.isEmpty(twoFactorAuthenticationCode)
                                                        }
                                                        variant="contained"
                                                        color={
                                                            preRegistration2FAComplete ? colors.green[500] : 'primary'
                                                        }
                                                        style={{
                                                            display: 'inline',
                                                            // float: 'right',
                                                            margin: theme.spacing.unit / 2,
                                                            marginTop: theme.spacing.unit,
                                                            padding: theme.spacing.unit,
                                                            height: 56
                                                        }}
                                                        onClick={handlePreRegistrationVerify}
                                                    >
                                                        {inProgress ? (
                                                            <CircularProgress
                                                                size={22}
                                                                thickness={4.8}
                                                                style={{
                                                                    display: inProgress ? '' : 'none',
                                                                    marginLeft: 4,
                                                                    color: 'white',
                                                                    verticalAlign: 'bottom'
                                                                }}
                                                            />
                                                        ) : (
                                                            loc('verify', lang)
                                                        )}
                                                    </Button>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </Paper>
                            </Collapse>

                            <div style={{ marginBottom: theme.spacing.unit * 3 }}>
                                <Collapse in={!promoInputShown}>
                                    <Typography
                                        variant="body1"
                                        color="primary"
                                        style={{
                                            marginTop: theme.spacing.unit * 4,
                                            marginBottom: -theme.spacing.unit,
                                            cursor: 'pointer'
                                        }}
                                        onClick={showPromoInput}
                                    >
                                        {loc('addPromoCode', lang)}
                                    </Typography>
                                </Collapse>
                                <Collapse in={promoInputShown}>
                                    <FormControl fullWidth style={{ marginTop: theme.spacing.unit }}>
                                        <PromoCodesInput
                                            customer={{}}
                                            promos={promos}
                                            onApplyPromo={handleApplyPromo}
                                            onRemovePromo={handleRemovePromo}
                                            charityPreferred={charityPreferred}
                                            donate={!_.isNil(charityPreferred)}
                                            onCharityPreferred={onCharityPreferred}
                                        />
                                    </FormControl>
                                </Collapse>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <Button
                                    variant="contained"
                                    color="default"
                                    style={{ height: 40, marginRight: theme.spacing.unit }}
                                    onClick={handleClose}
                                >
                                    {loc('back', lang)}
                                </Button>

                                <Button
                                    data-cy="registration-register-button"
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    disabled={
                                        (require2FABeforeRegistration && !preRegistration2FAComplete) ||
                                        inProgress ||
                                        (_.isNil(oAuthUser.email) && _.isEmpty(email)) ||
                                        emailError
                                    }
                                    style={{ height: 40 }}
                                    onClick={handleSubmit}
                                >
                                    <span>{loc('continue', lang)}</span>
                                </Button>
                            </div>
                        </div>
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            style={{
                                marginTop: theme.spacing.unit,
                                marginBottom: theme.spacing.unit * 2,
                                fontSize: '60%'
                            }}
                        >
                            {loc('registrationOAuth5', lang)} {process.env.REACT_APP_BRAND_NAME}{' '}
                            <ExternalLink
                                text={loc('termsAndConditions', lang)}
                                url={`${process.env.REACT_APP_ORIGIN_URL}/terms`}
                            />{' '}
                            {loc('registrationOAuth6', lang)}{' '}
                            <ExternalLink
                                text={loc('privacyNotice', lang)}
                                url={`${process.env.REACT_APP_ORIGIN_URL}/privacy`}
                            />
                            .
                        </Typography>
                    </DialogContent>
                </span>
            )}
            {_.isNil(oAuthUser) && (
                <span style={{ marginTop: theme.spacing.unit, textAlign: 'center' }}>
                    <Typography variant="subtitle1" style={{ marginTop: theme.spacing.unit * 2 }}>
                        {loc('authenticating', lang)}...
                    </Typography>
                    <DialogContent style={{ marginTop: theme.spacing.unit }}>
                        <div style={{ margin: 'auto 0' }}>
                            <CircularProgress />
                        </div>
                    </DialogContent>
                </span>
            )}
        </Dialog>
    );
}

export default withMobileDialog({ breakpoint: 'xs' })(withTheme()(OAuthDialog));

function isIncompletePhoneNumber(phoneNumber) {
    return phoneNumber.toString().length < 10;
}

function WaitToRetry({ time }) {
    const [timeElapsed, setTimeElapsed] = useState(getTimeDifferenceFromNow(time));

    useEffect(() => {
        setInterval(() => {
            setTimeElapsed(getTimeDifferenceFromNow(time));
        }, 1000);
    });
    return <>{timeElapsed}</>;
}

function getTimeDifferenceFromNow(time) {
    return formatsSecondsToTime(
        moment(time)
            .diff(moment(new Date()), 'seconds')
            .toString()
    );
}
