import React from 'react';
import _ from 'lodash';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    withTheme,
    Chip,
    colors,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Link,
    Tooltip
} from '@material-ui/core';
import { Icon as MDIcon } from '@mdi/react';
import { Clipboard } from '@capacitor/clipboard';
import { mdiPackageVariantClosedCheck } from '@mdi/js';

function PastLocationDialog(props) {
    const { open, onClose, title, currentPastLocations = [], searchTerm, theme, onSnackbar } = props;
    return (
        <Dialog open={open} maxWidth="md" fullWidth onClose={onClose}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Address</TableCell>
                            <TableCell align="right">Business Name</TableCell>
                            <TableCell align="right">Override Phone</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {currentPastLocations.map((location, idx) => (
                            <TableRow key={idx} selected={location.description === searchTerm}>
                                <TableCell>
                                    <span style={{ display: 'flex' }}>
                                        <Link
                                            href="#"
                                            onClick={e => {
                                                e.preventDefault();
                                                Clipboard.write({ string: location.description });
                                                onSnackbar(`Copied ${location.description} to clipboard!`);
                                            }}
                                            style={{
                                                marginRight: '5px'
                                            }}
                                        >
                                            {location.description}
                                        </Link>
                                        {(location.collectorName || location.collectorCode) && (
                                            <Tooltip
                                                title={
                                                    location.collectorName
                                                        ? location.collectorName
                                                        : location.collectorCode
                                                        ? location.collectorCode
                                                        : ''
                                                }
                                            >
                                                <MDIcon
                                                    path={mdiPackageVariantClosedCheck}
                                                    size={0.7}
                                                    color={theme.palette.primary.main}
                                                />
                                            </Tooltip>
                                        )}
                                    </span>
                                </TableCell>
                                <TableCell align="right">{_.get(location, 'pickupData.businessName', '')}</TableCell>
                                <TableCell align="right">{_.get(location, 'pickupData.phone', '')}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </DialogContent>
        </Dialog>
    );
}

export default withTheme()(PastLocationDialog);
