import React, { useState, useEffect, useContext } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import moment from 'moment-timezone';

import * as terms from 'localizations/terms';

import { withTheme } from '@material-ui/core/styles';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import {
    FormControl,
    FormControlLabel,
    FormGroup,
    LinearProgress,
    Collapse,
    colors,
    Typography,
    MenuItem
} from '@material-ui/core';
import { Dialog, DialogContent, DialogActions, DialogTitle } from '@material-ui/core';
import { Checkbox, TextField, Switch } from '@material-ui/core';
import { Button } from '@material-ui/core';
import CustomTextInput from '../../InputComponents/CustomTextInput';
import PromoCampaignInput from '../../InputComponents/PromoCampaignInput';

import DatePicker from 'components/DateTimePickersTz/DatePicker';
import useDatePicker from 'components/DateTimePickersTz/hooks/useDatePicker';

import CharitySelect from 'components/CharitySelect/CharitySelect';
import { _commodity, _time } from 'std';
import { getQueryStringValue } from 'utils/query';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

function PromoForm(props) {
    const {
        active,
        code,
        label,
        description,
        startDate,
        expirationDate,
        oneDayOnly,
        lockByCampaign,
        newCustomersOnly,
        numberOfUses,
        internalUseOnly,
        recurring,
        campaign,
        onSubmit,
        _id,
        charity,
        subdivision,
        requireFeatured,
        loading,
        theme,
        editing,
        open,
        onClose,
        fullScreen,
        allData,
        charities,
        commodities,
        allCommodities,
        fastTrackCharity,
        fastTrack,
        defaultLabel,
        defaultDescription,
        defaultCharity,
        defaultRequireFeautured,
        defaultCustomerSplit,
        defaultLockByCampaign,
        conditionalThresholds,
        growthPlanEnabled
    } = props;
    const { lang } = useContext(LocalizationContext);

    //Formik won't work for date selectors need to store these here
    const [startDateTouched, setStartDateTouched] = useState(false);
    const [expirationDateTouched, setExpirationDateTouched] = useState(false);

    const [showThresholdConditions, setShowThresholdConditions] = useState(!_.isEmpty(conditionalThresholds));

    const { date: formStartDate, handleChangeDate: setFormStartDate } = useDatePicker({
        timezones: [process.env.REACT_APP_REGION_TIMEZONE],
        initVal: _.isNil(startDate) ? _time.getStartOfToday(process.env.REACT_APP_REGION_TIMEZONE) : moment(startDate),
        initToNull: false
    });
    const { date: formExpirationDate, handleChangeDate: setFormExpirationDate } = useDatePicker({
        timezones: [process.env.REACT_APP_REGION_TIMEZONE],
        initVal: _.isNil(expirationDate)
            ? _time.getEndOfMonthStartOfDayObjFromDate(process.env.REACT_APP_REGION_TIMEZONE)
            : moment(expirationDate),
        initToNull: false
    });

    //used in formik submission logic, not submitted with other values
    const [limitPromoUses, setLimitPromoUses] = useState(!_.isNil(numberOfUses));
    const [useExpirationDate, setUseExpirationDate] = useState(!_.isNil(expirationDate));
    const [campaignInputValue, setCampaignInputValue] = useState(_.isNil(campaign) ? '' : campaign);

    const campaigns = _.filter(_.uniq(allData.map(data => data.campaign)), campaign => campaign);

    const handleFormSubmit = values => {
        if (new Date(formExpirationDate) < new Date(formStartDate) && useExpirationDate) {
            //TODO: figure out a way to do this when fomik validates the form
            setStartDateTouched(true);
            setExpirationDateTouched(true);
            return;
        }
        values.campaign = campaignInputValue;

        values.startDate = formStartDate;
        if (useExpirationDate) {
            values.expirationDate = formExpirationDate;
        } else {
            values.expirationDate = null;
        }
        values._id = _id;
        values.code = values.code.trim();
        values.description = values.description.trim();
        values.label = values.label.trim();
        if (!limitPromoUses) {
            values.numberOfUses = null;
        }

        if (!showThresholdConditions || _.isEmpty(values.conditionalThresholds)) {
            values.conditionalThresholds = {};
        }
        values.variables.customerSplit = values.variables.customerSplit / 100;

        onSubmit(values);
    };

    const initialCommodities = () => {
        if (!_.isNil(commodities)) return commodities;

        if (_.get(_.find(allCommodities, c => c.skuType === 'beverage'), '_id')) {
            return [_.find(allCommodities, c => c.skuType === 'beverage')._id];
        }

        return [];
    };

    const initialThresholds = () => {
        if (!_.isEmpty(conditionalThresholds)) return conditionalThresholds;

        const commodities = initialCommodities();
        const thresholds = {};

        commodities.forEach(commodityId => {
            const commodity = _.find(allCommodities, { _id: commodityId });
            if (!commodity || (commodity.skuType !== 'beverage' && commodity.skuType !== 'collectionbins')) return;
            thresholds[commodity.skuType] = 0;
        });

        return thresholds;
    };

    const formik = useFormik({
        initialValues: {
            active: _.isNil(active) ? true : active,
            code: _.isNil(code) ? '' : code,
            label: _.isNil(label) ? defaultLabel : label,
            description: _.isNil(description) ? defaultDescription : description,
            variables: {
                customerSplit: _.isNil(_.get(props, 'variables.customerSplit'))
                    ? defaultCustomerSplit
                    : _.get(props, 'variables.customerSplit') * 100
            },
            lockByCampaign: _.isNil(lockByCampaign) ? defaultLockByCampaign : lockByCampaign,
            newCustomersOnly: _.isNil(newCustomersOnly) ? false : newCustomersOnly,
            numberOfUses: _.isNil(numberOfUses) ? '' : numberOfUses,
            internalUseOnly: _.isNil(internalUseOnly) ? false : internalUseOnly,
            recurring: _.isNil(recurring) ? false : recurring,
            campaign: _.isNil(campaign) ? '' : campaign,
            charity: _.isNil(charity) ? defaultCharity : charity._id,
            subdivision: _.isNil(subdivision) ? '' : subdivision,
            requireFeatured: _.isNil(requireFeatured) ? defaultRequireFeautured : requireFeatured,
            commodities: initialCommodities(),
            conditionalThresholds: initialThresholds(),
            oneDayOnly: _.isNil(oneDayOnly) ? false : oneDayOnly
        },
        validationSchema: Yup.object({
            active: Yup.boolean(),
            code: Yup.string()
                .required('You need to provide a promo code')
                .strict()
                .uppercase()
                .ensure(),
            label: Yup.string()
                .required()
                .strict()
                .uppercase()
                .ensure(),
            description: Yup.string()
                .required()
                .uppercase()
                .ensure(),
            variables: Yup.object({
                customerSplit: Yup.number()
                    .min(0, 'Percentage must be 0 or more')
                    .max(100, 'Percentage must be 100 or less')
                    .required('You must provide a number between 0 and 100')
                    .integer('Must be an integer')
            }),
            lockByCampaign: Yup.boolean(),
            newCustomersOnly: Yup.boolean(),
            numberOfUses: Yup.number()
                .positive('This must be greater than 0')
                .integer('This must be a whole number'),
            internalUseOnly: Yup.boolean(),
            recurring: Yup.boolean(),
            campaign: Yup.string(),
            commodities: Yup.array()
                .min(1, 'You must select at least 1')
                .required('You must select at least 1'),
            conditionalThresholds: Yup.lazy(obj =>
                Yup.object(
                    _.mapValues(obj, (v, k) => {
                        if (!showThresholdConditions) {
                            return Yup.object().nullable(true);
                        }

                        return Yup.number()
                            .integer('This must be a whole number')
                            .moreThan(-1, 'This must be greater than 0')
                            .required('You must enter a threshold value');
                    })
                )
            )
        }),
        onSubmit: handleFormSubmit
    });

    const changeCommodities = e => {
        let updatedCommodities = e.target.value;

        const currentThresholds = _.get(formik, 'values.conditionalThresholds');
        const updatedThresholds = {};

        updatedCommodities.forEach(commodityId => {
            const commodity = _.find(allCommodities, { _id: commodityId });
            if (!commodity || (commodity.skuType !== 'beverage' && commodity.skuType !== 'collectionbins')) return; // Update this for dynamic thresholds

            updatedThresholds[commodity.skuType] = _.get(currentThresholds, `${commodity.skuType}`, 0);
        });

        if (_.isEmpty(updatedThresholds)) {
            setShowThresholdConditions(false);
        }

        formik.setFieldValue('commodities', updatedCommodities);
        formik.setFieldValue('conditionalThresholds', updatedThresholds);
    };

    const handleStartDate = date => {
        if (formik.values.oneDayOnly) {
            const expirationDate = moment(date)
                .clone()
                .add(1, 'day')
                .startOf('day');

            setFormExpirationDate(expirationDate);
        }

        setStartDateTouched(true);
        setFormStartDate(date);
    };

    const toggleOneDayOnly = e => {
        let toggle = e.target.checked;

        if (toggle) {
            const expirationDate = moment(formStartDate)
                .clone()
                .add(1, 'day')
                .startOf('day');

            setUseExpirationDate(true);
            setFormExpirationDate(expirationDate);
            formik.setFieldValue('recurring', false);
        }

        formik.setFieldValue('oneDayOnly', toggle);
    };

    const beverageId = _.get(_.find(allCommodities, { skuType: 'beverage' }), '_id');

    return (
        <Dialog onClose={onClose} open={open} fullScreen={fullScreen}>
            <DialogTitle>{editing ? 'Update' : 'Create'}</DialogTitle>
            <DialogContent>
                <Typography style={{ color: colors.orange[500] }} variant="h6">
                    {(_.isNil(active) ? false : !active) && 'This promo code is currently disabled'}
                </Typography>
                {editing && (
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Switch
                                    id="active"
                                    name="active"
                                    color="primary"
                                    onChange={formik.handleChange}
                                    value={formik.values.active}
                                    checked={formik.values.active}
                                    onBlur={formik.handleBlur}
                                />
                            }
                            label="Active"
                        />
                    </FormGroup>
                )}
                <CustomTextInput
                    {...formik.getFieldProps('code')}
                    upperCase
                    removeWhitespace
                    alphanumeric
                    error={formik.touched.code && formik.errors.code ? true : null}
                    label="Promo Code"
                    margin="normal"
                    variant="outlined"
                    helperText={formik.touched.code && formik.errors.code ? formik.errors.code : null}
                    fullWidth
                    data-cy="promo-code"
                />
                <CustomTextInput
                    upperCase
                    {...formik.getFieldProps('label')}
                    error={formik.touched.label && formik.errors.label ? true : null}
                    label="Label"
                    margin="normal"
                    variant="outlined"
                    helperText={formik.touched.label && formik.errors.label ? formik.errors.label : null}
                    fullWidth
                    data-cy="promo-label"
                />
                <TextField
                    {...formik.getFieldProps('description')}
                    error={formik.touched.description && formik.errors.description ? true : null}
                    label="Description"
                    margin="normal"
                    variant="outlined"
                    helperText={
                        formik.touched.description && formik.errors.description ? formik.errors.description : null
                    }
                    fullWidth
                    data-cy="promo-description"
                />

                <TextField
                    {...formik.getFieldProps('variables.customerSplit')}
                    type="number"
                    error={
                        _.get(formik.touched, 'variables.customerSplit', false) &&
                        _.get(formik.errors, 'variables.customerSplit', false)
                            ? true
                            : null
                    }
                    label="Customer Refund Percentage"
                    margin="normal"
                    variant="outlined"
                    helperText={
                        _.get(formik.touched, 'variables.customerSplit', false) &&
                        _.get(formik.errors, 'variables.customerSplit', false)
                            ? formik.errors.variables.customerSplit
                            : 'Enter as a percentage between 0 and 100'
                    }
                    fullWidth
                    data-cy="promo-percentage"
                />

                <PromoCampaignInput
                    campaigns={campaigns}
                    value={campaignInputValue}
                    onChange={value => setCampaignInputValue(value)}
                />

                <CharitySelect
                    style={{ marginTop: theme.spacing.unit * 3, marginBottom: theme.spacing.unit }}
                    hideTaxReciptAndSubdivision={false}
                    donate={!_.isNil(_.get(formik, 'values.charity'))}
                    charities={charities}
                    charityPreferred={_.get(formik, 'values.charity')}
                    subdivisionPreferred={_.get(formik, 'values.subdivision')}
                    taxReceiptRequested={false}
                    onCharityPreferred={(charityId, subdivision) => () => {
                        formik.setFieldValue('charity', charityId);
                        formik.setFieldValue('subdivision', subdivision);
                    }}
                    onChange={e => {
                        if (_.get(e, 'target.name') === 'subdivisionPreferred') {
                            formik.setFieldValue('subdivision', _.get(e, 'target.value'));
                        }
                    }}
                    //onChange={() => {}}
                    onSetDonation={donation => {
                        if (!donation) {
                            formik.setFieldValue('charity', null);
                            formik.setFieldValue('subdivision', null);
                            formik.setFieldValue('requireFeatured', false);
                        }
                    }}
                    onToggleTaxReceiptRequested={() => {}}
                    growthPlanEnabled={growthPlanEnabled}
                />

                <Collapse in={!_.isNil(_.get(formik, 'values.charity'))} style={{ marginBottom: theme.spacing.unit }}>
                    <FormGroup error={formik.touched.internalUseOnly && formik.errors.internalUseOnly ? true : null}>
                        <FormControlLabel
                            control={
                                <Switch
                                    data-cy="promo-form-require-featured"
                                    id="requireFeatured"
                                    name="requireFeatured"
                                    color="primary"
                                    onChange={formik.handleChange}
                                    value={formik.values.requireFeatured}
                                    checked={formik.values.requireFeatured}
                                    onBlur={formik.handleBlur}
                                />
                            }
                            label={`${terms.ORGANIZATION_NAME} must be featured`}
                        />
                    </FormGroup>
                </Collapse>

                <TextField
                    data-cy="promo-commodities"
                    {...formik.getFieldProps('commodities')}
                    onChange={changeCommodities}
                    fullWidth
                    select
                    SelectProps={{ multiple: true }}
                    error={
                        _.get(formik.touched, 'commodities', false) && _.get(formik.errors, 'commodities', false)
                            ? true
                            : null
                    }
                    label="Applicable Commodities"
                    margin="normal"
                    variant="outlined"
                    helperText={
                        _.get(formik.touched, 'commodities', false) &&
                        _.get(formik.errors, 'commodities', false) &&
                        formik.errors.commodities
                    }
                    style={{ marginTop: theme.spacing.unit, marginBottom: theme.spacing.unit * 2 }}
                >
                    {allCommodities.map(commodity => (
                        <MenuItem
                            data-cy={`promo-commodity-${_commodity.getSkuType(commodity)}`}
                            value={commodity._id}
                            key={commodity._id}
                        >
                            {_commodity.getSkuType(commodity)}
                        </MenuItem>
                    ))}
                </TextField>

                <FormControl fullWidth>
                    <DatePicker
                        style={{ marginTop: theme.spacing.unit * 2, marginBottom: theme.spacing.unit }}
                        error={
                            new Date(formStartDate) > new Date(formExpirationDate) &&
                            startDateTouched &&
                            expirationDateTouched
                                ? true
                                : null
                        }
                        format="MMM/DD/YYYY"
                        variant="outlined"
                        label={_.get(formik, 'values.oneDayOnly') ? 'Date of Event' : 'Start Date'}
                        margin="normal"
                        helperText={
                            new Date(formStartDate) > new Date(formExpirationDate) &&
                            startDateTouched &&
                            expirationDateTouched
                                ? 'Expiration date is before start date'
                                : ''
                        }
                        timezone={process.env.REACT_APP_REGION_TIMEZONE}
                        value={formStartDate}
                        onChange={date => handleStartDate(date)}
                    />
                </FormControl>

                <FormGroup error={formik.touched.oneDayOnly && formik.errors.oneDayOnly ? true : false}>
                    <FormControlLabel
                        control={
                            <Switch
                                id="oneDayOnly"
                                name="oneDayOnly"
                                color="primary"
                                onChange={e => toggleOneDayOnly(e)}
                                value={formik.values.oneDayOnly}
                                checked={formik.values.oneDayOnly}
                                // onBlur={formik.handleBlur}
                            />
                        }
                        label="One Day Event"
                    />
                </FormGroup>

                {/*TODO: make end date default to next day*/}
                {!_.get(formik, 'values.oneDayOnly') && (
                    <>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Switch
                                        color="primary"
                                        onChange={e => setUseExpirationDate(e.target.checked)}
                                        checked={useExpirationDate}
                                        disabled={formik.values.oneDayOnly}
                                    />
                                }
                                label="Add Expiration Date"
                            />
                        </FormGroup>

                        <Collapse in={useExpirationDate} style={{ marginBottom: theme.spacing.unit }}>
                            <FormControl fullWidth>
                                <DatePicker
                                    error={
                                        new Date(formStartDate) > new Date(formExpirationDate) &&
                                        startDateTouched &&
                                        expirationDateTouched
                                            ? true
                                            : null
                                    }
                                    format="MMM/DD/YYYY"
                                    variant="outlined"
                                    label="Expiration Date"
                                    helperText={
                                        new Date(formStartDate) > new Date(formExpirationDate) &&
                                        startDateTouched &&
                                        expirationDateTouched
                                            ? 'Expiration date is before start date'
                                            : ''
                                    }
                                    timezone={process.env.REACT_APP_REGION_TIMEZONE}
                                    value={formExpirationDate}
                                    onChange={date => {
                                        setExpirationDateTouched(true);
                                        setFormExpirationDate(date);
                                    }}
                                    disabled={formik.values.oneDayOnly}
                                />
                            </FormControl>
                        </Collapse>
                    </>
                )}

                <FormGroup>
                    <FormControlLabel
                        control={
                            <Switch
                                color="primary"
                                onChange={e => setLimitPromoUses(e.target.checked)}
                                checked={limitPromoUses}
                            />
                        }
                        label="Limit Number Of Uses"
                    />
                </FormGroup>

                <Collapse in={limitPromoUses}>
                    <TextField
                        {...formik.getFieldProps('numberOfUses')}
                        type="number"
                        error={
                            _.get(formik.touched, 'numberOfUses', false) && _.get(formik.errors, 'numberOfUses', false)
                                ? true
                                : null
                        }
                        label="Number Of Uses"
                        margin="normal"
                        variant="outlined"
                        helperText={
                            _.get(formik.touched, 'numberOfUses', false) && _.get(formik.errors, 'numberOfUses', false)
                                ? formik.errors.numberOfUses
                                : null
                        }
                        fullWidth
                    />
                </Collapse>

                <FormGroup error={formik.touched.lockByCampaign && formik.errors.lockByCampaign ? true : null}>
                    <FormControlLabel
                        control={
                            <Switch
                                id="lockByCampaign"
                                name="lockByCampaign"
                                color="primary"
                                onChange={formik.handleChange}
                                value={formik.values.lockByCampaign}
                                checked={formik.values.lockByCampaign}
                                onBlur={formik.handleBlur}
                            />
                        }
                        margin="normal"
                        label="Single Use Per Customer/Campaign"
                    />
                </FormGroup>

                <FormGroup error={formik.touched.newCustomersOnly && formik.errors.newCustomersOnly ? true : null}>
                    <FormControlLabel
                        control={
                            <Switch
                                id="newCustomersOnly"
                                name="newCustomersOnly"
                                color="primary"
                                onChange={formik.handleChange}
                                value={formik.values.newCustomersOnly}
                                checked={formik.values.newCustomersOnly}
                                onBlur={formik.handleBlur}
                            />
                        }
                        label="New Customers Only"
                    />
                </FormGroup>

                <FormGroup error={formik.touched.internalUseOnly && formik.errors.internalUseOnly ? true : null}>
                    <FormControlLabel
                        control={
                            <Switch
                                id="internalUseOnly"
                                name="internalUseOnly"
                                color="primary"
                                onChange={formik.handleChange}
                                value={formik.values.internalUseOnly}
                                checked={formik.values.internalUseOnly}
                                onBlur={formik.handleBlur}
                            />
                        }
                        label="Internal Use Only"
                    />
                </FormGroup>

                <FormGroup error={formik.touched.recurring && formik.errors.recurring ? true : null}>
                    <FormControlLabel
                        control={
                            <Switch
                                id="recurring"
                                name="recurring"
                                color="primary"
                                onChange={formik.handleChange}
                                value={formik.values.recurring}
                                checked={formik.values.recurring}
                                onBlur={formik.handleBlur}
                                disabled={formik.values.oneDayOnly}
                            />
                        }
                        label="Recurring"
                    />
                </FormGroup>

                {_.get(formik, 'values.commodities', []).includes(beverageId) && (
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Switch
                                    name={'showThresholdConditions'}
                                    onChange={() => setShowThresholdConditions(!showThresholdConditions)}
                                    value={showThresholdConditions}
                                    checked={showThresholdConditions}
                                    color="primary"
                                />
                            }
                            label="Volume Threshold"
                        />
                    </FormGroup>
                )}

                {showThresholdConditions &&
                    _.get(formik, 'values.conditionalThresholds') &&
                    Object.keys(formik.values.conditionalThresholds).map(commodity => {
                        const thresholdField = `conditionalThresholds.${commodity}`;
                        const minimumAmount = _.get(formik, 'values.conditionalThresholds', {})[commodity];

                        const thresholdTouched = _.get(formik.touched, thresholdField, false);
                        const thresholdErrors = _.get(formik.errors, thresholdField, false);

                        return (
                            <TextField
                                {...formik.getFieldProps(thresholdField)}
                                value={minimumAmount}
                                error={thresholdTouched && thresholdErrors ? true : null}
                                label={commodity === 'beverage' ? 'Containers' : `${commodity} Units`}
                                margin="normal"
                                variant="outlined"
                                helperText={
                                    thresholdTouched && thresholdErrors
                                        ? `You must select a value greater than or equal to zero`
                                        : null
                                }
                                fullWidth
                            />
                        );
                    })}

                {loading && <LinearProgress />}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} disabled={loading}>
                    Cancel
                </Button>
                <Button
                    type="submit"
                    color="primary"
                    disabled={loading}
                    onClick={formik.handleSubmit}
                    data-cy="promo-submit"
                >
                    {loc('submit', lang)}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default withMobileDialog()(withTheme()(PromoForm));
