import React from 'react';
import _ from 'lodash';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import {
    Dialog,
    DialogActions,
    DialogContent,
    Button,
    withMobileDialog,
    withTheme,
    DialogTitle
} from '@material-ui/core';

import { getSelect, getTextInput } from '../helperFunctions';

function TaxForm(props) {
    const {
        tax, //NOTE: will be null/undefined if creating a new one
        open,
        onClose,
        onSubmit,
        onSnackbar,
        theme,
        fullScreen
    } = props;

    const formik = useFormik({
        initialValues: {
            _id: _.get(tax, '_id'),
            name: _.get(tax, 'name', ''),
            type: _.get(tax, 'configuration.type', 0),
            value: _.get(tax, 'configuration.value', 0) * 100
        },
        validationSchema: Yup.object({
            name: Yup.string().required(),
            type: Yup.string().required(),
            value: Yup.number()
                .required()
                .min(0, 'This number must be greater than 0')
                .max(100, 'This number must be less than 100')
        }),
        onSubmit: values => {
            const newTaxObj = {
                _id: values._id,
                name: values.name,
                configuration: {
                    type: values.type,
                    value: values.value / 100
                }
            };
            onSubmit(newTaxObj);
        }
    });

    return (
        <Dialog onClose={onClose} open={open} fullScreen={fullScreen} fullWidth>
            <DialogTitle>Tax</DialogTitle>
            <DialogContent>
                {getTextInput(theme, 'name', 'Name', formik)}
                <div style={{ display: 'flex' }}>
                    <div style={{ marginRight: theme.spacing.unit, flexGrow: 1 }}>
                        {getTextInput(theme, 'value', 'Amount (%)', formik, 'number')}
                    </div>
                    <div style={{ flexGrow: 1 }}>
                        {getSelect(theme, 'type', 'Type', ['none', 'excluded', 'included'], formik)}
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={formik.handleSubmit} data-cy="tax-crud-submit-btn">
                    {'Submit'}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default withMobileDialog()(withTheme()(TaxForm));
