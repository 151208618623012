import React from 'react';
import _ from 'lodash';
import { _time, _user } from 'std';
import { withTheme } from '@material-ui/core';

import { deviceHelper } from 'utils/misc';
import { useState } from 'react';
import { openWindow } from 'helpers/windowHelper';
import OperatorContext from 'utils/contexts/OperatorContext';
import { useContext } from 'react';

import useTable from 'components/Table/useTable';
import DashBoardTable from 'components/Table/DashBoardTable';

import useDateRangePicker from 'components/DateTimePickersTz/hooks/useDateRangePicker';
import DateRangePicker from 'components/DateTimePickersTz/DateRangePicker';
import useTipsFilters from './hooks/useTipsFilters';

import {
    TableRow,
    TableCell,
    Paper,
    Grid,
    FormControl,
    MenuItem,
    Select,
    Menu,
    IconButton,
    Icon,
    Button,
    TextField,
    InputLabel,
    OutlinedInput,
    Checkbox,
    ListItemText,
    Tooltip,
    Avatar,
    Typography,
    colors
} from '@material-ui/core';

import { formatAsCurrency } from 'utils/misc';
import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery';
import { mdiAccountOff } from '@mdi/js';
import MDIcon from '@mdi/react';
import moment from 'moment-timezone';

const TipsHeaders = [
    { name: 'Actions', key: null },
    { name: 'Customer Name^', key: 'customer.name.first' },
    { name: 'Collector Name^', key: 'collector.name' },
    { name: 'Driver Name^', key: 'driver.name.first' },
    { name: 'Pay Through System^', key: 'driver.permissions.payThroughSystem' },
    { name: 'Date Tipped^', key: 'dateTipped' },
    { name: 'Original Amount^', key: 'originalAmount' },
    { name: 'Adjusted Amount^', key: 'adjustedAmount' }
];

function Tips({ theme, history }) {
    const operator = useContext(OperatorContext);

    const onViewCustomer = user => {
        if (_user.isCustomer(user)) {
            const URL = `/customers/${user._id}`;
            openWindow(history, !deviceHelper.isNativeApp(), URL);
            return;
        }
    };

    const onViewBulk = bulk => {
        if (deviceHelper.isNativeApp()) {
            history.push(`/operators/${_user.getId(operator)}/bulks/${bulk._id}`);
        } else {
            window.open(`/operators/${_user.getId(operator)}/bulks/${bulk._id}`, '_blank');
        }
    };

    const collapseSearch = useMediaQuery(theme.breakpoints.down('sm'));
    const [filtersOpen, setFiltersOpen] = useState(null);

    const {
        startDate,
        endDate,
        timezone,
        dateWindow,
        handleChangeStartDate,
        handleChangeEndDate,
        handleGoForwards,
        handleGoBackwards,
        handleWindowSelect
    } = useDateRangePicker({
        saveStateInURL: true,
        timezones: [process.env.REACT_APP_REGION_TIMEZONE],
        initStartVal: _time.getStartOfWeek(process.env.REACT_APP_REGION_TIMEZONE),
        initEndVal: _time.getEndOfDay(process.env.REACT_APP_REGION_TIMEZONE)
    });

    const {
        allCollectors,
        collectorsSelected,
        setCollectorsSelected,
        allDrivers,
        driversSelected,
        setDriversSelected,
        handleAllFilterCollectors
    } = useTipsFilters();

    const {
        loading,
        getData,
        response,
        page,
        rowsPerPage,
        filteredData,
        search,
        filteredHeaders,
        totalDataEntries,
        handleChange,
        handleSearch,
        handleChangeFilterHeaders,
        handleAllFilterHeaders,
        handleChangePage,
        handleChangeRowsPerPage
    } = useTable({
        url: `/tips?collectors=${collectorsSelected}&receivers=${driversSelected}`,
        key: 'tips',
        headers: TipsHeaders.map(h => h.name)
    });

    const renderAppliedFeeRow = (tip, filteredHeaders, key) => {
        return (
            <TableRow key={tip._id + key}>
                {filteredHeaders.includes('Actions') && (
                    <TableCell style={{ minWidth: 150 }}>
                        <Tooltip title="View User" disableFocusListener disableTouchListener>
                            <IconButton
                                onClick={() => {
                                    onViewCustomer(tip.customer);
                                }}
                            >
                                <Icon>visibility</Icon>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="View Bulk" disableFocusListener disableTouchListener>
                            <IconButton
                                onClick={() => {
                                    onViewBulk(tip.order);
                                }}
                            >
                                <Icon>ballot</Icon>
                            </IconButton>
                        </Tooltip>
                    </TableCell>
                )}
                {filteredHeaders.includes('Customer Name^') && (
                    <TableCell>
                        {_.get(tip, 'customer.name.first', '')} {_.get(tip, 'customer.name.last', '')}
                    </TableCell>
                )}
                {filteredHeaders.includes('Collector Name^') && (
                    <TableCell>{_.get(tip, 'collector.name', '')}</TableCell>
                )}
                {filteredHeaders.includes('Driver Name^') && (
                    <TableCell>
                        {_.get(tip, 'driver.name.first', '')} {_.get(tip, 'driver.name.last', '')}
                    </TableCell>
                )}
                {filteredHeaders.includes('Pay Through System^') && (
                    <TableCell>
                        <Icon
                            style={{
                                color: _.get(tip, 'driver.permissions.payThroughSystem', false)
                                    ? colors.green[500]
                                    : colors.red[500]
                            }}
                        >
                            {_.get(tip, 'driver.permissions.payThroughSystem', false) ? 'check' : 'close'}
                        </Icon>
                    </TableCell>
                )}
                {filteredHeaders.includes('Date Tipped^') && (
                    <TableCell>
                        {_.get(tip, 'dateTipped', '') ? moment(_.get(tip, 'dateTipped', '')).format('YYYY-MM-DD') : ''}
                    </TableCell>
                )}
                {filteredHeaders.includes('Original Amount^') && (
                    <TableCell>{formatAsCurrency(_.get(tip, 'originalAmount', 0) * 100)}</TableCell>
                )}
                {filteredHeaders.includes('Adjusted Amount^') && (
                    <TableCell>{formatAsCurrency(_.get(tip, 'adjustedAmount', 0) * 100)}</TableCell>
                )}
            </TableRow>
        );
    };
    const renderSearch = children => {
        if (collapseSearch) {
            return (
                <Menu open={Boolean(filtersOpen)} anchorEl={filtersOpen} onClose={handleFiltersOpen(true)}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            padding: theme.spacing.unit * 2
                        }}
                    >
                        {children}
                    </div>
                </Menu>
            );
        } else {
            return (
                <div
                    style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'space-between' }}
                >
                    {/* <div>{customSearch}</div> */}
                    <div style={{ display: 'flex', alignItems: 'center' }}>{children}</div>
                </div>
            );
        }
    };

    const handleSubmit = e => {
        getData();
        handleSearch(e);
        if (filtersOpen) {
            setFiltersOpen(false);
        }
    };

    const inputStyles = {
        margin: theme.spacing.unit / 2
    };

    const handleFiltersOpen = close => e => {
        if (close || Boolean(filtersOpen)) {
            setFiltersOpen(null);
        } else {
            setFiltersOpen(e.currentTarget);
        }
    };

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <div style={{ padding: theme.spacing.unit * 2, paddingBottom: theme.spacing.unit }}>
                        <Paper
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                padding: theme.spacing.unit * 2,
                                height: '100%',
                                flexWrap: 'wrap'
                            }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <DateRangePicker
                                    timezone={timezone}
                                    startDate={startDate}
                                    endDate={endDate}
                                    window={dateWindow}
                                    disabled={loading}
                                    handlePrevious={handleGoBackwards}
                                    handleNext={handleGoForwards}
                                    handleChangeStartDate={handleChangeStartDate}
                                    handleChangeEndDate={handleChangeEndDate}
                                />
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexWrap: 'wrap'
                                }}
                            >
                                <form onSubmit={handleSubmit}>
                                    {renderSearch(
                                        <>
                                            <FormControl>
                                                <Select
                                                    multiple
                                                    disabled={loading}
                                                    onChange={e => setCollectorsSelected(e.target.value)}
                                                    style={{
                                                        maxWidth: '250px',
                                                        margin: theme.spacing.unit
                                                    }}
                                                    value={collectorsSelected}
                                                    renderValue={s => `${s.length} Collectors`}
                                                >
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent: 'space-around'
                                                        }}
                                                    >
                                                        <Button
                                                            disabled={loading}
                                                            onClick={handleAllFilterCollectors(true)}
                                                        >
                                                            All
                                                        </Button>
                                                        <Button
                                                            disabled={loading}
                                                            onClick={handleAllFilterCollectors(false)}
                                                        >
                                                            None
                                                        </Button>
                                                    </div>
                                                    {allCollectors.map(({ _id, name }) => (
                                                        <MenuItem disabled={loading} key={_id} value={_id}>
                                                            <Checkbox checked={collectorsSelected.includes(_id)} />
                                                            <ListItemText>{name}</ListItemText>
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>

                                            <FormControl>
                                                <Select
                                                    disabled={loading}
                                                    multiple
                                                    renderValue={val =>
                                                        `${val.length} Driver${val.length !== 1 ? 's' : ''}`
                                                    }
                                                    value={driversSelected}
                                                    onChange={e => setDriversSelected(e.target.value)}
                                                    style={{
                                                        maxWidth: '250px',
                                                        margin: theme.spacing.unit
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent: 'space-around'
                                                        }}
                                                    >
                                                        <Button
                                                            onClick={e => {
                                                                e.stopPropagation();
                                                                setDriversSelected(
                                                                    _(allDrivers)
                                                                        .filter(driver =>
                                                                            collectorsSelected.includes(
                                                                                driver.collector._id
                                                                            )
                                                                        )
                                                                        .map(driver => driver._id)
                                                                        .value()
                                                                );
                                                            }}
                                                        >
                                                            All
                                                        </Button>
                                                        <Button
                                                            onClick={e => {
                                                                e.stopPropagation();
                                                                setDriversSelected([]);
                                                            }}
                                                        >
                                                            None
                                                        </Button>
                                                    </div>
                                                    {_.sortBy(allDrivers, d => d.name.first.toLowerCase()).map(
                                                        driver => {
                                                            const { _id, collector, banned } = driver;
                                                            if (!collectorsSelected.includes(collector._id))
                                                                return null;
                                                            return (
                                                                <MenuItem
                                                                    key={_id}
                                                                    value={_id}
                                                                    style={{
                                                                        textTransform: 'capitalize'
                                                                    }}
                                                                >
                                                                    {_user.getNameFull(driver).toLowerCase()}
                                                                    {banned && (
                                                                        <span
                                                                            style={{
                                                                                marginLeft: theme.spacing.unit,
                                                                                display: 'grid',
                                                                                placeItems: 'center'
                                                                            }}
                                                                        >
                                                                            <MDIcon
                                                                                path={mdiAccountOff}
                                                                                size={0.85}
                                                                                color={theme.palette.text.secondary}
                                                                            />
                                                                        </span>
                                                                    )}
                                                                </MenuItem>
                                                            );
                                                        }
                                                    )}
                                                </Select>
                                            </FormControl>

                                            <FormControl style={inputStyles}>
                                                <InputLabel htmlFor="collection" variant="outlined">
                                                    Column Filters
                                                </InputLabel>
                                                <Select
                                                    variant="outlined"
                                                    multiple
                                                    value={filteredHeaders}
                                                    onChange={handleChangeFilterHeaders}
                                                    style={{ width: 250 }}
                                                    input={<OutlinedInput labelWidth={140} />}
                                                >
                                                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                        <Button onClick={handleAllFilterHeaders(true)}>All</Button>
                                                        <Button onClick={handleAllFilterHeaders(false)}>None</Button>
                                                    </div>
                                                    {TipsHeaders.map(({ name }) => (
                                                        <MenuItem key={name} value={name}>
                                                            {_.startCase(name)}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>

                                            <>
                                                <TextField
                                                    variant="outlined"
                                                    name="search"
                                                    value={search || ''}
                                                    onChange={handleChange}
                                                    placeholder="Search"
                                                    style={{
                                                        ...inputStyles,
                                                        width: 250
                                                    }}
                                                />

                                                {
                                                    <Button
                                                        color="primary"
                                                        disabled={loading}
                                                        variant="contained"
                                                        data-cy="dashboard-search-button"
                                                        type="submit"
                                                        onClick={handleSubmit}
                                                    >
                                                        <Icon>search</Icon>
                                                    </Button>
                                                }
                                            </>
                                        </>
                                    )}

                                    {collapseSearch && (
                                        <div style={{ textAlign: 'right' }}>
                                            <IconButton onClick={handleFiltersOpen()}>
                                                <Icon>filter_list</Icon>
                                            </IconButton>
                                        </div>
                                    )}
                                </form>
                            </div>
                        </Paper>
                    </div>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <div
                    style={{
                        padding: theme.spacing.unit,
                        paddingLeft: theme.spacing.unit * 2,
                        paddingRight: theme.spacing.unit * 2
                    }}
                >
                    <Paper
                        style={{
                            display: 'flex',
                            justifyContent: 'space-evenly',
                            padding: theme.spacing.unit * 2,
                            height: '100%',
                            flexWrap: 'wrap'
                        }}
                    >
                        <span style={{ margin: theme.spacing.unit, display: 'flex', alignItems: 'center' }}>
                            <Avatar
                                style={{
                                    backgroundColor: theme.palette.primary.main,
                                    marginRight: theme.spacing.unit
                                }}
                            >
                                <Icon>monetization_on</Icon>
                            </Avatar>
                            <Typography style={{ marginRight: theme.spacing.unit * 2, display: 'inline-block' }}>
                                Total Tips:{' '}
                                {formatAsCurrency(
                                    _.get(response, 'totalSkip', 0) + _.get(response, 'totalCollector', 0)
                                )}
                            </Typography>
                        </span>
                        <span style={{ margin: theme.spacing.unit, display: 'flex', alignItems: 'center' }}>
                            <Avatar
                                style={{
                                    backgroundColor: theme.palette.primary.main,
                                    marginRight: theme.spacing.unit
                                }}
                            >
                                <Icon>local_shipping</Icon>
                            </Avatar>
                            <Typography style={{ marginRight: theme.spacing.unit * 2, display: 'inline-block' }}>
                                Total Tips Skip: {formatAsCurrency(_.get(response, 'totalSkip', 0))}
                            </Typography>
                        </span>
                        <span style={{ margin: theme.spacing.unit, display: 'flex', alignItems: 'center' }}>
                            <Avatar
                                style={{
                                    backgroundColor: theme.palette.primary.main,
                                    marginRight: theme.spacing.unit
                                }}
                            >
                                <Icon>local_atm</Icon>
                            </Avatar>
                            <Typography style={{ marginRight: theme.spacing.unit * 2, display: 'inline-block' }}>
                                Total Tips Depot : {formatAsCurrency(_.get(response, 'totalCollector', 0))}
                            </Typography>
                        </span>
                    </Paper>
                </div>
            </Grid>
            <DashBoardTable
                title="Tips"
                loading={loading}
                headers={TipsHeaders}
                filteredHeaders={filteredHeaders}
                length={totalDataEntries}
                page={page}
                rowsPerPage={rowsPerPage}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                refresh={getData}
            >
                {filteredData &&
                    filteredData.map((fee, key) => {
                        return renderAppliedFeeRow(fee, filteredHeaders, key);
                    })}
            </DashBoardTable>
        </>
    );
}

export default withTheme()(Tips);
