import React, { useContext } from 'react';
import _ from 'lodash';

import { colors, Typography, withTheme } from '@material-ui/core';

import { Icon as MDIcon } from '@mdi/react';
import * as allIcons from '@mdi/js';
import { _commodity } from 'std';
import LocalizationContext from 'utils/contexts/LocalizationContext';

function PayloadIndicators({ payload, commodities, indicatorSize = 1, showUnits = false, style, labelStyle, theme }) {
    const { lang } = useContext(LocalizationContext);

    return (
        <div style={{ display: 'flex', textAlign: 'center', alignItems: 'center', flexWrap: 'wrap', ...style }}>
            {Object.keys(payload).map(skuType => {
                const count = _.get(payload, skuType, 0);
                if (count > 0) {
                    const commodityForSku = _.find(commodities, { skuType });
                    const commodityIcon = _.get(commodityForSku, 'iconName');
                    return (
                        <span
                            key={skuType}
                            style={{
                                display: 'flex',
                                borderRadius: 4,
                                borderColor: colors.blue[300],
                                alignItems: 'center',
                                marginRight: theme.spacing.unit / 2
                            }}
                        >
                            <MDIcon
                                path={_.get(allIcons, commodityIcon)}
                                size={indicatorSize}
                                color={_.get(commodityForSku, 'color')}
                            />{' '}
                            <Typography style={{ ...labelStyle }}>
                                {count}{' '}
                                {showUnits && (
                                    <>
                                        {_commodity.getUnit(commodityForSku, lang)}
                                        {count > 1 && 's'}
                                    </>
                                )}
                            </Typography>
                        </span>
                    );
                } else {
                    return <></>;
                }
            })}
        </div>
    );
}

export default withTheme()(PayloadIndicators);
