import React, { useState, useContext, useEffect } from 'react';
import _ from 'lodash';

import { withTheme } from '@material-ui/core/styles';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { getSwitch, getTextInput, getSelect } from 'components/CRUDTables/helperFunctions';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    FormControl,
    FormControlLabel,
    FormHelperText,
    InputLabel,
    OutlinedInput,
    Select,
    MenuItem,
    Icon,
    IconButton,
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    ListItemSecondaryAction,
    Divider,
    Typography
} from '@material-ui/core';

import HttpContext from 'utils/contexts/HttpContext';
import ConfirmDialogContext from 'components/Dialogs/Confirm/ConfirmDialogContext';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

function RatePageConfigDialog(props) {
    const { rates, ratesPageConfig, loadRatesPageConfig, open, onClose, theme, fullScreen } = props;

    const http = useContext(HttpContext);
    const warnAction = useContext(ConfirmDialogContext);
    const { lang } = useContext(LocalizationContext);

    const [selectedRatesPageConfigI, setSelectedRatesPageConfigI] = useState(null);
    const selectedRatesPageConfig = !_.isNil(selectedRatesPageConfigI)
        ? ratesPageConfig[selectedRatesPageConfigI]
        : null;

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            geographicArea: _.get(selectedRatesPageConfig, 'geographicArea', ''),
            rates: _.get(selectedRatesPageConfig, 'rates', _.first(rates)._id),
            message: _.get(selectedRatesPageConfig, 'message', ''),
            serviceFee: _.get(selectedRatesPageConfig, 'serviceFee', 0) * 100,
            includeServiceFeeInContainerValues: _.get(
                selectedRatesPageConfig,
                'includeServiceFeeInContainerValues',
                false
            ),
            readOnly: _.get(selectedRatesPageConfig, 'readOnly', false)
        },
        validationSchema: Yup.object({
            geographicArea: Yup.string().required('You must enter a value'),
            serviceFee: Yup.number()
                .required('You must enter a value')
                .min(0, 'This must be positive')
                .max(100, 'This cannot be more than 100%')
        }),
        onSubmit: async values => {
            const ratesPageConfigCopy = _.cloneDeep(ratesPageConfig);
            const valuesCopy = _.cloneDeep(values);
            valuesCopy.serviceFee = values.serviceFee / 100;
            if (!_.isNil(selectedRatesPageConfigI)) {
                ratesPageConfigCopy[selectedRatesPageConfigI] = valuesCopy;
            } else {
                ratesPageConfigCopy.push(valuesCopy);
            }
            const res = await http.post('/rates/updateRatesPageConfig', { ratesPageConfig: ratesPageConfigCopy });
            if (res.ok) {
                await loadRatesPageConfig();
                setSelectedRatesPageConfigI(null);
                //formik.handleReset();
            }
        }
    });

    const handleRemoveRatesConfig = async i => {
        let ratesConfigCopy = _.cloneDeep(ratesPageConfig);
        ratesConfigCopy.splice(i, 1);
        const res = await http.post('/rates/updateRatesPageConfig', { ratesPageConfig: ratesConfigCopy });
        if (res.ok) {
            await loadRatesPageConfig();
            setSelectedRatesPageConfigI(null);
            //formik.handleReset();
        }
    };

    useEffect(() => {
        formik.handleReset();
    }, [selectedRatesPageConfigI, ratesPageConfig]);

    return (
        <Dialog onClose={onClose} open={open} fullScreen={fullScreen} maxWidth="md">
            <DialogTitle>Public Facing Rates Display</DialogTitle>
            <DialogContent>
                <div style={{ display: 'flex' }}>
                    <div style={{ borderRight: '1px solid grey', paddingRight: theme.spacing.unit }}>
                        {ratesPageConfig.map((geographicAreaConfig, i) => {
                            const rateIndex = _.findIndex(rates, { _id: _.get(geographicAreaConfig, 'rates') });
                            const rate = rates[rateIndex];
                            return (
                                <List key={i}>
                                    <ListItem
                                        selected={i === selectedRatesPageConfigI}
                                        onClick={() => {
                                            setSelectedRatesPageConfigI(i);
                                        }}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <ListItemText
                                            primary={`${_.get(geographicAreaConfig, 'geographicArea', '')} - ${_.get(
                                                rate,
                                                'name'
                                            )}`}
                                            secondary={_.get(geographicAreaConfig, 'message', '')}
                                        />
                                    </ListItem>
                                    <ListItemSecondaryAction>
                                        <IconButton
                                            onClick={() =>
                                                warnAction(
                                                    () => handleRemoveRatesConfig(i),
                                                    `Are you sure you want to delete this`
                                                )
                                            }
                                        >
                                            <Icon>delete</Icon>
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </List>
                            );
                        })}
                    </div>
                    <div style={{ marginLeft: theme.spacing.unit }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography variant="h6">
                                {!_.isNil(selectedRatesPageConfigI)
                                    ? `Editing ${_.get(selectedRatesPageConfig, 'geographicArea', '')}`
                                    : 'Creating New Configuration'}
                            </Typography>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => setSelectedRatesPageConfigI(null)}
                            >
                                <Icon>add_circle_outline</Icon> Add New
                            </Button>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <div style={{ marginRight: theme.spacing.unit }}>
                                {getTextInput(theme, 'geographicArea', 'Province/State/Territory', formik)}
                            </div>
                            <div style={{ marginRight: theme.spacing.unit, flex: 1 }}>
                                <FormControl fullWidth style={{ marginTop: theme.spacing.unit * 2 }}>
                                    <InputLabel variant="outlined">Rates</InputLabel>

                                    <Select
                                        {...formik.getFieldProps('rates')}
                                        fullWidth
                                        input={<OutlinedInput labelWidth={'Rates'.length * theme.spacing.unit} />}
                                    >
                                        {rates.map((rate, i) => (
                                            <MenuItem value={rate._id} key={rate._id}>
                                                {_.get(rate, 'name', '')}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        {getTextInput(theme, 'message', 'Message', formik)}
                        <div style={{ display: 'flex', marginTop: theme.spacing.unit, alignItems: 'center' }}>
                            <div style={{ marginRight: theme.spacing.unit }}>
                                {getSwitch(
                                    theme,
                                    'includeServiceFeeInContainerValues',
                                    'Display Deposits With Service Fee Applied',
                                    formik
                                )}
                            </div>
                            <div style={{ marginRight: theme.spacing.unit }}>
                                {getTextInput(
                                    theme,
                                    'serviceFee',
                                    'Service Fee(%)',
                                    formik,
                                    'text',
                                    {},
                                    {},
                                    null,
                                    !formik.values.includeServiceFeeInContainerValues
                                )}
                            </div>
                        </div>
                        <div style={{ marginRight: theme.spacing.unit, marginTop: theme.spacing.unit }}>
                            {getSwitch(theme, 'readOnly', 'Set rates to read-only', formik)}
                        </div>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={formik.handleSubmit}
                            style={{ marginTop: theme.spacing.unit }}
                        >
                            {!_.isNil(selectedRatesPageConfigI)
                                ? `Update ${_.get(selectedRatesPageConfig, 'geographicArea', '')}`
                                : `Create`}
                        </Button>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>{loc('close', lang)}</Button>
            </DialogActions>
        </Dialog>
    );
}

export default withMobileDialog()(withTheme()(RatePageConfigDialog));
