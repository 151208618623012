import React from 'react';

import _ from 'lodash';

import DateTimePicker from 'material-ui-pickers/DateTimePicker';

import * as timeHelper from 'utils/timeHelper';

export default function DateTimePickerTz(props) {
    const { timezone, value, ...rest } = props;

    const handleChange = date => {
        let dateAdjusted = timeHelper.mutateDateFromLocalToZone(date, timezone);
        props.onChange(dateAdjusted);
    };

    return (
        <DateTimePicker
            value={!_.isNil(timezone) ? timeHelper.mutateDateFromZoneToLocal(value, timezone) : null}
            {...rest}
            onChange={handleChange}
        />
    );
}
