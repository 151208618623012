import React, { useContext, useState, useRef } from 'react';

import _ from 'lodash';

import { withTheme } from '@material-ui/core/styles';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { Dialog, DialogContent, DialogActions, Button, Typography, TextField, colors, Grid } from '@material-ui/core';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import useWindowSize from 'utils/hooks/useWindowSize';

/**
 * Description.
 * Pop-up dialog that can be used anywhere in the system to confirm an action
 *
 * @param {Object} props
 */
function ConfirmationDialog({
    theme,
    open,
    message,
    warning,
    confirmString,
    confirmStringComparisonValue,
    enableConfirm,
    callbackFunc,
    onCloseConfirmDialog,
    onChangeString,
    cancelButtonText,
    confirmButtonText
}) {
    const [width, height] = useWindowSize();
    let mobileMode = width <= 600;
    let showKeyboard = !mobileMode && JSON.parse(localStorage.getItem('hideKeyboard')) !== true;
    const { lang } = useContext(LocalizationContext);
    const handleAccept = () => {
        callbackFunc();
        onCloseConfirmDialog();
    };

    const [layout, setLayout] = useState('default');
    const keyboard = useRef();

    const handleShift = () => {
        const newLayoutName = layout === 'default' ? 'shift' : 'default';
        setLayout(newLayoutName);
    };
    const handleEnter = () => {
        if (enableConfirm && !_.isEmpty(confirmString)) {
            handleAccept();
        }
    };

    const onKeyPress = button => {
        if (button === '{shift}' || button === '{lock}') handleShift();
        if (button === '{enter}') handleEnter();
    };

    return (
        <Dialog open={open} onClose={onCloseConfirmDialog}>
            <DialogContent>
                <Typography variant="h6">{message || loc('confirmDialog1', lang)}</Typography>
                {!_.isEmpty(warning) && (
                    <Typography style={{ marginTop: theme.spacing.unit, color: colors.grey[700] }}>
                        <span style={{ fontWeight: 'bold', color: colors.red[500] }}>
                            {loc('confirmDialog2', lang)}
                        </span>
                        {warning}
                    </Typography>
                )}
                {!_.isEmpty(confirmStringComparisonValue) && (
                    <TextField
                        label={loc('labels8', lang, {
                            string: confirmStringComparisonValue
                        })}
                        error={!enableConfirm}
                        value={confirmString}
                        onChange={e => {
                            onChangeString(e);
                            console.log('keyboard', keyboard);
                            if (showKeyboard && !_.isNil(keyboard.current)) {
                                keyboard.current.setInput(e.target.value);
                            }
                        }}
                        onKeyDown={e => {
                            if (e.key === 'Enter' && enableConfirm) handleAccept();
                        }}
                        variant="outlined"
                        fullWidth
                        autoFocus
                        style={{ marginTop: theme.spacing.unit * 2 }}
                    />
                )}
                {showKeyboard && !_.isEmpty(confirmStringComparisonValue) && (
                    <Grid item sm={12} xs={12} style={{ marginTop: 5 }}>
                        <Keyboard
                            keyboardRef={r => (keyboard.current = r)}
                            layoutName={layout}
                            onChange={input => {
                                let e = { target: { value: input } };
                                onChangeString(e);
                            }}
                            onKeyPress={onKeyPress}
                        />
                    </Grid>
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    color="default"
                    size="small"
                    onClick={onCloseConfirmDialog}
                    data-cy="por-screen-warning-dialog-cancel"
                >
                    {_.isNil(cancelButtonText) || _.isEmpty(cancelButtonText)
                        ? loc('nevermind', lang)
                        : cancelButtonText}
                </Button>
                <Button
                    color="primary"
                    size="small"
                    disabled={!enableConfirm}
                    onClick={handleAccept}
                    data-cy="por-screen-warning-dialog-confirm"
                >
                    {_.isNil(confirmButtonText) || _.isEmpty(confirmButtonText) ? loc('yes', lang) : confirmButtonText}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
export default withMobileDialog({ breakpoint: 'xs' })(withTheme()(ConfirmationDialog));
