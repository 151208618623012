import React from 'react';

import _ from 'lodash';

import { Grid, withTheme, Paper, Button, Icon, TableRow, TableCell } from '@material-ui/core';

import DatePicker from 'components/DateTimePickersTz/DatePicker';

import StateSelectDropDown from 'components/DropDownSelects/StateSelectDropDown';

import useTable from 'components/Table/useTable';
import Table from 'components/Table/Table';

import { _time, _user } from 'std';
import { formatAsCurrency, isAUSRegion } from 'utils/misc';

import useQueryDate from 'utils/hooks/useQueryDate';

const headers = [
    { name: 'Customer', key: null },
    { name: '0-90', key: null },
    { name: '91-180', key: null },
    { name: '181-360', key: null },
    { name: '360+', key: null },
    { name: 'Total', key: null }
];

function AgingBalancesReport(props) {
    const { theme } = props;

    const [endDate, setEndDate] = useQueryDate({
        key: 'endDate',
        initVal: _time.getStartOfToday(process.env.REACT_APP_REGION_TIMEZONE),
        enabled: true,
        timezone: process.env.REACT_APP_REGION_TIMEZONE
    });

    const {
        loading,
        searchTerm,
        getData,
        page,
        rowsPerPage,
        filteredData,
        response,
        search,
        filteredHeaders,
        totalDataEntries,
        handleChange,
        handleSearch,
        handleChangeFilterHeaders,
        handleAllFilterHeaders,
        handleChangePage,
        handleChangeRowsPerPage
    } = useTable({
        url: `/system/agingBalancesReport`,
        key: 'reportBreakdown',
        headers: headers.map(h => h.name)
    });

    const renderTotalsRow = (reportRow, filteredHeaders) => {
        return (
            <>
                <TableRow key="TOTAL" style={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>
                    {filteredHeaders.includes('Customer') && (
                        <TableCell style={{ fontWeight: 'bold' }}>TOTAL</TableCell>
                    )}
                    {filteredHeaders.includes('0-90') && (
                        <TableCell style={{ fontWeight: 'bold' }}>
                            {reportRow.balance0To90 !== 0 && formatAsCurrency(reportRow.balance0To90)}
                        </TableCell>
                    )}
                    {filteredHeaders.includes('91-180') && (
                        <TableCell style={{ fontWeight: 'bold' }}>
                            {reportRow.balance91To180 !== 0 && formatAsCurrency(reportRow.balance91To180)}
                        </TableCell>
                    )}
                    {filteredHeaders.includes('181-360') && (
                        <TableCell style={{ fontWeight: 'bold' }}>
                            {reportRow.balance181To360 !== 0 && formatAsCurrency(reportRow.balance181To360)}
                        </TableCell>
                    )}
                    {filteredHeaders.includes('360+') && (
                        <TableCell style={{ fontWeight: 'bold' }}>
                            {reportRow.balance360Plus !== 0 && formatAsCurrency(reportRow.balance360Plus)}
                        </TableCell>
                    )}
                    {filteredHeaders.includes('Total') && (
                        <TableCell style={{ fontWeight: 'bold' }}>
                            {reportRow.balanceTotal !== 0 && formatAsCurrency(reportRow.balanceTotal)}
                        </TableCell>
                    )}
                </TableRow>
            </>
        );
    };

    const renderRow = (reportRow, filteredHeaders) => {
        return (
            <TableRow key={reportRow._id}>
                {filteredHeaders.includes('Customer') && <TableCell>{reportRow.groupByObjectName}</TableCell>}
                {filteredHeaders.includes('0-90') && (
                    <TableCell>{reportRow.balance0To90 !== 0 && formatAsCurrency(reportRow.balance0To90)}</TableCell>
                )}
                {filteredHeaders.includes('91-180') && (
                    <TableCell>
                        {reportRow.balance91To180 !== 0 && formatAsCurrency(reportRow.balance91To180)}
                    </TableCell>
                )}
                {filteredHeaders.includes('181-360') && (
                    <TableCell>
                        {reportRow.balance181To360 !== 0 && formatAsCurrency(reportRow.balance181To360)}
                    </TableCell>
                )}
                {filteredHeaders.includes('360+') && (
                    <TableCell>
                        {reportRow.balance360Plus !== 0 && formatAsCurrency(reportRow.balance360Plus)}
                    </TableCell>
                )}
                {filteredHeaders.includes('Total') && (
                    <TableCell>{reportRow.balanceTotal !== 0 && formatAsCurrency(reportRow.balanceTotal)}</TableCell>
                )}
            </TableRow>
        );
    };

    return (
        <>
            <div style={{ maxWidth: '100%', padding: theme.spacing.unit * 2, paddingBottom: theme.spacing.unit }}>
                <Grid container spacing={theme.spacing.unit * 2}>
                    <Grid item xs={12}>
                        <div>
                            <Paper
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    padding: theme.spacing.unit * 2,
                                    height: '100%',
                                    flexWrap: 'wrap'
                                }}
                            >
                                <DatePicker
                                    timezone={process.env.REACT_APP_REGION_TIMEZONE}
                                    type="end"
                                    label="As of"
                                    disableFuture
                                    format="DD MMM YYYY"
                                    value={endDate}
                                    disabled={loading}
                                    style={{ margin: theme.spacing.unit }}
                                    variant="outlined"
                                    onChange={d => setEndDate(d)}
                                />
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexWrap: 'wrap'
                                    }}
                                >
                                    {isAUSRegion() && <StateSelectDropDown loading={loading} />}
                                    <Button color="primary" variant="contained" onClick={getData}>
                                        <Icon>search</Icon>
                                    </Button>
                                </div>
                            </Paper>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <Table
                title="Aging Summary"
                loading={loading}
                headers={headers}
                filteredHeaders={filteredHeaders}
                length={totalDataEntries}
                page={page}
                rowsPerPage={rowsPerPage}
                search={search}
                noSearch={true}
                handleChange={handleChange}
                handleChangeFilterHeaders={handleChangeFilterHeaders}
                handleAllFilterHeaders={handleAllFilterHeaders}
                handleSearch={handleSearch}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                refresh={getData}
            >
                {filteredData && [
                    renderTotalsRow(_.get(response, 'reportTotals', {}), filteredHeaders),
                    ...filteredData.map(reportRow => {
                        return renderRow(reportRow, filteredHeaders);
                    })
                ]}
            </Table>
        </>
    );
}

export default withTheme()(AgingBalancesReport);
