import React from 'react';

import { withTheme } from '@material-ui/core/styles';
import EmployeeTable from '../../../components/CRUDTables/EmployeeTable';

function Employees(props) {
    return <EmployeeTable {...props} />;
}

export default withTheme()(Employees);
