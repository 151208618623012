import React from 'react';
import _ from 'lodash';

import { Button, Divider, Icon, Paper, Typography } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';

function FilterSidePanel(props) {
    const { theme, title, open, overlay, handleOpen, toggleOverlay, disableFilter, handleFilter } = props;

    // if (!open) return <></>;

    const overlayStyles = overlay
        ? { height: '100%', position: 'absolute', top: 0, right: 0, zIndex: 1000, marginLeft: '16px' }
        : { height: 'auto' };

    return (
        <Paper
            square
            style={{
                maxWidth: open ? '350px' : 0,
                minWidth: open ? '350px' : 0,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                // transition: 'width 1s',
                // overflow: 'hidden',
                overflowX: 'hidden',
                ...overlayStyles
            }}
        >
            <div style={{ display: 'flex', flexDirection: 'column', margin: theme.spacing.unit }}>
                <Typography
                    variant="h6"
                    style={{ margin: theme.spacing.unit, textAlign: 'center', fontWeight: 500, whiteSpace: 'nowrap' }}
                >
                    {title}
                </Typography>
                <Divider style={{ marginBottom: theme.spacing.unit * 2 }} />
                {props.children}
            </div>
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-around',
                    margin: theme.spacing.unit,
                    marginTop: theme.spacing.unit * 2
                }}
            >
                <Button color="secondary" variant="contained" type="submit" onClick={() => handleOpen(false)}>
                    <Icon>close</Icon>
                </Button>
                <Button variant="contained" type="submit" onClick={() => toggleOverlay()}>
                    <Icon>view_sidebar</Icon>
                </Button>
                <Button
                    color="primary"
                    disabled={disableFilter}
                    variant="contained"
                    type="submit"
                    onClick={handleFilter}
                >
                    <Icon>search</Icon>
                </Button>
            </div>
        </Paper>
    );
}

export default withTheme()(FilterSidePanel);
