import React, { useContext } from 'react';
import _ from 'lodash';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { colors } from '@material-ui/core';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

const SecurityAnswerDialog = ({
    securityAnswerDialogOpen,
    handleSecurityAnswerDialog,
    securityAnswer,
    handleSecurityAnswerConfirmed
}) => {
    const { lang } = useContext(LocalizationContext);
    return (
        <Dialog open={securityAnswerDialogOpen} fullWidth onClose={() => handleSecurityAnswerDialog(false)}>
            <DialogTitle>{loc('redemption24', lang)}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {loc('redemption25a', lang)}
                    <span style={{ fontWeight: 'bold', color: colors.red[500] }}>{securityAnswer}</span>
                    {loc('redemption25b', lang)}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={() => handleSecurityAnswerDialog(false)}>
                    {loc('back', lang)}
                </Button>
                <Button data-cy="security-answer-dialog-yes" color="primary" onClick={handleSecurityAnswerConfirmed}>
                    {loc('next', lang)}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SecurityAnswerDialog;
