import React from 'react';

import { withTheme } from '@material-ui/core/styles';

import TruckTable from 'components/CRUDTables/TruckTable';

function Trucks(props) {
    return <TruckTable {...props} />;
}

export default withTheme()(Trucks);
