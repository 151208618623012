import React, { useContext } from 'react';

import _ from 'lodash';
import moment from 'moment-timezone';

import * as colors from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import { withTheme } from '@material-ui/core/styles';

import SnackbarContext from 'components/CustomSnackbar/SnackbarContext';

import { downloadObjectAsCSV, formatAsCurrency } from 'utils/misc';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

import { _user } from 'std';

function InvoiceDialog(props) {
    const { http, theme, open, invoice, onClose } = props;
    const { lang } = useContext(LocalizationContext);

    const onSnackbar = useContext(SnackbarContext);

    const handleDownloadCSV = async () => {
        const res = await http.getJSON(`/quickbooks/getReportByInvoice/${invoice.DocNumber}`, true);
        if (res.ok) {
            await downloadObjectAsCSV(
                res.data.report,
                `Invoice report ${invoice.CustomerRef.name} ${moment(res.data.invoiceDate).format('YY-MM-DD')}`
            );
        } else {
            onSnackbar(res.errorMessage, 'error');
        }
    };

    if (_.isNil(invoice)) {
        return null;
    }

    return (
        <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
            <DialogTitle>
                Invoice #{process.env.REACT_APP_REGION_EXT !== 'AUS' ? invoice.DocNumber : _.get(invoice, '_id')}
            </DialogTitle>
            <DialogContent>
                {process.env.REACT_APP_REGION_EXT !== 'AUS' ? (
                    <STDDialogContent
                        invoice={invoice}
                        theme={theme}
                        handleDownloadCSV={handleDownloadCSV}
                        lang={lang}
                    />
                ) : (
                    <AUSDialogContent invoice={invoice} theme={theme} />
                )}
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={onClose}>
                    {loc('close', lang)}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default withTheme()(InvoiceDialog);

function getInvoiceDate(invoice) {
    return '';
}

function STDDialogContent({ invoice, theme, handleDownloadCSV, lang }) {
    return (
        <>
            {!_.isNil(invoice.CustomerRef) && (
                <Typography variant="body1">
                    <span style={{ color: theme.palette.text.secondary }}>Client:</span> {invoice.CustomerRef.name}
                </Typography>
            )}
            {!_.isNil(invoice.EmailStatus) && (
                <Typography variant="body1">
                    <span style={{ color: theme.palette.text.secondary }}>Invoice email:</span>{' '}
                    {invoice.EmailStatus === 'EmailSent' ? 'Sent' : 'Not sent'}
                </Typography>
            )}
            <Typography variant="body1" style={{ marginTop: theme.spacing.unit }}>
                <span style={{ color: theme.palette.text.secondary }}>Due date:</span>{' '}
                {moment(invoice.DueDate).format('MMM Do YYYY')}
            </Typography>

            <Table style={{ marginTop: theme.spacing.unit }}>
                <TableHead>
                    <TableRow>
                        <TableCell padding="dense">#</TableCell>
                        <TableCell padding="dense">Product/Service</TableCell>
                        <TableCell padding="dense">Description</TableCell>
                        <TableCell padding="dense" align="right">
                            Qty
                        </TableCell>
                        <TableCell padding="dense" align="right">
                            Rate
                        </TableCell>
                        <TableCell padding="dense" align="right">
                            Amount
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {_.dropRight(invoice.Line, 1).map(line => (
                        <TableRow key={line.Id}>
                            <TableCell padding="dense">{line.LineNum}</TableCell>
                            <TableCell>{line.SalesItemLineDetail.ItemRef.name}</TableCell>
                            <TableCell>{line.Description}</TableCell>
                            <TableCell padding="dense" align="right">
                                {!_.isNil(line.SalesItemLineDetail.Qty) &&
                                    line.SalesItemLineDetail.Qty.toLocaleString()}
                            </TableCell>
                            <TableCell align="right" padding="dense">
                                {(!_.isNil(line.SalesItemLineDetail.UnitPrice)
                                    ? line.SalesItemLineDetail.UnitPrice
                                    : line.Amount
                                ).toLocaleString('en-CA', {
                                    maximumFractionDigits: 4,
                                    style: 'currency',
                                    currency: 'CAD'
                                })}
                            </TableCell>
                            <TableCell align="right" padding="dense">
                                {formatAsCurrency(line.Amount * 100)}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            <Typography variant="subtitle1" style={{ marginTop: theme.spacing.unit * 3 }}>
                <span style={{ color: theme.palette.text.secondary }}>Total amount:</span>{' '}
                {formatAsCurrency(invoice.TotalAmt * 100)}
            </Typography>
            {invoice.Deposit > 0 && (
                <Typography variant="subtitle1">
                    <span style={{ color: theme.palette.text.secondary }}>Deposit:</span>{' '}
                    {formatAsCurrency(invoice.Deposit * 100)}
                </Typography>
            )}
            <Typography variant="subtitle1">
                <span style={{ color: theme.palette.text.secondary }}>Balance owed:</span>{' '}
                <span style={{ color: invoice.Balance > 0 ? colors.red['A400'] : null }}>
                    {formatAsCurrency(invoice.Balance * 100)}
                </span>
            </Typography>

            <Button
                variant="outlined"
                color="primary"
                style={{ marginTop: theme.spacing.unit }}
                onClick={handleDownloadCSV}
            >
                {loc('taxReceipts25', lang)}
            </Button>
        </>
    );
}

function AUSDialogContent({ invoice, theme }) {
    return (
        <>
            {!_.isNil(invoice.CustomerRef) && (
                <Typography variant="body1">
                    <span style={{ color: theme.palette.text.secondary }}>Client:</span>{' '}
                    {_user.getNameFull(_.get(invoice, 'customerRef'))}
                </Typography>
            )}
            <Typography variant="body1" style={{ marginTop: theme.spacing.unit }}>
                <span style={{ color: theme.palette.text.secondary }}>Due date:</span>{' '}
                {moment(invoice.dueDate).format('MMM Do YYYY')}
            </Typography>

            <Table style={{ marginTop: theme.spacing.unit }}>
                <TableHead>
                    <TableRow>
                        <TableCell padding="dense">#</TableCell>
                        <TableCell padding="dense">Product/Service</TableCell>
                        <TableCell padding="dense">Description</TableCell>
                        <TableCell padding="dense" align="right">
                            Amount
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {invoice.invoiceLines.map(line => (
                        <TableRow key={line.index}>
                            <TableCell padding="dense">{line.index}</TableCell>
                            <TableCell>{line.itemName}</TableCell>
                            <TableCell>{line.description}</TableCell>
                            <TableCell align="right" padding="dense">
                                {formatAsCurrency(line.amount)}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            <Typography variant="subtitle1" style={{ marginTop: theme.spacing.unit * 3 }}>
                <span style={{ color: theme.palette.text.secondary }}>Total amount:</span>{' '}
                {formatAsCurrency(invoice.totalInvoiceAmount)}
            </Typography>
        </>
    );
}
