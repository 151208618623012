import React, { useState, useEffect, useContext } from 'react';

import _ from 'lodash';

import bulkHelper from 'helpers/bulkHelper';

import * as colors from '@material-ui/core/colors';

import { DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import { Typography, Fab, Icon, FormControl, InputLabel, Select, MenuItem, TextField, Button } from '@material-ui/core';

import { withTheme } from '@material-ui/core/styles';
import { formatAsCurrency, deviceHelper } from 'utils/misc';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

function ComplaintScreen(props) {
    const { email, name, bulk, pickup, handleGoBack, onClose, onSnackbar, http, theme, pickupsEnabled } = props;

    const { lang } = useContext(LocalizationContext);

    const [reason, setReason] = useState('');
    const [feedback, setFeedback] = useState('');

    const handleSubmit = async () => {
        let res = await http.post('/users/pickupComplaint', {
            message: feedback,
            reason,
            email,
            name,
            bulk
        });

        if (res.ok) {
            onClose();
            onSnackbar(loc('complaints9', lang));
        }
    };

    return (
        <div>
            <DialogTitle>{loc('complaints1', lang)}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {loc('complaints2a', lang)}
                    {pickupsEnabled && <strong>{loc('complaints2b', lang)}</strong>}
                    {pickupsEnabled && loc('complaints2c', lang)}
                    <strong>{loc('complaints2d', lang)}</strong>
                    {loc('complaints2e', lang)}
                </DialogContentText>
                {/* <DialogContentText style={{ marginTop: theme.spacing.unit, marginBottom: theme.spacing.unit }}>
                        Select what was wrong
                    </DialogContentText> */}
                <FormControl style={{ marginTop: theme.spacing.unit }}>
                    <InputLabel htmlFor="age-simple">{loc('complaints3', lang)}</InputLabel>
                    <Select
                        data-cy="share-and-feedback-dialog-complaint-reason"
                        style={{ minWidth: 200, marginBottom: theme.spacing.unit }}
                        value={reason}
                        onChange={event => setReason(event.target.value)}
                        inputProps={{
                            name: 'age',
                            id: 'age-simple'
                        }}
                    >
                        {/* <MenuItem value="">
                                <em>Please select</em>
                            </MenuItem> */}
                        {pickupsEnabled && !_.isNil(pickup) && (
                            <MenuItem value="pickup">{loc('complaints4', lang)}</MenuItem>
                        )}
                        <MenuItem value="bulk" data-cy="share-and-feedback-dialog-complaint-reason-refund-problem">
                            {loc('complaints5', lang)}
                        </MenuItem>
                        <MenuItem value="generic">{loc('complaints6', lang)}</MenuItem>
                    </Select>
                </FormControl>

                <FormControl fullWidth style={{ marginTop: theme.spacing.unit / 2 }}>
                    <TextField
                        data-cy="share-and-feedback-dialog-submit-error-textfield"
                        label={loc('complaints7', lang)}
                        value={feedback}
                        name="feedback"
                        multiline
                        onChange={event => setFeedback(event.target.value)}
                    />
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button data-cy="share-and-feedback-dialog-go-back-button" color="default" onClick={handleGoBack}>
                    {loc('complaints8', lang)}
                </Button>
                <Button
                    data-cy="share-and-feedback-dialog-submit-error-button"
                    color="primary"
                    disabled={_.isEmpty(feedback) || _.isEmpty(reason)}
                    onClick={handleSubmit}
                >
                    {loc('submit', lang)}
                </Button>
            </DialogActions>
        </div>
    );
}

export default withTheme()(ComplaintScreen);
