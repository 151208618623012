import React, { useState, useContext, useEffect } from 'react';

import _ from 'lodash';
import moment from 'moment-timezone';

import { _user } from 'std';

import {
    Table,
    TableBody,
    TableRow,
    TableCell,
    withTheme,
    Checkbox,
    IconButton,
    colors,
    Icon,
    TableHead,
    TablePagination,
    Tooltip,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    Typography,
    TextField,
    Button,
    FormControlLabel,
    Switch,
    DialogTitle,
    FormControl
} from '@material-ui/core';

import { downloadObjectAsCSV } from 'utils/misc';

import HttpContext from 'utils/contexts/HttpContext';
import ConfirmDialogContext from 'components/Dialogs/Confirm/ConfirmDialogContext';
import SnackbarContext from 'components/CustomSnackbar/SnackbarContext';

import charityTaskHelper from 'helpers/charityTaskHelper';
import { formatAsCurrency } from '../../../utils/misc';
import { loc } from 'localizations/localizationHandler';
import LocalizationContext from 'utils/contexts/LocalizationContext';
import DatePicker from 'components/DateTimePickersTz/DatePicker';

function SendOutListComponent(props) {
    const {
        sendOutLists = [],
        selectedLists = [],
        onChange,
        onReloadSendOutLists,
        onEditList,
        selectionDisabled,
        error = false,
        theme
    } = props;
    const { lang } = useContext(LocalizationContext);
    const timezone = process.env.REACT_APP_REGION_TIMEZONE;

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);

    const http = useContext(HttpContext);
    const warnAction = useContext(ConfirmDialogContext);
    const onSnackbar = useContext(SnackbarContext);

    const [loadingList, setLoadingList] = useState([]);
    const [showDownloadDialog, setShowDownloadDialog] = useState(false);
    const [enrichData, setEnrichData] = useState(false);
    const [startDate, setStartDate] = useState(moment());
    const [endDate, setEndDate] = useState(moment());
    const [selectedList, setSelectedList] = useState(null);

    useEffect(() => {
        const getLocalStorageData = () => {
            const data = localStorage.getItem('sendOutListLoadingArray');
            return JSON.parse(data) || [];
        };
        setLoadingList(getLocalStorageData());
        const handleStorageChange = () => {
            setLoadingList(getLocalStorageData());
        };
        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    const setSendOutListLoading = (_id, value) => {
        let sendOutListLoadingArray = JSON.parse(localStorage.getItem('sendOutListLoadingArray')) || [];
        let index = sendOutListLoadingArray.indexOf(_id);
        if (value) {
            if (index === -1) {
                sendOutListLoadingArray.push(_id);
            }
        } else {
            if (index !== -1) {
                sendOutListLoadingArray.splice(index, 1);
            }
        }
        localStorage.setItem('sendOutListLoadingArray', JSON.stringify(sendOutListLoadingArray));
        window.dispatchEvent(new Event('storage'));
    };

    const handleChange = list => {
        if (selectedLists.includes(list._id)) {
            onChange(_.filter(selectedLists, _id => _id !== list._id));
        } else {
            onChange([...selectedLists, list._id]);
        }
    };

    const handleDownloadClicked = list => {
        setShowDownloadDialog(prevState => !prevState);
        setSelectedList(list);
    };

    const handleDownload = async list => {
        if (enrichData && startDate > endDate) {
            return;
        }
        const downloadName = enrichData
            ? `${list.name}-Users_Data_${startDate.format('MMMDDYYYY')}-${endDate.format('MMMDDYYYY')}`
            : _.get(list, 'name', 'Untitled');

        let showEnrichedColumns = enrichData;
        setEnrichData(false);
        setShowDownloadDialog(false);
        setSendOutListLoading(`${list._id}_${list.name}`, true);
        let csvData = [];
        if (list.filters && list.filters.includeCustomers) {
            const url = `/sendOuts/sendOutLists/${
                list._id
            }/downloadUsers?enrichData=${showEnrichedColumns}&startDate=${new Date(
                startDate
            ).toISOString()}&endDate=${new Date(endDate).toISOString()}`;
            const res = await http.getJSON(url);

            if (res.ok) {
                csvData = _.get(res, 'data.users', []).map(user => {
                    const data = {
                        'First Name': _user.getNameFirst(user),
                        'Last Name': _user.getNameLast(user),
                        Id: _user.getUniqueID(user),
                        Address: _.get(user, 'location.description', ''),
                        Phone: _.get(user, 'phone', ''),
                        Email: _.get(user, 'email'),
                        Type: _.get(user, 'location.locationType', ''),
                        Charity: _.get(user, 'charity.name', ''),
                        'Donation Preference': _.get(user, 'charitySelected.name', ''),
                        'Charity Admin': !_.isNil(_.get(user, 'charity')),
                        Orders: _.get(user, 'bulkCount', 0)
                    };

                    if (showEnrichedColumns) {
                        data['First Order Date'] = !_.isNil(_.get(user, 'firstBulk.datePickedUp'))
                            ? moment(_.get(user, 'firstBulk.datePickedUp'))
                                  .tz(process.env.REACT_APP_REGION_TIMEZONE)
                                  .format('MMM D, YYYY')
                            : '';
                        data['First Order Collector'] = !_.isNil(_.get(user, 'firstBulk.collectorName'))
                            ? _.get(user, 'firstBulk.collectorName')
                            : '';
                        data['First Order Type'] = !_.isNil(_.get(user, 'lastBulk.bulkType'))
                            ? _.get(user, 'lastBulk.bulkType')
                            : '';
                        data['Last Order Date'] = !_.isNil(_.get(user, 'lastBulk.datePickedUp'))
                            ? moment(_.get(user, 'lastBulk.datePickedUp'))
                                  .tz(process.env.REACT_APP_REGION_TIMEZONE)
                                  .format('MMM D, YYYY')
                            : '';
                        data['Last Order Collector'] = !_.isNil(_.get(user, 'lastBulk.collectorName'))
                            ? _.get(user, 'lastBulk.collectorName')
                            : '';
                        data['Last Order Type'] = !_.isNil(_.get(user, 'lastBulk.bulkType'))
                            ? _.get(user, 'lastBulk.bulkType')
                            : '';
                        data['Total Adjustment Orders'] = !_.isNil(_.get(user, 'totalAdjustments'))
                            ? _.get(user, 'totalAdjustments')
                            : '';
                        data['Total Drop&Go Orders'] = !_.isNil(_.get(user, 'totalDropAndGo'))
                            ? _.get(user, 'totalDropAndGo')
                            : '';
                        data['Total Pickup Orders'] = !_.isNil(_.get(user, 'totalPickups'))
                            ? _.get(user, 'totalPickups')
                            : '';
                        data['Total Walk-in Orders'] = !_.isNil(_.get(user, 'totalWalkIn'))
                            ? _.get(user, 'totalWalkIn')
                            : '';
                    }

                    return data;
                });
            }
        } else if (list.filters && list.filters.includeCharities) {
            const res = await http.getJSON(`/sendOuts/sendOutLists/${list._id}/downloadUsers`);
            if (res.ok) {
                _.get(res, 'data.users', []).map(user => {
                    const { _id, charityTasks } = user.charity;
                    const numCharityTasksComplete = charityTaskHelper.numberOfTasksComplete(charityTasks);
                    const redemptionEmail = _.get(user, 'charity.redemptionEmail', undefined);
                    let totalDonationValue = user.totalDonations[0] ? user.totalDonations[0].totalDonationValue : 0;
                    let totalDonationCount = user.totalDonations[0] ? user.totalDonations[0].totalDonationCount : 0;

                    csvData.push({
                        Charity: _.get(user, 'charity.name', ''),
                        'First Name': _user.getNameFirst(user),
                        'Last Name': _user.getNameLast(user),
                        Email: _.get(user, 'email'),
                        'Growth Tasks Complete': numCharityTasksComplete,
                        'Total # of Donations': totalDonationCount,
                        'Total Donation Amount': formatAsCurrency(totalDonationValue, 'en')
                    });
                    if (!_.isNil(redemptionEmail)) {
                        csvData.push({
                            Charity: _.get(user, 'charity.name', ''),
                            'First Name': _.get(user, 'charity.name', ''),
                            'Last Name': '',
                            Email: redemptionEmail,
                            'Growth Tasks Complete': numCharityTasksComplete,
                            'Total # of Donations': totalDonationCount,
                            'Total Donation Amount': formatAsCurrency(totalDonationValue, 'en')
                        });
                    }
                });
            }
        } else if (!_.isEmpty(list.uploadedList)) {
            list.uploadedList.map(uploadedUser => {
                csvData.push(uploadedUser);
            });
        }
        await downloadObjectAsCSV(csvData, downloadName);

        setSendOutListLoading(`${list._id}_${list.name}`, false);
    };

    const handleDelete = async list => {
        setSendOutListLoading(`${list._id}_${list.name}`, true);
        const res = await http.post(`/sendOuts/sendOutLists/${list._id}/deleteSendOutList`, {}, true);
        if (res.ok) {
            if (selectedLists.includes(list._id)) {
                onChange(_.filter(selectedLists, _id => _id !== list._id));
            }
            await onReloadSendOutLists();
        } else {
            onSnackbar(res.errorMessage, 'error');
        }
        setSendOutListLoading(`${list._id}_${list.name}`, false);
    };

    const handleRefresh = async list => {
        setSendOutListLoading(`${list._id}_${list.name}`, true);
        const res = await http.post(`/sendOuts/sendOutLists/${list._id}/refresh`, {}, true);
        if (res.ok) {
            if (selectedLists.includes(list._id)) {
                onChange(_.filter(selectedLists, _id => _id !== list._id));
            }
            await onReloadSendOutLists();
            onSnackbar('List Refreshed Successfully', 'success');
        } else {
            onSnackbar(res.errorMessage, 'error');
        }
        setSendOutListLoading(`${list._id}_${list.name}`, false);
    };

    const cellStyles = {
        whiteSpace: 'nowrap',
        paddingLeft: theme.spacing.unit,
        paddingRight: theme.spacing.unit
    };

    return (
        <div
            style={{
                /*padding: theme.spacing.unit,
                borderStyle: 'solid',
                borderWidth: 1,
                borderColor: error ? colors.red[500] : theme.palette.text.disabled,
                borderRadius: theme.shape.borderRadius,*/
                overflow: 'auto',
                width: '100%'
            }}
        >
            <Table padding="dense">
                <TableHead>
                    <TableRow>
                        {!selectionDisabled && (
                            <TableCell style={cellStyles}>{selectedLists.length} selected</TableCell>
                        )}
                        <TableCell key="actions" style={cellStyles}>
                            Actions
                        </TableCell>
                        <TableCell key="name" style={cellStyles}>
                            Name
                        </TableCell>
                        <TableCell key="totalUsers" style={cellStyles}>
                            Total Users
                        </TableCell>
                        <TableCell key="lastRefreshed" style={cellStyles}>
                            Last Refreshed
                        </TableCell>
                        <TableCell key="processing" style={cellStyles}>
                            Processing
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {_.isEmpty(sendOutLists) && (
                        <TableRow>
                            <TableCell style={cellStyles} colSpan={4} align="center">
                                <i>No Lists</i>
                            </TableCell>
                        </TableRow>
                    )}
                    {loadingList
                        .filter(list => list.startsWith('tmp_'))
                        .map(list => {
                            const listName = list.substring(4);
                            return (
                                <TableRow>
                                    <TableCell style={cellStyles}>
                                        <Tooltip title="Download Users">
                                            <IconButton disabled={true}>
                                                <Icon>download</Icon>
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Edit List">
                                            <IconButton onClick={() => onEditList(list)} disabled={true}>
                                                <Icon>edit</Icon>
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Refresh List">
                                            <IconButton
                                                onClick={() =>
                                                    warnAction(
                                                        () => handleRefresh(list),
                                                        'Are you sure you want to refresh this list'
                                                    )
                                                }
                                                disabled={true}
                                            >
                                                <Icon>refresh</Icon>
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Delete List">
                                            <IconButton
                                                onClick={() =>
                                                    warnAction(
                                                        () => handleDelete(list),
                                                        'Are you sure you want to delete this'
                                                    )
                                                }
                                                disabled={true}
                                            >
                                                <Icon>delete</Icon>
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell style={{ ...cellStyles, color: 'lightgrey' }}>{listName}</TableCell>
                                    <TableCell style={{ ...cellStyles, color: 'lightgrey' }}>N/A</TableCell>
                                    <TableCell style={{ ...cellStyles, color: 'lightgrey' }}>N/A</TableCell>
                                    <TableCell style={cellStyles}>
                                        <CircularProgress
                                            size={10}
                                            thickness={4.8}
                                            style={{
                                                display: '',
                                                marginLeft: theme.spacing.unit / 2
                                            }}
                                        />
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    {sendOutLists.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(list => {
                        const { name, totalUserCount, lastRefreshedAt } = list;
                        const lastRefreshedAtFormatted = moment(lastRefreshedAt)
                            .tz(process.env.REACT_APP_REGION_TIMEZONE)
                            .format('MMM D, YYYY H:mm a');
                        const loadingItem = loadingList.find(item => item.split('_')[0] === list._id);
                        const loading = Boolean(loadingItem);
                        const loadingName = loadingItem ? loadingItem.split('_')[1] : null;
                        const showEnrichDialog = _.isEmpty(list.uploadedList) && !list.filters.includeCharities;

                        return (
                            <TableRow id={list._id} selected={selectedLists.includes(list._id)}>
                                {!selectionDisabled && (
                                    <TableCell style={cellStyles}>
                                        <Checkbox
                                            onClick={e => handleChange(list)}
                                            checked={selectedLists.includes(list._id)}
                                        />
                                    </TableCell>
                                )}
                                <TableCell style={cellStyles}>
                                    <Tooltip title="Download Users">
                                        <IconButton
                                            onClick={() =>
                                                showEnrichDialog ? handleDownloadClicked(list) : handleDownload(list)
                                            }
                                            disabled={loading}
                                        >
                                            <Icon>download</Icon>
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Edit List">
                                        <IconButton
                                            onClick={() => onEditList(list)}
                                            disabled={loading || !_.isEmpty(list.uploadedList)}
                                        >
                                            <Icon>edit</Icon>
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Refresh List">
                                        <IconButton
                                            onClick={() =>
                                                warnAction(
                                                    () => handleRefresh(list),
                                                    'Are you sure you want to refresh this list'
                                                )
                                            }
                                            disabled={loading}
                                        >
                                            <Icon>refresh</Icon>
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Delete List">
                                        <IconButton
                                            onClick={() =>
                                                warnAction(
                                                    () => handleDelete(list),
                                                    'Are you sure you want to delete this'
                                                )
                                            }
                                            disabled={loading}
                                        >
                                            <Icon>delete</Icon>
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                                <TableCell style={loading ? { ...cellStyles, color: 'lightgrey' } : cellStyles}>
                                    {loading ? loadingName : name}
                                </TableCell>
                                <TableCell style={loading ? { ...cellStyles, color: 'lightgrey' } : cellStyles}>
                                    {totalUserCount}
                                </TableCell>
                                <TableCell style={loading ? { ...cellStyles, color: 'lightgrey' } : cellStyles}>
                                    {lastRefreshedAtFormatted}
                                </TableCell>
                                <TableCell style={cellStyles}>
                                    {loading && (
                                        <CircularProgress
                                            size={10}
                                            thickness={4.8}
                                            style={{
                                                display: '',
                                                marginLeft: theme.spacing.unit / 2
                                            }}
                                        />
                                    )}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={sendOutLists.length}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                    'aria-label': 'Previous Page'
                }}
                nextIconButtonProps={{
                    'aria-label': 'Next Page'
                }}
                onChangePage={(e, page) => setPage(page)}
                onChangeRowsPerPage={e => setRowsPerPage(e.target.value)}
            />
            <Dialog
                open={showDownloadDialog}
                PaperProps={{
                    style: { width: '500px' }
                }}
            >
                <DialogTitle>Download List</DialogTitle>
                <DialogContent>
                    <FormControlLabel
                        control={
                            <Switch checked={enrichData} onChange={() => setEnrichData(!enrichData)} color="primary" />
                        }
                        label="Enrich Data From Database"
                        style={{ marginTop: theme.spacing.unit * 2 }}
                    />
                    {enrichData && (
                        <>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    gap: theme.spacing.unit,
                                    marginTop: theme.spacing.unit
                                }}
                            >
                                <Typography variant="subtitle2">Use Date Between</Typography>
                                <FormControl fullWidth style={{ flex: 1 }}>
                                    <DatePicker
                                        timezone={timezone}
                                        type="start"
                                        label="Start Date"
                                        format="DD MMM YYYY"
                                        value={startDate}
                                        variant="outlined"
                                        onChange={date => setStartDate(date)}
                                    />
                                </FormControl>
                                <FormControl fullWidth style={{ flex: 1 }}>
                                    <DatePicker
                                        timezone={timezone}
                                        type="end"
                                        label="End Date"
                                        format="DD MMM YYYY"
                                        value={endDate}
                                        variant="outlined"
                                        onChange={date => setEndDate(date)}
                                    />
                                </FormControl>
                            </div>
                            <Typography variant="body2" color="error" style={{ marginTop: theme.spacing.unit }}>
                                Large queries may timeout
                            </Typography>
                        </>
                    )}
                    <div style={{ marginBottom: theme.spacing.unit * 2 }} />
                </DialogContent>
                <DialogActions>
                    <Button color="default" size="small" onClick={() => setShowDownloadDialog(false)}>
                        Cancel
                    </Button>
                    <Button
                        color="primary"
                        size="small"
                        onClick={() => handleDownload(selectedList)}
                        data-cy="download-button"
                    >
                        Download
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default withTheme()(SendOutListComponent);
