import React, { useState, useContext } from 'react';
import _ from 'lodash';

import { withTheme } from '@material-ui/core/styles';

import {
    Select,
    FormControl,
    MenuItem,
    IconButton,
    Button,
    Icon,
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    colors
} from '@material-ui/core';

import CustomWrapper from '../../containers/Operators/BulkCounter/CustomWrapper';
import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

function PromoCampaignInput(props) {
    const { campaigns, value, onChange, theme, ...otherProps } = props;

    const { lang } = useContext(LocalizationContext);

    //const [selectedCampaign, setSelectedCampaign] = useState('');
    const [addedCampaign, setAddedCampaign] = useState(null);
    const [addCampaignDialogOpen, setAddCampaignDialogOpen] = useState(false);
    const [addCampaignInput, setAddCampaignInput] = useState('');

    const campaignEnteredExists = _.some(campaigns, campaign => campaign === addCampaignInput.trim());

    const onCampaignAdded = () => {
        const value = addCampaignInput.trim();
        setAddCampaignDialogOpen(false);
        setAddedCampaign(value);
        onChange(value);
        setAddCampaignInput('');
    };

    return (
        <>
            <CustomWrapper title={loc('promoCampaign3', lang)}>
                <Grid
                    container
                    spacing={theme.spacing.unit}
                    style={{ marginLeft: theme.spacing.unit, alignItems: 'Center' }}
                    justify="space-around"
                >
                    <Grid item xs={10}>
                        <FormControl fullWidth>
                            <Select
                                {...otherProps}
                                value={value}
                                onChange={event => onChange(event.target.value)}
                                disabled={!_.isNil(addedCampaign)}
                            >
                                {!_.isNil(addedCampaign) ? (
                                    <MenuItem value={addedCampaign} key={addedCampaign}>
                                        {addedCampaign}
                                    </MenuItem>
                                ) : (
                                    [
                                        <MenuItem value="" key="NoneMenuItem">
                                            <em>{loc('none', lang)}</em>
                                        </MenuItem>,
                                        ...campaigns.map(campaign => (
                                            <MenuItem value={campaign} key={campaign}>
                                                {campaign}
                                            </MenuItem>
                                        ))
                                    ]
                                )}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        {_.isNil(addedCampaign) ? (
                            <IconButton onClick={() => setAddCampaignDialogOpen(true)} color="primary">
                                <Icon>add_circle</Icon>
                            </IconButton>
                        ) : (
                            <IconButton onClick={() => setAddedCampaign(null)} style={{ color: colors.red[500] }}>
                                <Icon>cancel</Icon>
                            </IconButton>
                        )}
                    </Grid>
                </Grid>
                <Dialog open={addCampaignDialogOpen} onClose={() => setAddCampaignDialogOpen(false)}>
                    <DialogTitle onClose={() => setAddCampaignDialogOpen(false)}>
                        {loc('promoCampaign1', lang)}
                    </DialogTitle>
                    <DialogContent>
                        <br />
                        <TextField
                            error={campaignEnteredExists}
                            value={addCampaignInput}
                            onChange={event => setAddCampaignInput(event.target.value)}
                            label={loc('labels12', lang)}
                            helperText={campaignEnteredExists && loc('promoCampaign2', lang)}
                            variant="outlined"
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setAddCampaignDialogOpen(false)}>{loc('cancel', lang)}</Button>
                        <Button onClick={onCampaignAdded} disabled={!addCampaignInput || campaignEnteredExists}>
                            {loc('add', lang)}
                        </Button>
                    </DialogActions>
                </Dialog>
            </CustomWrapper>
        </>
    );
}

export default withTheme()(PromoCampaignInput);
