import React, { useState, useContext } from 'react';

import HttpContext from 'utils/contexts/HttpContext';

import {
    Dialog,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    withTheme,
    DialogTitle,
    LinearProgress
} from '@material-ui/core';

import { _user } from 'std';
import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

function AccountDeletedDialog(props) {
    const { open, handleClose, onLogout, isAdmin } = props;

    const { lang } = useContext(LocalizationContext);

    return (
        <Dialog fullWidth open={open}>
            <DialogTitle>Account Deleted</DialogTitle>
            <DialogContent style={{ paddingBottom: 0 }}>
                <DialogContentText>
                    This account has been deleted and can no longer be used or modified.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {isAdmin && <Button onClick={handleClose}>Dismiss</Button>}
                <Button onClick={onLogout} color="primary">
                    {loc('signOut', lang)}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default withTheme()(AccountDeletedDialog);
