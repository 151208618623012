import moment from 'moment-timezone';
import _ from 'lodash';
import { _time } from 'std';

import { useState, useCallback, useEffect } from 'react';
import { setQueryStringValue, getQueryStringValue } from 'utils/query';

function useQueryDate({ key, initVal, enabled = false, initToNull = false, timezone }) {
    const [value, setValue] = useState(getInitialState(key, initVal, timezone, initToNull));

    // sets the query paramater url to whatever it is initially set to
    useEffect(() => {
        if (enabled) {
            setQueryStringValue(key, generateDateParameter(value));
        }
    }, []);

    const handleSetValue = useCallback(
        newValue => {
            setValue(newValue);
            if (enabled) {
                setQueryStringValue(key, generateDateParameter(newValue));
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [key]
    );

    return [value, handleSetValue];
}

export default useQueryDate;

/**
 * Determines the initial value of the query date
 *
 * @param {String} key
 * @param {Boolean} initToNull
 * @param {Object} initVal
 * @param {String} timezone
 * @return {Moment Object}
 */
function getInitialState(key, initVal, timezone, initToNull) {
    const dateInISOFormat = getQueryStringValue(key);
    // attempt to query from URL first
    if (!_.isEmpty(dateInISOFormat) && dateInISOFormat !== 'null') {
        return moment(dateInISOFormat).tz(timezone);
    }

    // if should be null, set it to null, otherwise will set it to provided value, or to start of the day
    if (initToNull) {
        return null;
    }

    // if neither and there is a inital value provided, set to that the value
    if (initVal) {
        return initVal;
    } else {
        return _time.getStartOfToday(timezone);
    }
}

/**
 *
 * @param {String} date
 * @return {String}
 */
function generateDateParameter(date) {
    // not toISOString(), because we dont want the selected date to adjust to UTC.
    return !_.isEmpty(date) ? `${moment(date).format()}` : '';
}
