import { useMemo } from 'react';
import { _pickup } from 'std';

import _ from 'lodash';
import pickupHelper from 'helpers/pickupHelper';
import moment from 'moment-timezone';

function useDriverTripStatsList({ pickups, commoditiesAvailable, trip }) {
    const { abortedPickups, skippedPickups, completedPickups, totalNumberOfBags, collectedBags } = useMemo(
        () => getTripStats(pickups, commoditiesAvailable),
        [pickups, commoditiesAvailable]
    );
    const tripDriver = _.get(trip, 'transporter', {});
    const driver = !_.isEmpty(pickups) ? _.first(pickups).receiver : tripDriver;

    const timezone = !_.isEmpty(pickups) ? _.first(pickups).location.timezone : process.env.REACT_APP_REGION_TIMEZONE;

    const finishedPickups = _([...completedPickups, ...abortedPickups])
        .orderBy(p => new Date(p.completionDate ? p.completionDate : p.updatedAt), 'desc')
        .value();
    const displayPickups = _([
        ...completedPickups,
        ...abortedPickups,
        ...skippedPickups.filter(p => !(p.complete || p.aborted))
    ])
        .orderBy(p => new Date(p.completionDate ? p.completionDate : p.updatedAt), 'desc')
        .value();
    const averageTimePerPickup =
        finishedPickups.length > 0
            ? (
                  moment(_.first(finishedPickups).completionDate).diff(
                      moment(_.last(finishedPickups).completionDate),
                      'minutes'
                  ) / finishedPickups.length
              ).toFixed(2) + ' min'
            : 'N/A';

    const averageTimePerBag =
        collectedBags > 0
            ? (
                  moment(_.first(finishedPickups).completionDate).diff(
                      moment(_.last(finishedPickups).completionDate),
                      'minutes'
                  ) / collectedBags
              ).toFixed(2) + ' min'
            : 'N/A';

    return {
        abortedPickups,
        skippedPickups,
        completedPickups,
        totalNumberOfBags,
        averageTimePerPickup,
        averageTimePerBag,
        finishedPickups,
        displayPickups,
        driver,
        timezone
    };
}

export default useDriverTripStatsList;

function getTripStats(pickups, commoditiesAvailable) {
    let totalNumberOfBags = 0,
        collectedBags = 0;
    for (let pickup of pickups) {
        if (pickupHelper.isAborted(pickup)) {
            continue;
        }
        const num = _pickup.numberOfPayloadBags(pickup, commoditiesAvailable);
        if (pickupHelper.isComplete(pickup)) {
            collectedBags += num;
        }
        totalNumberOfBags += num;
    }

    return {
        abortedPickups: _(pickups)
            .filter(p => pickupHelper.isAborted(p))
            .value(),
        completedPickups: _(pickups)
            .filter(p => pickupHelper.isComplete(p))
            .value(),
        skippedPickups: _(pickups)
            .filter(p => pickupHelper.isSkipped(p))
            .value(),
        totalNumberOfBags,
        collectedBags
    };
}
