import React, { useState, useRef } from 'react';

import _ from 'lodash';

// npm components
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { withTheme } from '@material-ui/core/styles';

import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    FormControl,
    TextField,
    Grid
} from '@material-ui/core';
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import useWindowSize from 'utils/hooks/useWindowSize';

function AttachCustomerDialog({ theme, open, customerInfo, onChangeCustomerInfo, onSubmit, onClose }) {
    const [width, height] = useWindowSize();
    let mobileMode = width <= 600;
    let showKeyboard = !mobileMode && JSON.parse(localStorage.getItem('hideKeyboard')) !== true;

    const handleKeyPress = e => {
        if (e.key === 'Enter' && !_.isEmpty(customerInfo)) {
            onSubmit();
        }
    };

    const [layout, setLayout] = useState('default');
    const keyboard = useRef();

    const handleShift = () => {
        const newLayoutName = layout === 'default' ? 'shift' : 'default';
        setLayout(newLayoutName);
    };
    const handleEnter = () => {
        if (!_.isEmpty(customerInfo)) {
            onSubmit();
        }
    };

    const onKeyPress = button => {
        if (button === '{shift}' || button === '{lock}') handleShift();
        if (button === '{enter}') handleEnter();
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm">
            <DialogTitle>Attaching Customer</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Enter in either the unique customer ID, email, or phone number to attach to a customer
                </DialogContentText>
                <FormControl fullWidth>
                    <TextField
                        data-cy="bulk-counter-bagtag-search"
                        id="bulk-label"
                        label="ID/Email/Phone"
                        value={customerInfo}
                        margin="normal"
                        variant="outlined"
                        autoComplete="off"
                        // helperText={!_.isNil(error) ? error : null}
                        // error={!_.isNil(error)}
                        autoFocus
                        onChange={e => {
                            onChangeCustomerInfo(e);
                            if (showKeyboard && !_.isNil(keyboard.current)) {
                                keyboard.current.setInput(e.target.value);
                            }
                        }}
                        onKeyPress={handleKeyPress}
                    />
                </FormControl>

                {showKeyboard && (
                    <Grid item sm={12} xs={12}>
                        <Keyboard
                            keyboardRef={r => (keyboard.current = r)}
                            layoutName={layout}
                            onChange={input => {
                                let e = { target: { value: input } };
                                onChangeCustomerInfo(e);
                            }}
                            onKeyPress={onKeyPress}
                        />
                    </Grid>
                )}
            </DialogContent>
            <DialogActions>
                <Button color="default" onClick={onClose}>
                    Cancel
                </Button>
                <Button
                    color="primary"
                    disabled={_.isEmpty(customerInfo)}
                    onClick={onSubmit}
                    data-cy="bulk-attach-customer-confirm"
                >
                    Attach
                </Button>
            </DialogActions>
        </Dialog>
    );
}
export default withMobileDialog({ breakpoint: 'xs' })(withTheme()(AttachCustomerDialog));
