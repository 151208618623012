import React from 'react';

import { deviceHelper } from 'utils/misc';

import { withTheme } from '@material-ui/core/styles';

import { Browser } from '@capacitor/browser';

function ExternalLink(props) {
    const { theme, text, url, textInline } = props;
    const linkColor = theme.palette.linkColor;

    if (deviceHelper.isNativeApp()) {
        return (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a
                href={url}
                style={{ color: linkColor, fontFamily: theme.typography.fontFamily }}
                onClick={async e => {
                    e.preventDefault();
                    await Browser.open({ url, windowName: '_system' });
                }}
                //onClick={async () => await Window.open(url, '_blank')}
            >
                {text}
            </a>
        );
    }
    return (
        <>
            {textInline ? <></> : <br />}
            <a
                target="_blank"
                rel="noopener noreferrer"
                href={url}
                style={{ color: linkColor, fontFamily: theme.typography.fontFamily }}
            >
                {text}
            </a>
        </>
    );
}

export default withTheme()(ExternalLink);
