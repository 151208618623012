import React, { useContext } from 'react';

import { Button, Dialog, Typography, Card, CardActions, CardContent, Icon } from '@material-ui/core';
import * as colors from '@material-ui/core/colors';

import { withTheme } from '@material-ui/core/styles';
import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

function SwitchAccountDialog(props) {
    const { open, onClose } = props;
    const { lang } = useContext(LocalizationContext);

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <Card style={{ width: '100%', backgroundColor: colors.blue[400] }}>
                <CardContent>
                    <div style={{ textAlign: 'center' }}>
                        <Icon style={{ color: '#fff', fontSize: 75, marginBottom: 8 }} fontSize="large">
                            error
                        </Icon>
                    </div>
                    <Typography variant="h5" gutterBottom style={{ textAlign: 'center', color: '#fff' }}>
                        {loc('switchAcc1', lang)}
                    </Typography>
                    <Typography variant="body1" gutterBottom style={{ color: '#fff' }}>
                        {loc('switchAcc2', lang)}
                    </Typography>
                </CardContent>
                <CardActions style={{ justifyContent: 'flex-end' }}>
                    <Button style={{ color: '#fff' }} data-cy="switch-account-dialog" onClick={onClose}>
                        OK
                    </Button>
                </CardActions>
            </Card>
        </Dialog>
    );
}

export default withTheme()(SwitchAccountDialog);
