import React, { useState, useContext } from 'react';
import ConfirmDialogContext from 'components/Dialogs/Confirm/ConfirmDialogContext';
import _ from 'lodash';

import {
    Button,
    DialogActions,
    Dialog,
    DialogTitle,
    DialogContent,
    List,
    TextField,
    InputAdornment,
    ListItem,
    Avatar,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    Icon,
    LinearProgress,
    Typography
} from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';

import { Icon as MDIcon } from '@mdi/react';
import { mdiAccountSearch } from '@mdi/js';

import * as colors from '@material-ui/core/colors';

import CustomFormTitle from 'components/MaterialUIExtensions/CustomFormTitle';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

function CharityManageAccessDialog({
    open,
    theme,
    http,
    selectedCharityID,
    charityManagers: cManagers,
    setManageAccessDialog,
    reloadMethod = null,
    allowViewUser = false,
    customer = null,
    handleView
}) {
    const { lang } = useContext(LocalizationContext);
    const warnAction = useContext(ConfirmDialogContext);

    const [charityManagers, setCharityManagers] = useState(cManagers);

    const [userSearchString, setUserSearchString] = useState('');
    const [userSearchError, setUserSearchError] = useState('');

    const [isLoading, setIsLoading] = useState(false);

    const handleKeyPress = e => {
        if (e.key === 'Enter' && !_.isEmpty(userSearchString)) {
            handleAddCharityManager();
        }
    };

    const handleAddCharityManager = async () => {
        setIsLoading(true);
        const res = await http.postJSON(`/charities/${selectedCharityID}/addManager`, { userSearchString }, true);
        if (res.ok) {
            setCharityManagers(res.data.charityManagers);

            setUserSearchError('');
            setUserSearchString('');
            if (reloadMethod) {
                await reloadMethod();
            }
        } else {
            setUserSearchError(res.errorMessage);
        }
        setIsLoading(false);
    };

    const handleRemoveCharityManager = async user_id => {
        setIsLoading(true);
        const res = await http.postJSON(`/charities/${selectedCharityID}/removeManager`, { user_id });
        if (res.ok) {
            setCharityManagers(res.data.charityManagers);
            if (reloadMethod) {
                await reloadMethod();
            }
        }
        setIsLoading(false);
    };

    return (
        <Dialog
            open={open}
            onClose={() => {
                setManageAccessDialog(false);
            }}
        >
            <DialogTitle>Manage Access</DialogTitle>

            <DialogContent>
                <CustomFormTitle
                    titleText={`Link a new account`}
                    iconName="add_link"
                    style={{ marginBottom: 0, width: 500 }}
                />
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        verticalAlign: 'center',
                        paddingTop: theme.spacing.unit
                    }}
                >
                    <TextField
                        data-cy="link-account-customer-id-search"
                        label="Customer ID or Email"
                        value={userSearchString}
                        margin="normal"
                        variant="outlined"
                        autoComplete="off"
                        fullWidth
                        helperText={!_.isEmpty(userSearchError) ? userSearchError : null}
                        error={!_.isEmpty(userSearchError)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <MDIcon path={mdiAccountSearch} size={1} color={colors.blue[500]} />
                                </InputAdornment>
                            )
                        }}
                        style={{
                            marginTop: theme.spacing.unit / 2,
                            marginRight: theme.spacing.unit
                        }}
                        onChange={e => {
                            setUserSearchError('');
                            setUserSearchString(e.target.value);
                        }}
                        onKeyPress={handleKeyPress}
                    />
                    <Button
                        data-cy={`link-account-customer-id-search-submit`}
                        onClick={() => handleAddCharityManager()}
                        variant="contained"
                        color="primary"
                        disabled={isLoading}
                    >
                        Add
                    </Button>
                </div>

                <CustomFormTitle titleText={`Linked accounts`} iconName="people_alt" style={{ marginBottom: 0 }} />
                <List
                    style={{
                        margin: 0
                    }}
                >
                    {charityManagers &&
                        charityManagers.map((user, index) => {
                            return (
                                <ListItem
                                    key={index}
                                    style={{ paddingLeft: 0, paddingBottom: 0, opacity: user.disabled ? 0.7 : 1 }}
                                >
                                    <Avatar
                                        style={{
                                            width: 40,
                                            height: 40,
                                            backgroundColor: theme.palette.primary.main,
                                            color: 'white'
                                        }}
                                    >
                                        {_.get(user, 'name.first[0]', '?')}
                                    </Avatar>
                                    <ListItemText
                                        primary={
                                            <span
                                                style={{
                                                    whiteSpace: 'nowrap',
                                                    textOverflow: 'ellipsis',
                                                    overflow: 'hidden',
                                                    display: 'block',
                                                    paddingRight: 'inherit'
                                                }}
                                            >
                                                {`${_.get(user, 'name.first', 'N/A')} ${_.get(user, 'name.last', '')}`}
                                            </span>
                                        }
                                        style={{
                                            whiteSpace: 'normal',
                                            paddingRight: theme.spacing.unit * 3,
                                            position: 'relative'
                                        }}
                                    />
                                    <ListItemSecondaryAction>
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                paddingTop: theme.spacing.unit
                                            }}
                                        >
                                            {allowViewUser && (
                                                <IconButton onClick={handleView(user._id, selectedCharityID)}>
                                                    <Icon>visibility</Icon>
                                                </IconButton>
                                            )}
                                            <IconButton
                                                disabled={isLoading || (customer && customer._id === user._id)}
                                                onClick={() =>
                                                    warnAction(() => {
                                                        return handleRemoveCharityManager(user._id);
                                                    }, 'Are you sure you want to remove this user as an administrator for this charity?')
                                                }
                                            >
                                                <Icon>clear</Icon>
                                            </IconButton>
                                        </div>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            );
                        })}
                </List>
            </DialogContent>
            <DialogActions>
                <Button
                    color="default"
                    size="small"
                    onClick={() => {
                        setManageAccessDialog(false);
                    }}
                >
                    {loc('close', lang)}
                </Button>
            </DialogActions>
            {isLoading && <LinearProgress />}
        </Dialog>
    );
}

export default withTheme()(CharityManageAccessDialog);
