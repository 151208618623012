import React, { useContext } from 'react';

import { formatAsCurrency } from '../../../utils/misc';

import * as colors from '@material-ui/core/colors';
import { Hidden, Button, DialogActions, DialogContent, DialogContentText, withTheme } from '@material-ui/core';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

const Insufficient = ({ minRedemption, donationDisabled, redemptionType, balance, onClose, handleNext, theme }) => {
    const { lang } = useContext(LocalizationContext);

    return (
        <>
            <DialogContent style={{ marginTop: theme.spacing.unit * 3 }}>
                <DialogContentText>
                    {minRedemption > 1 ? (
                        <>
                            {loc('redemption54a', lang)}
                            <strong>{formatAsCurrency(minRedemption, lang)}</strong>
                            {loc('redemption54b', lang)}
                            {!donationDisabled && redemptionType !== 'charity' && <> {loc('redemption55', lang)}</>}
                        </>
                    ) : (
                        <>{loc('redemption56', lang)}</>
                    )}
                    <br />
                    <br />
                    {loc('redemption57', lang)}{' '}
                    <strong style={{ color: balance < 0 ? colors.red[500] : colors.green[500] }}>
                        {formatAsCurrency(balance, lang)}
                    </strong>
                </DialogContentText>
            </DialogContent>
            <DialogActions style={{ marginBottom: 'max(8px, env(safe-area-inset-bottom, 8px))' }}>
                <Hidden smUp>
                    <Button color="primary" onClick={onClose} data-cy="redemption-dialog-close-button">
                        {loc('close', lang)}
                    </Button>
                </Hidden>
                {!donationDisabled && redemptionType !== 'charity' && (
                    <Button
                        color="primary"
                        onClick={handleNext}
                        disabled={balance <= 0}
                        data-cy="redemption-dialog-donate"
                    >
                        {loc('donate', lang)}
                    </Button>
                )}
            </DialogActions>
        </>
    );
};

export default withTheme()(Insufficient);
