import React from 'react';

import _ from 'lodash';

import { withTheme, Typography, Tooltip } from '@material-ui/core';

function SingleRatingWidget(props) {
    const { goodCount, totalCount, tooltipText, noTotalTooltip } = props;

    return !_.isNil(goodCount) && !_.isNil(totalCount) && totalCount !== 0 ? (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <Tooltip title={tooltipText}>
                <Typography>
                    {goodCount}/{totalCount}
                </Typography>
            </Tooltip>
        </div>
    ) : (
        <Tooltip title={noTotalTooltip}>
            <Typography>N/A</Typography>
        </Tooltip>
    );
}

export default withTheme()(SingleRatingWidget);
