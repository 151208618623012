import React, { useContext } from 'react';
import charityTaskHelper from '../../helpers/charityTaskHelper';
import { _charity, _promo } from 'std';
import _ from 'lodash';

import { MenuItem, ListItemIcon, ListItemText, Icon, withTheme, Divider, colors } from '@material-ui/core';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

function CharityGrowthTasksList(props) {
    const { tasks, indexSelected, onTaskSelected, showRightArrows, theme, charity, charityPromo } = props;

    const { lang } = useContext(LocalizationContext);

    const getTaskIcon = taskInfo => {
        const task = taskInfo.task;
        let taskIcon, taskColor;

        if (task.notCompletable) {
            taskIcon = 'info';
            taskColor = theme.palette.linkColor;
        } else if (charityTaskHelper.isComplete(taskInfo)) {
            taskIcon = 'check_circle';
            taskColor = colors.green[500];
        } else if (charityTaskHelper.needsVerification(taskInfo)) {
            taskIcon = 'check_circle';
            taskColor = colors.orange[500];
        } else if (charityTaskHelper.isRejected(taskInfo)) {
            taskIcon = 'error';
            taskColor = colors.red[500];
        } else if (charityTaskHelper.isExpired(taskInfo)) {
            taskIcon = 'event';
            taskColor = colors.red[500];
        } else {
            taskIcon = 'highlight_off';
            taskColor = colors.grey[500];
        }

        return <Icon style={{ color: taskColor }}>{taskIcon}</Icon>;
    };

    const getPromoCodeTaskIcon = (charity, charityPromo) => {
        let taskIcon, taskColor;
        const promoCode = _promo.getCode(charityPromo);
        const charityIsFeatured = _charity.isFeatured(charity);

        let inactivePromoReason = '';
        let promoRequiresFeaturedCharity = _.get(charityPromo, 'requireFeatured', false);

        if (promoRequiresFeaturedCharity && !charityIsFeatured) {
            inactivePromoReason = loc('growthGeneral22', lang);
        } else if (!_promo.isActive(charityPromo)) {
            inactivePromoReason = loc('growthGeneral23', lang, { brand: process.env.REACT_APP_BRAND_NAME });
        }

        if (_.isNil(promoCode) || promoCode === '') {
            taskIcon = 'highlight_off';
            taskColor = colors.grey[500];
        } else if (inactivePromoReason) {
            taskIcon = 'check_circle';
            taskColor = colors.orange[500];
        } else {
            taskIcon = 'check_circle';
            taskColor = colors.green[500];
        }
        return <Icon style={{ color: taskColor }}>{taskIcon}</Icon>;
    };

    return tasks.map((taskInfo, index) => {
        const task = taskInfo.task;
        const taskIsPromoCode = !_.isNil(task.en.title) && task.en.title === '100% Refund Promo Code';
        let taskIcon = taskIsPromoCode ? getPromoCodeTaskIcon(charity, charityPromo) : getTaskIcon(taskInfo);
        return (
            !taskInfo.task.isIntroTask && (
                <span key={task._id}>
                    <MenuItem
                        key={task.taskName}
                        style={{ whiteSpace: 'normal', height: 'inherit' }}
                        onClick={() => onTaskSelected(index)}
                        selected={indexSelected === index}
                        data-cy={`growth-plan-dialog-stepper-${task[lang].shortTitle.replace(/[ ,.]/g, '')}`}
                    >
                        <ListItemIcon>{taskIcon}</ListItemIcon>
                        <ListItemText>
                            {/* {!task.notCompletable && <>{index}. </>} */}
                            <span style={{ color: task.notCompletable ? theme.palette.linkColor : undefined }}>
                                {task[lang].shortTitle}
                            </span>
                        </ListItemText>
                        {showRightArrows && <Icon>chevron_right</Icon>}
                    </MenuItem>
                    {index !== tasks.length - 1 && <Divider />}
                </span>
            )
        );
    });
}

export default withTheme()(CharityGrowthTasksList);
