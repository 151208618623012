import React, { useState, useContext } from 'react';
import _ from 'lodash';

import {
    TextField,
    InputAdornment,
    IconButton,
    Icon,
    withTheme,
    Typography,
    colors,
    Collapse
} from '@material-ui/core';

import LocalizationContext from 'utils/contexts/LocalizationContext';

import { loc, locDate } from 'localizations/localizationHandler';

const SPECIAL_CHARS = ['~', '!', '@', '?', '#', '$', '%', '^', '&', '*', '(', ')', '_', '-', '+', '='];

function PasswordInput(props) {
    const {
        InputProps = {},
        value,
        showValidationRules = false,
        theme,
        repeat = false,
        matchPassword = null,
        ...otherProps
    } = props;

    const [showPassword, setShowPassword] = useState(false);
    const [focused, setFocused] = useState(false);

    const { lang } = useContext(LocalizationContext);

    let lengthError = false,
        numberError = false,
        alphabetError = false,
        specialCharactersError = false,
        matchPasswordError = false;
    if ((!repeat && value.length < 8) || value.length > 127) {
        lengthError = true;
    }
    if (!repeat && !/\d/.test(value)) {
        numberError = true;
    }
    if ((!repeat && !/[A-Z]/.test(value)) || !/[a-z]/.test(value)) {
        alphabetError = true;
    }
    if (!repeat && !_.some(SPECIAL_CHARS, specialChar => value.includes(specialChar))) {
        specialCharactersError = true;
    }
    if (repeat && !_.isNil(matchPassword) && matchPassword !== value) {
        matchPasswordError = true;
    }

    const validationRules = (
        <Collapse in={focused}>
            {repeat ? (
                <div style={{ display: 'flex' }}>
                    <Icon
                        style={{
                            color: matchPasswordError ? colors.red[500] : colors.green[500],
                            marginRight: theme.spacing.unit
                        }}
                    >
                        {matchPasswordError ? 'close' : 'check'}
                    </Icon>
                    <Typography style={{ color: matchPasswordError ? colors.red[500] : colors.green[500] }}>
                        {loc('validate39', lang)}
                    </Typography>
                </div>
            ) : (
                <>
                    {alphabetError && (
                        <div style={{ display: 'flex' }}>
                            <Icon
                                style={{
                                    color: alphabetError ? colors.red[500] : colors.green[500],
                                    marginRight: theme.spacing.unit
                                }}
                            >
                                {alphabetError ? 'close' : 'check'}
                            </Icon>
                            <Typography style={{ color: alphabetError ? colors.red[500] : colors.green[500] }}>
                                {loc('validate36', lang)}
                            </Typography>
                        </div>
                    )}
                    {numberError && (
                        <div style={{ display: 'flex' }}>
                            <Icon
                                style={{
                                    color: numberError ? colors.red[500] : colors.green[500],
                                    marginRight: theme.spacing.unit
                                }}
                            >
                                {numberError ? 'close' : 'check'}
                            </Icon>
                            <Typography style={{ color: numberError ? colors.red[500] : colors.green[500] }}>
                                {loc('validate35', lang)}
                            </Typography>
                        </div>
                    )}
                    {specialCharactersError && (
                        <div style={{ display: 'flex' }}>
                            <Icon
                                style={{
                                    color: specialCharactersError ? colors.red[500] : colors.green[500],
                                    marginRight: theme.spacing.unit
                                }}
                            >
                                {specialCharactersError ? 'close' : 'check'}
                            </Icon>
                            <Typography style={{ color: specialCharactersError ? colors.red[500] : colors.green[500] }}>
                                {loc('validate37', lang, { charsStr: SPECIAL_CHARS.join('') })}
                            </Typography>
                        </div>
                    )}
                    {lengthError && (
                        <div style={{ display: 'flex' }}>
                            <Icon
                                style={{
                                    color: lengthError ? colors.red[500] : colors.green[500],
                                    marginRight: theme.spacing.unit
                                }}
                            >
                                {lengthError ? 'close' : 'check'}
                            </Icon>
                            <Typography style={{ color: lengthError ? colors.red[500] : colors.green[500] }}>
                                {loc('validate38', lang, { charsMin: 8, charsMax: 127 })}
                            </Typography>
                        </div>
                    )}
                </>
            )}
        </Collapse>
    );

    return (
        <TextField
            {...otherProps}
            value={value}
            type={showPassword ? 'text' : 'password'}
            onFocus={() => setFocused(true)}
            // onBlur={() => setFocused(false)}
            InputProps={{
                ...InputProps,
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton fontSize="small" onClick={() => setShowPassword(!showPassword)}>
                            <Icon>{showPassword ? 'visibility_off' : 'visibility'}</Icon>
                        </IconButton>
                    </InputAdornment>
                )
            }}
            helperText={showValidationRules ? validationRules : otherProps.helperText}
        />
    );
}

export default withTheme()(PasswordInput);
