import React from 'react';
import _ from 'lodash';
import { withTheme } from '@material-ui/core/styles';
import SelectDropDownTemplate from './SelectDropDownTemplate';
import moment from 'moment';

const MONTHS = new Array(12).fill(null).map((element, idx) => {
    let date = moment()
        .startOf('month')
        .subtract(idx, 'month');
    return {
        name: date.format('MMM-YYYY'),
        value: date.format('MM-DD-YYYY')
    };
});

function MonthDropDownSelect({ theme, style, value, cypress_id, id, showAll = false, onChange, multiple }) {
    let updatedStyle = _.cloneDeep(style);
    if (_.isNil(style)) {
        updatedStyle = { marginRight: theme.spacing.unit, width: 160 };
    }

    return (
        <SelectDropDownTemplate
            helperText="Month"
            id={id}
            name="month"
            value={value}
            onChange={onChange}
            labelCustomWidth={45}
            items={MONTHS}
            itemValueKey="value"
            itemRenderValueKey="name"
            cypress_id={cypress_id}
            showAll={showAll}
            style={updatedStyle}
            multiple={multiple}
        />
    );
}

export default withTheme()(MonthDropDownSelect);
