import React, { useContext, useState } from 'react';
import _ from 'lodash';

import { _charity, _promo } from 'std';

import {
    Typography,
    Button,
    withTheme,
    colors,
    Chip,
    Icon,
    Dialog,
    DialogContent,
    DialogActions,
    DialogContentText
} from '@material-ui/core';

import { Clipboard } from '@capacitor/clipboard';

import ConfirmDialogContext from 'components/Dialogs/Confirm/ConfirmDialogContext';
import SnackbarContext from 'components/CustomSnackbar/SnackbarContext';

import charityTaskHelper from 'helpers/charityTaskHelper';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

function PromoCodeDisplay({ promo, charity, onSubmitTask, submitted, theme }) {
    const [activeDialogOpen, setActiveDialogOpen] = useState(false);

    const warnAction = useContext(ConfirmDialogContext);
    const onSnackbar = useContext(SnackbarContext);

    const { lang } = useContext(LocalizationContext);

    const charityIsFeatured = _charity.isFeatured(charity);
    const completedCharityTaskCount = charityTaskHelper.numberOfTasksComplete(charity.charityTasks);

    const handleCopyPromoToClipboard = () => {
        Clipboard.write({
            string: _promo.getCode(promo)
        });
        onSnackbar(loc('charitySnackbar7', lang));
    };

    let inactivePromoReason = '';
    let promoRequiresFeaturedCharity = _.get(promo, 'requireFeatured', false);

    if (promoRequiresFeaturedCharity && !charityIsFeatured) {
        inactivePromoReason = loc('growthGeneral22', lang);
    } else if (!_promo.isActive(promo)) {
        inactivePromoReason = loc('growthGeneral23', lang, { brand: process.env.REACT_APP_BRAND_NAME });
    }

    return _.isNil(promo) ? (
        <>
            <Typography variant="body1">
                {charityIsFeatured
                    ? loc('growthGeneral24', lang)
                    : loc('growthGeneral25', lang, {
                          count: completedCharityTaskCount,
                          remaning: 5 - completedCharityTaskCount
                      })}
            </Typography>
            <div
                style={{
                    width: '100%',
                    padding: theme.spacing.unit * 2,
                    paddingLeft: theme.spacing.unit * 4,
                    paddingRight: theme.spacing.unit * 4
                }}
            >
                <Button
                    variant="contained"
                    color="primary"
                    style={{ width: '100%' }}
                    onClick={() =>
                        warnAction(() => {
                            return onSubmitTask(null, loc('charitySnackbar8', lang));
                        }, loc('charitySnackbar9', lang))
                    }
                    size="large"
                    disabled={!charityIsFeatured || submitted}
                    data-cy="promo-code-display-apply"
                >
                    {loc('apply', lang)}
                    <Icon style={{ marginLeft: theme.spacing.unit }}>arrow_upward</Icon>
                </Button>
            </div>
        </>
    ) : (
        <>
            <Typography variant="body1">{loc('growOrder6', lang)}</Typography>
            <div
                style={{
                    display: 'flex',
                    alignContent: 'center',
                    alignItems: 'flex-start',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    margin: theme.spacing.unit
                }}
            >
                <Typography
                    variant="h4"
                    style={{
                        textAlign: 'center',
                        marginBottom: theme.spacing.unit * 2,
                        color: theme.palette.linkColor,
                        margin: theme.spacing.unit,
                        cursor: 'pointer'
                    }}
                    onClick={handleCopyPromoToClipboard}
                    data-cy="promo-code-display-promocode"
                >
                    {_promo.getCode(promo)}
                </Typography>
                <Chip
                    data-cy="promo-code-display-status"
                    label={!inactivePromoReason ? loc('growthGeneral26', lang) : loc('growthGeneral27', lang)}
                    style={{
                        backgroundColor: !inactivePromoReason ? colors.green[500] : colors.red[500],
                        color: 'white',
                        height: '1.5em'
                    }}
                    onClick={() => setActiveDialogOpen(true)}
                />
            </div>
            <div
                style={{
                    paddingLeft: theme.spacing.unit * 3,
                    paddingRight: theme.spacing.unit * 3,
                    margin: theme.spacing.unit
                }}
            >
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleCopyPromoToClipboard}
                    style={{ width: '100%' }}
                >
                    <Icon>file_copy</Icon> {loc('growOrder7', lang)}
                </Button>
            </div>
            <Typography variant="body1" style={{ marginTop: theme.spacing.unit * 2 }}>
                {loc('growOrder8', lang)}
            </Typography>
            <Dialog open={activeDialogOpen} fullwidth onClose={() => setActiveDialogOpen(false)}>
                <DialogContent>
                    <DialogContentText>
                        {!inactivePromoReason ? (
                            <span>{loc('growthGeneral28', lang)}</span>
                        ) : (
                            <span>{loc('growthGeneral29', lang, { inactivePromoReason })}</span>
                        )}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setActiveDialogOpen(false)}>{loc('close', lang)}</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default withTheme()(PromoCodeDisplay);
