import React, { useState, useEffect, useContext } from 'react';
import { startCase, isEmpty, isNil } from 'lodash';
import { _time } from 'std';
import _ from 'lodash';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { CONTACT_EMAIL_OPTIONS } from 'constants.js';

import { withTheme } from '@material-ui/core/styles';
import {
    Card,
    CardHeader,
    CardContent,
    Icon,
    Paper,
    Grid,
    Button,
    Typography,
    IconButton,
    Collapse,
    CircularProgress,
    TextField,
    Divider,
    colors,
    InputAdornment,
    Switch,
    Select,
    OutlinedInput,
    MenuItem,
    InputLabel,
    FormControl
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import HttpContext from 'utils/contexts/HttpContext';
import SnackbarContext from 'components/CustomSnackbar/SnackbarContext';
import ConfirmDialogContext from 'components/Dialogs/Confirm/ConfirmDialogContext';

import { getTextInput } from 'components/CRUDTables/helperFunctions';
import StringListInput from 'components/CustomInputs/StringListInput';
import InputWithConfirmButtons from 'components/CustomInputs/InputWithConfirmButtons';
import { loc } from 'localizations/localizationHandler';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

const InformationForms = ({ theme, collectors }) => {
    const http = useContext(HttpContext);
    const warnAction = useContext(ConfirmDialogContext);
    const [lang, setLang] = useState(process.env.REACT_APP_REGION_LANGUAGE);

    const [informationFormsConfigLoading, setInformationFormsConfigLoading] = useState();
    const [informationFormsConfig, setInformationFormsConfig] = useState({});
    const [contactReasons, setContactReasons] = useState([]);
    const [faqs, setFaqs] = useState({});
    const [faqView, setFaqView] = useState('residential');
    const [howItWorks, setHowItWorks] = useState([]);
    const [howItWorksView, setHowItWorksView] = useState('how-it-works-0');

    const [submitRequired, setSubmitRequired] = useState(false);

    const onSnackbar = useContext(SnackbarContext);
    const loadInformationFormsConfig = async () => {
        setInformationFormsConfigLoading(true);
        const res = await http.getJSON(`/informationForms/${lang}`);
        if (res.ok) {
            setInformationFormsConfig(res.data.informationForms);
            setContactReasons(res.data.informationForms.contact);
            setFaqs(res.data.informationForms.faq);
            setHowItWorks(res.data.informationForms.howItWorks);
        }
        setInformationFormsConfigLoading(false);
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            ...informationFormsConfig
        },
        onSubmit: async values => {
            values.contact = contactReasons;
            values.faq = faqs;
            values.howItWorks = howItWorks;
            const res = await http.postJSON(`/system/updateInformationForms/${lang}`, { config: values }, true);
            if (res.ok) {
                onSnackbar('Values updated successfully');
                setInformationFormsConfig(res.data.informationForms);
                setContactReasons(res.data.informationForms.contact);
                setFaqs(res.data.informationForms.faq);
                setHowItWorks(res.data.informationForms.howItWorks);
            } else {
                onSnackbar(res.errorMessage, 'error');
            }
        }
    });

    useEffect(() => {
        loadInformationFormsConfig();
        formik.handleReset();
    }, [lang]);

    useEffect(() => {
        formik.handleReset();
    }, [informationFormsConfig]);

    const handleDeleteHowItWorksStep = (typeIndex, stepIndex) => {
        warnAction(() => {
            const howItWorksCopy = _.cloneDeep(_.get(howItWorks[typeIndex], 'steps', []));
            if (_.isNil(howItWorksCopy) || _.isEmpty(howItWorksCopy)) {
                return;
            }
            howItWorksCopy.splice(stepIndex, 1);
            _.set(howItWorks[typeIndex], 'steps', howItWorksCopy);
            setSubmitRequired(true);
        }, 'Are you sure you want to delete this?');
    };

    const handleAddHowItWorksStep = (typeIndex, step) => {
        const howItWorksCopy = _.cloneDeep(_.get(howItWorks[typeIndex], 'steps', []));
        if (_.isNil(howItWorksCopy)) {
            return;
        }
        howItWorksCopy.push(step);
        _.set(howItWorks[typeIndex], 'steps', howItWorksCopy);
        setSubmitRequired(true);
    };

    const handleUpdateHowItWorksStep = (typeIndex, stepIndex, name, value) => {
        _.set(howItWorks[typeIndex], `steps[${stepIndex}].${name}`, value);
        setSubmitRequired(true);
    };

    const handleDeleteFaq = (type, i) => {
        warnAction(() => {
            const faqCopy = _.cloneDeep(_.get(faqs, type, []));
            if (_.isNil(faqCopy) || _.isEmpty(faqCopy)) {
                return;
            }
            faqCopy.splice(i, 1);
            _.set(faqs, type, faqCopy);
            setSubmitRequired(true);
        }, 'Are you sure you want to delete this?');
    };

    const handleAddFaq = (type, values) => {
        const faqCopy = _.cloneDeep(_.get(faqs, type, []));
        if (_.isNil(faqCopy) || _.isEmpty(faqCopy)) {
            return;
        }
        faqCopy.push(values);
        _.set(faqs, type, faqCopy);
        setSubmitRequired(true);
    };

    const handleUpdateFaq = (type, index, name, value) => {
        _.set(faqs, `${type}[${index}].${name}`, value);
        setSubmitRequired(true);
    };

    const handleUpdateContactReason = (index, field, value) => {
        _.set(contactReasons, `[${index}][${field}]`, value);
        formik.setFieldValue(`contact[${index}][${field}]`, value);
        setSubmitRequired(true);
    };

    const handleAddContactReason = reason => {
        const updatedReasons = _.cloneDeep(contactReasons);
        let lastCode = updatedReasons[updatedReasons.length - 1].code;
        let newCodeNum = Number(lastCode.replace('CONTACT', ''));

        updatedReasons.push({
            ...reason,
            code: `CONTACT${newCodeNum + 1}`
        });

        setContactReasons(updatedReasons);
        setSubmitRequired(true);
    };

    const handleDeleteContactReason = index => {
        warnAction(() => {
            const updatedReasons = _.cloneDeep(contactReasons);
            updatedReasons.splice(index, 1);

            setContactReasons(updatedReasons);
            setSubmitRequired(true);
        }, 'Are you sure you want to delete this?');
    };

    useEffect(() => {
        if (submitRequired) {
            formik.handleSubmit();
            setSubmitRequired(false);
        }
    }, [submitRequired]);

    return (
        <div style={{ padding: theme.spacing.unit * 2 }}>
            <div style={{ paddingTop: theme.spacing.unit * 2, display: 'flex', justifyContent: 'center' }}>
                <ToggleButtonGroup
                    value={lang}
                    exclusive
                    onChange={(e, lang) => {
                        if (!_.isEmpty(lang)) setLang(lang);
                    }}
                    style={{ display: 'flex', flexWrap: 'wrap' }}
                >
                    <ToggleButton style={{ flexGrow: 1 }} value="en">
                        English
                    </ToggleButton>
                    <ToggleButton style={{ flexGrow: 1 }} value="fr">
                        French
                    </ToggleButton>
                </ToggleButtonGroup>
            </div>
            {informationFormsConfigLoading ? (
                <Paper style={{ margin: theme.spacing.unit * 2, padding: theme.spacing.unit * 2 }}>
                    <div
                        style={{
                            margin: theme.spacing.unit * 2,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <CircularProgress />
                    </div>
                </Paper>
            ) : (
                <>
                    <FormsWidget theme={theme} formik={formik} title={loc('howItWorks', lang)} autoSave={true}>
                        <Grid
                            item
                            xs={12}
                            style={{
                                paddingTop: theme.spacing.unit * 2,
                                paddingBottom: theme.spacing.unit * 2,
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                        >
                            <ToggleButtonGroup
                                value={howItWorksView}
                                exclusive
                                onChange={(e, view) => {
                                    if (!_.isEmpty(view)) setHowItWorksView(view);
                                }}
                                style={{ display: 'flex', flexWrap: 'wrap', marginRight: '16px' }}
                            >
                                {howItWorks.map((item, i) => {
                                    return (
                                        <ToggleButton style={{ flexGrow: 1, fontSize: 12 }} value={`how-it-works-${i}`}>
                                            {item.type}
                                        </ToggleButton>
                                    );
                                })}
                            </ToggleButtonGroup>
                        </Grid>
                        {howItWorks.map((item, typeIndex) => {
                            return (
                                howItWorksView === `how-it-works-${typeIndex}` && (
                                    <Grid container spacing={theme.spacing.unit}>
                                        {item.steps.map((step, stepIndex) => (
                                            <HowItWorksConfigUpdateWidget
                                                formik={formik}
                                                typeIndex={typeIndex}
                                                stepIndex={stepIndex}
                                                theme={theme}
                                                onDeleteHowItWorksStep={handleDeleteHowItWorksStep}
                                                onUpdateHowItWorksStep={handleUpdateHowItWorksStep}
                                                onSearch={() => {
                                                    window.open('https://material.io/resources/icons/?style=baseline');
                                                }}
                                                lang={lang}
                                            />
                                        ))}
                                        <Grid item xs={12}>
                                            <Divider
                                                style={{
                                                    marginTop: theme.spacing.unit * 2,
                                                    marginBottom: theme.spacing.unit * 2
                                                }}
                                            />
                                        </Grid>
                                        <HowItWorksConfigAddWidget
                                            typeIndex={typeIndex}
                                            theme={theme}
                                            onAddHowItWorksStep={handleAddHowItWorksStep}
                                            onSearch={() => {
                                                window.open('https://material.io/resources/icons/?style=baseline');
                                            }}
                                            lang={lang}
                                        />
                                    </Grid>
                                )
                            );
                        })}
                    </FormsWidget>
                    <FormsWidget theme={theme} formik={formik} title="FAQ" autoSave={true}>
                        <Grid
                            item
                            xs={12}
                            style={{
                                paddingTop: theme.spacing.unit * 2,
                                paddingBottom: theme.spacing.unit * 2,
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                        >
                            <ToggleButtonGroup
                                value={faqView}
                                exclusive
                                onChange={(e, view) => {
                                    if (!_.isEmpty(view)) setFaqView(view);
                                }}
                                style={{ display: 'flex', flexWrap: 'wrap', marginRight: '16px' }}
                            >
                                <ToggleButton style={{ flexGrow: 1, fontSize: 12 }} value="residential">
                                    {loc('faq-tab-residential', lang)}
                                </ToggleButton>
                                <ToggleButton style={{ flexGrow: 1, fontSize: 12 }} value="commercial">
                                    {loc('faq-tab-commercial-business', lang)}
                                </ToggleButton>
                                <ToggleButton style={{ flexGrow: 1, fontSize: 12 }} value="charity">
                                    {loc('faq-tab-charities', lang)}
                                </ToggleButton>
                                <ToggleButton style={{ flexGrow: 1, fontSize: 12 }} value="fundraising">
                                    {loc('faq-tab-fundraising-bottle-drives', lang)}
                                </ToggleButton>
                                <ToggleButton style={{ flexGrow: 1, fontSize: 12 }} value="bottledepot">
                                    {loc('faq-tab-bottle-depot', lang)}
                                </ToggleButton>
                                <ToggleButton style={{ flexGrow: 1, fontSize: 12 }} value="mobilerecycling">
                                    {loc('faq-tab-mobile-recycling', lang)}
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Grid>

                        {faqView === 'residential' && (
                            <FaqConfigWidget
                                theme={theme}
                                title={'Residential'}
                                type={'residential'}
                                formik={formik}
                                onAddFaq={handleAddFaq}
                                onDeleteFaq={handleDeleteFaq}
                                onUpdateFaq={handleUpdateFaq}
                                lang={lang}
                            />
                        )}
                        {faqView === 'commercial' && (
                            <FaqConfigWidget
                                data-cy="information-forms-faq-config-update-widget"
                                theme={theme}
                                title={'Commercial'}
                                type={'commercial'}
                                formik={formik}
                                onAddFaq={handleAddFaq}
                                onDeleteFaq={handleDeleteFaq}
                                onUpdateFaq={handleUpdateFaq}
                                lang={lang}
                            />
                        )}
                        {faqView === 'charity' && (
                            <FaqConfigWidget
                                data-cy="information-forms-faq-config-update-widget"
                                theme={theme}
                                title={'Charity'}
                                type={'charity'}
                                formik={formik}
                                onAddFaq={handleAddFaq}
                                onDeleteFaq={handleDeleteFaq}
                                onUpdateFaq={handleUpdateFaq}
                                lang={lang}
                            />
                        )}

                        {faqView === 'fundraising' && (
                            <FaqConfigWidget
                                data-cy="information-forms-faq-config-update-widget"
                                theme={theme}
                                title={'Fundraising'}
                                type={'fundraising'}
                                formik={formik}
                                onAddFaq={handleAddFaq}
                                onDeleteFaq={handleDeleteFaq}
                                onUpdateFaq={handleUpdateFaq}
                                lang={lang}
                            />
                        )}
                        {faqView === 'bottledepot' && (
                            <FaqConfigWidget
                                data-cy="information-forms-faq-config-update-widget"
                                theme={theme}
                                title={'Bottle Depot'}
                                type={'bottledepot'}
                                formik={formik}
                                onAddFaq={handleAddFaq}
                                onDeleteFaq={handleDeleteFaq}
                                onUpdateFaq={handleUpdateFaq}
                                lang={lang}
                            />
                        )}
                        {faqView === 'mobilerecycling' && (
                            <FaqConfigWidget
                                data-cy="information-forms-faq-config-update-widget"
                                theme={theme}
                                title={'Mobile Recycling'}
                                type={'mobilerecycling'}
                                formik={formik}
                                onAddFaq={handleAddFaq}
                                onDeleteFaq={handleDeleteFaq}
                                onUpdateFaq={handleUpdateFaq}
                                lang={lang}
                            />
                        )}
                    </FormsWidget>
                    <FormsWidget theme={theme} formik={formik} title={loc('contactUs', lang)} autoSave={true}>
                        {contactReasons.map((reason, index) => {
                            return (
                                <ContactConfigUpdateWidget
                                    theme={theme}
                                    formik={formik}
                                    onUpdateContactReason={handleUpdateContactReason}
                                    onDeleteContactReason={handleDeleteContactReason}
                                    index={index}
                                    lang={lang}
                                />
                            );
                        })}
                        <Grid item xs={12}>
                            <Divider
                                style={{ marginTop: theme.spacing.unit * 2, marginBottom: theme.spacing.unit * 2 }}
                            />
                        </Grid>
                        <ContactConfigAddWidget lang={lang} onAddContactReason={handleAddContactReason} theme={theme} />
                    </FormsWidget>
                    <FormsWidget theme={theme} formik={formik} title={loc('terms', lang)}>
                        {getTextInput(theme, 'terms', 'Terms', formik)}
                    </FormsWidget>
                    <FormsWidget theme={theme} formik={formik} title={loc('privacy', lang)}>
                        {getTextInput(theme, 'privacy', 'Privacy', formik)}
                    </FormsWidget>
                </>
            )}
        </div>
    );
};

export default withTheme()(InformationForms);

function ContactConfigAddWidget(props) {
    const { theme, lang, onAddContactReason } = props;
    const [message, setMessage] = useState('');
    const [isPickupIssue, setIsPickupIssue] = useState(false);
    const [isCountsIssue, setIsCountsIssue] = useState(false);
    const [isPaymentsIssue, setIsPaymentsIssue] = useState(false);
    const [toEmail, setToEmail] = useState(CONTACT_EMAIL_OPTIONS[0]);

    const onAdd = () => {
        onAddContactReason({
            message,
            isPickupIssue,
            isCountsIssue,
            isPaymentsIssue,
            toEmail
        });
        setMessage('');
        setIsPickupIssue(false);
        setIsCountsIssue(false);
        setIsPaymentsIssue(false);
        setToEmail(CONTACT_EMAIL_OPTIONS[0]);
    };
    return (
        <>
            <Grid item xs={6}>
                <TextField
                    style={{ marginRight: theme.spacing.unit }}
                    value={message}
                    name="reason"
                    onChange={e => setMessage(e.target.value)}
                    label={loc('reason', lang)}
                    variant="outlined"
                    fullWidth
                    data-cy={`information-forms-contact-reason-input`}
                />
            </Grid>
            <Grid item sm={2} xs={6}>
                <FormControl fullWidth>
                    <InputLabel variant="outlined">{loc('contactEmail', lang)}</InputLabel>
                    <Select
                        value={toEmail}
                        onChange={e => setToEmail(e.target.value)}
                        input={
                            <OutlinedInput
                                labelWidth={loc('contactEmail', lang).length * theme.spacing.unit}
                                name="contactEmail"
                            />
                        }
                        style={{
                            minWidth: 168
                        }}
                        data-cy={`information-forms-contact-email-select`}
                    >
                        {CONTACT_EMAIL_OPTIONS.map((email, i) => {
                            return (
                                <MenuItem
                                    data-cy={`information-forms-contact-email-select-${i}`}
                                    value={email.toLowerCase()}
                                >
                                    {email}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item sm={3} xs={10}>
                <Typography variant="caption" style={{ paddingLeft: theme.spacing.unit }}>
                    {loc('issueType', lang)}
                </Typography>
                <div style={{ display: 'inline-flex', paddingLeft: theme.spacing.unit }}>
                    <Typography variant="body2">
                        <Switch
                            name="isPickupIssue"
                            checked={isPickupIssue}
                            onChange={e => setIsPickupIssue(e.target.checked)}
                            color="primary"
                            style={{ padding: 0, margin: 0, height: '25px' }}
                        />
                        Pickup
                    </Typography>
                    <Typography variant="body2">
                        <Switch
                            name="isCountsIssue"
                            checked={isCountsIssue}
                            onChange={e => setIsCountsIssue(e.target.checked)}
                            color="primary"
                            style={{ padding: 0, margin: 0, height: '25px' }}
                        />
                        Counts
                    </Typography>
                    <Typography variant="body2">
                        <Switch
                            name="isPaymentsIssue"
                            checked={isPaymentsIssue}
                            onChange={e => setIsPaymentsIssue(e.target.checked)}
                            color="primary"
                            style={{ padding: 0, margin: 0, height: '25px' }}
                        />
                        Payments
                    </Typography>
                </div>
            </Grid>
            <Grid item sm={1} xs={2}>
                <IconButton
                    data-cy={`information-forms-contact-submit`}
                    color="primary"
                    onClick={onAdd}
                    disabled={_.isEmpty(message)}
                >
                    <Icon>add_circle_outline</Icon>
                </IconButton>
            </Grid>
        </>
    );
}

function ContactConfigUpdateWidget(props) {
    const { theme, formik, onDeleteContactReason, onUpdateContactReason, index, lang } = props;
    const [expanded, setExpanded] = useState(false);

    return (
        <Grid item xl={3} md={4} sm={6} xs={12}>
            <Card
                data-cy="information-forms-contact-config-update-widget"
                style={{
                    margin: theme.spacing.unit,
                    boxShadow: 'none',
                    border: '1px solid rgba(224, 224, 224, 1)'
                }}
            >
                <CardHeader
                    titleTypographyProps={{ variant: 'body1' }}
                    title={
                        expanded ? (
                            <InputWithConfirmButtons
                                initialValue={_.get(formik.values, `contact[${index}].message`, '')}
                                onConfirmValue={value => onUpdateContactReason(index, 'message', value)}
                                label={loc('reason', lang)}
                                variant="outlined"
                                fullWidth
                                multiline
                                dataCy={`information-forms-contact-${index}-reason`}
                            />
                        ) : (
                            _.get(formik.values, `contact[${index}].message`, '')
                        )
                    }
                    action={
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                            <IconButton
                                data-cy={`information-forms-contact-${index}-delete`}
                                onClick={() => onDeleteContactReason(index)}
                            >
                                <Icon>delete</Icon>
                            </IconButton>
                            <IconButton
                                data-cy={`information-forms-contact-${index}-expand`}
                                onClick={() => setExpanded(!expanded)}
                            >
                                <Icon>{expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</Icon>
                            </IconButton>
                        </span>
                    }
                />
                <Collapse in={expanded}>
                    <CardContent style={{ display: 'inline-flex' }}>
                        <div>
                            <FormControl fullWidth>
                                <InputLabel variant="outlined">{loc('contactEmail', lang)}</InputLabel>
                                <Select
                                    value={_.get(formik.values, `contact[${index}].toEmail`, '')}
                                    onChange={e =>
                                        onUpdateContactReason(index, 'toEmail', e.target.value.toLowerCase())
                                    }
                                    input={
                                        <OutlinedInput
                                            labelWidth={loc('contactEmail', lang).length * theme.spacing.unit}
                                            name="contactEmail"
                                        />
                                    }
                                    style={{
                                        minWidth: 168
                                    }}
                                    data-cy={`information-forms-contact-${index}-email-select`}
                                >
                                    {CONTACT_EMAIL_OPTIONS.map((email, i) => {
                                        return (
                                            <MenuItem
                                                data-cy={`information-forms-contact-${index}-email-select-${i}`}
                                                value={email.toLowerCase()}
                                            >
                                                {email}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography variant="body2">
                                <Switch
                                    name="isPickupIssue"
                                    checked={_.get(formik.values, `contact[${index}].isPickupIssue`, false)}
                                    onChange={e => onUpdateContactReason(index, 'isPickupIssue', e.target.checked)}
                                    color="primary"
                                    style={{ padding: 0, margin: 0, height: '25px' }}
                                />
                                Pickup Issue
                            </Typography>
                            <Typography variant="body2">
                                <Switch
                                    name="isCountsIssue"
                                    checked={_.get(formik.values, `contact[${index}].isCountsIssue`, false)}
                                    onChange={e => onUpdateContactReason(index, 'isCountsIssue', e.target.checked)}
                                    color="primary"
                                    style={{ padding: 0, margin: 0, height: '25px' }}
                                />
                                Counts Issue
                            </Typography>
                            <Typography variant="body2">
                                <Switch
                                    name="isPaymentsIssue"
                                    checked={_.get(formik.values, `contact[${index}].isPaymentsIssue`, false)}
                                    onChange={e => onUpdateContactReason(index, 'isPaymentsIssue', e.target.checked)}
                                    color="primary"
                                    style={{ padding: 0, margin: 0, height: '25px' }}
                                />
                                Payments Issue
                            </Typography>
                        </div>
                    </CardContent>
                </Collapse>
            </Card>
        </Grid>
    );
}

function FaqConfigWidget(props) {
    const { formik, type, theme, onDeleteFaq, onAddFaq, onUpdateFaq, lang } = props;
    return (
        <Grid container spacing={theme.spacing.unit}>
            {_.get(formik.values, `faq.${type}`, []).map((faq, i) => (
                <FaqConfigAddUpdateWidget
                    formik={formik}
                    type={type}
                    index={i}
                    theme={theme}
                    onDeleteFaq={onDeleteFaq}
                    onUpdateFaq={onUpdateFaq}
                    lang={lang}
                />
            ))}
            <Grid item xs={12}>
                <Divider style={{ marginTop: theme.spacing.unit * 2, marginBottom: theme.spacing.unit * 2 }} />
            </Grid>
            <FaqConfigAddUpdateWidget type={type} theme={theme} onAddFaq={onAddFaq} lang={lang} />
        </Grid>
    );
}

function FaqConfigAddUpdateWidget(props) {
    const { formik, type, index, theme, onAddFaq, onDeleteFaq, onUpdateFaq, lang } = props;

    const [newAnswer, setNewAnswer] = useState('');
    const [newQuestion, setNewQuestion] = useState('');

    const onAdd = () => {
        onAddFaq(type, { q: newQuestion, a: newAnswer });
        setNewAnswer('');
        setNewQuestion('');
    };

    return (
        <>
            <Grid data-cy={_.isNil(index) ? null : 'information-forms-faq-config-update-widget'} item xs={3}>
                {_.isNil(index) ? (
                    <TextField
                        style={{ marginRight: theme.spacing.unit }}
                        value={newQuestion}
                        name="question"
                        onChange={e => setNewQuestion(e.target.value)}
                        label={loc('question', lang)}
                        variant="outlined"
                        fullWidth
                        data-cy="information-forms-faq-question-input"
                    />
                ) : (
                    <InputWithConfirmButtons
                        initialValue={_.get(formik.values, `faq.${type}[${index}].q`)}
                        onConfirmValue={value => !_.isEmpty(value) && onUpdateFaq(type, index, 'q', value)}
                        label={loc('question', lang)}
                        variant="outlined"
                        fullWidth
                        multiline
                        dataCy={`information-forms-faq-${index}-question`}
                    />
                )}
            </Grid>
            <Grid item xs={8}>
                {_.isNil(index) ? (
                    <TextField
                        style={{ marginRight: theme.spacing.unit }}
                        value={newAnswer}
                        onChange={e => setNewAnswer(e.target.value)}
                        name="answer"
                        label={loc('answer', lang)}
                        variant="outlined"
                        fullWidth
                        data-cy="information-forms-faq-answer-input"
                    />
                ) : (
                    <InputWithConfirmButtons
                        initialValue={_.get(formik.values, `faq.${type}[${index}].a`)}
                        onConfirmValue={value => !_.isEmpty(value) && onUpdateFaq(type, index, 'a', value)}
                        label={loc('answer', lang)}
                        variant="outlined"
                        fullWidth
                        multiline
                        dataCy={`information-forms-faq-${index}-answer`}
                    />
                )}
            </Grid>
            <Grid item xs={1}>
                {_.isNil(index) ? (
                    <IconButton
                        color="primary"
                        onClick={onAdd}
                        disabled={_.isEmpty(newQuestion) || _.isEmpty(newAnswer)}
                        data-cy="information-forms-faq-submit"
                    >
                        <Icon>add_circle_outline</Icon>
                    </IconButton>
                ) : (
                    <IconButton
                        onClick={() => onDeleteFaq(type, index)}
                        data-cy={`information-forms-faq-${index}-delete`}
                    >
                        <Icon>delete</Icon>
                    </IconButton>
                )}
            </Grid>
        </>
    );
}

function HowItWorksConfigUpdateWidget(props) {
    const {
        formik,
        typeIndex,
        stepIndex,
        theme,
        onDeleteHowItWorksStep,
        onUpdateHowItWorksStep,
        onSearch,
        lang
    } = props;

    const initialIcon = _.isNil(formik)
        ? ''
        : _.get(formik.values, `howItWorks[${typeIndex}].steps[${stepIndex}].icon`, '');
    const [icon, setIcon] = useState(initialIcon);
    const iconChanged = icon !== initialIcon;
    const [expanded, setExpanded] = useState(false);

    return (
        <Grid item xl={4} md={6} sm={12}>
            <Card
                style={{
                    margin: theme.spacing.unit,
                    boxShadow: 'none',
                    border: '1px solid rgba(224, 224, 224, 1)'
                }}
                data-cy="information-forms-how-it-works-config-update-widget"
            >
                <CardHeader
                    titleTypographyProps={{ variant: 'body1' }}
                    title={
                        expanded ? (
                            <div style={{ display: 'flex' }}>
                                <Icon color="primary" style={{ fontSize: '50px', marginRight: theme.spacing.unit }}>
                                    {icon}
                                </Icon>
                                <TextField
                                    style={{ marginRight: 0, flex: 0.7 }}
                                    name="icon"
                                    label="Icon"
                                    variant="outlined"
                                    value={icon}
                                    onChange={e => setIcon(e.target.value)}
                                    data-cy={`information-forms-how-it-works-${stepIndex}-icon-input`}
                                    InputProps={{
                                        endAdornment: iconChanged && (
                                            <InputAdornment position="end">
                                                <Icon
                                                    fontSize="small"
                                                    style={{
                                                        color: !_.isEmpty(icon) ? colors.green[500] : colors.grey[300],
                                                        cursor: 'pointer',
                                                        marginRight: theme.spacing.unit
                                                    }}
                                                    onClick={() =>
                                                        !_.isEmpty(icon) &&
                                                        onUpdateHowItWorksStep(typeIndex, stepIndex, 'icon', icon)
                                                    }
                                                    data-cy={`information-forms-how-it-works-${stepIndex}-icon-confirm`}
                                                >
                                                    check
                                                </Icon>
                                                <Icon
                                                    fontSize="small"
                                                    style={{ color: colors.red[500], cursor: 'pointer' }}
                                                    onClick={() => setIcon(initialIcon)}
                                                    data-cy={`information-forms-how-it-works-${stepIndex}-icon-cancel`}
                                                >
                                                    close
                                                </Icon>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                                <IconButton
                                    style={{
                                        marginTop: theme.spacing.unit * 2,
                                        marginLeft: theme.spacing.unit,
                                        marginRight: theme.spacing.unit,
                                        padding: '2px',
                                        height: '100%'
                                    }}
                                    onClick={onSearch}
                                >
                                    <SearchIcon />
                                </IconButton>
                                <InputWithConfirmButtons
                                    initialValue={_.get(
                                        formik.values,
                                        `howItWorks[${typeIndex}].steps[${stepIndex}].title`,
                                        ''
                                    )}
                                    onConfirmValue={value =>
                                        onUpdateHowItWorksStep(typeIndex, stepIndex, 'title', value)
                                    }
                                    label={loc('title', lang)}
                                    variant="outlined"
                                    multiline
                                    dataCy={`information-forms-how-it-works-${stepIndex}-title`}
                                />
                            </div>
                        ) : (
                            <div style={{ display: 'flex' }}>
                                <Icon color="primary" style={{ fontSize: '50px', marginRight: theme.spacing.unit }}>
                                    {icon}
                                </Icon>{' '}
                                <Typography
                                    variant="h6"
                                    style={{
                                        marginTop: theme.spacing.unit
                                    }}
                                >
                                    {_.get(formik.values, `howItWorks[${typeIndex}].steps[${stepIndex}].title`, '')}
                                </Typography>
                            </div>
                        )
                    }
                    action={
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                            <IconButton
                                data-cy={`information-forms-how-it-works-${stepIndex}-delete`}
                                onClick={() => onDeleteHowItWorksStep(typeIndex, stepIndex)}
                            >
                                <Icon>delete</Icon>
                            </IconButton>
                            <IconButton
                                data-cy={`information-forms-how-it-works-${stepIndex}-expand`}
                                onClick={() => setExpanded(!expanded)}
                            >
                                <Icon>{expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</Icon>
                            </IconButton>
                        </span>
                    }
                />
                <Collapse in={expanded}>
                    <CardContent>
                        <InputWithConfirmButtons
                            initialValue={_.get(formik.values, `howItWorks[${typeIndex}].steps[${stepIndex}].text`, '')}
                            onConfirmValue={value => onUpdateHowItWorksStep(typeIndex, stepIndex, 'text', value)}
                            label={loc('growthPostInfo7', lang)}
                            variant="outlined"
                            fullWidth
                            multiline
                            dataCy={`information-forms-how-it-works-${stepIndex}-text`}
                        />
                        <StringListInput
                            InputProps={{
                                variant: 'outlined'
                            }}
                            label="Instructions (Optional)"
                            values={_.get(
                                formik.values,
                                `howItWorks[${typeIndex}].steps[${stepIndex}].instructions`,
                                []
                            )}
                            onChange={values => onUpdateHowItWorksStep(typeIndex, stepIndex, 'instructions', values)}
                            error={() => {}}
                            errorMessage=""
                            longStringFormat
                        />
                    </CardContent>
                </Collapse>
            </Card>
        </Grid>
    );
}

function HowItWorksConfigAddWidget(props) {
    const { typeIndex, stepIndex, theme, onAddHowItWorksStep, onSearch, lang } = props;

    const [newTitle, setNewTitle] = useState('');
    const [newText, setNewText] = useState('');
    const [newIcon, setNewIcon] = useState('');
    const [newInstructions, setNewInstructions] = useState([]);

    const onAdd = () => {
        onAddHowItWorksStep(typeIndex, {
            title: newTitle,
            text: newText,
            icon: newIcon,
            instructions: newInstructions
        });
        setNewTitle('');
        setNewText('');
        setNewIcon('');
        setNewInstructions([]);
    };

    return (
        <>
            <Grid item xl={2} md={3} xs={6} style={{ marginBottom: 0 }}>
                <div style={{ display: 'flex' }}>
                    <Icon color="primary" style={{ fontSize: '50px', marginRight: theme.spacing.unit }}>
                        {newIcon ? newIcon : 'pending'}
                    </Icon>
                    <TextField
                        style={{ marginRight: theme.spacing.unit }}
                        name="icon"
                        label="Icon"
                        variant="outlined"
                        value={newIcon}
                        onChange={e => setNewIcon(e.target.value)}
                        data-cy="information-forms-how-it-works-icon-input"
                    />
                    <IconButton
                        style={{
                            marginTop: theme.spacing.unit * 2,
                            marginLeft: theme.spacing.unit,
                            marginRight: theme.spacing.unit,
                            padding: '2px',
                            height: '100%'
                        }}
                        onClick={onSearch}
                    >
                        <SearchIcon />
                    </IconButton>
                </div>
            </Grid>
            <Grid item md={2} xs={6}>
                <TextField
                    style={{ marginRight: theme.spacing.unit }}
                    name="title"
                    label={loc('title', lang)}
                    variant="outlined"
                    value={newTitle}
                    onChange={e => setNewTitle(e.target.value)}
                    fullWidth
                    data-cy="information-forms-how-it-works-title-input"
                />
            </Grid>

            <Grid item xl={8} md={7} xs={12} style={{ marginBottom: 0 }}>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <TextField
                        style={{ marginRight: theme.spacing.unit }}
                        name="text"
                        label={loc('growthPostInfo7', lang)}
                        variant="outlined"
                        value={newText}
                        onChange={e => setNewText(e.target.value)}
                        fullWidth
                        data-cy="information-forms-how-it-works-text-input"
                    />

                    {/* {!_.isNil(stepIndex) &&
                        !_.isNil(_.get(formik.values, `howItWorks[${typeIndex}].steps[${stepIndex}].referApp`)) && (
                            <Typography variant="body2">
                                Refer App
                                <Switch
                                    checked={_.get(
                                        formik.values,
                                        `howItWorks[${typeIndex}].steps[${stepIndex}].referApp`,
                                        false
                                    )}
                                    onChange={e =>
                                        onUpdateHowItWorksStep(typeIndex, stepIndex, 'referApp', e.target.checked)
                                    }
                                    color="primary"
                                />
                            </Typography>
                        )} */}
                    <IconButton
                        color="primary"
                        style={{
                            marginTop: theme.spacing.unit * 2,
                            marginLeft: theme.spacing.unit,
                            marginRight: theme.spacing.unit,
                            padding: '2px',
                            height: '100%'
                        }}
                        onClick={onAdd}
                        disabled={_.isEmpty(newTitle) || _.isEmpty(newText) || isEmpty(newIcon)}
                        data-cy="information-forms-how-it-works-submit"
                    >
                        <Icon>add_circle_outline</Icon>
                    </IconButton>
                </div>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 0, marginBottom: _.isNil(stepIndex) ? 0 : theme.spacing.unit * 3 }}>
                <StringListInput
                    InputProps={{
                        variant: 'outlined'
                    }}
                    label="Instructions (Optional)"
                    values={newInstructions}
                    onChange={values => setNewInstructions(values)}
                    error={() => {}}
                    errorMessage=""
                    style={{ marginTop: 0 }}
                />
            </Grid>
        </>
    );
}

function FormsWidget(props) {
    const [expanded, setExpanded] = useState(false);
    const { children, formik, theme, title, autoSave = false } = props;
    return (
        <Paper style={{ padding: theme.spacing.unit * 2, marginTop: theme.spacing.unit * 2 }}>
            <Typography variant="h6">
                <IconButton data-cy={`expand-${_.camelCase(title)}`} onClick={() => setExpanded(!expanded)}>
                    <Icon>{expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</Icon>
                </IconButton>
                {title}
            </Typography>

            <Collapse in={expanded}>
                <Grid container spacing={theme.spacing.unit}>
                    {children}
                </Grid>
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: theme.spacing.unit * 2 }}>
                    <IconButton onClick={() => setExpanded(false)}>
                        <Icon>keyboard_arrow_up</Icon>
                    </IconButton>
                    {!autoSave && (
                        <Button
                            data-cy={`${_.camelCase(title)}-submit`}
                            color="primary"
                            onClick={formik.handleSubmit}
                            variant="contained"
                        >
                            Save
                        </Button>
                    )}
                </div>
            </Collapse>
        </Paper>
    );
}
