import React, { Component } from 'react';

import _ from 'lodash';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import { withTheme } from '@material-ui/core/styles';
import { _charity } from 'std';
import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

class CharitySelectedDialog extends Component {
    static contextType = LocalizationContext;
    state = {
        charity: undefined,
        subdivision: undefined
    };

    async componentDidMount() {
        const { http, org, subdivisionWithoutSpaces } = this.props;

        if (_.isNil(org)) {
            return;
        }

        let res = await http.getJSON('/getCharityByUrl/' + org.toLowerCase());
        if (res.ok) {
            if (!_.isNil(res.data.charity) && res.data.charity.approved) {
                const subdivision = _charity.findSubdivision(res.data.charity, subdivisionWithoutSpaces);
                this.setState({ charity: res.data.charity, subdivision });
            }
        }
    }

    async componentDidUpdate(prevProps) {
        const { http, org, subdivisionWithoutSpaces } = this.props;

        if (_.isNil(org)) {
            return;
        }

        if (prevProps.org !== org) {
            let res = await http.getJSON('/getCharityByUrl/' + org.toLowerCase());
            if (res.ok) {
                if (!_.isNil(res.data.charity) && res.data.charity.approved) {
                    const subdivision = _charity.findSubdivision(res.data.charity, subdivisionWithoutSpaces);
                    this.setState({ charity: res.data.charity, subdivision });
                }
            }
        }
    }

    handleSubmit = async () => {
        const { http, customer_id } = this.props;
        const { charity, subdivision } = this.state;

        let res = await http.post(`/users/${customer_id}/updateCharitySelected`, {
            charity_id: charity._id,
            subdivision
        });
        if (res.ok) {
            this.props.onSubmitSuccess(charity);
            this.props.onClose();
        }
    };

    render() {
        const { open } = this.props;
        const { charity, subdivision } = this.state;

        if (_.isNil(charity)) {
            return null;
        }

        if (charity.closed) {
            return (
                <Dialog open={open} maxWidth="sm" fullWidth onClose={this.props.onClose}>
                    <DialogTitle>{loc('campaignClosed', this.context.lang)}</DialogTitle>
                    <DialogContent>
                        <Typography data-cy="donation-preference-locked-message">
                            {loc('orgLinkExpired', this.context.lang)}
                        </Typography>
                    </DialogContent>

                    <DialogActions>
                        <Button color="primary" onClick={this.props.onClose}>
                            {loc('close', this.context.lang)}
                        </Button>
                    </DialogActions>
                </Dialog>
            );
        }

        return (
            <Dialog open={open} maxWidth="sm" fullWidth onClose={this.props.onClose}>
                <DialogTitle>
                    {this.props.lockDonationPreference
                        ? loc('donationPreferenceLocked', this.context.lang)
                        : loc('charitySelect4', this.context.lang)}
                </DialogTitle>
                {this.props.lockDonationPreference ? (
                    <DialogContent>
                        <Typography data-cy="donation-preference-locked-message">
                            {loc('donationPreferenceLockedMessage', this.context.lang, {
                                brandname: process.env.REACT_APP_BRAND_NAME
                            })}
                        </Typography>
                    </DialogContent>
                ) : (
                    <DialogContent>
                        <Typography>
                            {loc('charitySelect5', this.context.lang)} {charity.name}
                            {_charity.subdivisionInCharity(charity, subdivision) && ` (${subdivision})`}?
                        </Typography>
                        <Typography>{loc('charitySelect6', this.context.lang)}</Typography>
                    </DialogContent>
                )}

                {this.props.lockDonationPreference ? (
                    <DialogActions>
                        <Button color="primary" onClick={this.props.onClose}>
                            {loc('close', this.context.lang)}
                        </Button>
                    </DialogActions>
                ) : (
                    <DialogActions>
                        <Button color="primary" onClick={this.props.onClose}>
                            {loc('no', this.context.lang)}
                        </Button>
                        <Button data-cy="charity-select-dalog-yes-button" color="primary" onClick={this.handleSubmit}>
                            {loc('yes', this.context.lang)}
                        </Button>
                    </DialogActions>
                )}
            </Dialog>
        );
    }
}

export default withTheme()(CharitySelectedDialog);
