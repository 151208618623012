import React, { useContext } from 'react';

import _ from 'lodash';

import { DialogActions, Dialog, DialogContent, TextField, Button, withTheme, DialogTitle } from '@material-ui/core';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

function TrailerLabelDialog({ open, trailerLabel, onClose, onLabelChange, onSubmit }) {
    const { lang } = useContext(LocalizationContext);
    return (
        <Dialog open={open} onClose={onClose} fullWidth>
            <DialogTitle>Enter Trailer Label</DialogTitle>
            <DialogContent>
                <TextField
                    value={trailerLabel}
                    onChange={onLabelChange}
                    label="Trailer Number"
                    margin="normal"
                    variant="outlined"
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button color="primary" disabled={_.isEmpty(trailerLabel)} onClick={onSubmit}>
                    {loc('submit', lang)}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default withTheme()(TrailerLabelDialog);
