import React, { useContext, useState } from 'react';
import _ from 'lodash';

import useGetJSON from 'utils/hooks/useGetJSON';

import {
    Button,
    Paper,
    Icon,
    withTheme,
    Grid,
    LinearProgress,
    IconButton,
    List,
    ListItem,
    ListItemText,
    CardContent,
    CardActions,
    Card,
    CardHeader,
    Menu,
    MenuItem,
    ListItemIcon,
    Typography,
    FormGroup,
    FormControlLabel,
    Switch
} from '@material-ui/core';

import InputWithConfirmButtons from 'components/CustomInputs/InputWithConfirmButtons';

import ConfirmDialogContext from 'components/Dialogs/Confirm/ConfirmDialogContext';

import QuestionDialog from './QuestionDialog';
import AddQuestionnaireDialog from './AddNewQuestionnaireDialog';
import HttpContext from 'utils/contexts/HttpContext';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import { Icon as MDIcon } from '@mdi/react';

import { mdiDrag } from '@mdi/js';
import { useEffect } from 'react';

function Questionnaires(props) {
    const { theme } = props;

    const [addQuestonnaireDialogOpen, setAddQuestonnaireDialogOpen] = useState(false);
    const [questionDialogOpen, setQuestionDialogOpen] = useState(false);
    const [questionnaireSelected, setQuestionnaireSelected] = useState(null);
    const [questionISelected, setQuestionISelected] = useState(null);
    const [loading, setLoading] = useState(false);
    const [questionnaires, setQuestionnaires] = useState();

    const [toggleReorder, setToggleReorder] = useState(false);

    const http = useContext(HttpContext);

    const {
        loading: questionnairesLoading,
        error: questionnairesLoadingErr,
        data,
        refresh: refreshQuestionnaires
    } = useGetJSON(`/questionnaireSorted`, 'questionnaires');

    useEffect(() => {
        setQuestionnaires(data);
    }, [data]);

    const RefreshStates = async () => {
        setQuestionDialogOpen(false);
        setQuestionnaireSelected(null);
        await refreshQuestionnaires();
    };

    const onSubmitCreateQuestion = async question => {
        setLoading(true);

        questionnaireSelected.questions.push(question);
        const res = await http.postJSON(`/questionnaires/${questionnaireSelected._id}/update`, questionnaireSelected);
        if (res.ok) {
            RefreshStates();
        }

        setLoading(false);
    };

    const onSubmitEditQuestion = async question => {
        setLoading(true);

        questionnaireSelected.questions[questionISelected] = question;
        const res = await http.postJSON(`/questionnaires/${questionnaireSelected._id}/update`, questionnaireSelected);
        if (res.ok) {
            RefreshStates();
        }

        setLoading(false);
    };

    const handelAddQuestionnaire = async name => {
        setLoading(true);

        const res = await http.postJSON(`/questionnaires/create`, { name });
        if (res.ok) {
            RefreshStates();
        }
        setAddQuestonnaireDialogOpen(false);
        setLoading(false);
    };

    const handleDragEnd = async result => {
        const { source, destination, draggableId, type } = result;

        //Dragged out into nowhere
        if (!destination) return;

        //Dragged into same spot
        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        setLoading(true);

        //Dragging between questionnaire questions
        if (type === 'QUESTION') {
            var sourceQuestionnaire = _.find(questionnaires, { _id: source.droppableId });
            var destinationQuestionnaire = _.find(questionnaires, { _id: destination.droppableId });

            const questionToMove = _.find(sourceQuestionnaire.questions, { _id: draggableId });
            const sourceQuestionsFiltered = _.filter(
                sourceQuestionnaire.questions,
                question => question._id !== draggableId
            );

            var issue = false;

            //Dragged within same questionnaire
            if (destination.droppableId === source.droppableId) {
                const updatedQuestionOrder = _.cloneDeep(sourceQuestionsFiltered); // Problem if list items lose order
                updatedQuestionOrder.splice(destination.index, 0, questionToMove);
                sourceQuestionnaire.questions = updatedQuestionOrder;
            }
            //Dragged out into another questionnaire
            else {
                const updatedSourceQuestionOrder = _.cloneDeep(sourceQuestionsFiltered);
                sourceQuestionnaire.questions = updatedSourceQuestionOrder;

                const updatedDestinationQuestionOrder = _.cloneDeep(destinationQuestionnaire.questions);
                updatedDestinationQuestionOrder.splice(destination.index, 0, questionToMove);
                destinationQuestionnaire.questions = updatedDestinationQuestionOrder;

                const response = await http.postJSON(
                    `/questionnaires/${destination.droppableId}/update`,
                    destinationQuestionnaire
                );
                if (!response.ok) {
                    issue = true;
                }
            }

            if (!issue) {
                await http.postJSON(`/questionnaires/${source.droppableId}/update`, sourceQuestionnaire);
            }

            RefreshStates();
        } else {
            const questionToMove = _.find(questionnaires, { _id: draggableId });
            const questionsFiltered = _.filter(questionnaires, questionnaire => questionnaire._id !== draggableId);

            const updatedDestinationQuestionOrder = _.cloneDeep(questionsFiltered);

            updatedDestinationQuestionOrder.splice(destination.index, 0, questionToMove);
            var newOrderIds = updatedDestinationQuestionOrder.map(questionnaire => questionnaire._id);

            setQuestionnaires(updatedDestinationQuestionOrder);

            await http.postJSON(`/questionnairesSorted/changePosition`, newOrderIds);

            RefreshStates();
        }

        setLoading(false);
    };

    return (
        <div>
            <Grid
                container
                spacing={theme.spacing.unit * 2}
                style={{ padding: theme.spacing.unit * 2, width: '100%', margin: 0 }}
            >
                <Grid item xs={12}>
                    <Paper
                        style={{
                            padding: theme.spacing.unit * 2,
                            paddingTop: 0
                        }}
                    >
                        {(questionnairesLoading || loading) && <LinearProgress />}
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                paddingTop: theme.spacing.unit * 2
                            }}
                        >
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            color="primary"
                                            onChange={() => setToggleReorder(!toggleReorder)}
                                            value={toggleReorder}
                                            checked={toggleReorder}
                                            disabled={loading}
                                        />
                                    }
                                    label="Reorder Questionnaires"
                                />
                            </FormGroup>

                            <Button
                                data-cy="questionnaires-add-new-btn"
                                style={{ margin: theme.spacing.unit }}
                                variant="outlined"
                                onClick={() => setAddQuestonnaireDialogOpen(true)}
                                color="primary"
                            >
                                <Icon style={{ marginRight: theme.spacing.unit }}>add_circle_outline</Icon>
                                Add New
                            </Button>
                        </div>
                    </Paper>
                </Grid>

                <Grid item xs={12}>
                    {!_.isEmpty(questionnaires) && (
                        <DragDropContext onDragEnd={handleDragEnd}>
                            <Droppable type="CARD" direction="horizontal" droppableId="questionnaireDragDropList">
                                {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        style={
                                            toggleReorder
                                                ? {
                                                      overflowX: 'scroll',
                                                      display: 'flex',
                                                      flexWrap: 'nowrap',
                                                      padding: 30,
                                                      background: theme.palette.grey[200]
                                                  }
                                                : { display: 'flex', flexWrap: 'wrap' }
                                        }
                                    >
                                        {questionnaires.map((questionnaire, i) => {
                                            return (
                                                <Draggable
                                                    key={questionnaire._id.toString()}
                                                    index={i}
                                                    draggableId={questionnaire._id.toString()}
                                                >
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            className={
                                                                'MuiGrid-grid-lg-3-129' +
                                                                ' ' +
                                                                'MuiGrid-grid-md-4-116' +
                                                                ' ' +
                                                                'MuiGrid-grid-sm-6-104' +
                                                                ' ' +
                                                                'MuiGrid-grid-xs-12-96'
                                                            }
                                                            style={{ ...provided.draggableProps.style, flexShrink: 0 }}
                                                        >
                                                            <QuestionnaireCard
                                                                questionnaire={questionnaire}
                                                                theme={theme}
                                                                setLoading={setLoading}
                                                                refreshQuestionnaires={refreshQuestionnaires}
                                                                setQuestionDialogOpen={setQuestionDialogOpen}
                                                                setQuestionISelected={setQuestionISelected}
                                                                setQuestionnaireSelected={setQuestionnaireSelected}
                                                                loading={loading}
                                                                provided={provided}
                                                                toggleReorder={toggleReorder}
                                                            />
                                                        </div>
                                                    )}
                                                </Draggable>
                                            );
                                        })}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    )}{' '}
                </Grid>
            </Grid>
            <QuestionDialog
                open={questionDialogOpen}
                question={_.get(questionnaireSelected, `questions[${questionISelected}]`)}
                onClose={() => setQuestionDialogOpen(false)}
                onSubmit={_.isNil(questionISelected) ? onSubmitCreateQuestion : onSubmitEditQuestion}
            />
            <AddQuestionnaireDialog
                open={addQuestonnaireDialogOpen}
                onClose={() => setAddQuestonnaireDialogOpen(false)}
                onSubmit={name => handelAddQuestionnaire(name)}
            />
        </div>
    );
}

const QuestionnaireCard = ({
    questionnaire,
    theme,
    setLoading,
    refreshQuestionnaires,
    setQuestionDialogOpen,
    setQuestionISelected,
    setQuestionnaireSelected,
    loading,
    provided,
    toggleReorder
}) => {
    const warnAction = useContext(ConfirmDialogContext);
    const http = useContext(HttpContext);

    const handleUpdateQuestionnaireName = async (questionnaire, name) => {
        questionnaire.name = name;
        setLoading(true);
        const res = await http.postJSON(`/questionnaires/${questionnaire._id}/update`, questionnaire);
        if (res.ok) {
            await refreshQuestionnaires();
            setLoading(false);
        }
    };

    const handleDeleteQuestionnaire = async questionnaire => {
        setLoading(true);
        const res = await http.postJSON(`/questionnaires/${questionnaire._id}/delete`, {});
        if (res.ok) {
            await refreshQuestionnaires();
            setLoading(false);
        }
    };

    const handleCreateQuestion = questionnaire => {
        setQuestionISelected(null);
        setQuestionnaireSelected(questionnaire);
        setQuestionDialogOpen(true);
    };

    return (
        <Card
            style={{
                margin: theme.spacing.unit,
                flexShrink: 0
            }}
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    height: '100%',

                    marginBottom: theme.spacing.unit * 1.5,
                    marginTop: theme.spacing.unit * 1.5
                }}
            >
                {toggleReorder && (
                    <Icon {...provided.dragHandleProps}>
                        <MDIcon path={mdiDrag} size={1} color={theme.palette.text.secondary} />
                    </Icon>
                )}
            </div>
            <CardHeader
                title={
                    <InputWithConfirmButtons
                        initialValue={_.get(questionnaire, 'name', '')}
                        onConfirmValue={name => {
                            handleUpdateQuestionnaireName(questionnaire, name);
                        }}
                        fullWidth
                        disabled={loading}
                    />
                }
                action={
                    <IconButton
                        disabled={loading}
                        onClick={() => {
                            warnAction(() => {
                                handleDeleteQuestionnaire(questionnaire);
                            }, `Are you sure you want to delete this questionnaire`);
                        }}
                    >
                        <Icon>delete</Icon>
                    </IconButton>
                }
            />

            <CardContent>
                <List>
                    <Droppable direction="vertical" droppableId={questionnaire._id} type="QUESTION">
                        {(provided, snapshot) => (
                            <div ref={provided.innerRef} {...provided.droppableProps}>
                                {_.get(questionnaire, 'questions', []).map((question, i) => {
                                    return (
                                        <Draggable
                                            key={question._id}
                                            index={i}
                                            draggableId={question._id}
                                            isDragDisabled={loading}
                                        >
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    style={provided.draggableProps.style}
                                                >
                                                    <QuestionItem
                                                        question={question}
                                                        i={i}
                                                        theme={theme}
                                                        questionnaire={questionnaire}
                                                        loading={loading}
                                                        setLoading={setLoading}
                                                        refreshQuestionnaires={refreshQuestionnaires}
                                                        setQuestionISelected={setQuestionISelected}
                                                        setQuestionnaireSelected={setQuestionnaireSelected}
                                                        setQuestionDialogOpen={setQuestionDialogOpen}
                                                        provided={provided}
                                                    />
                                                </div>
                                            )}
                                        </Draggable>
                                    );
                                })}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </List>
            </CardContent>

            <CardActions>
                <Button
                    variant="outlined"
                    onClick={() => handleCreateQuestion(questionnaire)}
                    data-cy={`${_.get(questionnaire, 'name')}-add-question-btn`}
                    fullWidth
                    disabled={loading}
                >
                    <Icon style={{ marginRight: theme.spacing.unit }}>add_circle_outline</Icon> Add
                </Button>
            </CardActions>
        </Card>
    );
};

const QuestionItem = ({
    theme,
    questionnaire,
    loading,
    setLoading,
    refreshQuestionnaires,
    setQuestionISelected,
    setQuestionnaireSelected,
    setQuestionDialogOpen,
    question,
    i,
    provided
}) => {
    const [isSubMenuOpen, setIsSubMenuOpen] = useState(null);

    const handleMenuOpen = e => {
        setIsSubMenuOpen(e.currentTarget);
    };

    const handleMenuClose = e => {
        setIsSubMenuOpen(null);
    };

    return (
        <>
            <ListItem disableGutters>
                <ListItemIcon {...provided.dragHandleProps}>
                    <MDIcon path={mdiDrag} size={1} color={theme.palette.text.secondary} />
                </ListItemIcon>

                <ListItemText primary={_.get(question, 'description.en')} />

                <IconButton size="small" disabled={loading} onClick={e => handleMenuOpen(e)}>
                    <Icon>more_horiz</Icon>
                </IconButton>
            </ListItem>
            <QuestionSubMenu
                questionnaire={questionnaire}
                setLoading={setLoading}
                refreshQuestionnaires={refreshQuestionnaires}
                setQuestionISelected={setQuestionISelected}
                setQuestionnaireSelected={setQuestionnaireSelected}
                setQuestionDialogOpen={setQuestionDialogOpen}
                i={i}
                isSubMenuOpen={isSubMenuOpen}
                setIsSubMenuOpen={setIsSubMenuOpen}
                handleMenuClose={handleMenuClose}
            />
        </>
    );
};

const QuestionSubMenu = ({
    questionnaire,
    setLoading,
    refreshQuestionnaires,
    setQuestionISelected,
    setQuestionnaireSelected,
    setQuestionDialogOpen,
    i,
    isSubMenuOpen,
    setIsSubMenuOpen,
    handleMenuClose
}) => {
    const warnAction = useContext(ConfirmDialogContext);
    const http = useContext(HttpContext);

    const onDeleteQuestion = async (questionnaire, i) => {
        setLoading(true);

        questionnaire.questions.splice(i, 1);
        const res = await http.postJSON(`/questionnaires/${questionnaire._id}/update`, questionnaire);
        if (res.ok) {
            await refreshQuestionnaires();
        }
        setIsSubMenuOpen(null);

        setLoading(false);
    };

    const handleDuplicateQuestion = async (questionnaire, i) => {
        setLoading(true);

        setIsSubMenuOpen(null);
        var question = questionnaire.questions[i];

        // Let mongodb create _id
        question._id = undefined;
        questionnaire.questions.splice(i, 0, question);

        const res = await http.postJSON(`/questionnaires/${questionnaire._id}/update`, questionnaire);
        if (res.ok) {
            await refreshQuestionnaires();
        }

        setLoading(false);
    };

    const handleOpenEditDialog = (questionnaire, questionI) => {
        setQuestionISelected(questionI);
        setQuestionnaireSelected(questionnaire);
        setQuestionDialogOpen(true);
        setIsSubMenuOpen(null);
    };

    return (
        <Menu
            data-cy="question-sub-menu"
            anchorEl={isSubMenuOpen}
            open={Boolean(isSubMenuOpen)}
            onClose={handleMenuClose}
        >
            <MenuItem data-cy={`question-sub-menu-edit`} onClick={() => handleOpenEditDialog(questionnaire, i)}>
                <IconButton size="small">
                    <Icon>edit</Icon>
                </IconButton>
                <Typography>Edit</Typography>
            </MenuItem>

            <MenuItem
                data-cy={`question-sub-menu-edit`}
                onClick={() => {
                    handleDuplicateQuestion(questionnaire, i);
                }}
            >
                <IconButton size="small">
                    <Icon>copy_all</Icon>
                </IconButton>
                <Typography>Duplicate</Typography>
            </MenuItem>

            <MenuItem
                data-cy={`question-sub-menu-delete`}
                onClick={() => {
                    setIsSubMenuOpen(null);
                    warnAction(() => {
                        onDeleteQuestion(questionnaire, i);
                    }, `Are you sure you want to delete this`);
                }}
            >
                <IconButton>
                    <Icon>delete</Icon>
                </IconButton>
                <Typography>Delete</Typography>
            </MenuItem>
        </Menu>
    );
};

export default withTheme()(Questionnaires);
