import VERSION_FRONT from 'VERSION';

import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { withTheme } from '@material-ui/core/styles';

function RefreshRequiredDialog({ theme, open }) {
    return (
        <Dialog open={open} maxWidth="sm" fullWidth>
            <DialogTitle>Update required</DialogTitle>
            <DialogContent>
                <Typography variant="subtitle1">
                    We have introduced important changes that require you to update to the latest version of the app.
                    Please refresh the page to continue.
                </Typography>
                <Button variant="contained" onClick={() => window.location.reload(true)}>
                    Refresh
                </Button>

                <Typography variant="subtitle1" color="textSecondary" style={{ marginTop: theme.spacing.unit * 2 }}>
                    Your current version: {VERSION_FRONT}
                </Typography>
            </DialogContent>
        </Dialog>
    );
}

export default withTheme()(RefreshRequiredDialog);
