import { useEffect } from 'react';

import _ from 'lodash';

import useQueryString from 'utils/hooks/useQueryString';
import { getQueryStringValue } from 'utils/query';

function isValidsku(skuArr, sku) {
    if (sku === 'all') return true;
    return skuArr.includes(sku);
}

function useBulkSkuTypeSelectionDropDown({ skus = [], showAll = true, stateURLKey = 'skuType', saveStateInURL }) {
    const [sku, setSku] = useQueryString(stateURLKey, getInitialSkuTypeFilter(skus, showAll), saveStateInURL, sku => {
        return isValidsku(skus, sku);
    });

    useEffect(() => {
        if (!isValidsku(skus, sku)) {
            const skuFromQuery = getQueryStringValue(stateURLKey);
            if (!_.isEmpty(skuFromQuery)) {
                setSku(skuFromQuery);
            } else {
                setSku(getInitialSkuTypeFilter(skus, showAll));
            }
        }
    }, [skus]);

    const handleChange = e => {
        const val = e.target.value;
        setSku(val);
    };

    return {
        showAll,
        sku,
        handleChange
    };
}

export default useBulkSkuTypeSelectionDropDown;

function getInitialSkuTypeFilter(skus, showAll) {
    if (_.isEmpty(skus)) {
        return null;
    } else if (showAll) {
        return 'all';
    } else {
        return _.first(skus)._id;
    }
}
