import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';
import { _user } from 'std';

import { deviceHelper, formatAsCurrency } from 'utils/misc';

import { Icon, IconButton, Tooltip } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';

import { mdiLockReset, mdiLogout, mdiAccountOff, mdiAccountCheck } from '@mdi/js';
import MDIcon from '@mdi/react';

import UserForm from './Forms/UserForm';
import CRUDTable from './CRUDTable';
import PasswordResetDialog from './Dialogs/PasswordResetDialog';
import DisableAccountDialog from './Dialogs/DisableAccountDialog';

import useCRUD from './hooks/useCRUD';
import useGetJSON from 'utils/hooks/useGetJSON';
import { ROLES } from 'constants.js';

function AdminTable(props) {
    const {
        operator,
        http,
        google,
        onSnackbar,
        theme,
        twoFactorAuthenticationWarning,
        rolePermissions,
        collector
    } = props;

    const endpoints = {
        getEndPoint: '/users/getCRUDAdmins',
        getEditEndPoint: _id => `/users/${_id}/adminUpdate`,
        createEndPoint: `/users/adminCreateUser`
    };

    const [collectors, setCollectors] = useState([]);
    const [collectorsLoading, setCollectorsLoading] = useState(false);
    const [processors, setProcessors] = useState([]);
    const [processorsLoading, setProcessorsLoading] = useState(false);

    const [driverConfig, setDriverConfig] = useState({});
    const [driverConfigLoading, setDriverConfigLoading] = useState(false);

    const [passwordResetDialogOpen, setPasswordResetDialogOpen] = useState(false);
    const [disableAccountDialogOpen, setDisableAccountDialogOpen] = useState(false);

    const [selectedDocument, setSelectedDocument] = useState(null);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [activeOrderBy, setActiveOrderBy] = useState(null);
    const [activeOrder, setActiveOrder] = useState('asc');

    const [promptForDriverCode, setPromptForDriverCode] = useState(false);

    const {
        data,
        otherStats,
        startDateFilterStats,
        endDateFilterStats,
        loading,
        setLoading,
        setStartDateFilterStats,
        setEndDateFilterStats,
        dateFilterStatsErrorMessage,
        handleCreate,
        handleEdit,
        handleReloadData
    } = useCRUD({
        endpoints,
        setEditDialogOpen,
        setActiveOrderBy,
        setActiveOrder,
        http,
        onSnackbar
    });

    const { loading: commoditiesLoading, error: commoditiesLoadingErr, data: commodities } = useGetJSON(
        `/commodities/getAllCommodities`,
        'collectionData'
    );

    /*const { loading: collectorsLoading, error: collectorError, data: collectors = [] } = useGetJSON(
        '/collectors?includeDisabled=true',
        'collectors'
    );*/

    useEffect(() => {
        (async () => {
            setCollectorsLoading(true);
            const res = await http.getJSON('/collectors?includeDisabled=true');
            if (res.ok) {
                setCollectors(res.data.collectors);
            }
            setCollectorsLoading(false);
        })();
        (async () => {
            setProcessorsLoading(true);
            const res = await http.getJSON('/collectors/all');
            if (res.ok) {
                setProcessors(res.data.collectors);
            }
            setProcessorsLoading(false);
        })();
        (async () => {
            setDriverConfigLoading(true);
            const res = await http.getJSON('/users/getDriversConfig');
            if (res.ok) {
                setDriverConfig(res.data);
            }
            setDriverConfigLoading(false);
        })();
    }, []);

    const handleReenableDriverAccount = async () => {
        await handleReloadData();
        setSelectedDocument(_.find(data, d => d._id.toString() === selectedDocument._id.toString()));
        setEditDialogOpen(true);
    };

    useEffect(() => {
        if (promptForDriverCode) {
            handleReenableDriverAccount();
        }
    }, [disableAccountDialogOpen]);

    useEffect(() => {
        if (!editDialogOpen) {
            setPromptForDriverCode(false);
        }
    }, [editDialogOpen]);

    const onViewAccount = document => {
        if (ROLES.includes(operator.accountType) && rolePermissions) {
            if (
                (_user.isDriver(document) || _user.isClerk(document)) &&
                !_.get(rolePermissions, 'accounts.driver', false)
            ) {
                onSnackbar('You do not have permissions to view driver accounts', 'error');
                return;
            } else if (
                document.accountType === 'Collector Administrator' &&
                !_.get(rolePermissions, 'accounts.collector', false)
            ) {
                onSnackbar('You do not have permissions to view collector accounts', 'error');
                return;
            } else if (
                document.accountType === 'System Administrator' &&
                !_.get(rolePermissions, 'accounts.systemAdministrator', false)
            ) {
                onSnackbar('You do not have permissions to view system administrator accounts', 'error');
                return;
            }
        }
        let extension = '';
        if (_user.isDriver(document) || _user.isClerk(document)) {
            extension = 'driver';
        } else if (document.accountType === 'Collector Administrator') {
            extension = 'collector';
        }
        if (deviceHelper.isNativeApp()) {
            props.history.push(`/operators/${document._id}/${extension}`);
        } else {
            window.open(`/operators/${document._id}/${extension}`, '_blank');
        }
    };

    const onLogoutUser = async user => {
        setLoading(true);
        const res = await http.post(`/users/${user._id}/adminLogoutUser`);
        setLoading(false);
        if (res.ok) {
            onSnackbar('User logged out successfully');
        }
    };
    const onPasswordResetDialog = document => {
        setSelectedDocument(document);
        setPasswordResetDialogOpen(true);
    };
    const onDisableAccountDialog = document => {
        setSelectedDocument(document);
        setDisableAccountDialogOpen(true);
    };

    const systemAdminColumns = [
        { key: 'name.first', header: 'First Name' },
        { key: 'name.last', header: 'Last Name' },
        { key: 'driverCode', header: 'Code' },
        { key: 'email', header: 'Email' },
        { key: 'accountType', header: 'Type' },
        { key: 'accountPermissions', header: 'Permissions' },
        { key: 'phone', header: 'Phone', isPhoneNumber: true },
        { key: 'collector.name', header: 'Collector' },
        { key: 'depotLoginID', header: 'User ID' },
        { key: 'lastActivity', header: 'Last Login', isDate: true }
    ];

    const defaultColumnFiltersAdmin = [
        'name.first',
        'name.last',
        'driverCode',
        'email',
        'accountType',
        'accountPermissions',
        'phone',
        'collector.name',
        'depotLoginID',
        'googleDirectionCalls',
        'lastActivity'
    ];
    const editForm = (
        <UserForm
            user={selectedDocument}
            onSubmit={_.isNil(selectedDocument) ? handleCreate : handleEdit}
            http={http}
            driverConfig={driverConfig}
            setLoading={setLoading}
            loading={loading || collectorsLoading || driverConfigLoading || processorsLoading}
            editing={!_.isNil(selectedDocument)}
            onSnackbar={onSnackbar}
            operator={operator}
            open={editDialogOpen}
            onClose={
                promptForDriverCode
                    ? () => {}
                    : () => {
                          setPromptForDriverCode(false);
                          setEditDialogOpen(false);
                      }
            }
            collectors={collectors}
            processors={processors}
            commodities={commodities}
            google={google}
            twoFactorAuthenticationWarning={twoFactorAuthenticationWarning}
            promptForDriverCode={promptForDriverCode}
            accountTypes={['System Administrator', ...ROLES]}
        />
    );

    const renderExtraActions = document => {
        const disabled =
            ROLES.includes(operator.accountType) &&
            document.accountType !== 'Collector Employee' &&
            !_.get(rolePermissions, 'accounts.systemAdministrator', false);
        return (
            <>
                {!document.banned ? (
                    <Tooltip title="Ban Account" disableFocusListener disableTouchListener>
                        <IconButton
                            onClick={() => onDisableAccountDialog(document)}
                            data-cy="employee-table-ban-button"
                            disabled={disabled}
                        >
                            <MDIcon
                                path={mdiAccountOff}
                                size={1}
                                color={disabled ? 'rgba(0, 0, 0, 0.24)' : theme.palette.text.secondary}
                            />
                        </IconButton>
                    </Tooltip>
                ) : (
                    <Tooltip title="Unban Account" disableFocusListener disableTouchListener>
                        <IconButton
                            onClick={() => onDisableAccountDialog(document)}
                            data-cy="employee-table-enable-button"
                            disabled={disabled}
                        >
                            <MDIcon
                                path={mdiAccountCheck}
                                size={1}
                                color={disabled ? 'rgba(0, 0, 0, 0.24)' : theme.palette.text.secondary}
                            />
                        </IconButton>
                    </Tooltip>
                )}
                <Tooltip title="Reset Password" disableFocusListener disableTouchListener>
                    <IconButton
                        onClick={() => onPasswordResetDialog(document)}
                        data-cy="employee-table-reset-password"
                        disabled={disabled}
                    >
                        <MDIcon
                            path={mdiLockReset}
                            size={1}
                            color={disabled ? 'rgba(0, 0, 0, 0.24)' : theme.palette.text.secondary}
                        />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Logout" disableFocusListener disableTouchListener>
                    <IconButton onClick={() => onLogoutUser(document)} disabled={disabled}>
                        <MDIcon
                            path={mdiLogout}
                            size={1}
                            color={disabled ? 'rgba(0, 0, 0, 0.24)' : theme.palette.text.secondary}
                        />
                    </IconButton>
                </Tooltip>
                <Tooltip title="View Account" disableFocusListener disableTouchListener>
                    <IconButton
                        onClick={() => {
                            onViewAccount(document);
                        }}
                        data-cy="employee-table-view-account"
                    >
                        <Icon>visibility</Icon>
                    </IconButton>
                </Tooltip>
            </>
        );
    };

    return (
        <>
            <CRUDTable
                operator={operator}
                columns={systemAdminColumns}
                data={data}
                editForm={editForm}
                renderExtraActions={renderExtraActions}
                documentIsDisabled={employee => _.get(employee, 'banned', false)}
                defaultRowsPerPage={5}
                startDateFilterStats={startDateFilterStats}
                setStartDateFilterStats={setStartDateFilterStats}
                endDateFilterStats={endDateFilterStats}
                setEndDateFilterStats={setEndDateFilterStats}
                dateFilterStatsErrorMessage={dateFilterStatsErrorMessage}
                selectedDocument={selectedDocument}
                setSelectedDocument={setSelectedDocument}
                editDialogOpen={editDialogOpen}
                setEditDialogOpen={setEditDialogOpen}
                activeOrderBy={activeOrderBy}
                setActiveOrderBy={setActiveOrderBy}
                activeOrder={activeOrder}
                setActiveOrder={setActiveOrder}
                enabledHeaderText={'Enabled Accounts'}
                disabledHeaderText={'Banned Accounts'}
                loading={loading || collectorsLoading}
                defaultColumnFilters={defaultColumnFiltersAdmin}
                hideDateFilters
                initialCollectorFilter={
                    collector && _user.isCollectorAdmin(operator) && _.get(operator, 'collectors', []).length > 1
                        ? collector
                        : null
                }
            />
            <PasswordResetDialog
                open={passwordResetDialogOpen}
                onClose={() => setPasswordResetDialogOpen(false)}
                user={selectedDocument}
                http={http}
                onSnackbar={onSnackbar}
            />
            {disableAccountDialogOpen && (
                <DisableAccountDialog
                    selectedAccount={selectedDocument}
                    allEmployees={data}
                    open={disableAccountDialogOpen}
                    onClose={() => setDisableAccountDialogOpen(false)}
                    loading={loading || collectorsLoading}
                    setLoading={setLoading}
                    onReloadData={handleReloadData}
                    http={http}
                    setPromptForDriverCode={setPromptForDriverCode}
                />
            )}
        </>
    );
}

export default withTheme()(AdminTable);
