import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';

/**
 * Function to handle calling the camera plugin
 */
export async function takePhoto(uploadExistingPhoto = false) {
    try {
        const image = await Camera.getPhoto({
            quality: 70,
            allowEditing: false,
            resultType: CameraResultType.Uri,
            source: uploadExistingPhoto ? CameraSource.Photos : CameraSource.Camera
        });

        const data = await fetch(image.webPath).then(r => r.blob());
        return {
            data,
            image
        };
    } catch (err) {
        console.error(err);
    }
}

/**
 *
 * This version throws an error so that error messages can be displayed to the user
 */
export async function getPhoto(uploadExistingPhoto = false) {
    const image = await Camera.getPhoto({
        quality: 70,
        allowEditing: false,
        resultType: CameraResultType.Uri,
        source: uploadExistingPhoto ? CameraSource.Photos : CameraSource.Camera
    });

    const data = await fetch(image.webPath).then(r => r.blob());
    return {
        data,
        image
    };
}
