import React, { useState, useContext } from 'react';
import _ from 'lodash';
import * as colors from '@material-ui/core/colors';
import { withTheme } from '@material-ui/core/styles';

import {
    TextField,
    Button,
    Chip,
    Icon,
    InputAdornment,
    IconButton,
    Typography,
    Paper,
    Tooltip,
    Switch,
    FormControlLabel
} from '@material-ui/core';
import ConfirmDialogContext from 'components/Dialogs/Confirm/ConfirmDialogContext';

function SecurityQuestionsInput(props) {
    const {
        label,
        values = [],
        onChange,
        InputProps,
        theme,
        style,
        error,
        errorMessage, //accepts a string or a function if you need to validate multiple rules
        helperText,
        disabled = false,
        setDisableSubmission = () => {},
        infoText = ''
    } = props;

    const [inputValue, setInputValue] = useState('');

    const duplicateValue = _.some(values, value => value === inputValue);

    const warnAction = useContext(ConfirmDialogContext);

    const handleAdd = () => {
        const newValues = [...values, { question: inputValue.trim(), hidden: false }];
        onChange(newValues);
        setInputValue('');
        setDisableSubmission(false);
    };

    const handleSetHidden = (i, checked) => {
        let valuesCopy = _.cloneDeep(values);
        _.set(valuesCopy, `[${i}].hidden`, checked);
        onChange(valuesCopy);
    };

    const handleRemove = i => {
        warnAction(() => {
            let valuesCopy = _.cloneDeep(values);
            valuesCopy.splice(i, 1);
            onChange(valuesCopy);
        }, `Are you sure you want to remove ${_.get(values, `[${i}].question`)}?`);
    };

    const handleKeyPress = e => {
        e = e || window.event;
        if (e.key === 'Enter' && !_.isEmpty(inputValue) && !duplicateValue && !error(inputValue)) {
            handleAdd();
        }
    };
    const handleChange = e => {
        setInputValue(e.target.value);
        if (setDisableSubmission) {
            setDisableSubmission(!_.isEmpty(e.target.value));
        }
    };

    const errorMessageVal = typeof errorMessage === 'function' ? errorMessage(inputValue) : errorMessage;

    return (
        <div style={style}>
            <TextField
                data-cy="string-list-input"
                onChange={handleChange}
                label={label}
                value={inputValue}
                margin="normal"
                fullWidth
                onKeyDown={handleKeyPress}
                error={error(inputValue) || duplicateValue}
                helperText={
                    (duplicateValue && `You already entered "${inputValue}"`) ||
                    (error(inputValue) && errorMessageVal) ||
                    (!error(inputValue) && helperText)
                }
                disabled={disabled}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Button
                                data-cy="string-list-input-add-button"
                                variant="contained"
                                color="primary"
                                onClick={handleAdd}
                                disabled={_.isEmpty(inputValue) || duplicateValue || error(inputValue)}
                            >
                                Add
                            </Button>
                            {!_.isEmpty(infoText) && (
                                <Tooltip
                                    title={infoText}
                                    data-cy="string-list-input-info-text"
                                    style={{ color: theme.palette.text.disabled, fontSize: 18 }}
                                >
                                    <Icon data-cy="string-list-input-info-icon">info</Icon>
                                </Tooltip>
                            )}
                        </InputAdornment>
                    )
                }}
                InputLabelProps={{
                    style: { maxWidth: '89%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }
                }}
                {...InputProps}
            />
            <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'column' }}>
                {values.map((value, i) => (
                    <Paper
                        key={i}
                        style={{
                            margin: theme.spacing.unit / 2,
                            padding: theme.spacing.unit / 2,
                            opacity: _.get(value, 'hidden', false) ? 0.7 : 1
                        }}
                    >
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <div>
                                <Typography
                                    style={{ padding: '3px' }}
                                    color={_.get(value, 'hidden', false) ? 'textSecondary' : 'textPrimary'}
                                >
                                    {_.get(value, 'question', '')}
                                </Typography>
                            </div>
                            <div>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            color="primary"
                                            onChange={e => handleSetHidden(i, e.target.checked)}
                                            value={_.get(value, 'hidden', false)}
                                            checked={_.get(value, 'hidden', false)}
                                        />
                                    }
                                    label={
                                        <Typography color="textSecondary">Inactive</Typography>
                                        /*<Icon>{_.get(value, 'hidden', false) ? 'visibility_off' : 'visibility'} </Icon>*/
                                    }
                                />
                                <IconButton
                                    style={{ padding: '2px', color: 'primary' }}
                                    onClick={() => handleRemove(i)}
                                >
                                    <Icon>clear</Icon>
                                </IconButton>
                            </div>
                        </div>
                    </Paper>
                ))}
            </div>
        </div>
    );
}

export default withTheme()(SecurityQuestionsInput);
