import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash';
import dot from 'dot-object';
import { _time } from 'std';
import { LOGGED_COLLECTIONS, ROLES } from '../../../constants';

import { withTheme } from '@material-ui/core/styles';
import {
    Icon,
    Paper,
    Grid,
    Typography,
    TextField,
    FormControl,
    InputLabel,
    Select,
    Button,
    MenuItem,
    OutlinedInput,
    CircularProgress
} from '@material-ui/core';

import useDateRangePicker from 'components/DateTimePickersTz/hooks/useDateRangePicker';
import DateRangePicker from 'components/DateTimePickersTz/DateRangePicker';

import LogCard from './LogCard';
import CustomDebugDialog from 'components/CustomDebugDialog/CustomDebugDialog';
import HttpContext from 'utils/contexts/HttpContext';

const Logs = props => {
    const http = useContext(HttpContext);
    const { theme, auth } = props;

    let defaultCollections = LOGGED_COLLECTIONS.filter(col => col !== 'trips');
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [query, setQuery] = useState({
        source: '',
        target: '',
        changed: '',
        collection: defaultCollections,
        operation: ['i', 'u', 'd'],
        systemEvents: 'show'
    });
    const [data, setData] = useState([]);
    const [pairedData, setPairedData] = useState([]);
    const [selectedLog, setSelectedLog] = useState();

    const {
        startDate,
        endDate,
        timezone,
        dateWindow,
        handleChangeStartDate,
        handleChangeEndDate,
        handleGoForwards,
        handleGoBackwards,
        handleWindowSelect
    } = useDateRangePicker({
        saveStateInURL: true,
        timezones: [process.env.REACT_APP_REGION_TIMEZONE],
        initStartVal: _time.getStartOfWeek(process.env.REACT_APP_REGION_TIMEZONE),
        initEndVal: _time.getEndOfDay(process.env.REACT_APP_REGION_TIMEZONE)
    });

    const getLogs = async () => {
        let qs = '';
        for (let key in query) {
            if (qs !== '') {
                qs += '&';
            }
            qs += key + '=' + encodeURIComponent(query[key]);
        }

        const res = await http.getJSON(
            `/logs?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}&page=${page}&${qs}`
        );

        if (res.ok) {
            setData(res.data);
        }
    };

    const pairLogs = () => {
        setPairedData([]);
        for (let i = 0; i < data.length; i++) {
            const curr = _.cloneDeep(data[i]);

            // If the operation is not insert, find the previous copy of the document
            if (curr.o !== 'i') {
                const prev = _.find(data, { i: curr.i }, i + 1);

                // Copy target from previous log
                if (curr.t === null) {
                    curr.t = _.get(prev, 't', null);
                    curr.target = _.get(prev, 'target', null);
                }

                const dotPaths = dot.dot(_.get(curr, 'u.updatedFields', {}));
                for (let path in dotPaths) {
                    _.set(curr, `prev.${path}`, _.get(prev, `d.${path}`));
                }

                // If the operation is delete, get the last recorded state of the object
                if (curr.o === 'd') {
                    _.set(curr, 'prev', _.get(prev, 'd', {}));
                }
            }

            setPairedData(prevState => [...prevState, curr]);
        }
        setLoading(false);
    };

    const handleSearch = () => {
        setLoading(true);
        getLogs();
    };

    const handleChange = e => {
        setQuery({ ...query, [e.target.name]: e.target.value });
    };

    const handlePrev = () => {
        setPage(page - 1);
        setLoading(true);
    };

    const handleNext = () => {
        setPage(page + 1);
        setLoading(true);
    };

    // Fetch all log data
    useEffect(() => {
        setLoading(true);
        getLogs();
    }, [page, startDate, endDate, timezone, dateWindow]);

    // Pair logs to view differences
    useEffect(() => {
        pairLogs();
    }, [data]);

    const inputStyle = { marginRight: theme.spacing.unit, width: 175, minWidth: 100, maxWidth: 225 };

    const buttonStyle = { marginTop: theme.spacing.unit, marginBottom: theme.spacing.unit, textAlign: 'right' };

    if (auth.accountType !== 'System Administrator' && !ROLES.includes(auth.accountType))
        return <div>Unauthorized</div>;

    return (
        <Grid>
            <Grid item xs={12}>
                <div style={{ padding: theme.spacing.unit * 2, paddingBottom: theme.spacing.unit }}>
                    <Paper
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            padding: theme.spacing.unit * 2,
                            height: '100%',
                            flexWrap: 'wrap'
                        }}
                    >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <DateRangePicker
                                timezone={timezone}
                                startDate={startDate}
                                endDate={endDate}
                                window={dateWindow}
                                disabled={loading}
                                handlePrevious={handleGoBackwards}
                                handleNext={handleGoForwards}
                                handleChangeStartDate={handleChangeStartDate}
                                handleChangeEndDate={handleChangeEndDate}
                            />
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexWrap: 'wrap'
                            }}
                        >
                            <FormControl>
                                <Select
                                    value={dateWindow}
                                    disabled={loading}
                                    onChange={handleWindowSelect}
                                    style={{
                                        maxWidth: '250px',
                                        margin: theme.spacing.unit
                                    }}
                                >
                                    <MenuItem value="today">Daily</MenuItem>
                                    <MenuItem value="thisWeek">Weekly</MenuItem>
                                    <MenuItem value="thisMonth">Monthly</MenuItem>
                                    <MenuItem value="thisQuarter">Quarterly</MenuItem>
                                    <MenuItem value="allTime">All Time</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </Paper>
                </div>
            </Grid>

            <Grid item xs={12}>
                <div
                    style={{
                        padding: theme.spacing.unit,
                        paddingLeft: theme.spacing.unit * 2,
                        paddingRight: theme.spacing.unit * 2
                    }}
                >
                    <Paper
                        style={{
                            padding: theme.spacing.unit * 2,
                            height: '100%'
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                flexWrap: 'wrap',
                                alignItems: 'center'
                            }}
                        >
                            <Typography variant="h5">Search Logs</Typography>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    paddingLeft: theme.spacing.unit / 2,
                                    paddingRight: theme.spacing.unit / 2,
                                    flexWrap: 'wrap',
                                    alignItems: 'center'
                                }}
                            >
                                <TextField
                                    data-cy="logs-source-input"
                                    value={query.source}
                                    type="search"
                                    name="source"
                                    variant="outlined"
                                    label="Source"
                                    onChange={handleChange}
                                    style={inputStyle}
                                />
                                <TextField
                                    data-cy="logs-target-input"
                                    value={query.target}
                                    type="search"
                                    name="target"
                                    variant="outlined"
                                    label="Target"
                                    onChange={handleChange}
                                    style={inputStyle}
                                />
                                <TextField
                                    data-cy="logs-changed-input"
                                    value={query.changed}
                                    type="search"
                                    name="changed"
                                    variant="outlined"
                                    label="Changed"
                                    onChange={handleChange}
                                    style={inputStyle}
                                />
                                <FormControl variant="outlined">
                                    <InputLabel htmlFor="collection">Collection</InputLabel>
                                    <Select
                                        multiple
                                        input={<OutlinedInput id="collection" name="collection" labelWidth={75} />}
                                        value={query.collection}
                                        onChange={handleChange}
                                        style={{ ...inputStyle, width: 125 }}
                                    >
                                        {LOGGED_COLLECTIONS.map(collection => (
                                            <MenuItem key={collection} value={collection}>
                                                {collection.replace(/^\w/, c => c.toUpperCase())}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl variant="outlined">
                                    <InputLabel htmlFor="operation">Operation</InputLabel>
                                    <Select
                                        multiple
                                        inputProps={{ id: 'operation', name: 'operation' }}
                                        input={<OutlinedInput id="operation" name="operation" labelWidth={75} />}
                                        value={query.operation}
                                        onChange={handleChange}
                                        style={{ ...inputStyle, width: 125 }}
                                    >
                                        <MenuItem value="i">Insert</MenuItem>
                                        <MenuItem value="u">Update</MenuItem>
                                        <MenuItem value="d">Delete</MenuItem>
                                        <MenuItem value="l">Login</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl variant="outlined">
                                    <InputLabel htmlFor="systemEvents">System Events</InputLabel>
                                    <Select
                                        inputProps={{ id: 'systemEvents', name: 'systemEvents' }}
                                        input={<OutlinedInput id="systemEvents" name="systemEvents" labelWidth={105} />}
                                        value={query.systemEvents}
                                        onChange={handleChange}
                                        style={{ ...inputStyle, width: 125 }}
                                        disabled={!_.isEmpty(query.source)}
                                    >
                                        <MenuItem value="show">Show</MenuItem>
                                        <MenuItem value="hide">Hide</MenuItem>
                                        <MenuItem value="only">Only</MenuItem>
                                    </Select>
                                </FormControl>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    data-cy="config-logs-search-button"
                                    type="submit"
                                    onClick={handleSearch}
                                >
                                    <Icon>search</Icon>
                                </Button>
                            </div>
                        </div>
                    </Paper>
                </div>
            </Grid>
            <Grid item xs={12}>
                {!_.isEmpty(pairedData) ? (
                    <div
                        style={{
                            padding: theme.spacing.unit,
                            paddingLeft: theme.spacing.unit * 2,
                            paddingRight: theme.spacing.unit * 2
                        }}
                    >
                        <Paper style={{ padding: theme.spacing.unit * 2 }}>
                            {loading ? (
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <CircularProgress />
                                </div>
                            ) : (
                                <div style={{ overflowY: 'scroll', maxHeight: '70vh' }}>
                                    {pairedData.map((log, i) => {
                                        return <LogCard key={i} setSelectedLog={setSelectedLog} data={log} />;
                                    })}
                                </div>
                            )}
                            <div style={buttonStyle}>
                                <Button onClick={handlePrev} disabled={page === 0}>
                                    Prev
                                </Button>
                                <Button onClick={handleNext}>Next</Button>
                            </div>
                        </Paper>
                    </div>
                ) : (
                    loading && (
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <CircularProgress />
                        </div>
                    )
                )}
            </Grid>
            <CustomDebugDialog open={!_.isNil(selectedLog)} json={selectedLog} onClose={() => setSelectedLog()} />
        </Grid>
    );
};

export default withTheme()(Logs);
