import React, { useState } from 'react';
import _ from 'lodash';

function useFilterSidePanel(props) {
    const { onFilter } = props;

    const [open, setOpen] = useState(false);
    const [overlay, setOverlay] = useState(false);

    const handleFilter = () => {
        if (overlay) setOpen(false);

        onFilter();
    };

    const handleFilterPanelOpen = (state = !open) => {
        setOpen(state);
    };

    const toggleOverlay = () => {
        setOverlay(!overlay);
    };

    return { open, overlay, toggleOverlay, handleFilter, handleFilterPanelOpen };
}

export default useFilterSidePanel;
