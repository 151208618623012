import React from 'react';

import { getCustomerFirstNameAndLastInitial } from 'utils/misc';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { withTheme } from '@material-ui/core';

function BulkReceiverSelectionDropdown({
    theme,
    fullWidth,
    helperText,
    loading,
    bulkReceiverFilter,
    handleChange,
    labelCustomWidth,
    receivers,
    showAll = true,
    gutterTop = false
}) {
    return (
        <FormControl fullWidth={fullWidth} style={{ marginRight: 8, marginTop: gutterTop ? theme.spacing.unit : 0 }}>
            <InputLabel variant="outlined" htmlFor="receiver-filter">
                {helperText}
            </InputLabel>
            <Select
                data-cy="bulk-type-selection-dropdown"
                value={loading ? 'loading' : bulkReceiverFilter}
                onChange={handleChange}
                disabled={loading}
                inputProps={{
                    name: 'receiver-filter',
                    id: 'receiver-filter'
                }}
                input={<OutlinedInput labelWidth={labelCustomWidth || 36} name="receiver" id="outlined-age-simple" />}
                style={{
                    minWidth: 168
                }}
            >
                {loading && (
                    <MenuItem value="loading">
                        <em>Loading...</em>
                    </MenuItem>
                )}
                {showAll && (
                    <MenuItem value="all">
                        <em>All</em>
                    </MenuItem>
                )}
                {receivers.map(r => {
                    return (
                        <MenuItem value={r._id} key={r._id}>
                            <span style={{ color: theme.palette.linkColor }}>{r.numVal || '0'}</span> —{' '}
                            <em> {getCustomerFirstNameAndLastInitial(r)}</em>
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
}

export default withTheme()(BulkReceiverSelectionDropdown);
