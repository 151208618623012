import React from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';

import { withTheme } from '@material-ui/core/styles';
import { Popover, Typography, Divider, Icon } from '@material-ui/core';

import { formatAsPhoneNumber } from 'utils/misc';

function PhoneNumberPopover({
    theme,
    debug,
    anchorEl,
    phone,
    email,
    startTime,
    timezone,
    driverEfficiency,
    stopBaseDuration,
    onClose
}) {
    const callNumber = () => {
        window.open(`tel:${process.env.REACT_APP_COUNTRY_CALLING_CODE}${phone}`);
    };

    const sendEmail = () => {
        window.open(`mailto:${email}`);
    };

    const iconStyle = { marginRight: theme.spacing.unit, fontSize: 18 };
    const textStyle = { fontSize: 12, color: theme.palette.linkColor, cursor: 'pointer' };

    return (
        <Popover
            open={!_.isNil(anchorEl)}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
            }}
            style={{ padding: theme.spacing.unit }}
            onClose={onClose}
        >
            <div style={{ padding: theme.spacing.unit }}>
                <Typography varinat="subtitle1" gutterBottom>
                    Contact Info
                </Typography>
                <Divider style={{ marginBottom: theme.spacing.unit }} />
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <Icon color="action" style={iconStyle}>
                                    phone
                                </Icon>
                            </td>
                            <td>
                                <Typography
                                    style={textStyle}
                                    onClick={callNumber}
                                    data-cy="summary-pickup-subwidget-text"
                                >
                                    {formatAsPhoneNumber(phone)}
                                </Typography>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <Icon color="action" style={iconStyle}>
                                    email
                                </Icon>
                            </td>
                            <td>
                                <Typography style={textStyle} onClick={sendEmail} variant="caption">
                                    {email}
                                </Typography>
                            </td>
                        </tr>

                        {startTime && (
                            <tr>
                                <td>
                                    <Icon color="action" style={iconStyle}>
                                        schedule
                                    </Icon>
                                </td>
                                <td>
                                    <Typography variant="caption">
                                        Start time:{' '}
                                        {moment(startTime)
                                            .tz(timezone)
                                            .format('h:mm A')}
                                    </Typography>
                                </td>
                            </tr>
                        )}
                        {debug && (
                            <tr>
                                <td>
                                    <Icon color="action" style={iconStyle}>
                                        av_timer
                                    </Icon>
                                </td>
                                <td>
                                    <Typography variant="caption">
                                        Base Duration: {stopBaseDuration ? _.round(stopBaseDuration, 2) + 's' : 'N/A'}
                                    </Typography>
                                </td>
                            </tr>
                        )}
                        {debug && (
                            <tr>
                                <td>
                                    <Icon color="action" style={iconStyle}>
                                        timer
                                    </Icon>
                                </td>
                                <td>
                                    <Typography variant="caption">
                                        Time per bag: {driverEfficiency ? _.round(driverEfficiency, 2) + 's' : 'N/A'}
                                    </Typography>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </Popover>
    );
}

export default withTheme()(PhoneNumberPopover);
