import { loc } from '../../../localizations/localizationHandler';

export const MODES = {
    ADMIN: {
        index: 0,
        message: `Looks like you already know what you're doing!`,
        icon: 'developer_mode'
    },
    PICKUP_SELECT: {
        index: 1,
        message: 'selectHowToManage',
        icon: 'ballot',
        isLoc: true
    },
    PICKUP: {
        index: 2,
        message: 'proceedToNextPickup',
        icon: 'directions_car',
        isLoc: true
    },
    SELECT: {
        index: 3,
        message: 'yourNextPickup',
        icon: 'ballot',
        isLoc: true
    },
    DROPOFF: {
        index: 4,
        message: 'proceedToYourNextDropZone',
        icon: 'directions_car',
        isLoc: true
    },
    START_ROUTE: {
        index: 5,
        message: 'proceedToYourStartingLocation',
        icon: 'directions_car',
        isLoc: true
    },
    LUNCH: {
        index: 6,
        message: 'timeRemainingForLunch',
        icon: 'directions_car',
        isLoc: true
    }
};

export const DRIVER_REASONS_AVAILABLE = [
    {
        _id: 1, // Used internally to distinguish without string comparison
        short: { en: 'No bags found / Not refundable', fr: 'Aucun sac trouvé / Non remboursable' },
        description: {
            en: 'we had troubles finding your bags',
            fr: 'nous avons eu des difficultés à trouver vos sacs'
        },
        userFault: true
    },
    {
        _id: 2,
        short: { en: 'Could not find / access location', fr: `Impossible de trouver / accéder à l'emplacement` },
        description: {
            en: 'we had troubles accessing your bags',
            fr: `nous avons eu des difficultés à accéder à vos sacs`
        },
        userFault: true
    },
    {
        _id: 3,
        short: { en: 'Bad GPS - Location too far away', fr: `Mauvais GPS - Emplacement trop éloigné` },
        description: {
            en: 'we had troubles getting to your location',
            fr: `nous avons eu des difficultés à atteindre votre emplacement`
        },
        userFault: true
    },
    {
        _id: 4,
        short: { en: 'Hazardous', fr: 'Dangereux' },
        description: {
            en: 'we were unable to pickup your bags',
            fr: `nous n'avons pas pu ramasser vos sacs`
        },
        userFault: true
    }
];

export const ADMIN_REASONS_AVAILABLE = DRIVER_REASONS_AVAILABLE.concat([
    {
        _id: 5,
        short: { en: 'Reschedule', fr: 'Replanifier' },
        description: { en: 'we have rescheduled your pickup', fr: 'nous avons replanifié votre ramassage' },
        userFault: false
    }
]);

export const MODIFIERS = ['confirmed', 'unconfirmed', 'bypassZoneChecks', 'highPriority', 'alwaysBypassZoneChecks'];
