import React, { useState, useEffect, useContext } from 'react';

import _ from 'lodash';

import {
    Dialog,
    DialogActions,
    DialogContent,
    Button,
    DialogContentText,
    LinearProgress,
    TextField,
    Icon
} from '@material-ui/core';
import DialogTitlePrimary from 'components/MaterialUIExtensions/DialogTitlePrimary';
import { withTheme } from '@material-ui/core/styles';
import withMobileDialog from '@material-ui/core/withMobileDialog';

import ImageCapture from 'components/ImageUploads/ImageCapture';
import useImageUpload from 'components/ImageUploads/useImageUpload';

import { validate } from 'utils/validate';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

function VerificationDialog(props) {
    const { open, onClose, onSubmit, task, http, loading, setLoading, theme, fullScreen, onSnackbar } = props;

    const { lang } = useContext(LocalizationContext);

    const [step, setStep] = useState(0);
    const [verificationMethod, setVerificationMethod] = useState(null);

    const [verificationLink, setVerificationLink] = useState('');
    const [verificationLinkTouched, setVerificationLinkTouched] = useState(false);

    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');

    const { imageURLs, imagePreviews, uploadingImage, handleDeleteImage, handleAddImage } = useImageUpload({});

    useEffect(() => {
        if (verificationMethod === 'photo') {
            setError(_.isEmpty(imageURLs));
        } else {
            const validation = validate(['url'], verificationLink, lang);
            setError(validation.fail);
            setErrorMsg(validation.reason);
        }
    }, [imageURLs, verificationLink, verificationMethod]);

    const handlePhotoVerification = () => {
        setStep(1);
        setVerificationMethod('photo');
    };

    const handleLinkVerification = () => {
        setStep(1);
        setVerificationMethod('link');
    };

    const handleSubmit = async () => {
        if (verificationMethod === 'photo') {
            setLoading(true);
            const formData = new FormData();
            imageURLs.forEach(imageURL => {
                formData.append('images', imageURL);
            });
            const res = await http.postJSON(`/system/uploadMultipleImages`, formData, false, true);
            const images = _.get(res, 'data.imageUrls', []);

            onSubmit({ link: '', images, verificationType: verificationMethod });

            setLoading(false);
        } else {
            onSubmit({ link: verificationLink, images: [], verificationType: verificationMethod });
        }
    };

    const verificationForms = {
        link: open && (
            <>
                <DialogContentText>{loc('growthGeneral34', lang)}</DialogContentText>
                <TextField
                    data-cy="verification-dialog-url-input"
                    value={verificationLink}
                    onChange={event => {
                        setVerificationLink(event.target.value);
                        setVerificationLinkTouched(true);
                    }}
                    error={verificationLinkTouched && error}
                    label="URL"
                    margin="normal"
                    variant="outlined"
                    helperText={verificationLinkTouched && error ? errorMsg : ''}
                    fullWidth
                />
            </>
        ),
        photo: open && (
            <div style={{ marginTop: theme.spacing.unit * 2 }}>
                <ImageCapture
                    uploadingImage={uploadingImage}
                    imageURLs={imagePreviews}
                    handleAddImage={handleAddImage}
                    handleDeleteImage={handleDeleteImage}
                    placeholderText={loc('growthGeneral35', lang)}
                    onSnackbar={onSnackbar}
                />
            </div>
        )
    };

    const verificationType = _.get(task, 'taskVerificationType', 'Both');

    const form = verificationForms[verificationMethod];

    const screens = [
        <>
            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                <span
                    style={{
                        display: 'flex',
                        alignItems: 'stretch',
                        flexDirection: 'column',
                        marginRight: theme.spacing.unit * 4,
                        marginLeft: theme.spacing.unit * 4
                    }}
                >
                    <DialogContentText style={{ marginBottom: theme.spacing.unit * 2 }}>
                        {loc('growthVerifyTask2', lang, {
                            obj:
                                verificationType === 'Link'
                                    ? loc('growthVerifyTask3', lang)
                                    : verificationType === 'Photo'
                                    ? loc('growthVerifyTask4', lang)
                                    : loc('growthVerifyTask5', lang)
                        })}
                        :
                    </DialogContentText>
                    {(verificationType === 'Both' || verificationType === 'Photo') && (
                        <Button
                            data-cy="verification-dialog-photo-btn"
                            variant="outlined"
                            color="primary"
                            onClick={handlePhotoVerification}
                            style={{ marginTop: theme.spacing.unit * 2 }}
                        >
                            {loc('growthGeneral36', lang)}
                            <Icon style={{ marginLeft: theme.spacing.unit }}>photo</Icon>
                        </Button>
                    )}
                    {(verificationType === 'Both' || verificationType === 'Link') && (
                        <Button
                            data-cy="verification-dialog-link-btn"
                            variant="outlined"
                            color="primary"
                            onClick={handleLinkVerification}
                            style={{ marginTop: theme.spacing.unit * 2 }}
                        >
                            {loc('growthVerifyTask3', lang)}
                            <Icon style={{ marginLeft: theme.spacing.unit }}>link</Icon>
                        </Button>
                    )}
                </span>
            </div>
        </>,
        form
    ];

    const screen = screens[step];

    return (
        <Dialog open={open} onClose={onClose} fullScreen={fullScreen} fullWidth maxWidth={'sm'} scroll="paper">
            <DialogTitlePrimary closeButtonShown={true} onClose={onClose}>
                <span style={{ marginLeft: theme.spacing.unit * 2 }}>{loc('growthVerifyTask1', lang)}</span>
            </DialogTitlePrimary>
            <DialogContent>
                <div style={{ marginTop: theme.spacing.unit * 2 }}>{screen}</div>
                {loading && <LinearProgress />}
            </DialogContent>
            <DialogActions>
                {step === 1 && (
                    <>
                        <Button onClick={() => setStep(0)}>Back</Button>
                        <Button
                            color="primary"
                            onClick={handleSubmit}
                            disabled={error}
                            data-cy="verification-dialog-submit"
                        >
                            {loc('submit', lang)}
                        </Button>
                    </>
                )}
                {step === 0 && (
                    <>
                        <Button onClick={onClose}>close</Button>
                    </>
                )}
            </DialogActions>
        </Dialog>
    );
}

export default withMobileDialog({ breakpoint: 'sm' })(withTheme()(VerificationDialog));
