import React, { useContext } from 'react';

import { Typography, withTheme } from '@material-ui/core';

import SendContactEmailComponent from '../Components/SendContactEmailComponent';
import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

function SendContactEmailDisplay({ baseMessage, reason, theme }) {
    const { lang } = useContext(LocalizationContext);
    return (
        <>
            <Typography variant="body1" style={{ marginBottom: theme.spacing.unit }}>
                {loc('growthGeneral30', lang)}
            </Typography>
            <SendContactEmailComponent reason={reason} baseMessage={baseMessage} />
        </>
    );
}

export default withTheme()(SendContactEmailDisplay);
