import _ from 'lodash';

export const getPhoneNumberFormatted = user => {
    const phoneNumber = _.get(user, 'phone', '9999999999');
    let s2 = ('' + phoneNumber).replace(/\D/g, '');
    let m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
    return !m ? null : '(' + m[1] + ') ' + m[2] + '-' + m[3];
};

export const isAllowedToDebug = user => {
    return _.get(user, 'isAllowedToDebug', false);
};

export const isDriver = user => {
    return user.accountType === 'Driver';
};

export const hasSubscriptions = user => {
    return !_.isEmpty(user.recurringFees);
};

export const isSystemAdmin = user => {
    if (_.isNil(user)) return false;
    return user.accountType === 'System Administrator';
};

export const isInternalRole = user => {
    if (_.isNil(user)) return false;
    return [
        'Customer Service',
        'Customer Service Administrator',
        'Finance Administrator',
        'Marketing',
        'Manager',
        'IT Administrator'
        // 'New Role'
    ].includes(user.accountType);
};

export const isCustomerService = user => {
    if (_.isNil(user)) return false;
    return user.accountType === 'Customer Service';
};

export const isCustomerServiceAdministrator = user => {
    if (_.isNil(user)) return false;
    return user.accountType === 'Customer Service Administrator';
};

export const isFinanceAdministrator = function(user) {
    if (_.isNil(user)) return false;
    return user.accountType === 'Finance Administrator';
};
export const isMarketing = function(user) {
    if (_.isNil(user)) return false;
    return user.accountType === 'Marketing';
};

export const isManager = function(user) {
    if (_.isNil(user)) return false;
    return user.accountType === 'Manager';
};

export const isITAdministrator = function(user) {
    if (_.isNil(user)) return false;
    return user.accountType === 'IT Administrator';
};

// export const isNewRole = user => {
//     if (_.isNil(user)) return false;
//     return user.accountType === 'New Role';
// };
