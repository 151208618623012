import React, { Component, useContext } from 'react';

import _ from 'lodash';
import moment from 'moment-timezone';

import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import { withTheme } from '@material-ui/core/styles';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc, locDate } from '../../../localizations/localizationHandler';

function AbortedPickupSubwidget({ theme, pickup, clicksAllowed, onGoToHistory }) {
    const { lang } = useContext(LocalizationContext);
    return (
        <div
            id="aborted-pickup-subwidget"
            data-cy="aborted-pickup-subwidget"
            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
        >
            <div style={{ paddingLeft: theme.spacing.unit * 2, paddingRight: theme.spacing.unit * 2 }}>
                <Typography>
                    {loc('pickupWidgets25', lang, {
                        date: locDate(
                            pickup.date,
                            'MMM Do',
                            lang,
                            _.get(pickup, 'zone.timezone', process.env.REACT_APP_REGION_TIMEZONE)
                        )
                    })}
                </Typography>
            </div>
            <div
                style={{
                    borderLeft: '1px solid ' + theme.palette.text.hint,
                    paddingLeft: theme.spacing.unit,
                    paddingRight: theme.spacing.unit
                }}
            >
                <IconButton disabled={!clicksAllowed} onClick={onGoToHistory}>
                    <Icon>history</Icon>
                </IconButton>
            </div>
        </div>
    );
}

export default withTheme()(AbortedPickupSubwidget);
