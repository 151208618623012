import React, { useState, useContext } from 'react';
import _ from 'lodash';

import {
    Icon,
    Dialog,
    DialogActions,
    DialogContent,
    withMobileDialog,
    Button,
    withTheme,
    Table,
    TableHead,
    TableRow,
    TableCell,
    DialogTitle
} from '@material-ui/core';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

function IntegrationPermissionsDialog(props) {
    const { open, permissionsDescriptions, syncConfiguration, integrations, fullScreen, theme, onClose } = props;

    const { lang } = useContext(LocalizationContext);

    const rowNameStyles = {
        paddingLeft: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        width: 150
    };
    const tableCellStyles = {
        paddingLeft: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        textAlign: 'center',
        width: 80
    };

    return (
        <Dialog open={open} fullScreen={fullScreen} onClose={onClose} fullWidth>
            <DialogTitle>Integration Permissions</DialogTitle>
            <DialogContent>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell style={rowNameStyles} />
                            {integrations.map(integration => {
                                return <TableCell style={tableCellStyles}>{integration.name}</TableCell>;
                            })}
                        </TableRow>
                    </TableHead>

                    {Object.keys(permissionsDescriptions).map(key => (
                        <TableRow>
                            <TableCell style={rowNameStyles}>{permissionsDescriptions[key]}</TableCell>
                            {integrations.map(integration => {
                                const permission = _.get(integration, `permissions.${key}`, false);
                                return (
                                    <TableCell style={tableCellStyles}>
                                        <Icon style={{ color: permission ? 'green' : 'red' }}>
                                            {permission ? 'check' : 'cancel'}
                                        </Icon>
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    ))}
                    <TableRow>
                        <TableCell style={rowNameStyles}>Sync</TableCell>
                        {integrations.map(integration => {
                            const canSync = integration._id === _.get(syncConfiguration, 'integrationId');
                            return (
                                <TableCell style={tableCellStyles}>
                                    <Icon style={{ color: canSync ? 'green' : 'red' }}>
                                        {canSync ? 'check' : 'cancel'}
                                    </Icon>
                                </TableCell>
                            );
                        })}
                    </TableRow>
                </Table>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>{loc('close', lang)}</Button>
            </DialogActions>
        </Dialog>
    );
}

export default withMobileDialog()(withTheme()(IntegrationPermissionsDialog));
