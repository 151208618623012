import React, { useEffect, useState } from 'react';

import _ from 'lodash';
import io from 'socket.io-client';

const pathExtension = process.env.REACT_APP_ENV !== 'LOCAL' ? '/api' : ''; // connect through Nginx if not localhost

function useLiveCountsSocketListener() {
    const [socket, setSocket] = useState(null);
    const [bulk, setBulk] = useState(undefined);

    useEffect(() => {
        console.log(`%cuseLiveCountsSocketListenerIO: opening socket connection`, 'color: blue');
        try {
            const originURL =
                process.env.REACT_APP_ENV !== 'LOCAL'
                    ? process.env.REACT_APP_ORIGIN_URL
                    : process.env.REACT_APP_ORIGIN_URL.replace(/.$/, '1');

            const socket = io(`${originURL}/liveCounts`, {
                path: `${pathExtension}/socket.io`
            });

            setSocket(socket);

            socket.emit('socket-ready', { type: 'LISTENER' });

            socket.on('bulk-updated', res => {
                const { bulk } = res;
                setBulk(bulk);
            });

            return () => {
                if (!_.isNil(socket)) {
                    console.log(`%cclosed socket connection`, 'color: blue');
                    socket.close();
                }
            };
        } catch (err) {
            console.log(err);
        }
    }, []);

    return {
        bulk
    };
}

export default useLiveCountsSocketListener;
