import React, { useState } from 'react';
import { Clipboard } from '@capacitor/clipboard';
import _ from 'lodash';

import { Paper, withTheme, LinearProgress, Collapse, Typography, IconButton, Icon } from '@material-ui/core';

import { XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, BarChart, Bar, Cell } from 'recharts';
import useWindowSize from 'utils/hooks/useWindowSize';

function StatisticsChart(props) {
    const [width, height] = useWindowSize();
    let mobileMode = width <= 600;
    const { data, dataLoading, bar, title = 'Bar Chart', YAxisProps = {}, theme, handleDialogOpen, question } = props;

    const otherData = _.find(data, responseType => responseType.label === 'Other');
    const showOtherCopy = !_.isNil(otherData) && otherData.count > 1;
    const handleCopyOtherToClipboard = () => {
        if (_.isNil(otherData)) {
            return;
        }
        let string = '';
        for (let breakdownItem of _.get(otherData, 'otherBreakdown', [])) {
            string += `${_.get(breakdownItem, 'customResponse')}: ${_.get(breakdownItem, 'count')}\n`;
        }
        Clipboard.write({
            string
        });
        // onSnackbar(loc('charitySnackbar5', lang));
    };

    return (
        <Paper
            style={{ padding: theme.spacing.unit * 2, paddingBottom: 0, width: '100%' }}
            data-cy={`${title}-chart-container`}
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: theme.spacing.unit * 2
                }}
            >
                <div style={{ display: 'flex', alignItems: 'left' }}>
                    <Typography variant="h6" style={{ margin: theme.spacing.unit }}>
                        {title}
                    </Typography>
                </div>
                <div style={{ textAlign: 'right' }}>
                    <IconButton onClick={() => handleDialogOpen(question)} data-cy="audience-chart-filter">
                        <Icon>filter_list</Icon>
                    </IconButton>
                    {showOtherCopy && (
                        <IconButton onClick={handleCopyOtherToClipboard}>
                            <Icon>file_copy</Icon>
                        </IconButton>
                    )}
                </div>
            </div>

            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    overflow: 'hidden',
                    flexDirection: mobileMode ? 'column' : 'row'
                }}
            >
                <ResponsiveContainer width="95%" height={600} minWidth={200}>
                    <BarChart data={data} style={{ fontFamily: 'Roboto' }} maxBarSize={70}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="label" />
                        <YAxis type="number" tickCount={8} allowDataOverflow={true} {...YAxisProps} />
                        <Tooltip wrapperStyle={{ pointerEvents: 'auto' }} content={AudienceCaptureToolTip} />
                        <Bar dataKey={bar.dataKey} name={bar.name} fill={bar.fill}>
                            {data.map((entry, index) => (
                                <Cell key={`cell-${index}`} role={`bar-${_.get(entry, 'label')}`} />
                            ))}
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
            </div>
            <div style={{ paddingTop: theme.spacing.unit * 2 }}>{dataLoading && <LinearProgress />}</div>
        </Paper>
    );
}

export default withTheme()(StatisticsChart);

function AudienceCaptureToolTip(props) {
    if (props.payload && props.payload.length > 0) {
        const label = _.get(props, 'payload[0].payload.label', null);
        return (
            <div
                data-cy="graph-tooltip"
                style={{
                    border: '1px solid lightgray',
                    backgroundColor: 'white',
                    padding: '5px 10px',
                    maxWidth: 300,
                    maxHeight: 550,
                    overflowY: 'scroll'
                }}
            >
                {!_.isNil(label) && (
                    <span
                        data-cy="tooltip-label"
                        style={{
                            paddingBottom: '4px',
                            paddingTop: '8px'
                        }}
                    >
                        {label}
                    </span>
                )}

                {props.payload.map(item => {
                    const { name, value, color, dataKey, payload, response } = item;
                    const otherBreakdown = _.get(payload, 'otherBreakdown');
                    if (_.get(payload, 'response') === 'OTHER') {
                        return (
                            <div style={{ paddingBottom: '4px', paddingTop: '4px' }}>
                                <span data-cy={`tooltip-${dataKey}`} style={{ color: color }}>
                                    {name}: {value}
                                </span>
                                {!_.isNil(otherBreakdown) &&
                                    otherBreakdown.map(breakdownItem => (
                                        <div
                                            style={{ paddingLeft: '12px' }}
                                            key={_.get(breakdownItem, 'customResponse')}
                                        >
                                            <span style={{ color: color }}>
                                                - {_.get(breakdownItem, 'customResponse')}:{' '}
                                                {_.get(breakdownItem, 'count')}
                                            </span>
                                        </div>
                                    ))}
                            </div>
                        );
                    } else {
                        return (
                            <div style={{ paddingBottom: '4px', paddingTop: '4px' }}>
                                <span data-cy={`tooltip-${dataKey}`} style={{ color: color }}>
                                    {name}: {value}
                                </span>
                            </div>
                        );
                    }
                })}
            </div>
        );
    } else {
        return <span data-cy="empty-tooltip" />;
    }
}
