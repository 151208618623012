import React, { useState, useRef, useEffect, useContext } from 'react';

import _ from 'lodash';

import { getMapCenter } from 'helpers/mapHelper';
import { getQueryStringValue } from 'utils/query';

import { wait } from 'utils/misc';
import * as mapThemes from 'components/LargeMap/themes';

import { withTheme } from '@material-ui/core/styles';

import usePrevious from 'utils/hooks/usePrevious';

import { createMarker } from 'components/LargeMap/functions.js';

import GoogleContext from 'utils/contexts/GoogleContext';
import { isValidGps } from 'utils/latlngFunctions';

const mapThemeLight = mapThemes.defaultLight;
const mapThemeDark = mapThemes.dark;

const GPSSSelectMap = React.memo(
    props => {
        const {
            theme,
            mapCenter,
            manualGPSEdit,
            setManualGPSEdit,
            mapLocationSelected,
            setMapLocationSelected,
            defaultCoordinates
        } = props;

        const { google } = useContext(GoogleContext);
        const mapElemRef = useRef();

        const prevProps = usePrevious(props);

        const [map, setMap] = useState();
        const [maxZoom, setMaxZoom] = useState(20);

        useEffect(() => {
            const asyncFunc = async () => {
                let center;
                let lat;
                let lng;

                if (_.isNil(mapCenter)) {
                    lat = parseFloat(getQueryStringValue('lat'));
                    lng = parseFloat(getQueryStringValue('lng'));
                } else {
                    lat = _.get(mapCenter, 'lat');
                    lng = _.get(mapCenter, 'lng');
                }

                if (!isValidGps(lat, lng)) {
                    center = {
                        lat: _.get(defaultCoordinates, 'latitude'),
                        lng: _.get(defaultCoordinates, 'longitude')
                    };
                } else {
                    center = { lat, lng };
                }

                const map = new google.maps.Map(mapElemRef.current, {
                    styles: theme.palette.type === 'light' ? mapThemeLight : mapThemeDark,
                    center,
                    visualRefresh: true,
                    mapTypeControl: false,
                    streetViewControl: false,
                    mapTypeId: 'hybrid',
                    minZoom: 2,
                    maxZoom: maxZoom,
                    disableDefaultUI: true,
                    clickableIcons: false, // stops POI info windows
                    restriction: { latLngBounds: { north: 85, south: -85, west: -180, east: 180 } } // only limits north/south bounds
                });

                map.setTilt(0);
                map.setZoom(11);

                let maxZoomService = new google.maps.MaxZoomService();

                maxZoomService.getMaxZoomAtLatLng(center, result => {
                    if (result.status !== 'OK') {
                        // Max zoom service didn't reply. Try to zoom in for customer by default
                        map.setZoom(17);
                        setMaxZoom(17);
                    } else {
                        if (result.zoom >= 18) {
                            map.setZoom(18);
                        } else {
                            map.setZoom(result.zoom);
                        }

                        setMaxZoom(result.zoom);
                    }

                    map.addListener('zoom_changed', function() {
                        if (map.getZoom() > maxZoom) {
                            map.setZoom(maxZoom);
                        }
                        const position = map.getCenter();
                        const latlng = {
                            lat: position.lat() % 90,
                            lng: position.lng() % 180
                        };
                        setMapLocationSelected(latlng);
                    });
                });

                setMapLocationSelected(center);
                createMarker(mapCenter, 'customerSitePickup', null, map, google, undefined, { width: 32, height: 42 });

                map.addListener(
                    'dragend',
                    _.throttle(async () => {
                        const position = map.getCenter();
                        const latlng = {
                            lat: position.lat() % 90,
                            lng: position.lng() % 180
                        };
                        setMapLocationSelected(latlng);
                    }, 100)
                );

                setMap(map);
                // Zones (admins only):
                await wait(500); // HACK TODO:
            };

            asyncFunc();
        }, []);

        useEffect(() => {
            if (!_.isNil(map)) {
                map.setCenter(mapLocationSelected);
            }
            setManualGPSEdit(false);
        }, [manualGPSEdit]);

        useEffect(() => {
            if (!_.isNil(map)) {
                console.log('%cMap theme has changed.', 'color: seagreen');
                map.setOptions({ styles: theme.palette.type === 'light' ? mapThemeLight : mapThemeDark });
            }
        }, [theme.palette.type]);

        return (
            <React.Fragment>
                <div
                    ref={elem => (mapElemRef.current = elem)}
                    id="google-gps-select-map"
                    data-cy="google-gps-select-map"
                    style={{
                        position: 'relative',
                        width: '100%',
                        height: '100%'
                    }}
                />
            </React.Fragment>
        );
    },
    (prevProps, nextProps) => {
        return _.isEqual(prevProps, nextProps); //deep comparison on props
    }
);

export default withTheme()(GPSSSelectMap);
