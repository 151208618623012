import React from 'react';

import _ from 'lodash';

import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import { withTheme } from '@material-ui/core/styles';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc, locDate } from '../../../localizations/localizationHandler';
import { useContext } from 'react';
import { isEXPRegion } from 'utils/misc';

const ReportedPickupSubwidget = ({
    orderType,
    penaltyDetails = '',
    displayAdvisory,
    clicksAllowed,
    theme,
    onReportedDialog
}) => {
    const { lang } = useContext(LocalizationContext);
    return (
        <div
            id="reported-bulk-subwidget"
            data-cy="reported-bulk-subwidget"
            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
        >
            <div
                style={{
                    paddingLeft: theme.spacing.unit * 2,
                    paddingRight: theme.spacing.unit * 2,
                    overflow: 'hidden'
                }}
            >
                <Typography>
                    {isEXPRegion()
                        ? loc('pickupWidgets12EXP', lang)
                        : loc(displayAdvisory ? 'pickupWidgets12b' : 'pickupWidgets12a', lang, {
                              orderType: _.startCase(orderType),
                              penaltyDetails
                          })}
                </Typography>
            </div>
            <div
                style={{
                    borderLeft: '1px solid ' + theme.palette.text.hint,
                    paddingLeft: theme.spacing.unit,
                    paddingRight: theme.spacing.unit
                }}
            >
                <IconButton
                    data-cy="reported-pickup-info-button"
                    disabled={!clicksAllowed}
                    onClick={onReportedDialog(true)}
                >
                    <Icon>info</Icon>
                </IconButton>
            </div>
        </div>
    );
};

export default withTheme()(ReportedPickupSubwidget);
