import { useState, useMemo } from 'react';

import _ from 'lodash';
import moment from 'moment-timezone';

import { _bulk, _user } from 'std';

function useDriverComplete({ receiverFilter, dateFilteredTrips, dateFilteredBulks }) {
    const [pickupDialogOpen, setPickupDialogOpen] = useState(false);
    const [pickupIdSelected, setPickupIdSelected] = useState(null);
    const numberOfScheduledPickups = dateFilteredTrips.map(t => ({
        driver: t.transporter._id,
        numberOfPickups: t.numberOfPickups
    }));

    const todaysPickups = useMemo(() => {
        let todaysPickups = _.flatten(dateFilteredTrips.map(t => t.pickups));
        if (receiverFilter !== 'all') {
            todaysPickups = _(todaysPickups).filter(p => _user.getId(p.receiver) === receiverFilter);
        }

        todaysPickups.forEach(pickup => {
            const bulksInPickup = _(dateFilteredBulks)
                .filter(b => b.pickup === pickup._id)
                .value();

            if (!_.isEmpty(bulksInPickup)) {
                const mostRecentBulk = _.first(bulksInPickup);
                pickup.bulks = bulksInPickup;
                pickup.bulk_ids = bulksInPickup.map(b => b._id);
                pickup.bagtag = _bulk.getTruncatedLabel(mostRecentBulk);
            }

            // const tripForPickup = _.find(dateFilteredTrips, { _id: pickup.trip });
            // pickup.tripStats = getPickupTripStats(pickup, tripForPickup, todaysPickups);
        });

        return _(todaysPickups)
            .orderBy(p => new Date(p.completionDate), 'desc')
            .value();
    }, [dateFilteredBulks, dateFilteredTrips, receiverFilter]);

    const pickupSelected = useMemo(() => _.find(todaysPickups, p => p._id === pickupIdSelected), [
        todaysPickups,
        pickupIdSelected
    ]);

    // const pickupsByDrivers = useMemo(
    const pickupsByTrips = useMemo(() => {
        let newTodaysPickups = _(todaysPickups)
            .groupBy(p => _.get(p, 'trip'))
            .value();
        dateFilteredTrips.forEach(trip => {
            if (_.isEmpty(trip.pickups) && !_.isNil(trip.rescheduledPickupsCount) && trip.rescheduledPickupsCount > 0) {
                newTodaysPickups[trip._id] = [];
            }
        });
        return newTodaysPickups;
    }, [todaysPickups]);

    const handlePickupDialogOpen = state => {
        setPickupDialogOpen(state);
    };

    const handleOpenPickupDialog = pickupIdSelected => {
        setPickupIdSelected(pickupIdSelected);
        setPickupDialogOpen(true);
    };

    return {
        pickupSelected,
        pickupDialogOpen,
        pickupIdSelected,
        pickupsByTrips, // pickupsByDrivers,
        todaysPickups,
        numberOfScheduledPickups,
        handlePickupDialogOpen,
        handleOpenPickupDialog
    };
}

export default useDriverComplete;
