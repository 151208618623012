import React, { useContext } from 'react';
import _ from 'lodash';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    withTheme,
    FormControl,
    TextField
} from '@material-ui/core';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

function BinRequestDialog(props) {
    const {
        open,
        onClose,
        theme,
        handleBinRequestMessageChange,
        handleRequestBinChange,
        binRequestMessage,
        binRequestMessageError
    } = props;
    const { lang } = useContext(LocalizationContext);

    return (
        <Dialog open={open} fullWidth>
            <DialogTitle>{loc('binRequest1', lang)}</DialogTitle>
            <DialogContent>
                <DialogContentText>{loc('binRequest2', lang)}</DialogContentText>

                <FormControl style={{ marginBottom: theme.spacing.unit, marginTop: theme.spacing.unit }} fullWidth>
                    <TextField
                        data-cy="bin-request-message-input"
                        multiline
                        rows="3"
                        name="message"
                        label="Message"
                        value={binRequestMessage}
                        variant="outlined"
                        helperText={binRequestMessageError.reason}
                        error={binRequestMessageError.fail}
                        onChange={e => handleBinRequestMessageChange(e.target.value)}
                    />
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    onClick={() => {
                        handleRequestBinChange(false);
                        onClose();
                    }}
                >
                    {loc('binRequest3', lang)}
                </Button>
                <Button
                    color="primary"
                    onClick={() => {
                        handleRequestBinChange(true);
                        onClose();
                    }}
                    disabled={binRequestMessageError.fail || _.isEmpty(binRequestMessage.trim())}
                >
                    {loc('binRequest4', lang)}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default withTheme()(BinRequestDialog);
