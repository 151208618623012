import React from 'react';
import moment from 'moment-timezone';
// npm components
import * as colors from '@material-ui/core/colors';
import { withTheme, withMobileDialog, Grid, Typography, LinearProgress } from '@material-ui/core';
import CalendarCell from './CalendarCell';

import { _time } from 'std';

import _ from 'lodash';

const borderStyles = `1px solid ${colors.grey[300]}`;

function CustomCalendar(props) {
    const {
        theme,
        loading,
        datesWithPickups,
        dates,
        startDate,
        endDate,
        capacities,
        timezone,
        onOpenDateOnPickupsScreen
    } = props;

    return (
        <Grid container style={{ border: borderStyles, minWidth: 1400 }}>
            {['Sun', 'Mon', 'Tues', 'Wed', 'Thr', 'Fri', 'Sat'].map((d, i) => {
                return (
                    <Grid item style={{ width: '14.2857142857%' }} key={i}>
                        <div style={{ border: borderStyles, backgroundColor: theme.palette.background.default }}>
                            <Typography variant="caption" style={{ textAlign: 'center' }}>
                                <strong>{d}</strong>
                            </Typography>
                        </div>{' '}
                    </Grid>
                );
            })}
            {loading && <LinearProgress />}
            {dates.map((d, i) => {
                const date = datesWithPickups[d];
                const outOfDateRange = !moment(d)
                    .tz(timezone)
                    .isBetween(startDate, endDate, 'd', '[]');
                const isToday = moment(d)
                    .tz(timezone)
                    .isSame(_time.getStartOfToday(timezone), 'd');

                return (
                    <CalendarCell
                        key={i}
                        day={d}
                        outOfDateRange={outOfDateRange}
                        isToday={isToday}
                        driversOnDay={_.get(date, 'drivers', [])}
                        pickups={_.get(date, 'pickups', [])}
                        trips={_.get(date, 'trips', [])}
                        theme={theme}
                        onOpenDateOnPickupsScreen={onOpenDateOnPickupsScreen}
                        timezone={timezone}
                    />
                );
            })}
        </Grid>
    );
}

export default withMobileDialog({ breakpoint: 'xs' })(withTheme()(CustomCalendar));
