import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';
import { _time } from 'std';

import {
    Icon,
    Grid,
    Paper,
    Button,
    MenuItem,
    TextField,
    colors,
    withTheme,
    DialogActions,
    DialogContent,
    Dialog,
    Select,
    DialogTitle,
    OutlinedInput
} from '@material-ui/core';

import useGetJSON from 'utils/hooks/useGetJSON';

import useDateRangePicker from 'components/DateTimePickersTz/hooks/useDateRangePicker';
import DateRangePicker from 'components/DateTimePickersTz/DateRangePicker';

import AudienceCaptureChart from './AudienceCaptureChart';
import LocalizationContext from 'utils/contexts/LocalizationContext';

function AudienceCapture(props) {
    const { theme, http } = props;

    const [loading, setLoading] = useState(false);

    const [questionnaireSelected, setQuestionnaireSelected] = useState(null);
    const [chartInterval, setChartInterval] = useState('MONTH');
    const [statsByQuestionId, setStatsByQuestionId] = useState({});

    const [questionSelected, setQuestionSelected] = useState(null);

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [size, setSize] = useState(12);

    const {
        loading: questionnairesLoading,
        error: questionnairesLoadingErr,
        data: questionnaires = [],
        refresh: refreshQuestionnaires
    } = useGetJSON(`/questionnaires`, 'questionnaires');

    const {
        startDate,
        endDate,
        timezone,
        dateWindow,
        handleChangeStartDate,
        handleChangeEndDate,
        handleGoForwards,
        handleGoBackwards,
        handleWindowSelect
    } = useDateRangePicker({
        saveStateInURL: true,
        timezones: [process.env.REACT_APP_REGION_TIMEZONE],
        initStartVal: moment(new Date(2018, 0, 1))
            .tz(process.env.REACT_APP_REGION_TIMEZONE)
            .startOf('day'),
        initEndVal: _time.getEndOfDay(process.env.REACT_APP_REGION_TIMEZONE),
        initDateWindow: 'allTime'
    });

    const handleDialogOpen = question => {
        setSize(JSON.parse(window.localStorage.getItem(`question-${question._id}-size`)));
        setQuestionSelected(question);
        setIsDialogOpen(true);
    };

    const handleDialogClose = () => {
        setIsDialogOpen(false);
    };

    const handleDialogSubmit = () => {
        window.localStorage.setItem(`question-${questionSelected._id}-size`, JSON.stringify(size));
        handleDialogClose();
    };

    /*const visibleTrendKeysByQuestionId = useMemo(() => {
        const visibleTrendKeysByQuestionId = {};
        _.get(questionnaireSelected, 'questions', []).forEach(question => {
            visibleTrendKeysByQuestionId[question._id] = _.get(question, 'responses', []).map(r => r.en);
            if (_.get(question, 'allowCustomResponse', false)) {
                visibleTrendKeysByQuestionId[question._id].push('OTHER');
            }
        });
        return visibleTrendKeysByQuestionId;
    }, [questionnaireSelected]);

    const trendLinesByQuestionId = useMemo(() => {
        const trendLinesByQuestionId = {};
        _.get(questionnaireSelected, 'questions', []).forEach(question => {
            trendLinesByQuestionId[question._id] = _.get(question, 'responses', []).map((r, i) => ({
                name: r.en,
                dataKey: r.en,
                stroke: lineColors[i % lineColors.length]
            }));
            if (_.get(question, 'allowCustomResponse', false)) {
                trendLinesByQuestionId[question._id].push({
                    name: 'Other',
                    dataKey: 'OTHER',
                    stroke: lineColors[trendLinesByQuestionId[question._id].length % lineColors.length]
                });
            }
        });
        return trendLinesByQuestionId;
    }, [questionnaireSelected]);*/

    const handleLoadData = async () => {
        setLoading(true);
        const res = await http.getJSON(
            `/questionnaires/responsesStats?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}&questionnaireId=${_.get(
                questionnaireSelected,
                '_id'
            )}`
        );
        if (res.ok) {
            setStatsByQuestionId(populateLabelsAndSort(res.data.statsByQuestionId));
        }
        setLoading(false);
    };

    const handleDateWindowChange = e => {
        handleWindowSelect(e);
        switch (e.target.value) {
            case 'today':
                setChartInterval('DAY');
                break;
            case 'thisIsoWeek':
                setChartInterval('WEEK');
                break;
            case 'thisMonth':
                setChartInterval('MONTH');
                break;
            default:
                setChartInterval('MONTH');
        }
    };

    useEffect(() => {
        setQuestionnaireSelected(_.first(questionnaires));
        setStatsByQuestionId(initStats(_.first(questionnaires)));
    }, [questionnaires]);

    useEffect(() => {
        setStatsByQuestionId(initStats(questionnaireSelected));
    }, [questionnaireSelected]);

    return (
        <>
            <Grid
                container
                spacing={theme.spacing.unit * 2}
                style={{ padding: theme.spacing.unit, margin: 0, width: '100%' }}
            >
                <Grid item xs={12}>
                    <Paper
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            padding: theme.spacing.unit * 2,
                            flexWrap: 'wrap'
                        }}
                    >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <DateRangePicker
                                timezone={timezone}
                                startDate={startDate}
                                endDate={endDate}
                                window={dateWindow}
                                disabled={loading}
                                handlePrevious={handleGoBackwards}
                                handleNext={handleGoForwards}
                                handleChangeStartDate={handleChangeStartDate}
                                handleChangeEndDate={handleChangeEndDate}
                            />
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexWrap: 'wrap',
                                flexDirection: 'row'
                            }}
                        >
                            <TextField
                                margin="dense"
                                label="Window"
                                variant="outlined"
                                disabled={loading}
                                value={dateWindow}
                                onChange={handleDateWindowChange}
                                style={{ marginRight: theme.spacing.unit, minWidth: 100 }}
                                select
                            >
                                <MenuItem value="today">Daily</MenuItem>
                                <MenuItem value="thisIsoWeek">Weekly</MenuItem>
                                <MenuItem value="thisMonth">Monthly</MenuItem>
                                <MenuItem value="thisQuarter">Quarterly</MenuItem>
                                <MenuItem value="allTime">All Time</MenuItem>
                            </TextField>
                            <TextField
                                margin="dense"
                                label="Questionnaire"
                                name="questionnaire"
                                value={_.get(questionnaireSelected, '_id', '')}
                                onChange={e => {
                                    setStatsByQuestionId({});
                                    setQuestionnaireSelected(_.find(questionnaires, { _id: e.target.value }));
                                }}
                                variant="outlined"
                                select
                                style={{ marginRight: theme.spacing.unit, minWidth: 100 }}
                            >
                                {!_.isEmpty(questionnaires) &&
                                    questionnaires.map(questionnaire => (
                                        <MenuItem value={questionnaire._id}>{questionnaire.name}</MenuItem>
                                    ))}
                            </TextField>
                            <Button
                                color="primary"
                                disabled={loading || questionnairesLoading}
                                variant="contained"
                                data-cy="dashboard-search-button"
                                type="submit"
                                onClick={handleLoadData}
                            >
                                <Icon>search</Icon>
                            </Button>
                        </div>
                    </Paper>
                </Grid>

                {_.get(questionnaireSelected, 'questions', []).map(question => {
                    var chartSize = 12;

                    var localQuestionSize = JSON.parse(window.localStorage.getItem(`question-${question._id}-size`));

                    if (!_.isNil(localQuestionSize)) {
                        chartSize = localQuestionSize;
                    }

                    return (
                        <Grid item xs={chartSize} key={question._id}>
                            <AudienceCaptureChart
                                question={question}
                                data={_.get(statsByQuestionId, question._id, [])}
                                dataLoading={loading}
                                bar={{
                                    dataKey: 'count',
                                    name: 'Count',
                                    fill: colors.blue[500]
                                }}
                                title={_.get(question, `description.en`)}
                                YAxisProps={{ allowDecimals: false }}
                                handleDialogOpen={handleDialogOpen}
                            />
                        </Grid>
                    );
                })}
            </Grid>
            <EditDialog
                isDialogOpen={isDialogOpen}
                setSize={setSize}
                size={size}
                handleDialogClose={handleDialogClose}
                handleDialogSubmit={handleDialogSubmit}
                questionSelected={questionSelected}
                setIsDialogOpen={setIsDialogOpen}
            />
        </>
    );
}

const EditDialog = ({ isDialogOpen, size, setSize, handleDialogClose, handleDialogSubmit, questionSelected }) => {
    const { lang } = useContext(LocalizationContext);

    return (
        <Dialog open={isDialogOpen} fullWidth onClose={handleDialogClose}>
            <DialogTitle>{`Edit ${_.get(questionSelected, `description.${lang}`, 'Chart')}`}</DialogTitle>
            <DialogContent>
                <TextField
                    value={size || 12}
                    select
                    label="Size"
                    onChange={e => setSize(e.target.value)}
                    placeholder="Size"
                    fullWidth
                    input={<OutlinedInput label="Size" id="demo-dialog-native" />}
                    data-cy="date-range-select"
                >
                    <MenuItem value={12} data-cy="size-select-full">
                        Full
                    </MenuItem>
                    <MenuItem value={6} data-cy="size-select-half">
                        1/2
                    </MenuItem>
                    <MenuItem value={4} data-cy="size-select-third">
                        1/3
                    </MenuItem>
                    <MenuItem value={3} data-cy="size-select-quarter">
                        1/4
                    </MenuItem>
                </TextField>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={handleDialogClose}>
                    Close
                </Button>
                <Button
                    color="primary"
                    disabled={_.isNil(size)}
                    variant="contained"
                    data-cy="audienceCapture-search-button"
                    type="submit"
                    onClick={handleDialogSubmit}
                >
                    <Icon>search</Icon>
                </Button>
            </DialogActions>
        </Dialog>
    );
};

//    { name: 'Containers', dataKey: 'containers', stroke: colors.blue[500], type: 'container' },

export default withTheme()(AudienceCapture);

function initStats(questionnaire) {
    const dataByQuestionId = {};
    _.get(questionnaire, 'questions', []).forEach(question => {
        dataByQuestionId[question._id] = _.get(question, 'responses', []).map(resp => {
            return {
                label: _.get(resp, 'en'),
                response: _.get(resp, 'en'),
                count: 0
            };
        });
        if (_.get(question, 'allowCustomResponse', false)) {
            dataByQuestionId[question._id].push({
                label: 'Other',
                response: 'OTHER',
                count: 0
            });
        }
    });

    return dataByQuestionId;
}

function populateLabelsAndSort(dataByQuestionId) {
    const dataByQuestionIdCopy = _.cloneDeep(dataByQuestionId);
    Object.keys(dataByQuestionIdCopy).forEach(questionId => {
        dataByQuestionIdCopy[questionId].forEach((d, i) => {
            dataByQuestionIdCopy[questionId][i].label =
                dataByQuestionIdCopy[questionId][i].response === 'OTHER'
                    ? 'Other'
                    : dataByQuestionIdCopy[questionId][i].response;
        });
        dataByQuestionIdCopy[questionId] = _.sortBy(dataByQuestionIdCopy[questionId], 'count').reverse();
    });

    return dataByQuestionIdCopy;
}
