import React, { useContext } from 'react';

import _ from 'lodash';
import moment from 'moment-timezone';

import DatePicker from 'components/DateTimePickersTz/DatePicker';
import {
    Paper,
    Typography,
    Icon,
    FormControl,
    FormControlLabel,
    Select,
    MenuItem,
    Checkbox,
    Button,
    LinearProgress,
    Collapse,
    Fade,
    InputLabel,
    OutlinedInput,
    InputAdornment,
    TextField,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    DialogContentText
} from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';

import { getReceiverName } from 'utils/misc';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

const ScheduleUnservicedDialog = ({
    inProgress,
    open,
    handleClose,
    handleChange,
    handleSubmit,
    tripsAvailable,
    theme,
    pickupsSelected,
    form
}) => {
    const { lang } = useContext(LocalizationContext);
    const handleDateChange = momentDate => {
        const customEvent = {
            target: {
                name: 'date',
                value: momentDate.toDate()
            }
        };

        handleChange(customEvent);
    };

    const timezone = process.env.REACT_APP_REGION_TIMEZONE;
    return (
        <Dialog fullWidth open={open} onClose={inProgress ? () => undefined : handleClose}>
            <DialogTitle>Schedule Unserviced Pickups</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Number of pickups:{' '}
                    <span style={{ color: theme.palette.primary.main }}>{pickupsSelected.length}</span>
                </DialogContentText>
                {!_.isNil(form.date) ? (
                    <DialogContentText>
                        New date:{' '}
                        <span style={{ color: theme.palette.primary.main }}>
                            {moment(form.date)
                                .tz(timezone)
                                .format('dddd[,] MMM Do')}
                        </span>
                    </DialogContentText>
                ) : (
                    <DialogContentText>
                        New date: <span style={{ color: theme.palette.error.main }}>not selected</span>
                    </DialogContentText>
                )}
                <DialogContentText>
                    Notification emails:{' '}
                    <span>
                        {form.sendOutEmails ? (
                            <span style={{ color: theme.palette.primary.main }}>
                                will be sent to affected customers
                            </span>
                        ) : (
                            <span style={{ color: theme.palette.text.hint }}>will not be sent</span>
                        )}
                    </span>
                </DialogContentText>
                <FormControl
                    fullWidth
                    style={{
                        paddingTop: theme.spacing.unit * 0.5,
                        paddingBottom: theme.spacing.unit * 0.5
                    }}
                >
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="sendOutEmails"
                                checked={form.sendOutEmails}
                                style={{
                                    paddingTop: theme.spacing.unit * 0.5,
                                    paddingBottom: theme.spacing.unit * 0.5
                                }}
                                onChange={handleChange}
                            />
                        }
                        label="Send out emails"
                    />
                </FormControl>
                <FormControl fullWidth style={{ marginTop: theme.spacing.unit * 3 }}>
                    <DatePicker
                        disablePast
                        timezone={timezone}
                        data-cy="bulk-reschedule-dialog-datepicker-selector"
                        autoOk
                        label="Date"
                        name="date"
                        value={moment(form.date)}
                        variant="outlined"
                        style={{ marginBottom: theme.spacing.unit }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Icon>date_range</Icon>
                                </InputAdornment>
                            )
                        }}
                        onChange={handleDateChange}
                    />
                </FormControl>

                {tripsAvailable && (
                    <FormControl fullWidth margin="normal" helperText="Trip that the pickups will be assigned to">
                        <InputLabel htmlFor="trip-assign" variant="outlined" shrink>
                            Trip
                        </InputLabel>
                        <Select
                            value={form.trip}
                            onChange={handleChange}
                            input={<OutlinedInput name="trip" id="trip-assign" labelWidth={26} notched />}
                        >
                            {tripsAvailable &&
                                tripsAvailable.map(({ _id, startTime, collector, transporter }) => {
                                    return (
                                        <MenuItem key={_id} value={_id}>
                                            <span>
                                                <span style={{ color: theme.palette.text.secondary }}>
                                                    {moment(startTime).format('ddd, MMM Do h:mm A')}
                                                </span>{' '}
                                                {getReceiverName(transporter)} ➡{' '}
                                                <span style={{ color: theme.palette.primary.main }}>
                                                    {collector.code}
                                                </span>
                                            </span>
                                        </MenuItem>
                                    );
                                })}
                        </Select>
                    </FormControl>
                )}

                <Collapse in={form.sendOutEmails} style={{ marginTop: theme.spacing.unit }}>
                    <Paper
                        elevation={0}
                        style={{
                            borderRadius: theme.spacing.unit,
                            border: `1px solid ${theme.palette.text.hint}`,
                            backgroundColor: theme.palette.background.default,
                            padding: theme.spacing.unit * 2
                        }}
                    >
                        <Typography
                            variant="body1"
                            style={{
                                lineHeight: 1.15,
                                padding: theme.spacing.unit * 2,
                                paddingTop: 0,
                                paddingBottom: 0
                            }}
                        >
                            {getEmailHeading(theme, timezone, form.date, true)}
                        </Typography>
                        <FormControl
                            fullWidth
                            style={{
                                marginTop: theme.spacing.unit * 2,
                                backgroundColor: theme.palette.background.paper
                            }}
                        >
                            <TextField
                                data-cy=""
                                multiline
                                disabled={!form.sendOutEmails}
                                name="emailBody"
                                value={form.emailBody}
                                label="Email body"
                                variant="outlined"
                                onChange={handleChange}
                            />
                        </FormControl>
                    </Paper>
                </Collapse>
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={inProgress}
                    id="bulk-reschedule-dialog-close"
                    data-cy="bulk-reschedule-dialog-close"
                    color="primary"
                    onClick={handleClose}
                >
                    {loc('close', lang)}
                </Button>
                <Button
                    id="bulk-reschedule-dialog-submit"
                    data-cy="bulk-reschedule-dialog-submit"
                    color="primary"
                    disabled={inProgress || _.isNil(form.date)} // || moment(form.date).isSame(currentDate, 'days') removed this condition
                    onClick={handleSubmit}
                >
                    {loc('submit', lang)}
                </Button>
            </DialogActions>
            <Fade in={inProgress}>
                <LinearProgress />
            </Fade>
        </Dialog>
    );
};

export default withTheme()(ScheduleUnservicedDialog);

export function getEmailHeading(theme, timezone, newDate, renderAsHTML = false) {
    if (renderAsHTML) {
        return (
            <span>
                Your pickup has been rescheduled to{' '}
                <span style={{ color: theme.palette.primary.main }}>
                    {moment(newDate)
                        .tz(timezone)
                        .format('dddd[,] MMMM Do')}
                </span>{' '}
                by {process.env.REACT_APP_BRAND_NAME}.
            </span>
        );
    } else {
        return `Your pickup has been rescheduled to ${moment(newDate)
            .tz(timezone)
            .format('dddd[,] MMMM Do')} by ${process.env.REACT_APP_BRAND_NAME}.`;
    }
}
