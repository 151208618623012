import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import _ from 'lodash';

import ContainerWithLogo from 'components/ContainerWithLogo/ContainerWithLogo';

import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { withTheme } from '@material-ui/core/styles';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from '../../localizations/localizationHandler';

class ConfirmPickup extends Component {
    static contextType = LocalizationContext;
    state = {
        success: undefined,
        message: undefined
    };

    async componentDidMount() {
        this.handleConfirmPickup();
    }

    handleConfirmPickup = async () => {
        let pickupID = this.props.match.params.pickupID;
        let res = await this.props.http.postJSON('/oneStepConfirmPickup', { pickupID });
        if (res.ok) {
            this.setState({ success: res.data.success, message: res.data.message });
        } else {
            this.setState({ message: loc('oneStepPickupFail', this.context.lang), success: false });
        }
    };

    render() {
        const { theme } = this.props;
        const { success, message } = this.state;
        const { lang } = this.context;

        if (_.isNil(success)) {
            return (
                <ContainerWithLogo brandLogoOverride={_.get(this, 'props.brandLogoOverride')}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <div style={{ textAlign: 'center' }}>
                            <CircularProgress />
                            <Typography variant="subtitle1" style={{ marginTop: theme.spacing.unit * 2 }}>
                                {loc('verifying', lang)}...
                            </Typography>
                        </div>
                    </div>
                </ContainerWithLogo>
            );
        } else {
            return (
                <ContainerWithLogo brandLogoOverride={_.get(this, 'props.brandLogoOverride')}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <div style={{ textAlign: 'center' }}>
                            <Typography
                                variant="h6"
                                color={success ? 'default' : 'error'}
                                style={{ marginTop: theme.spacing.unit * 4 }}
                            >
                                {message}
                            </Typography>
                            {!success && (
                                <>
                                    <Button
                                        variant="contained"
                                        style={{ marginTop: theme.spacing.unit * 4 }}
                                        onClick={this.handleConfirmPickup}
                                    >
                                        {loc('confirmPickup', lang)}
                                    </Button>
                                    <br />
                                </>
                            )}
                            <Button
                                variant="contained"
                                component={Link}
                                to="/login"
                                color="primary"
                                style={{ marginTop: theme.spacing.unit * 4 }}
                            >
                                {loc('goBack', lang)}
                            </Button>
                        </div>
                    </div>
                </ContainerWithLogo>
            );
        }
    }
}

export default withTheme()(ConfirmPickup);
