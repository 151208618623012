import React from 'react';
import Typography from '@material-ui/core/Typography';
import DriverCommentImage from './DriverCommentImage';

export default {
    text: muiText,
    listItem: muiListItem,
    image: MuiImage
};

function muiText(props) {
    return <Typography style={{ display: 'inline', fontWeight: 'unset' }}>{props.children}</Typography>;
}

function muiListItem(props) {
    return <li>{props.children}</li>;
}

function MuiImage(props) {
    return <DriverCommentImage {...props} />;
}
