import React, { useMemo } from 'react';

import _ from 'lodash';

import moment from 'moment-timezone';

// npm components
import * as colors from '@material-ui/core/colors';
import { withTheme, withMobileDialog, Grid, Typography } from '@material-ui/core';
import pickupHelper from 'helpers/pickupHelper';

function CalendarCell(props) {
    const {
        pickups,
        day,
        driversOnDay,
        trips,
        outOfDateRange,
        isToday,
        onOpenDateOnPickupsScreen,
        timezone,
        theme
    } = props;

    // const capacity = tripCapacity > defaultCapacity ? tripCapacity : defaultCapacity;
    const capacity = driversOnDay.reduce(
        (sum, d) => sum + _.get(d, 'driverBagCapacity', 0) * _.get(d, 'driverLoadsPerDay', 1),
        0
    );

    const confirmedPickups = useMemo(
        () =>
            _(pickups)
                .filter(p => pickupHelper.isConfirmed(p))
                .value(),
        [pickups]
    );

    const unconfirmedPickups = useMemo(
        () =>
            _(pickups)
                .filter(p => !pickupHelper.isConfirmed(p))
                .value(),
        [pickups]
    );

    const numberOfConfirmedPickups = confirmedPickups.length || 0;
    const numberOfUnconfirmedPickups = unconfirmedPickups.length || 0;

    const numberOfConfirmedBags = confirmedPickups
        ? confirmedPickups.reduce((sum, p) => {
              const beverageTotal = _.get(p, 'payload.beverage') || 0;
              const clothingTotal = _.get(p, 'payload.clothing') || 0;
              const electronicsTotal = _.get(p, 'payload.electronics') || 0;

              return sum + parseInt(beverageTotal) + parseInt(clothingTotal) + parseInt(electronicsTotal);
          }, 0)
        : 0;
    const numberOfUnconfirmedBags = unconfirmedPickups
        ? unconfirmedPickups.reduce((sum, p) => {
              const beverageTotal = _.get(p, 'payload.beverage') || 0;
              const clothingTotal = _.get(p, 'payload.clothing') || 0;
              const electronicsTotal = _.get(p, 'payload.electronics') || 0;

              return sum + parseInt(beverageTotal) + parseInt(clothingTotal) + parseInt(electronicsTotal);
          }, 0)
        : 0;
    const loads =
        !_.isNil(trips) && !_.isEmpty(trips)
            ? trips.length
            : _.isEmpty(driversOnDay)
            ? 0
            : _.ceil(
                  (numberOfConfirmedBags + numberOfUnconfirmedBags) /
                      (driversOnDay.reduce((sum, d) => sum + _.get(d, 'driverBagCapacity', 200), 0) /
                          driversOnDay.length)
              );

    const {
        confirmedCapacityPercentage,
        unconfirmedCapacityPercentage,
        overCapacityPercentage,
        numberOfBagsOverCapacity,
        spaceRemaining,
        totalNumberOfBags,
        isOverCapacity
    } = useMemo(() => getCellData(numberOfConfirmedBags, numberOfUnconfirmedBags, capacity), [
        capacity,
        numberOfConfirmedBags,
        numberOfUnconfirmedBags,
        pickups
    ]);

    const hasData = useMemo(() => {
        return (!_.isNil(pickups) && !_.isEmpty(pickups)) || (!_.isNil(trips) && !_.isEmpty(trips));
    }, [pickups, trips]);

    return (
        <Grid item style={{ width: '14.2857142857%' }}>
            <div
                style={{
                    border: `${isToday ? 2 : 1}px solid ${isToday ? theme.palette.primary.main : colors.grey[300]}`,
                    padding: 4,
                    backgroundColor: outOfDateRange ? theme.palette.background.default : theme.palette.background.paper,
                    height: 148
                }}
            >
                <Typography variant="subtitle2">
                    <strong
                        style={{
                            cursor: 'pointer'
                        }}
                        onClick={() => onOpenDateOnPickupsScreen(day)}
                    >
                        {moment(day)
                            .tz(timezone)
                            .format('D MMM')}
                    </strong>
                </Typography>
                {!outOfDateRange && (
                    <div style={{ display: 'inline-flex', height: 102 }}>
                        <div style={{ border: `1px solid ${colors.grey[400]}` }}>
                            <div
                                style={{
                                    height: overCapacityPercentage,
                                    width: 8,
                                    marginTop:
                                        100 -
                                        confirmedCapacityPercentage -
                                        unconfirmedCapacityPercentage -
                                        overCapacityPercentage,
                                    backgroundColor: colors.red[300]
                                }}
                            />
                            <div
                                style={{
                                    height: unconfirmedCapacityPercentage,
                                    width: 8,
                                    marginTop:
                                        100 -
                                        confirmedCapacityPercentage -
                                        unconfirmedCapacityPercentage -
                                        overCapacityPercentage,
                                    backgroundColor: colors.yellow[700]
                                }}
                            />
                            <div
                                style={{
                                    height: confirmedCapacityPercentage || 0,
                                    width: 8,
                                    backgroundColor: colors.blue[300]
                                }}
                            />
                        </div>
                        {hasData && (
                            <div style={{ marginLeft: 4 }}>
                                <Typography variant="caption">
                                    <span style={{ textDecoration: 'underline' }}>
                                        {!_.isNil(trips) && !_.isEmpty(trips) ? 'Volume' : 'Estimated Volume'}
                                    </span>
                                </Typography>
                                <Typography variant="caption">
                                    <span
                                        data-cy={`${moment(day)
                                            .tz(timezone)
                                            .format('YYYY-MM-DD')}-pickups`}
                                    >
                                        Pickups:{' '}
                                        <span
                                            style={{
                                                fontWeight: 'bold',
                                                fontSize: '13px'
                                            }}
                                        >
                                            {numberOfUnconfirmedPickups + numberOfConfirmedPickups}
                                        </span>
                                        {numberOfUnconfirmedPickups > 0 && (
                                            <span style={{ color: colors.yellow[800] }}>
                                                {' '}
                                                (
                                                <span
                                                    style={{
                                                        fontWeight: 'bold',
                                                        fontSize: '13px'
                                                    }}
                                                    data-cy={`${moment(day)
                                                        .tz(timezone)
                                                        .format('YYYY-MM-DD')}-pending-pickups`}
                                                >
                                                    {numberOfUnconfirmedPickups}
                                                </span>{' '}
                                                Pending)
                                            </span>
                                        )}
                                    </span>
                                </Typography>
                                <Typography variant="caption">
                                    <span
                                        data-cy={`${moment(day)
                                            .tz(timezone)
                                            .format('YYYY-MM-DD')}-bags`}
                                    >
                                        Bags:{' '}
                                        <span
                                            style={{
                                                fontWeight: 'bold',
                                                fontSize: '13px'
                                            }}
                                        >
                                            {numberOfUnconfirmedBags + numberOfConfirmedBags}
                                        </span>
                                        {numberOfUnconfirmedBags > 0 && (
                                            <span style={{ color: colors.yellow[800] }}>
                                                {' '}
                                                (
                                                <span
                                                    style={{
                                                        fontWeight: 'bold',
                                                        fontSize: '13px'
                                                    }}
                                                    data-cy={`${moment(day)
                                                        .tz(timezone)
                                                        .format('YYYY-MM-DD')}-pending-bags`}
                                                >
                                                    {numberOfUnconfirmedBags}
                                                </span>{' '}
                                                Pending)
                                            </span>
                                        )}
                                    </span>
                                </Typography>
                                <Typography variant="caption">
                                    <span
                                        data-cy={`${moment(day)
                                            .tz(timezone)
                                            .format('YYYY-MM-DD')}-loads`}
                                    >
                                        {!_.isNil(trips) && !_.isEmpty(trips) ? 'Loads' : 'Estimated Loads'}:{' '}
                                        <span
                                            style={{
                                                fontWeight: 'bold',
                                                fontSize: '13px'
                                            }}
                                        >
                                            {loads}
                                        </span>
                                    </span>
                                </Typography>
                                {isOverCapacity && (
                                    <Typography variant="caption">
                                        <span
                                            style={{
                                                color: colors.red[500]
                                            }}
                                            data-cy={`${moment(day)
                                                .tz(timezone)
                                                .format('YYYY-MM-DD')}-over-capacity`}
                                        >
                                            {numberOfBagsOverCapacity} over capacity
                                        </span>
                                    </Typography>
                                )}
                            </div>
                        )}
                    </div>
                )}
            </div>
        </Grid>
    );
}

export default withMobileDialog({ breakpoint: 'xs' })(withTheme()(CalendarCell));

function getCellData(numberOfConfirmedBags, numberOfUnconfirmedBags, capacity) {
    const totalNumberOfBags = numberOfConfirmedBags + numberOfUnconfirmedBags;
    if (totalNumberOfBags === 0) {
        return {
            confirmedCapacityPercentage: 0,
            unconfirmedCapacityPercentage: 0,
            overCapacityPercentage: 0,
            numberOfBagsOverCapacity: 0,
            spaceRemaining: capacity,
            totalNumberOfBags,
            isOverCapacity: false
        };
    }
    const isOverCapacity = totalNumberOfBags > capacity;
    let confirmedCapacityPercentage, unconfirmedCapacityPercentage, overCapacityPercentage;
    if (isOverCapacity) {
        if (numberOfConfirmedBags > capacity) {
            confirmedCapacityPercentage = (capacity / totalNumberOfBags) * 100;
            unconfirmedCapacityPercentage = 0;
            overCapacityPercentage = ((totalNumberOfBags - capacity) / totalNumberOfBags) * 100;
        } else {
            confirmedCapacityPercentage = (numberOfConfirmedBags / totalNumberOfBags) * 100;
            unconfirmedCapacityPercentage = ((capacity - numberOfConfirmedBags) / totalNumberOfBags) * 100;
            overCapacityPercentage = ((totalNumberOfBags - capacity) / totalNumberOfBags) * 100;
        }
    } else {
        confirmedCapacityPercentage = (numberOfConfirmedBags / capacity) * 100;
        unconfirmedCapacityPercentage = (numberOfUnconfirmedBags / capacity) * 100;
        overCapacityPercentage = 0;
    }
    const numberOfBagsOverCapacity = Math.max(0, totalNumberOfBags - capacity);
    const spaceRemaining = Math.max(0, capacity - totalNumberOfBags);
    return {
        confirmedCapacityPercentage,
        unconfirmedCapacityPercentage,
        overCapacityPercentage,
        numberOfBagsOverCapacity,
        spaceRemaining,
        totalNumberOfBags,
        isOverCapacity
    };
}
// function getCellData(confirmedPickups, unconfirmedPickups, capacity) {
//     // get percentage of confirmed/unconfirmed pickups with respect to capacity
//     const percentageOfConfirmedPickups = _.round((confirmedPickups / capacity) * 100);
//     const percentageOfUnconfirmedPickups = _.round((unconfirmedPickups / capacity) * 100);

//     const totalNumberOfPickups = confirmedPickups + unconfirmedPickups;

//     let capacityTaken = capacity;
//     let pickupsToReschedule = 0;

//     // if total pickups is over capacity, then we must start removing some pending pickups from the total
//     if (totalNumberOfPickups > capacity) {
//         // if confirmed takes up all or more of the capacity
//         if (confirmedPickups >= capacity) {
//             pickupsToReschedule = unconfirmedPickups; // all pending pickups will be rescheduled
//             capacityTaken = confirmedPickups; // keep all confirmed pickups on capacity
//         } else {
//             // else that means the rest of the space will be taken by postponed pickups
//             // some pending pickups will be moved
//             const pendingSpacesRemaining = capacity - confirmedPickups;
//             pickupsToReschedule = unconfirmedPickups - pendingSpacesRemaining;
//         }
//     } else {
//         // since under capacity, we assume this amount of it is taken
//         capacityTaken = totalNumberOfPickups;
//     }
//     // get % of the bar used for each color
//     const percentUsed = percentageOfConfirmedPickups + percentageOfUnconfirmedPickups;
//     const percentageLeftToUse = 100 - percentageOfConfirmedPickups;

//     const isOverCapacity = capacityTaken > capacity;

//     const heightOfConfirmedBar = isOverCapacity ? 100 : percentageOfConfirmedPickups;
//     const heightOfUnconfirmedBar = percentUsed > 100 ? percentageLeftToUse : percentageOfUnconfirmedPickups;

//     const spaceRemaining = capacity - capacityTaken;

//     return {
//         percentageOfConfirmedPickups,
//         heightOfConfirmedBar,
//         heightOfUnconfirmedBar,
//         pickupsToReschedule,
//         spaceRemaining,
//         capacityTaken,
//         isOverCapacity
//     };
// }
