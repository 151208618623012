import React, { useContext } from 'react';

import _ from 'lodash';
import moment from 'moment-timezone';

import Table from 'components/Table/Table';

import useTable from 'components/Table/useTable';

import { TableRow, TableCell, Icon, IconButton, Grid, Paper, Button, withTheme } from '@material-ui/core';

import AddIpDialog from './AddIpDialog';

import SnackbarContext from 'components/CustomSnackbar/SnackbarContext';
import ConfirmDialogContext from 'components/Dialogs/Confirm/ConfirmDialogContext';

import HttpContext from 'utils/contexts/HttpContext';
import { useState } from 'react';

const headers = [{ name: 'Actions', locKey: null }, { name: 'Ip', locKey: null }, { name: 'Created', locKey: null }];

function IPBlocking(props) {
    const { theme } = props;

    const [addDialogOpen, setAddDialogOpen] = useState(false);
    const [loadingCreate, setLoadingCreate] = useState(false);

    const warnAction = useContext(ConfirmDialogContext);

    const onSnackbar = useContext(SnackbarContext);
    const http = useContext(HttpContext);

    const {
        loading,
        getData,
        page,
        rowsPerPage,
        filteredData,
        search,
        filteredHeaders,
        totalDataEntries,
        handleChange,
        handleSearch,
        handleChangeFilterHeaders,
        handleAllFilterHeaders,
        handleChangePage,
        handleChangeRowsPerPage
    } = useTable({
        url: `/ipBlocking`,
        key: 'ips',
        headers: headers.map(h => h.name)
    });

    const handleCreate = async ip => {
        setLoadingCreate(true);
        const res = await http.post('/ipBlocking/create', { ip });
        if (res.ok) {
            onSnackbar('Successfully blocked ip');
        }
        setLoadingCreate(false);
        setAddDialogOpen(false);
        getData();
    };

    const handleDelete = async ipBlocking => {
        const res = await http.post('/ipBlocking/delete', { ip: ipBlocking.ip });
        if (res.ok) {
            onSnackbar('Successfully deleted ip');
            getData();
        }
    };

    const renderRow = (ipBlocking, filteredHeaders) => {
        return (
            <TableRow key={ipBlocking._id}>
                {filteredHeaders.includes('Actions') && (
                    <TableCell>
                        {
                            <IconButton
                                onClick={() =>
                                    warnAction(
                                        async () => await handleDelete(ipBlocking),
                                        'Are you sure you want to delete this?'
                                    )
                                }
                            >
                                <Icon>delete</Icon>
                            </IconButton>
                        }
                    </TableCell>
                )}
                {filteredHeaders.includes('Ip') && <TableCell>{_.get(ipBlocking, 'ip')}</TableCell>}
                {filteredHeaders.includes('Created') && (
                    <TableCell>
                        {moment(ipBlocking.createdAt)
                            .tz(process.env.REACT_APP_REGION_TIMEZONE)
                            .format('LT MMM Do, YYYY')}
                    </TableCell>
                )}
            </TableRow>
        );
    };

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <div style={{ padding: theme.spacing.unit * 2, paddingBottom: theme.spacing.unit }}>
                        <Paper
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                padding: theme.spacing.unit * 2,
                                height: '100%',
                                flexWrap: 'wrap'
                            }}
                        >
                            <Button variant="outlined" color="primary" onClick={() => setAddDialogOpen(true)}>
                                <Icon style={{ marginRight: theme.spacing.unit }}>add_circle_outline</Icon>Add New
                            </Button>
                        </Paper>
                    </div>
                </Grid>
            </Grid>
            <Table
                title="Blocked Ips"
                loading={loading}
                headers={headers}
                filteredHeaders={filteredHeaders}
                length={totalDataEntries}
                page={page}
                rowsPerPage={rowsPerPage}
                search={search}
                handleChange={handleChange}
                handleChangeFilterHeaders={handleChangeFilterHeaders}
                handleAllFilterHeaders={handleAllFilterHeaders}
                handleSearch={handleSearch}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                refresh={getData}
            >
                {filteredData &&
                    filteredData.map(data => {
                        return renderRow(data, filteredHeaders);
                    })}
            </Table>
            <AddIpDialog
                open={addDialogOpen}
                onClose={() => setAddDialogOpen(false)}
                loading={loadingCreate}
                onSubmit={handleCreate}
            />
        </>
    );
}

export default withTheme()(IPBlocking);
