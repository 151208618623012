import React, { useState, useContext } from 'react';

import _ from 'lodash';
import moment from 'moment-timezone';

import { Clipboard } from '@capacitor/clipboard';

import { getCustomerName, formatAsCurrency } from 'utils/misc';

import Button from '@material-ui/core/Button';
import {
    Checkbox,
    CircularProgress,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    FormControlLabel,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Toolbar as MuiToolbar
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import { Icon, IconButton, LinearProgress, colors, Avatar } from '@material-ui/core';

import { withTheme } from '@material-ui/core/styles';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';
import { useMemo } from 'react';

function RedemptionBreakdownDialog(props) {
    const { theme, trips, onSnackbar, open, onClose, selectedRedemption, tripsLoading } = props;
    const { lang } = useContext(LocalizationContext);
    const [showPossibleGasCost, setShowPossibleGasCost] = useState(false);
    const [possibleGasCost, setPossibleGasCost] = useState(false);
    const [tripsExpanded, setTripsExpanded] = useState(false);
    const [tipsExpanded, setTipsExpanded] = useState(false);

    const handleCopy = string => {
        try {
            onSnackbar(`Copied ${string} to your clipboard!`, 'success', 1000);
            //Remove $ from sting
            if (string[0] === '$') {
                string = string.substring(1);
            }
            Clipboard.write({ string });
        } catch (err) {
            onSnackbar('Unable to copy value to clipboard', 'error', 1000);
        }
    };

    useMemo(() => {
        let costMisMatch = (trips || []).reduce((state, trip) => {
            return state || trip.possibleGasCost;
        }, false);
        setPossibleGasCost(costMisMatch);
        setShowPossibleGasCost(costMisMatch && _.get(selectedRedemption, 'claimant.payOutGas'));
        setTipsExpanded(false);
        setTripsExpanded(false);
    }, [selectedRedemption, trips]);

    return (
        <Dialog open={open} onClose={onClose} fullWidth>
            <DialogTitle>
                {getCustomerName(selectedRedemption.claimant)} (
                {moment(selectedRedemption.completionDate).format('MMM Do, YYYY')}) -{' '}
                {formatAsCurrency(selectedRedemption.amount)}
            </DialogTitle>
            <DialogContent>
                <List style={{ marginTop: 0, marginBottom: theme.spacing.unit }}>
                    {_.get(selectedRedemption, 'trips', []).reduce((sum, trip) => {
                        return (sum += trip.payment.amount);
                    }, 0) > 0 && (
                        <>
                            <ListItem
                                disableGutters
                                style={{
                                    paddingTop: 0,
                                    paddingBottom: 0
                                }}
                            >
                                <ListItemText
                                    primary={
                                        <span style={{ fontWeight: 'bold' }}>
                                            Trips
                                            <IconButton
                                                onClick={() => setTripsExpanded(!tripsExpanded)}
                                                style={{ padding: 0, margin: 0 }}
                                            >
                                                <Icon style={{ padding: 0, margin: 0 }}>
                                                    {tripsExpanded ? 'expand_less' : 'expand_more'}
                                                </Icon>
                                            </IconButton>
                                        </span>
                                    }
                                />
                                <ListItemSecondaryAction>
                                    <Typography variant="body2" style={{ fontWeight: 'bold' }}>
                                        {formatAsCurrency(
                                            _.get(selectedRedemption, 'trips', []).reduce((sum, trip) => {
                                                return (sum += trip.payment.amount);
                                            }, 0)
                                        )}
                                    </Typography>
                                </ListItemSecondaryAction>
                            </ListItem>
                            {tripsLoading ? (
                                <CircularProgress
                                    size={22}
                                    thickness={4.8}
                                    style={{
                                        display: _.isNil(trips) ? '' : 'none',
                                        marginLeft: theme.spacing.unit / 2
                                    }}
                                />
                            ) : (
                                <Collapse in={tripsExpanded}>
                                    {possibleGasCost && (
                                        <ListItem disableGutters>
                                            <ListItemSecondaryAction>
                                                <FormControl style={{ marginBottom: theme.spacing.unit }} fullWidth>
                                                    <FormControlLabel
                                                        label={
                                                            <Typography
                                                                variant="caption"
                                                                style={{ color: colors.grey[700] }}
                                                            >
                                                                *Gas cost may have been included. Show possible gas
                                                                cost?
                                                            </Typography>
                                                        }
                                                        labelPlacement="start"
                                                        control={
                                                            <Checkbox
                                                                checked={showPossibleGasCost}
                                                                onChange={e => setShowPossibleGasCost(e.target.checked)}
                                                                style={{
                                                                    paddingTop: theme.spacing.unit,
                                                                    paddingBottom: theme.spacing.unit / 2,
                                                                    paddingLeft: 0,
                                                                    transform: 'scale(0.75)'
                                                                }}
                                                            />
                                                        }
                                                    />
                                                </FormControl>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    )}
                                    {trips.map((trip, index) => {
                                        return (
                                            trip.payment.amount > 0 && (
                                                <ExpandableRedemptionDetails trip={trip} handleCopy={handleCopy}>
                                                    {trip.payBreakdown.map(item =>
                                                        item.commodity.toString() !== 'gasCost' ||
                                                        (trip.payOutGas ||
                                                            (trip.possibleGasCost && showPossibleGasCost)) ? (
                                                            <ListItem
                                                                disableGutters
                                                                style={{
                                                                    paddingTop: 0,
                                                                    paddingBottom: 0,
                                                                    paddingLeft: theme.spacing.unit,
                                                                    paddingRight: theme.spacing.unit
                                                                }}
                                                            >
                                                                <ListItemText
                                                                    primary={
                                                                        <Typography
                                                                            variant="caption"
                                                                            style={{ color: colors.grey[700] }}
                                                                        >
                                                                            {item.label}
                                                                            {possibleGasCost &&
                                                                                item.commodity.toString() ===
                                                                                    'gasCost' &&
                                                                                '*'}
                                                                        </Typography>
                                                                    }
                                                                />

                                                                <ListItemSecondaryAction
                                                                    style={{
                                                                        paddingRight: theme.spacing.unit
                                                                    }}
                                                                >
                                                                    <Typography
                                                                        variant="caption"
                                                                        style={{ color: colors.grey[700] }}
                                                                    >
                                                                        {item.breakdown} = {formatAsCurrency(item.pay)}
                                                                    </Typography>
                                                                </ListItemSecondaryAction>
                                                            </ListItem>
                                                        ) : (
                                                            ''
                                                        )
                                                    )}
                                                </ExpandableRedemptionDetails>
                                            )
                                        );
                                    })}
                                </Collapse>
                            )}
                        </>
                    )}
                    {_.get(selectedRedemption, 'tips', []).reduce((sum, tip) => {
                        return (sum += tip.adjustedAmount);
                    }, 0) > 0 && (
                        <>
                            <ListItem
                                disableGutters
                                style={{
                                    paddingTop: 0,
                                    paddingBottom: 0
                                }}
                            >
                                <ListItemText
                                    primary={
                                        <span style={{ fontWeight: 'bold' }}>
                                            Tips
                                            <IconButton
                                                onClick={() => setTipsExpanded(!tipsExpanded)}
                                                style={{ padding: 0, margin: 0 }}
                                            >
                                                <Icon style={{ padding: 0, margin: 0 }}>
                                                    {tipsExpanded ? 'expand_less' : 'expand_more'}
                                                </Icon>
                                            </IconButton>
                                        </span>
                                    }
                                />
                                <ListItemSecondaryAction>
                                    <Typography variant="body2" style={{ fontWeight: 'bold' }}>
                                        {formatAsCurrency(
                                            _.get(selectedRedemption, 'tips', []).reduce((sum, tip) => {
                                                return (sum += tip.adjustedAmount * 100);
                                            }, 0)
                                        )}
                                    </Typography>
                                </ListItemSecondaryAction>
                            </ListItem>
                            <Collapse in={tipsExpanded}>
                                {_.get(selectedRedemption, 'tips', []).map(tip => (
                                    <>
                                        <ListItem disableGutters>
                                            <ListItemText
                                                primary={
                                                    <span
                                                        style={{
                                                            display: 'inline-flex',
                                                            fontWeight: 'bold',
                                                            color: colors.grey[700]
                                                        }}
                                                    >
                                                        {moment(tip.dateTipped).format('MMM Do, YYYY')} (
                                                        <span
                                                            style={{
                                                                cursor: 'pointer',
                                                                textDecoration: 'underline'
                                                            }}
                                                            onClick={() => handleCopy(tip._id)}
                                                        >
                                                            {tip._id}
                                                        </span>
                                                        )
                                                    </span>
                                                }
                                                on
                                            />
                                            <ListItemSecondaryAction>
                                                <Typography
                                                    variant="body2"
                                                    style={{ color: colors.grey[700], fontWeight: 'bold' }}
                                                >
                                                    {formatAsCurrency(tip.adjustedAmount * 100)}
                                                </Typography>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        <Divider variant="fullWidth" component="li" />
                                    </>
                                ))}
                            </Collapse>
                        </>
                    )}

                    {selectedRedemption.taxAmount > 0 && (
                        <>
                            <ListItem
                                disableGutters
                                style={{
                                    paddingTop: 0,
                                    paddingBottom: 0
                                }}
                            >
                                <ListItemText primary={<span style={{ fontWeight: 'bold' }}>Tax</span>} />
                                <ListItemSecondaryAction>
                                    <Typography variant="body2" style={{ fontWeight: 'bold' }}>
                                        {formatAsCurrency(selectedRedemption.taxAmount)}
                                    </Typography>
                                </ListItemSecondaryAction>
                            </ListItem>
                            <Divider variant="fullWidth" component="li" />
                        </>
                    )}
                    <ListItem
                        disableGutters
                        style={{
                            paddingTop: 0,
                            paddingBottom: 0
                        }}
                    >
                        <ListItemText primary={<span style={{ fontWeight: 'bold' }}>Total</span>} />
                        <ListItemSecondaryAction>
                            <Typography variant="body2" style={{ fontWeight: 'bold' }}>
                                {formatAsCurrency(
                                    _.get(selectedRedemption, 'trips', []).reduce((sum, trip) => {
                                        return (sum += trip.payment.amount);
                                    }, 0) +
                                        _.get(selectedRedemption, 'tips', []).reduce(
                                            (sum, tip) => (sum += tip.adjustedAmount * 100),
                                            0
                                        ) +
                                        _.get(selectedRedemption, 'taxAmount', 0)
                                )}
                            </Typography>
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}

const ExpandableRedemptionDetails = ({ children, trip, handleCopy }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    return (
        <>
            <ListItem disableGutters>
                <ListItemText
                    primary={
                        <span style={{ display: 'inline-flex', fontWeight: 'bold', color: colors.grey[700] }}>
                            {moment(trip.startTime).format('MMM Do, YYYY')} (
                            <span
                                style={{
                                    cursor: 'pointer',
                                    textDecoration: 'underline'
                                }}
                                onClick={() => handleCopy(trip._id)}
                            >
                                {trip._id}
                            </span>
                            )
                        </span>
                    }
                />
                <ListItemSecondaryAction>
                    <Typography variant="body2" style={{ color: colors.grey[700], fontWeight: 'bold' }}>
                        {formatAsCurrency(trip.payment.amount)}
                        <IconButton onClick={() => setIsExpanded(!isExpanded)} style={{ padding: 0, margin: 0 }}>
                            <Icon style={{ padding: 0, margin: 0 }}>{isExpanded ? 'expand_less' : 'expand_more'}</Icon>
                        </IconButton>
                    </Typography>
                </ListItemSecondaryAction>
            </ListItem>
            <Collapse in={isExpanded}>{children}</Collapse>

            <Divider variant="fullWidth" component="li" />
        </>
    );
};

export default withTheme()(RedemptionBreakdownDialog);
