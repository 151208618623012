import React from 'react';

import _ from 'lodash';

import { Typography, Button, withTheme } from '@material-ui/core';

import { FacebookShareButton, FacebookIcon } from 'react-share';

import CustomGrid from '../Components/CustomGrid';

import { deviceHelper } from 'utils/misc';
import { loc } from 'localizations/localizationHandler';

function FacebookDisplay({
    charity,
    referralLinkComponent,
    subject,
    message,
    shareUrl,
    urlToLink,
    hashtags,
    socialMediaHandle,
    examples,
    onNativeShare,
    onShare,
    theme,
    lang
}) {
    const linkStyle = { overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' };

    return (
        <>
            <Typography variant="body1" style={{ marginBottom: theme.spacing.unit }}>
                <>
                    <ol>
                        <li>
                            {loc('growthGeneral13', lang)}
                            <div style={linkStyle}>
                                {charity.approved ? referralLinkComponent : loc('growthGeneral17', lang)}
                            </div>
                        </li>
                        <li>
                            {loc('growthGeneral14', lang)} {urlToLink(process.env.REACT_APP_FACEBOOK_LINK)}
                        </li>
                        <li>{loc('growthGeneral15', lang)}</li>
                        <li>{loc('growthGeneral16', lang)}</li>
                    </ol>
                </>
            </Typography>
            <CustomGrid
                contentItems={[
                    {
                        image: examples[0] ? examples[0] : null,
                        text: (
                            <>
                                {loc('growthFacebook1', lang, { socialMediaHandle, charity: charity.name })}
                                <br />
                                <br />
                                <div style={linkStyle}>{referralLinkComponent}</div>
                            </>
                        )
                    },
                    {
                        image: examples[1] ? examples[1] : null,
                        text: (
                            <>
                                {loc('growthFacebook2', lang, { socialMediaHandle })}
                                <br />
                                <br />
                                <div style={linkStyle}>{referralLinkComponent}</div>
                            </>
                        )
                    },
                    {
                        image: examples[2] ? examples[2] : null,
                        text: (
                            <>
                                {loc('growthFacebook3', lang, { socialMediaHandle })}

                                <br />
                                <br />
                                <div style={linkStyle}>{referralLinkComponent}</div>
                            </>
                        )
                    },
                    {
                        image: examples[3] ? examples[3] : null,
                        text: (
                            <>
                                {loc('growthFacebook4', lang, { socialMediaHandle, charity: charity.name })}
                                <br />
                                <br />
                                <div style={linkStyle}>{referralLinkComponent}</div>
                            </>
                        )
                    }
                ]}
            />
        </>
    );
}

export default withTheme()(FacebookDisplay);
