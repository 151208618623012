import React, { useContext } from 'react';

import _ from 'lodash';

import { containsErrors } from '../../../utils/validate';

import {
    Button,
    DialogActions,
    DialogContent,
    DialogContentText,
    TextField,
    InputAdornment,
    Icon,
    IconButton
} from '@material-ui/core';

import CustomFormTitle from 'components/MaterialUIExtensions/CustomFormTitle';
import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

const BankTransfer = props => {
    const {
        askForPreviousBankInfo,
        theme,
        banking,
        handleUsePreviousBankingInformation,
        redemptionForm,
        handleChange,
        usePreviousBankingInfo,
        formErrors,
        donationDisabled,
        handleBack,
        handleNext
    } = props;
    const { lang } = useContext(LocalizationContext);
    return (
        <>
            <DialogContent>
                <CustomFormTitle titleText={loc('redemption40', lang)} iconName="account_balance_wallet" />
                {askForPreviousBankInfo ? (
                    <DialogContentText>
                        {loc('redemption64', lang)}
                        <br />
                        <br />
                        {loc('redemption41', lang)}:{' '}
                        <span style={{ color: theme.palette.text.primary }}>
                            {_.get(banking, 'accountNumberMasked', 'N/A')}
                        </span>
                        <Button
                            color="primary"
                            size="small"
                            style={{
                                position: 'relative',
                                top: -2,
                                marginLeft: theme.spacing.unit / 2
                            }}
                            onClick={() => handleUsePreviousBankingInformation(false)}
                        >
                            {loc('change', lang)}
                        </Button>
                    </DialogContentText>
                ) : (
                    <>
                        <TextField
                            fullWidth
                            data-cy="bank-transfer-accountNumber-input"
                            name="accountNumber"
                            label={loc('redemption41', lang)}
                            value={_.get(redemptionForm, 'accountNumber', '')}
                            error={formErrors['accountNumber'].fail}
                            helperText={formErrors['accountNumber'].reason}
                            type="tel"
                            variant="outlined"
                            style={{ marginBottom: theme.spacing.unit * 2 }}
                            onChange={handleChange}
                        />
                        <TextField
                            fullWidth
                            data-cy="bank-transfer-transitNumber-input"
                            name="transitNumber"
                            label={loc('redemption43', lang)}
                            value={_.get(redemptionForm, 'transitNumber', '')}
                            error={formErrors['transitNumber'].fail}
                            helperText={formErrors['transitNumber'].reason}
                            type="tel"
                            variant="outlined"
                            style={{ marginBottom: theme.spacing.unit * 2 }}
                            onChange={handleChange}
                        />
                        <TextField
                            fullWidth
                            data-cy="bank-transfer-institutionNumber-input"
                            name="institutionNumber"
                            label={loc('redemption45', lang)}
                            value={_.get(redemptionForm, 'institutionNumber', '')}
                            error={formErrors['institutionNumber'].fail}
                            helperText={formErrors['institutionNumber'].reason}
                            type="tel"
                            variant="outlined"
                            style={{ marginBottom: theme.spacing.unit * 2 }}
                            onChange={handleChange}
                        />
                    </>
                )}
            </DialogContent>
            <DialogActions style={{ marginBottom: 'max(8px, env(safe-area-inset-bottom, 8px))' }}>
                {!donationDisabled && (
                    <Button color="primary" onClick={handleBack}>
                        {loc('back', lang)}
                    </Button>
                )}
                <Button
                    data-cy="redemption-dialog-next"
                    color="primary"
                    disabled={containsErrors(formErrors) && !usePreviousBankingInfo}
                    onClick={handleNext}
                >
                    {loc('next', lang)}
                </Button>
            </DialogActions>
        </>
    );
};

export default BankTransfer;
