import React from 'react';
import _ from 'lodash';
import {
    Icon,
    Grid,
    Paper,
    Divider,
    Avatar,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography
} from '@material-ui/core';
import * as colors from '@material-ui/core/colors';
import { withTheme } from '@material-ui/core/styles';

const IconCard = ({ theme, loading, color, icon, title, data, width = 4 }) => {
    const cellStyle = { borderBottom: 'none' };

    const getTableRows = (data, indentLevel = 0) => {
        return data.map(({ name, value, title, subValues, formatName = null }, i) => {
            return (
                <React.Fragment key={i}>
                    <TableRow title={title} style={{ height: 32 }} key={i}>
                        <TableCell style={cellStyle}>
                            <span style={{ marginLeft: theme.spacing.unit * 2 * indentLevel }}>
                                {!_.isNil(formatName) ? formatName(name) : name}
                            </span>
                        </TableCell>
                        {Array.isArray(value) ? (
                            value.map((val, i) => (
                                <TableCell
                                    key={i}
                                    align="right"
                                    style={cellStyle}
                                    data-cy={`admin-dashboard-${name.replace(/[, ]+/g, '-').trim()}-${i}`}
                                >
                                    {val}
                                </TableCell>
                            ))
                        ) : (
                            <TableCell
                                align="right"
                                style={cellStyle}
                                data-cy={`admin-dashboard-${name.replace(/[, ]+/g, '-').trim()}`}
                            >
                                {typeof value === 'string' && value.startsWith('-') ? (
                                    <span style={{ color: colors.red[500] }}>{value}</span>
                                ) : (
                                    <span>{value}</span>
                                )}
                            </TableCell>
                        )}
                    </TableRow>
                    {!_.isNil(subValues) && getTableRows(subValues, indentLevel + 1)}
                </React.Fragment>
            );
        });
    };

    return (
        <Grid item xs={12} md={width}>
            <Paper style={{ padding: theme.spacing.unit * 2, height: '100%' }}>
                <Grid container spacing={theme.spacing.unit * 2}>
                    <Grid item style={{ flexGrow: 1 }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div style={{ marginRight: theme.spacing.unit * 2 }}>
                                {loading ? (
                                    <CircularProgress size={40} style={{ marginTop: 8, color: colors[color][500] }} />
                                ) : (
                                    <Avatar
                                        style={{
                                            marginTop: theme.spacing.unit,
                                            backgroundColor: colors[color][500]
                                        }}
                                    >
                                        <Icon>{icon}</Icon>
                                    </Avatar>
                                )}
                            </div>
                            <div style={{ flex: 1 }}>
                                <Typography variant="h6">{title}</Typography>
                                <Divider
                                    style={{ marginTop: theme.spacing.unit / 2, marginBottom: theme.spacing.unit }}
                                />
                            </div>
                        </div>

                        <Table padding="none">
                            <TableBody>{data && getTableRows(data)}</TableBody>
                        </Table>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
};

export default withTheme()(IconCard);

/*
example data prop
[
    {   name: "ONE",
        value: 1,
        title: "ONE"},
    {   name: "TWO",
        value: 2,
        title: "TWO"},
]
*/
