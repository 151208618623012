import React, { Fragment, useState } from 'react';

import _ from 'lodash';
import TextField from '@material-ui/core/TextField';

import MaskedInput from 'react-text-mask';

import { withTheme } from '@material-ui/core/styles';
import { FormControl, InputAdornment, IconButton, Icon, Typography } from '@material-ui/core';

import HelpDialog from 'components/HelpDialog/HelpDialog';

const helpDialogObjects = [
    {
        title: 'Bank State Branch ("BSB")',
        body: (
            <Typography variant="body1">
                A six-digit number used to identify the branch of a financial institution. It can be found when you sign into your online banking portal.
            </Typography>
        )
    },
    {
        title: 'Account number',
        body: (
            <div>
                <Typography variant="body1">
                    A 6-9 digit number that is correlated to your BSB. If it is less than 6 digits, verify with your bank if you can pad it with leading zeroes
                    (e.g. 001234).
                    <br />
                    <br />
                    Contact your bank if you are having issues finding your information.
                </Typography>
                {/* <br />
                <Typography variation="caption" color="secondaryText">
                    We currently do not accept alphanumeric account numbers.
                </Typography> */}
            </div>
        )
    },
    {
        title: 'Account name',
        body: <Typography variant="body1">The name of the account.</Typography>
    }
];

const AustralianFinancialInfoInputs = props => {
    const { theme, bsbNumber, accountNumber, accountName, formErrors } = props;

    const [openHelpDialog, setOpenHelpDialog] = useState(false);
    const [helpDialogIndex, setHelpDialogIndex] = React.useState(0);

    const handleOpenHelpDialog = key => () => {
        setOpenHelpDialog(true);
        setHelpDialogIndex(key);
    };

    const handleCloseHelpDialog = () => {
        setOpenHelpDialog(false);
    };

    return (
        <Fragment>
            <FormControl fullWidth>
                <TextField
                    data-cy="australian-financial-info-bsbNumber-input"
                    name="bsbNumber"
                    label="BSB number"
                    value={bsbNumber}
                    error={formErrors['bsbNumber'].fail}
                    helperText={formErrors['bsbNumber'].reason}
                    type="tel"
                    variant="outlined"
                    style={{ marginBottom: theme.spacing.unit * 2 }}
                    InputProps={{
                        inputComponent: BSBMask,
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton size="small" onClick={handleOpenHelpDialog(0)}>
                                    <Icon>help</Icon>
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                    onChange={props.onChange}
                />
            </FormControl>
            <FormControl fullWidth>
                <TextField
                    data-cy="australian-financial-info-accountNumber-input"
                    name="accountNumber"
                    label="Account number"
                    value={accountNumber}
                    error={formErrors['accountNumber'].fail}
                    helperText={formErrors['accountNumber'].reason}
                    type="number"
                    variant="outlined"
                    style={{ marginBottom: theme.spacing.unit * 2 }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton size="small" onClick={handleOpenHelpDialog(1)}>
                                    <Icon>help</Icon>
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                    onChange={props.onChange}
                />
            </FormControl>
            <FormControl fullWidth>
                <TextField
                    data-cy="australian-financial-info-accountName-input"
                    name="accountName"
                    label="Account name"
                    value={accountName}
                    error={formErrors['accountName'].fail}
                    helperText={formErrors['accountName'].reason}
                    type="text"
                    variant="outlined"
                    // InputProps={{
                    //     endAdornment: (
                    //         <InputAdornment position="end">
                    //             <IconButton size="small" onClick={handleOpenHelpDialog(2)}>
                    //                 <Icon>help</Icon>
                    //             </IconButton>
                    //         </InputAdornment>
                    //     )
                    // }}
                    onChange={props.onChange}
                />
            </FormControl>
            <HelpDialog
                open={openHelpDialog}
                title={helpDialogObjects[helpDialogIndex].title}
                body={helpDialogObjects[helpDialogIndex].body}
                onClose={handleCloseHelpDialog}
            />
        </Fragment>
    );
};
export default withTheme()(AustralianFinancialInfoInputs);

const BSBMask = props => {
    const { inputRef, ...other } = props;

    const onKeyDown = e => {
        const { onChange } = props;

        if (Number(e.target.selectionStart) === 4 && Number(e.keyCode) === 8) {
            // Disable text mask events for this cursor positions
            e.preventDefault();

            e.target.value = e.target.value || '';
            e.target.value = e.target.value.replace(/\D+/g, '');

            // Simulate backspace delete
            e.target.value = e.target.value.substring(0, e.target.value.length - 1);

            if (onChange instanceof Function) onChange(e);
        }
    };

    return (
        <MaskedInput
            {...other}
            ref={ref => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
            placeholderChar={'\u2000'}
            showMask
            guide={false}
            keepCharPositions={false}
            onKeyDown={onKeyDown}
            placeholder={'XXX-XXX'}
        />
    );
};
