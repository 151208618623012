import React, { Component, useContext } from 'react';

import _ from 'lodash';

import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import {
    withTheme,
    MenuItem,
    TextField,
    ListItemText,
    FormControlLabel,
    FormControl,
    InputLabel,
    Select,
    OutlinedInput
} from '@material-ui/core';

import * as terms from 'localizations/terms';
import { _charity } from 'std';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

function CharityInfo({ theme, charity, style, subdivision, onSubdivision }) {
    const { lang } = useContext(LocalizationContext);
    if (_.isNil(charity)) {
        return null;
    }

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                position: 'relative',
                width: '100%',
                padding: theme.spacing.unit * 2,
                backgroundColor: theme.palette.background.default,
                textAlign: 'center',
                ...style
            }}
        >
            <img
                src={charity.logo}
                alt={loc('dashboardSetting10', lang, { organization: terms.ORGANIZATION_NAME })}
                style={{
                    margin: `${theme.spacing.unit}px auto`,
                    marginTop: 0,
                    height: 60
                }}
            />
            <Typography variant="h6">{charity.name}</Typography>
            <Typography style={{ marginTop: theme.spacing.unit * 2 }}>{charity.mission}</Typography>
            <Typography component="a" href={charity.website} target="_blank" style={{ marginTop: theme.spacing.unit }}>
                {charity.website}
            </Typography>
            <Typography
                variant="body2"
                style={{
                    fontSize: '0.75rem',
                    fontStyle: 'italic',
                    marginTop: theme.spacing.unit * 3,
                    color: 'rgba(0, 0, 0, 0.6)'
                }}
            >
                {loc('growthGeneral37', lang, { organization: terms.ORGANIZATION_NAME.toLowerCase() })}
            </Typography>
            {!_.isEmpty(_charity.getSubdivisions(charity)) && (
                <FormControl
                    data-cy="charity-select-select-dropdown"
                    fullWidth
                    style={{ marginTop: theme.spacing.unit * 2 }}
                >
                    <InputLabel variant="outlined" htmlFor="subdivisionPreferred">
                        Team/group/bottle drive
                    </InputLabel>
                    <Select
                        value={subdivision ? subdivision : 'General Donation'}
                        renderValue={value => `${value}`}
                        onChange={e => onSubdivision(e.target.value)}
                        input={
                            <OutlinedInput
                                labelWidth={180}
                                name="subdivisionPreferred"
                                id="subdivisionPreferred"
                                data-cy="charity-select-team-dropdown"
                                className="select-icon"
                            />
                        }
                        style={{ textAlign: 'left', backgroundColor: theme.palette.background.paper }}
                    >
                        <MenuItem value={null}>
                            <ListItemText primary="General Donation" style={{ fontStyle: 'italic' }} />
                        </MenuItem>
                        {_charity.getSubdivisions(charity).map(subdivision => (
                            <MenuItem key={subdivision} value={subdivision}>
                                {subdivision}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            )}

            <Divider style={{ marginTop: theme.spacing.unit * 3 }} />
        </div>
    );
}

export default withTheme()(CharityInfo);
