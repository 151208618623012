import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash';

import { DialogActions, Dialog, DialogContent, Button, withTheme } from '@material-ui/core';

import RatesDisplay from 'components/RatesDisplay';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

function RatingPlatformInput(props) {
    const { initialRate, commodities, onSubmit, ratesObj, open, onClose, onEditRates } = props;

    const { lang } = useContext(LocalizationContext);
    const rates = _.get(ratesObj, 'rates');

    const [selectedRate, setSelectedRate] = useState(initialRate);

    useEffect(() => {
        setSelectedRate(initialRate);
    }, [open, initialRate]);

    return (
        <Dialog open={open} fullWidth maxWidth="lg" PaperProps={{ style: { height: '80vh' } }}>
            <DialogContent style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <RatesDisplay
                    rates={rates}
                    defaultRateId={_.get(ratesObj, 'masterRatesId')}
                    commodities={commodities}
                    ratesSelectable
                    ratesSelectedId={selectedRate}
                    onRatesSelected={ratesId => setSelectedRate(ratesId)}
                    readOnlyRates={true}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>{loc('close', lang)}</Button>
                <Button onClick={onEditRates}>Edit Rates</Button>
                <Button
                    color="primary"
                    onClick={() => {
                        onSubmit(selectedRate);
                        onClose();
                    }}
                >
                    {loc('submit', lang)}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default withTheme()(RatingPlatformInput);
