import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';

import { _bulk } from 'std';

import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, withTheme } from '@material-ui/core';
import { Typography, TextField, Button } from '@material-ui/core';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc, locDate } from 'localizations/localizationHandler';

function MessageCollectorDialog(props) {
    const { open, onClose, initialSubject, initialBody, customerInfo, collectorId, http, theme } = props;

    const [subject, setSubject] = useState(initialSubject);
    const [body, setBody] = useState(initialBody);

    const { lang } = useContext(LocalizationContext);

    useEffect(() => {
        setSubject(initialSubject);
        setBody(initialBody);
    }, [open]);

    const bagtag = _.get(customerInfo, 'bagtag', '');
    const bulkType = _.get(customerInfo, 'bulkType');
    const date = locDate(_.get(customerInfo, 'date', ''), 'MMM Do YYYY', lang);

    let payloadInfoString = '';
    if (_bulk.bulkTypeIsQuickDrop(bulkType)) {
        payloadInfoString = `${_.get(customerInfo, 'payload', '')} dropped off on ${date} at ${locDate(
            _.get(customerInfo, 'date', ''),
            'h:mma',
            lang
        )}`;
    } else if (_bulk.bulkTypeIsPickup(bulkType)) {
        if (!_.get(customerInfo, 'pickupCompleted', false)) {
            payloadInfoString = `${_.get(customerInfo, 'payload', '')} to be picked up from ${_.get(
                customerInfo,
                'address',
                ''
            )} on ${date} by ${_.get(customerInfo, 'employeeName', '')}`;
        } else if (!_.get(customerInfo, 'aborted', false)) {
            payloadInfoString = `${_.get(customerInfo, 'payload', '')} picked up from ${_.get(
                customerInfo,
                'address',
                ''
            )} on ${date} by ${_.get(customerInfo, 'employeeName', '')}`;
        } else {
            payloadInfoString = `${_.get(customerInfo, 'payload', '')}  from ${_.get(
                customerInfo,
                'address',
                ''
            )} aborted by ${_.get(customerInfo, 'employeeName', '')} on ${date}`;
        }
    } else {
        payloadInfoString = `${_.get(customerInfo, 'payload', '')}  adjustment created by ${_.get(
            customerInfo,
            'employeeName',
            ''
        )} on ${date}`;
    }

    const handleSendMessage = async () => {
        await http.post(`/collectors/${collectorId}/messageCollector`, {
            subject: subject,
            messageBody: body,
            customerInfo: {
                depot: _.get(customerInfo, 'collectorName', ''),
                customerId: _.get(customerInfo, 'customerUniqueID', ''),
                bagtag: _.get(customerInfo, 'bagtag', ''),
                payloadInfo: payloadInfoString
            }
        });
        onClose();
    };

    return (
        <Dialog open={open} maxWidth="md" fullWidth onClose={onClose}>
            <DialogTitle>Message Collector</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Typography variant="h6">Subject</Typography>
                    <TextField
                        value={subject}
                        onChange={e => setSubject(e.target.value)}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                    />

                    <Typography variant="h6" style={{ marginBottom: theme.spacing.unit }}>
                        Message
                    </Typography>

                    <Typography>
                        <b>Depot:</b> {_.get(customerInfo, 'collectorName', '')}
                    </Typography>

                    <Typography>
                        <b>Customer ID:</b> {_.get(customerInfo, 'customerUniqueID', '')}
                    </Typography>

                    <Typography>
                        {!_.isEmpty(bagtag) && (
                            <>
                                <b>Bagtag:</b> {_.get(customerInfo, 'bagtag', '')}
                            </>
                        )}{' '}
                        <b>Payload:</b> {payloadInfoString}
                    </Typography>

                    <TextField
                        value={body}
                        onChange={e => setBody(e.target.value)}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        multiline
                    />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleSendMessage} color="primary">
                    Send Message
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default withTheme()(MessageCollectorDialog);
