import * as qs from 'query-string';
import _ from 'lodash';

// https://medium.com/swlh/using-react-hooks-to-sync-your-component-state-with-the-url-query-string-81ccdfcb174f

const setQueryStringWithoutPageReload = qsValue => {
    const newurl = window.location.protocol + '//' + window.location.host + window.location.pathname + qsValue;
    window.history.replaceState({ path: newurl }, '', newurl);
};

export const setQueryStringValue = (key, value, queryString = window.location.search) => {
    const values = qs.parse(queryString);

    let newValue = value;

    if (_.isArray(value)) {
        newValue = value.join(',');
    }

    const newQsValue = qs.stringify({ ...values, [key]: newValue });
    setQueryStringWithoutPageReload(`?${newQsValue}`);
};

export const getQueryStringValue = (key, queryString = window.location.search) => {
    const values = qs.parse(queryString);
    return values[key];
};
