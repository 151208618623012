import React, { useState, useContext, useEffect } from 'react';

import _ from 'lodash';
import { formatAsCurrency } from 'utils/misc';

import { getCustomerFirstNameAndLastInitial } from 'utils/misc';

import DialogTitlePrimary from 'components/MaterialUIExtensions/DialogTitlePrimary';
import OperatorContext from 'utils/contexts/OperatorContext';

import ReactJson from 'react-json-view';

import {
    colors,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    DialogContent,
    DialogActions,
    Dialog,
    Typography,
    withMobileDialog,
    CircularProgress,
    Divider,
    Collapse,
    IconButton,
    Icon,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Paper
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

import { withTheme } from '@material-ui/core/styles';
import { Icon as MDIcon } from '@mdi/react';
import { mdiCheckboxMarkedCircleOutline, mdiCodeJson, mdiTagArrowDown } from '@mdi/js';

import bulkHelper from 'helpers/bulkHelper';
import ConfirmDialogContext from 'components/Dialogs/Confirm/ConfirmDialogContext';
import { _bulk, _user } from 'std';

import CustomDebugDialog from 'components/CustomDebugDialog/CustomDebugDialog';

function BulkErrorDialog(props) {
    const { theme, http, bulk, open, operator, inProgress, refreshBulk, onClose } = props;

    const tableCellStyles1 = { width: '75%' };
    const tableCellStyles2 = { width: '25%' };
    const tableCellStyles = { padding: 0, color: theme.palette.text.secondary, borderBottom: 'none' };

    const [bagData, setBagData] = useState({});

    const [history, setHistory] = useState({});
    const [showHistory, setShowHistory] = useState(false);

    const [expandedItems, setExpandedItems] = useState([]);
    const [triggerPulse, setTriggerPulse] = useState(-1);

    const [allErrorsResolved, setAllErrorsResolved] = useState(false);

    const acceptCountsForError = (bagtag, errorIdx) => {
        const newBagData = _.cloneDeep(bagData);
        newBagData[bagtag].errors[errorIdx].resolution = 'accept';

        setBagData(newBagData);
    };

    const rejectCountsForError = (bagtag, errorIdx) => {
        const newBagData = _.cloneDeep(bagData);
        newBagData[bagtag].errors[errorIdx].resolution = 'reject';

        setBagData(newBagData);
    };

    const setMode = (bagtag, mode) => {
        if (_.isEmpty(bagData)) {
            return;
        }

        const bagDataClone = _.cloneDeep(bagData);
        bagDataClone[bagtag].mode = mode;

        setBagData(bagDataClone);
    };

    const handlePulse = bagtag => {
        setTriggerPulse(bagtag);
        setTimeout(() => setTriggerPulse(-1), 500);
    };

    const toggleExpandItem = expandItem => {
        if (expandedItems.includes(expandItem)) {
            setExpandedItems(_.filter(expandedItems, item => item != expandItem));
        } else {
            setExpandedItems([...expandedItems, expandItem]);
        }
    };

    const loadErrors = async () => {
        const res = await http.getJSON(`/bulks/${bulk._id}/detailedErrors`);

        if (res.ok) {
            const bulkErrors = _.get(res, 'data.bulk.bulkErrors', []);
            const unresolvedBulkErrors = _.filter(bulkErrors, error => !error.isResolved);
            for (let error of unresolvedBulkErrors) {
                error.resolution = null;
            }

            const groupedBulkErrors = {};
            for (let error of unresolvedBulkErrors) {
                const bagtag = error.bagtag;
                if (!bagtag) {
                    // TODO: ??
                    continue;
                }

                if (groupedBulkErrors[bagtag]) {
                    groupedBulkErrors[bagtag].errors.push(error);
                } else {
                    groupedBulkErrors[bagtag] = {
                        errors: [error],
                        mode: 'add'
                    };
                }
            }

            setBagData(groupedBulkErrors);
        }
    };

    const handleResolveErrors = async () => {
        const submissionData = {};
        for (let bagtag of Object.keys(bagData)) {
            const data = bagData[bagtag];
            submissionData[bagtag] = {};
            submissionData[bagtag].mode = data.mode;
            submissionData[bagtag].errors = data.errors.map(error => {
                return { history: _.get(error, 'history._id'), resolution: error.resolution };
            });
        }

        const res = await http.postJSON(`/bulks/${bulk._id}/resolveErrors`, { bagData: submissionData });

        if (res.ok) {
            setHistory({});
            setShowHistory(false);
            onClose();

            refreshBulk();
        }
    };

    useEffect(() => {
        loadErrors();
    }, []);

    useEffect(() => {
        const bagtagsWithErrors = Object.keys(bagData);
        if (_.isEmpty(bagtagsWithErrors)) {
            return;
        }

        for (let bagtag of bagtagsWithErrors) {
            const errors = bagData[bagtag].errors;
            for (let error of errors) {
                if (!error.resolution) {
                    setAllErrorsResolved(false);
                    return;
                }
            }
        }

        setAllErrorsResolved(true);
    }, [bagData]);

    const convertCounts = counts => {
        const rates = _.get(bulk, 'rates.rates', []);

        const convertedCounts = [];
        for (let count of counts) {
            const sku = count.sku;
            const rate = _.find(rates, rate => rate.sku == sku);
            const description = _.get(rate, 'descriptions.en', _.get(rate, 'description'));
            const superGroup = count.superGroup;

            const amount = count.quantity || 1;
            const refund = _.get(rate, 'value', 0);
            const countTotal = Number(amount) * Number(refund);

            convertedCounts.push({
                description,
                total: countTotal
                // isModified,
                // error,
                // sku,
                // quantity: amount,
                // superGroup
            });
        }
        return convertedCounts;
    };

    const aggregateCounts = counts => {
        const aggregatedCounts = {};
        for (let count of counts) {
            const description = count.description || 'Unknown';
            const refundAmount = count.total || 0;

            const mappedCount = aggregatedCounts[description];

            aggregatedCounts[description] = mappedCount ? mappedCount + refundAmount : refundAmount;
        }

        return Object.keys(aggregatedCounts).map(key => {
            return { description: key, total: aggregatedCounts[key] };
        });
    };

    return (
        <>
            <Dialog data-cy="multi-step-dialog" fullWidth open={open}>
                <DialogTitlePrimary>Errors</DialogTitlePrimary>
                <DialogContent>
                    {Object.keys(bagData).map((bagtag, idx) => {
                        const errors = bagData[bagtag].errors;
                        let hasUnresolvedErrors = false;
                        for (let error of errors) {
                            if (!error.resolution) {
                                hasUnresolvedErrors = true;
                                break;
                            }
                        }

                        const mode = bagData[bagtag].mode;

                        const countingSession = _.find(bulk.countingSessions, session => session.bagtag == bagtag);
                        const currentCounts = convertCounts(_.get(countingSession, 'items', []));

                        let totalRefund = 0;
                        for (let count of currentCounts) {
                            totalRefund += count.total;
                        }

                        const newCounts = bagData[bagtag].mode == 'add' ? _.cloneDeep(currentCounts) || [] : [];
                        for (let error of errors) {
                            if (error.resolution == 'accept') {
                                const items = _.get(error, 'history.data.reqBody.counts', []);
                                newCounts.push(...convertCounts(items));
                            }
                        }

                        let newTotalRefund = 0;
                        for (let count of newCounts) {
                            newTotalRefund += count.total;
                        }

                        return (
                            <>
                                {idx != 0 && <Divider />}
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}
                                    >
                                        {hasUnresolvedErrors && (
                                            <Icon
                                                style={{
                                                    marginRight: theme.spacing.unit,
                                                    color: colors.red[400],
                                                    fontSize: '24px'
                                                }}
                                            >
                                                error
                                            </Icon>
                                        )}
                                        {!hasUnresolvedErrors && (
                                            <Icon
                                                style={{
                                                    marginRight: theme.spacing.unit,
                                                    color: colors.green[400],
                                                    fontSize: '24px'
                                                }}
                                            >
                                                check_circle
                                            </Icon>
                                        )}
                                        <Typography style={{ marginLeft: theme.spacing.unit }}>
                                            [BAGTAG] {bagtag}
                                        </Typography>
                                    </div>
                                    <IconButton
                                        style={{ margin: theme.spacing.unit }}
                                        onClick={() => toggleExpandItem(idx)}
                                    >
                                        <Icon>
                                            {expandedItems.includes(idx) ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
                                        </Icon>
                                    </IconButton>
                                </div>
                                <Collapse in={expandedItems.includes(idx)}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            marginBottom: theme.spacing.unit * 2
                                        }}
                                    >
                                        <Paper style={{ width: '50%', marginRight: theme.spacing.unit / 2 }}>
                                            <Table style={{ borderCollapse: 'separate', borderSpacing: 5 }}>
                                                <TableBody>
                                                    <TableRow style={{ height: 20 }}>
                                                        <TableCell
                                                            colSpan={2}
                                                            style={{
                                                                ...tableCellStyles,
                                                                ...tableCellStyles1,
                                                                paddingTop: 0,
                                                                paddingBottom: 0,
                                                                fontWeight: 'bold',
                                                                textAlign: 'left'
                                                            }}
                                                        >
                                                            Current Counts
                                                        </TableCell>
                                                    </TableRow>
                                                    {aggregateCounts(currentCounts || []).map(currentCount => {
                                                        const description = currentCount.description;
                                                        const countRefund = currentCount.total;

                                                        return (
                                                            <TableRow style={{ height: 20 }}>
                                                                <TableCell
                                                                    style={{
                                                                        ...tableCellStyles,
                                                                        ...tableCellStyles1,
                                                                        paddingTop: 0,
                                                                        paddingBottom: 0,
                                                                        textAlign: 'left'
                                                                    }}
                                                                >
                                                                    {description}
                                                                </TableCell>
                                                                <TableCell
                                                                    style={{
                                                                        ...tableCellStyles,
                                                                        ...tableCellStyles2,
                                                                        paddingTop: 0,
                                                                        paddingBottom: 0,
                                                                        textAlign: 'right'
                                                                    }}
                                                                >
                                                                    {formatAsCurrency(Math.abs(countRefund))}
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    })}
                                                    <TableRow style={{ height: 0 }}>
                                                        <TableCell colSpan={2} style={{ padding: 0 }}>
                                                            <Divider />
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow style={{ height: 20 }}>
                                                        <TableCell
                                                            style={{
                                                                ...tableCellStyles,
                                                                ...tableCellStyles1,
                                                                paddingTop: 0,
                                                                paddingBottom: 0,
                                                                fontWeight: 'bold',
                                                                textAlign: 'left'
                                                            }}
                                                        >
                                                            Total
                                                        </TableCell>
                                                        <TableCell
                                                            style={{
                                                                ...tableCellStyles,
                                                                ...tableCellStyles2,
                                                                paddingTop: 0,
                                                                paddingBottom: 0,
                                                                textAlign: 'right'
                                                            }}
                                                        >
                                                            {formatAsCurrency(Math.abs(totalRefund))}
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </Paper>
                                        <Paper
                                            className={triggerPulse == bagtag ? 'pulse-effect' : ''}
                                            style={{ width: '50%', marginLeft: theme.spacing.unit / 2 }}
                                        >
                                            <Table style={{ borderCollapse: 'separate', borderSpacing: 5 }}>
                                                <TableBody>
                                                    <TableRow style={{ height: 20 }}>
                                                        <TableCell
                                                            colSpan={2}
                                                            style={{
                                                                ...tableCellStyles,
                                                                ...tableCellStyles1,
                                                                paddingTop: 0,
                                                                paddingBottom: 0,
                                                                fontWeight: 'bold',
                                                                textAlign: 'left'
                                                            }}
                                                        >
                                                            New Counts
                                                        </TableCell>
                                                    </TableRow>

                                                    {aggregateCounts(newCounts || []).map(currentCount => {
                                                        const description = currentCount.description;
                                                        const newCountRefund = currentCount.total;
                                                        // const modified = currentCount.isModified;

                                                        // May want to aggregate the counts here...

                                                        return (
                                                            <TableRow
                                                                style={{
                                                                    height: 20
                                                                }}
                                                            >
                                                                <TableCell
                                                                    style={{
                                                                        ...tableCellStyles,
                                                                        ...tableCellStyles1,
                                                                        paddingTop: 0,
                                                                        paddingBottom: 0,
                                                                        textAlign: 'left'
                                                                        // color: modified
                                                                        //     ? colors.green[500]
                                                                        //     : 'rgba(0, 0, 0, 0.54)'
                                                                    }}
                                                                >
                                                                    {description}
                                                                </TableCell>
                                                                <TableCell
                                                                    style={{
                                                                        ...tableCellStyles,
                                                                        ...tableCellStyles2,
                                                                        paddingTop: 0,
                                                                        paddingBottom: 0,
                                                                        textAlign: 'right'
                                                                        // color: modified
                                                                        //     ? colors.green[500]
                                                                        //     : 'rgba(0, 0, 0, 0.54)'
                                                                    }}
                                                                >
                                                                    {formatAsCurrency(Math.abs(newCountRefund))}
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    })}
                                                    <TableRow style={{ height: 0 }}>
                                                        <TableCell colSpan={2} style={{ padding: 0 }}>
                                                            <Divider />
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow style={{ height: 20 }}>
                                                        <TableCell
                                                            style={{
                                                                ...tableCellStyles,
                                                                ...tableCellStyles1,
                                                                paddingTop: 0,
                                                                paddingBottom: 0,
                                                                fontWeight: 'bold',
                                                                textAlign: 'left'
                                                            }}
                                                        >
                                                            Total
                                                        </TableCell>
                                                        <TableCell
                                                            style={{
                                                                ...tableCellStyles,
                                                                ...tableCellStyles2,
                                                                paddingTop: 0,
                                                                paddingBottom: 0,
                                                                textAlign: 'right'
                                                            }}
                                                        >
                                                            {formatAsCurrency(Math.abs(newTotalRefund))}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow style={{ height: 0 }}>
                                                        <TableCell colSpan={2} style={{ padding: 0 }}>
                                                            <Divider />
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow style={{ height: 20 }}>
                                                        <TableCell
                                                            style={{
                                                                ...tableCellStyles,
                                                                ...tableCellStyles1,
                                                                paddingTop: 0,
                                                                paddingBottom: 0,
                                                                fontWeight: 'bold',
                                                                textAlign: 'left'
                                                            }}
                                                        >
                                                            Mode
                                                        </TableCell>
                                                        <TableCell
                                                            style={{
                                                                ...tableCellStyles,
                                                                ...tableCellStyles1,
                                                                paddingTop: 0,
                                                                paddingBottom: 0,
                                                                display: 'flex',
                                                                justifyContent: 'right',
                                                                width: '100%'
                                                            }}
                                                        >
                                                            <Button
                                                                size="small"
                                                                variant="contained"
                                                                color="primary"
                                                                style={{
                                                                    height: '25px',
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    marginRight: theme.spacing.unit / 2
                                                                }}
                                                                onClick={() => {
                                                                    handlePulse(bagtag);
                                                                    setMode(bagtag, 'add');
                                                                }}
                                                            >
                                                                <span>Add</span>
                                                                {mode == 'add' && (
                                                                    <CheckIcon style={{ fontSize: '14px' }} />
                                                                )}
                                                            </Button>
                                                            <Button
                                                                size="small"
                                                                variant="contained"
                                                                color="primary"
                                                                style={{
                                                                    height: '25px',
                                                                    display: 'flex',
                                                                    alignItems: 'center'
                                                                }}
                                                                onClick={() => {
                                                                    handlePulse(bagtag);
                                                                    setMode(bagtag, 'override');
                                                                }}
                                                            >
                                                                <span>Override</span>
                                                                {mode == 'override' && (
                                                                    <CheckIcon style={{ fontSize: '14px' }} />
                                                                )}
                                                            </Button>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </Paper>
                                    </div>
                                    {errors.map((error, idx2) => {
                                        let errorResolved = false;
                                        if (error.resolution) {
                                            errorResolved = true;
                                        }

                                        return (
                                            <>
                                                {idx2 != 0 && <Divider />}
                                                <div
                                                    style={{
                                                        margin: theme.spacing.unit,
                                                        display: 'flex',
                                                        flexDirection: 'column'
                                                        // alignItems: 'center',
                                                        // justifyContent: 'flex-end'
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            marginBottom: theme.spacing.unit / 2
                                                        }}
                                                    >
                                                        {!errorResolved && (
                                                            <Icon
                                                                style={{
                                                                    marginRight: theme.spacing.unit,
                                                                    color: colors.red[400],
                                                                    fontSize: '20px'
                                                                }}
                                                            >
                                                                error
                                                            </Icon>
                                                        )}
                                                        {errorResolved && (
                                                            <Icon
                                                                style={{
                                                                    marginRight: theme.spacing.unit,
                                                                    color: colors.green[400],
                                                                    fontSize: '20px'
                                                                }}
                                                            >
                                                                check_circle
                                                            </Icon>
                                                        )}
                                                        <Typography style={{ fontSize: '11px' }}>
                                                            {_.get(error, 'description', 'Error not found')}
                                                        </Typography>
                                                    </div>
                                                    <div
                                                        style={{
                                                            marginLeft: theme.spacing.unit,
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'flex-end'
                                                        }}
                                                    >
                                                        <Button
                                                            variant="contained"
                                                            size="small"
                                                            // color="secondary"
                                                            onClick={() => {
                                                                setHistory(error.history);
                                                                setShowHistory(true);
                                                            }}
                                                            style={{
                                                                marginRight: theme.spacing.unit,
                                                                height: '25px'
                                                            }}
                                                        >
                                                            <Icon>visibility</Icon>
                                                        </Button>
                                                        <Button
                                                            variant="contained"
                                                            size="small"
                                                            color="primary"
                                                            style={{
                                                                marginRight: theme.spacing.unit,

                                                                height: '25px'
                                                            }}
                                                            disabled={error.resolution == 'accept'}
                                                            onClick={() => {
                                                                acceptCountsForError(bagtag, idx2);
                                                                handlePulse(bagtag);
                                                            }}
                                                        >
                                                            Accept Counts
                                                        </Button>
                                                        <Button
                                                            variant="contained"
                                                            size="small"
                                                            color="secondary"
                                                            onClick={() => {
                                                                rejectCountsForError(bagtag, idx2);
                                                                handlePulse(bagtag);
                                                            }}
                                                            style={{
                                                                height: '25px'
                                                            }}
                                                            disabled={error.resolution == 'reject'}
                                                        >
                                                            Reject Counts
                                                        </Button>
                                                    </div>
                                                </div>
                                            </>
                                        );
                                    })}
                                </Collapse>
                            </>
                        );
                    })}
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: theme.spacing.unit * 2
                        }}
                    >
                        <Icon
                            style={{
                                marginRight: theme.spacing.unit,
                                color: colors.yellow[700],
                                fontSize: '24px'
                            }}
                        >
                            warning
                        </Icon>
                        <Typography style={{ marginLeft: theme.spacing.unit }}>
                            Changes may not be made to the bulk while it is closed or if it has been redeemed.
                        </Typography>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button color="default" onClick={onClose}>
                        Cancel
                    </Button>
                    {operator.accountType === 'System Administrator' && _bulk.hasUnresolvedErrors(bulk) && (
                        <Button
                            color="primary"
                            onClick={() => handleResolveErrors()}
                            disabled={_bulk.isCompleted(bulk) || !allErrorsResolved}
                        >
                            Complete
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
            <CustomDebugDialog open={showHistory} json={history} onClose={() => setShowHistory(false)} />
        </>
    );
}

export default withMobileDialog({ breakpoint: 'xs' })(withTheme()(BulkErrorDialog));
