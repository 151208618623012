import { colors } from '@material-ui/core';

import {
    mdiTruckCheck,
    mdiRunFast,
    mdiCashEdit,
    mdiFlagOutline,
    mdiFlag,
    mdiFlagCheckered,
    mdiCurrencyUsd,
    mdiCheckAll,
    mdiRun,
    mdiTagArrowDown,
    mdiQrcodeScan,
    mdiStore,
    mdiPrinter,
    mdiSack
} from '@mdi/js';

export let MSG_SOCIAL_CUSTOMER_DEFAULT = 'MSG_SOCIAL_CUSTOMER_DEFAULT';
export let MSG_SOCIAL_ORGANIZATION_DEFAULT = 'MSG_SOCIAL_ORGANIZATION_DEFAULT';
export let MSG_SOCIAL_CUSTOMER_RECYCLED = 'MSG_SOCIAL_CUSTOMER_RECYCLED';
export let MSG_SOCIAL_CUSTOMER_DONATED = 'MSG_SOCIAL_CUSTOMER_DONATED';

export let AUDIT_INFRACTION_USER_STATES = ['Under Review', 'Suspension'];
export let AUDIT_INFRACTION_USER_STATE_UNDER_REVIEW = 'Under Review';
export let AUDIT_INFRACTION_USER_STATE_SUSPENSION = 'Suspension';

export const MAX_NUM_OF_RECYCLING_BAGS = 99;
export const MIN_NUM_OF_RECYCLING_BAGS = 0;

export const TIMEZONES = [
    'America/Edmonton',
    'America/Toronto',
    'America/Montreal',
    'America/Blanc-Sablon',
    'America/Vancouver',
    'America/Metlakatla',
    'America/Creston',
    'Australia/Brisbane',
    'Australia/Sydney',
    'Australia/Perth',
    'Australia/Melbourne'
];

// pickupTypes
export const RESIDENTIAL = 'Residential';
export const COMMERCIAL = 'Commercial';
export const CONDO = 'Condo';
export const BOTTLE_DRIVE = 'Bottle Drive';
export const EXPRESS = 'Express';
export const DROPANDGO = 'Drop&Go';
export const LABELS = 'Request Labels';
export const EXTERNAL_LINK = 'External Link';
export const WALK_IN = 'Walk-In';
export const DEPOT_SEARCH = 'Search';
export const INSTRUCTIONS = 'Instructions';
export const QR_SCAN = 'QR Scan';
export const ACCESS_DOOR = 'Access Door';

// bulkTypes
export const DROPNGO = 'inhouse';
export const ADJUSTMENT = 'adjustment';
export const PICKUP = 'pickup';
export const WALKIN = 'walk-in';

// export const PAYLOAD_ISSUE_NON_CONFORMANT = 'Non-conformant';
// export const PAYLOAD_ISSUE_HAZARDOUS = 'Hazardous';
// export const PAYLOAD_ISSUE_GARBAGE = 'Garbage';
// export const PAYLOAD_ISSUE_OTHER = 'Other';

export const COMMODITY_COLORS = {
    beverage: colors.blue[400],
    clothing: colors.green[400],
    electronics: colors.amber[500],
    credit: colors.orange[500],
    penalty: colors.red[500],
    promotion: colors.deepOrange[500]
};

export const ZONES_COLORS = [
    colors.blue, // Monday
    colors.teal, // Tuesday
    colors.lime, // Wednesday
    colors.orange, // Thursday
    colors.pink, // Friday,
    colors.deepPurple, // Saturday
    colors.brown // Sunday
];

export const BULK_STATUS_ICONS = {
    uncounted: mdiFlagOutline,
    counted: mdiFlag,
    submitted: mdiFlagCheckered,
    redeemed: mdiCurrencyUsd,
    invoiced: mdiCheckAll
};

export const BULK_STATUS_COLORS = {
    overdue: colors.red[500],
    uncounted: colors.yellow[200],
    counted: colors.indigo[200],
    submitted: colors.green[400],
    redeemed: colors.grey[200],
    invoiced: colors.cyan[200]
};

export const LABEL_ICONS = {
    printed: mdiPrinter,
    scanned: mdiTagArrowDown
};

export const BULK_TYPE_ICONS = {
    pickup: mdiTruckCheck,
    adjustment: mdiCashEdit,
    inhouse: mdiSack,
    'walk-in': process.env.REACT_APP_REGION_EXT === 'CON' ? mdiQrcodeScan : mdiStore
};

export const BULK_TYPE_COLORS = {
    pickup: colors.blue[400],
    inhouse: colors.green[400],
    adjustment: colors.amber[500]
};

export const LOGGED_COLLECTIONS = [
    'batches',
    'bulks',
    'charities',
    'collectors',
    'configs',
    'configuration',
    'defaultcharitytasks',
    'entries',
    'locations',
    'pickups',
    'promos',
    'rates',
    'ratingPlatforms',
    'redemptions',
    'referrals',
    'regions',
    'trips',
    'users',
    'zones'
];

export const maxFileSize = 5 * 1024 * 1024; //5MiB
export const maxFileSizeMB = 5;
export const maxCharityImageWidthAndHeight = 400;
export const maxImageWidthAndHeight = 1200;

export const DEFAULT_DRIVER_WAGE = 1800;
export const DEFAULT_DRIVER_COMMISSION = 2;
export const DEFAULT_TRUCK_KPL = 3.75;
export const DEFAULT_GAS_PRICE_STD = 60;
export const DEFAULT_GAS_PRICE_AUS = 148;

export const CONTACT_EMAIL_OPTIONS = ['Redemptions', 'Service', 'Orders'];

export const INVALID_REDEMPTION_EMAILS = ['@privaterelay.appleid.com'];

export const TAX_TYPES = ['none', 'excluded', 'included'];

export const USER_PERMISSIONS = ['Driver', 'Clerk'];
export const DRIVER_PAYMENT_MODELS = ['Wage', 'Commission'];

export const TIME_ZONES_STD = ['America/Edmonton', 'America/Toronto'];
export const TIME_ZONES_EXP = ['America/Vancouver', 'America/Metlakatla', 'America/Creston'];
export const TIME_ZONES_MXD = ['America/Montreal', 'America/Toronto'];
export const TIME_ZONES_CON = ['America/Montreal', 'America/Toronto'];
export const TIME_ZONES_AUS = ['Australia/Brisbane', 'Australia/Sydney', 'Australia/Perth', 'Australia/Melbourne'];
export const DAYS_OF_THE_WEEK = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

export const TIMEZONES_BY_ENV = {
    STD: TIME_ZONES_STD,
    EXP: TIME_ZONES_EXP,
    MXD: TIME_ZONES_MXD,
    CON: TIME_ZONES_CON,
    AUS: TIME_ZONES_AUS
};

export const CLUSTERING_ALGORITHMS = ['CAPACITY_FIRST', 'EVEN_SPLIT', 'MIN_DRIVERS_EVEN_SPLIT']; //TODO: add ROUTE_FIRST

export const COMMODITY_COMPLETION_STRATEGIES = ['Manual', 'Auto', 'Split Units', 'Accept Units'];

export const AVAILABLE_FREQUENCIES = {
    7: [7 * 1, 7 * 2, 7 * 4, 7 * 10, 7 * 12],
    14: [14 * 1, 14 * 2, 14 * 4, 14 * 6],
    21: [21 * 1, 21 * 2, 21 * 3, 21 * 4],
    28: [28 * 1, 28 * 2, 28 * 3, 28 * 4],
    35: [35 * 1, 35 * 2, 35 * 3],
    42: [42 * 1, 42 * 2, 42 * 3],
    49: [49 * 1, 49 * 2, 49 * 3],
    56: [56 * 1, 56 * 2, 56 * 3],
    63: [63 * 1, 63 * 2, 63 * 3],
    70: [70 * 1, 70 * 2, 70 * 3],
    77: [77 * 1, 77 * 2, 77 * 3],
    84: [84 * 1, 84 * 2, 84 * 3]
};

export const IGNORE_PAYLOAD_SIZE_SKUS = ['beverage'];
export const DEFAULT_GST_RATE_STD = 0.05;

export const TIPPING_MODEL_INDIVIDUAL = 'Individual';
export const TIPPING_MODEL_POOL = 'Pool';

export const AVAILABLE_LANGS = {
    STD: ['en'],
    AUS: ['en'],
    EXP: ['en'],
    MXD: ['fr', 'en'],
    CON: ['fr', 'en']
};

export const ACCOUNT_TYPE_SELECTIONS = {
    PERSONAL: 'PERSONAL',
    BUSINESS: 'BUSINESS',
    GROUP: 'GROUP',
    COLLECTION_PROGRAM: 'COLLECTION_PROGRAM'
};

export const COMMISSION_TYPES = ['Counts', 'Commodity Amount'];

export const INFRACTION_TYPES = {
    AUDIT_TYPE_BAGCOUNT: 'AUDIT_TYPE_BAGCOUNT',
    AUDIT_TYPE_DRIVERCOMPLAINT: 'AUDIT_TYPE_DRIVERCOMPLAINT',
    AUDIT_TYPE_COUNTERCOMPLAINT: 'AUDIT_TYPE_COUNTERCOMPLAINT',
    AUDIT_TYPE_AVGCONTAINERCOUNT: 'AUDIT_TYPE_AVGCONTAINERCOUNT',
    AUDIT_TYPE_AMOUNTDIFFERENCE: 'AUDIT_TYPE_AMOUNTDIFFERENCE',
    AUDIT_TYPE_REDEMPTIONAMOUNT: 'AUDIT_TYPE_REDEMPTIONAMOUNT',
    AUDIT_TYPE_REDEMPTIONEMAIL: 'AUDIT_TYPE_REDEMPTIONEMAIL',
    AUDIT_TYPE_SAMEIPADDRESS: 'AUDIT_TYPE_SAMEIPADDRESS',
    AUDIT_TYPE_HAZARDOUS: 'AUDIT_TYPE_HAZARDOUS',
    AUDIT_TYPE_GARBAGE: 'AUDIT_TYPE_GARBAGE'
};
export const PICKUP_TIME_OF_DAY_PREFERENCES = [
    { text: 'morning', value: 'Morning' },
    { text: 'afternoon', value: 'Afternoon' },
    { text: 'none', value: 'None' }
];

export const FEE_APPLICATION_OPTIONS = [
    'Apply to balance', //Normal (deduct from running balance / deposits)
    'Invoice' //Don't deduct from balance, create separate invoice item in history and keep out of balance.
];

export const INVOICE_FREQUENCY_OPTIONS = ['On Pickup', '15th of Month', '30th of Month'];

export const DESTINATION_ROUTING_OPTIONS = ['Zone', 'Driver'];

export const REPORT_TYPES = {
    CUSTOMER: 'customer',
    DRIVER: 'driver'
};

export const AUS_STATES = ['ACT', 'NSW', 'NT', 'QLD', 'SA', 'TAS', 'VIC', 'WA'];
export const AUS_STATES_FULL_NAME = [
    'Australian Capital Territory',
    'New South Wales',
    'Northern Territory',
    'Queensland',
    'South Australia',
    'Tasmania',
    'Victoria',
    'Western Australia'
];

export const S3_FALLBACK_IMAGE = 'https://local-std-pickup-photos.s3-us-west-2.amazonaws.com/fallback_image.jpg';

export const SHARE_AND_FEEDBACK_TYPES = [
    { key: 'shareAndFeedback-shareScreen', value: 'Share Screen' },
    { key: 'shareAndFeedback-referralScreen', value: 'Referral Screen' },
    { key: 'shareAndFeedback-ratingScreen', value: 'Rating Screen' }
];

export const DROP_OFF_TYPES = [
    { value: 'App Only', icon: 'mobile_friendly', localizationName: 'appOnly' },
    { value: 'Kiosk', icon: 'store', localizationName: 'kiosk' },
    { value: 'Kiosk & Scanner', icon: 'add_business', localizationName: 'kioskAndScanner' },
    { value: 'Access Door', icon: 'fiber_pin', localizationName: 'accessDoor' },
    { value: 'Reverse Vending Machine', icon: 'qr_code_scanner', localizationName: 'reverseVendingMachine' }
];

export const NO_TECHNOLOGY = { value: 'No Technology', icon: 'label_off', localizationName: 'noTechnology' };

export const ROLES = [
    'Customer Service',
    'Customer Service Administrator',
    'Finance Administrator',
    'Marketing',
    'Manager',
    'IT Administrator'
    // 'New Role'
];

export const NONCONFORMANTISSUE = 'NONCONFORMANTISSUE';
export const LOWVOLUMEISSUE = 'LOWVOLUMEISSUE';
export const NON_CONFORMANT_COMPLAINT_CODE = '4A';
export const LOW_VOLUME_COMPLAINT_CODE = '4B';

export const REGISTRATION_TABS = {
    PERSONAL: 0,
    CHARITY: 1,
    DEPOT: 2, //deprecated
    BOTTLE_DRIVE: 3,
    BUSINESS: 4,
    GROUP: 5,
    COLLECTION_PROGRAM: 6
};

export const EXPRESS_APP_BALANCE_SKUTYPE = 'Opening Balance';

export const DB_LOG_TYPES = {
    PICKUP: 'Pickup',
    ORDER: 'Order',
    USER: 'User',
    REDEMPTION: 'Redemption'
};

export const PAYLOAD_ISSUE_HAZARDOUS_CODE = '1';
export const PAYLOAD_ISSUE_GARBAGE_CODE = '2';

//these are taken from table 1 here: https://developers.google.com/maps/documentation/places/web-service/supported_types#table1
export const TRACKED_GOOGLE_PLACE_TYPES = [
    'accounting',
    'airport',
    'amusement_park',
    'aquarium',
    'art_gallery',
    'atm',
    'bakery',
    'bank',
    'bar',
    'beauty_salon',
    'bicycle_store',
    'book_store',
    'bowling_alley',
    'bus_station',
    'cafe',
    'campground',
    'car_dealer',
    'car_rental',
    'car_repair',
    'car_wash',
    'casino',
    'cemetery',
    'church',
    'city_hall',
    'clothing_store',
    'convenience_store',
    'courthouse',
    'dentist',
    'department_store',
    'doctor',
    'drugstore',
    'electrician',
    'electronics_store',
    'embassy',
    'fire_station',
    'florist',
    'funeral_home',
    'furniture_store',
    'gas_station',
    'gym',
    'hair_care',
    'hardware_store',
    'hindu_temple',
    'home_goods_store',
    'hospital',
    'insurance_agency',
    'jewelry_store',
    'laundry',
    'lawyer',
    'library',
    'light_rail_station',
    'liquor_store',
    'local_government_office',
    'locksmith',
    'lodging',
    'meal_delivery',
    'meal_takeaway',
    'mosque',
    'movie_rental',
    'movie_theater',
    'moving_company',
    'museum',
    'night_club',
    'painter',
    'park',
    'parking',
    'pet_store',
    'pharmacy',
    'physiotherapist',
    'plumber',
    'police',
    'post_office',
    'primary_school',
    'real_estate_agency',
    'restaurant',
    'roofing_contractor',
    'rv_park',
    'school',
    'secondary_school',
    'shoe_store',
    'shopping_mall',
    'spa',
    'stadium',
    'storage',
    'store',
    'subway_station',
    'supermarket',
    'synagogue',
    'taxi_stand',
    'tourist_attraction',
    'train_station',
    'transit_station',
    'travel_agency',
    'university',
    'veterinary_care',
    'zoo'
];
export const GOOGLE_PLACE_TYPE_LOCALIZATIONS = {
    accounting: { en: 'Accounting', fr: 'Comptabilité' },
    airport: { en: 'Airport', fr: 'Aéroport' },
    amusement_park: { en: 'Amusement Park', fr: "Parc d'attractions" },
    aquarium: { en: 'Aquarium', fr: 'Aquarium' },
    art_gallery: { en: 'Art Gallery', fr: "Galerie d'art" },
    atm: { en: 'Atm', fr: 'Distributeur automatique' },
    bakery: { en: 'Bakery', fr: 'Boulangerie' },
    bank: { en: 'Bank', fr: 'Banque' },
    bar: { en: 'Bar', fr: 'Bar' },
    beauty_salon: { en: 'Beauty Salon', fr: 'Salon de beauté' },
    bicycle_store: { en: 'Bicycle Store', fr: 'Magasin de vélos' },
    book_store: { en: 'Book Store', fr: 'Librairie' },
    bowling_alley: { en: 'Bowling Alley', fr: 'Salle de bowling' },
    bus_station: { en: 'Bus Station', fr: 'Gare routière' },
    cafe: { en: 'Cafe', fr: 'Café' },
    campground: { en: 'Campground', fr: 'Terrain de camping' },
    car_dealer: { en: 'Car Dealer', fr: 'Concessionnaire automobile' },
    car_rental: { en: 'Car Rental', fr: 'Location de voitures' },
    car_repair: { en: 'Car Repair', fr: 'Réparation automobile' },
    car_wash: { en: 'Car Wash', fr: 'Lave-auto' },
    casino: { en: 'Casino', fr: 'Casino' },
    cemetery: { en: 'Cemetery', fr: 'Cimetière' },
    church: { en: 'Church', fr: 'Église' },
    city_hall: { en: 'City Hall', fr: 'Hôtel de ville' },
    clothing_store: { en: 'Clothing Store', fr: 'Magasin de vêtements' },
    convenience_store: { en: 'Convenience Store', fr: 'Supérette' },
    courthouse: { en: 'Courthouse', fr: 'Palais de justice' },
    dentist: { en: 'Dentist', fr: 'Dentiste' },
    department_store: { en: 'Department Store', fr: 'Grand magasin' },
    doctor: { en: 'Doctor', fr: 'Médecin' },
    drugstore: { en: 'Drugstore', fr: 'Pharmacie' },
    electrician: { en: 'Electrician', fr: 'Électricien' },
    electronics_store: { en: 'Electronics Store', fr: "Magasin d'électronique" },
    embassy: { en: 'Embassy', fr: 'Ambassade' },
    fire_station: { en: 'Fire Station', fr: 'Caserne de pompiers' },
    florist: { en: 'Florist', fr: 'Fleuriste' },
    funeral_home: { en: 'Funeral Home', fr: 'Pompes funèbres' },
    furniture_store: { en: 'Furniture Store', fr: 'Magasin de meubles' },
    gas_station: { en: 'Gas Station', fr: 'Station-service' },
    gym: { en: 'Gym', fr: 'Salle de sport' },
    hair_care: { en: 'Hair Care', fr: 'Soin des cheveux' },
    hardware_store: { en: 'Hardware Store', fr: 'Quincaillerie' },
    hindu_temple: { en: 'Hindu Temple', fr: 'Temple hindou' },
    home_goods_store: { en: 'Home Goods Store', fr: "Magasin d'articles pour la maison" },
    hospital: { en: 'Hospital', fr: 'Hôpital' },
    insurance_agency: { en: 'Insurance Agency', fr: "Agence d'assurance" },
    jewelry_store: { en: 'Jewelry Store', fr: 'Bijouterie' },
    laundry: { en: 'Laundry', fr: 'Blanchisserie' },
    lawyer: { en: 'Lawyer', fr: 'Avocat' },
    library: { en: 'Library', fr: 'Bibliothèque' },
    light_rail_station: { en: 'Light Rail Station', fr: 'Station de tramway' },
    liquor_store: { en: 'Liquor Store', fr: 'Magasin de spiritueux' },
    local_government_office: { en: 'Local Government Office', fr: 'Bureau du gouvernement local' },
    locksmith: { en: 'Locksmith', fr: 'Serrurier' },
    lodging: { en: 'Lodging', fr: 'Hébergement' },
    meal_delivery: { en: 'Meal Delivery', fr: 'Livraison de repas' },
    meal_takeaway: { en: 'Meal Takeaway', fr: 'Plat à emporter' },
    mosque: { en: 'Mosque', fr: 'Mosquée' },
    movie_rental: { en: 'Movie Rental', fr: 'Location de films' },
    movie_theater: { en: 'Movie Theater', fr: 'Cinéma' },
    moving_company: { en: 'Moving Company', fr: 'Entreprise de déménagement' },
    museum: { en: 'Museum', fr: 'Musée' },
    night_club: { en: 'Night Club', fr: 'Boîte de nuit' },
    painter: { en: 'Painter', fr: 'Peintre' },
    park: { en: 'Park', fr: 'Parc' },
    parking: { en: 'Parking', fr: 'Parking' },
    pet_store: { en: 'Pet Store', fr: "Magasin d'animaux" },
    pharmacy: { en: 'Pharmacy', fr: 'Pharmacie' },
    physiotherapist: { en: 'Physiotherapist', fr: 'Physiothérapeute' },
    plumber: { en: 'Plumber', fr: 'Plombier' },
    police: { en: 'Police', fr: 'Police' },
    post_office: { en: 'Post Office', fr: 'Bureau de poste' },
    primary_school: { en: 'Primary School', fr: 'École primaire' },
    real_estate_agency: { en: 'Real Estate Agency', fr: 'Agence immobilière' },
    restaurant: { en: 'Restaurant', fr: 'Restaurant' },
    roofing_contractor: { en: 'Roofing Contractor', fr: 'Couvreur' },
    rv_park: { en: 'Rv Park', fr: 'Parc pour camping-cars' },
    school: { en: 'School', fr: 'École' },
    secondary_school: { en: 'Secondary School', fr: 'École secondaire' },
    shoe_store: { en: 'Shoe Store', fr: 'Magasin de chaussures' },
    shopping_mall: { en: 'Shopping Mall', fr: 'Centre commercial' },
    spa: { en: 'Spa', fr: 'Spa' },
    stadium: { en: 'Stadium', fr: 'Stade' },
    storage: { en: 'Storage', fr: 'Stockage' },
    store: { en: 'Store', fr: 'Magasin' },
    subway_station: { en: 'Subway Station', fr: 'Station de métro' },
    supermarket: { en: 'Supermarket', fr: 'Supermarché' },
    synagogue: { en: 'Synagogue', fr: 'Synagogue' },
    taxi_stand: { en: 'Taxi Stand', fr: 'Station de taxis' },
    tourist_attraction: { en: 'Tourist Attraction', fr: 'Attraction touristique' },
    train_station: { en: 'Train Station', fr: 'Gare' },
    transit_station: { en: 'Transit Station', fr: 'Station de transit' },
    travel_agency: { en: 'Travel Agency', fr: 'Agence de voyages' },
    university: { en: 'University', fr: 'Université' },
    veterinary_care: { en: 'Veterinary Care', fr: 'Soin vétérinaire' },
    zoo: { en: 'Zoo', fr: 'Zoo' }
};
