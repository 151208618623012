import React, { useRef, useEffect, useState } from 'react';
import _ from 'lodash';

import jsQR from 'jsqr';

const QrScanner = props => {
    const scannerRef = useRef(null);

    const { handleQRScanned, show, style } = props;

    const [scannedData, setScannedData] = useState(null);
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);

    const checkForQRCode = () => {
        try {
            const videoElement = scannerRef.current;
            const canvasElement = document.createElement('canvas');
            const context = canvasElement.getContext('2d');
            canvasElement.width = videoElement.videoWidth;
            canvasElement.height = videoElement.videoHeight;
            context.drawImage(videoElement, 0, 0, videoElement.videoWidth, videoElement.videoHeight);
            const imageData = context.getImageData(0, 0, canvasElement.width, canvasElement.height);

            const code = jsQR(imageData.data, imageData.width, imageData.height);

            if (code) {
                setScannedData(code.data);
            }
        } catch (err) {
            // console.error(err)
        }
    };

    const handleMetadataLoaded = () => {
        if (scannerRef.current) {
            const width = scannerRef.current.clientWidth;
            const height = scannerRef.current.clientHeight;

            setHeight(height);
            setWidth(width);
        }
    };

    useEffect(() => {
        if (_.isEmpty(scannedData)) {
            return;
        }

        handleQRScanned(scannedData);

        setTimeout(() => {
            setScannedData(null);
        }, 3000);
    }, [scannedData]);

    useEffect(() => {
        if (!scannerRef || !scannerRef.current) {
            return;
        }

        const vidComponent = scannerRef.current;
        let interval;
        let stream;
        let video;
        const startScanner = async () => {
            try {
                stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } });
                video = vidComponent;

                video.srcObject = stream;

                interval = setInterval(() => {
                    checkForQRCode();
                }, 1000);

                return true;
            } catch (error) {
                console.error('Camera permission denied:', error);
            }
        };

        startScanner();

        return () => {
            try {
                if (interval) {
                    clearInterval(interval);
                }

                const tracks = stream.getTracks();
                tracks.forEach(track => track.stop());
            } catch (err) {
                // console.error(err)
            }
        };
    }, [scannerRef]);

    return (
        <div style={{ display: show ? 'flex' : 'none', height: 'auto', width: 'auto', position: 'relative' }}>
            <video
                ref={scannerRef}
                id="cameraStream"
                autoPlay
                style={{ width: '100%', height: 'auto' }}
                onLoadedMetadata={handleMetadataLoaded}
            />
            <QROverlay />
        </div>
    );
};

export default QrScanner;

const QROverlay = () => (
    <div
        style={{
            position: 'absolute',
            top: 30,
            right: 30,
            bottom: 30,
            left: 30,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
        }}
    >
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <div
                style={{ borderLeft: '5px solid white', borderTop: '5px solid white', width: '30px', height: '30px' }}
            />
            <div
                style={{ borderRight: '5px solid white', borderTop: '5px solid white', width: '30px', height: '30px' }}
            />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <div
                style={{
                    borderLeft: '5px solid white',
                    borderBottom: '5px solid white',
                    width: '30px',
                    height: '30px'
                }}
            />
            <div
                style={{
                    borderRight: '5px solid white',
                    borderBottom: '5px solid white',
                    width: '30px',
                    height: '30px'
                }}
            />
        </div>
    </div>
);
