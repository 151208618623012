import React, { useMemo, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';

import useGetJSON from 'utils/hooks/useGetJSON';

import {
    Paper,
    Table,
    TableBody,
    TableHead,
    TableCell,
    TableRow,
    Icon,
    withTheme,
    colors,
    Grid,
    TextField,
    Button,
    InputAdornment
} from '@material-ui/core';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useContext } from 'react';
import HttpContext from 'utils/contexts/HttpContext';

import SnackbarContext from 'components/CustomSnackbar/SnackbarContext';
import { useState } from 'react';

function ImgCaptureStatus(props) {
    const { theme } = props;

    const http = useContext(HttpContext);
    const onSnackbar = useContext(SnackbarContext);

    const {
        loading: imgCaptureDataLoading,
        error: imgCaptureDataLoadingErr,
        data: imgCaptureData = [],
        refresh: refreshImgCaptureData
    } = useGetJSON(`/imgCaptureStatus/all`);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            dailyUploadLimit: _.get(imgCaptureData, 'imageCaptureConfig.dailyUploadLimit', 0) / 1000000,
            motionConfig: {
                threshold: _.get(imgCaptureData, 'imageCaptureConfig.motionConfig.threshold', '')
            }
        },
        validationSchema: Yup.object({
            dailyUploadLimit: Yup.number(),
            motionConfig: Yup.object({
                threshold: Yup.number().integer('You must enter a whole number.')
            })
        }),
        onSubmit: async values => {
            const valuesCopy = _.cloneDeep(values);
            valuesCopy.dailyUploadLimit *= 1000000;
            const res = await http.post('/imgCaptureStatus/updateConfig', valuesCopy);
            if (res.ok) {
                onSnackbar('Successfully updated image capture configuration.');
                refreshImgCaptureData();
            }
        }
    });

    const imgCaptureStatuses = useMemo(() => _.get(imgCaptureData, 'imgCaptureStatuses', []), [imgCaptureData]);

    useEffect(() => {
        formik.handleReset();
    }, [imgCaptureData]);

    return (
        <Grid
            container
            spacing={theme.spacing.unit * 2}
            style={{ margin: 0, width: '100%', padding: theme.spacing.unit * 2 }}
        >
            <Grid item xs={12}>
                <div>
                    <Paper style={{ padding: theme.spacing.unit * 2 }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div>
                                <TextField
                                    {...formik.getFieldProps('dailyUploadLimit')}
                                    label="Daily Upload Limit (MB)"
                                    variant="outlined"
                                    style={{ marginRight: theme.spacing.unit * 2 }}
                                    type="number"
                                    helperText={
                                        _.get(formik.touched, 'dailyUploadLimit', false) &&
                                        _.get(formik.errors, 'dailyUploadLimit', false) &&
                                        _.get(formik.errors, 'dailyUploadLimit', false)
                                    }
                                    error={
                                        _.get(formik.touched, 'dailyUploadLimit', false) &&
                                        _.get(formik.errors, 'dailyUploadLimit', false)
                                            ? true
                                            : null
                                    }
                                />
                                <TextField
                                    {...formik.getFieldProps('motionConfig.threshold')}
                                    label="Motion Threshold (Pixels)"
                                    variant="outlined"
                                    style={{ marginRight: theme.spacing.unit * 2 }}
                                    type="number"
                                    helperText={
                                        _.get(formik.touched, 'motionConfig.threshold', false) &&
                                        _.get(formik.errors, 'motionConfig.threshold', false) &&
                                        _.get(formik.errors, 'motionConfig.threshold', false)
                                    }
                                    error={
                                        _.get(formik.touched, 'motionConfig.threshold', false) &&
                                        _.get(formik.errors, 'motionConfig.threshold', false)
                                            ? true
                                            : null
                                    }
                                />
                            </div>
                            <Button variant="contained" color="primary" onClick={formik.handleSubmit}>
                                Submit
                            </Button>
                        </div>
                    </Paper>
                </div>
            </Grid>
            <Grid item xs={12}>
                <div>
                    <Paper>
                        <Table style={{ marginTop: theme.spacing.unit }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Device Id</TableCell>
                                    <TableCell>Is Ok</TableCell>
                                    <TableCell>Issue Description</TableCell>
                                    <TableCell>Last Status Update</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {!_.isEmpty(imgCaptureStatuses) &&
                                    imgCaptureStatuses.map(imgCaptureStatus => (
                                        <TableRow key={imgCaptureStatus._id}>
                                            <TableCell>{_.get(imgCaptureStatus, 'deviceId')}</TableCell>
                                            <TableCell>
                                                <Icon
                                                    style={{
                                                        color: _.get(imgCaptureStatus, 'hasIssue', false)
                                                            ? colors.red[500]
                                                            : colors.green[500]
                                                    }}
                                                >
                                                    {_.get(imgCaptureStatus, 'hasIssue', false)
                                                        ? 'cancel'
                                                        : 'check_circle'}
                                                </Icon>
                                            </TableCell>
                                            <TableCell>{_.get(imgCaptureStatus, 'issues', []).join(', ')}</TableCell>
                                            <TableCell>
                                                {!_.isNil(_.get(imgCaptureStatus, 'lastStatusMessage')) &&
                                                    moment(_.get(imgCaptureStatus, 'lastStatusMessage')).format(
                                                        'D MMM YYYY, h:mm a'
                                                    )}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </Paper>
                </div>
            </Grid>
        </Grid>
    );
}

export default withTheme()(ImgCaptureStatus);
