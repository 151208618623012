import React, { useEffect, useContext } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';

import { formatAsCurrency } from 'utils/misc';

import useTable from 'components/Table/useTable';
import Table from 'components/Table/Table';
import useGetJSON from 'utils/hooks/useGetJSON';

import { mdiDesktopClassic, mdiMapMarkerPath } from '@mdi/js';
import MDIcon from '@mdi/react';

import useDateRangePicker from 'components/DateTimePickersTz/hooks/useDateRangePicker';
import DateRangePicker from 'components/DateTimePickersTz/DateRangePicker';

import {
    Grid,
    withTheme,
    TableRow,
    TableCell,
    Paper,
    Typography,
    Icon,
    Avatar,
    FormControl,
    Select,
    MenuItem,
    Snackbar,
    IconButton
} from '@material-ui/core';

import { _user, _time, _redemption, _charity } from 'std';
import SnackbarContext from 'components/CustomSnackbar/SnackbarContext';
import HttpContext from 'utils/contexts/HttpContext';

const headers = [
    { name: 'Name', locKey: null },
    { name: 'Amount', locKey: null },
    { name: 'Fee', locKey: null },
    { name: 'Type', locKey: null },
    { name: 'QBO Expense ID', locKey: null },
    { name: 'QBO Fees ID', locKey: null },
    { name: 'Date Completed', locKey: null },
    { name: 'Date Sent to Quickbooks', locKey: null }
];

function ChequeReport(props) {
    const { theme } = props;

    const {
        loading,
        getData,
        page,
        rowsPerPage,
        filteredData,
        search,
        filteredHeaders,
        totalDataEntries,
        handleChange,
        handleSearch,
        handleChangeFilterHeaders,
        handleAllFilterHeaders,
        handleChangePage,
        handleChangeRowsPerPage
    } = useTable({
        url: '/redemptions?dateToFilter=dateSentToQuickbooks',
        key: 'redemptions',
        headers: headers.map(({ name }) => name),
        queryObj: { paymentModel: 'CHEQUE' }
    });

    const renderRow = chequeRedemption => {
        return (
            <TableRow key={chequeRedemption._id}>
                {filteredHeaders.includes('Name') && (
                    <>
                        {_redemption.isCustomerRedemption(chequeRedemption) && (
                            <TableCell>{_user.getNameFull(_.get(chequeRedemption, 'claimant'))}</TableCell>
                        )}
                        {_redemption.isCharityRedemption(chequeRedemption) && (
                            <TableCell>{_charity.getName(_.get(chequeRedemption, 'charity'))}</TableCell>
                        )}
                    </>
                )}
                {filteredHeaders.includes('Amount') && (
                    <TableCell>
                        {formatAsCurrency(_.get(chequeRedemption, 'amount', 0) - _.get(chequeRedemption, 'fee', 0))}
                    </TableCell>
                )}
                {filteredHeaders.includes('Fee') && (
                    <TableCell>{formatAsCurrency(_.get(chequeRedemption, 'fee', 0))}</TableCell>
                )}
                {filteredHeaders.includes('Type') && <TableCell>{_.get(chequeRedemption, 'type', '')}</TableCell>}
                {filteredHeaders.includes('QBO Expense ID') && (
                    <TableCell>{_.get(chequeRedemption, 'totalRedeemedQuickbooksId', '')}</TableCell>
                )}
                {filteredHeaders.includes('QBO Fees ID') && (
                    <TableCell>{_.get(chequeRedemption, 'feesQuickbooksId', '')}</TableCell>
                )}
                {filteredHeaders.includes('Date Completed') && (
                    <TableCell>
                        {moment(_.get(chequeRedemption, 'completionDate')).format('YYYY/MM/DD h:mm:ss a')}
                    </TableCell>
                )}
                {filteredHeaders.includes('Date Sent to Quickbooks') && (
                    <TableCell>
                        {moment(_.get(chequeRedemption, 'dateSentToQuickbooks')).format('YYYY/MM/DD h:mm:ss a')}
                    </TableCell>
                )}
            </TableRow>
        );
    };

    const {
        startDate,
        endDate,
        timezone,
        dateWindow,
        handleChangeStartDate,
        handleChangeEndDate,
        handleGoForwards,
        handleGoBackwards,
        handleWindowSelect
    } = useDateRangePicker({
        saveStateInURL: true,
        timezones: [process.env.REACT_APP_REGION_TIMEZONE],
        initStartVal: _time.getStartOfWeek(process.env.REACT_APP_REGION_TIMEZONE),
        initEndVal: _time.getEndOfDay(process.env.REACT_APP_REGION_TIMEZONE)
    });

    useEffect(() => {
        getData();
    }, [startDate, endDate, timezone, dateWindow]);

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <div style={{ padding: theme.spacing.unit * 2, paddingBottom: theme.spacing.unit }}>
                        <Paper
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                padding: theme.spacing.unit * 2,
                                height: '100%',
                                flexWrap: 'wrap'
                            }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <DateRangePicker
                                    timezone={timezone}
                                    startDate={startDate}
                                    endDate={endDate}
                                    window={dateWindow}
                                    disabled={loading}
                                    handlePrevious={handleGoBackwards}
                                    handleNext={handleGoForwards}
                                    handleChangeStartDate={handleChangeStartDate}
                                    handleChangeEndDate={handleChangeEndDate}
                                />
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexWrap: 'wrap'
                                }}
                            >
                                <FormControl>
                                    <Select
                                        value={dateWindow}
                                        disabled={loading}
                                        onChange={handleWindowSelect}
                                        style={{
                                            maxWidth: '250px',
                                            margin: theme.spacing.unit
                                        }}
                                    >
                                        <MenuItem value="today">Daily</MenuItem>
                                        <MenuItem value="thisWeek">Weekly</MenuItem>
                                        <MenuItem value="thisMonth">Monthly</MenuItem>
                                        <MenuItem value="thisQuarter">Quarterly</MenuItem>
                                        <MenuItem value="allTime">All Time</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </Paper>
                    </div>
                </Grid>
            </Grid>
            <Table
                title="Cheque Report"
                loading={loading}
                headers={headers}
                filteredHeaders={filteredHeaders}
                length={totalDataEntries}
                page={page}
                rowsPerPage={rowsPerPage}
                noSearch
                //search={search}
                handleChange={handleChange}
                handleChangeFilterHeaders={handleChangeFilterHeaders}
                handleAllFilterHeaders={handleAllFilterHeaders}
                //handleSearch={handleSearch}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                refresh={getData}
            >
                {filteredData &&
                    filteredData.map(user => {
                        return renderRow(user, filteredHeaders);
                    })}
            </Table>
        </>
    );
}

export default withTheme()(ChequeReport);
