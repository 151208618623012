import React, { useContext, useEffect, useRef } from 'react';

import _ from 'lodash';

import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';

import { Icon as MDIcon } from '@mdi/react';
import * as allIcons from '@mdi/js';

import { withTheme } from '@material-ui/core/styles';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

import ConfirmDialogContext from 'components/Dialogs/Confirm/ConfirmDialogContext';

function ImageScroll(props) {
    const {
        theme,
        displayImageIdx,
        disableDelete,
        displayImages,
        //onImageDelete,
        onImageSelect,
        scrollerStyles = {},
        imageIcons = [],
        imageIconSize = 1,
        blockStyles = {}
    } = props;

    return (
        <div
            style={{
                // margin: theme.spacing.unit / 2,
                padding: 6,
                display: 'flex',
                ...scrollerStyles
            }}
        >
            {displayImages.map((displayImage, idx) => {
                return (
                    <ImageBlock
                        theme={theme}
                        selected={displayImageIdx === idx}
                        disableDelete={disableDelete}
                        displayImage={displayImage}
                        onImageSelect={() => onImageSelect(idx)}
                        icon={_.get(imageIcons, `[${idx}]`)}
                        imageIconSize={imageIconSize}
                        style={blockStyles}
                        //onImageDelete={() => onImageDelete(idx)}
                    />
                );
            })}
        </div>
    );
}
export default withTheme()(ImageScroll);

function ImageBlock(props) {
    const { lang } = useContext(LocalizationContext);
    const warnAction = useContext(ConfirmDialogContext);
    const imageRef = useRef(null);

    const {
        theme,
        selected,
        displayImage,
        disableDelete,
        icon,
        onImageSelect,
        style,
        imageIconSize /*onImageDelete*/
    } = props;

    const iconName = _.get(icon, 'name');
    const iconColor = _.get(icon, 'color');

    /*let mouseDownInterval;
    const handleMouseDown = () => {
        if (disableDelete) return;

        if (mouseDownInterval) {
            clearInterval(mouseDownInterval);
        }

        const newMouseDownInterval = setInterval(() => {
            warnAction(() => onImageDelete(), loc('delete_image', lang));

            clearInterval(newMouseDownInterval);
        }, 1000);

        mouseDownInterval = newMouseDownInterval;
    };

    const handleMouseUp = () => {
        clearInterval(mouseDownInterval);
    };

    useEffect(() => {
        if (disableDelete) return;

        const element = _.get(imageRef, 'current');

        if (!element) return;

        element.addEventListener('mousedown', handleMouseDown);
        element.addEventListener('mouseup', handleMouseUp);

        return () => {
            clearInterval(mouseDownInterval);

            element.removeEventListener('mousedown', handleMouseDown);
            element.removeEventListener('mouseup', handleMouseUp);
        };
    }, [imageRef]);*/

    return (
        <div
            ref={imageRef}
            style={{
                margin: theme.spacing.unit / 2,
                borderStyle: selected ? 'ridge' : null,
                borderRadius: theme.spacing.unit / 2,
                minWidth: 64,
                minHeight: 64,
                maxWidth: 64,
                maxHeight: 64,
                textAlign: 'center',
                backgroundImage: `url(${displayImage})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                position: 'relative',
                cursor: 'pointer',
                ...style
            }}
            onClick={onImageSelect}
            /*onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}*/
        >
            {!_.isNil(icon) && (
                <div
                    style={{ position: 'absolute', top: -6, right: -6 }}
                    //onClick={() => warnAction(() => onImageDelete(), loc('delete_image', lang))}
                >
                    <IconButton style={{ padding: 0, margin: 0, backgroundColor: 'white' }}>
                        <MDIcon path={_.get(allIcons, iconName)} size={imageIconSize} color={iconColor} />
                    </IconButton>
                </div>
            )}
        </div>
    );
}
