import React, { Component } from 'react';

import _ from 'lodash';
import { validate } from 'utils/validate';

import TextField from '@material-ui/core/TextField';
import LocalizationContext from 'utils/contexts/LocalizationContext';
export default class ValidatedInput extends Component {
    static contextType = LocalizationContext;
    state = {
        initialValue: undefined,
        valueChanged: false
    };

    componentWillMount() {
        this.setState({ initialValue: this.props.value });
    }

    componentWillReceiveProps(nextProps) {
        const { initialValue, valueChanged } = this.state;

        if (!valueChanged && nextProps.value !== initialValue) {
            this.setState({ valueChanged: true });
        }
    }

    render() {
        const { value, validators, showError, matchValue, matchReason, ...rest } = this.props;
        const { valueChanged } = this.state;

        if (_.isNil(validators)) {
            throw new Error('Validators cannot be null or undefined.');
        } else if (!_.isArray(validators)) {
            throw new Error('Validators should be supplied as an array of strings.');
        }

        const matchFailed = _.isNil(matchValue) ? false : value !== matchValue;

        const validation = validate(validators, value, this.context.lang);
        const error = matchFailed || validation.fail;
        const errorMessage = matchFailed ? matchReason : validation.reason;

        return (
            <TextField
                {...rest}
                value={value}
                helperText={showError || valueChanged ? errorMessage : ''}
                error={showError || valueChanged ? error : false}
            />
        );
    }
}
