import React, { useContext, useState, useEffect } from 'react';

import moment from 'moment-timezone';
import _ from 'lodash';

import {
    Paper,
    List,
    ListItemText,
    ListItem,
    ListItemAvatar,
    Typography,
    FormControl,
    Select,
    MenuItem,
    Grid,
    withTheme,
    LinearProgress
} from '@material-ui/core';

import { getCustomerName, formatAsPhoneNumber } from 'utils/misc';

import { _time } from 'std';

import useDateRangePicker from 'components/DateTimePickersTz/hooks/useDateRangePicker';
import DateRangePicker from 'components/DateTimePickersTz/DateRangePicker';

import HttpContext from 'utils/contexts/HttpContext';

function SavedSMS({ theme }) {
    const http = useContext(HttpContext);

    const [smsLogs, setSMSLogs] = useState([]);
    const [loading, setLoading] = useState(false);

    const {
        startDate,
        endDate,
        timezone,
        dateWindow,
        handleChangeStartDate,
        handleChangeEndDate,
        handleGoForwards,
        handleGoBackwards,
        handleWindowSelect
    } = useDateRangePicker({
        saveStateInURL: true,
        timezones: [process.env.REACT_APP_REGION_TIMEZONE],
        initStartVal: _time.getStartOfWeek(process.env.REACT_APP_REGION_TIMEZONE),
        initEndVal: _time.getEndOfDay(process.env.REACT_APP_REGION_TIMEZONE)
    });

    const fetchData = async () => {
        setLoading(true);
        const res = await http.getJSON(`/system/smsLogs?startDate=${startDate}&endDate=${endDate}`);
        if (res.ok) {
            setSMSLogs(res.data.smsLogs);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        fetchData();
    }, [startDate, endDate]);

    return (
        <div
            style={{
                flex: '0 1 auto',
                minHeight: 0,
                padding: theme.spacing.unit * 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'stretch'
            }}
        >
            <Grid container>
                <Grid item xs={12}>
                    <div style={{ paddingBottom: theme.spacing.unit * 2 }}>
                        <Paper
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                padding: theme.spacing.unit * 2,
                                height: '100%',
                                flexWrap: 'wrap'
                            }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <DateRangePicker
                                    timezone={timezone}
                                    startDate={startDate}
                                    endDate={endDate}
                                    window={dateWindow}
                                    disabled={loading}
                                    handlePrevious={handleGoBackwards}
                                    handleNext={handleGoForwards}
                                    handleChangeStartDate={handleChangeStartDate}
                                    handleChangeEndDate={handleChangeEndDate}
                                />
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexWrap: 'wrap'
                                }}
                            >
                                <FormControl>
                                    <Select
                                        value={dateWindow}
                                        disabled={loading}
                                        onChange={handleWindowSelect}
                                        style={{
                                            maxWidth: '250px',
                                            margin: theme.spacing.unit
                                        }}
                                    >
                                        <MenuItem value="today">Daily</MenuItem>
                                        <MenuItem value="thisWeek">Weekly</MenuItem>
                                        <MenuItem value="thisMonth">Monthly</MenuItem>
                                        <MenuItem value="thisQuarter">Quarterly</MenuItem>
                                        <MenuItem value="allTime">All Time</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </Paper>
                    </div>
                </Grid>
            </Grid>
            {loading && <LinearProgress />}
            <Paper style={{ flex: 1, overflow: 'auto' }}>
                <List>
                    {smsLogs.map(smsLog => (
                        <ListItem key={_.get(smsLog, '_id', '')} data-cy={`saved-sms-${_.get(smsLog, 'phone', '')}`}>
                            <ListItemAvatar>
                                <Typography>{moment(_.get(smsLog, 'createdAt')).format('h:mm a MMM DD')}</Typography>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <>
                                        <span style={{ color: theme.palette.primary.main }} data-cy={`saved-sms-phone`}>
                                            {formatAsPhoneNumber(_.get(smsLog, 'phone', ''))}
                                        </span>{' '}
                                        - {getCustomerName(_.get(smsLog, 'user'))}
                                    </>
                                }
                                secondary={<span data-cy={`saved-sms-message`}>{_.get(smsLog, 'message', '')}</span>}
                            />
                        </ListItem>
                    ))}
                </List>
            </Paper>
        </div>
    );
}

export default withTheme()(SavedSMS);
