import React, { useMemo } from 'react';

import _ from 'lodash';

import { withTheme } from '@material-ui/core/styles';

import bulkHelper from 'helpers/bulkHelper';
import * as rateHelper from 'helpers/rateHelper';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

// can take in multiple bulks and roll them up into
function BulkCountsInputs({ theme, bulk, countForm, disabled, onCountChange, onCreateBulk }) {
    // TODO: add message here do explain why some might be disabled
    // MEMOIZED COUNT INPUTS
    const countInputs = useMemo(
        () =>
            _.keys(countForm).map(material => {
                const containerGroup = countForm[material];
                const numberOfColumns = containerGroup.length;

                return (
                    <React.Fragment key={material}>
                        {containerGroup.map(container => {
                            const disableInput = disabled;
                            return (
                                <Grid key={container.sku} item xs={12} sm={12 / numberOfColumns}>
                                    <TextField
                                        data-cy={
                                            'bulk-counter-' +
                                            container.description.replace(/\s/g, '-').toLowerCase() +
                                            '-small'
                                        }
                                        label={
                                            container.description + ` ${container.label ? `(${container.label})` : ''}`
                                        }
                                        type="tel"
                                        disabled={disableInput}
                                        inputProps={{ min: 0 }}
                                        id={material} // used in handleChange
                                        name={container.sku.toString()} // used in handleChange
                                        material={container.material}
                                        value={container.quantity.toString()}
                                        style={{
                                            width: `calc(100% - ${theme.spacing.unit}px)`,
                                            marginTop: theme.spacing.unit / 2
                                        }}
                                        onChange={onCountChange}
                                    />
                                </Grid>
                            );
                        })}
                    </React.Fragment>
                );
            }),
        [countForm, disabled, bulk, theme, onCountChange]
    );

    // if nothing to count, return nothing
    if (_.isEmpty(countInputs)) {
        return null;
    }

    return (
        <Grid container spacing={theme.spacing.unit}>
            {countInputs}
        </Grid>
    );
}

export default withTheme()(BulkCountsInputs);
