import React, { useContext } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';
import { formatsSecondsToTime } from 'utils/misc';
import { withTheme } from '@material-ui/core/styles';
import { Dialog, DialogContent, DialogActions, Button, Typography } from '@material-ui/core';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

// NOTE: this is not an actual error object, its an object from the logger
function CRONLogDialog({ theme, log, open = false, onClose }) {
    const timezone = _.get(log, 'timezones[0]', process.env.REACT_APP_REGION_TIMEZONE);
    const { lang } = useContext(LocalizationContext);
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogContent>
                <Typography variant="h6">{log.description}</Typography>
                <table style={{ width: '100%', marginTop: theme.spacing.unit }}>
                    <tbody style={{ fontSize: theme.typography.caption.fontSize }}>
                        <tr>
                            <td>Triggered By:</td>
                            <td>
                                {_.get(log, 'initiator.name.first', 'System')} {_.get(log, 'initiator.name.last', '')}
                            </td>
                        </tr>
                        <tr>
                            <td>Timezone:</td>
                            <td>{timezone}</td>
                        </tr>
                        <tr>
                            <td>Local Time:</td>
                            <td>
                                {moment(log.startTime)
                                    .tz(_.get(log, 'timezones[0]', process.env.REACT_APP_REGION_TIMEZONE))
                                    .format('L - LTS')}
                            </td>
                        </tr>
                        <tr>
                            <td>System Time:</td>
                            <td>
                                {moment(log.startTime)
                                    .tz(process.env.REACT_APP_REGION_TIMEZONE)
                                    .format('L - LTS')}
                            </td>
                        </tr>
                        <tr>
                            <td>Completion Time:</td>
                            <td>
                                {formatsSecondsToTime(
                                    moment(log.completionTime)
                                        .diff(log.startTime, 'seconds')
                                        .toString()
                                )}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <Typography variant="h6" style={{ marginTop: theme.spacing.unit }}>
                    Report
                </Typography>
                <div
                    style={{
                        marginTop: theme.spacing.unit,
                        border: `1px solid ${theme.palette.text.hint}`,
                        backgroundColor: theme.palette.background.default,
                        padding: theme.spacing.unit,
                        fontSize: 14,
                        fontFamily: theme.typography.fontFamily,
                        overflowX: 'scroll',
                        color: theme.palette.text.primary
                    }}
                >
                    {_.get(log, 'report', []).map(i => {
                        return (
                            <Typography variant="caption" gutterBottom>
                                {i.description || 'N/A'}
                            </Typography>
                        );
                    })}
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="default">
                    {loc('close', lang)}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default withTheme()(CRONLogDialog);
