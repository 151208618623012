import React from 'react';

import _ from 'lodash';
import { useContext, useEffect, useState } from 'react';

import {
    deviceHelper,
    profileNotComplete,
    emailIsVerified,
    uppercaseFirstLetter,
    getDeviceInformation
} from 'utils/misc.js';

import {
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    Icon,
    ListItemIcon,
    Collapse,
    Tooltip,
    colors,
    withTheme,
    Button,
    Typography,
    Divider
} from '@material-ui/core';

import SnackbarContext from 'components/CustomSnackbar/SnackbarContext';

import HttpContext from 'utils/contexts/HttpContext';
import ConfirmDialogContext from 'components/Dialogs/Confirm/ConfirmDialogContext';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc, locDate } from '../../localizations/localizationHandler';

import { NativeBiometric } from '@capgo/capacitor-native-biometric';

function DeviceInfoWidget(props) {
    const {
        user,
        isAdminView,
        biometricsAvailable,
        biometricsHasCredentials,
        onReloadUser,
        onCancel,
        onLogoutAll,
        onBiometricsHasCredentialsChange,
        theme
    } = props;

    const [currentDeviceInfo, setCurrentDeviceInfo] = useState(null);
    const warnAction = useContext(ConfirmDialogContext);

    const http = useContext(HttpContext);
    const { lang } = useContext(LocalizationContext);
    const onSnackbar = useContext(SnackbarContext);

    const handleRemoveDevice = async deviceInfo => {
        const uuid = _.get(deviceInfo, 'uuid');
        const res = await http.post(`/users/${user._id}/removeDevice`, { uuid });
        if (res.ok) {
            await onReloadUser();
            onSnackbar(loc('deviceRemoved', lang));
        }
    };

    const handleLogoutDevice = async deviceInfo => {
        const uuid = _.get(deviceInfo, 'uuid');
        const res = await http.post(`/users/${user._id}/logoutDevice`, { uuid });
        if (res.ok) {
            await onReloadUser();
            onSnackbar(loc('deviceSignOut', lang));
        }
    };

    const handleLogoutAll = async () => {
        if (isAdminView) {
            const res = await http.post(`/users/${user._id}/adminLogoutUser`);
            if (res.ok) {
                await onReloadUser();
                onSnackbar('Successfully logged out user');
            }
        } else {
            onLogoutAll();
        }
    };

    const handleClearTrustedDevices = async () => {
        const res = await http.post(`/users/${user._id}/clearTrustedDevices`);
        if (res.ok) {
            await onReloadUser();
            onSnackbar(loc('clearTrustedDevices', lang), 'success');
        } else {
            onSnackbar(loc('somethingWentWrong', lang), 'error');
        }
    };

    const deviceInfos = _.get(user, 'deviceInfos', []);
    const timezone = _.get(user, 'location.timezone', process.env.REACT_APP_REGION_TIMEZONE);

    useEffect(() => {
        getDeviceInformation().then(info => {
            setCurrentDeviceInfo(info);
        });
    }, []);

    return (
        <>
            <Typography variant="h6">{loc('devices', lang)}</Typography>
            <Divider style={{ marginTop: theme.spacing.unit / 2, marginBottom: theme.spacing.unit }} />
            <List>
                {deviceInfos.map(deviceInfo => (
                    <DeviceInfoListItem
                        theme={theme}
                        deviceInfo={deviceInfo}
                        currentDeviceUUID={_.get(currentDeviceInfo, 'uuid')}
                        trustedDevices={_.get(user, 'twoFactorAuthentication.trustedDevices', [])}
                        lang={lang}
                        timezone={timezone}
                        onRemoveDevice={handleRemoveDevice}
                        onLogoutDevice={handleLogoutDevice}
                    />
                ))}
            </List>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <div style={{ marginTop: 0, marginBottom: theme.spacing.unit }}>
                    <Typography onClick={handleClearTrustedDevices}>
                        <a
                            data-cy="profile-clear-trusted-devices"
                            href="#/"
                            style={{ color: theme.palette.linkColor }}
                            onClick={e => {
                                e.preventDefault();
                            }}
                        >
                            {loc('accountSettings25', lang)}
                        </a>
                    </Typography>
                </div>
            </div>

            {biometricsAvailable && biometricsHasCredentials && (
                <Button
                    fullWidth
                    variant="outlined"
                    color="secondary"
                    onClick={() => {
                        warnAction(async () => {
                            try {
                                await NativeBiometric.deleteCredentials({
                                    server: process.env.REACT_APP_ORIGIN_URL
                                });
                                window.localStorage.setItem('biometricsHasCredentials', 'false');
                                onBiometricsHasCredentialsChange(false);
                            } catch (err) {}
                        }, loc('biometrics7', lang));
                    }}
                >
                    {loc('biometrics6', lang)}
                </Button>
            )}

            <div style={{ marginTop: theme.spacing.unit * 3, float: 'right' }}>
                <Button color="primary" onClick={onCancel}>
                    {loc('cancel', lang)}
                </Button>
                <Button color="primary" variant="contained" onClick={handleLogoutAll} data-cy="profile-sign-out-all">
                    {loc('signOutAll', lang)}
                </Button>
            </div>
            <div style={{ clear: 'both' }} />
        </>
    );
}

export default withTheme()(DeviceInfoWidget);

function DeviceInfoListItem(props) {
    const {
        deviceInfo,
        currentDeviceUUID,
        trustedDevices = [],
        lang,
        timezone,
        onRemoveDevice,
        onLogoutDevice,
        theme
    } = props;

    const [expanded, setExpanded] = useState(false);

    const uuid = _.get(deviceInfo, 'uuid');
    const isTrustedDevice = _.includes(trustedDevices, uuid);

    return (
        <div
            style={{
                borderLeft:
                    !_.isNil(currentDeviceUUID) &&
                    currentDeviceUUID === uuid &&
                    `2px solid ${theme.palette.primary.main}`
            }}
        >
            <ListItem button key={uuid} onClick={() => setExpanded(!expanded)}>
                <ListItemIcon style={{ marginRight: 0 }}>
                    <Icon>{expanded ? 'expand_less' : 'expand_more'}</Icon>
                </ListItemIcon>
                <ListItemText
                    primary={getDeviceString(deviceInfo, isTrustedDevice, theme)}
                    secondary={
                        !_.isNil(_.get(deviceInfo, 'lastLogIn')) &&
                        locDate(_.get(deviceInfo, 'lastLogIn'), 'MMM D YYYY - h:mm a', lang, timezone)
                    }
                />
                <ListItemSecondaryAction>
                    <Tooltip title={loc('removeDevice', lang)}>
                        <IconButton
                            disabled={!_.isNil(_.get(deviceInfo, 'sessionId'))}
                            onClick={() => onRemoveDevice(deviceInfo)}
                        >
                            <Icon>delete</Icon>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={loc('signOut', lang)}>
                        <IconButton
                            disabled={_.isNil(_.get(deviceInfo, 'sessionId'))}
                            onClick={() => onLogoutDevice(deviceInfo)}
                        >
                            <Icon>logout</Icon>
                        </IconButton>
                    </Tooltip>
                </ListItemSecondaryAction>
            </ListItem>
            <Collapse in={expanded}>
                <List dense style={{ paddingLeft: theme.spacing.unit * 2 }}>
                    <ListItem>
                        <ListItemIcon>
                            <Icon>wifi</Icon>
                        </ListItemIcon>
                        <ListItemText primary={_.get(deviceInfo, 'ip', '')} />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <Icon>location_on</Icon>
                        </ListItemIcon>
                        <ListItemText
                            primary={`${_.get(deviceInfo, 'ipLocation.city', '')}, ${_.get(
                                deviceInfo,
                                'ipLocation.province',
                                ''
                            )}, ${_.get(deviceInfo, 'ipLocation.country', '')}`}
                        />
                    </ListItem>
                </List>
            </Collapse>
        </div>
    );
}

function getDeviceString(deviceInfo, isTrustedDevice, theme) {
    let str = _.get(deviceInfo, 'device', 'Unknown');
    const browser = uppercaseFirstLetter(_.get(deviceInfo, 'browser', ''));
    if (!_.isEmpty(browser) && browser !== 'Capacitor') {
        str += ` - ${uppercaseFirstLetter(browser)}`;
    }

    return (
        <span style={{ display: 'flex' }}>
            <span style={{ marginRight: theme.spacing.unit }}>{str}</span>
            {isTrustedDevice && <Icon style={{ color: colors.green[500] }}>verified_user</Icon>}
        </span>
    );
}
