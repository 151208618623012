import React, { useContext } from 'react';

import _ from 'lodash';

import { formatAsCurrency } from 'utils/misc';
import { getTimeUntilRedemptionProcessed } from 'helpers/redemptionHelper';

import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

const Pending = ({ amountPending, onClose, redemptions, redemptionOptions, theme }) => {
    const { lang } = useContext(LocalizationContext);
    return (
        <>
            <DialogContent style={{ marginTop: theme.spacing.unit }}>
                <DialogContentText>
                    {loc('pending1', lang, {
                        amount: formatAsCurrency(amountPending, lang),
                        date: getTimeUntilRedemptionProcessed(_.last(redemptions), lang, redemptionOptions)
                    })}
                </DialogContentText>
            </DialogContent>
            <DialogActions style={{ marginBottom: 'max(8px, env(safe-area-inset-bottom, 8px))' }}>
                <Hidden smUp>
                    <Button color="primary" onClick={onClose}>
                        {loc('close', lang)}
                    </Button>
                </Hidden>
                <Button color="primary" disabled>
                    {loc('next', lang)}
                </Button>
            </DialogActions>
        </>
    );
};

export default Pending;
