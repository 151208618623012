import React from 'react';

import PropTypes from 'prop-types';
import _ from 'lodash';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import blue from '@material-ui/core/colors/blue';
import amber from '@material-ui/core/colors/amber';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon
};

const styles = theme => ({
    success: {
        backgroundColor: green[600]
    },
    error: {
        backgroundColor: red[700]
    },
    info: {
        backgroundColor: blue[700]
    },
    warning: {
        backgroundColor: amber[700]
    },
    icon: {
        fontSize: 20
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing.unit
    },
    message: {
        display: 'flex',
        alignItems: 'center'
    }
});

function MySnackbarContent({ classes, className, message, onClose, variant, anchorTop, ...other }) {
    const Icon = variantIcon[variant];

    return (
        <SnackbarContent
            className={`${classes[variant]} ${className}`}
            aria-describedby="client-snackbar"
            message={
                <span
                    id="client-snackbar"
                    data-cy="client-snackbar"
                    className={classes.message}
                    style={{
                        maxWidth: 'calc(100vw - 108px)',
                        paddingTop: anchorTop ? 'var(--custom-safe-area-top)' : null
                    }}
                >
                    <Icon className={`${classes.icon} ${classes.iconVariant}`} />
                    <span data-cy="snackbar-message">{message}</span>
                </span>
            }
            action={[
                <IconButton
                    key="close"
                    id="close-snackbar"
                    data-cy="close-snackbar"
                    aria-label="Close"
                    color="inherit"
                    className={classes.close}
                    onClick={onClose}
                >
                    <CloseIcon className={classes.icon} />
                </IconButton>
            ]}
            {...other}
        />
    );
}

MySnackbarContent.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    message: PropTypes.node,
    onClose: PropTypes.func,
    variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired
};

const MySnackbarContentWrapper = withStyles(styles)(MySnackbarContent);

function CustomSnackbar(props) {
    const { open, variant, message, duration = 5000, width } = props;

    const autoHideDuration = duration;
    // const autoHideDuration = JSON.parse(process.env.REACT_APP_AUTOMATED_TESTER_MODE) ? 500 : duration;

    return (
        <Snackbar
            anchorOrigin={{
                vertical: width === 'xs' ? 'top' : 'bottom',
                horizontal: 'center'
            }}
            autoHideDuration={variant === 'error' ? null : autoHideDuration}
            open={open}
            onClose={props.onClose}
        >
            <MySnackbarContentWrapper
                onClose={props.onClose}
                variant={!_.isEmpty(variant) ? variant : 'success'}
                message={message}
                anchorTop={width === 'xs'}
            />
        </Snackbar>
    );
}

export default withWidth()(CustomSnackbar);
