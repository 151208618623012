import React, { useState } from 'react';
import _ from 'lodash';

import {
    Card,
    CardContent,
    CardHeader,
    Icon,
    IconButton,
    Collapse,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    Switch,
    colors,
    withTheme,
    Typography
} from '@material-ui/core';

function CharityTaskInput({ formik, allCharityTasks, defaults, theme }) {
    const [expanded, setExpanded] = useState(false);

    const handleExpand = () => {
        setExpanded(!expanded);
    };

    const hasError = _.get(formik.errors, 'defaultCharityTasks', false);

    const handleToggleCharityTask = (selectedId, checked) => {
        let newDefaultCharityTasks = []; //the ordering of ids matter, it corresponds to the order charities see the charitytasks. They should be ordered the same as the defaults
        defaults.defaultCharityTasks.forEach(charityTaskId => {
            if (checked) {
                const alreadySelected = !_.isNil(
                    _.find(formik.values.defaultCharityTasks, taskId => taskId === charityTaskId)
                );
                if (alreadySelected || charityTaskId === selectedId) {
                    newDefaultCharityTasks.push(charityTaskId);
                }
            } else {
                const alreadySelected = !_.isNil(
                    _.find(formik.values.defaultCharityTasks, taskId => taskId === charityTaskId)
                );
                if (alreadySelected && charityTaskId !== selectedId) {
                    newDefaultCharityTasks.push(charityTaskId);
                }
            }
        });
        formik.setFieldValue('defaultCharityTasks', newDefaultCharityTasks);
    };

    return (
        <Card
            style={{
                marginTop: theme.spacing.unit,
                boxShadow: 'none',
                border: '1px solid rgba(224, 224, 224, 1)'
            }}
        >
            <CardHeader
                titleTypographyProps={{ variant: 'h6' }}
                title="Charity tasks assigned on registration"
                action={
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                        {hasError && <Icon style={{ color: colors.red[500] }}>error</Icon>}
                        <IconButton onClick={handleExpand}>
                            <Icon>keyboard_arrow_down</Icon>
                        </IconButton>
                    </span>
                }
            />
            <Collapse in={expanded}>
                <CardContent>
                    {hasError && (
                        <Typography style={{ color: colors.red[500] }}>
                            {_.get(formik.errors, 'defaultCharityTasks', '')}
                        </Typography>
                    )}
                    <List>
                        {/*use default values for regions charity tasks to order all charity tasks*/}
                        {defaults.defaultCharityTasks.map(charityTaskId => {
                            const charityTask = _.find(allCharityTasks, { _id: charityTaskId });
                            const selected = !_.isNil(
                                _.find(formik.values.defaultCharityTasks, taskId => taskId === charityTaskId)
                            );
                            return (
                                <ListItem key={charityTaskId}>
                                    <ListItemText primary={charityTask.en.title} />
                                    <ListItemSecondaryAction>
                                        <Switch
                                            checked={selected}
                                            onChange={e => handleToggleCharityTask(charityTaskId, e.target.checked)}
                                        />
                                    </ListItemSecondaryAction>
                                </ListItem>
                            );
                        })}
                    </List>
                </CardContent>
            </Collapse>
        </Card>
    );
}

export default withTheme()(CharityTaskInput);
