import React from 'react';

const ext = process.env.REACT_APP_REGION_EXT;

export const CONDO_NAME = {
    AUS: 'Unit',
    STD: 'Condo',
    EXP: 'Condo',
    MXD: 'Condo',
    CON: 'Condo'
}[ext];

export const ORGANIZATION_NAME = {
    AUS: 'Organisation',
    STD: 'Organization',
    EXP: 'Organization',
    MXD: 'Organisation',
    CON: 'Organisation'
}[ext];

export const CHARITY_NUMBER_NAME = {
    AUS: 'Australian Business Number',
    STD: 'Charity Registration Number',
    EXP: 'Charity Registration Number',
    MXD: 'Charity Registration Number',
    CON: 'Charity Registration Number'
}[ext];
