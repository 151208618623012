import React from 'react';
import { withTheme } from '@material-ui/core/styles';

const CustomWrapper = ({ theme, title, children, outerDivStyle = {}, innerDivStyle = {}, color = null }) => {
    return (
        <div
            style={{
                ...outerDivStyle,
                marginTop: 10,
                position: 'relative',
                border: '1px solid',
                borderColor: color ? color : theme.palette.grey[400],
                borderRadius: 4,
                padding: theme.spacing.unit
            }}
        >
            <div
                style={{
                    ...innerDivStyle,
                    paddingLeft: 4,
                    paddingRight: 4,
                    position: 'absolute',
                    top: -10,
                    backgroundColor: theme.palette.background.paper,
                    fontFamily: theme.typography.subtitle2.fontFamily,
                    color: color
                        ? color
                        : `${theme.palette.type === 'light' ? theme.palette.grey[600] : theme.palette.grey[300]}`
                }}
            >
                {title}
            </div>
            {children}
        </div>
    );
};

export default withTheme()(CustomWrapper);
