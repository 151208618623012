import { useState } from 'react';

import { deviceHelper } from 'utils/misc';

// Works with QuickRegisterForm

function useQuickRegister({ http, history, collector, reloadUsers, onSnackbar, viewCustomerAfterRegister = true }) {
    const [quickRegisterFormOpen, setQuickRegisterFormOpen] = useState(false);

    const handleRegisterUser = async (values, { setSubmitting, resetForm }) => {
        setSubmitting(true);

        const res = await http.postJSON(`/users/quickRegister`, { ...values, collector }, true);

        if (res.ok) {
            setQuickRegisterFormOpen(false);
            resetForm();
            reloadUsers();
            if (viewCustomerAfterRegister) {
                const customer_id = res.data.customer_id;
                if (deviceHelper.isNativeApp()) {
                    history.push(`/customers/${customer_id}`);
                } else {
                    window.open(`/customers/${customer_id}`, '_blank');
                }
            } else {
                onSnackbar('Successfully registered customer');
                return true
            }
        } else {
            onSnackbar(res.errorMessage, 'error');
        }

        setSubmitting(false);
        return false
    };

    return { quickRegisterFormOpen, setQuickRegisterFormOpen, handleRegisterUser };
}

export default useQuickRegister;
