import React, { useContext } from 'react';
import { withTheme } from '@material-ui/core';

import moment from 'moment-timezone';
import _ from 'lodash';

import bulkHelper from 'helpers/bulkHelper';

import { Icon, Button } from '@material-ui/core';

import * as terms from 'localizations/terms';
import useConfirmationDialog from '../ConfirmationDialog/useConfirmationDialog';

import { getQuickDropName } from 'utils/misc';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc, locDate } from 'localizations/localizationHandler';

import { _bulk } from 'std';

function QuickdropInfoAndCancel(props) {
    const { quickdropBulk, customer, http, onCancel, theme, charityEnabled } = props;

    const { lang } = useContext(LocalizationContext);

    const handleCancel = async () => {
        await http.post(`/bulks/cancelUncountedQuickdropBulks/${customer._id}`);
        onCancel();
    };

    const { confirmationDialog, handleOpen: handleConfirmationDialogOpen } = useConfirmationDialog({
        content: loc('pickupDialogDrop36', lang, { type: getQuickDropName(lang) }),
        onConfirm: handleCancel,
        confirmText: loc('yes', lang),
        cancelText: loc('no', lang)
    });

    const dropLocationName = bulkHelper.getDropLocationName(quickdropBulk);
    const collectorName = _.get(quickdropBulk, 'collector.name');
    const displayName = dropLocationName || collectorName;

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap'
                }}
            >
                {!_.isEmpty(displayName) && (
                    <span
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            margin: theme.spacing.unit,
                            flexGrow: 1
                        }}
                    >
                        <Icon style={{ marginRight: theme.spacing.unit }}>room</Icon> {displayName}
                    </span>
                )}
                <span
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        margin: theme.spacing.unit,
                        flexGrow: 1
                    }}
                >
                    <Icon style={{ marginRight: theme.spacing.unit }}>calendar_today</Icon>{' '}
                    {locDate(bulkHelper.getQuickdropDropOffDate(quickdropBulk), 'MMM Do, h:mma', lang)}
                </span>
                {charityEnabled && (
                    <span
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            margin: theme.spacing.unit,
                            flexGrow: 1
                        }}
                    >
                        <Icon style={{ marginRight: theme.spacing.unit }}>favorite</Icon>
                        {_.isNil(_.get(quickdropBulk, 'charity', null)) ? (
                            <>{loc('pickupWidgets18', lang)}</>
                        ) : (
                            <>
                                {`${_.get(quickdropBulk, 'charity.name', '')} ${
                                    !_.isNil(_.get(quickdropBulk, 'subdivision', null))
                                        ? `(${_.get(quickdropBulk, 'subdivision')})`
                                        : ''
                                }
                                                `}
                            </>
                        )}
                    </span>
                )}
            </div>
            {/*Customers can only cancel quickdrops they submitted, not ones created by collectors/admins*/}
            {!_.isNil(quickdropBulk) && _bulk.isQuickDropCreatedByCustomer(quickdropBulk, customer) && (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                        variant="outlined"
                        color="secondary"
                        style={{ margin: theme.spacing.unit }}
                        onClick={() => handleConfirmationDialogOpen(true)}
                    >
                        {loc('cancel', lang)} {getQuickDropName(lang)}
                    </Button>
                </div>
            )}
            {confirmationDialog}
        </>
    );
}

export default withTheme()(QuickdropInfoAndCancel);
