import { useState } from 'react';

import _ from 'lodash';

import { MODES } from '../constants';

function useMode(pickups, pickupSelected, prevPickupSelected, tripStartScreenEnabled = false) {
    const [mode, setMode] = useState(MODES['START_ROUTE']);

    const handleMode = mode => {
        setMode(MODES[mode]);
    };

    if (_.isEmpty(pickups) && mode.index !== MODES['DROPOFF'].index) {
        // if (!_.isNil(pickupIdSelected)) {
        //     window.alert(`There was an update to your route and you don't have any more pickups left for today.`);
        // }
        handleMode('DROPOFF');
    } else if (!_.isEmpty(pickups) && tripStartScreenEnabled && mode.index !== MODES['START_ROUTE'].index) {
        handleMode('START_ROUTE');
    } else if (
        !_.isEmpty(pickups) &&
        !tripStartScreenEnabled &&
        !_.isNil(pickupSelected) &&
        !_.isNil(prevPickupSelected) &&
        mode.index === MODES['START_ROUTE'].index
    ) {
        handleMode('SELECT');
    } else if (
        !_.isEmpty(pickups) &&
        !_.isNil(pickupSelected) &&
        !_.isNil(prevPickupSelected) &&
        !tripStartScreenEnabled &&
        pickupSelected._id !== prevPickupSelected._id &&
        mode.index !== MODES['SELECT'].index
    ) {
        handleMode('SELECT');
    }

    return { mode, handleMode };
}

export default useMode;
