import { useState, useEffect, useMemo, useContext } from 'react';

import _ from 'lodash';
import moment from 'moment-timezone';

import HttpContext from 'utils/contexts/HttpContext';

function useDateFilteredTrips({ dateFilter, operator, timezone }) {
    const http = useContext(HttpContext);

    const [loading, setLoading] = useState(true);

    const [trips, setTrips] = useState();
    const [bulks, setBulks] = useState();

    const fetchData = async () => {
        setLoading(true);
        const startDate = moment(dateFilter)
            .startOf('day')
            .toDate();

        const res = await http.getJSON(`/trips/getCollectorTrips?date=${startDate.toISOString()}&timezone=${timezone}`);

        if (res.ok) {
            setTrips(_.get(res, 'data.trips', []));
            setBulks(_.get(res, 'data.bulks', []));
        } else {
            // TODO: handle error
        }
        setLoading(false);
    };

    useEffect(() => {
        if (moment(dateFilter).isValid()) {
            fetchData();
        }
    }, [dateFilter, operator]);

    const dateFilteredTrips = useMemo(() => {
        const date = moment(dateFilter)
            .tz(timezone)
            .format('YYYYMMDD');
        const dateFilteredTrips = _.filter(trips, t => t.startDateInteger === parseInt(date));

        return dateFilteredTrips;
    }, [dateFilter, trips]);

    return {
        loading,
        dateFilteredBulks: bulks,
        dateFilteredTrips
    };
}

export default useDateFilteredTrips;
