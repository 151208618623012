import React from 'react';

import { withTheme } from '@material-ui/core/styles';
import TaxesTable from 'components/CRUDTables/TaxesTable';

function Taxes(props) {
    return <TaxesTable {...props} />;
}

export default withTheme()(Taxes);
