import React, { Component } from 'react';
import _ from 'lodash';

import { withTheme } from '@material-ui/core/styles';

function FakeLink({ theme, text, onClickEvent, color, ...rest }) {
    return (
        <span
            style={{
                color: _.isNil(color) ? theme.palette.primary.main : color,
                textDecoration: 'underline',
                cursor: 'pointer'
            }}
            onClick={onClickEvent}
            {...rest}
        >
            {text}
        </span>
    );
}

export default withTheme()(FakeLink);
