import React, { useState, useContext, useEffect } from 'react';
import _ from 'lodash';

import { withTheme } from '@material-ui/core/styles';

import StringListInput from 'components/CustomInputs/StringListInput';

import { TextField, Button, Icon, InputAdornment, IconButton, Link, Grid } from '@material-ui/core';
import ConfirmDialogContext from 'components/Dialogs/Confirm/ConfirmDialogContext';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { Clipboard } from '@capacitor/clipboard';
import CustomFormTitle from 'components/MaterialUIExtensions/CustomFormTitle';
import HttpContext from 'utils/contexts/HttpContext';

import DialogTitlePrimary from 'components/MaterialUIExtensions/DialogTitlePrimary';

import FormControl from '@material-ui/core/FormControl';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

function SubdivisionPermissions(props) {
    const http = useContext(HttpContext);
    const { lang } = useContext(LocalizationContext);
    const { label, InputProps, theme, style, onSnackbar, charity, reloadCharity } = props;
    const formShape = {
        subdivisions: [],
        permissions: []
    };

    const [inputValue, setInputValue] = useState('');
    const [editing, setEditing] = useState(false);
    const [subdivisionToEdit, setSubdivisionToEdit] = useState('');
    const [usersToEdit, setUsersToEdit] = useState('');
    const [form, setForm] = useState(_.cloneDeep(formShape));

    const duplicateValue = _.some(_.get(form, 'subdivisions'), subdivision => subdivision === inputValue);

    const warnAction = useContext(ConfirmDialogContext);

    const referralLink = `${process.env.REACT_APP_ORIGIN_URL}/${_.get(charity, 'url')}`;

    const handleAddSubdivision = async () => {
        const newValues = [..._.get(form, 'subdivisions'), inputValue.trim()];
        let updatedForm = _.cloneDeep(form);
        _.set(updatedForm, 'subdivisions', newValues);
        let success = await handleUpdate(updatedForm);
        if (success) {
            _.set(form, 'subdivisions', newValues);
            setInputValue('');
        }
    };

    const openEditDialog = subdivision => {
        setEditing(true);
        setSubdivisionToEdit(subdivision);
        setUsersToEdit(
            _.get(form, 'permissions')
                .filter(p => p.subdivision === subdivision)
                .map(permission => permission.user)
        );
    };

    const handleRemoveSubdivision = async i => {
        warnAction(async () => {
            let updatedForm = _.cloneDeep(form);
            _.get(updatedForm, 'subdivisions').splice(i, 1);
            handleUpdate(updatedForm);
            _.set(form, 'subdivisions', _.get(updatedForm, 'subdivisions'));
            setInputValue('');
        }, `${loc('dashboardSubdivision1', lang, { charity: _.get(form, 'subdivisions')[i] })}?`);
    };

    const handleAddRemovePermissions = async (users, subdivision) => {
        let newValues = [..._.get(form, 'permissions')];
        _.get(form, 'permissions').forEach((p, i) => {
            if (!users.find(u => u === p.user) && p.subdivision === subdivision) {
                newValues.splice(i, 1);
            }
        });
        for (let user of users) {
            if (!newValues.find(p => p.user === user && p.subdivision === subdivision)) {
                let permission = { user, subdivision };
                newValues = [..._.get(form, 'permissions'), permission];
            }
        }

        let updatedForm = _.cloneDeep(form);
        _.set(updatedForm, 'permissions', newValues);
        let success = await handleUpdate(updatedForm);
        if (success) {
            _.set(form, 'permissions', newValues);
            setInputValue('');
            openEditDialog(subdivision);
        }
    };

    const handleKeyPress = e => {
        e = e || window.event;
        if (e.key === 'Enter' && !_.isEmpty(inputValue) && !duplicateValue) {
            handleAddSubdivision();
        }
    };

    const handleCopyToClipboard = string => () => {
        Clipboard.write({
            string
        });
        onSnackbar(loc('growthGeneral20', lang));
    };

    const handleUpdate = async updatedForm => {
        const formData = new FormData(); // NB: FormData objects cannot be displayed in console.log
        formData.append('form', JSON.stringify(updatedForm)); // will show up in req.body
        let res = await http.post(`/charities/${charity._id}/updateSubdivisions`, formData, true, true);
        if (res.ok) {
            await reloadCharity();
            onSnackbar(loc('saved', lang));
            return true;
        } else {
            onSnackbar(res.errorMessage, res.variant);
            return false;
        }
    };

    const handleClose = () => {
        setEditing(false);
    };

    useEffect(() => {
        const loadData = () => {
            const { subdivisions, permissions } = charity;

            let formUpdated = {
                subdivisions: subdivisions,
                permissions: permissions
            };

            setForm(formUpdated);
        };

        loadData();
    }, []);

    return (
        <>
            <Grid container spacing={theme.spacing.unit * 4} style={{ width: '100%', margin: 0 }}>
                <Grid item xs={12}>
                    <Paper style={{ padding: theme.spacing.unit * 2 }}>
                        <Typography variant="h6">{loc('dashboardSetting19', lang)}</Typography>
                        {/* <Divider style={{ marginTop: theme.spacing.unit, marginBottom: theme.spacing.unit * 2 }} /> */}
                        <Typography>{loc('dashboardSetting20', lang)}</Typography>
                        <TextField
                            disabled={charity.closed}
                            data-cy="subdivision-permissions-name"
                            onChange={e => setInputValue(e.target.value)}
                            label={label}
                            value={inputValue}
                            margin="normal"
                            onKeyDown={handleKeyPress}
                            error={duplicateValue}
                            helperText={duplicateValue && loc('dashboardSubdivision5', lang, { value: 'inputValue' })}
                            style={{ width: '100%', maxWidth: '350px' }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            color="primary"
                                            onClick={handleAddSubdivision}
                                            disabled={_.isEmpty(inputValue) || duplicateValue}
                                        >
                                            <Icon>add_circle</Icon>
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            InputLabelProps={{
                                style: {
                                    maxWidth: '89%',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap'
                                }
                            }}
                            {...InputProps}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper style={{ padding: theme.spacing.unit * 2 }}>
                        {(_.get(form, 'subdivisions') === undefined || _.get(form, 'subdivisions').length === 0) && (
                            <Typography>{loc('dashboardSetting21', lang)}</Typography>
                        )}
                        <Grid container>
                            {_.get(form, 'subdivisions') !== undefined &&
                                _.get(form, 'subdivisions').length !== 0 &&
                                _.get(form, 'subdivisions').map((subdivision, i) => (
                                    <Grid item xs={12} md={6} lg={4}>
                                        <Paper
                                            elevated={4}
                                            style={{
                                                // width: `calc(100% - ${theme.spacing.unit * 4}px)`,
                                                margin: theme.spacing.unit * 2,
                                                padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px`,
                                                backgroundColor: charity.closed
                                                    ? theme.palette.text.disabled
                                                    : theme.palette.primary.main,
                                                ...style
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                    marginRight: -theme.spacing.unit * 1.5
                                                }}
                                            >
                                                <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                    <Typography
                                                        variant="h6"
                                                        style={{
                                                            fontWeight: 500,
                                                            color: 'white'
                                                        }}
                                                    >
                                                        {subdivision}
                                                    </Typography>
                                                    <Typography style={{ color: 'white' }} noWrap>
                                                        {loc('dashboardSetting23', lang)}:{' '}
                                                        <Link
                                                            href={referralLink + '/' + subdivision.replace(/\s+/g, '')}
                                                            style={{ color: 'white' }}
                                                            onClick={e => {
                                                                e.preventDefault();
                                                                handleCopyToClipboard(
                                                                    referralLink + '/' + subdivision.replace(/\s+/g, '')
                                                                )();
                                                            }}
                                                        >
                                                            .../{_.get(charity, 'url')}/
                                                            {subdivision.replace(/\s+/g, '')}
                                                        </Link>
                                                    </Typography>
                                                    <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                        {/* {console.log(
                                                            _.get(form, 'permissions').filter(
                                                                p => p.subdivision === subdivision
                                                            )
                                                        )} */}
                                                        {_.get(form, 'permissions') === undefined ||
                                                        _.get(form, 'permissions').filter(
                                                            p => p.subdivision === subdivision
                                                        ).length === 0 ? (
                                                            <tr style={{ verticalAlign: 'middle', color: 'white' }}>
                                                                <td>
                                                                    <Typography
                                                                        variant="body1"
                                                                        style={{
                                                                            color: 'white',
                                                                            fontSize:
                                                                                theme.typography.captionNext.fontSize
                                                                        }}
                                                                    >
                                                                        {loc('dashboardSubdivision6', lang)}
                                                                    </Typography>
                                                                    <Typography
                                                                        variant="body1"
                                                                        style={{
                                                                            color: 'white',
                                                                            fontSize:
                                                                                theme.typography.captionNext.fontSize
                                                                        }}
                                                                    >
                                                                        {loc('dashboardSubdivision7', lang)}
                                                                    </Typography>
                                                                </td>
                                                            </tr>
                                                        ) : (
                                                            <>
                                                                {_.get(form, 'permissions')
                                                                    .filter(p => p.subdivision === subdivision)
                                                                    .map(permission => (
                                                                        <tr
                                                                            style={{
                                                                                verticalAlign: 'middle',
                                                                                color: 'white'
                                                                            }}
                                                                        >
                                                                            <td style={{ lineHeight: 0, width: 32 }}>
                                                                                <Icon
                                                                                    style={{
                                                                                        marginRight: theme.spacing.unit,
                                                                                        display: 'flex',
                                                                                        flexDirection: 'column'
                                                                                    }}
                                                                                >
                                                                                    person
                                                                                </Icon>
                                                                            </td>
                                                                            <td>
                                                                                <Typography
                                                                                    variant="body1"
                                                                                    style={{
                                                                                        color: 'white',
                                                                                        fontSize:
                                                                                            theme.typography.captionNext
                                                                                                .fontSize
                                                                                    }}
                                                                                >
                                                                                    {permission.user}
                                                                                </Typography>
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                            </>
                                                        )}
                                                    </div>
                                                </div>

                                                <div
                                                    style={{
                                                        borderLeft: '1px solid white',
                                                        paddingLeft: theme.spacing.unit,
                                                        paddingRight: theme.spacing.unit,
                                                        marginLeft: theme.spacing.unit
                                                    }}
                                                >
                                                    <IconButton
                                                        data-cy={`subdivision-permissions-${_.kebabCase(subdivision)}`}
                                                        style={{ color: 'white' }}
                                                        onClick={() => {
                                                            openEditDialog(subdivision);
                                                        }}
                                                        disabled={charity.closed}
                                                    >
                                                        <Icon>edit</Icon>
                                                    </IconButton>
                                                    <IconButton
                                                        disabled={charity.closed}
                                                        style={{ color: 'white' }}
                                                        onClick={() => {
                                                            handleRemoveSubdivision(i);
                                                        }}
                                                    >
                                                        <Icon>delete</Icon>
                                                    </IconButton>
                                                </div>
                                            </div>
                                        </Paper>
                                    </Grid>
                                ))}
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>

            <Dialog open={editing} onClose={handleClose} maxWidth="sm" fullWidth>
                <DialogTitlePrimary>{loc('dashboardSubdivision2', lang)}</DialogTitlePrimary>
                <DialogContent>
                    <FormControl style={{ marginBottom: theme.spacing.unit }} fullWidth>
                        <StringListInput
                            style={{ color: 'white' }}
                            label={loc('dashboardSubdivision3', lang)}
                            values={usersToEdit}
                            onChange={users => handleAddRemovePermissions(users, subdivisionToEdit)}
                            error={email => {
                                let regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                                return !(email === '') && !regexEmail.test(email.toLowerCase());
                            }}
                            errorMessage={loc('dashboardSubdivision4', lang)}
                            InputProps={{
                                'data-cy': 'subdivision-permissions-email'
                            }}
                        />
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={handleClose} data-cy="subdivision-permissions-done">
                        {loc('done', lang)}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default withTheme()(SubdivisionPermissions);
