import React from 'react';
import _ from 'lodash';
import { withTheme } from '@material-ui/core/styles';
import SelectDropDownTemplate from '../SelectDropDownTemplate';

function CollectorDropDownSelect({
    theme,
    style,
    value,
    collectors,
    cypress_id,
    id,
    showAll = true,
    onChange,
    multiple,
    fullWidth,
    label = 'Collector',
    disabled = false,
    endAdornment
}) {
    let updatedStyle = _.cloneDeep(style);
    if (_.isNil(style)) {
        updatedStyle = { marginRight: theme.spacing.unit, width: 160 };
    }

    return (
        <SelectDropDownTemplate
            helperText={label}
            id={id}
            name="collectors"
            value={value}
            onChange={onChange}
            labelCustomWidth={label.length * theme.spacing.unit}
            items={collectors}
            itemValueKey="_id"
            itemRenderValueKey="name"
            cypress_id={cypress_id}
            showAll={showAll}
            style={updatedStyle}
            multiple={multiple}
            fullWidth={fullWidth}
            disabled={disabled}
            endAdornment={endAdornment}
        />
    );
}

export default withTheme()(CollectorDropDownSelect);
