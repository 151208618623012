import React, { useContext } from 'react';

import { withTheme } from '@material-ui/core/styles';
import withMobileDialog from '@material-ui/core/withMobileDialog';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

function UncountedBagsDialog({ theme, open, message, onYes, onClose, onContinue }) {
    const { lang } = useContext(LocalizationContext);
    return (
        <Dialog open={open}>
            <DialogTitle>Did you count all bags?</DialogTitle>
            <DialogContent>
                <DialogContentText>{message || 'No message provided'}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color="primary" size="small" onClick={onYes} data-cy="UncountedBagsDialog-yes">
                    {loc('yes', lang)}
                </Button>
                <Button color="default" size="small" onClick={onClose} data-cy="UncountedBagsDialog-no">
                    {loc('no', lang)}
                </Button>
                <Button color="default" size="small" onClick={onContinue} data-cy="UncountedBagsDialog-no_dont_exist">
                    {loc('no_dont_exist', lang)}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
export default withMobileDialog({ breakpoint: 'xs' })(withTheme()(UncountedBagsDialog));
