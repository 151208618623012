import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    Grid,
    GridList,
    TextField,
    InputAdornment,
    DialogActions,
    LinearProgress,
    Button,
    Fade,
    colors,
    withTheme,
    withMobileDialog,
    Typography,
    FormControl,
    Select,
    MenuItem
} from '@material-ui/core';

import DialogTitlePrimary from 'components/MaterialUIExtensions/DialogTitlePrimary';

import { _collector, _commodity, _time } from 'std';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

import useDatePicker from 'components/DateTimePickersTz/hooks/useDatePicker';
import DatePicker from 'components/DateTimePickersTz/DatePicker';

const commodityPickupStatuses = ['Requested', 'Collected', 'Processed'];

function CommodityPickupDialog(props) {
    const {
        open,
        collectors = [],
        commodities = [],
        initialCommodity,
        initialStatus,
        //openBulkCounts,
        //commodityPickup = null,
        commodityPickups,
        loading,
        onClose,
        onSubmit,
        theme,
        fullScreen
        /*isAdmin,
        allCollectorsBySkuType,
        collectorIdsSelected = [],
        endDate,
        setCollectorIdsSelected,
        setEndDate,
        imageURLs,
        imagePreviews,
        handleAddImage,
        handleDeleteImage,
        uploadingImage,
        resetImages = () => {}*/
    } = props;

    /*const timezone = process.env.REACT_APP_REGION_TIMEZONE;

    const onSnackbar = useContext(SnackbarContext);
    const warnAction = useContext(ConfirmDialogContext);*/
    const { lang } = useContext(LocalizationContext);

    const editingSingleCommodityPickup = commodityPickups.length <= 1;

    //const [step, setStep] = useState(0);
    const [commodity, setCommodity] = useState(!_.isNil(initialCommodity) ? initialCommodity : _.first(commodities));
    const [status, setStatus] = useState(!_.isNil(initialStatus) ? initialStatus : 'Requested');
    const [collector, setCollector] = useState(_.get(_.first(commodityPickups), 'collector._id') || '');
    const [fieldValues, setFieldValues] = useState([]);
    const [trailerLabel, setTrailerLabel] = useState('');

    //const editing = !_.isNil(commodityPickup);

    //const skuType = _commodity.getSkuType(commodity);

    const totalFieldValuesZero = _.sum(fieldValues) === 0;

    /*const bulkBreakdownForCommodity = _.get(openBulkCounts, _commodity.getSkuType(commodity));
    const openOrders = _.get(bulkBreakdownForCommodity, 'totalBulks', 0);
    const totalCommodityAmount = _.get(bulkBreakdownForCommodity, 'totalCommodityAmount', 0);
    const openOrdersRequired =
        (_commodity.isSplitCompleted(commodity) || _commodity.isAcceptUnitsCompleted(commodity)) && !editing;
    const isSplitCompletedCommodity = _commodity.isSplitCompleted(commodity);
    const configuredCollectors = _.get(allCollectorsBySkuType, skuType, []);*/

    const handleChangeFieldValue = (e, i) => {
        let newVal = e.target.value;
        if (!_.isEmpty(newVal)) {
            newVal = parseInt(e.target.value);
        } else {
            newVal = 0;
        }
        const fieldValuesCopy = _.cloneDeep(fieldValues);
        fieldValuesCopy[i] = newVal;
        setFieldValues(fieldValuesCopy);
    };

    const handleChangeCommodity = e => {
        const commodity = _.find(commodities, { _id: e.target.value });
        if (!_.isNil(commodity)) {
            setCommodity(commodity);
            setFieldValues(
                getFieldValuesInitialValue(commodity, editingSingleCommodityPickup ? _.first(commodityPickups) : null)
            );
        }
    };

    const earliestDate = _.get(
        _.minBy(commodityPickups, c => _.get(c, 'collectionPeriod.startDate')),
        'collectionPeriod.startDate'
    );
    const latestDate = _.get(
        _.maxBy(commodityPickups, c => _.get(c, 'collectionPeriod.endDate')),
        'collectionPeriod.endDate'
    );
    const { date: startDate, timezone, handleChangeDate: handleChangeStartDate } = useDatePicker({
        timezones: [process.env.REACT_APP_REGION_TIMEZONE],
        initVal: moment(earliestDate).tz(process.env.REACT_APP_REGION_TIMEZONE)
    });
    const { date: endDate, handleChangeDate: handleChangeEndDate } = useDatePicker({
        timezones: [process.env.REACT_APP_REGION_TIMEZONE],
        initVal: moment(latestDate).tz(process.env.REACT_APP_REGION_TIMEZONE)
    });

    /*const handlePayloadNext = () => {
        const totalPayload = _.sum(fieldValues);
        if (editing) {
            onSubmit(fieldValues, trailerLabel);
        } else if (_commodity.isAcceptUnitsCompleted(commodity) && totalPayload < totalCommodityAmount) {
            warnAction(
                () => setStep(step + 1),
                <DialogContentText>
                    <Typography variant="h6">Are You Sure?</Typography>
                    <div style={{ color: colors.red[500], marginTop: theme.spacing.unit * 2 }}>
                        {totalCommodityAmount} {_commodity.getUnit(commodity)}
                        {totalCommodityAmount !== 0 && 's'} have been recovered from customers, please double check the
                        unit total and make sure to include any small or miscellaneous items.
                    </div>
                </DialogContentText>,
                null,
                null,
                { color: colors.red[500] }
            );
        } else {
            setStep(step + 1);
        }
    };*/

    const handleSubmit = () => {
        if (status === 'Requested') {
            onSubmit({
                status,
                fieldValues,
                commodityId: commodity._id,
                collectorId: editingSingleCommodityPickup ? collector : null,
                startDate
            });
        } else if (status === 'Collected') {
            onSubmit({
                status,
                fieldValues,
                commodityId: commodity._id,
                collectorId: editingSingleCommodityPickup ? collector : null,
                startDate,
                endDate
            });
        } else {
            onSubmit({
                status,
                fieldValues,
                trailerLabel,
                commodityId: commodity._id,
                collectorId: editingSingleCommodityPickup ? collector : null,
                startDate,
                endDate
            });
        }
    };

    const payloadStep = (
        <>
            <DialogContent style={{ paddingTop: theme.spacing.unit * 2 }}>
                {/*!editing && (
                    <DatePicker
                        style={{ marginTop: theme.spacing.unit * 4, marginBottom: theme.spacing.unit }}
                        format="MMM/DD/YYYY"
                        variant="outlined"
                        label="Cut off date"
                        margin="normal"
                        timezone={timezone}
                        value={endDate}
                        onChange={date => setEndDate(date)}
                        type="end"
                    />
                )*/}
                <DialogContentText>
                    <>
                        <TextField
                            style={{
                                marginRight: theme.spacing.unit,
                                marginTop: theme.spacing.unit
                            }}
                            margin="normal"
                            type="tel"
                            fullWidth
                            label="Commodity"
                            name="Commodity"
                            value={_.get(commodity, '_id')}
                            variant="outlined"
                            onChange={handleChangeCommodity}
                            data-cy={`commodity-pickup-dialog-commodity`}
                            select
                        >
                            {commodities.map(c => {
                                return (
                                    <MenuItem key={c._id} value={c._id}>
                                        {_commodity.getSkuType(c)}
                                    </MenuItem>
                                );
                            })}
                        </TextField>{' '}
                        {editingSingleCommodityPickup && (
                            <TextField
                                margin="normal"
                                style={{
                                    marginRight: theme.spacing.unit,
                                    marginTop: theme.spacing.unit
                                }}
                                type="tel"
                                fullWidth
                                label="Collector"
                                name="Collector"
                                value={collector}
                                variant="outlined"
                                onChange={e => setCollector(e.target.value)}
                                data-cy={`commodity-pickup-dialog-collector`}
                                select
                            >
                                {collectors.map(c => {
                                    return (
                                        <MenuItem key={c._id} value={c._id}>
                                            {_collector.getName(c)}
                                        </MenuItem>
                                    );
                                })}
                            </TextField>
                        )}
                        <div style={{ display: 'flex', marginBottom: theme.spacing.unit }}>
                            <FormControl
                                variant="outlined"
                                style={{ marginRight: theme.spacing.unit }}
                                margin="dense"
                                fullWidth
                            >
                                <DatePicker
                                    timezone={timezone}
                                    label="Start Date"
                                    format="DD MMM YYYY"
                                    value={startDate}
                                    style={{ margin: theme.spacing.unit }}
                                    onChange={handleChangeStartDate}
                                    variant="outlined"
                                />
                            </FormControl>
                            {status !== 'Requested' && (
                                <FormControl variant="outlined" fullWidth margin="dense">
                                    <DatePicker
                                        timezone={timezone}
                                        label="End Date"
                                        format="DD MMM YYYY"
                                        value={endDate}
                                        onChange={handleChangeEndDate}
                                        variant="outlined"
                                    />
                                </FormControl>
                            )}
                        </div>
                        <TextField
                            style={{
                                marginRight: theme.spacing.unit,
                                marginTop: theme.spacing.unit
                            }}
                            margin="normal"
                            type="tel"
                            fullWidth
                            label="Status"
                            name="Status"
                            value={status}
                            variant="outlined"
                            onChange={e => setStatus(e.target.value)}
                            data-cy={`commodity-pickup-dialog-status`}
                            select
                        >
                            {commodityPickupStatuses.map(status => {
                                return (
                                    <MenuItem
                                        key={status}
                                        value={status}
                                        data-cy={`commodity-pickup-dialog-status-${status.toLowerCase()}`}
                                    >
                                        {status}
                                    </MenuItem>
                                );
                            })}
                        </TextField>
                        {status === 'Processed' && (
                            <>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        marginTop: theme.spacing.unit,
                                        marginBottom: theme.spacing.unit * 2,
                                        flexWrap: 'wrap'
                                    }}
                                >
                                    <span>Enter the amounts to be picked up:</span>
                                    <span>
                                        {/*!editing && !_commodity.isAutoCompleted(commodity) && (
                                        <>
                                            <InlineChip
                                                color={theme.palette.primary.main}
                                                textColor="white"
                                                style={{ flexShrink: 0 }}
                                                text={`Open orders: ${openOrders}`}
                                            />
                                            <InlineChip
                                                color={theme.palette.secondary.main}
                                                textColor="white"
                                                style={{ flexShrink: 0, marginLeft: theme.spacing.unit }}
                                                text={`${totalCommodityAmount} ${_commodity.getUnit(commodity)}${
                                                    totalCommodityAmount !== 1 ? 's' : ''
                                                }`}
                                            />
                                        </>
                                    )*/}
                                    </span>
                                </div>
                                <Grid container spacing={theme.spacing.unit}>
                                    {_.get(commodity, 'collectorPickupFields', []).map((pickupField, i) => {
                                        return (
                                            <Grid item xs={12} lg={4} key={`${_.get(commodity, '_id')}-${i}`}>
                                                <TextField
                                                    style={{
                                                        marginRight: theme.spacing.unit,
                                                        marginTop: theme.spacing.unit
                                                    }}
                                                    type="tel"
                                                    fullWidth
                                                    label={_.get(pickupField, 'description')}
                                                    name={_.get(pickupField, 'description')}
                                                    value={fieldValues[i]}
                                                    variant="outlined"
                                                    onChange={e => handleChangeFieldValue(e, i)}
                                                    InputProps={{
                                                        endAdornment: !_.isEmpty(_.get(pickupField, 'unit')) && (
                                                            <InputAdornment position="end">
                                                                {_.get(pickupField, 'unit')}
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                    data-cy={`commodity-pickup-dialog-payload-${_.kebabCase(
                                                        _.get(pickupField, 'description')
                                                    )}`}
                                                />
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                                <div style={{ marginTop: theme.spacing.unit * 2 }}>
                                    <span>Enter the trailer number:</span>
                                    <Grid
                                        container
                                        spacing={theme.spacing.unit}
                                        style={{ marginTop: theme.spacing.unit * 2 }}
                                    >
                                        <Grid item xs={12} lg={4}>
                                            <TextField
                                                style={{
                                                    marginTop: theme.spacing.unit
                                                }}
                                                fullWidth
                                                label="Trailer Number"
                                                value={trailerLabel}
                                                variant="outlined"
                                                onChange={e => setTrailerLabel(e.target.value)}
                                            />
                                        </Grid>
                                    </Grid>
                                </div>{' '}
                            </>
                        )}
                    </>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={onClose} data-cy="commodity-pickup-dialog-close">
                    {loc('close', lang)}
                </Button>
                <Button
                    color="primary"
                    onClick={handleSubmit}
                    disabled={
                        (totalFieldValuesZero && status === 'Processed') ||
                        (editingSingleCommodityPickup && _.isEmpty(collector)) ||
                        loading
                    }
                    data-cy="commodity-pickup-dialog-payload-next-btn"
                >
                    {loc('submit', lang)}
                </Button>
            </DialogActions>
        </>
    );

    /*const pictureStep = (
        <>
            <DialogContent style={{ marginTop: theme.spacing.unit * 2 }}>
                <DialogContentText>
                    <ImageCapture
                        imageURLs={imagePreviews}
                        handleAddImage={handleAddImage}
                        handleDeleteImage={handleDeleteImage}
                        uploadingImage={uploadingImage}
                        placeholderText={<span>Please upload or take a photo of what needs to be picked up.</span>}
                        onSnackbar={onSnackbar}
                    />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={() => setStep(step - 1)}>
                    Back
                </Button>
                <Button
                    color="primary"
                    onClick={() => onSubmit(fieldValues, imageURLs)}
                    disabled={
                        loading || (_.isNil(imagePreviews) && !JSON.parse(process.env.REACT_APP_AUTOMATED_TESTER_MODE))
                    }
                    data-cy="commodity-pickup-dialog-picture-next-btn"
                >
                    {loc('submit', lang)}
                </Button>
            </DialogActions>
        </>
    );

    const steps = [payloadStep, pictureStep];*/

    return (
        <Dialog open={open} fullWidth onClose={onClose} fullScreen={fullScreen}>
            <DialogTitlePrimary closeButtonShown={true} onClose={onClose}>
                Edit {_commodity.getSkuType(commodity)} pickup
            </DialogTitlePrimary>
            {payloadStep}
            <Fade in={loading}>
                <LinearProgress />
            </Fade>
        </Dialog>
    );
}

export default withMobileDialog({ breakpoint: 'xs' })(withTheme()(CommodityPickupDialog));

function getFieldValuesInitialValue(commoditySelected, commodityPickup) {
    let initialValues = _.get(commoditySelected, 'collectorPickupFields', []).map(pickupField => {
        const payloadForField = _.find(_.get(commodityPickup, 'payload', []), {
            description: pickupField.description,
            unit: pickupField.unit
        });
        return _.get(payloadForField, 'quantity', 0);
    });
    const payloadForOther = _.find(_.get(commodityPickup, 'payload', []), {
        description: 'Other'
    });
    initialValues.push(_.get(payloadForOther, 'quantity', 0)); //add extra zero for 'Other' field
    return initialValues;
}

function commodityNotConfigured(configForCommodity) {
    return _.isNil(configForCommodity) || _.isEmpty(_.get(configForCommodity, 'emails'));
}
