import React, { useContext } from 'react';
import _ from 'lodash';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { Dialog, DialogActions, DialogContent, Button, withMobileDialog, withTheme } from '@material-ui/core';

import { getTextInput } from '../helperFunctions';

import SocialShareInput from '../Inputs/SocialShareInput';
import CharityTaskInput from '../Inputs/CharityTaskInput';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

function RegionForm(props) {
    const {
        region, //NOTE: will be null/undefined if creating a new one
        open,
        defaults,
        allCharityTasks,
        onClose,
        onSubmit,
        theme,
        fullScreen
    } = props;

    const { lang } = useContext(LocalizationContext);

    const formik = useFormik({
        initialValues: {
            _id: _.get(region, '_id', undefined),
            name: _.get(region, 'name', ''),
            lat: _.get(region, 'lat', ''),
            lng: _.get(region, 'lng', ''),
            rad: _.get(region, 'rad', ''),
            defaultCharityTasks: _.get(region, 'defaultCharityTasks', defaults.defaultCharityTasks),
            social: {
                MSG_SOCIAL_ORGANIZATION_DEFAULT: _.get(
                    region,
                    'social.MSG_SOCIAL_ORGANIZATION_DEFAULT',
                    defaults.social.MSG_SOCIAL_ORGANIZATION_DEFAULT
                ),
                MSG_SOCIAL_CUSTOMER_RECYCLED: _.get(
                    region,
                    'social.MSG_SOCIAL_CUSTOMER_RECYCLED',
                    defaults.social.MSG_SOCIAL_CUSTOMER_RECYCLED
                ),
                MSG_SOCIAL_CUSTOMER_DONATED: _.get(
                    region,
                    'social.MSG_SOCIAL_CUSTOMER_DONATED',
                    defaults.social.MSG_SOCIAL_CUSTOMER_DONATED
                ),
                MSG_SOCIAL_CUSTOMER_DEFAULT: _.get(
                    region,
                    'social.MSG_SOCIAL_CUSTOMER_DEFAULT',
                    defaults.social.MSG_SOCIAL_CUSTOMER_DEFAULT
                )
            }
        },
        validationSchema: Yup.object({
            name: Yup.string().required(),
            lat: Yup.number()
                .required()
                .min(-90, 'This must be -90 or greater')
                .max(90, 'This must be 90 or less'),
            lng: Yup.number()
                .required()
                .min(-180, 'This must be -180 or greater')
                .max(180, 'This must be 180 or less'),
            rad: Yup.number()
                .required()
                .min(0, 'This must be a positive number'),
            defaultCharityTasks: Yup.array().min(1, 'You must select at least 1'),
            social: Yup.object({
                MSG_SOCIAL_ORGANIZATION_DEFAULT: Yup.object({
                    subject: Yup.string().required('You must enter a subject'),
                    message: Yup.string().required('You must enter a message'),
                    hashtags: Yup.array(Yup.string())
                }),
                MSG_SOCIAL_CUSTOMER_RECYCLED: Yup.object({
                    subject: Yup.string().required('You must enter a subject'),
                    message: Yup.string().required('You must enter a message'),
                    hashtags: Yup.array(Yup.string())
                }),
                MSG_SOCIAL_CUSTOMER_DONATED: Yup.object({
                    subject: Yup.string().required('You must enter a subject'),
                    message: Yup.string().required('You must enter a message'),
                    hashtags: Yup.array(Yup.string())
                }),
                MSG_SOCIAL_CUSTOMER_DEFAULT: Yup.object({
                    subject: Yup.string().required('You must enter a subject'),
                    message: Yup.string().required('You must enter a message'),
                    hashtags: Yup.array(Yup.string())
                })
            })
        }),
        onSubmit
    });

    return (
        <Dialog onClose={onClose} open={open} fullScreen={fullScreen} fullWidth>
            <DialogContent>
                {getTextInput(theme, 'name', 'Name', formik)}
                <div style={{ display: 'flex' }}>
                    <div style={{ marginRight: theme.spacing.unit, flexGrow: 1 }}>
                        {getTextInput(theme, 'lat', 'Lat', formik, 'number')}
                    </div>
                    <div style={{ flexGrow: 1 }}>{getTextInput(theme, 'lng', 'Lng', formik, 'number')}</div>
                </div>
                {getTextInput(theme, 'rad', 'Radius (km)', formik, 'number')}
                <CharityTaskInput formik={formik} allCharityTasks={allCharityTasks} defaults={defaults} />
                <SocialShareInput
                    path="social.MSG_SOCIAL_CUSTOMER_DEFAULT"
                    formik={formik}
                    headerText="Customer default share message"
                />
                <SocialShareInput
                    path="social.MSG_SOCIAL_CUSTOMER_RECYCLED"
                    formik={formik}
                    headerText="Customer pickup completed share message"
                />
                <SocialShareInput
                    path="social.MSG_SOCIAL_CUSTOMER_DONATED"
                    formik={formik}
                    headerText="Customer pickup donated share message"
                />
                <SocialShareInput
                    path="social.MSG_SOCIAL_ORGANIZATION_DEFAULT"
                    formik={formik}
                    headerText="Charity share message"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button data-cy="region-form-submit" onClick={formik.handleSubmit}>
                    {loc('submit', lang)}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default withMobileDialog()(withTheme()(RegionForm));
