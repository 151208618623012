import React, { useContext, useState } from 'react';
import _ from 'lodash';
import { _time } from 'std';

import useTable from 'components/Table/useTable';
import DashBoardTable from 'components/Table/DashBoardTable';

import useDateRangePicker from 'components/DateTimePickersTz/hooks/useDateRangePicker';
import DateRangePicker from 'components/DateTimePickersTz/DateRangePicker';

import StateSelectDropDown from 'components/DropDownSelects/StateSelectDropDown';

import moment from 'moment-timezone';
import queryString from 'query-string';

import {
    withTheme,
    TableRow,
    TableCell,
    Paper,
    Grid,
    FormControl,
    MenuItem,
    Select,
    Menu,
    IconButton,
    Icon,
    Button,
    TextField,
    InputLabel,
    OutlinedInput,
    Typography
} from '@material-ui/core';

import { formatAsCurrency, downloadObjectAsCSV, isAUSRegion } from 'utils/misc';
import { _promo } from 'std';
import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

let headers = [
    { name: 'Code^', key: 'code' },
    { name: 'Uses^', key: 'Uses' },
    { name: 'Applied^', key: 'Applied' },
    { name: 'Amount Discounted^', key: 'Amount Discounted' },
    { name: 'Total Containers (Over/Under/Non)^', key: 'Total Containers' },
    { name: 'Total Deposit^', key: 'Total Deposit' },
    { name: 'Kickbacks^', key: 'Kickbacks' },
    { name: 'Kickback Value^', key: 'Kickback Value' }
];

isAUSRegion() && headers.push({ name: 'State^', key: 'State' });

function PromoUsage(props) {
    const { theme } = props;
    const { lang } = useContext(LocalizationContext);
    const collapseSearch = useMediaQuery(theme.breakpoints.down('sm'));
    const [filtersOpen, setFiltersOpen] = useState(null);
    const [exportLoading, setExportLoading] = useState(false);

    const {
        loading,
        getData,
        response,
        page,
        rowsPerPage,
        filteredData,
        search,
        filteredHeaders,
        totalDataEntries,
        handleChange,
        handleSearch,
        handleChangeFilterHeaders,
        handleAllFilterHeaders,
        handleChangePage,
        handleChangeRowsPerPage
    } = useTable({
        url: '/promos/AUS/getUsage?promoType=normal',
        key: 'promoUsage',
        headers: headers.map(h => h.name)
    });

    const {
        loading: loadingRef,
        getData: getDataRef,
        response: responseRef,
        page: pageRef,
        rowsPerPage: rowsPerPageRef,
        filteredData: filteredDataRef,
        search: searchRef,
        filteredHeaders: filteredHeadersRef,
        totalDataEntries: totalDataEntriesRef,
        handleChange: handleChangeRef,
        handleSearch: handleSearchRef,
        handleChangeFilterHeaders: handleChangeFilterHeadersRef,
        handleAllFilterHeaders: handleAllFilterHeadersRef,
        handleChangePage: handleChangePageRef,
        handleChangeRowsPerPage: handleChangeRowsPerPageRef
    } = useTable({
        url: '/promos/AUS/getUsage?promoType=referral',
        key: 'promoUsage',
        headers: headers.map(h => h.name)
    });

    const renderRow = (promo, filteredHeaders) => {
        return (
            <TableRow key={promo._id}>
                {filteredHeaders.includes('Code^') && <TableCell>{_promo.getCode(promo)}</TableCell>}
                {filteredHeaders.includes('Uses^') && <TableCell>{_.get(promo, 'Uses', 0)}</TableCell>}
                {filteredHeaders.includes('Applied^') && <TableCell>{_.get(promo, 'Applied', 0)}</TableCell>}
                {filteredHeaders.includes('Amount Discounted^') && (
                    <TableCell>{formatAsCurrency(_.get(promo, 'Amount Discounted', 0))}</TableCell>
                )}
                {filteredHeaders.includes('Total Containers (Over/Under/Non)^') && (
                    <TableCell>
                        {_.get(promo, 'Total Containers', 0)} ({_.get(promo, 'Large Containers', 0)}/
                        {_.get(promo, 'Small Containers', 0)}/{_.get(promo, 'NonConformant Containers', 0)})
                    </TableCell>
                )}
                {filteredHeaders.includes('Total Deposit^') && (
                    <TableCell>{formatAsCurrency(_.get(promo, 'Total Deposit', 0))}</TableCell>
                )}
                {filteredHeaders.includes('Kickbacks^') && <TableCell>{_.get(promo, 'Kickbacks', 0)}</TableCell>}
                {filteredHeaders.includes('Kickback Value^') && (
                    <TableCell>{formatAsCurrency(_.get(promo, 'Kickback Value', 0))}</TableCell>
                )}
                {isAUSRegion() && filteredHeaders.includes('State^') && (
                    <TableCell>{_.get(promo, 'State', 'N/A')}</TableCell>
                )}
            </TableRow>
        );
    };

    const renderSearch = children => {
        if (collapseSearch) {
            return (
                <Menu open={Boolean(filtersOpen)} anchorEl={filtersOpen} onClose={handleFiltersOpen(true)}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            padding: theme.spacing.unit * 2
                        }}
                    >
                        {children}
                    </div>
                </Menu>
            );
        } else {
            return (
                <div
                    style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'space-between' }}
                >
                    {/* <div>{customSearch}</div> */}
                    <div style={{ display: 'flex', alignItems: 'center' }}>{children}</div>
                </div>
            );
        }
    };

    const {
        startDate,
        endDate,
        timezone,
        dateWindow,
        handleChangeStartDate,
        handleChangeEndDate,
        handleGoForwards,
        handleGoBackwards,
        handleWindowSelect
    } = useDateRangePicker({
        saveStateInURL: true,
        timezones: [process.env.REACT_APP_REGION_TIMEZONE],
        initStartVal: _time.getStartOfWeek(process.env.REACT_APP_REGION_TIMEZONE),
        initEndVal: _time.getEndOfDay(process.env.REACT_APP_REGION_TIMEZONE)
    });

    const handleSubmit = e => {
        getData();
        getDataRef();
        handleSearch(e);
        handleSearchRef(e);
        if (filtersOpen) {
            setFiltersOpen(false);
        }
    };

    const inputStyles = {
        margin: theme.spacing.unit / 2
    };

    const handleChangeAll = e => {
        handleChange(e);
        handleChangeRef(e);
    };

    const handleFiltersOpen = close => e => {
        if (close || Boolean(filtersOpen)) {
            setFiltersOpen(null);
        } else {
            setFiltersOpen(e.currentTarget);
        }
    };

    const onChangeFilterHeader = e => {
        handleChangeFilterHeadersRef(e);
        handleChangeFilterHeaders(e);
    };

    const onChangeAllFilterHeaders = all => e => {
        handleAllFilterHeaders(all)(e);
        handleAllFilterHeadersRef(all)(e);
    };

    const handleDownload = async type => {
        setExportLoading(true);
        const csvRows = [];

        const url = `/promos/AUS/getUsage?promoType=${type}&page_size=${999999999}`;

        const qs = queryString.parse(window.location.search);
        let apiURL = url + `${url.includes('?') ? '&' : '?'}`;
        for (let key in qs) {
            if (qs[key]) {
                const searchKey = key;
                apiURL += `&${searchKey}=${encodeURIComponent(qs[key])}`;
            }
        }

        let res = await props.http.getJSON(apiURL);

        res.data.promoUsage.forEach(promo => {
            const newRow = {
                Code: _promo.getCode(promo),
                Uses: _.get(promo, 'Uses', 'N/A'),
                Applied: _.get(promo, 'Applied', 'N/A'),
                AmountDiscounted: formatAsCurrency(_.get(promo, 'Amount Discounted', 0)),
                TotalContainers: _.get(promo, 'Total Containers', 0),
                Over: _.get(promo, 'Large Containers', 0),
                Under: _.get(promo, 'Small Containers', 0),
                Non: _.get(promo, 'NonConformant Containers', 0),
                TotalDeposit: formatAsCurrency(_.get(promo, 'Total Deposit', 0)),
                KickBacks: _.get(promo, 'Kickbacks', 0),
                KickBacksValue: formatAsCurrency(_.get(promo, 'Kickback Value', 0)),
                State: _.get(promo, 'State', 'N/A')
            };

            csvRows.push(newRow);
        });

        const date = moment().format('YYYY-MM-DD HH:mm');
        const fileName = `promo_usage_${type}_${date}`;

        try {
            await downloadObjectAsCSV(csvRows, fileName);
        } catch (err) {
            console.log(err);
        }
        setExportLoading(false);
    };

    const customActions = type => {
        var tableResponse = response;

        if (type === 'referral') {
            tableResponse = responseRef;
        }

        return (
            isAUSRegion() && (
                <>
                    <FormControl style={{ marginRight: 16 }}>
                        <Typography>
                            Total Discounted: {formatAsCurrency(_.get(tableResponse, 'totalDiscounted', 0))}
                        </Typography>
                        <Typography>
                            Total Deposit: {formatAsCurrency(_.get(tableResponse, 'totalDeposited', 0))}
                        </Typography>
                        <Typography>
                            Total KickBack: {formatAsCurrency(_.get(tableResponse, 'totalKickBack', 0))}
                        </Typography>
                    </FormControl>

                    <Button
                        disabled={loading || exportLoading}
                        color="primary"
                        style={{ marginRight: theme.spacing.unit }}
                        onClick={() => handleDownload(type)}
                    >
                        <Icon>get_app</Icon>
                    </Button>
                </>
            )
        );
    };

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <div style={{ padding: theme.spacing.unit * 2, paddingBottom: theme.spacing.unit }}>
                        <Paper
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                padding: theme.spacing.unit * 2,
                                height: '100%',
                                flexWrap: 'wrap'
                            }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <DateRangePicker
                                    timezone={timezone}
                                    startDate={startDate}
                                    endDate={endDate}
                                    window={dateWindow}
                                    disabled={loading || exportLoading}
                                    handlePrevious={handleGoBackwards}
                                    handleNext={handleGoForwards}
                                    handleChangeStartDate={handleChangeStartDate}
                                    handleChangeEndDate={handleChangeEndDate}
                                />
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexWrap: 'wrap'
                                }}
                            >
                                {' '}
                                <form onSubmit={handleSubmit} style={{ flex: '0 1 100%' }}>
                                    {renderSearch(
                                        <>
                                            <FormControl>
                                                <Select
                                                    value={dateWindow}
                                                    disabled={loading || exportLoading}
                                                    onChange={handleWindowSelect}
                                                    style={{
                                                        maxWidth: '250px',
                                                        margin: theme.spacing.unit
                                                    }}
                                                >
                                                    <MenuItem value="today">Daily</MenuItem>
                                                    <MenuItem value="thisWeek">Weekly</MenuItem>
                                                    <MenuItem value="thisMonth">Monthly</MenuItem>
                                                    <MenuItem value="thisQuarter">Quarterly</MenuItem>
                                                    <MenuItem value="allTime">All Time</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <FormControl style={inputStyles}>
                                                <InputLabel htmlFor="collection" variant="outlined">
                                                    Column Filters
                                                </InputLabel>
                                                <Select
                                                    variant="outlined"
                                                    multiple
                                                    value={filteredHeaders}
                                                    onChange={onChangeFilterHeader}
                                                    style={{ width: 250 }}
                                                    input={<OutlinedInput labelWidth={140} />}
                                                >
                                                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                        <Button onClick={onChangeAllFilterHeaders(true)}>All</Button>
                                                        <Button onClick={onChangeAllFilterHeaders(false)}>None</Button>
                                                    </div>
                                                    {headers.map(({ name, locKey }) => (
                                                        <MenuItem key={name} value={name}>
                                                            {!_.isNil(locKey) ? loc(locKey, lang) : _.startCase(name)}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>

                                            <TextField
                                                variant="outlined"
                                                name="search"
                                                value={searchRef || ''}
                                                onChange={handleChangeAll}
                                                placeholder="Search"
                                                style={{
                                                    ...inputStyles,
                                                    width: 250
                                                }}
                                            />

                                            {isAUSRegion() && (
                                                <StateSelectDropDown loading={loading || exportLoading} />
                                            )}

                                            <Button
                                                color="primary"
                                                disabled={loading || exportLoading}
                                                variant="contained"
                                                data-cy="dashboard-search-button"
                                                type="submit"
                                                onClick={handleSubmit}
                                            >
                                                <Icon>search</Icon>
                                            </Button>
                                        </>
                                    )}

                                    {collapseSearch && (
                                        <div style={{ textAlign: 'right' }}>
                                            <IconButton onClick={handleFiltersOpen()}>
                                                <Icon>filter_list</Icon>
                                            </IconButton>
                                        </div>
                                    )}
                                </form>
                            </div>
                        </Paper>
                    </div>
                </Grid>
            </Grid>
            <DashBoardTable
                title="Promo Usage"
                loading={loading || exportLoading}
                headers={headers}
                filteredHeaders={filteredHeaders}
                length={totalDataEntries}
                page={page}
                rowsPerPage={rowsPerPage}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                refresh={getData}
                customAction={customActions('normal')}
            >
                {filteredData &&
                    filteredData.map(promo => {
                        return renderRow(promo, filteredHeaders);
                    })}
            </DashBoardTable>

            <DashBoardTable
                title="Referrals"
                loading={loadingRef || exportLoading}
                headers={headers}
                filteredHeaders={filteredHeadersRef}
                length={totalDataEntriesRef}
                page={pageRef}
                rowsPerPage={rowsPerPageRef}
                handleChangePage={handleChangePageRef}
                handleChangeRowsPerPage={handleChangeRowsPerPageRef}
                refresh={getDataRef}
                customAction={customActions('referral')}
            >
                {filteredDataRef &&
                    filteredDataRef.map(promo => {
                        return renderRow(promo, filteredHeadersRef);
                    })}
            </DashBoardTable>
        </>
    );
}

export default withTheme()(PromoUsage);
