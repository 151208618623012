import React, { useContext } from 'react';
import _ from 'lodash';

import { withTheme, Button, CircularProgress } from '@material-ui/core';

import CharitySelect from 'components/CharitySelect/CharitySelect';
import FakeLink from 'components/FakeLink/FakeLink';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from '../../../localizations/localizationHandler';

import BottleDriveForm from 'components/BottleDriveForm';
import useBottleDriveForm from 'components/BottleDriveForm/hooks/useBottleDriveForm';

function BottleDriveInformationForm(props) {
    const {
        formControlStyle,
        textFieldStyle,
        allCharities,
        collectors,
        beverageCommodity,
        handleBackToSelection,
        theme
    } = props;

    const { lang } = useContext(LocalizationContext);

    const {
        formik: bottleDriveFormik,
        handleSetDonation: bottleDriveHandleSetDonation,
        donate: bottleDriveFormDonate,
        loading: bottleDriveLoading,
        displayConfirmation: bottleDriveDisplayConfirmation
    } = useBottleDriveForm({});

    return (
        <>
            <BottleDriveForm
                charities={allCharities}
                formControlStyle={formControlStyle}
                textFieldStyle={textFieldStyle}
                collectors={collectors}
                beverageCommodity={beverageCommodity}
                onClose={handleBackToSelection}
                formik={bottleDriveFormik}
                handleSetDonation={bottleDriveHandleSetDonation}
                donate={bottleDriveFormDonate}
                loading={bottleDriveLoading}
                displayConfirmation={bottleDriveDisplayConfirmation}
            />
            {!bottleDriveDisplayConfirmation && (
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: theme.spacing.unit * 2 }}>
                    <Button
                        data-cy="bottle-drive-submit-btn"
                        type="submit"
                        variant="contained"
                        color="primary"
                        style={{ height: 40 }}
                        onClick={bottleDriveFormik.handleSubmit}
                        disabled={bottleDriveLoading}
                    >
                        <span>{loc('submit', lang)}</span>
                        {bottleDriveLoading && (
                            <CircularProgress
                                size={22}
                                thickness={4.8}
                                style={{
                                    marginLeft: theme.spacing.unit,
                                    color: 'white',
                                    verticalAlign: 'bottom'
                                }}
                            />
                        )}
                    </Button>
                </div>
            )}
        </>
    );
}

export default withTheme()(BottleDriveInformationForm);
