import React, { useState } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';

import { withTheme } from '@material-ui/core/styles';

import CRUDTable from './CRUDTable';

import {
    Avatar,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Icon,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Paper,
    Tooltip,
    Typography,
    colors
} from '@material-ui/core';
import ServicesForm from './Forms/ServicesForm';
import { Icon as MDIcon } from '@mdi/react';
import * as allIcons from '@mdi/js';
import { useEffect } from 'react';
import { useContext } from 'react';
import ConfirmDialogContext from 'components/Dialogs/Confirm/ConfirmDialogContext';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import {
    BOTTLE_DRIVE,
    COMMERCIAL,
    CONDO,
    DEPOT_SEARCH,
    DROPANDGO,
    EXPRESS,
    EXTERNAL_LINK,
    INSTRUCTIONS,
    LABELS,
    QR_SCAN,
    RESIDENTIAL,
    WALK_IN
} from '../../constants';
import SubServicesList from 'components/Lists/SubServicesList';
import { useFormik } from 'formik';
import { useMemo } from 'react';
import { loc } from 'localizations/localizationHandler';

function ServiceTable(props) {
    const { operator, http, onSnackbar, theme } = props;
    const warnAction = useContext(ConfirmDialogContext);

    const [selectedDocument, setSelectedDocument] = useState(null);
    const [editDialogOpen, setEditDialogOpen] = useState(false);

    const [activeOrderBy, setActiveOrderBy] = useState(null);
    const [activeOrder, setActiveOrder] = useState('asc');

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    const [accountTypesDialogOpen, setAccountTypesDialogOpen] = useState(false);
    const [reorderDialogOpen, setReorderDialogOpen] = useState(false);
    const [currentServicesList, setCurrentServicesList] = useState([]);

    useEffect(() => {
        handleReloadData();
    }, []);

    const handleReloadData = async () => {
        let resGetData = await http.getJSON('/services');
        if (resGetData.ok) {
            const services = resGetData.data.services.map((service, idx) => {
                if (!_.isNil(service.mdiIcon)) {
                    if (service.mdiIcon.startsWith('mdi')) {
                        service.mdiIcon = _.kebabCase(service.mdiIcon.substring(3));
                    } else {
                        service.mdiIcon = _.kebabCase(service.mdiIcon);
                    }
                }
                return { ...service, idx };
            });
            setData(services);
        }
    };
    const convertToExportName = icon => {
        if (_.isNil(icon)) {
            return icon;
        }
        if (icon.startsWith('mdi')) {
            return _.camelCase(`${icon}`);
        } else {
            return _.camelCase(`mdi-${icon}`);
        }
    };

    const handleCreate = async newValues => {
        let services = _.cloneDeep(data);
        services.push({ ...newValues });
        services = services.map(service => ({
            ...service,
            mdiIcon: convertToExportName(service.mdiIcon)
        }));
        await handleUpdateServices(services.map(service => _.omit(service, 'idx')));
    };

    const handleEdit = async values => {
        let services = _.cloneDeep(data);
        const newService = { ...services[values.idx], ...values, mdiIcon: convertToExportName(values.mdiIcon) };
        services[values.idx] = newService;
        services = services.map(service => ({
            ...service,
            mdiIcon: convertToExportName(service.mdiIcon)
        }));
        await handleUpdateServices(services.map(service => _.omit(service, 'idx')));
    };

    const handleUpdateServices = async services => {
        const editingAccountTypes = accountTypesDialogOpen;
        const editingOrder = reorderDialogOpen;
        setLoading(true);
        let res = await http.post('/system/updateServices', { services }, true);
        if (res.ok) {
            await handleReloadData();
            setAccountTypesDialogOpen(false);
            setEditDialogOpen(false);
            handleReorderDialog(false);
            if (editingAccountTypes) {
                onSnackbar('Account types have been updated');
            } else if (editingOrder) {
                onSnackbar('Service order has been updated');
            }
        } else {
            onSnackbar(res.errorMessage, 'error');
        }
        setLoading(false);
    };

    const columns = [
        { key: 'text.en.title', header: 'Title' },
        { key: 'text.en.secondaryTitle', header: 'Text' },
        {
            key: 'pickupType',
            header: 'Work Flow',
            formatValue: (value, document) => {
                let displayValue = 'Pickup';
                switch (value) {
                    case RESIDENTIAL:
                    case COMMERCIAL:
                    case CONDO:
                        displayValue = 'Pickup';
                        break;
                    case DROPANDGO:
                    case EXPRESS:
                    case WALK_IN:
                        displayValue = `Technology (${
                            _.isEmpty(document.technologies) || _.isNil(document.technologies)
                                ? 'No filter'
                                : document.technologies.join(', ')
                        })`;
                        break;
                    case BOTTLE_DRIVE:
                        displayValue = 'Booking Form';
                        break;
                    case INSTRUCTIONS:
                        displayValue = value + ` (${loc(document.instructionType, 'en')})`;
                        break;
                    case EXTERNAL_LINK:
                    case DEPOT_SEARCH:
                    case QR_SCAN:
                        displayValue = value;
                        break;
                    default:
                        displayValue = 'Pickup';
                }
                return displayValue;
            }
        },
        {
            key: 'mdiIcon',
            header: 'Icon',
            formatValue: value => (
                <MDIcon
                    path={_.get(allIcons, convertToExportName(value))}
                    size={1}
                    color={theme.palette.primary.main}
                />
            )
        },
        // {
        //     key: 'subServices',
        //     header: 'Sub-Services',

        //     formatValue: values =>
        //         _.isEmpty(values) ? 'None' : values.map(subService => _.get(subService, 'text.en.title')).join(', ')
        // },
        {
            key: 'hidden',
            header: 'Hidden',
            isBoolean: true
        },
        // {
        //     key: 'isMobilePickupService',
        //     header: 'Has Sub-Services',
        //     isBoolean: true
        // },
        { key: 'disablePromos', header: 'Disable Promos', isBoolean: true },
        { key: 'hideHelpButton', header: 'Hide Help', isBoolean: true },
        { key: 'charityLocked', header: 'Charity Locked', isBoolean: true }
    ];

    const editForm = (
        <ServicesForm
            {...selectedDocument}
            idx={_.indexOf(data, selectedDocument)}
            onSubmit={_.isNil(selectedDocument) ? handleCreate : handleEdit}
            loading={loading}
            editing={!_.isNil(selectedDocument)}
            open={editDialogOpen}
            onClose={() => setEditDialogOpen(false)}
        />
    );

    const serviceForLocationTypes = useMemo(
        () => _.find(data, service => service.isMobilePickupService && !service.disabled),
        [data]
    );
    const serviceForLocationTypesIndex = useMemo(() => _.indexOf(data, serviceForLocationTypes), [
        serviceForLocationTypes
    ]);

    const serviceForLocationTypesformik = useFormik({
        enableReinitialize: true,
        initialValues: {
            idx: serviceForLocationTypesIndex,
            ...serviceForLocationTypes
        },
        onSubmit: handleEdit
    });

    const handleReorderDialog = open => {
        setReorderDialogOpen(open);
        if (open) {
            setCurrentServicesList(data);
        } else {
            setCurrentServicesList([]);
        }
    };

    const handleReorderSubmit = async () => {
        await handleUpdateServices(currentServicesList.map(service => _.omit(service, 'idx')));
    };

    const handleDragEnd = ({ destination, source }) => {
        // dropped outside the list
        if (!destination) return;
        let newServicesList = reorder(currentServicesList, source.index, destination.index);
        setCurrentServicesList(newServicesList);
    };
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const headerContent = (
        <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
            {!_.isNil(serviceForLocationTypes) && (
                <Button
                    style={{ margin: theme.spacing.unit }}
                    variant="outlined"
                    onClick={() => setAccountTypesDialogOpen(true)}
                    color="primary"
                    data-cy="service-table-account-types-button"
                    disabled={loading}
                >
                    <Icon style={{ marginRight: theme.spacing.unit }}>person_pin_circle</Icon>
                    View/Edit Account Types
                </Button>
            )}
            <Button
                style={{ margin: theme.spacing.unit }}
                variant="outlined"
                onClick={() => handleReorderDialog(true)}
                color="primary"
                data-cy="service-table-re-order-button"
                disabled={loading}
            >
                <Icon style={{ marginRight: theme.spacing.unit }}>low_priority</Icon>
                Re-order Services
            </Button>
        </div>
    );
    return (
        <>
            <CRUDTable
                headerContent={headerContent}
                operator={operator}
                columns={columns}
                data={data}
                editForm={editForm}
                documentIsDisabled={service =>
                    _.get(service, 'disabled', false) ||
                    _.get(service, 'hidden', false) ||
                    _.get(service, 'pickupType', '') === LABELS
                }
                defaultRowsPerPage={5}
                startDateFilterStats={moment()}
                setStartDateFilterStats={() => {}}
                endDateFilterStats={moment()}
                setEndDateFilterStats={() => {}}
                dateFilterStatsErrorMessage={''}
                selectedDocument={selectedDocument}
                setSelectedDocument={setSelectedDocument}
                editDialogOpen={editDialogOpen}
                setEditDialogOpen={setEditDialogOpen}
                activeOrderBy={activeOrderBy}
                setActiveOrderBy={setActiveOrderBy}
                activeOrder={activeOrder}
                setActiveOrder={setActiveOrder}
                enabledHeaderText={'Start Screen Services'}
                disabledHeaderText={'Disabled or Hidden Services'}
                loading={loading}
                hideDateFilters
            />
            <Dialog
                onClose={() => setAccountTypesDialogOpen(false)}
                open={accountTypesDialogOpen}
                fullScreen={false}
                fullWidth
            >
                <DialogTitle>Edit Account Types</DialogTitle>
                <DialogContent>
                    <SubServicesList
                        allIcons={allIcons}
                        service={serviceForLocationTypesformik.values}
                        serviceFormik={serviceForLocationTypesformik}
                        subServices={serviceForLocationTypesformik.values.subServices}
                        onChange={values => serviceForLocationTypesformik.setFieldValue('subServices', values)}
                        pickupTypes={[RESIDENTIAL, CONDO, COMMERCIAL]}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setAccountTypesDialogOpen(false)}>Cancel</Button>
                    <Button onClick={serviceForLocationTypesformik.handleSubmit}>Submit</Button>
                </DialogActions>
            </Dialog>

            <Dialog onClose={() => setReorderDialogOpen(false)} open={reorderDialogOpen} fullScreen={false} fullWidth>
                <DialogTitle>Reorder Start Screen Services</DialogTitle>
                <DialogContent>
                    <List style={{ marginTop: theme.spacing.unit }}>
                        <DragDropContext onDragEnd={handleDragEnd}>
                            <Droppable droppableId="serviceOrderList">
                                {(provided, snapshot) => (
                                    <div ref={provided.innerRef} {...provided.droppableProps}>
                                        <List>
                                            {currentServicesList.map((service, i) => {
                                                const selectionTitle = _.get(service, `text.en.title`, 'title');
                                                const selectionSecondaryTitle = _.get(
                                                    service,
                                                    `text.en.secondaryTitle`,
                                                    'secondaryTitle'
                                                );
                                                return (
                                                    <Draggable
                                                        draggableId={service.text.en.title}
                                                        index={i}
                                                        key={service.text.en.title}
                                                    >
                                                        {(provided, snapshot) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                style={provided.draggableProps.style}
                                                            >
                                                                <ListItem
                                                                    disabled={service.disabled}
                                                                    style={{
                                                                        backgroundColor: theme.palette.background.paper,
                                                                        marginTop: i > 0 ? theme.spacing.unit : 0,
                                                                        border: `1px solid ${theme.palette.grey[400]}`,
                                                                        borderRadius: theme.shape.borderRadius,
                                                                        whiteSpace: 'normal'
                                                                    }}
                                                                >
                                                                    <MDIcon
                                                                        path={allIcons.mdiDrag}
                                                                        size={1}
                                                                        color={theme.palette.text.secondary}
                                                                    />
                                                                    <ListItemAvatar>
                                                                        <Avatar
                                                                            style={{
                                                                                backgroundColor: service.hidden
                                                                                    ? colors.grey[500]
                                                                                    : theme.palette.primary.main
                                                                            }}
                                                                        >
                                                                            <MDIcon
                                                                                path={_.get(
                                                                                    allIcons,
                                                                                    service.hidden
                                                                                        ? 'mdiEyeOffOutline'
                                                                                        : service.mdiIcon
                                                                                )}
                                                                                size={1}
                                                                                color={'white'}
                                                                            />
                                                                            {/* <Icon>{selection.icon}</Icon> */}
                                                                        </Avatar>
                                                                    </ListItemAvatar>
                                                                    <ListItemText
                                                                        primary={
                                                                            selectionTitle +
                                                                            (service.hidden ? ' (Hidden)' : '')
                                                                        }
                                                                        secondary={selectionSecondaryTitle}
                                                                        style={{
                                                                            paddingRight: theme.spacing.unit * 8
                                                                        }}
                                                                    />
                                                                    <ListItemSecondaryAction>
                                                                        <div>
                                                                            {!service.hideHelpButton && (
                                                                                <Icon
                                                                                    style={{
                                                                                        margin: 10,
                                                                                        color:
                                                                                            theme.palette.text.secondary
                                                                                    }}
                                                                                >
                                                                                    help
                                                                                </Icon>
                                                                            )}
                                                                            <Icon
                                                                                style={{
                                                                                    margin: 10,
                                                                                    color: theme.palette.text.secondary
                                                                                }}
                                                                            >
                                                                                arrow_forward
                                                                            </Icon>
                                                                        </div>
                                                                    </ListItemSecondaryAction>
                                                                </ListItem>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                );
                                            })}
                                        </List>
                                        <div style={{ width: 0 }}>{provided.placeholder}</div>
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setReorderDialogOpen(false)}>Cancel</Button>
                    <Button onClick={handleReorderSubmit}>Submit</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default withTheme()(ServiceTable);
