import React, { useMemo, useState } from 'react';
import _ from 'lodash';
import { withTheme } from '@material-ui/core/styles';
import {
    colors,
    Icon,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from '@material-ui/core';
import moment from 'moment-timezone';
import { mdiCodeJson } from '@mdi/js';
import MDIcon from '@mdi/react';
import dot from 'dot-object';

import Badge from 'components/Badge/Badge';

import ReactJson from 'react-json-view';

function LogTable({ data, theme, setSelectedLog }) {
    const rows = useMemo(() => generateTableData(data), [data]);
    const handleClick = (e, log) => {
        e.stopPropagation();
        setSelectedLog(log);
    };

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell align="left" />
                    <TableCell>Collection</TableCell>
                    <TableCell>Date/Time</TableCell>
                    <TableCell>Action</TableCell>
                    <TableCell>Performed By</TableCell>
                    <TableCell>Details</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {rows.map(row =>
                    row.action === 'l' ? (
                        <TableRow key={row._id}>
                            <TableCell>
                                <IconButton onClick={e => handleClick(e, row.log)} onFocus={e => e.stopPropagation()}>
                                    <MDIcon path={mdiCodeJson} size={1} color="rgba(0, 0, 0, 0.54)" />
                                </IconButton>
                            </TableCell>
                            <TableCell>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Icon style={{ color: theme.palette.primary.main }}>{row.icon}</Icon>
                                    <span style={{ marginLeft: theme.spacing.unit }}>{colls[row.collection]}</span>
                                </div>
                            </TableCell>
                            <TableCell>
                                {moment(row.date)
                                    .tz(process.env.REACT_APP_REGION_TIMEZONE)
                                    .format('MMM Do h:mm A')}
                            </TableCell>
                            <TableCell>{operations[row.action]}</TableCell>
                            <TableCell align="left">{row.performedBy}</TableCell>
                            <TableCell align="left">{`Logged in at ${row.ip}`}</TableCell>
                        </TableRow>
                    ) : (
                        <ExpandableTableRow key={row._id} expandComponent={<DetailedLogData row={row} theme={theme} />}>
                            <TableCell>
                                <IconButton onClick={e => handleClick(e, row.log)} onFocus={e => e.stopPropagation()}>
                                    <MDIcon path={mdiCodeJson} size={1} color="rgba(0, 0, 0, 0.54)" />
                                </IconButton>
                            </TableCell>
                            <TableCell>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Icon style={{ color: theme.palette.primary.main }}>{row.icon}</Icon>
                                    <span style={{ marginLeft: theme.spacing.unit }}>{colls[row.collection]}</span>
                                </div>
                            </TableCell>
                            <TableCell>
                                {moment(row.date)
                                    .tz(process.env.REACT_APP_REGION_TIMEZONE)
                                    .format('MMM Do h:mm A')}
                            </TableCell>
                            <TableCell>{operations[row.action]}</TableCell>
                            <TableCell>{row.performedBy}</TableCell>
                        </ExpandableTableRow>
                    )
                )}
            </TableBody>
        </Table>
    );
}

export default withTheme()(LogTable);

const generateTableData = (data = []) => {
    let tableData = [];
    for (let log of data) {
        let row = {};
        row._id = log._id;
        row.log = log;
        row.collection = log.c;
        row.icon = icons[log.c];
        row.date = log.createdAt;
        row.action = log.o;
        row.ip = log.ip;
        row.performedBy = !_.isNil(log.p)
            ? log.p
            : `${_.get(log, 'source.name.first', 'Native DB')} ${_.get(log, 'source.name.last', 'Event')}`;
        if (log.o === 'u' && !_.isNil(_.get(log, 'u.updatedFields'))) {
            const dotData = dot.dot(log.u.updatedFields);
            let beforeObj = {};
            let afterObj = {};
            for (let path in dotData) {
                const previousFieldValue = _.get(log.prev, path, null);
                const currentFieldValue = _.get(log.u.updatedFields, path, null);
                if (!_.isEqual(previousFieldValue, currentFieldValue)) {
                    _.set(beforeObj, path, previousFieldValue);
                    _.set(afterObj, path, currentFieldValue);
                }
            }
            row.beforeObj = dot.object(beforeObj);
            row.afterObj = dot.object(afterObj);
        }
        tableData.push(row);
    }

    return tableData;
};

const ExpandableTableRow = ({ children, expandComponent, ...otherProps }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    return (
        <>
            <TableRow {...otherProps}>
                {children}
                <TableCell padding="checkbox">
                    <IconButton onClick={() => setIsExpanded(!isExpanded)}>
                        {isExpanded ? <Icon>expand_less</Icon> : <Icon>expand_more</Icon>}
                    </IconButton>
                </TableCell>
            </TableRow>
            {/* <Collapse in={isExpanded}> */}
            {isExpanded && (
                <TableRow>
                    <TableCell colSpan="6">{expandComponent}</TableCell>
                </TableRow>
            )}
            {/* </Collapse> */}
        </>
    );
};

const operations = {
    i: <span style={{ color: 'green' }}>Add</span>,
    d: <span style={{ color: 'red' }}>Delete</span>,
    u: <span style={{ color: 'orange' }}>Update</span>,
    l: <span style={{ color: 'purple' }}>Sign In</span>
};

const icons = {
    batches: 'source',
    bulks: 'ballot',
    charities: 'volunteer_activism',
    collectors: 'inventory',
    configs: 'settings_applications',
    configuration: 'settings',
    defaultcharitytasks: 'task',
    pickups: 'local_shipping',
    promos: 'discount',
    rates: 'price_change',
    ratingPlatforms: 'app_registration',
    redemptions: 'monetization_on',
    referrals: 'attach_money',
    regions: 'place',
    trips: 'route',
    users: 'person',
    zones: 'map'
};

const colls = {
    batches: <Badge>batch</Badge>,
    bulks: <Badge style={{ backgroundColor: colors.deepPurple[500], color: '#fff' }}>bulk</Badge>,
    charities: <Badge>charity</Badge>,
    collectors: <Badge>collector</Badge>,
    configs: <Badge>config</Badge>,
    configuration: <Badge>configuration</Badge>,
    defaultcharitytasks: <Badge>defaultcharitytasks</Badge>,
    entries: <Badge>entries</Badge>,
    locations: <Badge>location</Badge>,
    pickups: <Badge style={{ backgroundColor: colors.blue[500], color: '#fff' }}>pickup</Badge>,
    promos: <Badge>promo</Badge>,
    rates: <Badge>rates</Badge>,
    ratingPlatforms: <Badge>ratingPlatform</Badge>,
    redemptions: <Badge style={{ backgroundColor: colors.green[600], color: '#fff' }}>redemption</Badge>,
    referrals: <Badge>referral</Badge>,
    regions: <Badge>region</Badge>,
    trips: <Badge>trip</Badge>,
    users: <Badge style={{ backgroundColor: colors.red[500], color: '#fff' }}>user</Badge>,
    zones: <Badge>zone</Badge>
};

const DetailedLogData = ({ row, theme }) => {
    const operationType = row.action;
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
                width: '100%'
            }}
        >
            {operationType === 'u' && (
                <>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <Typography>Previous Value</Typography>
                        <ReactJson
                            style={{
                                padding: theme.spacing.unit,
                                maxWidth: '350px',
                                minWidth: '350px',
                                overflowX: 'auto'
                            }}
                            theme="bright:inverted"
                            collapsed={2}
                            collapseStringsAfterLength={30}
                            enableClipboard={false}
                            indentWidth={2}
                            src={row.beforeObj || {}}
                        />
                    </div>
                    <Icon
                        fontSize="large"
                        color="primary"
                        style={{ marginRight: theme.spacing.unit * 2, alignSelf: 'center' }}
                    >
                        trending_flat
                    </Icon>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <Typography>Current Value</Typography>
                        <ReactJson
                            style={{
                                padding: theme.spacing.unit,
                                maxWidth: '350px',
                                minWidth: '350px',
                                overflowX: 'auto'
                            }}
                            theme="bright:inverted"
                            collapsed={false}
                            collapseStringsAfterLength={30}
                            enableClipboard={false}
                            indentWidth={2}
                            src={row.afterObj || {}}
                        />
                    </div>
                </>
            )}
            {operationType === 'i' && (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <Typography>Document Created</Typography>
                    <ReactJson
                        style={{
                            padding: theme.spacing.unit,
                            maxWidth: '350px',
                            overflowX: 'auto'
                        }}
                        theme="bright:inverted"
                        collapsed={true}
                        collapseStringsAfterLength={30}
                        enableClipboard={false}
                        indentWidth={2}
                        src={_.get(row, 'log.d') || {}}
                    />
                </div>
            )}
            {operationType === 'd' && (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <Typography>Document Deleted</Typography>
                    <ReactJson
                        style={{
                            padding: theme.spacing.unit,
                            maxWidth: '350px',
                            overflowX: 'auto'
                        }}
                        theme="bright:inverted"
                        collapsed={true}
                        collapseStringsAfterLength={30}
                        enableClipboard={false}
                        indentWidth={2}
                        src={_.get(row, 'log.prev') || {}}
                    />
                </div>
            )}
        </div>
    );
};
