import React from 'react';

import { withTheme } from '@material-ui/core/styles';
import DropLocationsTable from 'components/CRUDTables/DropLocationsTable';

function DropLocations(props) {
    return <DropLocationsTable {...props} />;
}

export default withTheme()(DropLocations);
