import React from 'react';
import _ from 'lodash';
import { _pickup } from 'std';

import { colors, Typography, withTheme, Tooltip } from '@material-ui/core';

import Badge from 'components/Badge/Badge';

import { Icon as MDIcon } from '@mdi/react';
import * as allIcons from '@mdi/js';

function PickupStatusIndicators({ theme, pickup, numberOfBins = 0, indicatorSize = 1, style, labelStyle }) {
    return (
        <span style={{ display: 'flex', textAlign: 'center', alignItems: 'center', flexWrap: 'wrap', ...style }}>
            <span
                style={{
                    display: 'flex',
                    borderRadius: 4,
                    borderColor: colors.blue[300],
                    alignItems: 'center',
                    marginRight: theme.spacing.unit / 2
                }}
            >
                <Badge
                    style={{
                        backgroundColor: _pickup.isOutOfSystemStop(pickup)
                            ? _.isNil(colors[_.get(pickup, 'stopIconColor', 'black')])
                                ? _.get(pickup, 'stopIconColor', 'black')
                                : colors[_.get(pickup, 'stopIconColor', 'black')][500]
                            : 'black',
                        color: 'white'
                    }}
                >
                    {_.get(pickup, 'collector.code', '-')} {_.get(pickup, 'customer.collector') ? '(R)' : ''}
                </Badge>{' '}
                <Tooltip title="Priority">
                    <MDIcon
                        path={_.get(allIcons, 'mdiStar')}
                        size={indicatorSize}
                        color={pickup.postponedBySystem > 0 ? 'gold' : 'grey'}
                        data-cy={
                            pickup.postponedBySystem
                                ? 'admin-ui-high-priority-badge-enabled'
                                : 'admin-ui-high-priority-badge-disabled'
                        }
                    />
                </Tooltip>{' '}
                <Tooltip title="System Postpones">
                    <MDIcon path={_.get(allIcons, 'mdiCalendarQuestion')} size={indicatorSize} color="grey" />
                </Tooltip>{' '}
                <Typography style={{ marginRight: 4, ...labelStyle }}>{pickup.postponedBySystem || 0}</Typography>
                <Tooltip title="Postpones/Aborts">
                    <MDIcon path={_.get(allIcons, 'mdiCalendarRemove')} size={indicatorSize} color="grey" />
                </Tooltip>{' '}
                <Typography style={{ marginRight: 4, ...labelStyle }}>{pickup.postponedCounter || 0}</Typography>
                {numberOfBins > 0 && (
                    <Tooltip title="Bins">
                        <MDIcon
                            path={_.get(allIcons, 'mdiDelete')}
                            size={indicatorSize}
                            color="grey"
                            data-cy="admin-ui-bin-indicator"
                        />
                    </Tooltip>
                )}
                {numberOfBins > 0 && (
                    <Typography style={{ marginRight: 4, ...labelStyle }}>{numberOfBins || 0}</Typography>
                )}
            </span>
        </span>
    );
}

export default withTheme()(PickupStatusIndicators);
