import React from 'react';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';

import { getQuickDropName, getWalkInName } from 'utils/misc';

function BulkTypeSelectionDropdown({
    fullWidth,
    helperText,
    disabled,
    typeFilter,
    handleChange,
    labelCustomWidth,
    disablePickupsType,
    showAll = true,
    style = { marginRight: 8 }
}) {
    return (
        <FormControl fullWidth={fullWidth} style={style}>
            <InputLabel variant="outlined" htmlFor="commodity-filter">
                {helperText}
            </InputLabel>
            <Select
                data-cy="bulk-type-selection-dropdown"
                value={typeFilter}
                onChange={handleChange}
                disabled={disabled}
                inputProps={{
                    name: 'commodity-filter',
                    id: 'commodity-filter'
                }}
                input={<OutlinedInput labelWidth={labelCustomWidth || 36} name="age" id="outlined-age-simple" />}
                style={{
                    minWidth: 125
                }}
            >
                {showAll && (
                    <MenuItem value="all">
                        <em>All</em>
                    </MenuItem>
                )}
                {!disablePickupsType && (
                    <MenuItem value="pickup">
                        <em>Pickup</em>
                    </MenuItem>
                )}
                <MenuItem value="inhouse">
                    <em>{getQuickDropName('en')}</em>
                </MenuItem>
                <MenuItem value="walk-in" data-cy="bulk-type-selection-dropdown-walk-in">
                    <em>{getWalkInName('en')}</em>
                </MenuItem>
                <MenuItem value="adjustment" data-cy="bulk-type-selection-dropdown-adjustment">
                    <em>Adjustment</em>
                </MenuItem>
            </Select>
        </FormControl>
    );
}

export default BulkTypeSelectionDropdown;
