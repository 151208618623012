import React, { useState, useContext } from 'react';
import _ from 'lodash';

import {
    IconButton,
    Icon,
    Button,
    Tooltip,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography
} from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';

import TaxForm from './Forms/TaxForm';
import TaxGroupForm from './Forms/TaxGroupForm';
import CRUDTable from './CRUDTable';

import useCRUD from './hooks/useCRUD';

import HttpContext from 'utils/contexts/HttpContext';
import ConfirmDialogContext from 'components/Dialogs/Confirm/ConfirmDialogContext';

function TaxesTable(props) {
    const { theme, operator, onSnackbar } = props;

    const http = useContext(HttpContext);
    const warnAction = useContext(ConfirmDialogContext);

    const [applicationInfoOpen, setApplicationInfoOpen] = useState(null);

    const taxEndpoints = {
        getEndPoint: '/taxes/getAllTaxes', // url to get all documents
        getEditEndPoint: _id => `/taxes/${_id}/updateTax`, // url to edit documents NOTE: this should be a function
        createEndPoint: `/taxes/createTax` // url to add new documents
    };

    const groupEndpoints = {
        getEndPoint: '/taxGroups/getAllTaxGroups', // url to get all documents
        getEditEndPoint: _id => `/taxGroups/${_id}/updateTaxGroup`, // url to edit documents NOTE: this should be a function
        createEndPoint: `/taxGroups/createTaxGroup` // url to add new documents
    };

    const [selectedTaxDocument, setSelectedTaxDocument] = useState(null);
    const [taxEditDialogOpen, setTaxEditDialogOpen] = useState(false);
    const [activeTaxOrderBy, setActiveTaxOrderBy] = useState(null);
    const [activeTaxOrder, setActiveTaxOrder] = useState('asc');
    const {
        data: taxData,
        startDateFilterStats: taxStartDateFilterStats,
        endDateFilterStats: taxEndDateFilterStats,
        loading: taxLoading,
        setLoading: setTaxLoading,
        setStartDateFilterStats: setTaxStartDateFilterStats,
        setEndDateFilterStats: setTaxEndDateFilterStats,
        dateFilterStatsErrorMessage: taxDateFilterStatsErrorMessage,
        defaults: taxDefaults,
        handleCreate: handleTaxCreate,
        handleEdit: handleTaxEdit,
        handleReloadData: reloadTaxData
    } = useCRUD({
        endpoints: taxEndpoints,
        setEditDialogOpen: setTaxEditDialogOpen,
        setActiveOrderBy: setActiveTaxOrderBy,
        setActiveOrder: setActiveTaxOrder,
        http,
        onSnackbar
    });

    const [selectedGroupDocument, setSelectedGroupDocument] = useState(null);
    const [groupEditDialogOpen, setGroupEditDialogOpen] = useState(false);
    const [activeGroupOrderBy, setActiveGroupOrderBy] = useState(null);
    const [activeGroupOrder, setactiveGroupOrder] = useState('asc');
    const {
        data: groupData,
        startDateFilterStats: groupStartDateFilterStats,
        endDateFilterStats: groupEndDateFilterStats,
        loading: groupLoading,
        setStartDateFilterStats: setGroupStartDateFilterStats,
        setEndDateFilterStats: setGroupEndDateFilterStats,
        dateFilterStatsErrorMessage: groupDateFilterStatsErrorMessage,
        defaults: groupDefaults,
        handleCreate: handleGroupCreate,
        handleEdit: handleGroupEdit
    } = useCRUD({
        endpoints: groupEndpoints,
        setEditDialogOpen: setGroupEditDialogOpen,
        setActiveOrderBy: setActiveGroupOrderBy,
        setActiveOrder: setactiveGroupOrder,
        http,
        onSnackbar
    });

    const groupColumns = [
        { key: 'name', header: 'Group Name' },
        { key: 'applicationMethod', header: 'Application' },
        { key: 'taxes', header: 'Taxes Applied', formatValue: taxes => taxes.map(tax => tax.name).join(' - ') }
    ];

    const taxColumns = [
        { key: 'name', header: 'Description' },
        { key: 'configuration.type', header: 'Type' },
        { key: 'configuration.value', header: 'Amount (%)', formatValue: value => _.round(value * 100, 5) }
    ];

    const taxEditForm = (
        <TaxForm
            defaults={taxDefaults}
            tax={selectedTaxDocument}
            open={taxEditDialogOpen}
            onSnackbar={onSnackbar}
            onClose={() => setTaxEditDialogOpen(false)}
            onSubmit={_.isNil(selectedTaxDocument) ? handleTaxCreate : handleTaxEdit}
        />
    );

    const groupEditForm = (
        <TaxGroupForm
            defaults={groupDefaults}
            taxes={taxData}
            taxGroup={selectedGroupDocument}
            open={groupEditDialogOpen}
            onSnackbar={onSnackbar}
            onClose={() => setGroupEditDialogOpen(false)}
            onSubmit={_.isNil(selectedGroupDocument) ? handleGroupCreate : handleGroupEdit}
            setApplicationInfoOpen={setApplicationInfoOpen}
        />
    );

    const crudFunctions = (
        <>
            <Button
                style={{ margin: theme.spacing.unit }}
                variant="outlined"
                onClick={() => {
                    setSelectedGroupDocument(null);
                    setGroupEditDialogOpen(true);
                }}
                color="primary"
                data-cy="crud-table-add-new"
            >
                <Icon>add_circle_outline</Icon>
                Add Group
            </Button>
            <Button
                style={{ margin: theme.spacing.unit }}
                variant="outlined"
                onClick={() => {
                    setSelectedTaxDocument(null);
                    setTaxEditDialogOpen(true);
                }}
                color="primary"
                data-cy="crud-table-add-new"
            >
                <Icon>add_circle_outline</Icon>
                Add Tax
            </Button>
        </>
    );

    const onDeleteTax = async tax => {
        const taxId = _.get(tax, '_id');
        if (!taxId) return;

        setTaxLoading(true);
        const res = await http.getJSON(`/taxes/${taxId}/deleteTax`);

        if (res.ok) {
            await reloadTaxData();
        } else {
            onSnackbar(res.errorMessage, 'error');
        }

        setTaxLoading(false);
    };

    const taxDeleteAction = document => (
        <Tooltip title="Delete tax type" disableFocusListener disableTouchListener>
            <IconButton
                onClick={() =>
                    warnAction(
                        async () => await onDeleteTax(document),
                        'Deleting this tax will not remove it from existing tax groups'
                    )
                }
            >
                <Icon>delete</Icon>
            </IconButton>
        </Tooltip>
    );

    return (
        <>
            <CRUDTable
                operator={operator}
                columns={groupColumns}
                data={groupData}
                editForm={groupEditForm}
                documentIsDisabled={group => !group.enabled}
                defaultRowsPerPage={5}
                startDateFilterStats={groupStartDateFilterStats}
                setStartDateFilterStats={setGroupStartDateFilterStats}
                endDateFilterStats={groupEndDateFilterStats}
                setEndDateFilterStats={setGroupEndDateFilterStats}
                dateFilterStatsErrorMessage={groupDateFilterStatsErrorMessage}
                selectedDocument={selectedGroupDocument}
                setSelectedDocument={setSelectedGroupDocument}
                editDialogOpen={groupEditDialogOpen}
                setEditDialogOpen={setGroupEditDialogOpen}
                activeOrderBy={activeGroupOrderBy}
                setActiveOrderBy={setActiveGroupOrderBy}
                activeOrder={activeGroupOrder}
                setActiveOrder={setactiveGroupOrder}
                enabledHeaderText={'Tax Groups'}
                disabledHeaderText={'Disabled Tax Groups'}
                loading={groupLoading}
                headerRightContent={crudFunctions}
                hideDateFilters
                hideAddButton
            />
            <CRUDTable
                operator={operator}
                columns={taxColumns}
                data={taxData}
                editForm={taxEditForm}
                documentIsDisabled={tax => false}
                defaultRowsPerPage={5}
                startDateFilterStats={taxStartDateFilterStats}
                setStartDateFilterStats={setTaxStartDateFilterStats}
                endDateFilterStats={taxEndDateFilterStats}
                setEndDateFilterStats={setTaxEndDateFilterStats}
                dateFilterStatsErrorMessage={taxDateFilterStatsErrorMessage}
                selectedDocument={selectedTaxDocument}
                setSelectedDocument={setSelectedTaxDocument}
                editDialogOpen={taxEditDialogOpen}
                setEditDialogOpen={setTaxEditDialogOpen}
                activeOrderBy={activeTaxOrderBy}
                setActiveOrderBy={setActiveTaxOrderBy}
                activeOrder={activeTaxOrder}
                setActiveOrder={setActiveTaxOrder}
                enabledHeaderText={'Taxes'}
                disabledHeaderText={'Disabled Taxes'}
                loading={taxLoading}
                hideHeaderBar
                renderExtraActions={taxDeleteAction}
            />
            <Dialog open={applicationInfoOpen}>
                <DialogTitle>Application Method</DialogTitle>
                <DialogContent>
                    <Typography>
                        The application method defines how taxes will be calculated. "Cumulative" will charge each tax
                        amount according to the initial taxable amount. "Consecutive" will charge taxes in the order
                        defined on the group, and the taxable amount will be reduced by the taxed amount for every tax
                        (inclusive taxes only).
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setApplicationInfoOpen(false)}>close</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default withTheme()(TaxesTable);
