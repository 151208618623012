import React, { useContext, useMemo } from 'react';
import _ from 'lodash';

import { withTheme } from '@material-ui/core/styles';
import DialogTitlePrimary from 'components/MaterialUIExtensions/DialogTitlePrimary';
import {
    DialogContent,
    DialogActions,
    Dialog,
    Button,
    Icon,
    IconButton,
    Typography,
    Snackbar
} from '@material-ui/core';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc, locDate } from '../../../localizations/localizationHandler';
import HttpContext from 'utils/contexts/HttpContext';
import ConfirmDialogContext from 'components/Dialogs/Confirm/ConfirmDialogContext';

import { formatAsAddress } from 'utils/misc';

import PickupInfo from './PickupInfo';

const EditOrCancelDialog = ({
    theme,
    open,
    fullScreen,
    onClose,
    pickups,
    charities,
    customer,
    handleCancelPickup,
    handleAdditionalPickup,
    handleEditPickup,
    handleToggleFlag,
    onReloadCustomer,
    adminView,
    multiplePickups,
    toggleHelpDialog,
    markerLocation
}) => {
    const { lang } = useContext(LocalizationContext);
    const warnAction = useContext(ConfirmDialogContext);
    const http = useContext(HttpContext);

    const separatorStyle = {
        height: 1,
        width: '100%',
        marginTop: theme.spacing.unit * 1,
        marginBottom: theme.spacing.unit * 1
    };

    const pickupsGroupedByLocation = useMemo(() => {
        const pickupsSorted = _.sortBy(pickups, 'date');
        //need index for data-cy tags
        const pickupsWithIndex = pickupsSorted.map((p, i) => {
            return {
                pickup: p,
                index: i
            };
        });
        return _.groupBy(pickupsWithIndex, 'pickup.location.place_id');
    }, [pickups]);

    const allLocations = useMemo(() => {
        if (!_.isNil(markerLocation)) {
            return [markerLocation];
        }
        return _.unionBy(pickups.map(p => p.location), _.get(customer, 'pastLocations', []), 'place_id');
    }, [markerLocation, customer, pickups]);

    let multiplePickupsDisabled = false;

    if (!_.get(multiplePickups, 'enabled', false)) {
        multiplePickupsDisabled = true;
    } else if (_.isNil(_.get(multiplePickups, 'limit', null)) || _.get(multiplePickups, 'limit', 0) <= pickups.length)
        multiplePickupsDisabled = true;

    const handleDeletePastLocation = async location => {
        const res = await http.getJSON(
            `/users/removePastLocation?place_id=${location.place_id}&customer_id=${customer._id}`
        );
        if (res.ok) {
            await onReloadCustomer();
        }
    };
    return (
        <Dialog open={open} onClose={onClose} fullWidth fullScreen={fullScreen}>
            <DialogTitlePrimary closeButtonShown onClose={onClose}>
                {loc('managePickups8', lang)}
            </DialogTitlePrimary>
            <DialogContent
                style={{
                    width: '100%',
                    marginTop: theme.spacing.unit * 3
                }}
            >
                {allLocations &&
                    allLocations.map(location => {
                        const pickupObjs = _.get(pickupsGroupedByLocation, _.get(location, 'place_id'), []);
                        const isResidential = _.get(_.first(pickupObjs), 'pickupType', 'Residential') === 'Residential';

                        return (
                            <div key={_.get(location, 'place_id')} style={{ marginBottom: theme.spacing.unit * 2 }}>
                                <Typography style={{ fontSize: '0.9rem' }}>
                                    <b>
                                        {formatAsAddress(location, false, !isResidential)}{' '}
                                        {_.isEmpty(pickupObjs) && (
                                            <IconButton
                                                style={{ marginLeft: theme.spacing.unit }}
                                                onClick={() =>
                                                    warnAction(
                                                        () => handleDeletePastLocation(location),
                                                        loc('confirmDeletion', lang)
                                                    )
                                                }
                                            >
                                                <Icon>delete</Icon>
                                            </IconButton>
                                        )}
                                    </b>
                                </Typography>
                                {_.isEmpty(pickupObjs) ? (
                                    <Typography variant="caption">{loc('pickupDialog69', lang)}</Typography>
                                ) : (
                                    pickupObjs.map(obj => {
                                        return (
                                            <PickupInfo
                                                customer={customer}
                                                charities={charities}
                                                theme={theme}
                                                handleCancelPickup={handleCancelPickup}
                                                handleEditPickup={handleEditPickup}
                                                handleToggleFlag={handleToggleFlag}
                                                pickup={obj.pickup}
                                                count={obj.index}
                                            />
                                        );
                                    })
                                )}
                            </div>
                        );
                    })}
            </DialogContent>

            <DialogActions
                style={{
                    position: 'relative',
                    justifyContent: 'center',
                    marginBottom: 'max(16px, env(safe-area-inset-bottom, 16px))'
                }}
            >
                <Button
                    variant="contained"
                    data-cy={`edit-or-cancel-dialog-add-button`}
                    color="primary"
                    onClick={handleAdditionalPickup}
                    disabled={multiplePickupsDisabled}
                >
                    {loc('managePickups5', lang)}
                </Button>
                {multiplePickupsDisabled && (
                    <IconButton
                        onClick={toggleHelpDialog('multiLocationPickups')}
                        style={{ position: 'absolute', right: '0' }}
                    >
                        <Icon>help</Icon>
                    </IconButton>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default withTheme()(EditOrCancelDialog);
