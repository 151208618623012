import React, { useState } from 'react';
import _ from 'lodash';

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import {
    List,
    ListItem,
    ListItemText,
    Typography,
    Paper,
    Icon,
    IconButton,
    ListItemSecondaryAction,
    Dialog,
    DialogActions,
    DialogContent,
    Button,
    withMobileDialog,
    withTheme,
    DialogTitle
} from '@material-ui/core';

import { getSelect, getTextInput, getSwitch } from '../helperFunctions';

import { Icon as MDIcon } from '@mdi/react';

import { mdiDrag, mdiTrashCan } from '@mdi/js';

function TaxGroupForm(props) {
    const {
        taxes,
        taxGroup, //NOTE: will be null/undefined if creating a new one
        open,
        onClose,
        onSubmit,
        onSnackbar,
        theme,
        fullScreen,
        setApplicationInfoOpen
    } = props;

    const formik = useFormik({
        initialValues: {
            _id: _.get(taxGroup, '_id'),
            name: _.get(taxGroup, 'name', ''),
            enabled: _.get(taxGroup, 'enabled', true),
            applicationMethod: _.get(taxGroup, 'applicationMethod', 'cumulative'),
            taxes: _.get(taxGroup, 'taxes', [])
        },
        validationSchema: Yup.object({
            name: Yup.string().required(),
            applicationMethod: Yup.string().required()
        }),
        onSubmit
    });

    const addTax = tax => {
        const value = tax.target.value;

        const taxToAdd = _.find(taxes, { name: value });

        const groupTaxes = formik.values.taxes;
        const groupTax = _.find(groupTaxes, { name: value });

        if (groupTax) {
            // group already contains that tax. Add snackbar
            return;
        }

        const updatedGroupTaxes = _.cloneDeep(groupTaxes);
        updatedGroupTaxes.push({
            name: value,
            configuration: {
                type: _.get(taxToAdd, 'configuration.type', 'none'),
                value: _.get(taxToAdd, 'configuration.value', 0)
            }
        });

        formik.setFieldValue('taxes', updatedGroupTaxes);
    };

    const removeTax = tax => {
        const groupTaxes = formik.values.taxes;
        const taxToRemoveIdx = _.findIndex(groupTaxes, { name: tax });

        if (taxToRemoveIdx < 0) {
            // couldnt find tax. Add snackbar
            return;
        }

        const updatedGroupTaxes = _.cloneDeep(groupTaxes); // Problem if list items lose order
        updatedGroupTaxes.splice(taxToRemoveIdx, 1);

        formik.setFieldValue('taxes', updatedGroupTaxes);
    };

    const handleDragEnd = ({ draggableId, destination }) => {
        if (!destination) return;

        const groupTaxes = formik.values.taxes;
        const taxToMove = _.find(groupTaxes, { name: draggableId });

        const filteredGroupTaxes = _.filter(groupTaxes, tax => tax.name !== draggableId);

        const updatedGroupTaxes = _.cloneDeep(filteredGroupTaxes); // Problem if list items lose order
        updatedGroupTaxes.splice(destination.index, 0, taxToMove);

        formik.setFieldValue('taxes', updatedGroupTaxes);
    };

    return (
        <Dialog onClose={onClose} open={open} fullScreen={fullScreen} fullWidth>
            <DialogTitle>Tax Group</DialogTitle>
            <DialogContent>
                {getSwitch(theme, 'enabled', 'Enabled', formik)}
                {getTextInput(theme, 'name', 'Name', formik)}
                {!_.isEmpty(formik.values.taxes) && (
                    <Paper
                        elevation={1}
                        style={{
                            width: '100%',
                            padding: theme.spacing.unit,
                            marginTop: theme.spacing.unit * 2,
                            marginBottom: theme.spacing.unit
                        }}
                    >
                        <DragDropContext onDragEnd={handleDragEnd}>
                            <Droppable droppableId="taxGroupList">
                                {(provided, snapshot) => (
                                    <div ref={provided.innerRef} {...provided.droppableProps}>
                                        <List>
                                            {formik.values.taxes.map((tax, i) => (
                                                <Draggable draggableId={tax.name} index={i} key={tax.name}>
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            style={provided.draggableProps.style}
                                                        >
                                                            <ListItem>
                                                                <ListItemText
                                                                    disableTypography
                                                                    style={{
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        marginRight: theme.spacing.unit * 4
                                                                    }}
                                                                >
                                                                    <MDIcon
                                                                        path={mdiDrag}
                                                                        size={1}
                                                                        color={theme.palette.text.secondary}
                                                                    />
                                                                    <Typography
                                                                        style={{ marginRight: theme.spacing.unit }}
                                                                    >
                                                                        {tax.name} -{' '}
                                                                        {_.round(
                                                                            _.get(tax, 'configuration.value', 0) * 100,
                                                                            5
                                                                        )}
                                                                        % (
                                                                        {_.get(
                                                                            tax,
                                                                            'configuration.type',
                                                                            'Invalid Config'
                                                                        )}
                                                                        )
                                                                    </Typography>
                                                                </ListItemText>
                                                                <ListItemSecondaryAction>
                                                                    <IconButton
                                                                        onClick={() => {
                                                                            removeTax(tax.name);
                                                                            // handleEdit(ratingPlatform);
                                                                        }}
                                                                    >
                                                                        <MDIcon
                                                                            path={mdiTrashCan}
                                                                            size={1}
                                                                            color={theme.palette.text.secondary}
                                                                        />
                                                                    </IconButton>
                                                                </ListItemSecondaryAction>
                                                            </ListItem>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                        </List>
                                        <div style={{ width: 0 }}>{provided.placeholder}</div>
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </Paper>
                )}
                {getSelect(theme, 'taxes', 'Add tax', taxes.map(tax => tax.name), formik, addTax)}
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    {getSelect(theme, 'applicationMethod', 'Application', [/*'consecutive', */ 'cumulative'], formik)}
                    <IconButton
                        onClick={() => setApplicationInfoOpen(true)}
                        style={{ marginTop: theme.spacing.unit * 2, marginLeft: theme.spacing.unit }}
                    >
                        <Icon>info</Icon>
                    </IconButton>
                </div>
                {/* {getTextInput(theme, 'applicationMethod', 'Application', formik)} */}
                {/* <div style={{ display: 'flex' }}>
                    <div style={{ marginRight: theme.spacing.unit, flexGrow: 1 }}>
                        {getTextInput(theme, 'value', 'Amount (%)', formik, 'number')}
                    </div>
                    <div style={{ flexGrow: 1 }}>
                        {getSelect(theme, 'type', 'Type', ['none', 'excluded', 'included'], formik)}
                    </div>
                </div> */}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={formik.handleSubmit} data-cy="tax-group-crud-submit-btn">
                    {'Submit'}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default withMobileDialog()(withTheme()(TaxGroupForm));
