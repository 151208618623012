import React from 'react';

import _ from 'lodash';

import { withTheme } from '@material-ui/core';

import { Dialog, DialogContent, DialogActions, Button, DialogContentText, LinearProgress } from '@material-ui/core';

function ConfirmationDialog(props) {
    const {
        theme,
        showLoader = true,
        confirmText = 'Yes',
        cancelText = 'Cancel',
        content,
        loading,
        onCancel,
        onConfirm,
        open,
        onClose
    } = props;
    return (
        <Dialog onClose={onClose} open={open}>
            <DialogContent>
                <DialogContentText style={{ marginBottom: showLoader ? theme.spacing.unit : undefined }}>
                    {content}
                </DialogContentText>
                {loading && showLoader && <LinearProgress />}
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={_.isNil(onCancel) ? () => onClose() : onCancel}>
                    {cancelText}
                </Button>
                <Button color="secondary" onClick={onConfirm} data-cy="confirmation-dialog-confirm">
                    {confirmText}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default withTheme()(ConfirmationDialog);
