import React, { useState, useMemo, useEffect, useContext } from 'react';

// Logic packages
import _ from 'lodash';
import moment from 'moment-timezone';
import { useHistory, useLocation } from 'react-router-dom';

// Material UI components
import { Grid, Drawer, Typography, Fab, Icon, CircularProgress } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';

import queryString from 'query-string';

// External components
import LargeMap from 'components/LargeMap/LargeMap';
import GeneralWarningDialog from 'components/Dialogs/Warning';

// Internal components
import Disconnected from './Disconnected';
import DatePickerPanel from './DatePickerPanel/';
import InfoPanel from './InfoPanel';
import DrawerContent from './DrawerContent';
import TripDialog from './TripDialog';
import AssignDialog from './AssignDialog';
import MultistepPickupDialog from './MultistepPickupDialog';
import MultistepAbortDialog from './MultistepAbortDialog';
import BulkRescheduleDialog from './BulkRescheduleDialog';
import OutOfSystemStopDialog from './OutOfSystemStopDialog';
import ScheduleUnservicedDialog from './ScheduleUnservicedDialog';
import SkipDialog from './SkipDialog';
import ZoneEditPanel from './ZoneEditPanel';
import ZoneLegend from './ZoneLegend';

// Custom hooks
import useAdminSocketIO from './hooks/useAdminSocketIO';
import useGetMapMetaData from './hooks/useGetMapMetaData';
import useSelection from './hooks/useSelection';
import useTripToCenter from './hooks/useTripToCenter';
import useFilterDate from './hooks/useFilterDate';
import useDataFilteredByDate from './hooks/useDataFilteredByDate';
import useDataToRender from './hooks/useDataToRender';
import useTripDialog from './hooks/useTripDialog';
import useBulkRescheduleDialog from './hooks/useBulkRescheduleDialog';
import useOutOfSystemStopDialog from './hooks/useOutOfSystemStopDialog';
import useScheduleUnserviced from './hooks/useScheduleUnserviced';
import useCollectorDropDown from 'components/DropDownSelects/Collectors/hooks/useCollectorDropDown';

import SnackbarContext from 'components/CustomSnackbar/SnackbarContext';
// Misc logic
import { getWindowWidth } from 'utils/misc';
import { MODES, ADMIN_REASONS_AVAILABLE, MODIFIERS } from './constants';
import { RESIDENTIAL, COMMERCIAL, CONDO, BOTTLE_DRIVE, TIMEZONES, ROLES } from 'constants.js';
import { _user, _pickup } from 'std';
// contexts
import HttpContext from 'utils/contexts/HttpContext';
import OperatorContext from 'utils/contexts/OperatorContext';
import useZoneCRUD from './hooks/useZoneCRUD';
import useDefaultCoordinates from 'utils/hooks/useDefaultCoordinates';

const timezones = TIMEZONES;

function PickupsAdmin(props) {
    const http = useContext(HttpContext);
    const operator = useContext(OperatorContext);
    const history = useHistory();
    const location = useLocation();
    const onSnackbar = useContext(SnackbarContext);

    const { theme, google, collectors, collector, reloadOperator } = props;

    const isSystemAdmin = operator.accountType === 'System Administrator' || ROLES.includes(operator.accountType);
    const isCollectorAdmin = operator.accountType === 'Collector Administrator';

    const [pickupDrawerOpen, setPickupDrawerOpen] = useState(false);
    const [recalculatingTrips, setRecalculatingTrips] = useState(false);
    const [sendingEtas, setSendingEtas] = useState(false);
    const [hiddenPickupsDialogOpen, setHiddenPickupsDialogOpen] = useState(false);
    // const [selectedCollector, setSelectedCollector] = useState();
    const [showAllPickups, setShowAllPickups] = useState(true);
    const [sendETANotifications, setSendETANotifications] = useState(false);

    const [centerOnMarkersTrigger, setCenterOnMarkersTrigger] = useState(false);
    const { defaultCoordinates } = useDefaultCoordinates(http);

    const [completePickupImageTitle, setCompletePickupImageTitle] = useState('');
    const [completePickupImageInstructions, setCompletePickupImageInstructions] = useState([]);
    const [abortPickupImageTitle, setAbortPickupImageTitle] = useState('');
    const [abortPickupImageInstructions, setAbortPickupImageInstructions] = useState([]);

    const [dropLocationMarkerOverride, setDropLocationMarkerOverride] = useState({});
    const [charityMarkerOverride, setCharityMarkerOverride] = useState({});

    const legendOverflow = getWindowWidth() <= theme.spacing.unit * 4 + 480 + 200;

    const [filters, setFilters] = useState({
        showZones: false,
        onlyTodayZones: false,
        showLegend: !legendOverflow ? true : false,
        showNextServiceDate: false,
        showClusterIds: 'None',
        clusterPickupMarkers: false,
        pickupTypes: [RESIDENTIAL, COMMERCIAL, CONDO, BOTTLE_DRIVE, 'Stop'],
        commodityTypes: [],
        modifierTypes: []
    });
    const [loadingNextPickup, setLoadingNextPickup] = useState(false);

    const {
        loading,
        socket,
        disconnected,
        trips,
        drivers,
        pickups,
        triplessPickups,
        clearAll,
        setSocketDate
    } = useAdminSocketIO(setLoadingNextPickup);

    const allPickupSkuTypes = useMemo(() => {
        const skuTypesSet = new Set();
        pickups.map(pickup => {
            Object.keys(_.get(pickup, 'payload', {})).forEach(skuType => {
                skuTypesSet.add(skuType);
            });
        });
        return [...skuTypesSet];
    }, [pickups]);

    const {
        fetchingData,
        zones,
        charities,
        driversAvailable,
        collectorsAvailable,
        commoditiesAvailable,
        reloadZones
    } = useGetMapMetaData({ http, operator, filters, setFilters, allPickupSkuTypes, collector });

    useEffect(() => {
        pickups.forEach(p => {
            p.numberOfBins = _pickup.getNumberOfBins(p, commoditiesAvailable);
        });
    }, [pickups, commoditiesAvailable]);

    const collectorsWithPickups = useMemo(() => {
        if (_user.isCollectorAdmin(operator)) {
            return _.get(operator, 'collectors', [collector]);
        } else {
            let collectorsActiveToday = new Set(); // Prevent duplicate collectors in array
            collectors.forEach(c => {
                if (
                    _.filter(pickups, p => _.isNil(p.trip))
                        .map(p => _.get(p, 'collector._id'))
                        .includes(c._id)
                ) {
                    collectorsActiveToday.add(c);
                }
                if (trips.map(t => _.get(t, 'transporter.collector')).includes(c._id)) {
                    collectorsActiveToday.add(c);
                }
            });

            return [...collectorsActiveToday];
        }
    }, [collectors, operator, pickups, trips]);

    useEffect(() => {
        (async function() {
            const res = await http.getJSON('/system/driverDialogConfig');
            if (res.ok) {
                setCompletePickupImageTitle(_.get(res, 'data.completePickupImageTitle', true));
                setCompletePickupImageInstructions(_.get(res, 'data.completePickupImageInstructions', true));
                setAbortPickupImageTitle(_.get(res, 'data.abortPickupImageTitle', true));
                setAbortPickupImageInstructions(_.get(res, 'data.abortPickupImageInstructions', true));
            }
        })();
        (async function() {
            const res = await http.getJSON('/system/getMarkerConfigs');
            if (res.ok) {
                setDropLocationMarkerOverride(res.data.dropLocationMarker);
                setCharityMarkerOverride(res.data.charityMarker);
            }
        })();
    }, []);

    const handleRecalc = () => {
        if (!_.isNil(socket) && !recalculatingTrips) {
            setRecalculatingTrips(true);

            onSnackbar('Fetching trips...');

            socket.emit(
                'refresh-selected-day',
                { date: filterDate.toISOString(), dateInteger: parseInt(filterDate.format('YYYYMMDD')) },
                success => {
                    if (!success) {
                        onSnackbar('Failed to fetch trips', 'error');
                    } else {
                        onSnackbar('Trips fetched.');
                    }
                    setRecalculatingTrips(false);
                }
            );
        }
    };

    /*  Selected data */
    const {
        pickupSelected,
        pickupsChecked,
        pickupIdsChecked,
        tripIdsUnchecked,
        pinSelected,
        selectedNums,
        unselectEverything,
        handleMapClick: handleSelectionMapClick,
        handlePickupClick,
        handlePinClick,
        handleCheckPickup,
        handleCheckPickups,
        handleRectangleSelectPickups,
        handleCheckTrip,
        draggablePickupsSelected
    } = useSelection(trips, pickups, commoditiesAvailable);

    const {
        selectedZoneBounds,
        zoneDataSelected,
        creatingNewZone,
        loading: zonesCRUDLoading,
        handleSelectZoneId,
        handleZoneBoundsUpdated,
        handleClickNewZone,
        handleSubmitValues,
        handleDisableZone,
        handleMapClick: handleZoneCRUDMapClick
    } = useZoneCRUD({
        zones,
        onReloadZones: reloadZones
    });

    const { tripToCenter, handleTripToCenter } = useTripToCenter();

    const {
        collector: selectedCollector,
        handleChange: handleChangeCollectorFilter,
        collectorObj
    } = useCollectorDropDown({
        collectors: collectorsWithPickups,
        receivers: [],
        saveStateInURL: true,
        showAll: false,
        initialCollector: _user.isCollectorAdmin(operator) ? collector : null
    });

    /* Filter date */
    const {
        filterDate,
        timezone,
        handleFilterDateChange,
        onUnservicedFilterDate,
        handlePrevFilterDate,
        handleNextFilterDate,
        handleChangeTimezone
    } = useFilterDate({
        trips,
        unselectEverything,
        timezones: !_.isEmpty(collectorObj)
            ? [_.get(collectorObj, 'timezone', process.env.REACT_APP_REGION_TIMEZONE)]
            : timezones
    });

    /* Data on filter date */
    const { driversOnDate } = useDataFilteredByDate(filterDate, drivers, trips);

    /* Data shown on the map */
    const {
        tripsRendered,
        routesRendered,
        pickupsRendered,
        startLocationsRendered,
        destinationsRendered
    } = useDataToRender({
        tripsOnDate: trips,
        tripIdsUnchecked,
        pickups,
        selectedCollector,
        showAllPickups,
        collectors,
        commoditiesAvailable,
        filters
    });

    // TODO: encapsulate logic within the dialog component (similar to AssignDialog)
    const {
        trip: tripSelected,
        dialogOpen: tripDialogOpen,
        form: tripDialogForm,
        inProgress: tripDialogInProgress,
        warningDistanceFromDepot,
        processorsAvailable,
        unsetDriver,
        unsetProcessor,
        handleTripDialog,
        handleTripFormChange,
        handleTripFormDateChange,
        handleToggleTripBottleDriveFlag,
        handleTripFormSuggestionSelected,
        handleTripDelete,
        handleTripSubmit,
        handleUseDriverLocation,
        handleUseCollectorLocation
    } = useTripDialog(
        socket,
        filterDate,
        collectorsAvailable,
        driversAvailable,
        onSnackbar,
        http,
        sendETANotifications,
        selectedCollector,
        operator
    );

    const [assignDialogOpen, setAssignDialogOpen] = useState(false);
    const [completeDialogOpen, setCompleteDialogOpen] = useState(false);
    const [abortDialogOpen, setAbortDialogOpen] = useState(false);
    const [skipDialogOpen, setSkipDialogOpen] = useState(false);

    // TODO: encapsulate logic within the dialog component (similar to AssignDialog)
    const {
        dialogOpen: bulkRescheduleDialogOpen,
        form: bulkRescheduleForm,
        inProgress: bulkRescheduleInProgress,
        handleBulkRescheduleDialog,
        handleBulkRescheduleChange,
        handleBulkRescheduleSubmit,
        rescheduleReasons,
        handleRescheduleReasonChange
    } = useBulkRescheduleDialog({
        theme,
        currentDate: filterDate,
        allPickups: pickups,
        pickupIdsChecked,
        unselectEverything,
        onSnackbar,
        http,
        handleRecalc
    });

    const {
        dialogOpen: outofSystemStopDialogOpen,
        form: outOfSystemStopForm,
        inProgress: outOfSystemStopInProgress,
        isEdit: isEditStop,
        OOSPreset,
        onCreateStop,
        onEditStop,
        toggleStopDialogClose,
        handleOutOfSystemStopChange,
        handleLocationChange: handleStopLocationChange,
        handleTripChange: handleStopTripChange,
        handleOutOfSystemStopSubmit,
        addOOSPreset,
        removeOOSPreset,
        handleOOSPresetChange
    } = useOutOfSystemStopDialog(http, operator, filterDate, onSnackbar, handleRecalc, collector);

    const {
        dialogOpen: scheduleUnservicedDialog,
        form: scheduleUnservicedForm,
        inProgress: scheduleUnservicedInProgress,
        tripsAvailable: scheduleUnservicedTripsAvailable,
        handleScheduleUnservicedDialog,
        handleScheduleUnservicedChange,
        handleScheduleUnservicedSubmit
    } = useScheduleUnserviced(theme, pickupIdsChecked, unselectEverything, onSnackbar, http, handleRecalc);

    // TODO: find a better place for styles
    const mapStyle = useMemo(
        () => ({
            position: 'relative',
            borderRadius: 0,
            borderRight: `1px solid ${
                theme.palette.type === 'light' ? theme.palette.grey[400] : theme.palette.grey[700]
            }`
        }),
        [theme]
    );

    const handleForceRecalculation = trip => () => {
        setRecalculatingTrips(true);

        onSnackbar(`Recalculating trip ${trip}`);
        socket.emit('force-recalculation', { trip, sendETANotifications }, success => {
            if (!success) {
                onSnackbar(`Failed to recalculate trip ${trip}`, 'error');
                setRecalculatingTrips(false);
            } else {
                onSnackbar(`Trip ${trip} recalcualted`);
                handleRecalc();
            }
            handleTripDialog(false);
        });
    };

    const handleSendEtas = async trip_id => {
        setSendingEtas(true);
        setRecalculatingTrips(true);
        const res = await http.post(`/trips/${trip_id}/sendEtaUpdates`);
        if (res.ok) {
            setSendingEtas(false);
            setRecalculatingTrips(false);
            onSnackbar('ETA notifications sent');
            handleRecalc();
        } else {
            setSendingEtas(false);
            setRecalculatingTrips(false);
            onSnackbar('Unable to send ETA notifications', 'error');
        }
    };

    const handleAssignPickupsToTrips = async setLoading => {
        onSnackbar('Assigning pickups to trips');

        const triplessPickupIds = triplessPickups.map(({ _id }) => _id);
        const res = await http.post('/trips/assignPickups', {
            dateInteger: parseInt(filterDate.format('YYYYMMDD')),
            ids: triplessPickupIds
        });

        if (res.ok) {
            setLoading(false);
            onSnackbar('Pickups assigned to trips');
            handleRecalc();
        } else {
            setLoading(false);
            onSnackbar('Unable to assign pickups to trips', 'error');
        }
    };

    const handleSetFirstPickupForTrip = async (pickup_id, trip) => {
        onSnackbar('Moving pickup to start of trip');

        const res = await http.post(`/trips/${trip}/setFirstPickupForTrip`, {
            pickup_id
        });

        if (res.ok) {
            onSnackbar(`Recalculating trip ${trip}`);
            socket.emit('force-recalculation', { trip, sendETANotifications }, success => {
                if (!success) {
                    onSnackbar(`Failed to recalculate trip ${trip}`, 'error');
                    setRecalculatingTrips(false);
                } else {
                    onSnackbar(`Trip ${trip} recalcualted`);
                    handleRecalc();
                }
                handleTripDialog(false);
            });
            // handleRecalc();
        } else {
            onSnackbar('Unable to set first pickup', 'error');
        }
    };

    // const handleCenterMapOnPickups = () => {
    //     setCenterOnMarkersTrigger(!centerOnMarkersTrigger);
    // };

    const handleFilters = e => {
        const { value, name } = e.target;
        setFilters({ ...filters, [name]: value });
    };

    const handleFiltersCheckbox = e => {
        const { checked, name } = e.target;
        setFilters({ ...filters, [name]: checked });
    };

    const handleMapClick = e => {
        handleSelectionMapClick();
        handleZoneCRUDMapClick(e);
    };

    // will refresh the day you select
    useEffect(() => {
        setSocketDate(filterDate);
        clearAll();

        if (isCollectorAdmin && moment(filterDate).isAfter(moment().add(7, 'days'))) {
            setHiddenPickupsDialogOpen(true);
        } else {
            handleRecalc();
        }
    }, [socket, filterDate, timezone]);

    useEffect(() => {
        let qs = queryString.parse(location.search);
        if (JSON.parse(qs.showZones || 'false')) {
            setFilters(filters => ({ ...filters, showZones: true }));
        }
    }, []);

    /* RENDER */

    if (loading || fetchingData) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <div style={{ textAlign: 'center' }}>
                    <CircularProgress size={80} />
                    <Typography variant="body1" style={{ marginTop: theme.spacing.unit * 2 }}>
                        Loading pickups data
                    </Typography>
                </div>
            </div>
        );
    }

    if (disconnected) {
        return <Disconnected />;
    }

    const bulkSelectionMode = pickupsChecked.length > 1;

    return (
        <Grid container spacing={0} style={{ height: '100%' }}>
            <Grid item xs={12} style={{ position: 'relative', height: '100%' }}>
                <LargeMap
                    centerOnMarkersTrigger={centerOnMarkersTrigger}
                    history={history}
                    defaultCoordinates={defaultCoordinates}
                    visible
                    closeViewAllowed
                    showDirections
                    showCustomerInfo
                    accountType={operator.accountType}
                    hideLegalInfo
                    routes={routesRendered}
                    collectors={collectorsAvailable}
                    pickups={pickupsRendered}
                    pickupSelected={pickupSelected}
                    zoneDataSelected={zoneDataSelected}
                    pickupIdsChecked={pickupIdsChecked}
                    pickupRectangleSelect={handleRectangleSelectPickups}
                    startLocations={startLocationsRendered}
                    destinations={destinationsRendered}
                    drivers={driversOnDate}
                    zones={zones}
                    tripToCenter={tripToCenter}
                    style={mapStyle}
                    onMapClick={handleMapClick}
                    onPickupClick={handlePickupClick}
                    onPinClick={handlePinClick}
                    onZoneClick={handleSelectZoneId}
                    onZoneBoundsChanged={handleZoneBoundsUpdated}
                    filters={filters}
                    filterDate={filterDate}
                    commoditiesAvailable={commoditiesAvailable}
                    preventDefaultCenterOnTrip={filters.showZones}
                    clusterPickupMarkers={filters.clusterPickupMarkers}
                    dropLocationMarkerOverride={dropLocationMarkerOverride}
                    charityMarkerOverride={charityMarkerOverride}
                />
                <DatePickerPanel
                    filterDate={filterDate}
                    trips={_.sortBy(trips, ['collector.name', 'startTime', 'transporter.name.first'])}
                    tripIdsUnchecked={tripIdsUnchecked}
                    timezones={timezones}
                    timezone={timezone}
                    pickups={pickups}
                    pickupsHidden={pickups.length - pickupsRendered.length}
                    collectors={collectorsWithPickups}
                    collector={collectorObj}
                    selectedNums={selectedNums}
                    commoditiesAvailable={commoditiesAvailable}
                    handleAssignPickupsToTrips={handleAssignPickupsToTrips}
                    selectedCollector={selectedCollector}
                    setSelectedCollector={handleChangeCollectorFilter}
                    recalculatingTrips={recalculatingTrips}
                    showAllPickups={showAllPickups}
                    setShowAllPickups={setShowAllPickups}
                    sendETANotifications={sendETANotifications}
                    setSendETANotifications={setSendETANotifications}
                    isSystemAdmin={isSystemAdmin}
                    handlePinClick={handlePinClick}
                    onChangeTimezone={handleChangeTimezone}
                    onUnservicedFilterDate={onUnservicedFilterDate}
                    onPrevFilterDate={handlePrevFilterDate}
                    onNextFilterDate={handleNextFilterDate}
                    onFilterDateChange={handleFilterDateChange}
                    onCheckTrip={handleCheckTrip}
                    onTripToCenter={handleTripToCenter}
                    onTripDialog={handleTripDialog}
                    onForceRecalculation={handleForceRecalculation}
                    hidden={
                        !_.isNil(zoneDataSelected) ||
                        (legendOverflow && _.get(filters, 'showLegend', false) && _.get(filters, 'showZones', false))
                    }
                    onSendEtas={handleSendEtas}
                    sendingEtas={sendingEtas}
                    drivers={driversAvailable}
                />
                <ZoneEditPanel
                    loading={zonesCRUDLoading || loading}
                    creatingNewZone={creatingNewZone}
                    zone={zoneDataSelected}
                    collectors={collectorsAvailable}
                    commodities={commoditiesAvailable}
                    onCancel={() => handleSelectZoneId(null)}
                    onSubmitValues={handleSubmitValues}
                    onDisableZone={handleDisableZone}
                    hidden={
                        _.isNil(zoneDataSelected) ||
                        (legendOverflow && _.get(filters, 'showLegend', false) && _.get(filters, 'showZones', false))
                    }
                />
                {filters.showZones && (
                    <ZoneLegend
                        filters={filters}
                        onCreateZone={handleClickNewZone}
                        leftOffset={
                            legendOverflow && _.get(filters, 'showLegend', false)
                                ? theme.spacing.unit * 2
                                : 480 + theme.spacing.unit * 4
                        }
                    />
                )}

                <InfoPanel
                    admin
                    socket={socket}
                    http={http}
                    isSystemAdmin={isSystemAdmin}
                    mode={MODES['ADMIN']}
                    bulkSelectionMode={bulkSelectionMode}
                    pickup={pickupSelected}
                    pinSelected={pinSelected}
                    charities={charities}
                    commoditiesAvailable={commoditiesAvailable}
                    setCompleteDialogOpen={setCompleteDialogOpen}
                    setAbortDialogOpen={setAbortDialogOpen}
                    setSkipDialogOpen={setSkipDialogOpen}
                    onOpenPickupsDrawer={() => setPickupDrawerOpen(true)}
                    onEditStop={onEditStop}
                    resetPickupIdSelected={() => null}
                    onMode={() => null}
                    onSnackbar={onSnackbar}
                    handleRecalc={handleRecalc}
                    operator={operator}
                    reloadOperator={reloadOperator}
                    setAsFirstPickup={handleSetFirstPickupForTrip}
                />
            </Grid>

            <Drawer
                open={pickupDrawerOpen}
                anchor="right"
                variant={pickupDrawerOpen ? 'persistent' : 'temporary'}
                data-cy="pickups-admin-pickup-drawer"
            >
                <div
                    style={{
                        paddingTop: `var(--custom-safe-area-top, ${theme.spacing.unit * 2}px)`,
                        paddingBottom: `env(safe-area-inset-bottom)`,
                        height: '100%',
                        width: getWindowWidth() - 60 > 420 ? 420 : getWindowWidth() - 60
                    }}
                >
                    <DrawerContent
                        isSystemAdmin={isSystemAdmin}
                        isCollectorAdmin={isCollectorAdmin}
                        collectorCanReschedulePickups={_.get(collector, 'configuration.canReschedulePickups', false)}
                        bulkSelectionMode={bulkSelectionMode}
                        trips={tripsRendered}
                        pickups={pickups}
                        pickupsHidden={pickups.length - pickupsRendered.length}
                        triplessPickups={triplessPickups}
                        pickupSelected={pickupSelected}
                        pickupIdsChecked={pickupIdsChecked}
                        commoditiesAvailable={commoditiesAvailable}
                        handlePinClick={handlePinClick}
                        onPickupClick={handlePickupClick}
                        onCheckPickup={handleCheckPickup}
                        onCheckPickups={handleCheckPickups}
                        setAssignDialogOpen={setAssignDialogOpen}
                        onBulkRescheduleDialog={handleBulkRescheduleDialog}
                        onCreateStopDialog={onCreateStop}
                        onScheduleUnservicedDialog={handleScheduleUnservicedDialog}
                        onTripToCenter={handleTripToCenter}
                        selectedCollector={selectedCollector}
                        showAllPickups={showAllPickups}
                        recalculatingTrips={recalculatingTrips}
                        handleRecalc={handleRecalc}
                        setPickupDrawerOpen={setPickupDrawerOpen}
                        filters={filters}
                        handleFilters={handleFilters}
                        handleFiltersCheckbox={handleFiltersCheckbox}
                        onSnackbar={onSnackbar}
                        onForceRecalculation={handleForceRecalculation}
                        sendETANotifications={sendETANotifications}
                        unselectEverything={unselectEverything}
                        draggablePickupsSelected={draggablePickupsSelected}
                    />
                </div>
            </Drawer>

            <Fab
                color="primary"
                style={{
                    position: 'absolute',
                    bottom: theme.spacing.unit * 2,
                    right: theme.spacing.unit * 2
                }}
                onClick={() => setPickupDrawerOpen(!pickupDrawerOpen)}
                data-cy="pickups-admin-pickups-button"
            >
                <Icon>local_shipping</Icon>
            </Fab>

            <TripDialog
                operator={operator}
                google={google}
                open={tripDialogOpen}
                isSystemAdmin={isSystemAdmin}
                form={tripDialogForm}
                inProgress={tripDialogInProgress}
                unsetDriver={unsetDriver}
                unsetProcessor={unsetProcessor}
                tripSelected={tripSelected}
                driversAvailable={driversAvailable}
                processorsAvailable={processorsAvailable}
                collectorsAvailable={collectorsAvailable}
                warningDistanceFromDepot={warningDistanceFromDepot}
                onClose={() => handleTripDialog(false)}
                onFormChange={handleTripFormChange}
                onFormDateChange={handleTripFormDateChange}
                onToggleTripBottleDriveFlag={handleToggleTripBottleDriveFlag}
                onFormSuggestionSelected={handleTripFormSuggestionSelected}
                onTripDelete={handleTripDelete}
                onTripSubmit={handleTripSubmit}
                onSnackbar={onSnackbar}
                onUseDriverLocation={handleUseDriverLocation}
                onUseCollectorLocation={handleUseCollectorLocation}
                selectedCollector={selectedCollector}
            />

            <AssignDialog
                socket={socket}
                open={assignDialogOpen}
                pickupSelected={pickupSelected}
                pickupsChecked={pickupsChecked}
                trips={trips}
                collectorsAvailable={collectorsAvailable}
                filterDate={filterDate}
                onClose={() => setAssignDialogOpen(false)}
                unselectEverything={unselectEverything}
                onSnackbar={onSnackbar}
                http={http}
                setRecalculatingTrips={setRecalculatingTrips}
            />

            <MultistepPickupDialog
                http={http}
                socket={socket}
                open={completeDialogOpen}
                operator={operator}
                admin
                pickupSelected={pickupSelected}
                currentLocation={null}
                filterDate={filterDate}
                onClose={() => setCompleteDialogOpen(false)}
                onMode={() => null}
                setAfterCompleteDialogOpen={() => null}
                onSnackbar={onSnackbar}
                setRecalculatingTrips={setRecalculatingTrips}
                completePickupImageTitle={completePickupImageTitle}
                completePickupImageInstructions={completePickupImageInstructions}
                commoditiesAvailable={commoditiesAvailable}
                fetchTrips={() => {}}
                inProgress={loadingNextPickup}
                setInProgress={setLoadingNextPickup}
            />

            <MultistepAbortDialog
                http={http}
                admin
                operator={operator}
                socket={socket}
                open={abortDialogOpen}
                pickupSelected={pickupSelected}
                currentLocation={null}
                filterDate={filterDate}
                reasonsAvailable={ADMIN_REASONS_AVAILABLE}
                onClose={() => setAbortDialogOpen(false)}
                onMode={() => undefined}
                onSnackbar={onSnackbar}
                setRecalculatingTrips={setRecalculatingTrips}
                abortPickupImageTitle={abortPickupImageTitle}
                abortPickupImageInstructions={abortPickupImageInstructions}
            />

            <SkipDialog
                open={skipDialogOpen}
                admin
                handleClose={() => setSkipDialogOpen(false)}
                pickupSelected={pickupSelected}
                onSnackbar={onSnackbar}
                socket={socket}
            />

            <BulkRescheduleDialog
                open={bulkRescheduleDialogOpen}
                form={bulkRescheduleForm}
                pickupsSelected={pickupsChecked}
                inProgress={bulkRescheduleInProgress}
                isSystemAdmin={isSystemAdmin}
                rescheduleReasons={rescheduleReasons}
                onClose={() => handleBulkRescheduleDialog(false)}
                onChange={handleBulkRescheduleChange}
                onSubmit={handleBulkRescheduleSubmit}
                onRescheduleReasonChange={handleRescheduleReasonChange}
            />

            <ScheduleUnservicedDialog
                open={scheduleUnservicedDialog}
                form={scheduleUnservicedForm}
                pickupsSelected={pickupsChecked}
                inProgress={scheduleUnservicedInProgress}
                tripsAvailable={scheduleUnservicedTripsAvailable}
                handleClose={handleScheduleUnservicedDialog(false)}
                handleChange={handleScheduleUnservicedChange}
                handleSubmit={handleScheduleUnservicedSubmit}
            />

            <OutOfSystemStopDialog
                http={http}
                google={google}
                open={outofSystemStopDialogOpen}
                driversAvailable={driversAvailable}
                form={outOfSystemStopForm}
                timezone={timezone}
                inProgress={outOfSystemStopInProgress}
                collectors={collectorsAvailable}
                trips={trips}
                onClose={toggleStopDialogClose}
                onChange={handleOutOfSystemStopChange}
                onLocationChange={handleStopLocationChange}
                onTripChange={handleStopTripChange}
                isEditStop={isEditStop}
                onSubmit={handleOutOfSystemStopSubmit}
                selectedCollector={selectedCollector}
                OOSPreset={OOSPreset}
                addOOSPreset={addOOSPreset}
                removeOOSPreset={removeOOSPreset}
                handleOOSPresetChange={handleOOSPresetChange}
            />

            <GeneralWarningDialog open={hiddenPickupsDialogOpen} onClose={() => setHiddenPickupsDialogOpen(false)} />
        </Grid>
    );
}

export default withTheme()(PickupsAdmin);
