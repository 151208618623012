import _ from 'lodash';

import Compressor from 'compressorjs';

function useImageCompressor(config) {
    const quality = _.get(config, 'quality', 100);
    const maxDimensions = _.get(config, 'maxDimensions', 10000);

    const compressImage = async image => {
        return new Promise((resolve, reject) => {
            new Compressor(image, {
                quality: quality / 100,
                maxWidth: maxDimensions,
                maxHeight: maxDimensions,
                success: compressedResult => {
                    resolve(compressedResult);
                },
                error: error => {
                    resolve(image);
                }
            });
        });
    };

    return { compressImage };
}

export default useImageCompressor;
