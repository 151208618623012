import React from 'react';

import _ from 'lodash';
import { DAYS_OF_THE_WEEK } from 'constants.js';
import { _user } from 'std';

import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    Checkbox
} from '@material-ui/core';

const AddDriverDialog = ({
    open,
    handleClose,
    inactiveDrivers,
    driversToAdd,
    dayToEdit,
    handleChange,
    handleAdd,
    handleAddToAllDays
}) => {
    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Add Drivers to {_.capitalize(DAYS_OF_THE_WEEK[dayToEdit])}</DialogTitle>
            <DialogContent>
                <List>
                    {inactiveDrivers &&
                        inactiveDrivers.map((driver, i) => {
                            return (
                                <ListItem key={driver._id}>
                                    <ListItemText>{_user.getNameFull(driver)}</ListItemText>
                                    <ListItemSecondaryAction>
                                        <Checkbox
                                            checked={driversToAdd.includes(driver._id)}
                                            onClick={handleChange(driver._id)}
                                            data-cy={`driver-select-${_.get(driver, 'email', '')}`}
                                        />
                                    </ListItemSecondaryAction>
                                </ListItem>
                            );
                        })}
                </List>
            </DialogContent>
            <DialogActions>
                <Button
                    color="secondary"
                    variant="contained"
                    onClick={() => handleAddToAllDays()}
                    data-cy="driver-select-add-all-button"
                >
                    Add to all days
                </Button>
                <Button color="primary" variant="contained" onClick={handleAdd} data-cy="driver-select-add-button">
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddDriverDialog;
