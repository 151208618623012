import React, { useState, useEffect } from 'react';
import _ from 'lodash';

import { TextField, InputAdornment, Icon, colors, withTheme } from '@material-ui/core';

function InputWithConfirmButton({
    initialValue,
    onConfirmValue,
    label = '',
    allowEmptyString = false,
    variant = 'standard',
    InputProps,
    theme,
    multiline = false,
    fullWidth = false,
    dataCy = 'input-with-confirm-button'
}) {
    const [value, setValue] = useState(initialValue);

    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    const valueChanged = value !== initialValue;

    return (
        <TextField
            label={label}
            style={{ marginRight: theme.spacing.unit, flex: 1 }}
            //variant="outlined"
            disabled={process.env.REACT_APP_REGION_EXT === 'EXP' && value === 'Express Rates'}
            InputProps={{
                endAdornment: valueChanged && (
                    <InputAdornment position="end">
                        <Icon
                            fontSize="small"
                            style={{
                                color: !_.isEmpty(value) || allowEmptyString ? colors.green[500] : colors.grey[300],
                                cursor: 'pointer',
                                marginRight: theme.spacing.unit
                            }}
                            onClick={() => (!_.isEmpty(value) || allowEmptyString) && onConfirmValue(value)}
                            data-cy={`${dataCy}-confirm`}
                        >
                            check
                        </Icon>
                        <Icon
                            fontSize="small"
                            style={{ color: colors.red[500], cursor: 'pointer' }}
                            onClick={() => setValue(initialValue)}
                            data-cy={`${dataCy}-cancel`}
                        >
                            close
                        </Icon>
                    </InputAdornment>
                )
            }}
            multiline={multiline}
            fullWidth={fullWidth}
            variant={variant}
            {...InputProps}
            value={value}
            onChange={e => setValue(e.target.value)}
            data-cy={`${dataCy}-input`}
        />
    );
}

export default withTheme()(InputWithConfirmButton);
