import React, { useState } from 'react';
import _ from 'lodash';
import {
    Icon,
    IconButton,
    Avatar,
    Card,
    CardHeader,
    CardContent,
    Collapse,
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    Typography
} from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';

import charityTaskHelper from 'helpers/charityTaskHelper';

const FeaturedCharityCard = ({ theme, regionName, charities, handleDialog }) => {
    const [expanded, setExpanded] = useState(false);

    const handleExpand = () => {
        setExpanded(!expanded);
    };

    return (
        <Card
            style={{
                flex: '0 1 450px',
                margin: theme.spacing.unit,
                boxShadow: 'none',
                border: '1px solid rgba(224, 224, 224, 1)',
                height: '100%'
            }}
        >
            <CardHeader
                avatar={<Avatar style={{ backgroundColor: theme.palette.primary.main }}>{charities.length}</Avatar>}
                title={regionName}
                action={
                    <IconButton onClick={handleExpand}>
                        <Icon>keyboard_arrow_down</Icon>
                    </IconButton>
                }
            />
            <Collapse in={expanded}>
                <CardContent>
                    <List>
                        {charities.map(charity => {
                            const { _id, charityTasks } = charity;
                            const numCharityTasksComplete = charityTaskHelper.numberOfTasksComplete(charityTasks);
                            const numCharityTasksCompletable = _.filter(
                                charityTasks,
                                charityTask => !charityTask.task.notCompletable && !charityTask.task.hidden
                            ).length;

                            return (
                                <ListItem key={charity._id}>
                                    <ListItemAvatar>
                                        <Avatar
                                            style={{ cursor: 'pointer' }}
                                            onClick={handleDialog('taskEditDialog', charity)}
                                        >
                                            <Typography variant="caption" style={{ color: '#fff' }}>
                                                {numCharityTasksComplete} / {numCharityTasksCompletable}
                                            </Typography>
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={charity.name} />
                                    <IconButton onClick={handleDialog('featureEditDialog', charity)}>
                                        <Icon>edit</Icon>
                                    </IconButton>
                                </ListItem>
                            );
                        })}
                    </List>
                </CardContent>
            </Collapse>
        </Card>
    );
};

export default withTheme()(FeaturedCharityCard);
