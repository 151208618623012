import React from 'react';

import { Typography, withTheme } from '@material-ui/core';

import DonationButtonsComponent from '../Components/DonationButtonsComponent';
import { loc } from 'localizations/localizationHandler';

function donationButtonDisplay({ charity, referralLink, buttonImageName, referralLinkComponent, lang }) {
    const linkStyle = { overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' };

    return charity.approved ? (
        <>
            <Typography variant="body1">
                {loc('growthPostInfo5', lang)}
                <ol>
                    <li>
                        {loc('growthPostInfo2', lang)}
                        <div style={linkStyle}>{referralLinkComponent}</div>
                    </li>
                    <li>
                        {loc('growthPostInfo3', lang)}
                        <div style={linkStyle}>{referralLinkComponent}</div>
                    </li>
                </ol>
            </Typography>
            <DonationButtonsComponent referralLink={referralLink} buttonImageName={buttonImageName} />
        </>
    ) : (
        <Typography variant="body1">{loc('growthPostInfo9', lang)}</Typography>
    );
}

export default withTheme()(donationButtonDisplay);
