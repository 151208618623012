import React, { useContext } from 'react';
import _ from 'lodash';

import { withTheme } from '@material-ui/core/styles';
import {
    Grid,
    Paper,
    Table as TableMUI,
    TableHead,
    TableBody,
    TableSortLabel,
    TableCell,
    TableRow,
    TablePagination,
    LinearProgress,
    Typography,
    Checkbox
} from '@material-ui/core';

import useQueryString from 'utils/hooks/useQueryString';
import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

const DashBoardTable = ({
    title,
    loading,
    theme,
    headers,
    filteredHeaders,
    length,
    hiddenCount = 0,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    handleCheckbox,
    refresh,
    checkbox,
    checkedItems,
    children,
    customAction,
    customActionAlign = 'right',
    customHeader = null,
    cellStyle = {},
    isReviewedInfractionsTable = false,
    customActionStyle = {},
    defaultSort = null
}) => {
    const { lang } = useContext(LocalizationContext);

    const customActionAlignments = ['left', 'right'];
    const [sortBy, setSortBy] = useQueryString(
        isReviewedInfractionsTable ? 'reviewed_sort_by' : 'sort_by',
        defaultSort ? defaultSort.column : (window.localStorage.getItem(`${title}-sortBy`) || ''),
        true
    );
    const [sortDir, setSortDir] = useQueryString(
        isReviewedInfractionsTable ? 'reviewed_sort_dir' : 'sort_dir',
        defaultSort ? defaultSort.dir : (window.localStorage.getItem(`${title}-sortDir`) || 'asc'),
        true
    );
    // only for infraction table
    const [reviewedSortBy, setReviewedSortBy] = useQueryString(
        'reviewed_sort_by',
        window.localStorage.getItem(`${title}-reviewedSortBy`) || 'Resolved On',
        true
    );
    const [reviewedSortDir, setReviewedSortDir] = useQueryString(
        'reviewed_sort_dir',
        window.localStorage.getItem(`${title}-reviewedSortDir`) || 'desc',
        true
    );
    const handleSortBy = key => {
        if (sortBy !== key) {
            setSortBy(key);
            setSortDir('desc');
            window.localStorage.setItem(`${title}-sortBy`, key);
            window.localStorage.setItem(`${title}-sortDir`, 'desc');
        } else if (sortBy === key && sortDir === 'desc') {
            setSortDir('asc');
            window.localStorage.setItem(`${title}-sortDir`, 'asc');
        } else {
            setSortBy();
            setSortDir();
            window.localStorage.removeItem(`${title}-sortBy`);
            window.localStorage.removeItem(`${title}-sortDir`);
        }
        if (refresh) {
            refresh();
        }
    };
    const handleReviewedSortBy = key => {
        if (reviewedSortBy !== key) {
            setReviewedSortBy(key);
            setReviewedSortDir('desc');
            window.localStorage.setItem(`${title}-reviewedSortBy`, key);
            window.localStorage.setItem(`${title}-reviewedSortDir`, 'desc');
        } else if (reviewedSortBy === key && reviewedSortDir === 'desc') {
            setReviewedSortDir('asc');
            window.localStorage.setItem(`${title}-reviewedSortDir`, 'asc');
        } else {
            setReviewedSortBy();
            setReviewedSortDir();
            window.localStorage.removeItem(`${title}-reviewedSortBy`);
            window.localStorage.removeItem(`${title}-reviewedSortDir`);
        }
        if (refresh) {
            refresh();
        }
    };

    return (
        <div
            style={{
                padding: theme.spacing.unit,
                paddingLeft: theme.spacing.unit * 2,
                paddingRight: theme.spacing.unit * 2
            }}
        >
            <Grid container spacing={theme.spacing.unit * 2}>
                <Grid item xs={12}>
                    <Paper>
                        {loading && <LinearProgress />}
                        {!_.isNil(customHeader) ? (
                            customHeader
                        ) : (
                            <>
                                <div
                                    style={{
                                        padding: theme.spacing.unit * 2,
                                        paddingBottom: hiddenCount > 0 ? 0 : theme.spacing.unit * 2,
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Typography
                                        variant="h6"
                                        noWrap
                                        style={{ flex: '1 0 auto', marginRight: theme.spacing.unit }}
                                    >
                                        {title}
                                    </Typography>
                                    {customActionAlign === 'right' ||
                                    !customActionAlignments.includes(customActionAlign)
                                        ? customAction
                                        : ''}
                                </div>
                                {hiddenCount > 0 && (
                                    <div
                                        style={{
                                            padding: theme.spacing.unit * 2,
                                            paddingTop: 0,
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <Typography variant="h6" noWrap style={{ fontSize: '0.9em' }}>
                                            There are <b>{hiddenCount}</b> entries hidden due to filter settings.
                                        </Typography>
                                    </div>
                                )}
                                {customActionAlign === 'left' ? (
                                    <div
                                        style={{
                                            padding: theme.spacing.unit * 2,
                                            display: 'flex',
                                            justifyContent: 'left',
                                            alignItems: 'center',
                                            ...customActionStyle
                                        }}
                                    >
                                        {customAction}
                                    </div>
                                ) : (
                                    ''
                                )}
                            </>
                        )}

                        <div style={{ overflow: 'auto' }}>
                            <TableMUI size="small" padding="dense">
                                <TableHead>
                                    <TableRow>
                                        {checkbox && (
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    checked={
                                                        checkedItems > 0 &&
                                                        (checkedItems === rowsPerPage || checkedItems === length)
                                                    }
                                                    onChange={handleCheckbox}
                                                    data-cy={`${title
                                                        .toLowerCase()
                                                        .replace(/[, ]+/g, '-')
                                                        .trim()}-select-all-checkbox`}
                                                />
                                            </TableCell>
                                        )}
                                        {headers.map(({ name, key, locKey, padding }, i) => {
                                            if (!filteredHeaders.includes(name)) return null;
                                            const formattedName = name.split('^');
                                            const labelText = !_.isNil(locKey) ? loc(locKey, lang) : formattedName[0];

                                            const canSortBy = name.includes('^') && key !== null;
                                            return (
                                                <TableCell
                                                    style={cellStyle}
                                                    key={i}
                                                    padding={_.isNil(padding) ? 'default' : padding}
                                                >
                                                    {canSortBy ? (
                                                        <TableSortLabel
                                                            onClick={() => {
                                                                if (isReviewedInfractionsTable) {
                                                                    handleReviewedSortBy(key);
                                                                } else {
                                                                    handleSortBy(key);
                                                                }
                                                            }}
                                                            active={
                                                                isReviewedInfractionsTable
                                                                    ? reviewedSortBy === key
                                                                    : sortBy === key
                                                            }
                                                            direction={
                                                                isReviewedInfractionsTable ? reviewedSortDir : sortDir
                                                            }
                                                        >
                                                            {labelText}
                                                        </TableSortLabel>
                                                    ) : (
                                                        labelText
                                                    )}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                </TableHead>
                                <TableBody>{children}</TableBody>
                            </TableMUI>
                        </div>
                        <div style={{ overflow: 'auto' }}>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 50, 'All']}
                                component="div"
                                count={length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
};

export default withTheme()(DashBoardTable);
