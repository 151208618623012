import React, { Component } from 'react';

import * as colors from '@material-ui/core/colors';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles, withTheme } from '@material-ui/core/styles';

import { deviceHelper } from 'utils/misc';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

const styles = {
    paperRed: {
        backgroundColor: colors.red[500]
    },
    paperBlue: {
        backgroundColor: colors.blue[500]
    }
};

class HttpErrorDialog extends Component {
    static contextType = LocalizationContext;

    getErrorType = (error, lang) => {
        return {
            NOT_AUTHENTICATED: loc('errorDialog1', lang),
            NOT_AUTHORIZED: loc('errorDialog2', lang),
            PAYLOAD_TOO_LARGE: loc('errorDialog3', lang),
            UNRECOVERABLE: loc('errorDialog4', lang),
            TOO_MANY_REQUESTS: loc('errorDialog16', lang)
        }[error];
    };

    Get_PAYLOAD_TOO_LARGE_errorMessage = (oversizedFileSize, lang) => {
        const fileSize = oversizedFileSize ? `(${(oversizedFileSize / 1048576).toFixed(2)} MB)` : '';
        return loc('errorDialog5', lang, { size: fileSize });
    };

    render() {
        const { classes, theme, open, errorType, oversizedFileSize } = this.props;
        const { lang } = this.context;

        if (errorType === 'CLIENT_SERVER_VERSION_MISMATCH') {
            // Temporary solution!
            // TODO: we should just bring up the "Please update" dialog instead
            return (
                <Dialog open={open} fullWidth classes={{ paper: classes.paperBlue }}>
                    <DialogTitle disableTypography>
                        <Typography variant="h5" style={{ fontWeight: 300, color: theme.palette.common.white }}>
                            {loc('errorDialog7', lang)}
                        </Typography>
                    </DialogTitle>
                    <DialogContent style={{ paddingBottom: theme.spacing.unit }}>
                        <DialogContentText style={{ color: theme.palette.common.white }}>
                            {deviceHelper.isNativeApp() ? loc('errorDialog8', lang) : loc('errorDialog9', lang)}
                        </DialogContentText>
                        <div style={{ marginTop: theme.spacing.unit * 4, padding: 4, textAlign: 'center' }}>
                            <Icon className="slow-spin" style={{ color: theme.palette.common.white, fontSize: 64 }}>
                                settings
                            </Icon>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button style={{ color: theme.palette.common.white }} onClick={this.props.onClose}>
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
            );
        }

        if (errorType === 'BACK_SERVER_OFFLINE') {
            return (
                <Dialog open={open} fullWidth classes={{ paper: classes.paperBlue }}>
                    <DialogTitle disableTypography>
                        <Typography variant="h5" style={{ fontWeight: 300, color: theme.palette.common.white }}>
                            {loc('errorDialog10', lang)}
                        </Typography>
                    </DialogTitle>
                    <DialogContent style={{ paddingBottom: theme.spacing.unit }}>
                        <DialogContentText style={{ color: theme.palette.common.white }}>
                            {loc('errorDialog11', lang)}
                        </DialogContentText>
                        <DialogContentText style={{ marginTop: theme.spacing.unit, color: theme.palette.common.white }}>
                            {loc('errorDialog12', lang)}
                        </DialogContentText>
                        <div style={{ marginTop: theme.spacing.unit * 4, padding: 4, textAlign: 'center' }}>
                            <Icon className="slow-spin" style={{ color: theme.palette.common.white, fontSize: 64 }}>
                                settings
                            </Icon>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button style={{ color: theme.palette.common.white }} onClick={this.props.onClose}>
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
            );
        }

        if (errorType === 'ACCOUNT_BANNED') {
            return (
                <Dialog open={open} fullWidth classes={{ paper: classes.paperRed }}>
                    <DialogTitle disableTypography>
                        <Typography variant="h5" style={{ fontWeight: 300, color: theme.palette.common.white }}>
                            {loc('errorDialog13', lang)}
                        </Typography>
                    </DialogTitle>
                    <DialogContent style={{ paddingBottom: theme.spacing.unit }}>
                        <DialogContentText style={{ color: theme.palette.common.white }}>
                            {loc('errorDialog14', lang)}{' '}
                            <a
                                href={`mailto:${process.env.REACT_APP_SERVICE_EMAIL_ADDRESS}`}
                                style={{ color: theme.palette.common.white }}
                            >
                                {process.env.REACT_APP_SERVICE_EMAIL_ADDRESS}
                            </a>{' '}
                            {loc('errorDialog15', lang)}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button style={{ color: theme.palette.common.white }} onClick={this.props.onClose}>
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
            );
        }

        return (
            <Dialog open={open} fullWidth classes={{ paper: classes.paperRed }}>
                <DialogTitle disableTypography>
                    <Typography variant="h5" style={{ fontWeight: 300, color: theme.palette.common.white }}>
                        {loc('networkError', lang)}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText style={{ color: theme.palette.common.white }}>
                        {errorType === 'PAYLOAD_TOO_LARGE'
                            ? this.Get_PAYLOAD_TOO_LARGE_errorMessage(oversizedFileSize, lang)
                            : this.getErrorType(errorType, lang)}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button style={{ color: theme.palette.common.white }} onClick={this.props.onClose}>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(styles)(withTheme()(HttpErrorDialog));
