import { useContext, useState } from 'react';

import _ from 'lodash';

import HttpContext from 'utils/contexts/HttpContext';
import SnackbarContext from 'components/CustomSnackbar/SnackbarContext';

function useImageUpload({ singleMode }) {
    const onSnackbar = useContext(SnackbarContext);

    const http = useContext(HttpContext);

    const [imageURLs, setImageURLs] = useState([]);
    const [imagePreviews, setImagePreviews] = useState([]);

    const [uploadingImage, setUploadingImage] = useState(false);

    const [lastImageBlob, setLastImageBlob] = useState(null);
    const [lastImagePreview, setLastImagePreview] = useState(null);

    const handleDeleteImage = idx => {
        const imageURLsClone = _.clone(imageURLs);
        const imagePreviewsClone = _.clone(imagePreviews);

        const newImageURLs = imageURLsClone.slice(0, idx).concat(imageURLsClone.slice(idx + 1, imageURLsClone.length));
        const newImagePreviews = imagePreviewsClone
            .slice(0, idx)
            .concat(imagePreviewsClone.slice(idx + 1, imagePreviewsClone.length));

        setImageURLs(newImageURLs);
        setImagePreviews(newImagePreviews);
    };

    const handleAddImage = (data, preview = null) => {
        if (singleMode) {
            setImageURLs([data]);
            setImagePreviews([preview]);
            return;
        }
        const imageURLsClone = _.clone(imageURLs);
        const imagePreviewsClone = _.clone(imagePreviews);

        imageURLsClone.push(data);
        imagePreviewsClone.push(preview);

        setImageURLs(imageURLsClone);
        setImagePreviews(imagePreviewsClone);

        setLastImageBlob(data);
        setLastImagePreview(preview);
    };

    const handleUploadImage = async (image, { urlPrefix = '' }) => {
        // Only pushes to image previews atm
        // Should convert handleAddImage to use this instead
        // urlPrefix appends a string within the url generated

        setUploadingImage(true);

        const formData = new FormData();
        formData.append('image', image);
        formData.append('other', JSON.stringify({ urlPrefix }));

        const res = await http.postJSON(`/system/uploadImage`, formData, false, true);
        const newImg = _.get(res, 'data.imageUrl');

        if (res.ok && newImg) {
            const imagePreviewsClone = _.clone(imagePreviews);
            imagePreviewsClone.push(newImg);

            setLastImageBlob(image);
            setLastImagePreview(newImg);

            setImagePreviews(imagePreviewsClone);
        } else {
            onSnackbar('An error occurred while uploading the image.');
        }

        setUploadingImage(false);

        return newImg;
    };

    const resetImages = () => {
        setImageURLs([]);
        setImagePreviews([]);
    };

    return {
        imageURLs,
        imagePreviews,
        lastImageBlob,
        lastImagePreview,
        setImageURLs,
        setImagePreviews,
        uploadingImage,
        handleDeleteImage,
        handleAddImage,
        handleUploadImage,
        resetImages
    };
}

export default useImageUpload;
