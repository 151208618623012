import React, { useMemo, useEffect, useContext } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';
import { _time, _user } from 'std';
import { MenuItem, withTheme } from '@material-ui/core';

import { useState } from 'react';

import useTable from 'components/Table/useTable';
import DashBoardTable from 'components/Table/DashBoardTable';

import useDateRangePicker from 'components/DateTimePickersTz/hooks/useDateRangePicker';
import DateRangePicker from 'components/DateTimePickersTz/DateRangePicker';

import { TableRow, TableCell, Paper, Grid, Menu, IconButton, Icon, Button, TextField } from '@material-ui/core';

import { Icon as MDIcon } from '@mdi/react';
import * as allIcons from '@mdi/js';

import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery';

import MultiSelect from 'components/InputComponents/MultiSelect';
import HttpContext from 'utils/contexts/HttpContext';

const headers = [
    { name: 'Name', key: 'customer.name.first' },
    { name: 'Code', key: 'customer.uniqueID' },
    { name: 'Address', key: 'customer.location.description' },
    { name: 'Business Name', key: 'customer.location.buisnessName' },
    { name: 'Phone', key: 'customer.phone' },
    { name: 'Email', key: 'customer.email' },
    { name: 'Type', key: 'type' },
    { name: 'On Site', key: 'onsite' },
    { name: 'Usage', key: 'usage' },
    { name: 'Pickups', key: 'pickups' },
    { name: 'Last Date', key: 'date' },
    { name: 'Next Date', key: 'nextDate' },
    { name: 'Lost Fee', key: 'lostFee' }
];

function AssetTracking({ theme }) {
    const http = useContext(HttpContext);

    const collapseSearch = useMediaQuery(theme.breakpoints.down('sm'));
    const [filtersOpen, setFiltersOpen] = useState(null);
    const [commodities, setCommodities] = useState([]);
    const [subcommodityFilter, setSubCommodityFilter] = useState([]);

    const allSubCommodities = useMemo(() => {
        return commodities.reduce((subCommodities, commodity) => {
            const commoditySubCommodities = _.get(commodity, 'subCommodities', []);
            return [...subCommodities, ...commoditySubCommodities];
        }, []);
    }, [commodities]);

    const {
        startDate,
        endDate,
        timezone,
        dateWindow,
        handleChangeStartDate,
        handleChangeEndDate,
        handleGoForwards,
        handleGoBackwards,
        handleWindowSelect
    } = useDateRangePicker({
        saveStateInURL: true,
        timezones: [process.env.REACT_APP_REGION_TIMEZONE],
        initStartVal: moment()
            .tz(process.env.REACT_APP_REGION_TIMEZONE)
            .subtract(1, 'month')
            .startOf('month'), //default to last 2 months
        initEndVal: _time.getEndOfDay(process.env.REACT_APP_REGION_TIMEZONE),
        initDateWindow: 'thisMonth'
    });

    const {
        loading,
        getData,
        response,
        page,
        rowsPerPage,
        filteredData,
        search,
        filteredHeaders,
        totalDataEntries,
        handleChange,
        handleSearch,
        handleChangeFilterHeaders,
        handleAllFilterHeaders,
        handleChangePage,
        handleChangeRowsPerPage
    } = useTable({
        url: `/pickups/assetTracking?subCommodities=${subcommodityFilter}`,
        key: 'assetTrackingEntries',
        headers: headers.map(h => h.name)
    });

    const renderRows = (row, filteredHeaders, key) => {
        const cellStyle = { whiteSpace: 'nowrap' };
        const subCommodity = _.find(allSubCommodities, { _id: row._id.subPayload });
        return (
            <TableRow key={row._id}>
                {filteredHeaders.includes('Name') && (
                    <TableCell style={cellStyle}>{_user.getNameFull(_.get(row, 'customer'))}</TableCell>
                )}
                {filteredHeaders.includes('Code') && (
                    <TableCell style={cellStyle}>{_.get(row, 'customer.uniqueID')}</TableCell>
                )}
                {filteredHeaders.includes('Address') && (
                    <TableCell style={cellStyle}>{_.get(row, 'address')}</TableCell>
                )}
                {filteredHeaders.includes('Buisness Name') && (
                    <TableCell style={cellStyle}>{_.get(row, 'businessName')}</TableCell>
                )}
                {filteredHeaders.includes('Phone') && (
                    <TableCell style={cellStyle}>
                        {_.isNil(_.get(row, 'phoneOverride'))
                            ? _.get(row, 'customer.phone')
                            : _.get(row, 'phoneOverride')}
                    </TableCell>
                )}
                {filteredHeaders.includes('Email') && (
                    <TableCell style={cellStyle}>{_.get(row, 'customer.email')}</TableCell>
                )}
                {filteredHeaders.includes('Type') && (
                    <TableCell style={cellStyle}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span style={{ marginRight: theme.spacing.unit }}>
                                <MDIcon
                                    path={_.get(allIcons, convertToImportName(_.get(subCommodity, 'iconName')))}
                                    size={1}
                                    color={_.get(subCommodity, 'color')}
                                />
                            </span>
                            {_.get(subCommodity, 'name.en')}
                        </div>
                    </TableCell>
                )}
                {filteredHeaders.includes('On Site') && <TableCell style={cellStyle}>{_.get(row, 'onSite')}</TableCell>}
                {filteredHeaders.includes('Usage') && <TableCell style={cellStyle}>{_.get(row, 'usage')}</TableCell>}
                {filteredHeaders.includes('Pickups') && (
                    <TableCell style={cellStyle}>{_.get(row, 'pickups')}</TableCell>
                )}
                {filteredHeaders.includes('Last Date') && (
                    <TableCell style={cellStyle}>{formatDate(_.get(row, 'lastDate'), timezone)}</TableCell>
                )}
                {filteredHeaders.includes('Next Date') && (
                    <TableCell style={cellStyle}>{formatDate(_.get(row, 'nextDate'), timezone)}</TableCell>
                )}
                {filteredHeaders.includes('Lost Fee') && (
                    <TableCell style={cellStyle}>{!_.isNil(_.get(subCommodity, 'fee')) ? 'Yes' : ''}</TableCell>
                )}
            </TableRow>
        );
    };
    const renderSearch = children => {
        if (collapseSearch) {
            return (
                <Menu open={Boolean(filtersOpen)} anchorEl={filtersOpen} onClose={handleFiltersOpen(true)}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            padding: theme.spacing.unit * 2
                        }}
                    >
                        {children}
                    </div>
                </Menu>
            );
        } else {
            return (
                <div
                    style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'space-between' }}
                >
                    {/* <div>{customSearch}</div> */}
                    <div style={{ display: 'flex', alignItems: 'center' }}>{children}</div>
                </div>
            );
        }
    };

    const handleSubmit = e => {
        getData();
        handleSearch(e);
        if (filtersOpen) {
            setFiltersOpen(false);
        }
    };

    const inputStyles = {
        margin: theme.spacing.unit / 2
    };

    const handleFiltersOpen = close => e => {
        if (close || Boolean(filtersOpen)) {
            setFiltersOpen(null);
        } else {
            setFiltersOpen(e.currentTarget);
        }
    };

    useEffect(() => {
        (async () => {
            const res = await http.getJSON('/commodities/getAllCommodities');
            if (res.ok) {
                setCommodities(_.get(res, 'data.collectionData', []));
            }
        })();
    }, []);

    useEffect(() => {
        setSubCommodityFilter(
            allSubCommodities
                .filter(subCommodity =>
                    _.get(subCommodity, 'name.en', '')
                        .toLowerCase()
                        .includes('bin')
                )
                .map(subCommodity => subCommodity._id)
        );
    }, [allSubCommodities]);

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <div style={{ padding: theme.spacing.unit * 2, paddingBottom: theme.spacing.unit }}>
                        <Paper
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                padding: theme.spacing.unit * 2,
                                height: '100%',
                                flexWrap: 'wrap'
                            }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <DateRangePicker
                                    timezone={timezone}
                                    startDate={startDate}
                                    endDate={endDate}
                                    window={dateWindow}
                                    disabled={loading}
                                    handlePrevious={handleGoBackwards}
                                    handleNext={handleGoForwards}
                                    handleChangeStartDate={handleChangeStartDate}
                                    handleChangeEndDate={handleChangeEndDate}
                                />
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexWrap: 'wrap'
                                }}
                            >
                                <form onSubmit={handleSubmit}>
                                    {renderSearch(
                                        <>
                                            <>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        flexWrap: 'wrap'
                                                    }}
                                                >
                                                    <TextField
                                                        select
                                                        variant="outlined"
                                                        value={dateWindow}
                                                        disabled={loading}
                                                        onChange={handleWindowSelect}
                                                        style={{
                                                            maxWidth: '250px',
                                                            margin: theme.spacing.unit
                                                        }}
                                                    >
                                                        <MenuItem value="today">Daily</MenuItem>
                                                        <MenuItem value="thisWeek">Weekly</MenuItem>
                                                        <MenuItem value="thisMonth">Monthly</MenuItem>
                                                        <MenuItem value="thisQuarter">Quarterly</MenuItem>
                                                        <MenuItem value="allTime">All Time</MenuItem>
                                                    </TextField>
                                                </div>
                                                <MultiSelect
                                                    variant="outlined"
                                                    selectedValues={subcommodityFilter}
                                                    selectableValues={allSubCommodities.map(subCommodity => ({
                                                        name: _.get(subCommodity, 'name.en'),
                                                        value: subCommodity._id,
                                                        obj: subCommodity
                                                    }))}
                                                    onChange={value => setSubCommodityFilter(value)}
                                                    style={{ marginRight: theme.spacing.unit }}
                                                    renderValue={v => `${v.length} Sub-Commodities`}
                                                    renderMenuItem={({ name, value, obj: subCommodity }) => {
                                                        return (
                                                            <MenuItem key={subCommodity._id} value={subCommodity._id}>
                                                                <span style={{ marginRight: theme.spacing.unit }}>
                                                                    <MDIcon
                                                                        path={_.get(
                                                                            allIcons,
                                                                            convertToImportName(
                                                                                _.get(subCommodity, 'iconName')
                                                                            )
                                                                        )}
                                                                        size={1}
                                                                        color={_.get(subCommodity, 'color')}
                                                                    />
                                                                </span>
                                                                {_.get(subCommodity, 'name.en')}
                                                            </MenuItem>
                                                        );
                                                    }}
                                                />
                                                <TextField
                                                    variant="outlined"
                                                    name="search"
                                                    value={search || ''}
                                                    onChange={handleChange}
                                                    placeholder="Search"
                                                    style={{
                                                        ...inputStyles,
                                                        width: 250
                                                    }}
                                                />

                                                {
                                                    <Button
                                                        color="primary"
                                                        disabled={loading}
                                                        variant="contained"
                                                        data-cy="dashboard-search-button"
                                                        type="submit"
                                                        onClick={handleSubmit}
                                                    >
                                                        <Icon>search</Icon>
                                                    </Button>
                                                }
                                            </>
                                        </>
                                    )}

                                    {collapseSearch && (
                                        <div style={{ textAlign: 'right' }}>
                                            <IconButton onClick={handleFiltersOpen()}>
                                                <Icon>filter_list</Icon>
                                            </IconButton>
                                        </div>
                                    )}
                                </form>
                            </div>
                        </Paper>
                    </div>
                </Grid>
            </Grid>
            <DashBoardTable
                title="Asset Tracking"
                loading={loading}
                headers={headers}
                filteredHeaders={filteredHeaders}
                length={totalDataEntries}
                page={page}
                rowsPerPage={rowsPerPage}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                refresh={getData}
            >
                {filteredData &&
                    filteredData.map((row, key) => {
                        return renderRows(row, filteredHeaders, key);
                    })}
            </DashBoardTable>
        </>
    );
}

export default withTheme()(AssetTracking);

function formatDate(date, timezone) {
    if (_.isNil(date)) {
        return '';
    } else {
        return moment(date)
            .tz(timezone)
            .format('MMM D, YYYY');
    }
}

function convertToImportName(iconName) {
    return _.camelCase(`mdi-${iconName}`);
}
