import React, { useContext } from 'react';

import { Typography, Paper, withTheme } from '@material-ui/core';

import * as terms from 'localizations/terms';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

function NewsLetterDisplay({ referralLinkComponent, theme }) {
    const { lang } = useContext(LocalizationContext);
    return (
        <>
            <Typography variant="body1" style={{ marginBottom: theme.spacing.unit }}>
                {loc('growthNewsLetters1', lang)}
            </Typography>

            <Paper style={{ padding: theme.spacing.unit }}>
                <Typography variant="body1">
                    <b>{loc('growthNewsLetters2', lang, { brand: process.env.REACT_APP_BRAND_NAME })}</b>
                    <br />
                    <br />
                    {loc('growthNewsLetters3', lang, { brand: process.env.REACT_APP_BRAND_NAME })}
                    <ol>
                        <li>
                            {loc('growthNewsLetters4', lang)}{' '}
                            <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                                {referralLinkComponent}
                            </div>
                        </li>
                        <li>{loc('growthNewsLetters5', lang)}</li>
                        <li>{loc('growthNewsLetters6', lang)}</li>
                    </ol>
                    {loc('growthNewsLetters7', lang, {
                        brand: process.env.REACT_APP_BRAND_NAME,
                        organization: terms.ORGANIZATION_NAME.toLowerCase()
                    })}
                </Typography>
            </Paper>
        </>
    );
}

export default withTheme()(NewsLetterDisplay);
