import _ from 'lodash';
import moment from 'moment-timezone';

import * as colors from '@material-ui/core/colors';

export function getDriversOnDate(drivers, filterDate) {
    if (_.isNil(drivers) || _.isEmpty(drivers)) {
        return [];
    }

    return _.filter(
        drivers,
        driver =>
            // moment(driver.date)
            //     .tz(_.get(driver, 'timezone', process.env.REACT_APP_REGION_TIMEZONE)) // TODO: include timezone in every driver and mutate date accordingly
            //     .startOf('day')
            //     .isSame(filterDate)
            moment(driver.date)
                .tz(_.get(driver, 'timezone', process.env.REACT_APP_REGION_TIMEZONE)) // TODO: include timezone in every driver and mutate date accordingly
                .startOf('day')
                .format('DD-MM-YYYY HH:mm:ss') === filterDate.format('DD-MM-YYYY HH:mm:ss')
        //for some reason 'isSame' was failing for some regions in australia
    );
}

const colorsFiltered = _.pick(colors, ['blue', 'pink', 'teal', 'deepOrange', 'purple', 'amber', 'cyan', 'lightGreen']);
const keys = _.keys(colorsFiltered);

export function colorLegs(trip, incompleteColor = colors.blue[500], completeColor = colors.blueGrey[500]) {
    const legs = trip.route.legs.map(leg => {
        let color = incompleteColor;

        if (leg.pickup_id) {
            const foundPickup = _.find(trip.pickups, p => p._id === _.get(leg, 'pickup_id', ''));

            if (_.get(foundPickup, 'skipped', true)) color = completeColor;
        }

        leg.color = color;

        return leg;
    });

    return { ...trip.route, legs };
}

export function addColorsToTrips(trips = []) {
    trips.forEach((trip, index) => {
        const color = colorsFiltered[keys[index % (keys.length - 1)]];

        trip.color = color[500];
        _.set(trip, 'route.color', color[500]);

        if (trip.route.success) {
            colorLegs(trip, color[500], color[200]);
        }
    });
}
