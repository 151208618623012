import React, { useState } from 'react';
import _ from 'lodash';

import {
    List,
    ListItemAvatar,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    Icon,
    Divider,
    withTheme,
    IconButton,
    Typography,
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    TextField,
    DialogTitle,
    Grow,
    DialogContentText
} from '@material-ui/core';
import { useFormik } from 'formik';
import * as allIcons from '@mdi/js';
import { Icon as MDIcon } from '@mdi/react';

import { ACCOUNT_TYPE_SELECTIONS, AVAILABLE_LANGS } from '../../../constants';

import * as Yup from 'yup';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { useContext } from 'react';

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import { getTextInput, getSelect } from 'components/CRUDTables/helperFunctions';

import ExternalLink from 'components/ExternalLink/ExternalLink';

import { useMemo } from 'react';
import ConfirmDialogContext from 'components/Dialogs/Confirm/ConfirmDialogContext';

function SubServicesList({
    allFees,
    allIcons,
    allIconNames,
    service,
    serviceFormik,
    subServices = [],
    editDisabled,
    onChange,
    theme,
    pickupTypes
}) {
    const [formOpen, setFormOpen] = useState(false);
    const [indexToEdit, setIndexToEdit] = useState(null);
    const [selectedLang, setSelectedLang] = useState('en');
    const [helpDialogOpen, setHelpDialogOpen] = useState(false);
    const warnAction = useContext(ConfirmDialogContext);

    const { lang } = useContext(LocalizationContext);

    const availableLangs = AVAILABLE_LANGS[process.env.REACT_APP_REGION_EXT];

    const handleRemove = i => {
        let newSubServices = _.cloneDeep(subServices);
        newSubServices.splice(i, 1);
        onChange(newSubServices);
    };

    const handleAddEdit = values => {
        values.mdiIcon = convertToExportName(values.mdiIcon);
        if (_.isNil(indexToEdit)) {
            const newSubServices = [...subServices, values];
            onChange(newSubServices);
        } else {
            let newSubServices = _.cloneDeep(subServices);
            newSubServices[indexToEdit] = values;
            onChange(newSubServices);
        }
        setFormOpen(false);
    };

    const handleEditOpen = index => {
        setFormOpen(true);
        setIndexToEdit(index);
    };

    const handleCreateOpen = index => {
        setFormOpen(true);
        setIndexToEdit(null);
    };

    const languageToDisplay = editDisabled ? lang : selectedLang;

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ width: '60%', display: 'flex' }}>
                    <Typography variant="caption">Icon</Typography>
                    <Typography variant="caption" style={{ marginLeft: theme.spacing.unit * 8 }}>
                        Name
                    </Typography>
                </div>

                <div style={{ width: '40%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography variant="caption">Unit</Typography>
                    {/* <IconButton onClick={() => setHelpDialogOpen(true)} style={{ padding: 5 }}>
                        <Icon>help</Icon>
                    </IconButton> */}
                    {!editDisabled && (
                        <ToggleButtonGroup
                            value={selectedLang}
                            exclusive
                            onChange={(e, lang) => !_.isEmpty(lang) && setSelectedLang(lang)}
                            style={{ marginRight: theme.spacing.unit * 2 }}
                        >
                            {availableLangs.map(lang => (
                                <ToggleButton key={lang} value={lang}>
                                    {lang}
                                </ToggleButton>
                            ))}
                        </ToggleButtonGroup>
                    )}
                </div>
            </div>
            <List>
                {subServices.map((row, i) => (
                    <div key={row.name}>
                        <Divider />
                        <ListItem
                            style={{
                                paddingLeft: 0,
                                paddingRight: 0,
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center', width: '60%' }}>
                                <ListItemAvatar>
                                    <MDIcon path={_.get(allIcons, row.mdiIcon)} size={1} />
                                </ListItemAvatar>
                                <ListItemText
                                    style={{ paddingLeft: theme.spacing.unit * 6 }}
                                    primary={_.get(row, `text.${languageToDisplay}.title`, '')}
                                    primaryTypographyProps={{
                                        'data-cy': `subservice-${i}-title`,
                                        style: { fontSize: '80%' }
                                    }}
                                />
                            </div>
                            <ListItemSecondaryAction
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: '40%'
                                }}
                            >
                                <ListItemText
                                    style={{ marginLeft: theme.spacing.unit / 2 }}
                                    primary={_.get(row, `associatedAccountTypes`, '').join(', ')}
                                    primaryTypographyProps={{
                                        'data-cy': `subservice-${i}-associated-accountTypes`,
                                        style: { fontSize: '80%' }
                                    }}
                                />
                                {!editDisabled && (
                                    <IconButton data-cy={`subservice-${i}-edit`} onClick={() => handleEditOpen(i)}>
                                        <Icon>edit</Icon>
                                    </IconButton>
                                )}

                                {!editDisabled && (
                                    <IconButton
                                        data-cy={`subservice-${i}-delete`}
                                        onClick={() => {
                                            warnAction(() => {
                                                return handleRemove(i);
                                            }, 'Are you sure you want delete this sub-service?');
                                        }}
                                    >
                                        <Icon>delete</Icon>
                                    </IconButton>
                                )}
                            </ListItemSecondaryAction>
                        </ListItem>
                    </div>
                ))}
                {!editDisabled && (
                    <>
                        <Divider />
                        <Button
                            variant="outlined"
                            color="primary"
                            style={{ width: '100%', marginTop: theme.spacing.unit }}
                            onClick={handleCreateOpen}
                        >
                            <Icon>add_circle</Icon> Add
                        </Button>
                    </>
                )}
            </List>
            {formOpen && (
                <SubServicesForm
                    values={_.get(subServices, `[${indexToEdit}]`, null)}
                    open={formOpen}
                    onClose={() => setFormOpen(false)}
                    onSubmit={handleAddEdit}
                    theme={theme}
                    pickupTypes={pickupTypes}
                />
            )}
            <Dialog open={helpDialogOpen} onClose={() => setHelpDialogOpen(false)}>
                <DialogContent>
                    <DialogContentText>
                        Subservices are used to differentiate between user location types.
                    </DialogContentText>
                    <DialogContentText>
                        *Only the first service with subservices will be used to display location type selections.
                        Subsequent subservices are ignored.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setHelpDialogOpen(false)}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

function SubServicesForm({ values, open, onClose, onSubmit, theme, pickupTypes }) {
    const availableLangs = useMemo(() => AVAILABLE_LANGS[process.env.REACT_APP_REGION_EXT].sort(), []);
    const formik = useFormik({
        initialValues: {
            ...values,
            pickupType: _.get(values, 'pickupType', ''),
            text: _.get(values, 'text', { en: { title: '', secondaryTitle: '' } }),
            icon: _.get(values, 'icon', ''),
            mdiIcon: convertToImportName(_.get(values, 'mdiIcon', '')),
            associatedAccountTypes: _.get(values, 'associatedAccountTypes', [])
        },
        validationSchema: Yup.object({
            pickupType: Yup.string().required('You must enter a type for this sub-service'),
            text: Yup.object().required('You must enter a name for this sub-service'),
            associatedAccountTypes: Yup.array(),
            mdiIcon: Yup.string().required()
        }),
        onSubmit
    });

    const formInputStyle = { marginTop: theme.spacing.unit };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogContent>
                <DialogTitle style={{ padding: 0 }}>Edit Sub-Service</DialogTitle>
                {getSelect(theme, 'pickupType', 'Service Type', pickupTypes, formik)}
                <div
                    style={{
                        ...formInputStyle,
                        position: 'relative',
                        padding: '18.5px 14px',
                        borderStyle: 'solid',
                        borderWidth: '1px',
                        borderRadius: '4px 4px 4px 4px',
                        borderColor: 'rgba(0, 0, 0, 0.23)'
                    }}
                >
                    <div
                        style={{
                            paddingLeft: 4,
                            paddingRight: 4,
                            position: 'absolute',
                            top: -10,
                            backgroundColor: theme.palette.background.paper,
                            fontFamily: theme.typography.subtitle2.fontFamily,
                            fontSize: '12px',
                            color: theme.palette.grey[600]
                        }}
                    >
                        Title
                    </div>
                    {availableLangs.map(lang => (
                        <TextField
                            data-cy={`localized-input-${_.kebabCase('Title')}-edit`}
                            key={lang}
                            style={{ marginBottom: theme.spacing.unit }}
                            fullWidth
                            multiline
                            label={lang}
                            value={_.get(formik.values, `text.${lang}.title`)}
                            name={lang}
                            onChange={e => formik.setFieldValue(`text.${lang}.title`, e.target.value)}
                        />
                    ))}
                </div>
                {getSelect(
                    theme,
                    'associatedAccountTypes',
                    'Associated Account Types',
                    _.values(ACCOUNT_TYPE_SELECTIONS),
                    formik,
                    null,
                    null,
                    null,
                    true
                )}
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        verticalAlign: 'middle'
                    }}
                >
                    <tr
                        style={{
                            verticalAlign: 'middle'
                        }}
                    >
                        <td
                            style={{
                                lineHeight: 0,
                                width: 32,
                                color: 'white'
                            }}
                        >
                            <MDIcon
                                path={_.get(allIcons, convertToExportName(_.get(formik.values, 'mdiIcon')))}
                                size={2}
                            />
                        </td>
                        <td>{getTextInput(theme, 'mdiIcon', 'Icon', formik)}</td>
                    </tr>
                </div>
                <div style={{ textAlign: 'right' }}>
                    <ExternalLink text="Click here to see all icons" url="https://pictogrammers.com/library/mdi/" />
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button data-cy="edit-sub-service-ok" onClick={formik.handleSubmit}>
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default withTheme()(SubServicesList);

function convertToImportName(iconName) {
    return _.kebabCase(`${iconName.substring(3)}`);
}

const convertToExportName = icon => {
    if (_.isNil(icon)) {
        return icon;
    }
    if (icon.startsWith('mdi')) {
        return _.camelCase(`${icon}`);
    } else {
        return _.camelCase(`mdi-${icon}`);
    }
};
