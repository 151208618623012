import { useState, useEffect } from 'react';

import _ from 'lodash';

import useQueryString from 'utils/hooks/useQueryString';
import { getQueryStringValue } from 'utils/query';

function isValidCollector(collectorArr, collector) {
    if (_.isNil(collector)) return false; // cannot be set to null
    if (collector === 'all' && collectorArr.length !== 1) return true;
    return collectorArr.map(c => c._id.toString()).includes(collector.toString());
}

function useCollectorDropDown({
    collectors = [],
    showAll = true,
    stateURLKey = 'collector',
    saveStateInURL,
    initialCollector
}) {
    const [collector, setCollector] = useQueryString(
        stateURLKey,
        getInitialCollectorState(collectors, showAll, initialCollector, stateURLKey),
        saveStateInURL,
        collector => {
            return isValidCollector(collectors, collector);
        }
    );

    useEffect(() => {
        // if collectors passed in changes, need to determine if were initialized properly
        if (!isValidCollector(collectors, collector)) {
            const collectorFromQuery = getQueryStringValue(stateURLKey);
            if (
                !_.isEmpty(collectorFromQuery) &&
                (_.isEmpty(collectors) || collectors.map(c => c._id.toString()).includes(collectorFromQuery.toString()))
            ) {
                setCollector(collectorFromQuery);
            } else {
                setCollector(getInitialCollectorState(collectors, showAll, initialCollector));
            }
        }
    }, [collectors, initialCollector]);

    useEffect(() => {
        if (!_.isNil(initialCollector)) {
            setCollector(initialCollector._id);
        }
    }, [initialCollector]);

    const handleChange = e => {
        const val = e.target.value;
        setCollector(val);
    };

    const collectorObj = _.first(
        _(collectors)
            .filter(c => c._id.toString() === collector)
            .value()
    );

    return {
        showAll,
        collector,
        collectorObj,
        handleChange
    };
}

export default useCollectorDropDown;

function getInitialCollectorState(collectors, showAll, collector = null, stateURLKey) {
    if (_.isEmpty(collectors)) {
        return null;
    }
    if (
        showAll &&
        collectors &&
        collectors.length !== 1 &&
        (!collector ||
            collector === 'all' ||
            !collectors.map(c => c._id.toString()).includes(_.get(collector, '_id', collector).toString()))
    ) {
        return 'all';
    }
    if (collectors && collectors.length === 1) {
        return _.first(collectors)._id;
    }
    if (collector) {
        return collector._id;
    }
    return _.first(collectors)._id;
}
