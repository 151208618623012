import React from 'react';

import _ from 'lodash';

import { Icon, withTheme, Typography, Paper, Divider, CircularProgress } from '@material-ui/core';

import { List, ListItem, ListItemText } from '@material-ui/core';

function CustomerFeedbackWidget(props) {
    const { customerFeedback, theme, loading, style } = props;
    const getSentiment = ratio => {
        if (ratio <= 0.2) {
            return 'sentiment_very_dissatisfied';
        }
        if (ratio <= 0.4) {
            return 'sentiment_dissatisfied';
        }
        if (ratio <= 0.6) {
            return 'sentiment_neutral';
        }
        if (ratio <= 0.8) {
            return 'sentiment_satisfied';
        }
        if (ratio > 0.8) {
            return 'sentiment_very_satisfied';
        }
        return 'sentiment_neutral';
    };
    const listItemStyles = { width: 125, marginTop: 0, marginBottom: 0, paddingTop: 0, paddingBottom: 0 };

    return (
        <Paper
            style={{
                marginTop: theme.spacing.unit * 2,
                paddingTop: theme.spacing.unit * 2,
                paddingBottom: theme.spacing.unit,
                ...style
            }}
        >
            <Typography variant="h6" style={{ margin: theme.spacing.unit * 2, marginTop: 0 }}>
                Customer Feedback
            </Typography>
            <Divider />
            {loading ? (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: theme.spacing.unit,
                        marginBottom: theme.spacing.unit
                    }}
                >
                    <CircularProgress />
                </div>
            ) : (
                <List style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
                    <ListItem style={listItemStyles}>
                        <div style={{ textAlign: 'center', justifyContent: 'center' }}>
                            <ListItemText
                                primary="On Time"
                                secondary={`${(customerFeedback.onTimeRatio * 100).toFixed(2)}%`}
                                style={{ paddingRight: 0 }}
                            />
                            <Icon color={customerFeedback.onTimeRatio > 0.6 ? 'primary' : 'secondary'}>
                                {getSentiment(customerFeedback.onTimeRatio)}
                            </Icon>
                        </div>
                    </ListItem>
                    {process.env.REACT_APP_REGION_EXT !== 'EXP' && (
                        <ListItem style={listItemStyles}>
                            <div style={{ textAlign: 'center', justifyContent: 'center' }}>
                                <ListItemText
                                    primary="Left Bags"
                                    secondary={`${(customerFeedback.leftBagsRatio * 100).toFixed(2)}%`}
                                    style={{ paddingRight: 0 }}
                                />
                                <Icon color={customerFeedback.leftBagsRatio > 0.6 ? 'primary' : 'secondary'}>
                                    {getSentiment(customerFeedback.leftBagsRatio)}
                                </Icon>
                            </div>
                        </ListItem>
                    )}
                    <ListItem style={listItemStyles}>
                        <div style={{ textAlign: 'center', justifyContent: 'center' }}>
                            <ListItemText
                                primary="Professional"
                                secondary={`${(customerFeedback.professionalRatio * 100).toFixed(2)}%`}
                                style={{ paddingRight: 0 }}
                            />
                            <Icon color={customerFeedback.professionalRatio > 0.6 ? 'primary' : 'secondary'}>
                                {getSentiment(customerFeedback.professionalRatio)}
                            </Icon>
                        </div>
                    </ListItem>
                </List>
            )}
        </Paper>
    );
}

export default withTheme()(CustomerFeedbackWidget);
