import { useState } from 'react';

function useCustomDebugDialog() {
    const [open, setOpen] = useState(false);
    const [json, setJson] = useState({});

    const handleDebugDialog = (obj) => {
        setJson(obj);
        setOpen(true);
    };

    const handleCloseDebugDialog = () => {
        setOpen(false);
    };

    return {
        open,
        json,
        handleCloseDebugDialog,
        handleDebugDialog
    };
}

export default useCustomDebugDialog;