import React from 'react';
import { withTheme } from '@material-ui/core/styles';

const CustomWrapper = ({ theme, title, children, titleFontSize = '0.875rem', style = {} }) => {
    return (
        <div
            style={{
                marginTop: 10,
                position: 'relative',
                border: '1px solid',
                borderColor: theme.palette.grey[400],
                borderRadius: 4,
                padding: theme.spacing.unit,
                ...style
            }}
        >
            <div
                style={{
                    paddingLeft: 4,
                    paddingRight: 4,
                    position: 'absolute',
                    top: -10,
                    backgroundColor: theme.palette.background.paper,
                    fontFamily: theme.typography.subtitle2.fontFamily,
                    fontSize: titleFontSize,
                    color: theme.palette.grey[600]
                }}
            >
                {title}
            </div>
            {children}
        </div>
    );
};

export default withTheme()(CustomWrapper);
