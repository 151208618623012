import React, { useState, useContext } from 'react';
import _ from 'lodash';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    FormControl,
    Select,
    MenuItem,
    Checkbox,
    ListItemText,
    TextField,
    OutlinedInput,
    withTheme
} from '@material-ui/core';

import HttpContext from 'utils/contexts/HttpContext';
import SnackbarContext from 'components/CustomSnackbar/SnackbarContext';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

function GasPriceDialog(props) {
    const { open, collectors, onReloadData, onClose, theme } = props;

    const http = useContext(HttpContext);
    const onSnackbar = useContext(SnackbarContext);
    const { lang } = useContext(LocalizationContext);

    const [collectorsSelected, setCollectorsSelected] = useState(collectors.map(collector => collector._id));
    const [gasPrice, setGasPrice] = useState('');

    const handleSubmit = async () => {
        const gasPriceCents = _.round(gasPrice * 100);
        const res = await http.post('/collector/updateGasPrice', {
            collectorIds: collectorsSelected,
            gasPrice: gasPriceCents
        });
        if (res.ok) {
            onSnackbar('Successfully updated gas prices on collectors.');
            onClose();
            onReloadData();
        }
    };

    const handleSelectAll = e => {
        setCollectorsSelected(collectors.map(collector => collector._id));
        e.stopPropagation();
    };

    const handleSelectNone = e => {
        setCollectorsSelected([]);
        e.stopPropagation();
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
            <DialogTitle>Update gas prices</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <FormControl fullWidth>
                        <Select
                            fullWidth
                            input={<OutlinedInput fullWidth />}
                            multiple
                            onChange={e => setCollectorsSelected(e.target.value)}
                            value={collectorsSelected}
                            renderValue={s => `${s.length} Collectors`}
                        >
                            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                <Button onClick={handleSelectAll}>All</Button>
                                <Button onClick={handleSelectNone}>None</Button>
                            </div>
                            {collectors.map(({ _id, name }) => (
                                <MenuItem key={_id} value={_id}>
                                    <Checkbox checked={collectorsSelected.includes(_id)} />
                                    <ListItemText>{name}</ListItemText>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <TextField
                        value={gasPrice}
                        onChange={e => setGasPrice(e.target.value)}
                        type="number"
                        error={_.isNil(gasPrice) || _.isEmpty(gasPrice)}
                        label="Gas Price ($/L)"
                        style={{ marginTop: theme.spacing.unit * 2 }}
                        variant="outlined"
                        helperText={(_.isNil(gasPrice) || _.isEmpty(gasPrice)) && 'You must enter a value'}
                        fullWidth
                    />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>{loc('close', lang)}</Button>
                <Button
                    disabled={_.isNil(gasPrice) || _.isEmpty(gasPrice) || _.isEmpty(collectorsSelected)}
                    onClick={handleSubmit}
                >
                    {loc('submit', lang)}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default withTheme()(GasPriceDialog);
