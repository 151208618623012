import React, { useEffect, useContext } from 'react';
import _ from 'lodash';

import DepositBreakdown from 'components/Tables/DepositBreakdown';
import ReferralReport from 'components/Tables/ReferralsReport';
import RedemptionsReport from 'components/Tables/RedemptionsReport';
import ChequeReport from 'components/Tables/ChequeReport';

import { withTheme } from '@material-ui/core';

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import { Paper } from '@material-ui/core';
import { useState } from 'react';

function Quickbooks(props) {
    const { theme } = props;

    const [view, setView] = useState('depositBreakdowns');

    return (
        <>
            <div style={{ padding: theme.spacing.unit * 2, display: 'flex', justifyContent: 'center' }}>
                <ToggleButtonGroup value={view} exclusive onChange={(e, name) => setView(_.isNil(name) ? view : name)}>
                    <ToggleButton value="depositBreakdowns">Deposit Breakdowns</ToggleButton>
                    <ToggleButton value="referralReport">Referral Report</ToggleButton>
                    <ToggleButton value="redemptionsReport">Redemptions Report</ToggleButton>
                    <ToggleButton value="cheques">Cheques</ToggleButton>
                </ToggleButtonGroup>
            </div>
            {view === 'depositBreakdowns' && <DepositBreakdown />}
            {view === 'referralReport' && <ReferralReport />}
            {view === 'redemptionsReport' && <RedemptionsReport />}
            {view === 'cheques' && <ChequeReport />}
        </>
    );
}

export default withTheme()(Quickbooks);
