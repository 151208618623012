import React, { useState } from 'react';

import { Dialog, Link, withTheme } from '@material-ui/core';
import ImageViewer from 'components/ImageUploads/ImageViewer';

function DriverCommentImage(props) {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <Link
                alt={props.alt}
                underline="always"
                onClick={() => setIsOpen(true)}
                style={{ color: props.theme.palette.envColor[500], cursor: 'pointer' }}
            >
                {props.alt}
            </Link>
            <Dialog open={isOpen} fullWidth onClose={() => setIsOpen(false)}>
                <ImageViewer
                    displayImages={[props.src]}
                    disableDelete={true}
                    scrollerStyles={{ maxWidth: 420, overflowX: 'auto' }}
                />
            </Dialog>
        </>
    );
}

export default withTheme()(DriverCommentImage);
