import React, { Component } from 'react';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withTheme } from '@material-ui/core/styles';

class Error extends Component {
    render() {
        const { theme, header, description } = this.props;

        return (
            <Paper
                style={{
                    width: `calc(100% - ${theme.spacing.unit * 4}px)`,
                    margin: theme.spacing.unit * 2,
                    padding: theme.spacing.unit * 4
                }}
            >
                <Typography variant="h4" color="error">
                    {header}
                </Typography>
                <Typography variant="subtitle1" color="error">
                    {description}
                </Typography>
            </Paper>
        );
    }
}

export default withTheme()(Error);
