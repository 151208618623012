import React, { Component } from 'react';

import _ from 'lodash';
import moment from 'moment-timezone';

import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import { withTheme } from '@material-ui/core/styles';

import * as terms from 'localizations/terms';
import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc, locDate } from '../../../localizations/localizationHandler';
import { isEXPRegion } from 'utils/misc';

class LastCompletedSubwidget extends Component {
    static contextType = LocalizationContext;
    render() {
        const { theme, lastCompletedItem, clicksAllowed } = this.props;

        const { lang } = this.context;

        return (
            <div
                id="last-pickup-subwidget"
                data-cy="last-pickup-subwidget"
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            >
                <div style={{ paddingLeft: theme.spacing.unit * 2, paddingRight: theme.spacing.unit * 2 }}>
                    <Typography>
                        {loc('pickupWidgets24', lang, {
                            pickup:
                                lastCompletedItem.type +
                                (isEXPRegion() ? ' ' + loc('pickupWidgetsExpQuickDropAlt', lang).toLowerCase() : ''),
                            date: locDate(lastCompletedItem.date, 'MMM Do', lang)
                        })}
                    </Typography>
                </div>
                <div
                    style={{
                        borderLeft: '1px solid ' + theme.palette.text.hint,
                        paddingLeft: theme.spacing.unit,
                        paddingRight: theme.spacing.unit
                    }}
                >
                    <IconButton disabled={!clicksAllowed} onClick={this.props.onGoToHistory}>
                        <Icon>history</Icon>
                    </IconButton>
                </div>
            </div>
        );
    }
}

export default withTheme()(LastCompletedSubwidget);
