import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash';

import { formatAsCurrency, isEXPRegion } from '../../utils/misc';

import { withTheme } from '@material-ui/core/styles';

import FeeForm from './Forms/FeeForm';
import CRUDTable from './CRUDTable';

import useCRUD from './hooks/useCRUD';

import HttpContext from 'utils/contexts/HttpContext';

import { Icon as MDIcon } from '@mdi/react';
import * as allIcons from '@mdi/js';
import { Button, Icon } from '@material-ui/core';
import FeeGroupForm from './Forms/FeeGroupForm';

function FeesCRUDTable(props) {
    const { theme, operator, onSnackbar } = props;

    const http = useContext(HttpContext);

    const feeEndpoints = {
        getEndPoint: '/fees/getAllFees', // url to get all documents
        getEditEndPoint: _id => `/fees/${_id}/updateFee`, // url to edit documents NOTE: this should be a function
        createEndPoint: `/fees/createFee` // url to add new documents
    };

    const groupEndpoints = {
        getEndPoint: '/feeGroups/getAllFeeGroups', // url to get all documents
        getEditEndPoint: _id => `/feeGroups/${_id}/updateFeeGroup`, // url to edit documents NOTE: this should be a function
        createEndPoint: `/feeGroups/createFeeGroup` // url to add new documents
    };

    const [allCommodities, setAllCommodities] = useState([]);

    const [selectedFeeDocument, setSelectedFeeDocument] = useState(null);
    const [editFeeDialogOpen, setEditFeeDialogOpen] = useState(false);
    const [activeFeeOrderBy, setActiveFeeOrderBy] = useState(null);
    const [activeFeeOrder, setActiveFeeOrder] = useState('asc');

    const {
        data: feeData,
        startDateFilterStats: feeStartDateFilterStats,
        endDateFilterStats: feeEndDateFilterStats,
        loading: feeLoading,
        setStartDateFilterStats: feeSetStartDateFilterStats,
        setEndDateFilterStats: feeSetEndDateFilterStats,
        dateFilterStatsErrorMessage: feeDateFilterStatsErrorMessage,
        defaults: feeDefaults,
        handleCreate: handleFeeCreate,
        handleEdit: handleFeeEdit
    } = useCRUD({
        endpoints: feeEndpoints,
        setEditDialogOpen: setEditFeeDialogOpen,
        setActiveOrderBy: setActiveFeeOrderBy,
        setActiveOrder: setActiveFeeOrder,
        http,
        onSnackbar
    });

    const [selectedGroupDocument, setSelectedGroupDocument] = useState(null);
    const [editGroupDialogOpen, setEditGroupDialogOpen] = useState(false);
    const [activeGroupOrderBy, setActiveGroupOrderBy] = useState(null);
    const [activeGroupOrder, setActiveGroupOrder] = useState('asc');

    const {
        data: groupData,
        startDateFilterStats: groupStartDateFilterStats,
        endDateFilterStats: groupEndDateFilterStats,
        loading: groupLoading,
        setStartDateFilterStats: groupSetStartDateFilterStats,
        setEndDateFilterStats: groupSetEndDateFilterStats,
        dateFilterStatsErrorMessage: groupDateFilterStatsErrorMessage,
        defaults: groupDefaults,
        handleCreate: handleGroupCreate,
        handleEdit: handleGroupEdit
    } = useCRUD({
        endpoints: groupEndpoints,
        setEditDialogOpen: setEditGroupDialogOpen,
        setActiveOrderBy: setActiveGroupOrderBy,
        setActiveOrder: setActiveGroupOrder,
        http,
        onSnackbar
    });

    const feeColumns = [
        {
            key: 'iconName',
            header: 'Icon',
            formatValue: (value, document) => (
                <MDIcon path={_.get(allIcons, value)} color={_.get(document, 'color')} size={1} />
            )
        },
        { key: 'description', header: 'Description' },
        { key: 'amount', header: 'Amount', formatValue: value => formatAsCurrency(value) },
        { key: 'contractorShare', header: 'Contractor Share (proportion)' },
        { key: 'tax.type', header: 'Tax Type' },
        { key: 'tax.value', header: 'Tax Value (proportion)' }
    ];

    const groupColumns = [
        { key: 'name', header: 'Name' },
        { key: 'fees', header: 'Fees Applied', formatValue: fees => fees.map(fee => fee.description).join(' - ') }
    ];

    const feeEditForm = (
        <FeeForm
            defaults={feeDefaults}
            fee={selectedFeeDocument}
            open={editFeeDialogOpen}
            allCommodities={allCommodities}
            onClose={() => setEditFeeDialogOpen(false)}
            onSubmit={_.isNil(selectedFeeDocument) ? handleFeeCreate : handleFeeEdit}
        />
    );

    const groupEditForm = (
        <FeeGroupForm
            defaults={groupDefaults}
            fees={_.filter(feeData, (fee) => !_.get(fee, 'disabled', false))}
            feeGroup={selectedGroupDocument}
            open={editGroupDialogOpen}
            onClose={() => setEditGroupDialogOpen(false)}
            onSubmit={_.isNil(selectedGroupDocument) ? handleGroupCreate : handleGroupEdit}
            onSnackbar={onSnackbar}
        />
    );

    const crudFunctions = !isEXPRegion() && (
        <>
            <Button
                style={{ margin: theme.spacing.unit }}
                variant="outlined"
                onClick={() => {
                    setSelectedGroupDocument(null);
                    setEditGroupDialogOpen(true);
                }}
                color="primary"
                data-cy="crud-table-add-new"
            >
                <Icon>add_circle_outline</Icon>
                Add Group
            </Button>
            <Button
                style={{ margin: theme.spacing.unit }}
                variant="outlined"
                onClick={() => {
                    setSelectedFeeDocument(null);
                    setEditFeeDialogOpen(true);
                }}
                color="primary"
                data-cy="crud-table-add-new"
            >
                <Icon>add_circle_outline</Icon>
                Add Fee
            </Button>
        </>
    );

    useEffect(() => {
        const fetchCommodities = async () => {
            const commodityRes = await http.getJSON('/commodities/getAllEnabledCommodities');
            if (commodityRes.ok) {
                setAllCommodities(commodityRes.data.commodities);
            }
        };

        fetchCommodities();
    }, []);

    return (
        <>
            <CRUDTable
                operator={operator}
                columns={groupColumns}
                data={groupData}
                editForm={groupEditForm}
                documentIsDisabled={group => _.get(group, 'disabled', false)}
                defaultRowsPerPage={5}
                startDateFilterStats={groupStartDateFilterStats}
                setStartDateFilterStats={groupSetStartDateFilterStats}
                endDateFilterStats={groupEndDateFilterStats}
                setEndDateFilterStats={groupSetEndDateFilterStats}
                dateFilterStatsErrorMessage={groupDateFilterStatsErrorMessage}
                selectedDocument={selectedGroupDocument}
                setSelectedDocument={setSelectedGroupDocument}
                editDialogOpen={editGroupDialogOpen}
                setEditDialogOpen={setEditGroupDialogOpen}
                activeOrderBy={activeGroupOrderBy}
                setActiveOrderBy={setActiveGroupOrderBy}
                activeOrder={activeGroupOrder}
                setActiveOrder={setActiveGroupOrder}
                enabledHeaderText={'Fee Groups'}
                disabledHeaderText={'Disabled Fee Groups'}
                loading={groupLoading}
                hideDateFilters
                headerRightContent={crudFunctions}
                hideAddButton
                hideEditButton={isEXPRegion()}
            />

            <CRUDTable
                operator={operator}
                columns={feeColumns}
                data={feeData}
                editForm={feeEditForm}
                documentIsDisabled={fee => _.get(fee, 'disabled', false)}
                defaultRowsPerPage={5}
                startDateFilterStats={feeStartDateFilterStats}
                setStartDateFilterStats={feeSetStartDateFilterStats}
                endDateFilterStats={feeEndDateFilterStats}
                setEndDateFilterStats={feeSetEndDateFilterStats}
                dateFilterStatsErrorMessage={feeDateFilterStatsErrorMessage}
                selectedDocument={selectedFeeDocument}
                setSelectedDocument={setSelectedFeeDocument}
                editDialogOpen={editFeeDialogOpen}
                setEditDialogOpen={setEditFeeDialogOpen}
                activeOrderBy={activeFeeOrderBy}
                setActiveOrderBy={setActiveFeeOrderBy}
                activeOrder={activeFeeOrder}
                setActiveOrder={setActiveFeeOrder}
                enabledHeaderText={'Fees'}
                disabledHeaderText={'Disabled Fees'}
                loading={feeLoading}
                hideDateFilters
                hideAddButton
                hideHeaderBar
                hideEditButton={isEXPRegion()}
            />
        </>
    );
}

export default withTheme()(FeesCRUDTable);
