import { useState, useMemo, useCallback, useEffect } from 'react';

import _ from 'lodash';

function useDriverSelection(pickups, route) {
    const [pickupIdSelected, setPickupIdSelected] = useState(null);

    const handlePickupClick = useCallback(
        (pickup, shiftPressed) => () => {
            setPickupIdSelected(pickup._id);
        },
        []
    );

    const resetPickupIdSelected = () => {
        setPickupIdSelected(null);
    };

    const getNextPickup = pickup_id => {
        const { pickups: routedPickups } = route;
        if (route && routedPickups) {
            const indexOfCurrent = routedPickups.findIndex(p => p._id === pickup_id);
            if (!_.isEmpty(pickups) && pickup_id !== pickups[0]._id) {
                setPickupIdSelected(pickups[0]._id);
            } else if (indexOfCurrent > -1 && indexOfCurrent < routedPickups.length - 1) {
                const nextPickupIndex = indexOfCurrent + 1;
                setPickupIdSelected(routedPickups[nextPickupIndex]._id);
            }
        } else {
            if (_.isEmpty(pickups)) {
                setPickupIdSelected(null);
            } else if (_.isNil(pickupIdSelected) || !pickups.map(p => p._id).includes(pickupIdSelected)) {
                setPickupIdSelected(_.first(pickups)._id);
            }
        }
    };

    const pickupSelected = useMemo(() => _.find(pickups, p => p._id === pickupIdSelected) || {}, [
        pickups,
        pickupIdSelected
    ]);

    useEffect(() => {
        if (_.isEmpty(pickups)) {
            setPickupIdSelected(null);
        } else if (_.isNil(pickupIdSelected) || !pickups.map(p => p._id).includes(pickupIdSelected)) {
            setPickupIdSelected(_.first(pickups)._id);
        }
    }, [pickups]);

    return { pickupSelected, handlePickupClick, resetPickupIdSelected, getNextPickup };
}

export default useDriverSelection;
