import { useState } from 'react';

import _ from 'lodash';

function useCustomSnackbar() {
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarVariant, setSnackbarVariant] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState(undefined);
    const [snackbarDuration, setSnackbarDuration] = useState(
        JSON.parse(process.env.REACT_APP_AUTOMATED_TESTER_MODE) ? 1000 : 5000
    );

    const handleSnackbar = (message, variant = 'success', duration) => {
        if (_.isNil(message)) {
            setSnackbarOpen(false);
        } else {
            setSnackbarOpen(true);
            setSnackbarVariant(variant);
            setSnackbarMessage(message);
            setSnackbarDuration(duration);
        }
    };

    return { snackbarOpen, snackbarVariant, snackbarMessage, snackbarDuration, handleSnackbar };
}

export default useCustomSnackbar;
