import React, { useState, useRef, useEffect } from 'react';
import _ from 'lodash';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { withTheme } from '@material-ui/core/styles';

import {
    FormControl,
    Dialog,
    DialogActions,
    DialogContent,
    Button,
    TextField,
    Typography,
    colors,
    Grid
} from '@material-ui/core';

import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import useWindowSize from 'utils/hooks/useWindowSize';
function EnterEmailDialog({ theme, open, onClose, emailDialogMode = 'eTransfer', handleSubmitEmailReceipt }) {
    const [width, height] = useWindowSize();
    let mobileMode = width <= 600;
    let showKeyboard = !mobileMode && JSON.parse(localStorage.getItem('hideKeyboard')) !== true;

    const [email, setEmail] = useState('');
    const [warning, setWarning] = useState('invalid email');
    const [layout, setLayout] = useState('default');
    const keyboard = useRef();

    const handleShift = () => {
        const newLayoutName = layout === 'default' ? 'shift' : 'default';
        setLayout(newLayoutName);
    };
    const handleAccept = email => {
        handleSubmitEmailReceipt(true, email, emailDialogMode === 'donate');
    };
    const handleEnter = () => {
        if (_.isEmpty(warning)) {
            handleAccept(email);
        }
    };
    function changeEmail(e) {
        let newEmail = e.target.value;
        if (emailDialogMode === 'eTransfer') {
            if (!isValidEmail(newEmail)) {
                setWarning('invalid email');
            } else {
                setWarning('');
            }
        } else {
            if (newEmail !== '' && !isValidEmail(newEmail)) {
                setWarning('invalid email');
            } else {
                setWarning('');
            }
        }
        setEmail(newEmail);
    }
    const onKeyPress = button => {
        if (button === '{shift}' || button === '{lock}') handleShift();
        if (button === '{enter}') handleEnter();
    };
    useEffect(() => {
        if (emailDialogMode === 'eTransfer') {
            if (!isValidEmail(email)) {
                setWarning('invalid email');
            } else {
                setWarning('');
            }
        } else {
            if (email !== '' && !isValidEmail(email)) {
                setWarning('invalid email');
            } else {
                setWarning('');
            }
        }
    }, [emailDialogMode]);
    return (
        <Dialog open={open} onClose={onClose} fullWidth>
            <DialogContent>
                <Typography variant="h6">{'Enter Email'}</Typography>
                {emailDialogMode === 'donate' && (
                    <Typography style={{ color: colors.grey[700] }}>{'Enter an optional email for receipt'}</Typography>
                )}
                <FormControl fullWidth>
                    <TextField
                        label={!_.isEmpty(warning) ? 'Please enter a valid email address' : 'Email'}
                        error={!_.isEmpty(warning)}
                        value={email}
                        onChange={e => {
                            changeEmail(e);
                            if (showKeyboard && !_.isNil(keyboard.current)) {
                                keyboard.current.setInput(e.target.value);
                            }
                        }}
                        onKeyDown={e => {
                            if (e.key === 'Enter' && !_.isEmpty(warning)) handleAccept(email);
                        }}
                        variant="outlined"
                        fullWidth
                        autoFocus
                        style={{ marginTop: theme.spacing.unit * 2 }}
                    />
                </FormControl>

                {showKeyboard && (
                    <Grid item sm={12} xs={12} style={{ marginTop: 5 }}>
                        <Keyboard
                            keyboardRef={r => (keyboard.current = r)}
                            layoutName={layout}
                            onChange={input => {
                                let e = { target: { value: input } };
                                changeEmail(e);
                            }}
                            onKeyPress={onKeyPress}
                        />
                    </Grid>
                )}
            </DialogContent>
            <DialogActions>
                <Button color="default" size="small" onClick={onClose} data-cy="por-screen-warning-dialog-cancel">
                    {'nevermind'}
                </Button>
                <Button
                    color="primary"
                    size="small"
                    disabled={!_.isEmpty(warning)}
                    onClick={() => {
                        handleAccept(email);
                    }}
                    data-cy="por-screen-warning-dialog-confirm"
                >
                    {'confirm'}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
export default withMobileDialog({ breakpoint: 'xs' })(withTheme()(EnterEmailDialog));

function isValidEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}
