import React from 'react';
import _ from 'lodash';
import { DialogActions, DialogContent, Button } from '@material-ui/core';
import CustomFormTitle from 'components/MaterialUIExtensions/CustomFormTitle';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc, locDate } from 'localizations/localizationHandler';
import { useContext } from 'react';

import ImageCapture from 'components/ImageUploads/ImageCapture';

const PictureConfirmation = ({
    pickupForm,
    imageURLs,
    imagePreviews,
    uploadingImage,
    handleUploadImage,
    handleDeleteImage,
    onSnackbar,
    onBack,
    onNext,
    activeStep,
    prevStep
}) => {
    const { lang } = useContext(LocalizationContext);

    const payloadImageConfirmation = _.get(pickupForm, 'zone.collector.payloadImageConfirmation');
    const payloadImageConfirmationRequired =
        pickupForm.zone &&
        !_.isNil(payloadImageConfirmation) &&
        payloadImageConfirmation.enabled &&
        pickupForm.payload.beverage >= payloadImageConfirmation.minimum;

    return (
        <>
            <DialogContent>
                <CustomFormTitle titleText={loc('pickupDialog36', lang)} iconName="camera" />
                <ImageCapture
                    uploadingImage={uploadingImage}
                    imageURLs={imagePreviews}
                    handleAddImage={handleUploadImage}
                    handleDeleteImage={handleDeleteImage}
                    placeholderText={
                        <span>
                            {loc(payloadImageConfirmationRequired ? 'pickupDialog37' : 'pickupDialog39', lang)}
                            <br />
                            {payloadImageConfirmationRequired && loc('pickupDialog38', lang)}
                        </span>
                    }
                    onSnackbar={onSnackbar}
                />
            </DialogContent>
            <DialogActions style={{ marginBottom: 'max(8px, env(safe-area-inset-bottom, 8px))' }}>
                <Button color="primary" onClick={onBack}>
                    Back
                </Button>
                <Button
                    color="primary"
                    onClick={onNext}
                    data-cy="picture-confirmation-next-button"
                    disabled={
                        uploadingImage ||
                        (_.isEmpty(imagePreviews) &&
                            !JSON.parse(process.env.REACT_APP_AUTOMATED_TESTER_MODE) &&
                            payloadImageConfirmationRequired)
                    }
                >
                    Next
                </Button>
            </DialogActions>
        </>
    );
};

export default PictureConfirmation;
