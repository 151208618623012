import React from 'react';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { withTheme } from '@material-ui/core/styles';

function Disconnected(props) {
    const { theme } = props;

    return (
        <>
            <Typography style={{ padding: theme.spacing.unit * 2 }}>
                Someone has logged into your account from another device. Only one connection at a time can be
                maintained. If you wish to disconnect them and use current device, click Reconnect.
            </Typography>

            <Button
                variant="contained"
                color="primary"
                style={{ marginLeft: theme.spacing.unit * 2 }}
                onClick={() => window.location.reload(true)}
            >
                Reconnect
            </Button>
        </>
    );
}

export default withTheme()(Disconnected);
