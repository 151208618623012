import React from 'react';

import _ from 'lodash';

import { Typography, withTheme } from '@material-ui/core';

function InlineChip({ theme, text, color, textColor, onClick, style }) {
    return (
        <span
            onClick={onClick}
            style={{
                background: color,
                padding: '2px 4px',
                borderRadius: 4,
                display: 'inline-block',
                ...style
            }}
        >
            <Typography
                style={{
                    color: !_.isNil(textColor) ? textColor : theme.palette.getContrastText(color),
                    display: 'inline',
                    fontSize: theme.typography.fontSize * 0.875
                }}
            >
                {text}
            </Typography>
        </span>
    );
}

export default withTheme()(InlineChip);
