import React, { useState } from 'react';
import _ from 'lodash';
import ConfirmationDialog from './ConfirmationDialog';

function useConfirmationDialog({
    content,
    onConfirm,
    onCancel,
    showLoader,
    closeOnConfirm = true,
    confirmText = 'Yes',
    cancelText = 'Cancel'
}) {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleConfirmPressed = async () => {
        setLoading(true);
        await onConfirm();
        if (closeOnConfirm) {
            setOpen(false);
        }
        setLoading(false);
    };

    const confirmationDialog = (
        <ConfirmationDialog
            showLoader={showLoader}
            confirmText={confirmText}
            cancelText={cancelText}
            content={content}
            loading={loading}
            onCancel={onCancel}
            onConfirm={handleConfirmPressed}
            open={open}
            onClose={() => setOpen(false)}
        />
    );

    return {
        confirmationDialog,
        handleOpen: setOpen,
        open
    };
}

export default useConfirmationDialog;
