import { useEffect, useState } from 'react';

const useGetMetaData = ({ http }) => {
    const [commoditiesAvailable, setCommoditiesAvailable] = useState([]);

    useEffect(() => {
        (async () => {
            const commoditiesRes = await http.getJSON('/commodities/getAllCommodities');
            if (commoditiesRes.ok) {
                setCommoditiesAvailable(commoditiesRes.data.collectionData);
            }
        })();
    }, []);

    return {
        commoditiesAvailable
    };
};

export default useGetMetaData;
