import React, { Component, useContext } from 'react';

import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import { withTheme } from '@material-ui/core/styles';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc, locDate } from '../../../localizations/localizationHandler';

class UncountedPickupSubwidget extends Component {
    static contextType = LocalizationContext;

    render() {
        const { theme, clicksAllowed, commodityUOM = 'Bag', daysToCount } = this.props;

        const { lang } = this.context;

        return (
            <div
                id="uncounted-pickup-subwidget"
                data-cy="uncounted-pickup-subwidget"
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            >
                <div
                    style={{
                        paddingLeft: theme.spacing.unit * 2,
                        paddingRight: theme.spacing.unit * 2,
                        overflow: 'hidden'
                    }}
                >
                    <Typography>{loc('pickupWidgets11', lang, { commodityUOM, daysToCount })}</Typography>
                </div>
                <div
                    style={{
                        borderLeft: '1px solid ' + theme.palette.text.hint,
                        paddingLeft: theme.spacing.unit,
                        paddingRight: theme.spacing.unit
                    }}
                >
                    <IconButton disabled={!clicksAllowed} onClick={this.props.onUncountedDialog(true)}>
                        <Icon>info</Icon>
                    </IconButton>
                </div>
            </div>
        );
    }
}

export default withTheme()(UncountedPickupSubwidget);
