import React, { useContext } from 'react';

import { containsErrors } from '../../../utils/validate';

import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import CustomFormTitle from 'components/MaterialUIExtensions/CustomFormTitle';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

const Cheque = ({ theme, redemptionForm, handleChange, handleBack, handleNext, formErrors }) => {
    const { lang } = useContext(LocalizationContext);
    return (
        <>
            <DialogContent>
                <CustomFormTitle titleText={loc('redemption30', lang)} iconName="account_balance_wallet" />
                <DialogContentText>{loc('redemption31', lang)}</DialogContentText>
                <FormControl fullWidth style={{ marginTop: theme.spacing.unit * 3 }}>
                    <TextField
                        data-cy="accountName"
                        name="accountName"
                        label={loc('redemption32', lang)}
                        variant="outlined"
                        value={redemptionForm.accountName}
                        onChange={handleChange}
                        helperText={formErrors['accountName'].reason}
                        error={formErrors['accountName'].fail}
                    />
                </FormControl>
                <FormControl fullWidth style={{ marginTop: theme.spacing.unit * 3 }}>
                    <TextField
                        data-cy="street"
                        name="street"
                        label={loc('redemption33', lang)}
                        variant="outlined"
                        value={redemptionForm.street}
                        onChange={handleChange}
                        helperText={formErrors['street'].reason}
                        error={formErrors['street'].fail}
                    />
                </FormControl>
                <FormControl fullWidth style={{ marginTop: theme.spacing.unit * 3 }}>
                    <TextField
                        data-cy="city"
                        name="city"
                        label={loc('redemption34', lang)}
                        variant="outlined"
                        value={redemptionForm.city}
                        onChange={handleChange}
                        helperText={formErrors['city'].reason}
                        error={formErrors['city'].fail}
                    />
                </FormControl>
                <FormControl fullWidth style={{ marginTop: theme.spacing.unit * 3 }}>
                    <TextField
                        data-cy="postal-code"
                        name="postalCode"
                        label={loc('redemption35', lang)}
                        variant="outlined"
                        value={redemptionForm.postalCode}
                        onChange={handleChange}
                        helperText={formErrors['postalCode'].reason}
                        error={formErrors['postalCode'].fail}
                    />
                </FormControl>
                <FormControl fullWidth style={{ marginTop: theme.spacing.unit * 3 }}>
                    <TextField
                        data-cy="province"
                        name="province"
                        label={loc('redemption67', lang)}
                        variant="outlined"
                        value={redemptionForm.province}
                        onChange={handleChange}
                        helperText={formErrors['province'].reason}
                        error={formErrors['province'].fail}
                    />
                </FormControl>
                <FormControl fullWidth style={{ marginTop: theme.spacing.unit * 3 }}>
                    <TextField
                        data-cy="country"
                        name="country"
                        label={loc('redemption36', lang)}
                        variant="outlined"
                        value={redemptionForm.country}
                        onChange={handleChange}
                        helperText={formErrors['country'].reason}
                        error={formErrors['country'].fail}
                    />
                </FormControl>
            </DialogContent>
            <DialogActions style={{ marginBottom: 'max(8px, env(safe-area-inset-bottom, 8px))' }}>
                <Button color="primary" onClick={handleBack}>
                    {loc('back', lang)}
                </Button>
                <Button
                    data-cy="redemption-dialog-next"
                    color="primary"
                    disabled={containsErrors(formErrors)}
                    onClick={handleNext}
                >
                    {loc('next', lang)}
                </Button>
            </DialogActions>
        </>
    );
};

export default Cheque;
