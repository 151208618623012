import React, { useMemo, memo, useContext } from 'react';

import _ from 'lodash';
import moment from 'moment-timezone';
import { _bulk, _user, _commodity } from 'std';

import { formatAsAddress, isEXPRegion, getQuickDropName, getWalkInName, isCONRegion } from 'utils/misc';

import { colors, Tooltip } from '@material-ui/core';
import { Icon as MDIcon } from '@mdi/react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import Typography from '@material-ui/core/Typography';

import * as terms from 'localizations/terms';

import { withTheme } from '@material-ui/core/styles';

import {
    mdiTruck,
    mdiTagArrowDown,
    mdiStore,
    mdiCashEdit,
    mdiQrcodeScan,
    mdiCurrencyUsd,
    mdiAlertOutline
} from '@mdi/js';
import * as allIcons from '@mdi/js';

import InlineChip from 'components/Chips/InlineChip';

import bulkHelper from 'helpers/bulkHelper';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { getComEquivalent } from 'containers/Operators/PORScreen/NumberOfBagsDialog';

const tileSize = 54;
const iconOffset = 12;

const CURRENT_DAY = moment(new Date());

const BulkCard = memo(({ data, index, style }) => {
    const { theme, items, history, commodityColors, operator, resetTimeoutCount, commodities } = data;
    const { lang } = useContext(LocalizationContext);

    const counter = () => {
        let counter;

        if (isEXPRegion()) {
            counter = bulkHelper.getCountedBy(bulk);
        }

        if (!_.isEmpty(counter)) return counter;

        if (_bulk.getMostRecentCounter(bulk) === 'N/A') {
            counter = _bulk.getDriverName(bulk);
        } else {
            counter = _bulk.getMostRecentCounter(bulk);
        }

        return counter;
    };

    const bulk = items[index];

    const rawAddress = _.get(bulk, 'pickup.location', null);
    const address = !_.isNil(rawAddress) ? formatAsAddress(rawAddress) : 'Unknown address';
    const inProgress = _bulk.isCounted(bulk) && !_bulk.isCompleted(bulk);

    let hideCustomerIdentification = false;
    if (operator.accountType === 'Collector Administrator' && !operator.adminView) {
        hideCustomerIdentification = _.get(operator, `collector.hideCustomerIdentification.hideForAdmin`, false);
    } else if (
        operator.accountType === 'Collector Employee' &&
        !operator.adminView &&
        operator.accountPermissions.includes('Clerk')
    ) {
        hideCustomerIdentification = _.get(operator, `collector.hideCustomerIdentification.hideForClerk`, false);
    }

    let machineCounter = '';
    const sessions = _.get(bulk, 'countingSessions', []);
    const firstSession = _.first(sessions);
    const tomraVariables = _.get(firstSession, 'tomraVariables');
    if (!_.isEmpty(tomraVariables)) {
        const machine = tomraVariables.rvmName || 'Unknown Machine Name';
        const serialNumber = tomraVariables.rvmSerialNo;
        machineCounter = `${machine.replace(' ', '')}-${serialNumber}`;
    }

    let bulkInfo;
    let bulkIcon = <MDIcon path={mdiTruck} size={0.6} color={colors.grey[500]} />;
    switch (bulk.bulkType) {
        case 'inhouse':
            bulkInfo = getQuickDropName('en');
            bulkIcon = <MDIcon path={mdiTagArrowDown} size={0.6} color={colors.grey[500]} />;
            break;
        case 'adjustment':
            bulkInfo = 'Adjustment';
            bulkIcon = <MDIcon path={mdiCashEdit} size={0.75} color={colors.grey[500]} />;
            break;
        case 'pickup':
            bulkInfo = hideCustomerIdentification ? `Pickup` : `Pickup - ${address}`;
            bulkIcon = <MDIcon path={mdiTruck} size={0.6} color={colors.grey[500]} />;
            break;
        case 'walk-in':
            bulkInfo = getWalkInName('en') + (machineCounter ? ` - [${machineCounter}]` : ` - [${counter()}]`);
            bulkIcon = isCONRegion() ? (
                <MDIcon path={mdiQrcodeScan} size={0.6} color={colors.grey[500]} />
            ) : (
                <MDIcon path={mdiStore} size={0.6} color={colors.grey[500]} />
            );
            break;
        default:
            bulkInfo = 'N/A';
    }
    const totalAmountCounted = _bulk.getTotalValue(bulk) - _bulk.getCustomFeesValue(bulk);

    let numOfSubComEquivalent = useMemo(() => getComEquivalent(bulk, commodities), [bulk, commodities]);
    let numOfSubCom = useMemo(() => getNumOfSubCom(bulk), [bulk]);
    let numOfSortedBags = _.get(
        _.find(_.get(bulk, 'commoditiesProcessedBreakdown', []), { isSubCommodity: false }),
        'amount',
        0
    );
    let numOfReportedBags = _.get(bulk, 'commodityAmount', 0) - numOfSubComEquivalent;
    let subComUnitMap = useMemo(() => {
        return getSubComUnitMap(bulk, commodities, lang);
    }, [bulk, commodities, lang]);
    let numOfProccessedSubCom = useMemo(() => getProcessedNumOfSubCom(bulk), [bulk]);
    const squareIcon = useMemo(() => {
        let totalAmount = numOfSubCom + Math.max(numOfReportedBags, numOfSortedBags);
        let unit =
            Object.keys(subComUnitMap).length === 1 && totalAmount === numOfSubCom
                ? Object.keys(subComUnitMap)[0]
                : totalAmount > 1
                ? 'items'
                : 'item';
        if (subComUnitMap === 0) {
            unit = _bulk.getCommodityUOMFormatted(bulk);
        }
        return totalAmountCounted !== 0 ? (
            <div style={{ color: theme.palette.text.primary }}>
                <span
                    style={{
                        fontSize: theme.typography.subtitle2.fontSize,
                        color: totalAmountCounted < 0 ? colors.red[500] : theme.palette.text.primary
                    }}
                >
                    {totalAmountCounted < 0 && <>-</>}$
                    {Math.abs((totalAmountCounted / 100).toFixed(totalAmountCounted > 999 ? 0 : 2))}
                </span>
                <br />
                <span
                    style={{
                        fontSize: theme.typography.caption.fontSize
                    }}
                >
                    total
                </span>
            </div>
        ) : (
            <div style={{ color: inProgress ? colors.yellow[900] : theme.palette.text.primary }}>
                <span style={{ fontSize: theme.typography.subtitle1.fontSize }}>
                    {numOfProccessedSubCom || 0}/{totalAmount > 0 ? totalAmount : '?'}
                </span>
                <br />
                <span
                    style={{
                        fontSize: theme.typography.caption.fontSize
                    }}
                >
                    {totalAmount > 0 ? unit : '?'}
                </span>
            </div>
        );
    }, [bulk, inProgress, theme, totalAmountCounted]);

    return (
        <div style={{ ...style, padding: '4px 0' }}>
            <Tooltip title={_bulk.hasErrors(bulk) ? 'Has Error' : _bulk.hasCustomerIssue(bulk) ? 'Has Complaint' : ''}>
                <Card
                    data-cy={`bulkcard-${bulk.label}-${bulk.skuType}`}
                    elevation={0}
                    style={{
                        margin: theme.spacing.unit,
                        border: '1px solid',
                        borderColor: theme.palette.grey[300],
                        maxHeight: '100%'
                    }}
                >
                    <CardActionArea
                        onClick={e => {
                            resetTimeoutCount();
                            const URL = `/operators/${operator._id}/bulks/${bulk._id}`;
                            if (e.ctrlKey) {
                                window.open(URL, '_blank');
                            } else {
                                history.push(URL);
                            }
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                padding: theme.spacing.unit,
                                paddingBottom: theme.spacing.unit
                            }}
                        >
                            <div
                                style={{
                                    border: '1px solid',
                                    borderColor: colors.grey[500],
                                    borderRadius: 4,
                                    // paddingTop: 6,
                                    height: tileSize,
                                    width: tileSize,
                                    textAlign: 'center',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                {squareIcon}
                            </div>
                            <div
                                style={{
                                    marginLeft: theme.spacing.unit,
                                    maxWidth: `calc(100% - ${tileSize + theme.spacing.unit}px )`,
                                    flex: '1'
                                }}
                            >
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <Typography
                                        variant="caption"
                                        style={{ fontWeight: 500, fontSize: '0.85rem', width: '99%' }}
                                        noWrap
                                    >
                                        {_bulk.getTruncatedLabel(bulk)}
                                        {_bulk.getCustomerUniqueID(bulk) !== _bulk.getTruncatedLabel(bulk) &&
                                            !_.isNil(bulk.owner) && (
                                                <span>
                                                    {', '}
                                                    {_bulk.getCustomerUniqueID(bulk)}.{' '}
                                                </span>
                                            )}
                                        {(_user.isSystemAdmin(operator) || _user.isInternalRole(operator)) && (
                                            <> - {_bulk.getCollectorName(bulk)}</>
                                        )}
                                        {inProgress && <span style={{ color: colors.yellow[900] }}> IN PROGRESS</span>}

                                        {bulk.isOverdue && (
                                            <span style={{ color: colors.red[600], fontWeight: 'lighter' }}>
                                                {' '}
                                                {Math.abs(moment(bulk.datePickedUp).diff(CURRENT_DAY, 'days'))} days
                                                overdue{' '}
                                            </span>
                                        )}
                                    </Typography>

                                    <div style={{ display: 'flex', flexDirection: 'row', marginRight: 5 }}>
                                        {Math.max(numOfReportedBags, numOfSortedBags) > 0 && (
                                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: -5 }}>
                                                <div
                                                    style={{
                                                        alignSelf: 'center',
                                                        justifyContent: 'center',
                                                        height: 24
                                                    }}
                                                >
                                                    <MDIcon
                                                        path={
                                                            allIcons[
                                                                _.get(
                                                                    _.find(commodities, {
                                                                        skuType: _.get(bulk, 'skuType', '')
                                                                    }),
                                                                    'iconName',
                                                                    ''
                                                                )
                                                            ]
                                                        }
                                                        size={1}
                                                        color={_.get(
                                                            _.find(commodities, {
                                                                skuType: _.get(bulk, 'skuType', '')
                                                            }),
                                                            'color',
                                                            colors.blue[300]
                                                        )}
                                                    />
                                                </div>
                                                <Typography
                                                    style={{
                                                        whiteSpace: 'nowrap',
                                                        alignSelf: 'center',
                                                        justifyContent: 'center'
                                                    }}
                                                >
                                                    {Math.max(numOfReportedBags, numOfSortedBags)}
                                                </Typography>
                                            </div>
                                        )}
                                        {Object.keys(subComUnitMap).map(key => {
                                            const { iconName, color, amount } = subComUnitMap[key];
                                            if (amount < 1) return null;
                                            return (
                                                <div style={{ display: 'flex', flexDirection: 'row', marginTop: -5 }}>
                                                    <div
                                                        style={{
                                                            alignSelf: 'center',
                                                            justifyContent: 'center',
                                                            height: 24
                                                        }}
                                                    >
                                                        <MDIcon
                                                            path={allIcons[convertToImportName(iconName)]}
                                                            size={1}
                                                            color={color}
                                                        />
                                                    </div>
                                                    <Typography
                                                        style={{
                                                            whiteSpace: 'nowrap',
                                                            alignSelf: 'center',
                                                            justifyContent: 'center'
                                                        }}
                                                    >
                                                        {amount}
                                                    </Typography>
                                                </div>
                                            );
                                        })}

                                        {_bulk.hasIssues(bulk) && (
                                            <MDIcon
                                                path={mdiAlertOutline}
                                                size={0.75}
                                                color={colors.orange[400]}
                                                title={
                                                    'Issues: ' +
                                                    _.get(bulk, 'payloadIssues.labels', ['Reported with issue']).join()
                                                }
                                            />
                                        )}
                                        {_bulk.isRedeemed(bulk) && (
                                            <MDIcon
                                                path={mdiCurrencyUsd}
                                                size={0.75}
                                                color={colors.grey[500]}
                                                title="Redeemed by the customer"
                                            />
                                        )}
                                    </div>
                                </div>

                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    {/* <div style={{ display: 'flex', alignItems: 'center' }}> */}
                                    {bulkIcon}

                                    {/* <Typography
                                        variant="caption"
                                        style={{
                                            fontWeight: 300,
                                            marginTop: 2,
                                            lineHeight: 1.15,
                                            width: '99%'
                                            // marginLeft: theme.spacing.unit / 4
                                        }}
                                        noWrap
                                    >
                                        {bulkInfo}
                                    </Typography> */}
                                    {/* </div> */}
                                    <InlineChip
                                        color={commodityColors[_.get(bulk, 'skuType')]}
                                        textColor={
                                            isLightColor(commodityColors[_.get(bulk, 'skuType')])
                                                ? theme.palette.text.primary
                                                : 'white'
                                        }
                                        text={_.get(bulk, 'skuType', '')}
                                        style={{ marginRight: theme.spacing.unit, textWrap: 'nowrap' }}
                                    />
                                </div>

                                {_bulk.isQuickDrop(bulk) ? (
                                    <Typography style={{ fontWeight: 300, width: '99%' }} variant="caption" noWrap>
                                        {_.isNil(bulk.receiver)
                                            ? `For ${_user.getNameFull(bulk.owner)} [${moment(bulk.datePickedUp)
                                                  .tz(
                                                      _.get(
                                                          bulk,
                                                          'collector.timezone',
                                                          process.env.REACT_APP_REGION_TIMEZONE
                                                      )
                                                  )
                                                  .format('MMM Do h:mm A')}]`
                                            : `Received by ${_user.getNameFirstNameAndLastInitial(
                                                  bulk.receiver
                                              )} [${moment(bulk.dateCounted)
                                                  .tz(
                                                      _.get(
                                                          bulk,
                                                          'collector.timezone',
                                                          process.env.REACT_APP_REGION_TIMEZONE
                                                      )
                                                  )
                                                  .format('MMM Do h:mm A')}]`}
                                    </Typography>
                                ) : (
                                    <>
                                        {_bulk.isCounted(bulk) || bulk.bulkType === 'walk-in' ? (
                                            <Typography
                                                style={{ fontWeight: 300, width: '99%' }}
                                                variant="caption"
                                                noWrap
                                            >
                                                Counted:{' '}
                                                {moment(bulk.dateCounted)
                                                    .tz(
                                                        _.get(
                                                            bulk,
                                                            'collector.timezone',
                                                            process.env.REACT_APP_REGION_TIMEZONE
                                                        )
                                                    )
                                                    .format('MMM Do h:mm A')}
                                            </Typography>
                                        ) : (
                                            <Typography
                                                style={{ fontWeight: 300, width: '99%' }}
                                                variant="caption"
                                                noWrap
                                            >
                                                {_bulk.getDriverName(bulk)}{' '}
                                                {!_.isNil(bulk.datePickedUp)
                                                    ? moment(bulk.datePickedUp)
                                                          .tz(
                                                              _.get(
                                                                  bulk,
                                                                  'collector.timezone',
                                                                  process.env.REACT_APP_REGION_TIMEZONE
                                                              )
                                                          )
                                                          .format('MMM Do h:mm A')
                                                    : 'N/A'}
                                            </Typography>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                        <div
                            style={{
                                backgroundColor: commodityColors[bulk.skuType] || colors.grey[500],
                                position: 'absolute',
                                height: '100%',
                                width: 8,
                                right: 0,
                                top: 0
                            }}
                        />
                    </CardActionArea>
                </Card>
            </Tooltip>
        </div>
    );
});

export default withTheme()(BulkCard);

function getNumOfSubCom(bulk) {
    const subPayloads = _.get(bulk, 'pickup.subPayloads', []).filter(
        subPayload => subPayload.skuType === _.get(bulk, 'skuType', '')
    );
    const commoditiesProcessedBreakdown = _.get(bulk, 'commoditiesProcessedBreakdown', []);
    let commodityMap = {};
    let sum = 0;
    for (let subPayload of subPayloads) {
        let subcomId = _.get(subPayload, `subCommodity`, '');
        commodityMap[subcomId] = _.get(subPayload, `amount`, 0);
    }
    for (let subPayload of commoditiesProcessedBreakdown) {
        if (_.get(subPayload, `isSubCommodity`, false)) {
            let subcomId = _.get(subPayload, `subCommodity`, '');
            if (commodityMap[subcomId] === undefined) {
                commodityMap[subcomId] = _.get(subPayload, `amount`, 0);
            } else {
                commodityMap[subcomId] = Math.max(_.get(subPayload, `amount`, 0), commodityMap[subcomId]);
            }
        }
    }
    Object.values(commodityMap).forEach(amount => {
        sum += amount;
    });
    return sum;
}

function getSubComUnitMap(bulk, commodities, lang) {
    const subPayloads = _.get(bulk, 'pickup.subPayloads', []).filter(
        subPayload => subPayload.skuType === _.get(bulk, 'skuType', '')
    );
    const commoditiesProcessedBreakdown = _.get(bulk, 'commoditiesProcessedBreakdown', []);
    if (_.isEmpty(subPayloads)) return getSubComUnitMapWalkin(bulk, commodities, lang);

    const tmpSubPayloads = _.cloneDeep(subPayloads);
    _commodity.populateSubPayloadsSubCommodities(tmpSubPayloads, commodities);

    let subComUnitMap = {};

    for (let subPayload of tmpSubPayloads) {
        const unit = _.get(subPayload, `subCommodity.units.${lang}`, null);
        const sortedSubCom = _.get(
            _.find(commoditiesProcessedBreakdown, {
                subCommodity: _.get(subPayload, `subCommodity._id`, null)
            }),
            'amount',
            0
        );
        if (unit !== null) {
            if (subComUnitMap[unit] === undefined)
                subComUnitMap[unit] = {
                    amount: Math.max(_.get(subPayload, `amount`, 0), sortedSubCom),
                    iconName: _.get(subPayload, `subCommodity.iconName`, ''),
                    color: _.get(subPayload, `subCommodity.color`, '')
                };
            else subComUnitMap[unit].amount += Math.max(_.get(subPayload, `amount`, 0), sortedSubCom);
        }
    }
    return subComUnitMap;
}
function getSubComUnitMapWalkin(bulk, commodities, lang) {
    const commoditiesProcessedBreakdown = _.get(bulk, 'commoditiesProcessedBreakdown', []);
    if (_.isEmpty(commoditiesProcessedBreakdown)) return 0;

    const tmpCommoditiesProcessedBreakdown = _.cloneDeep(commoditiesProcessedBreakdown);
    _commodity.populateSubPayloadsSubCommodities(tmpCommoditiesProcessedBreakdown, commodities);

    let subComUnitMap = {};

    for (let subPayload of tmpCommoditiesProcessedBreakdown) {
        if (_.get(subPayload, `isSubCommodity`, false)) {
            const unit = _.get(subPayload, `subCommodity.units.${lang}`, null);
            const amount = _.get(subPayload, 'amount', 0);
            if (unit !== null) {
                if (subComUnitMap[unit] === undefined)
                    subComUnitMap[unit] = {
                        amount: amount,
                        iconName: _.get(subPayload, `subCommodity.iconName`, ''),
                        color: _.get(subPayload, `subCommodity.color`, '')
                    };
                else subComUnitMap[unit].amount += Math.max(_.get(subPayload, `amount`, 0), amount);
            }
        }
    }
    return subComUnitMap;
}

function getProcessedNumOfSubCom(bulk) {
    const commoditiesProcessedBreakdown = _.get(bulk, 'commoditiesProcessedBreakdown', []);
    let sum = 0;
    for (let item of commoditiesProcessedBreakdown) {
        sum += item.amount;
    }
    return sum;
}

function convertToImportName(iconName) {
    return _.camelCase(`mdi-${iconName}`);
}

function isLightColor(color) {
    const hex = color.replace('#', '');
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;
    return brightness > 155;
}
