import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash';

import HttpContext from 'utils/contexts/HttpContext';
import SnackbarContext from 'components/CustomSnackbar/SnackbarContext';
import ConfirmDialogContext from 'components/Dialogs/Confirm/ConfirmDialogContext';

import { LinearProgress, Paper, Button, Icon, withTheme, Tooltip } from '@material-ui/core';

import RatesDisplay from 'components/RatesDisplay';
import ContainerRateForm from './ContainerRateForm';
import RatePageConfigDialog from './RatesPageConfigDialog';
import ContainerSizeDialog from './ContainerSizeDialog';
import CreateRatesDialog from './CreateRatesDialog';

import useTaxGroups from 'utils/hooks/useTaxGroups';
import { isEXPRegion } from 'utils/misc';

function Rates({ theme }) {
    const [loading, setLoading] = useState(false);
    const [masterRatesId, setMasterRatesId] = useState(null);
    const [allRates, setAllRates] = useState([]);
    const [commodities, setCommodities] = useState([]);
    const [containerSizes, setContainerSizes] = useState([]);
    const [ratesPageConfig, setRatesPageConfig] = useState([]);
    const [selectedRatesId, setSelectedRatesId] = useState(null);

    const [containerRateFormOpen, setContainerRateFormOpen] = useState(false);
    const [rateToEdit, setRateToEdit] = useState(null);
    const [skuToEdit, setSkuToEdit] = useState(null);

    const [ratesPageConfigDialogOpen, setRatesPageConfigDialogOpen] = useState(false);
    const [containerSizesDialogOpen, setContainerSizesDialogOpen] = useState(false);
    const [createRatesDialogOpen, setCreateRatesDialogOpen] = useState(false);

    const http = useContext(HttpContext);
    const onSnackbar = useContext(SnackbarContext);
    const warnAction = useContext(ConfirmDialogContext);

    const { taxGroups } = useTaxGroups();

    const loadData = async () => {
        setLoading(true);

        const [resRates, resCommodities, resContinerSizes, resRatesConfig] = await Promise.all([
            http.getJSON('/rates/allRates'),
            http.getJSON('/commodities/getAllCommodities'),
            http.getJSON('/system/containerSizes'),
            http.getJSON('/rates/ratesConfig')
        ]);

        if (resRates.ok) {
            let allRates = _.get(resRates, 'data.rates', []);
            allRates = _.sortBy(allRates, '_id');
            allRates = allRates.map(rate => {
                rate.rates = _.sortBy(rate.rates, 'skuType');
                return rate;
            });
            setAllRates(allRates);
            setMasterRatesId(_.get(resRates, 'data.masterRatesId'));
            setSelectedRatesId(_.get(resRates, 'data.masterRatesId'));
        }
        if (resCommodities.ok) {
            setCommodities(_.get(resCommodities, 'data.collectionData', []));
        }
        if (resContinerSizes.ok) {
            setContainerSizes(_.get(resContinerSizes, 'data.containerSizes'));
        }
        if (resRatesConfig.ok) {
            setRatesPageConfig(_.get(resRatesConfig, 'data.ratesConfig'));
        }
        setLoading(false);
    };

    const loadRatesPageConfig = async () => {
        const res = await http.getJSON('/rates/ratesConfig');
        if (res.ok) {
            setRatesPageConfig(res.data.ratesConfig);
        }
    };

    const handleCreateNewRate = async newRates => {
        const res = await http.post('/rates/createRates', { rates: newRates });
        if (res.ok) {
            onSnackbar('Successfully created new rates');
            loadData();
            setCreateRatesDialogOpen(false);
        }
    };

    const handleContainerRateFormEdit = (rateToEdit, skuToEdit) => {
        setRateToEdit(rateToEdit);
        setSkuToEdit(skuToEdit);
        setContainerRateFormOpen(true);
    };

    const handleContainerRateFormCreate = () => {
        setRateToEdit(null);
        setSkuToEdit(null);
        setContainerRateFormOpen(true);
    };

    const handleSubmitContainerRate = async ({ ratesIdsToAddTo, containerRate }) => {
        if (!_.isNil(rateToEdit) && !_.isNil(skuToEdit)) {
            const res = await http.post(
                `/rates/${rateToEdit._id}/updateSku`,
                { sku: skuToEdit, values: containerRate },
                true
            );
            if (res.ok) {
                onSnackbar('Successfully updated rates');
                setContainerRateFormOpen(false);
                loadData();
            } else {
                onSnackbar(res.errorMessage, 'error');
                setContainerRateFormOpen(false);
                loadData();
            }
        } else {
            //creating new container rate
            const res = await http.post(`/rates/addRateToRates`, { ratesIdsToAddTo, rate: containerRate }, true);
            if (res.ok) {
                onSnackbar('Successfully updated rates');
                setContainerRateFormOpen(false);
                loadData();
            } else {
                onSnackbar(res.errorMessage, 'error');
            }
        }
    };

    const handleUpdateRatesName = async (ratesId, name) => {
        const res = await http.post(`/rates/${ratesId}/updateName`, { name });
        if (res.ok) {
            onSnackbar('Successfully updated rates name');
            loadData();
        }
    };

    const handleDeleteRates = async ratesId => {
        const res = await http.post(`/rates/${ratesId}/deleteRates`, {}, true);
        if (res.ok) {
            onSnackbar('Successfully deleted rates');
            loadData();
        } else {
            onSnackbar(res.errorMessage, 'error');
        }
    };

    const handleDeleteContainerRate = async (rates, sku) => {
        /*const ratesCopy = _.cloneDeep(rates);
        const skuIndex = _.findIndex(ratesCopy.rates, { sku });
        ratesCopy.rates.splice(skuIndex, 1);*/
        const res = await http.post(`/rates/${rates._id}/deleteSku`, { sku }, true);
        if (res.ok) {
            onSnackbar('Successfully updated rates');
            loadData();
        } else {
            onSnackbar(res.errorMessage, 'error');
        }
    };

    const handleUpdateContainerSizes = async newContainerSizes => {
        const res = await http.post(`/system/updateContainerSizes`, { containerSizes: newContainerSizes });
        if (res.ok) {
            onSnackbar('Successfully updated container sizes');
            loadData();
            setContainerSizesDialogOpen(false);
        }
    };

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <Paper
                style={{
                    padding: theme.spacing.unit * 2,
                    paddingTop: 0,
                    margin: theme.spacing.unit * 2
                }}
            >
                {loading && <LinearProgress />}
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        paddingTop: theme.spacing.unit * 2
                    }}
                >
                    <div>
                        <Button
                            style={{ margin: theme.spacing.unit }}
                            variant="outlined"
                            onClick={() => setRatesPageConfigDialogOpen(true)}
                        >
                            Edit Public Facing Rates Display
                        </Button>
                    </div>
                    <div>
                        <Button
                            variant="outlined"
                            onClick={() => setContainerSizesDialogOpen(true)}
                            style={{ margin: theme.spacing.unit }}
                        >
                            Container Sizes
                        </Button>
                        {isEXPRegion() ? (
                            <Tooltip title="Updated via sync">
                                <span>
                                    <Button
                                        style={{ margin: theme.spacing.unit }}
                                        variant="outlined"
                                        onClick={() => {}}
                                        disabled
                                        color="primary"
                                    >
                                        <Icon style={{ marginRight: theme.spacing.unit }}>add_circle_outline</Icon>
                                        Add New Rates
                                    </Button>
                                </span>
                            </Tooltip>
                        ) : (
                            <Button
                                style={{ margin: theme.spacing.unit }}
                                variant="outlined"
                                onClick={() => setCreateRatesDialogOpen(true)}
                                color="primary"
                            >
                                <Icon style={{ marginRight: theme.spacing.unit }}>add_circle_outline</Icon>
                                Add New Rates
                            </Button>
                        )}
                        {isEXPRegion() ? (
                            <Tooltip title="Updated via sync">
                                <span>
                                    <Button
                                        style={{ margin: theme.spacing.unit }}
                                        variant="outlined"
                                        onClick={() => {}}
                                        color="secondary"
                                        disabled
                                    >
                                        <Icon style={{ marginRight: theme.spacing.unit }}>add_circle_outline</Icon>
                                        Add New Sku
                                    </Button>
                                </span>
                            </Tooltip>
                        ) : (
                            <Button
                                style={{ margin: theme.spacing.unit }}
                                variant="outlined"
                                onClick={handleContainerRateFormCreate}
                                color="secondary"
                            >
                                <Icon style={{ marginRight: theme.spacing.unit }}>add_circle_outline</Icon>
                                Add New Sku
                            </Button>
                        )}
                    </div>
                </div>
            </Paper>
            <Paper
                style={{
                    padding: theme.spacing.unit * 2,
                    margin: theme.spacing.unit * 2,
                    marginTop: 0,
                    flex: 1,
                    overflow: 'hidden'
                }}
            >
                <RatesDisplay
                    commodities={commodities}
                    defaultRateId={masterRatesId}
                    rates={allRates}
                    ratesSelectedId={selectedRatesId}
                    ratesPageConfig={ratesPageConfig}
                    // ratesEditable
                    onRatesSelected={selectedRatesId => setSelectedRatesId(selectedRatesId)}
                    onEditContainerRate={handleContainerRateFormEdit}
                    onDeleteContainerRate={handleDeleteContainerRate}
                    onUpdateRatesName={handleUpdateRatesName}
                    onDeleteRates={handleDeleteRates}
                />
            </Paper>
            {containerRateFormOpen && (
                <ContainerRateForm
                    allRates={allRates}
                    taxGroups={taxGroups}
                    open={containerRateFormOpen}
                    ratesPageConfig={ratesPageConfig}
                    containerRate={_.find(_.get(rateToEdit, 'rates', []), { sku: skuToEdit })}
                    containerSizes={containerSizes}
                    commodities={commodities}
                    onClose={() => setContainerRateFormOpen(false)}
                    onSubmitRate={handleSubmitContainerRate}
                />
            )}

            {ratesPageConfigDialogOpen && (
                <RatePageConfigDialog
                    defaultRateId={masterRatesId}
                    open={ratesPageConfigDialogOpen}
                    rates={allRates}
                    ratesPageConfig={ratesPageConfig}
                    loadRatesPageConfig={loadRatesPageConfig}
                    onClose={() => setRatesPageConfigDialogOpen(false)}
                />
            )}

            <ContainerSizeDialog
                open={containerSizesDialogOpen}
                initialContainerSizes={containerSizes}
                onUpdateSizes={handleUpdateContainerSizes}
                onClose={() => setContainerSizesDialogOpen(false)}
            />

            <CreateRatesDialog
                open={createRatesDialogOpen}
                rates={allRates}
                onClose={() => setCreateRatesDialogOpen(false)}
                onCreateRates={handleCreateNewRate}
            />
        </div>
    );
}

export default withTheme()(Rates);
