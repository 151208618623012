import React, { useContext } from 'react';

import _ from 'lodash';

import { Chip, colors } from '@material-ui/core';
import { withTheme } from '@material-ui/core';

import MDIcon from '@mdi/react';
import { mdiSprout } from '@mdi/js';

import charityTaskHelper from '../../helpers/charityTaskHelper';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

function CharityTaskCompletionChip(props) {
    const { charity, style, onCharityGrowthPlan, variant, displayFeaturedStatus, theme } = props;
    const { lang } = useContext(LocalizationContext);

    const countComplete = charityTaskHelper.numberOfTasksComplete(_.get(charity, 'charityTasks', []));
    const countCompletable = charityTaskHelper.numberOfTasksCompletable(_.get(charity, 'charityTasks', []));
    const chipColor = charity.isFeatured ? colors.green[800] : colors.red[500];
    const featuredStatus = charity.isFeatured ? loc('dashboardMain1', lang) : loc('dashboardMain12', lang);
    return (
        <>
            {charity.approved && (
                <Chip
                    variant={!_.isNil(variant) ? variant : 'outlined'}
                    label={
                        <span style={{ color: variant === 'default' ? 'white' : null }}>
                            {countComplete}/{countCompletable} {displayFeaturedStatus ? `- ${featuredStatus}` : ''}
                        </span>
                    }
                    style={{
                        borderColor: chipColor,
                        color: chipColor,
                        backgroundColor: variant === 'default' ? chipColor : null,
                        ...style
                    }}
                    icon={<MDIcon path={mdiSprout} size={1} color={variant === 'default' ? 'white' : chipColor} />}
                    onClick={
                        !_.isNil(onCharityGrowthPlan)
                            ? event => {
                                  event.stopPropagation(); //stop parent element onclick from being called
                                  onCharityGrowthPlan(true)();
                              }
                            : undefined
                    }
                />
            )}
        </>
    );
}

export default withTheme()(CharityTaskCompletionChip);
