import React from 'react';
import _ from 'lodash';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import {
    Dialog,
    DialogActions,
    DialogContent,
    Button,
    withMobileDialog,
    withTheme,
    TextField,
    DialogTitle,
    Typography
} from '@material-ui/core';

import { getSwitch } from '../helperFunctions';

import { loc } from 'localizations/localizationHandler';

// add new complaint/resolution options
function ComplaintsForm(props) {
    const {
        lang,
        initialObj,
        open,
        onClose,
        complaintsConfig,
        onSetField,
        onConfigSubmit,
        theme,
        fullScreen,
        complaintType
    } = props;

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            ...initialObj
        },
        validationSchema:
            complaintType === 'Customer'
                ? Yup.object({
                      code: Yup.string()
                          .required('Code should be auto generated')
                          .max(4, 'Must be less than 4 characters'),
                      label: Yup.string()
                          .required('Must include a label')
                          .min(5, 'Must be more than 4 characters'),
                      description: Yup.string().required('Must include a description text for customer email')
                  })
                : Yup.object({
                      code: Yup.string()
                          .required('Code should be auto generated')
                          .max(4, 'Must be less than 4 characters'),
                      label: Yup.string()
                          .required('Must include a label')
                          .min(5, 'Must be more than 4 characters')
                  }),
        onSubmit: async (values, { resetForm }) => {
            const configValues = _.pick(values, ['code', 'emailToCustomer', 'driverIssue', 'clothingBinIssue']);
            const localizationValues = _.pick(values, ['code', 'label', 'suggestions', 'description']);

            if (complaintType === 'Customer') {
                let length = _.get(complaintsConfig, 'customerComplaints.resolutions', []).length;
                let newCodeCounter = _.get(complaintsConfig, 'customerComplaints.codeCounter', 0) + 1;

                onSetField(`complaints.customerComplaints.codeCounter`, newCodeCounter);
                onSetField(`complaints.customerComplaints.resolutions[${length}]`, configValues);
                onSetField(`localization.en.complaints.customerComplaints.resolutions[${length}]`, localizationValues);
                onSetField(`localization.fr.complaints.customerComplaints.resolutions[${length}]`, localizationValues);
            } else {
                let length = _.get(complaintsConfig, `${complaintType.toLowerCase()}Complaints.options`, []).length;
                let newCodeCounter =
                    _.get(complaintsConfig, `${complaintType.toLowerCase()}Complaints.codeCounter`, 0) + 1;

                onSetField(`complaints.${complaintType.toLowerCase()}Complaints.codeCounter`, newCodeCounter);
                onSetField(`complaints.${complaintType.toLowerCase()}Complaints.options[${length}]`, configValues);
                onSetField(
                    `localization.en.complaints.${complaintType.toLowerCase()}Complaints.options[${length}]`,
                    localizationValues
                );
                onSetField(
                    `localization.fr.complaints.${complaintType.toLowerCase()}Complaints.options[${length}]`,
                    localizationValues
                );
            }

            await onConfigSubmit();
            onClose();
            resetForm();
        }
    });

    const objKeys = Object.keys(initialObj).filter(key => key !== 'sendToCustomer' && key !== 'emailToCustomer');
    const hasError = objKeys.some(
        key => _.get(formik.touched, `${key}`, false) && _.get(formik.errors, `${key}`, false)
    );

    return (
        <Dialog onClose={onClose} open={open} fullScreen={fullScreen} fullWidth>
            <DialogTitle>Add New {complaintType} Option</DialogTitle>
            <DialogContent>
                {objKeys.map((objKey, idx) => {
                    // if contain array of strings, create textfield for each element
                    if (Array.isArray(formik.values[objKey])) {
                        return formik.values[objKey].map((item, childIdx) => (
                            <div style={{ marginTop: theme.spacing.unit }} key={`${objKey} - ${idx} -${childIdx}`}>
                                <TextField
                                    {...formik.getFieldProps(`${objKey}[${childIdx}]`)}
                                    error={
                                        _.get(formik.touched, `${objKey}[${childIdx}]`, false) &&
                                        _.get(formik.errors, `${objKey}[${childIdx}]`, false)
                                            ? true
                                            : null
                                    }
                                    label={`${objKey} - field ${childIdx}`}
                                    style={{ marginTop: theme.spacing.unit }}
                                    multiline
                                    variant="outlined"
                                    helperText={
                                        _.get(formik.touched, `${objKey}[${childIdx}]`, false) &&
                                        _.get(formik.errors, `${objKey}[${childIdx}]`, false)
                                            ? _.get(formik.errors, `${objKey}[${childIdx}]`, false)
                                            : null
                                    }
                                    fullWidth
                                />
                            </div>
                        ));
                    } else {
                        return (
                            <div style={{ marginTop: theme.spacing.unit }} key={`${objKey} - ${idx}`}>
                                {objKey === 'code' ? (
                                    <Typography>Complaint Code: {formik.values[objKey]}</Typography>
                                ) : (
                                    <TextField
                                        {...formik.getFieldProps(`${objKey}`)}
                                        error={
                                            _.get(formik.touched, `${objKey}`, false) &&
                                            _.get(formik.errors, `${objKey}`, false)
                                                ? true
                                                : null
                                        }
                                        disabled={objKey === 'code'}
                                        label={objKey}
                                        style={{ marginTop: theme.spacing.unit }}
                                        multiline
                                        variant="outlined"
                                        helperText={
                                            _.get(formik.touched, `${objKey}`, false) &&
                                            _.get(formik.errors, `${objKey}`, false)
                                                ? _.get(formik.errors, `${objKey}`, false)
                                                : null
                                        }
                                        fullWidth
                                    />
                                )}
                            </div>
                        );
                    }
                })}
                {complaintType === 'Counter' && getSwitch(theme, `driverIssue`, 'Driver Issue', formik)}
                {complaintType === 'Driver' && getSwitch(theme, `clothingBinIssue`, 'Clothing Bin Issue', formik)}
                {!_.isNil(initialObj.emailToCustomer) &&
                    !formik.values.driverIssue &&
                    getSwitch(theme, `emailToCustomer`, 'Email to Customer', formik)}
                {formik.values.appliedBySystem &&
                    getSwitch(theme, `emailToCustomer`, 'Email to Customer', formik, {}, {}, () => {}, '', true)}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={formik.handleSubmit} disabled={hasError}>
                    {loc('submit', lang)}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default withMobileDialog()(withTheme()(ComplaintsForm));
