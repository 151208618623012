import React, { useState, useContext } from 'react';
import _ from 'lodash';

import { Formik, yupToFormErrors } from 'formik';
import * as Yup from 'yup';

import { withTheme } from '@material-ui/core/styles';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { Dialog, DialogTitle, DialogContent, DialogActions, LinearProgress } from '@material-ui/core';
import { Button, TextField } from '@material-ui/core';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

function PasswordResetDialog(props) {
    const { open, onClose, user, theme, fullScreen, http, onSnackbar } = props;
    const { lang } = useContext(LocalizationContext);
    const [loading, setLoading] = useState(false);

    function equalTo(ref, msg) {
        return Yup.mixed().test({
            name: 'equalTo',
            exclusive: false,
            message: msg || '${path} must be the same as ${reference}',
            params: {
                reference: ref.path
            },
            test: function(value) {
                return value === this.resolve(ref);
            }
        });
    }
    Yup.addMethod(Yup.string, 'equalTo', equalTo);

    return (
        open && (
            <Formik
                initialValues={{
                    newPassword: '',
                    confirmNewPassword: ''
                }}
                validationSchema={Yup.object({
                    newPassword: Yup.string()
                        .required('You must enter a password')
                        .min(6, 'This must be at least 6 characters long'),
                    confirmNewPassword: Yup.string().equalTo(Yup.ref('newPassword'), 'Passwords must match')
                })}
                onSubmit={async values => {
                    setLoading(true);
                    const res = await http.post(`/users/${user._id}/adminResetPassword`, {
                        password: values.newPassword
                    });
                    if (res.ok) {
                        onSnackbar('Password reset successfully');
                    } else {
                        onSnackbar('Unable to reset password', 'error');
                    }
                    onClose();
                    setLoading(false);
                }}
            >
                {formik => (
                    <Dialog open={open} fullScreen={fullScreen} onClose={onClose}>
                        <DialogTitle>
                            {`Reset 
                            ${_.get(user, 'name.first', '')} 
                            ${_.get(user, 'name.last', '')}'s 
                            Password`}
                        </DialogTitle>
                        <DialogContent>
                            <TextField
                                {...formik.getFieldProps('newPassword')}
                                type="password"
                                error={
                                    _.get(formik.touched, 'newPassword', false) &&
                                    _.get(formik.errors, 'newPassword', false)
                                        ? true
                                        : null
                                }
                                label="New Password"
                                margin="normal"
                                variant="outlined"
                                helperText={
                                    _.get(formik.touched, 'newPassword', false) &&
                                    _.get(formik.errors, 'newPassword', false)
                                        ? _.get(formik.errors, 'newPassword', false)
                                        : null
                                }
                                fullWidth
                                data-cy="password-reset-dialog-new-password"
                            />
                            <TextField
                                {...formik.getFieldProps('confirmNewPassword')}
                                type="password"
                                error={
                                    _.get(formik.touched, 'confirmNewPassword', false) &&
                                    _.get(formik.errors, 'confirmNewPassword', false)
                                        ? true
                                        : null
                                }
                                label="Confirm New Password"
                                margin="normal"
                                variant="outlined"
                                helperText={
                                    _.get(formik.touched, 'confirmNewPassword', false) &&
                                    _.get(formik.errors, 'confirmNewPassword', false)
                                        ? _.get(formik.errors, 'confirmNewPassword', false)
                                        : null
                                }
                                fullWidth
                                data-cy="password-reset-dialog-confirm-new-password"
                            />
                            {loading && <LinearProgress />}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={onClose} disabled={loading}>
                                Cancel
                            </Button>
                            <Button
                                disabled={loading}
                                onClick={formik.handleSubmit}
                                color="primary"
                                data-cy="password-reset-dialog-submit"
                            >
                                {loc('submit', lang)}
                            </Button>
                        </DialogActions>
                    </Dialog>
                )}
            </Formik>
        )
    );
}

export default withMobileDialog()(withTheme()(PasswordResetDialog));
