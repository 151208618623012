import React from 'react';
import _ from 'lodash';
import { _time } from 'std';
import moment from 'moment-timezone';
import { PDFObject } from 'react-pdfobject';

import DialogTitlePrimary from 'components/MaterialUIExtensions/DialogTitlePrimary';
import { withMobileDialog, Dialog, DialogContent } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';

import './PDFPreviewer.css';

const PDFPreviewer = ({ theme, open, onClose, pdfUrl, title = 'Depot Offloading Sheet' }) => {
    return (
        <>
            <Dialog
                fullScreen
                // fullWidth
                open={open}
                onClose={onClose}
            >
                <DialogTitlePrimary closeButtonShown onClose={onClose}>
                    {title}
                </DialogTitlePrimary>
                <DialogContent style={{ padding: 0, overflow: 'hidden' }}>
                    <PDFObject url={pdfUrl} width="100%" />
                </DialogContent>
            </Dialog>
        </>
    );
};

export default withMobileDialog({ breakpoint: 'xs' })(withTheme()(PDFPreviewer));
