import React, { useContext } from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@material-ui/core';
import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

const TripIncompleteDialog = ({ open, handleClose }) => {
    const { lang } = useContext(LocalizationContext);

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogContent>
                <DialogContentText>{loc('canOnlyClockOutWhenDone', lang)}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    {loc('dismiss', lang)}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default TripIncompleteDialog;
