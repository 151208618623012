import React, { Fragment, useState, useMemo, useContext } from 'react';

import _ from 'lodash';

import CustomFormTitle from 'components/MaterialUIExtensions/CustomFormTitle';
import TextMaskPhoneNumber from 'components/TextMasks/TextMaskPhoneNumber';

import { validate } from 'utils/validate';
import { DriverCommentMarkdownHelpertext, formatAsPhoneNumber, isMXDRegion } from 'utils/misc';
import { COMMERCIAL, RESIDENTIAL, CONDO } from 'constants.js';

import * as colors from '@material-ui/core/colors';
import {
    Button,
    TextField,
    FormControl,
    FormControlLabel,
    Switch,
    DialogContentText,
    DialogContent,
    DialogActions,
    Icon,
    IconButton,
    InputAdornment,
    Fade,
    LinearProgress,
    Collapse
} from '@material-ui/core';

import * as terms from 'localizations/terms';

import { withTheme } from '@material-ui/core/styles';
import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';
import { handlePaste } from '../../../../utils/Markdown/handlePaste';
import { containsMarkDownImage } from 'utils/Markdown/helpers';

const Comments = props => {
    const {
        http,
        theme,
        pickupForm,
        formErrors,
        inProgress,
        customer,
        toggleHelpDialog,
        togglePhoneOverride,
        onChange,
        onNext,
        onBack,
        enableCustomerPickupImageUpload,
        isSystemAdmin
    } = props;

    const { lang } = useContext(LocalizationContext);
    const { comment, phoneOverride, phone, pickupType } = pickupForm;
    const [isPreview, setIsPreview] = useState(false);

    const formContainsErrors =
        _.get(formErrors, 'comment.fail', false) || (phoneOverride && _.get(formErrors, 'phone.fail', false));

    return (
        <Fragment>
            <DialogContent>
                <CustomFormTitle titleText={loc('pickupDialog40', lang)} iconName="chat" />
                <FormControl fullWidth>
                    <TextField
                        id={'driver-comment-id'}
                        data-cy="pickupdialog-comment-input"
                        multiline
                        // rows="4"
                        name="comment"
                        label={loc('labels11', lang)}
                        disabled={!isSystemAdmin && !enableCustomerPickupImageUpload && containsMarkDownImage(comment)}
                        value={comment}
                        variant="outlined"
                        helperText={formErrors.comment.reason}
                        error={formErrors.comment.fail}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={toggleHelpDialog('comment')}
                                        data-cy="pickupdialog-comment-help"
                                    >
                                        <Icon>help</Icon>
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        onChange={onChange}
                        onPaste={async e => {
                            if (isSystemAdmin || enableCustomerPickupImageUpload) {
                                await handlePaste(e, http, onChange);
                            }
                        }}
                    />
                </FormControl>
                {isSystemAdmin && (
                    <>
                        <FormControlLabel
                            control={<Switch checked={isPreview} onChange={() => setIsPreview(!isPreview)} />}
                            label="Preview Markdown"
                        />
                        {isPreview && <DriverCommentMarkdownHelpertext source={comment} theme={props.theme} />}
                    </>
                )}

                <DialogContentText style={{ color: colors.grey[500], marginBottom: theme.spacing.unit * 2 }}>
                    {pickupType === RESIDENTIAL && (
                        <>
                            {loc('pickupDialog44aResidential', lang)}{' '}
                            {!isMXDRegion() && (
                                <b>{loc('pickupDialog44bResidential', lang, { condoName: terms.CONDO_NAME })}</b>
                            )}
                        </>
                    )}
                    {pickupType === CONDO && (
                        <>
                            {loc('pickupDialog44aCondo', lang)}{' '}
                            {!isMXDRegion() && (
                                <b>{loc('pickupDialog44bCondo', lang, { condoName: terms.CONDO_NAME })}</b>
                            )}
                        </>
                    )}
                    {pickupType === COMMERCIAL && <>{loc('pickupDialog44aCommercial', lang)}</>}
                </DialogContentText>
                <CustomFormTitle titleText={loc('pickupDialog45', lang)} iconName="phone" />
                <DialogContentText>
                    {loc('pickupDialog46', lang, { number: formatAsPhoneNumber(customer.phone) })}
                    <FormControlLabel
                        control={
                            <Switch
                                checked={phoneOverride}
                                onChange={() => togglePhoneOverride()}
                                color="primary"
                                data-cy="pickupdialog-toggle-phone-override"
                            />
                        }
                        label={<DialogContentText>{loc('pickupDialog47', lang)}</DialogContentText>}
                        style={{ marginBottom: theme.spacing.unit }}
                    />
                    <Collapse in={phoneOverride}>
                        <TextField
                            fullWidth
                            data-cy="pickupdialog-phone"
                            type="tel"
                            variant="outlined"
                            name="phone"
                            label={loc('phoneNumber', lang)}
                            value={phone}
                            helperText={formErrors['phone'].reason}
                            error={formErrors['phone'].fail}
                            InputProps={{
                                inputComponent: TextMaskPhoneNumber
                            }}
                            onChange={onChange}
                        />
                    </Collapse>
                </DialogContentText>
            </DialogContent>
            <DialogActions style={{ marginBottom: 'max(8px, env(safe-area-inset-bottom, 8px))' }}>
                <Button color="primary" onClick={onBack}>
                    {loc('back', lang)}
                </Button>
                <Button
                    color="primary"
                    disabled={formContainsErrors}
                    onClick={onNext}
                    data-cy="4-pickupdialog-next-button"
                >
                    {loc('next', lang)}
                </Button>
            </DialogActions>
            <Fade in={inProgress}>
                <LinearProgress />
            </Fade>
        </Fragment>
    );
};

export default withTheme()(Comments);
