import React from 'react';

import _ from 'lodash';

import TimePicker from 'material-ui-pickers/TimePicker';

import * as timeHelper from 'utils/timeHelper';

export default function TimePickerTz(props) {
    const { timezone, value, onChange, ...rest } = props;

    const handleChange = date => {
        const dateAdjusted = timeHelper.mutateDateFromLocalToZone(date, timezone);
        onChange(dateAdjusted);
    };

    return (
        <TimePicker
            value={!_.isNil(timezone) ? timeHelper.mutateDateFromZoneToLocal(value, timezone) : null}
            {...rest}
            onChange={handleChange}
        />
    );
}
