import { useMemo } from 'react';
import _ from 'lodash';
import { getDriversOnDate } from '../functions';

function useDataFilteredByDate(filterDate, drivers, trips) {
    let driversOnDate = useMemo(() => getDriversOnDate(drivers, filterDate), [drivers, filterDate]);

    const groupedTrips = _.groupBy(trips, 'transporter._id');
    driversOnDate.forEach(driver => {
        const matchedTrip = _.first(groupedTrips[driver._id]);
        if (matchedTrip) {
            driver.trip_id = matchedTrip._id;
        }
    });

    return { driversOnDate };
}

export default useDataFilteredByDate;
