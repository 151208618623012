import React from 'react';
import _ from 'lodash';
import {
    Icon,
    Grid,
    Paper,
    Divider,
    Avatar,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography
} from '@material-ui/core';
import * as colors from '@material-ui/core/colors';
import { withTheme } from '@material-ui/core/styles';

const Card = ({ theme, loading, color, icon, title, data }) => {
    const cellStyle = { borderBottom: 'none' };

    const getTableRows = (data, indentLevel = 0) => {
        return data.map(({ name, value, title, subValues }, i) => {
            return (
                <>
                    <TableRow title={title} style={{ height: 32 }} key={i}>
                        <TableCell style={cellStyle}>
                            <span style={{ marginLeft: theme.spacing.unit * 2 * indentLevel }}>{name}</span>
                        </TableCell>
                        <TableCell
                            align="right"
                            style={cellStyle}
                            data-cy={`admin-dashboard-${name.replace(/[, ]+/g, '-').trim()}`}
                        >
                            {value}
                        </TableCell>
                    </TableRow>
                    {!_.isNil(subValues) && getTableRows(subValues, indentLevel + 1)}
                </>
            );
        });
    };

    return (
        <Grid item xs={12} sm={4}>
            <Paper style={{ padding: theme.spacing.unit * 2, height: '100%' }}>
                <Grid container spacing={theme.spacing.unit * 2}>
                    <Grid item>
                        {loading ? (
                            <CircularProgress size={40} style={{ marginTop: 8, color: colors[color][500] }} />
                        ) : (
                            <Avatar style={{ marginTop: theme.spacing.unit, backgroundColor: colors[color][500] }}>
                                <Icon>{icon}</Icon>
                            </Avatar>
                        )}
                    </Grid>
                    <Grid item style={{ flexGrow: 1 }}>
                        <Typography variant="h6">{title}</Typography>
                        <Divider style={{ marginTop: theme.spacing.unit / 2, marginBottom: theme.spacing.unit }} />
                        <Table padding="none">
                            <TableBody>{data && getTableRows(data)}</TableBody>
                        </Table>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
};

export default withTheme()(Card);
