import React, { useMemo } from 'react';
import { Clipboard } from '@capacitor/clipboard';

import _ from 'lodash';
import { _user } from 'std';
import moment from 'moment-timezone';

import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withTheme } from '@material-ui/core/styles';
import { colors } from '@material-ui/core';

import DateTimePickerTz from 'components/DateTimePickersTz/DateTimePickerTz';
import GMapsAutocomplete from 'components/GMapsAutocomplete';

import { getDistanceFromLatLonInKm } from 'utils/latlngFunctions';

import { getReceiverName, formatAsCurrency, convertSecondsToHHmm, convertSecondsToMinutes } from 'utils/misc';
import * as terms from 'localizations/terms';
import { useState } from 'react';
import { useEffect } from 'react';

const ROUTING_ALGORITHMS_AVAILABLE = [
    {
        value: 'ROUTING_ALGORITHM_KCLUSTER', // NB: don't change this string
        label: 'Dynamic clustering'
    },
    {
        value: 'ROUTING_ALGORITHM_ZONES', // NB: don't change this string
        label: 'Zones routing'
    }
];

function TripDialog(props) {
    const {
        theme,
        operator,
        google,
        open,
        isSystemAdmin,
        form,
        unsetDriver,
        unsetProcessor,
        tripSelected,
        driversAvailable,
        collectorsAvailable,
        processorsAvailable,
        warningDistanceFromDepot,
        inProgress,
        onTripDelete,
        onTripSubmit,
        onUseDriverLocation,
        onUseCollectorLocation,
        onFormSuggestionSelected,
        selectedCollector
    } = props;

    const mode = !_.isNil(tripSelected) ? 'Update' : 'Create';
    const [transporter, setTransporter] = useState(null);

    const [startLocationWarningDialogOpen, setStartLocationWarningDialogOpen] = useState(false);

    const driver = useMemo(() => _.find(driversAvailable, driver => driver._id.toString() === form.driverSelected), [
        driversAvailable,
        form.driverSelected
    ]);
    const driverDefaultStartTime = _.get(driver, 'startTime', null);

    const lunchBreakDuration = _.isNil(tripSelected)
        ? 0
        : _.reduce(
              _.get(tripSelected, 'route.legs', []),
              (sum, leg) => {
                  return (sum += _.get(leg, 'lunchBreakDuration', 0));
              },
              0
          );

    useMemo(() => {
        if (!_.isNil(form.driverSelected) && !_.isEmpty(form.driverSelected)) {
            let driver = _.find(driversAvailable, d => d._id.toString() === form.driverSelected.toString());
            setTransporter(_.get(driver, 'collector._id'));
        } else if (_.isEmpty(transporter) || _.isNil(transporter)) {
            setTransporter(_.get(form, 'driverSelected.collector', form.collectorSelected));
            // _.set(form, 'collectorSelected', _.get(form, 'driverSelected.collector', form.collectorSelected));
        }
    }, [form.collectorSelected, form.driverSelected, driversAvailable]);

    useMemo(() => {
        if (
            (_.isNil(form.collectorSelected) || _.isEmpty(form.collectorSelected)) &&
            !_.isNil(transporter) &&
            !_.isEmpty(transporter)
        ) {
            _.set(form, 'collectorSelected', transporter);
        }
    }, [transporter]);

    const driversSelectable = useMemo(
        () =>
            _(driversAvailable)
                .filter(
                    driver =>
                        !driver.banned && driver.collector._id === (transporter ? transporter : form.collectorSelected)
                )
                .sortBy(driver => driver.name.first.toLowerCase())
                .value(),
        [driversAvailable, transporter, form.collectorSelected]
    );

    useEffect(() => {
        if (
            !_.isNil(driversSelectable) &&
            !_.isEmpty(driversSelectable) &&
            !driversSelectable.map(d => d._id.toString()).includes(form.driverSelected.toString())
        ) {
            unsetDriver();
        }
    }, [driversSelectable]);

    useEffect(() => {
        if (
            !_.isNil(processorsAvailable) &&
            !_.isEmpty(processorsAvailable) &&
            !processorsAvailable.map(p => p._id.toString()).includes(form.collectorSelected.toString())
        ) {
            unsetProcessor();
        }
    }, [processorsAvailable]);

    const locationDescription = _.get(form, 'locationSelected.description', '');
    const formIsNotComplete =
        _.isEmpty(form.driverSelected) ||
        _.isEmpty(form.collectorSelected) ||
        _.isEmpty(form.dateSelected) ||
        _.isEmpty(locationDescription) ||
        moment(form.dateSelected).isBefore(new Date(), 'day');

    const getTimezoneFromForm = form => {
        const collector = _.find(processorsAvailable, collector => collector._id === form.collectorSelected);
        return _.get(collector, 'timezone', process.env.REACT_APP_REGION_TIMEZONE);
    };

    const handleCopyToClipboard = string => () => {
        Clipboard.write({
            string
        });
        props.onSnackbar('ID copied!', 'success', 800);
    };

    const handleViewTrip = () => {
        const { transporter, collector, startDateInteger } = tripSelected;

        const url = `/operators/${_user.getId(operator)}/collector-completed?collector=${collector._id}&receiver=${
            transporter._id
        }&date=${moment(startDateInteger, 'YYYYMMDD').toISOString()}`;

        console.log(url);

        window.open(url, '_blank');
    };

    const checkStartLocationToDepotDistance = () => {
        const lat = _.get(form, 'locationSelected.lat');
        const lng = _.get(form, 'locationSelected.lng');

        const collectorId = _.get(form, 'collectorSelected', {});
        const collector = _.find(collectorsAvailable, { _id: collectorId });

        const collectorLat = _.get(collector, 'location.lat');
        const collectorLng = _.get(collector, 'location.lng');

        if (!collectorLat || !collectorLng || !lat || !lng) {
            return;
        }

        const distanceBetweenStartLocationAndDepot = getDistanceFromLatLonInKm(lat, lng, collectorLat, collectorLng);

        if (distanceBetweenStartLocationAndDepot > warningDistanceFromDepot) {
            setStartLocationWarningDialogOpen(true);
        }
    };

    useEffect(() => {
        checkStartLocationToDepotDistance();
    }, [form.locationSelected]);

    useEffect(() => {
        if (!open) {
            setTransporter(null);
        } else {
            setTransporter(_.get(tripSelected, 'transporterCollector._id', selectedCollector));
        }
    }, [open]);

    return (
        <>
            <Dialog open={open} fullWidth maxWidth="sm" onClose={inProgress ? () => undefined : props.onClose}>
                <DialogTitle>{mode} trip</DialogTitle>
                <DialogContent>
                    {!_.isNil(tripSelected) && (
                        <>
                            <Hidden xsDown>
                                <DialogContentText>
                                    Trip ID:{' '}
                                    <span style={{ color: theme.palette.primary.main }}>
                                        ObjectId("{tripSelected._id}")
                                    </span>
                                    <IconButton
                                        style={{ marginLeft: theme.spacing.unit, width: 32, height: 32, padding: 0 }}
                                        onClick={handleCopyToClipboard(`ObjectId("${tripSelected._id}")`)}
                                    >
                                        <Icon style={{ fontSize: 18 }}>file_copy</Icon>
                                    </IconButton>
                                </DialogContentText>
                            </Hidden>
                            <DialogContentText>
                                Distance:{' '}
                                <span style={{ color: theme.palette.primary.main }}>
                                    {_.round((tripSelected.distance || 0) / 1000, 1)} km
                                </span>
                            </DialogContentText>
                            <DialogContentText>
                                Default Duration:{' '}
                                <span style={{ color: theme.palette.primary.main }}>
                                    {convertSecondsToHHmm(tripSelected.durationFromDefaultValues || 0)}
                                </span>
                                {lunchBreakDuration > 0
                                    ? ` (+ ${convertSecondsToMinutes(lunchBreakDuration, 0)} lunch)`
                                    : ''}
                            </DialogContentText>
                            <DialogContentText>
                                Duration:{' '}
                                <span
                                    style={{
                                        color:
                                            tripSelected.durationFromDefaultValues < tripSelected.duration
                                                ? colors.red[500]
                                                : colors.green[500]
                                    }}
                                >
                                    {convertSecondsToHHmm(tripSelected.duration || 0)}
                                </span>
                                {lunchBreakDuration > 0
                                    ? ` (+ ${convertSecondsToMinutes(lunchBreakDuration, 0)} lunch)`
                                    : ''}
                            </DialogContentText>
                            {isSystemAdmin && (
                                <DialogContentText>
                                    Payment:{' '}
                                    <span style={{ color: theme.palette.primary.main }}>
                                        {formatAsCurrency(tripSelected.payment.amount || 0)}
                                    </span>
                                </DialogContentText>
                            )}
                            {isSystemAdmin && (
                                <DialogContentText>
                                    Estimated value:{' '}
                                    <span style={{ color: theme.palette.primary.main }}>
                                        {formatAsCurrency(tripSelected.estimatedValue || 0)}
                                    </span>
                                </DialogContentText>
                            )}
                        </>
                    )}
                    <FormControl
                        fullWidth
                        data-cy="create-edit-trip-dialog-collector-select"
                        style={{ marginTop: theme.spacing.unit * 1.5 }}
                    >
                        <InputLabel htmlFor="collectorSelected">Transporter</InputLabel>
                        <Select
                            value={transporter}
                            onChange={e => setTransporter(e.target.value)}
                            inputProps={{ id: 'transporter', name: 'transporter' }}
                            disabled={_.get(tripSelected, 'hasStarted', false)}
                        >
                            {collectorsAvailable.map(collector => {
                                return (
                                    <MenuItem
                                        key={collector._id}
                                        value={collector._id}
                                        data-cy={`create-edit-trip-${collector.name
                                            .replace(/\s*\W*\s+/g, '-')
                                            .toLowerCase()}-li`}
                                    >
                                        {collector.name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                    {_.isEmpty(transporter) && (
                        <Typography style={{ marginTop: theme.spacing.unit / 2, color: theme.palette.error.main }}>
                            <Icon
                                style={{
                                    marginRight: theme.spacing.unit / 2,
                                    lineHeight: 1.25,
                                    color: theme.palette.error.main
                                }}
                            >
                                warning
                            </Icon>{' '}
                            No transporter selected!
                        </Typography>
                    )}
                    <FormControl
                        fullWidth
                        data-cy="create-edit-trip-dialog-driver-select"
                        style={{ marginTop: theme.spacing.unit * 0.5 }}
                    >
                        <InputLabel htmlFor="driverSelected">Driver</InputLabel>
                        <Select
                            disabled={_.isEmpty(transporter) || _.get(tripSelected, 'hasStarted', false)}
                            value={form.driverSelected}
                            onChange={props.onFormChange}
                            inputProps={{ id: 'driverSelected', name: 'driverSelected' }}
                        >
                            {driversSelectable.map(driver => {
                                return (
                                    <MenuItem
                                        key={driver._id}
                                        value={driver._id}
                                        data-cy={`${getReceiverName(driver)
                                            .replace(/\s/g, '-')
                                            .toLowerCase()}-create-edit-trip-driver-li`}
                                    >
                                        {' '}
                                        <span style={{ color: theme.palette.primary.main }}>
                                            [{_.get(driver, 'collector.code')}]{' '}
                                        </span>
                                        {getReceiverName(driver)} ➡{' '}
                                        <span style={{ color: theme.palette.primary.main }}>
                                            {_.get(
                                                _.find(
                                                    processorsAvailable,
                                                    c => c._id.toString() === form.collectorSelected.toString()
                                                ),
                                                'code',
                                                'N/A'
                                            )}
                                        </span>
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                    {_.isEmpty(form.driverSelected) && (
                        <Typography style={{ marginTop: theme.spacing.unit / 2, color: theme.palette.error.main }}>
                            <Icon
                                style={{
                                    marginRight: theme.spacing.unit / 2,
                                    lineHeight: 1.25,
                                    color: theme.palette.error.main
                                }}
                            >
                                warning
                            </Icon>{' '}
                            No driver selected!
                        </Typography>
                    )}
                    <FormControl
                        fullWidth
                        data-cy="create-edit-trip-dialog-destination-select"
                        style={{ marginTop: theme.spacing.unit * 1.5 }}
                    >
                        <InputLabel htmlFor="collectorSelected">Processor</InputLabel>
                        <Select
                            value={form.collectorSelected}
                            onChange={props.onFormChange}
                            inputProps={{ id: 'collectorSelected', name: 'collectorSelected' }}
                        >
                            {processorsAvailable.map(collector => {
                                return (
                                    <MenuItem
                                        key={collector._id}
                                        value={collector._id}
                                        data-cy={`create-edit-trip-${collector.name
                                            .replace(/\s*\W*\s+/g, '-')
                                            .toLowerCase()}-li`}
                                    >
                                        {collector.name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                    {_.isEmpty(form.collectorSelected) && (
                        <Typography style={{ marginTop: theme.spacing.unit / 2, color: theme.palette.error.main }}>
                            <Icon
                                style={{
                                    marginRight: theme.spacing.unit / 2,
                                    lineHeight: 1.25,
                                    color: theme.palette.error.main
                                }}
                            >
                                warning
                            </Icon>{' '}
                            No processor selected!
                        </Typography>
                    )}
                    {!_.isEmpty(form.driverSelected) && (
                        <Typography style={{ marginTop: theme.spacing.unit / 2, color: theme.palette.text.secondary }}>
                            Driver default start time:{' '}
                            {moment(driverDefaultStartTime)
                                .tz(getTimezoneFromForm(form))
                                .format('h:mm A')}
                        </Typography>
                    )}
                    <FormControl fullWidth style={{ marginTop: theme.spacing.unit * 0.5 }}>
                        <DateTimePickerTz
                            data-cy="trip-date-picker-input"
                            timezone={getTimezoneFromForm(form)}
                            disabled={_.isEmpty(form.collectorSelected) || _.get(tripSelected, 'hasStarted', false)}
                            disablePast
                            label="Date/time"
                            name="dateSelected"
                            format="dddd, MMM Do h:mm A"
                            value={form.dateSelected}
                            onChange={props.onFormDateChange}
                        />
                    </FormControl>
                    {_.isEmpty(form.dateSelected) && (
                        <Typography style={{ marginTop: theme.spacing.unit / 2, color: theme.palette.error.main }}>
                            <Icon
                                style={{
                                    marginRight: theme.spacing.unit / 2,
                                    lineHeight: 1.25,
                                    color: theme.palette.error.main
                                }}
                            >
                                warning
                            </Icon>{' '}
                            No date/time selected!
                        </Typography>
                    )}
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <FormControl style={{ marginTop: theme.spacing.unit * 0.5, flexGrow: 1 }}>
                            <GMapsAutocomplete
                                google={google}
                                variant="standard"
                                label="Start location"
                                placeholder={`Enter ${terms.ORGANIZATION_NAME.toLowerCase()} address`}
                                selectedValue={_.get(form, 'locationSelected.description')}
                                types={['address']}
                                // restrictions={{ country: 'ca' }}
                                onSuggestionSelected={onFormSuggestionSelected}
                                disabled={_.get(tripSelected, 'hasStarted', false)}
                            />
                        </FormControl>
                        <Button
                            style={{ whiteSpace: 'nowrap', margin: theme.spacing.unit * 0.5 }}
                            color="primary"
                            variant="outlined"
                            size="small"
                            onClick={onUseDriverLocation}
                            disabled={_.isNil(driver)}
                        >
                            Use Driver
                        </Button>
                        <Button
                            style={{ whiteSpace: 'nowrap', margin: theme.spacing.unit * 0.5 }}
                            color="primary"
                            variant="outlined"
                            size="small"
                            onClick={onUseCollectorLocation}
                            disabled={_.isNil(form.collectorSelected)}
                        >
                            Use Collector
                        </Button>
                    </div>
                    {_.isEmpty(_.get(form, 'locationSelected.description', '')) && (
                        <Typography style={{ marginTop: theme.spacing.unit / 2, color: theme.palette.error.main }}>
                            <Icon
                                style={{
                                    marginRight: theme.spacing.unit / 2,
                                    lineHeight: 1.25,
                                    color: theme.palette.error.main
                                }}
                            >
                                warning
                            </Icon>{' '}
                            No location selected!
                        </Typography>
                    )}
                    <FormControl fullWidth style={{ marginTop: theme.spacing.unit * 0.5 }}>
                        <InputLabel htmlFor="routingAlgorithmSelected">Routing algorithm</InputLabel>
                        <Select
                            value={form.routingAlgorithmSelected}
                            onChange={props.onFormChange}
                            inputProps={{ id: 'routingAlgorithmSelected', name: 'routingAlgorithmSelected' }}
                        >
                            {ROUTING_ALGORITHMS_AVAILABLE.map(routingAlgorithm => {
                                return (
                                    <MenuItem key={routingAlgorithm.value} value={routingAlgorithm.value}>
                                        {routingAlgorithm.label}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    {mode === 'Update' && isSystemAdmin && (
                        <Button
                            data-cy="create-edit-trip-dialog-delete-button"
                            color="primary"
                            disabled={inProgress || !_.isEmpty(tripSelected.pickups)}
                            onClick={() => onTripDelete(tripSelected)}
                        >
                            Delete
                        </Button>
                    )}
                    <Button
                        data-cy="create-edit-trip-dialog-create-button"
                        color="primary"
                        disabled={inProgress || formIsNotComplete}
                        onClick={onTripSubmit}
                    >
                        {mode}
                    </Button>
                    {!_.isNil(tripSelected) && (
                        <Button data-cy="create-edit-trip-dialog-view-button" color="primary" onClick={handleViewTrip}>
                            View Trip
                        </Button>
                    )}
                </DialogActions>
                <Fade in={inProgress}>
                    <LinearProgress />
                </Fade>
            </Dialog>
            <Dialog
                open={startLocationWarningDialogOpen}
                fullWidth
                onClose={() => setStartLocationWarningDialogOpen(false)}
            >
                <DialogTitle>Warning: Verify Start Location</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        The start location for this trip is located over {warningDistanceFromDepot}km away from the
                        depot. Double check that you have selected the correct start location.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        data-cy="verify-start-location-close"
                        onClick={() => setStartLocationWarningDialogOpen(false)}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default withTheme()(TripDialog);
