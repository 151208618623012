import { useEffect, useState, useContext } from 'react';
import _ from 'lodash';
import queryString from 'query-string';
import useQueryString from 'utils/hooks/useQueryString';
import HttpContext from 'utils/contexts/HttpContext';

const useTable = ({
    url,
    key,
    headers,
    queryObj = {},
    searchTermKey = 'searchTerm',
    initialRowsPerPage = 10,
    customQueryName = null,
    customQueryValue = null
}) => {
    const http = useContext(HttpContext);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(
        initialRowsPerPage === 'All' ? 999999999 : parseInt(initialRowsPerPage)
    );
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [response, setResponse] = useState({});
    const [searchTerm, setSearchTerm] = useQueryString(searchTermKey, '', true);
    const [search, setSearch] = useState(searchTerm);
    const [filteredHeaders, setFilteredHeaders] = useState(
        JSON.parse(window.localStorage.getItem(`${url}-filterHeaders`)) || headers
    );
    const [totalDataEntries, setTotalDataEntries] = useState(0);
    const [timer, setTimer] = useState();
    const [autoSearch, setAutoSearch] = useState(false);

    const getData = async () => {
        const qs = queryString.parse(window.location.search);
        setLoading(true);
        let apiURL = url + `${url.includes('?') ? '&' : '?'}page_size=${rowsPerPage}&page_num=${page}`;
        for (let key in qs) {
            if (qs[key]) {
                const searchKey = key === searchTermKey ? 'searchTerm' : key;
                apiURL += `&${searchKey}=${encodeURIComponent(qs[key])}`;
            }
        }
        if (!_.isEmpty(queryObj)) {
            const stringifiedQueryObj = JSON.stringify(queryObj);
            apiURL += `&queryObj=${stringifiedQueryObj}`;
        }
        if (!_.isNil(customQueryName) && !_.isNil(customQueryValue)) {
            apiURL += `&${customQueryName}=${JSON.stringify(customQueryValue)}`;
        }
        const res = await http.getJSON(apiURL);
        if (res.ok) {
            setResponse(res.data);
            setData(res.data[key]);
            if (res.data.total) {
                setTotalDataEntries(res.data.total);
            } else {
                setTotalDataEntries(0);
            }
            setFilteredData(res.data[key]);
        }
        setLoading(false);
    };

    const handleChange = e => {
        setSearch(e.target.value);
        clearTimeout(timer);
        setTimer(setTimeout(() => setAutoSearch(true), 1000));
    };

    const handleSearch = e => {
        if (e) {
            e.preventDefault();
        }

        clearTimeout(timer);
        setTimer();
        setAutoSearch(false);

        setSearchTerm(search);
        if (!search) {
            getData();
        }
    };

    const handleChangePage = (e, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = e => {
        if (e.target.value === 'All') {
            setRowsPerPage(999999999);
        } else {
            setRowsPerPage(parseInt(e.target.value));
        }

        setPage(0);
    };

    const handleChangeFilterHeaders = e => {
        window.localStorage.setItem(`${url}-filterHeaders`, JSON.stringify(e.target.value));
        setFilteredHeaders(e.target.value);
    };

    const handleAllFilterHeaders = all => e => {
        e.stopPropagation();
        if (all) {
            window.localStorage.setItem(`${url}-filterHeaders`, JSON.stringify(headers));
            setFilteredHeaders(headers);
        } else {
            window.localStorage.setItem(`${url}-filterHeaders`, JSON.stringify([]));
            setFilteredHeaders([]);
        }
    };
    useEffect(() => {
        getData();
    }, [url, rowsPerPage, page, searchTerm, JSON.stringify(queryObj), JSON.stringify(customQueryValue)]);

    useEffect(() => {
        if (autoSearch) {
            handleSearch();
        }
    }, [autoSearch]);

    return {
        loading,
        searchTerm,
        page,
        rowsPerPage,
        data,
        response,
        filteredData,
        search,
        filteredHeaders,
        getData,
        totalDataEntries,
        handleChange,
        handleSearch,
        handleChangeFilterHeaders,
        handleAllFilterHeaders,
        handleChangePage,
        handleChangeRowsPerPage
    };
};

export default useTable;
