import React, { useContext } from 'react';
import _ from 'lodash';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { loc } from 'localizations/localizationHandler';
import LocalizationContext from 'utils/contexts/LocalizationContext';

const AlertDialog = ({ open, handleClose }) => {
    const { lang } = useContext(LocalizationContext);

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{loc('tripsRerouted', lang)}</DialogTitle>
            <DialogContent>
                <DialogContentText>{loc('reviewTripUpdate', lang)} </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    {loc('dismiss', lang)}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AlertDialog;
