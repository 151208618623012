import { useEffect } from 'react';

import _ from 'lodash';
import queryString from 'query-string';

import useQueryString from 'utils/hooks/useQueryString';
import { getQueryStringValue } from 'utils/query';

function isValidReceiver(receiverArr, receiver) {
    return receiverArr.map(r => r._id.toString()).includes(receiver.toString());
}

function useReceiverDropDown({ receivers, stateURLKey = 'receiver', saveStateInURL, loading = false }) {
    const [receiver, setReceiver] = useQueryString(
        stateURLKey,
        getInitialBulkReceiverFilterState(receivers, loading),
        saveStateInURL,
        receiver => {
            return isValidReceiver(receivers, receiver);
        }
    );

    // if the receivers are changed, make sure that the selected receiver is a part of that collector
    useEffect(() => {
        if (!isReceiverSelectedIsValid(receiver, receivers) && !loading) {
            if (_.isNil(receivers) || _.isEmpty(receivers)) {
                setReceiver('all');
            } else if (receivers.length === 1) {
                setReceiver(_.first(receivers)._id);
            } else {
                setReceiver('all');
            }
        }
    }, [receiver, receivers]);

    const handleChange = e => {
        const val = e.target.value;
        setReceiver(val);
    };

    return {
        receiver,
        handleChange
    };
}

export default useReceiverDropDown;

function getInitialBulkReceiverFilterState(receivers, loading) {
    const queryStringValue = getQueryStringValue('receiver');
    if (queryStringValue && loading){
        return queryStringValue;
    }
    if (_.isNil(receivers) || _.isEmpty(receivers)) {
        return 'all';
    } else if (receivers.length === 1) {
        return _.first(receivers)._id;
    } else {
        return 'all';
    }
}

function isReceiverSelectedIsValid(receiver, receivers) {
    if (_.isNil(receivers) || _.isEmpty(receivers)) {
        return false;
    }
    return receivers.map(r => r._id.toString()).includes(receiver.toString());
}
