import React, { useMemo, memo, useContext, useState } from 'react';

import _ from 'lodash';
import moment from 'moment-timezone';
import { _bulk, _user, _commodity } from 'std';

import { formatAsAddress, isEXPRegion, getQuickDropName, getWalkInName, isCONRegion } from 'utils/misc';

import { colors, Avatar, IconButton, Badge, Menu } from '@material-ui/core';
import { Icon as MDIcon } from '@mdi/react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import Typography from '@material-ui/core/Typography';

import { withTheme } from '@material-ui/core/styles';

import {
    mdiCash100,
    mdiTagArrowDown,
    mdiCurrencyUsd,
    mdiHelpRhombus,
    mdiStore,
    mdiCashEdit,
    mdiQrcodeScan,
    mdiAlertOutline,
    mdiTruck
} from '@mdi/js';
import * as allIcons from '@mdi/js';

import InlineChip from 'components/Chips/InlineChip';

import bulkHelper from 'helpers/bulkHelper';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { getComEquivalent } from 'containers/Operators/PORScreen/NumberOfBagsDialog';
import { PICKUP } from 'constants.js';

const tileSize = 54;
const iconOffset = 12;

const CURRENT_DAY = moment(new Date());

const SearchBulkCard = memo(({ data, index, style }) => {
    const {
        theme,
        items,
        history,
        commodityColors,
        operator,
        resetTimeoutCount,
        commodities,
        bagtagLocationMap,
        bulkSearchDialogQuery
    } = data;
    const { lang } = useContext(LocalizationContext);
    const [anchor, setAnchor] = useState(null);
    const [menuOpen, setMenuOpen] = useState(false);

    const counter = () => {
        let counter;

        if (isEXPRegion()) {
            counter = bulkHelper.getCountedBy(bulk);
        }

        if (!_.isEmpty(counter)) return counter;

        if (_bulk.getMostRecentCounter(bulk) === 'N/A') {
            counter = _bulk.getDriverName(bulk);
        } else {
            counter = _bulk.getMostRecentCounter(bulk);
        }

        return counter;
    };

    const bulk = items[index];

    const rawAddress = _.get(bulk, 'pickup.location', null);
    const address = !_.isNil(rawAddress) ? formatAsAddress(rawAddress) : 'Unknown address';

    let hideCustomerIdentification = false;
    if (operator.accountType === 'Collector Administrator' && !operator.adminView) {
        hideCustomerIdentification = _.get(operator, `collector.hideCustomerIdentification.hideForAdmin`, false);
    } else if (
        operator.accountType === 'Collector Employee' &&
        !operator.adminView &&
        operator.accountPermissions.includes('Clerk')
    ) {
        hideCustomerIdentification = _.get(operator, `collector.hideCustomerIdentification.hideForClerk`, false);
    }

    let machineCounter = '';
    const sessions = _.get(bulk, 'countingSessions', []);
    const firstSession = _.first(sessions);
    const tomraVariables = _.get(firstSession, 'tomraVariables');
    if (!_.isEmpty(tomraVariables)) {
        const machine = tomraVariables.rvmName;
        const serialNumber = tomraVariables.rvmSerialNo;
        machineCounter = `${machine.replace(' ', '')}-${serialNumber}`;
    }

    let bulkInfo = '';
    let allKioskBagtags = [];
    allKioskBagtags = _.get(bulk, 'countingSessions', []).map(session => {
        return session.bagtag;
    });
    const matchingBagtag = allKioskBagtags.find(b => b === bulkSearchDialogQuery);
    if (!_.isNil(matchingBagtag)) {
        bulkInfo = matchingBagtag;
    }
    if (_.isEmpty(bulkInfo)) {
        bulkInfo = allKioskBagtags[0];
    }
    if (_.isEmpty(bulkInfo))
        switch (bulk.bulkType) {
            case 'inhouse':
                bulkInfo = getQuickDropName('en');
                break;
            case 'adjustment':
                bulkInfo = 'Adjustment';
                break;
            case 'pickup':
                bulkInfo = hideCustomerIdentification ? `Pickup` : `Pickup - ${address}`;
                break;
            case 'walk-in':
                bulkInfo = getWalkInName('en') + (machineCounter ? ` - [${machineCounter}]` : ` - [${counter()}]`);
                break;
            default:
                bulkInfo = 'N/A';
        }

    let numOfSubComEquivalent = useMemo(() => getComEquivalent(bulk, commodities), [bulk, commodities]);
    let numOfSortedBags = _.get(
        _.find(_.get(bulk, 'commoditiesProcessedBreakdown', []), { isSubCommodity: false }),
        'amount',
        0
    );
    let numOfReportedBags = _.get(bulk, 'commodityAmount', 0) - numOfSubComEquivalent;
    let subComUnitMap = useMemo(() => {
        return getSubComUnitMap(bulk, commodities, lang);
    }, [bulk, commodities, lang]);

    let statusMessage = <span style={{ color: colors.yellow[900] }}> Collected</span>;
    if (_bulk.isCompleted(bulk)) statusMessage = <span style={{ color: colors.green[700] }}> Processed</span>;
    if (_bulk.isRedeemed(bulk)) statusMessage = <span style={{ color: colors.blue[700] }}> Paid</span>;

    let firstBagtag = _.get(bulk, 'countingSessions[0].bagtag', '');

    let dateInfo = '';
    if (!_.isEmpty(firstBagtag) && !_.isNil(bagtagLocationMap) && !_.isNil(bagtagLocationMap[firstBagtag])) {
        if (!_.isNil(bagtagLocationMap[firstBagtag].date) && !_.isEmpty(bagtagLocationMap[firstBagtag].date))
            dateInfo += moment(bagtagLocationMap[firstBagtag].date).format('MMM DD YYYY');
        if (
            !_.isNil(bagtagLocationMap[firstBagtag].kioskLocation) &&
            !_.isEmpty(bagtagLocationMap[firstBagtag].kioskLocation)
        ) {
            if (!_.isEmpty(dateInfo)) dateInfo += ', ';
            dateInfo += `${bagtagLocationMap[firstBagtag].kioskLocation}`;
        }

        if (!_.isEmpty(dateInfo)) dateInfo += ' | ';
    }

    dateInfo += moment(bulk.datePickedUp).format('MMM DD YYYY');
    if (!_.isNil(_.get(bulk, 'receiver.name'))) {
        if (!_.isEmpty(dateInfo)) dateInfo += ', ';
        dateInfo += `${bulk.bulkType === PICKUP ? 'Driver' : 'Clerk'} ${bulk.receiver.name.first} ${_.get(
            bulk,
            'receiver.name.last',
            ''
        ).charAt(0)}`;
    }

    let bulkIcon = <MDIcon path={mdiTruck} size={1} color={theme.palette.background.default} />;
    let bulkColor = colors.grey[500];
    switch (bulk.bulkType) {
        case 'pickup':
            bulkIcon = <MDIcon path={mdiTruck} size={1} color={theme.palette.background.default} />;
            bulkColor = colors.blue[500];
            break;
        case 'inhouse':
            bulkIcon = <MDIcon path={mdiTagArrowDown} size={1} color={theme.palette.background.default} />;
            bulkColor = colors.blue[500];
            break;
        case 'walk-in':
            bulkIcon = isCONRegion() ? (
                <MDIcon path={mdiQrcodeScan} size={1} color={theme.palette.background.default} />
            ) : (
                <MDIcon path={mdiStore} size={1} color={theme.palette.background.default} />
            );
            bulkColor = colors.blue[500];

            break;
        case 'adjustment':
            bulkIcon = <MDIcon path={mdiCashEdit} size={1} color={theme.palette.background.default} />;
            bulkColor = colors.orange[700];

            if (_bulk.isPromoBulk(bulk)) {
            } else if (_bulk.isPenaltyBulk(bulk)) {
                bulkColor = colors.red[500];
            }

            break;
        case 'promo':
            bulkIcon = <MDIcon path={mdiCash100} size={1} color={theme.palette.background.default} />;
            bulkColor = colors.amber[500];
            break;
        default:
            bulkIcon = <MDIcon path={mdiHelpRhombus} size={1} color={theme.palette.background.default} />;
            bulkColor = colors.grey[500];
            break;
    }

    return (
        <div style={{ ...style, padding: '3px 0' }}>
            <Card
                data-cy={`bulkcard-${bulk.label}-${bulk.skuType}`}
                elevation={0}
                style={{
                    margin: theme.spacing.unit,
                    border: '1px solid',
                    borderColor: theme.palette.grey[300],
                    maxHeight: '100%'
                }}
            >
                <CardActionArea
                    onClick={e => {
                        resetTimeoutCount();
                        const URL = `/operators/${operator._id}/bulks/${bulk._id}`;
                        if (e.ctrlKey) {
                            window.open(URL, '_blank');
                        } else {
                            history.push(URL);
                        }
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            padding: theme.spacing.unit,
                            paddingBottom: theme.spacing.unit
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            <Avatar style={{ backgroundColor: bulkColor }}>{bulkIcon}</Avatar>
                        </div>
                        <div
                            style={{
                                marginLeft: theme.spacing.unit,
                                flex: '1'
                            }}
                        >
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <Typography
                                    variant="caption"
                                    style={{ fontWeight: 500, fontSize: '0.85rem', width: '99%' }}
                                    noWrap
                                >
                                    {_bulk.getTruncatedLabel(bulk)}
                                    {_bulk.getCustomerUniqueID(bulk) !== _bulk.getTruncatedLabel(bulk) &&
                                        !_.isNil(bulk.owner) && (
                                            <span>
                                                {', '}
                                                {_bulk.getCustomerUniqueID(bulk)}.{' '}
                                            </span>
                                        )}
                                    {(_user.isSystemAdmin(operator) || _user.isInternalRole(operator)) && (
                                        <> - {_bulk.getCollectorName(bulk)}</>
                                    )}
                                    {statusMessage}
                                </Typography>

                                <div style={{ display: 'flex', flexDirection: 'row', marginRight: 5 }}>
                                    {Math.max(numOfReportedBags, numOfSortedBags) > 0 && (
                                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: -5 }}>
                                            <div style={{ alignSelf: 'center', justifyContent: 'center', height: 24 }}>
                                                <MDIcon
                                                    path={
                                                        allIcons[
                                                            _.get(
                                                                _.find(commodities, {
                                                                    skuType: _.get(bulk, 'skuType', '')
                                                                }),
                                                                'iconName',
                                                                ''
                                                            )
                                                        ]
                                                    }
                                                    size={1}
                                                    color={_.get(
                                                        _.find(commodities, {
                                                            skuType: _.get(bulk, 'skuType', '')
                                                        }),
                                                        'color',
                                                        colors.blue[300]
                                                    )}
                                                />
                                            </div>
                                            <Typography
                                                style={{
                                                    whiteSpace: 'nowrap',
                                                    alignSelf: 'center',
                                                    justifyContent: 'center'
                                                }}
                                            >
                                                {Math.max(numOfReportedBags, numOfSortedBags)}
                                            </Typography>
                                        </div>
                                    )}
                                    {Object.keys(subComUnitMap).map(key => {
                                        const { iconName, color, amount } = subComUnitMap[key];
                                        if (amount < 1) return null;
                                        return (
                                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: -5 }}>
                                                <div
                                                    style={{
                                                        alignSelf: 'center',
                                                        justifyContent: 'center',
                                                        height: 24
                                                    }}
                                                >
                                                    <MDIcon
                                                        path={allIcons[convertToImportName(iconName)]}
                                                        size={1}
                                                        color={color}
                                                    />
                                                </div>
                                                <Typography
                                                    style={{
                                                        whiteSpace: 'nowrap',
                                                        alignSelf: 'center',
                                                        justifyContent: 'center'
                                                    }}
                                                >
                                                    {amount}
                                                </Typography>
                                            </div>
                                        );
                                    })}

                                    {_bulk.hasIssues(bulk) && (
                                        <MDIcon
                                            path={mdiAlertOutline}
                                            size={0.75}
                                            color={colors.orange[400]}
                                            title={
                                                'Issues: ' +
                                                _.get(bulk, 'payloadIssues.labels', ['Reported with issue']).join()
                                            }
                                        />
                                    )}
                                    {_bulk.isRedeemed(bulk) && (
                                        <MDIcon
                                            path={mdiCurrencyUsd}
                                            size={0.75}
                                            color={colors.grey[500]}
                                            title="Redeemed by the customer"
                                        />
                                    )}
                                </div>
                            </div>

                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <Typography
                                        variant="caption"
                                        style={{ fontWeight: 300, marginTop: 1, lineHeight: 1.15, width: '100%' }}
                                        noWrap
                                    >
                                        {bulkInfo}
                                    </Typography>
                                    {allKioskBagtags.length > 1 && (
                                        <>
                                            <IconButton
                                                style={{ height: 4, width: 4, marginLeft: 5 }}
                                                onClick={e => {
                                                    e.stopPropagation();
                                                    setMenuOpen(!menuOpen);
                                                    setAnchor(e.currentTarget);
                                                }}
                                            >
                                                <Badge
                                                    badgeContent={allKioskBagtags.length}
                                                    color="primary"
                                                    anchorOrigin={{
                                                        vertical: 'bottom'
                                                    }}
                                                />
                                            </IconButton>
                                            <Menu
                                                anchorEl={anchor}
                                                data-cy="kioskBagtags"
                                                open={menuOpen}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'center'
                                                }}
                                                onClose={e => {
                                                    e.stopPropagation();
                                                    setMenuOpen(false);
                                                    setAnchor(null);
                                                }}
                                            >
                                                {allKioskBagtags.map((bagtag, idx) => {
                                                    const bagtagDate = _.get(bagtagLocationMap, `${bagtag}.date`, null);
                                                    let bagtagDateFormated = moment().format('MMM DD YYYY');
                                                    if (!_.isNil(bagtagDate)) {
                                                        bagtagDateFormated = moment(bagtagDate).format('MMM DD YYYY');
                                                    }
                                                    return (
                                                        <Typography
                                                            style={{ fontSize: 12, paddingLeft: 7, paddingRight: 7 }}
                                                        >
                                                            {bagtag}
                                                            {bagtagDate && ` - ${bagtagDateFormated}`}
                                                        </Typography>
                                                    );
                                                })}
                                            </Menu>
                                        </>
                                    )}
                                </div>
                                <InlineChip
                                    color={commodityColors[_.get(bulk, 'skuType')]}
                                    textColor={theme.palette.text.primary}
                                    text={_.get(bulk, 'skuType', '')}
                                    style={{ marginRight: theme.spacing.unit }}
                                />
                            </div>

                            <Typography style={{ fontWeight: 300, width: '99%' }} variant="caption" noWrap>
                                {dateInfo}
                            </Typography>
                        </div>
                    </div>
                    <div
                        style={{
                            backgroundColor: commodityColors[bulk.skuType] || colors.grey[500],
                            position: 'absolute',
                            height: '100%',
                            width: 8,
                            right: 0,
                            top: 0
                        }}
                    />
                </CardActionArea>
            </Card>
        </div>
    );
});

export default withTheme()(SearchBulkCard);

function getSubComUnitMap(bulk, commodities, lang) {
    const subPayloads = _.get(bulk, 'pickup.subPayloads', []).filter(
        subPayload => subPayload.skuType === _.get(bulk, 'skuType', '')
    );
    const commoditiesProcessedBreakdown = _.get(bulk, 'commoditiesProcessedBreakdown', []);
    if (_.isEmpty(subPayloads)) return getSubComUnitMapWalkin(bulk, commodities, lang);

    const tmpSubPayloads = _.cloneDeep(subPayloads);
    _commodity.populateSubPayloadsSubCommodities(tmpSubPayloads, commodities);

    let subComUnitMap = {};

    for (let subPayload of tmpSubPayloads) {
        const unit = _.get(subPayload, `subCommodity.units.${lang}`, null);
        const sortedSubCom = _.get(
            _.find(commoditiesProcessedBreakdown, {
                subCommodity: _.get(subPayload, `subCommodity._id`, null)
            }),
            'amount',
            0
        );
        if (unit !== null) {
            if (subComUnitMap[unit] === undefined)
                subComUnitMap[unit] = {
                    amount: Math.max(_.get(subPayload, `amount`, 0), sortedSubCom),
                    iconName: _.get(subPayload, `subCommodity.iconName`, ''),
                    color: _.get(subPayload, `subCommodity.color`, '')
                };
            else subComUnitMap[unit].amount += Math.max(_.get(subPayload, `amount`, 0), sortedSubCom);
        }
    }
    return subComUnitMap;
}
function getSubComUnitMapWalkin(bulk, commodities, lang) {
    const commoditiesProcessedBreakdown = _.get(bulk, 'commoditiesProcessedBreakdown', []);
    if (_.isEmpty(commoditiesProcessedBreakdown)) return 0;

    const tmpCommoditiesProcessedBreakdown = _.cloneDeep(commoditiesProcessedBreakdown);
    _commodity.populateSubPayloadsSubCommodities(tmpCommoditiesProcessedBreakdown, commodities);

    let subComUnitMap = {};

    for (let subPayload of tmpCommoditiesProcessedBreakdown) {
        if (_.get(subPayload, `isSubCommodity`, false)) {
            const unit = _.get(subPayload, `subCommodity.units.${lang}`, null);
            const amount = _.get(subPayload, 'amount', 0);
            if (unit !== null) {
                if (subComUnitMap[unit] === undefined)
                    subComUnitMap[unit] = {
                        amount: amount,
                        iconName: _.get(subPayload, `subCommodity.iconName`, ''),
                        color: _.get(subPayload, `subCommodity.color`, '')
                    };
                else subComUnitMap[unit].amount += Math.max(_.get(subPayload, `amount`, 0), amount);
            }
        }
    }
    return subComUnitMap;
}

function convertToImportName(iconName) {
    return _.camelCase(`mdi-${iconName}`);
}
