import { useState, useContext } from 'react';
import { isValidEmail } from 'utils/misc';

import _ from 'lodash';
import { _bulk } from 'std';
import { _charity } from 'std';

import HttpContext from 'utils/contexts/HttpContext';
import SnackbarContext from 'components/CustomSnackbar/SnackbarContext';

// TODO: break this up into smaller hooks
function useEmailReceiptDialog({ bulk, initialEmail = '', handleBack, setShowResultDialog, setResultDialogText }) {
    const http = useContext(HttpContext);
    const onSnackbar = useContext(SnackbarContext);

    const [emailReceiptDialogOpen, setEmailReceiptDialogOpen] = useState(false);
    const [customerEmail, setCustomerEmail] = useState('');
    const [error, setError] = useState('');

    const handleOpenEmailReceiptDialog = () => {
        setCustomerEmail(initialEmail);
        setEmailReceiptDialogOpen(true);
    };

    const handleCloseEmailReceiptDialog = () => {
        setEmailReceiptDialogOpen(false);
    };

    const handleCustomerEmailChange = e => {
        const email = e.target.value;
        setError('');
        setCustomerEmail(email);
    };

    const handleSubmitEmailReceipt = async (etransfer, emailPayload = customerEmail, donating) => {
        const res = await http.post(`/bulks/${bulk._id}/completeWalkIn`, { email: emailPayload, etransfer }, true);
        if (res.ok) {
            if (etransfer) {
                if (donating) {
                    setResultDialogText(`Donated to ${_.get(bulk, 'charity.name', '')}`);
                } else {
                    setResultDialogText(`e-Transferred to ${emailPayload}`);
                }
            } else {
                setResultDialogText('Funds were paid in cash');
            }
            setShowResultDialog(true);
            setCustomerEmail('');
            handleBack();
        } else {
            setError(res.errorMessage);
        }
    };

    return {
        emailReceiptDialogOpen,
        customerEmail,
        error,
        handleOpenEmailReceiptDialog,
        handleCloseEmailReceiptDialog,
        handleCustomerEmailChange,
        handleSubmitEmailReceipt
    };
}

export default useEmailReceiptDialog;
