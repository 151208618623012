import React, { useContext } from 'react';
import _ from 'lodash';

import {
    FormControl,
    InputLabel,
    Select,
    Button,
    MenuItem,
    Checkbox,
    ListItemText,
    TextField,
    withTheme
} from '@material-ui/core';

import { loc } from 'localizations/localizationHandler';
import LocalizationContext from 'utils/contexts/LocalizationContext';

//Select with "ALL" or "None" buttons
function MultiSelect(props) {
    const { lang } = useContext(LocalizationContext);

    const {
        theme,
        selectedValues,
        selectableValues,
        onChange,
        renderValue = null,
        renderMenuItem = null,
        ...other
    } = props;

    const defaultRenderValue = () => {
        const selectedNames = selectedValues.map(value => _.get(_.find(selectableValues, { value }), 'name'));
        return selectedNames.join(', ');
    };

    const handleAll = e => {
        e.stopPropagation();
        const newSelectedValues = selectableValues.map(({ name, value }) => value);
        onChange(newSelectedValues);
    };

    const handleNone = e => {
        e.stopPropagation();
        onChange([]);
    };

    return (
        <TextField
            select
            SelectProps={{
                multiple: true,
                renderValue: _.isNil(renderValue) ? defaultRenderValue : renderValue,
                MenuProps: {
                    PaperProps: {
                        style: {
                            marginTop: '30px'
                        }
                    }
                }
            }}
            value={selectedValues}
            onChange={e => onChange(e.target.value)}
            {...other}
        >
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <Button onClick={handleAll}>{loc('all', lang)}</Button>
                <Button onClick={handleNone}>{loc('none', lang)}</Button>
            </div>
            {selectableValues.map(({ name, value, obj }) => {
                if (renderMenuItem) {
                    return renderMenuItem({ name, value, obj });
                } else {
                    return (
                        <MenuItem key={value} value={value}>
                            <Checkbox checked={selectedValues.includes(value)} />
                            <ListItemText style={{ whiteSpace: 'normal' }}>{name}</ListItemText>
                        </MenuItem>
                    );
                }
            })}
        </TextField>
    );
}

export default withTheme()(MultiSelect);
