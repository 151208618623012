import React from 'react';

import _ from 'lodash';

import { FormControl, FormControlLabel, FormLabel, FormGroup, Checkbox, withTheme } from '@material-ui/core';
import { _commodity } from 'std';

function CommodityListInput({ selectedCommodityIds, allCommodities, label, enabledCommodities, onChange, theme }) {
    const handleSelect = _id => {
        const index = _.findIndex(selectedCommodityIds, commodityId => commodityId === _id);
        if (index !== -1) {
            let newSelectedCommodityIds = _.cloneDeep(selectedCommodityIds);
            newSelectedCommodityIds.splice(index, 1);
            onChange(newSelectedCommodityIds);
        } else {
            onChange([...selectedCommodityIds, _id]);
        }
    };

    return (
        <FormControl component="fieldset">
            <FormLabel component="legend">{label}</FormLabel>
            <FormGroup row>
                {allCommodities.map((commodity, idx) => (
                    <FormControlLabel
                        key={idx}
                        control={
                            <Checkbox
                                checked={
                                    _.findIndex(selectedCommodityIds, commodityId => commodityId === commodity._id) !==
                                    -1
                                }
                                onChange={() => handleSelect(commodity._id)}
                                disabled={_.isNil(
                                    _.find(enabledCommodities, commodityId => commodityId === commodity._id)
                                )}
                            />
                        }
                        label={_commodity.getSkuType(commodity)}
                    />
                ))}
            </FormGroup>
        </FormControl>
    );
}

export default withTheme()(CommodityListInput);
