import React from 'react';

import _ from 'lodash';

import DriverTripStatsList from 'components/Lists/DriverTripStatsList';

import { withTheme } from '@material-ui/core/styles';
import { withWidth } from '@material-ui/core';

import useGetJSON from 'utils/hooks/useGetJSON';

function TripHistoryLists(props) {
    const {
        theme,
        trips,
        pickupsByTrips, // pickupsByDrivers,
        debug,
        operator,
        driverConfig,
        isTodayOrFutureDate,
        onViewOnMaps,
        onOpenPickupDialog,
        commoditiesAvailable,
        numberOfScheduledPickups,
        hideCustomerIdentification
    } = props;

    const { loading: valuesLoading, error: valuesError, data: values } = useGetJSON(
        '/rates/values?commodities=beverage',
        'values'
    );

    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                overflowX: 'auto',
                marginTop: theme.spacing.unit
            }}
        >
            {_.keys(pickupsByTrips).map(key => {
                const trip = _.find(trips, { _id: key });

                let numberOfScheduledPickupsCount = pickupsByTrips[key].length;
                let rescheduledPickupsCount = _.get(trip, 'rescheduledPickupsCount', 0);

                const foundPickupData = numberOfScheduledPickups.find(p => p.driver === key);

                if (foundPickupData) {
                    numberOfScheduledPickupsCount = foundPickupData.numberOfPickups;
                }

                return (
                    <div
                        style={{
                            paddingRight: theme.spacing.unit,
                            height: '100%',
                            maxWidth: '100%',
                            flex: '0 0 350px'
                        }}
                        key={key}
                    >
                        <DriverTripStatsList
                            debug={debug}
                            trip={trip}
                            pickups={pickupsByTrips[key]}
                            operator={operator}
                            driverConfig={driverConfig}
                            commoditiesAvailable={commoditiesAvailable}
                            numberOfScheduledPickups={numberOfScheduledPickupsCount}
                            isTodayOrFutureDate={isTodayOrFutureDate}
                            onViewOnMaps={onViewOnMaps}
                            onClickInfoAction={onOpenPickupDialog}
                            rateValues={values}
                            hideCustomerIdentification={hideCustomerIdentification}
                            rescheduledPickupsCount={rescheduledPickupsCount}
                        />
                    </div>
                );
            })}
        </div>
    );
}

export default withWidth()(withTheme()(TripHistoryLists));
