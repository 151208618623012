import React, { useMemo } from 'react';

import _ from 'lodash';

import { withTheme } from '@material-ui/core/styles';

import { formatAsCurrency } from 'utils/misc';

import * as colors from '@material-ui/core/colors';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

function BulkCustomFeesTable({ theme, bulks }) {
    const customFeesCombined = useMemo(() => {
        return getRolledUpBulkCustomFeeLines(bulks || []);
    }, [bulks]);

    if (_.isEmpty(customFeesCombined)) {
        return null;
    }

    return (
        <>
            <Typography style={{ marginTop: theme.spacing.unit }}>Extra Fees</Typography>
            <Table>
                <TableBody>
                    {customFeesCombined.map((customFee, index) => (
                        <TableRow key={index} style={{ height: 36 }}>
                            <TableCell style={{ paddingTop: 0, paddingBottom: 0 }}>{customFee.description}</TableCell>
                            <TableCell style={{ paddingTop: 0, paddingBottom: 0 }}>
                                <span
                                    style={{
                                        color: customFee.amount > 0 ? colors.red[500] : theme.palette.text.primary
                                    }}
                                >
                                    {formatAsCurrency(-customFee.amount)}
                                </span>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </>
    );
}

export default withTheme()(BulkCustomFeesTable);

function getRolledUpBulkCustomFeeLines(bulks) {
    let newCustomFeesLines = [];

    if (_.isNil(bulks) || _.isEmpty(bulks)) return newCustomFeesLines;

    for (let bulk of bulks) {
        if (!_.isNil(bulk.customFees) && !_.isEmpty(bulk.customFees)) {
            newCustomFeesLines = newCustomFeesLines.concat(bulk.customFees);
        }
    }

    return _.filter(newCustomFeesLines, line => {
        return line.amount !== 0;
    });
}
