import React from 'react';

import { Typography } from '@material-ui/core';

import { logError } from 'utils/reporter';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, info) {
        logError(error);
        // TODO: You can also log the error to an error reporting service
        //logErrorToMyService(error, info);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <div style={{ padding: 8, paddingTop: '40vh' }}>
                    <Typography align="center" variant="h4" style={{ marginBottom: 8 }}>
                        Something went wrong!
                    </Typography>
                    <Typography align="center" variant="subtitle1">
                        We apologize for the inconvenience. Our team has been automatically
                        notified of the error.
                    </Typography>
                </div>
            );
        }
        return this.props.children;
    }
}

export default ErrorBoundary;
