import * as terms from 'localizations/terms';

export const pageTitle = [
    'registrationType1',
    process.env.REACT_APP_REGION_EXT === 'EXP' ? 'registrationType2a' : 'registrationType2',
    'registrationType3',
    'registrationType4',
    'registrationType5',
    'registrationType2',
    'registrationType6'
];

export const registrationCompleteText = [
    'Congratulations! Your account has been created. Please check your inbox to verify your e-mail.',
    `Congratulations! Your ${terms.ORGANIZATION_NAME.toLowerCase()} has been created. We will send you an e-mail after we verify your application. You can log into your account to make any further changes.`
];

export const oAuthRegistrationCompleteText = [
    "Congratulations! Your account has been linked to your Google account! To log in, select 'Sign in With Google' on the login page.",
    "Congratulations! Your account has been linked to your Facebook account! To log in, select 'Sign in With Facebook' on the login page."
];

export const descriptions = [
    process.env.REACT_APP_REGION_EXT === 'EXP' ? 'registrationDescriptions6' : 'registrationDescriptions1',
    `registrationDescriptions2`,
    'registrationDescriptions3',
    process.env.REACT_APP_REGION_EXT === 'EXP' ? 'registrationDescriptions5' : 'registrationDescriptions4',
    '',
    `registrationDescriptions2`
];
