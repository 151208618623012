import { useState } from 'react';

function usePopover() {
    const [anchorEl, setAnchorEl] = useState(undefined);

    const handleOpen = e => {
        setAnchorEl(e.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(undefined);
    };

    return {
        anchorEl,
        handleOpen,
        handleClose
    };
}

export default usePopover;
