import React from 'react';
import _ from 'lodash';

import { Icon } from '@material-ui/core';
import { colors } from '@material-ui/core';
import { withTheme } from '@material-ui/core';

import { Avatar } from '@material-ui/core';
import charityTaskHelper from 'helpers/charityTaskHelper';

function StepperIcon(props) {
    const { isCurrentTask, stepIndex, charityFeatured, charityTask, theme } = props;

    const isFeaturedTask = charityTask.task.featuredCharityTask;
    const complete = charityTaskHelper.isComplete(charityTask);
    const expired = charityTaskHelper.isExpired(charityTask);

    if ((!isFeaturedTask && _.isNil(charityTask.lastSubmission)) || (isFeaturedTask && !charityFeatured)) {
        return _.get(charityTask, 'task.notCompletable', false) ? (
            <Icon style={{ fontSize: 30, color: isCurrentTask ? theme.palette.linkColor : theme.palette.linkColor }}>
                info
            </Icon>
        ) : (
            <Avatar
                style={{
                    width: 28,
                    height: 28,
                    backgroundColor: isCurrentTask ? colors.grey[600] : colors.grey[400]
                }}
            >
                {stepIndex}
            </Avatar>
        );
    } else if (complete || (isFeaturedTask && charityFeatured)) {
        return (
            <Icon style={{ color: isCurrentTask ? colors.green[500] : colors.green[300], fontSize: 30 }}>
                check_circle
            </Icon>
        );
    } else if (expired) {
        return <Icon style={{ color: isCurrentTask ? colors.red[500] : colors.red[300], fontSize: 30 }}>event</Icon>;
    } else if (!charityTask.lastSubmission.reviewed) {
        return (
            <Avatar
                style={{
                    width: 28,
                    height: 28,
                    backgroundColor: isCurrentTask ? colors.orange[600] : colors.orange[400]
                }}
            >
                {stepIndex}
            </Avatar>
        );
    } else {
        return (
            <Avatar
                style={{
                    width: 28,
                    height: 28,
                    backgroundColor: isCurrentTask ? colors.red[600] : colors.red[400]
                }}
            >
                {stepIndex}
            </Avatar>
        );
    }
}

export default withTheme()(StepperIcon);
