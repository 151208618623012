import React, { useContext } from 'react';
import _ from 'lodash';
import { Button, Dialog, Typography, Card, CardActions, CardContent, Icon } from '@material-ui/core';
import * as colors from '@material-ui/core/colors';

import { withTheme } from '@material-ui/core/styles';
import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

function AfterCompleteDialog(props) {
    const { open, afterCompleteDialogTitle, afterCompleteDialogContent, afterCompleteDialogExamples } = props;
    const { lang } = useContext(LocalizationContext);

    return (
        <Dialog open={open} onClose={props.onClose} maxWidth="sm" fullWidth>
            <Card style={{ width: '100%', backgroundColor: colors.blue[400] }}>
                <CardContent>
                    <div style={{ textAlign: 'center' }}>
                        <Icon style={{ color: '#fff', fontSize: 75, marginBottom: 8 }} fontSize="large">
                            help
                        </Icon>
                    </div>
                    <Typography variant="h5" gutterBottom style={{ textAlign: 'center', color: '#fff' }}>
                        {_.get(afterCompleteDialogTitle, `${lang}`, '').toUpperCase()}
                    </Typography>
                    <Typography variant="body1" gutterBottom style={{ color: '#fff' }}>
                        {_.get(afterCompleteDialogContent, `${lang}`, '')}
                        {!_.isNil(_.get(afterCompleteDialogExamples, `${lang}`, null)) &&
                            _.get(afterCompleteDialogExamples, `${lang}`, []).map(example => (
                                <li style={{ margin: 8, marginLeft: 16 }}>{example}</li>
                            ))}
                    </Typography>
                </CardContent>
                <CardActions style={{ justifyContent: 'flex-end' }}>
                    <Button
                        style={{ color: '#fff' }}
                        id="aftercompletedialog-close"
                        data-cy="aftercompletedialog-close"
                        onClick={props.onClose}
                    >
                        {loc('dismiss', lang)}
                    </Button>
                </CardActions>
            </Card>
        </Dialog>
    );
}

export default withTheme()(AfterCompleteDialog);
