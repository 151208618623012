import React, { useState, useContext } from 'react';

import _ from 'lodash';

import { formatAsCurrency } from 'utils/misc';

import * as colors from '@material-ui/core/colors';
import Paper from '@material-ui/core/Paper';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withTheme } from '@material-ui/core/styles';

import {
    // Dialog,
    // DialogTitle,
    // DialogContent,
    // DialogContentText,
    // DialogActions,
    // Table,
    // TableBody,
    // TableRow,
    // TableCell,
    withMobileDialog,
    TableHead
} from '@material-ui/core';

import SnackbarContext from 'components/CustomSnackbar/SnackbarContext';

import { _charity } from 'std';

import { Clipboard } from '@capacitor/clipboard';

function BottleDriveWidget(props) {
    const { theme, elevated, bottleDrives, charities, fullScreen, style } = props;

    const onSnackbar = useContext(SnackbarContext);

    const latestBottleDrive = _.last(bottleDrives);
    // const latestBottleDriveCharity = _.find(
    //     charities,
    //     charity => _charity.getId(charity) === _.get(latestBottleDrive, 'charity')
    // );

    // const [bottleDrivesDialogOpen, setBottleDrivesDialogOpen] = useState(false);

    const handleCopyToClipboard = string => {
        Clipboard.write({
            string
        });
        onSnackbar('Copied share link to your clipboard.');
    };

    return (
        <>
            {bottleDrives.map(bottleDrive => {
                const bottleDriveCharity = _.find(
                    charities,
                    charity => _charity.getId(charity) === _.get(bottleDrive, 'charity')
                );
                return (
                    <Paper
                        elevated={elevated ? 4 : 2}
                        style={{
                            width: `calc(100% - ${theme.spacing.unit * 4}px)`,
                            margin: theme.spacing.unit * 2,
                            padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px`,
                            backgroundColor: theme.palette.primary.main,
                            ...style
                        }}
                    >
                        <Typography
                            variant="h5"
                            style={{
                                fontWeight: 300,
                                marginBottom: theme.spacing.unit * 2,
                                color: 'white'
                            }}
                        >
                            {_.get(bottleDrive, 'name')}
                        </Typography>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                marginRight: -theme.spacing.unit * 1.5
                            }}
                        >
                            <div style={{ overflow: 'hidden' }}>
                                <tr style={{ fontWeight: 300, verticalAlign: 'middle', color: 'white' }}>
                                    <td style={{ lineHeight: 0, width: 32, color: 'white' }}>
                                        <Icon
                                            style={{
                                                marginRight: theme.spacing.unit,
                                                display: 'flex',
                                                flexDirection: 'column'
                                            }}
                                        >
                                            favorite
                                        </Icon>
                                    </td>
                                    <td>
                                        <Typography variant="body1" style={{ color: 'white' }}>
                                            {_charity.getName(bottleDriveCharity)}
                                        </Typography>
                                    </td>
                                </tr>
                                <tr style={{ fontWeight: 300, verticalAlign: 'middle', color: 'white' }}>
                                    <td style={{ lineHeight: 0, width: 32 }}>
                                        <Icon
                                            style={{
                                                marginRight: theme.spacing.unit,
                                                display: 'flex',
                                                flexDirection: 'column'
                                            }}
                                        >
                                            person
                                        </Icon>
                                    </td>
                                    <td>
                                        <Typography variant="body1" style={{ color: 'white' }}>
                                            {_.get(bottleDrive, 'donations')} donations
                                        </Typography>
                                    </td>
                                </tr>
                                {_.get(bottleDrive, 'isActive') ? (
                                    ''
                                ) : (
                                    <tr style={{ fontWeight: 300, verticalAlign: 'middle', color: 'white' }}>
                                        <td style={{ lineHeight: 0, width: 32 }}>
                                            <Icon
                                                style={{
                                                    marginRight: theme.spacing.unit,
                                                    display: 'flex',
                                                    flexDirection: 'column'
                                                }}
                                            >
                                                close
                                            </Icon>
                                        </td>
                                        <td>
                                            <Typography variant="body1" style={{ color: 'white' }}>
                                                This bottle drive is no longer active
                                            </Typography>
                                        </td>
                                    </tr>
                                )}
                                <Typography
                                    variant="h5"
                                    style={{
                                        fontWeight: 300,
                                        color: 'white'
                                    }}
                                    data-cy="bottle-drive-widget-balance"
                                >
                                    {process.env.REACT_APP_CURRENCY.substr(0, 2)}{' '}
                                    {formatAsCurrency(_.get(bottleDrive, 'balance', 0))}
                                </Typography>
                            </div>
                            <div
                                style={{
                                    borderLeft: '1px solid white',
                                    paddingLeft: theme.spacing.unit,
                                    paddingRight: theme.spacing.unit,
                                    marginLeft: theme.spacing.unit
                                }}
                            >
                                <Button
                                    onClick={() =>
                                        handleCopyToClipboard(
                                            `${process.env.REACT_APP_ORIGIN_URL}/${_charity.getUrl(
                                                bottleDriveCharity,
                                                _.get(bottleDrive, 'name')
                                            )}`
                                        )
                                    }
                                    style={{ color: 'white' }}
                                >
                                    Share
                                </Button>
                            </div>
                        </div>
                    </Paper>
                );
            })}
            {/* <Paper
                elevated={elevated ? 4 : 2}
                style={{
                    width: `calc(100% - ${theme.spacing.unit * 4}px)`,
                    margin: theme.spacing.unit * 2,
                    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px`,
                    ...style
                }}
            >
                <Typography
                    variant="h5"
                    style={{
                        fontWeight: 300,
                        marginBottom: theme.spacing.unit * 2
                    }}
                >
                    Your Bottle Drives
                </Typography>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginRight: -theme.spacing.unit * 1.5
                    }}
                >
                    <div style={{ overflow: 'hidden' }}>
                        <Typography
                            variant="h5"
                            style={{
                                fontWeight: 300
                            }}
                        >
                            {process.env.REACT_APP_CURRENCY.substr(0, 2)}{' '}
                            {formatAsCurrency(_.get(latestBottleDrive, 'balance', 0))}
                        </Typography>

                        <Typography variant="body1" style={{ fontWeight: 300 }}>
                            Donated to {_charity.getName(latestBottleDriveCharity)} (
                            {_.get(latestBottleDrive, 'name', '')})
                        </Typography>
                    </div>
                    <div
                        style={{
                            borderLeft: '1px solid ' + theme.palette.text.hint,
                            paddingLeft: theme.spacing.unit,
                            paddingRight: theme.spacing.unit,
                            marginLeft: theme.spacing.unit
                        }}
                    >
                        <Button
                            onClick={() =>
                                handleCopyToClipboard(
                                    `${process.env.REACT_APP_ORIGIN_URL}/${_charity.getUrl(
                                        latestBottleDriveCharity,
                                        _.get(latestBottleDrive, 'name')
                                    )}`
                                )
                            }
                            color="primary"
                        >
                            Share
                        </Button>

                        <Button color="primary" onClick={() => setBottleDrivesDialogOpen(true)}>
                            View All
                        </Button>
                    </div>
                </div>
            </Paper>
            <Dialog fullScreen={fullScreen} fullWidth open={bottleDrivesDialogOpen}>
                <DialogTitle>Your Bottle Drives</DialogTitle>
                <DialogContent>
                    <Table style={{ tableLayout: 'fixed' }}>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    style={{ paddingLeft: theme.spacing.unit, paddingRight: theme.spacing.unit }}
                                >
                                    Name
                                </TableCell>
                                <TableCell
                                    style={{ paddingLeft: theme.spacing.unit, paddingRight: theme.spacing.unit }}
                                >
                                    Total Donations
                                </TableCell>
                                <TableCell
                                    style={{ paddingLeft: theme.spacing.unit, paddingRight: theme.spacing.unit }}
                                >
                                    {terms.ORGANIZATION_NAME}
                                </TableCell>
                                <TableCell
                                    style={{ paddingLeft: theme.spacing.unit, paddingRight: theme.spacing.unit }}
                                >
                                    Share links
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {bottleDrives.map(bottleDrive => {
                                const bottleDriveCharity = _.find(
                                    charities,
                                    charity => _charity.getId(charity) === _.get(bottleDrive, 'charity')
                                );
                                return (
                                    <TableRow>
                                        <TableCell
                                            style={{
                                                borderBottom: 'none',
                                                paddingLeft: theme.spacing.unit,
                                                paddingRight: theme.spacing.unit
                                            }}
                                        >
                                            {_.get(bottleDrive, 'name', '')}
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                borderBottom: 'none',
                                                paddingLeft: theme.spacing.unit,
                                                paddingRight: theme.spacing.unit
                                            }}
                                        >
                                            {formatAsCurrency(_.get(bottleDrive, 'balance', 0))}
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                borderBottom: 'none',
                                                paddingLeft: theme.spacing.unit,
                                                paddingRight: theme.spacing.unit
                                            }}
                                        >
                                            {_charity.getName(bottleDriveCharity)}
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                borderBottom: 'none',
                                                paddingLeft: theme.spacing.unit,
                                                paddingRight: theme.spacing.unit
                                            }}
                                        >
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                onClick={() =>
                                                    handleCopyToClipboard(
                                                        `${process.env.REACT_APP_ORIGIN_URL}/${_charity.getUrl(
                                                            bottleDriveCharity,
                                                            _.get(bottleDrive, 'name')
                                                        )}`
                                                    )
                                                }
                                            >
                                                Share
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setBottleDrivesDialogOpen(false)} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog> */}
        </>
    );
}

export default withMobileDialog({ breakpoint: 'xs' })(withTheme()(BottleDriveWidget));
