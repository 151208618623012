import React, { Fragment, useState, useEffect, useContext, useMemo } from 'react';

import _ from 'lodash';
import moment from 'moment-timezone';
import { _user, _time } from 'std';

import { BOTTLE_DRIVE, AVAILABLE_FREQUENCIES, PICKUP_TIME_OF_DAY_PREFERENCES } from 'constants.js';

import CustomFormTitle from 'components/MaterialUIExtensions/CustomFormTitle';
import DatePicker from 'components/DateTimePickersTz/DatePicker';
import TimePicker from 'material-ui-pickers/TimePicker';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import LockIcon from '@material-ui/icons/Lock';

import {
    DialogContentText,
    OutlinedInput,
    LinearProgress,
    Fade,
    InputAdornment,
    Icon,
    DialogActions,
    DialogContent,
    FormControl,
    MenuItem,
    Select,
    InputLabel,
    TextField,
    Button,
    Typography,
    Switch,
    colors,
    Grid,
    FormHelperText,
    Tooltip
} from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import OperatorContext from 'utils/contexts/OperatorContext';
import HttpContext from 'utils/contexts/HttpContext';

import ReceiversSelectDropdown from 'components/DropDownSelects/Receivers';
import { withTheme } from '@material-ui/core/styles';

import useGetJSON from 'utils/hooks/useGetJSON';
import { getReceiverName } from 'utils/misc';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc, locDate } from 'localizations/localizationHandler';

const SUNDAY = 0;
const MONDAY = 1;
const TUESDAY = 2;
const WEDNESDAY = 3;
const THURSDAY = 4;
const FRIDAY = 5;
const SATURDAY = 6;

const weekdayName = [];
weekdayName[MONDAY] = 'Monday';
weekdayName[TUESDAY] = 'Tuesday';
weekdayName[WEDNESDAY] = 'Wednesday';
weekdayName[THURSDAY] = 'Thursday';
weekdayName[FRIDAY] = 'Friday';
weekdayName[SATURDAY] = 'Saturday';
weekdayName[SUNDAY] = 'Sunday';

const DateComponent = props => {
    const operator = useContext(OperatorContext);
    const http = useContext(HttpContext);
    const { lang } = useContext(LocalizationContext);

    const {
        theme,
        unserviceable,
        inProgress,
        pickupForm,
        onBack,
        onNext,
        onChange,
        onTimeOverride,
        onDateChange,
        onReceiverChange,
        onTimePreferenceChange,
        onOverrideProcessorChange,
        onOverrideTransporterChange,
        onEfficiencyModifierChange,
        onGenericError,
        customer,
        isSystemAdmin,
        showTimeOverride,
        showBypassZoneOptions,
        onBypassChange,
        availableBookings,
        availableTimeSlots,
        showTimeSlots,
        handleAvailableTimeSlots,
        toggleBottleDrive,
        togglePlaceAtStartOfTrip,
        driversAvailable,
        collectorsAvailable,
        minFrequency,
        minFrequencyPickupTypes
    } = props;

    const {
        date,
        availableDates,
        recurring,
        frequency,
        pickupType,
        trip,
        zone,
        receiver,
        receivers,
        customerNextPickupDate,
        alwaysBypassZoneChecks,
        location,
        collector,
        transporterCollector,
        driver,
        isBottleDrive,
        timePreference,
        efficiencyModifier,
        overrideProcessor,
        overrideTransporter,
        promos,
        placeAtStartOfTrip
    } = pickupForm;

    const frequencies = getFrequencies(pickupType, zone, minFrequency, minFrequencyPickupTypes, isSystemAdmin);

    const {
        data: { pickupMessage = null }
    } = useGetJSON(`/config/pickupMessage`);

    const [loading, setLoading] = useState(false);
    const [showTripSelect, setShowTripSelector] = useState(false);
    const [tripsAvailable, setTripsAvailable] = useState([]);

    //displayed to admin
    const communityDaysOfTheWeek = generateCommunityDaysOfTheWeek(zone, onGenericError, lang); // TODO: handle multiday zones

    let datePickerHelperText;
    if (unserviceable && _.isNil(zone)) {
        datePickerHelperText = loc('pickupDialog67', lang);
    } else if (_.isNil(communityDaysOfTheWeek)) {
        datePickerHelperText = '';
    } else {
        let allowedDaysOfTheWeekCopy = _.cloneDeep(customer.allowedDaysOfTheWeek) || [];
        if (!_.isNil(allowedDaysOfTheWeekCopy)) {
            allowedDaysOfTheWeekCopy.sort((a, b) => {
                const ordering = {
                    Sunday: 0,
                    Monday: 1,
                    Tuesday: 2,
                    Wednesday: 3,
                    Thursday: 4,
                    Friday: 5,
                    Saturday: 6
                };
                return ordering[a] - ordering[b];
            });
        } else {
            allowedDaysOfTheWeekCopy = [];
        }

        // TODO: generate better text (include multiday zones support)
        datePickerHelperText = showTimeSlots ? '' : loc('pickupDialog12', lang, { days: communityDaysOfTheWeek });
    }

    const allowedDaysOfTheWeekCopy = useMemo(() => {
        let temp = _.cloneDeep(customer.allowedDaysOfTheWeek) || [];
        if (!_.isNil(temp)) {
            temp.sort((a, b) => {
                const ordering = {
                    Sunday: 0,
                    Monday: 1,
                    Tuesday: 2,
                    Wednesday: 3,
                    Thursday: 4,
                    Friday: 5,
                    Saturday: 6
                };
                return ordering[a] - ordering[b];
            });
        } else {
            temp = [];
        }
    }, [customer]);

    //const customTimeAdjusted = _.isNil(customTime) ? null : customTime; // weird fix for TimePicker, if its undefined it acts weird, but null its ok

    const handleFrequency = e => {
        let value = e.target.value;

        if (_.isNil(value)) {
            // if null, that means recurring is off
            onChange({ target: { value: false, name: 'recurring' } });
        } else if (!recurring) {
            // else turn it back on
            onChange({ target: { value: true, name: 'recurring' } });
        }
        onChange({ target: { value, name: e.target.name } });
    };

    const getTripsOnDate = async () => {
        const timezone = _.get(location, 'timezone', process.env.REACT_APP_REGION_TIMEZONE);
        const dateInteger = _time.getDateInteger(timezone, date);

        const res = await http.getJSON(`/trips/tripsOnDate?dateInteger=${dateInteger}&timezone=${timezone}`);

        if (res.ok) {
            const sortedTrips = _.sortBy(res.data, t => t.collector.code);
            setTripsAvailable(sortedTrips);
        }
    };

    useEffect(() => {
        if (isSystemAdmin) {
            const isToday = moment(date)
                .tz(_.get(location, 'timezone', process.env.REACT_APP_REGION_TIMEZONE))
                .isSame(new Date(), 'day');
            setShowTripSelector(isToday);
            if (isToday && _.isEmpty(tripsAvailable)) {
                getTripsOnDate();
            }
        }

        // Update time slot options when date changes
        if (showTimeSlots) {
            const timezone = _.get(zone, 'timezone', process.env.TIMEZONE);

            const objForSelectedDate = _.find(availableBookings, availableDate => {
                const dateToCheck = moment(availableDate.day).tz(timezone);
                const selectedDateAsMoment = moment(date).tz(timezone);

                return dateToCheck.isSame(selectedDateAsMoment, 'day');
            });

            const timeSlotsForSelectedDate = _.get(objForSelectedDate, 'availableTimes', []);

            // Sets default time to first available timeslot
            if (!_.isEmpty(timeSlotsForSelectedDate)) {
                onDateChange(momentForLocation(timeSlotsForSelectedDate[0], location));
            }

            handleAvailableTimeSlots(timeSlotsForSelectedDate);
        }
    }, [moment(date).format('LL')]);

    const bypassZoneDateString = locDate(
        date,
        'MMMM Do',
        lang,
        _.get(location, 'timezone', process.env.REACT_APP_REGION_TIMEZONE)
    );

    const driversSelectable = useMemo(
        () =>
            _(driversAvailable)
                .filter(
                    driver =>
                        (!driver.banned && driver.collector._id === transporterCollector) ||
                        driver._id.toString() === driver
                )
                .sortBy(driver => driver.name.first.toLowerCase())
                .value(),
        [driversAvailable, transporterCollector]
    );

    return (
        <Fragment>
            <DialogContent>
                {_user.isSystemAdmin(operator) ||
                    (_user.isCollectorAdmin(operator) && (
                        <>
                            <CustomFormTitle titleText="Driver" iconName="local_shipping" />
                            <FormControl
                                fullWidth
                                data-cy="pickupdialog-receiver-select"
                                style={{ marginBottom: theme.spacing.unit * 2 }}
                            >
                                <ReceiversSelectDropdown
                                    id="receiver-dropdown"
                                    value={receiver}
                                    receivers={receivers}
                                    cypress_id="trip-list-receiver-dropdown"
                                    onChange={onReceiverChange}
                                    showAll={false}
                                />
                            </FormControl>
                        </>
                    ))}
                {!_.some(promos, { oneDayOnly: true }) && (
                    <CustomFormTitle titleText={loc('pickupDialog35a', lang)} iconName="alarm" />
                )}
                {_.some(promos, { oneDayOnly: true }) && (
                    <CustomFormTitle
                        titleText={loc('pickupDialog35b', lang)}
                        iconName="warning"
                        style={{ marginTop: theme.spacing.unit * 2 }}
                        iconStyle={{ color: colors.red[500] }}
                    />
                )}
                <FormControl fullWidth>
                    <DatePicker
                        variant="outlined"
                        timezone={_.get(location, 'timezone', null)} // this is set on the api end
                        value={date}
                        availableDates={
                            /*showTimeSlots
                                ? availableBookings.map(availableDate => _.get(availableDate, 'day'))
                                : */ availableDates
                        }
                        helperText={pickupType !== BOTTLE_DRIVE ? datePickerHelperText : ''}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Icon>date_range</Icon>
                                </InputAdornment>
                            )
                        }}
                        onChange={onDateChange}
                        disabled={_.some(promos, { oneDayOnly: true })}
                        disableTimeChange={isSystemAdmin && showTimeOverride && !showTimeSlots}
                    />
                </FormControl>
                {!isBottleDrive && (
                    <>
                        <CustomFormTitle
                            style={{ marginTop: 5, marginBottom: theme.spacing.unit }}
                            titleText={loc('pickupDialog15', lang)}
                            iconName="autorenew"
                        />
                        <FormControl
                            fullWidth
                            data-cy="pickupdialog-frequency-select"
                            style={{ marginTop: theme.spacing.unit * 0 }}
                        >
                            <InputLabel variant="outlined" htmlFor="frequency">
                                {loc('pickupDialog16', lang)}
                            </InputLabel>
                            <Select
                                data-cy="multistep-dialog-recurring-select"
                                value={frequency || 0}
                                renderValue={value => getFrequencyText(value, lang)}
                                onChange={handleFrequency}
                                disabled={pickupType === BOTTLE_DRIVE || isBottleDrive}
                                IconComponent={pickupType === BOTTLE_DRIVE ? LockIcon : ArrowDropDownIcon}
                                input={
                                    <OutlinedInput
                                        notched
                                        labelWidth={78}
                                        name="frequency"
                                        id="frequency"
                                        className="select-icon"
                                    />
                                }
                            >
                                <MenuItem
                                    key="onetime-pickupdialog-frequency-li"
                                    value={null}
                                    data-cy={`onetime-pickupdialog-frequency-li`}
                                >
                                    {loc('pickupDialog17', lang)}
                                </MenuItem>
                                {frequencies.map((frequency, index) => {
                                    return (
                                        <MenuItem
                                            key={frequency}
                                            value={frequency}
                                            data-cy={`${index}-pickupdialog-frequency-li`}
                                        >
                                            {getFrequencyText(frequency, lang)}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </>
                )}
                {showTimeSlots && (
                    <>
                        {!_.isEmpty(availableTimeSlots) ? (
                            <FormControl fullWidth margin="normal">
                                <InputLabel variant="outlined" htmlFor="time-select">
                                    Time
                                </InputLabel>
                                <Select
                                    value={date.toISOString()}
                                    onChange={e => onDateChange(momentForLocation(e.target.value, location))}
                                    input={<OutlinedInput labelWidth={36} name="time" id="time-select" />}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <Icon>schedule</Icon>
                                        </InputAdornment>
                                    }
                                >
                                    {availableTimeSlots.map(time => {
                                        // selector uses iso strings for values
                                        // onChange converts the iso strings to moment objects
                                        return (
                                            <MenuItem value={time} key={time}>
                                                {momentForLocation(time, location).format('h:mm A')}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        ) : (
                            <Typography
                                variant="caption"
                                style={{ color: colors.red[500], margin: theme.spacing.unit / 2 }}
                            >
                                <b>{loc('pickupDialog66', lang)}</b>
                            </Typography>
                        )}
                    </>
                )}
                {!_.isNil(pickupMessage) && !_.isEmpty(pickupMessage) && (
                    <DialogContentText style={{ marginBottom: theme.spacing.unit }}>
                        <strong>{pickupMessage}</strong>
                    </DialogContentText>
                )}
                {!isSystemAdmin && pickupType !== BOTTLE_DRIVE && (
                    <FormControl fullWidth>
                        <TextField
                            id="outlined-name"
                            label={loc('pickupDialog13', lang)}
                            value="8AM & 5PM"
                            margin="normal"
                            disabled
                            variant="outlined"
                            InputProps={{
                                readOnly: true
                            }}
                            helperText={loc('pickupDialog14', lang)}
                            style={{
                                marginBottom: theme.spacing.unit * 3,
                                marginTop: theme.spacing.unit * 2
                            }}
                        />
                    </FormControl>
                )}
                {isSystemAdmin && showBypassZoneOptions && !showTimeSlots && !isBottleDrive && (
                    <>
                        <CustomFormTitle
                            titleText={`Heads up! ${bypassZoneDateString} is not when this area is picked up. Allow this pickup day?`}
                            iconName="warning"
                            style={{ marginTop: theme.spacing.unit }}
                            iconStyle={{ color: colors.red[500] }}
                        />

                        <FormControl
                            fullWidth
                            data-cy="pickupdialog-bypass-select"
                            style={{ marginBottom: theme.spacing.unit * 2 }}
                        >
                            <InputLabel variant="outlined">Bypass Zone Check</InputLabel>
                            <Select
                                disabled={isBottleDrive}
                                data-cy="multistep-dialog-bypass-select"
                                value={alwaysBypassZoneChecks ? 'bypassAlways' : 'bypassOnce'}
                                onChange={e => onBypassChange(e.target.value, true)}
                                input={
                                    <OutlinedInput
                                        notched
                                        labelWidth={'Bypass Zone Check '.length * theme.spacing.unit}
                                        name="bypass"
                                        id="bypass"
                                        className="select-icon"
                                    />
                                }
                            >
                                <MenuItem key="bypassOnce" value="bypassOnce" data-cy={`bypass-pickupdialog-li`}>
                                    Just Once
                                </MenuItem>
                                <MenuItem
                                    key="bypassAlways"
                                    value="bypassAlways"
                                    data-cy={`always-bypass-pickupdialog-li`}
                                >
                                    Always
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </>
                )}
            </DialogContent>
            <DialogActions style={{ marginBottom: 'max(8px, env(safe-area-inset-bottom, 8px))' }}>
                <Button color="primary" onClick={onBack} data-cy="pickup-dialog-date-back">
                    {loc('back', lang)}
                </Button>
                <Button color="primary" disabled={loading} onClick={onNext} data-cy="2-pickupdialog-next-button">
                    {loc('next', lang)}
                </Button>
            </DialogActions>
            <Fade in={inProgress}>
                <LinearProgress />
            </Fade>
        </Fragment>
    );
};

export default withTheme()(DateComponent);

function getFrequencies(pickupType, zone, minFrequency = 1, minFrequencyPickupTypes = [], isAdmin = false) {
    const frequencies = _.get(zone, 'frequency') ? AVAILABLE_FREQUENCIES[zone.frequency] : [];

    if (isAdmin || !minFrequencyPickupTypes.includes(pickupType)) {
        return frequencies;
    }

    const minFrequencyInDays = minFrequency * 7;

    return _.filter(frequencies, frequency => frequency >= minFrequencyInDays);
}

function getFrequencyText(frequency, lang) {
    if (_.isNil(frequency) || frequency < 1) return loc('pickupDialog17', lang);
    let text = '';
    if (frequency === 7) {
        text = loc('pickupDialog18', lang);
    } else {
        text = loc('pickupDialog19', lang, { frequencyWeeks: frequency / 7 });
    }
    if (frequency <= 7) {
        text += ' ' + loc('pickupDialog20', lang);
    } else if (frequency === 70) {
        text += ' ' + loc('pickupDialog21', lang);
    } else if (frequency === 84) {
        text += ' ' + loc('pickupDialog22', lang);
    } else if (frequency === 112) {
        text += ' ' + loc('pickupDialog23', lang);
    }
    return text;
}

function generateCommunityDaysOfTheWeek(zone, onGenericError, lang) {
    try {
        const dates = _.get(zone, 'startingDates', []).map(date => locDate(date, 'ddd', lang, zone.timezone));
        if (_.isEmpty(dates)) return undefined;
        if (dates.length === 1) {
            return locDate(_.first(zone.startingDates), 'dddd', lang, zone.timezone);
        } else {
            return dates.join(', ');
        }
    } catch (e) {
        console.error(e);
        return onGenericError();
    }
}

function momentForLocation(date, location) {
    return moment(date).tz(_.get(location, 'timezone', process.env.REACT_APP_REGION_TIMEZONE));
}
