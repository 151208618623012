import { useEffect, useRef, useCallback } from 'react';

// checks if the component is mounted or not, can be removed in a future version of react
const useIsMounted = () => {
    const isMounted = useRef(false);

    useEffect(() => {
        isMounted.current = true;

        return () => {
            isMounted.current = false;
        };
    }, []);

    return useCallback(() => isMounted.current, []);
};

export default useIsMounted;
