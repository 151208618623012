import { useEffect } from 'react';
import { Brightness } from '@ionic-native/brightness';

import { deviceHelper } from 'utils/misc';

const useScreenBrightness = () => {
    useEffect(() => {
        let deviceBrightness = 0;

        if (!deviceHelper.isNativeApp()) {
            return;
        }

        Brightness.getBrightness()
            .then(brightness => {
                deviceBrightness = brightness;
            })
            .then(() => Brightness.setBrightness(1));

        return () => Brightness.setBrightness(deviceBrightness);
    }, []);
};

export default useScreenBrightness;
