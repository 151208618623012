import React from 'react';

import _ from 'lodash';

import { withTheme } from '@material-ui/core/styles';

const Text = props => {
    const { doubleClickAction, singleClickAction, dataCy } = props;

    const toggleStyle = { textDecoration: 'underline', cursor: 'pointer', userSelect: 'none' };

    if (doubleClickAction) {
        return (
            <span onDoubleClick={doubleClickAction} style={toggleStyle} data-cy={dataCy}>
                {props.children}
            </span>
        );
    }
    if (singleClickAction) {
        return (
            <span onClick={singleClickAction} style={toggleStyle} data-cy={dataCy}>
                {props.children}
            </span>
        );
    }
    return <span>{props.children}</span>;
};

const LabeledText = props => {
    const { theme, doubleClickAction, singleClickAction, noBreak, label } = props;

    const labelStyle = { color: theme.palette.text.primary };

    return (
        <>
            <span data-cy={`labeled-text-${_.kebabCase(label)}`}>
                {label && <span style={labelStyle}>{label}</span>}
                <Text 
                    doubleClickAction={doubleClickAction} 
                    singleClickAction={singleClickAction} 
                    dataCy={`labeled-text-btn-${_.kebabCase(label)}`} 
                >
                    {props.children}
                </Text>
            </span>
            {!noBreak && <br />}
        </>
    );
};

export default withTheme()(LabeledText);
