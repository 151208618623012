import React, { useContext } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import Select from 'react-select';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import NoSsr from '@material-ui/core/NoSsr';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import CancelIcon from '@material-ui/icons/Cancel';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import { fullWidth } from 'validator/lib/isFullWidth';
import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from '../../localizations/localizationHandler';

const styles = theme => ({
    input: {
        display: 'flex',
        padding: 0
    },
    valueContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        alignItems: 'center',
        overflow: 'hidden'
    },
    chip: {
        margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`
    },
    chipFocused: {
        backgroundColor: emphasize(
            theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
            0.08
        )
    },
    noOptionsMessage: {
        padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`
    },
    singleValue: {
        fontSize: 16
    },
    placeholder: {
        position: 'absolute',
        left: 2,
        fontSize: 16
    },
    paper: {
        position: 'absolute',
        zIndex: 1,
        marginTop: theme.spacing.unit,
        left: 0,
        right: 0
    }
});

function NoOptionsMessage(props) {
    const { lang } = useContext(LocalizationContext);
    return (
        <Typography color="textSecondary" className={props.selectProps.classes.noOptionsMessage} {...props.innerProps}>
            {loc('noOptions', lang)}
        </Typography>
    );
}

function inputComponent({ inputRef, ...props }) {
    return <div ref={inputRef} {...props} />;
}

function Control(props) {
    return (
        <TextField
            fullWidth
            InputProps={{
                inputComponent,
                inputProps: {
                    className: props.selectProps.classes.input,
                    inputRef: props.innerRef,
                    children: props.children,
                    ...props.innerProps
                }
            }}
            {...props.selectProps.textFieldProps}
        />
    );
}

function Option(props) {
    return (
        <MenuItem
            buttonRef={props.innerRef}
            selected={props.isFocused}
            component="div"
            style={{
                fontWeight: props.isSelected ? 500 : 400
            }}
            {...props.innerProps}
        >
            {props.children}
        </MenuItem>
    );
}

function Placeholder(props) {
    return (
        <Typography color="textSecondary" className={props.selectProps.classes.placeholder} {...props.innerProps}>
            {props.children}
        </Typography>
    );
}

function SingleValue(props) {
    return (
        <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
            {props.children}
        </Typography>
    );
}

function ValueContainer(props) {
    return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function MultiValue(props) {
    return (
        <Chip
            color="primary"
            tabIndex={-1}
            label={props.children}
            className={classNames(props.selectProps.classes.chip, {
                [props.selectProps.classes.chipFocused]: props.isFocused
            })}
            onDelete={props.removeProps.onClick}
            deleteIcon={<CancelIcon {...props.removeProps} />}
        />
    );
}

function Menu(props) {
    return (
        <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
            {props.children}
        </Paper>
    );
}

const components = {
    Control,
    Menu,
    MultiValue,
    NoOptionsMessage,
    Option,
    Placeholder,
    SingleValue,
    ValueContainer
};

//This is modified from the example here https://v3.mui.com/demos/autocomplete/#react-select
function SearchableSelect(props) {
    const {
        value,
        name,
        label,
        placeholder = '',
        selectableValues, //Should be an array of the form [{label: "", value: ""}, {label: "", value: ""}, ...]
        isMulti,
        disabled,
        variant,
        error,
        helperText,
        fullWidth,
        style,
        onChange,
        onBlur,
        classes,
        theme
    } = props;

    const selectStyles = {
        input: base => ({
            ...base,
            color: theme.palette.text.primary,
            '& input': {
                font: 'inherit'
            }
        })
    };

    let objValue;
    if (isMulti) {
        objValue = value.map(v => {
            const valueLabel = _.get(_.find(selectableValues, { value: v }), 'label');
            return { value: v, label: valueLabel };
        });
    } else {
        const valueLabel = _.get(_.find(selectableValues, { value: value }), 'label');
        objValue = { value: value, label: valueLabel };
    }

    return (
        <div style={style}>
            <Select
                classes={classes}
                styles={selectStyles}
                textFieldProps={{
                    label,
                    InputLabelProps: {
                        shrink: true
                    },
                    name,
                    disabled,
                    variant,
                    error,
                    fullWidth,
                    helperText
                }}
                options={selectableValues}
                components={components}
                value={objValue}
                onChange={objValue => {
                    if (isMulti) {
                        onChange(objValue.map(v => v.value));
                    } else {
                        onChange(objValue.value);
                    }
                }}
                onBlur={onBlur}
                placeholder={placeholder}
                isMulti={isMulti}
                isSearchable
            />
        </div>
    );
}

export default withStyles(styles, { withTheme: true })(SearchableSelect);
