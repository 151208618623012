import _ from 'lodash';

import React from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import LinearProgress from '@material-ui/core/LinearProgress';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import FilterListIcon from '@material-ui/icons/FilterList';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import { withTheme } from '@material-ui/core/styles';

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }

    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });

    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

function EnhancedTable(props) {
    const {
        theme,
        title,
        dataset,
        headers,
        loading,
        rowsAreClickable,
        showCreateButton,
        selected = [],
        searchString = '',
        onChangeSelected,
        setAddDialogOpen,
        setSelectedId,
        selectAll,
        onAddClick
    } = props;

    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    //const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    function handleRequestSort(event, property) {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    }

    // function handleAddClick(event, id) {
    //     setAddDialogOpen(true);
    //     setSelectedId(id);
    // }

    function handleSelectAllClick(event) {
        if (event.target.checked) {
            const newSelectedIds = dataset.map(n => n.id);

            return onChangeSelected(newSelectedIds);
        }

        onChangeSelected([]);
    }

    function handleClick(event, id) {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }

        onChangeSelected(newSelected);
    }

    function handleChangePage(event, newPage) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event) {
        setRowsPerPage(event.target.value);
    }

    const isSelected = id => selected.indexOf(id) !== -1;

    const datasetIsKeyed = _.every(dataset, d => !_.isNil(d.id));
    if (!datasetIsKeyed) {
        throw new Error('Dataset is not keyed properly.');
    }

    const splitSearchString = searchString.split(/[ ]+/);
    const splitSearchStringWithoutEmpty = _.filter(splitSearchString, string => string !== '');

    const documentContainsSearchValue = (document, searchValue) => {
        return _.some(headers, column => {
            const value = _.get(document, column.id, '');
            if (_.isNil(value)) return false;
            const formatedString = value
                .toString()
                .toLowerCase()
                .trim();
            return formatedString.includes(searchValue.toLowerCase().trim());
        });
    };
    let filteredData = _.filter(dataset, document => {
        // filter by search string
        for (let searchValue of splitSearchStringWithoutEmpty) {
            if (!documentContainsSearchValue(document, searchValue)) {
                return false;
            }
        }
        return true;
    });

    // const emptyRows = rowsPerPage - Math.min(rowsPerPage, dataset.length - page * rowsPerPage);

    return (
        <React.Fragment>
            <EnhancedTableToolbar
                title={title}
                numSelected={selected.length}
                showCreateButton={showCreateButton}
                onAddClick={onAddClick}
            />
            <div>
                <Table aria-labelledby="tableTitle">
                    <EnhancedTableHead
                        headers={headers}
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={filteredData.length}
                        selectAll={selectAll}
                    />

                    <TableBody>
                        {stableSort(filteredData, getSorting(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map(n => {
                                const isItemSelected = isSelected(n.id);

                                return (
                                    <TableRow
                                        key={n.id}
                                        hover={rowsAreClickable}
                                        role="checkbox"
                                        aria-checked={selectAll && isItemSelected}
                                        tabIndex={-1}
                                        selected={selectAll && isItemSelected}
                                    >
                                        {selectAll && (
                                            <TableCell padding="checkbox" onClick={event => handleClick(event, n.id)}>
                                                <Checkbox checked={isItemSelected} />
                                            </TableCell>
                                        )}
                                        {headers.map(h => {
                                            return (
                                                <TableCell
                                                    key={h.id}
                                                    numeric={h.numeric}
                                                    padding={h.disablePadding ? 'none' : 'default'}
                                                >
                                                    {n[h.id]}
                                                </TableCell>
                                            );
                                        })}
                                        {/* <TableCell padding="default">
                                            <Tooltip title="Edit" onClick={event => handleAddClick(event, n.id)}>
                                                <IconButton aria-label="Edit">
                                                    <EditIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell> */}
                                    </TableRow>
                                );
                            })}
                        {/* {emptyRows > 0 && (
                            <TableRow style={{ height: 49 * emptyRows }}>
                                <TableCell colSpan={6} />
                            </TableRow>
                        )} */}
                    </TableBody>
                </Table>
            </div>

            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={dataset.length}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                    'aria-label': 'Previous Page'
                }}
                nextIconButtonProps={{
                    'aria-label': 'Next Page'
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />

            {loading && <LinearProgress />}
        </React.Fragment>
    );
}

export default EnhancedTable;

function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, headers, selectAll } = props;

    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {selectAll && (
                    <TableCell padding="checkbox">
                        <Checkbox
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={numSelected === rowCount}
                            onChange={onSelectAllClick}
                        />
                    </TableCell>
                )}
                {headers.map(row => (
                    <TableCell
                        key={row.id}
                        numeric={row.numeric}
                        padding={row.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === row.id ? order : false}
                    >
                        <Tooltip title="Sort" placement={row.numeric ? 'bottom-end' : 'bottom-start'} enterDelay={300}>
                            <TableSortLabel
                                active={orderBy === row.id}
                                direction={order}
                                onClick={createSortHandler(row.id)}
                            >
                                {row.label}
                            </TableSortLabel>
                        </Tooltip>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired
};

const EnhancedTableToolbar = props => {
    const { title, numSelected, selectAll, showCreateButton, onAddClick } = props;

    return (
        <Toolbar justify="space-between">
            <div>
                {numSelected > 0 && selectAll ? (
                    <Typography color="inherit" variant="subtitle1">
                        {numSelected} selected
                    </Typography>
                ) : (
                    <Typography variant="h6" id="tableTitle">
                        {title}
                    </Typography>
                )}
            </div>
            <div>
                {selectAll &&
                    (numSelected > 0 ? (
                        <Tooltip title="Delete">
                            <IconButton aria-label="Delete">
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    ) : (
                        <Tooltip title="Filter list">
                            <IconButton aria-label="Filter list">
                                <FilterListIcon />
                            </IconButton>
                        </Tooltip>
                    ))}
            </div>
            {showCreateButton && (
                <div>
                    <Tooltip title="Create" onClick={event => onAddClick(event, null)}>
                        <IconButton aria-label="Create" data-cy="enhanced-table-create">
                            <AddIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            )}
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired
};
