import React, { useState, useContext } from 'react';

import _ from 'lodash';
import moment from 'moment-timezone';

import { _user } from 'std';
import { getCustomerName, formatAsCurrency, deviceHelper, getStateFromAddress } from 'utils/misc';

import Button from '@material-ui/core/Button';
import { Avatar } from '@material-ui/core';
import { Icon, IconButton, Dialog, DialogTitle, DialogActions, DialogContent, Tooltip } from '@material-ui/core';

import { withTheme } from '@material-ui/core/styles';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';
import { openWindow } from 'helpers/windowHelper';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import DirectBankingTab from './AUSTables/DirectBankingTab';
import HistoryTab from './AUSTables/HistoryTab';

function AUSTables(props) {
    const {
        theme,
        redemptions,
        onNonChequeSelection,
        fetchRedemptionsAndBatches,
        selectedNonCheque,
        batches,
        redemptionOptions,
        operator,
        history,
        inProgress,
        onConfirmationDialog
    } = props;

    const { lang } = useContext(LocalizationContext);
    const [openRedemptionDialog, setOpenRedemptionDialog] = useState(false);
    const [batchToView, setBatchToView] = React.useState(undefined);
    const [view, setView] = useState('ausDirectBanking');
    const [selectedHistoryRedemptionId, setSelectedHistoryRedemptionId] = useState(null);

    const disableInAppRedemptionCancel = _.get(redemptionOptions, 'disableInAppRedemptionCancel', false);

    const handleCloseViewBatch = () => setOpenRedemptionDialog(false);

    const handleDownloadSelectedBatch = async batchNumber => {
        let res = await props.http.download(
            `/redemptions/batch/${batchNumber}/download`,
            `${decomposeFilename(batchNumber)}.txt`
        );
        if (!res.ok) {
            props.onSnackbar('Error downloading batch!', 'error');
        }
    };

    const customTableStyles = {
        paddingRight: theme.spacing.unit * 3,
        paddingBottom: theme.spacing.unit / 2
    };
    const iconStyle = { marginRight: theme.spacing.unit, fontSize: 18 };
    const tdIconStyle = { lineHeight: 0, width: 32 };

    let pendingChequeRows = [];
    let directBankingRows = [];
    let reviewChequeRows = [];
    let reviewBTRows = [];

    if (!_.isEmpty(redemptions)) {
        redemptions.forEach(redemption => {
            const passphrase = redemption.passphrase || 'N/A';
            let customerNameText =
                redemption.type === 'Customer' || redemption.type === 'Driver'
                    ? getCustomerName(redemption.claimant)
                    : redemption.charity.name;

            let state = getStateFromRedemption(redemption);

            let value = {
                email: redemption.email,
                name:
                    redemption.type === 'Customer'
                        ? getCustomerName(redemption.claimant)
                        : _.get(redemption, 'charity.name', 'N/A'),
                amount: formatAsCurrency(redemption.amount - redemption.fee),
                type: redemption.type,

                passphrase,
                accountNumberMasked: _.get(redemption, 'banking.accountNumberMasked', 'N/A'),
                state: state
            };

            let underReview = _.get(redemption, 'underReview', false);

            if (underReview) {
                let infractions = _.get(redemption, 'infractions', null);

                let disable = _.isEmpty(infractions) ? false : true;

                if (!_.isEmpty(infractions)) {
                    let unApprovedInfractions = _.filter(infractions, i => i.resolved !== true);

                    if (!_.isEmpty(unApprovedInfractions)) {
                        disable = true;
                    } else {
                        disable = false;
                    }
                }

                value.actions = (
                    <>
                        <Tooltip title="View Customer">
                            <IconButton
                                onClick={() => {
                                    const URL = `/customers/${_.get(redemption, 'claimant._id')}`;
                                    openWindow(history, !deviceHelper.isNativeApp(), URL);
                                }}
                            >
                                <Icon>visibility</Icon>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="View Infraction">
                            <IconButton
                                onClick={() => {
                                    const URL = `/operators/${_user.getId(
                                        operator
                                    )}/infractions?searchTerm=${customerNameText}`;
                                    openWindow(history, !deviceHelper.isNativeApp(), URL);
                                }}
                            >
                                <Icon>assignment_late</Icon>
                            </IconButton>
                        </Tooltip>
                    </>
                );
            }

            switch (redemption.paymentModel) {
                case 'AUS_DIRECT_BANKING':
                    value.method = (
                        <span style={{ color: theme.palette.primary.main, fontWeight: 500 }}>Direct Deposit</span>
                    );
                    if (underReview) {
                        reviewBTRows.push(value);
                    } else {
                        directBankingRows.push(value);
                    }
                    break;
                case 'CHEQUE':
                    value.method = (
                        <span style={{ color: theme.palette.secondary.main, fontWeight: 500 }}>
                            {loc('redemption5', lang)}
                        </span>
                    );
                    if (underReview) {
                        reviewChequeRows.push(value);
                    } else {
                        pendingChequeRows.push(value);
                    }
                    break;
                default:
                    break;
            }
        });
    }

    let batchesRows = [];
    if (!_.isEmpty(batches)) {
        batchesRows = batches.map((batch, index) => {
            return {
                batch: _.get(batch, 'batchNumber', 'N/A'),
                dateCreated: moment(_.get(batch, 'createdAt', 'N/A'))
                    .tz(process.env.REACT_APP_REGION_TIMEZONE)
                    .format('LT MMM Do, YYYY'),
                operatorName: getCustomerName(batch.operator),
                redemptions: batch.redemptions.length,
                totalValue: formatAsCurrency(_.get(batch, 'totalValue', '0')),
                actions: (
                    <React.Fragment>
                        <IconButton
                            color="primary"
                            onClick={async () => {
                                let res = await props.http.getJSON(`/redemptions/batch/${batch._id}`);
                                if (res.ok) {
                                    setBatchToView(res.data.batch);
                                    setOpenRedemptionDialog(true);
                                } else {
                                    props.onSnackbar('Error viewing batch', 'error');
                                }
                            }}
                        >
                            <Icon>visibility</Icon>
                        </IconButton>
                        <IconButton color="primary" onClick={() => handleDownloadSelectedBatch(batch.batchNumber)}>
                            <Icon>save_alt</Icon>
                        </IconButton>
                    </React.Fragment>
                )
            };
        });
    }

    return (
        <React.Fragment>
            <div style={{ padding: theme.spacing.unit * 2, display: 'flex', justifyContent: 'center' }}>
                <ToggleButtonGroup
                    value={view}
                    exclusive
                    onChange={(e, view) => {
                        if (!_.isEmpty(view)) setView(view);
                    }}
                    style={{ display: 'flex', flexWrap: 'wrap' }}
                >
                    <ToggleButton
                        style={{ flexGrow: 1 }}
                        value="ausDirectBanking"
                        data-cy="redemptions-ausDirectBanking-tab"
                    >
                        Direct Deposit
                        <Avatar
                            style={{
                                marginLeft: theme.spacing.unit,
                                backgroundColor: theme.palette.primary.main,
                                height: '24px',
                                width: '24px',
                                fontSize: '12px'
                            }}
                        >
                            {reviewBTRows.length + directBankingRows.length}
                        </Avatar>
                    </ToggleButton>
                    <ToggleButton
                        style={{ flexGrow: 1 }}
                        value="ausDirectBankingHistory"
                        data-cy="redemptions-ausDirectBanking-history-tab"
                    >
                        History
                    </ToggleButton>
                </ToggleButtonGroup>
            </div>

            {view === 'ausDirectBanking' && (
                <DirectBankingTab
                    theme={theme}
                    inProgress={inProgress}
                    fetchRedemptionsAndBatches={fetchRedemptionsAndBatches}
                    reviewBTRows={reviewBTRows}
                    directBankingRows={directBankingRows}
                    onNonChequeSelection={onNonChequeSelection}
                    selectedNonCheque={selectedNonCheque}
                    batchesRows={batchesRows}
                    onConfirmationDialog={onConfirmationDialog}
                    disableInAppRedemptionCancel={disableInAppRedemptionCancel}
                />
            )}

            {view === 'ausDirectBankingHistory' && (
                <HistoryTab
                    theme={theme}
                    inProgress={inProgress}
                    http={props.http}
                    onSnackbar={props.onSnackbar}
                    setBatchToView={setBatchToView}
                    setOpenRedemptionDialog={setOpenRedemptionDialog}
                    lang={lang}
                    setSelectedHistoryRedemptionId={setSelectedHistoryRedemptionId}
                />
            )}

            <Dialog open={openRedemptionDialog} fullWidth maxWidth="sm" onClose={handleCloseViewBatch}>
                <DialogTitle>
                    <strong>Batch: {_.get(batchToView, 'batchNumber', 'N/A')}</strong>
                </DialogTitle>
                <DialogContent>
                    {!_.isNil(batchToView) && (
                        <React.Fragment>
                            <table
                                style={{
                                    textAlign: 'left',
                                    fontFamily: theme.typography.fontFamily,
                                    marginBottom: theme.spacing.unit * 2,
                                    color: theme.palette.text.primary
                                }}
                            >
                                <tbody>
                                    <tr>
                                        <td style={tdIconStyle}>
                                            <Icon color="action" style={iconStyle}>
                                                date_range
                                            </Icon>
                                        </td>
                                        <td>
                                            {moment(_.get(batchToView, 'createdAt', 'N/A'))
                                                .tz(process.env.REACT_APP_REGION_TIMEZONE)
                                                .format('MMMM Do, YYYY')}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={tdIconStyle}>
                                            <Icon color="action" style={iconStyle}>
                                                access_time
                                            </Icon>
                                        </td>
                                        <td>
                                            {moment(_.get(batchToView, 'createdAt', 'N/A'))
                                                .tz(process.env.REACT_APP_REGION_TIMEZONE)
                                                .format('hh:mm:ss A')}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={tdIconStyle}>
                                            <Icon color="action" style={iconStyle}>
                                                person
                                            </Icon>
                                        </td>
                                        <td>{getCustomerName(batchToView.operator)}</td>
                                    </tr>
                                    <tr>
                                        <td style={tdIconStyle}>
                                            <Icon color="action" style={iconStyle}>
                                                payment
                                            </Icon>
                                        </td>
                                        <td>{formatAsCurrency(batchToView.totalValue)}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <table
                                style={{
                                    textAlign: 'left',
                                    fontFamily: theme.typography.fontFamily,
                                    fontSize: 12,
                                    color: theme.palette.text.primary
                                }}
                            >
                                <tbody>
                                    <tr>
                                        <th style={customTableStyles}>Name</th>
                                        <th style={customTableStyles}>Account</th>
                                        <th style={customTableStyles}>Amount</th>
                                        <th style={customTableStyles}>Type</th>
                                    </tr>

                                    {batchToView.redemptions.map((redemption, index) => {
                                        return (
                                            <tr key={index}>
                                                <td style={customTableStyles}>
                                                    {selectedHistoryRedemptionId === redemption._id ? (
                                                        <b>{getCustomerName(redemption.claimant)}</b>
                                                    ) : (
                                                        getCustomerName(redemption.claimant)
                                                    )}
                                                </td>
                                                <td style={customTableStyles}>
                                                    {_.get(redemption, 'banking.accountNumberMasked', 'N/A')}
                                                </td>
                                                <td style={customTableStyles}>{formatAsCurrency(redemption.amount)}</td>
                                                <td style={customTableStyles}>{_.get(redemption, 'type', 'N/A')}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </React.Fragment>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={() => handleDownloadSelectedBatch(batchToView.batchNumber)}>
                        Download Batch
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default withTheme()(AUSTables);

function decomposeFilename(filename) {
    const batchDateString = filename.substring(4, 10);
    const batchPaddedCounterString = filename.substring(10, 12);
    return `Collect ${batchDateString}-${batchPaddedCounterString}`;
}

function getStateFromRedemption(redemption) {
    let state = 'N/A';

    let location = _.get(redemption, 'claimant.location', null);
    if (!_.isNull(location)) {
        if (!_.isNull(_.get(location, 'description', null))) {
            state = getStateFromAddress(_.get(location, 'description'));
        }
    }

    return state;
}
