import React, { useEffect, useState, useContext } from 'react';

import { Typography, withTheme } from '@material-ui/core';

import FakeLink from 'components/FakeLink/FakeLink';
import ExternalLink from 'components/ExternalLink/ExternalLink';
import HttpContext from 'utils/contexts/HttpContext';

import { isEXPRegion, isMXDRegion } from 'utils/misc';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from '../../../localizations/localizationHandler';
import useIsMounted from 'utils/hooks/useIsMounted';

function NewUserInfo({ handleFAQ, handleHowItWorks, theme }) {
    const { lang } = useContext(LocalizationContext);
    const http = useContext(HttpContext);

    const isMounted = useIsMounted();

    const [serviceableAreaLinkEnabled, setServiceableAreaLinkEnabled] = useState(false);
    const [serviceableAreaLink, setServiceableAreaLink] = useState('');
    const [serviceableAreaLinkText, setServiceableAreaLinkText] = useState('');

    const getData = async () => {
        const resServiceableAreaLink = await http.getJSON('/serviceableAreaLink');
        if (resServiceableAreaLink.ok && isMounted()) {
            setServiceableAreaLink(resServiceableAreaLink.data.link);
            setServiceableAreaLinkEnabled(resServiceableAreaLink.data.isEnabled);
            setServiceableAreaLinkText(resServiceableAreaLink.data.text);
        }
    };

    useEffect(() => {
        getData();
    }, [isMounted]);

    if (isMXDRegion() && lang === 'fr') {
        return (
            <Typography style={{ marginTop: theme.spacing.unit }} variant="body2" color="textSecondary">
                {loc('registration1a', lang)}{' '}
                <ExternalLink
                    textInline={true}
                    theme={theme}
                    text={process.env.REACT_APP_BRAND_WEBSITE_NAME}
                    url={process.env.REACT_APP_BRAND_WEBSITE}
                />{' '}
                {loc('registration1b', lang)}{' '}
                <FakeLink theme={theme} text={'FAQ'} onClickEvent={handleFAQ(true)} data-cy="new-user-faq-link" />
                {'.'}
            </Typography>
        );
    }

    return (
        <Typography style={{ marginTop: theme.spacing.unit }} variant="body2" color="textSecondary">
            {loc('registration12', lang)}
            {': '}
            <FakeLink
                theme={theme}
                text={loc('howItWorks', lang)}
                onClickEvent={handleHowItWorks(true)}
                data-cy="new-user-how-it-works-link"
            />
            {', '}
            <FakeLink
                theme={theme}
                text={'Frequently Asked Question'}
                onClickEvent={handleFAQ(true)}
                data-cy="new-user-faq-link"
            />
            {', '}
            <ExternalLink
                textInline={true}
                theme={theme}
                text={process.env.REACT_APP_BRAND_WEBSITE_NAME}
                url={process.env.REACT_APP_BRAND_WEBSITE}
            />
        </Typography>
    );

    // return (
    //     <Typography style={{ marginTop: theme.spacing.unit }} variant="body2" color="textSecondary">
    //         {loc('registration2a', lang)}{' '}
    //         <FakeLink
    //             theme={theme}
    //             text={loc('howItWorks', lang)}
    //             onClickEvent={handleHowItWorks(true)}
    //             data-cy="new-user-how-it-works-link"
    //         />{' '}
    //         {loc('registration2b', lang)}{' '}
    //         <FakeLink
    //             theme={theme}
    //             text={isEXPRegion() ? 'Frequently Asked Questions' : 'FAQ'}
    //             onClickEvent={handleFAQ(true)}
    //             data-cy="new-user-faq-link"
    //         />
    //         . {loc('registration2c', lang)}{' '}
    //         <ExternalLink
    //             textInline={true}
    //             theme={theme}
    //             text={process.env.REACT_APP_BRAND_WEBSITE_NAME}
    //             url={process.env.REACT_APP_BRAND_WEBSITE}
    //         />
    //         {isEXPRegion() && ` ${loc('registration2d', lang)}`}
    //         {'. '}
    //         {serviceableAreaLinkEnabled && (
    //             <ExternalLink
    //                 textInline={true}
    //                 theme={theme}
    //                 text={serviceableAreaLinkText}
    //                 url={serviceableAreaLink}
    //             />
    //         )}
    //     </Typography>
    // );
}

export default withTheme()(NewUserInfo);
