import React from 'react';
import moment from 'moment-timezone';

import _ from 'lodash';

import { Icon, IconButton, TableCell, TableRow, Tooltip, Checkbox, colors } from '@material-ui/core';

const cellStyle = { whiteSpace: 'nowrap', paddingLeft: 12, paddingRight: 12 };

const BottleDriveRows = ({
    bottleDrive,
    filteredHeaders,
    headers,
    setCheckedList,
    checkedList,
    handleView,
    handleReject,
    handleUnreject,
    onSchedulePickup,
    checkbox = true,
    index = 0
}) => {
    const {
        _id,
        date,
        startTime,
        endTime,
        customer,
        charity,
        collector,
        contactName,
        contactEmail,
        contactPhone,
        location,
        comment,
        charityName,
        collectorName,
        rejected,
        pickup,
        processedDate
    } = bottleDrive;

    let allHeaders = headers;

    const isPastDate = moment()
        .tz(process.env.REACT_APP_REGION_TIMEZONE)
        .isAfter(
            moment(date).tz(
                _.get(location, 'timezone', _.get(customer, 'location.timezone', process.env.REACT_APP_REGION_TIMEZONE))
            ),
            'day'
        );

    const isNearDate = moment()
        .tz(process.env.REACT_APP_REGION_TIMEZONE)
        .add(3, 'days')
        .isAfter(
            moment(date).tz(
                _.get(location, 'timezone', _.get(customer, 'location.timezone', process.env.REACT_APP_REGION_TIMEZONE))
            ),
            'day'
        );

    return (
        <TableRow key={_id}>
            {checkbox && (
                <TableCell padding="checkbox">
                    <Checkbox
                        checked={checkedList.includes(_id)}
                        onChange={e => {
                            if (!e.target.checked) {
                                setCheckedList(
                                    checkedList.filter(element => {
                                        return element !== _id;
                                    })
                                );
                            } else {
                                setCheckedList([...checkedList, _id]);
                            }
                        }}
                    />
                </TableCell>
            )}
            {filteredHeaders.includes('Actions') && (
                <TableCell
                    style={{ ...cellStyle, paddingLeft: 0, paddingRight: 0 }}
                    padding={_.get(_.find(allHeaders, { name: 'Actions' }), 'padding', 'default')}
                >
                    <Tooltip title="View Customer">
                        <IconButton
                            onClick={() => (_.isNil(customer) ? {} : handleView(customer._id))}
                            disabled={_.isNil(customer)}
                        >
                            <Icon>visibility</Icon>
                        </IconButton>
                    </Tooltip>
                    {!_.isNil(pickup) && !rejected && (
                        <Tooltip title="Pickup Booked">
                            <span>
                                <IconButton disabled data-cy={`${index}-complete`}>
                                    <Icon style={{ color: 'green' }}>event_available</Icon>
                                </IconButton>
                            </span>
                        </Tooltip>
                    )}
                    {_.isNil(pickup) && !rejected && (
                        <Tooltip title="Schedule Pickup">
                            <IconButton
                                onClick={() => {
                                    onSchedulePickup(bottleDrive);
                                }}
                                data-cy={`${index}-schedule_pickup`}
                            >
                                <Icon>calendar_month</Icon>
                            </IconButton>
                        </Tooltip>
                    )}
                    {_.isNil(pickup) &&
                        (rejected ? (
                            <Tooltip title="Unreject">
                                <IconButton
                                    onClick={() => {
                                        handleUnreject([bottleDrive._id]);
                                    }}
                                    data-cy={`${index}-unreject`}
                                >
                                    <Icon>check</Icon>
                                </IconButton>
                            </Tooltip>
                        ) : (
                            <Tooltip title="Reject">
                                <IconButton
                                    onClick={() => {
                                        handleReject([bottleDrive._id]);
                                    }}
                                    data-cy={`${index}-reject`}
                                >
                                    <Icon>close</Icon>
                                </IconButton>
                            </Tooltip>
                        ))}
                </TableCell>
            )}
            {filteredHeaders.includes('Reject Reason') && (
                <TableCell
                    style={{
                        ...cellStyle,
                        whiteSpace: 'normal',
                        wordWrap: 'break-word'
                    }}
                >
                    {_.get(bottleDrive, 'rejectReason', 'N/A')}
                </TableCell>
            )}
            {filteredHeaders.includes('Pickup Date^') && (
                <TableCell
                    style={{
                        ...cellStyle,
                        whiteSpace: 'nowrap',
                        wordWrap: 'break-word'
                    }}
                    padding={_.get(_.find(allHeaders, { name: 'Date' }), 'padding', 'default')}
                >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {moment(_.get(pickup, 'date'))
                            .tz(
                                _.get(
                                    location,
                                    'timezone',
                                    _.get(customer, 'location.timezone', process.env.REACT_APP_REGION_TIMEZONE)
                                )
                            )
                            .format('MMM D, YYYY')}
                    </div>
                </TableCell>
            )}
            {filteredHeaders.includes('Bottle Drive Date^') && (
                <TableCell
                    style={{
                        ...cellStyle,
                        whiteSpace: 'nowrap',
                        wordWrap: 'break-word'
                    }}
                    padding={_.get(_.find(allHeaders, { name: 'Date' }), 'padding', 'default')}
                >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {!rejected && _.isNil(pickup) ? (
                            isPastDate ? (
                                <Tooltip title="Date for bottle drive has passed">
                                    <Icon style={{ color: colors.red[500] }}>error</Icon>
                                </Tooltip>
                            ) : isNearDate ? (
                                <Tooltip title="Date for bottle drive is coming up">
                                    <Icon style={{ color: colors.amber[500] }}>warning</Icon>
                                </Tooltip>
                            ) : (
                                ''
                            )
                        ) : !_.isNil(pickup) && !_.eq(_.get(pickup, 'complete', true), true) && isPastDate ? (
                            <Tooltip title="Bottle drive was assigned a pickup, however pickup is not complete, and its past the date">
                                <Icon style={{ color: colors.amber[500] }}>warning</Icon>
                            </Tooltip>
                        ) : (
                            ''
                        )}
                        {moment(date)
                            .tz(
                                _.get(
                                    location,
                                    'timezone',
                                    _.get(customer, 'location.timezone', process.env.REACT_APP_REGION_TIMEZONE)
                                )
                            )
                            .format('MMM D, YYYY')}
                    </div>
                </TableCell>
            )}
            {filteredHeaders.includes('Processed Date^') && (
                <TableCell
                    style={{
                        ...cellStyle,
                        whiteSpace: 'nowrap',
                        wordWrap: 'break-word'
                    }}
                >
                    {!_.isNil(processedDate)
                        ? moment(processedDate)
                              .tz(
                                  _.get(
                                      location,
                                      'timezone',
                                      _.get(customer, 'location.timezone', process.env.REACT_APP_REGION_TIMEZONE)
                                  )
                              )
                              .format('MMM D, YYYY')
                        : ''}
                </TableCell>
            )}
            {filteredHeaders.includes('Start Time^') && (
                <TableCell
                    style={{
                        ...cellStyle,
                        whiteSpace: 'nowrap',
                        wordWrap: 'break-word'
                    }}
                >
                    {moment(startTime)
                        .tz(
                            _.get(
                                location,
                                'timezone',
                                _.get(customer, 'location.timezone', process.env.REACT_APP_REGION_TIMEZONE)
                            )
                        )
                        .format('h:mm A')}
                </TableCell>
            )}
            {filteredHeaders.includes('End Time^') && (
                <TableCell
                    style={{
                        ...cellStyle,
                        whiteSpace: 'nowrap',
                        wordWrap: 'break-word'
                    }}
                >
                    {moment(endTime)
                        .tz(
                            _.get(
                                location,
                                'timezone',
                                _.get(customer, 'location.timezone', process.env.REACT_APP_REGION_TIMEZONE)
                            )
                        )
                        .format('h:mm A')}
                </TableCell>
            )}
            {filteredHeaders.includes('Contact Name^') && (
                <TableCell
                    style={{
                        ...cellStyle,
                        whiteSpace: 'normal',
                        wordWrap: 'break-word'
                    }}
                >
                    {contactName}
                </TableCell>
            )}

            {filteredHeaders.includes('Contact Email^') && (
                <TableCell
                    style={{
                        ...cellStyle,
                        whiteSpace: 'normal',
                        wordWrap: 'break-word'
                    }}
                >
                    {contactEmail}
                </TableCell>
            )}

            {filteredHeaders.includes('Contact Phone') && (
                <TableCell
                    style={{
                        ...cellStyle,
                        whiteSpace: 'normal',
                        wordWrap: 'break-word'
                    }}
                >
                    {contactPhone}
                </TableCell>
            )}
            {filteredHeaders.includes('Address^') && (
                <TableCell
                    style={{
                        ...cellStyle,
                        maxWidth: 250,
                        whiteSpace: 'normal',
                        wordWrap: 'break-word'
                    }}
                    padding={_.get(_.find(allHeaders, { name: 'Address^' }), 'padding', 'default')}
                >
                    <Tooltip title={_.get(location, 'description', 'N/A')} interactive>
                        <p style={{ width: 200 }}>{_.get(location, 'description', 'N/A')}</p>
                    </Tooltip>
                </TableCell>
            )}
            {filteredHeaders.includes('Comment/Directions') && (
                <TableCell
                    style={{
                        ...cellStyle,
                        minWidth: 200,
                        whiteSpace: 'normal',
                        wordWrap: 'break-word'
                    }}
                >
                    {comment}
                </TableCell>
            )}

            {filteredHeaders.includes('Referring Collector^') && (
                <TableCell style={cellStyle}>{_.get(collector, 'name', collectorName)}</TableCell>
            )}

            {filteredHeaders.includes('Charity^') && (
                <TableCell style={cellStyle}>{_.get(charity, 'name', charityName)}</TableCell>
            )}
        </TableRow>
    );
};

export default BottleDriveRows;
