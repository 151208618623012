import React from 'react';
import _ from 'lodash';
import { withTheme } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
    badge: {
        position: 'absolute',
        width: '100%',
        height: '100%'
    },
    numberSvg: {
        position: 'absolute',
        top: '50%',
        right: '50%',
        transform: 'translate(50%,-50%)',
        margin: '0',
        padding: '0',
        width: '75%',
        height: '40%',
        overflow: 'hidden'
    }
});

function RankBadge({ theme, classes, value = 0, length = 150 }) {
    const valueStr = value.toString().trim();
    const numStrLength = valueStr.length;

    // single, double and x characters where x is greater or equal to 3 yields a divisor of 2, 2.5 and x respectively
    const fontSizeDivisor = numStrLength + Math.floor((1 / numStrLength) * 2) / 2;
    const fontSizeVal = length / fontSizeDivisor;

    let superscriptOrdinal;
    // if value is a valid number and is not 0 as zero does not have a superscript ordinal
    if (!isNaN(+valueStr) && valueStr !== '0') {
        if (valueStr.charAt(numStrLength - 2) === '1') {
            // 11, 12 and 13 should all end in "th" as opposed to "st", "nd" and "rd"
            superscriptOrdinal = 'th';
        } else {
            switch (_.last(valueStr)) {
                case '1':
                    superscriptOrdinal = 'st';
                    break;
                case '2':
                    superscriptOrdinal = 'nd';
                    break;
                case '3':
                    superscriptOrdinal = 'rd';
                    break;
                default:
                    superscriptOrdinal = 'th';
            }
        }
    }

    return (
        <div style={{ position: 'relative', width: `${length}px`, height: `${length}px` }}>
            <svg
                width="100%"
                height="100%"
                viewBox="0 0 448 512"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={classes.badge}
            >
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M446.323 128.873C445.422 127.428 443.294 125.421 441.593 124.414C439.892 123.407 390.8 94.971 332.5 61.223C236.859 5.85999 226.158 -0.0920142 223 0.322986C220.744 0.619986 181.138 22.954 111.6 63.142C28.131 111.381 3.283 126.179 1.854 128.5C0.0850039 131.375 0.0069978 136.71 0.0039978 256.3C0.000997803 362.933 0.208995 381.504 1.439 383.882C2.567 386.064 26.366 400.138 111.689 449.082C206.928 503.715 220.999 511.494 224.5 511.451C227.928 511.41 243.797 502.571 335.5 449.627C394.35 415.651 443.625 386.75 445 385.403L447.5 382.955L447.73 257.227C447.94 142.28 447.82 131.275 446.323 128.873Z"
                    fill={theme.palette.primary.main}
                />
            </svg>
            <div class={classes.numberSvg}>
                <svg width="100%" height="100%" preserveAspectRatio="xMinYMid meet">
                    <text
                        x="50%"
                        y="50%"
                        dominantBaseline="central"
                        textAnchor="middle"
                        fontSize={fontSizeVal}
                        fontFamily={theme.typography.fontFamily}
                        fill="white"
                    >
                        {value}
                        {superscriptOrdinal && (
                            <tspan dy="-10" fontSize={fontSizeVal / 3}>
                                {superscriptOrdinal}
                            </tspan>
                        )}
                    </text>
                </svg>
            </div>
        </div>
    );
}

export default withStyles(styles)(withTheme()(RankBadge));
