import React, { useState } from 'react';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';

import _ from 'lodash';
import { Collapse, InputAdornment, MenuItem, Paper, TextField, withStyles, withTheme } from '@material-ui/core';
import { useRef } from 'react';

const styles = theme => ({
    container: {
        flexGrow: 1
    },
    suggestionsContainerOpen: {
        left: 0,
        right: 0,
        marginTop: theme.spacing.unit
    },
    suggestion: {
        display: 'block'
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: 'none'
    }
});

const AddressDropdown = ({ pendingAddresses, inputProps, onSuggestionSelected, theme, classes }) => {
    const [suggestions, setSuggestions] = useState([]); //array of strings

    const handleSuggestionsClearRequested = () => {
        setSuggestions([]);
    };

    const getSuggestionValue = addressText => {
        return addressText;
    };

    const filterSuggestions = searchText => {
        if (!_.isEmpty(searchText)) {
            const filteredSuggestions = pendingAddresses.filter(text => text.includes(searchText));
            setSuggestions(filteredSuggestions);
        }
    };
    const debouncedGetSuggestionsRef = useRef(_.debounce(filterSuggestions, 300));

    const handleSuggestionsFetchRequested = ({ value }) => {
        if (debouncedGetSuggestionsRef.current) {
            debouncedGetSuggestionsRef.current.cancel();
            debouncedGetSuggestionsRef.current(value);
        }
    };

    const handleSuggestionSelected = (e, { suggestion }) => {
        onSuggestionSelected(suggestion);
    };

    return (
        <Autosuggest
            theme={{
                container: classes.container,
                suggestionsContainer: classes.suggestionsContainerOpen,
                suggestionsList: classes.suggestionsList,
                suggestion: classes.suggestion
            }}
            suggestions={suggestions}
            onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
            onSuggestionsClearRequested={handleSuggestionsClearRequested}
            getSuggestionValue={getSuggestionValue}
            renderInputComponent={renderInput}
            renderSuggestion={renderSuggestion}
            renderSuggestionsContainer={renderSuggestionsContainer}
            inputProps={inputProps}
            onSuggestionSelected={handleSuggestionSelected}
        />
    );
};

export default withTheme()(withStyles(styles)(AddressDropdown));

function renderSuggestion(suggestion, { query, isHighlighted }) {
    const matches = match(suggestion, query);
    const parts = parse(suggestion, matches);

    return (
        <MenuItem selected={isHighlighted} component="div">
            <div
                style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                }}
            >
                {parts.map((part, index) => {
                    return part.highlight ? (
                        <span key={index} style={{ fontWeight: 300 }} id={`${index}-address-li`}>
                            {part.text}
                        </span>
                    ) : (
                        <strong key={index} style={{ fontWeight: 500 }} id={`${index}-address-li`}>
                            {part.text}
                        </strong>
                    );
                })}
            </div>
        </MenuItem>
    );
}

function renderSuggestionsContainer(options) {
    const { containerProps, children } = options;
    return (
        <Paper {...containerProps} square>
            <Collapse in={!_.isNil(children) && children.props.items.length > 0}>{children}</Collapse>
        </Paper>
    );
}

function renderInput(inputProps) {
    const {
        classes,
        variant,
        disabled,
        autoFocus,
        label,
        value,
        ref,
        error,
        endAdornment,
        style,
        ...other
    } = inputProps;

    return (
        <TextField
            multiline
            disabled={disabled}
            autoFocus={autoFocus}
            label={label}
            value={value}
            helperText={error && error.fail ? error.reason : null}
            error={error && error.fail ? true : false}
            inputRef={ref}
            variant={variant}
            InputProps={{
                id: 'subscription-address-input',
                'data-cy': 'subscription-address-input',
                endAdornment: <InputAdornment position="end">{endAdornment || <React.Fragment />}</InputAdornment>,
                ...other,
                autoComplete: 'new-password'
            }}
            fullWidth
            style={style}
        />
    );
}
