import React, { useState, useContext, useEffect } from 'react';

import _ from 'lodash';

import { getCustomerFirstNameAndLastInitial } from 'utils/misc';

import DialogTitlePrimary from 'components/MaterialUIExtensions/DialogTitlePrimary';
import OperatorContext from 'utils/contexts/OperatorContext';

import {
    colors,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    DialogContent,
    DialogActions,
    Dialog,
    Typography,
    withMobileDialog,
    CircularProgress
} from '@material-ui/core';

import { withTheme } from '@material-ui/core/styles';

import bulkHelper from 'helpers/bulkHelper';
import ConfirmDialogContext from 'components/Dialogs/Confirm/ConfirmDialogContext';
import { _user } from 'std';

// moment.tz.setDefault(process.env.REACT_APP_REGION_TIMEZONE);

function BulkComplaintDialog(props) {
    const {
        operator,
        fullScreen,
        bulk,
        theme,
        open,
        inProgress,
        customerIssueResolution,
        onClose,
        onToggleResolveCustomerIssue,
        onChangeCustomerIssueResolution,
        http
    } = props;

    const [resolutions, setResolutions] = useState([]);
    const loadResolutions = async () => {
        const res = await http.getJSON('/system/configuration/customerResolutions');
        if (res.ok) {
            setResolutions(res.data.resolutions);
        }
    };
    useEffect(() => {
        loadResolutions();
    }, []);

    const warnAction = useContext(ConfirmDialogContext);
    const hasResolvePermission =
        _.get(operator, 'adminView') ||
        _.get(operator, 'accountType') === 'System Administrator' ||
        _user.isInternalRole(operator) ||
        _.get(operator, 'collector.configuration.bulkPermissions.resolveComplaints.update');

    return (
        <Dialog data-cy="multi-step-dialog" fullScreen={fullScreen} fullWidth open={open}>
            <DialogTitlePrimary>Complaint</DialogTitlePrimary>
            <DialogContent>
                <Typography
                    variant="body2"
                    style={{
                        marginTop: theme.spacing.unit * 2,
                        marginBottom: theme.spacing.unit * 2
                    }}
                >
                    <span
                        style={{
                            background: bulkHelper.hasUnresolvedCustomerIssue(bulk)
                                ? colors.red[500]
                                : colors.blue[100],
                            color: theme.palette.getContrastText(
                                bulkHelper.hasUnresolvedCustomerIssue(bulk) ? colors.red[500] : colors.blue[100]
                            ),
                            fontSize: theme.typography.fontSize * 0.875,
                            marginRight: theme.spacing.unit,
                            padding: '2px 4px',
                            borderRadius: 4
                        }}
                    >
                        {bulkHelper.hasCustomerIssue(bulk) ? (
                            <>{bulkHelper.hasUnresolvedCustomerIssue(bulk) ? 'UNRESOLVED' : 'RESOLVED'}</>
                        ) : (
                            <>NO ISSUES :D</>
                        )}
                    </span>
                    "{_.get(bulk, 'customerIssues.issueDescription', 'N/A')}" -{' '}
                    <i>{getCustomerFirstNameAndLastInitial(bulk.owner)}</i>
                </Typography>
                <FormControl style={{ marginBottom: theme.spacing.unit }} fullWidth>
                    {resolutions.map(resolution => {
                        return (
                            <FormControlLabel
                                key={resolution.code}
                                control={
                                    <Checkbox
                                        checked={resolution.code === customerIssueResolution}
                                        onChange={onChangeCustomerIssueResolution}
                                        value={resolution.code}
                                        style={{
                                            paddingTop: theme.spacing.unit,
                                            paddingBottom: theme.spacing.unit / 2,
                                            marginLeft: theme.spacing.unit * 2
                                        }}
                                    />
                                }
                                label={resolution.label}
                                disabled={!bulkHelper.hasUnresolvedCustomerIssue(bulk)}
                            />
                        );
                    })}
                    {/* <TextField
                        data-cy="porscreen-resolution-input"
                        multiline
                        rows="2"
                        rowsMax="6"
                        name="resolution"
                        label="Resolution"
                        disabled={!bulkHelper.hasUnresolvedCustomerIssue(bulk)}
                        value={customerIssueResolution}
                        variant="outlined"
                        helperText={customerResolutionTextError || 'NOTE: This is visible to the customer'}
                        error={!_.isNil(customerResolutionTextError)}
                        onChange={onChangeCustomerIssueResolution}
                    />*/}
                </FormControl>
                {!hasResolvePermission && (
                    <Typography style={{ textAlign: 'right', color: colors.red[500] }} variant="body2">
                        <div>Only system administrators can resolve issues.</div>
                        <div>Please contact support if this is incorrect.</div>
                    </Typography>
                )}
            </DialogContent>
            <DialogActions>
                <Button color="default" onClick={onClose}>
                    Cancel
                </Button>
                <Button
                    color="primary"
                    disabled={
                        _.isEmpty(customerIssueResolution) ||
                        inProgress ||
                        !bulkHelper.hasCustomerIssue(bulk) ||
                        !hasResolvePermission
                    }
                    onClick={() => {
                        if (bulkHelper.hasUnresolvedCustomerIssue(bulk)) {
                            onToggleResolveCustomerIssue(true);
                        } else {
                            warnAction(() => {
                                return onToggleResolveCustomerIssue(true);
                            }, 'Are you sure you want reopen this issue? The customer will be notified.');
                        }
                    }}
                    data-cy="3-pickupdialog-next-button"
                >
                    {bulkHelper.hasUnresolvedCustomerIssue(bulk) ? 'MARK AS RESOLVED' : 'MARK AS UNRESOLVED'}
                    <CircularProgress
                        size={22}
                        thickness={4.8}
                        style={{
                            display: inProgress ? '' : 'none',
                            marginLeft: theme.spacing.unit / 2
                        }}
                    />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default withMobileDialog({ breakpoint: 'xs' })(withTheme()(BulkComplaintDialog));
