import React, { useState, useContext } from 'react';
import { Clipboard } from '@capacitor/clipboard';

import _ from 'lodash';

import { Tabs, Tab, Typography, Icon, IconButton, withTheme } from '@material-ui/core';

import CustomFormTitle from 'components/MaterialUIExtensions/CustomFormTitle';

import SnackbarContext from 'components/CustomSnackbar/SnackbarContext';
import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

function DonationButtonsComponent(props) {
    const { referralLink, buttonImageName, theme } = props;
    const { lang } = useContext(LocalizationContext);

    const [referralLinkDisplayTab, setReferralLinkDisplayTab] = useState(0);

    const onSnackbar = useContext(SnackbarContext);

    const buttonImgCode = `<a href="${referralLink}"> <img alt="Donate your recycling button" src=${process.env
        .REACT_APP_ORIGIN_URL +
        process.env.PUBLIC_URL +
        buttonImageName} width=200 title="${loc('growthPostInfo8', lang)}"/> </a>`;
    const linkCode = `<a href="${referralLink}"> ${loc('growthPostInfo8', lang)} </a>`;

    const handleCopyToClipboard = string => () => {
        Clipboard.write({
            string
        });
        onSnackbar(loc('charitySnackbar5', lang));
    };

    return (
        <>
            <Tabs
                value={referralLinkDisplayTab}
                onChange={(event, value) => {
                    setReferralLinkDisplayTab(value);
                }}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
            >
                <Tab icon={<Icon>insert_photo</Icon>} label={loc('growthPostInfo6', lang)} />
                <Tab icon={<Icon>short_text</Icon>} label={loc('growthPostInfo7', lang)} />
            </Tabs>
            {referralLinkDisplayTab === 0 && (
                <>
                    <CustomFormTitle titleText={`${loc('growthPostInfo4', lang)}:`} iconName="code" />
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            marginBottom: theme.spacing.unit * 2
                        }}
                    >
                        <Typography variant="body1" style={{ maxWidth: '100%' }}>
                            <code style={{ overflowWrap: 'break-word', wordWrap: 'break-word' }}>{buttonImgCode}</code>
                        </Typography>

                        <IconButton onClick={handleCopyToClipboard(buttonImgCode)}>
                            <Icon>file_copy</Icon>
                        </IconButton>
                    </div>
                    <CustomFormTitle titleText={`${loc('growthPostInfo5', lang)}:`} iconName="web_asset" />
                    <Typography
                        variant="body1"
                        style={{ color: theme.palette.linkColor }}
                        dangerouslySetInnerHTML={{
                            __html: buttonImgCode
                        }}
                    >
                        {/*<a href={referralLink}>
                            <img
                                alt="Donate your recycling button"
                                src={require('../../icons/SKIPTHEDEPOT_APP_ICON.svg')}
                            />
                    </a>*/}
                    </Typography>
                </>
            )}
            {referralLinkDisplayTab === 1 && (
                <>
                    <CustomFormTitle titleText={`${loc('growthPostInfo4', lang)}:`} iconName="code" />
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            marginBottom: theme.spacing.unit * 2
                        }}
                    >
                        <Typography variant="body1" style={{ maxWidth: '100%' }}>
                            <code style={{ overflowWrap: 'break-word', wordWrap: 'break-word' }}>{linkCode}</code>
                        </Typography>
                        <IconButton onClick={handleCopyToClipboard(linkCode)}>
                            <Icon>file_copy</Icon>
                        </IconButton>
                    </div>
                    <CustomFormTitle titleText={`${loc('growthPostInfo5', lang)}:`} iconName="web_asset" />
                    <Typography
                        variant="body1"
                        style={{ color: theme.palette.linkColor, marginBottom: theme.spacing.unit * 4 }}
                    >
                        <a href={referralLink}>{loc('growthPostInfo8', lang)}</a>
                    </Typography>
                </>
            )}
        </>
    );
}

export default withTheme()(DonationButtonsComponent);
