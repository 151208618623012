import React, { useContext, useMemo } from 'react';

import _ from 'lodash';

import * as allIcons from '@mdi/js';
import { Icon as MDIcon } from '@mdi/react';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import {
    Dialog,
    DialogActions,
    DialogContent,
    Button,
    withMobileDialog,
    withTheme,
    FormControl,
    InputLabel,
    Select,
    OutlinedInput,
    MenuItem,
    Typography,
    InputAdornment,
    TextField
} from '@material-ui/core';

import { getSelect, getSwitch, getTextInput } from '../helperFunctions';
import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';
import ExternalLink from 'components/ExternalLink/ExternalLink';
import { useRef } from 'react';

function FeeForm(props) {
    const {
        fee, //NOTE: will be null/undefined if creating a new one
        open,
        defaults,
        allCommodities,
        onClose,
        onSubmit,
        theme,
        fullScreen
    } = props;
    const { lang } = useContext(LocalizationContext);

    const allIconNames = useMemo(
        () => Object.keys(allIcons).map(importName => convertImportToIconName(importName)),
        []
    );

    const handleSubmit = values => {
        const toSubmit = _.cloneDeep(values);
        toSubmit.iconName = convertToImportName(values.iconName);
        onSubmit(toSubmit);
    };

    //fixes slowness when dragging color selector, prevents react from rerendering to much,
    //needs to be in a ref so the function isn't recreated every render
    const handleChangeColorRef = useRef(
        _.throttle((e, formik) => {
            if (!_.isNil(e) && !_.isNil(e.target)) {
                formik.setFieldValue('color', e.target.value);
            }
        }, 100)
    );

    const handleChangeColorWrapper = e => {
        e.persist(); //stops error from popping up in the console
        handleChangeColorRef.current(e, formik);
    };

    const formik = useFormik({
        initialValues: {
            _id: _.get(fee, '_id', undefined),
            disabled: _.get(fee, 'disabled', false),
            description: _.get(fee, 'description', ''),
            color: _.get(fee, 'color', '#000000'),
            iconName: convertImportToIconName(_.get(fee, 'iconName', '')),
            amount: _.get(fee, 'amount', 0),
            contractorShare: _.get(fee, 'contractorShare', 0),
            tax: {
                type: _.get(fee, 'tax.type', 'included'),
                value: _.get(fee, 'tax.value', 0)
            },
            preApplied: _.get(fee, 'preApplied', false),
            commodity: _.get(fee, 'commodity', null)
        },
        validationSchema: Yup.object({
            disabled: Yup.boolean(),
            description: Yup.string().required(),
            color: Yup.string().required('You must enter a color'),
            iconName: Yup.string()
                .oneOf(allIconNames, 'Invalid icon name')
                .required('You must enter an icon name'),
            amount: Yup.number()
                .required()
                .min(0, 'This must be a positive number')
                .integer('This must be an integer'), //only works at the end
            contractorShare: Yup.number()
                .required()
                .min(0, 'This must be 0 or greater')
                .max(1, 'This must be 1 or less'),
            tax: Yup.object({
                type: Yup.string()
                    .required()
                    .matches(/(included|excluded)/, { excludeEmptyString: true }),
                value: Yup.number()
                    .required()
                    .min(0, 'This must be 0 or greater')
                    .max(1, 'This must be 1 or less')
            }),
            preApplied: Yup.boolean().required(),
            commodity: Yup.string().nullable()
        }),
        onSubmit: handleSubmit
    });

    return (
        <Dialog onClose={onClose} open={open} fullScreen={fullScreen} fullWidth>
            <DialogContent>
                <div style={{ display: 'flex' }}>{getSwitch(theme, 'disabled', 'Disabled', formik)}</div>

                {getTextInput(theme, 'description', 'Description', formik)}

                <div style={{ display: 'flex' }}>
                    <div style={{ marginRight: theme.spacing.unit, flex: 1 }}>
                        {getTextInput(theme, 'iconName', 'Icon Name', formik, 'text', {
                            endAdornment: (
                                <InputAdornment position="end">
                                    <MDIcon
                                        path={_.get(allIcons, convertToImportName(formik.values.iconName))}
                                        size={1.5}
                                        color={formik.values.color}
                                    />
                                </InputAdornment>
                            )
                        })}
                        <div style={{ display: 'flex', marginTop: theme.spacing.unit }}>
                            <ExternalLink text="Click here to see all icons" url="https://materialdesignicons.com/" />
                        </div>
                    </div>
                    <div style={{ flex: 1 }}>
                        <TextField
                            type="color"
                            onChange={handleChangeColorWrapper}
                            value={formik.values.color}
                            onBlur={formik.handleBlur}
                            name="color"
                            error={formik.touched.color && formik.errors.color ? true : null}
                            label="Color"
                            margin="normal"
                            variant="outlined"
                            helperText={formik.touched.color && formik.errors.color ? formik.errors.color : null}
                            fullWidth
                        />
                    </div>
                </div>

                <div style={{ display: 'flex' }}>
                    <div style={{ marginRight: theme.spacing.unit, flexGrow: 1 }}>
                        {getTextInput(theme, 'amount', 'Amount (cent)', formik, 'number')}
                    </div>
                    <div style={{ flexGrow: 1 }}>
                        {getTextInput(theme, 'contractorShare', 'Contractor Share (decimal)', formik, 'number')}
                    </div>
                </div>

                <div style={{ display: 'flex' }}>
                    <div style={{ marginRight: theme.spacing.unit, flexGrow: 1 }}>
                        {getSelect(
                            theme,
                            'tax.type',
                            'Tax Type',
                            ['included', 'excluded'],
                            formik,
                            null,
                            null,
                            null,
                            false
                        )}
                    </div>
                    <div>{getTextInput(theme, 'tax.value', 'Tax Value (decimal)', formik, 'number')}</div>
                </div>
                {/* <FormControl fullWidth style={{ marginTop: theme.spacing.unit * 2 }}>
                    <InputLabel variant="outlined">Commodity</InputLabel>
                    <Select {...formik.getFieldProps('commodity')} fullWidth input={<OutlinedInput labelWidth={150} />}>
                        <MenuItem value={null} key="null field">
                            <Typography inline>None</Typography>
                        </MenuItem>
                        {allCommodities.map(commodity => (
                            <MenuItem value={_.get(commodity, '_id')} key={_.get(commodity, '_id')}>
                                <Typography inline>{_.get(commodity, `payloadInputName.${lang}`, '')}</Typography>
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {getSwitch(theme, 'preApplied', 'Pre-Applied', formik)} */}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={formik.handleSubmit} data-cy="fee-crud-submit-btn">
                    {loc('submit', lang)}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function convertToImportName(iconName) {
    return _.camelCase(`mdi-${iconName}`);
}

function convertImportToIconName(importName) {
    return _.kebabCase(importName.substring(3));
}

export default withMobileDialog()(withTheme()(FeeForm));
