import React, { useContext } from 'react';

import _ from 'lodash';

import DialogTitlePrimary from 'components/MaterialUIExtensions/DialogTitlePrimary';
import ExternalLink from '../ExternalLink/ExternalLink';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { withTheme } from '@material-ui/core/styles';

import * as terms from 'localizations/terms';
import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

function CharityInfoDialog({ theme, open, charity, withDonate, onClose, onDonate, lockDonationPreference }) {
    const { lang } = useContext(LocalizationContext);
    if (_.isNil(charity)) {
        return null;
    }

    return (
        <Dialog open={open} fullWidth maxWidth="sm" onClose={onClose}>
            <DialogTitlePrimary>{charity.name}</DialogTitlePrimary>
            <DialogContent style={{ marginTop: theme.spacing.unit * 3 }}>
                <img
                    src={charity.logo}
                    alt="Charity logo"
                    style={{ maxWidth: 160, maxHeight: 80, marginBottom: theme.spacing.unit }}
                />
                <Typography variant="subtitle1">{charity.mission}</Typography>
                {!_.isEmpty(charity.website) && (
                    <span style={{ marginTop: theme.spacing.unit }}>
                        <br />
                        <ExternalLink text={loc('dashboardUpdate2', lang)} url={charity.website} />
                    </span>
                )}
                <Typography variant="subtitle1" color="textSecondary" style={{ marginTop: theme.spacing.unit * 2 }}>
                    {charity.taxReceiptsIssued ? (
                        <span>
                            <span style={{ marginRight: 4, fontSize: '125%', color: theme.palette.primary.main }}>
                                ★
                            </span>
                            {loc('dashboardUpdate3', lang)}
                        </span>
                    ) : (
                        <span>{loc('pickupDialog53', lang)}</span>
                    )}
                </Typography>
                {lockDonationPreference && (
                    <Typography variant="body2" color="textSecondary" style={{ marginTop: theme.spacing.unit * 2 }}>
                        <span data-cy="donation-preference-locked-message">
                            {loc('donationPreferenceLockedMessage', lang, {
                                brandname: process.env.REACT_APP_BRAND_NAME
                            })}
                        </span>
                    </Typography>
                )}
            </DialogContent>
            <DialogActions>
                {withDonate && !lockDonationPreference ? (
                    <Button data-cy="charity-info-widget-donate-button" color="primary" onClick={onDonate(charity)}>
                        {loc('dashboardUpdate5', lang)}
                    </Button>
                ) : (
                    <Button color="primary" onClick={onClose}>
                        {loc('close', lang)}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
}

export default withTheme()(CharityInfoDialog);
