import React from 'react';
import _ from 'lodash';
import { _user } from 'std';

import { withTheme } from '@material-ui/core';

import { deviceHelper } from 'utils/misc';
import { useState } from 'react';
import { openWindow } from 'helpers/windowHelper';
import OperatorContext from 'utils/contexts/OperatorContext';
import { useContext } from 'react';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import Subscriptions from './Subscriptions';
import AppliedFees from './AppliedFees';

function FeesUsage({ theme, history }) {
    const operator = useContext(OperatorContext);

    const [view, setView] = useState('subscriptions');
    const showSubscriptions = view === 'subscriptions';
    const showAppliedFees = view === 'appliedFees';

    const onViewCustomer = user => {
        if (_user.isCustomer(user)) {
            const URL = `/customers/${user._id}`;
            openWindow(history, !deviceHelper.isNativeApp(), URL);
            return;
        }
    };

    const onViewBulk = bulk => {
        if (deviceHelper.isNativeApp()) {
            history.push(`/operators/${_user.getId(operator)}/bulks/${bulk._id}`);
        } else {
            window.open(`/operators/${_user.getId(operator)}/bulks/${bulk._id}`, '_blank');
        }
    };

    return (
        <>
            <div style={{ paddingTop: theme.spacing.unit * 2, display: 'flex', justifyContent: 'center' }}>
                <ToggleButtonGroup
                    value={view}
                    exclusive
                    onChange={(e, view) => {
                        if (!_.isEmpty(view)) setView(view);
                    }}
                    style={{ display: 'flex', flexWrap: 'wrap' }}
                >
                    <ToggleButton style={{ flexGrow: 1 }} value="subscriptions">
                        Subscriptions
                    </ToggleButton>
                    <ToggleButton style={{ flexGrow: 1 }} value="appliedFees">
                        Applied Fees
                    </ToggleButton>
                </ToggleButtonGroup>
            </div>
            {showSubscriptions && <Subscriptions theme={theme} history={history} onViewCustomer={onViewCustomer} />}
            {showAppliedFees && (
                <AppliedFees theme={theme} history={history} onViewBulk={onViewBulk} onViewCustomer={onViewCustomer} />
            )}
        </>
    );
}

export default withTheme()(FeesUsage);
