import React, { useState, useEffect, useContext } from 'react';
import { TIMEZONES } from 'constants.js';
import _ from 'lodash';
import moment from 'moment-timezone';
import { formatsSecondsToTime } from 'utils/misc';
import DatePicker from 'components/DateTimePickersTz/DatePicker';
import TimezonePicker from 'components/DropDownSelects/Timezone/index';
import useDatePicker from 'components/DateTimePickersTz/hooks/useDatePicker';
import {
    Paper,
    Button,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Switch,
    DialogTitle,
    CircularProgress
} from '@material-ui/core';

import { Dialog, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

function SystemTasksTable(props) {
    const {
        theme,
        systemTasks,
        infoDialogTask,
        infoDialogOpen,
        onToggleSystemTask,
        onViewMoreInfo,
        onTriggerSystemTask,
        onClose
    } = props;
    const { date, handleChangeDate, timezone, handleChangeTimezone } = useDatePicker({
        timezones: TIMEZONES
    });

    const { lang } = useContext(LocalizationContext);

    return (
        <>
            <Paper style={{ width: '100%', overflow: 'auto' }}>
                <Table padding="dense">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Time</TableCell>
                            <TableCell>Enabled</TableCell>
                            <TableCell>{loc('status', lang)}</TableCell>
                            <TableCell>Manually Trigger</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody style={{ whiteSpace: 'nowrap' }}>
                        {_.values(systemTasks).map((systemTask, index) => (
                            <TableRow key={index}>
                                <TableCell>
                                    <span
                                        onClick={() => onViewMoreInfo(systemTask)}
                                        style={{ color: theme.palette.linkColor, cursor: 'pointer' }}
                                    >
                                        {systemTask.name}
                                    </span>
                                </TableCell>
                                <TableCell>{systemTask.time}</TableCell>
                                <TableCell>
                                    <Switch
                                        checked={systemTask.enabled}
                                        onChange={() => onToggleSystemTask(systemTask)}
                                        disabled={systemTask.timeTriggered}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TaskStatus systemTask={systemTask} />
                                </TableCell>
                                <TableCell>
                                    <Button
                                        data-cy={`system-task-table-run-${_.kebabCase(systemTask.name)}`}
                                        variant="contained"
                                        color="secondary"
                                        size="small"
                                        disabled={systemTask.timeTriggered || !systemTask.enabled}
                                        onClick={() => onViewMoreInfo(systemTask)}
                                    >
                                        {systemTask.timeTriggered ? (
                                            <>
                                                <CircularProgress
                                                    size={18}
                                                    thickness={4.8}
                                                    style={{ marginRight: theme.spacing.unit }}
                                                />
                                                <TaskElapsedTime timeTriggered={systemTask.timeTriggered} />
                                            </>
                                        ) : (
                                            'Run Now'
                                        )}
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>

            <Dialog open={infoDialogOpen} onClose={onClose}>
                <DialogTitle>{_.get(infoDialogTask, 'name', 'Task')}</DialogTitle>
                <DialogContent>
                    <DialogContentText style={{ marginBottom: theme.spacing.unit * 2 }}>
                        {_.get(infoDialogTask, 'description', 'Task')}
                    </DialogContentText>
                    <DatePicker
                        timezone={timezone}
                        autoOk
                        label="Date"
                        name="date"
                        value={date}
                        onChange={handleChangeDate}
                    />
                    <TimezonePicker
                        timezones={TIMEZONES}
                        value={timezone}
                        onChange={handleChangeTimezone}
                        style={{ marginLeft: theme.spacing.unit * 2 }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={infoDialogTask.timeTriggered}
                        onClick={() => onTriggerSystemTask(infoDialogTask, date, timezone)}
                        data-cy="system-tasks-table-confirm"
                    >
                        {infoDialogTask.timeTriggered ? (
                            <>
                                <CircularProgress
                                    size={18}
                                    thickness={4.8}
                                    style={{ marginRight: theme.spacing.unit }}
                                />
                                <TaskElapsedTime timeTriggered={infoDialogTask.timeTriggered} />
                            </>
                        ) : (
                            'Run Now'
                        )}
                    </Button>
                    <Button onClick={onClose}>{loc('close', lang)}</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default withTheme()(SystemTasksTable);

function TaskStatus({ systemTask }) {
    if (systemTask.timeTriggered) return 'Running...';
    if (!systemTask.enabled) return 'Disabled';
    return 'Operational';
}

function TaskElapsedTime({ timeTriggered }) {
    const [timeElapsed, setTimeElapsed] = useState(getTimeDifferenceFromNow(timeTriggered));

    useEffect(() => {
        setInterval(() => {
            setTimeElapsed(getTimeDifferenceFromNow(timeTriggered));
        }, 1000);
    });
    return <>{timeElapsed}</>;
}

function getTimeDifferenceFromNow(time) {
    return formatsSecondsToTime(
        moment(new Date())
            .diff(moment(time), 'seconds')
            .toString()
    );
}
