import React, { Component } from 'react';

import { withTheme } from '@material-ui/core/styles';

const logo = require(process.env.REACT_APP_LOGO);

class CompanyLogo extends Component {
    render() {
        const { theme, style, brandLogoOverride } = this.props;

        return (
            <div
                style={{
                    position: 'fixed',
                    width: '100%',
                    paddingTop: 'max(8px, var(--custom-safe-area-top, 8px))',
                    paddingBottom: 2,
                    backgroundColor: theme.palette.envColor[500],
                    boxShadow: theme.shadows[2],
                    textAlign: 'center',
                    zIndex: 10,
                    ...style
                }}
            >
                <img
                    src={brandLogoOverride && brandLogoOverride.url ? brandLogoOverride.url : logo}
                    alt={`${process.env.REACT_APP_BRAND_NAME} logo`}
                    style={{
                        margin: '0px auto',
                        height: 42
                    }}
                />
            </div>
        );
    }
}

export default withTheme()(CompanyLogo);
