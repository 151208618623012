import React, { useState, useContext } from 'react';
import _ from 'lodash';
import io from 'socket.io-client';
import crypto from 'crypto';

import { validate, initFormErrors, containsErrors } from 'utils/validate';

import { getDeviceInformation, deviceHelper } from 'utils/misc.js';
import * as timeHelper from 'helpers/timeHelper';

import SnackbarContext from 'components/CustomSnackbar/SnackbarContext';
import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from '../../../localizations/localizationHandler';

import { InAppBrowser } from '@ionic-native/in-app-browser'; //Browser.close doesn't work on android had to use the cordova in-app-browser plugin

import { Browser } from '@capacitor/browser';
import { REGISTRATION_TABS } from '../../../constants';

const FirebaseAdmin = require('firebase/firebaseAdmin');

function useOAuthDialog({
    dispatch,
    history,
    charity,
    ad_id,
    adgroup,
    tabIndex,
    form,
    file,
    promos,
    ipAddressLocation,
    differentTeams,
    hasAnAccount,
    taxReceiptsIssued,
    validateForm,
    getValidatedKeys,
    setForm,
    setTabIndex,
    setInProgress
}) {
    const [showAuthDialog, setShowAuthDialog] = useState(false);
    const [oAuthRegistration, setOAuthRegistration] = useState(false);
    const [oAuthUser, setOAuthUser] = useState(null);

    const [socket, setSocket] = useState(null);

    const onSnackbar = useContext(SnackbarContext);
    const { lang, setLang } = useContext(LocalizationContext);

    const handleOAuth = async providerType => {
        //let oAuthRegistrationType = providerType === 'google' ? 0 : 1;

        setShowAuthDialog(true);
        setOAuthRegistration(true);

        attemptSocketClose(socket);

        let oAuthUser = await FirebaseAdmin.authenticateWithoAuth(providerType);
        console.log(oAuthUser, 'oAuthUser');
        if (oAuthUser instanceof Error) {
            setShowAuthDialog(false);
            onSnackbar(
                loc(oAuthUser.message, lang, {
                    providerType,
                    serviceEmailAddress: process.env.REACT_APP_SERVICE_EMAIL_ADDRESS
                }) || oAuthUser.message,
                'error'
            );
        } else {
            let idToken = await FirebaseAdmin.getCurrentUsersIDToken();
            if (!_.isNil(idToken)) {
                let registrationObj = await FirebaseAdmin.loginWithOAuthUser(oAuthUser, idToken, charity);
                if (registrationObj instanceof Error) {
                    setShowAuthDialog(false);
                    onSnackbar(loc(registrationObj.message, lang) || registrationObj.message, 'error');
                } else {
                    if (
                        registrationObj.isNotRegistered ||
                        [REGISTRATION_TABS.GROUP, REGISTRATION_TABS.CHARITY, REGISTRATION_TABS.BOTTLE_DRIVE].includes(
                            tabIndex
                        )
                    ) {
                        console.log('in handle o auth');
                        setOAuthUser(oAuthUser);
                    } else {
                        if (registrationObj.lang) {
                            setLang(registrationObj.lang);
                        }
                        dispatch(registrationObj);
                        history.push('/login');
                    }
                }
            }
        }
    };

    const handleCustomAuthWindowClose = socket => {
        setTimeout(() => {
            if (!socket) return;

            if (socket.connected) {
                setShowAuthDialog(false);
                onSnackbar(
                    'Authentication popup process was interrupted or request timed out. Please try again.',
                    'error'
                );
            }

            try {
                socket.close();
            } catch (err) {
                // unhandled catch block
            }

            setSocket(null);
        }, 1500);
    };

    const handleCustomAuth = async () => {
        setShowAuthDialog(true);
        setOAuthRegistration(true);

        let androidBrowserRef;

        // console.log(`%cuseLiveCountsSocketCounterIO: opening socket connection`, 'color: blue');
        const originURL =
            process.env.REACT_APP_ENV !== 'LOCAL'
                ? process.env.REACT_APP_ORIGIN_URL
                : process.env.REACT_APP_ORIGIN_URL.replace(/.$/, '1');
        const pathExtension = process.env.REACT_APP_ENV !== 'LOCAL' ? '/api' : ''; // connect through Nginx if not localhost

        const authSocket = io(`${originURL}/customAuth`, {
            path: `${pathExtension}/socket.io`
        });

        const state = crypto.randomBytes(20).toString('hex');

        authSocket.emit('socket-ready', { state });

        authSocket.on('custom-auth-complete', async res => {
            const { data } = res;

            const customerTypeID = data.customerTypeID;
            const token = data.accessToken;
            const user = {
                altEmail: '',
                email: data.email,
                emailVerified: true,
                nameFirst: data.firstName,
                nameLast: data.lastName,
                promos: [],
                oAuth: {
                    provider: data.provider,
                    uid: data.uid,
                    tokenData: {
                        accessToken: token,
                        tokenType: data.tokenType,
                        provider: data.provider,
                        expiresOn: data.expires,
                        issuedOn: data.issued
                    }
                },
                phone: data.phone,
                location: data.location,
                businessName: data.businessName
            };

            if (deviceHelper.isNativeApp()) {
                if (deviceHelper.AndroidCordova()) {
                    androidBrowserRef.close();
                } else {
                    Browser.close();
                }
            }

            // Check that the user isn't already registered
            let registrationObj = await FirebaseAdmin.loginWithOAuthUser(user, token, charity);
            if (registrationObj instanceof Error) {
                setShowAuthDialog(false);
                onSnackbar(loc(registrationObj.message, lang) || registrationObj.message, 'error');
            } else {
                if (registrationObj.isNotRegistered && customerTypeID === 3) {
                    setOAuthUser(user);
                    setTabIndex(1);
                    setForm({
                        ...form,
                        charityName: data.businessName,
                        location: data.location ? data.location : form.location
                    });
                } else if (registrationObj.isNotRegistered) {
                    setOAuthUser(user);
                } else {
                    if (registrationObj.lang) {
                        setLang(registrationObj.lang);
                    }
                    dispatch(registrationObj);
                    history.push('/login');
                }
            }

            authSocket.close();
        });

        authSocket.on('custom-auth-failed', error => {
            setShowAuthDialog(false);
            onSnackbar(error.errorMessage, 'error');

            if (deviceHelper.isNativeApp()) {
                if (deviceHelper.AndroidCordova()) {
                    androidBrowserRef.close();
                } else {
                    Browser.close();
                }
            }

            authSocket.close();
        });

        authSocket.on('disconnect', () => {
            if (deviceHelper.AndroidCordova()) {
                androidBrowserRef.close();
            } else {
                Browser.close();
            }

            authSocket.close(); // Redundant?
        });

        // Store socket in state so it can be closed elsewhere
        setSocket(authSocket);

        if (deviceHelper.isNativeApp()) {
            if (deviceHelper.AndroidCordova()) {
                androidBrowserRef = await InAppBrowser.create(
                    `${process.env.REACT_APP_API_URL}/redirect/${state}`,
                    '_blank',
                    'location=yes,popup=yes'
                );

                androidBrowserRef.on('exit').subscribe(event => {
                    handleCustomAuthWindowClose(authSocket);
                });
            } else {
                await Browser.open({
                    url: `${process.env.REACT_APP_API_URL}/redirect/${state}`,
                    presentationStyle: 'popover'
                });

                Browser.addListener('browserFinished', () => {
                    handleCustomAuthWindowClose(authSocket);
                });
            }
        } else if (!deviceHelper.isNativeApp()) {
            const popupWindow = window.open(
                `${process.env.REACT_APP_API_URL}/redirect/${state}`,
                'firebaseAuth',
                'height=615,width=650'
            );

            const popupWindowTimer = setInterval(() => {
                if (popupWindow.closed) {
                    clearInterval(popupWindowTimer);
                    handleCustomAuthWindowClose(authSocket);
                }
            }, 1000);
        }
    };

    const handleRegisterOAuthCustomer = async (oAuthUser, callbackFunction = () => {}) => {
        let idToken = await FirebaseAdmin.getCurrentUsersIDToken();
        let dispatchObj = await FirebaseAdmin.registerWithOAuthUser({ ...oAuthUser, ad_id, adgroup }, idToken, charity);

        setShowAuthDialog(false);
        if (dispatchObj instanceof Error) {
            onSnackbar(loc(dispatchObj.message, lang) || dispatchObj.message, 'error');
        } else if (dispatchObj.twoFactorAuthenticationCodeRequired) {
            return dispatchObj;
        } else {
            dispatch(dispatchObj);
            history.push('/login');
            callbackFunction();
        }
    };

    const handleRegisterOAuthCharityAndBottleDrive = async () => {
        let dispatchObj;
        let errorsLatest = validateForm();

        if (containsErrors(errorsLatest)) {
            return;
        }

        if ([REGISTRATION_TABS.GROUP, REGISTRATION_TABS.CHARITY].includes(tabIndex) && _.isNil(file)) {
            return onSnackbar('Please upload your logo first.', 'error');
        }

        let keys = getValidatedKeys(tabIndex, hasAnAccount, taxReceiptsIssued, oAuthRegistration);
        let formAdjusted = _.pick(form, keys);
        if (!_.isNil(charity) && [REGISTRATION_TABS.PERSONAL, REGISTRATION_TABS.BUSINESS].includes(tabIndex)) {
            formAdjusted.charityPreferred = charity._id;
        }

        if (!differentTeams) {
            formAdjusted.subdivisions = [];
        }
        if (!_.isEmpty(formAdjusted.location)) {
            formAdjusted.location.timezone = timeHelper.getTimezone();
        } else {
            formAdjusted.location = ipAddressLocation; //use location inferred from ip address if no address entered in form
            formAdjusted.location.timezone = timeHelper.getTimezone();
        }

        formAdjusted.promos = promos;

        setInProgress(true);

        formAdjusted.deviceInfo = await getDeviceInformation(); // get information about the device

        let idToken = await FirebaseAdmin.getCurrentUsersIDToken();

        if ([REGISTRATION_TABS.GROUP, REGISTRATION_TABS.CHARITY].includes(tabIndex)) {
            dispatchObj = await FirebaseAdmin.registerOAuthOrganisation(
                formAdjusted,
                file,
                { ...oAuthUser, ad_id, adgroup },
                idToken
            );
        } else if (tabIndex === REGISTRATION_TABS.BOTTLE_DRIVE) {
            dispatchObj = await FirebaseAdmin.registerOAuthBottleDrive(
                formAdjusted,
                { ...oAuthUser, ad_id, adgroup },
                idToken
            );
        }

        setInProgress(false);

        setShowAuthDialog(false);
        if (dispatchObj instanceof Error) {
            onSnackbar(loc(dispatchObj.message, lang) || dispatchObj.message, 'error');
        } else {
            dispatch(dispatchObj);
            history.push('/login');
        }
    };

    const handleCancelOAuthRegistration = async () => {
        attemptSocketClose(socket);

        await FirebaseAdmin.logOutOAuth();
        setShowAuthDialog(false);
        setOAuthUser(null);
        setOAuthRegistration(false);
    };

    return {
        showAuthDialog,
        oAuthRegistration,
        oAuthUser,
        handleOAuth,
        handleCustomAuth,
        handleRegisterOAuthCustomer,
        handleRegisterOAuthCharityAndBottleDrive,
        handleCancelOAuthRegistration,
        setShowAuthDialog
    };
}

export default useOAuthDialog;

function attemptSocketClose(socket) {
    // Not necessary, but no point leaving sockets open if they aren't being used
    try {
        socket.close();
    } catch (err) {}
}
