import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash';
import { _user, _collector, _pickup } from 'std';
import moment from 'moment-timezone';

import {
    getPickupDisplayedInfo,
    formatAsAddress,
    formatAsPhoneNumber,
    getFormattedPickupFrequency,
    formatAsCurrency
} from 'utils/misc';

import OperatorContext from 'utils/contexts/OperatorContext';

import Icon from '@material-ui/core/Icon';
import { withTheme } from '@material-ui/core/styles';
import {
    Button,
    Avatar,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Dialog,
    DialogTitle,
    DialogContent,
    colors,
    Typography,
    FormControl,
    FormControlLabel,
    Checkbox,
    Tooltip
} from '@material-ui/core';
import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery';
import Badge from 'components/Badge/Badge';
import { Icon as MDIcon } from '@mdi/react';
import * as allIcons from '@mdi/js';

// import PayloadIndicators from 'components/DriverWidgets/PayloadIndicators';
import DetailedPayloadIndicators from 'components/DriverWidgets/DetailedPayloadIndicators';
import PickupStatusIndicators from 'components/DriverWidgets/PickupStatusIndicators';
import InfoDialog from 'components/Dialogs/Pickup/Info';

import ImageViewer from 'components/ImageUploads/ImageViewer';
import ConfirmDialogContext from 'components/Dialogs/Confirm/ConfirmDialogContext';
import ReactMarkdown from 'react-markdown';
import customDriverCommentRenderer from 'utils/Markdown/customRenderer/driverComment/customDriverCommentRenderer';

function AdminUI(props) {
    const {
        theme,
        http,
        ListItemExpandable,
        getPickupCombinedComment,
        isSystemAdmin,
        pickup,
        pinSelected,
        commoditiesAvailable,
        charities,
        launchGoogleMaps,
        setCompleteDialogOpen,
        setAbortDialogOpen,
        setSkipDialogOpen,
        onSnackbar,
        handleRecalc,
        onEditStop,
        pickupTypeColor,
        promoCode,
        setAsFirstPickup,
        setIsExpanded
    } = props;

    const warnAction = useContext(ConfirmDialogContext);

    const operator = useContext(OperatorContext);
    const [payloadImageOpen, setPayloadImageOpen] = useState(false);
    const [collector, setCollector] = useState(null);
    const [driver, setDriver] = useState(null);
    const [pickups, setPickups] = useState([]);
    const [trip, setTrip] = useState(null);
    const [avatar, setAvatar] = useState(null);
    const [primary, setPrimary] = useState(null);
    const [secondary, setSecondary] = useState(null);
    const [buttons, setButtons] = useState(null);
    const [showInfoDialog, setShowInfoDialog] = useState(false);
    const [skippedReason, setSkippedReason] = useState('');
    const [charityName, setCharityName] = useState('');
    const [infoDialogAvaliable, setInfoDialogAvaliable] = useState(false);
    const [payloadImages, setPayloadImages] = useState([]);
    const [skipReasonImages, setSkipReasonImages] = useState([]);

    const isMobile = useMediaQuery('(max-width:600px)');
    const etaList = _.get(pickup, 'estimatedCompletionDates');
    const eta = etaList ? _.last(etaList) : null;
    const isLate = eta
        ? moment(eta)
              .tz(pickup.location.timezone)
              .isBefore(new Date())
        : false;

    const getCharityInfo = charity_id => {
        if (!_.isNil(charity_id)) {
            const charity = _.find(charities, charity => charity._id === charity_id);
            if (!_.isNil(charity)) {
                return charity.name.length > 40 ? charity.name.substring(0, '20') + '...' : charity.name;
            } else {
                return 'INVALID';
            }
        } else {
            return '—';
        }
    };

    const handleViewAccount = url => () => {
        window.open(url, '_blank');
    };

    const handleDeleteStop = async () => {
        const res = await http.post(`/stops/${pickup._id}/delete`, {});
        if (res.ok) {
            onSnackbar('Removed out of system stop.');
            handleRecalc();
        }
    };

    const handlePayloadImageOpen = state => {
        setPayloadImageOpen(state);
    };

    const handlePhoneCall = phone => {
        window.open(`tel:${process.env.REACT_APP_COUNTRY_CALLING_CODE}${phone}`);
    };

    const getCollector = async code => {
        const res = await http.getJSON(`/collector?code=${code}`);
        if (res.ok) {
            setCollector(res.data.collector);
        }
    };

    const getDriver = async _id => {
        const res = await http.getJSON(`/user?_id=${_id}`);
        if (res.ok) {
            setDriver(res.data.user);
        }
    };

    const getPickupsAndTrip = async trip_id => {
        const pickupRes = await http.getJSON(`/pickups?trip_id=${trip_id}`);
        if (pickupRes.ok) {
            setPickups(pickupRes.data.pickups);
        }

        const tripRes = await http.getJSON(`/trips?_id=${trip_id}`);
        if (tripRes.ok) {
            setTrip(tripRes.data.trip);
        }
    };

    const handleSkipPickup = async () => {
        if (pickup.skipped) {
            const formData = new FormData();
            formData.append(
                'form',
                JSON.stringify({
                    pickup_id: pickup._id,
                    trip_id: _.get(pickup, 'trip._id', pickup.trip),
                    skipped: false
                })
            );

            const res = await http.post('/pickups/skipPickup', formData, false, true);

            if (res.ok) {
                onSnackbar('Pickup unskipped');
                handleRecalc();
            } else {
                onSnackbar('Failed to unskip this pickup. Please try again in a moment.', 'error');
            }
        } else {
            setSkipDialogOpen(true);
        }
    };

    const typeOfPickup = _pickup.isOutOfSystemStop(pickup) ? 'Out of System' : pickup.pickupType;
    const buzzerCode = _.get(pickup, 'location.buzzerCode');
    useEffect(() => {
        if (!_.isEmpty(pickup)) {
            let secondaryText = getPickupDisplayedInfo(pickup, 'System Administrator');
            if (_pickup.isOutOfSystemStop(pickup)) {
                secondaryText = `${_.get(pickup, 'receiver.collector.name', 'TBD')}`;
                if (!_.isNil(_.get(pickup, 'receiver', null))) {
                    secondaryText += ` - ${_.get(pickup, 'receiver.name.first', '')} ${_.get(
                        pickup,
                        'receiver.name.last',
                        ''
                    )}`;
                }
            }
            setAvatar(
                _pickup.isOutOfSystemStop(pickup) && !_.isNil(_.get(pickup, 'stopIcon', null)) ? (
                    <MDIcon
                        path={_.get(allIcons, convertToImportName(pickup.stopIcon))}
                        size={2.2}
                        color={
                            _.isNil(colors[pickup.stopIconColor])
                                ? pickup.stopIconColor
                                : colors[pickup.stopIconColor][500]
                        }
                    />
                ) : (
                    _pickup.numberOfPayloadBags(pickup, commoditiesAvailable)
                )
            );
            setPrimary(
                <span
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'flex-end'
                    }}
                >
                    <Badge
                        style={{
                            backgroundColor: _pickup.isOutOfSystemStop(pickup)
                                ? _.isNil(colors[pickup.stopIconColor])
                                    ? _.get(
                                          pickup,
                                          'stopIconColor',
                                          _.get(pickupTypeColor, typeOfPickup, 'rgba(0, 0, 0, .54)')
                                      )
                                    : colors[pickup.stopIconColor][500]
                                : _.get(pickupTypeColor, typeOfPickup, 'rgba(0, 0, 0, .54)'),
                            color: '#fff',
                            whiteSpace: 'nowrap',
                            maxWidth: '70%',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            marginRight: '10px'
                        }}
                    >
                        {_pickup.isCommercial(pickup) ? (
                            <>{_.get(pickup, 'location.businessName', <i>Commercial</i>)}</>
                        ) : _pickup.isOutOfSystemStop(pickup) ? (
                            _.get(pickup, 'stopName', typeOfPickup)
                        ) : (
                            typeOfPickup
                        )}
                    </Badge>
                    <span
                        style={{
                            textDecoration: 'underline',
                            cursor: 'pointer',
                            fontSize: theme.typography.fontSize * 1.15,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                        }}
                        onClick={launchGoogleMaps(pickup.location)}
                    >
                        {formatAsAddress(pickup.location)}
                    </span>
                </span>
            );
            setSecondary(
                <span>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'flex-end'
                        }}
                    >
                        <span
                            style={{
                                color: theme.palette.primary.dark,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                maxWidth: _pickup.isOutOfSystemStop(pickup) ? '50%' : '35%',
                                whiteSpace: 'nowrap'
                            }}
                            data-cy="admin-ui-customer-name"
                        >
                            {secondaryText}
                        </span>{' '}
                        <span data-cy="admin-ui-recurrence">
                            {!_.isNil(pickup.nextDate) ? (
                                <span>Every {getFormattedPickupFrequency(theme, pickup, 7)}</span>
                            ) : (
                                'One-time pickup'
                            )}
                        </span>
                        {!_.isNil(_.get(pickup, 'phone')) || !_.isNil(_.get(pickup, 'customer.phone')) ? (
                            <span
                                style={{
                                    color: theme.palette.text.secondary,
                                    fontSize: theme.typography.fontSize * 1.1
                                }}
                                data-cy="admin-ui-pickup-phone"
                            >
                                {formatAsPhoneNumber(_pickup.getPhoneNumber(pickup, pickup.customer))}
                            </span>
                        ) : (
                            <span style={{ color: theme.palette.text.disabled }} />
                        )}
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        {!_pickup.isOutOfSystemStop(pickup) && (
                            <DetailedPayloadIndicators
                                commodities={_.get(pickup, 'zone.payloadAccepted', {})}
                                pickups={[pickup]}
                                style={{ marginTop: theme.spacing.unit }}
                                indicatorSize={0.75}
                                labelStyle={{
                                    fontSize: '0.75rem'
                                }}
                                showUnits
                            />
                        )}
                        {_pickup.isOutOfSystemStop(pickup) && (
                            <div>
                                <div>Stop Duration: {formatStopDuration(pickup)}</div>
                                {!_.isNil(pickup.stopPayAmount) && (
                                    <div>Stop Pay: {formatAsCurrency(pickup.stopPayAmount)}</div>
                                )}
                            </div>
                        )}
                        {!_.isNil(pickup.trip) && (
                            <Tooltip
                                title={pickup.manualTimeOverride ? 'Time override cannot be set as first pickup' : ''}
                            >
                                <FormControl
                                    style={{
                                        marginLeft: theme.spacing.unit,
                                        marginTop: theme.spacing.unit
                                    }}
                                    disabled={pickup.manualTimeOverride}
                                >
                                    <FormControlLabel
                                        label={
                                            <Typography
                                                variant="body2"
                                                style={{
                                                    fontSize: '80%',
                                                    marginLeft: theme.spacing.unit / 2,
                                                    marginRight: 0,
                                                    paddingLeft: 0,
                                                    paddingRight: 0
                                                }}
                                            >
                                                First Pickup
                                            </Typography>
                                        }
                                        control={
                                            <Checkbox
                                                name="placeAtStartOfTrip"
                                                checked={pickup.placeAtStartOfTrip}
                                                value="placeAtStartOfTrip"
                                                onChange={() =>
                                                    warnAction(
                                                        () =>
                                                            setAsFirstPickup(
                                                                pickup.placeAtStartOfTrip ? null : pickup._id,
                                                                _.get(pickup, 'trip._id', pickup.trip)
                                                            ),
                                                        pickup.placeAtStartOfTrip
                                                            ? 'Are you sure you want to remove the First Pickup flag from this pickup?'
                                                            : 'Are you sure you want make this the first pickup? This action will overwrite the previous first pickup for this trip.'
                                                    )
                                                }
                                                disabled={pickup.manualTimeOverride}
                                                style={{
                                                    marginLeft: theme.spacing.unit,
                                                    marginRight: 0,
                                                    paddingLeft: 0,
                                                    paddingRight: 0
                                                }}
                                            />
                                        }
                                    />
                                </FormControl>
                            </Tooltip>
                        )}

                        <PickupStatusIndicators
                            pickup={pickup}
                            style={{ marginTop: theme.spacing.unit }}
                            indicatorSize={0.75}
                            labelStyle={{
                                fontSize: '0.75rem'
                            }}
                            numberOfBins={pickup.numberOfBins}
                        />
                    </div>
                </span>
            );
            setButtons(
                <>
                    {isSystemAdmin &&
                        (!_.isNil(pickup.customer) ? (
                            <Button
                                data-cy="admin-ui-edit-button"
                                variant="outlined"
                                color="default"
                                style={{
                                    marginLeft: theme.spacing.unit * 0.5,
                                    marginRight: theme.spacing.unit * 0.5
                                }}
                                onClick={handleViewAccount(`/customers/${pickup.customer._id}`)}
                            >
                                {isMobile ? <Icon>visibility</Icon> : 'Edit/View'}
                            </Button>
                        ) : (
                            <Button
                                data-cy="admin-ui-stop-edit-button"
                                variant="outlined"
                                color="default"
                                style={{
                                    marginLeft: theme.spacing.unit * 0.5,
                                    marginRight: theme.spacing.unit * 0.5
                                }}
                                onClick={() => onEditStop(pickup)}
                            >
                                {isMobile ? <Icon>edit</Icon> : 'Edit Stop'}
                            </Button>
                        ))}

                    <Button
                        data-cy="admin-ui-pickup-complete"
                        disabled={pickup.unserviced}
                        variant="contained"
                        color="primary"
                        style={{
                            flexGrow: 1,
                            marginLeft: theme.spacing.unit * 0.5,
                            marginRight: theme.spacing.unit * 0.5
                        }}
                        onClick={() => {
                            pickup.trip
                                ? setCompleteDialogOpen(true)
                                : onSnackbar(
                                      'In order to complete this pickup, it must first be assigned to a driver and a trip',
                                      'info'
                                  );
                        }}
                    >
                        {isMobile ? <Icon>done</Icon> : 'Complete'}
                    </Button>
                    {!_.isNil(pickup.customer) ? (
                        <Button
                            data-cy="admin-ui-pickup-abort"
                            disabled={pickup.unserviced}
                            variant="outlined"
                            color="secondary"
                            style={{
                                marginLeft: theme.spacing.unit * 0.5,
                                marginRight: theme.spacing.unit * 0.5
                            }}
                            onClick={() => setAbortDialogOpen(true)}
                        >
                            Abort
                        </Button>
                    ) : (
                        <Button
                            data-cy="admin-ui-delete-stop"
                            variant="outlined"
                            color="secondary"
                            style={{
                                marginLeft: theme.spacing.unit * 0.5,
                                marginRight: theme.spacing.unit * 0.5
                            }}
                            onClick={() => handleDeleteStop()}
                        >
                            Delete
                        </Button>
                    )}
                    <Button
                        data-cy="admin-ui-pickup-skip"
                        disabled={pickup.unserviced}
                        variant="outlined"
                        color="primary"
                        style={{
                            marginLeft: theme.spacing.unit * 0.5,
                            marginRight: theme.spacing.unit * 0.5
                        }}
                        onClick={handleSkipPickup}
                    >
                        {pickup.skipped ? 'Unskip' : 'Skip'}
                    </Button>
                </>
            );
        } else if (pinSelected) {
            if (_.get(pinSelected, 'code')) {
                getCollector(pinSelected.code);
            } else if (_.get(pinSelected, 'name')) {
                getDriver(pinSelected._id);
                getPickupsAndTrip(pinSelected.trip_id);
            } else if (_.get(pinSelected, 'place_id')) {
                getDriver(pinSelected.transporter);
            }
        }
    }, [pinSelected, pickup]);

    useEffect(() => {
        if (pinSelected) {
            if (pinSelected.code) {
                //Destination
                setAvatar(<Icon>business</Icon>);
                setPrimary(pinSelected.description);
                setSecondary(
                    <>
                        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            <span style={{ color: theme.palette.primary.dark }}>{_collector.getName(collector)}</span>
                            <span> ({_collector.getCode(collector)})</span>
                        </div>

                        <div style={{ minHeight: 20 }}>
                            {(_collector.getCollectorrEmails(collector) || []).join(', ')}
                        </div>
                    </>
                );
                setButtons(
                    <>
                        {isSystemAdmin && (
                            <Button
                                data-cy="admin-ui-edit-button"
                                variant="outlined"
                                color="default"
                                style={{
                                    marginLeft: theme.spacing.unit * 0.5,
                                    marginRight: theme.spacing.unit * 0.5,
                                    flex: 1
                                }}
                                onClick={handleViewAccount(
                                    `/operators/${_.get(collector, 'collectorManager._id')}/collector`
                                )}
                            >
                                <Icon style={{ marginRight: theme.spacing.unit }}>visibility</Icon>
                                View Collector
                            </Button>
                        )}
                    </>
                );
            } else if (pinSelected.name) {
                //Driver
                const abortedPickups = _(pickups)
                    .filter(p => _pickup.isAborted(p))
                    .value();
                const completedPickups = _(pickups)
                    .filter(p => _pickup.isComplete(p))
                    .value();
                const skippedPickups = _(pickups)
                    .filter(p => _pickup.isSkipped(p))
                    .value();
                const finishedPickups = _([...completedPickups, ...abortedPickups])
                    .orderBy(p => new Date(p.completionDate ? p.completionDate : p.updatedAt), 'asc')
                    .value();

                let actualStartTime = 'N/A',
                    actualDuration = 'N/A',
                    timePerBag = 'N/A',
                    timePerPickup = 'N/A';

                if (!_.isEmpty(finishedPickups)) {
                    actualStartTime = moment(_.first(finishedPickups).completionDate)
                        .tz(_.first(finishedPickups).location.timezone)
                        .format('h:mm A');

                    actualDuration = moment(_.last(finishedPickups).completionDate)
                        .tz(_.first(finishedPickups).location.timezone)
                        .diff(
                            moment(_.first(finishedPickups).completionDate).tz(
                                _.last(finishedPickups).location.timezone
                            ),
                            'minutes'
                        );

                    timePerPickup = (actualDuration / finishedPickups.length).toFixed(2);

                    timePerBag = (
                        actualDuration /
                        finishedPickups.reduce(
                            (sum, { payload }) => sum + Object.values(payload).reduce((sum, val) => sum + val, 0),
                            0
                        )
                    ).toFixed(2);
                }

                let lastETARecalc = _.last(_.get(trip, 'etaRecalculations', []));
                setAvatar(<Icon>directions_car</Icon>);
                setPrimary(
                    <span style={{ color: theme.palette.primary.dark }}>
                        {_user.getNameFull(driver)}{' '}
                        <span style={{ color: theme.palette.text.secondary }}>
                            {_user.getPhoneNumberFormatted(driver)} | {_user.getEmail(driver)}
                        </span>
                        <br />
                    </span>
                );
                setSecondary(
                    <div style={{ fontSize: theme.typography.caption.fontSize }}>
                        {trip && (
                            <>
                                <span title="Start Time: Estimated/Actual">
                                    <b>Start:</b> (
                                    {moment(trip.startTime)
                                        .tz(trip.timezone)
                                        .format('h:mm A')}{' '}
                                    / {actualStartTime})
                                </span>{' '}
                                |{' '}
                                <span title="Duration: Estimated/Actual (mins)">
                                    <b>Dur:</b> ({Math.round(_.get(trip, 'duration') / 60)}m / {actualDuration}m)
                                </span>{' '}
                                |{' '}
                                <span title="Time: per Pickup/per Bag (mins)">
                                    <b>Time:</b> ({timePerPickup}m / {timePerBag}m)
                                </span>
                            </>
                        )}

                        <br />
                        <span title="Total | Completed | Aborted | Skipped | Rescheduled">
                            <b>Total:</b> {pickups.length} (<b>Completed:</b> {completedPickups.length} |{' '}
                            <b>Aborted: </b> {abortedPickups.length} | <b>Skipped:</b> {skippedPickups.length})
                        </span>
                        <br />
                        <span title="Last ETA Calculation">
                            <b>ETA Calculations:</b> {_.get(trip, 'etaRecalculations', []).length} (<b>Last Recalc:</b>{' '}
                            {_.isNil(lastETARecalc) || _.isNil(lastETARecalc.recalculationTimestamp)
                                ? 'N/A'
                                : moment(_.get(lastETARecalc, 'recalculationTimestamp'))
                                      .tz(process.env.REACT_APP_REGION_TIMEZONE)
                                      .format('YYYY-MM-DD HH:mm')}{' '}
                            | <b>Initiator:</b> {_.get(lastETARecalc, 'initiator', 'N/A')} |{' '}
                            <b>{_.get(lastETARecalc, 'manualRecalculation', false) ? 'Manual' : 'Automatic'}</b>)
                        </span>
                        {/* TODO: Add Rescheduled to this list */}
                    </div>
                );
                setButtons(
                    <>
                        <Button
                            variant="outlined"
                            color="default"
                            style={{
                                marginLeft: theme.spacing.unit * 0.5,
                                marginRight: theme.spacing.unit * 0.5,
                                flex: 1
                            }}
                            onClick={handleViewAccount(
                                `/operators/${_user.getId(operator)}/collector-completed?collector=${
                                    pinSelected.collector_id
                                }&receiver=${pinSelected._id}`
                            )}
                            data-cy="pickups-admin-info-panel-view-trip"
                        >
                            <Icon style={{ marginRight: theme.spacing.unit }}>visibility</Icon>
                            View Trip
                        </Button>
                        <Button
                            variant="outlined"
                            color="default"
                            style={{
                                marginLeft: theme.spacing.unit * 0.5,
                                marginRight: theme.spacing.unit * 0.5,
                                flex: 1
                            }}
                            onClick={() => handlePhoneCall(_user.getPhoneNumber(driver))}
                        >
                            <Icon style={{ marginRight: theme.spacing.unit }}>phone</Icon>
                            Call
                        </Button>
                    </>
                );
            } else if (pinSelected.place_id) {
                //Start location
                setAvatar(<Icon>flag</Icon>);
                setPrimary(
                    <span>
                        Recalculation initated by{' '}
                        <span style={{ color: theme.palette.primary.dark }}>{_user.getNameFull(driver)}</span>
                    </span>
                );
                setSecondary(
                    <span>
                        {moment(pinSelected.date)
                            .tz(process.env.REACT_APP_REGION_TIMEZONE)
                            .format('LT MMM Do, YYYY')}
                    </span>
                );
            }
        }
    }, [collector, driver, pickups, trip]);

    useEffect(() => {
        setInfoDialogAvaliable(false);
        setSkippedReason('');
        setCharityName('');
        setPayloadImages(_.get(pickup, 'images.payloadImages', []));
        setSkipReasonImages(_.get(pickup, 'images.abortImages', []));
        if (!_.isNil(pickup.explanation)) {
            setSkippedReason(pickup.explanation);
        }
        let charity = null;
        if (!_.isNil(pickup.charity)) {
            charity = _.find(charities, charity => charity._id === pickup.charity);
            setCharityName(_.get(charity, 'name'));
        }
        setInfoDialogAvaliable(!_.isNil(pickup.explanation) || !_.isNil(charity));
    }, [pickup]);

    return (
        <>
            <InfoDialog
                open={showInfoDialog}
                onClose={() => {
                    setShowInfoDialog(false);
                }}
                reason={_.isNil(skippedReason) ? '' : skippedReason}
                charityName={_.isNil(charityName) ? '' : charityName}
                skipReasonImages={skipReasonImages}
            />
            <div>
                <List dense disablePadding style={{ paddingTop: theme.spacing.unit * 1 }}>
                    <ListItem disableGutters style={{ padding: 0 }}>
                        <ListItemIcon
                            style={{ marginRight: 0, display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                        >
                            <Avatar
                                style={{
                                    backgroundColor: _pickup.isOutOfSystemStop(pickup)
                                        ? _.isNil(pickup.stopIcon)
                                            ? _.get(pickup, 'stopIconColor', 'black')
                                            : 'white'
                                        : theme.palette.primary.main,
                                    textDecoration: !_.isEmpty(payloadImages) ? 'underline' : 'unset',
                                    cursor: !_.isEmpty(payloadImages) ? 'pointer' : 'unset'
                                }}
                                onClick={() => handlePayloadImageOpen(true)}
                            >
                                {avatar}
                            </Avatar>
                        </ListItemIcon>
                        <ListItemText
                            primaryTypographyProps={{ noWrap: true }}
                            primary={primary}
                            secondary={secondary}
                            style={{ padding: 0, paddingLeft: theme.spacing.unit * 2 }}
                        />
                    </ListItem>
                    {!_.isEmpty(pickup) && (
                        <>
                            <ListItem
                                disableGutters
                                style={{
                                    padding: 0,
                                    paddingTop: theme.spacing.unit * 2
                                }}
                            >
                                <ListItemIcon
                                    style={{
                                        marginRight: 0
                                    }}
                                >
                                    <Avatar
                                        style={{
                                            backgroundColor: infoDialogAvaliable
                                                ? theme.palette.primary.main
                                                : theme.palette.action.active,
                                            cursor: infoDialogAvaliable ? 'pointer' : undefined
                                        }}
                                        onClick={
                                            infoDialogAvaliable
                                                ? () => {
                                                      setShowInfoDialog(true);
                                                  }
                                                : undefined
                                        }
                                    >
                                        <Icon>info</Icon>
                                    </Avatar>
                                </ListItemIcon>
                                <ListItemText
                                    primaryTypographyProps={{ noWrap: true }}
                                    primary={
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                flexWrap: pickup.skipped ? 'wrap' : 'nowrap'
                                            }}
                                        >
                                            {pickup.skipped ? (
                                                <span
                                                    style={{
                                                        display: 'block',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis'
                                                    }}
                                                    data-cy="skipped-badge"
                                                >
                                                    <Badge
                                                        style={{
                                                            backgroundColor: colors.yellow[500],
                                                            color: 'black',
                                                            marginRight: theme.spacing.unit
                                                        }}
                                                    >
                                                        SKIPPED: {pickup.explanation}
                                                    </Badge>
                                                </span>
                                            ) : (
                                                <span
                                                    style={{
                                                        color: isLate ? colors.red[500] : null,
                                                        fontWeight: isLate ? 500 : 400,
                                                        marginRight: '10px'
                                                    }}
                                                >
                                                    ETA:{' '}
                                                    {eta &&
                                                        moment(eta)
                                                            .tz(
                                                                _.get(
                                                                    pickup,
                                                                    'location.timezone',
                                                                    process.env.REACT_APP_REGION_TIMEZONE
                                                                )
                                                            )
                                                            .format('h:mm A')}
                                                </span>
                                            )}
                                            {_pickup.isOutOfSystemStop(pickup) ? (
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        flexWrap: 'nowrap'
                                                    }}
                                                >
                                                    {_.get(pickup, 'limitOneStopPerTrip', false) && (
                                                        <Badge
                                                            style={{
                                                                backgroundColor: theme.palette.primary.main,
                                                                color: '#fff',
                                                                display: 'block',
                                                                whiteSpace: 'nowrap',
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis'
                                                            }}
                                                        >
                                                            1 per Trip
                                                        </Badge>
                                                    )}
                                                    {_.get(pickup, 'placeAtStartOfTrip', false) && (
                                                        <Badge
                                                            style={{
                                                                backgroundColor: theme.palette.primary.main,
                                                                color: '#fff',
                                                                display: 'block',
                                                                whiteSpace: 'nowrap',
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis',
                                                                marginLeft: '10px'
                                                            }}
                                                        >
                                                            First Pickup
                                                        </Badge>
                                                    )}
                                                </div>
                                            ) : (
                                                <span
                                                    style={{
                                                        display: 'block',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis'
                                                    }}
                                                >
                                                    Donation: {getCharityInfo(pickup.charity)}
                                                </span>
                                            )}
                                        </div>
                                    }
                                    secondary={
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                flexWrap: 'wrap'
                                            }}
                                        >
                                            <span>
                                                Confirmed:{' '}
                                                {pickup.alwaysConfirmed
                                                    ? 'Always'
                                                    : _pickup.isConfirmed(pickup)
                                                    ? 'True'
                                                    : 'False'}
                                            </span>
                                            <span>
                                                ByPassZones:{' '}
                                                {pickup.alwaysBypassZoneChecks
                                                    ? 'Always'
                                                    : pickup.bypassZoneChecks
                                                    ? 'True'
                                                    : 'False'}
                                            </span>
                                            <span>
                                                NextDate:{' '}
                                                {pickup.nextDate
                                                    ? moment(pickup.nextDate)
                                                          .tz(
                                                              _.get(
                                                                  pickup,
                                                                  'location.timezone',
                                                                  process.env.REACT_APP_REGION_TIMEZONE
                                                              )
                                                          )
                                                          .format('MMM D')
                                                    : 'None Set'}
                                            </span>
                                        </div>
                                    }
                                    style={{ padding: 0, paddingLeft: theme.spacing.unit * 2 }}
                                />
                            </ListItem>
                            <ListItemExpandable
                                disableGutters
                                icon="comment"
                                primaryText={
                                    <>
                                        {buzzerCode && `(Buzzer Code: ${buzzerCode}) `}
                                        {pickup.numberOfBins > 0 ? `(Bins: ${pickup.numberOfBins}) ` : ''}
                                        {getPickupCombinedComment(pickup) ? (
                                            <ReactMarkdown
                                                allowedTypes={[
                                                    'root',
                                                    'text',
                                                    'break',
                                                    'paragraph',
                                                    'emphasis',
                                                    'strong',
                                                    'list',
                                                    'listItem',
                                                    'image'
                                                ]}
                                                unwrapDisallowed
                                                source={getPickupCombinedComment(pickup)}
                                                renderers={customDriverCommentRenderer}
                                            />
                                        ) : (
                                            <span style={{ color: theme.palette.text.disabled }}>
                                                No directions provided
                                            </span>
                                        )}
                                    </>
                                }
                                style={{
                                    padding: 0,
                                    paddingRight: theme.spacing.unit * 4,
                                    marginTop: theme.spacing.unit * 2
                                }}
                                setIsExpanded={setIsExpanded}
                            />
                            {!_.isNil(promoCode) && promoCode !== '' && (
                                <PromoCodeListItem
                                    promoCode={promoCode}
                                    theme={theme}
                                    style={{
                                        padding: 0,
                                        paddingRight: theme.spacing.unit * 4,
                                        marginTop: theme.spacing.unit * 2
                                    }}
                                />
                            )}
                        </>
                    )}
                    <ListItem
                        style={{
                            display: 'flex',
                            marginTop: theme.spacing.unit * 2,
                            padding: 0
                        }}
                    >
                        {buttons || <div style={{ height: 56.5 }} />}
                    </ListItem>
                </List>
            </div>
            {!_.isEmpty(payloadImages) && (
                <Dialog open={payloadImageOpen} fullWidth onClose={() => handlePayloadImageOpen(false)}>
                    <DialogTitle>Customer Payload Confirmation</DialogTitle>
                    <DialogContent>
                        <ImageViewer
                            displayImages={payloadImages}
                            disableDelete={true}
                            scrollerStyles={{ maxWidth: 420, overflowX: 'auto' }}
                        />
                    </DialogContent>
                </Dialog>
            )}
        </>
    );
}

export default withTheme()(AdminUI);

function formatStopDuration(pickup) {
    if (_.isNil(_.get(pickup, 'stopDuration'))) {
        return 'N/A';
    } else {
        return `${(_.get(pickup, 'stopDuration', 0) / 60).toLocaleString()} min(s)`;
    }
}

function PromoCodeListItem(props) {
    const { promoCode, theme, ...rest } = props;
    return (
        <ListItem {...rest}>
            <ListItemIcon style={{ marginRight: 0 }}>
                <Avatar style={{ backgroundColor: theme.palette.action.active }}>
                    <Icon>local_offer</Icon>
                </Avatar>
            </ListItemIcon>
            <div style={{ flexDirection: 'column', display: 'flex' }}>
                <ListItemText
                    disableTypography
                    primary={<Typography style={{ fontSize: '0.8125rem' }}>{'Applied PromoCode:'}</Typography>}
                    style={{ padding: 0, paddingLeft: theme.spacing.unit * 2 }}
                />
                <ListItemText
                    disableTypography
                    primary={<Typography style={{ fontSize: '0.8125rem' }}>{promoCode}</Typography>}
                    style={{ padding: 0, paddingLeft: theme.spacing.unit * 2 }}
                />
            </div>
        </ListItem>
    );
}

function convertToImportName(iconName) {
    return _.camelCase(`mdi-${iconName}`);
}
