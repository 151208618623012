import _ from 'lodash';
import { getCustomerFirstName, truncateBagtag, getQuickDropName } from '../utils/misc';
import moment from 'moment-timezone';

export default {
    isReported: function(bulk) {
        if (_.isNil(bulk.payloadIssues)) {
            return false;
        }
        return !_.isEmpty(bulk.payloadIssues.issues);
    },
    isOverdue: function(bulk, overdueHourWindow = 48) {
        return isBulkOverdue(bulk, overdueHourWindow);
    },
    isCounted: function(bulk) {
        if (_.isNil(bulk)) return false;
        return !_.isNil(bulk.dateCounted);
    },
    isAdjustment: function(bulk) {
        if (_.isNil(bulk)) return false;
        return bulk.bulkType === 'adjustment';
    },
    isUrgent: function(bulk) {
        return bulk.unresolvedCustomerIssue || bulk.isOverdue;
    },
    isCreditBulk: function(bulk) {
        return bulk.skuType === 'credit';
    },
    isPromoBulk: function(bulk) {
        return bulk.skuType === 'promotion';
    },
    isPenaltyBulk: function(bulk) {
        return bulk.skuType === 'penalty';
    },
    isDonating: function(bulk) {
        return !_.isNil(bulk.charity);
    },
    isPickup: function(bulk) {
        return bulk.bulkType === 'pickup';
    },
    isClothing: function(bulk) {
        return bulk.skuType === 'clothing';
    },
    isElectronics: function(bulk) {
        return bulk.skuType === 'electronics';
    },
    isFutile: function(bulk) {
        return _.get(bulk, 'skuType', '')
            .toLowerCase()
            .includes('futile');
    },
    isRedeemed: function(bulk) {
        return _.get(bulk, 'payments.owner.paid', false) || _.get(bulk, 'redemptionRequested', false);
    },
    isCompleted: function(bulk) {
        if (_.isNil(bulk)) return false;
        return !_.isNil(bulk.dateCompleted);
    },
    isSummarized: function(bulk) {
        return _.get(bulk, 'summarized.value', false);
    },
    isBeverage: function(bulk) {
        if (_.isNil(bulk)) return false;
        return bulk.skuType === 'beverage';
    },
    isCollectionBins: function(bulk) {
        if (_.isNil(bulk)) return false;
        return bulk.skuType === 'collectionbins';
    },
    isCommodityManual: function(bulk, commodities = []) {
        if (_.isNil(bulk) || _.isNil(commodities) || _.isEmpty(commodities)) return false;
        const commodity = commodities.find(c => c.skuType === bulk.skuType);
        if (_.isNil(commodity)) return false;
        return commodity.completionStrategy === 'Manual';
    },
    isResolved: function(bulk) {
        return _.get(bulk, 'payments.owner.paid', false) || _.get(bulk, 'redemptionRequested', false);
    },
    hasIssues: function(bulk) {
        return !_.isEmpty(_.get(bulk, 'payloadIssues.issues', null));
    },
    hasCustomerIssue: function(bulk) {
        return _.get(bulk, 'customerIssues.customerHasIssue', false);
    },
    hasUnresolvedCustomerIssue: function(bulk) {
        const dateResolved = _.get(bulk, 'customerIssues.dateResolved', null);
        const customerHasIssue = _.get(bulk, 'customerIssues.customerHasIssue', false);

        return customerHasIssue && _.isNil(dateResolved);
    },
    hasResolvedCustomerIssue: function(bulk) {
        const dateResolved = _.get(bulk, 'customerIssues.dateResolved', null);
        const customerHasIssue = _.get(bulk, 'customerIssues.customerHasIssue', false);
        return customerHasIssue && !_.isNil(dateResolved);
    },
    getCustomerUniqueID: function(bulk) {
        return _.get(bulk, 'owner.uniqueID', '');
    },
    getCollectorCode: function(bulk) {
        return _.get(bulk, 'collector.code', '');
    },
    getPromoCode: function(bulk) {
        return _.first(_.get(bulk, 'promosApplied', []));
    },
    getPickupOrDropOffPhotoURL: function(bulk) {
        let imgURLs = [];

        if (bulk.isLostAndFound) {
            imgURLs = _.get(bulk, 'lostAndFoundImages', []);
        } else if (bulk.bulkType === 'inhouse') {
            imgURLs = _.get(bulk, 'quickdropConfirmationImages', []);
        } else if (bulk.bulkType === 'pickup') {
            imgURLs = _.get(bulk, 'pickup.images.pickupImages', []);
        }

        return imgURLs;
    },
    getCustomerComplaint: function(bulk) {
        const complaint = _.get(bulk, 'customerIssues.issueDescription', '');
        if (_.isEmpty(complaint)) {
            return 'No complaints!';
        } else {
            return complaint;
        }
    },
    getCountItemsName: function(bulk) {
        let item = '';
        switch (bulk.skuType) {
            case 'beverage':
                item = 'containers';
                break;
            case 'clothing':
                item = 'bag';
                break;
            case 'electronics':
                item = 'kg';
                break;
            case 'credit':
                item = 'item';
                break;
            default:
                item = 'N/A';
        }
        if (bulk.commodityAmount > 1) {
            item = item + 's';
        }
        return item;
    },
    getCollectorName: function(bulk) {
        return _.get(bulk, 'collector.name', '');
    },
    getAggregatedCounts: function(bulk) {
        const counts = _.get(bulk, 'counts', []);
        const items = [];

        for (let count of counts) {
            for (let item of count.items) {
                const existingItem = _.find(items, i => i.sku === item.sku);
                if (_.isNil(existingItem)) {
                    items.push(item);
                } else {
                    existingItem.quantity += item.quantity;
                }
            }
        }

        return items;
    },
    getCountsItemsWithAmount: function(bulk, skus) {
        let counts = _.get(bulk, 'counts', []);

        counts.forEach(count => {
            const bulkItems = count.items.map(item => {
                let sku = _.first(
                    _.filter(skus, s => {
                        return s.sku === item.sku;
                    })
                );
                if (!_.isNil(sku)) {
                    return {
                        ...item,
                        description: sku.description,
                        value: Math.trunc(Math.abs(sku.value) * item.quantity) //always round down decimal cents
                    };
                } else {
                    return item;
                }
            });

            count.items = bulkItems;
        });
        return counts;
    },
    getStatus: function(bulk, overdueHourWindow = 48) {
        if (!_.isNil(bulk) && !_.isNil(bulk.invoice)) {
            return 'invoiced';
        } else if (_.get(bulk, 'payments.owner.paid', false) || _.get(bulk, 'redemptionRequested', false)) {
            return 'redeemed';
        } else if (!_.isNil(bulk.dateCompleted)) {
            return 'submitted';
        } else if (!_.isNil(bulk.dateCounted)) {
            return 'counted';
        } else {
            if (isBulkOverdue(bulk, overdueHourWindow)) {
                return 'overdue';
            } else {
                return 'uncounted';
            }
        }
    },
    getPayload: function(bulk) {
        return _.get(bulk, 'pickup.payload', {
            beverage: 0,
            clothing: 0,
            electronics: 0
        });
    },
    // useful backwards compatible bagtag/label getter
    getLabel: function(bulk) {
        const mostRecentBag = _.last(bulk.bags);

        let bagtag = _.get(bulk, 'owner.uniqueID', '');

        if (!_.isNil(mostRecentBag)) {
            bagtag = truncateBagtag(mostRecentBag.bagtag);
        }
        const label = !_.isNil(bulk.label) ? truncateBagtag(bulk.label) : bagtag;
        return label;
    },

    getLabelWithCustomerID: function(bulk) {
        const mostRecentBag = _.last(bulk.bags);

        let bagtag = _.get(bulk, 'owner.uniqueID', '');

        if (!_.isNil(mostRecentBag)) {
            bagtag = truncateBagtag(mostRecentBag.bagtag);
        }

        let label = !_.isNil(bulk.label) ? truncateBagtag(bulk.label) : bagtag;

        if (!_.isNil(bulk.owner.uniqueID)) {
            label = label + (truncateBagtag(bulk.label) !== bulk.owner.uniqueID ? `, ${bulk.owner.uniqueID}` : '');
        }

        return label;
    },
    getExpectedNumberOfBeverageBags: function(bulk) {
        return _.get(bulk, 'pickup.payload.beverage', 0);
    },
    createEmptyPayload: function(bulk) {
        return {};
    },
    getBulkUnitOfMeasurement: function(bulk) {
        switch (bulk.skuType) {
            case 'beverage':
                return 'bag';
            case 'clothing':
                return 'bag';
            case 'electronics':
                return 'kg';
            case 'credit':
                return 'item';
            default:
                return 'N/A';
        }
    },
    getCommodityUOMFormatted: function(bulk) {
        let UOM = bulk.commodityUOM.toString();
        if (bulk.commodityAmount > 1) {
            UOM += 's';
        }
        return UOM;
    },
    getCommodityPayloadFormatted: function(bulk) {
        let UOM = bulk.commodityAmount + ' ' + bulk.commodityUOM.toString();
        if (bulk.commodityAmount > 1) {
            UOM += 's';
        }
        return UOM;
    },
    getBulkTypeFormatted: function(bulk, lang = 'en') {
        if (_.isNil(bulk.bulkType)) return 'Unknown';
        return bulk.bulkType === 'inhouse' ? getQuickDropName(lang) : bulk.bulkType;
    },
    getDriverName: function(bulk) {
        return getCustomerFirstName(_.get(bulk, 'receiver', 'N/A'));
    },
    getPickupAddress: function(bulk) {
        return _.get(bulk, 'pickup.location.description', 'N/A');
    },
    getMostRecentCounter: function(bulk) {
        const recentCounts = _.last(_.get(bulk, 'counts', []));
        return !_.isNil(recentCounts) ? getCustomerFirstName(_.get(recentCounts, 'operator', null)) : 'N/A';
    },
    getPickupImageURL: function(bulk) {
        return _.get(bulk, 'pickup.images.pickupImages', []);
    },
    getCustomerAmount: function(bulk) {
        const ledgerLines = _.get(bulk, 'ledger.lines', []);
        if (_.isEmpty(ledgerLines)) {
            return 0;
        } else {
            const value = bulk.ledger.lines.reduce((sum, line) => (sum += line.amount), 0);
            return -value;
        }
    },
    getAmounts: function(bulk) {
        let customerAmount = 0;
        let transporterAmount = 0;
        let collectorAmount = 0;
        let totalValue = 0;

        const ledgerLines = _.get(bulk, 'ledger.lines', []);

        if (_.isEmpty(ledgerLines)) {
            // do nothing
        } else {
            for (let line of ledgerLines) {
                if (line.amount > 0) {
                    collectorAmount += line.amount;
                } else {
                    totalValue += Math.abs(line.amount);
                }
                customerAmount += line.amount;
            }
        }

        // customer amounts are negative when there are no fees, so flip them
        customerAmount = -customerAmount; //NOTE: don't use math.abs here

        return {
            customerAmount,
            transporterAmount,
            collectorAmount,
            totalValue
        };
    },
    // returns the total value of the entire bulk without any of the fees
    getTotalValue: function(bulk) {
        if (_.isNil(bulk)) return 0;

        const ledgerLines = _.get(bulk, 'ledger.lines', []);

        if (_.isEmpty(ledgerLines)) {
            return 0;
        } else {
            // only add the deposit amounts together
            return bulk.ledger.lines.reduce((sum, line) => {
                if (line.amount < 0) {
                    return sum + Math.abs(line.amount);
                } else {
                    return sum;
                }
            }, 0);
        }
    },
    getCustomFeesValue: function(bulk) {
        if (_.isNil(bulk.customFees) || _.isEmpty(bulk.customFees)) {
            return 0;
        } else {
            return bulk.customFees.reduce((sum, fee) => sum + fee.amount, 0);
        }
    },
    hasLedger: function(bulk) {
        return !_.isNil(bulk.ledger) && !_.isEmpty(bulk.ledger);
    },
    isInvoiced: function(bulk) {
        return !_.isNil(_.get(bulk, 'invoice')) || !_.isNil(_.get(bulk, 'csfInvoice'));
    },
    getDropLocationName: function(bulk) {
        return _.get(bulk, 'dropLocation.name', '');
    },
    getQuickdropDropOffDate: function(bulk) {
        return _.get(bulk, 'createdAt');
    },
    getBulkCounterFinancials: function(bulk, skus) {
        let currentCounts = _.get(bulk, 'counts', []);
        if (_.isNil(bulk) || _.isNil(currentCounts)) {
            return { totalFees: 0, totalPreviousCountsAmount: 0, grandTotal: 0 };
        }
        const totalFees = _.get(bulk, 'customFees', []).reduce((sum, item) => {
            return (sum += item.amount);
        }, 0);
        const totalPreviousCountsAmount = currentCounts.reduce((sum, c) => {
            return sum + getTotalAmountFromItems(c.items, skus[bulk.skuType]);
        }, 0);
        const grandTotal = totalPreviousCountsAmount - totalFees;
        return { totalFees, totalPreviousCountsAmount, grandTotal };
    },
    getCountedBy: function(bulk) {
        let expCounter;
        let foundCounter;

        const counts = _.get(bulk, 'counts', []);

        for (let i = 0; i < counts.length; i++) {
            const items = counts[i].items;
            if (!items) continue;

            for (let j = 0; j < items.length; j++) {
                const item = items[i];
                if (!item) continue;

                if (item.countedBy) {
                    expCounter = item.countedBy;
                    foundCounter = true;
                    break;
                }
            }

            if (foundCounter) break;
        }

        return expCounter;
    }
};

function isBulkOverdue(bulk, overdueHourWindow = 48) {
    if (_.isNil(bulk) || _.isNil(bulk.datePickedUp)) return false;
    if (!_.isNil(bulk.dateCompleted)) return false;
    const CURRENT_DAY = moment();
    if (Math.abs(CURRENT_DAY.diff(bulk.datePickedUp, 'hour')) >= overdueHourWindow) {
        return true;
    } else {
        return false;
    }
}

function inverseValue(val) {
    return -val;
}

function getTotalAmountFromItems(items, skus) {
    let total = 0;
    for (let item of items) {
        const sku = _(skus)
            .filter(s => s.sku === item.sku)
            .value();

        if (!_.isEmpty(sku)) {
            total += Math.trunc(inverseValue(_.first(sku).value) * item.quantity); //always round down decimal cents
        }
    }
    return total;
}
