import React, { useState, useEffect, useContext, useMemo } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';
import { _bulk, _commodity, _pickup } from 'std';

import pickupHelper from 'helpers/pickupHelper';
import { validatePayload } from 'utils/validate';
import {
    clusterAndSortReturnSitesByDistance,
    getClosestDropLocation,
    getClosestReturnSite
} from 'utils/latlngFunctions';
import { formatAsPhoneNumber, getGPSLocation, getQuickDropName } from 'utils/misc';

import {
    MobileStepper,
    Paper,
    CircularProgress,
    withMobileDialog,
    withTheme,
    withWidth,
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    List,
    ListItem,
    ListItemSecondaryAction,
    Icon,
    Avatar,
    Typography
} from '@material-ui/core';

import { Icon as MDIcon } from '@mdi/react';
import * as allIcons from '@mdi/js';

import Location from './screens/Location';
import Payload from './screens/Payload';
import PictureConfirmation from './screens/PictureConfirmation';
import Donation from './screens/Donation';
// import QrStep from './screens/QrStep';

import HttpContext from 'utils/contexts/HttpContext';
import { _dropLocation } from 'std';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

import useImageUpload from 'components/ImageUploads/useImageUpload';
import Instructions from './screens/Instructions';
import { DROP_OFF_TYPES } from 'constants.js';
import DialogTitlePrimary from 'components/MaterialUIExtensions/DialogTitlePrimary';
import OpenAccessDoor from './screens/OpenAccessDoor';
import { isWidthUp } from '@material-ui/core/withWidth';
import SearchDialog from './screens/SearchDialog';
import NoLocationFound from './screens/NoLocationFound';

function QuickdropSteps(props) {
    const {
        onClose,
        onBackPickupScreen,
        toggleHelpDialog,
        dropLocations,
        collectors,
        customer,
        charities,
        google,
        onReloadCustomer,
        onSnackbar,
        theme,
        bulks,
        lastPickup,
        collectorOverride,
        regions,
        multiBagDrops,
        commoditiesAvailable,
        // allowRequestLabels,
        charityEnabled,
        handleUpdateCurrentLocation,
        dropAndGoCollector,
        dropAndGoTechnologies,
        width,
        technologyFilter,
        onQRDialog,
        quickdropInstructions,
        kantechPhoneNumber,
        defaultToInstructions,
        defaultToActionStep,
        technologyTypeForInstructions
    } = props;
    const http = useContext(HttpContext);
    const { lang } = useContext(LocalizationContext);

    const { imageURLs, imagePreviews, uploadingImage, handleDeleteImage, handleAddImage } = useImageUpload({});

    let quickDropType = getQuickDropName(lang);

    const incompleteQuickdrops = _.filter(bulks, bulk => _bulk.isQuickDrop(bulk) && !_bulk.isCompleted(bulk));
    const lastIncompleteQuickdrop = _.last(incompleteQuickdrops);

    const charity = _.get(customer, 'charitySelected._id', null);

    const isSmUp = isWidthUp('sm', width);

    const getDropLocationId = () => {
        let collectorId = _.get(collectorOverride, '_id', '');
        if (!_.isNil(collectorId) && !_.isEmpty(collectorId)) {
            return collectorId;
        }
        return !_.isEmpty(dropLocations) ? dropLocations[0]._id : null;
    };

    const [activeStep, setActiveStep] = useState(0);
    const [skipInterveningSteps, setSkipInterveningSteps] = useState(false);
    const [quickdropForm, setQuickdropForm] = useState({
        dropLocationId: getDropLocationId(),
        charityPreferred: charity,
        donate: !_.isNil(charity),
        subdivision: null,
        taxReceiptRequested: _.get(customer, 'receiveTaxReceipts', true),
        customerLocation: null,
        address: _.get(customer, 'location', null),
        accessDoorCode: '',
        accessDoorCodeExpiry: null
    });
    const [inProgress, setInProgress] = useState(true);

    const [GPSLoading, setGPSLoading] = useState(false);

    const [overrideLocation, setOverrideLocation] = useState(customer.adminView);

    const [payload, setPayload] = useState(pickupHelper.createEmptyPayload());
    const [subPayloads, setSubPayloads] = useState([]);
    const [cumulativePayload, setCumulativePayload] = useState({});
    const [collector, setCollector] = useState(dropAndGoCollector);
    const [dropLocationTechnologies, setDropLocationTechnologies] = useState(dropAndGoTechnologies);

    const [returnSiteLookupOpen, setReturnSiteLookupOpen] = useState(false);

    const selectedDropLocation = useMemo(
        () => _.find(dropLocations, dropLocation => dropLocation._id === quickdropForm.dropLocationId),
        [quickdropForm.dropLocationId, dropLocations]
    );

    const selectedDropLocationTZ = useMemo(
        () => _.get(selectedDropLocation, 'collector.timezeone', process.env.REACT_APP_REGION_TIMEZONE),
        [selectedDropLocation]
    );

    // const qrScanRequired = allowRequestLabels && _.get(selectedDropLocation, 'qrScanRequired', false);
    const dropOffType = useMemo(() => _.get(selectedDropLocation, 'dropOffType', _.first(DROP_OFF_TYPES).value), [
        selectedDropLocation
    ]);
    const qrScanRequired = useMemo(() => _.get(selectedDropLocation, 'qrScanRequired', false), [selectedDropLocation]);

    const [date, setDate] = useState(moment().tz(selectedDropLocationTZ));

    const [growthPlanEnabled, setGrowthPlanEnabled] = useState(true);

    //set default drop location to closest one
    const getDropLocation = async () => {
        setGPSLoading(true);
        const coordinates = await getGPSLocation();
        setGPSLoading(false);

        let lat, lng;
        if (_.isNil(coordinates)) {
            lat = _.get(customer, 'location.lat');
            lng = _.get(customer, 'location.lng');

            if (_.isNil(lat) || _.isNil(lng)) {
                handleInProgress(false);
                return;
            }
        } else {
            lat = coordinates.coords.latitude;
            lng = coordinates.coords.longitude;
            handleUpdateCurrentLocation({ lat, lng });
        }
        const closestReturnSite = getClosestReturnSite(
            { lat, lng },
            dropLocations,
            collectors,
            technologyFilter,
            defaultToActionStep || defaultToInstructions ? null : customer.preferredReturnSite
        );
        if (!_.isNil(closestReturnSite)) {
            let dropLocationId = _.get(_.first(closestReturnSite.dropLocations), '_id');
            if (
                (defaultToInstructions || defaultToActionStep) &&
                !_.isNil(closestReturnSite.dropLocations) &&
                !_.isEmpty(closestReturnSite.dropLocations) &&
                !_.isNil(technologyTypeForInstructions)
            ) {
                let technology = _.find(DROP_OFF_TYPES, d => d.localizationName === technologyTypeForInstructions);
                if (!_.isNil(technology)) {
                    let filteredDropLocations = _.filter(
                        closestReturnSite.dropLocations,
                        dropLocation => dropLocation.dropOffType === technology.value
                    );
                    dropLocationId = _.get(_.first(filteredDropLocations), '_id');
                }
            }
            setQuickdropForm({
                ...quickdropForm,
                dropLocationId,
                customerLocation: { lat, lng }
            });
            if (_.isNil(collector) && (_.isNil(dropLocationTechnologies) || _.isEmpty(dropLocationTechnologies))) {
                setCollector(closestReturnSite.collector);
                setDropLocationTechnologies(closestReturnSite.dropLocations);
            }
        }

        if (!_.isNil(collectorOverride)) {
            setQuickdropForm({
                ...quickdropForm,
                customerLocation: { lat, lng }
            });
            handleInProgress(false);
            return;
        }

        handleInProgress(false);
    };

    useEffect(() => {
        getDropLocation();
        if (defaultToInstructions) {
            setActiveStep(1);
        } else if (defaultToActionStep && !_.isNil(selectedDropLocation)) {
            setActiveStep(2);
        }
    }, []);

    useEffect(() => {
        (async () => {
            const res = await http.getJSON('/config/charityTaskConfiguration');
            if (res.ok) {
                setGrowthPlanEnabled(res.data.config.growthPlanEnabled);
            }
        })();
    }, []);

    useEffect(() => {
        if (defaultToActionStep && _.isNil(selectedDropLocation)) {
            setActiveStep(0);
        }
    }, [selectedDropLocation]);

    useEffect(() => {
        setSkipInterveningSteps(dropOffType === 'Access Door');
    }, [dropOffType]);

    const handleInProgress = state => {
        if (state === true) {
            setInProgress(true);
            return;
        }

        // Uses timeout to ease flickering
        setTimeout(() => {
            setInProgress(false);
        }, 300);
    };

    const handleRefreshLocation = async () => {
        setGPSLoading(true);
        const coordinates = await getGPSLocation();
        setGPSLoading(false);

        let lat, lng;
        if (_.isNil(coordinates)) {
            lat = _.get(customer, 'location.lat');
            lng = _.get(customer, 'location.lng');

            if (_.isNil(lat) || _.isNil(lng)) {
                handleInProgress(false);
                return;
            }
            setQuickdropForm(quickdropForm => ({
                ...quickdropForm,
                customerLocation: { lat, lng }
            }));
        } else {
            lat = coordinates.coords.latitude;
            lng = coordinates.coords.longitude;
            setQuickdropForm(quickdropForm => ({
                ...quickdropForm,
                customerLocation: { lat, lng }
            }));
            handleUpdateCurrentLocation({ lat, lng });
        }
        // if (!_.isNil(coordinates)) {
        //     const lat = coordinates.coords.latitude;
        //     const lng = coordinates.coords.longitude;

        //     setQuickdropForm(quickdropForm => ({
        //         ...quickdropForm,
        //         customerLocation: { lat, lng }
        //     }));
        // } else {
        //     setQuickdropForm(quickdropForm => ({
        //         ...quickdropForm,
        //         customerLocation: null
        //     }));
        // }
    };

    const payloadErrors = validatePayload(
        _dropLocation.getPayloadAccepted(selectedDropLocation),
        _dropLocation.getPayloadRequired(selectedDropLocation),
        cumulativePayload,
        customer.adminView
    );

    const handleDropLocationChanged = e => {
        //const dropLocation = _.find(dropLocations, dropLocation => dropLocation._id === e.target.value);
        // const newDropLocation = _.find(dropLocations, { _id: e.target.value });
        // if (!newDropLocation.qrScanRequired && qrScanRequired) {
        //     setActiveStep(activeStep - 1);
        // }

        setPayload(pickupHelper.createEmptyPayload());
        setSubPayloads([]);
        setQuickdropForm({ ...quickdropForm, dropLocationId: e.target.value });
        setOverrideLocation(false);
    };

    const handleSelectReturnSite = returnSite => () => {
        setDropLocationTechnologies(returnSite.dropLocations);
        setCollector(returnSite.collector);
        setReturnSiteLookupOpen(false);
    };

    const handlePayload = e => {
        const updatedPayload = _.cloneDeep(payload);
        _.set(updatedPayload, e.target.name, e.target.value);

        setPayload(updatedPayload);
    };

    const handleSubPayloadsChange = e => {
        const path = e.target.name; // sub-commodity id
        const value = e.target.value;

        const updatedSubPayloads = _.cloneDeep(subPayloads);

        const commodity = _commodity.getCommodityBySubCommodityId(commoditiesAvailable, path);
        const subCommodity = _commodity.getSubCommodityById(commoditiesAvailable, path);

        let subPayload = _.find(updatedSubPayloads, subPayload => {
            const id = _.get(subPayload, 'subCommodity._id');
            return id === path;
        });

        if (!commodity || !subCommodity) return;

        if (subPayload) {
            subPayload.amount = value;
        } else {
            updatedSubPayloads.push({
                skuType: commodity.skuType,
                amount: value,
                subCommodity
            });
        }

        setSubPayloads(updatedSubPayloads);
    };

    useEffect(() => {
        const updatedPayload = _.cloneDeep(payload);
        _commodity.addSubPayloadsToPayload(updatedPayload, subPayloads);

        setCumulativePayload(updatedPayload);
    }, [payload, subPayloads]);

    const handleCharityPreferred = (charityId, subdivision) => () => {
        setQuickdropForm(quickdropForm => ({ ...quickdropForm, charityPreferred: charityId, subdivision }));
    };

    const handleChangeSubdivision = e => {
        const quickdropFormCopy = _.cloneDeep(quickdropForm);
        _.set(quickdropFormCopy, 'subdivision', e.target.value);
        setQuickdropForm(quickdropFormCopy);
    };

    const handleTaxReceiptChange = () => {
        setQuickdropForm(quickdropForm => ({
            ...quickdropForm,
            taxReceiptRequested: !quickdropForm.taxReceiptRequested
        }));
    };

    const handleAddressUnitChange = newUnitNumber => {
        const address = {
            ...quickdropForm.address,
            unitNumber: newUnitNumber
        };
        setQuickdropForm(quickdropForm => ({ ...quickdropForm, address }));
    };

    const handleAddressChange = ({ suggestion, place }) => {
        const address = {
            description: suggestion.description,
            place_id: suggestion.place_id,
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng()
        };
        setQuickdropForm(quickdropForm => ({ ...quickdropForm, address }));
    };

    const handleDateChange = date => {
        setDate(date);
    };

    const handleAccessDoorCode = ({ accessDoorCode, accessDoorCodeExpiry }) => {
        setQuickdropForm({ ...quickdropForm, accessDoorCode, accessDoorCodeExpiry });
    };

    const handleResetAccessDoorCode = date => {
        setQuickdropForm({ ...quickdropForm, accessDoorCode: '', accessDoorCodeExpiry: null });
    };

    const handleSubmitQuickdrop = async () => {
        handleInProgress(true);
        let images = [];

        const formData = new FormData();
        imageURLs.forEach(imageURL => {
            formData.append('images', imageURL);
        });

        if (!_.isEmpty(imageURLs)) {
            const res = await http.postJSON(`/system/uploadMultipleImages`, formData, false, true);
            images = _.get(res, 'data.imageUrls', []);
        }

        const res = await http.post(`/bulks/${customer._id}/submitQuickdrop`, {
            ...quickdropForm,
            payload: cumulativePayload,
            images
        });

        handleInProgress(false);
        if (res.ok) {
            onSnackbar(loc('quickDropSnackBar1', lang, { type: quickDropType }));
        } else {
            onSnackbar(loc('quickDropSnackBar2', lang, { type: quickDropType }), 'error');
        }

        onClose();
        onReloadCustomer();
    };

    const handleSetPreferredReturnSite = async returnSite => {
        const res = await http.post(`/users/${customer._id}/setPreferredReturnSite`, { returnSite });
        if (res.ok) {
            onSnackbar(loc('quickDropSnackBar5', lang));
            onReloadCustomer();
            getDropLocation();
        } else {
            onSnackbar(loc('quickDropSnackBar6', lang), 'error');
        }
    };

    const handleInHouseBooking = async () => {
        handleInProgress(true);

        const res = await http.post(`/bulks/${customer._id}/inHouseBooking`, {
            ...quickdropForm,
            payload: cumulativePayload,
            bookingDate: date
        });
        handleInProgress(false);

        if (res.ok) {
            onSnackbar(loc('quickDropSnackBar3', lang));
        } else {
            onSnackbar(loc('quickDropSnackBar4', lang));
        }
        onClose();
        onReloadCustomer();
    };

    const handleContinueWith = dropLocationId => () => {
        setQuickdropForm({ ...quickdropForm, dropLocationId });
        if (activeStep === 0 && dropOffType !== 'App Only' && dropOffType !== 'Access Door') {
            setActiveStep(steps.length - 1);
        } else if (activeStep === steps.length - 1) {
            handleSubmitQuickdrop();
        } else {
            setActiveStep(activeStep + 1);
        }
    };

    const handleNext = () => {
        if (activeStep === 1 && qrScanRequired) {
            onQRDialog(true)();
            onClose();
        } else if (activeStep === 0 && dropOffType !== 'App Only' && dropOffType !== 'Access Door') {
            setActiveStep(steps.length - 1);
        } else if (activeStep === steps.length - 1 && dropOffType === 'Access Door') {
            onClose();
        } else if (activeStep === steps.length - 1) {
            handleSubmitQuickdrop();
        } else {
            setActiveStep(activeStep + 1);
        }
    };

    const handleBack = () => {
        if (skipInterveningSteps) {
            setActiveStep(0);
            //setSkipInterveningSteps(false);
        } else if (
            activeStep === 0 ||
            (activeStep === 1 && defaultToInstructions) ||
            (activeStep === 2 && defaultToActionStep)
        ) {
            if (_.isNil(onBackPickupScreen)) {
                onClose();
            } else {
                onBackPickupScreen();
            }
        } else {
            setActiveStep(activeStep - 1);
        }
    };

    const handleAccessDoorStep = dropLocationId => async e => {
        await setQuickdropForm({ ...quickdropForm, dropLocationId });
        // setSkipInterveningSteps(true);
        if (stepTitles.includes(loc('openAccessDoorStep', lang))) {
            setActiveStep(_.indexOf(stepTitles, loc('openAccessDoorStep', lang)));
        } else {
            setActiveStep(activeStep + 2);
        }
    };

    const locationStep = (
        <Location
            customerLocation={quickdropForm.customerLocation}
            http={http}
            date={date}
            handleDateChange={handleDateChange}
            selectedLocationId={quickdropForm.dropLocationId}
            onDropLocationChange={handleDropLocationChanged}
            dropLocations={dropLocations}
            collectors={collectors}
            customer={customer}
            inProgress={inProgress}
            setInProgress={handleInProgress}
            onBack={_.isNil(onBackPickupScreen) ? null : handleBack}
            onNext={handleContinueWith}
            onBook={handleInHouseBooking}
            onClose={onClose}
            selectedDropLocation={selectedDropLocation}
            lastIncompleteQuickdrop={lastIncompleteQuickdrop}
            onReloadCustomer={onReloadCustomer}
            GPSLoading={GPSLoading}
            multiBagDrops={multiBagDrops}
            dropOffType={dropOffType}
            onRefreshLocation={handleRefreshLocation}
            charityEnabled={charityEnabled}
            dropAndGoCollector={collector}
            dropAndGoTechnologies={dropLocationTechnologies}
            isSmUp={isSmUp}
            setReturnSiteLookupOpen={setReturnSiteLookupOpen}
            onSetPreferredReturnSite={handleSetPreferredReturnSite}
            onQRDialog={onQRDialog}
            onAccessDoor={handleAccessDoorStep}
            // allowRequestLabels={allowRequestLabels}
        />
    );

    const payloadStep = (
        <Payload
            payload={payload}
            subPayloads={subPayloads}
            onSubPayloads={handleSubPayloadsChange}
            formErrors={payloadErrors}
            inProgress={inProgress}
            customer={customer}
            toggleHelpDialog={toggleHelpDialog}
            onBack={handleBack}
            onNext={handleNext}
            onPayload={handlePayload}
            selectedDropLocation={selectedDropLocation}
            http={http}
            onSnackbar={onSnackbar}
        />
    );

    const pictureConfirmationStep = (
        <PictureConfirmation
            imageURLs={imageURLs}
            imagePreviews={imagePreviews}
            handleAddImage={handleAddImage}
            handleDeleteImage={handleDeleteImage}
            uploadingImage={uploadingImage}
            onBack={handleBack}
            onNext={handleNext}
            selectedDropLocation={selectedDropLocation}
            onRefreshLocation={handleRefreshLocation}
            customerLocation={quickdropForm.customerLocation}
            inProgress={inProgress}
            setInProgress={inProgress => handleInProgress(inProgress)}
            http={http}
            onSnackbar={onSnackbar}
            overrideLocation={overrideLocation}
            onOverrideLocation={overrideLocation => setOverrideLocation(overrideLocation)}
            GPSLoading={GPSLoading}
            isLastStep={/*!qrScanRequired &&*/ !charityEnabled}
        />
    );

    const openAccessDoorStep = (
        <OpenAccessDoor
            onBack={handleBack}
            onNext={handleNext}
            selectedDropLocation={selectedDropLocation}
            onRefreshLocation={handleRefreshLocation}
            customerLocation={quickdropForm.customerLocation}
            inProgress={inProgress}
            setInProgress={inProgress => handleInProgress(inProgress)}
            http={http}
            onSnackbar={onSnackbar}
            overrideLocation={overrideLocation}
            onOverrideLocation={overrideLocation => setOverrideLocation(overrideLocation)}
            GPSLoading={GPSLoading}
            isLastStep={true}
            customer={customer}
            kantechPhoneNumber={kantechPhoneNumber}
            onAccessDoorCode={handleAccessDoorCode}
            onResetAccessDoorCode={handleResetAccessDoorCode}
            accessDoorCode={quickdropForm.accessDoorCode}
            accessDoorCodeExpiry={quickdropForm.accessDoorCodeExpiry}
        />
    );

    const donationStep = (
        <Donation
            donate={quickdropForm.donate}
            charities={charities}
            charityPreferred={quickdropForm.charityPreferred}
            subdivisionPreferred={quickdropForm.subdivision}
            taxReceiptRequested={quickdropForm.taxReceiptRequested}
            toggleTaxReceiptRequested={handleTaxReceiptChange}
            onCharityPreferred={handleCharityPreferred}
            onSubdivision={handleChangeSubdivision}
            toggleDonate={() =>
                setQuickdropForm(quickdropForm => ({ ...quickdropForm, donate: !quickdropForm.donate }))
            }
            onSetDonation={donate => setQuickdropForm(quickdropForm => ({ ...quickdropForm, donate }))}
            inProgress={inProgress}
            onBack={handleBack}
            onNext={handleNext}
            hasLastPickup={!_.isNil(lastPickup)}
            customer={customer}
            address={quickdropForm.address}
            selectedDropLocation={selectedDropLocation}
            regions={regions}
            onAddress={handleAddressChange}
            onAddressUnit={handleAddressUnitChange}
            http={http}
            google={google}
            // isLastStep={!qrScanRequired}
            isLastStep={true}
            growthPlanEnabled={growthPlanEnabled}
        />
    );

    // const qrPrintStep = (
    //     <QrStep
    //         customer={customer}
    //         onNext={handleNext}
    //         inProgress={inProgress}
    //         onBack={handleBack}
    //         toggleHelpDialog={toggleHelpDialog}
    //         type="print"
    //     />
    // );

    // const qrScanStep = (
    //     <QrStep
    //         customer={customer}
    //         onNext={handleNext}
    //         inProgress={inProgress}
    //         onBack={handleBack}
    //         toggleHelpDialog={toggleHelpDialog}
    //         type="scan"
    //     />
    // );
    // const instructions =
    const instructionsLocalizationName = _.get(
        _.find(DROP_OFF_TYPES, t => t.value === _.get(selectedDropLocation, 'dropOffType', '')),
        'localizationName',
        (defaultToInstructions || defaultToActionStep) &&
            !_.isNil(technologyTypeForInstructions) &&
            !_.isEmpty(technologyTypeForInstructions)
            ? technologyTypeForInstructions
            : 'appOnly'
    );
    const instructions = _.get(quickdropInstructions, `${instructionsLocalizationName}`, []);
    const instructionsStep = (
        <Instructions
            customer={customer}
            onClose={onClose}
            onNext={handleNext}
            onBack={handleBack}
            instructions={instructions}
            isLastScreen={
                (defaultToInstructions && _.isNil(selectedDropLocation)) ||
                (!qrScanRequired && dropOffType !== 'App Only' && dropOffType !== 'Access Door')
            }
            customDialogActions={
                qrScanRequired ? (
                    <>
                        <Button color="primary" onClick={handleBack} data-cy="quickdrop-steps-back">
                            {loc('back', lang)}
                        </Button>
                        <Button
                            color="primary"
                            disabled={inProgress}
                            onClick={handleNext}
                            data-cy="quickdrop-steps-next"
                        >
                            {loc('scanQRCode', lang)}
                        </Button>
                    </>
                ) : (
                    undefined
                )
            }
            dropOffType={dropOffType}
            kantechPhoneNumber={kantechPhoneNumber}
        />
    );

    const noLocationFound = (
        <NoLocationFound onClose={onClose} onBack={handleBack} dropOffType={technologyTypeForInstructions} />
    );

    let steps = [];
    if (dropOffType === 'Access Door') {
        steps = [locationStep, instructionsStep, openAccessDoorStep];
    } else if (dropOffType !== 'App Only') {
        steps = [locationStep, instructionsStep];
    } else if (defaultToActionStep && _.isNil(selectedDropLocation)) {
        steps = [noLocationFound];
    } else {
        steps = [locationStep, instructionsStep, payloadStep, pictureConfirmationStep];
        if (charityEnabled) {
            steps.push(donationStep);
        }
    }
    // if (qrScanRequired) {
    //     steps.unshift(qrPrintStep);
    //     steps.push(qrScanStep);
    // }
    let stepTitles = [];

    if (dropOffType === 'Access Door') {
        stepTitles = [
            '',
            `${loc(instructionsLocalizationName, lang)} ${loc('quickdropInstructionsStep', lang)}`,
            loc('openAccessDoorStep', lang)
        ];
    } else if (dropOffType !== 'App Only') {
        stepTitles = ['', `${loc(instructionsLocalizationName, lang)} ${loc('quickdropInstructionsStep', lang)}`];
    } else if (defaultToActionStep && _.isNil(selectedDropLocation)) {
        stepTitles = [loc('unavailable', lang)];
    } else {
        stepTitles = [
            '',
            `${loc(instructionsLocalizationName, lang)} ${loc('quickdropInstructionsStep', lang)}`,
            loc('quickdropPayloadStep', lang),
            loc('quickdropPictureConfirmationStep', lang)
        ];
        if (charityEnabled) {
            stepTitles.push(loc('quickdropDonationStep', lang));
        }
    }
    // if (qrScanRequired) {
    //     stepTitles.unshift(loc('quickdropQRPrintStep', lang));
    //     stepTitles.push(loc('quickdropQRScanStepStep', lang));
    // }

    return (
        <>
            {(activeStep !== 0 || !isSmUp || (defaultToActionStep && _.isNil(selectedDropLocation))) && (
                <DialogTitlePrimary closeButtonShown textVariant={'body1'} onClose={onClose}>
                    {stepTitles[activeStep]}
                </DialogTitlePrimary>
            )}
            {/*activeStep !== 0 &&
                steps.length > 2 &&
                !(defaultToInstructions && _.isNil(selectedDropLocation)) &&
                !defaultToActionStep && (
                    <MobileStepper
                        variant="dots"
                        steps={steps.length} //add 1 to account for first step in pickup dialog
                        position="static"
                        activeStep={activeStep}
                        nextButton={<div style={{ height: theme.spacing.unit * 6 }} />}
                        backButton={<div style={{ height: theme.spacing.unit * 6 }} />}
                        style={{ backgroundColor: theme.palette.background.paper, paddingBottom: 0 }}
                    />
                )*/}

            {!inProgress && steps[activeStep]}
            {inProgress && (
                <Paper
                    elevation={0}
                    style={{
                        minHeight: '80px',
                        padding: theme.spacing.unit * 2,
                        margin: theme.spacing.unit * 2,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <CircularProgress size={32} />
                </Paper>
            )}
            {returnSiteLookupOpen && (
                <SearchDialog
                    open={returnSiteLookupOpen}
                    onClose={() => setReturnSiteLookupOpen(false)}
                    dropLocations={dropLocations}
                    collectors={collectors}
                    customer={customer}
                    theme={theme}
                    handleUpdateCurrentLocation={handleUpdateCurrentLocation}
                    onSelectReturnSite={handleSelectReturnSite}
                />
            )}
        </>
    );
}

export default withWidth()(withMobileDialog()(withTheme()(QuickdropSteps)));
