import React, { Fragment, useContext, useState } from 'react';

import _ from 'lodash';
import moment from 'moment-timezone';

import CustomFormTitle from 'components/MaterialUIExtensions/CustomFormTitle';
import CustomTextBadge from 'components/CustomTextBadge/CustomTextBadge';
import DatePicker from 'components/DateTimePickersTz/DatePicker';

import {
    Paper,
    Typography,
    Icon,
    FormControl,
    FormControlLabel,
    Select,
    MenuItem,
    Checkbox,
    Button,
    LinearProgress,
    Collapse,
    Fade,
    InputAdornment,
    TextField,
    InputLabel,
    OutlinedInput,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    DialogContentText,
    colors
} from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc, locDate } from 'localizations/localizationHandler';

function BulkRescheduleDialog(props) {
    const {
        theme,
        open,
        form,
        pickupsSelected,
        inProgress,
        isSystemAdmin,
        rescheduleReasons,
        onRescheduleReasonChange
    } = props;

    const { lang } = useContext(LocalizationContext);
    const [viewLang, setViewLang] = useState('en');

    const handleDateChange = date => {
        const customEvent = {
            target: {
                name: 'date',
                value: date
            }
        };

        props.onChange(customEvent);
    };

    if (_.isEmpty(pickupsSelected)) {
        return null;
    }

    const firstPickupSelected = _.first(pickupsSelected);
    const currentDate = firstPickupSelected.date;
    const timezone = _.get(firstPickupSelected, 'collector.timezone', process.env.REACT_APP_REGION_TIMEZONE);

    return (
        <Dialog fullWidth open={open} onClose={inProgress ? () => undefined : props.onClose}>
            <DialogTitle>Bulk Reschedule</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Number of pickups:{' '}
                    <span style={{ color: theme.palette.primary.main }}>{pickupsSelected.length}</span>
                </DialogContentText>
                <DialogContentText>
                    Current date:{' '}
                    <span style={{ color: theme.palette.primary.main }}>
                        {moment(currentDate)
                            .tz(timezone)
                            .format('dddd[,] MMM Do')}
                    </span>
                    <CustomTextBadge
                        tooltip={
                            moment(currentDate)
                                .tz(timezone)
                                .format('MMM Do h:mm A Z') +
                            ' ' +
                            timezone
                        }
                    >
                        {moment(currentDate)
                            .tz(timezone)
                            .format('z')}
                    </CustomTextBadge>
                </DialogContentText>
                {!_.isNil(form.date) ? (
                    <DialogContentText>
                        New date:{' '}
                        <span style={{ color: theme.palette.primary.main }}>
                            {moment(form.date)
                                .tz(timezone)
                                .format('dddd[,] MMM Do')}
                        </span>
                        <CustomTextBadge
                            tooltip={
                                moment(form.date)
                                    .tz(timezone)
                                    .format('MMM Do h:mm A Z') +
                                ' ' +
                                timezone
                            }
                        >
                            {moment(form.date)
                                .tz(timezone)
                                .format('z')}
                        </CustomTextBadge>
                    </DialogContentText>
                ) : (
                    <DialogContentText>
                        New date: <span style={{ color: theme.palette.error.main }}>not selected</span>
                    </DialogContentText>
                )}
                <DialogContentText>
                    Notifications (email/sms) :{' '}
                    <span>
                        {form.sendNotifications ? (
                            <span style={{ color: theme.palette.primary.main }}>
                                will be sent to affected customers
                            </span>
                        ) : (
                            <span style={{ color: theme.palette.text.hint }}>will not be sent</span>
                        )}
                    </span>
                </DialogContentText>
                <DialogContentText>
                    Next dates:{' '}
                    <span>
                        {form.updateNextDates || Math.abs(moment(form.date).diff(currentDate, 'days')) >= 7 ? (
                            <span style={{ color: theme.palette.primary.main }}>
                                {generateUpcomingDates(form.date, timezone, theme)}
                            </span>
                        ) : (
                            <span style={{ color: theme.palette.text.hint }}>will not be updated</span>
                        )}
                    </span>
                </DialogContentText>
                <DialogContentText>
                    Confirmed:{' '}
                    <Select name="confirmed" value={form.confirmed} onChange={props.onChange}>
                        <MenuItem value="unchanged">Unchanged</MenuItem>
                        <MenuItem value="confirmed">Confirmed</MenuItem>
                        <MenuItem value="unconfirmed">Unconfirmed</MenuItem>
                    </Select>
                    <span />
                </DialogContentText>
                {Math.abs(moment(form.date).diff(currentDate, 'days')) >= 7 && (
                    <DialogContentText style={{ color: theme.palette.error.main }}>
                        <Icon style={{ position: 'relative', top: 3 }}>warning</Icon> Next dates will be forcefully
                        updated because of 7+ days difference in previous and selected dates
                    </DialogContentText>
                )}

                <FormControl
                    fullWidth
                    style={{
                        paddingTop: theme.spacing.unit * 0.5,
                        paddingBottom: theme.spacing.unit * 0.5
                    }}
                >
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="sendNotifications"
                                checked={form.sendNotifications}
                                style={{
                                    paddingTop: theme.spacing.unit * 0.5,
                                    paddingBottom: theme.spacing.unit * 0.5
                                }}
                                onChange={props.onChange}
                            />
                        }
                        label="Send out notifications"
                    />
                </FormControl>
                <FormControl fullWidth style={{ marginTop: theme.spacing.unit * 3 }}>
                    <DatePicker
                        disablePast
                        timezone={timezone}
                        data-cy="bulk-reschedule-dialog-datepicker-selector"
                        autoOk
                        label="Date"
                        name="date"
                        value={form.date}
                        variant="outlined"
                        style={{ marginBottom: theme.spacing.unit }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Icon>date_range</Icon>
                                </InputAdornment>
                            )
                        }}
                        onChange={handleDateChange}
                    />
                </FormControl>
                {isSystemAdmin && (
                    <>
                        <CustomFormTitle
                            titleText={`Allow future pickups ${
                                form.date
                                    ? `on ${moment(form.date)
                                          .tz(timezone)
                                          .format('dddd')}s`
                                    : 'as well'
                            }`}
                            iconName="warning"
                            style={{ marginTop: theme.spacing.unit * 2 }}
                            iconStyle={{ color: colors.red[500] }}
                        />

                        <FormControl fullWidth style={{ marginBottom: theme.spacing.unit * 2 }}>
                            <InputLabel variant="outlined">Bypass Zone Check</InputLabel>
                            <Select
                                value={form.updateNextDates}
                                onChange={props.onChange}
                                input={
                                    <OutlinedInput
                                        notched
                                        labelWidth={145}
                                        name="updateNextDates"
                                        id="updateNextDates"
                                        className="select-icon"
                                    />
                                }
                            >
                                <MenuItem key="bypassOnce" value={false}>
                                    Just Once
                                </MenuItem>
                                <MenuItem key="bypassAlways" value={true}>
                                    Always
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </>
                )}
                <Collapse in={form.sendNotifications} style={{ marginTop: theme.spacing.unit }}>
                    {process.env.REACT_APP_REGION_EXT !== 'MXD' ? (
                        <FormControl
                            fullWidth
                            style={{
                                marginBottom: theme.spacing.unit * 2,
                                backgroundColor: theme.palette.background.paper
                            }}
                        >
                            <InputLabel variant="outlined">Reschedule Reason</InputLabel>
                            <Select
                                value={form.rescheduleReasonSelected}
                                onChange={e => {
                                    onRescheduleReasonChange(e);
                                }}
                                data-cy="bulk-reschedule-dialog-reason-select"
                                input={
                                    <OutlinedInput
                                        notched
                                        labelWidth={145}
                                        name="rescheduleReasonSelected"
                                        id="selectRescheduleReason"
                                        className="select-icon"
                                    />
                                }
                            >
                                {rescheduleReasons.map((reason, i) => {
                                    return (
                                        <MenuItem
                                            value={reason.title}
                                            key={'template_' + i}
                                            data-cy={`reschedule-reason-select-${i}`}
                                        >
                                            {reason.title}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    ) : (
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <FormControl
                                style={{
                                    width: '49%',
                                    marginBottom: theme.spacing.unit * 2,
                                    backgroundColor: theme.palette.background.paper
                                }}
                            >
                                <InputLabel variant="outlined">Reschedule Reason</InputLabel>
                                <Select
                                    value={form.rescheduleReasonSelected}
                                    onChange={e => {
                                        onRescheduleReasonChange(e);
                                    }}
                                    data-cy="bulk-reschedule-dialog-reason-select"
                                    input={
                                        <OutlinedInput
                                            notched
                                            labelWidth={145}
                                            name="rescheduleReasonSelected"
                                            id="selectRescheduleReason"
                                            className="select-icon"
                                        />
                                    }
                                >
                                    {rescheduleReasons.map((reason, i) => {
                                        return (
                                            <MenuItem
                                                value={reason.title}
                                                key={'template_' + i}
                                                data-cy={`reschedule-reason-select-${i}`}
                                            >
                                                {reason.title}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                            <FormControl
                                style={{
                                    width: '49%',
                                    marginBottom: theme.spacing.unit * 2,
                                    backgroundColor: theme.palette.background.paper
                                }}
                            >
                                <InputLabel variant="outlined">Language</InputLabel>
                                <Select
                                    value={viewLang}
                                    renderValue={() => viewLang}
                                    onChange={e => {
                                        setViewLang(e.target.value);
                                        onRescheduleReasonChange(null);
                                    }}
                                    data-cy="bulk-reschedule-dialog-lang-select"
                                    input={
                                        <OutlinedInput
                                            notched
                                            labelWidth={75}
                                            name="rescheduleLangSelected"
                                            id="selectRescheduleLang"
                                            className="select-icon"
                                        />
                                    }
                                >
                                    <MenuItem value={'en'} key={'en'} data-cy={`reschedule-lang-select-en`}>
                                        {'en'}
                                    </MenuItem>
                                    <MenuItem value={'fr'} key={'fr'} data-cy={`reschedule-lang-select-fr`}>
                                        {'fr'}
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    )}
                    <Paper
                        elevation={0}
                        style={{
                            borderRadius: theme.spacing.unit,
                            border: `1px solid ${theme.palette.text.hint}`,
                            backgroundColor: theme.palette.background.default,
                            padding: theme.spacing.unit * 2
                        }}
                    >
                        <Typography
                            variant="body1"
                            style={{
                                lineHeight: 1.15,
                                padding: theme.spacing.unit * 2,
                                paddingTop: 0,
                                paddingBottom: 0
                            }}
                        >
                            {getEmailHeading(
                                theme,
                                timezone,
                                currentDate,
                                form.date,
                                _.get(form, `reason`, null),
                                true,
                                viewLang
                            )}
                        </Typography>
                        <FormControl
                            fullWidth
                            style={{
                                marginTop: theme.spacing.unit * 2,
                                backgroundColor: theme.palette.background.paper
                            }}
                        >
                            <TextField
                                data-cy=""
                                multiline
                                disabled={!form.sendNotifications}
                                name={`emailBody.${viewLang}`}
                                value={form.emailBody[viewLang]}
                                label={`Email body`}
                                variant="outlined"
                                onChange={props.onChange}
                            />
                        </FormControl>
                    </Paper>
                </Collapse>
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={inProgress}
                    id="bulk-reschedule-dialog-close"
                    data-cy="bulk-reschedule-dialog-close"
                    color="primary"
                    onClick={props.onClose}
                >
                    {loc('close', lang)}
                </Button>
                <Button
                    id="bulk-reschedule-dialog-submit"
                    data-cy="bulk-reschedule-dialog-submit"
                    color="primary"
                    disabled={inProgress || _.isNil(form.date)} // || moment(form.date).isSame(currentDate, 'days') removed this condition
                    onClick={props.onSubmit}
                >
                    {loc('submit', lang)}
                </Button>
            </DialogActions>
            <Fade in={inProgress}>
                <LinearProgress />
            </Fade>
        </Dialog>
    );
}

export default withTheme()(BulkRescheduleDialog);

const recurringIntervals = [7, 14, 28, 70]; // QUESTION: do we care about 28, 56, 84 ones?

function generateUpcomingDates(date, timezone, theme) {
    if (_.isNil(date)) {
        return <span style={{ color: theme.palette.error.main }}>date not selected</span>;
    }

    return (
        <span>
            <span>
                {moment(date)
                    .tz(timezone)
                    .format('dddd[:]')}
            </span>{' '}
            {recurringIntervals.map((interval, index) => {
                return (
                    <Fragment key={interval}>
                        <span>
                            {moment(date)
                                .add(interval, 'days')
                                .tz(timezone)
                                .format('MMM D')}
                        </span>
                        {index !== recurringIntervals.length - 1 && <span>, </span>}
                    </Fragment>
                );
            })}
        </span>
    );
}

export function getEmailHeading(theme, timezone, oldDate, newDate, reason = null, renderAsHTML = false, lang = 'en') {
    if (lang === 'fr') {
        if (renderAsHTML) {
            return (
                <span>
                    Votre ramassage du{' '}
                    <span style={{ color: theme.palette.primary.main }}>
                        {locDate(oldDate, 'dddd[,] MMMM Do', 'fr')}
                    </span>{' '}
                    a été reporté au{' '}
                    <span style={{ color: theme.palette.primary.main }}>
                        {locDate(newDate, 'dddd[,] MMMM Do', 'fr')}
                    </span>{' '}
                    par {process.env.REACT_APP_BRAND_NAME}
                    {reason ? ` en raison de ${_.get(reason, `[${lang}]`, '')}` : ''}.
                </span>
            );
        } else {
            return `Votre ramassage du ${locDate(oldDate, 'dddd[,] MMMM Do', 'fr')} a été reporté au ${locDate(
                newDate,
                'dddd[,] MMMM Do',
                'fr'
            )} par ${process.env.REACT_APP_BRAND_NAME}${
                reason ? ` en raison de ${_.get(reason, `[${lang}]`, '')}` : ''
            }.`;
        }
    } else {
        if (renderAsHTML) {
            return (
                <span>
                    Your pickup on{' '}
                    <span style={{ color: theme.palette.primary.main }}>
                        {locDate(oldDate, 'dddd[,] MMMM Do', 'en')}
                    </span>{' '}
                    has been rescheduled to{' '}
                    <span style={{ color: theme.palette.primary.main }}>
                        {locDate(newDate, 'dddd[,] MMMM Do', 'en')}
                    </span>{' '}
                    by {process.env.REACT_APP_BRAND_NAME}
                    {reason ? ` due to ${_.get(reason, `[${lang}]`, '')}` : ''}.
                </span>
            );
        } else {
            return `Your pickup on ${locDate(oldDate, 'dddd[,] MMMM Do', 'en')} has been rescheduled to ${locDate(
                newDate,
                'dddd[,] MMMM Do',
                'en'
            )} by ${process.env.REACT_APP_BRAND_NAME}${reason ? ` due to ${_.get(reason, `[${lang}]`, '')}` : ''}.`;
        }
    }
}
