import React, { useState } from 'react';
import _ from 'lodash';

import {
    Dialog,
    DialogActions,
    DialogContent,
    Typography,
    Button,
    FormControlLabel,
    Checkbox,
    withTheme
} from '@material-ui/core';

import List from '@material-ui/core/MenuList';
import ListItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import * as terms from 'localizations/terms';

import DialogTitlePrimary from 'components/MaterialUIExtensions/DialogTitlePrimary';
import ExternalLink from 'components/ExternalLink/ExternalLink';

import Favorite from '@material-ui/icons/Favorite';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';
import { _charity } from 'std';

function FeaturedCharitySelectDialog(props) {
    const { open, featuredCharitiesSameRegion, onClose, handleSetDonationPreference, inProgress, theme } = props;

    const [selectedCharity, setSelectedCharity] = useState(null);

    const handleToggle = value => () => {
        if (_charity.getId(value) === _charity.getId(selectedCharity)) {
            setSelectedCharity(null);
        } else {
            setSelectedCharity(value);
        }
    };

    const charityList = featuredCharitiesSameRegion.map((charity, index) => (
        <List key={charity._id}>
            <ListItem
                disableGutters
                style={{
                    paddingLeft: 0,
                    paddingRight: 0,
                    display: 'flex'
                }}
            >
                <img
                    src={charity.logo}
                    alt="Charity logo"
                    style={{
                        width: theme.spacing.unit * 6,
                        maxHeight: theme.spacing.unit * 5,
                        objectFit: 'contain'
                    }}
                    onClick={handleToggle(charity)}
                />
                <ListItemText
                    primary={<span onClick={handleToggle(charity)}>{charity.name}</span>}
                    secondary={<ExternalLink text="Learn more" url={charity.website} textInline />}
                    primaryTypographyProps={{
                        noWrap: true,
                        style: { lineHeight: 1.25, paddingRight: theme.spacing.unit * 4 }
                    }}
                    secondaryTypographyProps={{ noWrap: true, style: { lineHeight: 1.25 } }}
                />
                <ListItemSecondaryAction>
                    <FormControlLabel
                        style={{ marginRight: 0 }}
                        control={
                            <Checkbox
                                data-cy={`registration-org-selection-checkbox-${index}`}
                                style={{ marginRight: 0 }}
                                onChange={handleToggle(charity)}
                                checked={_charity.getId(charity) === _charity.getId(selectedCharity)}
                                icon={<FavoriteBorder />}
                                checkedIcon={<Favorite />}
                                value="checkedH"
                            />
                        }
                    />
                </ListItemSecondaryAction>
            </ListItem>
        </List>
    ));

    return (
        <Dialog open={open} fullWidth maxWidth="sm">
            <DialogTitlePrimary>Featured {terms.ORGANIZATION_NAME}s</DialogTitlePrimary>
            <DialogContent style={{ marginTop: theme.spacing.unit * 3 }}>
                <Typography variant="body2" color="textSecondary" style={{ marginBottom: theme.spacing.unit * 2 }}>
                    We are partnered with many non-profit and sports {terms.ORGANIZATION_NAME.toLowerCase()}s. You can
                    select one to set it as your donation preference (this can always be changed later).
                </Typography>
                {charityList}
            </DialogContent>
            <DialogActions>
                <Button
                    data-cy="registration-no-thanks-donation-button"
                    color="default"
                    onClick={onClose}
                    disabled={inProgress}
                >
                    No thanks
                </Button>
                <Button
                    data-cy="registration-donate-button"
                    color="primary"
                    disabled={_.isNil(selectedCharity)}
                    onClick={() => handleSetDonationPreference(selectedCharity)}
                >
                    Donate
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default withTheme()(FeaturedCharitySelectDialog);
