import React, { useState, useEffect, useContext } from 'react';

import _ from 'lodash';

import { maxFileSize, maxFileSizeMB, maxCharityImageWidthAndHeight } from 'constants.js';
import * as terms from 'localizations/terms';
import { validate, initFormErrors, containsErrors } from 'utils/validate';

import LogoUpload from 'components/LogoUpload/LogoUpload';
import Collapse from '@material-ui/core/Collapse';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Icon, LinearProgress, colors } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import SnackbarContext from 'components/CustomSnackbar/SnackbarContext';

import StringListInput from 'components/CustomInputs/StringListInput';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';
import imageCompression from 'browser-image-compression';
import { isAUSRegion, isEXPRegion } from 'utils/misc';
// moment.tz.setDefault(process.env.REACT_APP_REGION_TIMEZONE);

const formShape = {
    charityRegistrationNumber: '',
    redemptionEmail: '',
    website: '',
    url: '',
    mission: '',
    lng: '',
    lat: '',
    subdivisions: [],
    markerHeight: '',
    markerWidth: ''
};

const imageFileTypes = [
    'apng',
    'avif',
    'gif',
    'jpg',
    'jpeg',
    'jfif',
    'pjpeg',
    'pjp',
    'png',
    'svg',
    'webp',
    'bmp',
    'ico',
    'cur',
    'tif'
];

const errors = {
    charityRegistrationNumber: [process.env.REACT_APP_CHARITY_NUMBER_VALIDATION_RULE],
    redemptionEmail: ['email'],
    website: ['url'],
    url: ['internal-url'],
    mission: ['required', 'max-length:300'],
    lng: ['gps'],
    lat: ['gps'],
    subdivisions: []
};

function CharityTableEditDialog(props) {
    const {
        dialogOpen,
        handleDialogClose,
        handleReject,
        handleUpdate,
        charity,
        loading,
        theme,
        charityManagers
    } = props;
    const { lang } = useContext(LocalizationContext);
    const [file, setFile] = useState(null);
    const [form, setForm] = useState(_.cloneDeep(formShape));
    const [formErrors, setFormErrors] = useState(initFormErrors(formShape));
    const [formContainsErrors, setFormContainsErrors] = useState(false);
    const [taxReceiptsIssued, setTaxReceiptsIssued] = useState(false);
    const [overrideMarkerUrl, setOverrideMarkerUrl] = useState(false);
    const [differentTeams, setDifferentTeams] = useState(false);
    const [approveChange, setApproveChange] = useState(false);
    const [similarCharities, setSimilarCharities] = useState([]);
    const [sameCRNCharities, setSameCRNCharities] = useState([]);
    const [imageError, setImageError] = useState(false);
    const [markerFile, setMarkerFile] = useState(undefined);

    const onSnackbar = useContext(SnackbarContext);

    //set initial values of the form when the charity being eddited changes
    useEffect(() => {
        if (_.isNil(charity)) return;
        let newForm = _.cloneDeep(charity);

        newForm.lng = newForm.location.lng || null;
        newForm.lat = newForm.location.lat || null;

        let keys = Object.keys(formShape);
        for (let key of keys) {
            if (_.isNil(newForm[key])) {
                newForm[key] = '';
            }
        }
        //newForm.subdivisions = newForm.subdivisions.join(', ');
        setForm(newForm);
        setTaxReceiptsIssued(charity.taxReceiptsIssued);
        setOverrideMarkerUrl(!_.isNil(charity.markerUrl));
        setDifferentTeams(_.isEmpty(newForm.subdivisions) ? false : true);

        let isGroupAcct = !charity.taxReceiptsIssued;
        let formErrorsUpdated = _.cloneDeep(formShape);

        for (let key of keys) {
            if (isEXPRegion() && isGroupAcct && ['website', 'mission'].includes(key) && _.isEmpty(newForm[key])) {
                // Not required on Express GROUP accounts
                continue;
            }
            if (key === 'lng' || key === 'lat') {
                formErrorsUpdated['lat'] = validate(errors[key], newForm['lat'] + ',' + newForm['lng'], lang);
                formErrorsUpdated['lng'] = validate(errors[key], newForm['lat'] + ',' + newForm['lng'], lang);
            } else if (key === 'charityRegistrationNumber') {
                if (taxReceiptsIssued) {
                    formErrorsUpdated[key] = validate(errors[key], newForm[key], lang);
                } else {
                    formErrorsUpdated[key] = { fail: false, reason: null };
                }
            } else {
                formErrorsUpdated[key] = validate(errors[key], newForm[key], lang);
            }
        }
        setFormContainsErrors(containsErrors(formErrorsUpdated));
        setFormErrors(formErrorsUpdated);

        if (charity.showNameWarning && charity.similarCharities && charity.similarCharities.length > 0) {
            let newSimilarCharityNames = [];
            charity.similarCharities.forEach(similarCharity => {
                let charityId = _.get(similarCharity, '_id', null);
                let charityName = _.get(similarCharity, 'name', null);
                if (!_.isNil(charityId) && !_.isNil(charityName)) {
                    newSimilarCharityNames.push({ _id: charityId, name: charityName });
                }
            });
            setSimilarCharities(newSimilarCharityNames);
        }
        if (charity.showCRNWarning && charity.sameCRNCharities && charity.sameCRNCharities.length > 0) {
            let newSameCRNCharitiesNames = [];
            charity.sameCRNCharities.forEach(sameCRNCharity => {
                let charityId = _.get(sameCRNCharity, '_id', null);
                let charityName = _.get(sameCRNCharity, 'name', null);
                if (!_.isNil(charityId) && !_.isNil(charityName)) {
                    newSameCRNCharitiesNames.push({ _id: charityId, name: charityName });
                }
            });
            setSameCRNCharities(newSameCRNCharitiesNames);
        }
    }, [charity]);

    const formControlStyle = {
        marginTop: theme.spacing.unit / 2
    };

    const handleDrop = async e => {
        const options = {
            maxSizeMB: maxFileSizeMB,
            maxWidthOrHeight: maxCharityImageWidthAndHeight,
            fileType: 'image/png', //converts all images to png
            useWebWorker: true
        };
        const img = e.target.files[0];

        let validImageTypes = ['image/jpeg', 'image/png', 'image/webp', 'image/heif', 'image/heic', 'image/gif'];
        let validImageExtensions = ['.jpeg', '.jpg', '.png', '.webp', '.heif', '.heic', '.gif'];
        let isValidExt = validImageExtensions.some(
            type => _.endsWith(img.name, type) || _.endsWith(img.name, _.toUpper(type))
        );

        if (_.includes(validImageTypes, _.get(img, 'type')) && isValidExt) {
            const imageFile = await imageCompression(img, options);
            setFile(imageFile);
        } else {
            onSnackbar(loc('invalidImageFormat', lang), 'error');
        }
    };

    const handleOverrideMarkerUrl = e => {
        setOverrideMarkerUrl(!overrideMarkerUrl);
    };

    const handleMarkerFileDrop = e => {
        const img = e.target.files[0];

        if (img.size <= maxFileSize) {
            let formCopy = _.cloneDeep(form);
            const image = new Image();
            image.src = URL.createObjectURL(img);
            image.onload = () => {
                formCopy.markerHeight = image.height;
                formCopy.markerWidth = image.width;
                setForm(formCopy);
            };
            setMarkerFile(e.target.files[0]);
        }
    };

    const handleToggleTaxReceiptsIssued = () => {
        setTaxReceiptsIssued(!taxReceiptsIssued);
        validateForm(Object.keys(form), form, !taxReceiptsIssued);
    };

    const handleFormChange = e => {
        let name = e.target.name;
        let value = e.target.value;
        const newForm = _.set(form, name, value);
        setForm(newForm);
        validateForm([name], newForm);
    };

    const validateForm = (keys, newForm, hasTaxReceiptsIssued = taxReceiptsIssued) => {
        let isGroupAcct = !hasTaxReceiptsIssued;
        let formErrorsUpdated = _.cloneDeep(formErrors);
        for (let key of keys) {
            if (
                isEXPRegion() &&
                isGroupAcct &&
                ['website', 'mission'].includes(key) &&
                _.isEmpty(newForm[key].trim())
            ) {
                // Not required on Express GROUP accounts
                formErrorsUpdated[key] = { fail: false, reason: null };
                continue;
            }
            if (key === 'lng' || key === 'lat') {
                formErrorsUpdated['lat'] = validate(errors[key], newForm['lat'] + ',' + newForm['lng'], lang);
                formErrorsUpdated['lng'] = validate(errors[key], newForm['lat'] + ',' + newForm['lng'], lang);
            } else if (key === 'charityRegistrationNumber') {
                if (hasTaxReceiptsIssued) {
                    formErrorsUpdated[key] = validate(errors[key], newForm[key], lang);
                } else {
                    formErrorsUpdated[key] = { fail: false, reason: null };
                }
            } else {
                formErrorsUpdated[key] = validate(errors[key], newForm[key], lang);
            }
        }
        setFormContainsErrors(containsErrors(formErrorsUpdated));
        setFormErrors(formErrorsUpdated);
        return formErrorsUpdated;
    };

    const handleToggleDifferentTeams = () => {
        setDifferentTeams(!differentTeams);
    };

    const handleCheck = event => {
        setApproveChange(event.target.checked);
    };
    return (
        <Dialog fullWidth open={dialogOpen} onClose={handleDialogClose} aria-labelledby="Dialog">
            <DialogTitle data-cy="charity-table-edit-dialog-name">{form.name}</DialogTitle>
            <DialogContent>
                {similarCharities.length > 0 && (
                    <Typography
                        variant="body2"
                        style={{
                            marginBottom: theme.spacing.unit,
                            backgroundColor: 'pink',
                            textAlign: 'center',
                            paddingTop: 5,
                            paddingBottom: 5,
                            borderLeft: '2px solid red',
                            borderRight: '2px solid red'
                        }}
                    >
                        Similar charity names:{' '}
                        {similarCharities.map(similarCharity => {
                            return (
                                <a
                                    href={`/customers/${_.get(
                                        _.first(
                                            _.filter(charityManagers, manager =>
                                                _.get(manager, 'charities', []).includes(similarCharity._id)
                                            )
                                        ),
                                        '_id'
                                    )}/${similarCharity._id}/charity`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ marginRight: 5 }}
                                >
                                    {similarCharity.name}
                                </a>
                            );
                        })}
                    </Typography>
                )}
                {sameCRNCharities.length > 0 && (
                    <Typography
                        variant="body2"
                        style={{
                            marginBottom: theme.spacing.unit,
                            backgroundColor: 'pink',
                            textAlign: 'center',
                            paddingTop: 5,
                            paddingBottom: 5,
                            borderLeft: '2px solid red',
                            borderRight: '2px solid red'
                        }}
                    >
                        {isAUSRegion() ? 'Australian Business Number' : 'Charity Registration Number'} already in use:{' '}
                        {sameCRNCharities.map(sameCRNCharity => {
                            return (
                                <a
                                    href={`/customers/${_.get(
                                        _.first(
                                            _.filter(charityManagers, manager =>
                                                _.get(manager, 'charities', []).includes(sameCRNCharity._id)
                                            )
                                        ),
                                        '_id'
                                    )}/${sameCRNCharity._id}/charity`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ marginRight: 5 }}
                                >
                                    {sameCRNCharity.name}
                                </a>
                            );
                        })}
                    </Typography>
                )}
                <Typography variant="subtitle1" style={{ marginBottom: theme.spacing.unit }}>
                    To approve an {terms.ORGANIZATION_NAME.toLowerCase()}, verify the{' '}
                    <a
                        href="https://www.canada.ca/en/revenue-agency/services/charities-giving/charities-listings.html"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        CRN
                    </a>{' '}
                    (if applicable), verify the financial information, and enter the{' '}
                    {terms.ORGANIZATION_NAME.toLowerCase()} URL (no spaces).
                </Typography>
                <FormControl fullWidth style={{ marginTop: theme.spacing.unit }}>
                    <LogoUpload
                        theme={theme}
                        file={file}
                        handleDrop={handleDrop}
                        logo={form.logo}
                        name={terms.ORGANIZATION_NAME}
                        lang={lang}
                    />
                </FormControl>
                <FormGroup row>
                    <FormControlLabel
                        label={`This ${terms.ORGANIZATION_NAME.toLowerCase()} issues tax receipts`}
                        control={<Switch checked={taxReceiptsIssued} onChange={handleToggleTaxReceiptsIssued} />}
                    />
                </FormGroup>
                <Collapse in={taxReceiptsIssued}>
                    <FormControl fullWidth style={formControlStyle}>
                        <TextField
                            data-cy="charity-table-edit-dialog-crn"
                            value={form.charityRegistrationNumber}
                            helperText={formErrors.charityRegistrationNumber.reason}
                            error={formErrors.charityRegistrationNumber.fail}
                            label={terms.CHARITY_NUMBER_NAME}
                            name="charityRegistrationNumber"
                            onChange={handleFormChange}
                        />
                    </FormControl>
                </Collapse>
                <FormGroup row style={formControlStyle}>
                    <FormControlLabel
                        control={<Switch checked={differentTeams} onChange={handleToggleDifferentTeams} />}
                        label={`My ${terms.ORGANIZATION_NAME.toLowerCase()} has multiple groups/teams`}
                    />
                </FormGroup>
                {differentTeams && (
                    <StringListInput
                        error={inputValue => {}}
                        errorMessage=""
                        label={loc('dashboardSetting22', lang)}
                        values={_.get(form, 'subdivisions', [])}
                        onChange={subdivisions => setForm({ ...form, subdivisions })}
                    />
                )}
                <div style={formControlStyle}>
                    <TextField
                        data-cy="charity-table-edit-dialog-mission"
                        value={form.mission}
                        helperText={formErrors.mission.reason}
                        error={formErrors.mission.fail}
                        label="Mission/Vision"
                        name="mission"
                        onChange={handleFormChange}
                        multiline
                        fullWidth
                    />
                </div>

                <FormControl fullWidth style={formControlStyle}>
                    <TextField
                        data-cy="charity-table-edit-dialog-email"
                        value={form.redemptionEmail}
                        helperText={formErrors.redemptionEmail.reason}
                        error={formErrors.redemptionEmail.fail}
                        label="Redemption Email"
                        name="redemptionEmail"
                        onChange={handleFormChange}
                    />
                </FormControl>
                <FormControl fullWidth style={formControlStyle}>
                    <TextField
                        data-cy="charity-table-edit-dialog-website"
                        value={form.website}
                        helperText={formErrors.website.reason}
                        error={formErrors.website.fail}
                        label={`${terms.ORGANIZATION_NAME} ${loc('dashboardSetting6', lang)}`}
                        name="website"
                        onChange={handleFormChange}
                    />
                </FormControl>
                <FormControl fullWidth style={formControlStyle}>
                    <TextField
                        data-cy="charity-table-edit-dialog-internal-url"
                        value={form.url}
                        helperText={formErrors.url.reason}
                        error={formErrors.url.fail}
                        label="Internal URL"
                        name="url"
                        onChange={handleFormChange}
                    />
                </FormControl>
                <FormControl fullWidth style={{ display: 'inline-block', marginTop: theme.spacing.unit / 2 }}>
                    <TextField
                        style={{ width: '48%' }}
                        value={form.lat}
                        label="Latitude"
                        data-cy="charity-table-edit-dialog-latitude"
                        name="lat"
                        error={formErrors.lng.fail || formErrors.lat.fail}
                        helperText={formErrors.lng.reason}
                        onChange={handleFormChange}
                    />
                    <TextField
                        style={{ width: '48%', float: 'right' }}
                        value={form.lng}
                        label="Longitude"
                        data-cy="charity-table-edit-dialog-longitude"
                        name="lng"
                        error={formErrors.lng.fail || formErrors.lat.fail}
                        onChange={handleFormChange}
                    />
                </FormControl>
                <FormGroup row>
                    <FormControlLabel
                        label={`Override Marker Image`}
                        control={<Switch checked={overrideMarkerUrl} onChange={handleOverrideMarkerUrl} />}
                    />
                </FormGroup>
                <Collapse in={overrideMarkerUrl}>
                    {_.isNil(markerFile) && _.isNil(form.markerUrl) ? (
                        <FormControl fullWidth data-cy="upload-photo-input">
                            <input
                                accept={'image/*'}
                                style={{ display: 'none' }}
                                id="raised-button-file"
                                multiple={false}
                                type="file"
                                onChange={handleMarkerFileDrop}
                            />
                            <label htmlFor="raised-button-file">
                                <Button
                                    color="primary"
                                    size="small"
                                    variant="outlined"
                                    component="span"
                                    style={{ width: '100%', marginTop: theme.spacing.unit }}
                                >
                                    Upload Image
                                </Button>
                            </label>
                        </FormControl>
                    ) : (
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            {!_.isNil(markerFile) ? (
                                <img
                                    src={URL.createObjectURL(markerFile)}
                                    style={{ maxWidth: '100%' }}
                                    alt="Drop Location Marker"
                                />
                            ) : !imageError && imageFileTypes.includes(form.markerUrl.split('.').pop()) ? (
                                <img
                                    src={form.markerUrl}
                                    alt="charity-high-resolution-logo"
                                    onError={() => setImageError(true)}
                                    style={{
                                        maxWidth: '128px',
                                        maxHeight: '128px'
                                    }}
                                />
                            ) : (
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <Icon fontSize="large" style={{ color: colors.green[500] }}>
                                        check_circle
                                    </Icon>
                                    <Typography
                                        style={{
                                            color: colors.green[500],
                                            margin: theme.spacing.unit * 2,
                                            textAlign: 'center'
                                        }}
                                        variant="subtitle2"
                                    >
                                        Image Uploaded
                                    </Typography>
                                </div>
                            )}
                            <FormControl fullWidth data-cy="upload-photo-input">
                                <input
                                    accept={'image/*'}
                                    style={{ display: 'none' }}
                                    id="raised-button-file"
                                    multiple={false}
                                    type="file"
                                    onChange={handleMarkerFileDrop}
                                />
                                <label htmlFor="raised-button-file">
                                    <Button
                                        color="primary"
                                        size="small"
                                        variant="outlined"
                                        component="span"
                                        style={{ width: '100%', marginTop: theme.spacing.unit }}
                                    >
                                        Replace Image
                                    </Button>
                                </label>
                            </FormControl>
                        </div>
                    )}
                </Collapse>
                {!form.approved && (
                    <span>
                        <Typography variant="subtitle1" style={{ marginTop: theme.spacing.unit * 2 }}>
                            I approve that <span style={{ color: theme.palette.primary.main }}>{form.name}</span> is a
                            valid charity/{terms.ORGANIZATION_NAME.toLowerCase()}
                        </Typography>
                        <FormGroup row>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={approveChange}
                                        onChange={handleCheck}
                                        value="approveChange"
                                        data-cy="charity-table-edit-dialog-approved-checkbox"
                                    />
                                }
                                label="Yes"
                            />
                        </FormGroup>
                    </span>
                )}
            </DialogContent>
            {loading && <LinearProgress style={{ marginTop: theme.spacing.unit * 2 }} />}
            <DialogActions>
                <Button
                    color="secondary"
                    onClick={() => handleReject(form._id)}
                    data-cy="charity-table-edit-dialog-reject-button"
                >
                    Reject
                </Button>
                {!form.approved && (
                    <Button
                        data-cy="charity-table-edit-dialog-approve-button"
                        color="primary"
                        onClick={() =>
                            handleUpdate(taxReceiptsIssued, form, file, differentTeams, overrideMarkerUrl, markerFile)
                        }
                        disabled={!approveChange || formContainsErrors}
                    >
                        Approve
                    </Button>
                )}
                {form.approved && (
                    <Button
                        data-cy="charity-table-edit-dialog-update-button"
                        color="primary"
                        onClick={() =>
                            handleUpdate(taxReceiptsIssued, form, file, differentTeams, overrideMarkerUrl, markerFile)
                        }
                        disabled={formContainsErrors}
                    >
                        Update
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
}

export default withTheme()(CharityTableEditDialog);
