import React, { useContext, useEffect, useState } from 'react';

import _ from 'lodash';
import moment from 'moment-timezone';

import { validatePayload } from 'utils/validate';

import CustomFormTitle from 'components/MaterialUIExtensions/CustomFormTitle';
import CancelPickupDialog from 'components/Dialogs/PickupBooking/CancelPickupDialog';

import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
    withMobileDialog
} from '@material-ui/core';

import { withTheme } from '@material-ui/core/styles';

import { _commodity, _pickup, _zone } from 'std';

import PayloadInput from 'components/InputComponents/PayloadInput';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc, locDate } from 'localizations/localizationHandler';

function PickupConfirmationDialog(props) {
    const {
        theme,
        open,
        pickup,
        fullScreen,
        customer,
        commoditiesAvailable,
        http,
        onSnackbar,
        reloadCustomer,
        onSubmitSuccess,
        onPostpone,
        onCancel,
        onClose
    } = props;
    const { lang } = useContext(LocalizationContext);

    const [mode, setMode] = useState('ACTION');
    const [cancelReason, setCancelReason] = useState({ reason: '', comments: '' });

    const [payload, setPayload] = useState(_pickup.createEmptyPayload());
    const [subPayloads, setSubPayloads] = useState([]);
    const [cumulativePayload, setCumulativePayload] = useState({});

    const handlePayload = e => {
        const path = e.target.name;
        const value = e.target.value;

        const updatedPayload = _.cloneDeep(payload);
        setPayload(_.set(updatedPayload, path, value));
    };

    const handleSubPayloads = e => {
        const path = e.target.name; // sub-commodity id
        const value = e.target.value;

        const updatedSubPayloads = _.cloneDeep(subPayloads);

        const commodity = _commodity.getCommodityBySubCommodityId(commoditiesAvailable, path);
        const subCommodity = _commodity.getSubCommodityById(commoditiesAvailable, path);

        let subPayload = _.find(updatedSubPayloads, subPayload => {
            const id = _.get(subPayload, 'subCommodity._id');
            return id === path;
        });

        if (!commodity || !subCommodity) return;

        if (subPayload) {
            subPayload.amount = value;
        } else {
            updatedSubPayloads.push({
                skuType: commodity.skuType,
                amount: value,
                subCommodity: subCommodity
            });
        }

        setSubPayloads(updatedSubPayloads);
    };

    const handleCancelConfirmationDialog = openDialog => {
        setMode(openDialog ? 'CANCEL' : 'ACTION');
    };

    const handleCancelChange = e => {
        let path = e.target.name;
        let value = e.target.value;

        setCancelReason({ ...cancelReason, [path]: value });
    };

    const handleCancel = e => {
        if (!e.currentTarget.disabled) {
            e.currentTarget.disabled = true;

            setMode('ACTION');
            onCancel(pickup, cancelReason, () => {});
        }
    };

    const handleSubmit = async () => {
        let res = await http.post(`/pickups/${pickup._id}/confirm`, {
            payload: cumulativePayload,
            subPayloads
        });
        if (res.ok) {
            onSubmitSuccess();
            onClose();
        }
    };

    // useEffect(() => {
    //     const feeSubPayloads = _commodity.generateSubPayloadsForFees({ ...pickup, customer }, commoditiesAvailable);
    //     if (_.isEmpty(feeSubPayloads)) return;
    //     setSubPayloads(feeSubPayloads);
    // }, []);

    useEffect(() => {
        const updatedPayload = _.cloneDeep(payload);
        _commodity.addSubPayloadsToPayload(updatedPayload, subPayloads);

        setCumulativePayload(updatedPayload);
    }, [payload, subPayloads]);

    useEffect(() => {
        const payload = _pickup.getPayload(pickup);
        const subPayloads = _.get(pickup, 'subPayloads', []);

        const updatedPayload = _.cloneDeep(payload);
        const updatedSubPayloads = _.cloneDeep(subPayloads);

        _commodity.populateSubPayloadsSubCommodities(updatedSubPayloads, commoditiesAvailable);
        _commodity.removeSubPayloadsFromPayload(updatedPayload, updatedSubPayloads);

        setPayload(updatedPayload);
        setSubPayloads(updatedSubPayloads);
    }, [open, pickup, commoditiesAvailable]);

    const zone = _pickup.getZone(pickup);
    const frequencyWeeks = _zone.getFrequency(zone) / 7;

    const timezone = _.get(pickup, 'zone.timezone', process.env.REACT_APP_REGION_TIMEZONE);
    const dateString = locDate(pickup.date, 'dddd, MMMM Do', lang, timezone);

    const formErrors = validatePayload(
        _zone.getPayloadAccepted(zone),
        _zone.getPayloadRequired(zone),
        cumulativePayload,
        customer.adminView
    );

    const formContainsErrors = Object.keys(formErrors).reduce((acc, key) => {
        return formErrors[key].fail || acc;
    }, false);

    return (
        <>
            <Dialog open={open && mode === 'ACTION'} maxWidth="sm" fullWidth fullScreen={fullScreen} onClose={onClose}>
                <DialogTitle style={{ marginTop: `var(--custom-safe-area-top)` }}>
                    {loc('confirmPickup1', lang, { address: pickup.location.description, date: dateString })}
                </DialogTitle>
                <DialogContent>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexDirection: 'column',
                            height: '100%'
                        }}
                    >
                        <div>
                            <Typography variant="body1" style={{ marginBottom: theme.spacing.unit * 2 }}>
                                {frequencyWeeks === 1
                                    ? loc('confirmPickup2a', lang)
                                    : loc('confirmPickup2b', lang, { frequencyWeeks })}
                            </Typography>
                            <CustomFormTitle titleText={loc('confirmPickup3', lang)} iconName="check" />
                            <PayloadInput
                                payload={payload}
                                subPayloads={subPayloads}
                                onPayload={handlePayload}
                                onSubPayloads={handleSubPayloads}
                                formErrors={formErrors}
                                payloadAccepted={_zone.getPayloadAccepted(zone)}
                                payloadRequired={_zone.getPayloadRequired(zone)}
                                hideHelpButtons
                            />
                            <Typography
                                variant="caption"
                                style={{ marginTop: theme.spacing.unit, fontStyle: 'italic' }}
                            >
                                {loc('confirmPickup4', lang)}
                            </Typography>
                        </div>
                        {fullScreen && (
                            <div>
                                <Button
                                    onClick={onClose}
                                    fullWidth
                                    style={{ marginTop: theme.spacing.unit }}
                                    variant="outlined"
                                >
                                    {loc('confirmPickup5', lang)}
                                </Button>
                                <Button
                                    color="secondary"
                                    data-cy="pickup-confirmation-dialog-cancel-button"
                                    onClick={() => handleCancelConfirmationDialog(true)}
                                    fullWidth
                                    style={{ marginTop: theme.spacing.unit }}
                                    variant="outlined"
                                >
                                    {loc('confirmPickup6', lang)}
                                </Button>
                                <Button
                                    color="primary"
                                    data-cy="pickup-confirmation-dialog-postone-button"
                                    onClick={onPostpone(pickup)}
                                    disabled={_.isNil(pickup.frequency)}
                                    fullWidth
                                    style={{ marginTop: theme.spacing.unit }}
                                    variant="outlined"
                                >
                                    {loc('confirmPickup7', lang, { frequencyWeeks })}
                                </Button>
                                <Button
                                    color="primary"
                                    data-cy="pickup-confirmation-dialog-confirm-button"
                                    disabled={formContainsErrors || _pickup.payloadIsEmpty(cumulativePayload)}
                                    onClick={handleSubmit}
                                    fullWidth
                                    style={{ marginTop: theme.spacing.unit }}
                                    variant="outlined"
                                >
                                    {loc('confirmPickup8', lang)}
                                </Button>
                            </div>
                        )}
                    </div>
                </DialogContent>
                {!fullScreen && (
                    <DialogActions>
                        <Button onClick={onClose}>{loc('confirmPickup5', lang)}</Button>
                        <Button
                            color="secondary"
                            data-cy="pickup-confirmation-dialog-cancel-button"
                            onClick={() => handleCancelConfirmationDialog(true)}
                        >
                            {loc('confirmPickup6', lang)}
                        </Button>
                        <Button
                            color="primary"
                            data-cy="pickup-confirmation-dialog-postone-button"
                            onClick={onPostpone(pickup)}
                            disabled={_.isNil(pickup.frequency)}
                        >
                            {loc('confirmPickup7', lang, { frequencyWeeks })}
                        </Button>
                        <Button
                            color="primary"
                            data-cy="pickup-confirmation-dialog-confirm-button"
                            disabled={formContainsErrors || _pickup.payloadIsEmpty(cumulativePayload)}
                            onClick={handleSubmit}
                        >
                            {loc('confirmPickup8', lang)}
                        </Button>
                    </DialogActions>
                )}
            </Dialog>
            <CancelPickupDialog
                open={open && mode === 'CANCEL'}
                fullScreen={fullScreen}
                cancelReason={cancelReason}
                handleCancelConfirmationDialog={handleCancelConfirmationDialog}
                handleCancelChange={handleCancelChange}
                handleCancel={handleCancel}
                unserviceable={pickup.unserviceable}
                customer={customer}
                http={http}
                selectedPickup={pickup}
                onSnackbar={onSnackbar}
                onReloadCustomer={reloadCustomer}
                handleCloseConfirmation={onClose}
                payload={cumulativePayload}
                subPayloads={subPayloads}
            />
        </>
    );
}

export default withMobileDialog({ breakpoint: 'sm' })(withTheme()(PickupConfirmationDialog));
