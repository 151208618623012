import React from 'react';

import _ from 'lodash';

import ContainerWithLogo from 'components/ContainerWithLogo/ContainerWithLogo';

import Typography from '@material-ui/core/Typography';
import { withTheme } from '@material-ui/core/styles';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from '../../localizations/localizationHandler';
import androidBadge from 'icons/play-store.svg';
import iOSBadge from 'icons/app-store.svg';
import { Grid, Icon, MenuItem, Select, withWidth } from '@material-ui/core';
import { isWidthUp } from '@material-ui/core/withWidth';
import { AVAILABLE_LANGS } from '../../constants';
import CON_App_Screenshot_en from '../../icons/CON_App_Screenshot_en.png';
import CON_App_Screenshot_fr from '../../icons/CON_App_Screenshot_fr.png';
import STD_App_Screenshot_en from '../../icons/STD_App_Screenshot.png';
import AUS_App_Screenshot_en from '../../icons/AUS_App_Screenshot.png';
import { useContext } from 'react';

import { getQueryStringValue, setQueryStringValue } from 'utils/query';
import { useEffect } from 'react';
import { useState } from 'react';

const availableLangs = AVAILABLE_LANGS[process.env.REACT_APP_REGION_EXT];
function DownloadApp(props) {
    const screenshots = {
        CON_App_Screenshot_en,
        CON_App_Screenshot_fr,
        STD_App_Screenshot_en,
        AUS_App_Screenshot_en
    };
    const { theme, width, auth } = props;
    const { lang } = useContext(LocalizationContext);
    const [displayLang, setDisplayLang] = useState(process.env.REACT_APP_REGION_LANGUAGE);

    const handleClick = downloadLink => {
        window.open(downloadLink, '_system');
    };

    const isSmUp = isWidthUp('sm', width);

    useEffect(() => {
        let queryLang = getQueryStringValue('lang');
        if (!_.isNil(queryLang) && availableLangs.includes(queryLang)) {
            setSelectedLang(queryLang);
        } else if (auth.isAuthenticated) {
            setSelectedLang(lang);
        } else {
            setSelectedLang(process.env.REACT_APP_REGION_LANGUAGE);
        }
    }, []);

    const setSelectedLang = async selectedLang => {
        setQueryStringValue('lang', selectedLang);
        setDisplayLang(selectedLang);
    };

    return (
        <ContainerWithLogo brandLogoOverride={_.get(props, 'brandLogoOverride')}>
            <div
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'space-around',
                    height: '100%',
                    maxWidth: '1000px',
                    padding: theme.spacing.unit * 2
                }}
            >
                <Grid container spacing={theme.spacing.unit}>
                    <Grid item xs={12} sm={8} style={{ padding: isSmUp ? theme.spacing.unit * 3 : 'default' }}>
                        <Typography
                            variant={isSmUp ? 'h2' : 'h4'}
                            style={{ marginTop: theme.spacing.unit * 4, fontSize: isSmUp ? '3.5rem' : '2.5rem' }}
                        >
                            {loc('downloadPage1a', displayLang)}
                        </Typography>
                        <Typography
                            variant={isSmUp ? 'h2' : 'h4'}
                            style={{ marginTop: theme.spacing.unit * 4, fontSize: isSmUp ? '3.5rem' : '2.5rem' }}
                        >
                            {loc('downloadPage1b', displayLang)}
                        </Typography>
                        <Typography variant={isSmUp ? 'h6' : 'body1'} style={{ marginTop: theme.spacing.unit * 4 }}>
                            {loc('downloadPage2', displayLang)}
                        </Typography>
                        <div style={{ display: 'flex', justifyContent: isSmUp ? 'flex-start' : 'center' }}>
                            <div
                                style={{
                                    marginBottom: theme.spacing.unit * 3,
                                    marginTop: '35px',
                                    display: 'flex',
                                    maxWidth: 300
                                }}
                            >
                                <img
                                    style={{ maxWidth: 130, height: 50, display: 'block', cursor: 'pointer' }}
                                    src={iOSBadge}
                                    alt="App Store"
                                    onClick={() => handleClick(process.env.REACT_APP_APPSTORE_LINK)}
                                />
                                <img
                                    style={{
                                        maxWidth: 130,
                                        height: 50,
                                        display: 'block',
                                        marginLeft: theme.spacing.unit * 2,
                                        cursor: 'pointer'
                                    }}
                                    src={androidBadge}
                                    alt="Google Play"
                                    onClick={() => handleClick(process.env.REACT_APP_GOOGLE_PLAY_LINK)}
                                />
                            </div>
                        </div>

                        {availableLangs.length > 1 && (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'flex-start',
                                    marginTop: theme.spacing.unit,
                                    marginBottom: theme.spacing.unit
                                }}
                            >
                                <Icon color="inherit" style={{ marginRight: theme.spacing.unit }}>
                                    language
                                </Icon>
                                <Select
                                    value={displayLang}
                                    onChange={e => {
                                        setSelectedLang(e.target.value);
                                    }}
                                >
                                    {availableLangs.map(availableLang => {
                                        return (
                                            <MenuItem value={availableLang} key={availableLang}>
                                                {
                                                    {
                                                        en: 'English (CAN)',
                                                        fr: 'Français (CAN)'
                                                    }[availableLang]
                                                }
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </div>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                marginTop: isSmUp ? theme.spacing.unit * 4 : 0
                            }}
                        >
                            <img
                                src={_.get(
                                    screenshots,
                                    `${process.env.REACT_APP_REGION_EXT}_App_Screenshot_${displayLang}`
                                )}
                                alt="App store example"
                                style={{ maxHeight: 550, maxWidth: '90%', objectFit: 'contain' }}
                            />
                        </div>
                    </Grid>
                </Grid>
            </div>
        </ContainerWithLogo>
    );
}

export default withWidth()(withTheme()(DownloadApp));
