import React, { useMemo, useEffect, useContext, useState } from 'react';

import _ from 'lodash';

import { getBulkCounterFinancials, toggleFullScreen } from 'utils/misc';

import CountsScreen from 'components/BulkComponents/CountsScreen';

import useLiveCountsListenerSocket from 'components/LiveCounts/hooks/useLiveCountsListenerSocket';

import { withTheme, Paper, IconButton, Icon } from '@material-ui/core';

import SwipeableViews from 'resources/temp_packages/SwipeableViews';
import { autoPlay } from 'react-swipeable-views-utils';

import HttpContext from 'utils/contexts/HttpContext';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

function LiveCounts({ theme }) {
    const http = useContext(HttpContext);

    const [addsConfig, setAddsConfig] = useState({});

    const { bulk } = useLiveCountsListenerSocket();

    const bulkSkus = _.groupBy(_.get(bulk, 'rates.rates'), 'skuType');

    const currentCounts = _.get(bulk, 'counts', []);

    const { totalCountFormAmount, grandTotal, totalFees } = useMemo(
        () => getBulkCounterFinancials({ bulk, skus: bulkSkus, countForm: {}, currentCounts }),
        [bulk, currentCounts, bulkSkus]
    );

    useEffect(() => {
        const fetchData = async () => {
            const res = await http.getJSON('/config/liveCountAds');
            if (res.ok) {
                setAddsConfig(_.get(res, 'data.config', {}));
            }
        };

        fetchData();
    }, []);

    return (
        <>
            <IconButton
                style={{ position: 'absolute', left: 0, top: 0, zIndex: 10000, color: 'rgba(0,0,0,0.5)' }}
                onClick={() => {
                    toggleFullScreen(document.body);
                }}
            >
                <Icon style={{ color: 'rgba(0,0,0,0.5)' }}>fullscreen</Icon>
            </IconButton>
            {!_.isNil(bulk) ? (
                <Paper
                    style={{
                        display: 'flex',
                        height: `100%`,
                        padding: 0,
                        flex: 1
                    }}
                >
                    <div
                        style={{
                            flex: 1,
                            //height: `calc(100% - ${heightOffset}px)`,
                            height: '100%',
                            padding: 0,
                            display: 'flex',
                            flexDirection: 'column',
                            borderRight: '3px solid white'
                        }}
                    >
                        <CountsScreen
                            hideCounter
                            disableEditing
                            bulk={bulk}
                            inProgress={false}
                            customFeesForm={{}}
                            skus={bulkSkus}
                            countTotal={grandTotal + totalFees}
                            grandTotal={grandTotal}
                            totalCountFormAmount={totalCountFormAmount}
                            onRemoveCounts={() => {}}
                            onRemoveCustomFee={() => {}}
                            onClearCountsAndFees={() => {}}
                            countFontSize="1.4rem"
                            totalsFontSize="1.9rem"
                            headerFooterColor={theme.palette.primary.light}
                            headerFooterTextColor="white"
                            padding={theme.spacing.unit * 4}
                        />
                    </div>
                    <div
                        style={{
                            flex: 1,
                            height: `100%`,
                            maxHeight: `100%`,
                            margin: 0,
                            display: 'flex',
                            flexDirection: 'column',
                            overflow: 'hidden'
                        }}
                    >
                        <AutoPlayAds imageNames={_.get(addsConfig, 'halfScreen', [])} />
                    </div>
                </Paper>
            ) : (
                <Paper
                    style={{
                        flex: 1,
                        height: '100%',
                        maxHeight: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        overflow: 'hidden',
                        width: '100%'
                    }}
                >
                    <AutoPlayAds imageNames={_.get(addsConfig, 'fullScreen', [])} />
                </Paper>
            )}{' '}
        </>
    );
}

export default withTheme()(LiveCounts);

const AutoPlayAds = withTheme()(function({ imageNames, theme }) {
    const [imageIndex, setImageIndex] = useState(0);
    return (
        <AutoPlaySwipeableViews
            index={imageIndex}
            ignoreNativeScroll
            interval={10000}
            onChangeIndex={i => setImageIndex(i)}
            style={{ overflow: 'hidden', width: '100%' }}
        >
            {imageNames.map(imageName => (
                <img
                    src={process.env.REACT_APP_ORIGIN_URL + process.env.PUBLIC_URL + '/' + imageName}
                    alt={`ad`}
                    style={{ objectFit: 'cover', width: '100%' }}
                />
            ))}
        </AutoPlaySwipeableViews>
    );
});
