import React, { useContext } from 'react';
import _ from 'lodash';

import moment from 'moment-timezone';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    withTheme
} from '@material-ui/core';
import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc, locDate } from 'localizations/localizationHandler';

import ImageViewer from 'components/ImageUploads/ImageViewer';

function UncountedDialog(props) {
    const { open, onClose, pickupsCompletedAndUncounted, theme } = props;
    const { lang } = useContext(LocalizationContext);
    return (
        <Dialog open={open} fullWidth onClose={onClose}>
            <DialogTitle>
                {pickupsCompletedAndUncounted.length === 1
                    ? loc('pickupWidgets26', lang)
                    : loc('pickupWidgets27', lang)}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {pickupsCompletedAndUncounted.map(({ images, completionDate, location }, count) => (
                        <>
                            <li>
                                {location.description} —{' '}
                                {loc('pickupWidgets28', lang, {
                                    time: locDate(completionDate, 'h:mm A', lang),
                                    date: locDate(completionDate, 'dddd, MMM Do', lang)
                                })}
                            </li>
                            <br />
                            {!_.isEmpty(_.get(images, 'pickupImages', [])) && (
                                <>
                                    <ImageViewer
                                        displayImages={images.pickupImages}
                                        disableDelete={true}
                                        scrollerStyles={{ maxWidth: 420, overflowX: 'auto' }}
                                    />
                                    <br />
                                </>
                            )}
                        </>
                    ))}
                    {loc('pickupWidgets29', lang)}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={onClose}>
                    {loc('close', lang)}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default withTheme()(UncountedDialog);
