import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';
import { getQueryStringValue } from 'utils/query';

import { formatAsCurrency } from '../../utils/misc';

import { withTheme } from '@material-ui/core/styles';

import RegionForm from './Forms/RegionForm';

import CRUDTable from './CRUDTable';

import useCRUD from './hooks/useCRUD';

import HttpContext from 'utils/contexts/HttpContext';

function RegionsTable(props) {
    const { operator, onSnackbar, theme } = props;

    const http = useContext(HttpContext);

    const endpoints = {
        getEndPoint: '/regions/allRegions', // url to get all documents
        getEditEndPoint: _id => `/regions/${_id}/updateRegion`, // url to edit documents NOTE: this should be a function
        createEndPoint: `/regions/createRegion` // url to add new documents
    };

    const [allCharityTasks, setAllCharityTasks] = useState([]);

    const [selectedDocument, setSelectedDocument] = useState(null);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [activeOrderBy, setActiveOrderBy] = useState(null);
    const [activeOrder, setActiveOrder] = useState('asc');
    const {
        data,
        otherStats,
        startDateFilterStats,
        endDateFilterStats,
        loading,
        setLoading,
        setStartDateFilterStats,
        setEndDateFilterStats,
        dateFilterStatsErrorMessage,
        defaults,
        handleCreate,
        handleEdit
    } = useCRUD({
        endpoints,
        setEditDialogOpen,
        setActiveOrderBy,
        setActiveOrder,
        http,
        onSnackbar
    });

    const columns = [
        { key: 'name', header: 'Name' },
        { key: 'lat', header: 'Center Lat' },
        { key: 'lng', header: 'Center Lng' },
        { key: 'rad', header: 'Radius' }
    ];

    const editForm = (
        <RegionForm
            defaults={defaults}
            region={selectedDocument}
            open={editDialogOpen}
            allCharityTasks={allCharityTasks}
            onClose={() => setEditDialogOpen(false)}
            onSubmit={_.isNil(selectedDocument) ? handleCreate : handleEdit}
        />
    );

    const [customFunctionLoading, setCustomFunctionLoading] = useState(false);

    const recalcClosestRegionsObj = {
        btnLabel: 'Recalculate Closest Regions',
        btnMaterialIcon: 'refresh_outlined',
        loading: customFunctionLoading,
        method: async function () {
            setCustomFunctionLoading(true);
            const res = await http.postJSON('/charities/updateClosestRegion')

            if (res.ok) {
                onSnackbar('Closest region of all charities updated');
            }
            else {
                onSnackbar(res.errorMessage, 'error');
            }
            setCustomFunctionLoading(false);
        }
    }

    useEffect(() => {
        const fetchCharityTasks = async () => {
            const charitytasksRes = await http.getJSON('/charityTasks/allCharityTasks');
            if (charitytasksRes.ok) {
                setAllCharityTasks(charitytasksRes.data);
            }
        };

        fetchCharityTasks();
    }, []);

    return (
        <>
            <CRUDTable
                operator={operator}
                columns={columns}
                data={data}
                editForm={editForm}
                documentIsDisabled={region => false}
                defaultRowsPerPage={5}
                startDateFilterStats={startDateFilterStats}
                setStartDateFilterStats={setStartDateFilterStats}
                endDateFilterStats={endDateFilterStats}
                setEndDateFilterStats={setEndDateFilterStats}
                dateFilterStatsErrorMessage={dateFilterStatsErrorMessage}
                selectedDocument={selectedDocument}
                setSelectedDocument={setSelectedDocument}
                editDialogOpen={editDialogOpen}
                setEditDialogOpen={setEditDialogOpen}
                activeOrderBy={activeOrderBy}
                setActiveOrderBy={setActiveOrderBy}
                activeOrder={activeOrder}
                setActiveOrder={setActiveOrder}
                enabledHeaderText={'Regions'}
                disabledHeaderText={'Disabled Regions'}
                loading={loading}
                hideDateFilters
                nonCrudCustomFunction={recalcClosestRegionsObj}
            />
        </>
    );
}

export default withTheme()(RegionsTable);
