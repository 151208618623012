import React, { useContext } from 'react';
import _ from 'lodash';

import { Button, Dialog, Typography, Card, CardActions, CardContent, Icon, MenuItem } from '@material-ui/core';
import * as terms from 'localizations/terms';

import { withTheme } from '@material-ui/core/styles';
import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';
import { useState } from 'react';
import { validate } from 'utils/validate';
import StyledTextField from 'components/QuestionnaireForm/inputs/StyledTextField';

function CharityTypeDialog(props) {
    const { open, onClose, http, onSnackbar, theme, charity, reloadCharity } = props;
    const { lang } = useContext(LocalizationContext);

    const [charityType, setCharityType] = useState('GROUP');
    const [charityRegistrationNumber, setCharityRegistrationNumber] = useState(
        _.get(charity, 'charityRegistrationNumber') || ''
    );
    const [charityRegistrationNumberError, setCharityRegistrationNumberError] = useState('');

    const handleSubmit = async () => {
        let err;
        let body = { charityType };
        if (charityType === 'CHARITY') {
            body.charityRegistrationNumber = charityRegistrationNumber;
            err = validate(['charity-registration-number'], charityRegistrationNumber, lang);
        }
        if (_.get(err, 'fail', false)) {
            setCharityRegistrationNumberError(err.reason);
        } else {
            const res = await http.post(`/charities/${charity._id}/setCharityType`, body);
            if (res.ok) {
                onSnackbar(
                    loc('dashboardSetting29', lang, {
                        organization: terms.ORGANIZATION_NAME.toLowerCase()
                    })
                );
                reloadCharity();
                onClose();
            } else {
                onSnackbar(loc('charitySnackbar18', lang), 'error');
            }
        }
    };

    return (
        <Dialog open={open} maxWidth="sm" fullWidth>
            <Card style={{ width: '100%', backgroundColor: theme.palette.primary.main }}>
                <CardContent>
                    <div style={{ textAlign: 'center' }}>
                        <Icon style={{ fontSize: 75, marginBottom: 8, color: '#fff' }} fontSize="large">
                            switch_account
                        </Icon>
                    </div>
                    <Typography variant="h5" gutterBottom style={{ textAlign: 'center', color: '#fff' }}>
                        {loc('charityType1', lang)}
                    </Typography>
                    <Typography variant="body1" gutterBottom style={{ color: '#fff' }}>
                        {loc('charityType2', lang)}
                    </Typography>
                    <StyledTextField
                        textColor={'#fff'}
                        question={{}}
                        lang={lang}
                        value={charityType}
                        margin="dense"
                        fullWidth
                        InputProps={{}}
                        select
                        onChange={e => setCharityType(e.target.value)}
                    >
                        <MenuItem value="GROUP">Group</MenuItem>
                        <MenuItem value="CHARITY">Charity</MenuItem>
                    </StyledTextField>
                    {charityType === 'CHARITY' && (
                        <StyledTextField
                            textColor={'#fff'}
                            value={charityRegistrationNumber}
                            margin="dense"
                            fullWidth
                            InputProps={{}}
                            label={loc('charityNumName', lang)}
                            variant="outlined"
                            onChange={e => setCharityRegistrationNumber(e.target.value)}
                            error={!_.isEmpty(charityRegistrationNumberError)}
                            helperText={charityRegistrationNumberError}
                            placeholder="123456789RR0001"
                        />
                    )}
                </CardContent>
                <CardActions style={{ justifyContent: 'flex-end' }}>
                    <Button style={{ color: '#fff' }} data-cy="switch-account-dialog" onClick={handleSubmit}>
                        OK
                    </Button>
                </CardActions>
            </Card>
        </Dialog>
    );
}

export default withTheme()(CharityTypeDialog);
