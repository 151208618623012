import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    withMobileDialog,
    withTheme,
    Typography,
    FormControl,
    InputLabel,
    Select,
    OutlinedInput,
    colors,
    MenuItem,
    FormHelperText
} from '@material-ui/core';

import { _commodity } from 'std';

import StringListInput from 'components/CustomInputs/StringListInput';
import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

function CommodityPickupEmailsDialog(props) {
    const { open, collector, onClose, onSubmit, commodities, theme, fullScreen } = props;

    const { lang } = useContext(LocalizationContext);

    const commoditiesFiltered = _.filter(commodities, commodity => _commodity.getSkuType(commodity) !== 'beverage');

    const [commodityPickupConfig, setCommodityPickupConfig] = useState(_.get(collector, 'commodityPickupConfig', []));
    const [commodityIdSelected, setCommodityIdSelected] = useState(_.first(commoditiesFiltered)._id);

    const configForCommodityI = _.findIndex(commodityPickupConfig, { commodity: commodityIdSelected });
    const emailsForCommodity = _.get(commodityPickupConfig, `[${configForCommodityI}].emails`, []);

    const handleUpdateEmails = emails => {
        const commodityPickupConfigCopy = _.cloneDeep(commodityPickupConfig);
        if (configForCommodityI === -1) {
            commodityPickupConfigCopy.push({
                commodity: commodityIdSelected,
                emails
            });
        } else {
            commodityPickupConfigCopy[configForCommodityI].emails = emails;
        }
        setCommodityPickupConfig(commodityPickupConfigCopy);
    };

    useEffect(() => {
        setCommodityPickupConfig(_.get(collector, 'commodityPickupConfig', []));
        setCommodityIdSelected(_.first(commoditiesFiltered)._id);
    }, [collector, open]);

    return (
        <Dialog onClose={onClose} open={open} fullScreen={fullScreen} fullWidth>
            <DialogTitle>Collector Commodity Pickup Emails</DialogTitle>
            <DialogContent>
                <FormControl style={{ marginBottom: theme.spacing.unit, marginTop: theme.spacing.unit }} fullWidth>
                    <InputLabel variant="outlined">Commodity Type</InputLabel>
                    <Select
                        input={<OutlinedInput labelWidth={'Commodity Type'.length * theme.spacing.unit * 1.2} />}
                        onChange={e => setCommodityIdSelected(e.target.value)}
                        value={commodityIdSelected}
                    >
                        {commoditiesFiltered.map(commodity => (
                            <MenuItem key={commodity._id} value={commodity._id}>
                                {_commodity.getSkuType(commodity)}
                            </MenuItem>
                        ))}
                    </Select>
                    {_.isEmpty(emailsForCommodity) && (
                        <FormHelperText>
                            <Typography style={{ color: colors.orange[500] }}>
                                Collector pickups disabled for this commodity, add emails to enable it
                            </Typography>
                        </FormHelperText>
                    )}
                </FormControl>
                <StringListInput
                    InputProps={{
                        variant: 'outlined'
                    }}
                    label="Email"
                    values={emailsForCommodity}
                    onChange={values => handleUpdateEmails(values)}
                    error={email => {
                        let regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                        return !(email === '') && !regexEmail.test(email.toLowerCase());
                    }}
                    errorMessage="Please enter a valid email address."
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button type="submit" color="primary" onClick={() => onSubmit(commodityPickupConfig)}>
                    {loc('submit', lang)}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default withMobileDialog()(withTheme()(CommodityPickupEmailsDialog));
