import React from 'react';

import _ from 'lodash';

import { Typography, Button, withTheme } from '@material-ui/core';

import { TwitterShareButton, TwitterIcon } from 'react-share';

import CustomGrid from '../Components/CustomGrid';

import { deviceHelper } from 'utils/misc';
import { loc } from 'localizations/localizationHandler';

function TwitterDisplay({
    charity,
    referralLinkComponent,
    socialMediaHandle,
    subject,
    message,
    shareUrl,
    urlToLink,
    hashtags,
    onShare,
    onNativeShare,
    theme,
    lang
}) {
    const linkStyle = { overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' };
    return (
        <>
            <Typography variant="body1" style={{ marginBottom: theme.spacing.unit }}>
                <>
                    <ol>
                        <li>
                            {loc('growthGeneral13', lang)}
                            <div style={linkStyle}>
                                {charity.approved ? referralLinkComponent : loc('growthGeneral17', lang)}
                            </div>
                        </li>
                        <li>
                            {loc('growthGeneral14', lang)} {urlToLink(process.env.REACT_APP_TWITTER_LINK)}
                        </li>
                        <li>{loc('growthGeneral15', lang)}</li>
                        <li>{loc('growthGeneral16', lang)}</li>
                    </ol>
                </>
            </Typography>
            <CustomGrid
                contentItems={[
                    {
                        text: (
                            <>
                                {loc('growthTwitter1', lang, { socialMediaHandle })} <br />
                                <br />
                                {loc('growthTwitter2', lang)}
                                <br />
                                <br />
                                <div style={linkStyle}>{referralLinkComponent}</div>
                            </>
                        )
                    },
                    {
                        text: (
                            <>
                                {loc('growthTwitter3', lang, { socialMediaHandle })}
                                <br />
                                <br />
                                <div style={linkStyle}>{referralLinkComponent}</div>
                            </>
                        )
                    },
                    {
                        text: (
                            <>
                                {loc('growthTwitter4', lang, { socialMediaHandle })}
                                <br />
                                <br />
                                {loc('growthTwitter5', lang)}
                                <br />
                                <br />
                                <div style={linkStyle}>{referralLinkComponent}</div>
                            </>
                        )
                    },
                    {
                        text: (
                            <>
                                {loc('growthTwitter6', lang, { socialMediaHandle })}
                                <br />
                                <br />
                                <div style={linkStyle}>{referralLinkComponent}</div>
                            </>
                        )
                    }
                ]}
            />
        </>
    );
}

export default withTheme()(TwitterDisplay);
