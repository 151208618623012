import React, { useContext, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import { _user } from 'std';
import crypto from 'crypto';

import { withTheme } from '@material-ui/core/styles';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import GMapsAutocomplete from 'components/GMapsAutocomplete';
import {
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    Button,
    FormControl,
    MenuItem,
    Grid,
    colors,
    Icon,
    IconButton,
    InputAdornment
} from '@material-ui/core';

import { getTextInput } from '../helperFunctions';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';
import { TextField } from '@material-ui/core';
import { handlePaste } from 'utils/Markdown/handlePaste';
import { validate } from '../../../utils/validate';
import TextMaskPhoneNumber from 'components/TextMasks/TextMaskPhoneNumber';
import { getReceiverName } from 'utils/misc';

const recurring_stop_frequencies = {
    'Only Once': 0,
    Weekly: 7,
    'Every 2 Weeks': 14,
    'Every 3 Weeks': 21,
    'Every 4 Weeks': 28,
    'Every 5 Weeks': 35
};
function OTCGroupForm(props) {
    const {
        OTCLocation,
        allOTCLocations,
        allOTCGroups,
        loading,
        editing,
        open,
        theme,
        fullScreen,
        onSubmit,
        onClose,
        width,
        operator,
        http,
        google,
        allCollectors
    } = props;
    const isMultiCollector = _.get(operator, 'collectors', []).length > 1;
    const allAccessCodes = allOTCLocations.map(g => g.accessCode);
    const { lang } = useContext(LocalizationContext);
    const [driversAvailable, setDriversAvailable] = useState([]);

    async function getAllDrivers() {
        const driversRes = await http.getJSON(
            `/users/getAllDrivers/${
                _user.isCollectorAdmin(operator) && _.get(operator, 'collectors', []).length <= 1
                    ? _.get(operator, 'collector._id', '')
                    : ''
            }`
        );
        if (driversRes.ok) setDriversAvailable(driversRes.data.drivers);
    }

    useEffect(() => {
        getAllDrivers();
    }, []);
    const handleFormSubmit = values => {
        const collector = _.get(values, 'collector', null);
        const receiver = _.get(values, 'receiver', null);
        if (_.isEmpty(_.get(values, 'location.description', ''))) return;
        let valuesToSubmit = _.cloneDeep(values);
        valuesToSubmit.stopDuration = Math.round(valuesToSubmit.stopDuration * 60);
        valuesToSubmit.collector = _.isEmpty(collector) ? null : collector;
        valuesToSubmit.receiver = _.isEmpty(receiver) ? null : receiver;
        onSubmit(valuesToSubmit);
    };

    const formik = useFormik({
        initialValues: {
            _id: _.get(OTCLocation, '_id'),
            name: _.get(OTCLocation, 'name', ''),
            phone: _.get(OTCLocation, 'phone', ''),
            location: _.get(OTCLocation, 'location', {
                description: '',
                place_id: undefined
            }),
            description: _.get(OTCLocation, 'description', ''),
            frequency: _.get(OTCLocation, 'frequency', 0),
            accessCode: _.get(OTCLocation, 'accessCode', getNewUniqueAccessCode(allAccessCodes)),
            group: _.get(OTCLocation, 'group._id', ''),
            stopDuration: _.get(OTCLocation, 'stopDuration', 600) / 60,
            collector: _.get(
                OTCLocation,
                'collector._id',
                _user.isSystemAdmin(operator) ? '' : _.get(operator, 'collector._id', '')
            ),
            receiver: _.get(OTCLocation, 'receiver._id', '')
        },
        validationSchema: Yup.object({
            name: Yup.string().required('You must enter a name'),
            phone: Yup.string().test('validPhoneNumber', loc('labels15', lang), value => {
                if (!value) return _user.isSystemAdmin(operator) || _user.isInternalRole();
                let errorsObj = validate(['phone'], value, lang);
                return !errorsObj.fail;
            }),
            location: Yup.object({ description: Yup.string().required('You must enter an address') }),
            group: Yup.string().required('You must select a group'),
            stopDuration: Yup.number().required('You must enter a duration')
        }),
        onSubmit: handleFormSubmit
    });

    const {
        values,
        touched,
        errors,
        dirty,
        isSubmitting,
        handleChange,
        handleBlur,
        handleSubmit,
        handleReset,
        setFieldValue,
        getFieldProps
    } = formik;
    const driversFiltered = _.filter(driversAvailable, driver => {
        return (
            !_.get(driver, 'banned', false) &&
            _user.getCollectorId(driver) === _.get(values, 'collector', '') &&
            _user.isDriver(driver)
        );
    });
    const selectedGroupCollector = _.get(_.find(allOTCGroups, { _id: _.get(values, 'group', '') }), 'collector._id');
    useEffect(() => {
        if (!_.isNil(selectedGroupCollector)) {
            setFieldValue('collector', selectedGroupCollector);
        }
    }, [selectedGroupCollector]);

    const handleSuggestionSelected = ({ suggestion, place }) => {
        setFieldValue('location', {
            description: suggestion.description,
            place_id: suggestion.place_id,
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng()
        });
    };

    return (
        <>
            <Dialog open={open} fullScreen={fullScreen}>
                <DialogTitle
                    style={{
                        paddingBottom: 0
                    }}
                >
                    {editing ? 'Update' : 'Create'}
                </DialogTitle>
                <DialogContent>
                    <div style={{ marginTop: theme.spacing.unit }}>
                        <Grid container spacing={theme.spacing.unit}>
                            <Grid item xs={6}>
                                {getTextInput(theme, 'name', 'Name', formik)}
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    {...getFieldProps('phone')}
                                    error={
                                        _.get(touched, 'phone', false) && _.get(errors, 'phone', false) ? true : null
                                    }
                                    label={'Phone'}
                                    helperText={
                                        _.get(touched, 'phone', false) && _.get(errors, 'phone', false)
                                            ? _.get(errors, 'phone', false)
                                            : null
                                    }
                                    fullWidth
                                    InputProps={{
                                        inputComponent: TextMaskPhoneNumber
                                    }}
                                    style={{ marginTop: theme.spacing.unit * 2 }}
                                    variant="outlined"
                                    onPaste={e => {
                                        handlePaste('phone', e);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6} style={{ marginTop: theme.spacing.unit * 2 }}>
                                <FormControl fullWidth>
                                    <TextField
                                        {...getFieldProps('frequency')}
                                        variant="outlined"
                                        name="frequency"
                                        label="Frequency"
                                        select
                                    >
                                        {Object.keys(recurring_stop_frequencies).map(frequency => {
                                            return (
                                                <MenuItem key={frequency} value={recurring_stop_frequencies[frequency]}>
                                                    {frequency}
                                                </MenuItem>
                                            );
                                        })}
                                    </TextField>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} style={{ marginTop: theme.spacing.unit * 2 }}>
                                <FormControl fullWidth>
                                    <TextField
                                        {...getFieldProps('group')}
                                        variant="outlined"
                                        name="group"
                                        label="Group"
                                        select
                                        error={
                                            _.get(touched, 'group', false) && _.get(errors, 'group', false)
                                                ? true
                                                : null
                                        }
                                        helperText={_.get(errors, 'group', false)}
                                    >
                                        {allOTCGroups.map(group => {
                                            return (
                                                <MenuItem key={group} value={_.get(group, '_id', null)}>
                                                    {_.get(group, 'name', '')}
                                                </MenuItem>
                                            );
                                        })}
                                    </TextField>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} style={{ marginTop: theme.spacing.unit * 2 }}>
                                {getTextInput(
                                    theme,
                                    'stopDuration',
                                    'Duration (min)',
                                    formik,
                                    'number',
                                    {},
                                    {},
                                    null,
                                    false,
                                    undefined,
                                    {}
                                )}
                            </Grid>
                            <Grid item xs={6} style={{ marginTop: theme.spacing.unit * 2 }}>
                                <TextField
                                    {...getFieldProps('accessCode')}
                                    label={'Access Code'}
                                    fullWidth
                                    onChange={undefined}
                                    variant="outlined"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    data-cy="accessCode-refresh"
                                                    color="primary"
                                                    onClick={() => {
                                                        setFieldValue(
                                                            'accessCode',
                                                            getNewUniqueAccessCode(allAccessCodes)
                                                        );
                                                    }}
                                                >
                                                    <Icon>refresh</Icon>
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                            {(_user.isSystemAdmin(operator) ||
                                (_user.isCollectorAdmin(operator) && isMultiCollector)) && (
                                <Grid item xs={6} style={{ marginTop: theme.spacing.unit * 2 }}>
                                    <TextField
                                        {...getFieldProps('collector')}
                                        variant="outlined"
                                        name="collector"
                                        label="Force Collector"
                                        select
                                        error={
                                            _.get(touched, 'collector', false) && _.get(errors, 'collector', false)
                                                ? true
                                                : null
                                        }
                                        fullWidth
                                        helperText={_.get(errors, 'collector', false)}
                                        disabled={!_.isNil(selectedGroupCollector)}
                                    >
                                        {_user.isSystemAdmin(operator) && (
                                            <MenuItem key={'no-collector'} value={''}>
                                                Use Zone Configuration
                                            </MenuItem>
                                        )}
                                        {allCollectors.map(collector => {
                                            return (
                                                <MenuItem key={collector} value={_.get(collector, '_id', null)}>
                                                    {_.get(collector, 'name', '')}
                                                </MenuItem>
                                            );
                                        })}
                                    </TextField>
                                </Grid>
                            )}
                            {!_.isEmpty(driversFiltered) && (
                                <Grid item xs={6} style={{ marginTop: theme.spacing.unit * 2 }}>
                                    <TextField
                                        {...getFieldProps('receiver')}
                                        variant="outlined"
                                        name="receiver"
                                        label="Force Driver"
                                        select
                                        error={
                                            _.get(touched, 'receiver', false) && _.get(errors, 'receiver', false)
                                                ? true
                                                : null
                                        }
                                        fullWidth
                                        helperText={_.get(errors, 'receiver', false)}
                                    >
                                        <MenuItem key={'no-driver'} value={''}>
                                            Use Zone Configuration
                                        </MenuItem>
                                        {driversFiltered.map(driver => {
                                            return (
                                                <MenuItem key={driver._id} value={driver._id}>
                                                    {getReceiverName(driver)}
                                                </MenuItem>
                                            );
                                        })}
                                    </TextField>
                                </Grid>
                            )}
                            <Grid item xs={12} style={{ marginTop: theme.spacing.unit * 2 }}>
                                <FormControl fullWidth>
                                    <GMapsAutocomplete
                                        http={http}
                                        google={google}
                                        label="Address"
                                        placeholder="Enter your address"
                                        selectedValue={_.get(values, 'location.description', '')}
                                        types={['address']}
                                        error={{
                                            fail: _.get(errors, 'location.description', false),
                                            reason: 'You must enter an address'
                                        }}
                                        onSuggestionSelected={handleSuggestionSelected}
                                        name="location"
                                        style={{ paddingBottom: 0 }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} style={{ marginTop: theme.spacing.unit * 2 }}>
                                <FormControl fullWidth>
                                    <TextField
                                        {...getFieldProps('description')}
                                        variant="outlined"
                                        name="description"
                                        label="Description"
                                        multiline
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </div>
                </DialogContent>
                <DialogActions>
                    <div>
                        <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'flex-end' }}>
                            <Button onClick={onClose} disabled={loading}>
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                color="primary"
                                data-cy="submit"
                                disabled={loading}
                                onClick={handleSubmit}
                            >
                                {loc('submit', lang)}
                            </Button>
                        </div>
                    </div>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default withMobileDialog()(withTheme()(OTCGroupForm));

function getNewUniqueAccessCode(existingCodes) {
    let newCode = crypto
        .randomBytes(Math.ceil(4))
        .toString('hex') // convert to hexadecimal format
        .slice(0, 8)
        .toUpperCase(); // return required number of characters
    let attempt = 0;
    while (existingCodes.includes(newCode) && attempt < 1000) {
        newCode = crypto
            .randomBytes(Math.ceil(4))
            .toString('hex')
            .slice(0, 8)
            .toUpperCase();
        attempt++;
    }
    return newCode;
}
