import moment from 'moment-timezone';

// DEPRECATED!!!! DO NOT USE THESE FUNCTIONS ANY MORE
export const mutateDateFromZoneToLocal = (zoneTime, timezone) => {
    let offsetCompensation =
        moment()
            .tz(timezone)
            .utcOffset() - moment().utcOffset(); // how many minutes need to be added to zone-specific time to transform it into local
    return moment(zoneTime)
        .add(offsetCompensation, 'minutes')
        .toDate();
};
// DEPRECATED!!!! DO NOT USE THESE FUNCTIONS ANY MORE
export const mutateDateFromLocalToZone = (localTime, timezone) => {
    let offsetCompensation =
        moment().utcOffset() -
        moment()
            .tz(timezone)
            .utcOffset(); // how many minutes need to be added to local time to transform it into specified timezone
    return moment(localTime)
        .add(offsetCompensation, 'minutes')
        .toDate();
};
