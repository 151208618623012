import React, { useContext, useState, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';
import { isProductionEnv } from 'utils/misc';
import { getPlaceDetails, generateAutoCompleteRequest, getFilteredAutoCompleteResults } from 'utils/mapfunc';

import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';

import Collapse from '@material-ui/core/Collapse';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
// import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { withStyles, withTheme } from '@material-ui/core/styles';

import HttpContext from 'utils/contexts/HttpContext';
import GoogleContext from 'utils/contexts/GoogleContext';
import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';
import useDefaultCoordinates from 'utils/hooks/useDefaultCoordinates';
import { Badge, colors, Divider, Icon, IconButton, Menu, Tooltip } from '@material-ui/core';

const styles = theme => ({
    container: {
        flexGrow: 1
        // position: 'relative'
        // height: 250
    },

    suggestionsContainerOpen: {
        // position: 'absolute',
        left: 0,
        right: 0,
        marginTop: theme.spacing.unit,
        zIndex: 1
    },
    suggestion: {
        display: 'block'
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: 'none'
    },
    root: {
        minHeight: '32px',
        height: 'unset'
    }
});
function GMapsAutocomplete(props) {
    const http = useContext(HttpContext);
    const { lang } = useContext(LocalizationContext);
    const {
        radius,
        types,
        bounds,
        classes,
        disabled,
        autoFocus,
        label,
        placeholder,
        error,
        endAdornment,
        location,
        style,
        customer,
        pickupType,
        selectedValue,
        fetchZoneData = false,
        onFocus,
        onBlur,
        onSuggestionSelected,
        variant,

        //when true suggestions rendered will push content below this component downwards,
        //when false suggestions will be shown on top of content below it
        suggestionsOccupyVerticalSpace = true,
        onChange,
        onPastLocationChange,
        theme,
        defaultSuggestions = [],
        handleDeleteLocation
    } = props;

    const { google, placesService } = useContext(GoogleContext);

    const autocompleteService = useMemo(() => new google.maps.places.AutocompleteService(), [google]);

    //the requests to autocomplete service are free if a place details request with the same session token is issued within a couple miniutes
    //of starting the session, the session token needs to be changed whenever a place details request is made with that token
    //https://developers.google.com/maps/billing/gmp-billing#about-autocomplete-sessions
    const [autoCompleteSessionToken, setAutoCompleteSessionToken] = useState(
        new google.maps.places.AutocompleteSessionToken()
    );

    /*const placesService = useMemo(
        () => new google.maps.places.PlacesService(new google.maps.Map(document.createElement('div'))),
        [google]
    );*/

    const [value, setValue] = useState(selectedValue ? selectedValue : '');
    const [userValueSupplied, setUserValueSupplied] = useState(false);
    const [suggestions, setSuggestions] = useState([]);
    const [unserviceableDialogOpen, setUnserviceableDialogOpen] = useState(false);
    const { defaultCoordinates } = useDefaultCoordinates(http);

    useEffect(() => {
        if (selectedValue) {
            setValue(selectedValue);
        }
    }, [selectedValue]);

    useEffect(() => {
        // initialize pickup information using pastLocationData
        if (!disabled && !_.isEmpty(defaultSuggestions)) {
            let matchedLocation = defaultSuggestions.find(location => location.description === value);
            if (matchedLocation) {
                handleChange(undefined, { newValue: matchedLocation.description });
                if (!_.isNil(onPastLocationChange)) onPastLocationChange(matchedLocation);
            }
        }
    }, []);

    const getSuggestions = (searchText, fallbackCoordinates) => {
        if (_.isEmpty(searchText) || !autocompleteService) {
            return false;
        }

        let updatedBounds = {
            ...bounds
        };

        if (!bounds || (!bounds.ne && !bounds.south)) {
            updatedBounds = undefined;
        } else if (bounds.ne && bounds.sw) {
            updatedBounds = new google.maps.LatLngBounds(bounds.sw, bounds.ne);
        }

        let fallbackLocation = {
            lat: _.get(location, 'lat'),
            lng: _.get(location, 'lng')
        };
        if (!_.get(location, 'lat') || !_.get(location, 'lng')) {
            fallbackLocation = {
                lat: fallbackCoordinates.latitude,
                lng: fallbackCoordinates.longitude
            };
        }

        const request = generateAutoCompleteRequest({
            searchText,
            location: fallbackLocation,
            google,
            radius,
            types,
            bounds: updatedBounds,
            sessionToken: autoCompleteSessionToken
        });

        autocompleteService.getPlacePredictions(request, data => {
            const filteredData = getFilteredAutoCompleteResults(data);

            if (!_.isEmpty(filteredData)) {
                setSuggestions(filteredData);
            }
        });
    };

    const debouncedGetSuggestionsRef = useRef(_.debounce(getSuggestions, 700));

    const handleSuggestionsFetchRequested = ({ value }) => {
        if (debouncedGetSuggestionsRef.current) {
            debouncedGetSuggestionsRef.current.cancel(); //cancel pending calls to get suggestions
            debouncedGetSuggestionsRef.current(value, defaultCoordinates); //call get suggestions after delay
        }
    };

    const handleSuggestionsClearRequested = () => {
        setSuggestions([]);
    };

    const handleChange = (event, { newValue }) => {
        if (!_.isNil(onChange)) onChange();
        setValue(newValue);
        setUserValueSupplied(true);
    };

    const handleSuggestionSelected = async (
        event,
        { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
    ) => {
        if (!placesService) {
            return false;
        }

        const place = await getPlaceDetails(
            placesService,
            suggestion.place_id,
            google.maps.places.PlacesServiceStatus.OK,
            http,
            autoCompleteSessionToken
        );

        //can't reuse tokens as the session will be treated as invalid.
        setAutoCompleteSessionToken(new google.maps.places.AutocompleteSessionToken());

        if (fetchZoneData) {
            const lat = place.geometry.location.lat();
            const lng = place.geometry.location.lng();

            let url = `/zone/dates?lat=${lat}&lng=${lng}&allowAllDates=${process.env.REACT_APP_ALLOW_ALL_DATES}`;

            if (!_.isEmpty(pickupType)) {
                url += `&pickupType=${pickupType}`;
            }

            if (!_.isEmpty(customer)) {
                url += `&customer_id=${customer._id}`;
            }

            const res = await http.getJSON(url);

            if (res.ok) {
                onSuggestionSelected({
                    suggestion,
                    place,
                    availableDates: res.data.availableDates,
                    zone: res.data.zone,
                    receivers: res.data.receivers
                });
            }
        } else {
            onSuggestionSelected({ suggestion, place });
        }
    };

    const handleUnserviceableDialog = state => () => {
        setUnserviceableDialogOpen(state);
    };

    const handlePastLocationSelect = async e => {
        await handleSuggestionSelected(e, { suggestion: e.target.value });
        handleChange(e, { newValue: e.target.value.description });
        if (!_.isNil(onPastLocationChange)) onPastLocationChange(e.target.value);
    };

    return (
        <div>
            <Autosuggest
                theme={{
                    container: {
                        flexGrow: 1,
                        position: suggestionsOccupyVerticalSpace ? null : 'relative'
                    },
                    suggestionsContainer: {
                        position: suggestionsOccupyVerticalSpace ? null : 'absolute',
                        left: 0,
                        right: 0,
                        marginTop: theme.spacing.unit,
                        zIndex: 1
                    },
                    suggestionsList: classes.suggestionsList,
                    suggestion: classes.suggestion
                }}
                renderInputComponent={renderInput}
                suggestions={suggestions}
                onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
                onSuggestionsClearRequested={handleSuggestionsClearRequested}
                renderSuggestionsContainer={renderSuggestionsContainer}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                onSuggestionSelected={handleSuggestionSelected}
                inputProps={{
                    customer,
                    disabled,
                    autoFocus,
                    classes,
                    variant,
                    label,
                    placeholder,
                    error,
                    endAdornment,
                    defaultSuggestions,
                    handleDeleteLocation,
                    handlePastLocationSelect,
                    theme,
                    style,
                    value,
                    onChange: handleChange,
                    onFocus,
                    onBlur
                }}
            />

            <Dialog open={unserviceableDialogOpen} fullWidth maxWidth="sm">
                <DialogTitle>{loc('unservicedDialog1', lang)}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{loc('unservicedDialog2', lang)}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={handleUnserviceableDialog(false)}>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

GMapsAutocomplete.propTypes = {
    classes: PropTypes.object.isRequired,
    placeholder: PropTypes.string.isRequired,
    selectedValue: PropTypes.string,
    onSuggestionSelected: PropTypes.func,
    // Google maps parameters:
    location: PropTypes.object,
    radius: PropTypes.number,
    restrictions: PropTypes.shape({
        country: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)])
    }),
    error: PropTypes.object,
    style: PropTypes.object
};

GMapsAutocomplete.defaultProps = {
    location: { lat: 0, lng: 0 },
    radius: 0
};

export default withTheme()(withStyles(styles)(GMapsAutocomplete));

function renderInput(inputProps) {
    const {
        customer,
        classes,
        variant = 'outlined',
        disabled,
        autoFocus,
        label,
        value,
        ref,
        error,
        endAdornment,
        defaultSuggestions,
        handlePastLocationSelect,
        handleDeleteLocation,
        theme,
        style,
        ...other
    } = inputProps;

    return (
        <TextField
            multiline
            data-cy="gmapsautocomplete-address-input"
            disabled={disabled}
            autoFocus={autoFocus}
            className={classes.textField}
            label={label}
            value={value}
            helperText={error && error.fail ? error.reason : null}
            error={error && error.fail ? true : false}
            inputRef={ref}
            variant={_.isNil(variant) || _.isEmpty(variant) ? undefined : variant}
            autoComplete="new-address"
            InputProps={{
                classes: {
                    input: classes.input
                },
                id: 'gmapsautocomplete-address-input',
                endAdornment: (
                    <>
                        <InputAdornment position="end">
                            {!disabled && !_.isEmpty(defaultSuggestions) && (
                                <PastLocationMenu
                                    customer={customer}
                                    handleDeleteLocation={handleDeleteLocation}
                                    handlePastLocationSelect={handlePastLocationSelect}
                                    defaultSuggestions={defaultSuggestions}
                                    value={value}
                                    theme={theme}
                                />
                            )}
                        </InputAdornment>
                        {endAdornment || <React.Fragment />}
                    </>
                ),
                ...other
            }}
            fullWidth
            style={style}
            FormHelperTextProps={{ 'data-cy': `gmapsautocomplete-address-input-helper-txt` }}
        />
    );
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
    const matches = match(suggestion.description, query);
    const parts = parse(suggestion.description, matches);

    const suggestionWithoutSpaces = suggestion.description.replace(/[ ,.]/g, '');
    return (
        <MenuItem selected={isHighlighted} component="div">
            <div
                data-cy={`GMapsAutocomplete-${suggestionWithoutSpaces}`}
                style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                }}
            >
                {parts.map((part, index) => {
                    return part.highlight ? (
                        <span key={index} style={{ fontWeight: 300 }} id={`${index}-gmapsautocomplete-address-li`}>
                            {part.text}
                        </span>
                    ) : (
                        <strong key={index} style={{ fontWeight: 500 }} id={`${index}-gmapsautocomplete-address-li`}>
                            {part.text}
                        </strong>
                    );
                })}
            </div>
        </MenuItem>
    );
}

function renderSuggestionsContainer(options) {
    const { containerProps, children } = options;
    return (
        <Paper {...containerProps} square>
            <Collapse in={!_.isNil(children) && children.props.items.length > 0}>{children}</Collapse>
        </Paper>
    );
}

function getSuggestionValue(suggestion) {
    return suggestion.description;
}

const PastLocationMenu = ({
    customer,
    handleDeleteLocation,
    handlePastLocationSelect,
    defaultSuggestions = [],
    value,
    theme
}) => {
    const [anchor, setAnchor] = useState(null);
    const [menuOpen, setMenuOpen] = useState(false);
    const [deleteIdx, setDeleteIdx] = useState(false);

    const adminView = _.get(customer, 'adminView', false);

    const handleDeleteOption = item => {
        const placeId = _.get(item, 'place_id', null);

        if (defaultSuggestions.length === 1) {
            setMenuOpen(false);
            setAnchor(null);
        }

        handleDeleteLocation(placeId);
        setDeleteIdx(-1);
    };

    const handleExpandOptions = (e, idx) => {
        if (deleteIdx >= 0 && idx === deleteIdx) setDeleteIdx(-1);
        else setDeleteIdx(idx);

        e.stopPropagation();
        e.preventDefault();
    };

    return (
        <>
            <IconButton
                onClick={e => {
                    setMenuOpen(!menuOpen);
                    setAnchor(e.currentTarget);
                }}
            >
                <Badge badgeContent={defaultSuggestions.length} color="primary">
                    <Icon>history</Icon>
                </Badge>
            </IconButton>
            <Menu
                anchorEl={anchor}
                data-cy="past-address-selection-dropdown"
                open={menuOpen}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                onClose={() => {
                    setMenuOpen(false);
                    setAnchor(null);
                }}
            >
                {defaultSuggestions.map((suggestion, idx) => {
                    const showDelete = idx === deleteIdx;
                    const matchValue = suggestion.description === value;
                    return (
                        <MenuItem
                            key={suggestion.description}
                            onClick={() => {
                                handlePastLocationSelect({
                                    target: {
                                        value: suggestion
                                    }
                                });
                                setMenuOpen(false);
                                setAnchor(null);
                            }}
                            style={{
                                paddingRight: !adminView ? 0 : theme.spacing.unit * 4,
                                margin: theme.spacing.unit / 2,
                                color: matchValue ? 'white' : colors.grey[900],
                                backgroundColor: matchValue ? colors.green[500] : colors.grey[200],
                                overflow: 'auto',
                                whiteSpace: 'normal',
                                height: 'auto'
                            }}
                        >
                            <span style={{ display: 'inline-block' }}>
                                {suggestion.hasActiveBooking ? (
                                    <Icon style={{ color: colors.grey[700] }}>where_to_vote</Icon>
                                ) : (
                                    <Icon style={{ color: colors.grey[700] }}>location_pin</Icon>
                                )}
                            </span>
                            <span
                                style={{
                                    fontSize: '13px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    paddingLeft: '12px',
                                    paddingRight: '12px',
                                    lineHeight: '1.2em'
                                }}
                            >
                                <span>
                                    {suggestion.description}{' '}
                                    {suggestion.nextBookingDate && (
                                        <span
                                            style={{
                                                fontWeight: 'bold',
                                                fontSize: '13px'
                                            }}
                                        >
                                            <Tooltip title="Next Date">
                                                <span style={{ fontSize: '16px' }}>
                                                    <Icon
                                                        style={{
                                                            marginLeft: theme.spacing.unit / 2,
                                                            verticalAlign: 'middle'
                                                        }}
                                                        fontSize="inherit"
                                                    >
                                                        event
                                                    </Icon>
                                                </span>
                                            </Tooltip>{' '}
                                            {suggestion.nextBookingDate}
                                        </span>
                                    )}
                                </span>
                            </span>
                            {adminView && (
                                <span
                                    style={{
                                        width: showDelete ? '100%' : 'auto',
                                        position: 'absolute',
                                        top: 0,
                                        right: 0,
                                        bottom: 0,
                                        zIndex: 10,
                                        alignItems: 'center',
                                        justifyContent: 'flex-end',
                                        display: 'flex',
                                        color: showDelete ? colors.grey[900] : 'inherit',
                                        backgroundColor: showDelete ? colors.grey[200] : 'inherit'
                                    }}
                                    onClick={e => handleExpandOptions(e, idx)}
                                >
                                    {showDelete && (
                                        <span style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                                style={{ marginRight: theme.spacing.unit * 3 }}
                                                onClick={() => handleDeleteOption(suggestion)}
                                            >
                                                Delete Location
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                size="small"
                                                onClick={e => handleExpandOptions(e, -1)}
                                            >
                                                Cancel
                                            </Button>
                                        </span>
                                    )}
                                    <span
                                        style={{
                                            height: '100%',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}
                                    >
                                        <Divider orientation="vertical" style={{ height: '66%', width: '2px' }} />

                                        {!showDelete && (
                                            <Icon style={{ margin: '8px', fontSize: '22px', color: colors.grey[700] }}>
                                                close
                                            </Icon>
                                        )}
                                        {showDelete && suggestion.hasActiveBooking && (
                                            <Icon style={{ margin: '8px', fontSize: '22px', color: colors.grey[700] }}>
                                                where_to_vote
                                            </Icon>
                                        )}
                                        {showDelete && !suggestion.hasActiveBooking && (
                                            <Icon style={{ margin: '8px', fontSize: '22px', color: colors.grey[700] }}>
                                                location_pin
                                            </Icon>
                                        )}
                                    </span>
                                </span>
                            )}
                        </MenuItem>
                    );
                })}
            </Menu>
        </>
    );
};
