import React, { useContext, useEffect, useState } from 'react';

import _, { startCase } from 'lodash';
import moment from 'moment-timezone';

import DatePicker from 'components/DateTimePickersTz/DatePicker';
import GMapsAutocomplete from 'components/GMapsAutocomplete';

import { Card, Divider, Switch, Typography } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Fade from '@material-ui/core/Fade';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import * as colors from '@material-ui/core/colors';
import Menu from '@material-ui/core/Menu';
import { Icon as MDIcon } from '@mdi/react';
import * as allIcons from '@mdi/js';

import { DriverCommentMarkdownHelpertext, getReceiverName } from 'utils/misc';
import OperatorContext from 'utils/contexts/OperatorContext';
import { handlePaste } from '../../../utils/Markdown/handlePaste';

import { _user } from 'std';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

import { DRIVER_PAYMENT_MODELS } from 'constants.js';

import ConfirmDialogContext from 'components/Dialogs/Confirm/ConfirmDialogContext';
import CustomTextBadge from 'components/CustomTextBadge/CustomTextBadge';

import { mdiChevronDown } from '@mdi/js';

const iconColors = [
    'pink',
    'red',
    'deepOrange',
    'orange',
    'amber',
    'yellow',
    'lightGreen',
    'green',
    'teal',
    'blue',
    'indigo',
    'purple',
    'grey',
    'black'
];

const recurring_stop_frequencies = {
    'Only Once': 0,
    Weekly: 7,
    'Every 2 Weeks': 14,
    'Every 3 Weeks': 21,
    'Every 4 Weeks': 28,
    'Every 5 Weeks': 35
};

function OutOfSystemStopDialog(props) {
    const {
        http,
        google,
        theme,
        open,
        driversAvailable,
        form,
        inProgress,
        collectors,
        trips,
        onClose,
        onChange,
        onLocationChange,
        onTripChange,
        isEditStop,
        onSubmit,
        selectedCollector,
        OOSPreset,
        addOOSPreset,
        removeOOSPreset,
        handleOOSPresetChange
    } = props;
    const timezone = process.env.REACT_APP_REGION_TIMEZONE; //stop create & update endpoints assume this to be the timezone used on the date picker
    const warnAction = useContext(ConfirmDialogContext);
    const { lang } = useContext(LocalizationContext);

    const operator = useContext(OperatorContext);

    const [presetMenuEl, setPresetMenuEl] = useState(null);
    const [isPreview, setIsPreview] = useState(false);

    const driversFiltered = _.filter(driversAvailable, driver => {
        return (
            !_.get(driver, 'banned', false) &&
            _user.getCollectorId(driver) === form.collector_id &&
            _user.isDriver(driver)
        );
    });
    const selectedDriver = _.find(driversFiltered, { _id: form.receiver_id });

    useEffect(() => {
        if (!isEditStop) {
            handleCollectorChange(selectedCollector);
        }
    }, [selectedCollector, isEditStop]);

    useEffect(() => {
        if (!_.isNil(selectedDriver) && _user.getPaymentModel(selectedDriver) === DRIVER_PAYMENT_MODELS[0]) {
            onChange({
                target: {
                    name: 'stopPayAmount',
                    value: null
                }
            });
        }
    }, [form.receiver_id]);

    const handleDateChange = date => {
        const customEvent = {
            target: {
                name: 'date',
                value: date
            }
        };

        onChange(customEvent);
    };

    const handleFirstStopChange = e => {
        const customEvent = {
            target: {
                name: 'placeAtStartOfTrip',
                value: e.target.checked
            }
        };

        onChange(customEvent);
    };
    const handleOnePerTripChange = e => {
        const customEvent = {
            target: {
                name: 'limitOneStopPerTrip',
                value: e.target.checked
            }
        };

        onChange(customEvent);
    };

    const handleCollectorChange = value => {
        const customEvent = {
            target: {
                name: 'collector_id',
                value: value
            }
        };

        onChange(customEvent);
    };

    const handleSuggestionSelected = ({ suggestion, place }) => {
        onLocationChange({
            description: suggestion.description,
            place_id: suggestion.place_id,
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
            city: _.get(_.find(place.address_components, ac => ac.types.includes('locality')), 'long_name', undefined),
            province: _.get(
                _.find(place.address_components, ac => ac.types.includes('administrative_area_level_1')),
                'long_name',
                undefined
            ),
            postalCode: _.get(
                _.find(place.address_components, component => component.types.includes('postal_code')),
                'short_name',
                undefined
            )
        });
    };

    const showOOSBountyInput =
        !_.isNil(selectedDriver) && _user.getPaymentModel(selectedDriver) === DRIVER_PAYMENT_MODELS[0] ? false : true;
    return (
        <Dialog fullWidth open={open} onClose={inProgress ? () => undefined : onClose}>
            <DialogTitle>
                <Grid container style={{ alignItems: 'center', justifyContent: 'space-between' }}>
                    <Grid item sm="5" xs="4">
                        {isEditStop ? 'Edit Stop' : 'Create stop'}
                    </Grid>
                    <Grid
                        item
                        sm="7"
                        xs="8"
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}
                    >
                        <Button
                            id="OOSPresetLoad"
                            data-cy="OOSPresetLoad"
                            color="primary"
                            onClick={event => {
                                setPresetMenuEl(event.currentTarget);
                            }}
                            disabled={OOSPreset.length < 1}
                        >
                            {OOSPreset.length < 1 ? loc('OOSNoPreset', lang) : loc('OOSPresetLoad', lang)}
                            <MDIcon
                                path={mdiChevronDown}
                                size={1}
                                color={OOSPreset.length < 1 ? theme.palette.text.disabled : theme.palette.primary.main}
                            />
                        </Button>
                        <Menu
                            data-cy="help-menu"
                            anchorEl={presetMenuEl}
                            open={Boolean(presetMenuEl)}
                            onClose={() => {
                                setPresetMenuEl(null);
                            }}
                        >
                            {OOSPreset.map(preset => {
                                let OOSIcon = _.get(preset, 'icon', null);
                                let OOSIconColor = _.get(preset, 'iconColor', null);
                                let OOSName = _.get(preset, 'stopName', 'Untitled');
                                let presetId = _.get(preset, '_id', null);
                                if (_.isNil(OOSIcon) || _.isNil(OOSIconColor)) return null;
                                return (
                                    <MenuItem key={presetId} style={{ justifyContent: 'center' }}>
                                        <Icon
                                            color="primary"
                                            style={{ color: getColor(OOSIconColor), marginRight: theme.spacing.unit }}
                                            onClick={() => {
                                                handleOOSPresetChange(preset);
                                                setPresetMenuEl(null);
                                            }}
                                        >
                                            {OOSIcon}
                                        </Icon>{' '}
                                        <Typography
                                            onClick={() => {
                                                handleOOSPresetChange(preset);
                                                setPresetMenuEl(null);
                                            }}
                                        >
                                            {OOSName}
                                        </Typography>
                                        <Icon
                                            style={{ marginLeft: 10, color: theme.palette.text.secondary }}
                                            onClick={() => {
                                                warnAction(() => {
                                                    if (!_.isNil(presetId)) {
                                                        removeOOSPreset(presetId);
                                                    }
                                                }, `Are you sure you want to delete this preset?`);
                                            }}
                                        >
                                            close
                                        </Icon>
                                    </MenuItem>
                                );
                            })}
                        </Menu>
                        <Button
                            id="OOSPresetSave"
                            data-cy="OOSPresetSave"
                            color="primary"
                            onClick={addOOSPreset}
                            variant="contained"
                            style={{ marginRight: 10 }}
                            disabled={
                                _.isNil(form.stopIconColor) ||
                                form.stopIconColor === '' ||
                                _.isNil(form.stopIcon) ||
                                form.stopIcon === ''
                            }
                        >
                            {loc('OOSPresetSave', lang)}
                        </Button>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <DialogContentText style={{ marginBottom: theme.spacing.unit }}>
                    Stops are general purpose tasks.
                </DialogContentText>
                <Grid container spacing={theme.spacing.unit}>
                    <Grid item xs="4">
                        <FormControl>
                            <TextField
                                fullWidth
                                data-cy="oos-stop-name"
                                variant="outlined"
                                name="stopName"
                                value={_.get(form, 'stopName', '')}
                                type="text"
                                onChange={onChange}
                                label="Stop Name"
                                error={_.isEmpty(form.stopName)}
                                helperText={_.isEmpty(form.stopName) ? 'Required' : null}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs="4">
                        <DatePicker
                            timezone={timezone}
                            data-cy="pickupdialog-datepicker-selector"
                            autoOk
                            label="Date"
                            name="date"
                            value={form.date}
                            availableDates={getNext60Days(timezone)}
                            variant="outlined"
                            onChange={handleDateChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Icon>date_range</Icon>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                    <Grid item xs="4">
                        <FormControl fullWidth>
                            <TextField
                                variant="outlined"
                                name="frequency"
                                label="Recurring"
                                value={form.frequency}
                                onChange={onChange}
                                select
                            >
                                {Object.keys(recurring_stop_frequencies).map(frequency => {
                                    return (
                                        <MenuItem key={frequency} value={recurring_stop_frequencies[frequency]}>
                                            {frequency}
                                        </MenuItem>
                                    );
                                })}
                            </TextField>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} style={{ display: 'flex', flexDirection: 'row' }}>
                        {!_.isEmpty(form.stopIcon) && (
                            <MDIcon
                                path={_.get(allIcons, convertToImportName(form.stopIcon))}
                                size={2.2}
                                color={
                                    _.isNil(colors[form.stopIconColor])
                                        ? form.stopIconColor
                                        : colors[form.stopIconColor][500]
                                }
                            />
                        )}
                        <TextField
                            style={{ marginRight: theme.spacing.unit, width: _.isEmpty(form.stopIcon) ? 250 : 192 }}
                            name="stopIcon"
                            label="Icon"
                            variant="outlined"
                            value={form.stopIcon}
                            onChange={onChange}
                        />
                        <IconButton
                            style={{ margin: 0, padding: 0 }}
                            onClick={() => {
                                window.open('https://materialdesignicons.com/');
                            }}
                        >
                            <SearchIcon />
                        </IconButton>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl style={{ width: '100%' }} fullWidth>
                            <InputLabel variant="outlined">Icon Color</InputLabel>
                            <Select
                                value={form.stopIconColor}
                                onChange={onChange}
                                input={<OutlinedInput name="stopIconColor" id="Icon Color" labelWidth={70} />}
                                SelectDisplayProps={{ style: { display: 'flex', alignItems: 'center' } }}
                            >
                                {iconColors.map(color => {
                                    return (
                                        <MenuItem key={color} value={color}>
                                            <div
                                                style={{
                                                    marginRight: theme.spacing.unit,
                                                    height: 12,
                                                    width: 12,
                                                    borderRadius: '100%',
                                                    backgroundColor: getColor(color)
                                                }}
                                            />
                                            {startCase(color)}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        {(_user.isSystemAdmin(operator) || _user.isInternalRole(operator)) && (
                            <FormControl fullWidth data-cy="oos-stop-trip-select">
                                <TextField
                                    variant="outlined"
                                    name="trip_id"
                                    label="Trip"
                                    value={form.trip_id}
                                    onChange={e => {
                                        const trip = trips.find(t => t._id.toString() === e.target.value.toString());
                                        onTripChange(
                                            e.target.value,
                                            _.get(trip, 'transporterCollector._id', ''),
                                            _.get(trip, 'transporter._id', '')
                                        );
                                    }}
                                    select
                                >
                                    <MenuItem value={''}>TBD</MenuItem>
                                    {trips.map(trip => {
                                        const timezone = _.get(
                                            trip,
                                            'transporterCollector.timezone',
                                            _.get(
                                                trip,
                                                'transporter.location.timezone',
                                                process.env.REACT_APP_REGION_TIMEZONE
                                            )
                                        );
                                        return (
                                            <MenuItem
                                                key={trip._id}
                                                value={trip._id}
                                                data-cy={`${getReceiverName(trip.transporter)
                                                    .replace(/\s/g, '-')
                                                    .toLowerCase()}-pickups-admin-assign-trip-select-li`}
                                            >
                                                <span>
                                                    <span>
                                                        {moment(trip.startTime)
                                                            .tz(timezone)
                                                            .format('h:mm A')}
                                                        <CustomTextBadge
                                                            tooltip={
                                                                moment(trip.startTime)
                                                                    .tz(timezone)
                                                                    .format('h:mm A Z') +
                                                                ' ' +
                                                                timezone
                                                            }
                                                        >
                                                            {moment(trip.startTime)
                                                                .tz(timezone)
                                                                .format('z')}
                                                        </CustomTextBadge>
                                                    </span>{' '}
                                                    <span style={{ color: theme.palette.primary.main }}>
                                                        [{_.get(trip, 'transporterCollector.code', 'N/A')}]
                                                    </span>{' '}
                                                    {getReceiverName(trip.transporter)} ➡{' '}
                                                    <span style={{ color: theme.palette.primary.main }}>
                                                        {trip.collector.code}
                                                    </span>
                                                </span>
                                            </MenuItem>
                                        );
                                    })}
                                </TextField>
                            </FormControl>
                        )}
                    </Grid>
                    <Grid item xs={4}>
                        {(_user.isSystemAdmin(operator) || _user.isInternalRole(operator)) && (
                            <FormControl fullWidth data-cy="oos-stop-pay-collector-select">
                                <TextField
                                    variant="outlined"
                                    name="collector_id"
                                    label="Transporter"
                                    value={!_.isNil(form.collector_id) ? form.collector_id : ''}
                                    onChange={onChange}
                                    select
                                    disabled={!_.isEmpty(form.trip_id)}
                                >
                                    {collectors.map(collector => {
                                        return (
                                            <MenuItem
                                                key={collector._id}
                                                value={collector._id}
                                                data-cy={`oos-stop-pay-collector-${collector._id}`}
                                            >
                                                {collector.name}
                                            </MenuItem>
                                        );
                                    })}
                                </TextField>
                            </FormControl>
                        )}
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl
                            fullWidth
                            variant="outlined"
                            data-cy="oos-stop-pay-driver-select"
                            disabled={_.isEmpty(form.collector_id) || !_.isEmpty(form.trip_id)}
                        >
                            <TextField
                                id="receiver_id"
                                name="receiver_id"
                                variant="outlined"
                                label="Driver"
                                value={!_.isNil(form.receiver_id) ? form.receiver_id : ''}
                                onChange={onChange}
                                disabled={_.isEmpty(form.collector_id) || !_.isEmpty(form.trip_id)}
                                select
                                helperText={_.isEmpty(form.collector_id) && 'Please select a collector'}
                            >
                                <MenuItem value={null}>TBD</MenuItem>
                                {driversFiltered.map(driver => {
                                    return (
                                        <MenuItem
                                            key={driver._id}
                                            value={driver._id}
                                            data-cy={`oos-stop-pay-driver-${_.kebabCase(getReceiverName(driver))}`}
                                        >
                                            {getReceiverName(driver)}
                                        </MenuItem>
                                    );
                                })}
                            </TextField>
                        </FormControl>
                    </Grid>
                    <Grid item xs="4">
                        {showOOSBountyInput ? (
                            <FormControl fullWidth>
                                <TextField
                                    data-cy="oos-stop-pay-amount"
                                    variant="outlined"
                                    name="stopPayAmount"
                                    value={_.isNil(form.stopPayAmount) ? '' : form.stopPayAmount / 100}
                                    type="number"
                                    onChange={onChange}
                                    label="Pay (Dollars)"
                                    error={_.isNil(form.stopPayAmount) || form.stopPayAmount < 0}
                                    helperText={
                                        _.isEmpty(form.stopPayAmount)
                                            ? 'Required'
                                            : form.stopPayAmount < 0
                                            ? 'Cannot be negative'
                                            : null
                                    }
                                />
                            </FormControl>
                        ) : (
                            <FormControl fullWidth>
                                <TextField
                                    data-cy="oos-stop-pay-amount-hourly"
                                    variant="outlined"
                                    name="stopPayAmount"
                                    value="Hourly"
                                    label="Pay (Dollars)"
                                    disabled
                                />
                            </FormControl>
                        )}
                    </Grid>
                    <Grid item xs="4">
                        <FormControl>
                            <TextField
                                variant="outlined"
                                data-cy="oos-stop-pay-duration"
                                name="stopDuration"
                                label="Est. Duration (Min)"
                                value={form.stopDuration}
                                onChange={onChange}
                                type="number"
                                error={_.isEmpty(form.stopDuration) || form.stopDuration < 0}
                                helperText={
                                    _.isEmpty(form.stopDuration)
                                        ? 'Required'
                                        : form.stopDuration < 0
                                        ? 'Cannot be negative'
                                        : null
                                }
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs="2">
                        <FormControl
                            style={{
                                marginLeft: theme.spacing.unit,
                                marginTop: theme.spacing.unit
                            }}
                        >
                            <FormControlLabel
                                label={
                                    <Typography
                                        variant="body2"
                                        style={{
                                            fontSize: '80%',
                                            marginLeft: theme.spacing.unit / 2,
                                            marginRight: 0,
                                            paddingLeft: 0,
                                            paddingRight: 0
                                        }}
                                    >
                                        First Pickup
                                    </Typography>
                                }
                                control={
                                    <Checkbox
                                        name="placeAtStartOfTrip"
                                        checked={form.placeAtStartOfTrip}
                                        value="placeAtStartOfTrip"
                                        onChange={handleFirstStopChange}
                                        style={{
                                            marginLeft: theme.spacing.unit,
                                            marginRight: 0,
                                            paddingLeft: 0,
                                            paddingRight: 0
                                        }}
                                    />
                                }
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs="2">
                        <FormControl
                            style={{
                                marginTop: theme.spacing.unit
                            }}
                        >
                            <FormControlLabel
                                label={
                                    <Typography
                                        variant="body2"
                                        style={{
                                            fontSize: '80%',
                                            marginLeft: theme.spacing.unit / 2,
                                            marginRight: 0,
                                            paddingLeft: 0,
                                            paddingRight: 0
                                        }}
                                    >
                                        One per Trip
                                    </Typography>
                                }
                                control={
                                    <Checkbox
                                        name="limitOneStopPerTrip"
                                        checked={form.limitOneStopPerTrip}
                                        value="limitOneStopPerTrip"
                                        onChange={handleOnePerTripChange}
                                        style={{
                                            marginLeft: theme.spacing.unit / 2,
                                            marginRight: 0,
                                            paddingLeft: 0,
                                            paddingRight: 0
                                        }}
                                    />
                                }
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <GMapsAutocomplete
                                http={http}
                                google={google}
                                autoFocus={form.location.description.length === 0 ? true : false}
                                label="Address"
                                placeholder="Enter your address"
                                selectedValue={form.location.description}
                                types={['address']}
                                error={{ fail: _.isEmpty(form.location.description), reason: 'Required' }}
                                onSuggestionSelected={handleSuggestionSelected}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <>
                            <FormControl fullWidth>
                                <TextField
                                    id={'driver-comment-id'}
                                    variant="outlined"
                                    name="comment"
                                    label="Directions"
                                    value={form.comment}
                                    onChange={onChange}
                                    onPaste={async e => {
                                        await handlePaste(e, http, onChange);
                                    }}
                                    multiline
                                />
                            </FormControl>
                            <FormControlLabel
                                control={<Switch checked={isPreview} onChange={() => setIsPreview(!isPreview)} />}
                                label="Preview Markdown"
                            />
                            {isPreview && <DriverCommentMarkdownHelpertext source={form.comment} theme={theme} />}
                        </>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={inProgress}
                    id="create-stop-dialog-close"
                    data-cy="create-stop-dialog-close"
                    color="primary"
                    onClick={onClose}
                >
                    {loc('close', lang)}
                </Button>
                <Button
                    id="create-stop-dialog-submit"
                    data-cy="create-stop-dialog-submit"
                    color="primary"
                    disabled={inProgress || _.isEmpty(form.location.description) || form.stopPayAmount < 0}
                    onClick={onSubmit}
                >
                    {loc('submit', lang)}
                </Button>
            </DialogActions>
            <Fade in={inProgress}>
                <LinearProgress />
            </Fade>
        </Dialog>
    );
}

export default withTheme()(OutOfSystemStopDialog);

function getNext60Days(timezone) {
    return _.range(0, 59).map(daysToAdd =>
        moment()
            .tz(timezone)
            .startOf('day')
            .add(daysToAdd, 'days')
            .toDate()
    );
}
function getColor(color) {
    try {
        if (_.isNil(color) || color === '') {
            return '';
        }
        return color === 'black' ? colors['grey'][900] : colors[color][600];
    } catch (e) {
        return '';
    }
}

function convertToImportName(iconName) {
    return _.camelCase(`mdi-${iconName}`);
}
