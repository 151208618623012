import React from 'react';
import _ from 'lodash';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import {
    Dialog,
    DialogActions,
    DialogContent,
    Button,
    withMobileDialog,
    withTheme,
    TextField,
    DialogTitle,
    FormControl,
    InputLabel,
    Select,
    OutlinedInput,
    MenuItem,
    ListItemText,
    Grid
} from '@material-ui/core';

import { getSelect, getSwitch, getTextInput } from '../helperFunctions';
import { Icon as MDIcon } from '@mdi/react';
import * as allIcons from '@mdi/js';
import {
    RESIDENTIAL,
    COMMERCIAL,
    CONDO,
    LABELS,
    BOTTLE_DRIVE,
    AVAILABLE_LANGS,
    EXTERNAL_LINK,
    DROPANDGO,
    EXPRESS,
    WALK_IN,
    INSTRUCTIONS,
    DEPOT_SEARCH,
    QR_SCAN,
    ACCESS_DOOR,
    DROP_OFF_TYPES,
    NO_TECHNOLOGY
} from '../../../constants';
import { isAUSRegion, isEXPRegion } from 'utils/misc';
import generateHelperTextArray from 'components/Dialogs/PickupBooking/misc/helperTextGenerator';
import { useMemo } from 'react';
import ExternalLink from 'components/ExternalLink/ExternalLink';

// add new complaint/resolution options
function ServicesForm(props) {
    const {
        pickupType,
        helpDialogKey,
        mdiIcon,
        text,
        isMobilePickupService,
        disablePromos,
        externalLink,
        idx,
        hideHelpButton,
        collectionProgram,
        subServices,
        disabled,
        hidden,
        charityLocked,
        open,
        onClose,
        onSubmit,
        theme,
        fullScreen,
        editing,
        instructionType,
        technologies
    } = props;
    const availableLangs = useMemo(() => AVAILABLE_LANGS[process.env.REACT_APP_REGION_EXT].sort(), []);

    const WORK_FLOWS = [
        { name: 'Pickup', value: RESIDENTIAL },
        // {name: LABELS, value: LABELS},
        { name: 'Booking Form', value: BOTTLE_DRIVE },
        { name: 'Technology', value: isEXPRegion() ? EXPRESS : DROPANDGO },
        { name: EXTERNAL_LINK, value: EXTERNAL_LINK },
        { name: INSTRUCTIONS, value: INSTRUCTIONS },
        { name: DEPOT_SEARCH, value: DEPOT_SEARCH },
        { name: QR_SCAN, value: QR_SCAN },
        { name: ACCESS_DOOR, value: ACCESS_DOOR }
    ];
    const technologyFilters = [
        {
            name: 'App, Kiosk, Kiosk + Scanner, Access Door',
            value: isEXPRegion() || isAUSRegion() ? EXPRESS : DROPANDGO
        },
        { name: 'RVM', value: WALK_IN }
    ];
    const helperTextArray = generateHelperTextArray({ data: {}, theme, lang: process.env.REGION_LANG });
    const HELP_DIALOG_KEYS = ['residential', 'condo', 'commercial', 'dropandgo', 'walkin', 'bottleDrive'];

    const handleFormSubmit = values => {
        if (values.pickupType !== INSTRUCTIONS) {
            delete values.instructionType;
        }
        values.idx = idx;
        onSubmit(values);
    };

    const formInputStyle = {
        marginTop: theme.spacing.unit * 2
    };

    const convertToExportName = icon => {
        if (_.isNil(icon)) {
            return icon;
        }
        if (icon.startsWith('mdi')) {
            return _.camelCase(`${icon}`);
        } else {
            return _.camelCase(`mdi-${icon}`);
        }
    };
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            pickupType: _.isNil(pickupType)
                ? RESIDENTIAL
                : [DROPANDGO, WALK_IN].includes(pickupType)
                ? DROPANDGO
                : pickupType,
            helpDialogKey: _.isNil(helpDialogKey) ? 'residential' : helpDialogKey,
            mdiIcon: _.isNil(mdiIcon) ? undefined : mdiIcon,
            isMobilePickupService: _.isNil(isMobilePickupService) ? false : isMobilePickupService,
            disablePromos: _.isNil(disablePromos) ? false : disablePromos,
            hideHelpButton: _.isNil(hideHelpButton) ? false : hideHelpButton,
            collectionProgram: _.isNil(collectionProgram) ? false : collectionProgram,
            subServices: _.isNil(subServices) ? [] : subServices,
            text: _.isNil(text) ? { en: { title: '', secondaryTitle: '' } } : text,
            charityLocked: _.isNil(charityLocked) ? false : charityLocked,
            externalLink: _.isNil(externalLink) ? '' : externalLink,
            disabled: _.isNil(disabled) ? false : disabled,
            hidden: _.isNil(hidden) ? false : hidden,
            instructionType: _.isNil(instructionType) ? 'appOnly' : instructionType,
            technologies: _.isNil(technologies) ? [] : technologies
        },
        validationSchema: Yup.object({
            pickupType: Yup.string(),
            helpDialogKey: Yup.string(),
            mdiIcon: Yup.string(),
            hidden: Yup.boolean(),
            isMobilePickupService: Yup.boolean(),
            disablePromos: Yup.boolean(),
            hideHelpButton: Yup.boolean(),
            collectionProgram: Yup.boolean(),
            charityLocked: Yup.boolean(),
            externalLink: Yup.string().when('pickupType', {
                is: EXTERNAL_LINK,
                then: Yup.string()
                    .url()
                    .required('Must enter valid URL')
            }),
            technologies: Yup.array().when('pickupType', {
                is: value => [EXPRESS, DROPANDGO, WALK_IN].includes(value),
                then: Yup.array().min(1, 'You must select at least one filter')
            })
        }),
        onSubmit: handleFormSubmit
    });

    return (
        <Dialog onClose={onClose} open={open} fullScreen={fullScreen} fullWidth>
            <DialogTitle>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span>{editing ? 'Edit' : 'Add New'} Service</span>{' '}
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        {editing && getSwitch(theme, 'hidden', 'Hidden', formik)}
                        {editing && !isMobilePickupService && getSwitch(theme, 'disabled', 'Disabled', formik)}
                    </div>
                </div>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={theme.spacing.unit}>
                    <Grid item xs={12} sm={6}>
                        {getSelect(theme, 'pickupType', 'Work Flow', WORK_FLOWS, formik, null, 'value', 'name')}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <div
                            style={{
                                ...formInputStyle,
                                display: 'inline-flex',
                                paddingLeft: theme.spacing.unit
                            }}
                        >
                            {getSwitch(theme, 'disablePromos', 'Disable Promos', formik, {}, { fontSize: '80%' })}
                            {getSwitch(theme, 'hideHelpButton', 'Hide Help Button', formik, {}, { fontSize: '80%' })}
                        </div>
                    </Grid>
                    {/* {[EXPRESS, DROPANDGO, WALK_IN].includes(formik.values.pickupType) && (
                        <Grid item xs={12}>
                            {getSelect(
                                theme,
                                'technologyFilter',
                                'Filter',
                                technologyFilters,
                                formik,
                                null,
                                'value',
                                'name'
                            )}
                        </Grid>
                    )} */}
                    {[EXPRESS, DROPANDGO, WALK_IN].includes(formik.values.pickupType) && (
                        <Grid item xs={12}>
                            {getSelect(
                                theme,
                                'technologies',
                                'Filter',
                                [...DROP_OFF_TYPES, NO_TECHNOLOGY],
                                formik,
                                null,
                                'value',
                                'value',
                                true
                            )}
                        </Grid>
                    )}
                    {[INSTRUCTIONS].includes(formik.values.pickupType) && (
                        <Grid item xs={12}>
                            {getSelect(
                                theme,
                                'instructionType',
                                'Technology',
                                DROP_OFF_TYPES,
                                formik,
                                null,
                                'localizationName',
                                'value'
                            )}
                        </Grid>
                    )}
                    {formik.values.pickupType === EXTERNAL_LINK && (
                        <Grid item xs={12}>
                            {getTextInput(theme, 'externalLink', 'URL to Link To', formik, 'url')}
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    marginRight: theme.spacing.unit
                                }}
                            >
                                <MDIcon
                                    path={_.get(allIcons, convertToExportName(formik.values.mdiIcon))}
                                    size={2}
                                    color={theme.palette.primary.main}
                                />
                            </div>
                            {getTextInput(theme, 'mdiIcon', 'Icon', formik)}
                        </div>
                        <div style={{ textAlign: 'right' }}>
                            <ExternalLink
                                text="Click here to see all icons"
                                url="https://pictogrammers.com/library/mdi/"
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div
                            style={{
                                ...formInputStyle,
                                position: 'relative',
                                padding: '18.5px 14px',
                                borderStyle: 'solid',
                                borderWidth: '1px',
                                borderRadius: '4px 4px 4px 4px',
                                borderColor: 'rgba(0, 0, 0, 0.23)'
                            }}
                        >
                            <div
                                style={{
                                    paddingLeft: 4,
                                    paddingRight: 4,
                                    position: 'absolute',
                                    top: -10,
                                    backgroundColor: theme.palette.background.paper,
                                    fontFamily: theme.typography.subtitle2.fontFamily,
                                    fontSize: '12px',
                                    color: theme.palette.grey[600]
                                }}
                            >
                                Title
                            </div>
                            {availableLangs.map(lang => (
                                <TextField
                                    data-cy={`localized-input-${_.kebabCase('Title')}-edit`}
                                    key={lang}
                                    style={{ marginBottom: theme.spacing.unit }}
                                    fullWidth
                                    multiline
                                    label={lang}
                                    value={_.get(formik.values, `text.${lang}.title`)}
                                    name={lang}
                                    onChange={e => formik.setFieldValue(`text.${lang}.title`, e.target.value)}
                                    // error={formiktouched && !_.isNil(error)}
                                    // helperText={touched && !_.isNil(error) ? error : helperText}
                                />
                            ))}
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div
                            style={{
                                ...formInputStyle,
                                position: 'relative',
                                padding: '18.5px 14px',
                                borderStyle: 'solid',
                                borderWidth: '1px',
                                borderRadius: '4px 4px 4px 4px',
                                borderColor: 'rgba(0, 0, 0, 0.23)'
                            }}
                        >
                            <div
                                style={{
                                    paddingLeft: 4,
                                    paddingRight: 4,
                                    position: 'absolute',
                                    top: -10,
                                    backgroundColor: theme.palette.background.paper,
                                    fontFamily: theme.typography.subtitle2.fontFamily,
                                    fontSize: '12px',
                                    color: theme.palette.grey[600]
                                }}
                            >
                                Text
                            </div>
                            {availableLangs.map(lang => (
                                <TextField
                                    data-cy={`localized-input-${_.kebabCase('Text')}-edit`}
                                    key={lang}
                                    style={{ marginBottom: theme.spacing.unit }}
                                    fullWidth
                                    multiline
                                    label={lang}
                                    value={_.get(formik.values, `text.${lang}.secondaryTitle`)}
                                    name={lang}
                                    onChange={e => formik.setFieldValue(`text.${lang}.secondaryTitle`, e.target.value)}
                                    // error={formiktouched && !_.isNil(error)}
                                    // helperText={touched && !_.isNil(error) ? error : helperText}
                                />
                            ))}
                        </div>

                        {!formik.values.hideHelpButton && (
                            <Grid item xs={12}>
                                <FormControl fullWidth style={formInputStyle}>
                                    <InputLabel variant="outlined">Help Dialog</InputLabel>
                                    <Select
                                        value={formik.values.helpDialogKey}
                                        name="helpDialogKey"
                                        onChange={formik.handleChange}
                                        input={
                                            <OutlinedInput
                                                labelWidth={'Help Dialog'.length * theme.spacing.unit}
                                                name="helpDialogKey"
                                            />
                                        }
                                        style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                                        data-cy={`help-text-select`}
                                    >
                                        {helperTextArray
                                            .filter(helperText => HELP_DIALOG_KEYS.includes(helperText.topic))
                                            .map((helperText, i) => {
                                                let children = _.get(helperText, 'body.props.children', 'N/A');
                                                if (typeof children === 'object') {
                                                    children = _.get(helperText, 'body.props.children.0', 'N/A');
                                                }
                                                return (
                                                    <MenuItem
                                                        data-cy={`help-text-select-${i}`}
                                                        value={helperText.topic}
                                                        // style={{ maxWidth: 500 }}
                                                    >
                                                        <ListItemText
                                                            primary={helperText.title}
                                                            secondary={typeof children === 'string' ? children : 'N/A'}
                                                            secondaryTypographyProps={{
                                                                style: {
                                                                    maxWidth: 500,
                                                                    overflow: 'hidden',
                                                                    textOverflow: 'ellipsis'
                                                                }
                                                            }}
                                                            style={{ maxWidth: 500 }}
                                                        />
                                                    </MenuItem>
                                                );
                                            })}
                                    </Select>
                                </FormControl>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                {getSwitch(theme, 'collectionProgram', 'Collections Program Only', formik, {}, { fontSize: '80%' })}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button data-cy={`service-form-submit`} onClick={formik.handleSubmit}>
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default withMobileDialog()(withTheme()(ServicesForm));
