import React from 'react';
import _ from 'lodash';
import {
    Icon,
    Grid,
    Paper,
    Divider,
    Avatar,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography
} from '@material-ui/core';
import * as colors from '@material-ui/core/colors';
import { withTheme } from '@material-ui/core/styles';

const MultiIconCard = ({ theme, cardConfigs, width = 4 }) => {
    const cellStyle = { borderBottom: 'none' };

    const getTableRows = (data, indentLevel = 0) => {
        return data.map(({ name, value, title, subValues, subHeader, isLight, isBold }, i) => {
            let valueComponent = <span>{value}</span>;
            if (isLight) {
                valueComponent = <span style={{ color: colors.grey[500] }}>{value}</span>;
            } else if (isBold) {
                valueComponent = <b>{value}</b>;
            } else if (typeof value === 'string' && value.startsWith('-')) {
                valueComponent = <span style={{ color: colors.red[500] }}>{value}</span>;
            }

            return !_.isNil(subHeader) && !_.isEmpty(subHeader) ? (
                <TableRow key={name} style={{ marginTop: theme.spacing.unit, width: '100%' }}>
                    <TableCell style={{ ...cellStyle, fontSize: '16px', width: '100% ' }}>
                        {subHeader}
                        <Divider style={{ marginTop: theme.spacing.unit }} />
                    </TableCell>
                </TableRow>
            ) : (
                <React.Fragment key={name}>
                    <TableRow title={title} style={{ height: 32 }} key={i}>
                        <TableCell style={cellStyle}>
                            <span
                                style={{
                                    marginLeft: theme.spacing.unit * 2 * indentLevel,
                                    textTransform: 'capitalize'
                                }}
                            >
                                {name}
                            </span>
                        </TableCell>
                        <TableCell
                            align="right"
                            style={cellStyle}
                            data-cy={`admin-dashboard-${name.replace(/[, ]+/g, '-').trim()}`}
                        >
                            {valueComponent}
                        </TableCell>
                    </TableRow>
                    {!_.isNil(subValues) && getTableRows(subValues, indentLevel + 1)}
                </React.Fragment>
            );
        });
    };

    return (
        <Grid item xs={12} md={width}>
            <Paper style={{ padding: theme.spacing.unit * 2, height: '100%' }}>
                <Grid container spacing={theme.spacing.unit * 2}>
                    <Grid item style={{ flexGrow: 1 }}>
                        {cardConfigs.map((config, idx) => {
                            let icon;
                            if (typeof config.icon === 'string') {
                                icon = <Icon>{config.icon}</Icon>;
                            } else {
                                icon = config.icon;
                            }
                            return (
                                <React.Fragment key={idx}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            marginBottom: theme.spacing.unit,
                                            marginTop: idx > 0 ? theme.spacing.unit * 2 : 0
                                        }}
                                    >
                                        <div style={{ marginRight: theme.spacing.unit * 2 }}>
                                            <Avatar
                                                style={{
                                                    marginTop: theme.spacing.unit,
                                                    backgroundColor: colors[config.color][500]
                                                }}
                                            >
                                                {icon}
                                            </Avatar>
                                        </div>
                                        <div style={{ flex: 1 }}>
                                            <Typography variant="h6">{config.title}</Typography>
                                            <Divider
                                                style={{
                                                    marginTop: theme.spacing.unit / 2,
                                                    marginBottom: theme.spacing.unit
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <Table padding="none">
                                        <TableBody>{config.data && getTableRows(config.data)}</TableBody>
                                    </Table>
                                </React.Fragment>
                            );
                        })}
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
};

export default withTheme()(MultiIconCard);
