import React, { useContext, useEffect, useRef, useMemo } from 'react';

import _ from 'lodash';
import moment from 'moment-timezone';
import { _bulk, _pickup, _user } from 'std';
import { getQueryStringValue } from 'utils/query';

import { deviceHelper, getCustomerFirstNameAndLastInitial, formatAsCurrency } from 'utils/misc';

import { getDistanceFromLatLonInKm } from 'utils/latlngFunctions';
import bulkHelper from 'helpers/bulkHelper';
// npm components
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { withTheme } from '@material-ui/core/styles';

import HistoryListItem from './HistoryListItem';
import HistoryBulkListItem from './HistoryBulkListItem';
import EditNumberOfBagsDialog from './EditNumberOfBagsDialog';
import EditNumberOfBagsDialogV2 from './EditNumberOfBagsDialogV2';
import EditNoteDialog from './EditNoteDialog';
import EditAdjustmentReasonDialog from './EditAdjustmentReasonDialog';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

import * as colors from '@material-ui/core/colors';
import {
    Paper,
    Grid,
    FormControl,
    List,
    Divider,
    Select,
    MenuItem,
    InputLabel,
    OutlinedInput,
    Tooltip,
    Icon
} from '@material-ui/core';

import { Typography, IconButton, Button } from '@material-ui/core';

import { Icon as MDIcon } from '@mdi/react';
import { mdiClose, mdiPlus, mdiCheckboxBlankCircle, mdiAlertOutline, mdiAlertDecagram } from '@mdi/js';

import ConfirmDialogContext from 'components/Dialogs/Confirm/ConfirmDialogContext';
import CharitySelectDialog from 'components/CharitySelect/CharitySelectDialog';
import CharityInfoDialog from 'components/CharityInfoDialog/CharityInfoDialog';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

import ImageViewer from 'components/ImageUploads/ImageViewer';
import { ROLES, S3_FALLBACK_IMAGE } from '../../../constants';

function BulkInfoAndHistoryPanel({
    theme,
    bulk,
    customerHistory,
    operator,
    history,
    currentCollector,
    adminView,
    collectors,
    disableEditing,
    disclaimer,
    allBulksFromSameCustomer,
    inProgress,
    countTotal,
    totalCountFormAmount,
    commodityColors,
    onChangeCollector,
    onImageDialog,
    onViewBulk,
    onCreateBulk,
    http,
    resetTimeoutCount,
    handleRemoveCharity,
    handleOpenLAFImageDialog,
    reportOptions,
    rolePermissions,
    setImages,
    hideCustomerIdentification,
    commodities,
    handleSetCharity,
    onSnackbar,
    refreshBulk,
    adjustmentConfig,
    handleUpdateAdjustmentReason,
    addEmptySession,
    handleUpdateReferenceNote,
    allRenderList,
    overdueWindow,
    width
}) {
    const historyItemRefs = useRef({});
    const warnAction = useContext(ConfirmDialogContext);
    const { lang } = useContext(LocalizationContext);
    const isSmallScreen = isWidthDown('xs', width);

    const skuTypes = {};
    allBulksFromSameCustomer.forEach(bulk => {
        skuTypes[bulk.skuType] = bulk.skuType;
    });

    const [lostAndFoundBulks, setLostAndFoundBulks] = React.useState([]);
    const [allLostAndFoundAmount, setAllLostAndFoundAmount] = React.useState(0);
    const [selectedFilterTypes, setSelectedFilterTypes] = React.useState([
        ...Object.keys(skuTypes),
        'reports',
        'complaints'
    ]);
    const [historyItems, setHistoryItems] = React.useState([]);
    const [filteredBulks, setFilteredBulks] = React.useState([]);
    const [processedLabels, setProcessedLabels] = React.useState([]);
    const [allCharities, setAllCharities] = React.useState([]);
    const [charitySelectDialogOpen, setCharitySelectDialogOpen] = React.useState(false);
    const [charityInfoDialogOpen, setCharityInfoDialogOpen] = React.useState(false);
    const [charityInfo, setCharityInfo] = React.useState('');

    const [numberOfReports, setNumberOfReports] = React.useState(0);
    const [complaintText, setComplaintText] = React.useState(['No Complaints!']);
    const [reportText, setReportText] = React.useState(['No Issues!']);

    const [showPayloadEditDialog, setShowPayloadEditDialog] = React.useState(false);
    const [showEditNoteDialog, setShowEditNoteDialog] = React.useState(false);
    const [showEditAdjustmentReasonDialog, setShowEditAdjustmentReasonDialog] = React.useState(false);

    const [visibleItems, setVisibleItems] = React.useState([]);
    const [hasMore, setHasMore] = React.useState(true);
    const [batchSize, setBatchSize] = React.useState(5);

    const [growthPlanEnabled, setGrowthPlanEnabled] = React.useState(true);

    const handleUpdateCommodityUOM = async (commodityAmount, commoditiesProcessed) => {
        const res = await http.postJSON(
            `/bulks/${bulk._id}/updateCommodityAmount`,
            { commodityAmount, commoditiesProcessed, updateOnly: true },
            true
        );
        if (res.ok) {
            onSnackbar(`Updated total ${bulk.commodityUOM}s!`);
            setShowPayloadEditDialog(false);

            refreshBulk(bulk._id);
        } else {
            onSnackbar(res.errorMessage, 'error');
        }
    };

    useEffect(() => {
        fetchCharities();
    }, []);

    useEffect(() => {
        let allReportCodes = [];
        let allComplaintDescriptions = [];

        visibleItems.forEach(bulk => {
            let customerReportCodes = _.get(bulk, 'payloadIssues.issues', []);
            let driverReportCodes = _.get(bulk, 'driverIssues.issues', []);
            allReportCodes = [...allReportCodes, ...customerReportCodes, ...driverReportCodes];

            if (_.get(bulk, 'customerIssues.customerHasIssue', false)) {
                let complaintDescription = _.get(bulk, 'customerIssues.issueDescription', '');
                if (complaintDescription) allComplaintDescriptions.push(complaintDescription);
            }
        });

        let codeCountMap = _.countBy(allReportCodes);

        let newReportText = Object.entries(codeCountMap)
            .map(([code, count]) => {
                let reportObject = _.find(reportOptions, element => element.code === code);
                return reportObject ? `${count}x${reportObject.label}` : null;
            })
            .filter(Boolean);

        let totalReports = Object.values(codeCountMap).reduce((sum, count) => sum + count, 0);

        setReportText(newReportText);
        setNumberOfReports(totalReports);
        setComplaintText(allComplaintDescriptions);
    }, [visibleItems]);

    useEffect(() => {
        const newSkuTypes = {};
        allBulksFromSameCustomer.forEach(bulk => {
            newSkuTypes[bulk.skuType] = bulk.skuType;
        });
        setSelectedFilterTypes([...Object.keys(newSkuTypes), 'reports', 'complaints']);
    }, [allBulksFromSameCustomer]);

    useEffect(() => {
        let newFilterBulks = allBulksFromSameCustomer.filter(bulk => {
            if (selectedFilterTypes.includes('reports') && bulkHelper.isReported(bulk)) {
                return true;
            }

            if (selectedFilterTypes.includes('complaints') && bulkHelper.hasCustomerIssue(bulk)) {
                return true;
            }

            if (
                !bulkHelper.hasCustomerIssue(bulk) &&
                !bulkHelper.isReported(bulk) &&
                selectedFilterTypes.includes(bulk.skuType)
            ) {
                return true;
            }

            return false;
        });

        setFilteredBulks(newFilterBulks);
    }, [allBulksFromSameCustomer, selectedFilterTypes]);

    useEffect(() => {
        if (_.isEmpty(customerHistory) || !selectedFilterTypes.includes('beverage')) {
            setHistoryItems(filteredBulks.map(bulk => ({ ...bulk, isBulk: true, sortDate: bulk.createdAt })));
        } else {
            const combinedHistoryItems = [
                ...filteredBulks.map(bulk => ({ ...bulk, isBulk: true, sortDate: bulk.createdAt })),
                ...customerHistory.map(hist => ({ ...hist, sortDate: hist.date }))
            ];

            combinedHistoryItems.sort((a, b) => new Date(b.sortDate) - new Date(a.sortDate));

            setHistoryItems(combinedHistoryItems);
        }
    }, [filteredBulks, customerHistory]);

    const launchGoogleMaps = () => {
        const url = `https://www.google.com/maps/place/?q=place_id:${bulk.pickup.location.place_id}`;
        if (deviceHelper.AndroidCordova()) {
            window.open(url, '_system');
        } else if (deviceHelper.iOSCordova()) {
            window.open(url, '_system');
        } else {
            window.open(url, '_blank');
        }
    };

    const bulkImages = _bulk.getPickupOrDropOffPhotoURL(bulk || {});
    const sortedBulkImages = _bulk.sortBulkImages(bulkImages, _.get(bulk, 'skuType')) || [S3_FALLBACK_IMAGE];

    let dropoffDistance = 'N/A';
    if (_bulk.isQuickDrop(bulk) && !_.isNil(bulk.customerLocation) && !_.isNil(bulk.dropLocation)) {
        dropoffDistance = `${(
            getDistanceFromLatLonInKm(
                bulk.dropLocation.location.lat,
                bulk.dropLocation.location.lng,
                bulk.customerLocation.lat,
                bulk.customerLocation.lng
            ) * 1000
        ).toFixed(2)}m`;
    }
    const heightOffset = theme.spacing.unit;

    useEffect(() => {
        setTimeout(() => {
            if (!_.isNil(_.get(historyItemRefs.current, bulk._id))) {
                _.get(historyItemRefs.current, bulk._id).scrollIntoView({
                    block: 'center'
                });
            }
        }, 200);
    }, [filteredBulks, bulk]);

    useEffect(() => {
        if (isLostAndFound(bulk)) {
            getLostAndFoundBulks();
        }
    }, [bulk]);
    useEffect(() => {
        if (isLostAndFound(bulk)) {
            getAllLostAndFoundAmount();
        }
    }, [lostAndFoundBulks]);
    useEffect(() => {
        (async () => {
            const res = await http.getJSON('/config/charityTaskConfiguration');
            if (res.ok) {
                setGrowthPlanEnabled(res.data.config.growthPlanEnabled);
            }
        })();
    }, []);
    async function getLostAndFoundBulks() {
        let res = await http.getJSON(`/bulks/getLostAndFoundBulks/${operator._id}`);
        if (res.ok) {
            let newLostAndFoundBulks = res.data.bulks;
            setLostAndFoundBulks(newLostAndFoundBulks);
        }
    }
    function getAllLostAndFoundAmount() {
        let total = 0;
        for (let i = 0; i < lostAndFoundBulks.length; i++) {
            const historyBulk = lostAndFoundBulks[i];

            const bulkSkus = _.groupBy(_.get(historyBulk, 'rates.rates', []), 'skuType');
            const { grandTotal } = bulkHelper.getBulkCounterFinancials(historyBulk, bulkSkus);

            total += grandTotal;
        }
        setAllLostAndFoundAmount(total);
    }

    useEffect(() => {
        if (!_.isNil(historyItems) && !_.isEmpty(historyItems)) {
            const newBatch = _.take(historyItems, batchSize); //take 5 more history items
            loadMoreItems(_.get(_.last(newBatch), 'datePickedUp', '2000-10-09T17:23:55.930Z')); //use the date of the last item in the batch to load more items
        }
    }, [historyItems, bulk, batchSize]);

    useEffect(() => {
        if (!_.isNil(historyItems) && !_.isEmpty(historyItems)) {
            const labels = historyItems.flatMap(item => (item.countingSessions || []).map(session => session.bagtag));
            setProcessedLabels(labels);
        }
    }, [historyItems]);

    const loadMoreItems = cutoffDate => {
        if (_.isEmpty(historyItems)) {
            return;
        }

        const newOrders = historyItems.filter(item =>
            item.isBulk ? item.datePickedUp >= cutoffDate : item.sortDate >= cutoffDate
        );
        if (newOrders.length > 0) {
            setVisibleItems(newOrders);

            setHasMore(true);
        } else {
            setHasMore(false);
        }
        if (batchSize >= historyItems.length) {
            setHasMore(false);
        }
    };

    const handleLoadPressed = () => {
        setBatchSize(prevBatchSize => prevBatchSize + 5);
    };

    const fetchCharities = async () => {
        const res = await http.getJSON('/charities/all');
        if (res.ok) {
            setAllCharities(_.get(res, 'data.charities', []));
        }
    };

    const handleInfoDialog = (state, _id) => () => {
        setCharityInfoDialogOpen(state);
        setCharityInfo(_.find(allCharities, charity => charity._id === _id));
    };

    const handleCharityPreferred = (charityId, subdivision) => e => {
        handleSetCharity({ _id: charityId });
        setCharitySelectDialogOpen(false);
    };

    return (
        <Grid item md={4} xs={12} style={{ height: '100%', paddingBottom: 'env(safe-area-inset-bottom)' }}>
            <CharitySelectDialog
                open={charitySelectDialogOpen}
                charities={allCharities}
                onChange={handleCharityPreferred}
                onInfoDialog={handleInfoDialog}
                onClose={() => {
                    setCharitySelectDialogOpen(false);
                }}
                hideSubdivisions={true}
                regionToFilterFeatured={null}
                growthPlanEnabled={growthPlanEnabled}
            />
            <CharityInfoDialog open={charityInfoDialogOpen} charity={charityInfo} onClose={handleInfoDialog(false)} />
            <Paper
                elevation={1}
                style={{
                    padding: theme.spacing.unit,
                    marginBottom: theme.spacing.unit * 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: isSmallScreen ? 'auto' : `calc(37% - ${heightOffset / 2}px)`
                }}
            >
                <Grid container>
                    <Grid item sm={7} xs={12}>
                        <Typography variant="h6">
                            {_.capitalize(bulkHelper.getBulkTypeFormatted(bulk))}
                            {!_.isNil(bulk.owner) && !hideCustomerIdentification && (
                                <>
                                    {' '}
                                    for{' '}
                                    <span
                                        style={{ color: theme.palette.linkColor, cursor: 'pointer' }}
                                        onClick={() => {
                                            if (
                                                operator.accountType === 'System Administrator' ||
                                                ROLES.includes(operator.accountType)
                                            ) {
                                                if (deviceHelper.AndroidCordova() || deviceHelper.iOSCordova()) {
                                                    history.push(`/customers/${bulk.owner._id}`);
                                                } else {
                                                    window.open(
                                                        `${process.env.REACT_APP_ORIGIN_URL}/customers/${
                                                            bulk.owner._id
                                                        }`,
                                                        '_blank'
                                                    );
                                                }
                                            }
                                        }}
                                    >
                                        {getCustomerFirstNameAndLastInitial(bulk.owner)}
                                    </span>
                                </>
                            )}
                        </Typography>
                    </Grid>
                    <Grid item sm={5} xs={12}>
                        <FormControl style={{ maxWidth: '100%' }}>
                            <Select
                                value={currentCollector._id}
                                onChange={onChangeCollector}
                                disabled={
                                    (!(
                                        adminView ||
                                        (ROLES.includes(operator.accountType) &&
                                            (_.get(rolePermissions, 'users.collectors', false) ||
                                                _.get(rolePermissions, 'accounts.collector', false)))
                                    ) &&
                                        _.get(operator, 'collectors', []).length < 1) ||
                                    inProgress ||
                                    disableEditing
                                }
                                autoWidth
                                inputProps={{
                                    name: 'collector',
                                    id: 'collector-simple'
                                }}
                                data-cy={`bulk-counter-dialog-collector-select`}
                                // input={<OutlinedInput labelWidth={62} name="age" id="outlined-age-simple" />}
                            >
                                {collectors.map((collector, index) => {
                                    return (
                                        <MenuItem
                                            key={collector.name}
                                            value={collector._id}
                                            data-cy={`bulk-counter-dialog-collector-select-${collector.code}`}
                                        >
                                            {collector.code} - {collector.name}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>

                <Divider style={{ marginTop: theme.spacing.unit, marginBottom: theme.spacing.unit }} />
                <Grid container spacing={theme.spacing.unit} style={{ marginBottom: theme.spacing.unit }}>
                    <Grid item xs={6}>
                        <table style={{ width: '100%', marginBottom: theme.spacing.unit }}>
                            <tbody>
                                <tr>
                                    <td style={{ width: '35%' }}>
                                        {operator.accountType != 'System Administrator' && (
                                            <Typography variant="caption">Payload</Typography>
                                        )}
                                        {operator.accountType == 'System Administrator' && (
                                            <Typography
                                                variant="caption"
                                                onClick={() => setShowPayloadEditDialog(true)}
                                                style={{
                                                    color: theme.palette.linkColor,
                                                    textDecoration: 'underline',
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                Payload
                                            </Typography>
                                        )}
                                    </td>
                                    <td>
                                        <div
                                            style={{
                                                flexWrap: 'wrap',
                                                display: 'flex',
                                                flexDirection: 'row'
                                            }}
                                            data-cy="bulk-info-payload-item-list"
                                        >
                                            {allRenderList.length === 0 && (
                                                <Typography variant="caption">Nothing</Typography>
                                            )}
                                            {(allRenderList || []).map(renderItem => {
                                                return (
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            width: 'max-content',
                                                            flexDirection: 'row',
                                                            marginRight: 5
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                alignSelf: 'center',
                                                                justifyContent: 'center',
                                                                height: 16
                                                            }}
                                                        >
                                                            <MDIcon
                                                                path={_.get(renderItem, 'icon', '')}
                                                                size={0.7}
                                                                color={_.get(renderItem, 'color', '')}
                                                            />
                                                        </div>
                                                        <Typography
                                                            style={{
                                                                whiteSpace: 'nowrap',
                                                                alignSelf: 'center',
                                                                justifyContent: 'center',
                                                                fontSize: 11
                                                            }}
                                                        >
                                                            {_.get(renderItem, 'amount', '')}{' '}
                                                            {_.get(renderItem, 'name', '')}
                                                        </Typography>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <Typography variant="caption">
                                            {_bulk.isPickup(bulk) ? 'Driver' : 'Receiver'}
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography variant="caption">
                                            {getCustomerFirstNameAndLastInitial(bulk.receiver)}
                                        </Typography>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Typography variant="caption">Date</Typography>
                                    </td>
                                    <td>
                                        <Typography variant="caption">
                                            {moment(bulk.datePickedUp).format('ddd MMM D YYYY')}
                                        </Typography>
                                    </td>
                                </tr>
                                {!_.isNil(bulk.bookingDate) && (
                                    <tr>
                                        <td>
                                            <Typography variant="caption">Booking Date</Typography>
                                        </td>
                                        <td>
                                            <Typography variant="caption">
                                                {moment(bulk.bookingDate).format('ddd MMM D YYYY')}
                                            </Typography>
                                        </td>
                                    </tr>
                                )}
                                {!_.isNil(bulk.creator) && (
                                    <tr>
                                        <td>
                                            <Typography variant="caption">Creator</Typography>
                                        </td>
                                        <td>
                                            <Typography variant="caption">
                                                {getCustomerFirstNameAndLastInitial(bulk.creator)}
                                            </Typography>
                                        </td>
                                    </tr>
                                )}

                                {_bulk.isPickup(bulk) && (
                                    <>
                                        <tr>
                                            <td>
                                                <Typography variant="caption">Distance</Typography>
                                            </td>
                                            <td>
                                                <Typography variant="caption">
                                                    {_.get(bulk, 'pickup.receiverDistance', 'N/A')}m
                                                </Typography>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Typography variant="caption">Type</Typography>
                                            </td>
                                            <td>
                                                <Typography variant="caption">
                                                    {_.get(bulk, 'pickup.pickupType', 'N/A')}
                                                </Typography>
                                            </td>
                                        </tr>
                                    </>
                                )}
                                {_.get(bulk, 'pickup.pickupType', '').toLowerCase() === 'commercial' &&
                                    !hideCustomerIdentification && (
                                        <tr>
                                            <td>
                                                <Typography variant="caption">Business</Typography>
                                            </td>
                                            <td>
                                                <Typography variant="caption">
                                                    {_.get(bulk, 'pickup.location.businessName', 'N/A')}
                                                </Typography>
                                            </td>
                                        </tr>
                                    )}
                                <tr>
                                    <td>
                                        <Typography variant="caption">Order ID</Typography>
                                    </td>
                                    <td>
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'space-between'
                                            }}
                                        >
                                            <Typography variant="caption">{_bulk.getTruncatedLabel(bulk)}</Typography>
                                        </div>
                                    </td>
                                </tr>

                                {!hideCustomerIdentification && (!_.isNil(bulk.charity) || _bulk.isWalkIn(bulk)) && (
                                    <tr>
                                        <td>
                                            <Typography variant="caption">Charity</Typography>
                                        </td>
                                        <td>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent: 'space-between'
                                                }}
                                            >
                                                {!_.isNil(bulk.charity) && (
                                                    <Typography variant="caption">{bulk.charity.name}</Typography>
                                                )}
                                                {_.isNil(bulk.charity) && (
                                                    <Typography variant="caption">None</Typography>
                                                )}
                                                {!_bulk.isCompleted(bulk) && !_.isNil(bulk.charity) && (
                                                    <IconButton
                                                        data-cy="bulk-info-delete-charity-button"
                                                        style={{ padding: 0 }}
                                                        onClick={() => {
                                                            warnAction(() => {
                                                                handleRemoveCharity();
                                                            }, 'Are you sure you want to remove this charity?');
                                                        }}
                                                    >
                                                        <MDIcon path={mdiClose} size={0.8} color={colors.red[500]} />
                                                    </IconButton>
                                                )}
                                                {!_bulk.isCompleted(bulk) && _.isNil(bulk.charity) && (
                                                    <IconButton
                                                        data-cy="bulk-info-add-charity-button"
                                                        style={{ padding: 0 }}
                                                        onClick={() => {
                                                            setCharitySelectDialogOpen(true);
                                                        }}
                                                    >
                                                        <MDIcon path={mdiPlus} size={0.8} color={colors.blue[500]} />
                                                    </IconButton>
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                )}
                                {_bulk.isPickup(bulk) && !hideCustomerIdentification && (
                                    <tr>
                                        <td>
                                            <Typography variant="caption">Address</Typography>
                                        </td>
                                        <td
                                            style={{
                                                whiteSpace: 'nowrap',
                                                overflow: 'visible',
                                                maxWidth: '0'
                                            }}
                                        >
                                            <Typography
                                                variant="caption"
                                                style={{
                                                    color: theme.palette.linkColor,
                                                    cursor: 'pointer',
                                                    display: 'inline-block'
                                                }}
                                                onClick={launchGoogleMaps}
                                            >
                                                {_bulk.getPickupAddress(bulk)}
                                            </Typography>
                                        </td>
                                    </tr>
                                )}
                                {operator.accountType === 'System Administrator' && (
                                    <tr>
                                        <td>
                                            <Typography
                                                variant="caption"
                                                style={{
                                                    color: theme.palette.linkColor,
                                                    textDecoration: 'underline',
                                                    cursor: 'pointer'
                                                }}
                                                onClick={() => {
                                                    setShowEditNoteDialog(true);
                                                }}
                                            >
                                                Reference Note
                                            </Typography>
                                        </td>
                                        <td
                                            style={{
                                                whiteSpace: 'nowrap',
                                                overflow: 'visible',
                                                maxWidth: '0'
                                            }}
                                        >
                                            <Typography variant="caption" style={{ display: 'inline-block' }}>
                                                {_.get(bulk, 'referenceNote', '')}
                                            </Typography>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'flex-start'
                        }}
                    >
                        {_.get(bulk, 'bulkType', '') === 'adjustment' ? (
                            <div>
                                <Typography
                                    style={{
                                        color: theme.palette.linkColor,
                                        textDecoration: 'underline',
                                        cursor: 'pointer',
                                        fontWeight: 'normal'
                                    }}
                                    variant="subtitle2"
                                    onClick={() => {
                                        setShowEditAdjustmentReasonDialog(true);
                                    }}
                                >
                                    Adjustment Reason:
                                </Typography>
                                <Typography
                                    style={{
                                        color: colors.grey[500]
                                    }}
                                    variant="subtitle2"
                                >
                                    {_.get(bulk, 'adjustmentReason', 'Not Selected')}
                                </Typography>
                            </div>
                        ) : (
                            <ImageViewer
                                displayImages={sortedBulkImages}
                                disableDelete={true}
                                placeholderText={
                                    <Typography
                                        style={{
                                            color: colors.grey[400],
                                            display: 'flex',
                                            width: 150,
                                            height: 140
                                        }}
                                        variant="subtitle2"
                                    >
                                        <span>No photo provided</span>
                                    </Typography>
                                }
                                onImageClick={() => {
                                    if (!_.isEmpty(sortedBulkImages)) {
                                        setImages(sortedBulkImages);
                                        onImageDialog(true);
                                    }
                                }}
                                innerStyles={{
                                    cursor: 'pointer',
                                    overflow: 'hidden',
                                    display: null,
                                    minWidth: 0,
                                    minHeight: 0,
                                    maxWidth: 150,
                                    maxHeight: 140,
                                    margin: 0
                                }}
                                hideScroller={true}
                            />
                        )}
                        {!_.isNil(bulk) && bulk.isLostAndFound && _.isEmpty(bulk.lostAndFoundImages) && (
                            <Button
                                color={'primary'}
                                size={'small'}
                                variant="contained"
                                style={{ margin: theme.spacing.unit }}
                                onClick={() => {
                                    handleOpenLAFImageDialog();
                                }}
                            >
                                {loc('dashboardSetting11', lang)}
                            </Button>
                        )}
                    </Grid>
                </Grid>
                <div style={{ width: '100%' }}>
                    {_bulk.isQuickDrop(bulk) && (
                        <Typography variant="caption">Drop Off Distance: {dropoffDistance}</Typography>
                    )}
                    {isLostAndFound(bulk) && (
                        <Typography variant="caption">
                            Amount in All Lost and Found: {formatAsCurrency(allLostAndFoundAmount)}
                        </Typography>
                    )}
                </div>
            </Paper>
            <Paper
                elevation={1}
                style={{
                    padding: theme.spacing.unit,
                    marginBottom: theme.spacing.unit,
                    display: 'flex',
                    flexDirection: 'column',
                    height: isSmallScreen ? 'auto' : `calc(61% - ${heightOffset / 2}px)`
                }}
            >
                <div
                    style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}
                >
                    <Typography variant="h6" style={{ display: 'flex', alignItems: 'center' }}>
                        {isLostAndFound(bulk) ? 'Lost & Found' : 'History'}
                    </Typography>

                    {!isLostAndFound(bulk) && (
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    flexWrap: 'wrap',
                                    alignItems: 'center'
                                }}
                            >
                                {reportText.length > 0 && (
                                    <span
                                        style={{
                                            background: colors.yellow[400],
                                            padding: '4px 5px',
                                            marginRight: 4,
                                            borderRadius: 4
                                        }}
                                        title={reportText.join('\n')}
                                    >
                                        <span
                                            style={{
                                                fontFamily: theme.typography.fontFamily,
                                                display: 'flex',
                                                alignItems: 'center'
                                            }}
                                            data-cy="bulk-list-item-bulk-type"
                                        >
                                            <MDIcon
                                                path={mdiAlertOutline}
                                                size={'16px'}
                                                color={colors.deepOrange[500]}
                                            />
                                            X{numberOfReports}
                                        </span>
                                    </span>
                                )}
                                {complaintText.length > 0 && (
                                    <span
                                        style={{
                                            background: colors.red[200],
                                            padding: '4px 5px',
                                            marginRight: 4,
                                            borderRadius: 4
                                        }}
                                        title={complaintText.join('\n')}
                                    >
                                        <span
                                            style={{
                                                fontFamily: theme.typography.fontFamily,
                                                display: 'flex',
                                                alignItems: 'center'
                                            }}
                                            data-cy="bulk-list-item-bulk-type"
                                        >
                                            <MDIcon path={mdiAlertDecagram} size={'16px'} color={colors.red[500]} />X
                                            {complaintText.length}
                                        </span>
                                    </span>
                                )}
                                <FormControl style={{ marginLeft: theme.spacing.unit * 2 }}>
                                    <InputLabel htmlFor="skuType">Filters</InputLabel>
                                    <Select
                                        multiple
                                        value={selectedFilterTypes}
                                        onChange={e => {
                                            setSelectedFilterTypes(e.target.value);
                                        }}
                                        style={{ width: 130 }}
                                        inputProps={{
                                            name: 'Commodities',
                                            id: 'skuType'
                                        }}
                                        renderValue={selectedFilterTypes => (
                                            <div
                                                style={{
                                                    width: 110,
                                                    overflow: 'hidden',
                                                    'text-overflow': 'ellipsis',
                                                    'white-space': 'nowrap'
                                                }}
                                            >
                                                {selectedFilterTypes.join(', ')}
                                            </div>
                                        )}
                                    >
                                        {Object.keys(skuTypes).map(skuType => (
                                            <MenuItem key={skuType} value={skuType}>
                                                <MDIcon
                                                    path={mdiCheckboxBlankCircle}
                                                    color={_.get(commodityColors, skuType, colors.grey[500])}
                                                    size={0.4}
                                                />
                                                <span style={{ marginLeft: 2 }}>{_.startCase(skuType)}</span>
                                            </MenuItem>
                                        ))}
                                        <MenuItem key="reports" value="reports">
                                            <MDIcon path={mdiCheckboxBlankCircle} color={colors.red[500]} size={0.4} />
                                            <span style={{ marginLeft: 2 }}>Reports</span>
                                        </MenuItem>
                                        <MenuItem key="complaints" value="complaints">
                                            <MDIcon
                                                path={mdiCheckboxBlankCircle}
                                                color={colors.orange[500]}
                                                size={0.4}
                                            />
                                            <span style={{ marginLeft: 2 }}>Complaints</span>
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                    )}
                </div>
                <Divider style={{ marginTop: theme.spacing.unit, marginBottom: theme.spacing.unit / 2 }} />
                <List
                    style={{
                        flex: `1 1 auto`,
                        overflowY: 'scroll',
                        overflowX: 'hidden'
                    }}
                >
                    {isLostAndFound(bulk) &&
                        lostAndFoundBulks.map((historyBulk, index) => (
                            <span
                                data-cy={`bulk-history-item-${index}`}
                                key={`bulk-history-item-${index}`}
                                ref={el => _.set(historyItemRefs.current, historyBulk._id, el)}
                            >
                                <HistoryBulkListItem
                                    commodityColors={commodityColors}
                                    bulk={bulk}
                                    historyBulk={historyBulk}
                                    grandTotal={countTotal}
                                    totalCountFormAmount={totalCountFormAmount}
                                    onViewBulk={onViewBulk}
                                    resetTimeoutCount={resetTimeoutCount}
                                    reportOptions={reportOptions}
                                    setImages={setImages}
                                    onImageDialog={onImageDialog}
                                    hideCustomerIdentification={hideCustomerIdentification}
                                    commodities={commodities}
                                    overdueWindow={overdueWindow}
                                />
                            </span>
                        ))}
                    {!isLostAndFound(bulk) &&
                        !_.isNil(bulk.owner) &&
                        visibleItems.map((historyItem, index) => {
                            return (
                                <span
                                    data-cy={`bulk-history-item-${index}`}
                                    key={`bulk-history-item-${index}`}
                                    ref={el => _.set(historyItemRefs.current, historyItem._id, el)}
                                >
                                    {historyItem.isBulk && (
                                        <HistoryBulkListItem
                                            commodityColors={commodityColors}
                                            bulk={bulk}
                                            historyBulk={historyItem}
                                            grandTotal={countTotal}
                                            totalCountFormAmount={totalCountFormAmount}
                                            onViewBulk={onViewBulk}
                                            resetTimeoutCount={resetTimeoutCount}
                                            reportOptions={reportOptions}
                                            setImages={setImages}
                                            onImageDialog={onImageDialog}
                                            hideCustomerIdentification={hideCustomerIdentification}
                                            commodities={commodities}
                                            overdueWindow={overdueWindow}
                                        />
                                    )}
                                    {!historyItem.isBulk && (
                                        <HistoryListItem historyItem={historyItem} processedLabels={processedLabels} />
                                    )}
                                </span>
                            );
                        })}

                    {!isLostAndFound(bulk) && _.isNil(bulk.owner) && (
                        <Typography>Attach to a customer to see their history</Typography>
                    )}
                    {hasMore ? (
                        <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '10px 0' }}>
                            <Button
                                onClick={() => handleLoadPressed()}
                                variant="contained"
                                style={{ marginRight: '10px' }}
                            >
                                Load More
                            </Button>
                        </div>
                    ) : (
                        <Typography style={{ margin: '10px auto', textAlign: 'center' }}>
                            You’ve reached the end of the history.
                        </Typography>
                    )}
                </List>
            </Paper>
            <EditNumberOfBagsDialogV2
                theme={theme}
                open={showPayloadEditDialog}
                bulk={bulk}
                onClose={() => setShowPayloadEditDialog(false)}
                commodities={commodities}
                refreshBulk={refreshBulk}
                http={http}
                // setIdleCountdown={() => {}}
                // resetTimeoutCount={() => {}}
            />
            <EditNoteDialog
                theme={theme}
                open={showEditNoteDialog}
                onClose={() => setShowEditNoteDialog(false)}
                handleSubmit={note => {
                    handleUpdateReferenceNote(note);
                    setShowEditNoteDialog(false);
                }}
                bulk={bulk}
            />
            <EditAdjustmentReasonDialog
                theme={theme}
                open={showEditAdjustmentReasonDialog}
                bulk={bulk}
                onClose={() => setShowEditAdjustmentReasonDialog(false)}
                adjustmentConfig={adjustmentConfig}
                handleUpdateAdjustmentReason={handleUpdateAdjustmentReason}
                addEmptySession={addEmptySession}
            />
        </Grid>
    );
}
export default withMobileDialog({ breakpoint: 'xs' })(withTheme()(BulkInfoAndHistoryPanel));

function isLostAndFound(bulk) {
    if (bulk.isLostAndFound && _.isNil(bulk.owner)) return true;
    return false;
}
