import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash';

import RatingPlaformsList from 'components/CRUDTables/RatingPlatformsList';

import { validate } from 'utils/validate';

import {
    Button,
    Paper,
    Typography,
    Switch,
    TextField,
    InputAdornment,
    withTheme,
    BottomNavigation,
    BottomNavigationAction,
    IconButton,
    Tooltip
} from '@material-ui/core';

import { Icon as MDIcon } from '@mdi/react';
import { mdiFileLink } from '@mdi/js';

import useCollectorDropDown from 'components/DropDownSelects/Collectors/hooks/useCollectorDropDown';
import CollectorsSelectDropdown from 'components/DropDownSelects/Collectors';

import HttpContext from 'utils/contexts/HttpContext';

import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import SnackbarContext from 'components/CustomSnackbar/SnackbarContext';
import LocalizationContext from 'utils/contexts/LocalizationContext';

function RatingPlatforms({ theme, width }) {
    const http = useContext(HttpContext);
    const onSnackbar = useContext(SnackbarContext);
    const { lang } = useContext(LocalizationContext);

    const [tab, setTab] = useState(0);
    const [ratingPlatforms, setRatingPlatforms] = useState([]);
    const [collectors, setCollectors] = useState([]);
    const [loading, setLoading] = useState(false);
    //const [selectedCollectorId, setSelectedCollectorId] = useState('ALL');
    const [switchStates, setSwitchStates] = useState([]);
    const [textFieldStates, setTextFieldStates] = useState([]);

    const {
        showAll,
        collector: selectedCollectorId,
        handleChange: handleChangeCollectorFilter,
        collectorObj
    } = useCollectorDropDown({
        collectors,
        saveStateInURL: true,
        showAll: false
    });

    const handleRefreshRatingPlatforms = async () => {
        setLoading(true);
        const resRatingPlatforms = await http.getJSON('/ratingPlatforms');
        if (resRatingPlatforms.ok) {
            setRatingPlatforms(resRatingPlatforms.data.ratingPlatforms);
        }
        setLoading(false);
    };

    const handleRefreshCollectors = async () => {
        setLoading(true);
        const resCollectors = await http.getJSON('/collectors');
        if (resCollectors.ok) {
            setCollectors(resCollectors.data.collectors);
        }
        setLoading(false);
    };

    const handlePlatformEnabledChanged = (e, i) => {
        let switchStatesCopy = _.cloneDeep(switchStates);
        switchStatesCopy[i] = e.target.checked;
        setSwitchStates(switchStatesCopy);
        if (e.target.checked && _.isEmpty(textFieldStates[i])) {
            let textFieldStatesCopy = _.cloneDeep(textFieldStates);
            textFieldStatesCopy[i] = _.get(ratingPlatforms[i], 'defaultUrl', '');
            setTextFieldStates(textFieldStatesCopy);
        }
    };

    const updateRatingPlatformUrl = (e, i) => {
        let textFieldStatesCopy = _.cloneDeep(textFieldStates);
        textFieldStatesCopy[i] = e.target.value;
        setTextFieldStates(textFieldStatesCopy);
    };

    const handleSubmit = async () => {
        let ratingPlatformConfig = [];
        ratingPlatforms.forEach((ratingPlatform, i) => {
            if (switchStates[i]) {
                ratingPlatformConfig.push({ ratingPlatform: ratingPlatform._id, url: textFieldStates[i] });
            }
        });

        const _ids = selectedCollectorId === 'all' ? collectors.map(collector => collector._id) : [selectedCollectorId];

        const res = await http.post('/collectors/bulkUpdateRatingPlatforms', {
            _ids,
            ratingPlatforms: ratingPlatformConfig
        });
        if (res.ok) {
            await handleRefreshCollectors();
            onSnackbar('Succefully updated collectors');
        }
    };

    const handleApplyDefaultUrlToPlatform = (ratingPlatform, i) => {
        let textFieldStatesCopy = _.cloneDeep(textFieldStates);
        textFieldStatesCopy[i] = _.get(ratingPlatform, 'defaultUrl', '');
        setTextFieldStates(textFieldStatesCopy);
        let switchStatesCopy = _.cloneDeep(switchStates);
        switchStatesCopy[i] = true;
        setSwitchStates(switchStatesCopy);
    };

    const handleApplyDefaultUrlToAll = () => {
        setSwitchStates(ratingPlatforms.map(ratingPlatform => true));
        setTextFieldStates(ratingPlatforms.map(ratingPlatform => _.get(ratingPlatform, 'defaultUrl', '')));
    };

    const errors = textFieldStates.map((url, i) => {
        if (switchStates[i]) {
            if (_.isEmpty(url)) {
                return 'You must enter a value';
            }
            const urlValidation = validate(['url'], url, lang);
            if (urlValidation.fail) {
                return urlValidation.reason;
            }
        }
        return null;
    });

    const hasError = _.some(errors, error => !_.isNil(error));

    const isSmUp = isWidthUp('sm', width);

    useEffect(() => {
        if (selectedCollectorId === 'all') {
            setSwitchStates(ratingPlatforms.map(ratingPlatform => true));
            setTextFieldStates(ratingPlatforms.map(ratingPlatform => _.get(ratingPlatform, 'defaultUrl', '')));
        } else {
            const selectedCollector = _.find(collectors, { _id: selectedCollectorId });
            const collectorRatingPlatforms = _.get(selectedCollector, 'ratingPlatforms', []);
            setSwitchStates(
                ratingPlatforms.map(ratingPlatform =>
                    _.some(collectorRatingPlatforms, value => value.ratingPlatform === ratingPlatform._id)
                )
            );
            setTextFieldStates(
                ratingPlatforms.map(ratingPlatform => {
                    const ratingPlatformConfig = _.find(
                        collectorRatingPlatforms,
                        value => value.ratingPlatform === ratingPlatform._id
                    );
                    return _.get(ratingPlatformConfig, 'url', '');
                })
            );
        }
    }, [ratingPlatforms, selectedCollectorId]);

    useEffect(() => {
        handleRefreshRatingPlatforms();
        handleRefreshCollectors();
    }, []);

    const collectorRatingPlatforms = (
        <Paper style={{ padding: theme.spacing.unit }}>
            <div
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    margin: theme.spacing.unit * 2
                }}
            >
                <Typography variant="h6">Collector Urls</Typography>
                <div>
                    <Button
                        variant="outlined"
                        size="small"
                        color="primary"
                        style={{ marginRight: theme.spacing.unit }}
                        onClick={handleApplyDefaultUrlToAll}
                    >
                        <MDIcon path={mdiFileLink} size={1} color={theme.palette.primary.main} /> Apply defaults to this
                        collector
                    </Button>
                    <CollectorsSelectDropdown
                        showAll={showAll}
                        id="collector-dropdown"
                        value={selectedCollectorId || 'all'}
                        collectors={collectors}
                        style={{ width: 250, marginRight: theme.spacing.unit }}
                        onChange={handleChangeCollectorFilter}
                    />
                    <Button variant="contained" color="primary" onClick={handleSubmit} disabled={hasError}>
                        Save
                    </Button>
                </div>
            </div>
            <div style={{ overflow: 'auto', flexGrow: 1 }}>
                {ratingPlatforms.map((ratingPlatform, i) => (
                    <div
                        key={ratingPlatform._id}
                        style={{ marginLeft: theme.spacing.unit, marginRight: theme.spacing.unit }}
                    >
                        {/*<span
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                            }}
                        >
                            <Typography>{ratingPlatform.name}</Typography>
                        </span>*/}
                        <TextField
                            value={_.get(textFieldStates, `[${i}]`, '')}
                            onChange={e => updateRatingPlatformUrl(e, i)}
                            label={ratingPlatform.name}
                            margin="normal"
                            variant="outlined"
                            fullWidth
                            //disabled={!_.get(switchStates, `[${i}]`, false)}
                            helperText={!_.isNil(errors[i]) ? errors[i] : null}
                            error={!_.isNil(errors[i])}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Tooltip title="Apply default url">
                                            <IconButton
                                                onClick={() => handleApplyDefaultUrlToPlatform(ratingPlatform, i)}
                                            >
                                                <MDIcon
                                                    path={mdiFileLink}
                                                    size={1}
                                                    color={theme.palette.primary.main}
                                                />
                                            </IconButton>
                                        </Tooltip>
                                        <Switch
                                            color="primary"
                                            onChange={e => handlePlatformEnabledChanged(e, i)}
                                            checked={_.get(switchStates, `[${i}]`, false)}
                                        />
                                    </InputAdornment>
                                ),
                                disabled: !_.get(switchStates, `[${i}]`, false)
                            }}
                        />
                    </div>
                ))}
            </div>
        </Paper>
    );

    const splitView = (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ marginRight: theme.spacing.unit }}>
                <RatingPlaformsList
                    ratingPlatforms={ratingPlatforms}
                    onReloadRatingPlatforms={handleRefreshRatingPlatforms}
                    loading={loading}
                />
            </div>
            <div style={{ flex: 1 }}>{collectorRatingPlatforms}</div>
        </div>
    );

    const tabbedView = (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            {tab === 0 && (
                <RatingPlaformsList
                    ratingPlatforms={ratingPlatforms}
                    onReloadRatingPlatforms={handleRefreshRatingPlatforms}
                    loading={loading}
                />
            )}
            {tab === 1 && collectorRatingPlatforms}
        </div>
    );

    return (
        <div style={{ padding: theme.spacing.unit }}>
            <div style={{ marginBottom: !isSmUp && 56 }}>{isSmUp ? splitView : tabbedView}</div>
            {!isSmUp && (
                <BottomNavigation
                    value={tab}
                    onChange={(event, value) => setTab(value)}
                    showLabels
                    style={{ position: 'fixed', left: 0, bottom: 0, display: 'flex', width: '100%', zIndex: 10000 }}
                >
                    <BottomNavigationAction label="All Platforms" />
                    <BottomNavigationAction label="Collector Urls" />
                </BottomNavigation>
            )}
        </div>
    );
}

export default withWidth()(withTheme()(RatingPlatforms));
