import React from 'react';

import { withTheme } from '@material-ui/core/styles';
import RegionsTable from 'components/CRUDTables/RegionsTable';

function Regions(props) {
    return <RegionsTable {...props} />;
}

export default withTheme()(Regions);
