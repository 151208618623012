import { useEffect, useState } from 'react';
import { _user } from 'std';

import _ from 'lodash';

const useGetMapMetaData = ({ http, operator, filters, setFilters, allPickupSkuTypes, collector }) => {
    const [fetchingData, setFetchingData] = useState(true);
    const [zones, setZones] = useState([]);
    const [charities, setCharities] = useState([]);
    const [driversAvailable, setDriversAvailable] = useState([]);
    const [collectorsAvailable, setCollectorsAvailable] = useState([]);
    const [commoditiesAvailable, setCommoditiesAvailable] = useState([]);

    const reloadZones = async () => {
        const zonesRes = await http.getJSON(`/system/zones`);
        if (zonesRes.ok) setZones(zonesRes.data.zones);
        return zonesRes;
    };

    useEffect(() => {
        if (setFilters && filters) {
            setFilters(filters => ({
                ...filters,
                commodityTypes: commoditiesAvailable.map(({ skuType }) => skuType)
            }));
        }
    }, [allPickupSkuTypes, collectorsAvailable]);

    useEffect(() => {
        (async () => {
            const commoditiesRes = await http.getJSON('/commodities/getAllCommodities');
            if (commoditiesRes.ok) {
                setCommoditiesAvailable(commoditiesRes.data.collectionData);
            }

            if (_user.isSystemAdmin(operator) || _user.isInternalRole(operator) || _user.isCollectorAdmin(operator)) {
                const zonesRes = await reloadZones();

                const charitiesRes = await http.getJSON(`/charities/all`);
                if (charitiesRes.ok) setCharities(charitiesRes.data.charities);

                const driversRes = await http.getJSON(
                    `/users/getAllDrivers/${
                        _user.isCollectorAdmin(operator) && _.get(operator, 'collectors', []).length <= 1
                            ? collector
                                ? collector._id
                                : operator.collector._id
                            : ''
                    }`
                );
                if (driversRes.ok) setDriversAvailable(driversRes.data.drivers);

                const collectorsRes = await http.getJSON('/collectors');
                if (collectorsRes.ok) setCollectorsAvailable(collectorsRes.data.collectors);

                if (zonesRes.ok && charitiesRes.ok && driversRes.ok && collectorsRes.ok) {
                    setFetchingData(false);
                }
            }
        })();
    }, []);

    return {
        fetchingData,
        zones,
        charities,
        driversAvailable,
        collectorsAvailable,
        commoditiesAvailable,
        reloadZones
    };
};

export default useGetMapMetaData;
