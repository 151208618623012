import moment from 'moment-timezone';
import _ from 'lodash';

export default {
    isComplete: charityTask => {
        return (
            charityTask.complete &&
            !_.isNil(charityTask.dateExpires) &&
            moment(charityTask.dateExpires).isAfter(new Date())
        );
    },
    isExpired: charityTask => {
        return (
            charityTask.complete &&
            !_.isNil(charityTask.dateExpires) &&
            moment(charityTask.dateExpires).isBefore(new Date())
        );
    },
    isRejected: charityTask => {
        return (
            charityTask.complete && 
            _.get(charityTask, 'lastSubmission.rejected', false)
        );
    },
    needsVerification: charityTask => {
        return !_.isNil(charityTask.lastSubmission) && !charityTask.lastSubmission.reviewed;
    },
    sortyCharitiesByNumberCompletedTasks: function(charities) {
        const isCompleteFunction = this.isComplete;
        const charitiesWithNumberCompleted = charities.map(charity => {
            const numberCompleted = _.filter(charity.charityTasks, charityTask => isCompleteFunction(charityTask))
                .length;
            return { ...charity, numberCompleted };
        });

        return _.sortBy(charitiesWithNumberCompleted, 'numberCompleted').reverse();
    },
    numberOfTasksComplete: function(charityTasks) {
        return _.filter(charityTasks, charityTask => this.isComplete(charityTask)).length;
    },
    numberOfTasksCompletable: function(charityTasks) {
        return _.filter(charityTasks, charityTask => !charityTask.task.notCompletable).length;
    },
    numberOfTasksExpired: function(charityTasks) {
        return _.filter(charityTasks, charityTask => this.isExpired(charityTask)).length;
    },
    getDaysTillExpiration: function(charityTask) {
        if (_.isNil(charityTask.dateExpires)) return null;

        return moment(charityTask.dateExpires).diff(moment(), 'day');
    },
    getTaskLatestExpiration: function(charityTasks) {
        if (_.isEmpty(charityTasks)) return null;

        const charityTasksCopy = _.cloneDeep(charityTasks);

        charityTasksCopy.sort((a, b) => {
            if (_.isNil(a.dateExpires)) return 1;
            if (_.isNil(b.dateExpires)) return -1;

            return moment(a.dateExpires).diff(b.dateExpires, 'days');
        });

        return charityTasksCopy[0];
    }
};
