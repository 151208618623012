import React, { useState, useContext, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';

import { Paper, List, ListItem, ListItemText, withTheme, Typography } from '@material-ui/core';

import HttpContext from 'utils/contexts/HttpContext';

function SavedEmails({ theme }) {
    const http = useContext(HttpContext);

    const [emailList, setEmailList] = useState([]);
    const [selectedEmail, setSelectedEmail] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const res = await http.getJSON('/system/savedEmailList');
            if (res.ok) {
                setEmailList(res.data.emails);
            }
        };

        fetchData();
    }, []);

    return (
        <div style={{ flex: 1, minHeight: 0, padding: theme.spacing.unit * 2, display: 'flex' }}>
            <Paper style={{ display: 'flex', flex: 1, minHeight: 0 }}>
                <span style={{ marginRight: theme.spacing.unit, marginLeft: theme.spacing.unit, overflow: 'auto' }}>
                    <List>
                        {emailList.map((email, idx) => (
                            <ListItem
                                data-cy={`saved-emails-index-${idx}`}
                                key={_.get(email, 'filename', '')}
                                button
                                onClick={() => {
                                    setSelectedEmail(email);
                                }}
                            >
                                <div style={{ display: 'flex', flexDirection: 'column'}}>
                                    <ListItemText
                                        primary={getEmailFromFileName(_.get(email, 'filename', ''))}
                                        primaryTypographyProps={{
                                            'data-cy': `saved-email-${_.kebabCase(
                                                getEmailFromFileName(_.get(email, 'filename', ''))
                                            )}`
                                        }}
                                        secondary={
                                            <>
                                                <Typography
                                                    color="textSecondary"
                                                >
                                                    {getSubjectFromFileName(_.get(email, 'filename', ''))}
                                                </Typography>
                                                <Typography
                                                    color="textSecondary"
                                                    data-cy={`saved-email-date-${moment(_.get(email, 'stat.mtime')).format('DDMMYY')}`}
                                                >
                                                    {!_.isNil(_.get(email, 'stat.mtime')) &&
                                                        moment(_.get(email, 'stat.mtime')).format('h:mm a MMM DD, YYYY')}
                                                </Typography>
                                            </>
                                        }
                                    />
                                </div>
                            </ListItem>
                        ))}
                    </List>
                </span>
                <span
                    style={{ flexGrow: 1, minWidth: 300 }}
                    data-cy={`saved-emails-${_.kebabCase(getEmailFromFileName(_.get(selectedEmail, 'filename', '')))}`}
                >
                    <iframe
                        title="Email"
                        width="100%"
                        height="100%"
                        src={
                            !_.isNil(selectedEmail) &&
                            `${process.env.REACT_APP_API_URL}/emails/${selectedEmail.filename}`
                        }
                    />
                </span>
            </Paper>
        </div>
    );
}

export default withTheme()(SavedEmails);

function getEmailFromFileName(fileName) {
    const startIndex = fileName.indexOf('_', 14) + 1;
    const endIndex = fileName.indexOf('.html');

    if (startIndex !== -1 && endIndex !== -1) {
        return fileName.substring(startIndex, endIndex);
    } else {
        return fileName;
    }
}

function getSubjectFromFileName(fileName) {
    fileName = fileName.replace(/-/g, " ");
    const startIndex = fileName.indexOf('_') + 1;
    const endIndex = fileName.indexOf('_', 14);

    if (startIndex !== -1 && endIndex !== -1) {
        return fileName.substring(startIndex, endIndex);
    } else {
        return fileName;
    }
}
