import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import { withTheme } from '@material-ui/core/styles';

function ServerMaintenanceDialog({ open }) {
    return (
        <Dialog open={open} maxWidth="sm" fullWidth>
            <DialogTitle>Server maintenance</DialogTitle>
            <DialogContent>
                <Typography variant="subtitle1">
                    Hey! Our mobile app is currently undergoing maintenance. You can still use the{' '}
                    <a href={process.env.REACT_APP_ORIGIN_URL}>website</a> to access full functionality.
                </Typography>
            </DialogContent>
        </Dialog>
    );
}

export default withTheme()(ServerMaintenanceDialog);
