import React from 'react';
import { loc } from 'localizations/localizationHandler';

import { Dialog, DialogTitle, DialogContent, DialogActions, Button, withTheme, Typography } from '@material-ui/core';
import { useContext } from 'react';

import LocalizationContext from 'utils/contexts/LocalizationContext';

function CharityDeselectDialog(props) {
    const { open, onConfirm, onCancel, text, lockDonationPreference } = props;
    const { lang } = useContext(LocalizationContext);
    return (
        <Dialog open={open} fullWidth onClose={onCancel}>
            <DialogTitle>
                {lockDonationPreference ? loc('donationPreferenceLocked', lang) : 'Clear donation preference?'}
            </DialogTitle>
            <DialogContent>
                <Typography>{text}</Typography>
            </DialogContent>
            {lockDonationPreference ? (
                <DialogActions>
                    <Button onClick={onCancel}>close</Button>
                </DialogActions>
            ) : (
                <DialogActions>
                    <Button onClick={onCancel}>No</Button>
                    <Button color="primary" onClick={onConfirm}>
                        Yes
                    </Button>
                </DialogActions>
            )}
        </Dialog>
    );
}

export default withTheme()(CharityDeselectDialog);
