import _ from 'lodash';
import { isAUSRegion } from 'utils/misc';

/**
 * Description:
 * Get location data (place_id, lat, lng and description) from an object
 * If data it undefined, return a bare location object
 *
 * @param {Object} obj
 *
 * @returns {Object}
 */

export const getLocationFromObject = obj => {
    return _.get(obj, 'location', {
        place_id: '',
        lat: '',
        lng: '',
        description: ''
    });
};

/**
 * Description:
 * Get an empty location object (place_id, lat, lng and description)
 *
 * @returns {Object}
 */

export const getEmptyLocation = () => {
    return {
        place_id: '',
        lat: '',
        lng: '',
        description: ''
    };
};

/**
 * Description:
 * Check if a location object contains address data
 *
 * @param {Object} location
 *
 * @returns {Boolean}
 */

export const isCompleteAddress = location => {
    if (!_.get(location, 'description') || (!_.get(location, 'lat') && !_.get(location, 'lng'))) {
        return false;
    } else {
        return true;
    }
};

/**
 * Description:
 * Creates location Object
 *
 * @param {String} place_id
 * @param {String} description
 * @param {Number} lat
 * @param {Number} lng
 *
 * @returns {Object}
 */

export const createLocation = (place, description, lat, lng, city = null, province = null, postalCode = null) => {
    const placeTypes = _.get(place, 'types', []);
    const streetAddressTypes = ['street_address', 'premise', 'establishment'];
    const isStreet = _.isEmpty(_.intersection(placeTypes, streetAddressTypes));

    return {
        place_id: _.get(place, 'place_id', ''),
        description: description,
        lat: lat,
        lng: lng,
        city: city,
        province: province,
        postalCode: postalCode,
        isStreet
    };
};

/**
 * Remove Postal Code and Country from Address field.
 * @param {string} address
 * @returns
 */
export const removePostalCodeAndCountryFromAddress = address => {
    if (_.isNil(address)) return address;
    if (isAUSRegion()) {
        return address.replace(/\s[0-9][0-9][0-9][0-9][,]\sAustralia/gm, '').replace(', Australia', '');
    } else {
        return address.replace(/\s[A-VXY][0-9][A-Z]\s[0-9][A-Z][0-9]/gm, '').replace(', Canada', '');
    }
};
