import React, { useContext, useEffect } from 'react';
import _ from 'lodash';

// npm components
import {
    withTheme,
    withMobileDialog,
    Paper,
    Typography,
    FormControl,
    Select,
    MenuItem,
    LinearProgress,
    Button
} from '@material-ui/core';
// helpers
import * as userHelper from 'helpers/userHelper';
// custom components
import CustomCalendar from './CustomCalendar';
import CollectorsSelectDropdown from 'components/DropDownSelects/Collectors';
import DateRangePicker from 'components/DateTimePickersTz/DateRangePicker';
// hooks
import usePickupCalendar from './hooks/usePickupCalendar';
import useCollectorDropDown from 'components/DropDownSelects/Collectors/hooks/useCollectorDropDown';
import OperatorContext from 'utils/contexts/OperatorContext';
import { _user } from 'std';
import { useState } from 'react';

function PickupsCalendar(props) {
    const operator = useContext(OperatorContext);
    const { theme, collectors, history, initialCollector, http } = props;

    const [disableTransporterSelect, setDisableTransporterSelect] = useState(false);
    const [disableProcessorSelect, setDisableProcessorSelect] = useState(false);
    const [allCollectors, setAllCollectors] = useState(collectors);
    const [allCollectorsLoading, setAllCollectorsLoading] = useState(false);

    useEffect(() => {
        (async () => {
            setAllCollectorsLoading(true);
            const res = await http.getJSON('/collectors/all');
            if (res.ok) {
                setAllCollectors(res.data.collectors);
            }
            setAllCollectorsLoading(false);
        })();
    }, []);

    const transporterList =
        _user.isCollectorAdmin(operator) && _.get(operator, 'collector.configuration.enablePickups', false)
            ? [initialCollector]
            : _.filter(allCollectors, c => _.get(c, 'configuration.enablePickups', true));
    const processorList =
        _user.isCollectorAdmin(operator) && _.get(operator, 'collector.configuration.enableCounting', false)
            ? [initialCollector]
            : _.filter(allCollectors, c => _.get(c, 'configuration.enableCounting', true));
    const {
        showAll: showAllTransporters,
        collector: transporter,
        collectorObj: transporterObj,
        handleChange: handleChangeTransporterFilter
    } = useCollectorDropDown({
        collectors: transporterList,
        receivers: [],
        saveStateInURL: true,
        showAll: true,
        stateURLKey: 'transporter',
        initialCollector:
            _user.isCollectorAdmin(operator) && _.get(operator, 'collector.configuration.enablePickups', false)
                ? initialCollector
                : 'all'
    });

    const {
        showAll: showAllProcessors,
        collector: processor,
        collectorObj: processorObj,
        handleChange: handleChangeProcessorFilter
    } = useCollectorDropDown({
        collectors: processorList,
        receivers: [],
        saveStateInURL: true,
        showAll: true,
        stateURLKey: 'processor',
        initialCollector:
            _user.isCollectorAdmin(operator) && _.get(operator, 'collector.configuration.enableCounting', false)
                ? initialCollector
                : 'all'
    });

    const {
        loading,
        datesWithPickups,
        dates,
        timezone,
        startDate,
        endDate,
        dateWindow,
        handleOpenDateOnPickupsScreen,
        getPickups,
        handleGoForwards,
        handleGoBackwards,
        handleChangeStartDate,
        handleChangeEndDate,
        handleWindowSelect
    } = usePickupCalendar({
        transporter: transporterObj,
        processor: processorObj,
        history
    });

    useEffect(() => {
        if (!_.isNil(initialCollector) && !(_user.isInternalRole(operator) || _user.isSystemAdmin(operator))) {
            handleChangeTransporterFilter({
                target: { value: initialCollector._id }
            });
            handleChangeProcessorFilter({
                target: { value: initialCollector._id }
            });
        }
        if (!_.isNil(initialCollector) && !_user.isSystemAdmin(operator) && !_user.isInternalRole(operator)) {
            setDisableProcessorSelect(true);
            setDisableTransporterSelect(true);
        }
    }, [initialCollector, allCollectors, operator]);

    return (
        <div>
            <Paper style={{ margin: theme.spacing.unit * 2, padding: theme.spacing.unit * 2 }}>
                <Typography variant="h5">Calendar</Typography>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    {!allCollectorsLoading && (
                        <div>
                            <CollectorsSelectDropdown
                                showAll={showAllTransporters}
                                id="transporter-dropdown"
                                value={transporter}
                                collectors={transporterList}
                                cypress_id="trip-list-transporter-dropdown"
                                style={{ width: 250, marginTop: theme.spacing.unit }}
                                disabled={_user.isCollectorAdmin(operator) || _user.isClerk(operator)}
                                onChange={handleChangeTransporterFilter}
                                label="Transporter"
                            />
                            <CollectorsSelectDropdown
                                showAll={showAllProcessors}
                                id="processor-dropdown"
                                value={processor}
                                collectors={processorList}
                                cypress_id="trip-list-processor-dropdown"
                                style={{
                                    width: 250,
                                    marginTop: theme.spacing.unit,
                                    marginLeft: theme.spacing.unit
                                }}
                                disabled={_user.isCollectorAdmin(operator) || _user.isClerk(operator)}
                                onChange={handleChangeProcessorFilter}
                                label="Processor"
                            />
                        </div>
                    )}
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'flex-end',
                            flexWrap: 'wrap',
                            padding: theme.spacing.unit / 2
                        }}
                    >
                        <DateRangePicker
                            allowFutureDates
                            timezone={timezone}
                            startDate={startDate}
                            endDate={endDate}
                            window={dateWindow}
                            disabled={loading}
                            handlePrevious={handleGoBackwards}
                            handleNext={handleGoForwards}
                            handleChangeStartDate={handleChangeStartDate}
                            handleChangeEndDate={handleChangeEndDate}
                        />
                        <FormControl>
                            <Select
                                value={dateWindow}
                                disabled={loading}
                                onChange={handleWindowSelect}
                                style={{
                                    maxWidth: '250px'
                                }}
                            >
                                <MenuItem value="today">Daily</MenuItem>
                                <MenuItem value="thisWeek">Weekly</MenuItem>
                                <MenuItem value="thisMonth">Monthly</MenuItem>
                                <MenuItem value="thisQuarter">Quarterly</MenuItem>
                                <MenuItem value="allTime">All Time</MenuItem>
                            </Select>
                        </FormControl>
                        <Button
                            data-cy="calendar-search"
                            variant={'contained'}
                            color={'primary'}
                            style={{ marginLeft: 10 }}
                            onClick={() => {
                                getPickups();
                            }}
                        >
                            Search
                        </Button>
                    </div>
                </div>
            </Paper>
            <div style={{ margin: theme.spacing.unit * 2, marginBottom: 0 }}>{loading && <LinearProgress />}</div>
            <Paper
                style={{
                    margin: theme.spacing.unit * 2,
                    marginTop: 0,
                    padding: theme.spacing.unit * 2,
                    overflowX: 'scroll'
                }}
            >
                <CustomCalendar
                    loading={loading}
                    capacities={_.get(transporterObj, 'capacities', [0, 0, 0, 0, 0, 0, 0])}
                    datesWithPickups={datesWithPickups}
                    startDate={startDate}
                    endDate={endDate}
                    dates={dates}
                    theme={theme}
                    timezone={timezone}
                    onOpenDateOnPickupsScreen={handleOpenDateOnPickupsScreen}
                />
                <Typography variant="caption" style={{ marginTop: theme.spacing.unit * 2 }}>
                    Use this calendar to predict and prepare for days with large volume. The empty bar represents the
                    percentage of your capacity that has been filled on that particular day. As pickups are booked, they
                    will fill up that days bar in blue. Unconfirmed pickups (which may or may not be postponed to a
                    later date), are represented as the orange bar. The less white space, the less capacity you have for
                    that particular day.
                </Typography>
            </Paper>
        </div>
    );
}

export default withMobileDialog({ breakpoint: 'xs' })(withTheme()(PickupsCalendar));
