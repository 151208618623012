const _ = require('lodash');

/**
 * Upload image call for markdown methods
 * @param {*} http
 * @param {*} image
 * @param {*} param2
 * @returns
 */
export async function handleUploadImage(http, image, { urlPrefix = '' }) {
    const formData = new FormData();
    formData.append('image', image);
    formData.append('other', JSON.stringify({ urlPrefix }));

    const res = await http.postJSON(`/system/uploadImage`, formData, false, true);
    const newImg = _.get(res, 'data.imageUrl');

    return newImg;
}

export const options = {
    maxSizeMB: 0.6, // 600 KB
    maxWidthOrHeight: 1200,
    useWebWorker: true
};

export function formatAsMarkdown(imgUrl, tempFileName) {
    return `![${tempFileName}](${imgUrl})`;
}

export function insertTempName(event, tempName, onChange) {
    const element = event.target;

    const caretStart = element.selectionStart;
    const caretEnd = element.selectionEnd;

    const textEnd = element.value.length;
    const beforeSelection = element.value.substring(0, caretStart);
    const afterSelection = element.value.substring(caretEnd, textEnd);

    element.value = beforeSelection + `{${tempName}}` + afterSelection;
    onChange(event);
}

export function insertMarkDown(event, tempName, markdown, onChange) {
    const element = event.target;

    element.value = element.value.replace(`{${tempName}}`, markdown);

    onChange(event);
}

export function removeMarkDownLinks(markdown) {
    return markdown.replaceAll(new RegExp(/\[(.*?)\]\((.*?)\)/, 'g'), '');
}

export function removeMarkDownImage(markdown) {
    return markdown.replaceAll(new RegExp(/!\[(.*?)\]\((.*?)\)/, 'g'), '');
}

export function containsMarkDownImage(markdown) {
    let regex = new RegExp(/!\[(.*?)\]\((.*?)\)/, 'g');
    return regex.test(markdown);
}
