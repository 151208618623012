import React, { useContext } from 'react';

import _ from 'lodash';
import mustache from 'mustache';

import MdiIcon from '@mdi/react';
import * as allMdiIcons from '@mdi/js';
import {
    DialogActions,
    DialogContent,
    Button,
    withTheme,
    Typography,
    List,
    ListItemAvatar,
    ListItem,
    ListItemText,
    Avatar
} from '@material-ui/core';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';
import { DROP_OFF_TYPES } from 'constants.js';

function Instructions(props) {
    const {
        onClose,
        onBack,
        onNext,
        inProgress,
        theme,
        customer,
        instructions,
        isLastScreen,
        customDialogActions,
        dropOffType,
        kantechPhoneNumber
    } = props;

    const { lang } = useContext(LocalizationContext);

    const dropOffTypeValue = _.find(DROP_OFF_TYPES, d => d.value === dropOffType);

    const getMdiIcon = icon => {
        icon = _.isNil(icon) || _.isEmpty(icon) ? 'mdiCircle' : icon;
        return allMdiIcons[icon];
    };

    return (
        <>
            {/* <DialogTitle>{loc('pickupDialogDrop42', lang)}</DialogTitle> */}
            <DialogContent>
                <Typography
                    variant="body2"
                    style={{ marginTop: isLastScreen ? theme.spacing.unit * 2 : theme.spacing.unit }}
                >
                    {loc('quickdropInstructionsStep1', lang)}{' '}
                    {_.isNil(dropOffTypeValue)
                        ? dropOffType
                        : loc(dropOffTypeValue.localizationName, lang).toLocaleLowerCase()}
                    {':'}
                </Typography>
                <List>
                    {Array.isArray(instructions)
                        ? instructions.map((instruction, index) => (
                              <ListItem>
                                  <ListItemAvatar>
                                      <Avatar
                                          style={{
                                              backgroundColor: theme.palette.primary.main,
                                              height: 50,
                                              width: 50,
                                              marginRight: '20px'
                                          }}
                                      >
                                          <MdiIcon
                                              path={getMdiIcon(instruction.icon)}
                                              size={'100%'}
                                              color={'white'}
                                              style={{
                                                  maxWidth: '30px',
                                                  maxHeight: '30px'
                                              }}
                                          />
                                      </Avatar>
                                  </ListItemAvatar>
                                  <ListItemText
                                      primary={
                                          <Typography variant="h6">
                                              {index + 1}.{' '}
                                              {mustache.render(instruction.primary, {
                                                  uniqueID: _.get(customer, 'uniqueID'),
                                                  brandname: process.env.REACT_APP_BRAND_NAME,
                                                  kantechPhoneNumber
                                              })}
                                          </Typography>
                                      }
                                      secondary={mustache.render(instruction.secondary, {
                                          uniqueID: _.get(customer, 'uniqueID'),
                                          brandname: process.env.REACT_APP_BRAND_NAME,
                                          kantechPhoneNumber
                                      })}
                                  />
                              </ListItem>
                          ))
                        : null}
                </List>
            </DialogContent>
            <DialogActions style={{ marginBottom: 'max(8px, env(safe-area-inset-bottom, 8px))' }}>
                {!_.isNil(customDialogActions) ? (
                    customDialogActions
                ) : (
                    <>
                        <Button color="primary" onClick={onBack} data-cy="quickdrop-steps-back">
                            {loc('back', lang)}
                        </Button>
                        {isLastScreen ? (
                            <Button color="primary" disabled={inProgress} onClick={onClose}>
                                {loc('close', lang)}
                            </Button>
                        ) : (
                            <Button
                                color="primary"
                                disabled={inProgress}
                                onClick={onNext}
                                data-cy="quickdrop-steps-next"
                            >
                                {loc('next', lang)}
                            </Button>
                        )}
                    </>
                )}
            </DialogActions>
        </>
    );
}

export default withTheme()(Instructions);
