import React from 'react';

import _ from 'lodash';

import { withTheme, Typography, Paper, Divider, Icon, Grid } from '@material-ui/core';
import RankBadge from 'components/CustomIcons/RankBadge';

function CountingRatingWidget(props) {
    const { theme, countRating, isClerk, height } = props;

    const countAccuracyFormatted =
        _.get(countRating, 'counterRank.countAccuracy', 0) === 0
            ? 'N/A'
            : `${(_.get(countRating, 'counterRank.countAccuracy', 0) * 100).toFixed(1)}%`;

    const countAccuracyRankFormatted =
        countAccuracyFormatted !== 'N/A' ? `${_.get(countRating, 'counterRank.countAccuracyRank', 'N/A')}` : 'N/A';

    const boxStyleFlex = {
        border: '2px solid rgba(0, 0, 0, 0.12)',
        margin: theme.spacing.unit,
        padding: theme.spacing.unit * 2,
        flex: `calc(20% - ${theme.spacing.unit * 2}px)`,
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        alignItems: 'center'
    };

    return (
        <Paper style={{ padding: theme.spacing.unit * 2, height: height ? height : undefined }}>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingBottom: theme.spacing.unit / 2
                }}
            >
                <Typography variant="h6">{!isClerk && 'Weekly '}Sorting Statistics</Typography>
            </div>
            <Divider />

            {isClerk && (
                <>
                    <Grid container>
                        <Grid container item xs justify="center" style={{ marginTop: theme.spacing.unit * 2 }}>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    style={{ textAlign: 'center', marginBottom: theme.spacing.unit }}
                                >
                                    Sorting Accuracy
                                </Typography>
                                <RankBadge value={countAccuracyRankFormatted} />
                                <Typography
                                    variant="h6"
                                    color="default"
                                    style={{
                                        fontWeight: 400,
                                        whiteSpace: 'nowrap',
                                        textAlign: 'center',
                                        marginTop: theme.spacing.unit
                                    }}
                                >
                                    {countAccuracyFormatted}
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>

                    <Divider style={{ margin: `${theme.spacing.unit * 2}px 0` }} />
                </>
            )}

            <div
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    width: '100%',
                    justifyContent: 'center',
                    marginTop: !isClerk ? theme.spacing.unit * 2 : 'none'
                }}
            >
                <div style={boxStyleFlex}>
                    <Icon color="primary" style={{ fontSize: '30px' }}>
                        check_circle
                    </Icon>
                    <Typography variant="h6" color="default" style={{ fontWeight: 400, marginTop: theme.spacing.unit }}>
                        {countRating.goodCounts}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        Accurate
                    </Typography>
                </div>
                <div style={boxStyleFlex}>
                    <Icon color="primary" style={{ fontSize: '30px' }}>
                        category
                    </Icon>
                    <Typography variant="h6" color="default" style={{ fontWeight: 400, marginTop: theme.spacing.unit }}>
                        {countRating.totalCounts}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        Sorted
                    </Typography>
                </div>
                <div style={boxStyleFlex}>
                    <Icon color="primary" style={{ fontSize: '30px' }}>
                        report_problem
                    </Icon>
                    <Typography variant="h6" color="default" style={{ fontWeight: 400, marginTop: theme.spacing.unit }}>
                        {countRating.totalCounts - countRating.goodCounts}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        Complaints
                    </Typography>
                </div>
                <div style={boxStyleFlex}>
                    <Icon color="primary" style={{ fontSize: '30px' }}>
                        assignment
                    </Icon>
                    <Typography variant="h6" color="default" style={{ fontWeight: 400, marginTop: theme.spacing.unit }}>
                        {countRating.reportedCount}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        Reported
                    </Typography>
                </div>
            </div>
        </Paper>
    );
}

export default withTheme()(CountingRatingWidget);
