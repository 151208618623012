import _ from 'lodash';

// TODO MAKE THESE CONFIGURABLE TOO
const WEEKLY_FREQUENCIES = [7 * 1, 7 * 2, 7 * 4, 7 * 10];
const MONTHLY_FREQUENCIES = [28 * 1, 28 * 2, 28 * 3];

function pickupFormReducer(state, action) {
    const { type } = action;

    switch (type) {
        case 'set':
            let updatedState = _.cloneDeep(state);
            _.set(updatedState, action.path, action.value);
            return {
                ...updatedState
            };
        case 'set-location':
            return {
                ...state,
                location: action.location
            };
        case 'set-address-data':
            return {
                ...state,
                date: action.date,
                availableDates: action.availableDates,
                zone: action.zone,
                frequency: action.frequency,
                payload: action.payload, // depending on the zone, only allow certain items through
                subPayloads: action.subPayloads,
                customerNextPickupDate: action.customerNextPickupDate, //displayed to admins when booking a pickup for a user,
                receivers: action.receivers,
                receiver: action.receiver,
                location: { ...state.location, timezone: action.timezone }
            };
        case 'set-pickup-type-selected':
            return {
                ...state,
                pickupType: action.pickupType,
                recurring: action.recurring,
                donate: action.donate,
                charityPreferred: action.charityPreferred,
                organizationName: action.organizationName,
                customTime: action.charityPreferred
            };
        case 'set-donation':
            return { ...state, donate: action.donate };
        case 'set-business-name':
            return { ...state, businessName: action.businessName };
        case 'toggle-donation':
            return {
                ...state,
                donate: !state.donate
            };
        case 'set-payload':
            console.log('set-payload', action.payload);
            return {
                ...state,
                payload: action.payload
            };
        case 'set-sub-payloads':
            return {
                ...state,
                subPayloads: action.subPayloads
            };
        case 'set-object':
            return {
                ...state,
                payload: action.payload
            };

        case 'set-selected-suggestion':
            return {
                ...state,
                location: action.location
            };
        case 'set-customTime':
            return {
                ...state,
                customTime: action.customTime
            };
        case 'set-frequency':
            return {
                ...state,
                recurring: true,
                frequency: action.frequency
            };
        case 'toggle-recurring': {
            return {
                ...state,
                recurring: !state.recurring,
                frequency: state.frequency > 0 ? state.frequency : getLowestZoneFrequency(state.zone)
            };
        }
        case 'toggle-tax-receipts':
            return {
                ...state,
                taxReceiptRequested: !state.taxReceiptRequested
            };
        /* case 'toggle-gps-override':
            return {
                ...state,
                location: { ...state.location, isCustomGPS: action.customGPS}
            }; */
        case 'set-charity-preferred':
            return {
                ...state,
                charityPreferred: action.charityPreferred,
                subdivisionPreferred: !_.isNil(action.subdivision) ? action.subdivision : null
            };
        case 'set-charity':
            return {
                ...state,
                donate: true,
                charityPreferred: action.charity,
                subdivisionPreferred: !_.isNil(action.subdivision) ? action.subdivision : null
            };
        case 'set-date-change':
            return {
                ...state,
                date: action.date,
                customTime: null
            };
        case 'set-receiver':
            return {
                ...state,
                receiver: action.receiver
            };
        case 'add-promo':
            return {
                ...state,
                promos: _.concat(state.promos, action.promo)
            };
        case 'remove-promo':
            return {
                ...state,
                promos: _.filter(state.promos, p => p.code !== action.promo.code)
            };
        case 'toggle-promo-recurring':
            return {
                ...state,
                recurringPromo: !state.recurringPromo
            };
        case 'toggle-always-bypass-zone-check':
            return {
                ...state,
                alwaysBypassZoneChecks: action.alwaysBypassZoneChecks
            };
        case 'toggle-place-at-start-of-trip':
            return {
                ...state,
                placeAtStartOfTrip: !state.placeAtStartOfTrip
            };
        case 'toggle-phone-override':
            return {
                ...state,
                phoneOverride: !state.phoneOverride
            };
        case 'toggle-always-confirm-override':
            return {
                ...state,
                alwaysConfirmedOverride: action.toggle
            };
        case 'set-tip-amount':
            return {
                ...state,
                tipAmount: action.tipAmount
            };
        case 'set-time-preference':
            return {
                ...state,
                timePreference: action.timePreference
            };
        case 'set-efficiency-modifier':
            return {
                ...state,
                efficiencyModifier: action.efficiencyModifier
            };
        case 'set-override-processor':
            return {
                ...state,
                overrideProcessor: action.overrideProcessor
            };
        case 'set-override-transporter':
            return {
                ...state,
                overrideTransporter: action.overrideTransporter
            };
        case 'set-bin-requested':
            return {
                ...state,
                binRequested: action.binRequested
            };
        case 'set-bin-request-message':
            return {
                ...state,
                binRequestMessage: action.binRequestMessage
            };
        case 'set-past-location-data':
            let { businessName, comment, phone, frequency, charity, subdivision, taxReceiptRequested } = _.get(
                action,
                'pastLocation.pickupData',
                {}
            );
            return {
                ...state,
                businessName: state.pickupType === 'Commercial' && !_.isNil(businessName) ? businessName : '',
                comment: !_.isNil(comment) ? comment : state.comment,
                phoneOverride: !_.isNil(phone),
                phone: !_.isNil(phone) ? phone : state.phone,
                frequency: !_.isNil(frequency) ? frequency : frequency === null ? null : state.frequency,
                donate: !_.isNil(charity),
                charityPreferred: !_.isNil(charity) ? charity : state.charity,
                subdivisionPreferred: !_.isNil(subdivision) ? subdivision : state.subdivisionPreferred,
                taxReceiptRequested: !_.isNil(taxReceiptRequested) ? taxReceiptRequested : state.taxReceiptRequested
            };
        case 'set-donating-to-bottle-drive':
            const donatingToBottleDrive = _.get(action, 'donatingToBottleDrive', false);
            return {
                ...state,
                donatingToBottleDrive
            };
        case 'change-selected':
            return action.initialPickupForm;
        case 'reset':
            return;
        default:
            return state;
    }
}

export default pickupFormReducer;

function getLowestZoneFrequency(zone) {
    return _.get(zone, 'frequency', 7) === 7 ? _.last(WEEKLY_FREQUENCIES) : _.first(MONTHLY_FREQUENCIES);
}
