import React from 'react';
import _ from 'lodash';

import { TextField } from '@material-ui/core';

function CustomTextInput(props) {
    const { value, onChange, upperCase, removeWhitespace, alphanumeric, ...otherProps } = props;

    function convertValue(value) {
        if (removeWhitespace) {
            value = value.replace(/\s/g, '');
        }
        if (upperCase) {
            value = value.toUpperCase();
        }

        if (alphanumeric) {
            value = value.replace(/[^0-9a-z]/gi, '');
        }

        return value;
    }

    const convertedValue = _.isNil(value) ? value : convertValue(value);

    const handleChange = event => {
        let cursorStart = event.target.selectionStart,
            cursorEnd = event.target.selectionEnd;
        event.target.value = _.isNil(event.target.value) ? event.target.value : convertValue(event.target.value);
        event.target.setSelectionRange(cursorStart, cursorEnd);
        onChange(event);
    };

    return <TextField {...otherProps} value={convertedValue} onChange={handleChange} />;
}

export default CustomTextInput;
