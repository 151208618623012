import React from 'react';

import _ from 'lodash';

import { Typography, Button, withTheme } from '@material-ui/core';

import { LinkedinShareButton, LinkedinIcon } from 'react-share';

import { deviceHelper } from 'utils/misc';

import CustomGrid from '../Components/CustomGrid';
import { loc } from 'localizations/localizationHandler';

function LinkedInDisplay({
    charity,
    referralLinkComponent,
    urlToLink,
    examples,
    socialMediaHandle,
    theme,
    subject,
    message,
    shareUrl,
    hashtags,
    onShare,
    onNativeShare,
    lang
}) {
    const linkStyle = { overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' };

    const charityName = _.get(charity, 'name', 'YOUR ORGANIZATION');

    let linkedInHashtags;
    if (process.env.REACT_APP_REGION_EXT === 'STD')
        linkedInHashtags =
            '#calgary #yyc #yycnow #calgarybuzz #yycbuzz #yycbusiness #calgarylife #charity #nonprofit #giveback #donate #community #fundraising #recycle #reuse #environment #sustainability #yeg #yeglocal #yegbusiness #edmontonliving #alberta #yeglife⁠ #yql #yqllocal';
    else if (process.env.REACT_APP_REGION_EXT === 'EXP')
        linkedInHashtags =
            '#return-it #charity #nonprofit #giveback #donate #community #fundraising #recycle #reuse #environment #sustainability ';
    else if (process.env.REACT_APP_REGION_EXT === 'MXD') linkedInHashtags = loc('growthLinkedIn4', lang);
    else if (process.env.REACT_APP_REGION_EXT === 'CON') linkedInHashtags = loc('growthLinkedIn4b', lang);
    else if (process.env.REACT_APP_REGION_EXT === 'AUS')
        linkedInHashtags =
            '#australia #qld #act #nsw #wa #vic #charity #nonprofit #giveback #donate #community #fundraising #recycle #reuse #environment #sustainability #australialife #australian #aussie';
    return (
        <>
            <Typography variant="body1" style={{ marginBottom: theme.spacing.unit }}>
                <>
                    <ol>
                        <li>
                            {loc('growthGeneral13', lang)}
                            <div style={linkStyle}>
                                {charity.approved ? referralLinkComponent : loc('growthGeneral17', lang)}
                            </div>
                        </li>
                        <li>
                            {loc('growthGeneral14', lang)} {urlToLink(process.env.REACT_APP_LINKEDIN_LINK)}
                        </li>
                        <li>{loc('growthGeneral15', lang)}</li>
                        <li>{loc('growthGeneral16', lang)}</li>
                    </ol>
                </>
            </Typography>
            <CustomGrid
                contentItems={[
                    {
                        image: examples[0] ? examples[0] : null,
                        text: (
                            <>
                                {loc('growthLinkedIn1', lang, { socialMediaHandle, charity: charityName })}
                                <br />
                                {loc('growthLinkedIn2', lang, { socialMediaHandle, charity: charityName })}{' '}
                                <div style={linkStyle}>{referralLinkComponent}</div>
                                <br />
                                {loc('growthLinkedIn3', lang, {
                                    socialMediaHandle,
                                    brand: process.env.REACT_APP_BRAND_WEBSITE_NAME
                                })}
                                <br />
                                <br />
                                {linkedInHashtags}
                            </>
                        )
                    },
                    {
                        image: examples[1] ? examples[1] : null,
                        text: (
                            <>
                                {loc('growthLinkedIn5', lang, { socialMediaHandle })}
                                <br />
                                <br />
                                {loc('growthLinkedIn6', lang)}
                                <div style={linkStyle}>{referralLinkComponent}</div>
                                <br /> {loc('growthLinkedIn7', lang)}
                                <br />
                                <br />
                                {linkedInHashtags}
                            </>
                        )
                    },
                    {
                        image: examples[2] ? examples[2] : null,
                        text: (
                            <>
                                {loc('growthLinkedIn8', lang, { socialMediaHandle })}
                                <br />
                                <br />
                                {loc('growthLinkedIn9', lang, { socialMediaHandle })}
                                <br />
                                <br />
                                {loc('growthLinkedIn10', lang)} <div style={linkStyle}>{referralLinkComponent}</div>
                                <br />
                                {loc('growthLinkedIn11', lang, { charity: charityName })}
                                <br />
                                <br />
                                {linkedInHashtags}
                            </>
                        )
                    },
                    {
                        image: examples[3] ? examples[3] : null,
                        text: (
                            <>
                                {process.env.REACT_APP_REGION_EXT === 'STD' &&
                                    loc('growthLinkedIn12', lang, { socialMediaHandle })}
                                <br />
                                <br />
                                {loc('growthLinkedIn13', lang, { socialMediaHandle, charity: charityName })}
                                <br />
                                <br />
                                {loc('growthLinkedIn14', lang)}
                                <br />
                                <br /> {loc('growthLinkedIn15', lang)}{' '}
                                <div style={linkStyle}>{referralLinkComponent}</div>
                                <br />
                                {loc('growthLinkedIn16', lang, { brand: process.env.REACT_APP_BRAND_NAME })}
                                <br />
                                <br />
                                {linkedInHashtags}
                            </>
                        )
                    }
                ]}
            />
        </>
    );
}

export default withTheme()(LinkedInDisplay);
