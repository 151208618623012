import _ from 'lodash';
import { deviceHelper } from '../misc';

export function getNextPlatformToRateOn(collectorRatingPlatforms, customer) {
    return _.find(collectorRatingPlatforms, collectorRatingPlatform => {
        const ratingPlatform = collectorRatingPlatform.ratingPlatform;
        //check user has not already rated on current rating platform
        const hasNotRatedOnPlatform = !_.some(
            customer.platformsRatedOn,
            platformRatedOnId => platformRatedOnId === ratingPlatform._id
        );
        //check if version of app for which users should be prompted to rate this platform match
        if (
            ratingPlatform.appVersion === 'All' ||
            (!deviceHelper.isNativeApp() && ratingPlatform.appVersion === 'Web') ||
            (deviceHelper.iOSCordova() && ratingPlatform.appVersion === 'IOS') ||
            (deviceHelper.AndroidCordova() && ratingPlatform.appVersion === 'Android')
        ) {
            return hasNotRatedOnPlatform;
        } else {
            return false;
        }
    });
}
