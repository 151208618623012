import React, { Component } from 'react';
import _ from 'lodash';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import TextField from '@material-ui/core/TextField';
import Avatar from '@material-ui/core/Avatar';
import Icon from '@material-ui/core/Icon';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitlePrimary from 'components/MaterialUIExtensions/DialogTitlePrimary';
import FormControl from '@material-ui/core/FormControl';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import { withTheme } from '@material-ui/core/styles';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

class ReferralDialog extends Component {
    static contextType = LocalizationContext;
    state = {
        input: '',
        emails: [],
        formHasErrors: false,
        formErrorMessage: ''
    };

    componentDidMount = () => {};

    handleKeyPress = e => {
        const { input, formHasErrors } = this.state;
        if (e.key === 'Enter' && !_.isEmpty(input) && !formHasErrors) {
            let newEmails = parseEmails(input);

            this.setState(prevState => ({
                input: '',
                emails: _.uniq(prevState.emails.concat(newEmails))
            }));
        }
    };

    handleChange = e => {
        const { emails } = this.state;

        let value = e.target.value;

        let formErrorMessage = '';
        let formHasErrors = false;

        let newEmails = parseEmails(value);

        for (let email of newEmails) {
            if (!isValidEmail(email)) {
                formHasErrors = true;
                formErrorMessage = `${loc('referal6', this.context.lang)}: ` + email;
            }
            // TODO: deal with duplicate emails
            if (newEmails.length === 1 && emails.includes(email)) {
                formHasErrors = true;
                formErrorMessage = loc('referal8', this.context.lang);
            } else if (newEmails.length > 1 && _.intersection(emails, newEmails).length === newEmails.length) {
                formHasErrors = true;
                formErrorMessage = loc('referal8', this.context.lang);
            }
        }

        // if (!_.isEmpty(value) && !isValidEmail(value)) {
        //     formErrorMessage = 'Invalid email format';
        //     emailHasErrors = true;
        // } else if (emails.includes(value)) {
        //     formErrorMessage = 'Email already added to list';
        //     emailHasErrors = true;
        // }

        this.setState({
            [e.target.name]: value,
            formHasErrors,
            formErrorMessage
        });
    };

    handleAddEmails = input => () => {
        let newEmails = parseEmails(input);

        this.setState(prevState => ({
            input: '',
            emails: _.uniq(prevState.emails.concat(newEmails))
        }));
    };

    handleRemoveEmail = email => () => {
        const { emails } = this.state;
        this.setState({
            emails: _.remove(emails, function(n) {
                return n !== email;
            })
        });
    };

    handleSubmit = async () => {
        const { http, customer_id, charity_id } = this.props;
        const { emails } = this.state;

        let res = await http.post(`/referrals/${customer_id}/invite`, { emails, charity_id });
        if (res.ok) {
            //handleCharityTasksUpdate(res.data.charityTasks)
            this.props.onClose();
            this.setState({
                input: '',
                emails: [],
                formHasErrors: false,
                formErrorMessage: ''
            });
            this.props.onSnackbar(loc('referal7', this.context.lang));
        }
    };

    render() {
        const { theme, open, fullScreen } = this.props;
        const { input, emails, formHasErrors, formErrorMessage } = this.state;

        const inputParsedEmails = parseEmails(input);

        const emailList = array => {
            return array.map(value => (
                <ListItem style={{ paddingLeft: 0, paddingRight: 0 }} key={value}>
                    <ListItemAvatar>
                        <Avatar>
                            <Icon>email</Icon>
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={value} />
                    <ListItemSecondaryAction>
                        <IconButton onClick={this.handleRemoveEmail(value)}>
                            <Icon>clear</Icon>
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
            ));
        };

        return (
            <Dialog open={open} fullScreen={fullScreen} fullWidth onClose={this.props.onClose}>
                <DialogTitlePrimary closeButtonShown onClose={this.props.onClose}>
                    {loc('referal1', this.context.lang)}
                </DialogTitlePrimary>
                <DialogContent style={{ paddingTop: theme.spacing.unit * 3 }}>
                    <DialogContentText>{loc('referal2', this.context.lang)}</DialogContentText>

                    <FormControl fullWidth style={{ marginTop: theme.spacing.unit }}>
                        <TextField
                            autoComplete="off"
                            name="input"
                            value={input}
                            label={loc('referal3', this.context.lang)}
                            error={formHasErrors}
                            helperText={formErrorMessage}
                            onKeyPress={this.handleKeyPress}
                            onChange={this.handleChange}
                        />
                    </FormControl>

                    <List dense style={{ marginTop: theme.spacing.unit }}>
                        {emailList(emails)}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        disabled={formHasErrors || _.isEmpty(input)}
                        onClick={this.handleAddEmails(input)}
                    >
                        {loc('referal4', this.context.lang)}
                        {/* {inputParsedEmails.length > 1 ? 's' : ''} */}
                    </Button>
                    <Button color="primary" disabled={_.isEmpty(emails)} onClick={this.handleSubmit}>
                        {loc('referal5', this.context.lang)}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withMobileDialog({ breakpoint: 'xs' })(withTheme()(ReferralDialog));

function isValidEmail(value) {
    let regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regexEmail.test(value.toLowerCase());
}

function parseEmails(string) {
    return _.filter(string.replace(/ /g, ',').split(','), email => !_.isEmpty(email));
}
