import { useState, useContext, useEffect } from 'react';
import _ from 'lodash';

import HttpContext from 'utils/contexts/HttpContext';

function useAppliedFeesFilters() {
    const http = useContext(HttpContext);

    const [allCollectors, setAllCollectors] = useState([]);
    const [collectorsSelected, setCollectorsSelected] = useState([]);

    const [allDrivers, setAllDrivers] = useState([]);
    const [driversSelected, setDriversSelected] = useState([]);

    useEffect(() => {
        (async () => {
            const res = await http.getJSON('/users/getAllDrivers');
            if (res.ok) {
                let drivers = _.get(res, 'data.drivers', []);
                setAllDrivers(drivers);
                setDriversSelected(drivers.map(driver => driver._id));
            }
            const res2 = await http.getJSON('/collectors');
            if (res2.ok) {
                let collectors = _.get(res2, 'data.collectors', []);
                setAllCollectors(collectors);
                setCollectorsSelected(collectors.map(collector => collector._id));
            }
        })();
    }, []);

    const handleAllFilterCollectors = all => e => {
        e.stopPropagation();
        if (all) {
            setCollectorsSelected(allCollectors.map(c => c._id));
        } else {
            setCollectorsSelected([]);
        }
    };

    return {
        allCollectors,
        collectorsSelected,
        setCollectorsSelected,
        allDrivers,
        driversSelected,
        setDriversSelected,
        handleAllFilterCollectors
    };
}

export default useAppliedFeesFilters;
