import React, { useState, useMemo, useContext, useEffect } from 'react';

import _ from 'lodash';
import moment from 'moment-timezone';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Select, MenuItem } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';

import DatePicker from 'components/DateTimePickersTz/DatePicker';

import { _time, _user } from 'std';

import { downloadObjectAsCSV, isAUSRegion, isCONRegion, isEXPRegion, isSTDRegion } from 'utils/misc';
import HttpContext from 'utils/contexts/HttpContext';

import useDateRangePicker from 'components/DateTimePickersTz/hooks/useDateRangePicker';
import DateRangePicker from 'components/DateTimePickersTz/DateRangePicker';

import QuickSelect from 'components/InputComponents/QuickSelect';

import MDIcon from '@mdi/react';
import { mdiAccountOff } from '@mdi/js';

import { getStateFromAddress } from 'utils/misc';

function JournalReport(props) {
    const { pickupsEnabled, collectors, collector, theme, operator } = props;

    const http = useContext(HttpContext);

    const [loading, setLoading] = useState(false);

    const {
        startDate,
        endDate,
        timezone,
        dateWindow,
        handleChangeStartDate,
        handleChangeEndDate,
        handleGoForwards,
        handleGoBackwards,
        handleWindowSelect
    } = useDateRangePicker({
        saveStateInURL: true,
        timezones: [process.env.REACT_APP_REGION_TIMEZONE],
        initStartVal: _time.getStartOfIsoWeek(process.env.REACT_APP_REGION_TIMEZONE, 1),
        initEndVal: _time.getEndOfDay(process.env.REACT_APP_REGION_TIMEZONE),
        initDateWindow: 'thisIsoWeek'
    });

    const [accountNames, setAccountNames] = useState({});

    const [allDrivers, setAllDrivers] = useState([]);
    const [collectorsSelected, setCollectorsSelected] = useState(
        collectors.filter(c => _.get(c, 'configuration.enableCounting', true)).map(c => c._id)
    );
    const [transportersSelected, setTransportersSelected] = useState(
        collectors.filter(c => _.get(c, 'configuration.enablePickups', true)).map(c => c._id)
    );
    const [driversSelected, setDriversSelected] = useState([]);

    const handleDownloadCSV = async () => {
        setLoading(true);

        const res = await http.getJSON(
            `/journalEntries?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}&processors=${collectorsSelected}&transporters=${transportersSelected}&receivers=${driversSelected}`
        );
        if (res.ok) {
            const journalEntries = res.data.journalEntries;
            const report = generateCsvData(journalEntries, accountNames, collectors);
            await downloadObjectAsCSV(
                report,
                `journal_report_${moment(startDate)
                    .tz(process.env.REACT_APP_REGION_TIMEZONE)
                    .format('YYYY-MM-DD')}_${moment(endDate)
                    .tz(process.env.REACT_APP_REGION_TIMEZONE)
                    .format('YYYY-MM-DD')}`
            );
        }

        setLoading(false);
    };

    const handleCollectorsSelected = collectors => {
        setCollectorsSelected(collectors);
    };

    const handleTransportersSelected = transporters => {
        setTransportersSelected(transporters);
    };

    const selectableDrivers = useMemo(() => {
        return _.filter(allDrivers, driver => transportersSelected.includes(_.get(driver, 'collector._id')));
    }, [allDrivers, transportersSelected]);

    useEffect(() => {
        (async () => {
            const res = await http.getJSON('/system/configuration/journalEntries');
            if (res.ok) {
                setAccountNames(_.get(res, 'data.journalEntryConfig.accountNames', {}));
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            const res = await http.getJSON('/users/getAllDrivers');
            if (res.ok) {
                let drivers = _.get(res, 'data.drivers', []);
                setAllDrivers(drivers);
                setDriversSelected(
                    drivers
                        .filter(driver => transportersSelected.includes(driver.collector._id))
                        .map(driver => driver._id)
                );
            }
        })();
    }, []);

    const formControlStyle = {
        width: `calc(50% - ${theme.spacing.unit}px)`,
        marginLeft: theme.spacing.unit * 0.5,
        marginRight: theme.spacing.unit * 0.5,
        marginTop: theme.spacing.unit * 2
    };

    return (
        <div data-cy="Accounting" style={{ padding: theme.spacing.unit * 2 }}>
            <Paper style={{ marginTop: theme.spacing.unit * 2, padding: theme.spacing.unit * 2 }}>
                <Grid
                    container
                    style={{
                        marginLeft: -theme.spacing.unit * 0.5,
                        marginRight: -theme.spacing.unit * 0.5,
                        marginTop: theme.spacing.unit
                    }}
                >
                    <Grid item xs={12}>
                        <Typography variant="h6">Journal Entries</Typography>
                        <Typography color="textSecondary" style={{ marginTop: theme.spacing.unit }}>
                            Download all financial transactions within specified period:{' '}
                            <span style={{ color: theme.palette.primary.main }}>
                                {moment(startDate)
                                    .tz(process.env.REACT_APP_REGION_TIMEZONE)
                                    .format('YYYY-MM-DD HH:mm Z')}
                            </span>{' '}
                            to{' '}
                            <span style={{ color: theme.palette.primary.main }}>
                                {moment(endDate)
                                    .tz(process.env.REACT_APP_REGION_TIMEZONE)
                                    .format('YYYY-MM-DD HH:mm Z')}
                            </span>
                        </Typography>
                        <Typography color="textSecondary">
                            Date range applies to when{' '}
                            <span style={{ color: theme.palette.text.primary }}>containers</span> were received and{' '}
                            <span style={{ color: theme.palette.text.primary }}>adjustments</span> were issued.
                        </Typography>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                flexWrap: 'wrap',
                                marginTop: theme.spacing.unit * 0.5,
                                marginBottom: theme.spacing.unit * 5
                            }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <DateRangePicker
                                    timezone={timezone}
                                    startDate={startDate}
                                    endDate={endDate}
                                    window={dateWindow}
                                    disabled={loading}
                                    handlePrevious={handleGoBackwards}
                                    handleNext={handleGoForwards}
                                    handleChangeStartDate={handleChangeStartDate}
                                    handleChangeEndDate={handleChangeEndDate}
                                />
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexWrap: 'wrap'
                                }}
                            >
                                <FormControl>
                                    <TextField
                                        variant="outlined"
                                        disabled={loading}
                                        value={dateWindow}
                                        onChange={handleWindowSelect}
                                        style={{
                                            maxWidth: '250px',
                                            margin: theme.spacing.unit
                                        }}
                                        select
                                    >
                                        <MenuItem value="today">Daily</MenuItem>
                                        <MenuItem value="thisIsoWeek">Weekly</MenuItem>
                                        <MenuItem value="thisMonth">Monthly</MenuItem>
                                        <MenuItem value="thisQuarter">Quarterly</MenuItem>
                                        <MenuItem value="allTime">All Time</MenuItem>
                                    </TextField>
                                </FormControl>

                                {pickupsEnabled && (
                                    <QuickSelect
                                        variant="outlined"
                                        selectableValues={collectors
                                            .filter(c => _.get(c, 'configuration.enablePickups', true))
                                            .map(c => {
                                                return {
                                                    name: _.get(c, 'name', ''),
                                                    value: _.get(c, '_id'),
                                                    obj: c
                                                };
                                            })}
                                        quickSelectId="transporters"
                                        multiple
                                        disabled={loading}
                                        onChange={handleTransportersSelected}
                                        style={{
                                            maxWidth: '250px',
                                            margin: theme.spacing.unit
                                        }}
                                        value={transportersSelected}
                                        renderValue={s => `${s.length} Transporters`}
                                        data-cy="dashboard-transporter-select"
                                    />
                                )}

                                <QuickSelect
                                    variant="outlined"
                                    multiple
                                    value={collectorsSelected}
                                    selectableValues={collectors
                                        .filter(c => _.get(c, 'configuration.enableCounting', true))
                                        .map(c => {
                                            return { name: _.get(c, 'name', ''), value: _.get(c, '_id'), obj: c };
                                        })}
                                    renderValue={s => `${s.length} Processors`}
                                    quickSelectId="processors"
                                    onChange={handleCollectorsSelected}
                                    disabled={loading}
                                    style={{
                                        maxWidth: '250px',
                                        margin: theme.spacing.unit
                                    }}
                                />
                                {pickupsEnabled && (
                                    <QuickSelect
                                        variant="outlined"
                                        disabled={loading}
                                        renderValue={val => `${val.length} Driver${val.length !== 1 ? 's' : ''}`}
                                        value={driversSelected}
                                        onChange={drivers => setDriversSelected(drivers)}
                                        style={{
                                            maxWidth: '250px',
                                            margin: theme.spacing.unit
                                        }}
                                        selectableValues={selectableDrivers.map(driver => {
                                            return {
                                                value: driver._id,
                                                name: _user.getNameFull(driver).toLowerCase(),
                                                obj: driver
                                            };
                                        })}
                                        customSort={values =>
                                            _.orderBy(
                                                values,
                                                [({ obj }) => !obj.banned, ({ obj }) => obj.name.first.toLowerCase()],
                                                ['desc', 'asc']
                                            )
                                        }
                                        renderMenuItem={({ name, value, obj, selected }) => (
                                            <MenuItem
                                                value={value}
                                                key={value}
                                                style={{
                                                    textTransform: 'capitalize'
                                                }}
                                            >
                                                {name}
                                                {_.get(obj, 'banned') && (
                                                    <span
                                                        style={{
                                                            marginLeft: theme.spacing.unit,
                                                            display: 'grid',
                                                            placeItems: 'center'
                                                        }}
                                                    >
                                                        <MDIcon
                                                            path={mdiAccountOff}
                                                            size={0.85}
                                                            color={theme.palette.text.secondary}
                                                        />
                                                    </span>
                                                )}
                                            </MenuItem>
                                        )}
                                        quickSelectId="drivers"
                                    />
                                )}
                            </div>
                        </div>
                    </Grid>
                    {/*<Grid item xs={12}>
                        <Grid item xs={12} lg={6} style={{ marginBottom: theme.spacing.unit * 2 }}>
                            <FormControl style={formControlStyle}>
                                <DatePicker
                                    disabled={loading}
                                    timezone={process.env.REACT_APP_REGION_TIMEZONE}
                                    label="Start date"
                                    name="date"
                                    format="MMMM Do, YYYY"
                                    value={startDate}
                                    variant="outlined"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Icon>date_range</Icon>
                                            </InputAdornment>
                                        )
                                    }}
                                    onChange={date => setStartDate(date)}
                                />
                            </FormControl>
                            <FormControl style={formControlStyle}>
                                <DatePicker
                                    disabled={loading}
                                    timezone={process.env.REACT_APP_REGION_TIMEZONE}
                                    label="End date"
                                    name="date"
                                    format="MMMM Do, YYYY"
                                    value={endDate}
                                    variant="outlined"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Icon>date_range</Icon>
                                            </InputAdornment>
                                        )
                                    }}
                                    onChange={date => setEndDate(date.endOf('day'))}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>*/}

                    {/* <Grid item xs={12} style={{ marginLeft: theme.spacing.unit * 0.5, marginRight: theme.spacing.unit * 0.5 }}>
                        <Typography color="textSecondary">Enter account names for accounting purposes.</Typography>
                    </Grid> */}
                </Grid>
                <br />
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button variant="contained" color="primary" disabled={loading} onClick={handleDownloadCSV}>
                        Download CSV
                    </Button>
                </div>
            </Paper>

            {loading && (
                <LinearProgress
                    style={{
                        borderBottomLeftRadius: theme.shape.borderRadius,
                        borderBottomRightRadius: theme.shape.borderRadius
                    }}
                />
            )}
        </div>
    );
}

export default withTheme()(JournalReport);

function generateCsvData(journalEntries, enteredAccountNames, collectors) {
    const csvData = [];
    for (let i = 0; i < journalEntries.length; i++) {
        const journalEntry = journalEntries[i];

        const lineNo = i + 1;
        const transactionDateFormatted = moment(journalEntry.transactionDate)
            .tz(process.env.REACT_APP_REGION_TIMEZONE)
            .format('MMM D, YYYY');
        const type = _.get(journalEntry, 'type');

        const transporter = _.find(collectors, { _id: _.get(journalEntry, 'transporter') });
        const processor = _.find(collectors, { _id: _.get(journalEntry, 'processor') });

        let province = '';
        if (isAUSRegion()) {
            if (_.get(journalEntry, 'type') === 'Redemption') {
                province = getStateFromAddress(_.get(journalEntry, 'redemptionLocation.description', '')); //NOTE: this function only works for AUS
            } else {
                province = getStateFromAddress(_.get(processor, 'location.description', ''));
            }
        } else if (isEXPRegion()) {
            province = 'BC';
        } else if (isCONRegion()) {
            province = 'QC';
        } else {
            province = 'AB';
        }

        for (let line of _.get(journalEntry, 'intuitJournalEntry.Line', [])) {
            const accountKey = _.get(line, 'JournalEntryLineDetail.AccountRef.value');
            csvData.push({
                'Line No': lineNo,
                'Transaction Date': transactionDateFormatted,
                Account: _.get(enteredAccountNames, accountKey, accountKey),
                Amount:
                    _.get(line, 'Amount', 0) *
                    (_.get(line, 'JournalEntryLineDetail.PostingType', 'Credit') === 'Credit' ? 1 : -1),
                Type: type,
                Transporter: _.get(transporter, 'name', ''),
                Processor: _.get(processor, 'name', ''),
                'State/Province': province,
                Description: _.get(line, 'Description')

                //BulkId: _.get(journalEntry, 'bulk')
            });
        }
    }
    return csvData;
}
