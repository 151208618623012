import { useState, useContext, useEffect } from 'react';
import _ from 'lodash';
import { downloadObjectAsCSV, isEXPRegion } from 'utils/misc';
import HttpContext from 'utils/contexts/HttpContext';
import moment from 'moment-timezone';
import SnackbarContext from 'components/CustomSnackbar/SnackbarContext';

import bulkHelper from 'helpers/bulkHelper';

function useBulkStats({ startDate, endDate, collectors, initialCollector = null, pickupsEnabled }) {
    const orderTypes = pickupsEnabled
        ? ['pickup', 'inhouse', 'walk-in', 'adjustment']
        : ['inhouse', 'walk-in', 'adjustment'];
    const orderTypesString = pickupsEnabled ? 'pickup,inhouse,walk-in,adjustment' : 'inhouse,walk-in,adjustment';
    const http = useContext(HttpContext);
    const onSnackbar = useContext(SnackbarContext);
    const [counters, setCounters] = useState([]);
    const [bulkList, setBulkList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [collectorsSelected, setCollectorsSelected] = useState(
        !_.isNil(initialCollector) ? [initialCollector._id] : () => collectors.map(c => c._id)
    );
    const [groupBy, setGroupBy] = useState(isEXPRegion() ? 'Bulk' : 'Counter'); // "Counter" | "Bulk"
    const [orderTypesSelected, setOrdersTypeSelected] = useState(
        (localStorage.getItem(`orderTypesSelected`) || orderTypesString).split(',').filter(s => !_.isEmpty(s)) ||
            orderTypes
    );

    useEffect(() => {
        if (!_.isNil(initialCollector)) {
            setCollectorsSelected([initialCollector._id]);
        }
    }, [initialCollector]);

    const handleFetchReport = async () => {
        setLoading(true);
        if (_.isEmpty(collectorsSelected)) {
            onSnackbar('Please select a collector', 'warning');
            setLoading(false);
            return;
        }

        let url = `/bulks/stats?collector=${collectorsSelected}&startDate=${startDate}&endDate=${endDate}&orderTypes=${orderTypesSelected}`;
        const res = await http.getJSON(url, true);
        if (res.ok) {
            if (_.isEmpty(res.data.counterList) || _.isEmpty(res.data.bulkList)) {
                onSnackbar('No counts found with provided collector and date range', 'info');
            } else {
                setCounters(res.data.counterList);
                setBulkList(res.data.bulkList);
            }
        } else {
            onSnackbar(res.errorMessage, 'error');
        }
        setLoading(false);
    };

    const handleToggleCountersSelected = counter_id => {
        // group by counter toggle
        const updatedCounters = _.cloneDeep(counters);
        const index = updatedCounters.findIndex(e => e._id === counter_id);
        updatedCounters[index].selected = !updatedCounters[index].selected;
        setCounters(updatedCounters);

        //group by bulk toggle
        const updatedBulkList = _.cloneDeep(bulkList);
        updatedBulkList.forEach(b => {
            if (!_.isNil(_.get(b, `reportStats.${counter_id}`))) {
                b.reportStats[counter_id].selected = !_.get(b, `reportStats.${counter_id}.selected`, false);
            }
            let isSelected = Object.keys(b.reportStats).some(id => b.reportStats[id].selected === true);
            b.selected = isSelected;
        });
        setBulkList(updatedBulkList);
    };

    const handleExportBulkStats = async () => {
        let report = [];
        let { newMaterialList, newAmountMaterialList } = getMaterialList(counters);
        if (groupBy === 'Counter') {
            for (let counter of counters) {
                if (counter.selected) {
                    let bulks = counter.bulks;
                    for (let bulk of bulks) {
                        let reportItem = {
                            employee: `${_.get(counter, 'name.first', '')} ${_.get(counter, 'name.last', '')}`,
                            label: bulk.label,
                            qty: _.get(bulk, `reportStats.${counter._id}.quantity`)
                        };
                        for (let i = 0; i < newMaterialList.length; i++) {
                            let material = newMaterialList[i];
                            reportItem[`qty (${material})`] = _.get(
                                bulk,
                                `reportStats.${counter._id}.${material}.quantity`,
                                0
                            );
                        }
                        reportItem.amount = _.get(bulk, `reportStats.${counter._id}.amount`);
                        for (let i = 0; i < newAmountMaterialList.length; i++) {
                            let material = newAmountMaterialList[i];
                            reportItem[`amount (${material})`] = _.get(
                                bulk,
                                `reportStats.${counter._id}.${material}.amount`,
                                0
                            );
                        }
                        reportItem.fees = _.get(bulk, `reportStats.${counter._id}.fee`);
                        reportItem.dateCompleted = bulk.dateCompleted;
                        reportItem.collector = _.get(bulk, 'collector.name', '');
                        report.push(reportItem);
                    }
                }
            }
        } else {
            for (let bulk of bulkList) {
                if (bulk.selected) {
                    let reportStats = bulk.reportStats;
                    Object.keys(reportStats).forEach(counterId => {
                        let stat = reportStats[counterId];
                        if (stat.selected) {
                            const reportItem = {
                                label: bulk.label,
                                employee: `${_.get(stat, 'name.first', '')} ${_.get(stat, 'name.last', '')}`,
                                qty: _.get(stat, 'quantity', 0),
                                amount: _.get(stat, 'amount', 0),
                                fees: _.get(stat, 'fee', 0),
                                dateCompleted: bulk.dateCompleted,
                                collector: _.get(bulk, 'collector.name', '')
                            };

                            if (isEXPRegion()) {
                                reportItem['Counter'] = bulkHelper.getCountedBy(bulk);
                            }

                            if (!isEXPRegion()) {
                                for (let i = 0; i < newMaterialList.length; i++) {
                                    let material = newMaterialList[i];
                                    reportItem[`qty (${material})`] = _.get(
                                        bulk,
                                        `reportStats.${counterId}.${material}.quantity`,
                                        0
                                    );
                                }
                                for (let i = 0; i < newAmountMaterialList.length; i++) {
                                    let material = newAmountMaterialList[i];
                                    reportItem[`amount (${material})`] = _.get(
                                        bulk,
                                        `reportStats.${counterId}.${material}.amount`,
                                        0
                                    );
                                }
                            }

                            report.push(reportItem);
                        }
                    });
                }
            }
        }

        await downloadObjectAsCSV(
            report,
            `clerk_report_${moment(startDate)
                .tz(process.env.REACT_APP_REGION_TIMEZONE)
                .format('YYYYMMDD')}_to_${moment(endDate)
                .tz(process.env.REACT_APP_REGION_TIMEZONE)
                .format('YYYYMMDD')}`
        );
    };

    // const getCollectorNameFromCollectors = function(bulk, collectors) {
    //     const targetCollector = collectors.find(collector => bulk.collector === collector._id);
    //     return _.get(targetCollector, 'name', '');
    // };

    const handleCollectorsSelected = e => {
        setCollectorsSelected(e.target.value);
    };

    const handleAllFilterCollectors = all => e => {
        e.stopPropagation();
        if (all) {
            setCollectorsSelected(collectors.map(c => c._id));
        } else {
            setCollectorsSelected([]);
        }
    };

    const handleOrderTypesSelected = e => {
        setOrdersTypeSelected(e.target.value);
        localStorage.setItem('orderTypesSelected', e.target.value);
    };

    function getMaterialList(countersStats) {
        let newMaterialListSet = {};
        let newAmountMaterialListSet = {};
        for (let i = 0; i < countersStats.length; i++) {
            let currentCounter = countersStats[i];
            let currentBulks = _.get(currentCounter, 'bulks', []);
            for (let j = 0; j < currentBulks.length; j++) {
                let currentBulk = currentBulks[j];
                let reportStats = currentBulk.reportStats;
                Object.keys(reportStats).forEach(stat => {
                    let currentStat = _.get(reportStats, stat, []);
                    Object.keys(currentStat).forEach(material => {
                        // if is actual material and not stat
                        if (
                            material !== 'amount' &&
                            material !== 'quantity' &&
                            !_.isNil(currentStat[material].amount) &&
                            !_.isNil(currentStat[material].quantity)
                        ) {
                            if (_.get(currentStat, `${material}.amount`, 0) > 0) {
                                newAmountMaterialListSet[material] = material;
                            }
                            newMaterialListSet[material] = _.get(currentStat, `${material}.sku`, '');
                        }
                    });
                });
            }
        }
        return {
            newMaterialList: Object.keys(newMaterialListSet).sort(),
            newAmountMaterialList: Object.keys(newAmountMaterialListSet).sort(),
            materialToSkuMap: newMaterialListSet
        };
    }

    return {
        counters,
        bulkList,
        loading,
        collectorsSelected,
        groupBy,
        setGroupBy,
        orderTypesSelected,
        handleToggleCountersSelected,
        handleExportBulkStats,
        handleFetchReport,
        handleCollectorsSelected,
        handleAllFilterCollectors,
        getMaterialList,
        handleOrderTypesSelected
        // getCollectorNameFromCollectors
    };
}

export default useBulkStats;
