import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';

import _ from 'lodash';

import { getDeviceInformation } from 'utils/misc.js';
import { _time } from 'std';

import CustomSnackbar from 'components/CustomSnackbar/CustomSnackbar';
import useWindowSize from 'utils/hooks/useWindowSize';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';

import Icon from '@material-ui/core/Icon';

import LocalizationContext from 'utils/contexts/LocalizationContext';

function DepotLogin(props) {
    const { theme, history } = props;
    const collectorID = props.match.params.collectorID;
    const [depotLoginID, setDepotLoginID] = React.useState('');
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const [snackbarVariant, setSnackbarVariant] = React.useState('error');
    const [snackbarMessage, setSnackbarMessage] = React.useState('');
    const [attemptingLogin, setAttemptingLogin] = React.useState(false);
    const [width, height] = useWindowSize();
    let mobileMode = width <= 700;
    let buttonHeight = mobileMode ? 70 : 100;
    let buttonWidth = mobileMode ? 70 : 100;
    // UPDATE IMAGE DIMENSION ON IMAGE CHANGE
    const logoWidth = 655;
    // const logoHeight = 73;

    const { setLang } = useContext(LocalizationContext);

    function handleDepotLoginIDChange(newDepotLoginID) {
        if (newDepotLoginID.length < 5) {
            setDepotLoginID(newDepotLoginID);
        }
    }

    return (
        <div
            style={{
                flexDirection: 'column',
                backgroundColor: 'white',
                height: '100%'
            }}
        >
            <img
                style={{
                    display: 'block',
                    margin: 'auto',
                    paddingTop: '50px',
                    marginBottom: '50px',
                    // aspectRatio: logoWidth / logoHeight,
                    height: 'auto',
                    width: mobileMode ? width - 20 : logoWidth
                }}
                alt={'depot logo'}
                src={require(`../../icons/depot-login-header-${process.env.REACT_APP_REGION_EXT}.png`)}
            />
            <div
                style={{
                    flexDirection: mobileMode ? 'column' : 'row',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <div
                    style={{
                        flexDirection: mobileMode ? 'row' : 'column',
                        display: 'flex',
                        width: '280px',
                        marginRight: mobileMode ? '0px' : '20px',
                        marginBottom: mobileMode ? '10px' : '55px'
                    }}
                >
                    {attemptingLogin ? (
                        <div style={{ margin: '0 auto', marginTop: '10px', textAlign: 'center' }}>
                            <CircularProgress size={42} style={{ color: theme.palette.envColor[500] }} />
                        </div>
                    ) : (
                        <TextField
                            data-cy="depotLoginID"
                            value={depotLoginID}
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                    <Icon style={{ marginRight: theme.spacing.unit, color: '#008ece' }}>person</Icon>
                                ),
                                disableUnderline: true
                            }}
                            variant="outlined"
                            type="Password"
                            placeholder="User ID"
                            autoComplete="new-password"
                            onChange={event => {
                                let newDepotLoginID = event.target.value.replace(/\D/g, '');
                                handleDepotLoginIDChange(newDepotLoginID);
                            }}
                            disabled={attemptingLogin}
                        />
                    )}
                </div>
                <div
                    style={{
                        flexDirection: 'column'
                    }}
                >
                    <div style={{}}>
                        <Button
                            variant="outlined"
                            style={{ width: buttonWidth, height: buttonHeight }}
                            onClick={() => {
                                handleDepotLoginIDChange(depotLoginID + '7');
                            }}
                            disabled={attemptingLogin}
                        >
                            7
                        </Button>
                        <Button
                            variant="outlined"
                            style={{ width: buttonWidth, height: buttonHeight }}
                            onClick={() => {
                                handleDepotLoginIDChange(depotLoginID + '8');
                            }}
                            disabled={attemptingLogin}
                        >
                            8
                        </Button>
                        <Button
                            variant="outlined"
                            style={{ width: buttonWidth, height: buttonHeight }}
                            onClick={() => {
                                handleDepotLoginIDChange(depotLoginID + '9');
                            }}
                            disabled={attemptingLogin}
                        >
                            9
                        </Button>
                        <Button
                            variant="outlined"
                            style={{ width: buttonWidth, height: buttonHeight }}
                            onClick={() => {
                                handleDepotLoginIDChange(depotLoginID.substring(0, depotLoginID.length - 1));
                            }}
                            disabled={attemptingLogin}
                        >
                            back
                        </Button>
                    </div>
                    <div style={{}}>
                        <Button
                            variant="outlined"
                            style={{ width: buttonWidth, height: buttonHeight }}
                            onClick={() => {
                                handleDepotLoginIDChange(depotLoginID + '4');
                            }}
                            disabled={attemptingLogin}
                        >
                            4
                        </Button>
                        <Button
                            variant="outlined"
                            style={{ width: buttonWidth, height: buttonHeight }}
                            onClick={() => {
                                handleDepotLoginIDChange(depotLoginID + '5');
                            }}
                            disabled={attemptingLogin}
                        >
                            5
                        </Button>
                        <Button
                            variant="outlined"
                            style={{ width: buttonWidth, height: buttonHeight }}
                            onClick={() => {
                                handleDepotLoginIDChange(depotLoginID + '6');
                            }}
                            disabled={attemptingLogin}
                        >
                            6
                        </Button>
                        <Button
                            variant="outlined"
                            style={{ width: buttonWidth, height: buttonHeight }}
                            onClick={() => {
                                handleDepotLoginIDChange('');
                            }}
                            disabled={attemptingLogin}
                        >
                            clear
                        </Button>
                    </div>
                    <div
                        style={{
                            flexDirection: 'row',
                            display: 'flex'
                        }}
                    >
                        <div
                            style={{
                                flexDirection: 'column'
                            }}
                        >
                            <div style={{}}>
                                <Button
                                    variant="outlined"
                                    style={{ width: buttonWidth, height: buttonHeight }}
                                    onClick={() => {
                                        handleDepotLoginIDChange(depotLoginID + '1');
                                    }}
                                    disabled={attemptingLogin}
                                >
                                    1
                                </Button>
                                <Button
                                    variant="outlined"
                                    style={{ width: buttonWidth, height: buttonHeight }}
                                    onClick={() => {
                                        handleDepotLoginIDChange(depotLoginID + '2');
                                    }}
                                    disabled={attemptingLogin}
                                >
                                    2
                                </Button>
                                <Button
                                    variant="outlined"
                                    style={{ width: buttonWidth, height: buttonHeight }}
                                    onClick={() => {
                                        handleDepotLoginIDChange(depotLoginID + '3');
                                    }}
                                    disabled={attemptingLogin}
                                >
                                    3
                                </Button>
                            </div>
                            <div style={{}}>
                                <Button
                                    variant="outlined"
                                    style={{ width: buttonWidth * 2, height: buttonHeight }}
                                    onClick={() => {
                                        handleDepotLoginIDChange(depotLoginID + '0');
                                    }}
                                    disabled={attemptingLogin}
                                >
                                    0
                                </Button>
                                <Button
                                    variant="outlined"
                                    style={{ width: buttonWidth, height: buttonHeight }}
                                    onClick={() => {
                                        handleDepotLoginIDChange(depotLoginID + '.');
                                    }}
                                    disabled={attemptingLogin}
                                >
                                    .
                                </Button>
                            </div>
                        </div>
                        <Button
                            variant="outlined"
                            style={{ width: buttonWidth, height: buttonHeight * 2 }}
                            onClick={e => {
                                login(e);
                            }}
                            disabled={attemptingLogin}
                            data-cy="depot-login-submit"
                        >
                            enter
                        </Button>
                    </div>
                </div>
            </div>
            <CustomSnackbar
                open={snackbarOpen}
                message={snackbarMessage}
                variant={snackbarVariant}
                onClose={() => {
                    setSnackbarOpen(false);
                }}
            />
        </div>
    );

    async function login(e) {
        e.preventDefault();

        if (_.isEmpty(depotLoginID)) {
            setAttemptingLogin(false);
            setSnackbarMessage('Please enter your user ID');
            setSnackbarOpen(true);
            setSnackbarVariant('error');
            setDepotLoginID('');
            return;
        }

        let deviceInfo = await getDeviceInformation();
        let form = {
            depotLoginID: depotLoginID,
            deviceInfo,
            timezone: _time.getTimezone(),
            collectorID: collectorID
        };
        setAttemptingLogin(true);

        const Break = {};
        fetch(process.env.REACT_APP_API_URL + '/depotLogin', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify(form)
        })
            .then(async res => {
                setDepotLoginID('');
                if (res.status === 200) {
                    const json = await res.json();
                    return json;
                } else if (res.status === 401) {
                    setAttemptingLogin(false);
                    setSnackbarMessage('Invalid user ID');
                    setSnackbarOpen(true);
                    setSnackbarVariant('error');
                    throw new Break();
                } else if (res.status === 418) {
                    setAttemptingLogin(false);
                    setSnackbarMessage('IP not authorized.');
                    setSnackbarOpen(true);
                    setSnackbarVariant('error');
                    throw new Break();
                } else if (res.status === 419) {
                    setAttemptingLogin(false);
                    setSnackbarMessage('This collector is not registered in the system, please check the link');
                    setSnackbarOpen(true);
                    setSnackbarVariant('error');
                    throw new Break();
                } else if (res.status === 502) {
                    setAttemptingLogin(false);
                    setSnackbarMessage('Server is unreachable');
                    setSnackbarOpen(true);
                    setSnackbarVariant('error');
                    throw new Break();
                } else {
                    setAttemptingLogin(false);
                    setSnackbarMessage('Server error occurred');
                    setSnackbarOpen(true);
                    setSnackbarVariant('error');
                    throw new Break();
                }
            })
            .then(async data => {
                let homeURL = _.get(data, 'depotLoginRecentPage', null);
                if (_.isNil(homeURL) || _.isEmpty(homeURL)) {
                    switch (data.accountType) {
                        case 'Customer':
                            homeURL = '/customers/' + data._id;
                            if (!_.isNil(data.charity)) {
                                homeURL += '/charity';
                            }
                            break;
                        case 'Collector Employee':
                            homeURL = `/operators/${data._id}/driver`;
                            break;
                        case 'Collector Administrator':
                            homeURL = `/operators/${data._id}/collector`;
                            break;
                        case 'System Administrator':
                            homeURL = '/operators/' + data._id;
                            break;
                        default:
                            throw new Error('Unexpected account type.');
                    }
                }
                if (data.lang) {
                    setLang(data.lang);
                }
                props.dispatch({
                    type: 'SET_AUTH_TRUE',
                    accountType: data.accountType,
                    name: !_.isNil(data.name) ? data.name.first + ' ' + data.name.last : undefined,
                    _id: data._id,
                    collector_id: _.get(data, 'collector._id', undefined),
                    home: homeURL,
                    accountPermissions: data.accountPermissions,
                    multipleAccountAccessList: data.multipleAccountAccessList,
                });
                history.push(homeURL);
            })
            .catch(Break => {})
            .catch(err => {
                console.error('POST /login', err);
                setAttemptingLogin(false);
                setSnackbarMessage('Connection error occurred');
                setSnackbarOpen(true);
                setSnackbarVariant('error');
            });
    }
}

export default withRouter(DepotLogin);
