import React from 'react';
import { withTheme } from '@material-ui/core/styles';
import {
    Button,
    Checkbox,
    FormControl,
    ListItemText,
    MenuItem,
    Select,
    InputLabel,
    OutlinedInput
} from '@material-ui/core';

function CollectorDashboardDropDown({
    theme,
    loading,
    collectors,
    cypress_id,
    handleCollectorsSelected,
    handleAllFilterCollectors,
    collectorsSelected,
    label = 'Collectors',
    labelCustomWidth
}) {
    return (
        <FormControl
            style={{
                marginTop: 10,
                marginRight: 10
            }}
        >
            <InputLabel variant="outlined" htmlFor="collector-filter">
                {label}
            </InputLabel>
            <Select
                multiple
                data-cy={cypress_id}
                onChange={handleCollectorsSelected}
                disabled={loading || collectors.length <= 1}
                inputProps={{
                    name: 'collector-filter',
                    id: 'collector-filter'
                }}
                style={{
                    width: '150px'
                }}
                input={
                    <OutlinedInput labelWidth={labelCustomWidth || 36} name="collector-filter" id="collector-filter" />
                }
                value={collectorsSelected}
                displayEmpty // if true, the selected item will be rendered even if the value is empty
                renderValue={s => `${s.length} ${label}`}
            >
                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                    <Button disabled={loading} onClick={handleAllFilterCollectors(true)}>
                        All
                    </Button>
                    <Button disabled={loading} onClick={handleAllFilterCollectors(false)}>
                        None
                    </Button>
                </div>
                {collectors.map(({ _id, name }) => (
                    <MenuItem key={_id} value={_id} disabled={loading}>
                        <Checkbox checked={collectorsSelected.includes(_id)} />
                        <ListItemText>{name}</ListItemText>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default withTheme()(CollectorDashboardDropDown);
