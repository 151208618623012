import React, { useEffect, useState } from 'react';
import _ from 'lodash';

import { withTheme } from '@material-ui/core/styles';
import {
    Tooltip,
    IconButton,
    Icon,
    colors,
    withStyles,
    Badge,
    FormControl,
    InputLabel,
    Select,
    OutlinedInput,
    MenuItem
} from '@material-ui/core';
import { Icon as MDIcon } from '@mdi/react';
import * as allIcons from '@mdi/js';

import OTCGroupForm from './Forms/OTCGroupForm';

import CRUDTable from './CRUDTable';
import useCRUD from './hooks/useCRUD';

import { useContext } from 'react';

import HttpContext from 'utils/contexts/HttpContext';

import { _commodity, _user } from 'std';
import ConfirmDialogContext from 'components/Dialogs/Confirm/ConfirmDialogContext';

const styles = theme => ({
    badgeOk: {
        backgroundColor: colors.green[500],
        color: 'white'
    },
    badgeError: {
        backgroundColor: colors.red[500],
        color: 'white'
    }
});

function OTCGroupTable(props) {
    const { theme, operator, onSnackbar, setGroupFilter, handleToggleTableTab, allCollectors } = props;
    const isMultiCollector = _.get(operator, 'collectors', []).length > 1;
    const managedCollectorIds = _.get(operator, 'collectors', []).map(c => c._id);

    const http = useContext(HttpContext);
    const warnAction = useContext(ConfirmDialogContext);

    const [selectedDocument, setSelectedDocument] = useState(null);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [activeOrderBy, setActiveOrderBy] = useState(null);
    const [activeOrder, setActiveOrder] = useState('asc');
    const [selectedCollector, setSelectedCollector] = useState('all');
    const [collectors, setCollectors] = useState([]);

    const OTCGroupEndpoints = {
        getEndPoint: `/getAllExternalGroups`,
        getEditEndPoint: _id => `/updateExternalGroup/${_id}`, // url to edit documents NOTE: this should be a function
        createEndPoint: '/createExternalGroup' // url to add new documents
    };
    let OTCGroupQuery = `&collector=${selectedCollector}`;
    if (isMultiCollector) {
        OTCGroupQuery += `&collectors=${managedCollectorIds}`;
    } else if (_user.isCollectorAdmin(operator)) {
        OTCGroupQuery = `&collector=${_.get(operator, 'collector._id', null)}`;
    }
    const { data: allOTCGroups, loading, handleCreate, handleEdit, handleReloadData } = useCRUD({
        endpoints: OTCGroupEndpoints,
        setEditDialogOpen,
        setActiveOrderBy,
        setActiveOrder,
        http,
        onSnackbar,
        getQuery: OTCGroupQuery
    });
    useEffect(() => {
        if (!_.isEmpty(collectors)) return;
        const newCollectors = _.uniqBy(
            [...allOTCGroups.map(obj => _.get(obj, 'collector'))].filter(obj => obj !== undefined),
            '_id'
        );
        setCollectors(newCollectors);
    }, [allOTCGroups]);
    useEffect(() => {
        handleReloadData();
    }, [selectedCollector]);

    const OTClocationEndpoints = {
        getEndPoint: `/getAllExternalLocations`,
        getEditEndPoint: _id => `/updateExternalLocation/${_id}`, // url to edit documents NOTE: this should be a function
        createEndPoint: '/createExternalLocation' // url to add new documents
    };
    const { data: allOTCLocations } = useCRUD({
        endpoints: OTClocationEndpoints,
        setEditDialogOpen,
        setActiveOrderBy,
        setActiveOrder,
        http,
        onSnackbar
    });

    let columns = [
        {
            key: 'name',
            header: 'Name'
        },
        { key: 'email', header: 'Email' },
        {
            key: 'defaultIcon',
            header: 'Icon',
            formatValue: (value, rowData) => (
                <MDIcon
                    path={_.get(allIcons, _.camelCase(`mdi-${value}`))}
                    size={1}
                    color={
                        _.isNil(colors[_.get(rowData, 'defaultColor', 'black')])
                            ? _.get(rowData, 'defaultColor', 'black')
                            : colors[_.get(rowData, 'defaultColor', 'black')][500]
                    }
                />
            )
        }
    ];
    if (_user.isSystemAdmin(operator) || (_user.isCollectorAdmin(operator) && isMultiCollector)) {
        columns.push({
            key: 'collector.name',
            header: 'Collector',
            formatValue: value => (value ? value : 'N/A')
        });
    }
    columns.push({ key: 'accessCode', header: 'Access Code' });

    const handleDeleteOTCGroup = async OTCGroupId => {
        const res = await http.post(`/deleteExternalGroup`, { _id: OTCGroupId });
        if (res.ok) {
            onSnackbar('Group Removed');
            handleReloadData();
        }
    };
    const defaultColumnFilters = columns.map(c => c.key);
    const editForm = (
        <OTCGroupForm
            OTCGroup={selectedDocument}
            allOTCGroup={allOTCGroups}
            onSubmit={_.isNil(selectedDocument) ? handleCreate : handleEdit}
            loading={loading}
            editing={!_.isNil(selectedDocument)}
            open={editDialogOpen}
            onClose={() => setEditDialogOpen(false)}
            operator={operator}
            allCollectors={allCollectors}
        />
    );
    const onEditRow = document => {
        setSelectedDocument(document);
        setEditDialogOpen(true);
    };

    const renderExtraActions = document => {
        const groupId = _.get(document, '_id', '');
        const locationFromGroup = allOTCLocations.filter(l => _.get(l, 'group._id') === groupId);
        return (
            <>
                <Tooltip title="Edit">
                    <IconButton
                        data-cy={`edit-button`}
                        onClick={e => {
                            onEditRow(document);
                        }}
                    >
                        <Icon>edit</Icon>
                    </IconButton>
                </Tooltip>
                <Tooltip title="View locations">
                    <IconButton
                        data-cy={`view-button`}
                        onClick={e => {
                            setGroupFilter(_.get(document, '_id', ''));
                            handleToggleTableTab('location');
                        }}
                    >
                        <Badge badgeContent={locationFromGroup.length} color="primary" showZero>
                            <Icon>store</Icon>
                        </Badge>
                    </IconButton>
                </Tooltip>
                <Tooltip title="View past pickups">
                    <IconButton
                        data-cy={`history-button`}
                        onClick={e => {
                            props.history.push(`/operators/${operator._id}/stops?OTCGroupFilter=${groupId}`);
                        }}
                    >
                        <Icon>history</Icon>
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                    <IconButton
                        data-cy={`edit-button`}
                        onClick={e => {
                            for (let i = 0; i < allOTCLocations.length; i++) {
                                const groupId = _.get(allOTCLocations[i], 'group._id');
                                if (groupId === _.get(document, '_id')) {
                                    onSnackbar('Cannot delete groups with existing locations', 'error');
                                    return;
                                }
                            }

                            warnAction(() => {
                                handleDeleteOTCGroup(_.get(document, '_id'));
                            }, 'Are you sure you want to delete this group?');
                        }}
                    >
                        <Icon>delete</Icon>
                    </IconButton>
                </Tooltip>
            </>
        );
    };

    return (
        <>
            <CRUDTable
                operator={operator}
                columns={columns}
                data={allOTCGroups}
                editForm={editForm}
                documentIsDisabled={g => _.get(g, 'disabled', false)}
                defaultRowsPerPage={10}
                selectedDocument={selectedDocument}
                setSelectedDocument={setSelectedDocument}
                editDialogOpen={editDialogOpen}
                setEditDialogOpen={setEditDialogOpen}
                activeOrderBy={activeOrderBy}
                setActiveOrderBy={setActiveOrderBy}
                activeOrder={activeOrder}
                setActiveOrder={setActiveOrder}
                enabledHeaderText={'OTC Groups'}
                loading={loading}
                renderExtraActions={renderExtraActions}
                hideDateFilters
                defaultColumnFilters={defaultColumnFilters}
                hideEditButton={true}
                hideViewJSON={true}
                showEmptyTable
                showCollectorFilter={false}
                customFilters={
                    (_user.isSystemAdmin(operator) || (_user.isCollectorAdmin(operator) && isMultiCollector)) && (
                        <FormControl variant="outlined">
                            <InputLabel htmlFor="collection">Collector</InputLabel>
                            <Select
                                input={<OutlinedInput id="collection" name="collection" labelWidth={65} />}
                                value={selectedCollector}
                                onChange={e => setSelectedCollector(e.target.value)}
                                style={{
                                    width: '200px',
                                    marginRight: 8
                                }}
                            >
                                <MenuItem key={'all'} value={'all'}>
                                    All
                                </MenuItem>
                                {collectors.map(c => (
                                    <MenuItem key={_.get(c, '_id')} value={_.get(c, '_id')}>
                                        {_.get(c, 'name')}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )
                }
            />
        </>
    );
}

export default withStyles(styles)(withTheme()(OTCGroupTable));
