import React, { useEffect } from 'react';

import { deviceHelper } from 'utils/misc';

function useIdleLogout({ auth, onLogout, timeout = 10 * 60 * 1000 }) {
    useEffect(() => {
        let idleLogoutTimeout;

        function resetTimer() {
            clearTimeout(idleLogoutTimeout);
            idleLogoutTimeout = setTimeout(onLogout, timeout); // time is in milliseconds
        }

        if (timeout > 0) {
            if (auth.isAuthenticated && !deviceHelper.isNativeApp()) {
                //Modified from https://stackoverflow.com/a/24989958
                window.addEventListener('load', resetTimer);
                window.addEventListener('mousemove', resetTimer);
                window.addEventListener('mousedown', resetTimer);
                window.addEventListener('touchstart', resetTimer);
                window.addEventListener('touchmove', resetTimer);
                window.addEventListener('click', resetTimer);
                window.addEventListener('keydown', resetTimer);
                window.addEventListener('scroll', resetTimer, true);
                resetTimer();
                /*window.onload = resetTimer;
            window.onmousemove = resetTimer;
            window.onmousedown = resetTimer; // catches touchscreen presses as well
            window.ontouchstart = resetTimer; // catches touchscreen swipes as well
            window.ontouchmove = resetTimer; // required by some devices
            window.onclick = resetTimer; // catches touchpad clicks as well
            window.onkeydown = resetTimer;
            window.addEventListener('scroll', resetTimer, true); // improved; see comments*/
            }
        }

        return () => {
            clearTimeout(idleLogoutTimeout);
            window.removeEventListener('load', resetTimer);
            window.removeEventListener('mousemove', resetTimer);
            window.removeEventListener('mousedown', resetTimer);
            window.removeEventListener('touchstart', resetTimer);
            window.removeEventListener('touchmove', resetTimer);
            window.removeEventListener('click', resetTimer);
            window.removeEventListener('keydown', resetTimer);
            window.removeEventListener('scroll', resetTimer, true);
        };
    }, [auth, timeout]);
}

export default useIdleLogout;
