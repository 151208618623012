import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    FormControl,
    FormControlLabel,
    InputLabel,
    OutlinedInput,
    MenuItem,
    TextField,
    Switch,
    Select,
    withMobileDialog,
    withTheme,
    Collapse
} from '@material-ui/core';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

function CreateRatesDialog({ open, rates, onClose, onCreateRates, theme, fullScreen }) {
    const { lang } = useContext(LocalizationContext);

    const [ratesName, setRatesName] = useState('');
    const [copyExistingRates, setCopyExistingRates] = useState(false);
    const [ratesIdToCopy, setRatesIdToCopy] = useState(_.get(_.first(rates), '_id', null));

    const handleCreateRates = () => {
        let newRates = {
            name: ratesName,
            rates: []
        };
        if (copyExistingRates) {
            const ratesToCopy = _.find(rates, { _id: ratesIdToCopy });
            newRates.rates = ratesToCopy.rates;
        }
        onCreateRates(newRates);
    };

    useEffect(() => {
        setRatesIdToCopy(_.get(_.first(rates), '_id', null));
    }, [rates]);

    return (
        <Dialog onClose={onClose} open={open} fullScreen={fullScreen}>
            <DialogTitle>Create Rates</DialogTitle>
            <DialogContent>
                <TextField
                    margin="normal"
                    fullWidth
                    name="ratesName"
                    label="Rates Name"
                    value={ratesName}
                    variant="outlined"
                    onChange={e => setRatesName(e.target.value)}
                />
                <FormControlLabel
                    margin="normal"
                    fullWidth
                    control={
                        <Switch
                            checked={copyExistingRates}
                            onChange={e => {
                                setCopyExistingRates(e.target.checked);
                            }}
                            color="primary"
                        />
                    }
                    label="Copy from existing rates"
                />
                <Collapse in={copyExistingRates}>
                    <FormControl
                        margin="normal"
                        fullWidth
                        style={{ marginBottom: theme.spacing.unit, marginTop: theme.spacing.unit * 3 }}
                    >
                        <InputLabel variant="outlined" htmlFor="reason">
                            Rates to copy
                        </InputLabel>
                        <Select
                            value={ratesIdToCopy}
                            onChange={e => setRatesIdToCopy(e.target.value)}
                            input={
                                <OutlinedInput
                                    labelWidth={'Rates to copy'.length * theme.spacing.unit}
                                    name="receiver"
                                    id="outlined-age-simple"
                                />
                            }
                        >
                            {rates.map(r => {
                                return (
                                    <MenuItem value={r._id} key={r._id}>
                                        {_.get(r, 'name', '')}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Collapse>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button type="submit" color="primary" onClick={handleCreateRates}>
                    {loc('submit', lang)}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default withMobileDialog()(withTheme()(CreateRatesDialog));
