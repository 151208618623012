import React, { useContext } from 'react';
import _ from 'lodash';

import * as colors from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withTheme } from '@material-ui/core/styles';

import { loc } from 'localizations/localizationHandler';
import LocalizationContext from 'utils/contexts/LocalizationContext';
import ConfirmDialogContext from 'components/Dialogs/Confirm/ConfirmDialogContext';
import { isCONRegion } from 'utils/misc';

function VerifyCollectionWidget(props) {
    const { theme, customer } = props;

    // const warnAction = useContext(ConfirmDialogContext);
    const { lang } = useContext(LocalizationContext);

    const WARN_STATUSES = ['pending', 'rejected'];
    const verificationStatus = _.get(customer, 'collectionVerification.status');
    if (!WARN_STATUSES.includes(verificationStatus)) {
        return <></>;
    }

    return (
        <React.Fragment>
            <Paper
                style={{
                    width: `calc(100% - ${theme.spacing.unit * 4}px)`,
                    margin: theme.spacing.unit * 2,
                    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px`,
                    background: verificationStatus == 'pending' ? colors.yellow[500] : colors.red[500]
                }}
            >
                <Typography
                    style={{
                        color: verificationStatus == 'pending' ? theme.palette.common.black : theme.palette.common.white
                    }}
                >
                    {verificationStatus === 'pending'
                        ? loc('dashboardUpdate12', lang, { collectionProgram: isCONRegion() ? 'eCSP' : 'IC&I' })
                        : loc('dashboardUpdate13', lang, {
                              collectionProgram: isCONRegion() ? 'eCSP' : 'IC&I',
                              reasons: _.get(customer, 'collectionVerification.rejectionReasons', []).join(', ')
                          })}
                </Typography>
            </Paper>
        </React.Fragment>
    );
}

export default withTheme()(VerifyCollectionWidget);
