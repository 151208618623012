import React from 'react';
import _ from 'lodash';

import { withTheme } from '@material-ui/core/styles';

import { TextField, colors, InputAdornment, MenuItem, Menu, Icon, withStyles } from '@material-ui/core';
import CustomWrapper from 'components/BulkComponents/CustomWrapper';

import { _commodity } from 'std';

import { COMMISSION_TYPES } from '../../../constants';

import { Icon as MDIcon } from '@mdi/react';
import * as allIcons from '@mdi/js';

import { mdiSack, mdiCounter } from '@mdi/js';
import { useState } from 'react';

const iconForCommissionType = {
    Counts: mdiCounter,
    'Commodity Amount': mdiSack
};

function CommissionRatesInput(props) {
    const {
        theme,
        onChange,
        value,
        commodities,
        title = 'Commission',
        endAdornment = null,
        startAdornment = null,
        disabled
    } = props;

    //const [commodityTypeSelectEl, setCommodityTypeSelectEl] = useState(null);

    const handleChange = (e, commodity) => {
        const cloneArr = _.cloneDeep(value);
        let newVal = parseFloat(_.get(e, 'target.value', 0), 10) * 100;
        newVal = Math.round(newVal * 10000) / 10000; //round to 4 decimal places

        let commissionRateObj = _.find(cloneArr, { commodity: commodity._id });
        if (_.isNil(commissionRateObj)) {
            commissionRateObj = {
                commodity: commodity._id,
                skuType: _commodity.getSkuType(commodity),
                rate: newVal,
                commissionType: 'Counts'
            };
            cloneArr.push(commissionRateObj);
        } else {
            commissionRateObj.rate = newVal;
        }

        onChange(cloneArr);
    };

    const handleChangeCommissionType = (type, commodity) => {
        const cloneArr = _.cloneDeep(value);

        let commissionRateObj = _.find(cloneArr, { commodity: commodity._id });
        if (_.isNil(commissionRateObj)) {
            commissionRateObj = {
                commodity: commodity._id,
                skuType: _commodity.getSkuType(commodity),
                rate: 0,
                commissionType: type
            };
            cloneArr.push(commissionRateObj);
        } else {
            commissionRateObj.commissionType = type;
        }

        onChange(cloneArr);
    };

    /*const handleCommodityTypeSelectOpen = e => {
        setCommodityTypeSelectEl(e.currentTarget);
    };*/

    return (
        <CustomWrapper title={title} titleFontSize="13px" style={{ marginTop: 0 }}>
            <div style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'space-between' }}>
                {startAdornment}
                <span style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {commodities.map((commodity, index) => {
                        const commodityIcon = _.get(commodity, 'iconName');

                        const commissionRateObj = _.find(value, { commodity: commodity._id });
                        const commissionRate = _.get(commissionRateObj, 'rate', 0);
                        const commissionRateDollars =
                            Math.round((commissionRate / 100) * 10000) / 10000; /*round to 4 decimal places */

                        const commissionType = _.get(commissionRateObj, 'commissionType', 'Counts');

                        return (
                            <span
                                style={{
                                    display: 'inline-flex',
                                    borderRadius: 4,
                                    borderColor: colors.blue[300],
                                    alignItems: 'center',
                                    marginRight: theme.spacing.unit
                                }}
                                key={commodity._id}
                            >
                                <MDIcon
                                    path={_.get(allIcons, commodityIcon)}
                                    size={1}
                                    color={_.get(commodity, 'color')}
                                />{' '}
                                <TextField
                                    data-cy={`${index}-commission-rate`}
                                    //label={_commodity.getSkuType(commodity)}
                                    type="number"
                                    value={commissionRateDollars}
                                    margin="normal"
                                    style={{
                                        width: 130,
                                        marginLeft: theme.spacing.unit,
                                        marginTop: theme.spacing.unit / 2
                                    }}
                                    onChange={e => handleChange(e, commodity)}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <CommissionTypeSelectBtnWithTheme
                                                    value={commissionType}
                                                    onChange={type => handleChangeCommissionType(type, commodity)}
                                                />
                                            </InputAdornment>
                                        )
                                    }}
                                    disabled={disabled}
                                />
                            </span>
                        );
                    })}
                </span>
                {endAdornment}
            </div>
        </CustomWrapper>
    );
}

export default withTheme()(CommissionRatesInput);

const CommissionTypeSelectBtn = function(props) {
    const { value, onChange, theme, classes } = props;

    const [commodityTypeSelectEl, setCommodityTypeSelectEl] = useState(null);

    const handleCommodityTypeSelectOpen = e => {
        setCommodityTypeSelectEl(e.currentTarget);
    };

    const handleChange = type => {
        setCommodityTypeSelectEl(null);
        onChange(type);
    };

    return (
        <>
            <span onClick={handleCommodityTypeSelectOpen} className={classes.btnSelect}>
                <MDIcon path={iconForCommissionType[value]} size={0.8} />
                <Icon>arrow_drop_down</Icon>
            </span>
            <Menu
                anchorEl={commodityTypeSelectEl}
                open={Boolean(commodityTypeSelectEl)}
                onClose={() => setCommodityTypeSelectEl(null)}
            >
                {COMMISSION_TYPES.map(type => {
                    return (
                        <MenuItem value={type} key={type} onClick={() => handleChange(type)} selected={value === type}>
                            <span style={{ marginRight: theme.spacing.unit, display: 'flex', alignItems: 'center' }}>
                                <MDIcon path={iconForCommissionType[type]} size="24px" />
                            </span>
                            {type === 'Commodity Amount' ? 'Bags/Items' : type}
                        </MenuItem>
                    );
                })}
            </Menu>
        </>
    );
};

const CommissionTypeSelectBtnStyles = theme => ({
    btnSelect: {
        '&:hover': {
            backgroundColor: theme.palette.action.hover
        },
        borderRadius: theme.shape.borderRadius,
        paddingLeft: theme.spacing.unit * 0.5,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center'
    }
});

const CommissionTypeSelectBtnWithTheme = withStyles(CommissionTypeSelectBtnStyles)(
    withTheme()(CommissionTypeSelectBtn)
);
