import { useState, useEffect, useContext } from 'react';
import _ from 'lodash';

import HttpContext from 'utils/contexts/HttpContext';

function useZoneCRUD({ zones, onReloadZones }) {
    const [loading, setLoading] = useState(false);
    const [zoneDataSelected, setZoneDataSelected] = useState(null);
    const [selectedZoneBounds, setSelectedZoneBounds] = useState([]);

    const http = useContext(HttpContext);

    const creatingNewZone = _.isNil(_.get(zoneDataSelected, '_id')) && !_.isNil(zoneDataSelected);

    const handleZoneBoundsUpdated = (zonePolygon, zone) => {
        try {
            let bounds = _.cloneDeep(
                zonePolygon
                    .getPath()
                    .getArray()
                    .map(coords => ({ lat: coords.lat(), lng: coords.lng() }))
            );

            setSelectedZoneBounds(bounds.concat(_.first(bounds)));
        } catch (err) {
            console.log('Google Maps API Error. Please replace googleApiKey ', { err, data: zonePolygon.getPath() });
        }
    };

    const handleClickNewZone = () => {
        setZoneDataSelected({});
        setSelectedZoneBounds([]);
    };

    const handleSelectZoneId = zoneId => {
        const zone = _.find(zones, { _id: zoneId });
        if (_.isNil(zone)) {
            setZoneDataSelected(null);
            setSelectedZoneBounds([]);
        } else {
            setZoneDataSelected(zone);
            setSelectedZoneBounds(_.get(zone, 'bounds', []));
        }
    };

    const handleMapClick = e => {
        if (
            _.isNil(_.get(zoneDataSelected, '_id')) &&
            !_.isNil(zoneDataSelected) &&
            _.isNil(_.get(zoneDataSelected, 'bounds'))
        ) {
            const lat = e.latLng.lat();
            const lng = e.latLng.lng();

            const bounds = [
                { lat: lat + 0.1, lng: lng + 0.1 },
                { lat: lat + 0.1, lng: lng - 0.1 },
                { lat: lat - 0.1, lng: lng - 0.1 },
                { lat: lat - 0.1, lng: lng + 0.1 },
                { lat: lat + 0.1, lng: lng + 0.1 }
            ];
            const zoneDataSelectedCopy = _.cloneDeep(zoneDataSelected);
            zoneDataSelectedCopy.bounds = bounds;
            setZoneDataSelected(zoneDataSelectedCopy);
            setSelectedZoneBounds(bounds);
        }
    };

    const handleSubmitValues = async values => {
        setLoading(true);
        values.bounds = selectedZoneBounds;
        let res;
        if (creatingNewZone) {
            res = await http.postJSON(`/zone/createZone`, { values });
        } else {
            res = await http.postJSON(`/zone/${zoneDataSelected._id}/updateZone`, { values });
        }
        if (res.ok) {
            await onReloadZones();
            handleSelectZoneId(null);
        }
        setLoading(false);
    };

    const handleDisableZone = async () => {
        setLoading(true);
        const res = await http.postJSON(`/zone/${zoneDataSelected._id}/updateZone`, { values: { active: false } });
        if (res.ok) {
            await onReloadZones();
            handleSelectZoneId(null);
        }
        setLoading(false);
    };

    return {
        selectedZoneBounds,
        zoneDataSelected,
        creatingNewZone,
        loading,
        handleSelectZoneId,
        handleZoneBoundsUpdated,
        handleClickNewZone,
        handleMapClick,
        handleSubmitValues,
        handleDisableZone
    };
}

export default useZoneCRUD;
