import React, { useState, useMemo, useEffect, useContext, useRef } from 'react';

import _ from 'lodash';
import moment from 'moment-timezone';

import { _time, _user } from 'std';

import {
    Paper,
    Typography,
    Icon,
    IconButton,
    Toolbar,
    Menu,
    MenuItem,
    Grid,
    TextField,
    Button
} from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';

import EnhancedTable from 'components/MaterialUIExtensions/EnhancedTable';
import InvoiceDialog from 'containers/Operators/CollectorDashboard/InvoiceDialog'; // TODO: move
import CreateInvoiceDialog from './CreateInvoiceDialog';

import useDateRangePicker from 'components/DateTimePickersTz/hooks/useDateRangePicker';
import DateRangePicker from 'components/DateTimePickersTz/DateRangePicker';

import { Icon as MDIcon } from '@mdi/react';
import { mdiReceipt } from '@mdi/js';

import useGetJSON from 'utils/hooks/useGetJSON';
import { downloadObjectAsCSV, formatAsCurrency, isAUSRegion } from 'utils/misc';
import { Tooltip } from '@material-ui/core';

import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery';

import ConfirmDialogContext from 'components/Dialogs/Confirm/ConfirmDialogContext';

import MultiSelect from 'components/InputComponents/MultiSelect';
import useQueryString from 'utils/hooks/useQueryString';

const INVOICE_TYPES = ['Depot', 'Commodity', 'Customer'];

const INVOICE_HEADERS_STD = [
    { id: 'DocNumber', label: 'Invoice number' },
    { id: 'client', label: 'Client' },
    { id: 'MetaData.CreateTime', label: 'Created' },
    {
        id: 'DueDate',
        label: 'Due date',
        renderValue: value => {
            const isOverdue = moment().isAfter(moment(value));
            return isOverdue ? <b>{value}</b> : value;
        }
    },
    { id: 'TotalAmt', label: 'Total amount' },
    { id: 'Balance', label: 'Balance' },
    { id: 'emailStatus', label: 'Email status' },
    { id: 'actions', label: 'Actions' }
];

const INVOICE_HEADERS_AUS = [
    { id: 'id', label: 'Invoice number' },
    { id: 'client', label: 'Client' },
    { id: 'invoiceDate', label: 'Date' },
    {
        id: 'dueDate',
        label: 'Due date',
        renderValue: value => {
            const isOverdue = moment().isAfter(moment(value));
            return isOverdue ? <b>{value}</b> : value;
        }
    },
    { id: 'totalInvoiceAmount', label: 'Total amount' },
    { id: 'status', label: 'Status' },
    { id: 'actions', label: 'Actions' }
];

function Accounting(props) {
    const { theme, http, onSnackbar } = props;

    const collapseSearch = useMediaQuery(theme.breakpoints.down('sm'));

    const [loading, setLoading] = useState(false);
    const [invoices, setInvoices] = useState([]);
    const [invoicesError, setInvoicesError] = useState(false);

    const [filtersOpen, setFiltersOpen] = useState(null);
    const [search, setSearch] = useState('');
    const [invoiceTypesFilter, setInvoiceTypesFilter] = useState(INVOICE_TYPES);
    const [invoiceSelection, setInvoiceSelection] = useState([]);

    const [dateFilterSelection, setDateFilterSelection] = useQueryString(
        'dateFilterSelection',
        isAUSRegion() ? 'invoicedDate' : 'createdDate',
        true
    );

    const [createInvoiceDialogOpen, setCreateInvoiceDialogOpen] = useState(false);
    const [invoiceDialogOpen, setInvoiceDialogOpen] = useState(false);
    const [invoiceIdSelected, setInvoiceIdSelected] = useState(null);
    const invoiceSelected = useMemo(
        () =>
            _.find(
                invoices,
                i => _.get(i, process.env.REACT_APP_REGION_EXT !== 'AUS' ? 'Id' : '_id') === invoiceIdSelected
            ),
        [invoiceIdSelected, invoices]
    );

    const warnAction = useContext(ConfirmDialogContext);

    const getData = async () => {
        setLoading(true);
        let res;
        if (process.env.REACT_APP_REGION_EXT !== 'AUS') {
            res = await http.getJSON(
                `/quickbooks/getPendingInvoices?startDate=${startDate
                    .toDate()
                    .toISOString()}&endDate=${endDate
                    .toDate()
                    .toISOString()}&search=${search}&types=${invoiceTypesFilter}&dateFilterSelection=${dateFilterSelection}`
            );
        } else {
            res = await http.getJSON(
                `/invoices/getAllInvoices?startDate=${startDate
                    .toDate()
                    .toISOString()}&endDate=${endDate
                    .toDate()
                    .toISOString()}&search=${search}&types=${invoiceTypesFilter}&dateFilterSelection=${dateFilterSelection}`
            );
        }
        if (res.ok) {
            setInvoices(_.get(res, 'data.invoices', []));
        } else {
            setInvoicesError(true);
        }
        setLoading(false);
    };

    const { data: depositBreakdowns } = useGetJSON(`/depositBreakdowns/all`, 'depositBreakdowns', [], true);

    const {
        startDate,
        endDate,
        timezone,
        dateWindow,
        handleChangeStartDate,
        handleChangeEndDate,
        handleGoForwards,
        handleGoBackwards,
        handleWindowSelect
    } = useDateRangePicker({
        saveStateInURL: true,
        timezones: [process.env.REACT_APP_REGION_TIMEZONE],
        initStartVal: _time.getStartOfWeek(process.env.REACT_APP_REGION_TIMEZONE),
        initEndVal: _time.getEndOfDay(process.env.REACT_APP_REGION_TIMEZONE),
        initDateWindow: 'thisWeek'
    });

    const handleOpenInvoiceDialog = invoice_id => {
        setInvoiceIdSelected(invoice_id);
        setInvoiceDialogOpen(true);
    };

    const handleDownloadDepositBreakdown = async depositBreakdown => {
        let res = await props.http.download(
            `/depositBreakdowns/${depositBreakdown.fileName}/download`,
            `${depositBreakdown.fileName}.csv`
        );
        if (!res.ok) {
            onSnackbar('Error downloading deposit breakdown', 'error');
        }
    };

    const handleDownloadInvoiceReport = async invoice => {
        const res = await http.getJSON(`/quickbooks/getReportByInvoice/${invoice.DocNumber}`, true);
        if (res.ok) {
            await downloadObjectAsCSV(
                res.data.report,
                `Invoice report ${invoice.CustomerRef.name} ${moment(res.data.invoiceDate).format('YY-MM-DD')}`
            );
        } else {
            onSnackbar(res.errorMessage, 'error');
        }
    };

    // Invoicing dataset
    const invoicesHeaders = process.env.REACT_APP_REGION_EXT === 'AUS' ? INVOICE_HEADERS_AUS : INVOICE_HEADERS_STD;
    const invoicesRows =
        process.env.REACT_APP_REGION_EXT === 'AUS'
            ? getAUSInvoiceRows({ invoices, handleOpenInvoiceDialog })
            : getSTDInvoiceRows({
                  invoices,
                  depositBreakdowns,
                  theme,
                  handleOpenInvoiceDialog,
                  handleDownloadDepositBreakdown,
                  handleDownloadInvoiceReport
              });

    const handleFiltersOpen = close => e => {
        if (close || Boolean(filtersOpen)) {
            setFiltersOpen(null);
        } else {
            setFiltersOpen(e.currentTarget);
        }
    };

    const renderSearch = children => {
        if (collapseSearch) {
            return (
                <Menu open={Boolean(filtersOpen)} anchorEl={filtersOpen} onClose={handleFiltersOpen(true)}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            padding: theme.spacing.unit * 2
                        }}
                    >
                        {children}
                    </div>
                </Menu>
            );
        } else {
            return (
                <div
                    style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'space-between' }}
                >
                    {/* <div>{customSearch}</div> */}
                    <div style={{ display: 'flex', alignItems: 'center' }}>{children}</div>
                </div>
            );
        }
    };

    const handleChangeSearch = e => {
        setSearch(e.target.value);
    };

    const handleSubmit = e => {
        e.preventDefault();
        getData();
        //handleChangeSearch(e);
        if (filtersOpen) {
            setFiltersOpen(false);
        }
    };

    const handleMarkAsPaid = async () => {
        const res = await http.post('/invoices/markAsPaid', { _ids: invoiceSelection });
        if (res.ok) {
            setInvoiceSelection([]);
            getData();
        }
    };

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        const timeout = setTimeout(() => getData(), 1000);
        return () => clearTimeout(timeout);
    }, [search]);

    return (
        <div data-cy="Accounting" style={{ padding: theme.spacing.unit * 2 }}>
            <Grid container>
                <Grid item xs={12}>
                    <div style={{ paddingBottom: theme.spacing.unit * 2 }}>
                        <Paper
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                padding: theme.spacing.unit * 2,
                                height: '100%',
                                flexWrap: 'wrap'
                            }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <DateRangePicker
                                    timezone={timezone}
                                    startDate={startDate}
                                    endDate={endDate}
                                    window={dateWindow}
                                    disabled={loading}
                                    handlePrevious={handleGoBackwards}
                                    handleNext={handleGoForwards}
                                    handleChangeStartDate={handleChangeStartDate}
                                    handleChangeEndDate={handleChangeEndDate}
                                />
                                <TextField
                                    select
                                    variant="outlined"
                                    value={dateFilterSelection}
                                    disabled={loading}
                                    onChange={e => setDateFilterSelection(e.target.value)}
                                    style={{
                                        maxWidth: '250px',
                                        margin: theme.spacing.unit
                                    }}
                                    label="Date to Filter"
                                >
                                    {isAUSRegion() ? (
                                        <MenuItem value="invoicedDate">Invoiced Date</MenuItem>
                                    ) : (
                                        <MenuItem value="createdDate">Created Date</MenuItem>
                                    )}
                                    <MenuItem value="dueDate">Due Date</MenuItem>
                                </TextField>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexWrap: 'wrap'
                                }}
                            >
                                <form onSubmit={handleSubmit}>
                                    {renderSearch(
                                        <>
                                            <>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        flexWrap: 'wrap'
                                                    }}
                                                >
                                                    <TextField
                                                        select
                                                        variant="outlined"
                                                        value={dateWindow}
                                                        disabled={loading}
                                                        onChange={handleWindowSelect}
                                                        style={{
                                                            maxWidth: '250px',
                                                            margin: theme.spacing.unit
                                                        }}
                                                    >
                                                        <MenuItem value="today">Daily</MenuItem>
                                                        <MenuItem value="thisWeek">Weekly</MenuItem>
                                                        <MenuItem value="thisMonth">Monthly</MenuItem>
                                                        <MenuItem value="thisQuarter">Quarterly</MenuItem>
                                                        <MenuItem value="allTime">All Time</MenuItem>
                                                    </TextField>
                                                </div>

                                                <MultiSelect
                                                    variant="outlined"
                                                    selectedValues={invoiceTypesFilter}
                                                    selectableValues={INVOICE_TYPES.map(type => ({
                                                        name: type,
                                                        value: type
                                                    }))}
                                                    onChange={value => setInvoiceTypesFilter(value)}
                                                    style={{ marginRight: theme.spacing.unit }}
                                                />

                                                <TextField
                                                    variant="outlined"
                                                    name="search"
                                                    value={search || ''}
                                                    onChange={handleChangeSearch}
                                                    placeholder="Search"
                                                    style={{
                                                        width: 250,
                                                        marginRight: theme.spacing.unit
                                                    }}
                                                />

                                                {
                                                    <Button
                                                        color="primary"
                                                        disabled={loading}
                                                        variant="contained"
                                                        data-cy="dashboard-search-button"
                                                        type="submit"
                                                        onClick={handleSubmit}
                                                    >
                                                        <Icon>search</Icon>
                                                    </Button>
                                                }
                                            </>
                                        </>
                                    )}

                                    {collapseSearch && (
                                        <div style={{ textAlign: 'right' }}>
                                            <IconButton onClick={handleFiltersOpen()}>
                                                <Icon>filter_list</Icon>
                                            </IconButton>
                                        </div>
                                    )}
                                </form>
                                {process.env.REACT_APP_REGION_EXT === 'AUS' && (
                                    <Button
                                        variant="contained"
                                        onClick={() =>
                                            warnAction(
                                                () => handleMarkAsPaid(),
                                                'Are you sure you want to mark these invoices as paid?'
                                            )
                                        }
                                        disabled={_.isEmpty(invoiceSelection)}
                                        style={{ marginLeft: theme.spacing.unit }}
                                        color="secondary"
                                    >
                                        Mark As Paid
                                    </Button>
                                )}
                            </div>
                        </Paper>
                    </div>
                </Grid>
            </Grid>

            <Paper>
                {!invoicesError ? (
                    <EnhancedTable
                        showCreateButton={process.env.REACT_APP_REGION_EXT !== 'AUS'}
                        selectAll={process.env.REACT_APP_REGION_EXT === 'AUS'}
                        selected={invoiceSelection}
                        onChangeSelected={selection => setInvoiceSelection(selection)}
                        title="Invoices"
                        dataset={invoicesRows}
                        headers={invoicesHeaders}
                        loading={loading}
                        onAddClick={() => setCreateInvoiceDialogOpen(true)}
                    />
                ) : (
                    <>
                        <Toolbar justify="space-between">
                            <Typography variant="h6">Invoices</Typography>
                        </Toolbar>
                        <Typography variant="body1" style={{ padding: theme.spacing.unit * 3 }}>
                            Invoices were not loaded due to a server error. Please contact us directly for inquiries.
                        </Typography>
                    </>
                )}
            </Paper>

            <InvoiceDialog
                http={http}
                open={invoiceDialogOpen}
                invoice={invoiceSelected}
                onClose={() => setInvoiceDialogOpen(false)}
            />

            <CreateInvoiceDialog
                http={http}
                open={createInvoiceDialogOpen}
                onClose={() => setCreateInvoiceDialogOpen(false)}
                onSnackbar={onSnackbar}
                refreshInvoices={getData}
            />
        </div>
    );
}

export default withTheme()(Accounting);

function getEmailStatusIcon(theme, EmailStatus) {
    switch (EmailStatus) {
        case 'NotSet':
        case 'NeedToSend':
            return <span style={{ color: theme.palette.text.disabled }}>Not sent</span>;
        case 'EmailSent':
            return <span style={{ fontWeight: 500 }}>Sent</span>;
        default:
            throw new Error('Invalid email status.');
    }
}

function getCollectorName(theme, invoice) {
    if (_.isNil(_.get(invoice, 'collector'))) {
        return <span style={{ color: theme.palette.text.disabled }}>N/A</span>;
    } else {
        return _.get(invoice, 'collector.name');
    }
}

function getSTDInvoiceRows({
    invoices,
    depositBreakdowns,
    theme,
    handleOpenInvoiceDialog,
    handleDownloadDepositBreakdown,
    handleDownloadInvoiceReport
}) {
    return invoices.map(invoice => {
        const depositBreakdownForInvoice = _.find(
            depositBreakdowns,
            depositBreakdown =>
                depositBreakdown.invoice === invoice.DocNumber &&
                moment(invoice.MetaData.CreateTime).format('YYYY-MM-DD') ===
                    moment(depositBreakdown.createdAt).format('YYYY-MM-DD')
        );
        return {
            ...invoice,
            id: invoice.Id,
            client: invoice.isCommodityPickupInvoice
                ? `${invoice.CustomerRef.name} (${_.get(invoice, 'collector.name')})`
                : invoice.CustomerRef.name,
            collector: getCollectorName(theme, invoice),
            'MetaData.CreateTime': moment(invoice.MetaData.CreateTime).format('DD MMM YYYY'),
            DueDate: moment(invoice.DueDate).format('DD MMM YYYY'),
            TotalAmt: formatAsCurrency(invoice.TotalAmt * 100),
            Balance: formatAsCurrency(invoice.Balance * 100),
            emailStatus: getEmailStatusIcon(theme, invoice.EmailStatus),
            actions: (
                <>
                    <Tooltip title="View invoice info">
                        <IconButton onClick={() => handleOpenInvoiceDialog(invoice.Id)}>
                            <Icon>description</Icon>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Download deposit breakdown">
                        <IconButton
                            onClick={() => handleDownloadDepositBreakdown(depositBreakdownForInvoice)}
                            disabled={_.isNil(depositBreakdownForInvoice)}
                        >
                            <Icon>attach_money</Icon>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Download invoice report">
                        <IconButton onClick={() => handleDownloadInvoiceReport(invoice)}>
                            <MDIcon path={mdiReceipt} size={0.8} />
                        </IconButton>
                    </Tooltip>
                </>
            )
        };
    });
}

function getAUSInvoiceRows({ invoices, handleOpenInvoiceDialog }) {
    return invoices.map(invoice => {
        const isOverdue = moment().isAfter(moment(invoice.dueDate));
        return {
            ...invoice,
            id: invoice._id,
            client: _user.getNameFull(_.get(invoice, 'customerRef')),
            invoiceDate: moment(invoice.invoicedDate).format('DD MMM YYYY'),
            dueDate: isOverdue ? (
                <b>{moment(invoice.dueDate).format('DD MMM YYYY')} </b>
            ) : (
                moment(invoice.dueDate).format('DD MMM YYYY')
            ),
            totalInvoiceAmount: formatAsCurrency(invoice.totalInvoiceAmount),
            status: invoice.status,
            actions: (
                <>
                    <Tooltip title="View invoice info">
                        <IconButton onClick={() => handleOpenInvoiceDialog(invoice._id)}>
                            <Icon>description</Icon>
                        </IconButton>
                    </Tooltip>
                </>
            )
        };
    });
}
