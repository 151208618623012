import React, { useState, useContext, useEffect } from 'react';
import _ from 'lodash';

import { withMobileDialog, withTheme } from '@material-ui/core';

import {
    Dialog,
    DialogActions,
    DialogContent,
    Button,
    TextField,
    InputAdornment,
    Icon,
    FormControl,
    DialogContentText,
    colors,
    DialogTitle,
    Typography
} from '@material-ui/core';
import { Icon as MDIcon } from '@mdi/react';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';
import NumberOfBagsInput from 'components/InputComponents/NumberOfBagsInput';
import { getComEquivalent, shortenSubComName } from 'containers/Operators/PORScreen/NumberOfBagsDialog';
import * as allIcons from '@mdi/js';
import { MAX_NUM_OF_RECYCLING_BAGS, MIN_NUM_OF_RECYCLING_BAGS } from 'constants.js';
import OperatorContext from 'utils/contexts/OperatorContext';
import useWindowSize from 'utils/hooks/useWindowSize';
import { _bulk } from 'std';

function CreateEditNumberOfCommoditiesDialog({
    theme,
    open,
    bulk,
    onClose,
    // onUpdateCommodityAmount,
    // setIdleCountdown,
    // resetTimeoutCount,
    // countingSessions,
    currentCountingSessionId,
    commodities,
    handleUpdateCommoditesProccessedBreakdown,
    // setCountingSessions
    createNewCountingSession,
    mode,
    http
}) {
    const [width, height] = useWindowSize();
    let mobileMode = width <= 620;
    const operator = useContext(OperatorContext);
    const PORDefaultSort = _.get(operator, 'collector.PORDefaultSort', 'Nothing');
    const { lang } = useContext(LocalizationContext);
    const [commodityCounted, setCommodityCounted] = useState(
        _.get(_.find(_.get(bulk, 'countingSessions', []), { _id: currentCountingSessionId }), 'commodityCounted', [])
    );
    const [renderList, setRenderList] = useState([]);
    const [showAllSubCom, setShowAllSubCom] = useState(false);
    const [editHistory, setEditHistory] = useState([]);
    useEffect(() => {
        initialFunctions();
    }, [bulk, commodities, currentCountingSessionId, open, mode]);
    async function initialFunctions() {
        if (bulk === undefined || commodities === undefined || commodities.length < 1 || !open) return;
        let currentCommoditiesCounted = _.get(
            _.find(_.get(bulk, 'countingSessions', []), { _id: currentCountingSessionId }),
            'commodityCounted',
            []
        );
        let newRenderList = await getSortingRenderList();
        addAllSubCom(newRenderList);
        if (mode === 'edit') {
            setCommodityCounted(currentCommoditiesCounted);
        } else {
            initNewCommodityCounted(newRenderList);
        }
        setShowAllSubCom(true);
    }
    async function getCommoditiesProcessedBreakdown() {
        const res = await http.getJSON(`/bulks/${_.get(bulk, '_id', '')}/getCommoditiesProcessedBreakdown`);
        if (res.ok) {
            return _.get(res, 'data.commoditiesProcessedBreakdown', []);
        }
        return [];
    }
    const handleSubmit = () => {
        let commodityCountedChangeMap = {};
        let oldCommodityCounted = _.cloneDeep(
            _.get(
                _.find(_.get(bulk, 'countingSessions', []), { _id: currentCountingSessionId }),
                'commodityCounted',
                []
            )
        );
        let newCommodityCounted = _.cloneDeep(commodityCounted);
        newCommodityCounted.forEach(count => {
            if (!count.isSubCommodity) {
                commodityCountedChangeMap['main'] = count;
            } else {
                commodityCountedChangeMap[count.subCommodity] = count;
            }
        });
        if (mode === 'edit') {
            oldCommodityCounted.forEach(count => {
                if (!count.isSubCommodity) {
                    if (commodityCountedChangeMap['main'] !== undefined) {
                        commodityCountedChangeMap['main'].amount -= count.amount;
                    } else {
                        commodityCountedChangeMap['main'] = count;
                        commodityCountedChangeMap['main'].amount *= -1;
                    }
                } else {
                    if (commodityCountedChangeMap[count.subCommodity] !== undefined) {
                        commodityCountedChangeMap[count.subCommodity].amount -= count.amount;
                    } else {
                        commodityCountedChangeMap[count.subCommodity] = count;
                        commodityCountedChangeMap[count.subCommodity].amount *= -1;
                    }
                }
            });
        }
        if (mode === 'edit') {
            handleUpdateCommoditesProccessedBreakdown(Object.values(commodityCountedChangeMap));
        } else {
            createNewCountingSession(Object.values(commodityCountedChangeMap));
        }
        onClose();
        setShowAllSubCom(false);
        setEditHistory([]);
    };

    function initNewCommodityCounted(renderList) {
        let newCommodityCounted = [];
        let sortedFirst = false;
        if (PORDefaultSort !== 'Nothing')
            renderList.forEach(item => {
                let subCommodity = _.get(item, 'subCommodity', null);
                let amount = _.get(item, 'amount', 0);
                let totalAmount = _.get(item, 'totalAmount', 0);
                if (PORDefaultSort === 'First Items' && !sortedFirst && totalAmount > 0 && amount < totalAmount) {
                    sortedFirst = true;
                    newCommodityCounted.push({
                        isSubCommodity: !(subCommodity === null),
                        skuType: _.get(bulk, 'skuType'),
                        subCommodity: subCommodity,
                        amount: 1,
                        totalAmount: totalAmount
                    });
                } else if (PORDefaultSort === 'All Items') {
                    newCommodityCounted.push({
                        isSubCommodity: !(subCommodity === null),
                        skuType: _.get(bulk, 'skuType'),
                        subCommodity: subCommodity,
                        amount: totalAmount - amount,
                        totalAmount: totalAmount
                    });
                }
            });
        setCommodityCounted(newCommodityCounted);
    }
    async function getSortingRenderList() {
        const originalCommoditiesProcessedBreakdown = await getCommoditiesProcessedBreakdown();
        const currentSkuCom = _.find(commodities, {
            skuType: _.get(bulk, 'skuType', '')
        });
        let commoditiesProcessedBreakdown = originalCommoditiesProcessedBreakdown;
        let processedSubComList = _.filter(commoditiesProcessedBreakdown, { isSubCommodity: true });
        let sortedBags = _.get(_.find(commoditiesProcessedBreakdown, { isSubCommodity: false }), 'amount', 0);
        let renderList = [];
        let numOfSubCom = getComEquivalent(bulk, commodities, true);
        let totalNumOfBags = _.get(bulk, 'commodityAmount', 0) - numOfSubCom;
        let remainingBags = totalNumOfBags - sortedBags;
        let commoditiesMap = {};

        commoditiesProcessedBreakdown.forEach(commodity => {
            if (!commodity.isSubCommodity && commoditiesMap['main'] === undefined) {
                commoditiesMap['main'] = _.cloneDeep(commodity);
            }
            if (commodity.isSubCommodity && commoditiesMap[commodity.subCommodity] === undefined) {
                commoditiesMap[commodity.subCommodity] = _.cloneDeep(commodity);
            }
        });
        Object.values(commoditiesMap).forEach(count => {
            let isSubCommodity = _.get(count, 'isSubCommodity', true);
            let amountOnBulk = _.get(count, 'amount', 0);
            if (!isSubCommodity) {
                renderList.push({
                    color: _.get(currentSkuCom, 'color', colors.blue[300]),
                    icon: allIcons[_.get(currentSkuCom, 'iconName', '')],
                    amount: amountOnBulk,
                    name: _.get(bulk, 'commodityUOM', 'bag') + 's',
                    totalAmount: totalNumOfBags,
                    remainingAmount: remainingBags,
                    hidden: _.get(currentSkuCom, 'hidePrimary', false)
                });
            } else {
                let subComId = _.get(count, 'subCommodity', '');
                let sortedSubCom = _.get(_.find(processedSubComList, { subCommodity: subComId }), 'amount', 0);
                let totalAmount = _.get(
                    _.find(_.get(bulk, 'subPayloads', []), { subCommodity: subComId }),
                    'amount',
                    0
                );
                let remainingAmount = totalAmount - sortedSubCom;
                if (amountOnBulk < 1 && remainingAmount < 1) return;
                let subCommodityLookup = _.get(currentSkuCom, 'subCommodities', []);
                let subCommodity = _.find(subCommodityLookup, { _id: subComId });
                let name = _.get(subCommodity, `name.${lang}`, '');
                renderList.push({
                    color: _.get(subCommodity, 'color', colors.blue[300]),
                    icon: allIcons[convertToImportName(_.get(subCommodity, 'iconName', ''))],
                    amount: amountOnBulk,
                    name: shortenSubComName(name, amountOnBulk),
                    subCommodity: subComId,
                    totalAmount: totalAmount,
                    remainingAmount: remainingAmount
                });
            }
        });
        setRenderList(renderList);
        return renderList;
    }

    function updatecommodityCounted(subCommodity, amountDelta) {
        let newCommodityCounted = _.cloneDeep(commodityCounted);
        let findQuery = subCommodity === null ? { isSubCommodity: false } : { subCommodity: subCommodity };
        let index = _.findIndex(newCommodityCounted, findQuery);
        if (index === -1) {
            newCommodityCounted.push({
                isSubCommodity: !(subCommodity === null),
                skuType: _.get(bulk, 'skuType'),
                subCommodity: subCommodity,
                amount: Math.max(amountDelta, 0)
            });
        } else {
            if (newCommodityCounted[index].amount + amountDelta < 1) {
                newCommodityCounted.splice(index, 1);
            } else {
                newCommodityCounted[index].amount = newCommodityCounted[index].amount + amountDelta;
            }
        }
        setCommodityCounted(newCommodityCounted);
    }

    function addAllSubCom(sortingRenderListArg) {
        let sortingRenderList = _.isNil(sortingRenderListArg) ? renderList : sortingRenderListArg;
        const currentSkuCom = _.find(commodities, {
            skuType: _.get(bulk, 'skuType', '')
        });
        let commoditiesProcessedBreakdown = _.get(bulk, 'commoditiesProcessedBreakdown', []);
        let subCommodityLookup = _.get(currentSkuCom, 'subCommodities', []);
        let newSortingRenderList = _.cloneDeep(sortingRenderList);
        let numOfSubCom = getComEquivalent(bulk, commodities, true);
        let totalNumOfBags = _.get(bulk, 'commodityAmount', 0) - numOfSubCom;
        let sortedBags = _.get(_.find(commoditiesProcessedBreakdown, { isSubCommodity: false }), 'amount', 0);
        let remainingBags = totalNumOfBags - sortedBags;
        let processedSubComList = _.filter(commoditiesProcessedBreakdown, { isSubCommodity: true });

        let bagIndex = _.findIndex(newSortingRenderList, item => _.get(item, 'subCommodity', null) === null);
        if (bagIndex === -1) {
            newSortingRenderList.push({
                color: _.get(currentSkuCom, 'color', colors.blue[300]),
                icon: allIcons[_.get(currentSkuCom, 'iconName', '')],
                amount: 0,
                name: _.get(bulk, 'commodityUOM', 'bag') + 's',
                totalAmount: totalNumOfBags,
                remainingAmount: Math.max(remainingBags, 0),
                hidden: _.get(currentSkuCom, 'hidePrimary', false)
            });
        }
        subCommodityLookup.forEach(subCom => {
            let subComId = _.get(subCom, '_id', null);
            let totalAmount = _.get(_.find(_.get(bulk, 'subPayloads', []), { subCommodity: subComId }), 'amount', 0);
            let remainingAmount =
                totalAmount - _.get(_.find(processedSubComList, { subCommodity: subComId }), 'amount', 0);
            if (subComId !== null) {
                let index = _.findIndex(newSortingRenderList, { subCommodity: subComId });
                if (index === -1) {
                    newSortingRenderList.push({
                        color: _.get(subCom, 'color', colors.blue[300]),
                        icon: allIcons[convertToImportName(_.get(subCom, 'iconName', ''))],
                        amount: 0,
                        name: shortenSubComName(_.get(subCom, `name.${lang}`, '')),
                        subCommodity: subComId,
                        totalAmount: totalAmount,
                        remainingAmount: Math.max(remainingAmount, 0)
                    });
                }
            }
        });
        setRenderList(newSortingRenderList);
        return newSortingRenderList;
    }
    function updateRemainingAmount(subComId, newAmount) {
        let newSortingRenderList = _.cloneDeep(renderList);
        newSortingRenderList.forEach((render, index) => {
            let subCommodity = _.get(render, 'subCommodity', null);
            if (subCommodity === subComId) {
                newSortingRenderList[index].remainingAmount = newAmount;
            }
        });
        setRenderList(newSortingRenderList);
    }

    function maxAllCommodity() {
        let newCommodityCounted = _.cloneDeep(commodityCounted);
        let newEditHistory = _.cloneDeep(editHistory);
        let newEditHistoryactions = [];
        renderList.forEach(renderItem => {
            let subComId = _.get(renderItem, 'subCommodity', null);
            let totalAmount = _.get(renderItem, 'totalAmount', 0);
            let findQuery = subComId === null ? { isSubCommodity: false } : { subCommodity: subComId };
            let index = _.findIndex(newCommodityCounted, findQuery);

            let originalSessionCommodityCounted = _.get(
                _.find(_.get(bulk, 'countingSessions', []), { _id: currentCountingSessionId }),
                'commodityCounted',
                []
            );
            let currentSessionAmount =
                subComId === null
                    ? _.get(_.find(commodityCounted, { isSubCommodity: false }), 'amount', 0)
                    : _.get(_.find(commodityCounted, { subCommodity: subComId }), 'amount', 0);
            let originalSessionAmount =
                mode === 'create'
                    ? 0
                    : subComId === null
                    ? _.get(_.find(originalSessionCommodityCounted, { isSubCommodity: false }), 'amount', 0)
                    : _.get(_.find(originalSessionCommodityCounted, { subCommodity: subComId }), 'amount', 0);
            let countedAmount = _.get(renderItem, 'amount', 0) - originalSessionAmount + currentSessionAmount;
            let remainingAmount = totalAmount - countedAmount;

            if (index === -1 && totalAmount > 0 && remainingAmount > 0) {
                newCommodityCounted.push({
                    isSubCommodity: !(subComId === null),
                    skuType: _.get(bulk, 'skuType'),
                    subCommodity: subComId,
                    amount: remainingAmount
                });

                let newEditHistoryItem = { amount: remainingAmount };
                if (!_.isNil(subComId)) newEditHistoryItem.subCommodity = subComId;
                newEditHistoryactions.push(newEditHistoryItem);
            } else if (totalAmount > 0 && countedAmount < totalAmount && remainingAmount > 0) {
                let newEditHistoryItem = { amount: remainingAmount };
                if (!_.isNil(subComId)) newEditHistoryItem.subCommodity = subComId;
                newEditHistoryactions.push(newEditHistoryItem);
                newCommodityCounted[index].amount += remainingAmount;
            }
        });
        setCommodityCounted(newCommodityCounted);

        if (newEditHistoryactions.length > 0) newEditHistory.push(newEditHistoryactions);
        setEditHistory(newEditHistory);
    }

    function clearAllCommodity() {
        let newEditHistory = _.cloneDeep(editHistory);
        let newEditHistoryactions = [];
        commodityCounted.forEach(commodity => {
            let subComId = _.get(commodity, 'subCommodity', null);
            let commodityAmount = _.get(commodity, 'amount', null);
            let newEditHistoryItem = { amount: commodityAmount * -1 };
            if (!_.isNil(subComId)) newEditHistoryItem.subCommodity = subComId;
            if (commodityAmount > 0) newEditHistoryactions.push(newEditHistoryItem);
        });
        if (newEditHistoryactions.length > 0) newEditHistory.push(newEditHistoryactions);
        setEditHistory(newEditHistory);
        setCommodityCounted([]);
    }

    function undoCommodityEdit() {
        let newCommoditiesProcessedBreakdown = _.cloneDeep(commodityCounted);
        let newEditHistory = _.cloneDeep(editHistory);
        let lastActions = newEditHistory.pop();
        setEditHistory(newEditHistory);
        lastActions.forEach(action => {
            let subCommodity = _.get(action, 'subCommodity', null);
            let amountDelta = _.get(action, 'amount', 0) * -1;
            let findQuery = subCommodity === null ? { isSubCommodity: false } : { subCommodity: subCommodity };
            let index = _.findIndex(newCommoditiesProcessedBreakdown, findQuery);
            if (index === -1) {
                newCommoditiesProcessedBreakdown.push({
                    isSubCommodity: !(subCommodity === null),
                    skuType: _.get(bulk, 'skuType'),
                    subCommodity: subCommodity,
                    amount: Math.max(amountDelta, 0)
                });
            } else {
                if (newCommoditiesProcessedBreakdown[index].amount + amountDelta < 1) {
                    newCommoditiesProcessedBreakdown.splice(index, 1);
                } else {
                    newCommoditiesProcessedBreakdown[index].amount =
                        newCommoditiesProcessedBreakdown[index].amount + amountDelta;
                }
            }
        });
        setCommodityCounted(newCommoditiesProcessedBreakdown);
    }

    let filteredRenderList = _.filter(renderList, item => !item.hidden);
    let commodityButtons = filteredRenderList.map((renderItem, index) => {
        let subComId = _.get(renderItem, 'subCommodity', null);

        let originalSessionCommodity = _.get(
            _.find(_.get(bulk, 'countingSessions', []), { _id: currentCountingSessionId }),
            'commodityCounted',
            []
        );
        let currentSessionAmount =
            subComId === null
                ? _.get(_.find(commodityCounted, { isSubCommodity: false }), 'amount', 0)
                : _.get(_.find(commodityCounted, { subCommodity: subComId }), 'amount', 0);
        let originalSessionAmount =
            mode === 'create'
                ? 0
                : subComId === null
                ? _.get(_.find(originalSessionCommodity, { isSubCommodity: false }), 'amount', 0)
                : _.get(_.find(originalSessionCommodity, { subCommodity: subComId }), 'amount', 0);
        let totalAmount = _.get(renderItem, 'totalAmount', 0);
        let countedAmount = _.get(renderItem, 'amount', 0) - originalSessionAmount + currentSessionAmount;
        let extraAmount = countedAmount - totalAmount;
        if (countedAmount - totalAmount > currentSessionAmount) extraAmount = currentSessionAmount;
        let dataName = _.get(renderItem, 'name', '');
        if (dataName.endsWith('s')) dataName = dataName.slice(0, -1);
        return (
            <Button
                style={{
                    backgroundColor:
                        extraAmount > 0
                            ? colors.red[600]
                            : totalAmount === 0 || countedAmount >= totalAmount
                            ? 'lightgrey'
                            : colors.blue[600],
                    paddingLeft: 5,
                    paddingRight: 5,
                    paddingBottom: 2,
                    width: 67
                }}
                variant="contained"
                onClick={() => {
                    updatecommodityCounted(subComId, 1);
                    let newEditHistory = _.cloneDeep(editHistory);
                    let newEditHistoryItem = { amount: 1 };
                    if (!_.isNil(subComId)) newEditHistoryItem.subCommodity = subComId;
                    newEditHistory.push([newEditHistoryItem]);
                    setEditHistory(newEditHistory);
                }}
                data-cy={`bulk-counter-commodity-${dataName}`}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <div
                        style={{
                            alignContent: 'center',
                            maxHeight: 20
                        }}
                    >
                        <MDIcon path={_.get(renderItem, 'icon', '')} size={0.8} color={'white'} />
                    </div>
                    <Typography style={{ color: 'white', fontSize: 11 }}>{_.get(renderItem, 'name', '')}</Typography>
                    {(totalAmount > 0 || countedAmount > 0) && (
                        <Typography style={{ color: 'black', fontSize: 10, fontWeight: 'bold' }}>
                            {extraAmount > 0
                                ? `${extraAmount} Extra`
                                : totalAmount > 0 && `${Math.max(0, totalAmount - countedAmount)} Left`}
                        </Typography>
                    )}
                </div>
            </Button>
        );
    });
    let utilButtons = [
        <Button
            style={{
                backgroundColor: 'black',
                paddingLeft: 5,
                paddingRight: 5,
                paddingBottom: 2,
                width: 67
            }}
            variant="contained"
            onClick={() => {
                maxAllCommodity();
            }}
            data-cy={`bulk-counter-commodity-all`}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <div
                    style={{
                        alignContent: 'center',
                        maxHeight: 20
                    }}
                >
                    <Icon style={{ color: 'white' }}>fullscreen</Icon>
                </div>
                <Typography style={{ color: 'white', fontSize: 11 }}>ALL</Typography>
            </div>
        </Button>,
        <Button
            style={{
                backgroundColor: editHistory.length < 1 ? 'lightgrey' : 'black',
                paddingLeft: 5,
                paddingRight: 5,
                paddingBottom: 2,
                width: 67
            }}
            variant="contained"
            disabled={editHistory.length < 1}
            onClick={() => {
                undoCommodityEdit();
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <div
                    style={{
                        alignContent: 'center',
                        maxHeight: 20
                    }}
                >
                    <Icon style={{ color: 'white' }}>undo</Icon>
                </div>
                <Typography style={{ color: 'white', fontSize: 11 }}>UNDO</Typography>
            </div>
        </Button>,
        <Button
            style={{
                backgroundColor: 'black',
                paddingLeft: 5,
                paddingRight: 5,
                paddingBottom: 2,
                width: 67
            }}
            variant="contained"
            onClick={() => {
                clearAllCommodity();
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <div
                    style={{
                        alignContent: 'center',
                        maxHeight: 20
                    }}
                >
                    <Icon style={{ color: 'white' }}>close</Icon>
                </div>
                <Typography style={{ color: 'white', fontSize: 11 }}>CLEAR</Typography>
            </div>
        </Button>
    ];

    const buttonPlaceHolder = (
        <div
            style={{
                width: 67
            }}
        />
    );

    let buttonList = [...commodityButtons];
    let buttonsPerRow = mobileMode ? 4 : 7;
    let buttonRowCounter = 0;
    let buttonRenderList = [];
    let buttonRow = [];
    for (let i = 0; i < buttonList.length; i++) {
        if (buttonRowCounter < buttonsPerRow) {
            buttonRow.push(buttonList[i]);
            buttonRowCounter++;
        }
        if (buttonRowCounter === buttonsPerRow) {
            buttonRenderList.push(buttonRow);
            buttonRowCounter = 0;
            buttonRow = [];
        }
        if (i === buttonList.length - 1) {
            const remainingSpaces = buttonsPerRow - buttonRowCounter;
            // enough room to fit 3 util buttons, fill current row with placeholders until 3 spaces left and put util buttons at the end
            if (remainingSpaces > 2) {
                for (let j = 0; j < remainingSpaces - 3; j++) {
                    buttonRow.push(buttonPlaceHolder);
                }
                buttonRow = [...buttonRow, ...utilButtons];
                buttonRenderList.push(buttonRow);
                buttonRowCounter = 0;
                buttonRow = [];
            }
            // not enough room to fit 3 util buttons, fill current row with placeholders and put util buttons on next row
            else {
                for (let j = 0; j < remainingSpaces; j++) {
                    buttonRow.push(buttonPlaceHolder);
                }
                buttonRenderList.push(buttonRow);
                buttonRowCounter = 0;
                buttonRow = [];
                if (mobileMode) {
                    buttonRenderList.push([buttonPlaceHolder, ...utilButtons]);
                } else {
                    buttonRenderList.push([
                        buttonPlaceHolder,
                        buttonPlaceHolder,
                        buttonPlaceHolder,
                        buttonPlaceHolder,
                        ...utilButtons
                    ]);
                }
            }
        }
    }

    return (
        <Dialog open={open} maxWidth="sm" fullWidth fullScreen={mobileMode}>
            <DialogTitle style={{ paddingBottom: 8 }}>
                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                    <span style={{ marginRight: 5 }}>What are you sorting?</span>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                    {commodityCounted
                        .filter(c => c.amount > 0)
                        .map((commodity, index) => {
                            let subComId = _.get(commodity, 'subCommodity', null);
                            let commodityAmount = _.get(commodity, 'amount', null);
                            let matchingRenderItem = _.isNil(subComId)
                                ? renderList.find(item => item.subCommodity === undefined)
                                : renderList.find(item => item.subCommodity === subComId);
                            let name = _.get(matchingRenderItem, 'name', '');
                            if (name === 'bag') {
                                if (commodityAmount > 1) name = 'Bags';
                                else name = 'Bag';
                            }
                            if (commodityAmount > 0)
                                return (
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <Typography
                                            style={{
                                                whiteSpace: 'nowrap',
                                                alignSelf: 'center',
                                                justifyContent: 'center',
                                                color: colors.blue[600],
                                                fontSize: 16
                                            }}
                                        >
                                            {index > 0 && ', '}
                                            {commodityAmount}
                                            &nbsp;
                                            {name}
                                        </Typography>
                                    </div>
                                );
                        })}
                </div>
            </DialogTitle>

            <DialogContent>
                {buttonRenderList.map(buttonRow => {
                    return (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                marginBottom: 10
                            }}
                        >
                            {buttonRow.map(button => button)}
                        </div>
                    );
                })}
            </DialogContent>
            <DialogActions>
                <Button
                    color="default"
                    onClick={() => {
                        onClose();
                        setShowAllSubCom(false);
                    }}
                >
                    {loc('close', lang)}
                </Button>
                <Button
                    data-cy="bulk-counter-bags-submit"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={commodityCounted.length < 1 && !_bulk.isAdjustment(bulk)}
                >
                    {loc('submit', lang)}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
export default withMobileDialog({ breakpoint: 'xs' })(withTheme()(CreateEditNumberOfCommoditiesDialog));

function convertToImportName(iconName) {
    return _.camelCase(`mdi-${iconName}`);
}
