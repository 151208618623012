import React from 'react';

import { withTheme } from '@material-ui/core/styles';
import FeesCRUDTable from 'components/CRUDTables/FeesCRUDTable';

function Fees(props) {
    return <FeesCRUDTable {...props} />;
}

export default withTheme()(Fees);
