import React, { useContext } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';

import {
    Grid,
    Paper,
    Button,
    Icon,
    LinearProgress,
    withTheme,
    List,
    ListItem,
    TableRow,
    TableBody,
    TableCell,
    IconButton,
    Typography,
    colors
} from '@material-ui/core';
import { useState } from 'react';

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import SendOutsTable from './SendOutsTable';
import SendOutLists from './SendOutLists';

function SendOuts(props) {
    const { regions, theme } = props;
    const [view, setView] = useState('sendOuts');

    return (
        <>
            <div
                style={{
                    padding: theme.spacing.unit * 2,
                    paddingBottom: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    flex: '0 0 auto'
                }}
            >
                <ToggleButtonGroup value={view} exclusive onChange={(e, name) => setView(_.isNil(name) ? view : name)}>
                    <ToggleButton value="sendOuts" data-cy="messaging-sendouts-tab">
                        Send Outs
                    </ToggleButton>
                    <ToggleButton value="customerLists" data-cy="messaging-customer-lists-tab">
                        Customer Lists
                    </ToggleButton>
                </ToggleButtonGroup>
            </div>
            {view === 'sendOuts' && <SendOutsTable regions={regions} />}
            {view === 'customerLists' && <SendOutLists regions={regions} />}
        </>
    );
}

export default withTheme()(SendOuts);
