import React from 'react';

import _ from 'lodash';

import MaskedInput from 'react-text-mask';

const CAD_MASK = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
const AUS_MASK = [/\d/, /[1-9]/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/];

// positions to skip over such as () or spaces
const CAD_POSITIONS = [6, 10];
const AUS_POSITIONS = [3, 8];

export default class TextMaskPhoneNumber extends React.Component {
    state = {
        mask: undefined,
        positions: []
    };

    componentDidMount = () => {
        let mask, positions;
        switch (process.env.REACT_APP_REGION_EXT) {
            case 'STD':
            case 'EXP':
            case 'MXD':
            case 'CON':
                mask = CAD_MASK;
                positions = CAD_POSITIONS;
                break;
            case 'AUS':
                mask = AUS_MASK;
                positions = AUS_POSITIONS;
                break;
            default:
                mask = CAD_MASK;
                positions = CAD_POSITIONS;
        }
        this.setState({ mask, positions });
    };

    onKeyDown = e => {
        const { onChange } = this.props;
        const { positions } = this.state;

        const isBackspace = Number(e.keyCode) === 8;
        const cursorPos = Number(e.target.selectionStart);

        if (process.env.REACT_APP_REGION_EXT === 'AUS') {
            e.target.value = e.target.value.substr(1);
            e.target.value = '0' + e.target.value;
        }

        if (positions.includes(cursorPos) && isBackspace) {
            // Disable text mask events for this cursor positions
            e.preventDefault();

            e.target.value = e.target.value || '';
            e.target.value = e.target.value.replace(/\D+/g, '');

            // Simulate backspace delete
            e.target.value = e.target.value.substring(0, e.target.value.length - 1);

            // Send event with new value to your onChange
            if (onChange instanceof Function) onChange(e);
        }
    };

    onFocus = e => {
        if (process.env.REACT_APP_REGION_EXT === 'AUS') {
            let { onChange } = this.props;
            if (_.isEmpty(e.target.value)) {
                e.target.value = '0';
                if (onChange instanceof Function) onChange(e);
            }
        }
    };

    render() {
        const { inputRef, ...other } = this.props; // HACK: suppresses the warning about inputRef

        const { mask } = this.state;

        return (
            <MaskedInput
                {...other}
                ref={ref => {
                    inputRef(ref ? ref.inputElement : null);
                }}
                mask={mask}
                placeholderChar={'\u2000'}
                showMask
                guide={false}
                keepCharPositions={false}
                // placeholder={process.env.REACT_APP_PHONE_PLACEHOLDER}
                onKeyDown={this.onKeyDown}
                onFocus={this.onFocus}
            />
        );
    }
}
