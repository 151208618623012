import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Dialog } from '@material-ui/core';

const styles = {
    dialogPaper: {
        maxWidth: '80%'
    }
};

const CustomDialog = ({ classes, children, ...rest }) => (
    <Dialog {...rest} classes={{ paper: classes.dialogPaper }}>
        {children}
    </Dialog>
);

export default withStyles(styles)(CustomDialog);
