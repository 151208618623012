import React, { useContext } from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import CustomFormTitle from 'components/MaterialUIExtensions/CustomFormTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

const InfoDialog = ({
    infoDialogOpen,
    handleConfirmationDialog,
    redemptionFee,
    formattedRedemptionFee,
    handleInfoDialog,
    paymentModel
}) => {
    const { lang } = useContext(LocalizationContext);
    const methodString = {
        CHEQUE: loc('redemption5', lang),
        INTERAC_ETRANSFERS: loc('redemption4', lang),
        AUS_DIRECT_BANKING: 'an Electronic Fund Transfer',
        BANK_TRANSFER: loc('redemption6', lang)
    };

    return (
        <Dialog open={infoDialogOpen} fullWidth onClose={() => handleConfirmationDialog(false)}>
            <DialogContent>
                <CustomFormTitle titleText={loc('redemption22', lang)} iconName="toc" />
                <DialogContentText>
                    {redemptionFee > 0 && (
                        <>
                            {loc('redemption23a', lang, {
                                method: methodString[paymentModel],
                                amount: formattedRedemptionFee
                            })}
                        </>
                    )}
                    {loc('redemption23b', lang, {
                        method: methodString[paymentModel]
                    })}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={() => handleInfoDialog(false)}>
                    {loc('close', lang)}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default InfoDialog;
