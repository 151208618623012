import React, { useState, useContext, useEffect } from 'react';
import _ from 'lodash';
import {
    Select,
    FormControl,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    InputAdornment,
    Switch,
    CircularProgress,
    Grid,
    withTheme
} from '@material-ui/core';
import HttpContext from 'utils/contexts/HttpContext';
import {
    DEFAULT_DRIVER_WAGE,
    DEFAULT_TRUCK_KPL,
    DEFAULT_GAS_PRICE_STD,
    DEFAULT_GAS_PRICE_AUS,
    DEFAULT_DRIVER_COMMISSION
} from 'constants.js';

import { DRIVER_PAYMENT_MODELS } from 'constants.js';

import CommissionRatesInput from 'components/CustomInputs/CommissionRatesInput';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

const INIT_GAS_PRICE = process.env.REACT_APP_REGION_EXT === 'AUS' ? DEFAULT_GAS_PRICE_AUS : DEFAULT_GAS_PRICE_STD;

const BulkUpdate = ({ open, ids, firstSelectedTrip, commodities, handleClose, getData, theme }) => {
    const http = useContext(HttpContext);
    const { lang } = useContext(LocalizationContext);
    const [data, setData] = useState(getInitState(firstSelectedTrip, commodities));
    const [loading, setLoading] = useState(false);

    const [valuesToUpdate, setValuesToUpdate] = useState({
        driverWage: true,
        driverCommissions: true,
        kpl: true,
        gasPrice: true,
        distance: false,
        measuredDuration: false,
        paymentModel: false
    });

    const handleChange = e => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const handleChangeValuesToUpdate = e => {
        setValuesToUpdate({ ...valuesToUpdate, [e.target.name]: e.target.checked });
    };

    const handleUpdate = async () => {
        setLoading(true);
        let ok = true;
        const { driverWage, kpl, gasPrice, driverCommissions, measuredDuration, distance, paymentModel } = data;

        for (let id of ids) {
            const calculationData = {
                id,
                driverWage: valuesToUpdate.driverWage ? driverWage * 100 : undefined,
                driverCommissions: valuesToUpdate.driverCommissions ? driverCommissions : undefined,
                kmPerLiter: valuesToUpdate.kpl ? kpl : undefined,
                gasPrice: valuesToUpdate.gasPrice ? gasPrice * 100 : undefined,
                distance: valuesToUpdate.distance ? distance * 1000 : undefined,
                measuredDuration: valuesToUpdate.measuredDuration ? measuredDuration * 3600 : undefined
            };

            if (valuesToUpdate.paymentModel) {
                calculationData.paymentModel = paymentModel;
            }

            const res = await http.post('/trips/updateCalculationData', calculationData);

            if (!res.ok) {
                ok = false;
            }
        }
        if (ok) {
            getData();
            handleClose();
        }
        setLoading(false);
    };

    useEffect(() => {
        setData(getInitState(firstSelectedTrip));
    }, [open]);

    return (
        <Dialog open={open} onClose={handleClose} fullWidth>
            <DialogTitle>Update Trip Statistics</DialogTitle>
            <DialogContent>
                <Grid container spacing={theme.spacing.unit * 2} style={{ marginTop: theme.spacing.unit }}>
                    <Grid item xs={6}>
                        <TextField
                            select
                            variant="outlined"
                            fullWidth
                            name="paymentModel"
                            value={data.paymentModel}
                            onChange={handleChange}
                            input={<OutlinedInput labelWidth={200} />}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Switch
                                            name="paymentModel"
                                            color="primary"
                                            checked={valuesToUpdate.paymentModel}
                                            onChange={handleChangeValuesToUpdate}
                                        />
                                    </InputAdornment>
                                ),
                                disabled: !valuesToUpdate.paymentModel
                            }}
                            label="Driver Payment Type"
                        >
                            {DRIVER_PAYMENT_MODELS.map((driverPaymentModel, idx) => (
                                <MenuItem key={idx} value={driverPaymentModel}>
                                    {driverPaymentModel}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    {(_.isNil(data.paymentModel) || data.paymentModel === 'Wage') && (
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                type="number"
                                label="Driver Wage ($/hr)"
                                name="driverWage"
                                value={data.driverWage || ''}
                                onChange={handleChange}
                                variant="outlined"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Switch
                                                name="driverWage"
                                                color="primary"
                                                checked={valuesToUpdate.driverWage}
                                                onChange={handleChangeValuesToUpdate}
                                            />
                                        </InputAdornment>
                                    ),
                                    disabled: !valuesToUpdate.driverWage
                                }}
                            />
                        </Grid>
                    )}
                    {data.paymentModel === 'Commission' && (
                        <Grid item xs={12}>
                            <CommissionRatesInput
                                onChange={commissionRates => {
                                    setData(data => ({ ...data, driverCommissions: commissionRates }));
                                }}
                                value={data.driverCommissions}
                                commodities={commodities}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <Switch
                                            name="driverCommissions"
                                            color="primary"
                                            checked={valuesToUpdate.driverCommissions}
                                            onChange={handleChangeValuesToUpdate}
                                        />
                                    </InputAdornment>
                                }
                                disabled={!valuesToUpdate.driverCommissions}
                            />
                        </Grid>
                    )}
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            type="number"
                            label="Gas Price ($/L)"
                            name="gasPrice"
                            value={data.gasPrice || ''}
                            onChange={handleChange}
                            variant="outlined"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Switch
                                            name="gasPrice"
                                            color="primary"
                                            checked={valuesToUpdate.gasPrice}
                                            onChange={handleChangeValuesToUpdate}
                                        />
                                    </InputAdornment>
                                ),
                                disabled: !valuesToUpdate.gasPrice
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            type="number"
                            label="Truck Efficiency (km/L)"
                            name="kpl"
                            value={data.kpl || ''}
                            onChange={handleChange}
                            variant="outlined"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Switch
                                            name="kpl"
                                            color="primary"
                                            checked={valuesToUpdate.kpl}
                                            onChange={handleChangeValuesToUpdate}
                                        />
                                    </InputAdornment>
                                ),
                                disabled: !valuesToUpdate.kpl
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            type="number"
                            label="Distance (km)"
                            name="distance"
                            value={data.distance || ''}
                            onChange={handleChange}
                            variant="outlined"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Switch
                                            name="distance"
                                            color="primary"
                                            checked={valuesToUpdate.distance}
                                            onChange={handleChangeValuesToUpdate}
                                        />
                                    </InputAdornment>
                                ),
                                disabled: !valuesToUpdate.distance
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            type="number"
                            label="Duration (hours)"
                            name="measuredDuration"
                            value={data.measuredDuration || ''}
                            onChange={handleChange}
                            variant="outlined"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Switch
                                            name="measuredDuration"
                                            color="primary"
                                            checked={valuesToUpdate.measuredDuration}
                                            onChange={handleChangeValuesToUpdate}
                                        />
                                    </InputAdornment>
                                ),
                                disabled: !valuesToUpdate.measuredDuration
                            }}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button color="default" onClick={handleClose}>
                    {loc('close', lang)}
                </Button>
                <Button color="primary" onClick={handleUpdate} disabled={loading}>
                    Update{' '}
                    <CircularProgress
                        size={22}
                        thickness={4.8}
                        style={{
                            display: loading ? '' : 'none',
                            marginLeft: theme.spacing.unit
                        }}
                    />
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default withTheme()(BulkUpdate);

function getInitState(firstSelectedTrip, commodities) {
    const beverageCommodity = _.find(commodities, { skuType: 'beverage' });
    const defaultDriverCommissionRates = [
        { commodity: _.get(beverageCommodity, '_id'), skuType: 'beverage', rate: DEFAULT_DRIVER_COMMISSION }
    ];
    return _.isNil(firstSelectedTrip)
        ? {
              driverWage: DEFAULT_DRIVER_WAGE / 100,
              driverCommissions: defaultDriverCommissionRates,
              kpl: DEFAULT_TRUCK_KPL,
              gasPrice: INIT_GAS_PRICE / 100,
              distance: '',
              measuredDuration: '',
              paymentModel: 'Wage'
          }
        : {
              driverWage: _.get(firstSelectedTrip, 'driverWage', DEFAULT_DRIVER_WAGE) / 100,
              driverCommissions: _.get(firstSelectedTrip, 'driverCommissions', defaultDriverCommissionRates),
              kpl: _.get(firstSelectedTrip, 'kmPerLiter', DEFAULT_TRUCK_KPL),
              gasPrice: _.get(firstSelectedTrip, 'gasPrice', INIT_GAS_PRICE) / 100,
              distance: '',
              measuredDuration: '',
              paymentModel: _.get(firstSelectedTrip, 'paymentModel', 'Wage')
          };
}
