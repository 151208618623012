import React, { PureComponent, useState, useEffect, useContext, useMemo } from 'react';

import _ from 'lodash';
import queryString from 'query-string';
import moment from 'moment-timezone';
import * as colors from '@material-ui/core/colors';
import pickupHelper from 'helpers/pickupHelper';

import { deviceHelper, formatAsAddress, formatsSecondsToTime, padBagtag } from 'utils/misc';
import { RESIDENTIAL, CONDO, COMMERCIAL, BOTTLE_DRIVE } from 'constants.js';

import AdminUI from './AdminUI';

import SwipeableViews from 'resources/temp_packages/SwipeableViews';
import PropTypes from 'prop-types';

import { Icon as MDIcon } from '@mdi/react';
import * as allIcons from '@mdi/js';

import { _pickup, _user } from 'std';

import {
    CircularProgress,
    TextField,
    RootRef,
    DialogContentText,
    DialogContent,
    DialogActions,
    DialogTitle,
    Dialog,
    ListItemSecondaryAction,
    ListItemText,
    ListItemIcon,
    ListItem,
    List,
    Slide,
    IconButton,
    Avatar,
    Icon,
    Button,
    Typography,
    Fab,
    Paper,
    Divider,
    FormControl,
    InputAdornment,
    Select,
    MenuItem,
    InputLabel,
    Input,
    Grid,
    ListItemAvatar,
    Tooltip
} from '@material-ui/core';
import { red, green, blue, yellow } from '@material-ui/core/colors';
import { withTheme } from '@material-ui/core/styles';

import DetailedPayloadIndicators from 'components/DriverWidgets/DetailedPayloadIndicators';

import { Clipboard } from '@capacitor/clipboard';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';
import CustomFormTitle from 'components/MaterialUIExtensions/CustomFormTitle';
import { getDistanceFromLatLonInKm } from 'utils/latlngFunctions';

import ImageViewer from 'components/ImageUploads/ImageViewer';
import PickupStatusIndicators from 'components/DriverWidgets/PickupStatusIndicators';
import useGetMetaData from 'containers/Operators/DriverComplete/hooks/useGetMetaData';
import PickupInfoDialog from 'components/Dialogs/Pickup/InfoDialog';
import ReactMarkdown from 'react-markdown';
import customDriverCommentRenderer from 'utils/Markdown/customRenderer/driverComment/customDriverCommentRenderer';
import CurrentPickupUI from '../InfoPanel/InfoPanel/CurrentPickupUI';

const pickupTypeColor = {
    Residential: red[500],
    Commercial: blue[500],
    Condo: green[500],
    'Out of System': 'black'
};

const getErrorTextAndStyle = (maxEarlyStartRouteTime, lang) => ({
    gpsDisabled: {
        title: loc('gpsNotRegistered', lang),
        text: loc('turnLocationsOn', lang),
        color: colors.red[500]
    },
    tooFar: {
        title: loc('youAreToFarAway', lang),
        text: loc('moveCloserToStart', lang),
        color: colors.orange[500]
    },
    tooEarly: {
        title: loc('youArrivedToEarly', lang),
        text: loc('noEarlierThan', lang, { maxEarlyStartRouteTime }),
        color: colors.blue[500]
    }
});

function InfoPanel(props) {
    const {
        theme,
        http,
        admin,
        socket,
        mode,
        trips,
        selectedTrip,
        loadingTrips,
        bulkSelectionMode,
        isSystemAdmin,
        operator,
        pickup,
        pinSelected,
        commoditiesAvailable,
        destination,
        currentLocation,
        handlRefreshGPS,
        getStartTripTimerStartTime,
        updateTripTruckData,
        recordLateStart,
        charities,
        onOpenPickupsDrawer,
        handleRecalc,
        onEditStop,
        onSnackbar,
        startRouteTimerStartTime,
        dropOffTimerStartTime,
        etaCountDownTime,
        startingTripTasks,
        generalOffloadReminders,
        lastTripOffloadReminders,
        maxEarlyStartRouteTime = 30,
        maxDistanceFromStartLocation = 5,
        trucks,
        reloadOperator,
        onCenterMapOnPickups,
        setRefreshSettings,
        endLunch,
        lunchCountDownTime,
        setAsFirstPickup = () => {},
        loadingNextPickup
    } = props;
    const { lang } = useContext(LocalizationContext);
    const [isExpanded, setIsExpanded] = useState(false);
    const [confirmSendSMSDialogOpen, setConfirmSendSMSDialogOpen] = useState(false);
    const [finishDayDialogOpen, setFinishDayDialogOpen] = useState(false);
    const [payloadImageOpen, setPayloadImageOpen] = useState(false);
    const [abortMessageDialogOpen, setAbortMessageDialogOpen] = useState(false);
    const [payloadImages, setPayloadImages] = useState([]);
    const [promoCode, setPromoCode] = React.useState('');

    //start route states
    const [startRouteDialogOpen, setStartRouteDialogOpen] = useState(false);
    const [startRouteLoading, setStartRouteLoading] = useState(false);
    const [showStartTripWarning, setShowStartTripWarning] = useState(false);
    const [warningType, setWarningType] = useState(null);
    const [startTimeElapsed, setStartTimeElapsed] = useState(formatsSecondsToTime(0));
    const [selectedTruck, setSelectedTruck] = useState(null); // _id
    const [odometer, setOdometer] = useState(undefined);
    const [intervalId, setIntervalId] = useState(null);
    const [GPSLoading, setGPSLoading] = useState(false);
    const [isLastTrip, setIsLastTrip] = useState(false);
    const [isFirstTrip, setIsFirstTrip] = useState(false);
    const [timeSheet, setTimeSheet] = useState(null);
    const [swipeableActions, setSwipableActions] = useState(null);
    const [showLateReason, setShowLateReason] = useState(false);
    const [lateReason, setLateReason] = useState('');

    const warningTextAndStyle = _.get(getErrorTextAndStyle(maxEarlyStartRouteTime, lang), warningType, {});
    const canAbortPickups = _.get(operator, 'permissions.abortPickups', true);
    const getTodayTimeSheet = async function() {
        const res = await http.getJSON(`/timeSheets/${operator._id}/getTodayTimeSheet`);
        if (res.ok) {
            setTimeSheet(_.get(res, 'data.timeSheet', null));
        }
    };
    useEffect(() => {
        getTodayTimeSheet();
    }, [startRouteTimerStartTime]);

    useEffect(() => {
        setIsFirstTrip(
            _.isNil(
                _.find(_.filter(trips, t => t && t._id !== _.get(selectedTrip, '_id')), {
                    upcoming: false
                })
            )
        );
        setIsLastTrip(
            _.isNil(
                _.find(_.filter(trips, t => t && t._id !== _.get(selectedTrip, '_id')), {
                    upcoming: true
                })
            )
        );
    }, [trips, selectedTrip]);

    useEffect(() => {
        if (showStartTripWarning) {
            // re-evaluate error dialog
            setShowStartTripWarning(false);
            handleStartRouteDialog();
        }
    }, [currentLocation, showStartTripWarning, selectedTrip, warningType, maxDistanceFromStartLocation]);

    const commentUpadatedChip = (
        <>
            {pickup.lastCompletedPickupComment !== pickup.comment && (
                <Typography
                    style={{
                        display: 'inline',
                        alignContent: 'center',
                        background: theme.palette.primary.main,
                        borderRadius: theme.shape.borderRadius * 2,
                        padding: theme.spacing.unit,
                        marginRight: theme.spacing.unit,
                        color: theme.palette.common.white,
                        fontSize: theme.typography.fontSize * 0.8
                    }}
                >
                    {loc('new', lang)}
                </Typography>
            )}
        </>
    );

    const handleConfirmSendSMS = () => {
        setConfirmSendSMSDialogOpen(true);
    };

    const handleCancelSendSMS = () => {
        setConfirmSendSMSDialogOpen(false);
    };

    const handleSendSMS = () => {
        setConfirmSendSMSDialogOpen(false);
        if (_.isNil(startRouteTimerStartTime)) {
            getStartTripTimerStartTime();
        }
        let leg = _.get(selectedTrip, 'route.legs', []).find(
            l => _.get(l, 'pickup_id', '').toString() === pickup._id.toString()
        );
        // Don't await this. Can make app janky for drivers
        http.post('/pickups/sendSms', {
            pickup_id: pickup._id,
            driveTimeToPickup: _.get(leg, 'duration', 0),
            driverName: _.get(selectedTrip, 'transporter.name')
        });
        props.onMode('PICKUP');
    };

    const handlePayloadImageOpen = state => () => {
        setPayloadImageOpen(state);
    };

    const launchGoogleMaps = location => () => {
        const url = `https://www.google.com/maps/dir/?api=1&query=&destination=${location.lat},${
            location.lng
        }&travelmode=driving`;
        if (deviceHelper.AndroidCordova()) {
            window.open(url, '_system');
        } else if (deviceHelper.iOSCordova()) {
            window.open(url, '_system');
        } else {
            window.open(url, '_blank');
        }
    };

    const callCustomer = (phone = _pickup.getPhoneNumber(pickup, pickup.customer)) => {
        window.open(`tel:${process.env.REACT_APP_COUNTRY_CALLING_CODE}${phone}`);
    };

    const handleCopyToClipboard = string => () => {
        try {
            Clipboard.write({ string });
            props.onSnackbar(`Copied ${string} to your clipboard!`);
        } catch (err) {
            props.onSnackbar('Unable to copy value to clipboard', 'error');
        }
    };

    const handleSkipPrompt = type => () => {
        if (type === 'abort') {
            props.setAbortDialogOpen(true);
        } else if (type === 'skip') {
            props.setSkipDialogOpen(true);
        }
    };

    const handleDone = async () => {
        const res = await http.post('/pickups/dropoff', { location: destination });
        if (res.ok) {
            await http.post(`/trips/${selectedTrip._id}/recordOffloadTime`, { isLastTrip });
            if (isLastTrip && _.get(operator, 'collector.configuration.enableClockin', true)) {
                await http.post(`/timeSheets/${operator._id}/clockOut`, { clockOutTime: moment().toDate() });
            }

            props.onMode('SELECT');

            const filterCompletedTrips = _.filter(trips, t => t && t._id !== _.get(selectedTrip, '_id'));

            const nextTrip = _.find(filterCompletedTrips, { upcoming: true });

            if (!_.isEmpty(nextTrip) && !_.isEmpty(nextTrip.pickups)) {
                let time = nextTrip.startTime;

                props.onCustomHeader(`Trip ${moment(time).format('h:mm A')}`);
                props.history.push(
                    `/operators/${operator._id}/pickups?trip_id=${nextTrip._id}&${queryString.stringify({
                        back: true
                    })}`
                );
                await getStartTripTimerStartTime();
            } else if (_.isEmpty(pickup)) {
                props.history.push('/');
            }
            setFinishDayDialogOpen(false);

            props.onSnackbar(loc('tripComplete', lang));
        }
    };

    const handleRefreshLocation = async () => {
        setRefreshSettings(true);
        setGPSLoading(true);
        await handlRefreshGPS(true);
        setGPSLoading(false);
    };

    const handleStartRouteDialog = async () => {
        // check if driver is at starting location
        // if driver is too far or gps is not registered or more than 30 minutes early than trip start time, show error
        const gpsNotRegistered = _.isNil(currentLocation);
        const tooFarFromStartLocation =
            !_.isNil(currentLocation) &&
            !_.isNil(selectedTrip) &&
            getDistanceFromLatLonInKm(
                selectedTrip.startLocation.lat,
                selectedTrip.startLocation.lng,
                currentLocation.lat,
                currentLocation.lng
            ) > maxDistanceFromStartLocation;
        const tooEarly =
            !_.isNil(selectedTrip) &&
            moment(selectedTrip.startTime).diff(moment(new Date()), 'minutes') > maxEarlyStartRouteTime;

        const lateStart =
            !_.isNil(selectedTrip) && moment(selectedTrip.startTime).diff(moment(new Date()), 'minutes') < -10;

        if (gpsNotRegistered || tooFarFromStartLocation || tooEarly) {
            if (tooEarly) {
                setWarningType('tooEarly');
            } else if (gpsNotRegistered) {
                setWarningType('gpsDisabled');
            } else if (tooFarFromStartLocation) {
                setWarningType('tooFar');
            }
            setShowStartTripWarning(true);
        } else {
            if (lateStart) {
                setShowLateReason(true);
            }
            setStartRouteDialogOpen(true);
            // driver is at starting location
            // record current time, start timer
            const actualStartTime = await getStartTripTimerStartTime();

            if (!_.isNil(intervalId)) {
                clearInterval(intervalId);
            }
            if (!_.isNil(actualStartTime)) {
                const newInterval = setInterval(() => {
                    setStartTimeElapsed(getTimeDifferenceFromNow(actualStartTime));
                }, 1000);
                setIntervalId(newInterval);
            }
        }
    };

    const recordVehicleData = _.get(operator, 'recordVehicleData', false);

    const handleStartRoute = async () => {
        setStartRouteLoading(true);
        // record truck + odometer
        if (recordVehicleData) {
            const res = await updateTripTruckData({ truckId: selectedTruck, odometer, transporter: operator._id });
            if (!res.ok) {
                setStartRouteLoading(false);
                onSnackbar(res.errorMessage, 'error');
                return;
            }
        }

        if (showLateReason) {
            const res = await recordLateStart({ reason: lateReason });
            if (!res.ok) {
                setStartRouteLoading(false);
                onSnackbar(res.errorMessage, 'error');
                return;
            }
        }

        await http.post(`/trips/${selectedTrip._id}/recordStartTripTime`);

        socket.emit('refresh-route', undefined, success => {
            if (!success) {
                console.error('trip is not refreshed due to an error');
            }
        });

        // change to trip ui
        setStartRouteDialogOpen(false);
        props.onMode('SELECT');

        setStartRouteLoading(false);
    };

    const getPromoCode = async promoCode_id => {
        const res = await http.getJSON(`/promos/findOne/?promoCode_id=${promoCode_id}`);
        if (res.ok) {
            setPromoCode(_.get(res, 'data.promos[0].code', ''));
        }
    };

    // hook for fetching promo code for AdminUI
    useEffect(() => {
        setPayloadImages(_.get(pickup, 'images.payloadImages', []));
        setPromoCode('');
        let isAdmin =
            operator.accountType === 'Collector Administrator' || operator.accountType === 'System Administrator';
        let promoCodeValid = !_.isEmpty(pickup) && !_.isNil(pickup.promos[0]) && pickup.promos[0] !== '';
        if (isAdmin && promoCodeValid) {
            getPromoCode(pickup.promos[0]);
        }
    }, [pickup]);

    const [suggestedBagTag, setSuggestedBagTag] = useState(null);
    const [customerBagTag, setCustomerBagTag] = useState(null);
    const [showUndoPickupDialog, setShowUndoPickupDialog] = useState(false);
    const pickups = useMemo(() => {
        return _.get(_.find(trips, trip => trip._id === selectedTrip._id), 'pickups', []);
    }, [trips, selectedTrip]);

    useEffect(() => {
        // only do this for drivers
        if (_user.isDriver(operator) && !_.isEmpty(pickup)) {
            (async function() {
                const res = await http.getJSON(`/bulks/nextAvailableBagtag/${operator._id}/${pickup._id}`);
                if (res.ok) {
                    const newBagtag = _.get(res, 'data.bagtag', null);
                    if (!_.isEmpty(newBagtag)) {
                        setSuggestedBagTag(newBagtag);
                    }
                }
            })();
        }
    }, [operator, pickup]);

    useEffect(() => {
        if (_.get(pickup, 'customer.uniqueID')) {
            getAvailableBagTag(pickup.customer.uniqueID);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pickup._id]);

    const getAvailableBagTag = async (bagtag, padding = 1) => {
        const newBagTag = `${bagtag}${padding > 1 ? '-' + padding : ''}`;
        const paddedBagtag = padBagtag(newBagTag);
        if (_.isNil(pickup.collector)) {
            setCustomerBagTag(newBagTag);
            return;
        }
        const res = await http.getJSON(
            '/bulks/getBagStatusByBagtag/' +
                paddedBagtag +
                '/' +
                pickup.customer._id +
                '/' +
                _.get(pickup, 'collector._id', '')
        );
        if (res.ok) {
            if (res.data.inuse) {
                getAvailableBagTag(bagtag, padding + 1);
            } else {
                setCustomerBagTag(newBagTag);
            }
        }
    };

    if (loadingTrips) {
        return null;
    }

    if (admin && _.isEmpty(pickup) && _.isNil(pinSelected)) {
        return null;
    }

    if (bulkSelectionMode) {
        return null;
    }

    const highPriority = pickup.postponedBySystem > 0;
    const skipButtonDisabled = pickup.skipped;

    let pickupType = _.get(pickup, 'pickupType', RESIDENTIAL);
    if (_.isNil(pickup.customer)) {
        pickupType = 'Out-of-system stop';
    }

    let etaList = _.get(pickup, 'estimatedCompletionDates');
    let eta = etaList ? _.last(etaList) : null;

    const isLate = eta
        ? moment(eta)
              .tz(pickup.location.timezone)
              .isBefore(new Date())
        : false;

    let selectUI = <div />;
    let pickupSelectUI = <div />;
    let pickupUI = <div />;
    let destinationUI = <div />;
    let startRouteUI = <div />;
    let endLunchUI = <div />;
    let adminUI = <div />;
    let panelStyles = {
        position: 'absolute',
        maxWidth: 480,
        boxShadow: theme.shadows[2]
    };

    const buttonStyle = {
        whiteSpace: 'nowrap',
        marginTop: theme.spacing.unit * 1
    };

    const typeOfPickup = _pickup.isOutOfSystemStop(pickup) ? 'Out of System' : pickup.pickupType;
    const buzzerCode = _.get(pickup, 'location.buzzerCode');

    if (!admin) {
        panelStyles = {
            ...panelStyles,
            bottom: 0,
            width: '100%'
        };
        startRouteUI = (
            <div>
                <List dense disablePadding style={{ paddingTop: theme.spacing.unit * 1 }}>
                    <ListItem disableGutters style={{ padding: 0, paddingRight: 64 + theme.spacing.unit }}>
                        <ListItemIcon style={{ marginRight: 0 }}>
                            <Avatar style={{ backgroundColor: theme.palette.secondary.main }}>
                                <Icon>sports_score</Icon>
                            </Avatar>
                        </ListItemIcon>
                        <ListItemText
                            primaryTypographyProps={{
                                style: { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }
                            }}
                            primary={formatAsAddress(_.get(selectedTrip, 'startLocation'))}
                            secondary={
                                <span style={{ color: theme.palette.secondary.main }}>
                                    {_.get(selectedTrip, 'startingCollector', 'starting location')}
                                </span>
                            }
                            style={{ padding: 0, paddingLeft: theme.spacing.unit * 2 }}
                        />
                        <Button
                            variant="outlined"
                            color="default"
                            style={{ marginLeft: theme.spacing.unit * 0.5, marginRight: theme.spacing.unit * 0.5 }}
                            onClick={handleCopyToClipboard(formatAsAddress(_.get(selectedTrip, 'startLocation')))}
                        >
                            <Icon color="action">file_copy</Icon>
                        </Button>
                        <ListItemSecondaryAction style={{ right: 0 }}>
                            <Button
                                variant="outlined"
                                color="default"
                                onClick={launchGoogleMaps(_.get(selectedTrip, 'startLocation'))}
                            >
                                <Icon color="action">map</Icon>
                            </Button>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem
                        disableGutters
                        style={{ padding: 0, marginTop: theme.spacing.unit, paddingRight: 64 + theme.spacing.unit }}
                    >
                        <ListItemIcon style={{ marginRight: 0 }}>
                            <Avatar style={{ backgroundColor: theme.palette.secondary.main }}>
                                <Icon>assignment</Icon>
                            </Avatar>
                        </ListItemIcon>
                        <ListItemText
                            primaryTypographyProps={{
                                style: { overflow: 'hidden', textOverflow: 'default' }
                            }}
                            primary={`${loc('proceedTo', lang)} ${_.get(
                                selectedTrip,
                                'startingCollector',
                                'starting location'
                            )}, ${loc('clockInMessage', lang)}`}
                            style={{
                                padding: 0,
                                paddingLeft: theme.spacing.unit * 2,
                                paddingRight: theme.spacing.unit * 2
                            }}
                        />
                    </ListItem>
                </List>

                <div
                    style={{
                        display: 'flex',
                        marginTop: theme.spacing.unit * 2,
                        marginLeft: -theme.spacing.unit * 0.5,
                        marginRight: -theme.spacing.unit * 0.5
                    }}
                >
                    <Button
                        variant="contained"
                        data-cy="start-route-button"
                        color="primary"
                        style={{
                            flexGrow: 1,
                            marginLeft: theme.spacing.unit * 0.5,
                            marginRight: theme.spacing.unit * 0.5,
                            background: theme.palette.common.black,
                            color: theme.palette.common.white
                        }}
                        onClick={handleStartRouteDialog}
                    >
                        {timeSheet === null ? loc('clockIn', lang) : loc('completePreTrip', lang)}
                    </Button>
                </div>
            </div>
        );
        selectUI = !_.isEmpty(pickup) ? (
            <>
                <UndoPickupDialog
                    open={showUndoPickupDialog}
                    onClose={() => {
                        setShowUndoPickupDialog(false);
                    }}
                    theme={theme}
                    trip={selectedTrip}
                    http={http}
                    operator={operator}
                    pickups={pickups}
                    onSnackbar={onSnackbar}
                    reloadOperator={reloadOperator}
                    setShowUndoPickupDialog={setShowUndoPickupDialog}
                />
                {loadingNextPickup ? (
                    <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 6 }}>
                        <CircularProgress size={35} thickness={4} />
                    </div>
                ) : (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Icon color="primary" style={{ marginRight: theme.spacing.unit }}>
                                location_on
                            </Icon>{' '}
                            <Typography>
                                <span data-cy="info-panel-address">{formatAsAddress(pickup.location)}</span> -{' '}
                                <span style={{ color: theme.palette.text.secondary }}>{pickupType}</span>
                            </Typography>
                        </div>
                        <Typography secondary style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <DetailedPayloadIndicators
                                showUnits
                                commodities={commoditiesAvailable}
                                pickups={[pickup]}
                                style={{ marginTop: theme.spacing.unit }}
                                lang={lang}
                            />
                            {!_.isNil(pickup.numberOfBins) && pickup.numberOfBins > 0 && (
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'flex-end',
                                        marginTop: theme.spacing.unit / 2
                                    }}
                                >
                                    <MDIcon
                                        path={_.get(allIcons, 'mdiDelete')}
                                        color="grey"
                                        size={1}
                                        data-cy="admin-ui-bin-indicator"
                                    />
                                    <Typography>Bins: {pickup.numberOfBins}</Typography>
                                </div>
                            )}
                        </Typography>
                        <Typography style={{ marginTop: theme.spacing.unit * 2 }}>
                            {loc('selectNextActions', lang)}
                        </Typography>

                        <div
                            style={{
                                display: 'flex',
                                marginTop: theme.spacing.unit * 2
                            }}
                        >
                            <Button
                                data-cy="info-panel-go-to-next-pickup-button"
                                variant="contained"
                                style={{
                                    ...buttonStyle,
                                    background: theme.palette.common.black,
                                    color: theme.palette.common.white
                                }}
                                fullWidth
                                onClick={
                                    pickup.smsNotificationSent || _.isNil(pickup.customer)
                                        ? handleSendSMS
                                        : handleConfirmSendSMS
                                }
                            >
                                {loc('goNextPickup', lang)}
                            </Button>
                            {!_.isNil(etaCountDownTime) && (
                                <Button
                                    disabled
                                    variant="outlined"
                                    style={{ ...buttonStyle, marginLeft: theme.spacing.unit }}
                                >
                                    <ETACountDown eta={etaCountDownTime} theme={theme} />
                                </Button>
                            )}
                        </div>

                        <Button
                            data-cy="go-to-history"
                            variant="outlined"
                            margin=""
                            style={buttonStyle}
                            onClick={() => {
                                if (
                                    _.get(operator, 'collector.configuration.enableClockin', false) &&
                                    timeSheet === null
                                ) {
                                    onSnackbar(loc('onlyUndoClockedIn', lang), 'error');
                                } else {
                                    setShowUndoPickupDialog(true);
                                }
                            }}
                        >
                            {loc('undoPickups', lang)}
                        </Button>
                    </div>
                )}
            </>
        ) : (
            <div />
        );

        pickupSelectUI = !_.isEmpty(pickup) ? (
            <div style={{ display: 'flex', flexDirection: 'column', paddingTop: theme.spacing.unit * 1.5 }}>
                <Button
                    variant="contained"
                    fullWidth
                    data-cy="info-panel-pickup-complete"
                    style={{
                        ...buttonStyle,
                        background: theme.palette.common.black,
                        color: theme.palette.common.white
                    }}
                    onClick={() => props.setCompleteDialogOpen(true)}
                >
                    {loc('completeFoundBags', lang)}
                </Button>

                <Button
                    data-cy="skip-pickup-button"
                    disabled={skipButtonDisabled}
                    variant="contained"
                    style={{
                        ...buttonStyle,
                        background: yellow[600],
                        color: skipButtonDisabled ? theme.palette.text.disabled : theme.palette.common.black
                    }}
                    onClick={handleSkipPrompt('skip')}
                >
                    {pickup.skipped ? loc('skipped', lang) : loc('skipBackLater', lang)}
                </Button>

                <div style={{ display: 'flex' }}>
                    <Button
                        variant="outlined"
                        color="default"
                        style={buttonStyle}
                        onClick={() => props.onMode('PICKUP')}
                    >
                        <Icon color="action">arrow_back</Icon>
                    </Button>
                    <Button
                        disabled={_.isNil(pickup.customer) || !canAbortPickups}
                        variant="outlined"
                        data-cy="info-panel-pickup-abort"
                        color="secondary"
                        style={{
                            ...buttonStyle,
                            marginLeft: theme.spacing.unit,
                            flex: '1'
                        }}
                        onClick={canAbortPickups ? handleSkipPrompt('abort') : () => setAbortMessageDialogOpen(true)}
                    >
                        {loc('abortCantFind', lang)}
                    </Button>
                </div>
            </div>
        ) : (
            <div />
        );

        pickupUI = !_.isEmpty(pickup) && (
            <CurrentPickupUI
                updateHeight={() => swipeableActions.updateHeight()}
                pickup={pickup}
                commoditiesAvailable={commoditiesAvailable}
                abortMessageDialogOpen={abortMessageDialogOpen}
                setAbortMessageDialogOpen={setAbortMessageDialogOpen}
                operator={operator}
                callCustomer={callCustomer}
                eta={eta}
                isLate={isLate}
                onMode={props.onMode}
                typeOfPickup={typeOfPickup}
                highPriority={highPriority}
                launchGoogleMaps={launchGoogleMaps}
                etaCountDownTime={etaCountDownTime}
                commentUpadatedChip={commentUpadatedChip}
                buzzerCode={buzzerCode}
                comment={getPickupCombinedComment(pickup)}
                suggestedBagTag={suggestedBagTag}
                customerBagTag={customerBagTag}
                pickupType={pickupType}
                ETACountDown={ETACountDown}
                lang={lang}
                http={http}
                onSnackbar={onSnackbar}
            />
        );

        endLunchUI = !_.isEmpty(pickup) ? (
            <div>
                <div
                    fullWidth
                    style={{
                        border: '2px solid rgba(0, 0, 0, 0.12)',
                        borderRadius: '5px',
                        marginBottom: theme.spacing.unit,
                        padding: theme.spacing.unit,
                        flex: `calc(20% - ${theme.spacing.unit * 2}px)`,
                        display: 'flex',
                        flexDirection: 'column',
                        textAlign: 'center',
                        alignItems: 'center'
                    }}
                >
                    {!_.isNil(lunchCountDownTime) && (
                        <ETACountDown eta={lunchCountDownTime} theme={theme} fontSize="15pt" />
                    )}
                </div>
                <div
                    style={{
                        display: 'flex',
                        marginLeft: -theme.spacing.unit * 0.5,
                        marginRight: -theme.spacing.unit * 0.5
                    }}
                >
                    <Button
                        variant="contained"
                        data-cy="resume-trip-button"
                        color="primary"
                        style={{
                            flexGrow: 1,
                            marginLeft: theme.spacing.unit * 0.5,
                            marginRight: theme.spacing.unit * 0.5,
                            background: theme.palette.common.black,
                            color: theme.palette.common.white
                        }}
                        onClick={endLunch}
                    >
                        Resume Trip
                    </Button>
                </div>
            </div>
        ) : (
            <div />
        );

        destinationUI = (
            <div>
                <List dense disablePadding style={{ paddingTop: theme.spacing.unit * 1 }}>
                    <ListItem disableGutters style={{ padding: 0, paddingRight: 64 + theme.spacing.unit }}>
                        <ListItemIcon style={{ marginRight: 0 }}>
                            <Avatar style={{ backgroundColor: theme.palette.secondary.main }}>
                                <Icon>place</Icon>
                            </Avatar>
                        </ListItemIcon>
                        <ListItemText
                            primaryTypographyProps={{
                                style: { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }
                            }}
                            primary={formatAsAddress(destination)}
                            secondary={
                                <span style={{ color: theme.palette.secondary.main }}>{loc('dropOffPoint', lang)}</span>
                            }
                            style={{ padding: 0, paddingLeft: theme.spacing.unit * 2 }}
                        />
                        <Button
                            variant="outlined"
                            color="default"
                            style={{ marginLeft: theme.spacing.unit * 0.5, marginRight: theme.spacing.unit * 0.5 }}
                            onClick={handleCopyToClipboard(formatAsAddress(destination))}
                        >
                            <Icon color="action">file_copy</Icon>
                        </Button>
                        <ListItemSecondaryAction style={{ right: 0 }}>
                            <Button variant="outlined" color="default" onClick={launchGoogleMaps(destination)}>
                                <Icon color="action">map</Icon>
                            </Button>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem
                        disableGutters
                        style={{ padding: 0, marginTop: theme.spacing.unit, paddingRight: 64 + theme.spacing.unit }}
                    >
                        <ListItemIcon style={{ marginRight: 0 }}>
                            <Avatar style={{ backgroundColor: theme.palette.secondary.main }}>
                                <Icon>assignment</Icon>
                            </Avatar>
                        </ListItemIcon>
                        <ListItemText
                            primaryTypographyProps={{
                                style: { overflow: 'hidden', textOverflow: 'default' }
                            }}
                            primary={`${loc('proceedTo', lang)} ${_.get(
                                selectedTrip,
                                'collector.name',
                                'depot'
                            )}, ${loc('offLoadAndPrepare', lang)} ${
                                isLastTrip && _.get(operator, 'collector.configuration.enableClockin', true)
                                    ? loc('clockOut', lang)
                                    : loc('finishTrip', lang)
                            } ${loc('whenDone', lang)}`}
                            style={{
                                padding: 0,
                                paddingLeft: theme.spacing.unit * 2,
                                paddingRight: theme.spacing.unit * 2
                            }}
                        />
                        {dropOffTimerStartTime && (
                            <ListItemSecondaryAction style={{ right: 0 }}>
                                <div
                                    style={{
                                        display: 'flex'
                                    }}
                                >
                                    <Icon color="action">timer</Icon>
                                    <OffloadElapsedTime startTime={dropOffTimerStartTime} />
                                </div>
                            </ListItemSecondaryAction>
                        )}
                    </ListItem>
                </List>

                <div
                    style={{
                        display: 'flex',
                        marginTop: theme.spacing.unit * 2,
                        marginLeft: -theme.spacing.unit * 0.5,
                        marginRight: -theme.spacing.unit * 0.5
                    }}
                >
                    <Button
                        variant="contained"
                        data-cy="finish-day-button"
                        color="primary"
                        style={{
                            flexGrow: 1,
                            marginLeft: theme.spacing.unit * 0.5,
                            marginRight: theme.spacing.unit * 0.5,
                            background: theme.palette.common.black,
                            color: theme.palette.common.white
                        }}
                        onClick={() => {
                            setFinishDayDialogOpen(true);
                        }}
                    >
                        {isLastTrip && _.get(operator, 'collector.configuration.enableClockin', true)
                            ? loc('clockOut', lang)
                            : loc('finishTrip', lang)}
                    </Button>
                    {!_.isEmpty(pickup) && (
                        <Button
                            variant="outlined"
                            color="default"
                            disabled={_.isEmpty(pickup)}
                            style={{ marginLeft: theme.spacing.unit * 0.5, marginRight: theme.spacing.unit * 0.5 }}
                            onClick={() => props.onMode('SELECT')}
                        >
                            <Icon {...!_.isEmpty(pickup) && { color: 'action' }}>arrow_back</Icon>
                        </Button>
                    )}
                </div>
            </div>
        );
    } else {
        const open = !_.isEmpty(pickup) || !_.isNil(pinSelected);
        panelStyles = {
            ...panelStyles,
            bottom: theme.spacing.unit * 2,
            left: theme.spacing.unit * 2,
            width: `calc(100% - ${theme.spacing.unit * 4}px)`
        };

        adminUI = open ? (
            <AdminUI
                http={http}
                ListItemExpandable={ListItemExpandable} // TEMP
                getPickupCombinedComment={getPickupCombinedComment} // TEMP
                isSystemAdmin={isSystemAdmin}
                pickup={pickup}
                pinSelected={pinSelected}
                charities={charities}
                commoditiesAvailable={commoditiesAvailable}
                launchGoogleMaps={launchGoogleMaps}
                setCompleteDialogOpen={props.setCompleteDialogOpen}
                setAbortDialogOpen={props.setAbortDialogOpen}
                setSkipDialogOpen={props.setSkipDialogOpen}
                onSnackbar={props.onSnackbar}
                onEditStop={onEditStop}
                handleRecalc={handleRecalc}
                pickupTypeColor={pickupTypeColor}
                theme={theme}
                promoCode={promoCode}
                setAsFirstPickup={setAsFirstPickup}
                setIsExpanded={setIsExpanded}
            />
        ) : (
            <div />
        );
    }

    return (
        <React.Fragment>
            <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', zIndex: 100 }}>
                <div style={panelStyles}>
                    <div style={{ overflow: 'hidden' }}>
                        <Slide direction="up" in={true}>
                            <>
                                {!_user.isDriver(operator) && (
                                    <Fab
                                        color="primary"
                                        size="small"
                                        style={{
                                            position: 'absolute',
                                            top: -24,
                                            right: theme.spacing.unit * 2
                                        }}
                                        onClick={onOpenPickupsDrawer}
                                        data-cy="pickups-admin-pickups-button"
                                    >
                                        <Icon>local_shipping</Icon>
                                    </Fab>
                                )}
                                {_user.isDriver(operator) && (
                                    <Fab
                                        color="primary"
                                        size="small"
                                        style={{
                                            position: 'absolute',
                                            top: -24,
                                            right: theme.spacing.unit * 2
                                        }}
                                        onClick={onCenterMapOnPickups}
                                        data-cy="pickups-admin-pickups-button"
                                    >
                                        <Icon>center_focus_strong</Icon>
                                    </Fab>
                                )}
                                <Paper
                                    style={{
                                        padding: theme.spacing.unit * 2,
                                        paddingTop: theme.spacing.unit * 1.5
                                    }}
                                >
                                    {!admin && mode.index !== 2 && (
                                        <Grid container spacing={theme.spacing.unit}>
                                            <Grid item xs={10}>
                                                <Typography variant="body1">
                                                    {mode.isLoc ? loc(mode.message, lang) : mode.message}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    )}
                                    <SwipeableViews
                                        action={actions => {
                                            setSwipableActions(actions);
                                        }}
                                        index={mode.index}
                                        disabled={true}
                                        animateHeight={true}
                                        slideStyle={{ overflow: 'hidden' }}
                                        springConfig={{
                                            duration: '0.15s',
                                            easeFunction: 'cubic-bezier(0.15, 0.3, 0.25, 1)',
                                            delay: '0s'
                                        }}
                                        style={{ marginTop: admin ? 0 : theme.spacing.unit / 2 }}
                                    >
                                        {adminUI}
                                        {pickupSelectUI}
                                        {pickupUI}
                                        {selectUI}
                                        {destinationUI}
                                        {startRouteUI}
                                        {endLunchUI}
                                    </SwipeableViews>
                                </Paper>
                            </>
                        </Slide>
                    </div>
                </div>
            </div>

            {!_.isEmpty(pickup) && (
                <Dialog open={confirmSendSMSDialogOpen && !pickup.smsNotificationSent} fullWidth maxWidth="sm">
                    <DialogTitle>{loc('sendSMS', lang)}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {loc('confirmSendSMSMessage1', lang)}

                            <span style={{ color: theme.palette.primary.main }}>
                                ({_pickup.numberOfPayloadBags(pickup, commoditiesAvailable)} bags)
                            </span>

                            {loc('confirmSendSMSMessage2', lang)}
                        </DialogContentText>
                        <DialogContentText style={{ marginTop: theme.spacing.unit * 2 }}>
                            {/*lastCompletedPickupComment !== pickup.comment && (
                                <div>
                                    <Chip
                                        label="NEW"
                                        color="primary"
                                        icon={<MDIcon path={mdiCommentAlert} size={1} color={'white'} />}
                                    />
                                </div>
                            )*/}
                            <b>{loc('customerComments', lang)}</b> {commentUpadatedChip}{' '}
                            {getPickupCombinedComment(pickup) ? (
                                <ReactMarkdown
                                    allowedTypes={[
                                        'root',
                                        'text',
                                        'break',
                                        'paragraph',
                                        'emphasis',
                                        'strong',
                                        'list',
                                        'listItem',
                                        'image'
                                    ]}
                                    unwrapDisallowed
                                    source={getPickupCombinedComment(pickup)}
                                    renderers={customDriverCommentRenderer}
                                />
                            ) : (
                                <span style={{ color: theme.palette.text.disabled }}>No directions provided</span>
                            )}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button data-cy="info-panel-dialog-cancel" color="secondary" onClick={handleCancelSendSMS}>
                            {loc('cancel', lang)}
                        </Button>
                        <Button data-cy="info-panel-dialog-yes" color="primary" onClick={handleSendSMS}>
                            {loc('yes', lang)}
                        </Button>
                    </DialogActions>
                </Dialog>
            )}

            {selectedTrip && isFirstTrip && (
                <Dialog open={startRouteDialogOpen} fullWidth maxWidth="sm">
                    <DialogTitle>
                        <span style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span>{loc('youHaveClockedIn', lang)} </span>
                            {startRouteTimerStartTime && (
                                <span style={{ display: 'flex', alignItems: 'center' }}>
                                    <Icon color="action">timer</Icon>
                                    <Typography
                                        inline
                                        variant="body2"
                                        color="textSecondary"
                                        style={{ fontSize: '1.0rem' }}
                                    >
                                        {startTimeElapsed}
                                    </Typography>
                                </span>
                            )}
                        </span>
                    </DialogTitle>
                    <DialogContent>
                        {!_.isNil(_.get(startingTripTasks, `${lang}`, null)) &&
                            !_.isEmpty(_.get(startingTripTasks, `${lang}`, [])) && (
                                <CustomFormTitle titleText={loc('rememberTo', lang)} iconName="assignment" />
                            )}
                        <List>
                            {!_.isNil(_.get(startingTripTasks, `${lang}`, null)) &&
                                _.get(startingTripTasks, `${lang}`, []).map((task, index) => (
                                    <>
                                        <ListItem>
                                            <ListItemText primary={`${index + 1}. ${task}`} />
                                        </ListItem>
                                        {index < _.get(startingTripTasks, `${lang}`, []).length - 1 && (
                                            <Divider variant="fullWidth" component="li" />
                                        )}
                                    </>
                                ))}
                        </List>
                        <Typography inline variant="body2" color="textSecondary" style={{ fontSize: '1.0rem' }}>
                            {showLateReason ? loc('shouldHaveLeft', lang) : loc('shouldLeave', lang)}{' '}
                            {moment(selectedTrip.startTime).format('H:mm A')}
                        </Typography>

                        {showLateReason && (
                            <FormControl fullWidth style={{ marginTop: theme.spacing.unit / 2 }}>
                                <TextField
                                    data-cy="late-reason-input"
                                    label={loc('lateReason', lang)}
                                    value={lateReason}
                                    name="lateReason"
                                    type="text"
                                    onChange={e => setLateReason(e.target.value)}
                                    error={showLateReason && _.isEmpty(lateReason)}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </FormControl>
                        )}

                        {recordVehicleData && (
                            <>
                                <CustomFormTitle titleText={loc('truckInformation', lang)} iconName="info" />
                                <FormControl fullWidth style={{ marginTop: theme.spacing.unit / 2 }}>
                                    <InputLabel htmlFor="truck-number" shrink={true}>
                                        {loc('truckNumber', lang)}
                                    </InputLabel>
                                    <Select
                                        value={selectedTruck}
                                        onChange={e => {
                                            !_.isNil(e.target.value)
                                                ? setSelectedTruck(e.target.value)
                                                : setSelectedTruck(undefined);
                                        }}
                                        data-cy="truck-select"
                                        input={
                                            <Input
                                                name="truck-number"
                                                id="truck-number"
                                                error={_.isNil(selectedTruck)}
                                            />
                                        }
                                    >
                                        {trucks.map((truck, idx) => {
                                            return (
                                                <MenuItem
                                                    key={truck._id}
                                                    value={truck._id}
                                                    style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
                                                    data-cy={`truck-select-${idx}`}
                                                >
                                                    {truck.number} - {truck.name}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                                <FormControl fullWidth style={{ marginTop: theme.spacing.unit / 2 }}>
                                    <TextField
                                        data-cy="truck-odometer"
                                        label={loc('odometer', lang)}
                                        value={odometer}
                                        name="odometer"
                                        type="number"
                                        onChange={e => setOdometer(e.target.value)}
                                        error={_.isEmpty(odometer)}
                                        // helperText={_.isEmpty(odometer) ? 'this field is required' : ''}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Icon>speed</Icon>
                                                </InputAdornment>
                                            ),
                                            endAdornment: <InputAdornment position="start">KM</InputAdornment>
                                        }}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </FormControl>
                            </>
                        )}
                    </DialogContent>
                    <DialogActions>
                        {startRouteLoading ? (
                            <div style={{ justifyContent: 'center', width: '100%', display: 'flex', marginBottom: 10 }}>
                                <CircularProgress />
                            </div>
                        ) : (
                            <>
                                <Button
                                    data-cy="info-panel-start-route-dialog-cancel"
                                    color="secondary"
                                    onClick={() => setStartRouteDialogOpen(false)}
                                >
                                    {loc('cancel', lang)}
                                </Button>
                                <Button
                                    data-cy="info-panel-start-route-dialog-start"
                                    color="primary"
                                    disabled={
                                        (recordVehicleData && (_.isNil(selectedTruck) || _.isEmpty(odometer))) ||
                                        (showLateReason && _.isEmpty(lateReason))
                                    }
                                    onClick={handleStartRoute}
                                >
                                    {loc('proceedToFirstPickup', lang)}
                                </Button>
                            </>
                        )}
                    </DialogActions>
                </Dialog>
            )}

            {selectedTrip && (
                <Dialog
                    open={showStartTripWarning}
                    PaperProps={{
                        style: {
                            backgroundColor: warningTextAndStyle.color
                        }
                    }}
                >
                    <DialogTitle>
                        <Typography variant="h6" style={{ color: 'white' }} data-cy="start-trip-warning-title">
                            {warningTextAndStyle.title}
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Typography style={{ color: 'white' }}>
                            {warningTextAndStyle.text}{' '}
                            {['gpsDisabled', 'tooFar'].includes(warningType) && (
                                <span
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexWrap: 'wrap',
                                        justifyContent: 'space-between'
                                    }}
                                >
                                    <Button
                                        data-cy="start-route-refresh-location"
                                        onClick={handleRefreshLocation}
                                        size="small"
                                        variant="outlined"
                                        style={{
                                            color: 'white',
                                            borderColor: 'white',
                                            marginRight: theme.spacing.unit,
                                            marginTop: theme.spacing.unit
                                        }}
                                        disabled={GPSLoading}
                                    >
                                        <span style={{ marginRight: theme.spacing.unit }}>
                                            {loc('pickupDialogDrop15', lang)}
                                        </span>
                                        {GPSLoading ? (
                                            <CircularProgress size={22} style={{ color: 'white' }} />
                                        ) : (
                                            <Icon style={{ color: 'white' }}>refresh</Icon>
                                        )}
                                    </Button>
                                </span>
                            )}
                            {warningType === 'tooEarly' && (
                                <span>
                                    {loc('scheduledStartTimeIs', lang)}{' '}
                                    {moment(selectedTrip.startTime)
                                        .tz(_.get(selectedTrip, 'timezone', process.env.REACT_APP_REGION_TIMEZONE))
                                        .format('h:mm A')}
                                </span>
                            )}
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => setShowStartTripWarning(false)}
                            style={{ color: 'white' }}
                            data-cy="start-trip-error-back-btn"
                        >
                            {loc('back', lang)}
                        </Button>
                    </DialogActions>
                </Dialog>
            )}

            {selectedTrip && (
                <Dialog open={finishDayDialogOpen} fullWidth maxWidth="sm">
                    <DialogTitle>
                        {isLastTrip && _.get(operator, 'collector.configuration.enableClockin', true)
                            ? loc('clockOut', lang)
                            : loc('finishTrip', lang)}
                    </DialogTitle>
                    <DialogContent>
                        {!_.isNil(_.get(generalOffloadReminders, `${lang}`, null)) &&
                            !_.isEmpty(_.get(generalOffloadReminders, `${lang}`, [])) && (
                                <CustomFormTitle titleText={loc('rememberTo', lang)} iconName="assignment" />
                            )}
                        <List>
                            {_.get(generalOffloadReminders, `${lang}`, []).map((reminder, index) => (
                                <>
                                    <ListItem>
                                        <ListItemText primary={`${index + 1}. ${reminder}`} />
                                    </ListItem>
                                    <Divider variant="fullWidth" component="li" />
                                </>
                            ))}

                            {isLastTrip &&
                                !_.isNil(_.get(lastTripOffloadReminders, `${lang}`, null)) &&
                                !_.isEmpty(_.get(lastTripOffloadReminders, `${lang}`, [])) &&
                                _.get(lastTripOffloadReminders, `${lang}`, []).map((reminder, index) => (
                                    <>
                                        <ListItem>
                                            <ListItemText
                                                primary={`${index +
                                                    1 +
                                                    _.get(generalOffloadReminders, `${lang}`, []).length}. ${reminder}`}
                                            />
                                        </ListItem>
                                        {index < _.get(lastTripOffloadReminders, `${lang}`, []).length - 1 && (
                                            <Divider variant="fullWidth" component="li" />
                                        )}
                                    </>
                                ))}
                        </List>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            data-cy="info-panel-finish-day-dialog-cancel"
                            color="secondary"
                            onClick={() => setFinishDayDialogOpen(false)}
                        >
                            {loc('cancel', lang)}
                        </Button>
                        <Button data-cy="info-panel-finish-day-dialog-yes" color="primary" onClick={() => handleDone()}>
                            {isLastTrip
                                ? _.get(operator, 'collector.configuration.enableClockin', true)
                                    ? loc('clockOut', lang)
                                    : loc('finishTrip', lang)
                                : loc('proceedToNextTrip', lang)}
                        </Button>
                    </DialogActions>
                </Dialog>
            )}

            {!_.isEmpty(payloadImages) && (
                <Dialog open={payloadImageOpen} fullWidth onClose={handlePayloadImageOpen(false)}>
                    <DialogTitle>Customer Payload Confirmation</DialogTitle>
                    <DialogContent>
                        <ImageViewer
                            displayImages={payloadImages}
                            disableDelete={true}
                            scrollerStyles={{ maxWidth: 420, overflowX: 'auto' }}
                        />
                    </DialogContent>
                </Dialog>
            )}
        </React.Fragment>
    );
}

export default withTheme()(InfoPanel);

class ListItemExpandableWT extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            contentIsOverflown: false,
            expanded: false,
            editing: false,
            overFlowHeight: 50
        };
    }

    primaryTypographyRef = React.createRef();

    componentDidMount() {
        this.setState({ contentIsOverflown: this.contentIsOverflowing(this.primaryTypographyRef.current) }, () =>
            this.context.swipeableViews.slideUpdateHeight()
        );
    }

    componentDidUpdate(prevProps, prevState) {
        let contentIsOverflown = this.contentIsOverflowing(this.primaryTypographyRef.current);
        if (prevState.contentIsOverflown !== contentIsOverflown) {
            this.setState({ contentIsOverflown }, () => this.context.swipeableViews.slideUpdateHeight());
        }

        if (prevProps.id !== this.props.id) {
            this.setState({ expanded: false });
        }
    }

    toggleExpanded = () => {
        if (this.props.setIsExpanded) {
            this.props.setIsExpanded(!this.state.expanded);
        }
        this.setState(prevState => ({ expanded: !prevState.expanded }));
    };

    contentIsOverflowing = () => {
        if (!_.isNil(this.primaryTypographyRef.current)) {
            const element = this.primaryTypographyRef.current;
            return element.offsetHeight > this.state.overFlowHeight;
        }
    };

    handleEditing = state => () => {
        this.setState({ editing: state, expanded: false });
    };

    handleSave = () => {
        const { driverComment } = this.props;

        this.props.onSave(driverComment);
        this.setState({ editing: false });
    };

    render() {
        const {
            theme,
            editable,
            icon,
            primaryText,
            payloadImage,
            driverComment,
            initValue,
            inProgress,
            onSave,
            onDriverComment,
            ...rest
        } = this.props;
        const { contentIsOverflown, expanded, editing, overFlowHeight } = this.state;

        let iconComponent;
        if (editable) {
            if (editing) {
                iconComponent = (
                    <IconButton style={{ padding: 8, color: green[500] }} onClick={this.handleSave}>
                        <Icon>done</Icon>
                    </IconButton>
                );
            } else {
                iconComponent = (
                    <IconButton style={{ padding: 8 }} onClick={this.handleEditing(true)}>
                        <Icon>edit</Icon>
                    </IconButton>
                );
            }
        } else {
            iconComponent = (
                <Avatar style={{ backgroundColor: theme.palette.action.active }}>
                    <Icon>{icon}</Icon>
                </Avatar>
            );
        }

        return (
            <ListItem {...rest}>
                <ListItemIcon style={{ marginRight: 0 }}>
                    {inProgress ? (
                        <CircularProgress size={40} style={{ color: rest.style.backgroundColor }} />
                    ) : (
                        iconComponent
                    )}
                </ListItemIcon>
                <ListItemText
                    disableTypography
                    primary={
                        editing ? (
                            <TextField fullWidth value={driverComment} onChange={this.props.onDriverComment} />
                        ) : (
                            <RootRef rootRef={this.primaryTypographyRef}>
                                <div>{primaryText}</div>
                            </RootRef>
                        )
                    }
                    style={{
                        padding: 0,
                        paddingLeft: theme.spacing.unit * 2,
                        height: expanded ? 'unset' : overFlowHeight,
                        overflow: 'hidden'
                    }}
                />
                {(expanded || contentIsOverflown) && (
                    <ListItemSecondaryAction style={{ right: 0 }}>
                        <IconButton color="default" onClick={this.toggleExpanded}>
                            <Icon color="action">{expanded ? 'expand_less' : 'expand_more'}</Icon>
                        </IconButton>
                    </ListItemSecondaryAction>
                )}
            </ListItem>
        );
    }
}

ListItemExpandableWT.contextTypes = {
    swipeableViews: PropTypes.object.isRequired
};

const ListItemExpandable = withTheme()(ListItemExpandableWT);

function getPickupCombinedComment(pickup) {
    let comment = '';
    if (!_.isNil(pickup.comment)) {
        comment += pickup.comment;
    }
    if (!_.isEmpty(pickup.sms)) {
        if (!_.isEmpty(comment)) {
            comment += ' ';
        }
        comment += pickup.sms.join(' '); //TODO: is this still used?
    }

    return comment;
}

function getDropoffIcon(pickup) {
    let icon;

    if (_.isNil(pickup.customer)) {
        return <Icon>explore</Icon>;
    }

    switch (pickup.pickupType) {
        case RESIDENTIAL:
            icon = 'home';
            break;
        case CONDO:
            icon = 'location_city';
            break;
        case COMMERCIAL:
            icon = 'store';
            break;
        case BOTTLE_DRIVE:
            icon = 'favorite';
            break;
        default:
            icon = 'home';
    }

    return <Icon>{icon}</Icon>;
}

function OffloadElapsedTime({ startTime }) {
    const [timeElapsed, setTimeElapsed] = useState(getTimeDifferenceFromNow(startTime));

    setInterval(() => {
        setTimeElapsed(getTimeDifferenceFromNow(startTime));
    }, 1000);
    return (
        <Typography variant="body2" color="textSecondary" style={{ fontSize: '1.0rem' }}>
            {timeElapsed}
        </Typography>
    );
}

function ETACountDown({ eta, theme, fontSize = '' }) {
    const [secondsElapsed, setSecondsElapsed] = useState(moment(new Date()).diff(moment(eta), 'second'));
    setInterval(() => {
        setSecondsElapsed(moment(new Date()).diff(moment(eta), 'second'));
    }, 1000);
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'flex-end'
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center'
                }}
            >
                <Icon style={{ fontSize }} color={secondsElapsed < 0 ? 'action' : 'error'}>
                    timer
                </Icon>
            </div>{' '}
            <Typography
                variant="body2"
                color={secondsElapsed < 0 ? 'textSecondary' : 'error'}
                data-cy="info-panel-eta-timer"
                style={{ fontSize }}
            >
                {formatsSecondsToTime(Math.abs(secondsElapsed), secondsElapsed > 3600)}
            </Typography>
        </div>
    );
}

function getTimeDifferenceFromNow(time) {
    return formatsSecondsToTime(moment(new Date()).diff(moment(time), 'second'), true);
}

function UndoPickupDialog(props) {
    const {
        open,
        onClose,
        theme,
        trip,
        http,
        operator,
        pickups,
        onSnackbar,
        reloadOperator,
        setShowUndoPickupDialog
    } = props;
    const { lang } = useContext(LocalizationContext);

    let abortedPickups = _(pickups)
        .filter(p => pickupHelper.isAborted(p))
        .value();
    let completedPickups = _(pickups)
        .filter(p => pickupHelper.isComplete(p))
        .value();
    let skippedPickups = _(pickups)
        .filter(p => pickupHelper.isSkipped(p))
        .value();
    const displayPickups = _([
        ...completedPickups,
        ...abortedPickups,
        ...skippedPickups.filter(p => !(p.complete || p.aborted))
    ])
        .orderBy(p => new Date(p.completionDate ? p.completionDate : p.updatedAt), 'desc')
        .value();

    const timezone = !_.isEmpty(pickups) ? _.first(pickups).location.timezone : process.env.REACT_APP_REGION_TIMEZONE;
    let hideCustomerIdentification = false;
    if (operator.accountType === 'Collector Administrator' && !operator.adminView) {
        hideCustomerIdentification = _.get(operator, `collector.hideCustomerIdentification.hideForAdmin`, false);
    } else if (
        operator.accountType === 'Collector Employee' &&
        !operator.adminView &&
        !operator.accountPermissions.includes('Driver')
    ) {
        hideCustomerIdentification = _.get(operator, `collector.hideCustomerIdentification.hideForClerk`, false);
    }

    const { commoditiesAvailable } = useGetMetaData({ http });
    const [driverConfig, setDriverConfig] = useState({});
    const [pickupSelected, setPickupSelected] = useState({});
    const [pickupDialogOpen, setPickupDialogOpen] = useState(false);
    const handleOpenPickupDialog = pickupSelected => {
        if (pickupSelected.bagtag === undefined) {
            pickupSelected.bagtag = _.get(pickupSelected, 'customer.uniqueID', 'N/A');
        }
        setPickupSelected(pickupSelected);
        setPickupDialogOpen(true);
    };

    useEffect(() => {
        (async () => {
            const res = await http.getJSON('/users/getDriversConfig');
            if (res.ok) {
                setDriverConfig(res.data);
            }
        })();
    }, []);

    return (
        <>
            <PickupInfoDialog
                debug={false}
                open={pickupDialogOpen}
                driverConfig={driverConfig}
                allowUndoPickup={true}
                pickup={pickupSelected}
                trip={trip}
                commoditiesAvailable={commoditiesAvailable}
                onClose={() => {
                    setPickupDialogOpen(false);
                    setShowUndoPickupDialog(true);
                }}
                reloadOperator={reloadOperator}
                onSnackbar={onSnackbar}
                hideCustomerIdentification={hideCustomerIdentification}
                http={http}
            />
            <Dialog
                open={open}
                fullWidth
                // maxWidth="sm"
            >
                <DialogTitle>{loc('undoPickups', lang)}</DialogTitle>
                <DialogContent>
                    <div
                        style={{
                            width: '100%',
                            flex: '1',
                            overflowY: 'auto',
                            overflowX: 'hidden',
                            paddingBottom: theme.spacing.unit * 2
                        }}
                    >
                        <List dense disablePadding>
                            {displayPickups.map(pickup => {
                                const timeCompleted = pickup.completionDate ? pickup.completionDate : pickup.updatedAt;
                                const eta = _.last(_.get(pickup, 'estimatedCompletionDates', []));
                                let etaDiff = null,
                                    isGoodTime = true;
                                if (eta) {
                                    etaDiff = moment(timeCompleted).diff(eta, 'minutes');
                                    isGoodTime = etaDiff > -10 && etaDiff < 10;
                                }

                                const tripStats = pickup.tripStats;

                                let stopBaseDuration = _.get(trip, 'stopBaseDuration');
                                let stopPerBagDuration = _.get(trip, 'driverEfficiency');

                                if (_.isNil(stopBaseDuration)) {
                                    stopBaseDuration = _.get(
                                        pickup,
                                        'receiver.stopBaseDuration',
                                        _.get(driverConfig, 'stopBaseDuration', 240)
                                    );
                                }

                                if (_.isNil(stopPerBagDuration)) {
                                    stopPerBagDuration = _.get(
                                        pickup,
                                        'receiver.driverEfficiency',
                                        _.get(driverConfig, 'stopPerBagDuration', 30)
                                    );
                                }

                                stopBaseDuration = _.round(stopBaseDuration / 60, 2);
                                stopPerBagDuration = _.round(stopPerBagDuration / 60, 2);

                                let primaryText = formatAsAddress(pickup.location);
                                let secondaryText = (
                                    <span>
                                        {moment(timeCompleted)
                                            .tz(timezone)
                                            .format('h:mm A')}
                                        {pickup.skipped && (
                                            <span
                                                style={{
                                                    color: colors.orange[500]
                                                }}
                                            >
                                                {' '}
                                                ({loc('skipped', lang)})
                                            </span>
                                        )}
                                        {!pickup.skipped && etaDiff && (
                                            <span
                                                style={{
                                                    color: _.get(tripStats, 'isGoodTime', true)
                                                        ? colors.green[500]
                                                        : colors.red[500]
                                                }}
                                            >
                                                ({Math.abs(etaDiff)} mins
                                                {etaDiff > 0 ? ' late' : ' early'})
                                            </span>
                                        )}{' '}
                                        - {pickup.receiverDistance ? pickup.receiverDistance + 'm ' : 'N/A '}
                                        <br />
                                        {_.get(pickup, 'pickupType') === 'Commercial'
                                            ? _.get(pickup, 'location.businessName')
                                            : (
                                                  _.get(pickup, 'customer.name.first', loc('guest', lang)) +
                                                  ' ' +
                                                  _.get(pickup, 'customer.name.last', '').charAt(0)
                                              ).trim()}{' '}
                                        ({_.get(pickup, 'customer.uniqueID', 'N/A')}){' '}
                                        {pickup.complete && (
                                            <span
                                                style={{
                                                    padding: '0px 6px',
                                                    backgroundColor:
                                                        theme.palette.type === 'light'
                                                            ? theme.palette.grey[100]
                                                            : theme.palette.grey[900],
                                                    color: theme.palette.text.primary,
                                                    borderRadius: 4,
                                                    border: `1px solid ${
                                                        theme.palette.type === 'light'
                                                            ? theme.palette.grey[300]
                                                            : theme.palette.grey[700]
                                                    }`
                                                }}
                                            >
                                                {pickup.bagtag || _.get(pickup, 'customer.uniqueID', 'N/A')}
                                            </span>
                                        )}
                                    </span>
                                );
                                if (hideCustomerIdentification) {
                                    primaryText = (
                                        <span>
                                            {moment(timeCompleted)
                                                .tz(timezone)
                                                .format('h:mm A')}
                                            {pickup.skipped && (
                                                <span
                                                    style={{
                                                        color: colors.orange[500]
                                                    }}
                                                >
                                                    ({loc('skipped', lang)})
                                                </span>
                                            )}
                                            {!pickup.skipped && etaDiff && (
                                                <span
                                                    style={{
                                                        color: _.get(tripStats, 'isGoodTime', true)
                                                            ? colors.green[500]
                                                            : colors.red[500]
                                                    }}
                                                >
                                                    ({Math.abs(etaDiff)} mins
                                                    {etaDiff > 0 ? ' ' + loc('late', lang) : ' ' + loc('early', lang)})
                                                </span>
                                            )}{' '}
                                            - {pickup.receiverDistance ? pickup.receiverDistance + 'm ' : 'N/A '}
                                            <br /> {_.get(pickup, 'customer.uniqueID', 'N/A')}{' '}
                                            {pickup.complete && (
                                                <span
                                                    style={{
                                                        padding: '0px 6px',
                                                        backgroundColor:
                                                            theme.palette.type === 'light'
                                                                ? theme.palette.grey[100]
                                                                : theme.palette.grey[900],
                                                        color: theme.palette.text.primary,
                                                        borderRadius: 4,
                                                        border: `1px solid ${
                                                            theme.palette.type === 'light'
                                                                ? theme.palette.grey[300]
                                                                : theme.palette.grey[700]
                                                        }`
                                                    }}
                                                >
                                                    {pickup.bagtag || _.get(pickup, 'customer.uniqueID', 'N/A')}
                                                </span>
                                            )}
                                        </span>
                                    );
                                    secondaryText = null;
                                }

                                return (
                                    <React.Fragment key={pickup._id}>
                                        <ListItem style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <ListItemAvatar>
                                                    <Avatar
                                                        style={{
                                                            backgroundColor: !_.isNil(pickup.customer)
                                                                ? pickup.aborted
                                                                    ? theme.palette.error.main
                                                                    : pickup.complete
                                                                    ? theme.palette.primary.main
                                                                    : pickup.skipped
                                                                    ? colors.yellow[600]
                                                                    : colors.grey[500]
                                                                : colors.grey[500]
                                                        }}
                                                    >
                                                        {_pickup.numberOfPayloadBags(pickup, commoditiesAvailable)}
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText primary={primaryText} secondary={secondaryText} />
                                            </div>
                                            <ListItemText
                                                style={{ padding: 0 }}
                                                primary={
                                                    <span>
                                                        {pickup.complete && (
                                                            <>
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        justifyContent: 'space-between'
                                                                    }}
                                                                >
                                                                    <PickupStatusIndicators
                                                                        pickup={pickup}
                                                                        style={{ marginTop: theme.spacing.unit }}
                                                                        indicatorSize={0.75}
                                                                        labelStyle={{
                                                                            fontSize: '0.75rem'
                                                                        }}
                                                                        numberOfBins={pickup.numberOfBins}
                                                                    />
                                                                    {!_pickup.isOutOfSystemStop(pickup) && (
                                                                        <DetailedPayloadIndicators
                                                                            showUnits
                                                                            commodities={commoditiesAvailable}
                                                                            pickups={[pickup]}
                                                                            style={{ marginTop: theme.spacing.unit }}
                                                                            lang={lang}
                                                                        />
                                                                    )}
                                                                </div>
                                                            </>
                                                        )}
                                                        <br />
                                                        {tripStats && (
                                                            <>
                                                                <span
                                                                    style={{
                                                                        color:
                                                                            tripStats.actualTimeFromLastLocation <=
                                                                            tripStats.estTimeFromLastLocation + 2
                                                                                ? colors.green[500]
                                                                                : colors.red[500]
                                                                    }}
                                                                >
                                                                    (DBG) ETA:{' '}
                                                                    <Tooltip title="ETA">
                                                                        <span>
                                                                            {moment(eta)
                                                                                .tz(timezone)
                                                                                .format('h:mm A')}
                                                                        </span>
                                                                    </Tooltip>{' '}
                                                                    {` (${
                                                                        tripStats.etaDifferenceFromLastETA > 0
                                                                            ? '+'
                                                                            : ''
                                                                    }`}
                                                                    <Tooltip title="Difference from last ETA">
                                                                        <span>
                                                                            {tripStats.etaDifferenceFromLastETA}
                                                                        </span>
                                                                    </Tooltip>
                                                                    {` from last ETA)`}
                                                                </span>
                                                                <br />
                                                                <span
                                                                    style={{
                                                                        color:
                                                                            tripStats.actualTimeFromLastLocation <=
                                                                            tripStats.estTimeFromLastLocation + 2
                                                                                ? colors.green[500]
                                                                                : colors.red[500]
                                                                    }}
                                                                >
                                                                    Est: {tripStats.estTimeFromLastLocation} min, Act:{' '}
                                                                    {tripStats.actualTimeFromLastLocation} min
                                                                </span>
                                                                <br />
                                                                <span
                                                                    style={{
                                                                        color:
                                                                            tripStats.actualTimeFromLastLocation <=
                                                                            tripStats.estTimeFromLastLocation + 2
                                                                                ? colors.green[500]
                                                                                : colors.red[500]
                                                                    }}
                                                                >
                                                                    Breakdown:{' '}
                                                                    <Tooltip title="Base Stop Duration (min)">
                                                                        <span>{stopBaseDuration}</span>
                                                                    </Tooltip>
                                                                    {' + '}
                                                                    <Tooltip title="Bags">
                                                                        <span>
                                                                            {_pickup.numberOfPayloadBags(
                                                                                pickup,
                                                                                commoditiesAvailable
                                                                            )}
                                                                        </span>
                                                                    </Tooltip>
                                                                    {' * '}
                                                                    <Tooltip title="Time Per Bag (min)">
                                                                        <span>{stopPerBagDuration}</span>
                                                                    </Tooltip>
                                                                    {' + '}
                                                                    <Tooltip title="Driving Time (min)">
                                                                        <span>
                                                                            {tripStats.estDrivingTimeFromLastLocation}
                                                                        </span>
                                                                    </Tooltip>
                                                                </span>
                                                                <br />
                                                                <span
                                                                    style={{
                                                                        color:
                                                                            tripStats.actualTimeFromLastLocation <=
                                                                            tripStats.estTimeFromLastLocation + 2
                                                                                ? colors.green[500]
                                                                                : colors.red[500]
                                                                    }}
                                                                >
                                                                    <Tooltip title="Start Address">
                                                                        <span>
                                                                            {
                                                                                _.get(
                                                                                    tripStats,
                                                                                    'legStartAddress',
                                                                                    ''
                                                                                ).split(',')[0]
                                                                            }
                                                                        </span>
                                                                    </Tooltip>
                                                                    {' -> '}
                                                                    <Tooltip title="End Address">
                                                                        <span>
                                                                            {
                                                                                _.get(
                                                                                    tripStats,
                                                                                    'legEndAddress',
                                                                                    ''
                                                                                ).split(',')[0]
                                                                            }
                                                                        </span>
                                                                    </Tooltip>
                                                                </span>
                                                            </>
                                                        )}
                                                    </span>
                                                }
                                            />
                                            <ListItemSecondaryAction>
                                                <IconButton
                                                    data-cy="driver-history-pickup-btn"
                                                    onClick={() => {
                                                        handleOpenPickupDialog(pickup);
                                                        onClose();
                                                    }}
                                                >
                                                    <Icon>info</Icon>
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        {_.get(tripStats, 'etaRecalculations', []).map((e, idx) => {
                                            return (
                                                <div
                                                    key={idx}
                                                    style={{
                                                        marginBottom: theme.spacing.unit * 2,
                                                        textAlign: 'center'
                                                    }}
                                                >
                                                    {' '}
                                                    <Typography variant="caption" style={{ color: colors.red[500] }}>
                                                        {e.initiator === 'N/A'
                                                            ? ''
                                                            : e.manualRecalculation
                                                            ? 'Manual '
                                                            : 'Automatic '}
                                                        ETA Recalc Triggered By {e.initiator}
                                                    </Typography>
                                                    <Divider
                                                        style={{
                                                            background: colors.red[500]
                                                        }}
                                                    />
                                                    <Typography variant="caption" style={{ color: colors.red[500] }}>
                                                        (
                                                        {e.recalculationTimestamp
                                                            ? moment(e.recalculationTimestamp)
                                                                  .tz(timezone)
                                                                  .format('h:mm:ss A')
                                                            : 'N/A'}
                                                        )
                                                        {!_.isNil(e.notificationSent) &&
                                                            e.notificationSent > 0 &&
                                                            `[Notification Sent]`}
                                                    </Typography>
                                                </div>
                                            );
                                        })}
                                    </React.Fragment>
                                );
                            })}
                        </List>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button data-cy="UndoPickupDialogClose" color="secondary" onClick={onClose}>
                        {loc('close', lang)}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
