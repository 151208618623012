import React from 'react';

import Typography from '@material-ui/core/Typography';

export default {
    paragraph: muiParagraph,
    heading: muiHeading,
    listItem: muiListItem
};

function muiParagraph(props) {
    return <Typography variant="body2">{props.children}</Typography>;
}

function muiHeading(props) {
    if (props.level === 1) {
        return (
            <Typography variant="h4" align="center" style={{ marginTop: 16, marginBottom: 32 }}>
                {props.children}
            </Typography>
        );
    } else {
        return (
            <Typography variant="h6" style={{ marginTop: 8, marginBottom: 8 }}>
                {props.children}
            </Typography>
        );
    }
}

function muiListItem(props) {
    return (
        <li>
            <Typography variant="body2">{props.children}</Typography>
        </li>
    );
}
