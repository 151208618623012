import React from 'react';
import _ from 'lodash';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { Typography, withTheme } from '@material-ui/core';
import moment from 'moment-timezone';
import { colors } from '@material-ui/core';
function BulkTripSelectionDropdown({
    theme,
    fullWidth,
    helperText,
    loading,
    bulkTripFilter,
    handleChange,
    labelCustomWidth,
    trips,
    showAll = true,
    gutterTop = false,
    style = {}
}) {
    return (
        <FormControl fullWidth={fullWidth} style={style}>
            <InputLabel variant="outlined" htmlFor="receiver-filter">
                {helperText}
            </InputLabel>
            <Select
                data-cy="bulk-trip-selection-dropdown"
                value={loading ? 'loading' : bulkTripFilter}
                onChange={handleChange}
                disabled={loading}
                inputProps={{
                    name: 'trip-filter',
                    id: 'trip-filter'
                }}
                input={<OutlinedInput labelWidth={labelCustomWidth || 36} name="trip" id="outlined-age-simple" />}
                style={{
                    minWidth: 140
                }}
            >
                {loading && (
                    <MenuItem value="loading">
                        <em>Loading...</em>
                    </MenuItem>
                )}
                {showAll && (
                    <MenuItem value="all">
                        <em>All</em>
                    </MenuItem>
                )}
                {trips &&
                    trips.length > 0 &&
                    trips.map(t => {
                        return (
                            <MenuItem value={t._id} key={t._id}>
                                <Typography style={{ fontSize: '90%' }}>
                                    <span
                                        style={{
                                            color:
                                                t.numberOfCompletedBulks === t.numberOfBulks
                                                    ? 'green'
                                                    : t.numberOfOverdueBulks > 0
                                                    ? 'red'
                                                    : colors.yellow[700]
                                        }}
                                    >
                                        {`${t.numberOfCompletedBulks || '0'}/${t.numberOfBulks || '0'}`}
                                    </span>{' '}
                                    —{' '}
                                    <em>
                                        {' ['}
                                        {t.transporterCollectorCode}
                                        {'] '}
                                        {t.driverName}
                                    </em>{' '}
                                    ({moment(t.startTime).format('MMM DD, YYYY - hh:ss')})
                                </Typography>
                            </MenuItem>
                        );
                    })}
            </Select>
        </FormControl>
    );
}

export default withTheme()(BulkTripSelectionDropdown);
