import React, { useContext } from 'react';

import { DialogActions, DialogContent, Button, withTheme, Typography } from '@material-ui/core';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

function NoLocationfound(props) {
    const { onClose, onBack, inProgress, theme, isLastScreen, dropOffType } = props;

    const { lang } = useContext(LocalizationContext);

    return (
        <>
            <DialogContent>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography
                        variant="h6"
                        style={{ marginTop: isLastScreen ? theme.spacing.unit * 2 : theme.spacing.unit }}
                    >
                        {loc('quickdropNoLocationFound', lang, {
                            technology: loc(dropOffType, lang)
                        })}
                    </Typography>
                </div>
            </DialogContent>
            <DialogActions style={{ marginBottom: 'max(8px, env(safe-area-inset-bottom, 8px))' }}>
                <Button color="primary" onClick={onBack}>
                    {loc('back', lang)}
                </Button>
                <Button color="primary" disabled={inProgress} onClick={onClose}>
                    {loc('close', lang)}
                </Button>
            </DialogActions>
        </>
    );
}

export default withTheme()(NoLocationfound);
