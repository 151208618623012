import React from 'react';

import * as colors from '@material-ui/core/colors';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import { withTheme } from '@material-ui/core/styles';

function CustomFormTitle({ theme, style = {}, iconStyle = {}, titleText, iconName, noMarginBottom = false }) {
    const styleCustom = {
        display: 'inline-flex',
        marginBottom: noMarginBottom ? 0 : theme.spacing.unit * 2,
        borderRadius: 4,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing.unit / 2,
        width: '100%',
        ...style
    };

    return (
        <span style={styleCustom}>
            <Icon color="primary" style={{ ...iconStyle, paddingLeft: 0, marginRight: theme.spacing.unit }}>
                {iconName}
            </Icon>
            <Typography
                variant="subtitle2"
                style={{
                    color: theme.palette.text.secondary,
                    fontWeight: 'bold',
                    marginTop: 2
                }}
            >
                <span style={{ marginBottom: theme.spacing.unit }}>{titleText}</span>
            </Typography>
        </span>
    );
}

export default withTheme()(CustomFormTitle);
