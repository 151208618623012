import React, { useState, useContext, useEffect } from 'react';
import _ from 'lodash';

import {
    Dialog,
    DialogContent,
    DialogActions,
    withTheme,
    withMobileDialog,
    DialogTitle,
    Button,
    Typography,
    FormControl,
    FormControlLabel,
    InputLabel,
    Select,
    OutlinedInput,
    MenuItem,
    FormHelperText,
    colors,
    Checkbox,
    FormLabel,
    Collapse,
    CircularProgress
} from '@material-ui/core';

import StringListInput from 'components/CustomInputs/StringListInput';

import { _commodity } from 'std';

import CommodityListInput from '../Inputs/CommodityListInput';

import useGetJSON from 'utils/hooks/useGetJSON';

import HttpContext from 'utils/contexts/HttpContext';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import SnackbarContext from 'components/CustomSnackbar/SnackbarContext';
import { loc } from 'localizations/localizationHandler';
import { useMemo } from 'react';

function CollectorCommoditiesDialog(props) {
    const { collector, open, allCommodities, commoditiesLoading, onClose, reloadCollectors, theme, fullScreen } = props;

    const http = useContext(HttpContext);
    const { lang } = useContext(LocalizationContext);
    const onSnackbar = useContext(SnackbarContext);
    const [loading, setLoading] = useState(false);

    //const [payloadAccepted, setPayloadAccepted] = useState(_.get(collector, 'payloadAccepted', []));
    const [commoditiesSelected, setCommoditiesSelected] = useState(
        getInitialCommoditiesSelected(collector, allCommodities)
    );
    //const [payloadRequired, setPayloadRequired] = useState(_.get(collector, 'payloadRequired', []));
    const [disableSubmission, setDisableSubmission] = useState(false);

    const [commodityPickupConfig, setCommodityPickupConfig] = useState(_.get(collector, 'commodityPickupConfig', []));

    const commoditiesToShow = useMemo(() => {
        return _.filter(allCommodities, commodity => _.get(commodity, 'commodityPickupsEnabled', false));
    }, [allCommodities]);

    const errCommodityPickupConfig = useMemo(() => {
        return _.some(commoditiesSelected, commodityId => {
            const commodity = _.find(allCommodities, { _id: commodityId });
            const commodityConfig = _.find(commodityPickupConfig, { commodity: commodity._id });
            return _.isNil(commodityConfig) || _.isEmpty(_.get(commodityConfig, 'emails', []));
        });
    }, [commodityPickupConfig, commoditiesSelected]);

    const handleUpdateCommoditiesSelected = _id => {
        let newValues;
        const index = _.findIndex(commoditiesSelected, commodityId => commodityId === _id);
        if (index !== -1) {
            let newSelectedCommodityIds = _.cloneDeep(commoditiesSelected);
            newSelectedCommodityIds.splice(index, 1);
            newValues = newSelectedCommodityIds;
        } else {
            newValues = [...commoditiesSelected, _id];
        }

        setCommoditiesSelected(newValues);
    };

    const handleUpdateCollectorCommodities = async () => {
        setLoading(true);

        const collectorCopy = _.cloneDeep(collector);
        const commodityPickupConfigCopy = _.cloneDeep(commodityPickupConfig);

        for (let config of commodityPickupConfigCopy) {
            config.collectorSubmittable = _.includes(commoditiesSelected, config.commodity);
        }

        collectorCopy.commodityPickupConfig = commodityPickupConfigCopy;

        await http.postJSON(`/collector/${collector._id}/updateCollector`, collectorCopy);

        await reloadCollectors();
        setLoading(false);
        onSnackbar('Collector commodity pickups updated!');
        onClose();
    };

    const handleUpdateEmails = (commodity, emails) => {
        const configForCommodityI = _.findIndex(commodityPickupConfig, {
            commodity: commodity._id
        });
        const commodityPickupConfigCopy = _.cloneDeep(commodityPickupConfig);
        if (configForCommodityI === -1) {
            commodityPickupConfigCopy.push({
                commodity: commodity._id,
                emails
            });
        } else {
            commodityPickupConfigCopy[configForCommodityI].emails = emails;
        }
        setCommodityPickupConfig(commodityPickupConfigCopy);
    };

    useEffect(() => {
        setCommoditiesSelected(getInitialCommoditiesSelected(collector, allCommodities));
        //setPayloadRequired(_.get(collector, 'payloadRequired', []));

        setCommodityPickupConfig(_.get(collector, 'commodityPickupConfig', []));
    }, [collector, open]);

    return (
        <Dialog open={open} fullScreen={fullScreen} onClose={onClose} maxWidth={'sm'} fullWidth>
            <DialogTitle>{_.get(collector, 'name', '')} Commodity Pickup</DialogTitle>
            <DialogContent>
                {!commoditiesLoading && (
                    <>
                        <FormControl component="fieldset">
                            {commoditiesToShow.map(commodity => {
                                const configForCommodityI = _.findIndex(commodityPickupConfig, {
                                    commodity: commodity._id
                                });
                                const emailsForCommodity = _.get(
                                    commodityPickupConfig,
                                    `[${configForCommodityI}].emails`,
                                    []
                                );

                                const commodityChecked =
                                    _.findIndex(commoditiesSelected, commodityId => commodityId === commodity._id) !==
                                    -1;
                                return (
                                    <React.Fragment key={commodity._id}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    data-cy={`checkbox-${_commodity.getSkuType(commodity)}`}
                                                    checked={commodityChecked}
                                                    onChange={() => handleUpdateCommoditiesSelected(commodity._id)}
                                                />
                                            }
                                            label={_commodity.getSkuType(commodity)}
                                        />
                                        <Collapse in={true}>
                                            <StringListInput
                                                InputProps={{
                                                    variant: 'outlined'
                                                }}
                                                label="Email"
                                                values={emailsForCommodity}
                                                onChange={values => handleUpdateEmails(commodity, values)}
                                                error={email => {
                                                    let regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                                                    return !(email === '') && !regexEmail.test(email.toLowerCase());
                                                }}
                                                errorMessage="Please enter a valid email address."
                                                disabled={!_.get(commodity, 'commodityPickupsEnabled', false)}
                                                setDisableSubmission={setDisableSubmission}
                                            />
                                        </Collapse>
                                    </React.Fragment>
                                );
                            })}
                        </FormControl>
                        {/*<CommodityListInput
                            label="Commodities Accepted"
                            enabledCommodities={allCommodities.map(commodity => commodity._id)}
                            selectedCommodityIds={payloadAccepted}
                            allCommodities={allCommodities}
                            onChange={handleUpdatePayloadAccepted}
                        />*/}
                    </>
                )}
            </DialogContent>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    padding: theme.spacing.unit * 2
                }}
            >
                {disableSubmission && (
                    <FormHelperText>
                        <Typography style={{ color: colors.orange[500] }}>
                            Finish entering all values before submission
                        </Typography>
                    </FormHelperText>
                )}
            </div>

            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button
                    onClick={handleUpdateCollectorCommodities}
                    color="primary"
                    disabled={disableSubmission || loading || errCommodityPickupConfig}
                    data-cy="collector-commodities-dialog-submit-button"
                >
                    {loading ? <CircularProgress size={24} color="primary" /> : loc('submit', lang)}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default withMobileDialog()(withTheme()(CollectorCommoditiesDialog));

function getInitialCommoditiesSelected(collector, allCommodities) {
    return _.get(collector, 'commodityPickupConfig', [])
        .filter(config => {
            const commodityForConfig = _.find(allCommodities, commodity => commodity._id === config.commodity);
            return (
                config.collectorSubmittable &&
                !_.isNil(commodityForConfig) &&
                !_.get(commodityForConfig, 'disabled', false)
            );
        })
        .map(config => _.get(config, 'commodity'));
}
