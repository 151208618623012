import React, { useContext } from 'react';

import { Dialog, DialogTitle, DialogContent, DialogActions, Button, withTheme, Typography } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableCell from '@material-ui/core/TableCell';
import { green, red } from '@material-ui/core/colors';
import Link from '@material-ui/core/Link';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment-timezone';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';
import ConfirmDialogContext from 'components/Dialogs/Confirm/ConfirmDialogContext';

function InfractionsDialog(props) {
    const {
        open,
        onClose,
        title,
        infractionList,
        resolveInfraction,
        unresolveInfraction,
        handlerDocs,
        collectorDocs,
        handleViewDriver,
        handleViewCollector,
        resolveAllInfraction,
        unresolveAllInfraction
    } = props;
    const { lang } = useContext(LocalizationContext);
    const warnAction = useContext(ConfirmDialogContext);

    const headCells = [
        { id: 'infractionType', numeric: false, disablePadding: false, canSort: false, label: 'Infraction Type' },
        { id: 'reason', numeric: false, disablePadding: false, canSort: false, label: 'Reason' },
        { id: 'infractionDate', numeric: false, disablePadding: false, canSort: true, label: 'Infraction Date' },
        { id: 'resolutionDate', numeric: false, disablePadding: false, canSort: true, label: 'Resolution Date' },
        { id: 'collector', numeric: false, disablePadding: false, canSort: true, label: 'Collector' },
        { id: 'handler', numeric: false, disablePadding: false, canSort: true, label: 'Handler' },
        { id: 'address', numeric: false, disablePadding: false, canSort: true, label: 'Address' },
        { id: 'status', numeric: false, disablePadding: false, canSort: true, label: 'Status' },
        { id: 'action', numeric: false, disablePadding: false, canSort: false, label: 'Action' }
    ];

    function EnhancedTableHead(props) {
        const { order, orderBy, onRequestSort } = props;
        const createSortHandler = headCell => event => {
            if (!headCell.canSort) return;
            onRequestSort(event, headCell.id);
        };

        return (
            <TableHead>
                <TableRow>
                    {headCells.map(headCell => (
                        <TableCell
                            key={headCell.id}
                            // align={headCell.numeric ? "right" : "left"}
                            padding={headCell.disablePadding ? 'none' : 'dense'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell)}
                            >
                                {headCell.label}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    EnhancedTableHead.propTypes = {
        classes: PropTypes.object.isRequired,
        onRequestSort: PropTypes.func.isRequired,
        order: PropTypes.oneOf(['asc', 'desc']).isRequired,
        orderBy: PropTypes.string.isRequired,
        rowCount: PropTypes.number.isRequired
    };

    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map(el => el[0]);
    }

    return (
        <Dialog open={open} fullWidth maxWidth={'xl'} onClose={onClose}>
            <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
                <DialogTitle>{title}</DialogTitle>
                <div style={{ marginTop: 20, marginRight: 20 }}>
                    <Button
                        onClick={() => {
                            warnAction(() => {
                                resolveAllInfraction();
                            }, 'Are you sure you want to resolve all infractions from this user?');
                        }}
                        style={{ backgroundColor: green['500'] }}
                        variant="contained"
                    >
                        {loc('resolveAll', lang)}
                    </Button>
                    <Button
                        onClick={() => {
                            warnAction(() => {
                                unresolveAllInfraction();
                            }, 'Are you sure you want to unresolve all infractions from this user?.');
                        }}
                        style={{ backgroundColor: red['500'], marginLeft: 10 }}
                        variant="contained"
                    >
                        {loc('unresolveAll', lang)}
                    </Button>
                </div>
            </div>
            <DialogContent>
                <Table aria-labelledby="tableTitle" aria-label="enhanced table" style={{}}>
                    <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
                    <TableBody>
                        {stableSort(infractionList, getComparator(order, orderBy)).map((row, index) => {
                            let infractionId = _.get(row, '_id', '');
                            let infractionType = _.get(row, 'infractionType', '');
                            let infractionTypeText = loc(infractionType, lang);
                            let reason = _.get(row, 'reason', '');
                            let isResolved = _.get(row, 'resolved', false);
                            let status = isResolved ? 'Resolved' : 'Unresolved';
                            let infractionDate = _.get(row, 'infractionDate', '');
                            if (infractionDate !== '') {
                                infractionDate = moment(infractionDate).format('YYYY-MM-DD HH:mm');
                            }
                            let resolutionDate = isResolved ? _.get(row, 'resolutionDate', '') : '';
                            if (resolutionDate !== '') {
                                resolutionDate = moment(resolutionDate).format('YYYY-MM-DD HH:mm');
                            }
                            let collectorId = _.get(row, 'collector', '');
                            let handlerId = _.get(row, 'handler', '');
                            let address = _.get(row, 'address', '');
                            let collectorObj = _.find(collectorDocs, { _id: collectorId });
                            let collectorName = _.get(collectorObj, 'name', '');
                            let handlerObj = _.find(handlerDocs, { _id: handlerId });
                            let handlerName = `${_.get(handlerObj, 'name.first', '')} ${_.get(
                                handlerObj,
                                'name.last',
                                ''
                            )}`;
                            if (_.isNil(resolutionDate)) {
                                resolutionDate = '';
                            }
                            return (
                                <TableRow key={'row' + index}>
                                    <TableCell
                                        padding={'dense'}
                                        style={{ minWidth: infractionTypeText.length > 20 ? '200px' : undefined }}
                                    >
                                        {infractionTypeText}
                                    </TableCell>
                                    <TableCell padding={'dense'} style={{ minWidth: '300px' }}>
                                        {reason}
                                    </TableCell>
                                    <TableCell padding={'dense'} style={{}}>
                                        {infractionDate}
                                    </TableCell>
                                    <TableCell padding={'dense'} style={{}}>
                                        {resolutionDate}
                                    </TableCell>
                                    <TableCell
                                        padding={'dense'}
                                        style={{}}
                                        onClick={() => {
                                            if (!_.isNil(_.get(collectorObj, 'collectorManager', null))) {
                                                handleViewCollector(collectorObj);
                                            }
                                        }}
                                    >
                                        {_.isNil(_.get(collectorObj, 'collectorManager', null)) ? (
                                            <Typography>{collectorName}</Typography>
                                        ) : (
                                            <Link>{collectorName}</Link>
                                        )}
                                    </TableCell>
                                    <TableCell
                                        padding={'dense'}
                                        style={{}}
                                        onClick={() => {
                                            handleViewDriver(handlerObj);
                                        }}
                                    >
                                        <Link>{handlerName}</Link>
                                    </TableCell>
                                    <TableCell padding={'dense'} style={{}}>
                                        {address}
                                    </TableCell>
                                    <TableCell padding={'dense'} style={{}}>
                                        <Typography
                                            padding={'dense'}
                                            style={{ color: isResolved ? green['500'] : red['500'] }}
                                        >
                                            {status}
                                        </Typography>
                                    </TableCell>
                                    <TableCell padding={'dense'} style={{}}>
                                        <Button
                                            onClick={() => {
                                                if (isResolved) {
                                                    unresolveInfraction(infractionId);
                                                } else {
                                                    resolveInfraction(infractionId);
                                                }
                                            }}
                                            style={{ backgroundColor: isResolved ? red['500'] : green['500'] }}
                                            variant="contained"
                                        >
                                            {loc(isResolved ? 'unresolve' : 'resolve', lang)}
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>{loc('close', lang)}</Button>
            </DialogActions>
        </Dialog>
    );
}

export default withTheme()(InfractionsDialog);
