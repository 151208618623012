import React from 'react';
import _ from 'lodash';
import { withTheme } from '@material-ui/core/styles';
import SelectDropDownTemplate from '../SelectDropDownTemplate';

import { getCustomerName } from 'utils/misc';

function ReceiverDropDownSelect({ theme, style, value, receivers, cypress_id, id, showAll = true, onChange }) {
    let updatedStyle = _.cloneDeep(style);

    if (_.isNil(style)) {
        updatedStyle = { marginRight: theme.spacing.unit, width: 160 };
    }

    return (
        <SelectDropDownTemplate
            helperText="Driver"
            id={id}
            name="receivers"
            value={value}
            onChange={onChange}
            labelCustomWidth={'driver'.length * theme.spacing.unit}
            items={receivers}
            itemValueKey="_id"
            itemRenderValueFunction={getCustomerName}
            cypress_id={cypress_id}
            showAll={showAll}
            style={updatedStyle}
        />
    );
}

export default withTheme()(ReceiverDropDownSelect);
