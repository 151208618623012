import React, { useState, useContext } from 'react';
import _ from 'lodash';
import * as colors from '@material-ui/core/colors';
import { withTheme } from '@material-ui/core/styles';

import {
    TextField,
    Button,
    Chip,
    Icon,
    InputAdornment,
    IconButton,
    Typography,
    Paper,
    Tooltip
} from '@material-ui/core';
import ConfirmDialogContext from 'components/Dialogs/Confirm/ConfirmDialogContext';

function StringListInput(props) {
    const {
        label,
        values = [],
        onChange,
        InputProps,
        theme,
        style,
        error,
        errorMessage, //accepts a string or a function if you need to validate multiple rules
        helperText,
        disabled = false,
        setDisableSubmission = () => {},
        longStringFormat = false,
        type = undefined,
        infoText = '',
        useButton = false
    } = props;

    const [inputValue, setInputValue] = useState('');

    const duplicateValue = _.some(values, value => formatString(type, value) === formatString(type, inputValue));

    const warnAction = useContext(ConfirmDialogContext);

    const handleAdd = () => {
        const newValues = [...values, formatString(type, inputValue.trim())];
        onChange(newValues);
        setInputValue('');
        setDisableSubmission(false);
    };

    const handleRemove = i => {
        warnAction(() => {
            let valuesCopy = _.cloneDeep(values);
            valuesCopy.splice(i, 1);
            onChange(valuesCopy);
        }, `Are you sure you want to remove ${formatString(type, values[i])}?`);
    };

    const handleKeyPress = e => {
        e = e || window.event;
        if (e.key === 'Enter' && !_.isEmpty(inputValue) && !duplicateValue && !error(inputValue)) {
            handleAdd();
        }
    };

    const handlePaste = e => {
        e.preventDefault();
        let paste = (e.clipboardData || window.clipboardData).getData('text');
        paste = type === 'phone' ? paste.replace(/[^0-9]/g, '').trim() : paste;
        setInputValue(paste);
    };

    const handleChange = e => {
        setInputValue(e.target.value);

        if (setDisableSubmission) {
            setDisableSubmission(!_.isEmpty(e.target.value));
        }
    };

    const errorMessageVal = typeof errorMessage === 'function' ? errorMessage(inputValue) : errorMessage;

    return (
        <div style={style}>
            <TextField
                data-cy="string-list-input"
                onChange={handleChange}
                onPaste={handlePaste}
                label={label}
                value={inputValue}
                margin="normal"
                fullWidth
                onKeyDown={handleKeyPress}
                error={error(inputValue) || duplicateValue}
                helperText={
                    (duplicateValue &&
                        `You already entered "${type === 'phone' ? formatString('phone', inputValue) : inputValue}"`) ||
                    (error(inputValue) && errorMessageVal) ||
                    (!error(inputValue) && helperText)
                }
                disabled={disabled}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {!useButton && (
                                <IconButton
                                    data-cy="string-list-input-add-button"
                                    color="primary"
                                    onClick={handleAdd}
                                    disabled={_.isEmpty(inputValue) || duplicateValue || error(inputValue)}
                                >
                                    <Icon>add_circle</Icon>
                                </IconButton>
                            )}
                            {useButton && (
                                <Button
                                    data-cy="string-list-input-add-button"
                                    color="primary"
                                    variant="contained"
                                    onClick={handleAdd}
                                    disabled={_.isEmpty(inputValue) || duplicateValue || error(inputValue)}
                                >
                                    Add
                                </Button>
                            )}
                            {!_.isEmpty(infoText) && (
                                <Tooltip
                                    title={infoText}
                                    data-cy="string-list-input-info-text"
                                    style={{ color: theme.palette.text.disabled, fontSize: 18 }}
                                >
                                    <Icon data-cy="string-list-input-info-icon">info</Icon>
                                </Tooltip>
                            )}
                        </InputAdornment>
                    )
                }}
                InputLabelProps={{
                    style: { maxWidth: '89%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }
                }}
                {...InputProps}
            />
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {values.map((value, i) =>
                    longStringFormat ? (
                        <Paper
                            key={i}
                            style={{
                                margin: theme.spacing.unit / 2,
                                padding: theme.spacing.unit / 2,
                                background: theme.palette.background.default
                            }}
                        >
                            <div style={{ display: 'inline-flex' }}>
                                <div>
                                    <Typography variante="caption" style={{ padding: '3px' }}>
                                        {value}
                                    </Typography>
                                </div>
                                <IconButton
                                    style={{ padding: '2px', color: theme.palette.text.secondary }}
                                    onClick={() => handleRemove(i)}
                                >
                                    <Icon>clear</Icon>
                                </IconButton>
                            </div>
                        </Paper>
                    ) : (
                        <Chip
                            key={value}
                            label={formatString(type, value)}
                            onDelete={() => handleRemove(i)}
                            style={{ margin: theme.spacing.unit / 2 }}
                        />
                    )
                )}
            </div>
        </div>
    );
}

export default withTheme()(StringListInput);

const formatString = (type = '', string) => {
    let value = string;
    switch (type) {
        case 'phone':
            string = string.replace(/[^0-9]/g, '').trim();
            const phoneNumberGroupings = process.env.REACT_APP_PHONE_NUMBER_GROUPING.split(' ');
            value = string.replace(
                new RegExp(
                    `(\\d{${phoneNumberGroupings[0]}})(\\d{${phoneNumberGroupings[1]}})(\\d{${
                        phoneNumberGroupings[2]
                    }})`
                ),
                process.env.REACT_APP_PHONE_NUMBER_FORMAT
            );
            break;
        default:
            value = string;
    }

    return value;
};
