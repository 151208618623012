import { useState, useEffect } from 'react';
import _ from 'lodash';
import { dot } from 'dot-object';

const useSystemHistory = ({ query, http, startDate, endDate }) => {
    const [logs, setLogs] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pairedData, setPairedData] = useState([]);

    const handleSearch = async () => {
        setLoading(true);

        let qs = '';
        for (let key in query) {
            if (qs !== '') {
                qs += '&';
            }
            qs += key + '=' + encodeURIComponent(query[key]);
        }

        const res = await http.getJSON(
            `/logs/systemHistory?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}&${qs}`
        );

        if (res.ok) {
            setLogs(res.data);
        }
        setLoading(false);
    };

    const pairLogs = () => {
        setPairedData([]);
        for (let i = 0; i < logs.length; i++) {
            const curr = _.cloneDeep(logs[i]);

            // If the operation is not insert, find the previous copy of the document
            if (curr.o !== 'i') {
                const prev = _.find(logs, { i: curr.i }, i + 1);

                // Copy target from previous log
                if (curr.t === null) {
                    curr.t = _.get(prev, 't', null);
                    curr.target = _.get(prev, 'target', null);
                }

                const dotPaths = dot(_.get(curr, 'u.updatedFields', {}));
                for (let path in dotPaths) {
                    _.set(curr, `prev.${path}`, _.get(prev, `d.${path}`));
                }

                // If the operation is delete, get the last recorded state of the object
                if (curr.o === 'd') {
                    _.set(curr, 'prev', _.get(prev, 'd', {}));
                }
            }

            setPairedData(prevState => [...prevState, curr]);
        }
        setLoading(false);
    };

    useEffect(() => {
        pairLogs();
    }, [logs]);

    useEffect(() => {
        setLoading(true);
        handleSearch();
    }, [query.page]);

    return {
        logs,
        pairedData,
        loading,
        handleSearch
    };
};

export default useSystemHistory;
