import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';

import { Brightness } from '@ionic-native/brightness';

import { deviceHelper, isEXPRegion } from 'utils/misc';

import {
    Dialog,
    DialogActions,
    DialogContent,
    Button,
    withTheme,
    DialogContentText,
    withMobileDialog,
    Typography,
    CircularProgress,
    Icon
} from '@material-ui/core';

import MDIcon from '@mdi/react';
import { mdiQrcodeScan } from '@mdi/js';

import QRCode from 'qrcode.react';
import QrScanner from './QrScanner';
import useScreenBrightness from 'utils/hooks/useScreenBrightness';

import CustomFormTitle from 'components/MaterialUIExtensions/CustomFormTitle';
import DialogTitlePrimary from 'components/MaterialUIExtensions/DialogTitlePrimary';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

import { _user } from 'std';

function QrScanDialog(props) {
    const { http, fullScreen, scanQREnabled, showQREnabled, customer, open, onClose, theme } = props;
    const { lang } = useContext(LocalizationContext);

    useScreenBrightness();

    const [loading, setLoading] = useState(false);
    const [scannerSuccess, setScannerSuccess] = useState(false);
    const [scannerFail, setScannerFail] = useState(false);

    const handleQRScanned = async data => {
        if (loading) {
            return;
        }

        setLoading(true);

        let res = await http.post('/users/qr-scan', { qrData: data });

        if (res.ok) {
            setScannerSuccess(true);
            setScannerFail(false);
        } else {
            setScannerSuccess(false);
            setScannerFail(true);
        }

        setLoading(false);
    };

    if (scannerSuccess) {
        return (
            <Dialog fullScreen={fullScreen} open={true /*open*/} onClose={onClose}>
                <DialogTitlePrimary closeButtonShown onClose={onClose} style={{ marginBottom: theme.spacing.unit * 2 }}>
                    {loc('scanQRSuccessTitle', lang)}
                </DialogTitlePrimary>
                <DialogContent>
                    <div
                        style={{
                            margin: theme.spacing.unit,
                            marginBottom: theme.spacing.unit * 2,
                            textAlign: 'center'
                        }}
                    >
                        <MDIcon path={mdiQrcodeScan} size={5} color={theme.palette.primary.main} />
                    </div>
                    <Typography data-cy="how-it-works-text" color="textSecondary" variant="subtitle1">
                        {loc('scanQRSuccess', lang)}
                    </Typography>
                </DialogContent>
                <DialogActions style={{ marginBottom: 'max(8px, env(safe-area-inset-bottom, 8px))' }}>
                    <Button color="primary" onClick={onClose}>
                        {loc('close', lang)}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    return (
        <Dialog fullScreen={fullScreen} open={true /*open*/} onClose={onClose}>
            <DialogTitlePrimary closeButtonShown onClose={onClose} style={{ marginBottom: theme.spacing.unit * 2 }}>
                {loc('QRTitle', lang)}
            </DialogTitlePrimary>
            <DialogContent>
                {showQREnabled && (
                    <>
                        <CustomFormTitle titleText={loc('showQRCodeSubTitle', lang)} iconName="qr_code" />
                        <div
                            style={{
                                width: '100%',
                                paddingBottom: theme.spacing.unit,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center'
                            }}
                        >
                            <div style={{ backgroundColor: '#fafafa', padding: theme.spacing.unit * 2 }}>
                                <QRCode id="print-qr-code" value={`#${_user.getUniqueID(customer)}`} size={300} />
                            </div>
                        </div>
                    </>
                )}
                {scanQREnabled && (
                    <>
                        <CustomFormTitle titleText={loc('scanQRCodeSubTitle', lang)} iconName="qr_code" />
                        <div
                            style={{
                                width: '100%',
                                paddingBottom: theme.spacing.unit,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <CircularProgress style={{ display: loading ? '' : 'none', margin: theme.spacing.unit }} />
                            <QrScanner
                                handleQRScanned={handleQRScanned}
                                show={!loading}
                                style={{
                                    width: '100%',
                                    height: 'auto',
                                    overflow: 'hidden',
                                    justifyContent: 'center'
                                }}
                            />
                        </div>

                        {scannerFail && (
                            <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                                <Icon style={{ color: 'red', fontSize: '24px' }}>error_outline</Icon>
                                <Typography color="error" style={{ margin: theme.spacing.unit * 2 }}>
                                    {loc('scanQRFail', lang)}
                                </Typography>
                            </div>
                        )}
                    </>
                )}
            </DialogContent>
            <DialogActions style={{ marginBottom: 'max(8px, env(safe-area-inset-bottom, 8px))' }}>
                <Button color="primary" onClick={onClose}>
                    {loc('close', lang)}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default withMobileDialog({ breakpoint: 'xs' })(withTheme()(QrScanDialog));
