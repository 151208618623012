import { useEffect, useState, useMemo } from 'react';

import _ from 'lodash';
import moment from 'moment-timezone';

import { _time, _user } from 'std';

const formInitialValues = {
    trip_id: null,
    bottleDriveFlag: false,
    driverSelected: '',
    collectorSelected: '',
    dateSelected: '',
    locationSelected: { description: '' },
    routingAlgorithmSelected: 'ROUTING_ALGORITHM_KCLUSTER'
};

const defaultStartTime = moment()
    .startOf('day')
    .add(9, 'hours')
    .toDate();

function useTripDialog(
    socket,
    filterDate,
    collectorsAvailable,
    driversAvailable,
    onSnackbar,
    http,
    sendETANotifications,
    selectedCollector,
    operator
) {
    const [trip, setTrip] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [form, setForm] = useState(formInitialValues); // TODO: should be a reducer
    const [inProgress, setInProgress] = useState(false);

    const [warningDistanceFromDepot, setWarningDistanceFromDepot] = useState(300);

    const processorsAvailable = useMemo(() => {
        if (_user.isSystemAdmin(operator) || _user.isInternalRole(operator)) {
            return collectorsAvailable;
        } else {
            let processors = _.cloneDeep(collectorsAvailable);
            driversAvailable.forEach(driver => {
                if (!_.isNil(driver) && !_.isNil(driver.driverDestination)) {
                    processors.push(driver.driverDestination);
                }
            });

            return _.uniqBy(processors, '_id');
        }
    }, [collectorsAvailable, driversAvailable, form.driverSelected, operator]);

    const handleTripDialog = (state, trip = null) => {
        setTrip(trip);
        setForm({
            trip_id: _.isNil(trip) ? null : trip._id,
            bottleDriveFlag: _.isNil(trip) ? false : trip.bottleDrive,
            driverSelected: _.isNil(trip) ? '' : trip.transporter._id,
            collectorSelected: _.isNil(trip)
                ? getInitialCollector(collectorsAvailable, selectedCollector)
                : trip.collector._id,
            dateSelected: _.isNil(trip) ? getInitialStartTime(filterDate) : moment(trip.startTime),
            locationSelected: _.isNil(trip) ? { description: '' } : trip.startLocation, // NB: currentStartLocation doesn't have description set when updated by GPS
            routingAlgorithmSelected: _.isNil(trip)
                ? 'ROUTING_ALGORITHM_KCLUSTER'
                : trip.routingAlgorithm || 'ROUTING_ALGORITHM_KCLUSTER'
        });

        setDialogOpen(state);
    };

    const handleTripFormChange = e => {
        const name = e.target.name;
        const value = e.target.value;

        let updatedForm = _.cloneDeep(form);
        _.set(updatedForm, name, value);
        if (name === 'driverSelected' || name === 'collectorSelected') {
            const collectorId = name === 'driverSelected' ? form.collectorSelected : value;
            const collector = _.find(processorsAvailable, collector => collector._id === collectorId);
            const timezone = _.get(collector, 'timezone', process.env.REACT_APP_REGION_TIMEZONE);
            const driverId = name === 'driverSelected' ? value : form.driverSelected;
            const driver = _.find(driversAvailable, driver => driver._id.toString() === driverId);

            if (!_.isNil(driver)) {
                const hours = moment(driver.startTime)
                    .tz(timezone)
                    .hours();
                const minutes = moment(driver.startTime)
                    .tz(timezone)
                    .minutes();
                const date = moment(
                    _time.convertToNewTimezone(process.env.REACT_APP_REGION_TIMEZONE, timezone, filterDate)
                )
                    .add(hours, 'hours')
                    .add(minutes, 'minutes');
                _.set(updatedForm, 'dateSelected', date);
            }

            const locationDescription = _.get(updatedForm, 'locationSelected.description', '');
            if (!_.isNil(driver) && _.isEmpty(locationDescription)) {
                _.set(updatedForm, 'locationSelected', driver.home);
            }
        }

        setForm(updatedForm);
    };

    const unsetDriver = () => {
        let updatedForm = _.cloneDeep(form);
        const driverSelected = '';
        const locationSelected = { description: '' };
        const dateSelected = getInitialStartTime(filterDate);

        _.set(updatedForm, 'driverSelected', driverSelected);
        _.set(updatedForm, 'locationSelected', locationSelected);
        _.set(updatedForm, 'dateSelected', dateSelected);

        setForm(updatedForm);
    };

    const unsetProcessor = () => {
        let updatedForm = _.cloneDeep(form);
        _.set(updatedForm, 'collectorSelected', '');
        setForm(updatedForm);
    };

    const handleTripFormDateChange = date => {
        let updatedForm = _.cloneDeep(form);
        _.set(updatedForm, 'dateSelected', moment(date));

        setForm(updatedForm);
    };

    const handleToggleTripBottleDriveFlag = () => {
        let updatedForm = _.cloneDeep(form);
        _.set(updatedForm, 'bottleDriveFlag', !updatedForm.bottleDriveFlag);

        setForm(updatedForm);
    };

    const handleTripFormSuggestionSelected = ({ suggestion, place }) => {
        let updatedForm = _.cloneDeep(form);
        const locationSelected = updatedForm.locationSelected;

        if (
            _.get(locationSelected, 'place_id') === place.place_id &&
            !_.isNil(locationSelected.lat) &&
            !_.isNil(locationSelected.lng)
        ) {
            return;
        }

        const location = {
            place_id: place.place_id,
            description: suggestion.description,
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng()
        };

        _.set(updatedForm, 'locationSelected', location);

        setForm(updatedForm);
    };

    const handleUseDriverLocation = () => {
        let updatedForm = _.cloneDeep(form);
        const driver = _.find(driversAvailable, driver => driver._id.toString() === form.driverSelected);

        if (!_.isNil(driver)) {
            _.set(updatedForm, 'locationSelected', driver.home);
            setForm(updatedForm);
        }
    };

    const handleUseCollectorLocation = () => {
        let updatedForm = _.cloneDeep(form);
        const collector = _.find(collectorsAvailable, collector => collector._id === form.collectorSelected);

        if (!_.isNil(collector)) {
            _.set(updatedForm, 'locationSelected', collector.location);
            setForm(updatedForm);
        }
    };

    const handleTripDelete = async trip => {
        setInProgress(true);

        const res = await http.post('/trips/deleteTrip', {
            trip_id: trip._id
        });

        if (res.ok) {
            onSnackbar('Trip deleted. Recalculating trip...');
            setDialogOpen(false);
            setInProgress(false);

            socket.emit(
                'refresh-selected-day',
                { date: filterDate.toISOString(), dateInteger: parseInt(filterDate.format('YYYYMMDD')) },
                success => {
                    if (success) {
                        onSnackbar('Trip recalculation complete.');
                    } else {
                        onSnackbar('Failed to recalculate trip. Please manually trigger trip recalculation', 'error');
                    }
                }
            );
        } else {
            onSnackbar('Failed to delete a trip. Please try again in a moment.', 'error');
            setInProgress(false);
        }
    };

    const handleTripSubmit = async () => {
        setInProgress(true);
        form.sendETANotifications = sendETANotifications;

        const res = await http.post('/trips/createEditTrip', form);

        if (res.ok) {
            onSnackbar(`Trip was successfully ${_.isNil(trip) ? 'created' : 'updated'}. Recalculating trip...`);
            setDialogOpen(false);
            setInProgress(false);
            socket.emit(
                'refresh-selected-day',
                { date: filterDate.toISOString(), dateInteger: parseInt(filterDate.format('YYYYMMDD')) },
                success => {
                    if (success) {
                        onSnackbar('Trip recalculation complete.');
                    } else {
                        onSnackbar('Failed to recalculate trip. Please manually trigger trip recalculation', 'error');
                    }
                }
            );
        } else {
            onSnackbar(
                `Failed to  ${
                    _.isNil(trip) ? 'create' : 'update'
                } a trip due to a server error. Please try again later.`,
                'error'
            );
            setInProgress(false);
        }
    };

    useEffect(() => {
        if (!_.isNil(form.driverSelected) && !_.isNil(processorsAvailable)) {
            const driverId = _.get(form, 'driverSelected');
            const driver = _.find(driversAvailable, { _id: driverId });
            if (!_.isNil(driver) && !_.isNil(driver.driverDestination)) {
                let destinationProcessor = _.find(
                    processorsAvailable,
                    p => p._id === _.get(driver, 'driverDestination._id', driver.driverDestination)
                );
                if (!_.isNil(destinationProcessor)) {
                    let updatedForm = _.cloneDeep(form);
                    _.set(updatedForm, 'collectorSelected', destinationProcessor._id);
                    setForm(updatedForm);
                }
            }
        }
    }, [form.driverSelected]);

    useEffect(() => {
        (async function() {
            const res = await http.getJSON('/system/driverConfig');
            if (res.ok) {
                setWarningDistanceFromDepot(_.get(res, 'data.warningDistanceFromDepot', 300));
            }
        })();
    }, []);

    return {
        trip,
        dialogOpen,
        form,
        inProgress,
        warningDistanceFromDepot,
        processorsAvailable,
        unsetDriver,
        unsetProcessor,
        handleTripDialog,
        handleTripFormChange,
        handleTripFormDateChange,
        handleToggleTripBottleDriveFlag,
        handleTripFormSuggestionSelected,
        handleTripDelete,
        handleTripSubmit,
        handleUseDriverLocation,
        handleUseCollectorLocation
    };
}

export default useTripDialog;

function getInitialCollector(collectorsAvailable, selectedCollector = null) {
    if (collectorsAvailable.length >= 1) {
        if (
            !_.isNil(selectedCollector) &&
            !_.isNil(_.find(collectorsAvailable, c => c._id.toString() === selectedCollector.toString()))
        ) {
            return selectedCollector;
        }
        return _.first(collectorsAvailable)._id;
    } else {
        return '';
    }
}

function getInitialStartTime(filterDate) {
    return moment(filterDate)
        .tz(process.env.REACT_APP_REGION_TIMEZONE)
        .startOf('day')
        .add(defaultStartTime.getHours(), 'hours')
        .add(defaultStartTime.getMinutes(), 'minutes');
}
