import React from 'react';

import _ from 'lodash';

import {
    Switch,
    FormGroup,
    FormControlLabel,
    FormControl,
    withTheme,
    Typography,
    TextField,
    Grid
} from '@material-ui/core';

function VolumeFeeRatesInput(props) {
    const {
        chargeVolumeFee,
        volumeFeeRates,
        containerSizes,
        onChargeVolumeFee,
        onVolumeFeeRatesChange,
        style,
        theme
    } = props;

    const handleRateChange = (e, containerSize) => {
        const volumeFeeRatesCopy = _.cloneDeep(volumeFeeRates);
        const rateI = _.findIndex(volumeFeeRatesCopy, { containerSize });
        if (rateI === -1) {
            volumeFeeRatesCopy.push({ containerSize, rate: e.target.value });
        } else {
            volumeFeeRatesCopy[rateI].rate = e.target.value;
        }
        onVolumeFeeRatesChange(volumeFeeRatesCopy);
    };

    return (
        <div
            style={{
                position: 'relative',
                padding: '0px 14px',
                paddingTop: 0,
                borderStyle: 'solid',
                borderWidth: '1px',
                borderRadius: '4px 4px 4px 4px',
                borderColor: 'rgba(0, 0, 0, 0.23)',
                ...style
                //borderBottom: 'none',
            }}
        >
            <div
                style={{
                    paddingLeft: 4,
                    paddingRight: 4,
                    position: 'absolute',
                    top: -10,
                    backgroundColor: theme.palette.background.paper,
                    fontFamily: theme.typography.subtitle2.fontFamily,
                    fontSize: '12px',
                    color: theme.palette.grey[600]
                }}
            >
                Volume Fee
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body1" style={{ marginRight: theme.spacing.unit }}>
                    Charge Volume Fee:
                </Typography>
                <Switch
                    color="primary"
                    onChange={e => onChargeVolumeFee(e.target.checked)}
                    value={chargeVolumeFee}
                    checked={chargeVolumeFee}
                />
            </div>
            {chargeVolumeFee && (
                <>
                    <Typography variant="body1" style={{ marginRight: theme.spacing.unit }}>
                        Rate (¢/container):
                    </Typography>
                    <Grid container spacing={theme.spacing.unit * 3} style={{ paddingBottom: '18.5px' }}>
                        {containerSizes.map(containerSize => {
                            const rateForContainerSize = _.find(volumeFeeRates, { containerSize });

                            return (
                                <Grid item key={containerSize} xs={12} md={6}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            marginTop: theme.spacing.unit
                                        }}
                                    >
                                        {/*<Typography
                                    style={{
                                        marginRight: theme.spacing.unit * 0.5,
                                        color: !chargeVolumeFee
                                            ? theme.palette.text.disabled
                                            : theme.palette.text.secondary
                                    }}
                                    variant="body2"
                                >
                                    {containerSize}:
                                </Typography>*/}
                                        <TextField
                                            style={{ flex: 1 }}
                                            type="number"
                                            value={_.get(rateForContainerSize, 'rate', 0)}
                                            onChange={e => handleRateChange(e, containerSize)}
                                            disabled={!chargeVolumeFee}
                                            label={containerSize}
                                        />
                                    </div>
                                </Grid>
                            );
                        })}
                    </Grid>
                </>
            )}
        </div>
    );
}

export default withTheme()(VolumeFeeRatesInput);
