import React, { useState, useEffect, useMemo } from 'react';
import _ from 'lodash';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    Typography,
    Icon,
    IconButton,
    withTheme,
    withMobileDialog,
    Divider,
    FormControlLabel,
    Switch
} from '@material-ui/core';

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { AVAILABLE_LANGS } from '../../../constants';

function QuestionDialog(props) {
    const { open, question = null, onClose, onSubmit, fullScreen, theme } = props;

    const [lang, setLang] = useState('en');
    const [newResponseText, setNewResponseText] = useState('');

    const availableLangs = useMemo(() => _.cloneDeep(AVAILABLE_LANGS[process.env.REACT_APP_REGION_EXT]).sort(), []);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            _id: _.get(question, '_id'),
            description: _.get(question, 'description', {}),
            responses: _.get(question, 'responses', []),
            allowCustomResponse: _.get(question, 'allowCustomResponse', false)
        },
        validationSchema: Yup.object({
            description: Yup.object().required('You must enter a value'),
            responses: Yup.array().required('You must enter a value')
        }),
        onSubmit: async (values, { resetForm }) => {
            onSubmit(values);
        }
    });

    const handleChangeQuestionDescription = e => {
        const updatedDescription = _.cloneDeep(_.get(formik, 'values.description', {}));
        _.set(updatedDescription, lang, e.target.value);
        formik.setFieldValue('description', updatedDescription);
    };

    const hanldeUpdateResponse = (e, i) => {
        const updatedResponses = _.cloneDeep(_.get(formik, 'values.responses', []));
        _.set(updatedResponses[i], lang, e.target.value);
        formik.setFieldValue('responses', updatedResponses);
    };

    const handleRemoveResponse = i => {
        const updatedResponses = _.cloneDeep(_.get(formik, 'values.responses', []));
        updatedResponses.splice(i, 1);
        formik.setFieldValue('responses', updatedResponses);
    };

    const handleAddResponse = () => {
        const updatedResponses = _.cloneDeep(_.get(formik, 'values.responses', []));
        const newResponse = {};
        availableLangs.forEach(lang => (newResponse[lang] = newResponseText));
        updatedResponses.push(newResponse);
        formik.setFieldValue('responses', updatedResponses);
        setNewResponseText('');
    };

    const handleKeyPress = e => {
        e = e || window.event;
        if (e.key === 'Enter' && !_.isEmpty(newResponseText)) {
            handleAddResponse();
        }
    };

    useEffect(() => {
        formik.handleReset();
    }, [open]);

    return (
        <Dialog open={open} fullWidth fullScreen={fullScreen} onClose={onClose}>
            <DialogTitle>
                {' '}
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <span>
                        {' '}
                        <ToggleButtonGroup
                            value={lang}
                            exclusive
                            onChange={(e, lang) => !_.isEmpty(lang) && setLang(lang)}
                            style={{
                                marginBottom: theme.spacing.unit,
                                marginRight: theme.spacing.unit,
                                display: 'flex',
                                flexWrap: 'wrap'
                            }}
                        >
                            {availableLangs.map(lang => (
                                <ToggleButton key={lang} value={lang}>
                                    {lang}
                                </ToggleButton>
                            ))}
                        </ToggleButtonGroup>
                    </span>
                </div>
            </DialogTitle>
            <DialogContent>
                <TextField
                    inputProps={{ 'data-cy': 'question-dialog-description' }}
                    fullWidth
                    label="Question"
                    name="description"
                    value={_.get(formik, `values.description.${lang}`)}
                    onChange={handleChangeQuestionDescription}
                    error={
                        _.get(formik.touched, 'description', false) && _.get(formik.errors, 'description', false)
                            ? true
                            : null
                    }
                    variant="outlined"
                    helperText={
                        _.get(formik.touched, 'description', false) && _.get(formik.errors, 'description', false)
                            ? _.get(formik.errors, 'description', false)
                            : ''
                    }
                    style={{ marginTop: theme.spacing.unit * 2 }}
                />
                <FormControlLabel
                    control={
                        <Switch
                            data-cy="question-dialog-allow-custom-response"
                            name="allowCustomResponse"
                            color="primary"
                            onChange={e => formik.setFieldValue('allowCustomResponse', e.target.checked)}
                            value={formik.values.allowCustomResponse}
                            checked={formik.values.allowCustomResponse}
                        />
                    }
                    label="Show Other Option"
                />

                <Typography variant="h6" style={{ marginTop: theme.spacing.unit * 2 }}>
                    Selectable Answers
                </Typography>
                {_.get(formik, 'values.responses', []).map((response, i) => (
                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                        <TextField
                            fullWidth
                            value={_.get(response, lang)}
                            onChange={e => {
                                hanldeUpdateResponse(e, i);
                            }}
                            variant="outlined"
                            style={{
                                marginBottom: theme.spacing.unit
                            }}
                        />
                        <IconButton onClick={() => handleRemoveResponse(i)}>
                            <Icon>delete</Icon>
                        </IconButton>
                    </div>
                ))}
                {_.isEmpty(_.get(formik, 'values.responses', [])) && (
                    <Typography
                        variant="body1"
                        color="textSecondary"
                        style={{ marginBottom: theme.spacing.unit }}
                        align="center"
                    >
                        No Answers Entered
                    </Typography>
                )}
                <Divider style={{ marginTop: theme.spacing.unit }} />
                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <TextField
                        inputProps={{ 'data-cy': 'question-dialog-response-input' }}
                        fullWidth
                        onKeyDown={handleKeyPress}
                        margin="normal"
                        value={newResponseText}
                        onChange={e => {
                            setNewResponseText(e.target.value);
                        }}
                        variant="outlined"
                    />
                    <IconButton onClick={() => handleAddResponse()}>
                        <Icon>add_circle</Icon>
                    </IconButton>
                </div>
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={onClose}>
                    Cancel
                </Button>
                <Button color="primary" onClick={formik.handleSubmit} data-cy="question-dialog-submit-btn">
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default withMobileDialog()(withTheme()(QuestionDialog));
