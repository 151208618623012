import React, { Component, useContext } from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

const HelpDialog = props => {
    const { open, title, body } = props;

    const { lang } = useContext(LocalizationContext);

    return (
        <Dialog open={open} fullWidth maxWidth="sm" onClose={props.onClose}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent data-cy="help-dialog-content">{body}</DialogContent>
            <DialogActions>
                <Button color="primary" onClick={props.onClose} data-cy="help-dialog-close-btn">
                    {loc('close', lang)}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default HelpDialog;
