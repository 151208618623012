import React, { useContext } from 'react';

import _ from 'lodash';

import DialogTitlePrimary from 'components/MaterialUIExtensions/DialogTitlePrimary';
import CopyableLink from 'components/Links/CopyableLink';
import CustomFormTitle from 'components/MaterialUIExtensions/CustomFormTitle';

import { _charity } from 'std';

import {
    Dialog,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    Table,
    TableBody,
    TableRow,
    TableCell,
    withMobileDialog,
    withTheme,
    Typography
} from '@material-ui/core';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

function ReferralLinksDialog(props) {
    const { open, onClose, fullScreen, charity, theme } = props;

    const { lang } = useContext(LocalizationContext);

    return (
        <Dialog fullScreen={fullScreen} fullWidth open={open} onClose={onClose}>
            <DialogTitlePrimary closeButtonShown onClose={onClose}>
                {loc('dashboardMain6', lang)}
            </DialogTitlePrimary>
            <DialogContent style={{ marginTop: theme.spacing.unit * 2 }}>
                <DialogContentText>
                    <CustomFormTitle titleText={loc('dashboardMain7', lang)} iconName="link" />
                    <div style={{ marginBottom: theme.spacing.unit }}>
                        <CopyableLink href={getFullUrl(_charity.getUrl(charity))}>
                            <span>{getFullUrl(_charity.getUrl(charity))}</span>
                        </CopyableLink>
                    </div>
                    <CustomFormTitle titleText={loc('dashboardMain8', lang)} iconName="people" />
                    <Table style={{ 'word-break': 'break-word' }}>
                        <TableBody>
                            {_charity.getSubdivisions(charity).map(subdivision => (
                                <TableRow>
                                    <CopyableLink
                                        subdivision={subdivision}
                                        href={getFullUrl(_charity.getUrl(charity, subdivision))}
                                    />
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </DialogContentText>
            </DialogContent>{' '}
            <DialogActions>
                <Button onClick={onClose}>{loc('close', lang)}</Button>
            </DialogActions>
        </Dialog>
    );
}

export default withMobileDialog({ breakpoint: 'xs' })(withTheme()(ReferralLinksDialog));

function getFullUrl(extension) {
    return `${process.env.REACT_APP_ORIGIN_URL}/${extension}`;
}
