import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import App from './containers/App';
import ErrorBoundary from './ErrorBoundary';
import CssBaseline from '@material-ui/core/CssBaseline';
import MomentUtils from 'material-ui-pickers/utils/moment-utils';
import MuiPickersUtilsProvider from 'material-ui-pickers/MuiPickersUtilsProvider'; // https://reacttraining.com/react-router/web/guides/dealing-with-update-blocking
// import registerServiceWorker from './registerServiceWorker';
import { unregister } from './registerServiceWorker';

import './index.css';

const FirebaseAdmin = require('firebase/firebaseAdmin.js');
const Analytics = require('utils/analytics.js');

const startApp = () => {
    // init firebase services
    ReactDOM.render(
        <Router>
            <div id="indexjs" style={{ height: '100%' }}>
                <CssBaseline />
                <ErrorBoundary>
                    <Route
                        render={props => (
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <App />
                            </MuiPickersUtilsProvider>
                        )}
                    />
                </ErrorBoundary>
            </div>
        </Router>,
        document.getElementById('root')
    );
};

const app = {
    // Application Constructor
    init: function() {
        if (window.hasOwnProperty('cordova')) {
            document.addEventListener('deviceready', app.capacitorReady);
        } else {
            document.addEventListener('DOMContentLoaded', app.webReady);
        }
    },
    capacitorReady: function() {
        console.log('Capacitor version.');
        // @deprecated IN 2.17.0 PLEASE REMOVE ON NEXT RELEASE
        if (window.wkWebView) {
            // solution for iOS app cookies not working on first launch. the url here must match the api url exactly
            window.wkWebView.injectCookie(`${process.env.REACT_APP_COOKIE_URL}/api`);
        }
        Analytics.initializeAnalytics();
        FirebaseAdmin.init(); // still need to initialize firebase even though its done natively
        startApp(); // LETS GO!
    },
    webReady: function() {
        console.log('Web version.');
        FirebaseAdmin.init();
        Analytics.initializeAnalytics();
        startApp();
    }
};
app.init();
// registerServiceWorker();
unregister();
