import React, { useState, useEffect } from 'react';

import { withTheme, Paper, Grid } from '@material-ui/core';

import DeviceInfoWidget from 'components/DeviceInfoWidget';
import ChangePasswordWidget from 'components/ChangePasswordWidget';

import { NativeBiometric } from '@capgo/capacitor-native-biometric';

function Profile(props) {
    const { history, operator, reloadOperator, onLogoutAll, theme } = props;

    const [biometricsAvailable, setBiometricsAvailable] = useState(false);
    const [biometricsOptIn, setBiometricsOptIn] = useState(false);
    const [biometricsHasCredentials, setBiometricsHasCredentials] = useState(false);

    const handleCancel = () => {
        history.push(`/operators`);
    };

    useEffect(() => {
        (async () => {
            try {
                const availableResult = await NativeBiometric.isAvailable();
                setBiometricsAvailable(availableResult.isAvailable);
                setBiometricsHasCredentials(JSON.parse(window.localStorage.getItem('biometricsHasCredentials')));
                setBiometricsOptIn(JSON.parse(window.localStorage.getItem('biometricsHasCredentials')));
            } catch (err) {}
        })();
    }, []);

    return (
        <Grid
            container
            spacing={theme.spacing.unit * 2}
            style={{ width: '100%', padding: theme.spacing.unit, margin: 0 }}
        >
            <Grid item xs={12} md={6} lg={4}>
                <Paper style={{ padding: '16px 24px' }}>
                    <ChangePasswordWidget
                        user={operator}
                        biometricsAvailable={biometricsAvailable}
                        biometricsOptIn={biometricsOptIn}
                        biometricsHasCredentials={biometricsHasCredentials}
                        onCancel={handleCancel}
                        onBiometricsOptInChange={val => setBiometricsOptIn(val)}
                    />
                </Paper>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <Paper style={{ padding: '16px 24px' }}>
                    <DeviceInfoWidget
                        user={operator}
                        isAdminView={operator.adminView}
                        biometricsAvailable={biometricsAvailable}
                        biometricsHasCredentials={biometricsHasCredentials}
                        onReloadUser={reloadOperator}
                        onCancel={handleCancel}
                        onLogoutAll={onLogoutAll}
                        onBiometricsHasCredentialsChange={biometricsHasCredentials =>
                            setBiometricsHasCredentials(biometricsHasCredentials)
                        }
                    />
                </Paper>
            </Grid>
        </Grid>
    );
}

export default withTheme()(Profile);
