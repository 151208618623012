import { useState, useEffect } from 'react';
import _ from 'lodash';

import { getEmailHeading } from '../ScheduleUnservicedDialog';
import { _time } from 'std';

const RESCHEDULE_EMAIL_BODY = `If you need to make changes to your pickup, please use the app/website to confirm or modify your pickup. If you have any issues please reply to ${
    process.env.REACT_APP_SERVICE_EMAIL_ADDRESS
} - sorry for the inconvenience.`;

const formInitialValues = {
    sendOutEmails: true,
    date: _time.getStartOfToday(process.env.REACT_APP_REGION_TIMEZONE).toDate(),
    emailBody: RESCHEDULE_EMAIL_BODY,
    trip: null
};

const useScheduleUnserviced = (theme, pickupIdsChecked, unselectEverything, onSnackbar, http, handleRecalc) => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [form, setForm] = useState(formInitialValues);
    const [inProgress, setInProgress] = useState(false);
    const [tripsAvailable, setTripsAvailable] = useState([]);

    const handleScheduleUnservicedDialog = open => () => {
        setDialogOpen(open);
    };

    const handleScheduleUnservicedChange = e => {
        const { name, value, checked } = e.target;
        const updatedForm = _.cloneDeep(form);

        if (!_.isNil(checked)) {
            _.set(updatedForm, name, checked);
        } else {
            _.set(updatedForm, name, value);
        }

        setForm(updatedForm);
    };

    const handleScheduleUnservicedSubmit = async () => {
        setInProgress(true);
        const timezone = process.env.REACT_APP_REGION_TIMEZONE;

        const res = await http.post('/pickups/scheduleUnservicedPickups', {
            pickup_ids: pickupIdsChecked,
            email: getEmailHeading(theme, timezone, form.date) + ' ' + form.emailBody,
            ...form
        });

        if (res.ok) {
            let updatedForm = _.cloneDeep(form);
            _.set(updatedForm, 'date', _time.getStartOfToday(process.env.REACT_APP_REGION_TIMEZONE).toDate());
            setDialogOpen(false);
            setForm(updatedForm);
            unselectEverything();
            onSnackbar('Pickups scheduled');
            setInProgress(false);
            handleRecalc();
        } else {
            onSnackbar('Pickup is not located within a serviceable zone.', 'error');
            setInProgress(false);
        }
    };

    const getTripsOnDate = async () => {
        const res = await http.getJSON(`/trips/tripsOnDate?date=${form.date.toISOString()}`);
        if (res.ok) {
            const sortedTrips = _.sortBy(res.data, t => t.collector.code);
            setTripsAvailable(sortedTrips);
        }
    };

    useEffect(() => {
        if (form.date) {
            getTripsOnDate();
        }
    }, [form.date]);

    return {
        dialogOpen,
        form,
        inProgress,
        tripsAvailable,
        handleScheduleUnservicedDialog,
        handleScheduleUnservicedChange,
        handleScheduleUnservicedSubmit
    };
};

export default useScheduleUnserviced;
