import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash';
import {
    MenuItem,
    FormControl,
    InputLabel,
    OutlinedInput,
    Select,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    TextField,
    InputAdornment,
    Switch,
    Grid,
    FormGroup,
    FormControlLabel
} from '@material-ui/core';
import Badge from 'components/Badge/Badge';
import { formatAsCurrency } from 'utils/misc';
import { DEFAULT_DRIVER_WAGE, DEFAULT_TRUCK_KPL, DEFAULT_GAS_PRICE_STD, DEFAULT_DRIVER_COMMISSION } from 'constants.js';

import { DRIVER_PAYMENT_MODELS } from 'constants.js';

import CommissionRatesInput from 'components/CustomInputs/CommissionRatesInput';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';
import { _commodity } from 'std';

const CostCalculation = ({ open, commodities, handleClose, info, http, getData, theme }) => {
    //const [updatePaymentModel, setUpdatePaymentModel] = useState(false);
    const { lang } = useContext(LocalizationContext);

    const [data, setData] = useState({
        driverWage: 0,
        driverCommissions: [],
        gasPrice: 0,
        kpl: 0,
        distance: 0,
        duration: 0,
        paymentModel: 'Wage',
        payOutGas: false
    });

    const [maxGasPayout, setMaxGasPayout] = useState(NaN);

    const handleChange = e => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const handleChangeSwitch = e => {
        setData({ ...data, [e.target.name]: e.target.checked });
    };

    /*const handlePaymentModelToggle = e => {
        if (!e.target.checked) {
            // Toggle being turned off
            setData({ ...data, paymentModel: info.paymentModel });
        }

        setUpdatePaymentModel(e.target.checked);
    };*/
    const handleInputDefault = () => {
        const beverageCommodity = _.find(commodities, { skuType: 'beverage' });
        const defaultDriverCommissionRates = [
            {
                commodity: _.get(beverageCommodity, '_id'),
                skuType: 'beverage',
                rate: DEFAULT_DRIVER_COMMISSION,
                commissionType: 'Counts'
            }
        ];
        setData({
            ...data,
            driverWage: DEFAULT_DRIVER_WAGE / 100,
            driverCommissions: defaultDriverCommissionRates,
            gasPrice: DEFAULT_GAS_PRICE_STD / 100,
            kpl: DEFAULT_TRUCK_KPL,
            payOutGas: _.get(info, 'transporter.payOutGas', false)
        });
    };

    const handleUpdate = async () => {
        const { driverWage, driverCommissions, kpl, gasPrice, distance, duration, paymentModel, payOutGas } = data;
        const res = await http.post('/trips/updateCalculationData', {
            id: info._id,
            driverWage: driverWage * 100,
            driverCommissions: driverCommissions,
            gasPrice: gasPrice * 100,
            kmPerLiter: kpl,
            distance: distance * 1000,
            measuredDuration: duration * 3600,
            paymentModel: paymentModel,
            payOutGas: payOutGas
        });

        if (res.ok) {
            getData();
            handleClose();
        }
    };

    useEffect(() => {
        setData({
            driverWage: _.get(info, 'driverWage', 0) / 100,
            driverCommissions: _.get(info, 'driverCommissions', []),
            gasPrice: _.get(info, 'gasPrice', 0) / 100,
            kpl: _.get(info, 'kmPerLiter', 0),
            distance: (_.get(info, 'distance', 0) / 1000).toFixed(2),
            duration: (_.get(info, 'measuredDuration', 0) / 3600).toFixed(2),
            paymentModel: _.get(info, 'paymentModel', 'Wage'),
            payOutGas: _.get(info, 'payOutGas', _.get(info, 'transporter.payOutGas', false))
        });
    }, [info]);

    useEffect(() => {
        (async function() {
            const res = await http.getJSON('/system/driverConfig');
            if (res.ok) {
                setMaxGasPayout(_.get(res, 'data.maxGasPayout', NaN));
            }
        })();
    }, []);

    const beverageCommissionRate = _.get(_.find(data.driverCommissions, { skuType: 'beverage' }), 'rate');

    const typeStyle = { display: 'inline' };

    const sums = {
        driverWage: data.driverWage * 100 * (parseFloat(data.duration) + info.offloadTime / 60),
        driverCommission: beverageCommissionRate * _.get(info, 'containers', 0),
        driverCommissions: _(_.get(data, 'driverCommissions', []))
            .map(commissionRateObj => {
                const countForSkuType = _.get(
                    _.find(_.get(info, 'countsBySkuType', []), { skuType: commissionRateObj.skuType }),
                    'total',
                    0
                );
                const commodityAmount = _.get(
                    _.find(_.get(info, 'bagsGroupedBySku', []), { skuType: commissionRateObj.skuType }),
                    'total',
                    0
                );
                const commissionRate = _.get(commissionRateObj, 'rate', 0);
                const commissionType = _.get(commissionRateObj, 'commissionType', 'Count');
                let commission = 0;
                if (commissionType === 'Counts') {
                    commission = commissionRate * countForSkuType;
                } else if (commissionType === 'Commodity Amount') {
                    commission = commissionRate * commodityAmount;
                }
                return {
                    skuType: commissionRateObj.skuType,
                    commissionType,
                    commission,
                    count: countForSkuType,
                    commodityAmount,
                    rate: commissionRate
                };
            })
            .sortBy('skuType')
            .value(),
        gas: (data.gasPrice * 100 * data.distance) / data.kpl
    };

    let totalPay = 0;
    if (info.paymentModel === 'Commission') {
        totalPay += _.sumBy(sums.driverCommissions, 'commission');
    } else if (info.paymentModel === 'Wage') {
        totalPay += sums.driverWage;
    }
    if (info.payOutGas) {
        totalPay += Math.min(sums.gas, maxGasPayout);
    }

    return (
        <Dialog open={open} fullWidth onClose={handleClose}>
            <DialogTitle>
                Cost of trip{' '}
                <Badge>
                    <code>{info._id}</code>
                </Badge>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={theme.spacing.unit * 2} style={{ marginTop: theme.spacing.unit }}>
                    <Grid item xs={6}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel>Driver Payment Type</InputLabel>
                            <Select
                                name="paymentModel"
                                value={data.paymentModel}
                                onChange={handleChange}
                                input={<OutlinedInput labelWidth={200} />}
                            >
                                {DRIVER_PAYMENT_MODELS.map((driverPaymentModel, idx) => (
                                    <MenuItem key={idx} value={driverPaymentModel}>
                                        {driverPaymentModel}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    {(_.isNil(data.paymentModel) || data.paymentModel === 'Wage') && (
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label="Driver Wage ($/hr)"
                                name="driverWage"
                                value={data.driverWage}
                                onChange={handleChange}
                                variant="outlined"
                                inputProps={{
                                    'data-cy': 'trip-statistics-driver-wage'
                                }}
                            />
                        </Grid>
                    )}

                    {data.paymentModel === 'Commission' && (
                        <Grid item xs={12}>
                            {/*<TextField
                            fullWidth
                            label="Driver Commission ($/container)"
                            name="driverCommission"
                            value={data.driverCommission}
                            onChange={handleChange}
                            variant="outlined"
                            inputProps={{
                                'data-cy': 'trip-statistics-driver-commission'
                            }}
                        />*/}
                            <CommissionRatesInput
                                onChange={commissionRates => {
                                    setData(data => ({ ...data, driverCommissions: commissionRates }));
                                }}
                                value={data.driverCommissions}
                                commodities={commodities}
                            />
                        </Grid>
                    )}
                    <Grid item xs={3}>
                        <TextField
                            fullWidth
                            label="Gas Price ($/L)"
                            name="gasPrice"
                            value={data.gasPrice}
                            onChange={handleChange}
                            variant="outlined"
                            inputProps={{
                                'data-cy': 'trip-statistics-gas-price'
                            }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl>
                            <FormControlLabel
                                control={
                                    <Switch
                                        name="payOutGas"
                                        color="primary"
                                        onChange={handleChangeSwitch}
                                        value={data.payOutGas}
                                        checked={data.payOutGas}
                                        data-cy={`pay-out-gas-toggle`}
                                    />
                                }
                                label={
                                    <Typography
                                        variant="body2"
                                        style={{
                                            fontSize: '80%'
                                        }}
                                    >
                                        Reimburse Gas Cost
                                    </Typography>
                                }
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="Truck Efficiency (km/L)"
                            name="kpl"
                            value={data.kpl}
                            onChange={handleChange}
                            variant="outlined"
                            inputProps={{
                                'data-cy': 'trip-statistics-kpl'
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="Distance (km)"
                            name="distance"
                            value={data.distance}
                            onChange={handleChange}
                            variant="outlined"
                            inputProps={{
                                'data-cy': 'trip-statistics-distance'
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="Duration (hours)"
                            name="duration"
                            value={data.duration}
                            onChange={handleChange}
                            variant="outlined"
                            inputProps={{
                                'data-cy': 'trip-statistics-duration'
                            }}
                        />
                    </Grid>
                </Grid>
                <div style={{ textAlign: 'right' }}>
                    <Button onClick={handleInputDefault} color="primary">
                        Use default values
                    </Button>
                </div>

                {(_.isNil(data.paymentModel) || data.paymentModel === 'Wage') && (
                    <>
                        <Typography color="textSecondary" style={typeStyle}>
                            Driver Wage:{' '}
                        </Typography>
                        <Typography variant="caption" style={typeStyle}>
                            {formatAsCurrency(data.driverWage * 100)}/hr × ({data.duration} hrs +{' '}
                            {_.round(info.offloadTime / 60, 3)} hrs) = {formatAsCurrency(sums.driverWage)}
                        </Typography>

                        <br />
                        <br />
                    </>
                )}

                {data.paymentModel === 'Commission' && (
                    <>
                        {sums.driverCommissions.map(commissionObj => {
                            const commodity = _.find(commodities, { skuType: commissionObj.skuType });
                            return (
                                <div key={commissionObj.skuType}>
                                    <Typography color="textSecondary" style={typeStyle}>
                                        {_.startCase(commissionObj.skuType)} Commission:{' '}
                                    </Typography>
                                    {commissionObj.commissionType === 'Counts' ? (
                                        <Typography variant="caption" style={typeStyle}>
                                            {`$${commissionObj.rate / 100}`}/
                                            {commissionObj.skuType === 'beverage' ? 'container' : 'unit'} ×{' '}
                                            {commissionObj.count.toLocaleString('en-CA', { maximumFractionDigits: 2 })}{' '}
                                            {commissionObj.skuType === 'beverage' ? 'containers' : 'units'} ={' '}
                                            {formatAsCurrency(commissionObj.commission)}
                                        </Typography>
                                    ) : (
                                        <Typography variant="caption" style={typeStyle}>
                                            {`$${commissionObj.rate / 100}`}/{_commodity.getUnit(commodity, 'en')} ×{' '}
                                            {commissionObj.commodityAmount.toLocaleString('en-CA', {
                                                maximumFractionDigits: 2
                                            })}{' '}
                                            {_commodity.getUnit(commodity, 'en')}s ={' '}
                                            {formatAsCurrency(commissionObj.commission)}
                                        </Typography>
                                    )}
                                </div>
                            );
                        })}
                        {/*<Typography color="textSecondary" style={typeStyle}>
                            Driver Commission:{' '}
                        </Typography>
                        <Typography variant="caption" style={typeStyle}>
                            {formatAsCurrency(beverageCommissionRate)}/container × {_.get(info, 'containers', 0)}{' '}
                            containers = {formatAsCurrency(sums.driverCommission)}
                        </Typography>*/}

                        <Typography color="textSecondary" style={typeStyle}>
                            Total Commission:{' '}
                        </Typography>
                        <Typography variant="caption" style={typeStyle}>
                            {formatAsCurrency(
                                _.sumBy(sums.driverCommissions, commissionObj => commissionObj.commission)
                            )}
                        </Typography>

                        <br />
                        <br />
                    </>
                )}

                <Typography color="textSecondary" style={typeStyle}>
                    Gas Cost:{' '}
                </Typography>
                {sums.gas <= maxGasPayout * 100 && (
                    <Typography variant="caption" style={typeStyle}>
                        ${data.gasPrice}/L × {data.distance}km ÷ {data.kpl}km/L = {formatAsCurrency(sums.gas)}
                    </Typography>
                )}
                {sums.gas > maxGasPayout * 100 && (
                    <Typography variant="caption" style={typeStyle}>
                        ${data.gasPrice}/L × {data.distance}km ÷ {data.kpl}km/L ={' '}
                        <span style={{ color: 'red' }}>
                            {formatAsCurrency(maxGasPayout * 100)} (Gas reimbursement capped)
                        </span>
                    </Typography>
                )}

                <br />

                <br />
                <div style={{ marginTop: 24 }}>
                    <Typography color="textSecondary" style={typeStyle}>
                        Total Cost:{' '}
                    </Typography>
                    <Typography variant="caption" style={typeStyle} data-cy={`trip-statistics-total-cost`}>
                        {info.contractor ? (
                            formatAsCurrency(0)
                        ) : (
                            <>
                                {formatAsCurrency(
                                    info.paymentModel === 'Commission'
                                        ? _.sumBy(sums.driverCommissions, 'commission')
                                        : sums.driverWage
                                )}{' '}
                                + {formatAsCurrency(Math.min(sums.gas, maxGasPayout))} ={' '}
                                {formatAsCurrency(
                                    (info.paymentModel === 'Commission'
                                        ? _.sumBy(sums.driverCommissions, 'commission')
                                        : sums.driverWage) + Math.min(sums.gas, maxGasPayout)
                                )}
                            </>
                        )}
                    </Typography>
                    <br />
                </div>

                <div style={{ marginTop: 24 }}>
                    <Typography color="textSecondary" style={typeStyle} display="inline">
                        Complete Pickups:
                    </Typography>
                    <Typography variant="caption" style={{ ...typeStyle, marginRight: 16 }} display="inline">
                        {info.completePickups}
                    </Typography>
                    <Typography color="textSecondary" style={typeStyle} display="inline">
                        Incomplete Pickups:
                    </Typography>
                    <Typography variant="caption" style={{ ...typeStyle, marginRight: 16 }} display="inline">
                        {info.incompletePickups}
                    </Typography>
                </div>
                <div>
                    <Typography color="textSecondary" style={typeStyle} display="inline">
                        Measured Duration:
                    </Typography>
                    <Typography
                        data-cy="trip-statistics-measured-duration"
                        variant="caption"
                        style={{ ...typeStyle, marginRight: 16 }}
                        display="inline"
                    >
                        {(info.measuredDuration / 3600).toFixed(2)} hrs
                    </Typography>
                    <Typography color="textSecondary" style={typeStyle} display="inline">
                        Difference:
                    </Typography>
                    <Typography variant="caption" style={{ ...typeStyle, marginRight: 16 }} display="inline">
                        {Math.abs(info.differenceInTime / 3600).toFixed(2)} hrs
                    </Typography>
                </div>
            </DialogContent>
            <DialogActions>
                <Button color="default" onClick={handleClose}>
                    {loc('close', lang)}
                </Button>
                <Button color="primary" onClick={handleUpdate}>
                    Update
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CostCalculation;
