import { useState, useEffect, useContext } from 'react';

import _ from 'lodash';
import io from 'socket.io-client';

import SnackbarContext from 'components/CustomSnackbar/SnackbarContext';
import ConfirmDialogContext from 'components/Dialogs/Confirm/ConfirmDialogContext';

const pathExtension = process.env.REACT_APP_ENV !== 'LOCAL' ? '/api' : ''; // connect through Nginx if not localhost

function useSystemSocket() {
    const warnAction = useContext(ConfirmDialogContext);
    const onSnackbar = useContext(SnackbarContext);

    const [socket, setSocket] = useState(null);
    const [systemTasks, setSystemTasks] = useState({});
    const [systemTaskLogs, setSystemTaskLogs] = useState([]);

    const [infoDialogOpen, setInfoDialogOpen] = useState(false);
    const [infoDialogTask, setInfoDialogTask] = useState({});

    useEffect(() => {
        console.log('%cuseSystemSocketIO: running useEffect', 'color: blue');

        const originURL =
            process.env.REACT_APP_ENV !== 'LOCAL'
                ? process.env.REACT_APP_ORIGIN_URL
                : process.env.REACT_APP_ORIGIN_URL.replace(/.$/, '1');

        const socket = io(`${originURL}/system`, {
            path: `${pathExtension}/socket.io`
        });

        setSocket(socket);

        socket.on('socket-ready', res => {
            setSystemTasks(res.systemTasks);
            setSystemTaskLogs(res.logs);
        });

        socket.on('system-task-changed', res => {
            setSystemTasks(res.systemTasks);
            setSystemTaskLogs(res.logs);
        });

        socket.on('system-task-notification', res => {
            const { message, type = 'success' } = res;
            onSnackbar(message, type);
        });

        return () => socket.close();
    }, []);

    const handleViewMoreInfo = task => {
        setInfoDialogTask(task);
        setInfoDialogOpen(true);
    };

    const handleCloseMoreInfo = task => {
        setInfoDialogOpen(false);
    };

    const handleTriggerSystemTask = (task, date, timezone) => {
        warnAction(() => {
            socket.emit('manually-trigger-system-task', { task, date: date.toISOString(), timezone }, success => {
                if (!success) {
                    onSnackbar('Failed trigger system task', 'error');
                } else {
                    // onSnackbar(`${task.name} task triggered`, 'info');
                    setInfoDialogOpen(false);
                }
            });
        }, 'You are about to trigger this system task manually. Are you sure you want to do this?');
    };

    const handleToggleSystemTask = task => {
        warnAction(() => {
            socket.emit('toggle-system-task', { taskKey: task.taskFunctionName }, success => {
                if (!success) {
                    onSnackbar('Failed trigger system task', 'error');
                } else {
                    // onSnackbar(`${task.name} task triggered`, 'info');
                    setInfoDialogOpen(false);
                }
            });
        }, 'You are about to toggle this system task, are you sure you want to do this?');
    };

    return {
        infoDialogOpen,
        infoDialogTask,
        systemTasks,
        systemTaskLogs,
        handleToggleSystemTask,
        handleCloseMoreInfo,
        handleViewMoreInfo,
        handleTriggerSystemTask
    };
}

export default useSystemSocket;
