import React, { useState, useEffect, useContext, useMemo } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    Grid,
    GridList,
    TextField,
    InputAdornment,
    DialogActions,
    LinearProgress,
    Button,
    Fade,
    colors,
    withTheme,
    withMobileDialog,
    Typography,
    FormControl,
    Select,
    MenuItem,
    Checkbox,
    ListItemText
} from '@material-ui/core';

import DatePicker from 'components/DateTimePickersTz/DatePicker';

import DialogTitlePrimary from 'components/MaterialUIExtensions/DialogTitlePrimary';
import InlineChip from 'components/Chips/InlineChip';

import { _commodity, _time } from 'std';

import SnackbarContext from 'components/CustomSnackbar/SnackbarContext';
import ConfirmDialogContext from 'components/Dialogs/Confirm/ConfirmDialogContext';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

import ImageViewer from 'components/ImageUploads/ImageViewer';
import ImageCapture from 'components/ImageUploads/ImageCapture';

function CommodityPickupRequestDialog(props) {
    const {
        open,
        commodity,
        openBulkCounts,
        commodityPickup = null,
        collector,
        comments,
        loading,
        onClose,
        onComments,
        onSubmit,
        onCancelRequest,
        theme,
        fullScreen,
        isAdmin,
        allCollectorsBySkuType,
        collectorIdsSelected = [],
        endDate,
        setCollectorIdsSelected,
        setEndDate,
        imageURLs,
        imagePreviews,
        latestCommodityPickups = [],
        handleAddImage,
        handleDeleteImage,
        uploadingImage,
        resetImages = () => {}
    } = props;

    const timezone = process.env.REACT_APP_REGION_TIMEZONE;

    const onSnackbar = useContext(SnackbarContext);
    const warnAction = useContext(ConfirmDialogContext);
    const { lang } = useContext(LocalizationContext);

    const [isRequestingPickup, setIsRequestingPickup] = useState(false);

    const [imageCaptureDisplayI, setImageCaptureDisplayI] = useState(null);

    const lastCommodityPickupForCommodity = useMemo(() => {
        const lastCommodityPickup = _.get(
            _.find(latestCommodityPickups, { _id: _.get(commodity, '_id') }),
            'commodityPickup'
        );
        return lastCommodityPickup;
    }, [latestCommodityPickups, commodity]);

    const skuType = _commodity.getSkuType(commodity);
    const commodityPickupConfig = _.get(collector, 'commodityPickupConfig', []);
    const configForSelectedCommodity = _.find(commodityPickupConfig, { commodity: _.get(commodity, '_id') });

    //const totalFieldValuesZero = _.sum(fieldValues) === 0;

    const bulkBreakdownForCommodity = _.get(openBulkCounts, _commodity.getSkuType(commodity));
    const openOrders = _.get(bulkBreakdownForCommodity, 'totalBulks', 0);
    const totalCommodityAmount = _.get(bulkBreakdownForCommodity, 'totalCommodityAmount', 0);
    //const openOrdersRequired = _commodity.isSplitCompleted(commodity) || _commodity.isAcceptUnitsCompleted(commodity);
    const isSplitCompletedCommodity = _commodity.isSplitCompleted(commodity);
    const configuredCollectors = _.get(allCollectorsBySkuType, skuType, []);

    const defaultCollectorNotConfigured =
        !_.isNil(collector) && _.isNil(_.find(configuredCollectors, col => col._id === collector._id));

    /*const handleChangeFieldValue = (e, i) => {
        let newVal = e.target.value;
        if (!_.isEmpty(newVal)) {
            newVal = parseInt(e.target.value);
        } else {
            newVal = 0;
        }
        const fieldValuesCopy = _.cloneDeep(fieldValues);
        fieldValuesCopy[i] = newVal;
        setFieldValues(fieldValuesCopy);
    };

    const handlePayloadNext = () => {
        const totalPayload = _.sum(fieldValues);
        if (editing) {
            onSubmit(fieldValues, trailerLabel);
        } else if (_commodity.isAcceptUnitsCompleted(commodity) && totalPayload < totalCommodityAmount) {
            warnAction(
                () => setStep(step + 1),
                <DialogContentText>
                    <Typography variant="h6">Are You Sure?</Typography>
                    <div style={{ color: colors.red[500], marginTop: theme.spacing.unit * 2 }}>
                        {totalCommodityAmount} {_commodity.getUnit(commodity)}
                        {totalCommodityAmount !== 0 && 's'} have been recovered from customers, please double check the
                        unit total and make sure to include any small or miscellaneous items.
                    </div>
                </DialogContentText>,
                null,
                null,
                { color: colors.red[500] }
            );
        } else {
            setStep(step + 1);
        }
    };

    useEffect(() => {
        setStep(0);
        resetImages();
        setFieldValues(getFieldValuesInitialValue(commodity, commodityPickup));
        setTrailerLabel(_.get(commodityPickup, 'trailerLabel', ''));
        if (_.get(commodityPickup, 'collectionPeriod.endDate') && !editing && setEndDate) {
            setEndDate(moment(_.get(commodityPickup, 'collectionPeriod.endDate')).tz(timezone));
        }
    }, [commodity, open, commodityPickup]);*/

    useEffect(() => {
        if (open) {
            setIsRequestingPickup(
                _.isNil(lastCommodityPickupForCommodity) ||
                    _.get(lastCommodityPickupForCommodity, 'status') !== 'Requested'
            );
        }
    }, [open]);

    useEffect(() => {
        if (_.isEmpty(imagePreviews)) {
            setImageCaptureDisplayI(null);
        } else {
            setImageCaptureDisplayI(imagePreviews.length - 1);
        }
    }, [imagePreviews]);

    return (
        <Dialog open={open} fullWidth onClose={onClose} fullScreen={fullScreen}>
            <DialogTitlePrimary closeButtonShown={true} onClose={onClose}>
                {isRequestingPickup
                    ? `Request ${_commodity.getSkuType(commodity)} pickup`
                    : `${_commodity.getSkuType(commodity)} Pickup Collected`}
            </DialogTitlePrimary>
            <DialogContent style={{ marginTop: theme.spacing.unit * 2 }}>
                {commodityNotConfigured(configForSelectedCommodity) ? (
                    <DialogContentText>
                        <span>
                            This commodity pickup is not enabled for you. Contact {process.env.REACT_APP_BRAND_NAME} to
                            start collecting this!
                        </span>
                    </DialogContentText>
                ) : (
                    <>
                        <DialogContentText>
                            {isRequestingPickup ? (
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Typography
                                        style={{
                                            marginBottom: theme.spacing.unit,
                                            width: 420
                                        }}
                                    >
                                        To request a collection, please take a picture of the payload and fill out any
                                        additional information below. When the collector arrives, please fill out this
                                        section again.
                                    </Typography>
                                </div>
                            ) : (
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Typography
                                        style={{
                                            marginBottom: theme.spacing.unit,
                                            width: 420
                                        }}
                                    >
                                        When the collection company takes away your {_commodity.getSkuType(commodity)},
                                        please complete the pickup below.
                                    </Typography>
                                </div>
                            )}
                            <ImageCapture
                                imageURLs={imagePreviews}
                                handleAddImage={handleAddImage}
                                handleDeleteImage={handleDeleteImage}
                                uploadingImage={uploadingImage}
                                placeholderText={
                                    <span>Please upload or take a photo of what needs to be picked up.</span>
                                }
                                onSnackbar={onSnackbar}
                                displayImageIdx={imageCaptureDisplayI}
                                onDisplayImageIdxChange={i => setImageCaptureDisplayI(i)}
                            />
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <TextField
                                    fullWidth
                                    value={comments}
                                    onChange={e => onComments(e.target.value)}
                                    label="Additional Comments"
                                    variant="outlined"
                                    multiline
                                    style={{ width: 420, marginTop: theme.spacing.unit }}
                                />
                            </div>
                        </DialogContentText>
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Button color="default" onClick={onClose}>
                    Close
                </Button>
                {!isRequestingPickup && (
                    <Button
                        color="secondary"
                        onClick={() => {
                            warnAction(() => {
                                onCancelRequest();
                            }, `Are you sure you want to cancel this commodity pickup?`);
                        }}
                    >
                        Cancel Request
                    </Button>
                )}
                <Button
                    color="primary"
                    onClick={() => onSubmit(comments, imageURLs)}
                    disabled={
                        loading || (_.isNil(imagePreviews) && !JSON.parse(process.env.REACT_APP_AUTOMATED_TESTER_MODE))
                    }
                    data-cy="commodity-pickup-dialog-picture-next-btn"
                >
                    {isRequestingPickup ? 'Request' : 'complete request'}
                </Button>
            </DialogActions>

            <Fade in={loading}>
                <LinearProgress />
            </Fade>
        </Dialog>
    );
}

export default withMobileDialog({ breakpoint: 'xs' })(withTheme()(CommodityPickupRequestDialog));

function getFieldValuesInitialValue(commoditySelected, commodityPickup) {
    let initialValues = _.get(commoditySelected, 'collectorPickupFields', []).map(pickupField => {
        const payloadForField = _.find(_.get(commodityPickup, 'payload', []), {
            description: pickupField.description,
            unit: pickupField.unit
        });
        return _.get(payloadForField, 'quantity', 0);
    });
    const payloadForOther = _.find(_.get(commodityPickup, 'payload', []), {
        description: 'Other'
    });
    initialValues.push(_.get(payloadForOther, 'quantity', 0)); //add extra zero for 'Other' field
    return initialValues;
}

function commodityNotConfigured(configForCommodity) {
    return _.isNil(configForCommodity) || _.isEmpty(_.get(configForCommodity, 'emails'));
}
