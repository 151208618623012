import React, { useState } from 'react';
import _ from 'lodash';

import { withTheme } from '@material-ui/core';

import SavedEmails from './SavedEmails';
import SavedSMS from './SavedSMS';
import SavedPush from './SavedPush';

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

function SavedNotifications({ theme }) {
    const [view, setView] = useState('emails');

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%', maxHeight: '100%' }}>
            <div
                style={{ padding: theme.spacing.unit * 2, display: 'flex', justifyContent: 'center', flex: '0 0 auto' }}
            >
                <ToggleButtonGroup value={view} exclusive onChange={(e, name) => setView(_.isNil(name) ? view : name)}>
                    <ToggleButton value="emails" data-cy="saved-notifications-emails-tab">
                        Emails
                    </ToggleButton>
                    <ToggleButton value="sms" data-cy="saved-notifications-sms-tab">
                        SMS
                    </ToggleButton>
                    <ToggleButton value="push" data-cy="saved-notifications-push-tab">
                        App
                    </ToggleButton>
                </ToggleButtonGroup>
            </div>
            {view === 'emails' && <SavedEmails />}
            {view === 'sms' && <SavedSMS />}
            {view === 'push' && <SavedPush />}
        </div>
    );
}

export default withTheme()(SavedNotifications);
