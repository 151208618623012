import React, { useState, useEffect } from 'react';
import _ from 'lodash';

import { DialogActions, withTheme, withMobileDialog } from '@material-ui/core';
import { Dialog, DialogTitle, DialogContent, Button, TextField } from '@material-ui/core';

function AddNewQuestionnaireDialog(props) {
    const { open, onClose, onSubmit, fullScreen, theme } = props;

    const [questionnaireName, setQuestionnaireName] = useState('');

    useEffect(() => {
        setQuestionnaireName('');
    }, [open]);

    return (
        <Dialog open={open} fullWidth fullScreen={fullScreen} onClose={onClose}>
            <DialogTitle>Create</DialogTitle>
            <DialogContent>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: theme.spacing.unit }}>
                    <TextField
                        inputProps={{ 'data-cy': 'questionnaire-name-input' }}
                        fullWidth
                        label="Questionnaire Name"
                        value={questionnaireName}
                        onChange={e => setQuestionnaireName(e.target.value)}
                        variant="outlined"
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">
                    Cancel
                </Button>
                <Button
                    onClick={() => onSubmit(questionnaireName)}
                    color="primary"
                    disabled={_.isEmpty(questionnaireName)}
                    data-cy="create-questionnaire-submit-btn"
                >
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default withMobileDialog()(withTheme()(AddNewQuestionnaireDialog));
