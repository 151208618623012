import { useState } from 'react';
import _ from 'lodash';
import { removeSpacesFromString } from 'utils/misc';

const DEFAULT_CONFIRM_MSG = 'Are you sure you want to do this?';

/**
 * Description.
 * Hook for pop-up dialog that can be used anywhere in the system to confirm an action
 *
 * @param {Object} props
 */
function useConfirmationDialog() {
    const [callbackFunc, setCallbackFunc] = useState(() => () => {
        // empty callback function
    });
    const [message, setMessage] = useState('');
    const [warning, setWarning] = useState('');
    const [open, setOpen] = useState(false);

    const [confirmButtonText, setConfirmButtonText] = useState('');
    const [cancelButtonText, setCancelButtonText] = useState('');

    const [confirmStringComparisonValue, setConfirmStringComparisonValue] = useState(null);
    const [confirmString, setConfirmString] = useState('');

    const handleConfirmAction = (
        callbackFunction,
        text = DEFAULT_CONFIRM_MSG,
        stringComparisonValue = null,
        warningText = null,
        confirmButtonText,
        cancelButtonText
    ) => {
        setCallbackFunc(() => () => callbackFunction());
        setMessage(text);
        setWarning(warningText);
        setConfirmStringComparisonValue(removeSpacesFromString(_.upperCase(stringComparisonValue)));
        setConfirmButtonText(confirmButtonText);
        setCancelButtonText(cancelButtonText);
        setOpen(true);
    };

    const handleCloseConfirmDialog = () => {
        setOpen(false);
        setConfirmStringComparisonValue(null);
        setConfirmString('');
    };

    const handleChangeString = e => {
        const val = removeSpacesFromString(_.upperCase(e.target.value));
        setConfirmString(val);
    };

    return {
        callbackFunc,
        message,
        warning,
        open,
        enableConfirm: _.isNil(confirmStringComparisonValue) || confirmString === confirmStringComparisonValue,
        confirmString,
        confirmStringComparisonValue,
        handleCloseConfirmDialog,
        handleConfirmAction,
        handleChangeString,
        confirmButtonText,
        cancelButtonText
    };
}

export default useConfirmationDialog;
