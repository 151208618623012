import React, { useState, useEffect, useContext } from 'react';

import _ from 'lodash';

import DialogTitlePrimary from 'components/MaterialUIExtensions/DialogTitlePrimary';

import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Avatar from '@material-ui/core/Avatar';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { withTheme } from '@material-ui/core/styles';

import * as terms from 'localizations/terms';

import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

import { _charity } from 'std';
import { DialogContentText, Typography, Tooltip, Switch } from '@material-ui/core';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';
import useBulkStats from 'containers/Operators/BulkStats/hooks/useBulkStats';


function CharityMultiSelectDialog(props) {
    const {
        theme,
        fullScreen,
        open,
        charities,
        charitiesPreferred,
        onClose,
        onChange,
        onInfoDialog,
        hideSubdivisions,
        regionToFilterFeatured = null, //NOTE: only filters featured charities when no search value is entered
        initialSearchValue = '',
        clearCharitiesPreferred,
        currentSelections,
        handleCurrentSelections,
        handleClearSelectedCharities
    } = props;

    const { lang } = useContext(LocalizationContext);

    const [searchValue, setSearchValue] = useState(initialSearchValue);
    const [featuredInfoDialogOpen, setFeaturedInfoDialogOpen] = useState(false);
    const [charitiesFiltered, setCharitiesFiltered]  = useState([]);
    const [charitiesAndSubdivisionsRendered, setCharitiesAndSubdivisionsRendered] = useState([]);
    const [approvedCharities, setApprovedCharities] = useState(_.filter(charities, charity => charity.approved))
    const [includePending, setIncludePending]  = useState(false);
    useEffect(() => {
        let tmpFilter = [];
        let approvedChar = []
        
        for(let id of currentSelections) {
            const matchedCharity = charities.find(charity => charity._id === id);
            if (matchedCharity) {
                tmpFilter.push(matchedCharity);
            }
        };
        
        if (_.isEmpty(searchValue)) {
            approvedChar = _.filter(approvedCharities, charity => _charity.isFeatured(charity) 
            &&(tmpFilter.length === 0 || !tmpFilter.some(tmpCharity => _.get(tmpCharity, '_id', '')=== charity._id)));
            if (!_.isNil(regionToFilterFeatured)) {
                approvedChar = _.filter(charitiesFiltered, charity => {
                const regions = _.get(charity, 'regionsFeaturedIn', []);
        
                if (_.isEmpty(regions)) {
                    return true;
                } else {
                    return _.find(regions, city => city._id === _.get(regionToFilterFeatured, '_id', '')) !== undefined;
                }
                    });
            }
            tmpFilter = tmpFilter.concat(approvedChar)
        } else {
            tmpFilter = _.filter(
            approvedCharities,
            charity =>
              _charity
                .getName(charity)
                .toLowerCase()
                .includes(searchValue.trim().toLowerCase()) ||
              _charity
                .getUrl(charity)
                .toLowerCase()
                .includes(searchValue.trim().toLowerCase()) ||
              _.some(_charity.getSubdivisions(charity), subdivision =>
                subdivision.toLowerCase().includes(searchValue.trim().toLowerCase())
              )
          );
    
          tmpFilter = tmpFilter.slice(0, 30);
          
        }
    
        setCharitiesFiltered(tmpFilter);
        setCharitiesAndSubdivisionsRendered(tmpFilter)
    }, [searchValue, approvedCharities, regionToFilterFeatured, currentSelections]);
    
    useEffect(() => {
        if(includePending){
            setApprovedCharities(_.filter(charities, charity => charity.approved || charity.approvalPending || charity.requiresReview))
        }
        else{
            setApprovedCharities(_.filter(charities, charity => charity.approved));
        }
    }, [includePending]);

    const handleSearch = e => {
        setSearchValue(e.target.value);
    };

    const handleClearSearch = () => {
        setSearchValue('');
    };

    if (_.isNil(charities)) {
        return null;
    }
    const handleSelectAll = (currentSearchedItems) => {
        const charityIds = currentSearchedItems.map(charity => charity._id);
        handleCurrentSelections(charityIds)();
    };

    const handleIncludePendingCharities=()=>{ 
        setIncludePending(prevState => !prevState)
    }

    const MenuItemStyle = {
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2
    };

    
    
    
    //charitiesFiltered = charitiesFiltered.concat(currentSelections);

    
    // if (_.isEmpty(searchValue)) {
    //     charitiesFiltered = _.filter(approvedCharities, charity => _charity.isFeatured(charity));
    //     if (!_.isNil(regionToFilterFeatured)) {
    //         charitiesFiltered = _.filter(charitiesFiltered, charity => {
    //             //if no city locations set show featured charity anyway
    //             const regions = _.get(charity, 'regionsFeaturedIn', []);
    //             if (_.isEmpty(regions)) {
    //                 return true;
    //             } else {
    //                 return _.find(regions, city => city._id === _.get(regionToFilterFeatured, '_id', '')) !== undefined;
    //             }
    //         });
    //     }
    // } else {
    //     charitiesFiltered = _.filter(
    //         approvedCharities,
    //         charity =>
    //             _charity
    //                 .getName(charity)
    //                 .toLowerCase()
    //                 .includes(searchValue.trim().toLowerCase()) ||
    //             _charity
    //                 .getUrl(charity)
    //                 .toLowerCase()
    //                 .includes(searchValue.trim().toLowerCase()) ||
    //             _.some(_charity.getSubdivisions(charity), subdivision =>
    //                 subdivision.toLowerCase().includes(searchValue.trim().toLowerCase())
    //             )
    //     );

    //     charitiesFiltered = charitiesFiltered.slice(0, 30);
    // }
    

    
    

    const renderCharityRow = ({ index, style }) => {
        const charity = charitiesAndSubdivisionsRendered[index];
        return (
            <div style={style}>
                <MenuItem
                    key={charity._id}
                    data-cy={`charity-select-dialog-${index}`}
                    value={charity._id}
                    style={MenuItemStyle}
                    onClick={() => handleCurrentSelections([charity._id])()}
                    selected={currentSelections.some(item => item === charity._id) }
                >
                    <Avatar alt="charity logo" src={charity.logo} />
                    <ListItemText
                        primary={
                            <>
                                <span
                                    style={{
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                        display: 'block',
                                        paddingRight: charity.taxReceiptsIssued ? '32px' : 'inherit'
                                    }}
                                >
                                    {charity.name}
                                </span>
                                {/*charity.taxReceiptsIssued && (
                                    <span
                                        style={{
                                            position: 'absolute',
                                            fontSize: '125%',
                                            color: theme.palette.primary.main,
                                            right: '32px',
                                            top: '-4px'
                                        }}
                                    >
                                        ★
                                    </span>
                                    )*/}
                            </>
                        }
                        secondary={!_.isNil(charity.subdivisionName) && charity.subdivisionName}
                        style={{
                            whiteSpace: 'normal',
                            paddingRight: theme.spacing.unit * 3,
                            position: 'relative'
                        }}
                    />
                    <ListItemSecondaryAction>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {charity.taxReceiptsIssued && (
                                <span
                                    style={{
                                        fontSize: '125%',
                                        color: theme.palette.primary.main
                                    }}
                                >
                                    ★
                                </span>
                            )}
                            <IconButton onClick={onInfoDialog(true, charity._id)}>
                                <Icon>info</Icon>
                            </IconButton>
                        </div>
                    </ListItemSecondaryAction>
                </MenuItem>
            </div>
        );
    };

    return (
        <>
            <Dialog
                open={open}
                fullScreen={fullScreen}
                fullWidth
                PaperProps={{
                    style: {
                        height: fullScreen ? '100vh' : '80vh'
                    }
                }}
            >
                <DialogTitlePrimary closeButtonShown onClose={()=>{onClose(); handleClearSelectedCharities();}}>
                    {loc('pickupDialog50', lang, { organizationName: terms.ORGANIZATION_NAME.toLowerCase() })}
                </DialogTitlePrimary>
                <DialogContent style={{ marginTop: theme.spacing.unit * 2, paddingBottom: 0, height: '100%' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                        <FormControl fullWidth style={{ marginTop: theme.spacing.unit * 2 }}>
                            <TextField
                                label={loc('search', lang)}
                                autoFocus
                                data-cy="charity-select-dialog-input"
                                variant="outlined"
                                placeholder={loc('pickupDialog51', lang)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Icon>search</Icon>
                                        </InputAdornment>
                                    ),
                                    endAdornment: searchValue.length > 0 && (
                                        <InputAdornment position="end">
                                            <IconButton onClick={handleClearSearch}>
                                                <Icon>clear</Icon>
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                value={searchValue}
                                style={{ marginLeft: theme.spacing.unit * 2, marginRight: theme.spacing.unit * 2 }}
                                onChange={handleSearch}
                            />
                        </FormControl>
                        <Typography style={{ margin: theme.spacing.unit }}>
                            <span style={{ marginRight: 4, fontSize: '125%', color: theme.palette.primary.main }}>
                                ★
                            </span>
                            {loc('pickupDialog52', lang)}
                        </Typography>
                        {_.isEmpty(searchValue) && (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography variant="h6" style={{ margin: theme.spacing.unit * 2 }}>
                                    {loc('featuredOrganizations', lang, { organizationName: terms.ORGANIZATION_NAME })}
                                </Typography>
                                <IconButton onClick={() => setFeaturedInfoDialogOpen(true)}>
                                    <Icon>info</Icon>
                                </IconButton>
                            </div>
                        )}
                        
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Switch
                                size="small"
                                onChange={handleIncludePendingCharities}
                                checked={includePending}
                            />
                            <Typography variant='body2' style={{ fontSize: '0.8rem', marginLeft: '0.5rem' }}>Include Pending Charities</Typography>
                        </div>
                        
                        <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                            <MenuItem
                                key="none"
                                data-cy="charity-select-dialog-select-all"
                                onClick={() => handleSelectAll(charitiesAndSubdivisionsRendered)}
                            >
                                All
                            </MenuItem>
                            <MenuItem
                                key="select-all"
                                data-cy="charity-select-dialog-select-all"
                                onClick={() => handleClearSelectedCharities()}
                            >
                                None
                            </MenuItem>
                        </div>
                        <List style={{ flexGrow: 1 }}>
                            <AutoSizer>
                                {({ height, width }) => (
                                    <FixedSizeList
                                        height={height - 1}
                                        itemCount={charitiesAndSubdivisionsRendered.length}
                                        itemSize={56}
                                        width={width}
                                    >
                                        {renderCharityRow}
                                    </FixedSizeList>
                                )}
                            </AutoSizer>
                        </List>
                    </div>
                </DialogContent>
                <DialogActions style={{ marginBottom: 'max(8px, env(safe-area-inset-bottom, 8px))' }}>
                    <Button color='secondary' onClick={()=>{
                        onClose(); 
                        handleClearSelectedCharities();
                    }}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={()=>{
                        onChange(currentSelections)();
                        onClose();
                    }}>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={featuredInfoDialogOpen} fullWidth onClose={() => setFeaturedInfoDialogOpen(false)}>
                <DialogContent>
                    <DialogContentText>
                        {Math.floor(charities.length / 10) * 10 > 0 &&
                            loc('pickupDialog55', lang, {
                                organizationName: terms.ORGANIZATION_NAME.toLowerCase(),
                                num: Math.floor(charities.length / 10) * 10,
                                brand: process.env.REACT_APP_BRAND_NAME
                            })}
                        {charities.length !== 1 &&
                            Math.floor(charities.length / 10) * 10 === 0 &&
                            loc('pickupDialog56', lang, {
                                organizationName: terms.ORGANIZATION_NAME.toLowerCase(),
                                num: charities.length,
                                brand: process.env.REACT_APP_BRAND_NAME
                            })}
                        {charities.length === 1 &&
                            loc('pickupDialog57', lang, {
                                organizationName: terms.ORGANIZATION_NAME.toLowerCase(),
                                brand: process.env.REACT_APP_BRAND_NAME
                            })}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setFeaturedInfoDialogOpen(false)}>{loc('close', lang)}</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default withMobileDialog()(withTheme()(CharityMultiSelectDialog));

function populateSubdivisionsItems(charities) {
    let charitiesAndSubdivisions = [];

    charities.forEach(charity => {
        charitiesAndSubdivisions.push(charity);

        _charity.getSubdivisions(charity).forEach(subdivision => {
            let charitySubdivision = _.cloneDeep(charity);
            charitySubdivision.subdivisionName = subdivision;
            charitiesAndSubdivisions.push(charitySubdivision);
        });
    });

    return charitiesAndSubdivisions;
}
