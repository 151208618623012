import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';

import InlineChip from 'components/Chips/InlineChip';

import useTable from 'components/Table/useTable';
import Table from 'components/Table/Table';

import useDateRangePicker from 'components/DateTimePickersTz/hooks/useDateRangePicker';
import DateRangePicker from 'components/DateTimePickersTz/DateRangePicker';

import CustomDebugDialog from 'components/CustomDebugDialog/CustomDebugDialog';
import MultiSelect from 'components/InputComponents/MultiSelect';

import useQueryString from 'utils/hooks/useQueryString';

import { mdiCodeJson } from '@mdi/js';
import MDIcon from '@mdi/react';

import {
    Grid,
    withTheme,
    TableRow,
    TableCell,
    Paper,
    FormControl,
    Select,
    MenuItem,
    colors,
    Avatar,
    Typography,
    Icon,
    Tooltip,
    IconButton,
    Button,
    TextField
} from '@material-ui/core';

import { _time } from 'std';

import { formatAsPhoneNumber } from 'utils/misc';

const notifTypes = ['EMAIL', 'PUSH', 'SMS'];

const headers = [
    { name: 'Status', locKey: null },
    { name: 'Recipient', locKey: null },
    { name: 'Notification Type', locKey: null },
    { name: 'Message Type', locKey: null },
    { name: 'Date', locKey: null },
    { name: 'Errors', locKey: null }
];

function getNotificationTypeColor(type) {
    switch (type) {
        case 'EMAIL':
            return colors.blue[100];
        case 'PUSH':
            return colors.purple[100];
        case 'SMS':
            return colors.indigo[100];
        default:
            return colors.blue[100];
    }
}

function MsgLogs(props) {
    const { http, theme } = props;

    const [jsonDialogOpen, setJsonDialogOpen] = useState(false);
    const [jsonToView, setJsonToView] = useState({});

    const [notifTypesFilter, setNotifTypesFilter] = useQueryString('notifTypes', notifTypes, true);
    const [msgTypeFilter, setMsgTypeFilter] = useQueryString('msgType', 'ALL', true);
    const [allMsgTypes, setAllMsgTypes] = useState([]);

    const {
        loading,
        getData,
        page,
        rowsPerPage,
        filteredData,
        search,
        filteredHeaders,
        totalDataEntries,
        handleChange,
        handleSearch,
        handleChangeFilterHeaders,
        handleAllFilterHeaders,
        handleChangePage,
        handleChangeRowsPerPage
    } = useTable({
        url: `/msgLogs`,
        key: 'msgLogs',
        headers: headers.map(({ name }) => name)
    });

    const {
        startDate,
        endDate,
        timezone,
        dateWindow,
        handleChangeStartDate,
        handleChangeEndDate,
        handleGoForwards,
        handleGoBackwards,
        handleWindowSelect
    } = useDateRangePicker({
        saveStateInURL: true,
        timezones: [process.env.REACT_APP_REGION_TIMEZONE],
        initStartVal: _time.getStartOfWeek(process.env.REACT_APP_REGION_TIMEZONE),
        initEndVal: _time.getEndOfDay(process.env.REACT_APP_REGION_TIMEZONE)
    });

    const [loadingTotals, setLoadingTotals] = useState(false);
    const [emailsSent, setEmailsSent] = useState(0);
    const [SMSSent, setSMSSent] = useState(0);
    const [SMSReceived, setSMSReceived] = useState(0);
    const [pushSent, setPushSent] = useState(0);

    const getTotals = async () => {
        setLoadingTotals(true);
        const res = await http.getJSON(
            `/msgLogs/totals?startDate=${startDate}&endDate=${endDate}&notifTypes=${notifTypesFilter}&msgType=${msgTypeFilter}`
        );
        if (res.ok) {
            setEmailsSent(_.get(res, 'data.totals.email', emailsSent));
            setSMSSent(_.get(res, 'data.totals.outSMS', SMSSent));
            setSMSReceived(_.get(res, 'data.totals.inSMS', SMSReceived));
            setPushSent(_.get(res, 'data.totals.push', pushSent));
        }
        setLoadingTotals(false);
    };

    const getMsgTypes = async () => {
        const res = await http.getJSON('/msgLogs/allMsgTypes');
        if (res.ok) {
            const msgTypeObjs = res.data.msgTypes;
            const allMsgTypes = msgTypeObjs.map(m => m._id);
            allMsgTypes.sort();
            setAllMsgTypes(allMsgTypes);
        }
    };

    const handleLoadData = () => {
        getTotals();
        getData();
    };

    useEffect(() => {
        getMsgTypes();
        getTotals();
    }, []);

    /*useEffect(() => {
        getTotals();
        getData();
    }, [startDate, endDate, timezone, dateWindow]);*/

    const renderRow = msgLog => {
        if (!msgLog) return;
        return (
            <TableRow key={msgLog._id}>
                {filteredHeaders.includes('Status') && (
                    <TableCell>
                        {msgLog.status === 'SENT' && (
                            <Icon style={{ color: 'green', marginLeft: theme.spacing.unit * 1.5 }}>check_circle</Icon>
                        )}
                        {msgLog.status === 'RECEIVED' && (
                            <Icon style={{ color: 'green', marginLeft: theme.spacing.unit * 1.5 }}>check_circle</Icon>
                        )}
                        {msgLog.status === 'FAILED' && (
                            <Icon style={{ color: 'red', marginLeft: theme.spacing.unit * 1.5 }}>warning</Icon>
                        )}
                        {msgLog.status === 'INVALID' && (
                            <Tooltip
                                title="The environment prevented this notification from being sent"
                                disableFocusListener
                                disableTouchListener
                            >
                                <Icon style={{ color: 'yellow', marginLeft: theme.spacing.unit * 1.5 }}>info</Icon>
                            </Tooltip>
                        )}
                    </TableCell>
                )}
                {filteredHeaders.includes('Recipient') && (
                    <TableCell>{msgLog.email || formatAsPhoneNumber(msgLog.phone) || 'Unknown'}</TableCell>
                )}
                {filteredHeaders.includes('Message Type') && (
                    <TableCell>
                        <InlineChip
                            color={colors.blueGrey[100]}
                            textColor="black"
                            style={{ flexShrink: 0 }}
                            text={_.get(msgLog, 'msgType', '')}
                        />
                    </TableCell>
                )}
                {filteredHeaders.includes('Notification Type') && (
                    <TableCell>
                        <InlineChip
                            color={getNotificationTypeColor(_.get(msgLog, 'notifType', ''))}
                            textColor="black"
                            style={{ flexShrink: 0 }}
                            text={_.get(msgLog, 'notifType', '')}
                        />
                    </TableCell>
                )}
                {filteredHeaders.includes('Date') && (
                    <TableCell>{moment(_.get(msgLog, 'createdAt')).format('LLLL')}</TableCell>
                )}

                {filteredHeaders.includes('Errors') && (
                    <TableCell>
                        <Tooltip title="View Data" disableFocusListener disableTouchListener>
                            <IconButton
                                onClick={() => {
                                    setJsonDialogOpen(true);
                                    setJsonToView({
                                        _id: msgLog._id,
                                        err: msgLog.err
                                    });
                                }}
                            >
                                <MDIcon path={mdiCodeJson} size={1} color={theme.palette.text.secondary} />
                            </IconButton>
                        </Tooltip>
                    </TableCell>
                )}
            </TableRow>
        );
    };

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <div style={{ padding: theme.spacing.unit * 2, paddingBottom: theme.spacing.unit }}>
                        <Paper
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                padding: theme.spacing.unit * 2,
                                height: '100%',
                                flexWrap: 'wrap'
                            }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <DateRangePicker
                                    timezone={timezone}
                                    startDate={startDate}
                                    endDate={endDate}
                                    window={dateWindow}
                                    disabled={loading}
                                    handlePrevious={handleGoBackwards}
                                    handleNext={handleGoForwards}
                                    handleChangeStartDate={handleChangeStartDate}
                                    handleChangeEndDate={handleChangeEndDate}
                                />
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexWrap: 'wrap',
                                        marginRight: theme.spacing.unit
                                    }}
                                >
                                    <FormControl>
                                        <Select
                                            value={dateWindow}
                                            disabled={loading}
                                            onChange={handleWindowSelect}
                                            style={{
                                                maxWidth: '250px',
                                                margin: theme.spacing.unit
                                            }}
                                        >
                                            <MenuItem value="today">Daily</MenuItem>
                                            <MenuItem value="thisWeek">Weekly</MenuItem>
                                            <MenuItem value="thisMonth">Monthly</MenuItem>
                                            <MenuItem value="thisQuarter">Quarterly</MenuItem>
                                            <MenuItem value="allTime">All Time</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <MultiSelect
                                    selectedValues={notifTypesFilter}
                                    selectableValues={notifTypes.map(type => ({ name: type, value: type }))}
                                    onChange={value => setNotifTypesFilter(value)}
                                    style={{ marginRight: theme.spacing.unit }}
                                />
                                <TextField
                                    select
                                    value={msgTypeFilter}
                                    onChange={e => setMsgTypeFilter(e.target.value)}
                                    style={{ marginRight: theme.spacing.unit }}
                                >
                                    <MenuItem value="ALL">
                                        <i>ALL</i>
                                    </MenuItem>
                                    {allMsgTypes.map(type => (
                                        <MenuItem value={type}>{type}</MenuItem>
                                    ))}
                                </TextField>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexWrap: 'wrap'
                                    }}
                                >
                                    <Button color="primary" variant="contained" onClick={handleLoadData}>
                                        <Icon>search</Icon>
                                    </Button>
                                </div>
                            </div>
                        </Paper>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div style={{ padding: theme.spacing.unit * 2, paddingBottom: theme.spacing.unit }}>
                        <Paper
                            style={{
                                display: 'flex',
                                justifyContent: 'space-around',
                                padding: theme.spacing.unit * 2,
                                height: '100%',
                                flexWrap: 'wrap'
                            }}
                        >
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <Avatar
                                    style={{
                                        backgroundColor: theme.palette.primary.main,
                                        marginRight: theme.spacing.unit * 2
                                    }}
                                >
                                    <Icon>email</Icon>
                                </Avatar>
                                <Typography>Outgoing Emails: {loadingTotals ? '...' : emailsSent}</Typography>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <Avatar
                                    style={{
                                        backgroundColor: theme.palette.primary.main,
                                        marginRight: theme.spacing.unit * 2
                                    }}
                                >
                                    <Icon>sms</Icon>
                                </Avatar>
                                <Typography>Outgoing SMS: {loadingTotals ? '...' : SMSSent}</Typography>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <Avatar
                                    style={{
                                        backgroundColor: theme.palette.primary.main,
                                        marginRight: theme.spacing.unit * 2
                                    }}
                                >
                                    <Icon>sms</Icon>
                                </Avatar>
                                <Typography>Incoming SMS: {loadingTotals ? '...' : SMSReceived}</Typography>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <Avatar
                                    style={{
                                        backgroundColor: theme.palette.primary.main,
                                        marginRight: theme.spacing.unit * 2
                                    }}
                                >
                                    <Icon>notifications</Icon>
                                </Avatar>
                                <Typography>Outgoing Push Notifications: {loadingTotals ? '...' : pushSent}</Typography>
                            </div>
                        </Paper>
                    </div>
                </Grid>
            </Grid>
            <Table
                title="Messaging Logs"
                loading={loading}
                headers={headers}
                filteredHeaders={filteredHeaders}
                length={totalDataEntries}
                page={page}
                rowsPerPage={rowsPerPage}
                search={search}
                handleChange={handleChange}
                handleChangeFilterHeaders={handleChangeFilterHeaders}
                handleAllFilterHeaders={handleAllFilterHeaders}
                handleSearch={handleSearch}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                refresh={getData}
            >
                {filteredData &&
                    filteredData.map(msgLog => {
                        return renderRow(msgLog, filteredHeaders);
                    })}
            </Table>
            <CustomDebugDialog open={jsonDialogOpen} json={jsonToView} onClose={() => setJsonDialogOpen(false)} />
        </>
    );
}

export default withTheme()(MsgLogs);
