import { useState, useEffect, useContext } from 'react';
import { deviceHelper, isCONRegion } from 'utils/misc';

import _ from 'lodash';
import queryString from 'query-string';
import { _user } from 'std';

import * as terms from 'localizations/terms';

import { isWidthUp } from '@material-ui/core/withWidth';

// contexts
import HttpContext from 'utils/contexts/HttpContext';
import SnackbarContext from 'components/CustomSnackbar/SnackbarContext';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

import { Network } from '@capacitor/network';
import { Dialog as Modals } from '@capacitor/dialog';

let networkWatcher;

function useForceUpdate() {
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => ++value); // update the state to force render
}

function useOperatorSite({
    match,
    auth,
    location,
    history,
    width,
    onToggleNightMode,
    onAdminTheme,
    refreshUnresolvedInfractionCount,
    setRefreshUnresolvedInfractionCount,
    refreshPendingCharityCount,
    setRefreshPendingCharityCount,
    refreshPendingBottleDriveCount,
    setRefreshPendingBottleDriveCount,
    refreshPendingMobileCollectionCount,
    setRefreshPendingMobileCollectionCount,
    refreshProblemAssetCount,
    setRefreshProblemAssetCount
}) {
    const onSnackbar = useContext(SnackbarContext);
    const forceUpdate = useForceUpdate();
    const http = useContext(HttpContext);
    const { lang, setLang } = useContext(LocalizationContext);

    const [_id, set_id] = useState(match.params._id);
    const [adminThemeActivated, setAdminThemeActivated] = useState(false);

    const [loading, setLoading] = useState(true);
    const [operator, setOperator] = useState(undefined);
    const [pickups, setPickups] = useState(undefined);
    const [trips, setTrips] = useState(undefined);
    const [skus, setSkus] = useState([]);
    const [receivers, setReceivers] = useState([]);
    const [bulks, setBulks] = useState(undefined);
    const [redemptions, setRedemptions] = useState(undefined);
    const [redemptionOptions, setRedemptionOptions] = useState(undefined);
    const [minRedemption, setMinRedemption] = useState(undefined);
    const [redemptionFee, setRedemptionFee] = useState(undefined);
    const [collectors, setCollectors] = useState([]);
    const [processors, setProcessors] = useState([]);
    const [regions, setRegions] = useState(undefined);
    const [customHeader, setCustomHeader] = useState(undefined);
    const [rolePermissions, setRolePermissions] = useState(undefined);
    const [tippingEnabled, setTippingEnabled] = useState(false);

    const isLiveCountsPage = location.pathname === `/operators/${_id}/live-counts`;

    const [drawerOpen, setDrawerOpen] = useState(
        JSON.parse(
            getInitialDrawerState(width === 'lg' || width === 'md' || width === 'xl', isLiveCountsPage) ||
                isWidthUp('sm')
                ? 'true'
                : 'false'
        )
    );

    const [drawerWidth, setDrawerWidth] = useState(drawerOpen ? 280 : 0);

    const [drawerPickupOpen, setDrawerPickupOpen] = useState(false);
    const [helpDialogEl, setHelpDialogEl] = useState(null);
    const [langDialogEl, setLangDialogEl] = useState(null);

    const [contactDialogOpen, setContactDialogOpen] = useState(false);
    const [howItWorksOpen, setHowItWorksDialogOpen] = useState(false);
    const [faqOpen, setFaqOpen] = useState(false);

    const [collapseOpen, setCollapseOpen] = useState(null);
    const [anchorEl, setAnchorlEl] = useState(null);
    const [twoFactorAuthenticationWarning, setTwoFactorAuthenticationWarning] = useState(null);

    const [menuOptions, setMenuOptions] = useState({});

    const homePath = '/operators/' + _id;
    const pages = {
        // Admins:
        [homePath]: loc('home', lang),
        [homePath + '/profile']: 'Profile',
        // [homePath + '/pickups-admin']: 'Today',
        //[homePath + '/counter']: 'Counter',
        // Bulks
        [homePath + '/bulks']: 'Counter',
        [homePath + '/bulks-history']: loc('history', lang),
        [homePath + '/bulks-stats']: 'Clerk Report',
        [homePath + '/live-counts']: 'Live Counts',
        [homePath + '/infractions']: 'Review Infractions',

        // Accounting
        [homePath + '/redemptions']: 'Redemptions',
        [homePath + '/accounting']: 'Invoicing',
        [homePath + '/quickbooks']: 'Quickbooks',
        [homePath + '/reconciliation']: 'Reconciliation',
        [homePath + '/aging-balances-report']: 'Aging Balances',
        [homePath + '/journal-entries']: 'Journal Entries',

        // Users
        [homePath + '/admins']: 'Admins',
        [homePath + '/users']: 'Customers',
        [homePath + '/charities']: terms.ORGANIZATION_NAME + 's',
        [homePath + '/employees']: 'Employees',
        [homePath + '/collectors']: 'Collectors',
        [homePath + '/collection-users']: isCONRegion() ? 'eCSP' : 'IC&I',
        //Usage
        [homePath + '/trends']: 'Trends',
        [homePath + '/audience-capture']: 'Questionnaires',
        [homePath + '/promo-usage']: 'Promo Usage',
        [homePath + '/donations']: 'Donations',
        [homePath + '/fees-usage']: 'Subscriptions & Fees',
        [homePath + '/tips']: 'Tips',
        [homePath + '/complaints']: 'Complaints',
        [homePath + '/stops']: 'Stops',
        // Configuration
        [homePath + '/announcements']: 'Announcements',
        [homePath + '/commodities']: 'Commodities',
        [homePath + '/fees']: 'Fees',
        [homePath + '/integrations']: 'Integrations',
        [homePath + '/promos']: 'Promos',
        [homePath + '/rates']: 'Rates',
        [homePath + '/rating-platforms']: 'Rating Platforms',
        [homePath + '/regions']: 'Regions',
        [homePath + '/services']: 'Services & Account Types',
        [homePath + '/taxes']: 'Taxes',
        [homePath + '/trucks']: 'Trucks',
        [homePath + '/questionnaires']: 'Questionnaires',
        //Logs
        [homePath + '/google-calls']: 'Google Calls',
        // [homePath + '/logs']: 'Database',
        [homePath + '/db-logs']: 'Database',
        [homePath + '/msg-logs']: 'Notifications (SMS/Email/App)',
        [homePath + '/req-logs']: 'HTTP Requests',
        //Security
        [homePath + '/ip-blocking']: 'IP Blocking',
        //System
        [homePath + '/send-outs']: 'Single Sends',
        [homePath + '/img-capture-status']: 'Image Capture Device Status',
        [homePath + '/role-permissions']: 'Roles & Permissions',
        [homePath + '/saved-notifications']: 'Saved Notifications',
        [homePath + '/settings']: 'Settings',
        [homePath + '/informationForms']: 'Information Editor',
        [homePath + '/email-editor']: 'Emails / SMS / Push',
        [homePath + '/debug']: 'Automated Tasks',

        // [homePath + '/feed']: 'Live Feed',
        // [homePath + '/droplocations']: 'Drop Locations',
        // [homePath + '/quick-register']: 'Depot Customers',
        // Drivers:
        [homePath + '/driver']: loc('dashboard', lang),
        [homePath + '/pickups']: 'Pickups',
        [homePath + '/driver-completed']: _.capitalize(loc('completed', lang)),
        [homePath + '/import-pickups']: _.capitalize(loc('import', lang)),
        // Collectors:
        [homePath + '/collector']: loc('dashboard', lang),
        [homePath + '/pickups-manager']: 'Today',
        [homePath + '/collector-completed']: 'Completed',
        [homePath + '/collector-history']: 'Trip Statistics',
        [homePath + '/pickups-calendar']: 'Calendar',
        [homePath + '/commodity-pickups']: 'Commodity',
        [homePath + '/asset-tracking']: 'Asset Management',
        [homePath + '/inventory']: 'Inventory',
        [homePath + '/bottle-drives']: 'Bottle Drives',
        [homePath + '/depotLogin']: 'Depot Login',
        [homePath + '/options']: 'Options',

        [homePath + '/OTC']: 'OTC & External'
    };

    const reloadOperator = async (user_id = _id) => {
        const res = await http.getJSON('/users/operators/' + user_id);
        if (res.ok) {
            const operatorUser = res.data.operator;

            if (!_.isNil(operatorUser) && operatorUser.adminView && !adminThemeActivated) {
                setAdminThemeActivated(true);
                onAdminTheme(true);
            } else if (!_.get(operatorUser, 'adminView', false) && adminThemeActivated) {
                setAdminThemeActivated(false);
                onAdminTheme(false);
            }

            setOperator(operatorUser);
            setPickups(_.get(res, 'data.pickups', []));
            setRedemptions(_.get(res, 'data.redemptions', []));
            setRedemptionOptions(_.get(res, 'data.redemptionOptions', []));
            setSkus(_.get(res, 'data.skus', {}));
            setReceivers(_.get(res, 'data.receivers', []));
            setCollectors(_.get(res, 'data.collectors', []));
            setProcessors(_.get(res, 'data.processors', []));
            setRegions(_.get(res, 'data.regions', []));
            setMinRedemption(_.get(res, 'data.minRedemption', undefined));
            setRedemptionFee(_.get(res, 'data.redemptionFee', undefined));
            setTwoFactorAuthenticationWarning(_.get(res, 'data.twoFactorAuthenticationWarning'));
            setRolePermissions(_.get(res, 'data.rolePermissions'));
            setMenuOptions(_.get(res, 'data.menuOptions', {}));
            // setDriverStartLocationDialogOpen(driverStartLocationDialogOpen);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (deviceHelper.isNativeApp()) {
            networkWatcher = Network.addListener('networkStatusChange', async status => {
                if (!status.connected) {
                    await Modals.alert({
                        title: 'Connection Interrupted',
                        message:
                            'Please ensure you have a strong internet connection before continuing with your trips. Low quality conections can cause issues with the app and with your trips.'
                    });
                }
            });
        }
        window.addEventListener('resize', handleResize);
        reloadOperator();

        return () => {
            // cleanup function for component did unmount
            window.removeEventListener('resize', handleResize);
            try {
                if (deviceHelper.isNativeApp()) {
                    networkWatcher.remove();
                }
            } catch (err) {
                console.error('Error removing network watcher');
            }
        };
    }, [location]); // TODO: remove this location from reloading the operator and the entire page

    const fetchTippingConfig = async () => {
        let resTippingConfig = await http.getJSON('/config/tippingConfig');
        if (resTippingConfig.ok) {
            setTippingEnabled(_.get(resTippingConfig, 'data.config.isEnabled', false));
        }
    };

    useEffect(() => {
        fetchTippingConfig();
    }, []);

    const handleResize = () => {
        forceUpdate(); // we do this to get updated app bar height from getAppBarHeight()
    };

    const handleCustomHeader = text => {
        setCustomHeader(text);
    };

    const handleDrawer = open => () => {
        setDrawerOpen(open);
        const updatedWidth = open ? 280 : 0;
        localStorage.setItem('drawerWidth', updatedWidth);
        setDrawerWidth(updatedWidth);
    };

    const handlePickupDrawer = open => () => {
        setDrawerPickupOpen(open);
    };

    const handleClose = () => {
        setAnchorlEl(null);
    };

    const handleNightModeToggle = () => {
        onToggleNightMode();
        setDrawerOpen(false);
        setDrawerWidth(0);
    };

    const handleSaveRedemptionOptions = async redemptionOptions => {
        const res = await http.post('/redemptions/options', redemptionOptions);
        if (res.ok) {
            onSnackbar('Redemption options saved.');
        }
    };

    const handleHelpClick = event => {
        setHelpDialogEl(event.currentTarget);
    };

    const handleHelpClose = () => {
        setHelpDialogEl(null);
    };

    const handleLangClick = event => {
        setLangDialogEl(event.currentTarget);
    };

    const handleLangClose = event => {
        setLangDialogEl(null);
    };

    const handleFAQ = state => () => {
        setFaqOpen(state);
        setHelpDialogEl(null);
    };

    const handleHowItWorks = state => () => {
        setHowItWorksDialogOpen(state);
        setHelpDialogEl(null);
    };

    const handleContactDialog = state => () => {
        setContactDialogOpen(state);
        setHelpDialogEl(null);
    };

    const handleContactSubmitSuccess = () => {
        setContactDialogOpen(false);
        onSnackbar(
            process.env.REACT_APP_REGION_EXT === 'EXP'
                ? 'Thank you for submitting a ticket. A member of our Customer Relations team will get back to you within two business days.'
                : 'Thank you for your feedback!'
        );
    };

    const handleGoBack = () => {
        history.goBack();
    };

    const handleDriverStartLocationDialog = state => {
        // setDriverStartLocationDialogOpen(state);
    };

    const handleExitView = async () => {
        setLoading(true);
        history.push(`/operators/${auth._id}`);
        set_id(auth._id);
        await reloadOperator(auth._id);
        // setAdminThemeActivated(false);
        // onAdminTheme(false);
    };

    const handleSelectLanguage = selectedLang => {
        if (selectedLang === lang) return;

        setLang(selectedLang);
    };

    const handleExpandCollapse = (collapseToOpen = null) => {
        setCollapseOpen(collapseToOpen === collapseOpen ? null : collapseToOpen);
    };

    let lastRedemption = _.last(redemptions);
    let redemptionPending = (!_.isNil(lastRedemption) && !lastRedemption.complete) || false;

    const backButtonShown = queryString.parse(location.search).back;

    // drawer menu numerical badge/bubble notifier logic
    const [pendingCount, setPendingCount] = useState(0);
    const [overdueComplaintCount, setOverdueComplaintCount] = useState(0);
    const [unresolvedInfractionCount, setUnresolvedInfractionCount] = useState(0);
    const [pendingBottleDriveCount, setPendingBottleDriveCount] = useState(0);
    const [pendingMobileCollectionCount, setPendingMobileCollectionCount] = useState(0);
    const [problemAssetCount, setProblemAssetCount] = useState(0);
    const isAdmin = _user.isSystemAdmin(operator) || _user.isInternalRole(operator);
    useEffect(() => {
        if (isAdmin && refreshPendingCharityCount) {
            getPendingCharityCount();
        }
    }, [refreshPendingCharityCount]);
    useEffect(() => {
        if (isAdmin && refreshUnresolvedInfractionCount) {
            getUnresolvedInfractionCount();
        }
    }, [refreshUnresolvedInfractionCount]);
    useEffect(() => {
        if (isAdmin && refreshPendingBottleDriveCount) {
            getPendingBottleDriveCount();
        }
    }, [refreshPendingBottleDriveCount]);
    useEffect(() => {
        if (isAdmin && refreshPendingMobileCollectionCount) {
            getPendingMobileCollectionCount();
        }
    }, [refreshPendingMobileCollectionCount]);
    useEffect(() => {
        if (isAdmin && refreshProblemAssetCount) {
            getProblemAssetCount();
        }
    }, [refreshProblemAssetCount]);
    useEffect(() => {
        if (_.isNil(operator)) return;
        getOverdueOrderCount();
        getPendingCharityCount();
        getUnresolvedInfractionCount();
        getPendingBottleDriveCount();
        getPendingMobileCollectionCount();
        getProblemAssetCount();
        let getPendingCharityCountSVCID = '';
        let getUnresolvedInfractionCountSVCID = '';
        let getPendingBottleDriveCountSVCID = '';
        let getProblemAssetCountSVCID = '';
        let getPendingMobileCollectionCountSVCID = '';
        if (isAdmin) {
            getPendingCharityCountSVCID = setInterval(() => {
                getPendingCharityCount();
            }, 120000);
            getUnresolvedInfractionCountSVCID = setInterval(() => {
                getUnresolvedInfractionCount();
            }, 120000);
            getPendingBottleDriveCountSVCID = setInterval(() => {
                getPendingBottleDriveCount();
            }, 120000);
            getPendingMobileCollectionCountSVCID = setInterval(() => {
                getPendingMobileCollectionCount();
            }, 120000);
            getProblemAssetCountSVCID = setInterval(() => {
                getProblemAssetCount();
            }, 120000);
        }
        let getOverdueOrderCountSVCID = setInterval(() => {
            getOverdueOrderCount();
        }, 600000);
        return () => {
            if (isAdmin) {
                clearInterval(getPendingCharityCountSVCID);
                clearInterval(getUnresolvedInfractionCountSVCID);
                clearInterval(getPendingBottleDriveCountSVCID);
                clearInterval(getPendingMobileCollectionCountSVCID);
                clearInterval(getProblemAssetCountSVCID);
            }
            clearInterval(getOverdueOrderCountSVCID);
        };
    }, [operator]);

    async function getPendingCharityCount() {
        const res = await http.getJSON('/charities/pendingV2', false, true);
        if (res.ok) {
            const pendingCharities = res.data.charities;
            if (!_.isNil(pendingCharities) && !_.isNaN(pendingCharities.length)) {
                setPendingCount(pendingCharities.length);
            } else {
                setPendingCount(0);
            }
        }
        setRefreshPendingCharityCount(false);
    }

    async function getOverdueOrderCount() {
        let operatorId = _.get(operator, '_id', null);
        if (operatorId === null) return;
        let newOverdueComplaintCount = 0;
        const [overdue, whiny] = await Promise.all([
            http.getJSON(`/bulks/getBulkCounts/${operator._id}?bulkType=overdue`, false, true),
            http.getJSON(`/bulks/getBulkCounts/${operator._id}?bulkType=whiny`, false, true)
        ]);
        if (overdue.ok) {
            newOverdueComplaintCount += _.get(overdue, 'data.bulkCounts', 0);
        }
        if (whiny.ok) {
            newOverdueComplaintCount += _.get(whiny, 'data.bulkCounts', 0);
        }
        setOverdueComplaintCount(newOverdueComplaintCount);
    }
    async function getUnresolvedInfractionCount() {
        let res = await http.getJSON(`/infractions/unresolvedCount`, false, true);
        if (res.ok) {
            let newCount = _.get(res, 'data', 0);
            if (!_.isNil(res.data)) {
                setUnresolvedInfractionCount(newCount);
            }
        }
        setRefreshUnresolvedInfractionCount(false);
    }
    async function getPendingBottleDriveCount() {
        let res = await http.getJSON(`/bottleDrives/pendingCount`, false, true);
        if (res.ok) {
            let newCount = _.get(res, 'data', 0);
            if (!_.isNil(res.data)) {
                setPendingBottleDriveCount(newCount);
            }
        }
        setRefreshPendingBottleDriveCount(false);
    }
    async function getPendingMobileCollectionCount() {
        let res = await http.getJSON(`/users/pendingMobileCollectionUsers`, false, true);
        if (res.ok) {
            let newCount = _.get(res, 'data', 0);
            if (!_.isNil(res.data)) {
                setPendingMobileCollectionCount(newCount);
            }
        }
        setRefreshPendingMobileCollectionCount(false);
    }
    async function getProblemAssetCount() {
        let res = await http.getJSON(`/pickups/problemAssetCount`, false, true);
        if (res.ok) {
            let newCount = _.get(res, 'data', 0);
            if (!_.isNil(res.data)) {
                setProblemAssetCount(newCount);
            }
        }
        setRefreshProblemAssetCount(false);
    }

    return {
        _id,
        adminThemeActivated,
        lastRedemption,
        redemptionPending,
        backButtonShown,
        pages,
        homePath,
        loading: loading || _.isNil(operator),
        operator,
        pickups,
        trips,
        skus,
        receivers,
        bulks,
        redemptions,
        redemptionOptions,
        minRedemption,
        redemptionFee,
        collectors,
        processors,
        regions,
        customHeader,
        drawerOpen,
        drawerWidth,
        drawerPickupOpen,
        helpDialogEl,
        langDialogEl,
        contactDialogOpen,
        howItWorksOpen,
        faqOpen,
        collapseOpen,
        anchorEl,
        isLiveCountsPage,
        twoFactorAuthenticationWarning,
        pendingCount,
        overdueComplaintCount,
        unresolvedInfractionCount,
        pendingBottleDriveCount,
        pendingMobileCollectionCount,
        problemAssetCount,
        menuOptions,
        handleResize,
        handleCustomHeader,
        handleDrawer,
        handlePickupDrawer,
        handleClose,
        handleNightModeToggle,
        handleSaveRedemptionOptions,
        handleHelpClick,
        handleHelpClose,
        handleLangClick,
        handleLangClose,
        handleFAQ,
        handleHowItWorks,
        handleContactDialog,
        handleContactSubmitSuccess,
        handleGoBack,
        handleDriverStartLocationDialog,
        handleExitView,
        handleExpandCollapse,
        reloadOperator,
        rolePermissions,
        tippingEnabled,
        handleSelectLanguage
    };
}

export default useOperatorSite;

function getInitialDrawerState(isDesktop, isLiveCountsPage) {
    if (isLiveCountsPage) {
        return false; //don't show drawer for live counts page
    }
    return isDesktop;
}
