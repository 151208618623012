import React, { useState } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';
import { _user } from 'std';
import { DAYS_OF_THE_WEEK } from 'constants.js';

import {
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    Button,
    Typography,
    DialogContentText,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    IconButton
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { withTheme } from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery';
import Icon from '@mdi/react';
import { mdiDrag, mdiDelete, mdiAlert, mdiPencil, mdiCalendarMultiple } from '@mdi/js';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

const DriverOptions = ({
    theme,
    dayToEdit,
    capacity,
    handleChangeDayToEdit,
    handleDragEnd,
    aggregationPreferences,
    dayDrivers,
    inactiveDrivers,
    availableDrivers,
    collector,
    destinationRouting,
    handleAddToAllDays,
    handleRemoveDriver,
    handleAddDriverDialog,
    handleEditDriverDialog
}) => {
    const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.values.sm}px)`);

    const [showWarning, setShowWarning] = useState(false);
    const [selectedDriver, setSelectedDriver] = useState(null);

    const timezone = _.get(collector, 'timezone', process.env.REACT_APP_REGION_TIMEZONE);
    const routeDestinationByDriver = destinationRouting === 'Driver';

    const handleAddAll = driver => {
        setShowWarning(true);
        setSelectedDriver(driver);
    };

    const handleAcceptAddAll = () => {
        setShowWarning(false);
        handleAddToAllDays(_.get(selectedDriver, '_id', null));
    };

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <ToggleButtonGroup exclusive value={dayToEdit} onChange={handleChangeDayToEdit}>
                    {DAYS_OF_THE_WEEK.map((name, i) => {
                        const noDrivers = _.find(aggregationPreferences, obj => obj.day === i).drivers.length === 0;
                        return (
                            <ToggleButton
                                data-cy={`driver-options-day-${name}`}
                                value={i}
                                key={name}
                                style={{
                                    color: noDrivers ? red[500] : '',
                                    padding: `0 ${theme.spacing.unit}px`,
                                    minWidth: 0
                                }}
                            >
                                {name.slice(0, isMobile ? 2 : 3)}
                            </ToggleButton>
                        );
                    })}
                </ToggleButtonGroup>
            </div>

            <Typography paragraph style={{ marginTop: theme.spacing.unit }} align="center">
                Bag Capacity: {capacity}
            </Typography>

            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="active">
                    {(provided, snapshot) => {
                        return (
                            <div
                                style={{ overflow: 'auto', maxHeight: 400 }}
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                <List>
                                    {_.isEmpty(dayDrivers) && (
                                        <ListItem style={{ display: 'flex', flexDirection: 'column' }}>
                                            <Icon path={mdiAlert} size={3} color={red[500]} />
                                            <ListItemText disableTypography>
                                                <Typography variant="h6" align="center" style={{ color: red[500] }}>
                                                    No Drivers available. Pickups will not be routed
                                                </Typography>
                                            </ListItemText>
                                        </ListItem>
                                    )}
                                    {dayDrivers &&
                                        dayDrivers.map((_id, i) => {
                                            const driver = availableDrivers[_id];
                                            if (_.isNil(driver)) return null;

                                            return (
                                                <Draggable draggableId={_id} index={i} key={`${i}${_id.toString()}`}>
                                                    {(provided, snapshot) => {
                                                        return (
                                                            <div
                                                                key={_id}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                ref={provided.innerRef}
                                                                style={{ ...provided.draggableProps.style }}
                                                            >
                                                                <ListItem>
                                                                    <Icon
                                                                        path={mdiDrag}
                                                                        size={1}
                                                                        color={theme.palette.text.secondary}
                                                                        style={{
                                                                            fontSize: '80%'
                                                                        }}
                                                                    />
                                                                    <ListItemText
                                                                        primary={
                                                                            <Typography>
                                                                                {_user.getNameFull(driver)}
                                                                                {routeDestinationByDriver
                                                                                    ? ` ➡ ${_.get(
                                                                                          driver,
                                                                                          'driverDestination.code',
                                                                                          'N/A'
                                                                                      )}`
                                                                                    : ''}
                                                                            </Typography>
                                                                        }
                                                                        secondary={
                                                                            <Typography
                                                                                style={{
                                                                                    color: theme.palette.text.secondary
                                                                                }}
                                                                            >
                                                                                {!isMobile && 'Bags: '}
                                                                                {driver.driverBagCapacity}
                                                                                {' x '}
                                                                                {_.get(driver, 'driverLoadsPerDay', 1)}
                                                                                {!isMobile && (
                                                                                    <>
                                                                                        {' '}
                                                                                        — Start:{' '}
                                                                                        {moment(driver.startTime)
                                                                                            .tz(timezone)
                                                                                            .format('h:mm A')}
                                                                                    </>
                                                                                )}
                                                                            </Typography>
                                                                        }
                                                                        style={{ whiteSpace: 'normal' }}
                                                                    />
                                                                    <ListItemSecondaryAction>
                                                                        <IconButton
                                                                            onClick={handleEditDriverDialog(driver)}
                                                                            data-cy={`edit-driver-${i}`}
                                                                        >
                                                                            <Icon
                                                                                path={mdiPencil}
                                                                                size={1}
                                                                                color={theme.palette.text.secondary}
                                                                            />
                                                                        </IconButton>
                                                                        <IconButton
                                                                            onClick={() => handleAddAll(driver)}
                                                                            data-cy={`add-all-days-${i}`}
                                                                        >
                                                                            <Icon
                                                                                path={mdiCalendarMultiple}
                                                                                size={1}
                                                                                color={theme.palette.text.secondary}
                                                                            />
                                                                        </IconButton>
                                                                        <IconButton
                                                                            onClick={handleRemoveDriver(_id)}
                                                                            data-cy={`remove-driver-${i}`}
                                                                        >
                                                                            <Icon
                                                                                path={mdiDelete}
                                                                                size={1}
                                                                                color={theme.palette.text.secondary}
                                                                            />
                                                                        </IconButton>
                                                                    </ListItemSecondaryAction>
                                                                </ListItem>
                                                            </div>
                                                        );
                                                    }}
                                                </Draggable>
                                            );
                                        })}
                                </List>
                                {provided.placeholder}
                            </div>
                        );
                    }}
                </Droppable>
            </DragDropContext>
            <Typography align="center">
                <Button
                    color="primary"
                    variant="outlined"
                    onClick={handleAddDriverDialog(true)}
                    disabled={inactiveDrivers.length === 0}
                    data-cy="add-inactive-driver-button"
                >
                    Add Inactive Driver ({inactiveDrivers.length})
                </Button>
            </Typography>

            <Dialog open={showWarning} onClose={() => setShowWarning(false)}>
                <DialogTitle>Add driver to all days</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        This will add the driver to all days of the week. Would you like to continue?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" size="small" onClick={() => setShowWarning(false)}>
                        No
                    </Button>
                    <Button color="primary" size="small" onClick={() => handleAcceptAddAll()}>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default withTheme()(DriverOptions);
