import React, { useState, useEffect, useContext } from 'react';
import { startCase, isEmpty, isNil } from 'lodash';
import { _time } from 'std';
import _ from 'lodash';

import { EditorState, convertToRaw } from 'draft-js';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { withTheme } from '@material-ui/core/styles';
import {
    Icon,
    Paper,
    Grid,
    Button,
    Avatar,
    Typography,
    TextField,
    InputAdornment,
    IconButton,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    OutlinedInput,
    List,
    ListItem,
    ListItemText,
    FormControlLabel,
    Switch,
    Checkbox,
    LinearProgress
} from '@material-ui/core';
import * as colors from '@material-ui/core/colors';
import SearchIcon from '@material-ui/icons/Search';
import RefreshIcon from '@material-ui/icons/Refresh';
import ToggleOffIcon from '@material-ui/icons/ToggleOff';
import ToggleOnIcon from '@material-ui/icons/ToggleOn';
import Badge from 'components/Badge/Badge';

import { AnnouncementTemplate } from 'components/Announcement';
import HttpContext from 'utils/contexts/HttpContext';
import SnackbarContext from 'components/CustomSnackbar/SnackbarContext';

import useDatePicker from 'components/DateTimePickersTz/hooks/useDatePicker';
import DatePicker from 'components/DateTimePickersTz/DatePicker';
import CollectorsSelectDropdown from 'components/DropDownSelects/Collectors';
import moment from 'moment-timezone';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

import useGetJSON from 'utils/hooks/useGetJSON';
import { useRef } from 'react';
import { useMemo } from 'react';

import { DROP_OFF_TYPES } from 'constants.js';
import RichTextEditor, { getRichTextEditorContentFromEditorState, initializeEditorState } from 'components/RichTextEditor';

const SHOW_VALUES = {
    ALWAYS: 'Always',
    ONCE: 'Once',
    NEW_USERS_ONLY: 'New Users Only'
};

const INIT_FORM = {
    active: false,
    title: '',
    icon: '',
    body: '',
    backgroundColor: colors.blue[400],
    textColor: '#ffffff',
    // alwaysShow: false,
    show: SHOW_VALUES.ONCE,
    collectors: [],
    excludeNewUsers: false,
    questionnaireRequired: false,
    accountType: '',
    technologies: []
};

const Announcements = ({ theme, collectors, charityEnabled }) => {
    const http = useContext(HttpContext);
    const snack = useContext(SnackbarContext);
    const { lang } = useContext(LocalizationContext);
    const [form, setForm] = useState(INIT_FORM);
    const [allAnnouncements, setAllAnnouncements] = useState([]);
    const [selectedAnnouncement, setSelectedAnnouncement] = useState();
    const [enabledDates, setEnabledDates] = useState({ startDate: false, endDate: false });

    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        var bodyContents = _.get(form, 'body', '');

        initializeEditorState(bodyContents, setEditorState);
    }, [selectedAnnouncement]);

    const { date: startDate, timezone, handleChangeDate: handleChangeStartDate } = useDatePicker({
        timezones: [process.env.REACT_APP_REGION_TIMEZONE],
        initVal: _time.getStartOfWeek(process.env.REACT_APP_REGION_TIMEZONE)
    });
    const { date: endDate, handleChangeDate: handleChangeEndDate } = useDatePicker({
        timezones: [process.env.REACT_APP_REGION_TIMEZONE],
        initVal: _time.getEndOfDay(process.env.REACT_APP_REGION_TIMEZONE)
    });

    // fixes slowness when dragging color selector, prevents react from rerendering to much,
    // needs to be in a ref so the function isn't recreated every render
    const handleChangeColorRef = useRef(
        _.throttle(e => {
            if (!_.isNil(e) && !_.isNil(e.target)) {
                const { value, name } = e.target;
                setForm(previousForm => ({ ...previousForm, [name]: value }));
            }
        }, 100)
    );

    const handleChangeColorWrapper = e => {
        handleChangeColorRef.current(e);
    };

    const {
        loading: questionnairesLoading,
        error: questionnairesLoadingErr,
        data: questionnaires = [],
        refresh: refreshQuestionnaires
    } = useGetJSON(`/questionnaireSorted`, 'questionnaires');

    const { loading: servicesLoading, error: servicesLoadingErr, data: servicesData } = useGetJSON('/services');

    const mobilePickupSubServices = useMemo(
        () =>
            _.get(
                _.find(
                    _.get(servicesData, 'services', []),
                    service => service.isMobilePickupService && !service.disabled
                ),
                'subServices',
                []
            ),
        [servicesData]
    );

    const handleSearch = () => {
        window.open('https://material.io/resources/icons/?style=baseline');
    };

    const handleAdd = () => {
        setForm(INIT_FORM);
        setSelectedAnnouncement(null);
    };

    const handleClick = _id => () => {
        const selectedAnn = allAnnouncements.find(a => a._id === _id);

        let startDateEnabled = selectedAnn.startDate !== 19700101;
        let endDateEnabled = selectedAnn.endDate !== 20990101;

        setForm({
            ...selectedAnn,
            show: selectedAnn.alwaysShow
                ? SHOW_VALUES.ALWAYS
                : selectedAnn.newUsersOnly
                ? SHOW_VALUES.NEW_USERS_ONLY
                : SHOW_VALUES.ONCE
        });
        setSelectedAnnouncement(selectedAnn);
        setEnabledDates({ startDate: startDateEnabled, endDate: endDateEnabled });
        handleChangeStartDate(_time.getMomentObjectFromDateInteger(timezone, selectedAnn.startDate));
        handleChangeEndDate(_time.getMomentObjectFromDateInteger(timezone, selectedAnn.endDate));
    };

    const handleChange = e => {
        const { value, name } = e.target;
        setForm({ ...form, [name]: value });
    };

    const handleChangeSwitch = e => {
        const { checked, name } = e.target;
        setForm({ ...form, [name]: checked });
    };

    const handleEnableDates = e => {
        e.stopPropagation();
        const { name } = e.currentTarget;
        if (name === 'startDate') handleChangeStartDate(_time.getStartOfToday(process.env.REACT_APP_REGION_TIMEZONE));
        else handleChangeEndDate(_time.getEndOfDay(process.env.REACT_APP_REGION_TIMEZONE));
        setEnabledDates({ ...enabledDates, [name]: !enabledDates[name] });
    };

    const handleSave = async () => {
        setIsLoading(true);
        let modifiedForm = _.cloneDeep(form);
        delete modifiedForm.show;
        switch (form.show) {
            case SHOW_VALUES.NEW_USERS_ONLY:
                modifiedForm.alwaysShow = false;
                modifiedForm.newUsersOnly = true;
                break;
            case SHOW_VALUES.ALWAYS:
                modifiedForm.alwaysShow = true;
                modifiedForm.newUsersOnly = false;
                break;
            case SHOW_VALUES.ONCE:
            default:
                modifiedForm.alwaysShow = false;
                modifiedForm.newUsersOnly = false;
                break;
        }
        const res = await http.post('/system/announcement', {
            form: {
                ...modifiedForm,
                body: convertToRaw(editorState.getCurrentContent()),
                startDate: enabledDates.startDate ? _time.getDateInteger(timezone, startDate) : 19700101,
                endDate: enabledDates.endDate ? _time.getDateInteger(timezone, endDate) : 20990101
            }
        });
        if (res.ok) {
            snack('Announcement saved.');
            setForm(INIT_FORM);
            setSelectedAnnouncement(null);
            getAnnouncements();
        } else {
            snack('Something went wrong. Please try again', 'error');
        }
        setIsLoading(false);
    };

    const handleDelete = async () => {
        setIsLoading(true);
        const res = await http.post('/system/announcement', { form, remove: true });
        if (res.ok) {
            snack('Announcement deleted.');
            setForm(INIT_FORM);
            setSelectedAnnouncement(null);
            getAnnouncements();
        } else {
            snack('Something went wrong. Please try again', 'error');
        }
        setIsLoading(false);
    };

    const getAnnouncements = async () => {
        setIsLoading(true);
        const res = await http.getJSON('/system/announcement?inactive=true');
        if (res.ok) {
            const { announcements } = res.data;
            setAllAnnouncements(announcements);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        getAnnouncements();
    }, []);

    return (
        <Grid
            container
            style={{ width: '100%', padding: theme.spacing.unit, margin: 0 }}
            spacing={theme.spacing.unit * 2}
        >
            <Grid item xs={12}>
                {isLoading && <LinearProgress />}
                <Paper style={{ width: '100%', padding: theme.spacing.unit * 2 }}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            flexWrap: 'wrap'
                        }}
                    >
                        <Button
                            style={{ marginBottom: theme.spacing.unit / 2, marginTop: theme.spacing.unit }}
                            variant="outlined"
                            onClick={handleAdd}
                            color="primary"
                            data-cy="crud-table-add-new"
                        >
                            <Icon style={{ marginRight: theme.spacing.unit }}>refresh</Icon>
                            Reset Form
                        </Button>
                    </div>
                </Paper>
            </Grid>
            <Grid item xs={12} style={{ height: '100%' }}>
                <Paper style={{ padding: theme.spacing.unit * 2, overflowY: 'auto' }}>
                    <Typography variant="h5" gutterBottom>
                        Announcements
                    </Typography>

                    <Grid container spacing={16} style={{ width: '100%', margin: 0 }}>
                        <Grid item xs={6} sm={4}>
                            <Typography variant="h6" gutterBottom>
                                All Announcements
                            </Typography>

                            <List>
                                {isEmpty(allAnnouncements) ? (
                                    <ListItem>
                                        <ListItemText>
                                            <Typography color="textSecondary" style={{ fontStyle: 'oblique' }}>
                                                No announcements
                                            </Typography>
                                        </ListItemText>
                                    </ListItem>
                                ) : (
                                    allAnnouncements.map(
                                        ({
                                            title,
                                            icon,
                                            active,
                                            alwaysShow,
                                            newUsersOnly,
                                            _id,
                                            startDate,
                                            endDate,
                                            collectors: annCollectors,
                                            backgroundColor,
                                            textColor,
                                            accountType
                                        }) => {
                                            const startDateMoment = _time.getMomentObjectFromDateInteger(
                                                process.env.REACT_APP_REGION_TIMEZONE,
                                                startDate
                                            );
                                            const startDateFormatted = startDateMoment.format('MMM D, YYYY');
                                            const endDateMoment = _time.getMomentObjectFromDateInteger(
                                                process.env.REACT_APP_REGION_TIMEZONE,
                                                endDate
                                            );
                                            const endDateFormatted = endDateMoment.format('MMM D, YYYY');
                                            const selected = _id === _.get(selectedAnnouncement, '_id');
                                            return (
                                                <ListItem
                                                    button
                                                    key={_id}
                                                    onClick={handleClick(_id)}
                                                    selected={selected}
                                                >
                                                    <Avatar
                                                        style={{
                                                            backgroundColor: backgroundColor
                                                        }}
                                                    >
                                                        {icon && <Icon style={{ color: textColor }}>{icon}</Icon>}
                                                    </Avatar>
                                                    <ListItemText
                                                        secondary={
                                                            <>
                                                                <Badge
                                                                    style={{
                                                                        backgroundColor:
                                                                            active && moment().isBefore(startDateMoment)
                                                                                ? colors.blue[500]
                                                                                : active &&
                                                                                  moment().isBetween(
                                                                                      startDateMoment,
                                                                                      endDateMoment
                                                                                  )
                                                                                ? colors.green[400]
                                                                                : colors.red[500],
                                                                        color: '#fff',
                                                                        overflow: 'hidden',
                                                                        textOverflow: 'ellipsis',
                                                                        whiteSpace: 'nowrap'
                                                                    }}
                                                                >
                                                                    {active && moment().isBefore(startDateMoment)
                                                                        ? 'Scheduled '
                                                                        : active && moment().isAfter(endDateMoment)
                                                                        ? 'Ended '
                                                                        : active &&
                                                                          moment().isBetween(
                                                                              startDateMoment,
                                                                              endDateMoment
                                                                          )
                                                                        ? 'Active '
                                                                        : 'Disabled '}
                                                                    -{' '}
                                                                    {alwaysShow
                                                                        ? SHOW_VALUES.ALWAYS
                                                                        : newUsersOnly
                                                                        ? SHOW_VALUES.NEW_USERS_ONLY
                                                                        : SHOW_VALUES.ONCE}
                                                                </Badge>{' '}
                                                                ({startDateFormatted} - {endDateFormatted})
                                                            </>
                                                        }
                                                    >
                                                        {title} (
                                                        {_.isEmpty(annCollectors)
                                                            ? 'All Collectors'
                                                            : _.filter(collectors, c => annCollectors.includes(c._id))
                                                                  .map(c => c.code)
                                                                  .join(', ')}
                                                        ) {!_.isEmpty(accountType) && ' - (' + accountType + ')'}
                                                    </ListItemText>
                                                </ListItem>
                                            );
                                        }
                                    )
                                )}
                            </List>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <Typography variant="h6" gutterBottom>
                                {selectedAnnouncement ? 'Edit Announcement' : 'New Announcement'}
                                <IconButton onClick={handleAdd}>
                                    <RefreshIcon />
                                </IconButton>
                            </Typography>
                            <div style={{ display: 'flex', marginBottom: theme.spacing.unit }}>
                                <FormControl
                                    variant="outlined"
                                    style={{ marginRight: theme.spacing.unit }}
                                    margin="dense"
                                    fullWidth
                                >
                                    <DatePicker
                                        timezone={timezone}
                                        label="Start Date"
                                        format="DD MMM YYYY"
                                        value={startDate}
                                        textFieldOverride={!enabledDates.startDate ? 'NONE' : ''}
                                        style={{ margin: theme.spacing.unit }}
                                        onChange={handleChangeStartDate}
                                        variant="outlined"
                                        disabled={!enabledDates.startDate}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton name="startDate" onClick={handleEnableDates}>
                                                        {enabledDates.startDate ? <ToggleOnIcon /> : <ToggleOffIcon />}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </FormControl>
                                <FormControl variant="outlined" fullWidth margin="dense">
                                    <DatePicker
                                        timezone={timezone}
                                        label="End Date"
                                        format="DD MMM YYYY"
                                        value={endDate}
                                        textFieldOverride={!enabledDates.endDate ? 'NONE' : ''}
                                        onChange={handleChangeEndDate}
                                        variant="outlined"
                                        disabled={!enabledDates.endDate}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton name="endDate" onClick={handleEnableDates}>
                                                        {enabledDates.endDate ? <ToggleOnIcon /> : <ToggleOffIcon />}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </FormControl>
                            </div>
                            <div style={{ marginBottom: theme.spacing.unit }}>
                                <CollectorsSelectDropdown
                                    showAll={false}
                                    id="collectors"
                                    value={form.collectors}
                                    collectors={collectors}
                                    onChange={handleChange}
                                    multiple
                                    style={{ width: '100%' }}
                                />
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <FormControl
                                    variant="outlined"
                                    fullWidth
                                    margin="dense"
                                    style={{ marginRight: theme.spacing.unit }}
                                >
                                    <InputLabel htmlFor="accountType">Account Type</InputLabel>
                                    <Select
                                        value={form.accountType || ''}
                                        onChange={handleChange}
                                        input={<OutlinedInput name="accountType" id="accountType" labelWidth={100} />}
                                        data-cy="announcement-type-select"
                                    >
                                        <MenuItem value={''} data-cy={`announcement-accountType-select-none`}>
                                            None
                                        </MenuItem>
                                        {mobilePickupSubServices.map(subService => {
                                            return (
                                                <MenuItem
                                                    value={subService.pickupType}
                                                    data-cy={`announcement-accountType-select-${subService.pickupType}`}
                                                >
                                                    {subService.pickupType}
                                                </MenuItem>
                                            );
                                        })}
                                        {charityEnabled && (
                                            <MenuItem
                                                value={'Charity'}
                                                data-cy={`announcement-accountType-select-charity`}
                                            >
                                                Charity
                                            </MenuItem>
                                        )}
                                    </Select>
                                </FormControl>{' '}
                                <FormControl
                                    variant="outlined"
                                    fullWidth
                                    margin="dense"
                                    style={{ marginRight: theme.spacing.unit }}
                                >
                                    <InputLabel shrink htmlFor="technologies">
                                        Technology
                                    </InputLabel>
                                    <Select
                                        notched
                                        value={form.technologies || []}
                                        renderValue={x =>
                                            _.isEmpty(x)
                                                ? 'All Technologies'
                                                : x.length > 0 && x.length <= 3
                                                ? x.join(', ')
                                                : `${x.length} Types`
                                        }
                                        displayEmpty={true}
                                        label={'technologies'}
                                        onChange={handleChange}
                                        input={<OutlinedInput name="technologies" id="technologies" labelWidth={85} />}
                                        data-cy="announcement-technologies-select"
                                        multiple
                                    >
                                        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                            <Button
                                                onClick={e => {
                                                    e.stopPropagation();

                                                    setForm(previousForm => ({
                                                        ...previousForm,
                                                        technologies: DROP_OFF_TYPES.map(dropType => dropType.value)
                                                    }));
                                                }}
                                            >
                                                All
                                            </Button>
                                            <Button
                                                onClick={e => {
                                                    e.stopPropagation();

                                                    setForm(previousForm => ({
                                                        ...previousForm,
                                                        technologies: []
                                                    }));
                                                }}
                                            >
                                                None
                                            </Button>
                                        </div>
                                        {DROP_OFF_TYPES.map(dropType => {
                                            return (
                                                <MenuItem
                                                    value={dropType.value}
                                                    data-cy={`announcement-technologies-select-${dropType.value}`}
                                                >
                                                    <Checkbox checked={form.technologies.includes(dropType.value)} />
                                                    {dropType.value}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <FormControl
                                    variant="outlined"
                                    fullWidth
                                    margin="dense"
                                    style={{ marginRight: theme.spacing.unit }}
                                >
                                    <InputLabel htmlFor="active">Active</InputLabel>
                                    <Select
                                        value={form.active}
                                        onChange={handleChange}
                                        input={<OutlinedInput name="active" id="active" labelWidth={45} />}
                                        data-cy="announcement-active-select"
                                    >
                                        <MenuItem value={true} data-cy="announcement-active-select-active">
                                            Active
                                        </MenuItem>
                                        <MenuItem value={false} data-cy="announcement-active-select-inactive">
                                            Inactive
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl fullWidth style={{ marginRight: theme.spacing.unit }}>
                                    <TextField
                                        name="icon"
                                        label="Icon"
                                        fullWidth
                                        variant="outlined"
                                        value={form.icon || ''}
                                        onChange={handleChange}
                                        margin="dense"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={handleSearch}>
                                                        <SearchIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </FormControl>
                            </div>
                            <TextField
                                name="title"
                                label="Title"
                                fullWidth
                                variant="outlined"
                                value={form.title || ''}
                                onChange={handleChange}
                                margin="dense"
                                inputProps={{ 'data-cy': 'announcement-title' }}
                            />
                            <RichTextEditor editorState={editorState} setEditorState={setEditorState} />
                            <FormControl
                                variant="outlined"
                                fullWidth
                                margin="dense"
                                style={{ marginRight: theme.spacing.unit }}
                            >
                                <InputLabel htmlFor="questionnaire">Questionnaire</InputLabel>
                                <Select
                                    fullWidth
                                    value={form.questionnaire || []}
                                    onChange={handleChange}
                                    input={<OutlinedInput name="questionnaire" id="questionnaire" labelWidth={100} />}
                                    data-cy="announcement-questionnaire"
                                    multiple
                                >
                                    {!_.isEmpty(questionnaires) &&
                                        questionnaires.map(questionnaire => (
                                            <MenuItem
                                                value={questionnaire._id}
                                                data-cy={`announcement-questionnaire-${_.get(questionnaire, 'name')}`}
                                            >
                                                {questionnaire.name}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    flexWrap: 'wrap'
                                }}
                            >
                                <span>
                                    {!isEmpty(form.questionnaire) && (
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={form.questionnaireRequired}
                                                    onChange={handleChangeSwitch}
                                                    color="primary"
                                                />
                                            }
                                            name="questionnaireRequired"
                                            label="Questionnaire Required"
                                            style={{ marginBottom: theme.spacing.unit }}
                                        />
                                    )}
                                </span>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={form.excludeNewUsers}
                                            onChange={handleChangeSwitch}
                                            color="primary"
                                            disabled={form.show === SHOW_VALUES.NEW_USERS_ONLY}
                                        />
                                    }
                                    name="excludeNewUsers"
                                    label="Exclude New Users"
                                    style={{ marginBottom: theme.spacing.unit }}
                                />
                            </div>
                            <div style={{ display: 'flex' }}>
                                <FormControl
                                    variant="outlined"
                                    style={{ marginRight: theme.spacing.unit }}
                                    margin="dense"
                                    fullWidth
                                >
                                    <TextField
                                        type="color"
                                        onChange={handleChangeColorWrapper}
                                        value={form.backgroundColor}
                                        name="backgroundColor"
                                        label="Background"
                                        margin="dense"
                                        variant="outlined"
                                        fullWidth
                                    />
                                </FormControl>
                                <FormControl
                                    variant="outlined"
                                    fullWidth
                                    margin="dense"
                                    style={{ marginRight: theme.spacing.unit }}
                                >
                                    <TextField
                                        type="color"
                                        onChange={handleChangeColorWrapper}
                                        value={form.textColor}
                                        name="textColor"
                                        label="Text Colour"
                                        margin="dense"
                                        variant="outlined"
                                        fullWidth
                                    />
                                </FormControl>
                                <FormControl variant="outlined" fullWidth margin="normal">
                                    <InputLabel htmlFor="always-show">Show</InputLabel>
                                    <Select
                                        value={form.show}
                                        onChange={handleChange}
                                        input={<OutlinedInput margin="dense" name="show" id="show" labelWidth={40} />}
                                    >
                                        <MenuItem value={SHOW_VALUES.ONCE}>{SHOW_VALUES.ONCE}</MenuItem>
                                        <MenuItem value={SHOW_VALUES.ALWAYS}>{SHOW_VALUES.ALWAYS}</MenuItem>
                                        <MenuItem value={SHOW_VALUES.NEW_USERS_ONLY} disabled={form.excludeNewUsers}>
                                            {SHOW_VALUES.NEW_USERS_ONLY}
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="h6" gutterBottom>
                                {loc('growthPostInfo5', lang)}
                            </Typography>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <AnnouncementTemplate
                                    title={form.title}
                                    body={getRichTextEditorContentFromEditorState(editorState)}
                                    icon={form.icon}
                                    backgroundColor={form.backgroundColor}
                                    textColor={form.textColor}
                                    alwaysShow
                                    questionnaire={_.filter(questionnaires, questionnaire =>
                                        _.includes(form.questionnaire, _.get(questionnaire, '_id'))
                                    )}
                                    questionnaireRequired={form.questionnaireRequired}
                                    previewMode
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <div>
                            <Button
                                style={{ marginRight: theme.spacing.unit }}
                                onClick={handleDelete}
                                disabled={isNil(form._id) || isLoading}
                            >
                                Delete
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSave}
                                disabled={
                                    editorState
                                        .getCurrentContent()
                                        .getPlainText()
                                        .trim().length === 0 ||
                                    isEmpty(form.title) ||
                                    isLoading
                                }
                                data-cy="announcement-save"
                            >
                                Save
                            </Button>
                        </div>
                    </div>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default withTheme()(Announcements);
