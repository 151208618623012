import React from 'react';

import _ from 'lodash';

import { Dialog, DialogActions, Button, withTheme, Typography } from '@material-ui/core';

import ImageViewer from 'components/ImageUploads/ImageViewer';

function InfoDialog(props) {
    const { onClose, reason, charityName, open, skipReasonImages } = props;
    return (
        <Dialog open={open} onClose={onClose}>
            <div style={{ marginTop: 15, marginLeft: 20, marginRight: 20 }}>
                {charityName !== '' && (
                    <div>
                        <Typography variant="h6">Donation</Typography>
                        <div style={{ flexDirection: 'column' }}>
                            <Typography variant="body2">{'Donating to: ' + charityName}</Typography>
                        </div>
                    </div>
                )}
                {reason !== '' && (
                    <div style={{ marginTop: charityName !== '' ? 15 : 0 }}>
                        <Typography variant="h6">Skip Reason</Typography>
                        <div style={{ flexDirection: 'column' }}>
                            {reason.split('\n').map(line => {
                                return <Typography variant="body2">{line}</Typography>;
                            })}
                        </div>
                        {!_.isEmpty(skipReasonImages) && (
                            <ImageViewer
                                displayImages={skipReasonImages}
                                disableDelete={true}
                                scrollerStyles={{ maxWidth: 420, overflowX: 'auto' }}
                            />
                        )}
                    </div>
                )}
            </div>
            <DialogActions>
                <Button onClick={onClose}>OK</Button>
            </DialogActions>
        </Dialog>
    );
}

export default withTheme()(InfoDialog);
