import React, { useState, useContext } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';

import { Collapse, Icon, IconButton, Typography, withTheme } from '@material-ui/core';
import LabeledText from 'components/LabeledText/LabeledText';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc, locDate } from '../../../localizations/localizationHandler';

const ExpandableInfoPanel = ({
    info,
    alwaysConfirmed,
    bypassZoneChecks,
    alwaysBypassZoneChecks,
    highPriority,
    hasClothingBin,
    theme,
    handleCopy,
    handleClicks,
    isInfoExpanded
}) => {
    const labelStyle = { color: theme.palette.text.primary };

    const { lang } = useContext(LocalizationContext);

    return (
        <Collapse in={isInfoExpanded}>
            <div
                style={{
                    marginTop: theme.spacing.unit,
                    fontFamily: 'monospace',
                    display: 'grid',
                    gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
                    gridColumnGap: theme.spacing.unit * 2
                }}
            >
                <div>
                    <LabeledText
                        label="_id: "
                        singleClickAction={() => handleCopy(`ObjectId("${_.get(info, '_id', '')}")`)}
                    >
                        {_.get(info, '_id', '')}
                    </LabeledText>
                    <LabeledText
                        label="confirmed: "
                        doubleClickAction={handleClicks(info._id, 'confirmed', !info.confirmed)}
                    >
                        {info.confirmed.toString()}
                    </LabeledText>
                    <LabeledText
                        label="alwaysConfirmed: "
                        doubleClickAction={handleClicks(info._id, 'alwaysConfirmed', !alwaysConfirmed)}
                    >
                        {alwaysConfirmed.toString()}
                    </LabeledText>
                    <LabeledText
                        label="bypassZoneChecks: "
                        doubleClickAction={handleClicks(info._id, 'bypassZoneChecks', !bypassZoneChecks)}
                    >
                        {bypassZoneChecks.toString()}
                    </LabeledText>
                    <LabeledText
                        label="alwaysBypassZoneChecks: "
                        doubleClickAction={handleClicks(info._id, 'alwaysBypassZoneChecks', !alwaysBypassZoneChecks)}
                    >
                        {alwaysBypassZoneChecks.toString()}
                    </LabeledText>
                    <LabeledText
                        noBreak
                        label="priorityLevel: "
                        singleClickAction={handleClicks(
                            info._id,
                            'postponedBySystem',
                            highPriority > 0 ? highPriority - 1 : 0
                        )}
                    >
                        -
                    </LabeledText>
                    <span style={labelStyle}> {highPriority} </span>
                    <LabeledText singleClickAction={handleClicks(info._id, 'postponedBySystem', highPriority + 1)}>
                        +
                    </LabeledText>
                    <LabeledText label="abortedCounter: ">{_.get(info, 'abortedCounter', '0')}</LabeledText>
                    <LabeledText
                        noBreak
                        label="hasClothingBin: "
                        doubleClickAction={handleClicks(info._id, 'hasClothingBin', !hasClothingBin)}
                    >
                        {hasClothingBin.toString()}
                    </LabeledText>
                </div>
                <div>
                    <LabeledText label="dateInteger: ">{`${_.get(info, 'dateInteger', '')}`}</LabeledText>
                    <LabeledText label="date: ">{`${_.get(info, 'date', '')}`}</LabeledText>
                    {info.timeSlotBooking && info.date && (
                        <LabeledText label="time: ">
                            <b>
                                {locDate(
                                    info.date,
                                    'hh:mm A',
                                    lang,
                                    _.get(info, 'location.timezone', process.env.REACT_APP_REGION_TIMEZONE)
                                )}
                            </b>
                        </LabeledText>
                    )}
                    <LabeledText label="nextDate: ">
                        {info.nextDate || <span style={{ color: theme.palette.text.disabled }}>N/A</span>}
                    </LabeledText>
                    <LabeledText label="zone: ">
                        {_.get(info, 'zone.name', undefined) || (
                            <span style={{ color: theme.palette.text.disabled }}>N/A</span>
                        )}
                    </LabeledText>
                    <LabeledText label="timezone: ">
                        {_.get(info, 'location.timezone', undefined) || (
                            <span style={{ color: theme.palette.text.disabled }}>N/A</span>
                        )}
                    </LabeledText>
                    <LabeledText label="collector: ">
                        {_.get(info, 'collector.name', <span style={{ color: theme.palette.text.disabled }}>N/A</span>)}
                    </LabeledText>
                    <LabeledText label="receiver: " noBreak>
                        {`${_.get(info, 'receiver._id', '')} (${_.get(info, 'receiver.name.first', 'N/A')})`}
                    </LabeledText>
                </div>
            </div>
        </Collapse>
    );
};

export default withTheme()(ExpandableInfoPanel);
