import _ from 'lodash';
import React from 'react';
import { useState } from 'react';
import { formatAsCurrency } from 'utils/misc';
const { TextField } = require('@material-ui/core');

const CurrencyInput = ({ formik, obj, currencyKey, theme, dataCy, label, style }) => {
    const [amount, setAmount] = useState(_.get(obj, currencyKey, 0) * 100);
    const handleCurrencyChange = e => {
        // Forces user to adjust the dollar amounts with the cursor at the end position
        // Anything more complicated would probaby require a library

        const fieldName = e.target.name;
        const value = e.target.value;

        const currencyLimit = 99999; // Limit of $999.99

        const valueWithoutCurrency = value.substring(1, value.length);
        const decimalValues = valueWithoutCurrency.split('.');

        const fractionalCurrencyAsString = decimalValues[1];

        const mainCurrency = Number(decimalValues[0]);
        const fractionalCurrency = Number(decimalValues[1]);

        if (_.isNaN(mainCurrency) || _.isNaN(fractionalCurrency)) return;

        // Handles when a user tries to change the dollar amount
        if (mainCurrency !== Math.floor(amount / 100)) return;

        let newAmount;

        if (fractionalCurrencyAsString.length <= 2) {
            // Removing a number
            newAmount = Math.floor(amount / 10);
        } else if (amount <= currencyLimit / 10) {
            // Adding a number (within currency limits)
            // If user is trying to add a number anywhere other then the end, return
            if (amount % 100 !== Math.floor(fractionalCurrency / 10)) return;
            newAmount = amount * 10 + (fractionalCurrency % 10);
        } else if (amount > currencyLimit / 10) {
            // Adding a number (over currency limits)
            return;
        }

        setAmount(newAmount);
        formik.setFieldValue(fieldName, newAmount / 100);
    };
    return (
        <TextField
            fullWidth
            data-cy={dataCy}
            label={label}
            name={currencyKey}
            value={formatAsCurrency(amount)}
            onChange={handleCurrencyChange}
            variant="outlined"
            style={{ marginTop: theme.spacing.unit * 2, marginRight: theme.spacing.unit, ...style }}
            error={_.get(formik.touched, currencyKey, false) && _.get(formik.errors, currencyKey, false) ? true : null}
            helperText={
                _.get(formik.touched, currencyKey, false) && _.get(formik.errors, currencyKey, false)
                    ? _.get(formik.errors, currencyKey, false)
                    : null
            }
            onBlur={formik.handleBlur}
        />
    );
};

export default CurrencyInput;
