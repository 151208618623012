import moment from 'moment-timezone';
import { _time } from 'std';

const tz = process.env.REACT_APP_REGION_TIMEZONE;

// const TODAY = moment().endOf('day');
// const START_OF_TODAY = moment().startOf('day');
const EARLIEST_START_DATE = moment(new Date(2018, 0, 1));
// const START_OF_THIS_WEEK = moment().startOf('isoWeek');
const START_OF_THIS_QUARTER = moment().startOf('quarter');
// const START_OF_THIS_MONTH = moment().startOf('month');
const LAST_21_DAYS = moment().subtract(21, 'days');

const TODAY = _time.getEndOfDay(tz);
const START_OF_TODAY = _time.getStartOfToday(tz);
//const EARLIEST_START_DATE = _time.getEarliestDate(tz);
const START_OF_THIS_WEEK = _time.getStartOfWeek(tz);
//const START_OF_THIS_QUARTER = _time.getStartOfQuarter(tz);
const START_OF_THIS_MONTH = _time.getStartOfMonth(tz);

const START_OF_THIS_ISO_WEEK = _time.getStartOfIsoWeek(tz);

export const today = {
    startDate: START_OF_TODAY,
    endDate: TODAY,
    increment: 'day'
};

export const thisWeek = {
    startDate: START_OF_THIS_WEEK,
    endDate: TODAY,
    increment: 'week'
};

//week starts on mondays, also the the week counter starts the first thursday of the year
export const thisIsoWeek = {
    startDate: START_OF_THIS_ISO_WEEK,
    endDate: TODAY,
    increment: 'isoWeek'
};

export const thisMonth = {
    startDate: START_OF_THIS_MONTH,
    endDate: TODAY,
    increment: 'month'
};

export const thisQuarter = {
    startDate: START_OF_THIS_QUARTER,
    endDate: TODAY,
    increment: 'quarter'
};

export const last21Days = {
    startDate: LAST_21_DAYS,
    endDate: TODAY,
    increment: 'day'
};

export const allTime = {
    startDate: EARLIEST_START_DATE,
    endDate: TODAY,
    increment: 'alltime'
};
