import React from 'react';
import _ from 'lodash';

import { withTheme, FormControl, MenuItem, Select, Button, Checkbox, ListItemText } from '@material-ui/core';

import useQueryString from 'utils/hooks/useQueryString';

import { AUS_STATES } from '../../constants';

function SelectTemplate({ loading }) {
    const [states, setStates] = useQueryString('states', [...AUS_STATES], true);

    const handleAllStates = all => e => {
        e.stopPropagation();
        if (all) {
            setStates([...AUS_STATES]);
        } else {
            setStates([]);
        }
    };

    return (
        <FormControl style={{ padding: 8 }}>
            <Select
                multiple
                disabled={loading}
                value={states}
                onChange={e => setStates(e.target.value)}
                renderValue={val =>
                    `${val.length === AUS_STATES.length ? 'All' : val.length} State${val.length !== 1 ? 's' : ''}`
                }
                style={{
                    minWidth: 132
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-around'
                    }}
                >
                    <Button disabled={loading} onClick={handleAllStates(true)}>
                        All
                    </Button>
                    <Button disabled={loading} onClick={handleAllStates(false)}>
                        None
                    </Button>
                </div>
                {AUS_STATES.map((state, index) => {
                    return (
                        <MenuItem key={index} value={state} disabled={loading}>
                            <Checkbox checked={states.includes(state)} />
                            <ListItemText>{state}</ListItemText>
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
}

export default withTheme()(SelectTemplate);
