import { useState } from 'react';

function useCRONLogDialog(props) {
    const { isOpenOnLoad = false } = props || {};

    const [open, setOpen] = useState(isOpenOnLoad);
    const [log, setLog] = useState({});

    return {
        open,
        log,
        handleViewLog: err => {
            setLog(err);
            setOpen(true);
        },
        handleClose: () => setOpen(false)
    };
}

export default useCRONLogDialog;
