import React, { useEffect, useContext } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';

import { getCustomerName } from 'utils/misc';

import InlineChip from 'components/Chips/InlineChip';

import useTable from 'components/Table/useTable';
import Table from 'components/Table/Table';

import useDateRangePicker from 'components/DateTimePickersTz/hooks/useDateRangePicker';
import DateRangePicker from 'components/DateTimePickersTz/DateRangePicker';
import CustomDebugDialog from 'components/CustomDebugDialog/CustomDebugDialog';

import { mdiCodeJson } from '@mdi/js';
import MDIcon from '@mdi/react';

import {
    Grid,
    withTheme,
    TableRow,
    TableCell,
    Paper,
    Icon,
    FormControl,
    Select,
    MenuItem,
    IconButton,
    colors,
    Tooltip
} from '@material-ui/core';

import { _time } from 'std';
import SnackbarContext from 'components/CustomSnackbar/SnackbarContext';
import HttpContext from 'utils/contexts/HttpContext';
import { formatAsCurrency } from 'utils/misc';
import { useState } from 'react';

const headers = [
    { name: 'Actions', locKey: null },
    { name: 'Ip', locKey: null },
    { name: 'ResCode', locKey: null },
    { name: 'Method', locKey: null },
    { name: 'Path', locKey: null },
    { name: 'User', locKey: null },
    { name: 'Date', locKey: null }
];

function getResCodeColor(resCode) {
    if (resCode < 200) {
        return colors.grey[500];
    } else if (resCode < 300) {
        return colors.green[500];
    } else if (resCode < 400) {
        return colors.blueGrey[500];
    } else if (resCode < 500) {
        return colors.red[300];
    } else {
        return colors.red[500];
    }
}

function getMethodColor(method) {
    switch (method) {
        case 'GET':
            return colors.blue[500];
        case 'POST':
            return colors.red[500];
        case 'PUT':
            return colors.blueGrey[500];
        case 'PATCH':
            return colors.yellow[500];
        case 'DELETE':
            return colors.orange[500];
        default:
            return colors.grey[500];
    }
}

function DepositBreakdown(props) {
    const { theme } = props;

    const [jsonDialogOpen, setJsonDialogOpen] = useState(false);
    const [jsonToView, setJsonToView] = useState({});

    const {
        loading,
        getData,
        page,
        rowsPerPage,
        filteredData,
        search,
        filteredHeaders,
        totalDataEntries,
        handleChange,
        handleSearch,
        handleChangeFilterHeaders,
        handleAllFilterHeaders,
        handleChangePage,
        handleChangeRowsPerPage
    } = useTable({ url: '/reqLogs', key: 'reqLogs', headers: headers.map(({ name }) => name) });

    const {
        startDate,
        endDate,
        timezone,
        dateWindow,
        handleChangeStartDate,
        handleChangeEndDate,
        handleGoForwards,
        handleGoBackwards,
        handleWindowSelect
    } = useDateRangePicker({
        saveStateInURL: true,
        timezones: [process.env.REACT_APP_REGION_TIMEZONE],
        initStartVal: _time.getStartOfWeek(process.env.REACT_APP_REGION_TIMEZONE),
        initEndVal: _time.getEndOfDay(process.env.REACT_APP_REGION_TIMEZONE)
    });

    useEffect(() => {
        getData();
    }, [startDate, endDate, timezone, dateWindow]);

    const renderRow = reqLog => {
        return (
            <TableRow key={reqLog._id}>
                {filteredHeaders.includes('Actions') && (
                    <TableCell>
                        <Tooltip title="View Data" disableFocusListener disableTouchListener>
                            <IconButton
                                onClick={() => {
                                    setJsonDialogOpen(true);
                                    setJsonToView({
                                        _id: reqLog._id,
                                        query: reqLog.query,
                                        body: reqLog.body
                                    });
                                }}
                            >
                                <MDIcon path={mdiCodeJson} size={1} color={theme.palette.text.secondary} />
                            </IconButton>
                        </Tooltip>
                    </TableCell>
                )}
                {filteredHeaders.includes('Ip') && <TableCell>{_.get(reqLog, 'ip', '')}</TableCell>}
                {filteredHeaders.includes('ResCode') && (
                    <TableCell>
                        <InlineChip
                            color={getResCodeColor(_.get(reqLog, 'resCode', ''))}
                            textColor="white"
                            style={{ flexShrink: 0 }}
                            text={_.get(reqLog, 'resCode', '')}
                        />
                    </TableCell>
                )}
                {filteredHeaders.includes('Method') && (
                    <TableCell>
                        <InlineChip
                            color={getMethodColor(_.get(reqLog, 'method', ''))}
                            textColor="white"
                            style={{ flexShrink: 0 }}
                            text={_.get(reqLog, 'method', '')}
                        />
                    </TableCell>
                )}
                {filteredHeaders.includes('Path') && <TableCell>{_.get(reqLog, 'path', '')}</TableCell>}
                {filteredHeaders.includes('User') && (
                    <TableCell>{!_.isNil(_.get(reqLog, 'user')) && getCustomerName(_.get(reqLog, 'user'))}</TableCell>
                )}
                {filteredHeaders.includes('Date') && (
                    <TableCell>
                        {!_.isNil(_.get(reqLog, 'createdAt')) &&
                            moment(_.get(reqLog, 'createdAt'))
                                .tz(timezone)
                                .format('MMM Do h:mm A')}
                    </TableCell>
                )}
            </TableRow>
        );
    };

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <div style={{ padding: theme.spacing.unit * 2, paddingBottom: theme.spacing.unit }}>
                        <Paper
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                padding: theme.spacing.unit * 2,
                                height: '100%',
                                flexWrap: 'wrap'
                            }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <DateRangePicker
                                    timezone={timezone}
                                    startDate={startDate}
                                    endDate={endDate}
                                    window={dateWindow}
                                    disabled={loading}
                                    handlePrevious={handleGoBackwards}
                                    handleNext={handleGoForwards}
                                    handleChangeStartDate={handleChangeStartDate}
                                    handleChangeEndDate={handleChangeEndDate}
                                />
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexWrap: 'wrap'
                                }}
                            >
                                <FormControl>
                                    <Select
                                        value={dateWindow}
                                        disabled={loading}
                                        onChange={handleWindowSelect}
                                        style={{
                                            maxWidth: '250px',
                                            margin: theme.spacing.unit
                                        }}
                                    >
                                        <MenuItem value="today">Daily</MenuItem>
                                        <MenuItem value="thisWeek">Weekly</MenuItem>
                                        <MenuItem value="thisMonth">Monthly</MenuItem>
                                        <MenuItem value="thisQuarter">Quarterly</MenuItem>
                                        <MenuItem value="allTime">All Time</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </Paper>
                    </div>
                </Grid>
            </Grid>
            <Table
                title="Request Logs"
                loading={loading}
                headers={headers}
                filteredHeaders={filteredHeaders}
                length={totalDataEntries}
                page={page}
                rowsPerPage={rowsPerPage}
                search={search}
                handleChange={handleChange}
                handleChangeFilterHeaders={handleChangeFilterHeaders}
                handleAllFilterHeaders={handleAllFilterHeaders}
                handleSearch={handleSearch}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                refresh={getData}
            >
                {filteredData &&
                    filteredData.map(reqLog => {
                        return renderRow(reqLog, filteredHeaders);
                    })}
            </Table>
            <CustomDebugDialog open={jsonDialogOpen} json={jsonToView} onClose={() => setJsonDialogOpen(false)} />
        </>
    );
}

export default withTheme()(DepositBreakdown);
