import React from 'react';

import _ from 'lodash';

import { withTheme, Typography, Paper, CircularProgress } from '@material-ui/core';

import { Icon as MDIcon } from '@mdi/react';
import { mdiAccountCash } from '@mdi/js';
import { formatAsCurrency } from 'utils/misc';

function TipsWidget(props) {
    const { tipBalance, theme, loading, style } = props;

    return (
        <Paper
            style={{
                padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px`,
                ...style
            }}
        >
            {loading ? (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: theme.spacing.unit,
                        marginBottom: theme.spacing.unit
                    }}
                >
                    <CircularProgress />
                </div>
            ) : (
                <div
                    data-cy="financial-widget"
                    id="financial-widget"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'left',
                        marginRight: -theme.spacing.unit * 1.5
                    }}
                >
                    <div style={{ overflow: 'hidden' }}>
                        <Typography data-cy="tip-balance-value" variant="h4" color="primary">
                            {process.env.REACT_APP_CURRENCY.substr(0, 2)} {formatAsCurrency(tipBalance)}
                        </Typography>
                        <Typography variant="body1" style={{ fontWeight: 300 }}>
                            Tip Balance
                        </Typography>
                    </div>
                    <div
                        style={{
                            paddingLeft: theme.spacing.unit,
                            paddingRight: theme.spacing.unit
                        }}
                    >
                        <MDIcon path={mdiAccountCash} color={theme.palette.primary.main} size={2.5} />
                    </div>
                </div>
            )}
        </Paper>
    );
}

export default withTheme()(TipsWidget);
