import React, { Component } from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';

import ContainerWithLogo from 'components/ContainerWithLogo/ContainerWithLogo';

import ValidatedInput from 'components/ValidatedInput/ValidatedInput';

import { CircularProgress } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import { withTheme } from '@material-ui/core/styles';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from '../../localizations/localizationHandler';
import PasswordInput from '../../components/InputComponents/PasswordInput';

import { validate } from 'utils/validate';

class PasswordReset extends Component {
    static contextType = LocalizationContext;

    state = {
        newPassword: '',
        newPasswordConfirm: '',
        success: undefined,
        linkInvalid: false,
        isLoading: false
    };

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleSnackbarClose = () => {
        this.setState({ snackbarOpen: false });
    };

    handleSubmit = async () => {
        const { http } = this.props;
        const { newPassword, newPasswordConfirm } = this.state;
        this.setState({ isLoading: true });

        if (newPassword !== newPasswordConfirm || newPassword.length === 0) return;

        let id = this.props.match.params.id;
        let token = this.props.match.params.token;

        let res = await http.postJSON('/reset', { id, token, newPassword }, true);
        if (res.ok) {
            this.setState({ success: res.data.success, linkInvalid: !res.data.success, isLoading: false });
            this.props.onSnackbar(loc(res.data.message, this.context.lang), res.data.success ? 'success' : 'error');
        } else {
            if (res.errorMessage) {
                this.props.onSnackbar(res.errorMessage, 'error');
            } else {
                this.props.onSnackbar(loc('serverError', this.context.lang), 'error');
            }
            this.setState({ success: false, isLoading: false });
        }
    };

    handleResendPassordResetLink = async () => {
        const { http } = this.props;
        let id = this.props.match.params.id;

        let res = await http.post('/requestResetPassword', { id }, true);

        if (res.ok) {
            this.props.onSnackbar(loc('resetLinkSent', this.context.lang));
        } else {
            this.props.onSnackbar(loc('resetLinkNotSent', this.context.lang), 'error');
        }
    };

    render() {
        const { theme } = this.props;
        const { newPassword, newPasswordConfirm, success, linkInvalid, isLoading } = this.state;

        const passwordErr = validate(['password'], newPassword, this.context.lang);

        const MessageBlock = (
            <Typography variant="subtitle1" color="textSecondary" align="center">
                {loc('passwordReset1', this.context.lang)}
            </Typography>
        );

        const FormBlock = (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="h6">{loc('passwordReset2', this.context.lang)}</Typography>
                <FormControl style={{ marginTop: theme.spacing.unit }}>
                    <PasswordInput
                        showValidationRules
                        type="password"
                        variant="outlined"
                        name="newPassword"
                        label={loc('passwordReset3', this.context.lang)}
                        value={newPassword}
                        validators={['required']}
                        onChange={this.handleChange}
                        error={passwordErr.fail && !_.isEmpty(newPassword)}
                    />
                </FormControl>
                <FormControl style={{ marginTop: theme.spacing.unit }}>
                    <PasswordInput
                        type="password"
                        variant="outlined"
                        name="newPasswordConfirm"
                        label={loc('passwordReset4', this.context.lang)}
                        value={newPasswordConfirm}
                        validators={['required']}
                        matchValue={newPassword}
                        matchReason={loc('passwordReset7', this.context.lang)}
                        onChange={this.handleChange}
                        error={newPassword !== newPasswordConfirm}
                    />
                </FormControl>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={
                        newPassword !== newPasswordConfirm || newPassword.length === 0 || passwordErr.fail || isLoading
                    }
                    style={{ marginTop: theme.spacing.unit * 2, height: 40 }}
                    onClick={this.handleSubmit}
                >
                    {loc('change', this.context.lang)}
                    <CircularProgress
                        size={22}
                        thickness={4.8}
                        style={{
                            display: isLoading ? '' : 'none',
                            marginLeft: theme.spacing.unit
                        }}
                    />
                </Button>

                {linkInvalid && (
                    <Button
                        onClick={this.handleResendPassordResetLink}
                        style={{ marginTop: theme.spacing.unit }}
                        variant="contained"
                    >
                        {loc('passwordReset5', this.context.lang)}
                    </Button>
                )}
            </div>
        );

        return (
            <ContainerWithLogo
                brandLogoOverride={this.props && this.props.brandLogoOverride ? this.props.brandLogoOverride : null}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '100%',
                        minHeight: 300
                    }}
                >
                    <div style={{ width: '100%', maxWidth: 320, marginTop: theme.spacing.unit * 3 }}>
                        {success ? MessageBlock : FormBlock}
                        <div style={{ marginTop: theme.spacing.unit * 4, textAlign: 'center' }}>
                            <Typography component={Link} to="/login" variant="subtitle1" color="primary">
                                {loc('passwordReset6', this.context.lang)}
                            </Typography>
                        </div>
                    </div>
                </div>
            </ContainerWithLogo>
        );
    }
}

export default withTheme()(PasswordReset);
