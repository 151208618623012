import React, { useState } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';

import { Paper, Icon, IconButton, Button, withTheme, Typography } from '@material-ui/core';

import { ZONES_COLORS } from 'constants.js';

function ZoneLegend(props) {
    const { filters, onCreateZone, leftOffset, theme } = props;

    const showLegend = _.get(filters, 'showLegend', false);

    return (
        showLegend && (
            <div
                style={{
                    position: 'absolute',
                    left: leftOffset, //theme.spacing.unit * 2 + 480 + theme.spacing.unit * 2 /*width of date panel*/,
                    top: theme.spacing.unit * 2,
                    width: `calc(150 - ${theme.spacing.unit * 4}px)`,
                    maxWidth: 150
                }}
            >
                <Paper style={{ padding: theme.spacing.unit * 2, paddingBottom: theme.spacing.unit, width: 150 }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography variant="h6">Zones</Typography>
                        <IconButton size="small" onClick={onCreateZone}>
                            <Icon>add_circle</Icon>
                        </IconButton>
                    </div>
                    {ZONES_COLORS.map((color, i) => (
                        <div
                            key={color[500]}
                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                        >
                            <span style={{ width: 15, height: 15, backgroundColor: color[500] }} />
                            <Typography>
                                {moment()
                                    .isoWeekday(i + 1)
                                    .format('dddd')}
                            </Typography>
                        </div>
                    ))}
                </Paper>
            </div>
        )
    );
}

export default withTheme()(ZoneLegend);
