import React from 'react';

import CompanyLogo from 'components/CompanyLogo/CompanyLogo';

export default function ContainerWithLogo(props) {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%' }}>
            <CompanyLogo brandLogoOverride={props.brandLogoOverride} />
            <h1
                style={{
                    position: 'absolute',
                    height: '1px',
                    width: '1px',
                    overflow: 'hidden',
                    clip: 'rect(1px, 1px, 1px, 1px)',
                    whiteSpace: 'nowrap'
                }}
            >
                {process.env.REACT_APP_BRAND_NAME}
            </h1>
            <div style={{ marginTop: 55.5 }}>{props.children}</div>
        </div>
    );
}
