import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    withMobileDialog,
    withTheme
} from '@material-ui/core';

import HttpContext from 'utils/contexts/HttpContext';

import StringListInput from 'components/CustomInputs/StringListInput';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

function ContainerSizeDialog({ open, initialContainerSizes, onClose, onUpdateSizes, theme, fullScreen }) {
    const { lang } = useContext(LocalizationContext);
    const [containerSizes, setContainerSizes] = useState(initialContainerSizes);

    useEffect(() => {
        setContainerSizes(initialContainerSizes);
    }, [initialContainerSizes]);

    return (
        <Dialog onClose={onClose} open={open} fullScreen={fullScreen}>
            <DialogContent>
                <StringListInput
                    label="Container Sizes"
                    values={containerSizes}
                    onChange={values => setContainerSizes(values)}
                    error={() => {}}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button type="submit" color="primary" onClick={() => onUpdateSizes(containerSizes)}>
                    {loc('submit', lang)}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default withMobileDialog()(withTheme()(ContainerSizeDialog));
