import _ from 'lodash';
import { _commodity } from 'std';

export default {
    /**
     *
     */
    isConfirmed: function(pickup) {
        return _.get(pickup, 'confirmed', false);
    },
    isComplete: function(pickup) {
        return _.get(pickup, 'complete', false) && !_.get(pickup, 'aborted', false);
    },
    isAborted: function(pickup) {
        return _.get(pickup, 'aborted', false);
    },
    isSkipped: function(pickup) {
        return _.get(pickup, 'skipped', false);
    },
    isOutOfSystemStop: function(pickup) {
        return _.isNil(pickup.customer);
    },
    getPayload: function(pickup) {
        return _.get(pickup, 'payload', {
            beverage: 0,
            clothing: 0,
            electronics: 0
        });
    },
    getReceiverDistance: function(pickup) {
        return _.get(pickup, 'receiverDistance', -1).toLocaleString();
    },
    getPickupImageURL: function(pickup) {
        if (pickup.aborted || (pickup.skipped && !pickup.complete)) return _.get(pickup, 'images.abortImages', []);
        return _.get(pickup, 'images.pickupImages', []);
    },
    // NOTE: this assumes that bulks have been rolled up into pickup.bulks
    getLastDateCounted: function(pickup) {
        if (_.isEmpty(pickup.bulks)) {
            return null;
        }
        const datesCounted = pickup.bulks.map(b => b.dateCounted);
        return _.first(_.sortBy(datesCounted, b => b.dateCounted));
    },
    setPayload: function(pickup, payloadObj) {
        return _.set(pickup, 'payload', payloadObj);
    },
    payloadIsEmpty: function(payload) {
        return _.sum(Object.values(payload)) <= 0;
    },
    createEmptyPayload: function(pickup) {
        return {};
    },
    isCounted: function(pickup) {
        return true;
    },
    hasClothing: function(pickup) {
        return _.get(pickup, 'payload.clothing', 0) > 0;
    },
    floorPayloadValues: function(payload) {
        return {
            beverage: Math.floor(_.get(payload, 'beverage', 0) || 0),
            clothing: Math.floor(_.get(payload, 'clothing', 0) || 0),
            electronics: Math.floor(_.get(payload, 'electronics', 0) || 0)
        };
    },
    numberOfCountedBulksOnPickup: function(pickup) {
        // returns -1 if there is no bulks on the pickup
        if (_.isNil(pickup.bulks) || _.isEmpty(pickup.bulks)) return -1;
        return pickup.bulks.reduce((sum, b) => {
            return !_.isNil(b.dateCounted) ? (sum += 1) : sum;
        }, 0);
    },
    numberOfCompletedBulksOnPickup: function(pickup) {
        // returns -1 if there is no bulks on the pickup
        if (_.isNil(pickup.bulks) || _.isEmpty(pickup.bulks)) return -1;
        return pickup.bulks.reduce((sum, b) => {
            return !_.isNil(b.dateCompleted) ? (sum += 1) : sum;
        }, 0);
    },
    hasUncompletedBeverageBulks: function(pickup) {
        if (_.get(pickup, 'payload.beverage', 0) === 0 && _.get(pickup, 'payload.collectionbins', 0) === 0)
            return false;
        if (_.isNil(pickup.bulks) || _.isEmpty(pickup.bulks)) return false;
        return pickup.bulks.some(
            b =>
                _.isNil(b.dateCompleted) &&
                (_.get(b, 'skuType', '') === 'beverage' || _.get(b, 'skuType', '') === 'collectionbins')
        );
    },
    isPending: function(pickup) {
        return (!_.isNil(pickup) && !pickup.aborted && !pickup.complete) || false;
    },
    /**
     * REVIEW: THIS IS AN IMPORTANT AND DELICATE FUNCTION. TEST RIGOROUSLY!!
     * This function will roll up bulks into their respective pickups as pickup.bulks: []
     *  - NOTE: assumes the pickups are already in the correct order and does not change the order
     * @param {Array} pickups
     * @param {Array} bulks
     */
    rollupPickupsWithAssociatedBulks: function(pickups = [], bulks = [], tips = []) {
        if (_.isEmpty(pickups)) {
            return [];
        }

        // grab bulks with pickups to match up since quickdrop/adjustments arent associated to any pickup
        const bulksWithPickups = _(bulks)
            .filter(b => !_.isNil(b.pickup))
            .value();
        const tipsWithPickups = _(tips)
            .filter(t => !_.isNil(t.pickup))
            .value();

        let rolledUpPickups = pickups.map(p => {
            const bulksInPickup =
                _(bulksWithPickups)
                    .filter(b => b.pickup._id === p._id)
                    .value() || [];

            const tipForPickup = _.find(tipsWithPickups, t => t.pickup.toString() === p._id.toString());
            return {
                ...p,
                bulks: bulksInPickup,
                tipAmount: tipForPickup ? tipForPickup.originalAmount || tipForPickup.adjustedAmount || 0 : 0
            };
        });

        // filter out pickups without bulks
        let completedPickups = _(rolledUpPickups)
            .filter(p => p.complete && !p.aborted)
            .value();

        // if every completed pickup has bulks then we assume that everything was (probably) rolled up properly
        if (rolledUpPickups.length === completedPickups.length) {
            // anything with recent data should always return here
            return rolledUpPickups;
        }

        // PAST THIS POINT MEANS THAT THE USER HAS LEGACY DATA
        // 200 IQ TIME PAST THIS POINT. THIS HANDLES LEGACY DATA
        const bulksWithoutPickups = _(bulks)
            .filter(b => _.isNil(b.pickup) && b.bulkType === 'pickup')
            .value();
        // keep this in so we can tell when someone has old bulks without an association
        console.log('%cLegacy bulks: ', 'color: orange; font-weight: bold;', bulksWithoutPickups);

        if (!_.isEmpty(bulksWithoutPickups)) {
            const completedPickupsWithoutBulks = _(rolledUpPickups)
                .filter(p => _.isEmpty(p.bulks) && p.complete && !p.aborted)
                .value();

            console.log('%cLegacy pickups: ', 'color: orange; font-weight: bold;', completedPickupsWithoutBulks);

            let legacyBulkIndex = 0;
            rolledUpPickups.forEach(p => {
                // if a completed pickup has no bulks, then its most likely missing data
                if (_.isEmpty(p.bulks) && p.complete && !p.aborted) {
                    if (legacyBulkIndex < bulksWithoutPickups.length) {
                        p.bulks = [bulksWithoutPickups[legacyBulkIndex]];
                        legacyBulkIndex++;
                    }
                }
            });
        }

        return rolledUpPickups;
    }
};
