import React, { useEffect, useState, useMemo } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';
import { _time, _user } from 'std';

import {
    Icon,
    Grid,
    Paper,
    Button,
    FormControl,
    Checkbox,
    Select,
    MenuItem,
    ListItemText,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Tooltip,
    colors,
    Typography,
    LinearProgress
} from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import IconCard from 'components/IconCard/IconCard';
import MultiIconCard from 'components/IconCard/MultiIconCard';
import { blue } from '@material-ui/core/colors';
import useDashboard from './useDashboard';

import { formatAsCurrency } from 'utils/misc';

import BaseTable from './BaseTable';

import useDateRangePicker from 'components/DateTimePickersTz/hooks/useDateRangePicker';
import DateRangePicker from 'components/DateTimePickersTz/DateRangePicker';

import MDIcon from '@mdi/react';
import { mdiAccountOff, mdiBottleSodaClassic, mdiScaleBalance } from '@mdi/js';
import QuickSelect from 'components/InputComponents/QuickSelect';

const Dashboard = ({ http, collectors, onSnackbar, regions, theme, collector, pickupsEnabled }) => {
    const [cardConfigs, setCardConfigs] = useState({});

    const {
        startDate,
        endDate,
        timezone,
        dateWindow,
        handleChangeStartDate,
        handleChangeEndDate,
        handleGoForwards,
        handleGoBackwards,
        handleWindowSelect
    } = useDateRangePicker({
        saveStateInURL: true,
        timezones: [process.env.REACT_APP_REGION_TIMEZONE],
        initStartVal: _time.getStartOfIsoWeek(process.env.REACT_APP_REGION_TIMEZONE, 1),
        initEndVal: _time.getEndOfDay(process.env.REACT_APP_REGION_TIMEZONE),
        initDateWindow: 'thisIsoWeek'
    });

    const {
        loading,
        collectorsSelected,
        hideInactiveCollectors,
        fetchQuickStats,
        quickStats,
        collectorSelected,
        transportersSelected,
        allDrivers,
        driversSelected,
        handleCollectorsSelected,
        handleTransportersSelected,
        handleAllFilterCollectors,
        handleAllFilterTransporters,
        handleViewCollector,
        handleHideInactiveCollectors,
        handleDownloadUsers,
        handleDownloadPickups,
        handleDownloadContainers,
        handleClothingDialog,
        handleCloseOutstandingClothing,
        getCustomerQuickstats,
        getContractorQuickStats,
        getProcessingQuickStats,
        getContainersQuickStats,
        getCheckAndBalanceStats,
        getSTDQuickStatsAndTotal,
        getDepotQuickStatsAndTotal,
        getInvoicingStats,
        setDriversSelected
    } = useDashboard({ http, collectors, onSnackbar, startDate, endDate, regions, collector, pickupsEnabled });

    const cellStyle = { whiteSpace: 'nowrap' };

    const collectorTableHeaders = [
        { id: 'actions', label: 'Actions', sortOption: false },
        { id: 'name', label: 'Name' },
        { id: 'location.description', label: 'Address' },
        { id: 'numberOfPickups', label: 'Total Pickups' },
        { id: 'totalCompleted', label: 'Completed' },
        { id: 'numberOfUncountedBulks', label: 'Pending' },
        { id: 'numberOfBulksUnderThreshold', label: 'Under Threshold' },
        { id: 'numberOfAbortedPickups', label: 'Aborted' },
        { id: 'numberOfOutstandingClothingBags', label: 'Clothing Bags' },
        { id: 'amountOfEWaste', label: 'E-Waste' },
        { id: 'revenue.totalDeposits', label: 'Total Deposits' },
        { id: 'revenue.handlingFees', label: 'Total HFs' },
        { id: 'totalContainers', label: 'Containers' },
        { id: 'totalCustomers', label: 'Total Customers' },
        { id: 'ratings.pickups.total', label: 'Pickup Rating' },
        { id: 'ratings.counts.total', label: 'Count Rating' }
    ];

    // Copy then condition collector data before sending it to the table for rendering
    const collectorTableData = collectors => {
        const tableCollectors = _.cloneDeep(collectors);
        tableCollectors.forEach(collector => {
            let totalContainers = 0,
                totalInhouseContainers = 0;

            const containers = _.get(collector, 'containers');

            for (let key in containers) {
                if (key === 'inhouse') {
                    totalInhouseContainers += _.get(containers[key], 'small', 0) + _.get(containers[key], 'large', 0);
                }
                totalContainers += _.get(containers[key], 'small', 0) + _.get(containers[key], 'large', 0);
            }

            const totalCompleted =
                _.get(collector, 'numberOfPickupsCompleted', 0) + _.get(collector, 'numberOfInhouseCompleted', 0);
            const totalInhouseCustomers = _.get(collector, 'numberOfInhouseCustomers', []).length;

            collector.totalContainers = totalContainers;
            collector.totalInhouseContainers = totalInhouseContainers;
            collector.totalCompleted = totalCompleted;
            collector.totalInhouseCustomers = totalInhouseCustomers;
            collector.totalCustomers += _.get(collector, 'totalInhouseCustomers', 0);
        });

        return tableCollectors;
    };

    const handleSubmit = () => {
        fetchQuickStats();
    };

    const invoicingWontLineUp =
        moment(startDate)
            .tz(process.env.REACT_APP_REGION_TIMEZONE)
            .day() !== 1 ||
        moment(endDate)
            .tz(process.env.REACT_APP_REGION_TIMEZONE)
            .day() !== 0;

    const generateConfigPanels = stats => {
        const cards = {};

        const showDepot = _.get(stats, 'panelItems.showDepot', false);
        const showInvoicing = _.get(stats, 'panelItems.showInvoicing', false);
        const showChecksAndBalances = _.get(stats, 'panelItems.showChecksAndBalances', false);
        const showDrivers = _.get(stats, 'panelItems.showDrivers', false);

        cards['A'] = [];
        cards['A'].push({
            title: (
                <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                    Customers{' '}
                    {quickStats && (
                        <Tooltip title="Download Users">
                            <div>
                                <Button
                                    color="primary"
                                    style={{ marginRight: theme.spacing.unit }}
                                    onClick={handleDownloadUsers}
                                >
                                    <Icon>get_app</Icon>
                                </Button>
                            </div>
                        </Tooltip>
                    )}
                </div>
            ),
            color: 'pink',
            icon: 'supervisor_account',
            data: quickStats ? getCustomerQuickstats() : []
        });

        if (showChecksAndBalances) {
            cards['A'].push({
                title: 'Checks and Balances',
                color: 'blueGrey',
                icon: <MDIcon path={mdiScaleBalance} size={0.85} color="white" />,
                data: quickStats ? getCheckAndBalanceStats() : []
            });
        }

        cards['B'] = [];
        cards['B'].push({
            title: (
                <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                    Processing{' '}
                    {quickStats && (
                        <Tooltip title="Download Pickups">
                            <div>
                                <Button
                                    color="primary"
                                    style={{ marginRight: theme.spacing.unit }}
                                    onClick={handleDownloadPickups}
                                >
                                    <Icon>get_app</Icon>
                                </Button>
                            </div>
                        </Tooltip>
                    )}
                </div>
            ),
            color: 'orange',
            icon: 'local_shipping',
            data: quickStats ? getProcessingQuickStats() : []
        });

        cards['B'].push({
            title: <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>Containers</div>,
            color: 'indigo',
            icon: <MDIcon path={mdiBottleSodaClassic} size={0.9} color="white" />,
            data: quickStats ? getContainersQuickStats() : []
        });

        const { stdStats, stdStatsTotal } = quickStats ? getSTDQuickStatsAndTotal() : { stdStats: [], total: 0 };

        cards['C'] = [];
        cards['C'].push({
            title: (
                <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                    {process.env.REACT_APP_BRAND_NAME} <span>{formatAsCurrency(stdStatsTotal)}</span>
                    {quickStats && (
                        <Tooltip title="Download Containers">
                            <div>
                                <Button
                                    color="primary"
                                    style={{ marginRight: theme.spacing.unit }}
                                    onClick={handleDownloadContainers}
                                >
                                    <Icon>get_app</Icon>
                                </Button>
                            </div>
                        </Tooltip>
                    )}
                </div>
            ),
            icon: 'attach_money',
            color: 'green',
            data: stdStats
        });

        if (showDrivers && pickupsEnabled) {
            const { driverStats, totalDriverCost } = quickStats
                ? getContractorQuickStats()
                : { driverStats: [], totalDriverCost: 0 };

            cards['C'].push({
                title: (
                    <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                        Transport <span style={{ color: 'red' }}>{formatAsCurrency(totalDriverCost)}</span>
                    </div>
                ),
                color: 'purple',
                icon: 'account_circle',
                data: driverStats
            });
        }

        if (showDepot || showInvoicing) {
            cards['D'] = [];
        }

        if (showDepot) {
            const { depotStats, depotTotal } = quickStats
                ? getDepotQuickStatsAndTotal()
                : { depotStats: [], depotTotal: 0 };

            cards['D'].push({
                title: (
                    <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                        Depot <span>{formatAsCurrency(depotTotal)}</span>
                    </div>
                ),
                color: 'teal',
                icon: 'store',
                data: depotStats
            });
        }

        if (showInvoicing) {
            cards['D'].push({
                title: 'Invoicing',
                color: 'blue',
                icon: 'request_quote',
                data: quickStats ? getInvoicingStats() : []
            });
        }

        setCardConfigs(cards);
    };

    useEffect(() => {
        if (!quickStats) return;

        generateConfigPanels(quickStats);
    }, [quickStats]);

    let visiblePanels = 0;
    if (cardConfigs) {
        for (let key of Object.keys(cardConfigs)) {
            if (cardConfigs[key].length > 0) visiblePanels += 1;
        }
    }

    const selectableDrivers = useMemo(() => {
        return _.filter(allDrivers, driver => transportersSelected.includes(_.get(driver, 'collector._id')));
    }, [allDrivers, transportersSelected]);

    return (
        <div style={{ padding: theme.spacing.unit * 2 }}>
            <Grid container spacing={theme.spacing.unit * 2}>
                <Grid item xs={12}>
                    <Paper
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            padding: theme.spacing.unit * 2,
                            height: '100%',
                            flexWrap: 'wrap'
                        }}
                    >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <DateRangePicker
                                timezone={timezone}
                                startDate={startDate}
                                endDate={endDate}
                                window={dateWindow}
                                disabled={loading}
                                handlePrevious={handleGoBackwards}
                                handleNext={handleGoForwards}
                                handleChangeStartDate={handleChangeStartDate}
                                handleChangeEndDate={handleChangeEndDate}
                            />
                            {invoicingWontLineUp && (
                                <>
                                    <Icon style={{ color: colors.orange[500], marginRight: theme.spacing.unit }}>
                                        warning_amber
                                    </Icon>
                                    <Typography style={{ color: colors.orange[500] }}>
                                        Invoicing won't line up with other data
                                    </Typography>
                                </>
                            )}
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexWrap: 'wrap'
                            }}
                        >
                            <FormControl>
                                <Select
                                    disabled={loading}
                                    value={dateWindow}
                                    onChange={handleWindowSelect}
                                    style={{
                                        maxWidth: '250px',
                                        margin: theme.spacing.unit
                                    }}
                                >
                                    <MenuItem value="today">Daily</MenuItem>
                                    <MenuItem value="thisIsoWeek">Weekly</MenuItem>
                                    <MenuItem value="thisMonth">Monthly</MenuItem>
                                    <MenuItem value="thisQuarter">Quarterly</MenuItem>
                                    <MenuItem value="allTime">All Time</MenuItem>
                                </Select>
                            </FormControl>

                            {pickupsEnabled && (
                                <QuickSelect
                                    selectableValues={collectors
                                        .filter(c => _.get(c, 'configuration.enablePickups', true))
                                        .map(c => {
                                            return { name: _.get(c, 'name', ''), value: _.get(c, '_id'), obj: c };
                                        })}
                                    quickSelectId="transporters"
                                    multiple
                                    disabled={loading}
                                    onChange={handleTransportersSelected}
                                    style={{
                                        maxWidth: '250px',
                                        margin: theme.spacing.unit
                                    }}
                                    value={transportersSelected}
                                    renderValue={s => `${s.length} Transporters`}
                                    data-cy="dashboard-transporter-select"
                                />
                            )}

                            <QuickSelect
                                multiple
                                value={collectorsSelected}
                                selectableValues={collectors
                                    .filter(c => _.get(c, 'configuration.enableCounting', true))
                                    .map(c => {
                                        return { name: _.get(c, 'name', ''), value: _.get(c, '_id'), obj: c };
                                    })}
                                renderValue={s => `${s.length} Processors`}
                                quickSelectId="processors"
                                onChange={handleCollectorsSelected}
                                disabled={loading}
                                style={{
                                    maxWidth: '250px',
                                    margin: theme.spacing.unit
                                }}
                            />
                            {pickupsEnabled && (
                                <QuickSelect
                                    disabled={loading}
                                    renderValue={val => `${val.length} Driver${val.length !== 1 ? 's' : ''}`}
                                    value={driversSelected}
                                    onChange={drivers => setDriversSelected(drivers)}
                                    style={{
                                        maxWidth: '250px',
                                        margin: theme.spacing.unit
                                    }}
                                    selectableValues={selectableDrivers.map(driver => {
                                        return {
                                            value: driver._id,
                                            name: _user.getNameFull(driver).toLowerCase(),
                                            obj: driver
                                        };
                                    })}
                                    customSort={values =>
                                        _.orderBy(
                                            values,
                                            [({ obj }) => !obj.banned, ({ obj }) => obj.name.first.toLowerCase()],
                                            ['desc', 'asc']
                                        )
                                    }
                                    renderMenuItem={({ name, value, obj, selected }) => (
                                        <MenuItem
                                            value={value}
                                            key={value}
                                            style={{
                                                textTransform: 'capitalize'
                                            }}
                                        >
                                            {name}
                                            {_.get(obj, 'banned') && (
                                                <span
                                                    style={{
                                                        marginLeft: theme.spacing.unit,
                                                        display: 'grid',
                                                        placeItems: 'center'
                                                    }}
                                                >
                                                    <MDIcon
                                                        path={mdiAccountOff}
                                                        size={0.85}
                                                        color={theme.palette.text.secondary}
                                                    />
                                                </span>
                                            )}
                                        </MenuItem>
                                    )}
                                    quickSelectId="drivers"
                                />
                            )}

                            <Button
                                color="primary"
                                disabled={loading || _.isEmpty(collectorsSelected)}
                                variant="contained"
                                data-cy="dashboard-search-button"
                                type="submit"
                                onClick={handleSubmit}
                            >
                                <Icon>search</Icon>
                            </Button>
                        </div>
                    </Paper>
                    {loading && <LinearProgress />}
                </Grid>

                {_.isEmpty(cardConfigs) && (
                    <div
                        style={{
                            margin: theme.spacing.unit,
                            width: '100%',
                            height: '50vh',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column'
                        }}
                    >
                        <Icon style={{ fontSize: '20vh', color: 'gray' }}>coffee</Icon>
                        <Typography variant="h6" style={{ color: 'gray' }}>
                            Your dashboard is empty
                        </Typography>
                        <Typography style={{ color: 'gray' }}>Everything is calm... for now</Typography>
                    </div>
                )}

                {!_.isEmpty(cardConfigs['A']) && (
                    <MultiIconCard cardConfigs={cardConfigs['A']} width={12 / visiblePanels} />
                )}
                {!_.isEmpty(cardConfigs['B']) && (
                    <MultiIconCard cardConfigs={cardConfigs['B']} width={12 / visiblePanels} />
                )}
                {!_.isEmpty(cardConfigs['C']) && (
                    <MultiIconCard cardConfigs={cardConfigs['C']} width={12 / visiblePanels} />
                )}
                {!_.isEmpty(cardConfigs['D']) && (
                    <MultiIconCard cardConfigs={cardConfigs['D']} width={12 / visiblePanels} />
                )}
                {!_.isEmpty(quickStats) && (
                    <Grid item xs={12}>
                        <BaseTable
                            loading={loading}
                            headers={collectorTableHeaders}
                            tableTitle="Collectors"
                            controlLabel="Hide inactive collectors"
                            controlChanged={handleHideInactiveCollectors}
                            controlChecked={hideInactiveCollectors}
                            data={
                                hideInactiveCollectors
                                    ? collectorTableData(quickStats ? quickStats.collectorsData : []).filter(
                                          c => _.get(c, 'revenue.totalDeposits', 0) !== 0
                                      )
                                    : collectorTableData(quickStats ? quickStats.collectorsData : [])
                            }
                            handleClothingDialog={handleClothingDialog}
                            handleViewCollector={handleViewCollector}
                            defaultPage={0}
                            defaultRowsPerPage={5}
                            cellStyle={cellStyle}
                            formatAsCurrency={formatAsCurrency}
                            defaultOrder="desc"
                            defaultOrderBy="revenue.totalDeposits"
                        />
                    </Grid>
                )}
            </Grid>

            <Dialog open={Boolean(collectorSelected)} fullWidth onClose={handleClothingDialog()}>
                <DialogTitle>Flag clothing as collected?</DialogTitle>
                <DialogContent>
                    {collectorSelected && (
                        <DialogContentText>
                            Bottle depot:{' '}
                            <span style={{ color: theme.palette.text.primary }}>{collectorSelected.name}</span>
                            <br />
                            Time interval:{' '}
                            <span style={{ color: theme.palette.text.primary }}>
                                {moment(startDate).format('ddd, MMM Do')}
                            </span>{' '}
                            to{' '}
                            <span style={{ color: theme.palette.text.primary }}>
                                {moment(endDate).format('ddd, MMM Do')}
                            </span>
                            <br />
                            Clothing to be accepted:{' '}
                            <span style={{ color: blue[500] }}>
                                {collectorSelected.numberOfOutstandingClothingBags} bags
                            </span>
                        </DialogContentText>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={handleClothingDialog(false)}>
                        No
                    </Button>
                    <Button color="primary" onClick={handleCloseOutstandingClothing}>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default withTheme()(Dashboard);
