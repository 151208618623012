import React, { useContext, useState } from 'react';

import _ from 'lodash';

import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from '@material-ui/core/Checkbox';
import { withTheme } from '@material-ui/core/styles';
import SnackbarContext from 'components/CustomSnackbar/SnackbarContext';

import { FormControlLabel, Switch, colors } from '@material-ui/core';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc, locDate } from '../../localizations/localizationHandler';
import HttpContext from 'utils/contexts/HttpContext';

import { validate } from 'utils/validate';
import PasswordInput from '../InputComponents/PasswordInput';

import { isEXPRegion } from 'utils/misc.js';

function ChangePasswordWidget(props) {
    const {
        user,
        biometricsAvailable,
        biometricsOptIn,
        biometricsHasCredentials,
        onCancel,
        onBiometricsOptInChange,
        theme
    } = props;

    const [passwordCurrent, setPasswordCurrent] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [savingPassword, setSavingPassword] = useState(false);

    const { lang } = useContext(LocalizationContext);
    const http = useContext(HttpContext);
    const onSnackbar = useContext(SnackbarContext);

    const formControlStyle = {
        marginTop: theme.spacing.unit
    };

    const handlePasswordsChange = e => {
        const passwordField = e.target.name;
        const passwordChange = e.target.value;

        switch (passwordField) {
            case 'passwordCurrent':
                setPasswordCurrent(passwordChange);
                break;
            case 'password':
                setPassword(passwordChange);
                break;
            case 'passwordConfirmation':
                setPasswordConfirmation(passwordChange);
                break;
            default:
        }
    };

    const handleChangePasswordSubmit = async () => {
        setSavingPassword(true);

        const res = await http.post(`/users/${user._id}/changePassword`, {
            passwordCurrent,
            password
        });

        if (res.ok) {
            onSnackbar(loc('changedPassword', lang));
            setPasswordCurrent('');
            setPassword('');
            setPasswordConfirmation('');
        } else {
            onSnackbar(loc('changePasswordFailed', lang), 'error');
        }

        setSavingPassword(false);
    };

    const passwordsMatch = password === passwordConfirmation;
    const passwordErr = validate(['password'], password, lang);

    return (
        <>
            <Typography variant="h6">{loc('password', lang)}</Typography>
            <Divider style={{ marginTop: theme.spacing.unit / 2, marginBottom: theme.spacing.unit }} />
            <FormControl fullWidth style={formControlStyle}>
                <PasswordInput
                    data-cy="profile-current-password"
                    name="passwordCurrent"
                    label={loc('accountSettings3', lang)}
                    type="password"
                    value={passwordCurrent}
                    helperText={''}
                    disabled={!_.isNil(user.oAuth.provider)}
                    onChange={handlePasswordsChange}
                    error={false}
                />
            </FormControl>
            <FormControl fullWidth style={formControlStyle}>
                <PasswordInput
                    showValidationRules
                    data-cy="profile-new-password"
                    name="password"
                    label={loc('accountSettings4', lang)}
                    type="password"
                    value={password}
                    helperText={passwordErr.description}
                    disabled={!_.isNil(user.oAuth.provider)}
                    onChange={handlePasswordsChange}
                    error={passwordErr.fail && !_.isEmpty(password)}
                />
            </FormControl>
            <FormControl fullWidth style={formControlStyle}>
                <PasswordInput
                    data-cy="profile-new-password-confirmation"
                    name="passwordConfirmation"
                    label={loc('accountSettings5', lang)}
                    type="password"
                    value={passwordConfirmation}
                    helperText={''}
                    disabled={!_.isNil(user.oAuth.provider)}
                    onChange={handlePasswordsChange}
                    error={!passwordsMatch}
                />
            </FormControl>
            {biometricsAvailable && (
                <FormControl fullWidth style={{ marginTop: theme.spacing.unit }}>
                    <FormControlLabel
                        control={
                            <Switch
                                color="primary"
                                onChange={() => onBiometricsOptInChange(!biometricsOptIn)}
                                value={biometricsOptIn}
                                checked={biometricsOptIn}
                                disabled={biometricsHasCredentials}
                            />
                        }
                        label={loc('biometrics1', lang)}
                    />
                </FormControl>
            )}
            <Typography style={{ color: colors.orange[700] }}>
                {loc(biometricsAvailable ? 'biometrics9' : 'biometrics8', lang)}
            </Typography>
            <div style={{ marginTop: theme.spacing.unit * 3, float: 'right' }}>
                <Button color="primary" disabled={savingPassword} onClick={onCancel}>
                    {loc('cancel', lang)}
                </Button>
                <Button
                    data-cy="profile-change-password"
                    variant="contained"
                    color="primary"
                    disabled={
                        !passwordsMatch || password.length === 0 || savingPassword || user.deleted || passwordErr.fail
                    }
                    style={{ marginLeft: theme.spacing.unit * 2 }}
                    onClick={handleChangePasswordSubmit}
                >
                    {loc('change', lang)}{' '}
                    <CircularProgress
                        size={22}
                        thickness={4.8}
                        style={{
                            display: savingPassword ? '' : 'none',
                            marginLeft: 4,
                            color: 'white',
                            verticalAlign: 'bottom'
                        }}
                    />
                </Button>
            </div>
            <div style={{ clear: 'both' }} />
        </>
    );
}

export default withTheme()(ChangePasswordWidget);
