import React from 'react';
import _ from 'lodash';

import { ContentState, EditorState, RichUtils, convertFromRaw, convertToRaw } from 'draft-js';
import draftjsToHtml from 'draftjs-to-html';
import { withTheme } from '@material-ui/core/styles';
import { Editor } from 'react-draft-wysiwyg';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import DOMPurify from '../../../node_modules/dompurify';
import draftToHtml from 'draftjs-to-html';

const basicStyle = {
    border: 'solid 1px rgba(0, 0, 0, 0.23)',
    borderRadius: 4,
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    color: 'rgba(0, 0, 0, 0.87)'
};

// Prevents a security/performance vulnerability.
// ref: https://developer.chrome.com/docs/lighthouse/best-practices/external-anchors-use-rel-noopener/
DOMPurify.addHook('afterSanitizeAttributes', function(node) {
    // set all elements owning target and target=_blank to contain rel attribute as well
    if ('target' in node && node.getAttribute('target') === '_blank') {
        node.setAttribute('rel', 'noopener noreferrer');
    }
});

const RichTextEditor = props => {
    const { editorState, setEditorState } = props;
    return (
        <Editor
            onEditorStateChange={e => setEditorState(e)}
            toolbarStyle={{
                ...basicStyle,
                backgroundColor: `${props.theme.palette.background.default}`
            }}
            editorStyle={{
                ...basicStyle,
                padding: '0px 14px',
                height: 'inherit',
                color: `${props.theme.palette.text.primary}`
            }}
            wrapperStyle={{ width: '100%' }}
            toolbar={{
                options: ['inline', 'fontSize', 'list', 'textAlign', 'link', 'embedded', 'image', 'history'],
                inline: {
                    options: ['bold']
                },
                embedded: {
                    defaultSize: {
                        height: '400px',
                        width: '100%'
                    }
                }
            }}
            handleKeyCommand={command => handleDeletionIssue(command, editorState, setEditorState)}
            {...props}
        />
    );
};

/**
 * Bandage fix for GITLAB <a ahref="https://gitlab.com/tgayef/lt3-skipthedepot/-/issues/3059">#3059</a>.
 * Fix issue where react-draft rich-text missing `entityMap` & `blocks.data` objects. Pass in rich-text body to fix.
 * @param {*} body
 * @returns
 */
export function fixRichTextBody(body) {
    if (_.isNil(body)) return;

    if (_.isNil(body.entityMap)) {
        body.entityMap = {};
    }
    body.blocks.forEach(blk => {
        if (_.isNil(blk.data)) {
            blk.data = {};
        }
    });
}

/**
 * Bandage fix for GITLAB - https://github.com/jpuri/react-draft-wysiwyg/issues/987#issuecomment-766424943
 * Fixes issue where deleting a <p> before an image will cause  the editor to freeze
 * @param {*} command
 * @returns
 */
export function handleDeletionIssue(command, editorState, setEditorState) {
    const newState = RichUtils.handleKeyCommand(editorState, command);

    if (newState) {
        setEditorState(newState);
        return 'handled';
    }

    return 'not-handled';
}

export function initializeEditorState(bodyContents, setEditor) {
    if (_.isObject(bodyContents)) {
        fixRichTextBody(bodyContents);
        setEditor(EditorState.createWithContent(convertFromRaw(bodyContents)));
    } else {
        setEditor(EditorState.createWithContent(ContentState.createFromText(bodyContents)));
    }
}

export function getRichTextEditorContent(content) {
    if (_.isObject(content)) {
        fixRichTextBody(content);
    }

    return _.isObject(content) ? (
        <div
            dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                    draftjsToHtml(convertToRaw(convertFromRaw(content)), {}, false, ({ type, data }) =>
                        fixImageCenterAlign(type, data)
                    ),
                    {
                        ADD_TAGS: ['iframe'],
                        ADD_ATTR: ['allowfullscreen', 'frameborder', 'scrolling', 'target']
                    }
                )
            }}
        />
    ) : (
        content
    );
}

export function getRichTextEditorContentFromEditorState(editorState) {
    return (
        <div
            dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                    draftToHtml(convertToRaw(editorState.getCurrentContent()), {}, false, ({ type, data }) =>
                        fixImageCenterAlign(type, data)
                    ),
                    {
                        ADD_TAGS: ['iframe'],
                        ADD_ATTR: ['allowfullscreen', 'frameborder', 'scrolling', 'target']
                    }
                )
            }}
        />
    );
}

/**
 * Bandage fix for GITLAB - https://github.com/jpuri/draftjs-to-html/issues/28
 * Fixes issue where the image is not centered properly when dispalying
 * @returns
 */
export function fixImageCenterAlign(type, data) {
    if (type === 'IMAGE') {
        const alignment = data.alignment || 'none';
        const textAlign = alignment === 'none' ? 'center' : alignment;

        return `
            <p style="text-align:${textAlign};">
                <img src="${data.src}" alt="${data.alt}" style="height: ${data.height};width: ${data.width}"/>
            </p>
        `;
    }
}

export default withTheme()(RichTextEditor);
