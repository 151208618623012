import React, { useState, useContext } from 'react';

import _ from 'lodash';
import { deviceHelper } from 'utils/misc';

import { withTheme } from '@material-ui/core/styles';

import {
    Button,
    FormControl,
    Grid,
    Icon,
    IconButton,
    InputLabel,
    Menu,
    MenuItem,
    OutlinedInput,
    Paper,
    Select,
    TextField
} from '@material-ui/core';
import useTable from 'components/Table/useTable';

import { openWindow } from 'helpers/windowHelper';

import DashBoardTable from 'components/Table/DashBoardTable';
import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import ConfirmDialogContext from 'components/Dialogs/Confirm/ConfirmDialogContext';
import { useEffect } from 'react';

import GoogleContext from 'utils/contexts/GoogleContext';

import BottleDriveRows from './BottleDriveRows';
import SchedulePickupDialog from './SchedulePickupDialog';
import RejectBottleDriverDialog from './RejectBottleDriveDialog';
import moment from 'moment-timezone';

const pendingHeaders = [
    { name: 'Actions', key: null, padding: 'none' },
    { name: 'Bottle Drive Date^', key: 'date', padding: 'none' },
    { name: 'Start Time^', key: 'startTime', padding: 'none' },
    { name: 'End Time^', key: 'endTime', padding: 'none' },
    { name: 'Contact Name^', key: 'contactName', padding: 'none' },
    { name: 'Contact Email^', key: 'contactEmail', padding: 'none' },
    { name: 'Contact Phone', key: 'contactPhone', padding: 'none' },
    { name: 'Address^', key: 'location', padding: 'none' },
    { name: 'Comment/Directions', key: 'comment', padding: 'none' },
    { name: 'Referring Collector^', key: 'collector', padding: 'none' },
    { name: 'Charity^', key: 'charity', padding: 'none' }
];

const bookedHeaders = [
    { name: 'Actions', key: null, padding: 'none' },
    { name: 'Pickup Date^', key: 'date', padding: 'none' },
    { name: 'Processed Date^', key: 'processedDate', padding: 'none' },
    { name: 'Start Time^', key: 'startTime', padding: 'none' },
    { name: 'End Time^', key: 'endTime', padding: 'none' },
    { name: 'Contact Name^', key: 'contactName', padding: 'none' },
    { name: 'Contact Email^', key: 'contactEmail', padding: 'none' },
    { name: 'Contact Phone', key: 'contactPhone', padding: 'none' },
    { name: 'Address^', key: 'location', padding: 'none' },
    { name: 'Comment/Directions', key: 'comment', padding: 'none' },
    { name: 'Referring Collector^', key: 'collector', padding: 'none' },
    { name: 'Charity^', key: 'charity', padding: 'none' }
];

const completedHeaders = [
    { name: 'Actions', key: null, padding: 'none' },
    { name: 'Bottle Drive Date^', key: 'date', padding: 'none' },
    { name: 'Processed Date^', key: 'processedDate', padding: 'none' },
    { name: 'Start Time^', key: 'startTime', padding: 'none' },
    { name: 'End Time^', key: 'endTime', padding: 'none' },
    { name: 'Contact Name^', key: 'contactName', padding: 'none' },
    { name: 'Contact Email^', key: 'contactEmail', padding: 'none' },
    { name: 'Contact Phone', key: 'contactPhone', padding: 'none' },
    { name: 'Address^', key: 'location', padding: 'none' },
    { name: 'Comment/Directions', key: 'comment', padding: 'none' },
    { name: 'Referring Collector^', key: 'collector', padding: 'none' },
    { name: 'Charity^', key: 'charity', padding: 'none' }
];

const rejectedHeaders = [
    { name: 'Actions', key: null, padding: 'none' },
    { name: 'Reject Reason', key: 'rejectReason', padding: 'none' },
    { name: 'Bottle Drive Date^', key: 'date', padding: 'none' },
    { name: 'Processed Date^', key: 'processedDate', padding: 'none' },
    { name: 'Start Time^', key: 'startTime', padding: 'none' },
    { name: 'End Time^', key: 'endTime', padding: 'none' },
    { name: 'Contact Name^', key: 'contactName', padding: 'none' },
    { name: 'Contact Email^', key: 'contactEmail', padding: 'none' },
    { name: 'Contact Phone', key: 'contactPhone', padding: 'none' },
    { name: 'Address^', key: 'location', padding: 'none' },
    { name: 'Comment/Directions', key: 'comment', padding: 'none' },
    { name: 'Referring Collector^', key: 'collector', padding: 'none' },
    { name: 'Charity^', key: 'charity', padding: 'none' }
];

// Contains all possible headers
const headerFilters = [
    { name: 'Actions', key: null, padding: 'none' },
    { name: 'Reject Reason', key: 'rejectReason', padding: 'none' },
    { name: 'Pickup Date^', key: 'date', padding: 'none' },
    { name: 'Bottle Drive Date^', key: 'date', padding: 'none' },
    { name: 'Processed Date^', key: 'processedDate', padding: 'none' },
    { name: 'Start Time^', key: 'startTime', padding: 'none' },
    { name: 'End Time^', key: 'endTime', padding: 'none' },
    { name: 'Contact Name^', key: 'contactName', padding: 'none' },
    { name: 'Contact Email^', key: 'contactEmail', padding: 'none' },
    { name: 'Contact Phone', key: 'contactPhone', padding: 'none' },
    { name: 'Address^', key: 'location', padding: 'none' },
    { name: 'Comment/Directions', key: 'comment', padding: 'none' },
    { name: 'Referring Collector^', key: 'collector', padding: 'none' },
    { name: 'Charity^', key: 'charity', padding: 'none' }
];

const BottleDrives = ({ theme, http, onSnackbar, history, operator, auth, setRefreshPendingBottleDriveCount }) => {
    const collapseSearch = useMediaQuery(theme.breakpoints.down('sm'));
    const { lang } = useContext(LocalizationContext);
    const warnAction = useContext(ConfirmDialogContext);
    const [filtersOpen, setFiltersOpen] = useState(null);
    const [reviewedFiltersOpen, setReviewedFiltersOpen] = useState(null);

    const [checkedPendingBottleDrives, setCheckedPendingBottleDrives] = useState([]);
    const [checkedRejectedBottleDrives, setCheckedRejectedBottleDrives] = useState([]);

    // Data for schedule
    const [allCharities, setAllCharities] = useState([]);
    const [allProcessors, setAllProcessors] = useState([]);
    const [allTransporters, setAllTransporters] = useState([]);
    const [allDrivers, setAllDrivers] = useState([]);

    const [selectedBottleDrive, setSelectedBottleDrive] = useState({});
    const [selectedBottleDriveId, setSelectedBottleDriveId] = useState('');

    const [showSchedulePickupDialog, setShowSchedulePickupDialog] = useState(false);
    const [showRejectReasonDialog, setShowRejectReasonDialog] = useState(false);
    const [bottleDriveIdsToReject, setBottleDriveIdsToReject] = useState([]);
    const [rejectReason, setRejectReason] = useState('');

    const [todaysDate, setTodaysDate] = useState(moment(new Date()));

    const { google } = useContext(GoogleContext);

    //PENDING
    const {
        loading,
        getData,
        page,
        rowsPerPage,
        data,
        filteredData,
        search,
        filteredHeaders,
        totalDataEntries,
        handleChange,
        handleSearch,
        handleChangeFilterHeaders,
        handleAllFilterHeaders,
        handleChangePage,
        handleChangeRowsPerPage
    } = useTable({
        url: '/bottleDrives',
        key: 'bottleDrives',
        headers: pendingHeaders.map(h => h.name),
        queryObj: {
            pickup: { $exists: false },
            rejected: { $ne: true }
        }
    });

    //BOOKED
    const {
        loading: loading4,
        getData: getData4,
        page: page4,
        rowsPerPage: rowsPerPage4,
        data: data4,
        filteredData: filteredData4,
        search: search4,
        filteredHeaders: filteredHeaders4,
        totalDataEntries: totalDataEntries4,
        handleChange: handleChange4,
        handleSearch: handleSearch4,
        handleChangeFilterHeaders: handleChangeFilterHeaders4,
        handleAllFilterHeaders: handleAllFilterHeaders4,
        handleChangePage: handleChangePage4,
        handleChangeRowsPerPage: handleChangeRowsPerPage4
    } = useTable({
        url: '/bottleDrives',
        key: 'bottleDrives',
        headers: bookedHeaders.map(h => h.name),
        queryObj: {
            pickup: { $exists: true },
            'pickup.complete': { $ne: true },
            'pickup.date': { $gt: todaysDate },
            rejected: { $ne: true }
        }
    });

    //COMPLETED
    const {
        loading: loading3,
        getData: getData3,
        page: page3,
        rowsPerPage: rowsPerPage3,
        data: data3,
        filteredData: filteredData3,
        search: search3,
        filteredHeaders: filteredHeaders3,
        totalDataEntries: totalDataEntries3,
        handleChange: handleChange3,
        handleSearch: handleSearch3,
        handleChangeFilterHeaders: handleChangeFilterHeaders3,
        handleAllFilterHeaders: handleAllFilterHeaders3,
        handleChangePage: handleChangePage3,
        handleChangeRowsPerPage: handleChangeRowsPerPage3
    } = useTable({
        url: '/bottleDrives',
        key: 'bottleDrives',
        headers: completedHeaders.map(h => h.name),
        queryObj: {
            rejected: { $ne: true },
            'pickup.complete': true
        }
    });

    //REJECTED
    const {
        loading: loading2,
        getData: getData2,
        page: page2,
        rowsPerPage: rowsPerPage2,
        data: data2,
        filteredData: filteredData2,
        search: search2,
        filteredHeaders: filteredHeaders2,
        totalDataEntries: totalDataEntries2,
        handleChange: handleChange2,
        handleSearch: handleSearch2,
        handleChangeFilterHeaders: handleChangeFilterHeaders2,
        handleAllFilterHeaders: handleAllFilterHeaders2,
        handleChangePage: handleChangePage2,
        handleChangeRowsPerPage: handleChangeRowsPerPage2
    } = useTable({
        url: '/bottleDrives',
        key: 'bottleDrives',
        headers: rejectedHeaders.map(h => h.name),
        queryObj: {
            rejected: true
        }
    });

    const fetchData = async () => {
        const [resCharities, resCollectors, resDrivers] = await Promise.all([
            http.getJSON('/allCharities', false, true),
            http.getJSON('/collectors', false, true),
            http.getJSON('/users/getAllDrivers', false, true)
        ]);
        if (resCharities.ok) {
            setAllCharities(_.get(resCharities, 'data.charities', []));
        }
        if (resCollectors.ok) {
            const allCollectors = _.get(resCollectors, 'data.collectors', []);
            setAllProcessors(
                _.filter(allCollectors, c => _.get(c, 'configuration.enableCounting', true) && !c.disabled)
            );
            setAllTransporters(
                _.filter(allCollectors, c => _.get(c, 'configuration.enablePickups', true) && !c.disabled)
            );
        }
        if (resDrivers.ok) {
            setAllDrivers(_.get(resDrivers, 'data.drivers', []));
        }
    };

    useEffect(() => {
        let newSelectedBottleDrive = _.find(filteredData, { _id: selectedBottleDriveId });
        if (_.isNil(newSelectedBottleDrive)) {
            newSelectedBottleDrive = _.find(filteredData2, { _id: selectedBottleDriveId });
        }
        if (!_.isNil(newSelectedBottleDrive)) {
            setSelectedBottleDrive(newSelectedBottleDrive);
        }
    }, [filteredData, filteredData2, filteredData3, filteredData4]);

    useEffect(() => {
        fetchData();
    }, []);

    const handleView = _id => {
        const URL = `/customers/${_id}`;
        openWindow(history, !deviceHelper.isNativeApp(), URL);
    };

    const handleFiltersOpen = close => e => {
        if (close || Boolean(filtersOpen)) {
            setFiltersOpen(null);
        } else {
            setFiltersOpen(e.currentTarget);
        }
    };
    const handleSubmit = e => {
        handleSearch(e);
        handleSearch2(e);
        handleSearch3(e);
        handleSearch4(e);
        if (filtersOpen) {
            setFiltersOpen(false);
        }
        if (reviewedFiltersOpen) {
            setReviewedFiltersOpen(false);
        }
    };

    const handleSchedulePickupDialog = bottleDrive => {
        setSelectedBottleDrive(bottleDrive);
        setShowSchedulePickupDialog(true);
    };

    const handleReject = async () => {
        const res = await http.post('/bottleDrives/reject', {
            bottleDriveIds: bottleDriveIdsToReject,
            rejectReason
        });
        if (res.ok) {
            setTimeout(() => {
                getData();
                getData2();
                getData3();
                getData4();
            }, 50);
            onSnackbar('Bottle drive(s) rejected.');
            setCheckedPendingBottleDrives([]);
            setBottleDriveIdsToReject([]);
            setShowRejectReasonDialog(false);
            setRejectReason('');
            setRefreshPendingBottleDriveCount(true);
        } else {
            onSnackbar('Unable to reject bottle drive(s)', 'error');
        }
    };

    const handleRejectDialog = bottleDriveIds => {
        setBottleDriveIdsToReject(bottleDriveIds);
        setShowRejectReasonDialog(true);
    };

    const handleUnreject = async bottleDriveIds => {
        const res = await http.post('/bottleDrives/unreject', { bottleDriveIds });
        if (res.ok) {
            setTimeout(() => {
                getData();
                getData2();
                getData3();
                getData4();
            }, 50);
            onSnackbar('Bottle drive(s) unrejected.');
            setCheckedRejectedBottleDrives([]);
            setRefreshPendingBottleDriveCount(true);
        } else {
            onSnackbar('Unable to unreject bottle drive(s)', 'error');
        }
    };

    const onChangeFilterHeader = e => {
        handleChangeFilterHeaders4(e);
        handleChangeFilterHeaders3(e);
        handleChangeFilterHeaders2(e);
        handleChangeFilterHeaders(e);
    };

    const onChangeAllFilterHeaders = all => e => {
        handleAllFilterHeaders(all)(e);
        handleAllFilterHeaders2(all)(e);
        handleAllFilterHeaders3(all)(e);
        handleAllFilterHeaders4(all)(e);
    };

    const handleChangeAll = e => {
        handleChange(e);
        handleChange2(e);
        handleChange3(e);
        handleChange4(e);
    };

    function handlePendingCheckbox() {
        if (
            checkedPendingBottleDrives.length === totalDataEntries ||
            checkedPendingBottleDrives.length === rowsPerPage
        ) {
            setCheckedPendingBottleDrives([]);
        } else {
            let newCheckedPendingBottleDrives = Array.from(filteredData, bottleDrive => {
                return bottleDrive._id;
            });
            setCheckedPendingBottleDrives(newCheckedPendingBottleDrives);
        }
    }

    function handleRejectedCheckbox() {
        if (
            checkedRejectedBottleDrives.length === totalDataEntries2 ||
            checkedRejectedBottleDrives.length === rowsPerPage
        ) {
            setCheckedRejectedBottleDrives([]);
        } else {
            let newcheckedRejectedBottleDrives = Array.from(filteredData2, bottleDrive => {
                return bottleDrive._id;
            });
            setCheckedRejectedBottleDrives(newcheckedRejectedBottleDrives);
        }
    }

    const inputStyles = {
        margin: theme.spacing.unit / 2
    };

    return (
        <>
            <SchedulePickupDialog
                open={showSchedulePickupDialog}
                history={history}
                lang={lang}
                theme={theme}
                selectedBottleDrive={selectedBottleDrive}
                onClose={() => {
                    setShowSchedulePickupDialog(false);
                }}
                google={google}
                http={http}
                onSnackbar={onSnackbar}
                charities={allCharities}
                processors={allProcessors}
                transporters={allTransporters}
                receivers={allDrivers}
                handleView={handleView}
                afterSubmitSuccess={() => {
                    setTimeout(() => {
                        getData();
                        getData2();
                        getData3();
                        getData4();
                    }, 50);
                    setShowSchedulePickupDialog(false);
                    setSelectedBottleDrive(null);
                    onSnackbar('Successfully scheduled bottle drive pickup');
                    setRefreshPendingBottleDriveCount(true);
                }}
            />

            <RejectBottleDriverDialog
                showRejectReasonDialog={showRejectReasonDialog}
                bottleDriveIdsToReject={bottleDriveIdsToReject}
                rejectReason={rejectReason}
                setRejectReason={setRejectReason}
                theme={theme}
                lang={lang}
                setShowRejectReasonDialog={setShowRejectReasonDialog}
                handleReject={handleReject}
            />

            <Grid container>
                <Grid item xs={12}>
                    <div style={{ padding: theme.spacing.unit * 2, paddingBottom: theme.spacing.unit }}>
                        <Paper
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                padding: theme.spacing.unit * 2,
                                height: '100%',
                                flexWrap: 'wrap'
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexWrap: 'wrap'
                                }}
                            >
                                <form onSubmit={handleSubmit} style={{ flex: '0 1 100%' }}>
                                    <RenderSearch
                                        filtersOpen={filtersOpen}
                                        handleFiltersOpen={handleFiltersOpen}
                                        collapseSearch={collapseSearch}
                                        theme={theme}
                                    >
                                        <div style={{ flexDirection: 'row', display: 'flex', 'flex-wrap': 'wrap' }}>
                                            <FormControl style={inputStyles}>
                                                <InputLabel htmlFor="collection" variant="outlined">
                                                    Column Filters
                                                </InputLabel>
                                                <Select
                                                    variant="outlined"
                                                    multiple
                                                    value={filteredHeaders2}
                                                    onChange={onChangeFilterHeader}
                                                    style={{ width: 250 }}
                                                    input={<OutlinedInput labelWidth={140} />}
                                                >
                                                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                        <Button onClick={onChangeAllFilterHeaders(true)}>All</Button>
                                                        <Button onClick={onChangeAllFilterHeaders(false)}>None</Button>
                                                    </div>
                                                    {headerFilters.map(({ name }) => (
                                                        <MenuItem key={name} value={name}>
                                                            {_.startCase(name)}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            <>
                                                <TextField
                                                    variant="outlined"
                                                    name="search"
                                                    value={search2 || ''}
                                                    onChange={handleChangeAll}
                                                    placeholder="Search"
                                                    style={{
                                                        ...inputStyles,
                                                        width: 250
                                                    }}
                                                />

                                                <Button
                                                    color="primary"
                                                    disabled={loading || loading2 || loading3}
                                                    variant="contained"
                                                    data-cy="dashboard-search-button"
                                                    type="submit"
                                                    onClick={handleSubmit}
                                                >
                                                    <Icon>search</Icon>
                                                </Button>
                                            </>
                                        </div>
                                    </RenderSearch>

                                    {collapseSearch && (
                                        <div style={{ textAlign: 'right' }}>
                                            <IconButton onClick={handleFiltersOpen()} data-cy="bottleDrive-filters">
                                                <Icon>filter_list</Icon>
                                            </IconButton>
                                        </div>
                                    )}
                                </form>
                            </div>
                        </Paper>
                    </div>
                </Grid>
            </Grid>
            <>
                <DashBoardTable
                    title="Pending Bottle Drives"
                    loading={loading}
                    headers={pendingHeaders}
                    filteredHeaders={_.intersection(
                        filteredHeaders,
                        pendingHeaders.map(h => h.name)
                    )}
                    length={totalDataEntries}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    handleChangePage={(e, newPage) => {
                        handleChangePage(e, newPage);
                        setCheckedPendingBottleDrives([]);
                    }}
                    handleChangeRowsPerPage={e => {
                        handleChangeRowsPerPage(e);
                        setCheckedPendingBottleDrives([]);
                    }}
                    refresh={getData}
                    checkbox
                    handleCheckbox={handlePendingCheckbox}
                    checkedItems={checkedPendingBottleDrives.length}
                    cellStyle={{ paddingLeft: 12, paddingRight: 12 }}
                    customActionAlign="left"
                    customAction={
                        <Button
                            data-cy="reject"
                            variant="contained"
                            color="secondary"
                            disabled={checkedPendingBottleDrives.length === 0}
                            style={{}}
                            onClick={e => {
                                e.preventDefault();
                                handleRejectDialog(checkedPendingBottleDrives);
                            }}
                        >
                            Reject
                        </Button>
                    }
                    customActionStyle={{ paddingTop: 0 }}
                    defaultSort={{ column: 'date', dir: 'desc' }}
                >
                    {filteredData &&
                        filteredData.map((bottleDrive, index) => {
                            return (
                                <BottleDriveRows
                                    bottleDrive={bottleDrive}
                                    filteredHeaders={_.intersection(
                                        filteredHeaders,
                                        pendingHeaders.map(h => h.name)
                                    )}
                                    checkedList={checkedPendingBottleDrives}
                                    setCheckedList={setCheckedPendingBottleDrives}
                                    handleView={handleView}
                                    theme={theme}
                                    handleReject={handleRejectDialog}
                                    handleUnreject={handleUnreject}
                                    onSchedulePickup={handleSchedulePickupDialog}
                                    index={index}
                                />
                            );
                        })}
                </DashBoardTable>

                <DashBoardTable
                    title="Booked Bottle Drives"
                    loading={loading4}
                    headers={bookedHeaders}
                    filteredHeaders={_.intersection(
                        filteredHeaders4,
                        bookedHeaders.map(h => h.name)
                    )}
                    length={totalDataEntries4}
                    page={page4}
                    rowsPerPage={rowsPerPage4}
                    handleChangePage={(e, newPage) => {
                        handleChangePage4(e, newPage);
                        setCheckedRejectedBottleDrives([]);
                    }}
                    handleChangeRowsPerPage={e => {
                        handleChangeRowsPerPage4(e);
                        setCheckedRejectedBottleDrives([]);
                    }}
                    refresh={getData4}
                    cellStyle={{ paddingLeft: 12, paddingRight: 12 }}
                    defaultSort={{ column: 'date', dir: 'desc' }}
                >
                    {filteredData4 &&
                        filteredData4.map((bottleDrive, index) => {
                            return (
                                <BottleDriveRows
                                    bottleDrive={bottleDrive}
                                    filteredHeaders={_.intersection(
                                        filteredHeaders4,
                                        bookedHeaders.map(h => h.name)
                                    )}
                                    checkedList={[]}
                                    setCheckedList={() => {}}
                                    handleView={handleView}
                                    theme={theme}
                                    handleReject={handleRejectDialog}
                                    handleUnreject={handleUnreject}
                                    onSchedulePickup={handleSchedulePickupDialog}
                                    checkbox={false}
                                    index={index}
                                />
                            );
                        })}
                </DashBoardTable>

                <DashBoardTable
                    title="Completed Bottle Drives"
                    loading={loading3}
                    headers={completedHeaders}
                    filteredHeaders={_.intersection(
                        filteredHeaders3,
                        completedHeaders.map(h => h.name)
                    )}
                    length={totalDataEntries3}
                    page={page3}
                    rowsPerPage={rowsPerPage3}
                    handleChangePage={(e, newPage) => {
                        handleChangePage3(e, newPage);
                        setCheckedRejectedBottleDrives([]);
                    }}
                    handleChangeRowsPerPage={e => {
                        handleChangeRowsPerPage3(e);
                        setCheckedRejectedBottleDrives([]);
                    }}
                    refresh={getData3}
                    cellStyle={{ paddingLeft: 12, paddingRight: 12 }}
                    defaultSort={{ column: 'date', dir: 'asc' }}
                >
                    {filteredData3 &&
                        filteredData3.map((bottleDrive, index) => {
                            return (
                                <BottleDriveRows
                                    bottleDrive={bottleDrive}
                                    filteredHeaders={_.intersection(
                                        filteredHeaders3,
                                        completedHeaders.map(h => h.name)
                                    )}
                                    checkedList={[]}
                                    setCheckedList={() => {}}
                                    handleView={handleView}
                                    theme={theme}
                                    handleReject={handleRejectDialog}
                                    handleUnreject={handleUnreject}
                                    onSchedulePickup={handleSchedulePickupDialog}
                                    checkbox={false}
                                    index={index}
                                    completeBottleDriveTable
                                />
                            );
                        })}
                </DashBoardTable>

                <DashBoardTable
                    title="Rejected Bottle Drives"
                    loading={loading2}
                    headers={rejectedHeaders}
                    filteredHeaders={filteredHeaders2}
                    length={totalDataEntries2}
                    page={page2}
                    rowsPerPage={rowsPerPage2}
                    handleChangePage={(e, newPage) => {
                        handleChangePage2(e, newPage);
                        setCheckedRejectedBottleDrives([]);
                    }}
                    handleChangeRowsPerPage={e => {
                        handleChangeRowsPerPage2(e);
                        setCheckedRejectedBottleDrives([]);
                    }}
                    refresh={getData2}
                    checkbox
                    handleCheckbox={handleRejectedCheckbox}
                    checkedItems={checkedRejectedBottleDrives.length}
                    cellStyle={{ paddingLeft: 12, paddingRight: 12 }}
                    customActionAlign="left"
                    customActionStyle={{ paddingTop: 0 }}
                    customAction={
                        <Button
                            data-cy="unreject"
                            variant="contained"
                            color="secondary"
                            disabled={checkedRejectedBottleDrives.length === 0}
                            style={{}}
                            onClick={() => {
                                warnAction(() => {
                                    handleUnreject(checkedRejectedBottleDrives);
                                }, `Unreject ${checkedRejectedBottleDrives.length} bottle drive(s)?`);
                            }}
                        >
                            Unreject
                        </Button>
                    }
                    defaultSort={{ column: 'processedDate', dir: 'asc' }}
                >
                    {filteredData2 &&
                        filteredData2.map((bottleDrive, index) => {
                            return (
                                <BottleDriveRows
                                    bottleDrive={bottleDrive}
                                    filteredHeaders={filteredHeaders2}
                                    checkedList={checkedRejectedBottleDrives}
                                    setCheckedList={setCheckedRejectedBottleDrives}
                                    handleView={handleView}
                                    theme={theme}
                                    handleReject={handleRejectDialog}
                                    handleUnreject={handleUnreject}
                                    onSchedulePickup={handleSchedulePickupDialog}
                                    index={index}
                                />
                            );
                        })}
                </DashBoardTable>
            </>
        </>
    );
};

export default withTheme()(BottleDrives);

const RenderSearch = ({ children, filtersOpen, handleFiltersOpen, collapseSearch, theme }) => {
    if (collapseSearch) {
        return (
            <Menu open={Boolean(filtersOpen)} anchorEl={filtersOpen} onClose={handleFiltersOpen(true)}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        padding: theme.spacing.unit * 2
                    }}
                >
                    {children}
                </div>
            </Menu>
        );
    } else {
        return (
            <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>{children}</div>
            </div>
        );
    }
};
