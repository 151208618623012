import React, { useContext, useMemo } from 'react';
import _ from 'lodash';
import { formatAsCurrency, getCustomerFirstNameAndLastInitial } from 'utils/misc';

import { _bulk, _rates } from 'std';

import ConfirmDialogContext from 'components/Dialogs/Confirm/ConfirmDialogContext';

import { Typography, Divider, Button, IconButton, colors, withTheme } from '@material-ui/core';

import { Icon as MDIcon } from '@mdi/react';
import { mdiClose } from '@mdi/js';

const CountsScreen = React.forwardRef((props, ref) => {
    const {
        theme,
        bulk = {},
        disableEditing,
        inProgress,
        customFeesForm,
        skus,
        countTotal,
        grandTotal,
        totalCountFormAmount,
        hideCounter = false,
        countFontSize = null,
        totalsFontSize = null,
        headerFooterColor = null,
        headerFooterTextColor = 'black',
        padding = 2,
        onRemoveCounts,
        onRemoveCustomFee,
        onClearCountsAndFees,
        setIdleCountdown,
        resetTimeoutCount,
        printingReceipt
    } = props;
    const warnAction = useContext(ConfirmDialogContext);

    const aggregatedCounts = useMemo(() => _bulk.getCountsItemsWithAmount(bulk, skus[bulk.skuType] || []), [
        bulk,
        skus
    ]);

    const breakdowns = useMemo(() => getBreakdowns(bulk, skus[bulk.skuType] || [], grandTotal + totalCountFormAmount), [
        bulk,
        grandTotal,
        skus,
        totalCountFormAmount
    ]);

    const headerFooterStyle = {
        fontSize: totalsFontSize,
        color: headerFooterTextColor
    };
    const countsStyle = { fontSize: countFontSize, paddingLeft: padding };

    return (
        <div
            // ref={ref}
            style={{
                overflowY: 'auto',
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                margin: !printingReceipt ? 0 : 20
            }}
        >
            <div
                style={{ overflowY: 'auto', flex: `1 1 auto`, minHeight: !printingReceipt ? '150px' : '0px' }}
                id="custom-scroller"
            >
                <table
                    style={{
                        width: '100%',
                        fontSize: countFontSize,
                        border: 'none',
                        borderSpacing: padding,
                        borderCollapse: 'collapse',
                        flex: 1
                    }}
                >
                    <thead style={{ backgroundColor: headerFooterColor }}>
                        <tr
                            style={{
                                textDecoration: 'underline',
                                fontSize: countFontSize,
                                color: headerFooterTextColor
                            }}
                        >
                            <td style={{ padding }}>
                                <Typography style={headerFooterStyle}>Item</Typography>
                            </td>
                            {!printingReceipt && (
                                <td style={{ padding }}>
                                    <Typography style={headerFooterStyle}>Bag</Typography>
                                </td>
                            )}
                            <td style={{ padding }}>
                                <Typography style={headerFooterStyle}>Qty</Typography>
                            </td>
                            <td style={{ padding }}>
                                <Typography style={headerFooterStyle}>Amt</Typography>
                            </td>
                            {!hideCounter && (
                                <td style={{ padding }}>
                                    <Typography style={headerFooterStyle}>Counter</Typography>
                                </td>
                            )}
                        </tr>
                    </thead>
                    <tbody style={{ backgroundColor: headerFooterColor }}>
                        {aggregatedCounts.map((count, i) => {
                            // TODO: find was in UI to show bulk counts versus singular counts
                            return count.items.map((item, j) => {
                                const epicStyle = {
                                    // borderBottom: isLastItemInCount ? `1px solid ${colors.grey[300]}` : null
                                };

                                return (
                                    <tr
                                        key={`${item.description}-${j}`}
                                        style={{
                                            marginBottom: 5,
                                            paddingBottom: 5
                                        }}
                                    >
                                        <td style={epicStyle} data-cy={`counts-panel-item-${i}`}>
                                            <Typography noWrap style={countsStyle}>
                                                {item.description} {item.size}
                                            </Typography>
                                        </td>

                                        {!printingReceipt && (
                                            <td style={epicStyle}>
                                                <Typography style={countsStyle} data-cy={`counts-panel-bagscount-${i}`}>
                                                    {!_.isNil(item.bags) ? item.bags : ''}
                                                </Typography>
                                            </td>
                                        )}

                                        <td style={epicStyle}>
                                            <Typography style={countsStyle} data-cy={`counts-panel-quantity-${i}`}>
                                                {Math.floor(_.get(item, 'quantity', 0))}
                                            </Typography>
                                        </td>
                                        <td style={epicStyle}>
                                            <Typography style={countsStyle}>
                                                {formatAsCurrency(item.value || 0)}
                                            </Typography>
                                        </td>

                                        {!hideCounter && (
                                            <td style={epicStyle} data-cy="counts-screen-table-row-counter">
                                                <Typography noWrap style={{ ...countsStyle }}>
                                                    {getCustomerFirstNameAndLastInitial(count.operator)}
                                                </Typography>
                                            </td>
                                        )}
                                        {!disableEditing && !printingReceipt && (
                                            <DeleteColumn
                                                style={countsStyle}
                                                show={!disableEditing} // show del for all items in bulk count
                                                disabled={disableEditing || inProgress}
                                                epicStyle={epicStyle}
                                                onFunction={() => {
                                                    warnAction(() => {
                                                        onRemoveCounts(count._id, item._id);
                                                        resetTimeoutCount();
                                                    }, `Are you sure you want to delete [${item.description} ${item.size} x ${item.quantity}] from the counts?`);
                                                    setIdleCountdown(0);
                                                    resetTimeoutCount();
                                                }}
                                            />
                                        )}
                                    </tr>
                                );
                            });
                        })}
                        {(bulk.customFees || []).map((fee, index) => {
                            return (
                                <tr key={`${fee.amount}-${index}`}>
                                    <td>
                                        <Typography style={countsStyle}>{fee.description}</Typography>
                                    </td>
                                    {!printingReceipt && <td />}
                                    <td>
                                        <Typography style={countsStyle}>1</Typography>
                                    </td>
                                    <td>
                                        <Typography
                                            style={{
                                                color: fee.amount > 0 ? theme.palette.error.main : colors.green[400],
                                                ...countsStyle
                                            }}
                                        >
                                            {formatAsCurrency(-fee.amount)}
                                        </Typography>
                                    </td>
                                    {!hideCounter && (
                                        <td>
                                            <Typography style={countsStyle}>
                                                {!_.isNil(_.get(fee, 'appliedBy'))
                                                    ? getCustomerFirstNameAndLastInitial(_.get(fee, 'appliedBy'))
                                                    : ''}
                                            </Typography>
                                        </td>
                                    )}

                                    {!printingReceipt && (
                                        <DeleteColumn
                                            style={countsStyle}
                                            show={!disableEditing}
                                            disabled={disableEditing || inProgress}
                                            onFunction={() => {
                                                onRemoveCustomFee(index);
                                                setIdleCountdown(0);
                                                resetTimeoutCount();
                                            }}
                                        />
                                    )}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                <div id="custom-anchor" />
            </div>
            <Divider style={{ marginTop: theme.spacing.unit }} />
            <table
                style={{
                    tableLayout: 'fixed',
                    width: '100%',
                    backgroundColor: headerFooterColor,
                    padding: padding,
                    paddingRight: 0
                }}
            >
                <tbody>
                    {breakdowns.map((breakdown, index) => {
                        return (
                            <tr key={breakdown.title}>
                                <td>
                                    <Typography style={headerFooterStyle}>{breakdown.title}</Typography>
                                </td>
                                <td>
                                    <Typography style={headerFooterStyle}>{Math.floor(breakdown.quantity)}</Typography>
                                </td>
                                <td>
                                    <Typography
                                        style={{
                                            color:
                                                breakdown.value < 0
                                                    ? theme.palette.error.main
                                                    : theme.palette.text.primary,
                                            ...headerFooterStyle
                                        }}
                                        data-cy={`counts-panel-breakdown-value-${breakdown.title}`}
                                    >
                                        {formatAsCurrency(breakdown.value)}
                                    </Typography>
                                </td>
                                {index === 0 && !disableEditing && !printingReceipt && (
                                    <td style={{ textAlign: 'right' }}>
                                        <Button
                                            data-cy="counts-panel-clear-counts"
                                            disabled={
                                                disableEditing ||
                                                inProgress ||
                                                (_.isEmpty(aggregatedCounts) && _.isEmpty(bulk.customFees))
                                            }
                                            style={{ padding: 0, height: 21 }}
                                            size="small"
                                            onClick={() => {
                                                warnAction(() => {
                                                    resetTimeoutCount();
                                                    onClearCountsAndFees(); // TODO:
                                                }, 'Warning! You are about to remove all counts and fees. Are you sure you want to do this?');
                                                setIdleCountdown(0);
                                                resetTimeoutCount();
                                            }}
                                        >
                                            clear
                                        </Button>
                                    </td>
                                )}
                            </tr>
                        );
                    })}
                    <tr>
                        <td>
                            <Typography style={headerFooterStyle}>Rates</Typography>
                        </td>
                        <td>
                            <Typography style={headerFooterStyle} />
                        </td>
                        <td>
                            <Typography style={headerFooterStyle} noWrap>
                                {_.get(bulk, 'rates.name', '')}
                            </Typography>
                        </td>
                    </tr>

                    {!printingReceipt && (
                        <tr>
                            <td>
                                <Typography style={headerFooterStyle}>Avg</Typography>
                            </td>
                            <td>
                                <Typography style={headerFooterStyle} />
                            </td>
                            <td>
                                <Typography style={headerFooterStyle} noWrap>
                                    {getValuePerBag(countTotal, bulk)} / {_bulk.getBulkUnitOfMeasurement(bulk)}
                                </Typography>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
});

const DeleteColumn = ({ show, disabled, onFunction }) => {
    return (
        <td style={{ textAlign: 'left' }}>
            {show && (
                <IconButton disabled={disabled} style={{ padding: 0 }} onClick={() => onFunction()}>
                    <MDIcon path={mdiClose} size={0.8} color={colors.red[500]} />
                </IconButton>
            )}
        </td>
    );
};

function getBreakdowns(bulk, skus, grandTotal) {
    const fees = _.get(bulk, 'customFees', []);

    const [feesAmount, paymentsAmount] = fees.reduce(
        (arr, { amount }) => {
            if (amount > 0) {
                arr[0] = arr[0] + amount;
            } else if (amount < 0) {
                arr[1] = arr[1] + amount;
            }

            return arr;
        },

        [0, 0]
    );

    const feesBreakdown = {
        title: 'Fees',
        quantity: fees.filter(({ amount }) => amount > 0).length,
        value: -feesAmount
    };

    const paymentsBreakdown = {
        title: 'Payments',
        quantity: fees.filter(({ amount }) => amount < 0).length,
        value: -paymentsAmount
    };

    const rates = _.get(bulk, 'rates.rates', []);
    const skusToIgnoreForTotal = _rates.getIgnoredSkusForTotals(rates);
    const totalBreakdown = {
        title: 'Total',
        quantity: _bulk.getCountsTotalItemCount(bulk, skusToIgnoreForTotal),
        value: grandTotal
    };

    let breakdowns = [totalBreakdown];

    if (!_.isEmpty(skus)) {
        const allSkuLabels = _(skus)
            .groupBy('label')
            .keys()
            .value();

        // only show more lines if theres more than 1 label types
        if (allSkuLabels.length > 1) {
            const flattenedAndGroupedCountsObj = _.groupBy(
                _.get(bulk, 'counts', []).reduce((array, count) => (array = array.concat(count.items)), []),
                'size'
            );

            for (let label of allSkuLabels) {
                if (!_.isEmpty(label)) {
                    let quantity = 0;
                    let value = 0;

                    const flattenedAndGroupedCountsArr = flattenedAndGroupedCountsObj[label];
                    if (!_.isEmpty(flattenedAndGroupedCountsArr)) {
                        for (let flatCounts of flattenedAndGroupedCountsArr) {
                            quantity += flatCounts.quantity;

                            const sku = _(skus)
                                .filter(sku => sku.sku === flatCounts.sku)
                                .value();

                            value += flatCounts.quantity * _.first(sku).value * -1; // skus values are inverted
                        }
                    }

                    const newBreakdown = {
                        title: label,
                        quantity,
                        value
                    };

                    breakdowns.push(newBreakdown);
                }
            }
        }
    }

    if (!_.isEmpty(fees)) {
        breakdowns.push(feesBreakdown);
        breakdowns.push(paymentsBreakdown);
    }

    return breakdowns;
}

function getValuePerBag(countTotal, bulk) {
    let totalCommoditiesProcessed = _.get(bulk, 'commoditiesProcessed', 0);
    if (totalCommoditiesProcessed === 0) {
        totalCommoditiesProcessed = _.get(bulk, 'commodityAmount', 0);
    }
    if (totalCommoditiesProcessed > 0) {
        return formatAsCurrency(countTotal / totalCommoditiesProcessed);
    } else {
        return '?';
    }
}

export default withTheme()(CountsScreen);
