import React from 'react';

import _ from 'lodash';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

function UpdateGPSDialog(props) {
    const { open, onAccept, onClose } = props;

    return (
        <Dialog fullWidth open={open} onClose={onClose}>
            <DialogTitle>Update GPS?</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you currently at the correct pickup location? Clicking yes will update the pickup location to
                    your current GPS position.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={onClose}>
                    NO
                </Button>
                <Button color="secondary" onClick={onAccept}>
                    YES
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default UpdateGPSDialog;
