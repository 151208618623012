import React, { useEffect, useState, useContext } from 'react';

import _ from 'lodash';
import moment from 'moment-timezone';
import { _bulk } from 'std';

import { getCustomerName, truncateBagtag, formatAsCurrency, getCustomerFirstNameAndLastInitial } from 'utils/misc';

// npm components
import * as colors from '@material-ui/core/colors';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { withTheme } from '@material-ui/core/styles';

import { Menu, ListItem, Avatar, Tooltip, Icon, MenuItem } from '@material-ui/core';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { Icon as MDIcon } from '@mdi/react';
import { mdiEye, mdiAlertOutline, mdiAlertDecagram, mdiArrowDownDropCircle } from '@mdi/js';

import bulkHelper from 'helpers/bulkHelper';
import { Typography, IconButton } from '@material-ui/core';
import { BULK_STATUS_COLORS, BULK_TYPE_ICONS, BULK_TYPE_COLORS, COMMODITY_COLORS } from 'constants.js';
import useWindowSize from 'utils/hooks/useWindowSize';
import { isCONRegion, isEXPRegion } from 'utils/misc';
import { getComEquivalentPayload } from './EditNumberOfBagsDialogV2';
import { getComEquivalent, shortenSubComName } from 'containers/Operators/PORScreen/NumberOfBagsDialog';
import * as allIcons from '@mdi/js';
import LocalizationContext from 'utils/contexts/LocalizationContext';

function HistoryBulkListItem({
    theme,
    bulk,
    historyBulk,
    grandTotal,
    totalCountFormAmount,
    key,
    commodityColors,
    onViewBulk,
    resetTimeoutCount,
    reportOptions,
    onImageDialog,
    setImages,
    hideCustomerIdentification,
    commodities,
    overdueWindow
}) {
    const [width, height] = useWindowSize();
    let mobileMode = width <= 500;
    const [reportText, setReportText] = useState(['Reported with issue']);
    const [deactivateMenuEl, setDeactivateMenuEl] = React.useState(null);
    const bulkStatus = bulkHelper.getStatus(historyBulk);
    // const total =
    //     bulk._id === historyBulk._id ? grandTotal + totalCountFormAmount : bulkHelper.getTotalValue(historyBulk);
    const total = bulkHelper.getTotalValue(historyBulk);

    let totalCommoditiesProcessed = _.get(historyBulk, 'commoditiesProcessed', 0);
    if (totalCommoditiesProcessed === 0) {
        totalCommoditiesProcessed = _.get(historyBulk, 'commodityAmount', 0);
    }
    const bulkImages = _bulk.getPickupOrDropOffPhotoURL(historyBulk || {});
    const sortedBulkImages = _bulk.sortBulkImages(bulkImages, _.get(historyBulk, 'skuType'));

    let promos = _.get(historyBulk, 'promosApplied', []);
    let promoCodes = Array.from(promos, item => item.code).join(', ');
    const countingSessions = _.get(historyBulk, 'countingSessions', []);
    const bagtags = [];
    for (let session of countingSessions) {
        if (historyBulk.bulkType !== 'inhouse' || !isCONRegion()) break;
        if (session.bagtag) bagtags.push(session.bagtag);
    }

    const [expandBagtags, setExpandBagtags] = useState(false);

    useEffect(() => {
        let customerReportCodes = _.get(historyBulk, 'payloadIssues.issues', []);
        let driverReportCodes = _.get(historyBulk, 'driverIssues.issues', []);

        let reportCodes = [...customerReportCodes, ...driverReportCodes];
        let newReportText = [];
        for (let i = 0; i < reportCodes.length; i++) {
            let reportObject = _.find(reportOptions, element => {
                return element.code === reportCodes[i];
            });
            if (reportObject) newReportText.push(reportObject.label);
        }
        setReportText(newReportText);
    }, [historyBulk]);

    return (
        <ListItem
            key={key}
            style={{
                borderRadius: 4,
                border:
                    bulk._id === historyBulk._id
                        ? `1px solid ${colors.grey[500]}`
                        : `1px solid ${theme.palette.background.paper}`,
                alignItems: 'stretch',
                width: '100%'
            }}
        >
            <div
                style={{
                    // display: 'inline-grid',
                    // textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRight: `1px solid ${colors.grey[500]}`,
                    padding: theme.spacing.unit,
                    paddingLeft: 0,
                    marginRight: theme.spacing.unit
                }}
            >
                <Avatar
                    style={{ backgroundColor: commodityColors[historyBulk.skuType], width: '44px', height: '44px' }}
                >
                    <MDIcon
                        path={BULK_TYPE_ICONS[historyBulk.bulkType]}
                        size={1}
                        color={theme.palette.background.paper}
                    />
                </Avatar>
                <Typography
                    data-cy="bulk-list-item-balance"
                    variant="caption"
                    style={
                        _bulk.isCompleted(historyBulk)
                            ? { color: theme.palette.text.secondary, fontSize: '0.71rem' }
                            : { color: 'red', fontSize: '0.61rem', fontWeight: 500 }
                    }
                >
                    {_bulk.isCompleted(historyBulk) ? formatAsCurrency(total) : 'PENDING'}
                </Typography>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '75%' }}>
                <Typography variant="body2">
                    {isCONRegion() && _bulk.isWalkIn(historyBulk) && (
                        <CustomChip theme={theme} bgColor={commodityColors[historyBulk.skuType]}>
                            <span data-cy="bulk-list-item-bulk-type">RVM</span>
                        </CustomChip>
                    )}
                    {!(isCONRegion() && _bulk.isWalkIn(historyBulk)) && (
                        <CustomChip
                            textColor={isLightColor(commodityColors[historyBulk.skuType]) ? 'black' : 'white'}
                            theme={theme}
                            bgColor={commodityColors[historyBulk.skuType]}
                        >
                            <span data-cy="bulk-list-item-bulk-type">
                                {_.capitalize(bulkHelper.getBulkTypeFormatted(historyBulk))}
                            </span>
                        </CustomChip>
                    )}
                    {/* <span data-cy="bulk-list-item-balance">{formatAsCurrency(total)}</span>{' '} */}
                    {moment(historyBulk.datePickedUp).format('MMM D YYYY, h:mm A')}{' '}
                    {/* {totalCommoditiesProcessed > 1 && bulkHelper.isCompleted(historyBulk) && (
                        <>
                            ({formatAsCurrency(total / totalCommoditiesProcessed)} per {historyBulk.commodityUOM})
                        </>
                    )}{' '} */}
                    {!_.isEmpty(reportText) && (
                        <MDIcon
                            path={mdiAlertOutline}
                            size={'16px'}
                            color={colors.deepOrange[500]}
                            title={reportText.join()}
                        />
                    )}
                    {bulkHelper.hasCustomerIssue(historyBulk) && (
                        <MDIcon
                            path={mdiAlertDecagram}
                            size={'16px'}
                            color={colors.red[500]}
                            title={bulkHelper.getCustomerComplaint(historyBulk)}
                        />
                    )}
                </Typography>

                <Typography variant="body2" data-cy="bulk-list-item-payload">
                    <PayloadInformation bulk={historyBulk} commodities={commodities} total={total} />
                </Typography>
                <Typography variant="body2">
                    {_bulk.isOverdue(historyBulk, overdueWindow) && (
                        <CustomChip
                            theme={theme}
                            bgColor={BULK_STATUS_COLORS['overdue']}
                            textColor={isLightColor(BULK_STATUS_COLORS['overdue']) ? 'black' : 'white'}
                        >
                            Overdue
                        </CustomChip>
                    )}
                    {_bulk.isQuickDrop(historyBulk) && _.isNil(historyBulk.commodityAmount) && (
                        <CustomChip
                            theme={theme}
                            bgColor={colors.grey[400]}
                            textColor={isLightColor(colors.grey[400]) ? 'black' : 'white'}
                        >
                            Rogue
                        </CustomChip>
                    )}
                    {/* <CustomChip theme={theme} bgColor={commodityColors[historyBulk.skuType]}>
                        {_.capitalize(historyBulk.skuType)}
                    </CustomChip>
                    <CustomChip theme={theme} bgColor={colors.blue[100]}>
                        {truncateBagtag(historyBulk.label)}
                    </CustomChip> */}
                </Typography>
                {promos.length > 0 && !hideCustomerIdentification && (
                    <Typography variant="body2">Promos applied: {promoCodes}</Typography>
                )}
                {bagtags.length > 0 && (
                    <Typography
                        noWrap={!expandBagtags}
                        style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                        }}
                        variant="body2"
                    >
                        Processed bagtags:{' '}
                        <span
                            onClick={() => setExpandBagtags(!expandBagtags)}
                            style={{ color: theme.palette.linkColor, cursor: 'pointer' }}
                        >
                            {expandBagtags ? 'Hide Bagtags' : 'Show Bagtags'}
                        </span>
                    </Typography>
                )}
                {expandBagtags && <Typography variant="body2">{bagtags.join(', ')}</Typography>}
            </div>
            <ListItemSecondaryAction>
                {bulk._id === historyBulk._id ? (
                    <Typography variant="caption" style={{ color: theme.palette.text.secondary }}>
                        Viewing
                    </Typography>
                ) : !_.isEmpty(sortedBulkImages) ? (
                    mobileMode ? (
                        <>
                            <IconButton
                                onClick={event => {
                                    setDeactivateMenuEl(event.currentTarget);
                                }}
                            >
                                <MDIcon path={mdiArrowDownDropCircle} size={1} color={'grey'} />
                            </IconButton>
                            <Menu
                                data-cy="help-menu"
                                anchorEl={deactivateMenuEl}
                                open={Boolean(deactivateMenuEl)}
                                onClose={() => {
                                    setDeactivateMenuEl(null);
                                }}
                            >
                                <MenuItem
                                    style={{ justifyContent: 'center' }}
                                    onClick={() => {
                                        setImages(sortedBulkImages);
                                        onImageDialog(true);
                                        resetTimeoutCount();
                                    }}
                                >
                                    <Tooltip title="View Pickup Image">
                                        <Icon>image</Icon>
                                    </Tooltip>
                                </MenuItem>
                                <MenuItem
                                    style={{ justifyContent: 'center' }}
                                    onClick={() => {
                                        onViewBulk(historyBulk._id);
                                        resetTimeoutCount();
                                    }}
                                    disabled={bulk._id === historyBulk._id}
                                >
                                    <Tooltip title="View Order">
                                        <MDIcon path={mdiEye} size={1} color={theme.palette.text.secondary} />
                                    </Tooltip>
                                </MenuItem>
                            </Menu>
                        </>
                    ) : (
                        <>
                            <IconButton
                                onClick={() => {
                                    setImages(sortedBulkImages);
                                    onImageDialog(true);
                                    resetTimeoutCount();
                                }}
                            >
                                <Tooltip title="View Pickup Image">
                                    <Icon>image</Icon>
                                </Tooltip>
                            </IconButton>
                            <IconButton
                                onClick={() => {
                                    onViewBulk(historyBulk._id);
                                    resetTimeoutCount();
                                }}
                                disabled={bulk._id === historyBulk._id}
                            >
                                <Tooltip title="View Order">
                                    <MDIcon path={mdiEye} size={1} color={theme.palette.text.secondary} />
                                </Tooltip>
                            </IconButton>
                        </>
                    )
                ) : (
                    <IconButton
                        onClick={() => {
                            onViewBulk(historyBulk._id);
                            resetTimeoutCount();
                        }}
                        disabled={bulk._id === historyBulk._id}
                    >
                        <MDIcon path={mdiEye} size={1} color={theme.palette.text.secondary} />
                    </IconButton>
                )}
            </ListItemSecondaryAction>
        </ListItem>
    );
}
export default withMobileDialog({ breakpoint: 'xs' })(withTheme()(HistoryBulkListItem));

export const CustomChip = ({ theme, children, bgColor, textColor = 'black' }) => {
    return (
        <span
            style={{
                background: bgColor,
                color: textColor,
                padding: '2px 4px',
                marginRight: 4,
                borderRadius: 4,
                marginBottom: theme.spacing.unit / 2
            }}
        >
            {children}
        </span>
    );
};

const PayloadInformation = ({ bulk, commodities, total }) => {
    const { lang } = useContext(LocalizationContext);
    let containersProcessed = 0;
    if (bulk.skuType == 'beverage') {
        const counts = bulk.bulkCounts || [];
        for (let count of counts) {
            const items = count.items || [];
            for (let item of items) {
                containersProcessed += item.quantity;
            }
        }
    }
    const inProgress = _bulk.isCounted(bulk) && !_bulk.isCompleted(bulk);

    let numOfSubCom = _.isNil(_.get(bulk, 'pickup'))
        ? getComEquivalentPayload(commodities, _.get(bulk, 'pickupLessSubPayloads'))
        : getComEquivalent(bulk, commodities);
    let numOfBags = _.get(bulk, 'commodityAmount', 0) - numOfSubCom;
    const currentSkuCom = _.find(commodities, {
        skuType: _.get(bulk, 'skuType', '')
    });

    let commoditiesProcessedBreakdown = _.get(bulk, 'commoditiesProcessedBreakdown', []);
    let sortedBags = _.get(_.find(commoditiesProcessedBreakdown, { isSubCommodity: false }), 'amount', 0);
    let processedSubComList = _.filter(commoditiesProcessedBreakdown, { isSubCommodity: true });
    let renderList = [];
    if (Math.max(sortedBags, numOfBags) > 0) {
        renderList.push({
            color: _.get(currentSkuCom, 'color', colors.blue[300]),
            icon: allIcons[_.get(currentSkuCom, 'iconName', '')],
            amount: inProgress ? `${sortedBags}\/${numOfBags}` : Math.max(sortedBags, numOfBags),
            name: shortenSubComName(_.get(bulk, 'commodityUOM', 'bag'), Math.max(sortedBags, numOfBags)),
            total: total
        });
    }
    let combinedProcessedSubComList = {};
    _.get(bulk, 'pickup.subPayloads', _.get(bulk, 'pickupLessSubPayloads', []))
        .filter(subPayload => subPayload.skuType === _.get(bulk, 'skuType', ''))
        .forEach(subCom => {
            let id = _.get(subCom, 'subCommodity', '');
            let amount = _.get(subCom, 'amount', 0);
            if (amount > 0) combinedProcessedSubComList[id] = subCom;
        });
    processedSubComList.forEach(subCom => {
        let id = _.get(subCom, 'subCommodity', '');
        let amount = _.get(subCom, 'amount', 0);
        if (amount > 0) {
            if (combinedProcessedSubComList[id] === undefined) {
                combinedProcessedSubComList[id] = subCom;
            } else {
                combinedProcessedSubComList[id].amount = Math.max(
                    combinedProcessedSubComList[id].amount,
                    _.get(subCom, 'amount', 0)
                );
            }
        }
    });
    Object.values(combinedProcessedSubComList).forEach(subCom => {
        let sortedSubCom = _.get(
            _.find(processedSubComList, { subCommodity: _.get(subCom, 'subCommodity', '') }),
            'amount',
            0
        );
        let amount = _.get(subCom, 'amount', 0);
        let subCommodityLookup = _.get(currentSkuCom, 'subCommodities', []);
        let subCommodity = _.find(subCommodityLookup, { _id: _.get(subCom, 'subCommodity', '') });
        let name = _.get(subCommodity, `name.${lang}`, '');

        renderList.push({
            color: _.get(subCommodity, 'color', colors.blue[300]),
            icon: allIcons[convertToImportName(_.get(subCommodity, 'iconName', ''))],
            amount: inProgress ? `${sortedSubCom}\/${amount}` : Math.max(sortedSubCom, amount),
            name: shortenSubComName(name, amount),
            total: total
        });
    });
    const renderCommodities = (renderList || []).map((renderItem, index) => {
        let valuePerBag = '';
        if (total > 0) {
            valuePerBag = ` (${formatAsCurrency(_.get(renderItem, 'total') / _.get(renderItem, 'amount', ''))}/${_.get(
                renderItem,
                'name',
                ''
            ).toLowerCase()}`;
            valuePerBag = valuePerBag.endsWith('s') ? valuePerBag.slice(0, -1) + ')' : valuePerBag + ')';
        }
        return (
            <React.Fragment key={index}>
                <MDIcon
                    path={_.get(renderItem, 'icon', '')}
                    size={0.7}
                    color={_.get(renderItem, 'color', '')}
                    style={{ verticalAlign: 'middle', marginBottom: 3 }}
                />
                {_.get(renderItem, 'amount', '')} {_.get(renderItem, 'name', '').toLowerCase()}
                {valuePerBag}
                {index < renderList.length - 1 && ', '}
            </React.Fragment>
        );
    });
    switch (bulk.bulkType) {
        case 'pickup':
            return (
                <>
                    {renderCommodities} driver {getCustomerFirstNameAndLastInitial(bulk.receiver)}
                </>
            );
        case 'inhouse':
            return (
                <>
                    {renderCommodities} at {_.get(bulk, 'collectorName', 'Unknown Collector')}
                </>
            );
        case 'adjustment':
            return (
                <>
                    {renderCommodities} created by {getCustomerFirstNameAndLastInitial(bulk.receiver)}
                </>
            );
        case 'walk-in':
            return bulk.skuType != 'beverage' ? null : (
                <>
                    {renderCommodities} at {_.get(bulk, 'collectorName', 'Unknown Collector')}
                </>
            );

        default:
            return null;
    }
};

function convertToImportName(iconName) {
    return _.camelCase(`mdi-${iconName}`);
}

function isLightColor(color) {
    const hex = color.replace('#', '');
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;
    return brightness > 155;
}
