import React, { useState, useEffect } from 'react';
import { Redirect, Link } from 'react-router-dom';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import * as qs from 'query-string';

import _ from 'lodash';
import ContainerWithLogo from 'components/ContainerWithLogo/ContainerWithLogo';
import { Button, Typography } from '@material-ui/core';
import { loc } from 'localizations/localizationHandler';

const ReferralRedirect = ({ match, location, http, lang, theme, brandLogoOverride }) => {
    const [url, setUrl] = useState();
    const [closed, setClosed] = useState(false);

    useEffect(() => {
        console.log('ReferralRedirect', match, location);
        getFormattedUrl();
    }, []);

    const getFormattedUrl = async () => {
        let queryStringValues = qs.parse(_.get(location, 'search'));
        let res = await http.getJSON(`/getCharityByUrl/${match.params.url.toLowerCase()}`, false, true);

        //let res = await fetch(process.env.REACT_APP_API_URL + `/getCharityByUrl/${match.params.url}`);
        if (res.ok) {
            //let json = await res.json();
            let json = res.data;
            if (!_.isNil(json.charity)) {
                if (json.charity.closed) {
                    setClosed(true);
                    return;
                }
                queryStringValues.org = match.params.url.toLowerCase();
                if (_.get(match, 'params.subdivision')) {
                    queryStringValues.subdivision = _.get(match, 'params.subdivision');
                }
                let url = `/register?${qs.stringify(queryStringValues)}`;
                setUrl(url);
                return;
            }
        }
        //referrals links
        let res2 = await http.getJSON(`/getCustomerByUniqueId/${match.params.url}`, false, true);
        //res = await fetch(process.env.REACT_APP_API_URL + `/getCustomerByUniqueId/${match.params.url}`);
        if (res2.ok) {
            let json = res2.data;
            if (!_.isNil(json.user)) {
                queryStringValues.tab = 'customer';
                queryStringValues.promoCode = match.params.url;
                setUrl(`/register?${qs.stringify(queryStringValues)}`);
                return;
            }
        }
        // promocode links
        let res3 = await http.postJSON(`/promos/validate/${JSON.stringify(match.params.url)}`, {});
        if (res3.ok) {
            let json = res3.data;
            if (json.result.valid) {
                queryStringValues.tab = 'customer';
                queryStringValues.promoCode = match.params.url;
                setUrl(`/register?${qs.stringify(queryStringValues)}`);
                return;
            }
        }

        setUrl(`/register?${qs.stringify(queryStringValues)}`);
    };

    if (closed)
        return (
            <ContainerWithLogo brandLogoOverride={brandLogoOverride}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <div style={{ textAlign: 'center' }}>
                        <Typography variant="subtitle1">{loc('orgLinkExpired', lang)}</Typography>

                        <Button
                            variant="contained"
                            component={Link}
                            to="/login"
                            color="primary"
                            style={{ marginTop: theme.spacing.unit * 4 }}
                        >
                            {loc('goBack', lang)}
                        </Button>
                    </div>
                </div>
            </ContainerWithLogo>
        );
    if (_.isNil(url)) return <LoadingScreen />;

    return (
        <Redirect to={url} /> // serves redirect to guest users
    );
};

export default ReferralRedirect;
