import React, { useContext } from 'react';
import _ from 'lodash';

import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import { withTheme } from '@material-ui/core/styles';

import moment from 'moment-timezone';

import bulkHelper from 'helpers/bulkHelper';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc, locDate } from '../../../localizations/localizationHandler';

function UncountedQuickdropSubwidget({ quickdropBulk, clicksAllowed, onUncountedQuickdropDialog, theme, daysToCount }) {
    const tdIconStyle = { lineHeight: 0, width: 32 };
    const iconStyle = { marginRight: theme.spacing.unit, fontSize: 18 };
    const tdTextStyle = { paddingTop: 3 };
    const textStyle = { fontSize: 12 };

    const { lang } = useContext(LocalizationContext);

    return (
        <div
            id="uncounted-quickdrop-subwidget"
            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
        >
            <div
                style={{
                    paddingLeft: theme.spacing.unit * 2,
                    paddingRight: theme.spacing.unit * 2,
                    overflow: 'hidden'
                }}
            >
                <table style={{ borderSpacing: '0px 5px', tableLayout: 'fixed', width: '100%' }}>
                    <tbody>
                        <tr>
                            <td style={tdIconStyle}>
                                <Icon color="action" style={iconStyle}>
                                    calendar_today
                                </Icon>
                            </td>
                            <td style={tdTextStyle}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography style={textStyle} data-cy="edit-pickup-subwidget-date">
                                        <span style={{ marginRight: theme.spacing.unit }}>
                                            {locDate(
                                                bulkHelper.getQuickdropDropOffDate(quickdropBulk),
                                                'MMM Do h:mm A',
                                                lang
                                            )}{' '}
                                        </span>
                                    </Typography>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Icon color="action" style={iconStyle}>
                                    info
                                </Icon>
                            </td>
                            <td>
                                <Typography variant="caption">
                                    {loc('pickupWidgets11', lang, {
                                        commodityUOM: _.get(quickdropBulk, 'commodityUOM', 'Bag'),
                                        daysToCount
                                    })}
                                </Typography>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div
                style={{
                    borderLeft: '1px solid ' + theme.palette.text.hint,
                    paddingLeft: theme.spacing.unit,
                    paddingRight: theme.spacing.unit
                }}
            >
                <IconButton
                    data-cy="uncounted-quickdrop-dialog-edit-button"
                    disabled={!clicksAllowed}
                    onClick={onUncountedQuickdropDialog}
                >
                    <Icon>edit</Icon>
                </IconButton>
            </div>
        </div>
    );
}

export default withTheme()(UncountedQuickdropSubwidget);
