import moment from 'moment-timezone';

export const DEFAULT_TIMEZONE = process.env.REACT_APP_REGION_TIMEZONE;

// DEPRECATED DEPRECATED DEPRECATED DEPRECATED DEPRECATED DEPRECATED DEPRECATED DEPRECATED
// DEPRECATED DEPRECATED DEPRECATED DEPRECATED DEPRECATED DEPRECATED DEPRECATED DEPRECATED
// DEPRECATED DEPRECATED DEPRECATED DEPRECATED DEPRECATED DEPRECATED DEPRECATED DEPRECATED
// DEPRECATED DEPRECATED DEPRECATED DEPRECATED DEPRECATED DEPRECATED DEPRECATED DEPRECATED
// DEPRECATED DEPRECATED DEPRECATED DEPRECATED DEPRECATED DEPRECATED DEPRECATED DEPRECATED
// DEPRECATED DEPRECATED DEPRECATED DEPRECATED DEPRECATED DEPRECATED DEPRECATED DEPRECATED
// Use _time Use _time Use _time Use _time Use _time Use _time Use _time Use _time Use _time
export const getTimezone = (overwriteCache = true) => {
    return moment.tz.guess(overwriteCache);
};

export const getStartOfTodayWithTimezone = (timezone = DEFAULT_TIMEZONE) => {
    return moment(new Date())
        .tz(timezone)
        .startOf('day')
        .toDate();
};

export const isToday = date => {
    return moment(new Date())
        .tz(DEFAULT_TIMEZONE)
        .isSame(date, 'day');
};

export const applyTimezoneToDate = (date, timezone = DEFAULT_TIMEZONE) => {
    return moment(date)
        .tz(timezone)
        .toDate();
};

export const mutateDateFromZoneToLocal = (zoneTime, timezone) => {
    let offsetCompensation =
        moment()
            .tz(timezone)
            .utcOffset() - moment().utcOffset(); // how many minutes need to be added to zone-specific time to transform it into local
    return moment(zoneTime)
        .add(offsetCompensation, 'minutes')
        .toDate();
};

export const mutateDateFromLocalToZone = (localTime, timezone) => {
    let offsetCompensation =
        moment().utcOffset() -
        moment()
            .tz(timezone)
            .utcOffset(); // how many minutes need to be added to local time to transform it into specified timezone
    return moment(localTime)
        .add(offsetCompensation, 'minutes')
        .toDate();
};
