import React, { Component } from 'react';

import _ from 'lodash';

import BaseWidget from './BaseWidget';

import * as colors from '@material-ui/core/colors';
import Typography from '@material-ui/core/Typography';
import { withTheme } from '@material-ui/core/styles';

import { ResponsiveContainer, Cell, Legend, PieChart, Pie, Tooltip } from 'recharts';
import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

class HouseholdWidget extends Component {
    static contextType = LocalizationContext;
    render() {
        const { theme, elevated, data } = this.props;

        const sortedData = data.sort((a, b) => b.value - a.value).slice(0, 7);

        // const hideCategories = data.length > 10;

        // const CustomTooltip = ({ payload }) => {
        //     if (_.isEmpty(payload)) return <></>;

        //     const data = payload[0];
        //     const name = _.get(data, 'name');
        //     if (!name) return <></>;

        //     return (
        //         <div style={{ backgroundColor: 'white', borderRadius: '4px' }}>
        //             <Typography>{` ${name} `}</Typography>
        //         </div>
        //     );
        // };

        return (
            <BaseWidget elevated={elevated}>
                {!_.isEmpty(sortedData) ? (
                    <ResponsiveContainer width="100%" height={200}>
                        <PieChart>
                            <Pie
                                stroke="none"
                                data={sortedData}
                                dataKey="value"
                                label={false}
                                labelLine={false}
                                style={{ fontFamily: 'Roboto' }}
                            >
                                {sortedData.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={getCellColor(index)} />
                                ))}
                            </Pie>
                            {/* {hideCategories && <Tooltip content={<CustomTooltip />} />} */}
                            {/*!hideCategories*/ true && (
                                <Legend
                                    iconType="square"
                                    verticalAlign="middle"
                                    layout="vertical"
                                    align="right"
                                    wrapperStyle={{
                                        fontFamily: theme.typography.fontFamily,
                                        color: theme.palette.text.secondary
                                    }}
                                />
                            )}
                        </PieChart>
                    </ResponsiveContainer>
                ) : (
                    <Typography color="textSecondary">{loc('household1', this.context.lang)}</Typography>
                )}
            </BaseWidget>
        );
    }
}

export default withTheme()(HouseholdWidget);

function getCellColor(index) {
    let colorsFiltered = _.omit(colors, ['common', 'yellow', 'brown', 'grey']);
    let keys = _.keys(colorsFiltered);
    return colorsFiltered[keys[((index + 4) * 2) % 15]][500];
}
