import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';

import {
    Dialog,
    DialogActions,
    DialogContent,
    Button,
    withTheme,
    DialogContentText,
    withMobileDialog,
    Typography,
    CircularProgress,
    Icon
} from '@material-ui/core';

import DialogTitlePrimary from 'components/MaterialUIExtensions/DialogTitlePrimary';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

import { _user } from 'std';

function VerificationDialog(props) {
    const { http, fullScreen, customer, onClose, theme } = props;
    const { lang } = useContext(LocalizationContext);

    return (
        <Dialog fullScreen={fullScreen} open={true} onClose={onClose}>
            <DialogTitlePrimary closeButtonShown onClose={onClose} style={{ marginBottom: theme.spacing.unit * 2 }}>
                {loc('actionDisabled', lang)}
            </DialogTitlePrimary>
            <DialogContent>
                <Typography>{loc('collectionsDialog1', lang)}</Typography>
            </DialogContent>
            <DialogActions style={{ marginBottom: 'max(8px, env(safe-area-inset-bottom, 8px))' }}>
                <Button color="primary" onClick={onClose}>
                    {loc('close', lang)}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default withMobileDialog({ breakpoint: 'xs' })(withTheme()(VerificationDialog));
