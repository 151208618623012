import { useContext, useState, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { getCustomerFirstName, getCustomerLastName, getCustomerName } from 'utils/misc';

import LocalizationContext from 'utils/contexts/LocalizationContext.js';
import { loc } from 'localizations/localizationHandler';

import { MAX_NUM_OF_RECYCLING_BAGS, MIN_NUM_OF_RECYCLING_BAGS } from 'constants.js';
import HttpContext from 'utils/contexts/HttpContext';

import { validate } from 'utils/validate';

function useBottleDriveForm(props) {
    const { customer } = props;

    const { lang } = useContext(LocalizationContext);
    const http = useContext(HttpContext);

    const [donate, setDonate] = useState(false);
    const [loading, setLoading] = useState(false);
    const [displayConfirmation, setDisplayConfirmation] = useState(false);

    const formik = useFormik({
        initialValues: {
            date: moment()
                .tz(process.env.REACT_APP_REGION_TIMEZONE)
                .add(3, 'days'),
            startTime: null,
            endTime: null,
            location: {
                description: _.get(customer, 'location.description', ''),
                place_id: _.get(customer, 'location.place_id', ''),
                lat: _.get(customer, 'location.lat', ''),
                lng: _.get(customer, 'location.lng', '')
            },
            estimatedBags: 0,
            comment: '',
            charity: _.get(customer, 'charity.name', ''),
            subdivision: '',
            firstName: !_.isNil(customer) ? getCustomerFirstName(customer) : '',
            lastName: !_.isNil(customer) ? getCustomerLastName(customer) : '',
            contactEmail: _.get(customer, 'email', ''),
            contactPhone: _.get(customer, 'phone', ''),
            collectorName: ''
        },
        validationSchema: Yup.object({
            startTime: Yup.mixed().required(loc('validate4', lang)),
            endTime: Yup.mixed().required(loc('validate4', lang)),
            location: Yup.object({
                description: Yup.string().required(loc('validate4', lang))
            }),
            estimatedBags: Yup.number()
                .required(loc('validate4', lang))
                .min(40, loc('pickupDialogBottleDrive11')),
            comment: Yup.string().required(loc('validate4', lang)),
            charity: Yup.string().required(loc('validate4', lang)),
            subdivision: Yup.string(),
            // contactName: Yup.string().required(loc('validate4', lang)),
            firstName: Yup.string().required(loc('validate4', lang)),
            lastName: Yup.string().required(loc('validate4', lang)),
            contactEmail: Yup.string()
                .required(loc('validate4', lang))
                .email(loc('referal6', lang)),
            contactPhone: Yup.string()
                .required(loc('validate4', lang))
                .test('validPhoneNumber', loc('labels15', lang), value => {
                    if (!value) return false;
                    let errorsObj = validate(['phone'], value, lang);
                    return !errorsObj.fail;
                })
        }),
        onSubmit: async values => {
            setLoading(true);
            let valuesAdjusted = _.cloneDeep(values);
            valuesAdjusted.contactPhone = values.contactPhone.replace(/\D+/g, '');
            valuesAdjusted.contactName = values.firstName + ' ' + values.lastName;
            const res = await http.post('/submitBottleDrive', valuesAdjusted);
            if (res.ok) {
                setDisplayConfirmation(true);
            }
            setLoading(false);
        }
    });

    const handleSetDonation = donate => {
        setDonate(donate);
        if (!donate) {
            formik.setFieldValue('charity', null);
        }
    };

    useEffect(() => {
        formik.validateForm(); //for some reason formik is not validating the charity value after its changed
    }, [formik.values.charity]);

    return {
        formik,
        handleSetDonation,
        donate,
        loading,
        displayConfirmation
    };
}

export default useBottleDriveForm;
