import React, { useState } from 'react';

function useCharitySelect({
    initialCharityPreferred = null,
    initialSubdivisionPreferred = null,
    initialDonate = false
}) {
    const [charityPreferred, setCharityPreferred] = useState(initialCharityPreferred);
    const [subdivisionPreferred, setSubdivisionPreferred] = useState(initialSubdivisionPreferred);
    const [donate, setDonate] = useState(initialDonate);

    const handleCharityPreferred = (charityId, subdivision) => () => {
        setCharityPreferred(charityId);
        setSubdivisionPreferred(subdivision);
    };

    const handleSubdivisionPreferred = e => {
        setSubdivisionPreferred(e.target.value);
    };

    const handleSetDonation = donate => {
        setDonate(donate);
        if (!donate) {
            setCharityPreferred(null);
        }
    };

    return {
        charityPreferred,
        subdivisionPreferred,
        donate,
        handleCharityPreferred,
        handleSubdivisionPreferred,
        handleSetDonation
    };
}

export default useCharitySelect;
