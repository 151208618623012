import React, { useContext, useEffect, useState } from 'react';

import _ from 'lodash';

import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { withTheme } from '@material-ui/core/styles';

import { colors, Icon } from '@material-ui/core';

import HttpContext from 'utils/contexts/HttpContext';

import CollectorCRUDTable from '../../../components/CRUDTables/CollectorCRUDTable';
import DropLocationsTable from 'components/CRUDTables/DropLocationsTable';

function Collectors(props) {
    const { theme } = props;

    const http = useContext(HttpContext);

    const [toggle, setToggle] = useState('collector');
    // const [showCollectorWarning, setShowCollectorWarning] = useState(false);
    const [showDropLocationsWarning, setShowDropLocationsWarning] = useState(false);

    const [dropLocationsProps, setDropLocationsProps] = useState({});
    const [initialCollectorFilter, setInitialCollectorFilter] = useState(null);

    const handleToggle = (e, toggleType) => {
        setToggle(toggleType);
    };
    const switchToDropLocation = (e, collectorId, technologyCount) => {
        if (!_.isNil(collectorId) && technologyCount >= 0) {
            handleToggle(null, 'dropLocation');
            setInitialCollectorFilter({ _id: collectorId });
        }
    };

    const updateDropLocationsProps = (name, value) => {
        const newDropLocationsProps = _.cloneDeep(dropLocationsProps);
        newDropLocationsProps[name] = value;

        setDropLocationsProps(newDropLocationsProps);
    };

    const getUnmappedIntegrationData = async () => {
        const res = await http.getJSON(`/dropLocations/unmappedIntegrationData`);
        if (res.ok) {
            const unmappedIntegrationData = _.get(res, 'data.unmappedIntegrationData', []);
            if (!_.isEmpty(unmappedIntegrationData)) {
                setShowDropLocationsWarning(true);
            } else {
                setShowDropLocationsWarning(false);
            }
            updateDropLocationsProps('unmappedIntegrationData', unmappedIntegrationData);
        }
    };

    useEffect(() => {
        getUnmappedIntegrationData();
    }, []);

    return (
        <>
            <div style={{ padding: theme.spacing.unit * 2, display: 'flex', justifyContent: 'center' }}>
                <ToggleButtonGroup value={toggle} exclusive onChange={handleToggle}>
                    <ToggleButton value="collector">Collectors</ToggleButton>
                    <ToggleButton value="dropLocation" data-cy="collectors-drop-location-tab">
                        <>Collector Technology Information</>
                        {showDropLocationsWarning && (
                            <Icon
                                style={{
                                    marginLeft: theme.spacing.unit / 2,
                                    fontSize: theme.spacing.unit * 3,
                                    color: colors.red[500]
                                }}
                            >
                                error
                            </Icon>
                        )}
                    </ToggleButton>
                </ToggleButtonGroup>
            </div>
            {toggle === 'collector' && <CollectorCRUDTable {...props} switchToDropLocation={switchToDropLocation} />}
            {toggle === 'dropLocation' && (
                <DropLocationsTable
                    {...props}
                    {...dropLocationsProps}
                    reloadUnmappedData={getUnmappedIntegrationData}
                    initialCollectorFilter={initialCollectorFilter}
                />
            )}
        </>
    );
}

export default withTheme()(Collectors);
