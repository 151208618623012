import SnackbarContext from 'components/CustomSnackbar/SnackbarContext';
import { useState, useContext, useEffect } from 'react';
import _ from 'lodash';

import HttpContext from 'utils/contexts/HttpContext';

const userTypes = ['Customer', 'Driver', 'Counter', 'System'];

function useComplaintsStats() {
    const http = useContext(HttpContext);
    const onSnackbar = useContext(SnackbarContext);

    const [allCollectors, setAllCollectors] = useState([]);
    const [collectorsSelected, setCollectorsSelected] = useState([]);

    const [allDrivers, setAllDrivers] = useState([]);
    const [driversSelected, setDriversSelected] = useState([]);

    const [allComplaints, setAllComplaints] = useState([]);
    const [complaintsSelected, setComplaintsSelected] = useState([]);

    const [userTypesSelected, setUserTypesSelected] = useState(userTypes);

    useEffect(() => {
        (async () => {
            const res = await http.getJSON('/users/getAllDrivers');
            if (res.ok) {
                let drivers = _.get(res, 'data.drivers', []);
                setAllDrivers(drivers);
                setDriversSelected(drivers.map(driver => driver._id));
            }
            const res2 = await http.getJSON('/collectors');
            if (res2.ok) {
                let collectors = _.get(res2, 'data.collectors', []);
                setAllCollectors(collectors);
                setCollectorsSelected(collectors.map(collector => collector._id));
            }
            const res3 = await http.getJSON('/system/allComplaints');
            if (res3.ok) {
                let complaints = _.get(res3, 'data.complaints', []);
                setAllComplaints(complaints);
                setComplaintsSelected(complaints.map(complaint => complaint.code));
            }
        })();
    }, []);

    const handleAllFilterCollectors = all => e => {
        e.stopPropagation();
        if (all) {
            setCollectorsSelected(allCollectors.map(c => c._id));
        } else {
            setCollectorsSelected([]);
        }
    };

    const handleAllFilterUserTypes = all => e => {
        e.stopPropagation();
        if (all) {
            setUserTypesSelected([...userTypes]);
        } else {
            setUserTypesSelected([]);
        }
    };

    const handleAllFilterComplaints = all => e => {
        e.stopPropagation();
        if (all) {
            setComplaintsSelected(allComplaints.map(complaint => complaint.code));
        } else {
            setComplaintsSelected([]);
        }
    };

    return {
        allCollectors,
        collectorsSelected,
        setCollectorsSelected,
        allDrivers,
        driversSelected,
        setDriversSelected,
        allComplaints,
        complaintsSelected,
        setComplaintsSelected,
        userTypesSelected,
        setUserTypesSelected,
        userTypes,
        handleAllFilterCollectors,
        handleAllFilterUserTypes,
        handleAllFilterComplaints
    };
}

export default useComplaintsStats;
