import React, { useState, useContext } from 'react';
import _ from 'lodash';

import { TextField, MenuItem, withTheme, InputAdornment, InputLabel } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { withStyles } from '@material-ui/core/styles';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from '../../localizations/localizationHandler';
import { useEffect } from 'react';
import StyledTextField from './inputs/StyledTextField';

function QuestionnaireForm(props) {
    const {
        questionnaire,
        responses,
        textColor = null,
        InputProps = {},
        touchedInputs,
        overrideTouched,
        onResponseSelected,
        onCustomResponseChange,
        variant = 'filled'
    } = props;

    return (
        !_.isNil(questionnaire) &&
        !_.isEmpty(questionnaire) &&
        _.get(questionnaire, 'questions', []).map((question, i) => {
            return (
                <div style={{ marginLeft: 5, marginRight: 5 }}>
                    <ResponseList
                        key={i}
                        question={question}
                        i={i}
                        questionnaire={questionnaire}
                        textColor={textColor}
                        responses={responses}
                        touchedInputs={touchedInputs}
                        onResponseSelected={onResponseSelected}
                        overrideTouched={overrideTouched}
                        InputProps={InputProps}
                        variant={variant}
                        onCustomResponseChange={onCustomResponseChange}
                    />
                </div>
            );
        })
    );
}

const ResponseList = ({
    question,
    i,
    textColor,
    responses,
    questionnaire,
    touchedInputs,
    onResponseSelected,
    overrideTouched,
    InputProps,
    variant,
    onCustomResponseChange
}) => {
    const { lang } = useContext(LocalizationContext);

    const [responseSearch, setResponseSearch] = useState('');

    const [filteredResponses, setFilteredResponses] = useState(_.get(question, 'responses', []));
    const [responseAmount, setResponseAmount] = useState(_.size(_.get(question, 'responses', 0)));

    const [showSearch, setShowSearch] = useState(false);

    const responseForQuestion = _.find(responses, { questionId: question._id });
    const touchedInputsForQuestion = _.find(touchedInputs, { questionId: question._id });
    const showErrorMainInput =
        (_.get(touchedInputsForQuestion, 'mainInputTouched') || overrideTouched) &&
        _.isEmpty(_.get(responseForQuestion, 'responseSelected', ''));
    const showErrorOtherInput =
        (_.get(touchedInputsForQuestion, 'otherInputTouched') || overrideTouched) &&
        _.isEmpty(_.get(responseForQuestion, 'customResponse', ''));

    const handleSearchChange = e => {
        let value = e.target.value.toLowerCase();
        setResponseSearch(value);
    };

    useEffect(() => {
        if (responseAmount >= 10) {
            setShowSearch(true);
        } else {
            setShowSearch(false);
        }
    }, [question, responseAmount]);

    useEffect(() => {
        let filteredList = [];

        if (!_.isNil(responseSearch) && responseSearch !== '') {
            filteredList = _.get(question, 'responses', []).filter(response => {
                return _.get(response, lang)
                    .toLowerCase()
                    .includes(responseSearch);
            });
        } else {
            filteredList = _.get(question, 'responses', []);
        }

        setFilteredResponses(filteredList);
    }, [question, responseSearch, lang]);

    return (
        <>
            <StyledTextField
                textColor={textColor}
                question={question}
                lang={lang}
                value={_.get(responseForQuestion, 'responseSelected', '')}
                margin="dense"
                fullWidth
                variant={_.isNil(variant) || _.isEmpty(variant) ? undefined : variant}
                InputProps={{ InputProps }}
                data-cy={`questionnaire-form-${i}`}
                select
                onChange={e => onResponseSelected(questionnaire, question, e.target.value)}
                error={showErrorMainInput}
                helperText={showErrorMainInput && loc('validate4', lang)}
            >
                {showSearch && <SearchBar responseSearch={responseSearch} handleSearchChange={handleSearchChange} />}
                {!_.isNil(filteredResponses) &&
                    !_.isEmpty(filteredResponses) &&
                    filteredResponses.map((resp, index) => (
                        <MenuItem
                            key={index}
                            value={_.get(resp, 'en')}
                            data-cy={`questionnaire-form-${i}-${_.get(resp, 'en')}`}
                        >
                            {_.get(resp, lang)}
                        </MenuItem>
                    ))}
                {_.get(question, 'allowCustomResponse', false) && (
                    <MenuItem value="OTHER" data-cy={`questionnaire-form-${i}-OTHER`}>
                        {loc('other', lang)}
                    </MenuItem>
                )}
            </StyledTextField>

            {_.get(responseForQuestion, 'responseSelected') === 'OTHER' && (
                <StyledTextField
                    textColor={textColor}
                    value={_.get(responseForQuestion, 'customResponse', '')}
                    margin="dense"
                    fullWidth
                    InputProps={InputProps}
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    inputProps={{ 'data-cy': `questionnaire-form-${i}-custom-resp` }}
                    label={loc('pleaseSpecify', lang)}
                    variant="outlined"
                    onChange={e => onCustomResponseChange(questionnaire, question, e.target.value)}
                    error={showErrorOtherInput}
                    helperText={showErrorOtherInput && loc('validate4', lang)}
                />
            )}
        </>
    );
};

const SearchBar = ({ responseSearch, handleSearchChange }) => {
    return (
        <div style={{ marginLeft: 16, marginRight: 16 }}>
            <TextField
                name="search"
                label="Search"
                fullWidth
                variant="outlined"
                value={responseSearch || ''}
                onChange={e => handleSearchChange(e)}
                margin="dense"
                autoComplete="false"
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <SearchIcon />
                        </InputAdornment>
                    )
                }}
            />
        </div>
    );
};

export default withTheme()(QuestionnaireForm);
