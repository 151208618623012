import VERSION_FRONT from 'VERSION';

import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';

import { withTheme } from '@material-ui/core/styles';

import { deviceHelper } from 'utils/misc';

import LocalizationContext from 'utils/contexts/LocalizationContext.js';
import { loc } from '../../localizations/localizationHandler';
import { useContext } from 'react';

function UpdateRequiredDialog({ theme, open, disabling, onClose }) {
    const { lang } = useContext(LocalizationContext);

    return (
        <Dialog open={open} maxWidth="sm" fullWidth>
            <DialogTitle>{loc('errorDialog7', lang)}</DialogTitle>
            <DialogContent>
                {!deviceHelper.isNativeApp() && (
                    <>
                        <Typography variant="subtitle1">
                            {loc('updateDialog1', lang)} {loc('updateDialog2', lang)}
                        </Typography>
                        <Button variant="contained" onClick={() => window.location.reload(true)}>
                            {loc('refresh', lang)}
                        </Button>
                    </>
                )}
                {deviceHelper.AndroidCordova() && (
                    <>
                        <Typography variant="subtitle1">
                            {loc('updateDialog1', lang)} {loc('updateDialog3', lang)} {loc('updateDialog5', lang)}{' '}
                            <Link
                                href={process.env.REACT_APP_ORIGIN_URL}
                                style={{
                                    color: theme.palette.linkColor,
                                    overflowWrap: 'break-word',
                                    wordWrap: 'break-word'
                                }}
                            >
                                {process.env.REACT_APP_ORIGIN_URL}
                            </Link>
                        </Typography>
                        <Button variant="contained" href={process.env.REACT_APP_GOOGLE_PLAY_LINK}>
                            Google Play
                        </Button>
                    </>
                )}
                {deviceHelper.iOSCordova() && (
                    <>
                        <Typography variant="subtitle1">
                            {loc('updateDialog1', lang)} {loc('updateDialog4', lang)} {loc('updateDialog5', lang)}{' '}
                            <Link
                                href={process.env.REACT_APP_ORIGIN_URL}
                                style={{
                                    color: theme.palette.linkColor,
                                    overflowWrap: 'break-word',
                                    wordWrap: 'break-word'
                                }}
                            >
                                {process.env.REACT_APP_ORIGIN_URL}
                            </Link>
                        </Typography>
                        <Button variant="contained" href={process.env.REACT_APP_APPSTORE_LINK}>
                            App Store
                        </Button>
                    </>
                )}
                <Typography variant="subtitle1" color="textSecondary" style={{ marginTop: theme.spacing.unit * 2 }}>
                    {loc('updateDialog6', lang, { version: VERSION_FRONT })}
                </Typography>
            </DialogContent>
            {!disabling && (
                <DialogActions style={{ justifyContent: 'right' }}>
                    <Button onClick={onClose}>Dismiss</Button>
                </DialogActions>
            )}
        </Dialog>
    );
}

export default withTheme()(UpdateRequiredDialog);
