import React, { useContext } from 'react';

import _ from 'lodash';
import * as colors from '@material-ui/core/colors';

import { Button, FormControl, TextField, Grid, InputAdornment, Typography } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import { formatAsCurrency } from 'utils/misc';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

function TipScreen(props) {
    const { standardTipAmounts, tipAmount, setTipAmount, tipAmountOther, setTipAmountOther, maxAmount, theme } = props;
    const { lang } = useContext(LocalizationContext);
    const selectedStyle = {
        backgroundColor: colors.green[500],
        color: theme.palette.background.default,
        height: 75,
        fontSize: 20
    };
    const deselectedStyle = { color: colors.green[500], height: 75, fontSize: 20 };

    const handleStandardTipAmount = amount => {
        if (!tipAmountOther && tipAmount === amount) {
            setTipAmount(0);
        } else {
            setTipAmount(amount);
        }
        setTipAmountOther(false);
    };
    const adjustedMaxAmount = Math.min(maxAmount, 100);

    return (
        <>
            <Typography style={{ marginBottom: theme.spacing.unit * 2 }}>{loc('feedback12', lang)}</Typography>
            <Grid container spacing={theme.spacing.unit} style={{ marginBottom: theme.spacing.unit }}>
                {standardTipAmounts.map((value, idx) => {
                    return (
                        <Grid item xs={12 / (standardTipAmounts.length + 1)}>
                            <Button
                                data-cy={`tip-screen-standard-amount-${value}-button`}
                                variant={
                                    !tipAmountOther &&
                                    tipAmount === (adjustedMaxAmount > value ? value : adjustedMaxAmount)
                                        ? 'contained'
                                        : 'outlined'
                                }
                                fullWidth
                                style={
                                    !tipAmountOther &&
                                    tipAmount === (adjustedMaxAmount > value ? value : adjustedMaxAmount)
                                        ? selectedStyle
                                        : deselectedStyle
                                }
                                onClick={() =>
                                    handleStandardTipAmount(adjustedMaxAmount > value ? value : adjustedMaxAmount)
                                }
                            >
                                {formatAsCurrency((adjustedMaxAmount > value ? value : adjustedMaxAmount) * 100, lang)}
                            </Button>
                        </Grid>
                    );
                })}
                <Grid item xs={12 / (standardTipAmounts.length + 1)}>
                    <Button
                        data-cy="tip-screen-other-amount-button"
                        variant={tipAmountOther ? 'contained' : 'outlined'}
                        fullWidth
                        style={tipAmountOther ? selectedStyle : deselectedStyle}
                        onClick={() => {
                            setTipAmount(0);
                            setTipAmountOther(!tipAmountOther);
                        }}
                    >
                        {loc('feedback13', lang)}
                    </Button>
                </Grid>

                {tipAmountOther && (
                    <Grid item xs="12">
                        <FormControl
                            fullWidth
                            style={{
                                paddingLeft: theme.spacing.unit * 2,
                                paddingRight: theme.spacing.unit * 2
                            }}
                        >
                            <TextField
                                data-cy="tip-screen-other-amount-input"
                                name="otherAmount"
                                label={loc('feedback14', lang)}
                                type="number"
                                value={tipAmount}
                                onChange={e => setTipAmount(Math.min(Math.max(e.target.value, 0), adjustedMaxAmount))}
                                color="success"
                                InputProps={{
                                    disableUnderline: true,
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                                }}
                                InputLabelProps={{
                                    style: { color: colors.green[500] }
                                }}
                                style={{
                                    marginBottom: theme.spacing.unit,
                                    borderBottomWidth: '1px',
                                    'border-color': colors.green[500],
                                    'border-style': 'solid'
                                }}
                            />
                        </FormControl>
                    </Grid>
                )}
            </Grid>
        </>
    );
}

export default withTheme()(TipScreen);
