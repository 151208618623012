import React, { useState, useEffect, useContext } from 'react';
import _, { startCase } from 'lodash';

import {
    DialogActions,
    Dialog,
    DialogContent,
    Button,
    Typography,
    TextField,
    InputLabel,
    Select,
    OutlinedInput,
    MenuItem,
    Tabs,
    Tab,
    withTheme,
    withMobileDialog,
    DialogTitle,
    FormControl,
    Icon,
    IconButton,
    Grid
} from '@material-ui/core';
import { Icon as MDIcon } from '@mdi/react';
import * as allIcons from '@mdi/js';

import SearchIcon from '@material-ui/icons/Search';
import * as colors from '@material-ui/core/colors';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Keypad from 'containers/Operators/PORScreen/Keypad';
import BulkSorterCard from 'containers/Operators/PORScreen/BulkSorterCard';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';
import useWindowSize from 'utils/hooks/useWindowSize';
import ConfigurationEditDialog from './Dialogs/ConfigurationEditDialog';

function BulkStationConfigurationInput(props) {
    const {
        theme,
        fullScreen,
        collectorRates,
        commodities,
        open,
        onClose,
        onSubmit,
        collectors,
        collector,
        http,
        onSnackbar,
        handleReloadData
    } = props;

    const { lang } = useContext(LocalizationContext);

    const [rates, setRates] = useState(_.get(collectorRates, 'rates', []));
    const skusGroupedBySkuType = _.groupBy(rates, 'skuType');
    const [config, setConfig] = useState(
        _.get(_.first(_.filter(collectors, { _id: _.get(collector, '_id', '') })), 'bulkStationConfig', [])
    );
    useEffect(() => {
        const initialConfig = _.get(
            _.first(_.filter(collectors, { _id: _.get(collector, '_id', '') })),
            'bulkStationConfig',
            []
        );
        setConfig(initialConfig);
    }, [collectors, collector]);
    const [selectedSkuType, setSelectedSkuType] = useState(
        'beverage' in skusGroupedBySkuType ? 'beverage' : _.first(Object.keys(skusGroupedBySkuType))
    );
    const [copyTargetId, setCopyTargetId] = useState('');
    const [targetCollectorShrink, setTargetCollectorShrink] = useState(false);
    const [copyDisabled, setCopyDisabled] = useState(false);
    //used on small screens only
    const [tabSelected, setTabSelected] = useState(0);

    const defualtConfig = {
        enabled: false,
        fillCapacity: 0,
        fullWidth: false,
        icon: '',
        iconColor: '',
        backgroundColor: '',
        name: '',
        width: 'full'
    };

    const handleToggleEnabled = sku => {
        let updatedConf = _.cloneDeep(config);
        const configForSku = _.find(updatedConf, { sku });
        if (_.isNil(configForSku)) {
            updatedConf.push({ ...defualtConfig, sku, enabled: true });
        } else {
            configForSku.enabled = !configForSku.enabled;
        }
        setConfig(updatedConf);
    };

    const handleChangeConfig = (sku, newValues) => {
        let updatedConf = _.cloneDeep(config);
        const configForSku = _.find(updatedConf, { sku });
        if (_.isNil(configForSku)) {
            let newConfig = { ...defualtConfig, sku };
            for (const key in newValues) {
                newConfig[key] = newValues[key];
            }
            updatedConf.push(newConfig);
        } else {
            for (const key in newValues) {
                configForSku[key] = newValues[key];
            }
        }
        setConfig(updatedConf);
    };

    const handleChangeSpecialConfig = (type, newValues) => {
        let updatedConf = _.cloneDeep(config);
        const configForSku = _.find(updatedConf, { special: type });
        if (_.isNil(configForSku)) {
            let newConfig = { special: type, enabled: true };
            for (const key in newValues) {
                newConfig[key] = newValues[key];
            }
            updatedConf.push(newConfig);
        } else {
            for (const key in newValues) {
                configForSku[key] = newValues[key];
            }
        }
        setConfig(updatedConf);
    };
    async function handleCopyConfig() {
        setCopyDisabled(true);
        const res = await http.post(
            `/collectors/copyBulkStationConfig`,
            {
                fromId: _.get(collector, '_id', null),
                toId: copyTargetId
            },
            true
        );
        if (res.ok) {
            handleReloadData();
            onSnackbar('Copy Successful');
        } else {
            onSnackbar(res.errorMessage, 'error');
        }
        setTimeout(() => {
            setCopyDisabled(false);
        }, 1000);
    }

    const useSplitView = !fullScreen; //fullscreen is true when screen is "md" or below

    const tableCellStyle = {
        padding: theme.spacing.unit,
        textAlign: 'center'
    };
    const [configDialogOpen, setConfigDialogOpen] = useState(false);
    const [currentConfigForSku, setCurrentConfigForSku] = useState(null);
    const [currentConfigList, setCurrentConfigList] = useState([]);
    let previewConfig = currentConfigList.map(configItem => ({
        ...configItem.configForSku,
        fillAmountRemaining: configItem.configForSku.fillCapacity
    }));
    previewConfig = _.filter(previewConfig, { enabled: true });
    useEffect(() => {
        let newConfigList = [];
        for (let i = 0; i < rates.length; i++) {
            let containerRate = rates[i];
            if (_.isNil(containerRate.sku) || !containerRate.countable || containerRate.skuType !== selectedSkuType)
                continue;
            const configForSku = _.find(config, { sku: containerRate.sku });
            let enabled = _.get(configForSku, 'enabled', false);
            if (enabled) newConfigList.push({ ...containerRate, configForSku: configForSku });
        }

        // adding 'special' items to list (fees, report)
        let specialConfigItems = _.filter(config, c => c && c.special);
        let specialItemCounter = Number.MAX_SAFE_INTEGER;
        for (let configItem of specialConfigItems) {
            newConfigList.push({
                _id: configItem.special,
                sku: specialItemCounter--,
                configForSku: { ...configItem }
            });
        }

        newConfigList.sort((a, b) => {
            return a.configForSku.index - b.configForSku.index;
        });
        setCurrentConfigList(newConfigList);
    }, [rates, config, selectedSkuType]);

    const onDragEnd = ({ destination, source }) => {
        // dropped outside the list
        if (!destination) return;
        let newConfigList = reorder(currentConfigList, source.index, destination.index);
        setCurrentConfigList(newConfigList);
        let updatedConf = _.cloneDeep(config);
        for (let i = 0; i < newConfigList.length; i++) {
            let configID = _.get(newConfigList[i], 'configForSku._id', null);
            if (configID !== null) {
                const configForSku = _.find(updatedConf, { _id: configID });
                configForSku.index = i;
            }
        }
        setConfig(updatedConf);
    };
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };
    const configTable = (
        <>
            <Typography variant="subtitle1" noWrap style={{ height: 25 }}>
                Available
            </Typography>
            <div
                style={{
                    maxHeight: '45%',
                    overflowX: 'hidden',
                    overflowY: 'scroll',
                    marginTop: '10px',
                    borderTop: '1px solid lightgrey'
                }}
            >
                <Grid container spacing={theme.spacing.unit} style={{ marginTop: 10, marginBottom: 10 }}>
                    {rates.map((containerRate, i) => {
                        const configForSku = _.find(config, { sku: containerRate.sku });
                        let description = containerRate.description + ' ' + containerRate.label;
                        let enabled = _.get(configForSku, 'enabled', false);
                        return (
                            containerRate.countable &&
                            containerRate.skuType === selectedSkuType && (
                                <Grid item xs={4} key={i}>
                                    <Button
                                        fullWidth
                                        style={{
                                            color: 'white',
                                            backgroundColor: enabled ? colors.green[800] : 'grey',
                                            textTransform: 'none'
                                        }}
                                        onClick={() => handleToggleEnabled(containerRate.sku)}
                                        data-cy={`toggle-${containerRate.sku}`}
                                    >
                                        {description} - {-containerRate.value}¢ {containerRate.sku}
                                    </Button>
                                </Grid>
                            )
                        );
                    })}
                </Grid>
            </div>
            <Typography variant="subtitle1" noWrap style={{ height: 25, marginTop: 10 }}>
                Current
            </Typography>
            <div style={{ height: '45%', overflow: 'auto' }}>
                <div style={{ flexDirection: 'row', display: 'flex', borderBottom: '1px solid lightgrey' }}>
                    <Typography style={{ ...tableCellStyle, width: '75%', fontSize: 12 }} />
                    <Typography style={{ ...tableCellStyle, width: '11%', fontSize: 12 }}>Edit</Typography>
                    <Typography style={{ ...tableCellStyle, fontSize: 12 }}>Remove</Typography>
                </div>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="skuList">
                        {(provided, snapshot) => (
                            <div ref={provided.innerRef} {...provided.droppableProps}>
                                {currentConfigList.map((configItem, i) => {
                                    const configForSku = _.get(configItem, 'configForSku', {});
                                    let isSpecial = _.get(configForSku, 'special');

                                    return (
                                        <Draggable draggableId={configForSku._id} index={i} key={configForSku._id}>
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={{
                                                        ...provided.draggableProps.style,
                                                        flexDirection: 'row',
                                                        display: 'flex',
                                                        borderBottom: '1px solid lightgrey'
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            ...tableCellStyle,
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            width: '75%'
                                                        }}
                                                    >
                                                        <Grid
                                                            container
                                                            spacing={theme.spacing.unit}
                                                            style={{
                                                                maxWidth: useSplitView ? 472 : undefined
                                                            }}
                                                        >
                                                            <BulkSorterCard
                                                                key={configItem.sku}
                                                                sku={{
                                                                    ...configItem,
                                                                    ...configForSku,
                                                                    fillAmountRemaining: configForSku.fillCapacity
                                                                }}
                                                                disableEditing={true}
                                                                amount={0}
                                                                onAddCountsAndUpdateBulkSorter={() => {}}
                                                                setIdleCountdown={() => {}}
                                                                resetTimeoutCount={() => {}}
                                                                operator={{}}
                                                                bulkDonating={false}
                                                                onCustomFeeDialog={() => {}}
                                                                opaque={true}
                                                            />
                                                        </Grid>
                                                    </div>
                                                    <div
                                                        style={{
                                                            ...tableCellStyle,
                                                            width: '10%',
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            'justify-content': 'center'
                                                        }}
                                                    >
                                                        <IconButton
                                                            onClick={() => {
                                                                setCurrentConfigForSku(configForSku);
                                                                setConfigDialogOpen(true);
                                                            }}
                                                            data-cy={`edit-${configItem.sku}`}
                                                        >
                                                            <Icon>edit</Icon>
                                                        </IconButton>
                                                    </div>
                                                    <div
                                                        style={{
                                                            ...tableCellStyle,
                                                            width: '10%',
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            'justify-content': 'center'
                                                        }}
                                                    >
                                                        {!isSpecial && (
                                                            <IconButton
                                                                onClick={() => handleToggleEnabled(configItem.sku)}
                                                            >
                                                                <Icon style={{ color: colors.red[500] }}>close</Icon>
                                                            </IconButton>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </Draggable>
                                    );
                                })}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        </>
    );

    if (rates.length < 1) {
        return (
            <>
                <Dialog open={open} fullWidth>
                    <DialogContent style={{ paddingBottom: 0 }}>
                        <Typography>Please make sure rates has been configured for this collector.</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose}>{loc('close', lang)}</Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }

    return (
        <>
            <Dialog
                open={open}
                fullWidth
                maxWidth="lg"
                PaperProps={{
                    style: {
                        height: fullScreen ? '100vh' : '90vh'
                    }
                }}
                fullScreen={fullScreen}
            >
                <DialogTitle>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="h6" noWrap style={{}}>
                            Order Station Configuration
                        </Typography>
                        <div
                            style={{ display: 'flex', flexDirection: 'row', flex: 1, marginRight: 50, marginLeft: 50 }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignContent: 'center'
                                }}
                            >
                                <Button
                                    color="primary"
                                    onClick={() => {
                                        handleCopyConfig();
                                    }}
                                    variant="contained"
                                    data-cy={'copyPOR'}
                                    style={{
                                        height: '70%',
                                        marginRight: 10
                                    }}
                                    disabled={copyTargetId === '' || copyDisabled}
                                >
                                    Copy From
                                </Button>
                            </div>
                            <FormControl style={{ flex: 1 }}>
                                <InputLabel variant="outlined" shrink={targetCollectorShrink}>
                                    Target Collector
                                </InputLabel>
                                <Select
                                    input={<OutlinedInput labelWidth={115} />}
                                    onChange={e => {
                                        setCopyTargetId(e.target.value);
                                        setTargetCollectorShrink(true);
                                    }}
                                    value={copyTargetId}
                                    data-cy={'TargetCollector'}
                                >
                                    {collectors.map(c => {
                                        const _id = _.get(c, '_id', '');
                                        const name = _.get(c, 'name', '');
                                        if (_.get(collector, '_id', '') === _id) return null;
                                        return (
                                            <MenuItem key={_id} value={_id} data-cy={_id}>
                                                {name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </div>
                        <FormControl style={{ flex: 1 }}>
                            <InputLabel variant="outlined">Commodity Type</InputLabel>
                            <Select
                                input={
                                    <OutlinedInput labelWidth={'Commodity Type'.length * theme.spacing.unit * 1.2} />
                                }
                                onChange={e => setSelectedSkuType(e.target.value)}
                                value={selectedSkuType}
                                data-cy={'skuSelect'}
                            >
                                {Object.keys(skusGroupedBySkuType)
                                    .filter(skuType => {
                                        const commodity = _.find(commodities, { skuType });
                                        return !_.get(commodity, 'disabled', true);
                                    })
                                    .map(skuType => (
                                        <MenuItem key={skuType} value={skuType} data-cy={skuType}>
                                            {skuType}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </div>
                </DialogTitle>
                <DialogContent style={{ paddingBottom: 0 }}>
                    {useSplitView ? (
                        <div style={{ display: 'flex', height: '100%', width: '100%' }}>
                            <div
                                style={{
                                    height: '100%',
                                    overflow: 'auto',
                                    marginRight: theme.spacing.unit * 2,
                                    flex: 2
                                }}
                            >
                                {configTable}
                            </div>
                            <div
                                style={{
                                    width: 480,
                                    overflow: 'hidden',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: '100%'
                                }}
                            >
                                <Keypad
                                    bulkStationConfig={previewConfig}
                                    skus={skusGroupedBySkuType}
                                    bulkSkuType={selectedSkuType}
                                    disableEditing={true}
                                    amount={0}
                                    bulkDonating={false}
                                    hideIssue
                                    opaque
                                />
                            </div>
                        </div>
                    ) : (
                        <div
                            style={{
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column'
                            }}
                        >
                            <Tabs
                                value={tabSelected}
                                onChange={(e, value) => setTabSelected(value)}
                                style={{ width: '100%', marginBottom: theme.spacing.unit }}
                                indicatorColor="primary"
                                textColor="primary"
                                centered
                            >
                                <Tab label="Configuration" />
                                <Tab label={loc('growthPostInfo5', lang)} />
                            </Tabs>
                            {tabSelected === 0 && (
                                <div
                                    style={{
                                        overflow: 'auto',
                                        flex: 1
                                    }}
                                >
                                    {configTable}
                                </div>
                            )}
                            {tabSelected === 1 && (
                                <Keypad
                                    bulkStationConfig={previewConfig}
                                    skus={skusGroupedBySkuType}
                                    bulkSkuType={selectedSkuType}
                                    disableEditing={true}
                                    amount={0}
                                    bulkDonating={false}
                                    hideIssue
                                    opaque
                                />
                            )}
                        </div>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setCopyTargetId('');
                            onClose();
                        }}
                        data-cy={'orderStationConfigClose'}
                    >
                        {loc('close', lang)}
                    </Button>
                    <Button
                        color="primary"
                        onClick={() => {
                            onSubmit(config);
                            onClose();
                        }}
                        data-cy={'orderStationConfigSubmit'}
                    >
                        {loc('submit', lang)}
                    </Button>
                </DialogActions>
            </Dialog>
            <ConfigurationEditDialog
                open={configDialogOpen}
                collector={collector}
                onClose={() => {
                    setConfigDialogOpen(false);
                }}
                theme={theme}
                handleChangeConfig={handleChangeConfig}
                handleChangeSpecialConfig={handleChangeSpecialConfig}
                currentConfigForSku={currentConfigForSku}
                onSnackbar={onSnackbar}
            />
        </>
    );
}

export default withMobileDialog()(withTheme()(BulkStationConfigurationInput));

//keeps the bulk station buttons in the same order as they appear in the rates except it puts non-conformant last
function sortBulkStationConfig(bulkStationConfig, rates) {
    return _.sortBy(bulkStationConfig, config =>
        config.sku === 9010 ? Infinity : _.findIndex(rates, { sku: config.sku })
    );
}
