import React, { useState, useContext } from 'react';
import moment from 'moment-timezone';

import _ from 'lodash';
import { deviceHelper, formatAsCurrency } from 'utils/misc';
import bulkHelper from 'helpers/bulkHelper';

import { withTheme } from '@material-ui/core/styles';

import {
    Button,
    FormControl,
    FormControlLabel,
    Grid,
    Icon,
    IconButton,
    InputLabel,
    Menu,
    MenuItem,
    OutlinedInput,
    Paper,
    Select,
    TableCell,
    TableRow,
    TextField,
    Tooltip,
    Link,
    Checkbox,
    Dialog,
    DialogContent,
    DialogActions,
    Typography,
    Divider,
    DialogTitle,
    colors,
    CircularProgress
} from '@material-ui/core';

import useTable from 'components/Table/useTable';
import { _user } from 'std';
import { INFRACTION_TYPES } from '../../../constants';

import { openWindow } from 'helpers/windowHelper';

import DashBoardTable from 'components/Table/DashBoardTable';
import DateRangePicker from 'components/DateTimePickersTz/DateRangePicker';
import useDateRangePicker from 'components/DateTimePickersTz/hooks/useDateRangePicker';
import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';
import ConfirmDialogContext from 'components/Dialogs/Confirm/ConfirmDialogContext';
import { last21Days } from 'components/DateTimePickersTz/TimePeriods/TimePeriods';
import { useEffect } from 'react';
import ApproveRedemptionDialog from './Infractions/ApproveRedemptionDialog';

import ImageViewer from 'components/ImageUploads/ImageViewer';

const pendingHeaders = [
    { name: 'Actions', key: null, padding: 'none' },
    { name: 'Type^', key: 'Infraction Type', padding: 'none' },
    { name: 'Reason', key: 'Reason', padding: 'none' },
    { name: 'Collector^', key: 'Collector', padding: 'none' },
    { name: 'Driver^', key: 'Driver', padding: 'none' },
    { name: 'Counter^', key: 'Counter', padding: 'none' },
    { name: 'Name^', key: 'Name', padding: 'none' },
    { name: 'ID', key: null, padding: 'none' },
    { name: 'Infraction Date^', key: 'Infraction Date', padding: 'none' },
    { name: 'Pickup Date^', key: 'Pickup Date', padding: 'none' },
    { name: 'Count Date^', key: 'Count Date', padding: 'none' },
    { name: 'Bulk Type^', key: 'Bulk Type', padding: 'none' },
    { name: 'Address^', key: 'Address', padding: 'none' }
];

const reviewedHeaders = [
    { name: 'Actions', key: null, padding: 'none' },
    { name: 'Resolved On^', key: 'Resolved On', padding: 'none' },
    { name: 'Resolved By^', key: 'Resolved By', padding: 'none' },
    { name: 'Type^', key: 'Infraction Type', padding: 'none' },
    { name: 'Reason', key: 'Reason', padding: 'none' },
    { name: 'Collector^', key: 'Collector', padding: 'none' },
    { name: 'Driver^', key: 'Driver', padding: 'none' },
    { name: 'Counter^', key: 'Counter', padding: 'none' },
    { name: 'Name^', key: 'Name', padding: 'none' },
    { name: 'ID', key: null, padding: 'none' },
    { name: 'Infraction Date^', key: 'Infraction Date', padding: 'none' },
    { name: 'Pickup Date^', key: 'Pickup Date', padding: 'none' },
    { name: 'Count Date^', key: 'Count Date', padding: 'none' },
    { name: 'Bulk Type^', key: 'Bulk Type', padding: 'none' },
    { name: 'Address^', key: 'Address', padding: 'none' }
];

const infractionTypes = [
    INFRACTION_TYPES.AUDIT_TYPE_BAGCOUNT,
    INFRACTION_TYPES.AUDIT_TYPE_DRIVERCOMPLAINT,
    INFRACTION_TYPES.AUDIT_TYPE_AMOUNTDIFFERENCE,
    INFRACTION_TYPES.AUDIT_TYPE_COUNTERCOMPLAINT,
    INFRACTION_TYPES.AUDIT_TYPE_REDEMPTIONAMOUNT,
    INFRACTION_TYPES.AUDIT_TYPE_REDEMPTIONEMAIL,
    INFRACTION_TYPES.AUDIT_TYPE_SAMEIPADDRESS,
    INFRACTION_TYPES.AUDIT_TYPE_HAZARDOUS,
    INFRACTION_TYPES.AUDIT_TYPE_GARBAGE
];

const Infractions = ({ theme, http, onSnackbar, history, operator, auth, setRefreshUnresolvedInfractionCount }) => {
    const collapseSearch = useMediaQuery(theme.breakpoints.down('sm'));
    const { lang } = useContext(LocalizationContext);
    const warnAction = useContext(ConfirmDialogContext);
    const [filtersOpen, setFiltersOpen] = useState(null);
    const [reviewedFiltersOpen, setReviewedFiltersOpen] = useState(null);
    const [checkedPendingInfractions, setCheckedPendingInfractions] = useState([]);
    const [checkedReviewedInfractions, setCheckedReviewedInfractions] = useState([]);
    const [currentRedemptionList, setCurrentRedemptionList] = useState([]);
    const [showRedemptionDialog, setShowRedemptionDialog] = useState(false);
    const [currentImages, setCurrentImages] = useState([]);
    const [imageDialogOpen, setImageDialogOpen] = useState(false);
    const [showInfoDialog, setShowInfoDialog] = useState(false);
    const [showResolveCustomerComplaintsDialog, setShowResolveCustomerComplaintsDialog] = useState(false);
    const [selectedInfraction, setSelectedInfraction] = useState({});
    const [selectedInfractionId, setSelectedInfractionId] = useState('');
    const [selectedInfractionTypes, setSelectedInfractionTypes] = useState(
        JSON.parse(window.localStorage.getItem(`/infractions-selectedTypes`)) || infractionTypes
    );
    const [searchInfractionTypes, setSearchInfractionTypes] = useState(
        JSON.parse(window.localStorage.getItem(`/infractions-selectedTypes`)) || infractionTypes
    );
    const [selectedDateType, setSelectedDateType] = useState(
        window.localStorage.getItem(`/infractions-selectedDateType`) || 'infractionDate'
    );
    const [timer, setTimer] = useState();
    const [customerComplaintOrders, setCustomerComplaintOrders] = useState([]);
    const [showContactDialog, setShowContactDialog] = useState(false);
    const [hiddenUnresolvedInfractions, setHiddenUnresolvedInfractions] = useState(0);
    const [hiddenResolvedInfractions, setHiddenResolvedInfractions] = useState(0);

    const {
        startDate: pendingInfractionStartDate,
        endDate: pendingInfractionEndDate,
        timezone: pendingTimezone,
        dateWindow: pendingDateWindow,
        handleChangeStartDate: handleChangePendingStartDate,
        handleChangeEndDate: handleChangePendingEndDate,
        handleGoForwards: handlePendingGoForwards,
        handleGoBackwards: handlePendingGoBackwards,
        handleWindowSelect: handlePendingWindowSelect
    } = useDateRangePicker({
        saveStateInURL: true,
        timezones: [process.env.REACT_APP_REGION_TIMEZONE],
        initStartVal: last21Days.startDate,
        initEndVal: last21Days.endDate,
        initDateWindow: 'last21Days'
    });

    const {
        loading,
        getData,
        page,
        rowsPerPage,
        data,
        filteredData,
        search,
        filteredHeaders,
        totalDataEntries,
        handleChange,
        handleSearch,
        handleChangeFilterHeaders,
        handleAllFilterHeaders,
        handleChangePage,
        handleChangeRowsPerPage
    } = useTable({
        url: '/infractions',
        key: 'infractions',
        headers: pendingHeaders.map(h => h.name),
        queryObj: {
            resolved: false,
            infractionDate:
                selectedDateType === 'infractionDate'
                    ? {
                          $gte: pendingInfractionStartDate.toISOString(),
                          $lte: pendingInfractionEndDate.toISOString()
                      }
                    : undefined,
            resolutionDate:
                selectedDateType === 'resolutionDate'
                    ? {
                          $gte: pendingInfractionStartDate.toISOString(),
                          $lte: pendingInfractionEndDate.toISOString()
                      }
                    : undefined,
            pickupDate:
                selectedDateType === 'pickupDate'
                    ? {
                          $gte: pendingInfractionStartDate.toISOString(),
                          $lte: pendingInfractionEndDate.toISOString()
                      }
                    : undefined,
            countDate:
                selectedDateType === 'countDate'
                    ? {
                          $gte: pendingInfractionStartDate.toISOString(),
                          $lte: pendingInfractionEndDate.toISOString()
                      }
                    : undefined,
            infractionType: {
                $in: searchInfractionTypes
            }
        }
    });

    const {
        loading: loading2,
        getData: getData2,
        page: page2,
        rowsPerPage: rowsPerPage2,
        data: data2,
        filteredData: filteredData2,
        search: search2,
        filteredHeaders: filteredHeaders2,
        totalDataEntries: totalDataEntries2,
        handleChange: handleChange2,
        handleSearch: handleSearch2,
        handleChangeFilterHeaders: handleChangeFilterHeaders2,
        handleAllFilterHeaders: handleAllFilterHeaders2,
        handleChangePage: handleChangePage2,
        handleChangeRowsPerPage: handleChangeRowsPerPage2
    } = useTable({
        url: '/infractions',
        key: 'infractions',
        headers: reviewedHeaders.map(h => h.name),
        queryObj: {
            resolved: true,
            infractionDate:
                selectedDateType === 'infractionDate'
                    ? {
                          $gte: pendingInfractionStartDate.toISOString(),
                          $lte: pendingInfractionEndDate.toISOString()
                      }
                    : undefined,
            resolutionDate:
                selectedDateType === 'resolutionDate'
                    ? {
                          $gte: pendingInfractionStartDate.toISOString(),
                          $lte: pendingInfractionEndDate.toISOString()
                      }
                    : undefined,
            pickupDate:
                selectedDateType === 'pickupDate'
                    ? {
                          $gte: pendingInfractionStartDate.toISOString(),
                          $lte: pendingInfractionEndDate.toISOString()
                      }
                    : undefined,
            countDate:
                selectedDateType === 'countDate'
                    ? {
                          $gte: pendingInfractionStartDate.toISOString(),
                          $lte: pendingInfractionEndDate.toISOString()
                      }
                    : undefined,
            infractionType: {
                $in: searchInfractionTypes
            }
        }
    });

    async function getUnresolvedInfractionCount() {
        let res = await http.getJSON(`/infractions/unresolvedCount`, false, true);
        if (res.ok) {
            let newCount = _.get(res, 'data', 0);
            if (!_.isNil(res.data)) {
                setHiddenUnresolvedInfractions(newCount - totalDataEntries);
                return;
            }
        }
        setHiddenUnresolvedInfractions(0);
    }
    useEffect(() => {
        getUnresolvedInfractionCount();
    }, [totalDataEntries]);

    async function getResolvedInfractionCount() {
        let res = await http.getJSON(`/infractions/resolvedCount`, false, true);
        if (res.ok) {
            let newCount = _.get(res, 'data', 0);
            if (!_.isNil(res.data)) {
                setHiddenResolvedInfractions(newCount - totalDataEntries2);
                return;
            }
        }
        setHiddenResolvedInfractions(0);
    }
    useEffect(() => {
        getResolvedInfractionCount();
    }, [totalDataEntries2]);

    const textToInfractionTypeMap = React.useMemo(() => {
        let textToInfractionTypeMap = {};
        for (let infractionType in INFRACTION_TYPES) {
            textToInfractionTypeMap[loc(infractionType, lang)] = infractionType;
        }
        return textToInfractionTypeMap;
    }, []);

    useEffect(() => {
        let newSelectedInfraction = _.find(filteredData, { _id: selectedInfractionId });
        if (_.isNil(newSelectedInfraction)) {
            newSelectedInfraction = _.find(filteredData2, { _id: selectedInfractionId });
        }
        if (!_.isNil(newSelectedInfraction)) {
            setSelectedInfraction(newSelectedInfraction);
        }
    }, [filteredData, filteredData2]);

    const handleView = _id => {
        const URL = `/customers/${_id}`;
        openWindow(history, !deviceHelper.isNativeApp(), URL);
    };

    const handleViewDriver = document => {
        if (_.isNil(document)) return;
        let extension = '';
        if (_user.isDriver(document) || _user.isClerk(document)) {
            extension = 'driver';
        } else if (document.accountType === 'Collector Administrator') {
            extension = 'collector';
        }
        const URL = `/operators/${document._id}/${extension}`;
        openWindow(history, !deviceHelper.isNativeApp(), URL);
    };

    const onViewBulk = bulkId => {
        const URL = `/operators/${_user.getId(operator)}/bulks/${bulkId}`;
        openWindow(history, !deviceHelper.isNativeApp(), URL);
    };

    const handleViewTrip = (collector, reciever, date) => {
        date = new Date(new Date(date).toDateString());
        const URL = `/operators/${auth._id}/collector-completed?collector=${collector}&date=${moment(
            date,
            'YYYYMMDD'
        ).toISOString()}&receiver=${reciever}`;
        openWindow(history, !deviceHelper.isNativeApp(), URL);
    };

    const handleFiltersOpen = close => e => {
        if (close || Boolean(filtersOpen)) {
            setFiltersOpen(null);
        } else {
            setFiltersOpen(e.currentTarget);
        }
    };
    const handleSubmit = e => {
        handleSearch(e);
        handleSearch2(e);
        if (filtersOpen) {
            setFiltersOpen(false);
        }
        if (reviewedFiltersOpen) {
            setReviewedFiltersOpen(false);
        }
    };

    const onChangeFilterHeader = e => {
        handleChangeFilterHeaders2(e);
        handleChangeFilterHeaders(e);
    };

    const onChangeAllFilterHeaders = all => e => {
        handleAllFilterHeaders(all)(e);
        handleAllFilterHeaders2(all)(e);
    };

    const handleChangeAll = e => {
        e.target.value =
            textToInfractionTypeMap[e.target.value] !== undefined
                ? textToInfractionTypeMap[e.target.value]
                : e.target.value;
        handleChange(e);
        handleChange2(e);
    };

    function handleViewInfo(infraction) {
        setSelectedInfraction(infraction);
        setSelectedInfractionId(_.get(infraction, '_id', ''));
        setShowInfoDialog(true);
    }

    async function resolveInfractions({ infractionIds = checkedPendingInfractions, customerIDs = [] }) {
        const res = await http.post(`/infractions/resolveInfractions`, {
            infractionIds: infractionIds
        });
        if (res.ok) {
            setTimeout(() => {
                getData();
                getData2();
            }, 50);
            let hasCustomerComplaintOrder = await getCustomerComplaintOrders(infractionIds);
            onSnackbar('Infraction(s) resolved.');
            setCheckedPendingInfractions([]);
            await promptRedemptionApproval(customerIDs, hasCustomerComplaintOrder);
            setRefreshUnresolvedInfractionCount(true);
        }
    }

    async function unresolveInfractions(infractionIds = checkedReviewedInfractions) {
        const res = await http.post(`/infractions/unresolveInfractions`, {
            infractionIds: infractionIds
        });
        if (res.ok) {
            setTimeout(() => {
                getData();
                getData2();
            }, 50);
            onSnackbar('Infraction(s) unresolved.');
            setCheckedReviewedInfractions([]);
            setRefreshUnresolvedInfractionCount(true);
        }
    }

    async function promptRedemptionApproval(customerIDs, hasCustomerComplaintOrder) {
        let URL = `/redemptions/getUnderReviewByClaimants`;
        const res = await http.postJSON(URL, {
            claimantIds: customerIDs
        });
        let haveUnderReviewRedemptions = false;
        if (res.ok) {
            if (res.data.redemptions.length > 0) {
                let underReviewRedemptions = [];
                res.data.redemptions.forEach(redemption => {
                    let method = '';
                    switch (redemption.paymentModel) {
                        case 'INTERAC_ETRANSFERS':
                            method = (
                                <span style={{ color: theme.palette.primary.main, fontWeight: 500 }}>e-Transfer</span>
                            );
                            break;
                        case 'CHEQUE':
                            method = (
                                <span style={{ color: theme.palette.secondary.main, fontWeight: 500 }}>
                                    {loc('redemption5', lang)}
                                </span>
                            );
                            break;
                        case 'AUS_DIRECT_BANKING':
                            method = (
                                <span style={{ color: theme.palette.secondary.main, fontWeight: 500 }}>
                                    {loc('directDeposit', lang)}
                                </span>
                            );
                            break;
                        default:
                            break;
                    }
                    let redemptionValue = {
                        _id: redemption._id,
                        email: redemption.email,
                        name: (
                            _.get(redemption, 'claimant.name.first', 'Guest') +
                            ' ' +
                            _.get(redemption, 'claimant.name.last', 'account')
                        ).trim(),

                        dateRequested: moment(redemption.createdAt)
                            .tz(process.env.REACT_APP_REGION_TIMEZONE)
                            .format('LT MMM Do, YYYY'),
                        amount: formatAsCurrency(redemption.amount - redemption.fee),
                        type: redemption.type,
                        method: method,
                        customerId:  _.get(redemption, 'claimant._id', null)
                    };
                    underReviewRedemptions.push(redemptionValue);
                });
                haveUnderReviewRedemptions = true;
                setCurrentRedemptionList(underReviewRedemptions);
                setShowRedemptionDialog(true);
            }
        }
        if (
            !haveUnderReviewRedemptions &&
            (hasCustomerComplaintOrder || (customerComplaintOrders && customerComplaintOrders.length > 0))
        ) {
            setShowResolveCustomerComplaintsDialog(true);
        }
    }

    function handlePendingCheckbox() {
        if (checkedPendingInfractions.length === totalDataEntries || checkedPendingInfractions.length === rowsPerPage) {
            setCheckedPendingInfractions([]);
        } else {
            let newCheckedPendingInfractions = Array.from(filteredData, infraction => {
                return infraction._id;
            });
            setCheckedPendingInfractions(newCheckedPendingInfractions);
        }
    }
    function handleReviewedCheckbox() {
        if (
            checkedReviewedInfractions.length === totalDataEntries2 ||
            checkedReviewedInfractions.length === rowsPerPage
        ) {
            setCheckedReviewedInfractions([]);
        } else {
            let newCheckedReviewedInfractions = Array.from(filteredData2, infraction => {
                return infraction._id;
            });
            setCheckedReviewedInfractions(newCheckedReviewedInfractions);
        }
    }
    function handleViewImages(images, filterBySku = false) {
        if (!filterBySku) {
            setCurrentImages(images);
            setImageDialogOpen(true);
            return;
        }

        const skuTypes = _.get(selectedInfraction, 'skuTypes', []);
        const filteredImages = getSkuFilteredImages(images, skuTypes);

        setCurrentImages(filteredImages);
        setImageDialogOpen(true);
    }

    async function getCustomerComplaintOrders(infractionIds) {
        let orderIds = [];
        let checkedPendingInfractionObjs = filteredData.filter(infraction => infractionIds.includes(infraction._id));
        checkedPendingInfractionObjs.forEach(infraction => {
            let orderId = _.get(infraction, 'order', null);
            if (!_.isNil(orderId)) {
                orderIds.push(orderId);
            }
        });
        let URL = `/bulks/getBulksByIds`;
        const res = await http.postJSON(URL, {
            orderIds: orderIds
        });
        let complaintOrders = [];
        if (res.ok) {
            if (res.data.bulks.length > 0) {
                res.data.bulks.forEach(order => {
                    if (bulkHelper.hasUnresolvedCustomerIssue(order)) {
                        complaintOrders.push(order);
                    }
                });
            }
            setCustomerComplaintOrders(complaintOrders);
            if (complaintOrders.length > 0) {
                return true;
            }
        }
        return false;
    }
    async function refreshCustomerComplaintOrders() {
        let orderIds = Array.from(customerComplaintOrders, order => order._id);
        let URL = `/bulks/getBulksByIds`;
        const res = await http.postJSON(URL, {
            orderIds: orderIds
        });
        if (res.ok) {
            setCustomerComplaintOrders(res.data.bulks);
        }
    }

    const inputStyles = {
        margin: theme.spacing.unit / 2
    };

    return (
        <>
            <ApproveRedemptionDialog
                http={http}
                open={showRedemptionDialog}
                onClose={() => {
                    if (customerComplaintOrders && customerComplaintOrders.length > 0) {
                        setShowResolveCustomerComplaintsDialog(true);
                    }
                    setShowRedemptionDialog(false);
                }}
                redemptionRows={currentRedemptionList}
                onSnackbar={onSnackbar}
            />
            <PickupImageDialog
                open={imageDialogOpen}
                theme={theme}
                onClose={() => {
                    setImageDialogOpen(false);
                }}
                imagesToView={currentImages}
            />
            <InfoDialog
                open={showInfoDialog}
                onClose={() => {
                    setShowInfoDialog(false);
                }}
                lang={lang}
                infraction={selectedInfraction}
                http={http}
                onViewBulk={onViewBulk}
                handleViewTrip={handleViewTrip}
                handleViewDriver={handleViewDriver}
                resolveInfractions={resolveInfractions}
                unresolveInfractions={unresolveInfractions}
                handleView={handleView}
                handleViewImages={handleViewImages}
                setShowContactDialog={setShowContactDialog}
                theme={theme}
            />
            <ResolveCustomerComplaintsDialog
                open={showResolveCustomerComplaintsDialog}
                onClose={() => {
                    setShowResolveCustomerComplaintsDialog(false);
                    setCustomerComplaintOrders([]);
                }}
                customerComplaintOrders={customerComplaintOrders}
                theme={theme}
                operator={operator}
                http={http}
                onSnackbar={onSnackbar}
                lang={lang}
                refreshCustomerComplaintOrders={refreshCustomerComplaintOrders}
                warnAction={warnAction}
                handleViewImages={handleViewImages}
                onViewBulk={onViewBulk}
            />
            <ContactDialog
                open={showContactDialog}
                lang={lang}
                theme={theme}
                selectedInfraction={selectedInfraction}
                onClose={() => {
                    setShowContactDialog(false);
                }}
                http={http}
                onSnackbar={onSnackbar}
            />
            <Grid container>
                <Grid item xs={12}>
                    <div style={{ padding: theme.spacing.unit * 2, paddingBottom: theme.spacing.unit }}>
                        <Paper
                            style={{
                                display: 'flex',
                                padding: theme.spacing.unit * 2,
                                height: '100%',
                                flexWrap: 'wrap'
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexWrap: 'wrap'
                                }}
                            >
                                <form onSubmit={handleSubmit} style={{ flex: '0 1 100%' }}>
                                    <RenderSearch
                                        filtersOpen={filtersOpen}
                                        handleFiltersOpen={handleFiltersOpen}
                                        collapseSearch={collapseSearch}
                                        theme={theme}
                                    >
                                        <div style={{ flexDirection: 'row', display: 'flex', 'flex-wrap': 'wrap' }}>
                                            <FormControl style={inputStyles}>
                                                <InputLabel htmlFor="collection" variant="outlined">
                                                    Type Filters
                                                </InputLabel>
                                                <Select
                                                    variant="outlined"
                                                    multiple
                                                    value={selectedInfractionTypes}
                                                    onChange={e => {
                                                        window.localStorage.setItem(
                                                            `/infractions-selectedTypes`,
                                                            JSON.stringify(e.target.value)
                                                        );
                                                        setSelectedInfractionTypes(e.target.value);
                                                        clearTimeout(timer);
                                                        setTimer(
                                                            setTimeout(
                                                                () => setSearchInfractionTypes(e.target.value),
                                                                1000
                                                            )
                                                        );
                                                    }}
                                                    style={{ width: 175 }}
                                                    input={<OutlinedInput labelWidth={140} />}
                                                    data-cy="infraction-type-select"
                                                >
                                                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                        <Button
                                                            onClick={e => {
                                                                e.stopPropagation();
                                                                clearTimeout(timer);
                                                                window.localStorage.setItem(
                                                                    `/infractions-selectedTypes`,
                                                                    JSON.stringify(infractionTypes)
                                                                );
                                                                setSelectedInfractionTypes(infractionTypes);
                                                                setSearchInfractionTypes(infractionTypes);
                                                            }}
                                                            data-cy="infraction-type-select-all"
                                                        >
                                                            All
                                                        </Button>
                                                        <Button
                                                            onClick={e => {
                                                                e.stopPropagation();
                                                                clearTimeout(timer);
                                                                window.localStorage.setItem(
                                                                    `/infractions-selectedTypes`,
                                                                    JSON.stringify([])
                                                                );
                                                                setSelectedInfractionTypes([]);
                                                                setSearchInfractionTypes([]);
                                                            }}
                                                            data-cy="infraction-type-select-none"
                                                        >
                                                            None
                                                        </Button>
                                                    </div>
                                                    {infractionTypes.map(infractionType => (
                                                        <MenuItem
                                                            key={infractionType}
                                                            value={infractionType}
                                                            data-cy={`infraction-type-select-${infractionType}`}
                                                        >
                                                            {loc(infractionType, lang)}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            <FormControl style={inputStyles}>
                                                <InputLabel htmlFor="collection" variant="outlined">
                                                    Column Filters
                                                </InputLabel>
                                                <Select
                                                    variant="outlined"
                                                    multiple
                                                    value={filteredHeaders2}
                                                    onChange={onChangeFilterHeader}
                                                    style={{ width: 175 }}
                                                    input={<OutlinedInput labelWidth={140} />}
                                                >
                                                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                        <Button onClick={onChangeAllFilterHeaders(true)}>All</Button>
                                                        <Button onClick={onChangeAllFilterHeaders(false)}>None</Button>
                                                    </div>
                                                    {reviewedHeaders.map(({ name }) => (
                                                        <MenuItem key={name} value={name}>
                                                            {_.startCase(name)}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <FormControl style={inputStyles}>
                                                    <InputLabel htmlFor="collection" variant="outlined">
                                                        Date Type
                                                    </InputLabel>
                                                    <Select
                                                        disabled={loading}
                                                        value={selectedDateType}
                                                        onChange={e => {
                                                            const { value } = e.target;
                                                            setSelectedDateType(value);
                                                        }}
                                                        style={{
                                                            maxWidth: '250px',
                                                            margin: theme.spacing.unit
                                                        }}
                                                    >
                                                        <MenuItem value="infractionDate">Infraction Date</MenuItem>
                                                        <MenuItem value="resolutionDate">Resolution Date</MenuItem>
                                                        <MenuItem value="pickupDate">Pickup Date</MenuItem>
                                                        <MenuItem value="countDate">Count Date</MenuItem>
                                                    </Select>
                                                </FormControl>
                                                <DateRangePicker
                                                    timezone={pendingTimezone}
                                                    startDate={pendingInfractionStartDate}
                                                    endDate={pendingInfractionEndDate}
                                                    window={pendingDateWindow}
                                                    disabled={loading}
                                                    handlePrevious={handlePendingGoBackwards}
                                                    handleNext={handlePendingGoForwards}
                                                    handleChangeStartDate={handleChangePendingStartDate}
                                                    handleChangeEndDate={handleChangePendingEndDate}
                                                    startLabel={'Start Date'}
                                                    endLabel={'End Date'}
                                                    inputStyle={{ width: 120 }}
                                                />
                                                <FormControl>
                                                    <Select
                                                        disabled={loading}
                                                        value={pendingDateWindow}
                                                        onChange={handlePendingWindowSelect}
                                                        style={{
                                                            maxWidth: '250px',
                                                            margin: theme.spacing.unit
                                                        }}
                                                    >
                                                        <MenuItem value="today">Daily</MenuItem>
                                                        <MenuItem value="thisWeek">Weekly</MenuItem>
                                                        <MenuItem value="thisMonth">Monthly</MenuItem>
                                                        <MenuItem value="thisQuarter">Quarterly</MenuItem>
                                                        <MenuItem value="last21Days">Last 21 Days</MenuItem>
                                                        <MenuItem value="allTime">All Time</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <>
                                                <TextField
                                                    variant="outlined"
                                                    name="search"
                                                    value={search2 || ''}
                                                    onChange={handleChangeAll}
                                                    placeholder="Search"
                                                    style={{
                                                        ...inputStyles,
                                                        width: 250
                                                    }}
                                                />

                                                {
                                                    <Button
                                                        color="primary"
                                                        disabled={loading || loading2}
                                                        variant="contained"
                                                        data-cy="dashboard-search-button"
                                                        type="submit"
                                                        onClick={handleSubmit}
                                                    >
                                                        <Icon>search</Icon>
                                                    </Button>
                                                }
                                            </>
                                        </div>
                                    </RenderSearch>

                                    {collapseSearch && (
                                        <div style={{ textAlign: 'right' }}>
                                            <IconButton onClick={handleFiltersOpen()} data-cy="infraction-filters">
                                                <Icon>filter_list</Icon>
                                            </IconButton>
                                        </div>
                                    )}
                                </form>
                            </div>
                        </Paper>
                    </div>
                </Grid>
            </Grid>
            <>
                <DashBoardTable
                    title="Pending Infractions"
                    loading={loading}
                    headers={pendingHeaders}
                    filteredHeaders={filteredHeaders}
                    length={totalDataEntries}
                    hiddenCount={hiddenUnresolvedInfractions}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    handleChangePage={(e, newPage) => {
                        handleChangePage(e, newPage);
                        setCheckedPendingInfractions([]);
                    }}
                    handleChangeRowsPerPage={e => {
                        handleChangeRowsPerPage(e);
                        setCheckedPendingInfractions([]);
                    }}
                    refresh={getData}
                    checkbox
                    handleCheckbox={handlePendingCheckbox}
                    checkedItems={checkedPendingInfractions.length}
                    cellStyle={{ paddingLeft: 12, paddingRight: 12 }}
                    customActionAlign="left"
                    customAction={
                        <Button
                            data-cy="resolve"
                            variant="contained"
                            color="secondary"
                            disabled={checkedPendingInfractions.length === 0}
                            style={{}}
                            onClick={() => {
                                warnAction(() => {
                                    let customerIDs = [];
                                    let checkedPendingInfractionObjs = filteredData.filter(infraction =>
                                        checkedPendingInfractions.includes(infraction._id)
                                    );
                                    checkedPendingInfractionObjs.forEach(infraction => {
                                        let customerID = _.get(infraction, 'user', null);
                                        if (!_.isNil(customerID)) {
                                            customerIDs.push(customerID);
                                        }
                                    });
                                    resolveInfractions({
                                        customerIDs: customerIDs
                                    });
                                }, `Resolve ${checkedPendingInfractions.length} infraction(s)?`);
                            }}
                        >
                            Resolve
                        </Button>
                    }
                    customActionStyle={{ paddingTop: 0 }}
                >
                    {filteredData &&
                        filteredData.map(infraction => {
                            return (
                                <InfractionRows
                                    infraction={infraction}
                                    filteredHeaders={filteredHeaders}
                                    checkedReviewedInfractions={checkedReviewedInfractions}
                                    checkedPendingInfractions={checkedPendingInfractions}
                                    setCheckedReviewedInfractions={setCheckedReviewedInfractions}
                                    setCheckedPendingInfractions={setCheckedPendingInfractions}
                                    handleView={handleView}
                                    handleViewDriver={handleViewDriver}
                                    lang={lang}
                                    onViewBulk={onViewBulk}
                                    handleViewTrip={handleViewTrip}
                                    resolveInfractions={resolveInfractions}
                                    handleViewInfo={handleViewInfo}
                                    theme={theme}
                                />
                            );
                        })}
                </DashBoardTable>
            </>
            <>
                <DashBoardTable
                    title="Reviewed Infractions"
                    loading={loading2}
                    headers={reviewedHeaders}
                    filteredHeaders={filteredHeaders2}
                    length={totalDataEntries2}
                    hiddenCount={hiddenResolvedInfractions}
                    page={page2}
                    rowsPerPage={rowsPerPage2}
                    handleChangePage={(e, newPage) => {
                        handleChangePage2(e, newPage);
                        setCheckedReviewedInfractions([]);
                    }}
                    handleChangeRowsPerPage={e => {
                        handleChangeRowsPerPage2(e);
                        setCheckedReviewedInfractions([]);
                    }}
                    refresh={getData2}
                    checkbox
                    handleCheckbox={handleReviewedCheckbox}
                    checkedItems={checkedReviewedInfractions.length}
                    cellStyle={{ paddingLeft: 12, paddingRight: 12 }}
                    isReviewedInfractionsTable
                    customActionAlign="left"
                    customAction={
                        <Button
                            data-cy="unresolve"
                            variant="contained"
                            color="secondary"
                            disabled={checkedReviewedInfractions.length === 0}
                            style={{}}
                            onClick={() => {
                                warnAction(() => {
                                    unresolveInfractions();
                                }, `Unresolve ${checkedReviewedInfractions.length} infraction(s)?`);
                            }}
                        >
                            Unresolve
                        </Button>
                    }
                    customActionStyle={{ paddingTop: 0 }}
                >
                    {filteredData2 &&
                        filteredData2.map(infraction => {
                            return (
                                <InfractionRows
                                    infraction={infraction}
                                    filteredHeaders={filteredHeaders2}
                                    checkedReviewedInfractions={checkedReviewedInfractions}
                                    checkedPendingInfractions={checkedPendingInfractions}
                                    setCheckedReviewedInfractions={setCheckedReviewedInfractions}
                                    setCheckedPendingInfractions={setCheckedPendingInfractions}
                                    handleView={handleView}
                                    handleViewDriver={handleViewDriver}
                                    lang={lang}
                                    onViewBulk={onViewBulk}
                                    handleViewTrip={handleViewTrip}
                                    unresolveInfractions={unresolveInfractions}
                                    handleViewInfo={handleViewInfo}
                                    theme={theme}
                                />
                            );
                        })}
                </DashBoardTable>
            </>
        </>
    );
};

export default withTheme()(Infractions);

const cellStyle = { whiteSpace: 'nowrap', paddingLeft: 12, paddingRight: 12 };
const InfractionRows = props => {
    const {
        infraction,
        filteredHeaders,
        checkedReviewedInfractions,
        checkedPendingInfractions,
        setCheckedReviewedInfractions,
        setCheckedPendingInfractions,
        handleView,
        handleViewDriver,
        lang,
        onViewBulk,
        handleViewTrip,
        resolveInfractions = () => {},
        unresolveInfractions = () => {},
        handleViewInfo,
        theme
    } = props;
    const {
        _id,
        user,
        infractionType,
        reason,
        resolved,
        outstandingValue,
        thresholdValue,
        infractionDate,
        resolutionDate,
        collector,
        driver,
        counter,
        order,
        redemption,
        address,
        userFullName,
        userUniqueID,
        userBusinessName,
        driverFullName,
        counterFullName,
        collectorName,
        pickup,
        pickupDate,
        countDate,
        resolvedBy,
        resolverFullName,
        bulkType
    } = infraction;
    let isReviewedTable = resolved;
    let allHeaders = isReviewedTable ? reviewedHeaders : pendingHeaders;
    return (
        <TableRow key={_id}>
            <TableCell padding="checkbox">
                <Checkbox
                    checked={
                        isReviewedTable
                            ? checkedReviewedInfractions.includes(_id)
                            : checkedPendingInfractions.includes(_id)
                    }
                    onChange={e => {
                        if (isReviewedTable) {
                            if (!e.target.checked) {
                                setCheckedReviewedInfractions(
                                    checkedReviewedInfractions.filter(element => {
                                        return element !== _id;
                                    })
                                );
                            } else {
                                setCheckedReviewedInfractions([...checkedReviewedInfractions, _id]);
                            }
                        } else {
                            if (!e.target.checked) {
                                setCheckedPendingInfractions(
                                    checkedPendingInfractions.filter(element => {
                                        return element !== _id;
                                    })
                                );
                            } else {
                                setCheckedPendingInfractions([...checkedPendingInfractions, _id]);
                            }
                        }
                    }}
                />
            </TableCell>
            {filteredHeaders.includes('Actions') && (
                <TableCell
                    style={{ ...cellStyle, paddingLeft: 0, paddingRight: 0 }}
                    padding={_.get(_.find(allHeaders, { name: 'Actions' }), 'padding', 'default')}
                >
                    {isReviewedTable ? (
                        <Tooltip title="Unresolve">
                            <IconButton
                                onClick={() => {
                                    unresolveInfractions([_id]);
                                }}
                            >
                                <Icon>close</Icon>
                            </IconButton>
                        </Tooltip>
                    ) : (
                        <Tooltip title="Resolve">
                            <IconButton
                                onClick={() => {
                                    resolveInfractions({ infractionIds: [_id], customerIDs: [user] });
                                }}
                            >
                                <Icon>check</Icon>
                            </IconButton>
                        </Tooltip>
                    )}
                    <Tooltip title="View Details">
                        <IconButton
                            onClick={() => {
                                handleViewInfo(infraction);
                            }}
                            color="primary"
                        >
                            <Icon>info</Icon>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="View Customer">
                        <IconButton onClick={() => handleView(user)}>
                            <Icon>visibility</Icon>
                        </IconButton>
                    </Tooltip>
                    {!_.isNil(order) && (
                        <Tooltip title="View Order">
                            <IconButton
                                onClick={() => {
                                    onViewBulk(order);
                                }}
                            >
                                <Icon>ballot</Icon>
                            </IconButton>
                        </Tooltip>
                    )}
                    {!_.isNil(_.get(pickup, '_id', null)) &&
                        !_.isNil(collector) &&
                        !_.isNil(_.get(driver, '_id', null)) &&
                        !_.isNil(infractionDate) && (
                            <Tooltip title="View Trip">
                                <IconButton
                                    onClick={e => handleViewTrip(collector, _.get(driver, '_id', ''), infractionDate)}
                                >
                                    <Icon>local_shipping</Icon>
                                </IconButton>
                            </Tooltip>
                        )}
                </TableCell>
            )}
            {filteredHeaders.includes('Resolved On^') && isReviewedTable && (
                <TableCell
                    style={cellStyle}
                    padding={_.get(_.find(allHeaders, { name: 'Resolved On^' }), 'padding', 'default')}
                >
                    {!_.isNil(resolutionDate) ? moment(resolutionDate).format('ddd, MMM DD YYYY') : ''}
                </TableCell>
            )}
            {filteredHeaders.includes('Resolved By^') && isReviewedTable && (
                <TableCell
                    style={cellStyle}
                    padding={_.get(_.find(allHeaders, { name: 'Resolved By^' }), 'padding', 'default')}
                    onClick={() => {
                        handleViewDriver(resolvedBy);
                    }}
                >
                    <Link>{resolverFullName}</Link>
                </TableCell>
            )}
            {filteredHeaders.includes('Type^') && (
                <TableCell
                    style={{
                        ...cellStyle,
                        whiteSpace: 'normal',
                        wordWrap: 'break-word'
                    }}
                    padding={_.get(_.find(allHeaders, { name: 'Type^' }), 'padding', 'default')}
                >
                    <p style={{ width: 150 }}>{loc(infractionType, lang)}</p>
                </TableCell>
            )}
            {filteredHeaders.includes('Reason') && (
                <TableCell
                    style={{
                        ...cellStyle,
                        whiteSpace: 'normal',
                        wordWrap: 'break-word'
                    }}
                    padding={_.get(_.find(allHeaders, { name: 'Reason' }), 'padding', 'default')}
                >
                    <p style={{ width: 300 }}>
                        <BoldedReason
                            reason={reason}
                            type={infractionType}
                            theme={theme}
                            fontSizeMultiplier={0.92}
                            basic
                        />
                    </p>
                </TableCell>
            )}
            {filteredHeaders.includes('Collector^') && (
                <TableCell
                    style={cellStyle}
                    padding={_.get(_.find(allHeaders, { name: 'Collector^' }), 'padding', 'default')}
                >
                    {collectorName}
                </TableCell>
            )}
            {filteredHeaders.includes('Driver^') && (
                <TableCell
                    style={cellStyle}
                    padding={_.get(_.find(allHeaders, { name: 'Driver^' }), 'padding', 'default')}
                    onClick={() => {
                        handleViewDriver(driver);
                    }}
                >
                    <Link>{driverFullName}</Link>
                </TableCell>
            )}
            {filteredHeaders.includes('Counter^') && (
                <TableCell
                    style={cellStyle}
                    padding={_.get(_.find(allHeaders, { name: 'Counter^' }), 'padding', 'default')}
                    onClick={() => {
                        handleViewDriver(counter);
                    }}
                >
                    <Link>{counterFullName}</Link>
                </TableCell>
            )}

            {filteredHeaders.includes('Name^') && (
                <TableCell
                    style={cellStyle}
                    padding={_.get(_.find(allHeaders, { name: 'Name^' }), 'padding', 'default')}
                >
                    {`${userFullName}${
                        !_.isNil(userBusinessName) && !_.isEmpty(userBusinessName) ? ` (${userBusinessName})` : ``
                    }`}
                </TableCell>
            )}
            {filteredHeaders.includes('ID') && (
                <TableCell style={cellStyle} padding={_.get(_.find(allHeaders, { name: 'ID' }), 'padding', 'default')}>
                    {userUniqueID}
                </TableCell>
            )}
            {filteredHeaders.includes('Infraction Date^') && (
                <TableCell
                    style={cellStyle}
                    padding={_.get(_.find(allHeaders, { name: 'Infraction Date^' }), 'padding', 'default')}
                >
                    {!_.isNil(infractionDate) ? moment(infractionDate).format('ddd, MMM DD YYYY') : ''}
                </TableCell>
            )}
            {filteredHeaders.includes('Pickup Date^') && (
                <TableCell
                    style={cellStyle}
                    padding={_.get(_.find(allHeaders, { name: 'Pickup Date^' }), 'padding', 'default')}
                >
                    {!_.isNil(pickupDate) ? moment(pickupDate).format('ddd, MMM DD YYYY') : ''}
                </TableCell>
            )}
            {filteredHeaders.includes('Count Date^') && (
                <TableCell
                    style={cellStyle}
                    padding={_.get(_.find(allHeaders, { name: 'Count Date^' }), 'padding', 'default')}
                >
                    {!_.isNil(countDate) ? moment(countDate).format('ddd, MMM DD YYYY') : ''}
                </TableCell>
            )}
            {filteredHeaders.includes('Bulk Type^') && (
                <TableCell
                    style={cellStyle}
                    padding={_.get(_.find(allHeaders, { name: 'Bulk Type^' }), 'padding', 'default')}
                >
                    {bulkType}
                </TableCell>
            )}
            {filteredHeaders.includes('Address^') && (
                <TableCell
                    style={{
                        ...cellStyle,
                        maxWidth: 250,
                        whiteSpace: 'normal',
                        wordWrap: 'break-word'
                    }}
                    padding={_.get(_.find(allHeaders, { name: 'Address^' }), 'padding', 'default')}
                >
                    <Tooltip title={address} interactive>
                        <p style={{ width: 200 }}>{address}</p>
                    </Tooltip>
                </TableCell>
            )}
        </TableRow>
    );
};

const RenderSearch = ({ children, filtersOpen, handleFiltersOpen, collapseSearch, theme }) => {
    if (collapseSearch) {
        return (
            <Menu open={Boolean(filtersOpen)} anchorEl={filtersOpen} onClose={handleFiltersOpen(true)}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        padding: theme.spacing.unit * 2
                    }}
                >
                    {children}
                </div>
            </Menu>
        );
    } else {
        return (
            <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>{children}</div>
            </div>
        );
    }
};

function PickupImageDialog({ open, onClose, imagesToView, theme }) {
    return (
        <Dialog open={open} fullWidth onClose={onClose}>
            <ImageViewer
                displayImages={imagesToView}
                disableDelete={true}
                innerStyles={{ marginTop: theme.spacing.unit * 2 }}
                scrollerStyles={{ maxWidth: 420, overflowX: 'auto' }}
            />
        </Dialog>
    );
}

const quarterCol3Style = {
    borderRight: '1px solid rgba(0, 0, 0, 0.2)',
    paddingLeft: 24,
    paddingBottom: 10,
    paddingTop: 10
};
const quarterCol4Style = {
    paddingLeft: 24,
    paddingBottom: 10,
    paddingTop: 10
};
const quarterWidth1 = 2;
const quarterWidth2 = 4;
const halfCol1Style = {
    paddingLeft: 24,
    borderRight: '1px solid rgba(0, 0, 0, 0.2)',
    paddingBottom: 10,
    paddingTop: 10
};
const halfCol2Style = {
    paddingLeft: 24,
    paddingBottom: 10,
    paddingTop: 10
};
const halfCol1Width = 2;
const halfCol2Width = 9;
const containerStyle = {};
function InfoDialog({
    open,
    onClose,
    lang,
    infraction,
    http,
    onViewBulk,
    handleViewTrip,
    resolveInfractions,
    unresolveInfractions,
    handleView,
    handleViewDriver,
    handleViewImages,
    setShowContactDialog,
    theme
}) {
    const {
        _id,
        user,
        infractionType,
        reason,
        resolved,
        outstandingValue,
        thresholdValue,
        infractionDate,
        resolutionDate,
        collector,
        driver,
        counter,
        order,
        redemption,
        address,
        userFullName,
        userUniqueID,
        pickup,
        pickupDate,
        countDate,
        resolvedBy,
        timeWindow,
        lastOrder,
        bulkType,
        matchedIPAccounts,
        userType,
        userPermissions
    } = infraction;
    const [pastCounterComplaintOrders, setPastCounterComplaintOrders] = useState([]);
    const [pastDriverComplaintPickups, setPastDriverComplaintPickups] = useState([]);
    const [driverIssueTranslationMap, setDriverIssueTranslationMap] = useState([]);
    const [counterIssueTranslationMap, setCounterIssueTranslationMap] = useState([]);
    const [currentOrder, setCurrentOrder] = useState(null);
    const [previousOrder, setPreviousOrder] = useState(null);
    const [extraInfoReady, setExtraInfoReady] = useState(false);
    let userBusinessName = _.get(infraction, 'userBusinessName', '');
    userBusinessName = _.isNil(userBusinessName) ? '' : userBusinessName.trim();
    let resolverFullName = _.get(infraction, 'resolverFullName', '');
    resolverFullName = _.isNil(resolverFullName) ? '' : resolverFullName.trim();
    let driverFullName = _.get(infraction, 'driverFullName', '');
    driverFullName = _.isNil(driverFullName) ? '' : driverFullName.trim();
    let counterFullName = _.get(infraction, 'counterFullName', '');
    counterFullName = _.isNil(counterFullName) ? '' : counterFullName.trim();
    let collectorName = _.get(infraction, 'collectorName', '');
    collectorName = _.isNil(collectorName) ? '' : collectorName.trim();
    let matchedAccountFullName = _.get(infraction, 'matchedAccountFullName', '');
    matchedAccountFullName = _.isNil(matchedAccountFullName) ? '' : matchedAccountFullName.trim();
    let ip = _.get(infraction, 'ip', '');

    const skuTypes = _.get(infraction, 'skuTypes', []);
    let pickupImages = _.get(pickup, 'images.pickupImages', []);
    let infractionImages = getSkuFilteredImages(pickupImages, skuTypes);

    const loadDriverIssueText = async () => {
        let URL = '/system/configuration/driverComplaintOptions/en';
        const res = await http.getJSON(URL);
        if (res.ok) {
            let newReportOptionsMap = {};
            _.get(res, 'data.options', []).forEach(option => {
                newReportOptionsMap[option.code] = option.label;
            });
            setDriverIssueTranslationMap(newReportOptionsMap);
        }
    };
    const loadCounterIssueText = async () => {
        let URL = '/system/configuration/clerkComplaintOptions/en';
        const res = await http.getJSON(URL);
        if (res.ok) {
            let newReportOptionsMap = {};
            _.get(res, 'data.options', []).forEach(option => {
                newReportOptionsMap[option.code] = option.label;
            });
            setCounterIssueTranslationMap(newReportOptionsMap);
        }
    };
    useEffect(() => {
        loadDriverIssueText();
        loadCounterIssueText();
    }, []);
    useEffect(() => {
        if (infractionType === INFRACTION_TYPES.AUDIT_TYPE_COUNTERCOMPLAINT) {
            getPastCounterComplaintOrders();
        } else if (infractionType === INFRACTION_TYPES.AUDIT_TYPE_DRIVERCOMPLAINT) {
            getPastDriverComplaintPickups();
        } else if (infractionType === INFRACTION_TYPES.AUDIT_TYPE_AMOUNTDIFFERENCE) {
            getCurrentAndPreviousOrder();
        }
    }, [infraction]);
    async function getPastCounterComplaintOrders() {
        setExtraInfoReady(false);
        setPastCounterComplaintOrders([]);
        let startDate = new Date(infractionDate);
        let month = isNaN(timeWindow) ? 12 : timeWindow;
        startDate.setMonth(startDate.getMonth() - month);
        const res = await http.postJSON(`/bulks/complaintOrderBetweenDate`, {
            customerID: user,
            startDate: startDate,
            endDate: infractionDate
        });
        if (res.ok) {
            setPastCounterComplaintOrders(res.data.orders);
            setExtraInfoReady(true);
        }
    }
    async function getPastDriverComplaintPickups() {
        setExtraInfoReady(false);
        setPastDriverComplaintPickups([]);
        let startDate = new Date(infractionDate);
        let month = isNaN(timeWindow) ? 12 : timeWindow;
        startDate.setMonth(startDate.getMonth() - month);
        const res = await http.postJSON(`/pickups/driverComplaintPickupByCustomerBetweenDate`, {
            customerID: user,
            startDate: startDate,
            endDate: infractionDate
        });
        if (res.ok) {
            setPastDriverComplaintPickups(res.data.pickups);
            setExtraInfoReady(true);
        }
    }
    async function getCurrentAndPreviousOrder() {
        setExtraInfoReady(false);
        setPreviousOrder(null);
        setCurrentOrder(null);
        let current, previous;
        if (!_.isNil(order)) {
            current = await http.getJSON(`/bulks/getBulkById/${order}`, false, true);
            if (current.ok) {
                let newCurrentOrder = _.get(current, 'data.bulk', null);
                setCurrentOrder(newCurrentOrder);
                setExtraInfoReady(true);
            }
        }
        if (!_.isNil(lastOrder)) {
            previous = await http.getJSON(`/bulks/getBulkById/${lastOrder}`, false, true);
            if (previous.ok) {
                let newPreviousOrder = _.get(previous, 'data.bulk', null);
                setPreviousOrder(newPreviousOrder);
                setExtraInfoReady(true);
            }
        }
    }
    let leftColumns = [
        {
            label: infractionType === INFRACTION_TYPES.AUDIT_TYPE_SAMEIPADDRESS ? 'Logged In User' : 'Customer Name',
            text:
                infractionType === INFRACTION_TYPES.AUDIT_TYPE_SAMEIPADDRESS
                    ? `${userFullName} (${getShortUserType({
                          accountType: _.first(userType),
                          accountPermissions: userPermissions
                      })})`
                    : userFullName
        },
        { label: 'Customer ID', text: userUniqueID }
    ];
    if (!_.isNil(collectorName) && !_.isEmpty(collectorName)) {
        leftColumns.push({ label: 'Collector Name', text: collectorName });
    }
    if (!_.isNil(counterFullName) && !_.isEmpty(counterFullName)) {
        leftColumns.push({ label: 'Counter Name', text: counterFullName });
    }
    if (!_.isNil(driverFullName) && !_.isEmpty(driverFullName)) {
        leftColumns.push({ label: 'Driver Name', text: driverFullName });
    }
    if (!_.isNil(matchedAccountFullName) && !_.isEmpty(matchedAccountFullName)) {
        leftColumns.push({ label: 'Matched Account Name', text: matchedAccountFullName });
    }
    if (!_.isNil(ip) && !_.isEmpty(ip)) {
        leftColumns.push({ label: 'Matched IP', text: ip });
    }
    let rightColumns = [];
    if (!_.isNil(infractionDate) && !_.isEmpty(infractionDate)) {
        rightColumns.push({ label: 'Infraction Date', text: moment(infractionDate).format('ddd, MMM DD YYYY') });
    }
    if (!_.isNil(pickupDate) && !_.isEmpty(pickupDate)) {
        rightColumns.push({ label: 'Pickup Date', text: moment(pickupDate).format('ddd, MMM DD YYYY') });
    }
    if (!_.isNil(countDate) && !_.isEmpty(countDate)) {
        rightColumns.push({ label: 'Count Date', text: moment(countDate).format('ddd, MMM DD YYYY') });
    }
    if (!_.isNil(resolutionDate) && !_.isEmpty(resolutionDate)) {
        rightColumns.push({ label: 'Resolved On', text: moment(resolutionDate).format('ddd, MMM DD YYYY') });
    }
    if (!_.isNil(resolverFullName) && !_.isEmpty(resolverFullName)) {
        rightColumns.push({ label: 'Resolved By', text: resolverFullName });
    }
    if (!_.isNil(bulkType) && !_.isEmpty(bulkType)) {
        rightColumns.push({ label: 'Bulk Type', text: bulkType });
    }
    // creates a combined array for rendering the left and right columns
    let leftPointer = 0,
        rightPointer = 0,
        combinedColumns = [];
    while (leftPointer < leftColumns.length || rightPointer < rightColumns.length) {
        if (!_.isNil(leftColumns[leftPointer])) {
            combinedColumns.push(leftColumns[leftPointer]);
            leftPointer++;
        } else {
            combinedColumns.push({});
        }
        if (!_.isNil(rightColumns[rightPointer])) {
            combinedColumns.push(rightColumns[rightPointer]);
            rightPointer++;
        } else {
            combinedColumns.push({});
        }
    }
    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth={'md'}>
            <DialogTitle style={{ paddingBottom: 12, paddingTop: 16 }}>
                <div style={{ justifyContent: 'space-between', flexDirection: 'row', display: 'flex' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <span
                            style={{
                                fontFamily: theme.typography.fontFamily,
                                fontSize: 20
                            }}
                        >
                            {loc(infractionType, lang)}
                        </span>
                        <span
                            style={{
                                fontFamily: theme.typography.fontFamily,
                                fontSize: 20,
                                marginLeft: 7,
                                marginRight: 7
                            }}
                        >
                            -
                        </span>
                        <span
                            style={{
                                fontFamily: theme.typography.fontFamily,
                                fontSize: 20,
                                color: resolved ? 'green' : 'red'
                            }}
                        >
                            {resolved ? loc('resolved', lang) : loc('unresolved', lang)}
                        </span>
                    </div>
                    <div>
                        {resolved ? (
                            <Tooltip title="Unresolve">
                                <IconButton
                                    onClick={() => {
                                        unresolveInfractions([_id]);
                                        onClose();
                                    }}
                                >
                                    <Icon>close</Icon>
                                </IconButton>
                            </Tooltip>
                        ) : (
                            <Tooltip title="Resolve">
                                <IconButton
                                    onClick={() => {
                                        resolveInfractions({ infractionIds: [_id], customerIDs: [user] });
                                        onClose();
                                    }}
                                >
                                    <Icon>check</Icon>
                                </IconButton>
                            </Tooltip>
                        )}
                        <Tooltip title="View Customer">
                            <IconButton onClick={() => handleView(user)}>
                                <Icon>visibility</Icon>
                            </IconButton>
                        </Tooltip>
                        {!_.isNil(order) && (
                            <Tooltip title="View Order">
                                <IconButton
                                    onClick={() => {
                                        onViewBulk(order);
                                    }}
                                >
                                    <Icon>ballot</Icon>
                                </IconButton>
                            </Tooltip>
                        )}
                        {!_.isNil(_.get(pickup, '_id', null)) &&
                            !_.isNil(collector) &&
                            !_.isNil(_.get(driver, '_id', null)) &&
                            !_.isNil(infractionDate) && (
                                <Tooltip title="View Trip">
                                    <IconButton
                                        onClick={e =>
                                            handleViewTrip(collector, _.get(driver, '_id', ''), infractionDate)
                                        }
                                    >
                                        <Icon>local_shipping</Icon>
                                    </IconButton>
                                </Tooltip>
                            )}
                        {!_.isEmpty(infractionImages) && (
                            <Tooltip title="View Image">
                                <IconButton
                                    onClick={() => {
                                        handleViewImages(infractionImages, true);
                                    }}
                                >
                                    <Icon>image</Icon>
                                </IconButton>
                            </Tooltip>
                        )}
                        {(!_.isNil(user) || !_.isNil(driver) || !_.isNil(counter)) && (
                            <Tooltip title="Contact">
                                <IconButton
                                    onClick={e => {
                                        setShowContactDialog(true);
                                    }}
                                >
                                    <Icon>email</Icon>
                                </IconButton>
                            </Tooltip>
                        )}
                    </div>
                </div>
            </DialogTitle>
            <DialogContent style={{}}>
                <Grid container stlye={containerStyle}>
                    {combinedColumns.map(cell => {
                        let label = _.get(cell, 'label', '');
                        let text = _.get(cell, 'text', '');
                        return (
                            <>
                                <Grid item xs={quarterWidth1} style={quarterCol3Style}>
                                    <Typography variant="body2">{label}</Typography>
                                </Grid>
                                <Grid item xs={quarterWidth2} style={quarterCol4Style}>
                                    <Typography>{text}</Typography>
                                </Grid>
                            </>
                        );
                    })}
                </Grid>
                {!_.isNil(address) && !_.isEmpty(address) && (
                    <Grid container stlye={containerStyle}>
                        <Grid item xs={halfCol1Width} style={halfCol1Style}>
                            <Typography>{'Pickup Address'}</Typography>
                        </Grid>
                        <Grid item xs={halfCol2Width} style={halfCol2Style}>
                            <Typography>{address}</Typography>
                        </Grid>
                    </Grid>
                )}
                {!_.isNil(matchedIPAccounts) && !_.isEmpty(matchedIPAccounts) && (
                    <Grid container stlye={containerStyle}>
                        <Grid item xs={halfCol1Width} style={halfCol1Style}>
                            <Typography>{'Matched Accounts'}</Typography>
                        </Grid>
                        <Grid item xs={halfCol2Width} style={halfCol2Style}>
                            {matchedIPAccounts.map((account, index) => {
                                return (
                                    <span
                                        style={{
                                            color: theme.palette.primary.main,
                                            cursor: 'pointer',
                                            fontFamily: theme.typography.fontFamily,
                                            fontSize: theme.typography.fontSize
                                        }}
                                        onClick={() => {
                                            if (account.accountType === 'Customer') {
                                                handleView(account._id);
                                            } else {
                                                handleViewDriver(account);
                                            }
                                        }}
                                    >
                                        {`${account.name.first} ${account.name.last} (${getShortUserType(account)})${
                                            index < matchedIPAccounts.length - 1 ? ', ' : ''
                                        }`}
                                    </span>
                                );
                            })}
                        </Grid>
                    </Grid>
                )}
                <Grid container stlye={containerStyle}>
                    <Grid item xs={halfCol1Width} style={halfCol1Style}>
                        <Typography>{'Reason'}</Typography>
                    </Grid>
                    <Grid item xs={halfCol2Width} style={halfCol2Style}>
                        <BoldedReason reason={reason} type={infractionType} theme={theme} />
                    </Grid>
                </Grid>
                {!_.isEmpty(infractionImages) && infractionType === INFRACTION_TYPES.AUDIT_TYPE_BAGCOUNT && (
                    <>
                        <Typography
                            style={{
                                paddingLeft: 24,
                                paddingBottom: 10,
                                paddingTop: 10
                            }}
                        >
                            {'Pickup Image'}
                        </Typography>
                        <ImageViewer
                            displayImages={infractionImages}
                            disableDelete={true}
                            outerStyles={{ marginLeft: '5%', width: '90%' }}
                            innerStyles={{ marginTop: theme.spacing.unit * 2 }}
                            scrollerStyles={{ maxWidth: 420, overflowX: 'auto' }}
                        />
                    </>
                )}

                {infractionType === INFRACTION_TYPES.AUDIT_TYPE_COUNTERCOMPLAINT &&
                    (pastCounterComplaintOrders.length > 0 && extraInfoReady ? (
                        <PastCounterComplaints
                            pastCounterComplaintOrders={pastCounterComplaintOrders}
                            timeWindow={timeWindow}
                            reportOptionsMap={counterIssueTranslationMap}
                            handleViewImages={handleViewImages}
                            onViewBulk={onViewBulk}
                        />
                    ) : (
                        <div
                            style={{
                                display: extraInfoReady ? 'none' : 'flex',
                                justifyContent: 'center',
                                marginTop: 20
                            }}
                        >
                            <CircularProgress
                                size={75}
                                thickness={5}
                                style={{
                                    display: extraInfoReady ? 'none' : ''
                                }}
                            />
                        </div>
                    ))}
                {infractionType === INFRACTION_TYPES.AUDIT_TYPE_DRIVERCOMPLAINT &&
                    (pastDriverComplaintPickups.length > 0 && extraInfoReady ? (
                        <PastDriverComplaints
                            pastDriverComplaintPickups={pastDriverComplaintPickups}
                            timeWindow={timeWindow}
                            reportOptionsMap={driverIssueTranslationMap}
                            handleViewImages={handleViewImages}
                        />
                    ) : (
                        <div
                            style={{
                                display: extraInfoReady ? 'none' : 'flex',
                                justifyContent: 'center',
                                marginTop: 20
                            }}
                        >
                            <CircularProgress
                                size={75}
                                thickness={5}
                                style={{
                                    display: extraInfoReady ? 'none' : ''
                                }}
                            />
                        </div>
                    ))}
                {infractionType === INFRACTION_TYPES.AUDIT_TYPE_AMOUNTDIFFERENCE &&
                    (extraInfoReady ? (
                        !_.isNil(currentOrder) && !_.isNil(previousOrder) ? (
                            <PreviousOrderStat
                                currentOrder={currentOrder}
                                previousOrder={previousOrder}
                                handleViewImages={handleViewImages}
                                onViewBulk={onViewBulk}
                            />
                        ) : (
                            _.isNil(previousOrder) && (
                                <Typography style={{ marginTop: 10, textAlign: 'center' }}>
                                    Previous order statistic unavailable
                                </Typography>
                            )
                        )
                    ) : (
                        <div
                            style={{
                                display: extraInfoReady ? 'none' : 'flex',
                                justifyContent: 'center',
                                marginTop: 20
                            }}
                        >
                            <CircularProgress
                                size={75}
                                thickness={5}
                                style={{
                                    display: extraInfoReady ? 'none' : ''
                                }}
                            />
                        </div>
                    ))}
            </DialogContent>
            <DialogActions>
                <Button color="default" size="small" onClick={onClose} data-cy="cancel">
                    {loc('close', lang)}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
function PastCounterComplaints(props) {
    const { pastCounterComplaintOrders, timeWindow, reportOptionsMap, handleViewImages, onViewBulk } = props;
    return (
        <>
            <Typography variant="h5" style={{ marginBottom: 20, marginTop: 12 }}>
                {`${pastCounterComplaintOrders.length} order(s) with counter complaints were found within last ${
                    isNaN(timeWindow) ? 12 : timeWindow
                } month:`}
            </Typography>
            {pastCounterComplaintOrders.map((order, index) => {
                let orderCountDate = _.get(order, 'dateCounted', null);
                let orderReceiverName = (
                    _.get(order, 'receiver.name.first', '') +
                    ' ' +
                    _.get(order, 'receiver.name.last', '')
                ).trim();
                let orderCreatorName = (
                    _.get(order, 'creator.name.first', '') +
                    ' ' +
                    _.get(order, 'creator.name.last', '')
                ).trim();
                let orderReceiverOrCreatorName = _.isEmpty(orderReceiverName) ? orderCreatorName : orderReceiverName;
                let payloadIssues = [];
                _.get(order, 'payloadIssues.issues', []).forEach(issue => {
                    payloadIssues.push(reportOptionsMap[issue]);
                });
                let payloadIssueDescription = _.get(order, 'payloadIssues.description', '');
                payloadIssueDescription = _.isNil(payloadIssueDescription) ? '' : payloadIssueDescription.trim();
                let payloadIssueImages = _.get(order, 'payloadIssues.images', []);
                let collectorName = _.get(order, 'collector.name', '');
                return (
                    <div style={{ marginBottom: 28 }}>
                        {index !== 0 && <Divider style={{ marginBottom: 24 }} />}

                        <div style={{ justifyContent: 'space-between', flexDirection: 'row', display: 'flex' }}>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Typography variant="h6" style={{ paddingLeft: 24 }}>
                                    {`Order ${index + 1} `}
                                </Typography>
                            </div>
                            <div>
                                {!_.isEmpty(payloadIssueImages) && (
                                    <Tooltip title="View Complaint Image">
                                        <IconButton
                                            onClick={() => {
                                                handleViewImages(payloadIssueImages);
                                            }}
                                        >
                                            <Icon>image</Icon>
                                        </IconButton>
                                    </Tooltip>
                                )}
                                {!_.isNil(_.get(order, '_id', null)) && (
                                    <Tooltip title="View Order">
                                        <IconButton
                                            onClick={() => {
                                                onViewBulk(_.get(order, '_id', ''));
                                            }}
                                        >
                                            <Icon>ballot</Icon>
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </div>
                        </div>

                        <Grid container>
                            {!_.isNil(orderCountDate) && !_.isEmpty(orderCountDate) && (
                                <>
                                    <Grid item xs={quarterWidth1} style={quarterCol3Style}>
                                        <Typography>{'Count Date'}</Typography>
                                    </Grid>
                                    <Grid item xs={quarterWidth2} style={quarterCol4Style}>
                                        <Typography>{moment(orderCountDate).format('ddd, MMM DD YYYY')}</Typography>
                                    </Grid>
                                </>
                            )}

                            {!_.isNil(orderReceiverOrCreatorName) && !_.isEmpty(orderReceiverOrCreatorName) && (
                                <>
                                    <Grid item xs={quarterWidth1} style={quarterCol3Style}>
                                        <Typography>{'Counter Name'}</Typography>
                                    </Grid>
                                    <Grid item xs={quarterWidth2} style={quarterCol4Style}>
                                        <Typography>{orderReceiverOrCreatorName}</Typography>
                                    </Grid>
                                </>
                            )}

                            {!_.isNil(collectorName) && !_.isEmpty(collectorName) && (
                                <>
                                    <Grid item xs={quarterWidth1} style={quarterCol3Style}>
                                        <Typography>{'Collector Name'}</Typography>
                                    </Grid>
                                    <Grid item xs={quarterWidth2} style={quarterCol4Style}>
                                        <Typography>{collectorName}</Typography>
                                    </Grid>
                                </>
                            )}

                            {!_.isNil(payloadIssues) && !_.isEmpty(payloadIssues) && (
                                <>
                                    <Grid container stlye={containerStyle}>
                                        <Grid item xs={halfCol1Width} style={halfCol1Style}>
                                            <Typography style={{ fontWeight: 'bold' }}>{`Issues${
                                                payloadIssues.length > 0 ? ` (${payloadIssues.length})` : ''
                                            }`}</Typography>
                                        </Grid>
                                        <Grid item xs={halfCol2Width} style={halfCol2Style}>
                                            <Typography style={{ fontWeight: 'bold' }}>
                                                {payloadIssues.join(', ')}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </>
                            )}

                            {!_.isNil(payloadIssueDescription) && !_.isEmpty(payloadIssueDescription) && (
                                <>
                                    <Grid container stlye={containerStyle}>
                                        <Grid item xs={halfCol1Width} style={halfCol1Style}>
                                            <Typography>{'Description'}</Typography>
                                        </Grid>
                                        <Grid item xs={halfCol2Width} style={halfCol2Style}>
                                            <Typography>{payloadIssueDescription}</Typography>
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </div>
                );
            })}
        </>
    );
}

function PastDriverComplaints(props) {
    const { pastDriverComplaintPickups, timeWindow, reportOptionsMap, handleViewImages } = props;
    return (
        <>
            <Typography variant="h5" style={{ marginBottom: 20, marginTop: 12 }}>
                {`${pastDriverComplaintPickups.length} pickup(s) with driver complaints were found within last ${
                    isNaN(timeWindow) ? 12 : timeWindow
                } month:`}
            </Typography>
            {pastDriverComplaintPickups.map((pickup, index) => {
                let completionDate = _.get(pickup, 'completionDate', null);
                let receiverName =
                    _.get(pickup, 'receiver.name.first', '') + ' ' + _.get(pickup, 'receiver.name.last', '');
                let collectorName = _.get(pickup, 'collector.name', '');
                let driverIssues = [];
                _.get(pickup, 'driverIssues', []).forEach(issue => {
                    driverIssues.push(reportOptionsMap[issue]);
                });
                let driverIssueDescription = _.get(pickup, 'driverIssueDescription', '');
                driverIssueDescription = _.isNil(driverIssueDescription) ? '' : driverIssueDescription.trim();
                let pickupImages = _.get(pickup, 'images.pickupImages', []);
                return (
                    <div style={{ marginBottom: 28 }}>
                        {index !== 0 && <Divider style={{ marginBottom: 24 }} />}

                        <div style={{ justifyContent: 'space-between', flexDirection: 'row', display: 'flex' }}>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Typography variant="h6" style={{ paddingLeft: 24 }}>
                                    {`Pickup ${index + 1} `}
                                </Typography>
                            </div>
                            <div>
                                {!_.isEmpty(pickupImages) && (
                                    <Tooltip title="View Pickup Image">
                                        <IconButton
                                            onClick={() => {
                                                handleViewImages(pickupImages);
                                            }}
                                        >
                                            <Icon>image</Icon>
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </div>
                        </div>

                        <Grid container>
                            {!_.isNil(completionDate) && !_.isEmpty(completionDate) && (
                                <>
                                    <Grid item xs={quarterWidth1} style={quarterCol3Style}>
                                        <Typography>{'Pickup Date'}</Typography>
                                    </Grid>
                                    <Grid item xs={quarterWidth2} style={quarterCol4Style}>
                                        <Typography>{moment(completionDate).format('ddd, MMM DD YYYY')}</Typography>
                                    </Grid>
                                </>
                            )}

                            {!_.isNil(receiverName) && !_.isEmpty(receiverName) && (
                                <>
                                    <Grid item xs={quarterWidth1} style={quarterCol3Style}>
                                        <Typography>{'Driver Name'}</Typography>
                                    </Grid>
                                    <Grid item xs={quarterWidth2} style={quarterCol4Style}>
                                        <Typography>{receiverName}</Typography>
                                    </Grid>
                                </>
                            )}

                            {!_.isNil(collectorName) && !_.isEmpty(collectorName) && (
                                <>
                                    <Grid item xs={quarterWidth1} style={quarterCol3Style}>
                                        <Typography>{'Collector Name'}</Typography>
                                    </Grid>
                                    <Grid item xs={quarterWidth2} style={quarterCol4Style}>
                                        <Typography>{collectorName}</Typography>
                                    </Grid>
                                </>
                            )}

                            {!_.isNil(driverIssues) && !_.isEmpty(driverIssues) && (
                                <>
                                    <Grid container stlye={containerStyle}>
                                        <Grid item xs={halfCol1Width} style={halfCol1Style}>
                                            <Typography style={{ fontWeight: 'bold' }}>{`Issues${
                                                driverIssues.length > 0 ? ` (${driverIssues.length})` : ''
                                            }`}</Typography>
                                        </Grid>
                                        <Grid item xs={halfCol2Width} style={halfCol2Style}>
                                            <Typography style={{ fontWeight: 'bold' }}>
                                                {driverIssues.join(', ')}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </>
                            )}

                            {!_.isNil(driverIssueDescription) && !_.isEmpty(driverIssueDescription) && (
                                <>
                                    <Grid container stlye={containerStyle}>
                                        <Grid item xs={halfCol1Width} style={halfCol1Style}>
                                            <Typography>{'Description'}</Typography>
                                        </Grid>
                                        <Grid item xs={halfCol2Width} style={halfCol2Style}>
                                            <Typography>{driverIssueDescription}</Typography>
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </div>
                );
            })}
        </>
    );
}

function PreviousOrderStat(props) {
    const { currentOrder, previousOrder, handleViewImages, onViewBulk } = props;
    let currentOrderCountDate = _.get(currentOrder, 'dateCounted', null);
    let currentOrderReceiverName = (
        _.get(currentOrder, 'receiver.name.first', '') +
        ' ' +
        _.get(currentOrder, 'receiver.name.last', '')
    ).trim();
    let currentOrderCreatorName = (
        _.get(currentOrder, 'creator.name.first', '') +
        ' ' +
        _.get(currentOrder, 'creator.name.last', '')
    ).trim();
    let currentOrderReceiverOrCreatorName = _.isEmpty(currentOrderReceiverName)
        ? currentOrderCreatorName
        : currentOrderReceiverName;
    let currentCollectorName = _.get(currentOrder, 'collector.name', '');

    const currOrderSkuType = _.get(currentOrder, 'skuType');
    let currentPickupImages = _.get(currentOrder, 'pickup.images.pickupImages', []);
    let currFilteredPickupImages = getSkuFilteredImages(
        currentPickupImages,
        currOrderSkuType ? [currOrderSkuType] : []
    );

    const currentOrderAmount = bulkHelper.getTotalValue(currentOrder);
    let currentOrderNumberOfBags = _.get(currentOrder, 'commodityAmount', 0);
    let currentOrderAmountPerBag = currentOrderNumberOfBags === 0 ? 0 : currentOrderAmount / currentOrderNumberOfBags;

    let previousOrderCountDate = _.get(previousOrder, 'dateCounted', null);
    let previousOrderReceiverName = (
        _.get(previousOrder, 'receiver.name.first', '') +
        ' ' +
        _.get(previousOrder, 'receiver.name.last', '')
    ).trim();
    let previousOrderCreatorName = (
        _.get(previousOrder, 'creator.name.first', '') +
        ' ' +
        _.get(previousOrder, 'creator.name.last', '')
    ).trim();
    let previousOrderReceiverOrCreatorName = _.isEmpty(previousOrderReceiverName)
        ? previousOrderCreatorName
        : previousOrderReceiverName;
    let previousCollectorName = _.get(previousOrder, 'collector.name', '');

    const prevOrderSkuType = _.get(previousOrder, 'skuType');
    let previousPickupImages = _.get(previousOrder, 'pickup.images.pickupImages', []);
    let prevFilteredPickupImages = getSkuFilteredImages(
        previousPickupImages,
        prevOrderSkuType ? [prevOrderSkuType] : []
    );

    const previousOrderAmount = bulkHelper.getTotalValue(previousOrder);
    let previousOrderNumberOfBags = _.get(previousOrder, 'commodityAmount', 0);
    let previousOrderAmountPerBag =
        previousOrderNumberOfBags === 0 ? 0 : previousOrderAmount / previousOrderNumberOfBags;
    return (
        <>
            <div style={{ marginTop: 10 }}>
                <div style={{ justifyContent: 'space-between', flexDirection: 'row', display: 'flex' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Typography style={{ paddingLeft: 24 }}>
                            {`${
                                !_.isNil(currentOrderCountDate) && !_.isEmpty(currentOrderCountDate)
                                    ? moment(currentOrderCountDate).format('ddd, MMM DD YYYY')
                                    : ''
                            }${
                                !_.isNil(currentCollectorName) && !_.isEmpty(currentCollectorName)
                                    ? ' | ' + currentCollectorName
                                    : ''
                            }${
                                !_.isNil(currentOrderReceiverOrCreatorName) &&
                                !_.isEmpty(currentOrderReceiverOrCreatorName)
                                    ? ' - ' + currentOrderReceiverOrCreatorName
                                    : ''
                            }${
                                !_.isNil(currentOrderNumberOfBags) && !isNaN(currentOrderNumberOfBags)
                                    ? ' - ' + currentOrderNumberOfBags + ' Bags'
                                    : ''
                            }${
                                !_.isNil(currentOrderAmount) && !isNaN(currentOrderAmount)
                                    ? ' - ' + formatAsCurrency(currentOrderAmount)
                                    : ''
                            }${
                                !_.isNil(currentOrderAmountPerBag) && !isNaN(currentOrderAmountPerBag)
                                    ? ' - ' + formatAsCurrency(currentOrderAmountPerBag) + '/bag |'
                                    : ''
                            }`}
                        </Typography>
                    </div>
                    <div>
                        {!_.isEmpty(currFilteredPickupImages) && (
                            <Tooltip title="View Pickup Image">
                                <IconButton
                                    onClick={() => {
                                        handleViewImages(currFilteredPickupImages);
                                    }}
                                >
                                    <Icon>image</Icon>
                                </IconButton>
                            </Tooltip>
                        )}
                        {!_.isNil(_.get(currentOrder, '_id', null)) && (
                            <Tooltip title="View Order">
                                <IconButton
                                    onClick={() => {
                                        onViewBulk(_.get(currentOrder, '_id', ''));
                                    }}
                                >
                                    <Icon>ballot</Icon>
                                </IconButton>
                            </Tooltip>
                        )}
                    </div>
                </div>
            </div>

            <div style={{}}>
                <div style={{ justifyContent: 'space-between', flexDirection: 'row', display: 'flex' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Typography style={{ paddingLeft: 24 }}>
                            {`${
                                !_.isNil(previousOrderCountDate) && !_.isEmpty(previousOrderCountDate)
                                    ? moment(previousOrderCountDate).format('ddd, MMM DD YYYY')
                                    : ''
                            }${
                                !_.isNil(previousCollectorName) && !_.isEmpty(previousCollectorName)
                                    ? ' | ' + previousCollectorName
                                    : ''
                            }${
                                !_.isNil(previousOrderReceiverOrCreatorName) &&
                                !_.isEmpty(previousOrderReceiverOrCreatorName)
                                    ? ' - ' + previousOrderReceiverOrCreatorName
                                    : ''
                            }${
                                !_.isNil(currentOrderNumberOfBags) && !isNaN(previousOrderNumberOfBags)
                                    ? ' - ' + previousOrderNumberOfBags + ' Bags'
                                    : ''
                            }${
                                !_.isNil(previousOrderAmount) && !isNaN(previousOrderAmount)
                                    ? ' - ' + formatAsCurrency(previousOrderAmount)
                                    : ''
                            }${
                                !_.isNil(previousOrderAmountPerBag) && !isNaN(previousOrderAmountPerBag)
                                    ? ' - ' + formatAsCurrency(previousOrderAmountPerBag) + '/bag |'
                                    : ''
                            }`}
                        </Typography>
                    </div>
                    <div>
                        {!_.isEmpty(prevFilteredPickupImages) && (
                            <Tooltip title="View Pickup Image">
                                <IconButton
                                    onClick={() => {
                                        handleViewImages(prevFilteredPickupImages);
                                    }}
                                >
                                    <Icon>image</Icon>
                                </IconButton>
                            </Tooltip>
                        )}
                        {!_.isNil(_.get(previousOrder, '_id', null)) && (
                            <Tooltip title="View Order">
                                <IconButton
                                    onClick={() => {
                                        onViewBulk(_.get(previousOrder, '_id', ''));
                                    }}
                                >
                                    <Icon>ballot</Icon>
                                </IconButton>
                            </Tooltip>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

function ResolveCustomerComplaintsDialog(props) {
    const {
        open,
        onClose,
        customerComplaintOrders,
        theme,
        operator,
        http,
        onSnackbar,
        lang,
        refreshCustomerComplaintOrders,
        onViewBulk,
        warnAction,
        handleViewImages
    } = props;
    const [resolutions, setResolutions] = useState([]);
    const [inProgress, setInProgress] = useState(false);
    const [customerIssueResolutionList, setCustomerIssueResolutionList] = useState({});
    const hasResolvePermission =
        _.get(operator, 'adminView', false) ||
        _.get(operator, 'collector.configuration.bulkPermissions.resolveComplaints.update', false);
    const loadResolutions = async () => {
        const res = await http.getJSON('/system/configuration/customerResolutions');
        if (res.ok) {
            setResolutions(res.data.resolutions);
        }
    };
    useEffect(() => {
        loadResolutions();
    }, []);

    const handleToggleResolveCustomerIssue = async order => {
        setInProgress(true);
        let customerIssueResolution = customerIssueResolutionList[order._id];
        const isResolved = _.isNil(_.get(order, 'customerIssues.dateResolved', null));
        const res = await http.post(`/bulks/${order._id}/resolve`, { isResolved, customerIssueResolution }, true);
        if (res.ok) {
            refreshCustomerComplaintOrders();
            onSnackbar('Successfully resolved customer issue');
        } else {
            onSnackbar(res.errorMessage, 'error');
        }
        setInProgress(false);
    };

    const handleChangeCustomerIssueResolution = (e, orderId) => {
        let newCustomerIssueResolution = { ...customerIssueResolutionList };
        newCustomerIssueResolution[orderId] = e.target.value;
        setCustomerIssueResolutionList(newCustomerIssueResolution);
    };
    function getCustomerIssueResolution(orderId) {
        return customerIssueResolutionList[orderId];
    }
    return (
        <Dialog open={open} onClose={() => {}} fullWidth maxWidth={'lg'}>
            <DialogContent>
                <Typography variant="h5" style={{ marginBottom: 24, marginTop: 12 }}>
                    {`${customerComplaintOrders.length} order(s) with customer complaints were found:`}
                </Typography>
                {customerComplaintOrders.map((order, index) => {
                    let orderCountDate = _.get(order, 'dateCounted', null);
                    let orderReceiverName = (
                        _.get(order, 'receiver.name.first', '') +
                        ' ' +
                        _.get(order, 'receiver.name.last', '')
                    ).trim();
                    let orderCreatorName = (
                        _.get(order, 'creator.name.first', '') +
                        ' ' +
                        _.get(order, 'creator.name.last', '')
                    ).trim();
                    let orderReceiverOrCreatorName = _.isEmpty(orderReceiverName)
                        ? orderCreatorName
                        : orderReceiverName;
                    let collectorName = _.get(order, 'collector.name', '');

                    const skuType = _.get(order, 'skuType');
                    let pickupImages = _.get(order, 'pickup.images.pickupImages', []);
                    let infractionImages = getSkuFilteredImages(pickupImages, skuType ? [skuType] : []);

                    const orderAmount = bulkHelper.getTotalValue(order);
                    let orderNumberOfBags = _.get(order, 'commodityAmount', 0);
                    let orderAmountPerBag = orderNumberOfBags === 0 ? 0 : orderAmount / orderNumberOfBags;
                    let userFullName = (
                        _.get(order, 'owner.name.first', '') +
                        ' ' +
                        _.get(order, 'owner.name.last', '')
                    ).trim();
                    return (
                        <div style={{ marginBottom: 28 }}>
                            {index !== 0 && <Divider style={{ marginBottom: 28 }} />}

                            <div style={{ justifyContent: 'space-between', flexDirection: 'row', display: 'flex' }}>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Typography variant="h6" style={{ paddingLeft: 24, marginBottom: 12 }}>
                                        {`Order ${index + 1} `}
                                    </Typography>
                                </div>
                                <div>
                                    {!_.isEmpty(infractionImages) && (
                                        <Tooltip title="View Pickup Image">
                                            <IconButton
                                                onClick={() => {
                                                    handleViewImages(infractionImages);
                                                }}
                                            >
                                                <Icon>image</Icon>
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    {!_.isNil(_.get(order, '_id', null)) && (
                                        <Tooltip title="View Order">
                                            <IconButton
                                                onClick={() => {
                                                    onViewBulk(_.get(order, '_id', ''));
                                                }}
                                            >
                                                <Icon>ballot</Icon>
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </div>
                            </div>

                            <Grid container>
                                {!_.isNil(userFullName) && !_.isEmpty(userFullName) && (
                                    <>
                                        <Grid item xs={quarterWidth1} style={quarterCol3Style}>
                                            <Typography variant="body2">{'Customer Name'}</Typography>
                                        </Grid>
                                        <Grid item xs={quarterWidth2} style={quarterCol4Style}>
                                            <Typography>{userFullName}</Typography>
                                        </Grid>
                                    </>
                                )}
                                {!_.isNil(orderCountDate) && !_.isEmpty(orderCountDate) && (
                                    <>
                                        <Grid item xs={quarterWidth1} style={quarterCol3Style}>
                                            <Typography>{'Count Date'}</Typography>
                                        </Grid>
                                        <Grid item xs={quarterWidth2} style={quarterCol4Style}>
                                            <Typography>{moment(orderCountDate).format('ddd, MMM DD YYYY')}</Typography>
                                        </Grid>
                                    </>
                                )}

                                {!_.isNil(orderReceiverOrCreatorName) && !_.isEmpty(orderReceiverOrCreatorName) && (
                                    <>
                                        <Grid item xs={quarterWidth1} style={quarterCol3Style}>
                                            <Typography>{'Counter Name'}</Typography>
                                        </Grid>
                                        <Grid item xs={quarterWidth2} style={quarterCol4Style}>
                                            <Typography>{orderReceiverOrCreatorName}</Typography>
                                        </Grid>
                                    </>
                                )}

                                {!_.isNil(collectorName) && !_.isEmpty(collectorName) && (
                                    <>
                                        <Grid item xs={quarterWidth1} style={quarterCol3Style}>
                                            <Typography>{'Collector Name'}</Typography>
                                        </Grid>
                                        <Grid item xs={quarterWidth2} style={quarterCol4Style}>
                                            <Typography>{collectorName}</Typography>
                                        </Grid>
                                    </>
                                )}
                                {!_.isNil(orderAmount) && !isNaN(orderAmount) && (
                                    <>
                                        <Grid item xs={quarterWidth1} style={quarterCol3Style}>
                                            <Typography>{'Amount'}</Typography>
                                        </Grid>
                                        <Grid item xs={quarterWidth2} style={quarterCol4Style}>
                                            <Typography>{formatAsCurrency(orderAmount)}</Typography>
                                        </Grid>
                                    </>
                                )}

                                {!_.isNil(orderNumberOfBags) && !isNaN(orderNumberOfBags) && (
                                    <>
                                        <Grid item xs={quarterWidth1} style={quarterCol3Style}>
                                            <Typography>{'Bag Count'}</Typography>
                                        </Grid>
                                        <Grid item xs={quarterWidth2} style={quarterCol4Style}>
                                            <Typography>{orderNumberOfBags}</Typography>
                                        </Grid>
                                    </>
                                )}

                                {!_.isNil(orderAmountPerBag) && !isNaN(orderAmountPerBag) && (
                                    <>
                                        <Grid item xs={quarterWidth1} style={quarterCol3Style}>
                                            <Typography>{'Amount Per Bag'}</Typography>
                                        </Grid>
                                        <Grid item xs={quarterWidth2} style={quarterCol4Style}>
                                            <Typography>{formatAsCurrency(orderAmountPerBag)}</Typography>
                                        </Grid>
                                    </>
                                )}
                                {/* {!_.isNil(pickupIMGURL) && !_.isEmpty(pickupIMGURL) && (
                                    <>
                                        <Grid container stlye={containerStyle}>
                                            <Typography
                                                style={{
                                                    paddingLeft: 24,
                                                    paddingBottom: 10,
                                                    'padding-top': 10
                                                }}
                                            >
                                                {'Pickup Image'}
                                            </Typography>
                                            <img
                                                src={pickupIMGURL}
                                                alt="pickup-img"
                                                style={{ marginLeft: '5%', width: '90%' }}
                                            />
                                        </Grid>
                                    </>
                                )} */}

                                <>
                                    <Grid item xs={12}>
                                        <Typography
                                            style={{
                                                paddingLeft: 24,
                                                paddingBottom: 6,
                                                paddingTop: 20
                                            }}
                                        >
                                            {'Customer Complaint'}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        style={{
                                            paddingLeft: 24
                                        }}
                                    >
                                        <Typography
                                            variant="body2"
                                            style={{
                                                marginTop: theme.spacing.unit * 2,
                                                marginBottom: theme.spacing.unit * 2
                                            }}
                                        >
                                            <span
                                                style={{
                                                    background: bulkHelper.hasUnresolvedCustomerIssue(order)
                                                        ? colors.red[500]
                                                        : colors.blue[100],
                                                    color: theme.palette.getContrastText(
                                                        bulkHelper.hasUnresolvedCustomerIssue(order)
                                                            ? colors.red[500]
                                                            : colors.blue[100]
                                                    ),
                                                    fontSize: theme.typography.fontSize * 0.875,
                                                    marginRight: theme.spacing.unit,
                                                    padding: '2px 4px',
                                                    borderRadius: 4
                                                }}
                                            >
                                                {bulkHelper.hasCustomerIssue(order) ? (
                                                    <>
                                                        {bulkHelper.hasUnresolvedCustomerIssue(order)
                                                            ? 'UNRESOLVED'
                                                            : 'RESOLVED'}
                                                    </>
                                                ) : (
                                                    <>NO ISSUES :D</>
                                                )}
                                            </span>
                                            "{_.get(order, 'customerIssues.issueDescription', 'N/A')}" -{' '}
                                            <i>{_user.getNameFirstNameAndLastInitial(order.owner)}</i>
                                        </Typography>
                                        <FormControl style={{ marginBottom: theme.spacing.unit }} fullWidth>
                                            {resolutions.map(resolution => {
                                                return (
                                                    <FormControlLabel
                                                        key={resolution.code}
                                                        control={
                                                            <Checkbox
                                                                checked={
                                                                    resolution.code ===
                                                                    getCustomerIssueResolution(_.get(order, '_id', ''))
                                                                }
                                                                onChange={e => {
                                                                    handleChangeCustomerIssueResolution(
                                                                        e,
                                                                        _.get(order, '_id', '')
                                                                    );
                                                                }}
                                                                value={resolution.code}
                                                                style={{
                                                                    paddingTop: theme.spacing.unit,
                                                                    paddingBottom: theme.spacing.unit / 2,
                                                                    marginLeft: theme.spacing.unit * 2
                                                                }}
                                                            />
                                                        }
                                                        label={resolution.label}
                                                        disabled={!bulkHelper.hasUnresolvedCustomerIssue(order)}
                                                    />
                                                );
                                            })}
                                        </FormControl>
                                        {!hasResolvePermission && (
                                            <Typography
                                                style={{ textAlign: 'right', color: colors.red[500] }}
                                                variant="body2"
                                            >
                                                <div>Only system administrators can resolve issues.</div>
                                                <div>Please contact support if this is incorrect.</div>
                                            </Typography>
                                        )}
                                        <Button
                                            color="primary"
                                            disabled={
                                                _.isEmpty(getCustomerIssueResolution(_.get(order, '_id', ''))) ||
                                                inProgress ||
                                                !bulkHelper.hasCustomerIssue(order) ||
                                                !hasResolvePermission
                                            }
                                            onClick={() => {
                                                if (bulkHelper.hasUnresolvedCustomerIssue(order)) {
                                                    handleToggleResolveCustomerIssue(order);
                                                } else {
                                                    warnAction(() => {
                                                        return handleToggleResolveCustomerIssue(order);
                                                    }, 'Are you sure you want reopen this issue? The customer will be notified.');
                                                }
                                            }}
                                            data-cy="3-pickupdialog-next-button"
                                        >
                                            {bulkHelper.hasUnresolvedCustomerIssue(order)
                                                ? 'MARK AS RESOLVED'
                                                : 'MARK AS UNRESOLVED'}
                                            <CircularProgress
                                                size={22}
                                                thickness={4.8}
                                                style={{
                                                    display: inProgress ? '' : 'none',
                                                    marginLeft: theme.spacing.unit / 2
                                                }}
                                            />
                                        </Button>
                                    </Grid>
                                </>
                            </Grid>
                        </div>
                    );
                })}
            </DialogContent>
            <DialogActions>
                <Button
                    color="default"
                    onClick={() => {
                        onClose();
                        setCustomerIssueResolutionList({});
                    }}
                >
                    {loc('dismiss', lang)}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function ContactDialog(props) {
    const { open, lang, theme, selectedInfraction, onClose, http, onSnackbar } = props;
    const [recipientList, setRecipientList] = useState([]);
    const [selectedRecipientIds, setSelectedRecipientIds] = useState([]);
    const [selectedContactMethods, setSelectedContactMethods] = useState([]);
    const [message, setMessage] = useState('');
    async function contactUser() {
        const res = await http.post(`/infractions/contactUser`, {
            userIds: selectedRecipientIds,
            message: message,
            contactMethods: selectedContactMethods
        });
        if (res.ok) {
            setSelectedContactMethods([]);
            setSelectedRecipientIds([]);
            setMessage('');
            onClose();
            onSnackbar('Message sent.');
        }
    }
    useEffect(() => {
        const { user, driver, counter, userFullName } = selectedInfraction;
        let driverFullName = _.get(selectedInfraction, 'driverFullName', '');
        driverFullName = _.isNil(driverFullName) ? '' : driverFullName.trim();
        let counterFullName = _.get(selectedInfraction, 'counterFullName', '');
        counterFullName = _.isNil(counterFullName) ? '' : counterFullName.trim();
        let newRecipientSet = { user: { id: user, display: `${userFullName} (customer)`, name: userFullName } };
        let driverId = _.get(driver, '_id', null);
        if (!_.isNil(driverId) && newRecipientSet[driverId] === undefined) {
            newRecipientSet[driverId] = { id: driverId, display: `${driverFullName} (driver)`, name: driverFullName };
        }
        let counterId = _.get(counter, '_id', null);
        if (!_.isNil(counterId) && newRecipientSet[counterId] === undefined) {
            newRecipientSet[counterId] = {
                id: counterId,
                display: `${counterFullName} (counter)`,
                name: counterFullName
            };
        } else if (newRecipientSet[counterId] !== undefined) {
            newRecipientSet[counterId].display = `${driverFullName} (driver/counter)`;
        }
        setRecipientList(newRecipientSet);
    }, [selectedInfraction]);

    return (
        <Dialog open={open} onClose={onClose} fullWidth>
            <DialogTitle style={{ paddingBottom: 14 }}>Compose Message</DialogTitle>
            <DialogContent>
                <FormControl
                    fullWidth
                    style={{
                        marginTop: theme.spacing.unit * 2,
                        backgroundColor: theme.palette.background.paper
                    }}
                >
                    <InputLabel variant="outlined" shrink={true}>
                        Recipient
                    </InputLabel>
                    <Select
                        multiple
                        value={selectedRecipientIds}
                        renderValue={() => {
                            let names = Object.values(recipientList).filter(item =>
                                selectedRecipientIds.includes(item.id)
                            );
                            names = Array.from(names, item => item.display);
                            let namesText = names.join(', ');
                            return namesText;
                        }}
                        onChange={e => {
                            setSelectedRecipientIds(e.target.value);
                        }}
                        data-cy="recipient"
                        input={
                            <OutlinedInput
                                notched
                                labelWidth={66}
                                name="recipient"
                                id="recipient"
                                className="select-icon"
                            />
                        }
                    >
                        {Object.values(recipientList).map(recipient => {
                            const { id, display, name } = recipient;
                            return (
                                !_.isNil(id) &&
                                !_.isNil(name) &&
                                !_.isEmpty(name) && (
                                    <MenuItem value={id} key={id} data-cy={id}>
                                        {display}
                                    </MenuItem>
                                )
                            );
                        })}
                    </Select>
                </FormControl>
                <FormControl
                    fullWidth
                    style={{
                        marginTop: theme.spacing.unit * 2,
                        backgroundColor: theme.palette.background.paper
                    }}
                >
                    <InputLabel variant="outlined" shrink={true}>
                        Contact Method
                    </InputLabel>
                    <Select
                        multiple
                        value={selectedContactMethods}
                        onChange={e => {
                            setSelectedContactMethods(e.target.value);
                        }}
                        data-cy="contactMethod"
                        input={
                            <OutlinedInput
                                notched
                                labelWidth={115}
                                name="contactMethod"
                                id="contactMethod"
                                className="select-icon"
                            />
                        }
                    >
                        {
                            <MenuItem value={'email'} key={'email'} data-cy={'email'}>
                                Email
                            </MenuItem>
                        }
                        {
                            <MenuItem value={'SMS'} key={'SMS'} data-cy={'SMS'}>
                                SMS
                            </MenuItem>
                        }
                    </Select>
                </FormControl>
                <FormControl
                    fullWidth
                    style={{
                        marginTop: theme.spacing.unit * 2,
                        backgroundColor: theme.palette.background.paper
                    }}
                >
                    <TextField
                        rows={3}
                        rowsMax={10}
                        InputLabelProps={{ shrink: true }}
                        data-cy="emailBody"
                        multiline
                        name="emailBody"
                        value={message}
                        label="Message"
                        variant="outlined"
                        onChange={e => {
                            setMessage(e.target.value);
                        }}
                    />
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    size="small"
                    onClick={() => {
                        contactUser();
                    }}
                    data-cy="send"
                    disabled={
                        _.isEmpty(selectedRecipientIds) || _.isEmpty(message) || _.isEmpty(selectedContactMethods)
                    }
                >
                    {loc('send', lang)}
                </Button>
                <Button color="default" onClick={onClose}>
                    {loc('close', lang)}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function BoldedReason(props) {
    const { reason, type, theme, fontSizeMultiplier = 1.01, basic } = props;
    let fontSize = theme.typography.fontSize * fontSizeMultiplier;
    let fontWeight = 'bold';
    if (type === INFRACTION_TYPES.AUDIT_TYPE_AMOUNTDIFFERENCE) {
        try {
            let previousOrderMissing = reason.includes('was used because a previous order could not be found');
            let amountDiffPercent = reason.match(/Threshold difference of (.*?) detected/i)[1];
            let newBulkAmountPerBag = reason.match(/detected. (.*?)\/bag \(current\)/i)[1];
            let lastBulkAmountPerBag = reason.match(/bag \(current\) vs (.*?)\/bag \(previous\)/i)[1];
            let amountDiff = reason.match(/bag \(previous\) difference of (.*?)\/bag. Allowable difference /i)[1];
            let amountDiffThresholdPercent = reason.match(/Allowable difference is (.*?)\./i)[1];
            let infoMissing =
                _.isNil(amountDiffPercent) ||
                _.isNil(newBulkAmountPerBag) ||
                _.isNil(lastBulkAmountPerBag) ||
                _.isNil(amountDiff) ||
                _.isNil(amountDiffThresholdPercent);
            if (infoMissing) {
                if (basic) {
                    return <p>{reason}</p>;
                }
                return <Typography>{reason}</Typography>;
            }
            let textList = [
                { text: 'Threshold difference of ', fontSize: fontSize },
                { text: amountDiffPercent, fontSize: fontSize, fontWeight: fontWeight },
                { text: ' detected. ', fontSize: fontSize },
                { text: newBulkAmountPerBag, fontSize: fontSize, fontWeight: fontWeight },
                { text: '/bag (current) vs ', fontSize: fontSize },
                { text: lastBulkAmountPerBag, fontSize: fontSize, fontWeight: fontWeight },
                { text: '/bag (previous) difference of ', fontSize: fontSize },
                { text: amountDiff, fontSize: fontSize, fontWeight: fontWeight },
                { text: '/bag. Allowable difference is ', fontSize: fontSize },
                { text: amountDiffThresholdPercent, fontSize: fontSize, fontWeight: fontWeight },
                { text: '.', fontSize: fontSize }
            ];
            if (previousOrderMissing) {
                textList = [
                    { text: 'Threshold difference of ', fontSize: fontSize },
                    { text: amountDiffPercent, fontSize: fontSize, fontWeight: fontWeight },
                    { text: ' detected. ', fontSize: fontSize },
                    { text: newBulkAmountPerBag, fontSize: fontSize, fontWeight: fontWeight },
                    { text: '/bag (current) vs ', fontSize: fontSize },
                    { text: '$6.72', fontSize: fontSize, fontWeight: fontWeight },
                    { text: '/bag (previous) difference of ', fontSize: fontSize },
                    { text: amountDiff, fontSize: fontSize, fontWeight: fontWeight },
                    { text: '/bag. Allowable difference is ', fontSize: fontSize },
                    { text: amountDiffThresholdPercent, fontSize: fontSize, fontWeight: fontWeight },
                    {
                        text: '. Default amount of $6.72/bag was used because a previous order could not be found',
                        fontSize: fontSize
                    }
                ];
            }
            return <RenderBoldReason textList={textList} theme={theme} />;
        } catch (e) {
            if (basic) {
                return <p>{reason}</p>;
            }
            return <Typography>{reason}</Typography>;
        }
    }
    if (type === INFRACTION_TYPES.AUDIT_TYPE_COUNTERCOMPLAINT) {
        try {
            let pastComplaintBulk = reason.match(/Counters logged (.*?) complaints against customer in the last /i)[1];
            let counterComplaintTimeWindow = reason.match(
                / complaints against customer in the last (.*?) months. Maximum /i
            )[1];
            let counterComplaintThreshold = reason.match(/ months. Maximum (.*?) complaints are allowed in /i)[1];
            let infoMissing =
                _.isNil(pastComplaintBulk) || _.isNil(counterComplaintTimeWindow) || _.isNil(counterComplaintThreshold);
            if (infoMissing) {
                if (basic) {
                    return <p>{reason}</p>;
                }
                return <Typography>{reason}</Typography>;
            }
            let textList = [
                { text: 'Counters logged ', fontSize: fontSize },
                { text: pastComplaintBulk, fontSize: fontSize, fontWeight: fontWeight },
                { text: ' complaints against customer in the last ', fontSize: fontSize },
                { text: counterComplaintTimeWindow, fontSize: fontSize, fontWeight: fontWeight },
                { text: ' months. Maximum ', fontSize: fontSize },
                { text: counterComplaintThreshold, fontSize: fontSize, fontWeight: fontWeight },
                { text: ' complaints are allowed in ', fontSize: fontSize },
                { text: counterComplaintTimeWindow, fontSize: fontSize, fontWeight: fontWeight },
                { text: ' months.', fontSize: fontSize }
            ];
            return <RenderBoldReason textList={textList} theme={theme} />;
        } catch (e) {
            if (basic) {
                return <p>{reason}</p>;
            }
            return <Typography>{reason}</Typography>;
        }
    }
    if (type === INFRACTION_TYPES.AUDIT_TYPE_BAGCOUNT) {
        try {
            let diffText = reason.match(/Difference of (.*?) detected. Customer reported /i)[1];
            let reportText = reason.match(/ detected. Customer reported (.*?). Driver reported /i)[1];
            let pickupText = reason.match(/. Driver reported (.*?). Allowable difference is /i)[1];
            let pickupBagDifference = reason.match(/. Allowable difference is (.*?)\./i)[1];
            let infoMissing =
                _.isNil(diffText) || _.isNil(reportText) || _.isNil(pickupText) || _.isNil(pickupBagDifference);
            if (infoMissing) {
                if (basic) {
                    return <p>{reason}</p>;
                }
                return <Typography>{reason}</Typography>;
            }
            let textList = [
                { text: 'Difference of ', fontSize: fontSize },
                { text: diffText, fontSize: fontSize, fontWeight: fontWeight },
                { text: ' detected. Customer reported ', fontSize: fontSize },
                { text: reportText, fontSize: fontSize, fontWeight: fontWeight },
                { text: '. Driver reported ', fontSize: fontSize },
                { text: pickupText, fontSize: fontSize, fontWeight: fontWeight },
                { text: '. Allowable difference is ', fontSize: fontSize },
                { text: pickupBagDifference, fontSize: fontSize, fontWeight: fontWeight },
                { text: '.', fontSize: fontSize }
            ];
            return <RenderBoldReason textList={textList} theme={theme} />;
        } catch (e) {
            if (basic) {
                return <p>{reason}</p>;
            }
            return <Typography>{reason}</Typography>;
        }
    }
    if (type === INFRACTION_TYPES.AUDIT_TYPE_DRIVERCOMPLAINT) {
        try {
            let pastComplaintPickup = reason.match(/Drivers logged (.*?) complaints against customer in the last /i)[1];
            let driverComplaintTimeWindow = reason.match(
                / complaints against customer in the last (.*?) months. Maximum /i
            )[1];
            let driverComplaintThreshold = reason.match(/ months. Maximum (.*?) complaints are allowed in /i)[1];
            let infoMissing =
                _.isNil(pastComplaintPickup) || _.isNil(driverComplaintTimeWindow) || _.isNil(driverComplaintThreshold);
            if (infoMissing) {
                if (basic) {
                    return <p>{reason}</p>;
                }
                return <Typography>{reason}</Typography>;
            }
            let textList = [
                { text: 'Drivers logged ', fontSize: fontSize },
                { text: pastComplaintPickup, fontSize: fontSize, fontWeight: fontWeight },
                { text: ' complaints against customer in the last ', fontSize: fontSize },
                { text: driverComplaintTimeWindow, fontSize: fontSize, fontWeight: fontWeight },
                { text: ' months. Maximum ', fontSize: fontSize },
                { text: driverComplaintThreshold, fontSize: fontSize, fontWeight: fontWeight },
                { text: ' complaints are allowed in ', fontSize: fontSize },
                { text: driverComplaintTimeWindow, fontSize: fontSize, fontWeight: fontWeight },
                { text: ' months.', fontSize: fontSize }
            ];
            return <RenderBoldReason textList={textList} theme={theme} />;
        } catch (e) {
            if (basic) {
                return <p>{reason}</p>;
            }
            return <Typography>{reason}</Typography>;
        }
    }
    if (type === INFRACTION_TYPES.AUDIT_TYPE_REDEMPTIONAMOUNT) {
        try {
            let redemptionThreshold = reason.match(/Customer exceeded allowable redemption amount of (.*?) in /i)[1];
            let redemptionTimeWindow = reason.match(/ in (.*?) months with redemption of /i)[1];
            let currentRedemptionsAmount = reason.match(/ months with redemption of (.*?) \(/i)[1];
            let redemptionAmountWithinWindow = reason.match(/ \((.*?) total\) on /i)[1];
            let redemptionDate = reason.match(/ total\) on (.*?)\./i)[1];
            let infoMissing =
                _.isNil(redemptionThreshold) ||
                _.isNil(redemptionTimeWindow) ||
                _.isNil(currentRedemptionsAmount) ||
                _.isNil(redemptionAmountWithinWindow) ||
                _.isNil(redemptionDate);
            if (infoMissing) {
                if (basic) {
                    return <p>{reason}</p>;
                }
                return <Typography>{reason}</Typography>;
            }
            let textList = [
                { text: 'Customer exceeded allowable redemption amount of ', fontSize: fontSize },
                { text: redemptionThreshold, fontSize: fontSize, fontWeight: fontWeight },
                { text: ' in ', fontSize: fontSize },
                { text: redemptionTimeWindow, fontSize: fontSize, fontWeight: fontWeight },
                { text: ' months with redemption of ', fontSize: fontSize },
                { text: currentRedemptionsAmount, fontSize: fontSize, fontWeight: fontWeight },
                { text: ' (', fontSize: fontSize },
                { text: redemptionAmountWithinWindow, fontSize: fontSize, fontWeight: fontWeight },
                { text: ' total) on ', fontSize: fontSize },
                { text: redemptionDate, fontSize: fontSize },
                { text: '.', fontSize: fontSize }
            ];
            return <RenderBoldReason textList={textList} theme={theme} />;
        } catch (e) {
            if (basic) {
                return <p>{reason}</p>;
            }
            return <Typography>{reason}</Typography>;
        }
    }
    if (type === INFRACTION_TYPES.AUDIT_TYPE_REDEMPTIONEMAIL) {
        try {
            let accountEmail = reason.match(/Customer account (.*?) is redeeming /i)[1];
            let currentRedemptionsAmount = reason.match(/ is redeeming (.*?) to /i)[1];
            let redemptionEmail = reason.match(/ to (.*?)\./i)[1];
            let infoMissing = _.isNil(accountEmail) || _.isNil(redemptionEmail) || _.isNil(currentRedemptionsAmount);
            if (infoMissing) {
                if (basic) {
                    return <p>{reason}</p>;
                }
                return <Typography>{reason}</Typography>;
            }
            let textList = [
                { text: 'Customer account ', fontSize: fontSize },
                { text: accountEmail, fontSize: fontSize, fontWeight: fontWeight },
                { text: ' is redeeming ', fontSize: fontSize },
                { text: currentRedemptionsAmount, fontSize: fontSize, fontWeight: fontWeight },
                { text: ' to ', fontSize: fontSize },
                { text: redemptionEmail, fontSize: fontSize, fontWeight: fontWeight },
                { text: '.', fontSize: fontSize }
            ];
            return <RenderBoldReason textList={textList} theme={theme} />;
        } catch (e) {
            console.log(e);
            if (basic) {
                return <p>{reason}</p>;
            }
            return <Typography>{reason}</Typography>;
        }
    }
    if (basic) {
        return <p>{reason}</p>;
    }
    return <Typography>{reason}</Typography>;
}

function RenderBoldReason(props) {
    const { textList, theme } = props;
    return (
        <>
            {textList.map(textObj => {
                const { fontSize, fontWeight, text } = textObj;
                return (
                    <span
                        style={{
                            fontFamily: theme.typography.fontFamily,
                            fontSize: fontSize,
                            fontWeight: fontWeight,
                            color: theme.palette.text.primary
                        }}
                    >
                        {text}
                    </span>
                );
            })}
        </>
    );
}

function getSkuFilteredImages(images, skuTypes) {
    if (_.isEmpty(skuTypes)) {
        return images;
    }

    const filteredImages = _.filter(images, imgURL => {
        const lastSlashIndex = imgURL.lastIndexOf('/');
        const fileName = imgURL.substring(lastSlashIndex, imgURL.length);

        for (let skuType of skuTypes) {
            if (fileName.includes(skuType)) {
                return true;
            }
        }
    });

    if (_.isEmpty(filteredImages)) {
        // If all images are filtered out, return them all anyways
        return images;
    }

    return filteredImages;
}

function getShortUserType(user) {
    let accountType = user.accountType;
    if (user.accountType === 'Collector Employee') {
        return user.accountPermissions.join('/');
    }
    const shortUserTypeDict = {
        'System Administrator': 'System Admin',
        'Collector Administrator': 'Manager'
    };
    if (shortUserTypeDict[accountType] === undefined) {
        return accountType;
    }
    return shortUserTypeDict[accountType];
}
