import React, { useState } from 'react';
import _ from 'lodash';

import {
    Button,
    CircularProgress,
    Grid,
    Typography,
    TextField,
    withTheme,
    Table,
    TableRow,
    TableHead,
    TableCell,
    TableBody,
    MenuItem,
    colors,
    FormControlLabel,
    FormControl,
    Switch
} from '@material-ui/core';
import DatePicker from 'components/DateTimePickersTz/DatePicker';
import StringListInput from 'components/CustomInputs/StringListInput';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { useContext } from 'react';
import HttpContext from 'utils/contexts/HttpContext';
import SnackbarContext from 'components/CustomSnackbar/SnackbarContext';
import { isStagingEnv } from 'utils/misc';

import moment from 'moment-timezone';

function TestSendComponent(props) {
    const { formik, templateVariables, theme, smsConfigData, attachments } = props;
    const sendOut = formik.values;
    const timezone = process.env.REACT_APP_REGION_TIMEZONE;
    const defaultDate = moment()
        .tz(timezone)
        .add(1, 'day')
        .add(1, 'hour')
        .startOf('hour')
        .toDate();
    const [notificationType, setNotificationType] = useState('email');
    const [emails, setEmails] = useState([]);
    const [phoneNumbers, setPhoneNumbers] = useState([]);
    const [pushUserEmails, setPushUserEmails] = useState([]);
    const [loading, setLoading] = useState(false);
    const [templateVariableMode, setTemplateVariableMode] = useState('manual');
    const [templateVariableValues, setTemplateVariableValues] = useState({});

    const http = useContext(HttpContext);
    const onSnackbar = useContext(SnackbarContext);

    const handleSendTestNotification = async () => {
        setLoading(true);
        const testSendObject = {
            emails: notificationType === 'email' ? emails : undefined,
            phoneNumbers: notificationType === 'sms' ? phoneNumbers : undefined,
            pushUserEmails: notificationType === 'push' ? pushUserEmails : undefined,
            sendOut,
            templateVariableMode,
            templateVariableValues
        };
        const formData = new FormData();
        formData.append('values', JSON.stringify(testSendObject));
        attachments.forEach((file, index) => {
            formData.append(`attachments`, file);
        });
        const res = await http.post(`/sendOuts/testSendOut/${notificationType}`, formData, false, true);
        if (res.ok) {
            onSnackbar('Notification Sent');
        }
        setLoading(false);
    };

    return (
        <Grid container spacing={theme.spacing.unit * 2}>
            <Grid item xs={12} md={12}>
                <ToggleButtonGroup
                    value={notificationType}
                    exclusive
                    onChange={(e, type) => !_.isEmpty(type) && setNotificationType(type)}
                    style={{ marginTop: theme.spacing.unit * 2, marginBottom: theme.spacing.unit, display: 'flex' }}
                >
                    <ToggleButton key="email" value="email" style={{ flexGrow: 1 }}>
                        Email
                    </ToggleButton>
                    {smsConfigData.config.isEnabled && (
                        <ToggleButton key="sms" value="sms" style={{ flexGrow: 1 }}>
                            SMS
                        </ToggleButton>
                    )}
                    <ToggleButton key="push" value="push" style={{ flexGrow: 1 }}>
                        Push Notification
                    </ToggleButton>
                </ToggleButtonGroup>
                {notificationType === 'email' && (
                    <StringListInput
                        InputProps={{
                            variant: 'outlined'
                        }}
                        disabled={
                            !_.get(sendOut, 'emailNotification.sendEmailNotification', false) ||
                            _.isEmpty(_.get(sendOut, 'emailNotification.templateId')) ||
                            _.isNil(_.get(sendOut, 'emailNotification.unsubscribeGroup'))
                        }
                        label="Emails"
                        values={emails}
                        onChange={values => setEmails(values)}
                        error={email => {
                            let regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                            return !(email === '') && !regexEmail.test(email.toLowerCase());
                        }}
                        errorMessage="Please enter a valid email address."
                    />
                )}

                {notificationType === 'sms' && (
                    <StringListInput
                        InputProps={{
                            variant: 'outlined'
                        }}
                        disabled={
                            !_.get(sendOut, 'smsNotification.sendSms', false) ||
                            _.isEmpty(_.get(sendOut, 'smsNotification.text'))
                        }
                        label="Phone Numbers"
                        error={() => {}}
                        values={phoneNumbers}
                        onChange={values => setPhoneNumbers(values)}
                    />
                )}

                {notificationType === 'push' && (
                    <>
                        <Typography>
                            Push notifications can only be sent to users who have previously logged into there account
                            on the Android/IOS app.
                        </Typography>
                        <StringListInput
                            InputProps={{
                                variant: 'outlined'
                            }}
                            disabled={
                                !_.get(sendOut, 'pushNotification.sendPushNotification', false) ||
                                _.isEmpty(_.get(sendOut, 'pushNotification.title')) ||
                                _.isEmpty(_.get(sendOut, 'pushNotification.text'))
                            }
                            label="Account Emails"
                            values={pushUserEmails}
                            onChange={values => setPushUserEmails(values)}
                            error={email => {
                                let regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                                return !(email === '') && !regexEmail.test(email.toLowerCase());
                            }}
                            errorMessage="Please enter a valid email address."
                        />
                    </>
                )}

                <TextField
                    fullWidth
                    variant="outlined"
                    select
                    value={templateVariableMode}
                    onChange={e => setTemplateVariableMode(e.target.value)}
                    style={{ marginTop: theme.spacing.unit }}
                >
                    <MenuItem value="manual">Enter Values Manually</MenuItem>
                    <MenuItem value="linkAcount">Use Account Values</MenuItem>
                </TextField>
                {templateVariableMode === 'linkAcount' && (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginTop: theme.spacing.unit * 2
                        }}
                    >
                        <Typography variant="h6">Restrict User Data</Typography>
                        <FormControlLabel
                            control={
                                <Switch
                                    {...formik.getFieldProps('testRestrictData')}
                                    checked={_.get(sendOut, 'testRestrictData')}
                                />
                            }
                        />
                    </div>
                )}
                {_.get(sendOut, 'testRestrictData') && templateVariableMode === 'linkAcount' && (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            gap: theme.spacing.unit,
                            marginTop: theme.spacing.unit
                        }}
                    >
                        <Typography variant="subtitle2">Use Date Between</Typography>
                        <FormControl fullWidth style={{ flex: 1 }}>
                            <DatePicker
                                timezone={timezone}
                                type="start"
                                label="Start Date"
                                format="DD MMM YYYY"
                                value={moment(_.get(sendOut, 'testStartDate'))}
                                variant="outlined"
                                onChange={d => formik.setFieldValue('testStartDate', d.toDate())}
                            />
                        </FormControl>
                        <FormControl fullWidth style={{ flex: 1 }}>
                            <DatePicker
                                timezone={timezone}
                                type="end"
                                label="End Date"
                                format="DD MMM YYYY"
                                value={moment(_.get(sendOut, 'testEndDate'))}
                                variant="outlined"
                                onChange={d => formik.setFieldValue('testEndDate', d.toDate())}
                            />
                        </FormControl>
                    </div>
                )}
                <Button
                    variant="outlined"
                    disabled={sendDisabled(emails, phoneNumbers, pushUserEmails, notificationType) || loading}
                    onClick={handleSendTestNotification}
                    style={{ marginTop: theme.spacing.unit * 2 }}
                >
                    Send {notificationType}{' '}
                    {loading && (
                        <CircularProgress size={22} thickness={4.8} style={{ marginLeft: theme.spacing.unit }} />
                    )}
                </Button>
                {isStagingEnv && (
                    <Typography style={{ color: colors.orange[500] }}>
                        This will send notifications on staging.
                    </Typography>
                )}
            </Grid>
            <Grid item xs={12} md={12} style={{}}>
                <Table
                    style={{
                        display: 'block',
                        maxHeight: 250,
                        overflowY: 'auto',
                        padding: theme.spacing.unit,
                        borderStyle: 'solid',
                        borderWidth: 1,
                        borderColor: theme.palette.text.disabled,
                        borderRadius: theme.shape.borderRadius
                    }}
                >
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ padding: '8px' }}>Dynamic Variable</TableCell>
                            <TableCell style={{ padding: '8px' }}>Placeholder</TableCell>
                            {templateVariableMode === 'manual' && (
                                <TableCell style={{ padding: '8px' }}>Test Value</TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {templateVariables.map(tempVar => (
                            <TableRow key={_.get(tempVar, 'name')}>
                                <TableCell style={{ padding: '8px' }}>{tempVar.description}</TableCell>
                                <TableCell style={{ padding: '8px' }}>
                                    <span style={{ color: theme.palette.text.secondary }}>{`{{{${
                                        tempVar.name
                                    }}}}`}</span>
                                </TableCell>
                                {templateVariableMode === 'manual' && (
                                    <TableCell style={{ padding: '8px' }}>
                                        <TextField
                                            name={tempVar.name}
                                            value={_.get(templateVariableValues, tempVar.name, '')}
                                            onChange={e =>
                                                setTemplateVariableValues(val => {
                                                    return { ...val, [e.target.name]: e.target.value };
                                                })
                                            }
                                        />
                                    </TableCell>
                                )}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Grid>
        </Grid>
    );
}

export default withTheme()(TestSendComponent);

function sendDisabled(emails, phoneNumbers, pushUserEmails, notificationType) {
    if (notificationType === 'email') return _.isEmpty(emails);
    else if (notificationType === 'sms') return _.isEmpty(phoneNumbers);
    else return _.isEmpty(pushUserEmails);
}
