import React from 'react';
import _ from 'lodash';
import { getFilteredName, isEXPRegion } from 'utils/misc.js';
import Button from '@material-ui/core/Button';

import FormControl from '@material-ui/core/FormControl';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { withTheme } from '@material-ui/core/styles';

import { _user } from 'std';
import { loc } from '../../../../localizations/localizationHandler';

import PasswordInput from 'components/InputComponents/PasswordInput';
import { LinearProgress } from '@material-ui/core';

function ProfileNotCompleteDialog(props) {
    const {
        profileNotCompleteDialogOpen,
        customer,
        lang,
        formControlStyle,
        password,
        passwordErr,
        operator,
        savingPassword,
        handlePasswordsChange,
        passwordConfirmation,
        passwordsMatch,
        handleChangePasswordSubmit,
        setprofileNotCompleteDialogOpen
    } = props;

    return (
        <Dialog open={profileNotCompleteDialogOpen} maxWidth="sm" fullWidth>
            <DialogTitle>
                {loc(customer.forcePasswordChange ? 'accountSettings33' : 'accountSettings20', lang)}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {customer.forcePasswordChange ? (
                        <ForcePasswordContent
                            lang={lang}
                            formControlStyle={formControlStyle}
                            password={password}
                            passwordErr={passwordErr}
                            customer={customer}
                            operator={operator}
                            savingPassword={savingPassword}
                            handlePasswordsChange={handlePasswordsChange}
                            passwordConfirmation={passwordConfirmation}
                            passwordsMatch={passwordsMatch}
                        />
                    ) : (
                        <>
                            <div style={{ marginBottom: props.theme.spacing.unit }}>
                                {loc('accountSettings21', lang)}
                            </div>
                            {!getFilteredName(customer, 'name.first') && (
                                <div style={{ fontWeight: 'bold' }}>{loc('firstName', lang)}</div>
                            )}
                            {!getFilteredName(customer, 'name.last') && (
                                <div style={{ fontWeight: 'bold' }}>{loc('lastName', lang)}</div>
                            )}
                            {_.isEmpty(customer.phone) && (
                                <div style={{ fontWeight: 'bold' }}>{loc('phoneNumber', lang)}</div>
                            )}
                            {((isEXPRegion() && _.eq(_.get(customer, 'isExpressLegacyLocationUpdated', true), false)) ||
                                _.isEmpty(_.get(customer, 'location.description', {}))) && (
                                <div style={{ fontWeight: 'bold' }}>{loc('taxReceiptCol4', lang)}</div>
                            )}
                            {_.isEmpty(customer.email) && (
                                <div style={{ fontWeight: 'bold' }}>{loc('email', lang)}</div>
                            )}
                            <div style={{ marginTop: props.theme.spacing.unit }}> {loc('accountSettings22', lang)}</div>
                        </>
                    )}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        if (customer.forcePasswordChange) {
                            handleChangePasswordSubmit();
                        } else {
                            setprofileNotCompleteDialogOpen(false);
                        }
                    }}
                    color="primary"
                    disabled={
                        customer.forcePasswordChange &&
                        (!passwordsMatch || password.length === 0 || savingPassword || passwordErr.fail)
                    }
                >
                    {loc('okay', lang)}
                </Button>
            </DialogActions>
            {savingPassword && <LinearProgress />}
        </Dialog>
    );
}

const ForcePasswordContent = props => {
    const {
        lang,
        formControlStyle,
        password,
        passwordErr,
        customer,
        operator,
        savingPassword,
        handlePasswordsChange,
        passwordConfirmation,
        passwordsMatch
    } = props;

    return (
        <>
            <div>{loc('accountSettings38', lang)}</div>
            <br />
            <div>{loc('accountSettings37', lang)}</div>
            <FormControl fullWidth style={formControlStyle}>
                <PasswordInput
                    showValidationRules
                    data-cy="profile-new-password-dialog"
                    name="password"
                    label={loc('accountSettings39', lang)}
                    type="password"
                    value={password}
                    helperText={passwordErr.description}
                    disabled={
                        !_.isNil(customer.oAuth.provider) ||
                        (customer.disabled && !_user.isSystemAdmin(operator)) ||
                        savingPassword
                    }
                    onChange={handlePasswordsChange}
                    error={passwordErr.fail && !_.isEmpty(password)}
                />
            </FormControl>
            <FormControl fullWidth style={formControlStyle}>
                <PasswordInput
                    data-cy="profile-new-password-confirmation-dialog"
                    name="passwordConfirmation"
                    label={loc('accountSettings40', lang)}
                    type="password"
                    value={passwordConfirmation}
                    helperText={''}
                    disabled={
                        !_.isNil(customer.oAuth.provider) ||
                        (customer.disabled && !_user.isSystemAdmin(operator)) ||
                        savingPassword
                    }
                    onChange={handlePasswordsChange}
                    error={!passwordsMatch}
                />
            </FormControl>
        </>
    );
};

export default withTheme()(ProfileNotCompleteDialog);
