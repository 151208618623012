import React, { useRef, useEffect } from 'react';

import _ from 'lodash';

import moment from 'moment-timezone';

import { Grid, Paper, Typography, IconButton, Button, Icon, withTheme, withStyles } from '@material-ui/core';

import { deviceHelper } from 'utils/misc';

import { Filesystem, FilesystemDirectory, FilesystemEncoding } from '@capacitor/filesystem';
import { Clipboard } from '@capacitor/clipboard';

import { useContext } from 'react';
import SnackbarContext from 'components/CustomSnackbar/SnackbarContext';

import { locDate, loc } from 'localizations/localizationHandler';
import LocalizationContext from 'utils/contexts/LocalizationContext';

const styles = {
    root: {
        '&:hover': {
            opacity: 1
        }
    }
};

async function copyImage(id, onSnackbar, lang) {
    var img = document.getElementById(id);
    var imgCanvas = document.createElement('canvas');

    imgCanvas.height = img.naturalHeight;
    imgCanvas.width = img.naturalWidth;

    //stop app from crashing on large image
    if (img.naturalHeight > 2000 || img.naturalWidth > 2000) {
        const scaleFactor = Math.min(2000 / img.naturalHeight, 2000 / img.naturalWidth);
        imgCanvas.height *= scaleFactor;
        imgCanvas.width *= scaleFactor;
    }

    document.body.appendChild(imgCanvas);
    var originalContext = imgCanvas.getContext('2d');
    originalContext.drawImage(img, 0, 0, img.naturalWidth, img.naturalHeight, 0, 0, imgCanvas.width, imgCanvas.height);
    //clipboard plugin image copy only works for IOS
    if (deviceHelper.iOSCordova()) {
        const dataUrl = imgCanvas.toDataURL();
        await Clipboard.write({
            image: dataUrl
        });
        onSnackbar(loc('charitySnackbar1', lang));
        //save file instead of copying
    } else if (deviceHelper.AndroidCordova()) {
        const dataUrl = imgCanvas.toDataURL();
        Filesystem.writeFile({
            path: `example_${locDate(moment(), 'DD-MM-YYYY_HH:mm:ss', lang)}.png`,
            data: dataUrl,
            directory: FilesystemDirectory.Documents
        })
            .then(() => {
                onSnackbar(loc('charitySnackbar2', lang));
            })
            .catch(err => {
                console.log(err);
                onSnackbar(loc('charitySnackbar3', lang), 'error');
            });
    } else {
        imgCanvas.toBlob(async blob => {
            try {
                navigator.clipboard.write([
                    // eslint-disable-next-line no-undef
                    new ClipboardItem({
                        'image/png': blob
                    })
                ]);

                onSnackbar(loc('charitySnackbar1', lang));
            } catch (error) {
                console.error(error);
                onSnackbar(loc('charitySnackbar4', lang), 'error');
            }
        });
    }
    imgCanvas.remove();
}

function CustomGrid(props) {
    const { contentItems, theme, classes } = props;

    const onSnackbar = useContext(SnackbarContext);
    const { lang } = useContext(LocalizationContext);

    return (
        <Grid container spacing={theme.spacing.unit * 2} justify="center">
            {contentItems.map((contentItem, index) => {
                return (
                    <Grid
                        key={index}
                        item
                        sm={_.isNil(_.get(contentItem, 'width.md', null)) ? 6 : _.get(contentItem, 'width.md', null)}
                        xs={_.isNil(_.get(contentItem, 'width.xs', null)) ? 12 : _.get(contentItem, 'width.xs', null)}
                    >
                        <Paper style={{ height: '100%' }}>
                            {contentItem.image && (
                                <div style={{ position: 'relative' }}>
                                    <div>
                                        <img
                                            src={require('../../../../public' + contentItem.image)}
                                            /*src={
                                                process.env.REACT_APP_ORIGIN_URL +
                                                process.env.PUBLIC_URL +
                                                '/' +
                                                contentItem.image
                                            }*/
                                            id={
                                                process.env.REACT_APP_ORIGIN_URL +
                                                process.env.PUBLIC_URL +
                                                '/' +
                                                contentItem.image
                                            }
                                            style={{ width: '100%' }}
                                            alt="post"
                                        />
                                    </div>
                                    <IconButton
                                        classes={{ root: classes.root }}
                                        style={{
                                            position: 'absolute',
                                            bottom: theme.spacing.unit,
                                            right: theme.spacing.unit,
                                            opacity: 0.7
                                        }}
                                        onClick={event =>
                                            copyImage(
                                                process.env.REACT_APP_ORIGIN_URL +
                                                    process.env.PUBLIC_URL +
                                                    '/' +
                                                    contentItem.image,
                                                onSnackbar,
                                                lang
                                            )
                                        }
                                    >
                                        <Icon> {deviceHelper.AndroidCordova() ? 'save_alt' : 'file_copy'}</Icon>
                                    </IconButton>
                                </div>
                            )}
                            {contentItem.text && (
                                <div style={{ padding: theme.spacing.unit }}>
                                    <Typography variant="body1">{contentItem.text}</Typography>
                                </div>
                            )}
                        </Paper>
                    </Grid>
                );
            })}
        </Grid>
    );
}

export default withStyles(styles)(withTheme()(CustomGrid));
