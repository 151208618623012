import React, { useEffect, useContext } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';

import { formatAsCurrency } from 'utils/misc';

import useTable from 'components/Table/useTable';
import Table from 'components/Table/Table';
import useGetJSON from 'utils/hooks/useGetJSON';

import { mdiDesktopClassic, mdiMapMarkerPath } from '@mdi/js';
import MDIcon from '@mdi/react';

import useDateRangePicker from 'components/DateTimePickersTz/hooks/useDateRangePicker';
import DateRangePicker from 'components/DateTimePickersTz/DateRangePicker';

import {
    Grid,
    withTheme,
    TableRow,
    TableCell,
    Paper,
    Typography,
    Icon,
    Avatar,
    FormControl,
    Select,
    MenuItem,
    Snackbar,
    IconButton
} from '@material-ui/core';

import { _user, _time, _bulk } from 'std';
import SnackbarContext from 'components/CustomSnackbar/SnackbarContext';
import HttpContext from 'utils/contexts/HttpContext';

const headers = [
    { name: 'Redemptions', locKey: null },
    { name: 'Total Redeemed', locKey: null },
    { name: 'Total fees', locKey: null },
    { name: 'QBO Expense ID', locKey: null },
    { name: 'QBO Fees ID', locKey: null },
    { name: 'Date Completed', locKey: null },
    { name: 'Date Sent to Quickbooks', locKey: null }
];

function DepositBreakdown(props) {
    const { theme } = props;

    const {
        loading,
        getData,
        page,
        rowsPerPage,
        filteredData,
        search,
        filteredHeaders,
        totalDataEntries,
        handleChange,
        handleSearch,
        handleChangeFilterHeaders,
        handleAllFilterHeaders,
        handleChangePage,
        handleChangeRowsPerPage
    } = useTable({ url: '/redemptions/redemptionReport', key: 'redemptionReport', headers: headers.map(h => h.name) });

    const renderRow = redemptionGroup => {
        return (
            <TableRow key={redemptionGroup._id}>
                {filteredHeaders.includes('Redemptions') && (
                    <TableCell>{_.get(redemptionGroup, 'totalRedemptions', 0)}</TableCell>
                )}
                {filteredHeaders.includes('Total Redeemed') && (
                    <TableCell>{formatAsCurrency(_.get(redemptionGroup, 'totalAmount', 0))}</TableCell>
                )}
                {filteredHeaders.includes('Total fees') && (
                    <TableCell>{formatAsCurrency(_.get(redemptionGroup, 'totalFees', 0))}</TableCell>
                )}
                {filteredHeaders.includes('QBO Expense ID') && (
                    <TableCell>{_.get(redemptionGroup, 'totalRedeemedQuickbooksId', '')}</TableCell>
                )}
                {filteredHeaders.includes('QBO Fees ID') && (
                    <TableCell>{_.get(redemptionGroup, 'feesQuickbooksId', '')}</TableCell>
                )}
                {filteredHeaders.includes('Date Completed') && (
                    <TableCell>{moment(_.get(redemptionGroup, '_id')).format('YYYY/MM/DD h:mm:ss a')}</TableCell>
                )}
                {filteredHeaders.includes('Date Sent to Quickbooks') && (
                    <TableCell>
                        {moment(_.get(redemptionGroup, 'dateSentToQuickbooks')).format('YYYY/MM/DD h:mm:ss a')}
                    </TableCell>
                )}
            </TableRow>
        );
    };

    const {
        startDate,
        endDate,
        timezone,
        dateWindow,
        handleChangeStartDate,
        handleChangeEndDate,
        handleGoForwards,
        handleGoBackwards,
        handleWindowSelect
    } = useDateRangePicker({
        saveStateInURL: true,
        timezones: [process.env.REACT_APP_REGION_TIMEZONE],
        initStartVal: _time.getStartOfWeek(process.env.REACT_APP_REGION_TIMEZONE),
        initEndVal: _time.getEndOfDay(process.env.REACT_APP_REGION_TIMEZONE)
    });

    useEffect(() => {
        getData();
    }, [startDate, endDate, timezone, dateWindow]);

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <div style={{ padding: theme.spacing.unit * 2, paddingBottom: theme.spacing.unit }}>
                        <Paper
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                padding: theme.spacing.unit * 2,
                                height: '100%',
                                flexWrap: 'wrap'
                            }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <DateRangePicker
                                    timezone={timezone}
                                    startDate={startDate}
                                    endDate={endDate}
                                    window={dateWindow}
                                    disabled={loading}
                                    handlePrevious={handleGoBackwards}
                                    handleNext={handleGoForwards}
                                    handleChangeStartDate={handleChangeStartDate}
                                    handleChangeEndDate={handleChangeEndDate}
                                />
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexWrap: 'wrap'
                                }}
                            >
                                <FormControl>
                                    <Select
                                        value={dateWindow}
                                        disabled={loading}
                                        onChange={handleWindowSelect}
                                        style={{
                                            maxWidth: '250px',
                                            margin: theme.spacing.unit
                                        }}
                                    >
                                        <MenuItem value="today">Daily</MenuItem>
                                        <MenuItem value="thisWeek">Weekly</MenuItem>
                                        <MenuItem value="thisMonth">Monthly</MenuItem>
                                        <MenuItem value="thisQuarter">Quarterly</MenuItem>
                                        <MenuItem value="allTime">All Time</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </Paper>
                    </div>
                </Grid>
            </Grid>
            <Table
                title="Redemptions Report"
                loading={loading}
                headers={headers}
                filteredHeaders={filteredHeaders}
                length={totalDataEntries}
                page={page}
                rowsPerPage={rowsPerPage}
                noSearch
                //search={search}
                handleChange={handleChange}
                handleChangeFilterHeaders={handleChangeFilterHeaders}
                handleAllFilterHeaders={handleAllFilterHeaders}
                //handleSearch={handleSearch}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                refresh={getData}
            >
                {filteredData &&
                    filteredData.map(user => {
                        return renderRow(user, filteredHeaders);
                    })}
            </Table>
        </>
    );
}

export default withTheme()(DepositBreakdown);
