import React from 'react';
const { Button, colors, Icon } = require('@material-ui/core');

function RoundSquareButton(props) {
    const { button, theme, style, onClick, icon = 'person', width = 90, variant = 'outlined' } = props;
    const buttonColor = button.disabled ? colors.grey[500] : button.color;
    return (
        <Button
            style={{
                ...style,
                border: variant === 'outlined' ? '1px solid' : undefined,
                backgroundColor: variant === 'contained' ? buttonColor : undefined,
                borderColor: buttonColor,
                borderRadius: 4,
                paddingTop: 6,
                height: width,
                width: width,
                textAlign: 'center'
            }}
            onClick={onClick ? onClick : button.onClick}
            disabled={button.disabled}
            data-cy={button['data-cy']}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <Icon
                    style={{
                        fontSize: '36px',
                        alignSelf: 'center',
                        color: variant === 'contained' ? 'white' : buttonColor
                    }}
                >
                    {icon}
                </Icon>
                <div
                    style={{
                        color: variant === 'contained' ? 'white' : buttonColor,
                        fontFamily: theme.typography.fontFamily,
                        fontSize: theme.typography.fontSize * 0.9,
                        fontWeight: 400
                        // whiteSpace: 'nowrap'
                    }}
                >
                    {button.text}
                </div>
            </div>
        </Button>
    );
}

export default RoundSquareButton;
